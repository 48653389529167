import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { message } from 'antd';
import { EntityButtonProps } from '../../../../components';

export const config: Config = {
    entities: {
        couponConsumeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/couponConsumeLoader',
            filters: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<codeRequired>>',
                    },
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                    },
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrddh>>',
                    },
                },
                consumeTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hxsj>>',
                    defaultValue: undefined,
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                consumeTime: {
                    type: 'string',
                    displayName: '<<hxsj>>',
                },
            },
        },
        couponConsumeDetailsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/couponConsumeLoader',
            properties: {
                id: {
                    type: 'string',
                },
                productName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                goodsId: {
                    type: 'string',
                    displayName: '货品 ID',
                },
                type: {
                    type: 'string',
                    displayName: '商品类型',
                },
                quantity: {
                    type: 'string',
                    displayName: '商品数量',
                },
                price: {
                    type: 'number.price',
                    displayName: '商品原价',
                },
                execPrice: {
                    type: 'number.price',
                    displayName: '促销折后价',
                },
                totalPrice: {
                    type: 'number.price',
                    displayName: '商品总价',
                },
                afterFoldingPrice: {
                    type: 'number.price',
                    displayName: '商品优惠券折后价',
                },
            },
        },
    },
    components: {
        couponConsumeView: {
            component: 'Viewport',
        },
        couponConsumePage: {
            component: 'FlexLayout',
            entity: 'couponConsumeEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'couponConsumeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 16,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '导出数据',
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );

                                            if (
                                                entityButtonProps.entity.filters &&
                                                (entityButtonProps.entity.filters as any)
                                                    .consumeTime
                                            ) {
                                                Object.assign(params, {
                                                    startTime: (entityButtonProps.entity
                                                        .filters as any).consumeTime.start,
                                                    endTime: (entityButtonProps.entity
                                                        .filters as any).consumeTime.end,
                                                    consumeTime: undefined,
                                                });
                                            }

                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                                apiPath: '/admin/coupon_consume_records/export',
                                            };

                                            api.get(params, config)
                                                .then(() => {
                                                    message.success(
                                                        '数据生成中，请在下载优惠券数据导出'
                                                    );
                                                })
                                                .catch((error) => {
                                                    errorHandle(error);
                                                });
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'couponConsumeTable',
                },
            ],
        },
        couponConsumeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteCode',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'consumeTime',
                },
            ],
        },
        couponConsumeTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteCode',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'consumeTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 120,
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        text: '<<details>>',
                        path: '/coupon-consume/details/{{row.id}}',
                        config: {
                            style: {
                                marginRight: 0,
                            },
                        },
                    },
                ],
            },
        },
        couponConsumeDetailsPage: {
            component: 'FlexLayout',
            entity: 'couponConsumeDetailsEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '商品明细',
                            content: {
                                component: 'couponConsumeDetailsTable',
                            },
                        },
                    ],
                },
            ],
        },
        couponConsumeDetailsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            columns: [
                {
                    property: 'productName',
                },
                {
                    property: 'goodsId',
                },
                {
                    property: 'type',
                },
                {
                    property: 'quantity',
                },
                {
                    property: 'price',
                },
                {
                    property: 'execPrice',
                },
                {
                    property: 'totalPrice',
                },
                {
                    property: 'afterFoldingPrice',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-consume',
                    component: 'couponConsumeView',
                    breadcrumbName: '线下核销优惠券',
                    privilege: {
                        path: 'couponConsume',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/details/:id',
                            component: 'couponConsumeDetailsPage',
                        },
                        {
                            path: '/',
                            component: 'couponConsumePage',
                        },
                    ],
                },
            ],
        },
    ],
};
