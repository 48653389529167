import React, { PureComponent } from 'react';
import { debounce, find, forEach } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Modal, message as AntMessage, Form, Select, Input, Checkbox } from 'antd';
import {
    LogisticsBillGenerationMethodValue,
    BaseDeliveryCompany,
    DeliveryCompanyObj,
    LogisticsBillGenerationMethodEnum,
    DeliveryCompanyCodeEnum,
    DeliveryModeValue,
    DeliveryMode,
} from '../types';
import './index.less';
import { getUrlParam } from '../../../applications/cae/services/utils';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

export interface AddDeliveryCompanyState {
    // 物流公司列表
    companyOptions: BaseDeliveryCompany[];
    // 选中的物流公司
    company: BaseDeliveryCompany | undefined;
    // 运单生成方式
    logisticsBillGenerationMethods: Array<LogisticsBillGenerationMethodEnum>;
    // 账号
    account: string;
    // 密钥
    password: string;
    // 是否默认
    defaulted: boolean;
}

/**
 * 添加/编辑物流公司
 */
export class AddDeliveryCompany extends PureComponent<any, AddDeliveryCompanyState> {
    // 加载物流公司可选项
    loadCompanyOptions = debounce((name?: string) => {
        const { deliveryMode } = this.props;
        const { logisticsBillGenerationMethods } = this.state;
        //没有勾选方式，公司返回空集合
        if (!logisticsBillGenerationMethods || logisticsBillGenerationMethods.length === 0) {
            this.setState({
                companyOptions: [],
            });
            return;
        }
        //有勾选方式时
        const params = {
            deliveryMode,
            name,
        };
        api.get(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/express_companies`,
        }).then((response: any) => {
            let newList = [];
            if (response.result && response.result.length > 0) {
                const isHavaAutomatic = logisticsBillGenerationMethods.includes(
                    LogisticsBillGenerationMethodEnum.AUTOMATIC
                );
                if (isHavaAutomatic) {
                    forEach(response.result, (r: BaseDeliveryCompany) => {
                        const automaticModeDisabled =
                            r && DeliveryCompanyCodeEnum[r.code] ? false : true;
                        if (!automaticModeDisabled) {
                            newList.push(r);
                        }
                    });
                } else {
                    newList = response.result;
                }
            }
            this.setState({
                companyOptions: newList,
            });
        });
    }, 200);

    constructor(props: any) {
        super(props);
        this.state = {
            companyOptions: [],
            company: undefined,
            account: '',
            password: '',
            defaulted: false,
            logisticsBillGenerationMethods: [LogisticsBillGenerationMethodEnum.MANUAL],
        };
    }

    componentDidMount() {
        this.loadCompanyOptions();
        const { type, editCompany } = this.props;
        if (type === 'edit' && editCompany) {
            const company: BaseDeliveryCompany = {
                id: editCompany.companyId,
                name: editCompany.companyName,
                code: editCompany.companyCode,
            };

            this.setState({
                company: company,
                logisticsBillGenerationMethods: editCompany.logisticsBillGenerationMethods,
                account: editCompany.account,
                password: editCompany.password,
                defaulted: editCompany.defaulted,
            });
        }
    }

    onSubmit = () => {
        const { type, subsiteId, onClose, editCompany } = this.props;
        const {
            company,
            account,
            password,
            defaulted,
            logisticsBillGenerationMethods,
        } = this.state;
        if (!this.validate()) {
            return false;
        }

        if (type === 'edit') {
            const params = {
                subsiteId,
                companyId: company?.id,
                companyCode: company?.code,
                logisticsBillGenerationMethods,
                account,
                password,
                defaulted,
            };
            api.put(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: '/admin/delivery_logistics_companies/' + editCompany.id,
            }).then(
                () => {
                    AntMessage.success(services.language.getText('editSuccess'));
                    onClose(true);
                },
                (error) => {
                    services.errorHandle(error);
                }
            );
        } else {
            const logisticsConfigId = getUrlParam(window.location.href, 'logisticsConfigId');
            const params = {
                logisticsConfigId,
                subsiteId,
                companyId: company?.id,
                companyCode: company?.code,
                logisticsBillGenerationMethods,
                account: logisticsBillGenerationMethods.includes(
                    LogisticsBillGenerationMethodEnum.AUTOMATIC
                )
                    ? account
                    : '',
                password: logisticsBillGenerationMethods.includes(
                    LogisticsBillGenerationMethodEnum.AUTOMATIC
                )
                    ? password
                    : '',
                defaulted,
            };
            api.post(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: '/admin/delivery_logistics_companies',
            }).then(
                () => {
                    AntMessage.success(services.language.getText('xzcg'));
                    onClose(true);
                },
                (error) => {
                    services.errorHandle(error);
                }
            );
        }
    };
    /**
     * 提交前额外验证
     */
    validate = () => {
        const { company, account, password, logisticsBillGenerationMethods } = this.state;
        if (!logisticsBillGenerationMethods || logisticsBillGenerationMethods.length === 0) {
            AntMessage.warning(services.language.getText('qxzydscfs'));
            return false;
        }
        if (!company) {
            AntMessage.warning(services.language.getText('qxzwlgs'));
            return false;
        }
        // 自动叫单时根据快递公司校验账户和密码
        if (logisticsBillGenerationMethods.includes(LogisticsBillGenerationMethodEnum.AUTOMATIC)) {
            const companyObj = company ? DeliveryCompanyObj[company.code] : null;
            const accountItem = companyObj ? companyObj.account : undefined;
            const passwordItem = companyObj ? companyObj.password : undefined;
            const textReg = /[\u4E00-\u9FA5]/g;
            if (accountItem?.required && !account) {
                AntMessage.warning(accountItem.placeholder);
                return false;
            }
            if (accountItem?.required && textReg.test(account)) {
                AntMessage.warning(accountItem.name + services.language.getText('errorformat'));
                return false;
            }
            if (passwordItem?.required && !password) {
                AntMessage.warning(passwordItem.placeholder);
                return false;
            }
            if (passwordItem?.required && textReg.test(password)) {
                AntMessage.warning(passwordItem.name + services.language.getText('errorformat'));
                return false;
            }
        }

        return true;
    };

    onChangeCompany = (companyId: number) => {
        let { logisticsBillGenerationMethods } = this.state;
        const company = find(this.state.companyOptions, { id: companyId });
        this.setState({
            company,
            logisticsBillGenerationMethods,
            account: '',
            password: '',
        });
    };
    onChangeBillGenerationMethod = (value: any) => {
        this.setState(
            {
                logisticsBillGenerationMethods: value,
                company: undefined,
                account: '',
                password: '',
            },
            () => {
                this.loadCompanyOptions();
            }
        );
    };
    onInputAccount = (e: any) => {
        this.setState({ account: e.target.value });
    };
    onInputPassword = (e: any) => {
        this.setState({ password: e.target.value });
    };
    onChangeDefaulted = (value: any) => {
        this.setState({
            defaulted: value && value.length ? true : false,
        });
    };

    render() {
        const { type, visible = false, onClose, deliveryMode } = this.props;
        let {
            companyOptions,
            company,
            account,
            password,
            defaulted,
            logisticsBillGenerationMethods,
        } = this.state;

        const companyObj = company ? DeliveryCompanyObj[company.code] : null;
        const accountItem = companyObj ? companyObj.account : undefined;
        const passwordItem = companyObj ? companyObj.password : undefined;
        const modeName = DeliveryModeValue[deliveryMode as DeliveryMode];
        const modalProps = {
            width: 700,
            title:
                type === 'add'
                    ? `${language.getText('common.add')}${language.getText(
                          modeName
                      )}${language.getText('wlgs')}`
                    : `${language.getText('common.edit')}${language.getText(
                          modeName
                      )}${language.getText('wlgs')}`,
            visible: visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onSubmit,
            onCancel: () => {
                onClose(false);
            },
        };

        return (
            <div>
                <Modal {...modalProps}>
                    <Form {...FormItemLayout} className="add-company-form">
                        <Form.Item label={services.language.getText('ydscfs')} required>
                            <Checkbox.Group
                                value={logisticsBillGenerationMethods}
                                onChange={this.onChangeBillGenerationMethod}
                            >
                                <Checkbox value={LogisticsBillGenerationMethodEnum.MANUAL}>
                                    {language.getText(LogisticsBillGenerationMethodValue.MANUAL)}
                                </Checkbox>
                                <Checkbox value={LogisticsBillGenerationMethodEnum.AUTOMATIC}>
                                    {language.getText(LogisticsBillGenerationMethodValue.AUTOMATIC)}
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item label={services.language.getText('wlgs')} required>
                            <Select
                                className="company-select"
                                autoClearSearchValue
                                showSearch
                                filterOption={false}
                                placeholder={services.language.getText('selectPlease')}
                                value={company?.id}
                                onChange={this.onChangeCompany}
                                onSearch={this.loadCompanyOptions}
                                onFocus={this.loadCompanyOptions}
                            >
                                {companyOptions.map((item) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}-{item.deliveryModeName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        {accountItem &&
                            logisticsBillGenerationMethods.includes(
                                LogisticsBillGenerationMethodEnum.AUTOMATIC
                            ) && (
                                <Form.Item
                                    label={language.getText(accountItem.name)}
                                    required={accountItem.required}
                                >
                                    <Input
                                        className="field-control"
                                        value={account}
                                        placeholder={language.getText(accountItem.placeholder)}
                                        onInput={this.onInputAccount}
                                        maxLength={30}
                                    />
                                </Form.Item>
                            )}
                        {passwordItem &&
                            logisticsBillGenerationMethods.includes(
                                LogisticsBillGenerationMethodEnum.AUTOMATIC
                            ) && (
                                <Form.Item
                                    label={language.getText(passwordItem.name)}
                                    required={passwordItem.required}
                                >
                                    <Input
                                        className="field-control"
                                        value={password}
                                        placeholder={language.getText(passwordItem.placeholder)}
                                        onInput={this.onInputPassword}
                                        maxLength={30}
                                    />
                                </Form.Item>
                            )}
                        <Form.Item label={services.language.getText('swmrpsgs')}>
                            <Checkbox.Group value={[defaulted]} onChange={this.onChangeDefaulted}>
                                <Checkbox
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                    value={true}
                                ></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
