import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export enum LotteryStatus {
    /*
     *已开奖
     * */
    REWARDED = 'REWARDED',
    /*
     *未开奖
     * */
    NOT_REWARDED = 'NOT_REWARDED',
}
export enum RewardStatus {
    /*
     *已发放
     * */
    ISSUED = 'ISSUED',
    /*
     *未发放
     * */
    UNISSUED = 'UNISSUED',
    /*
     *发放失败
     * */
    FAILISSUED = 'FAILISSUED',
    /*
     *发放失败
     * */
    UNLOTTERY = 'UNLOTTERY',
    NONE = 'NONE',
}

const rewardStatusList = [
    { id: RewardStatus.ISSUED, name: '<<yff>>' },
    { id: RewardStatus.UNISSUED, name: '<<wff>>' },
    { id: RewardStatus.UNLOTTERY, name: '<<wzj>>' },
    { id: RewardStatus.FAILISSUED, name: 'ffsb' },
    { id: RewardStatus.NONE, name: '-' },
];
const rewardStatusSearchList = [
    { id: RewardStatus.ISSUED, name: '<<yff>>' },
    { id: RewardStatus.UNISSUED, name: '<<wff>>' },
];
export const config: Config = {
    entities: {
        TopicEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/topicPromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'TOPIC_ACTIVITY',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        ruleIllustrate: {
                            type: 'string.text.paragraph',
                            displayName: '<<activityRule>>',
                            rules: [
                                {
                                    whitespace: true,
                                    max: 600,
                                    message: '<<limit600Input>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrhdbz>>',
                                rows: 4,
                                maxLength: 600,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        topic: {
                            type: 'array.ContentTopicSelector',
                            displayName: '<<xzht_1>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        subsiteIds: {
                            type: 'array.options.autoComplete',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '',
                        },
                        rewardBehavior: {
                            type: 'string.options.radio',
                            displayName: '<<jlxw>>',
                            defaultValue: 'VIEWS',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'VIEWS', name: '<<browse>>' },
                                { id: 'LICKED', name: '<<dz_2>>' },
                                { id: 'COMMENT', name: '<<pl>>' },
                                { id: 'FORWARD', name: '<<zf_2>>' },
                            ],
                        },
                        range: {
                            type: 'string.stringDesc',
                            displayName: '<<jlfw>>',
                            controlConfig: {
                                value: '<<rangeExtra>>',
                                style: {
                                    color: '#AAAAAA',
                                },
                            },
                        },
                        type: {
                            type: 'string.stringDesc',
                            displayName: '<<jllx>>',
                            controlConfig: {
                                value: '<<pmjl>>',
                                style: {
                                    color: '#AAAAAA',
                                },
                            },
                        },
                        ladderRewardRule: {
                            type: 'array.activityReward',
                            displayName: '<<jtsz>>',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/activityRewardItems',
                                paramsFilter: ['TOPIC_ACTIVITY'],
                            },
                            rules: [{ required: true }],

                            controlConfig: {
                                isRange: true,
                                maxCount: 3,
                                rewardType: 'multi',
                                stepValueTextConfig: {
                                    start: '<<topdi>>',
                                    end: '<<hdyxjl>>',
                                    unit: '<<ming>>',
                                },
                                rewardTextConfig: {
                                    start: ' ',
                                },
                            },
                        },
                        showTime: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<pxbzssj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<jyhdsjzwpxbzssj>>',
                        },
                        lotteryTime: {
                            type: 'string.dateTime',
                            displayName: '<<kjsj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<lotteryTimeExtra>>',
                        },
                        lotteryType: {
                            type: 'string.options.radio',
                            displayName: '<<jlff>>',
                            defaultValue: 'AUTO',

                            options: [
                                { id: 'AUTO', name: '<<zdff>>' },
                                { id: 'MANUAL', name: '<<rgff>>' },
                            ],
                            extra: '<<zdffwkjsff>>',
                        },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        TopicDataEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/topicPromotionEncourageData',
            filters: {
                author: {
                    type: 'string',
                    displayName: '<<authorMobile>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<bjlx>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'PICTURE', name: '<<pictures>>' },
                        { id: 'VIDEO', name: '<<video>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 170,
                        },
                    },
                },
                lotteryStatusEnum: {
                    type: 'string.options.select',
                    displayName: '<<kjzt>>',
                    defaultValue: '',
                    options: [
                        { id: LotteryStatus.REWARDED, name: '<<ykj>>' },
                        { id: LotteryStatus.NOT_REWARDED, name: '<<wkj>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 170,
                        },
                    },
                },
                rewardStatusEnum: {
                    type: 'string.options.select',
                    displayName: '<<ffzt>>',
                    defaultValue: '',
                    options: rewardStatusSearchList,
                    controlConfig: {
                        style: {
                            width: 170,
                        },
                    },
                },
            },
            properties: {
                noteId: {
                    type: 'number',
                    displayName: '<<noteId>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<authorMobile>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<bjlx>>',
                    options: [
                        { id: 'PICTURE', name: '<<tpbj>>' },
                        { id: 'VIDEO', name: '<<spbj>>' },
                    ],
                },
                publishTime: {
                    type: 'string',
                    displayName: '<<releaseTime>>',
                },
                viewsNum: {
                    type: 'number',
                    displayName: '<<browse>>',
                },
                lickedNum: {
                    type: 'number',
                    displayName: '<<dz_2>>',
                },
                commentNum: {
                    type: 'number',
                    displayName: '<<pl>>',
                },
                forwardNum: {
                    type: 'number',
                    displayName: '<<zf_2>>',
                },
                noteRank: {
                    type: 'number',
                    displayName: '<<pm_1>>',
                },
                lotteryStatusEnum: {
                    type: 'string.options.select',
                    displayName: '<<kjzt>>',
                    options: [
                        { id: LotteryStatus.REWARDED, name: '<<ykj>>' },
                        { id: LotteryStatus.NOT_REWARDED, name: '<<wkj>>' },
                    ],
                },
                rewardStatusEnum: {
                    type: 'string.options.select',
                    displayName: '<<ffzt>>',
                    options: rewardStatusList,
                },
            },
        },
    },
    components: {
        TopicPromotionEncourageView: {
            component: 'Viewport',
        },
        TopicPromotionEncouragePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'TopicPromotionEncourageTables' }],
        },
        TopicPromotionEncourageTables: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'TopicEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        {
                            component: 'TopicPromotionPageFilter',
                            params: { eventType: 'TOPIC_ACTIVITY' },
                        },
                        {
                            component: 'GridLayout',
                            className: 'mt24 mb24',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzhtjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/topic-promotion-encourage/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'TopicPromotionEncourageTable',
                    params: { eventType: 'TOPIC_ACTIVITY' },
                },
            ],
        },
        TopicPromotionPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        TopicPromotionEncourageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 200,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/topic_activities/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/topic_activities/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path: '/topic-promotion-encourage/edit/{{row.id}}',
                            },
                            {
                                text: '<<viewData>>',
                                type: 'link',
                                path: '/topic-promotion-encourage/data/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        topicDataContentFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'author',
                },
                {
                    property: 'contentType',
                },
                {
                    property: 'lotteryStatusEnum',
                },
                {
                    property: 'rewardStatusEnum',
                },
            ],
        },
        topicDataContentTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            align: 'left',
            scroll: { x: true },
            columns: [
                {
                    property: 'noteId',
                },
                {
                    property: 'memberMobile',
                },
                {
                    property: 'contentType',
                },
                {
                    property: 'publishTime',
                },
                {
                    property: 'viewsNum',
                },
                {
                    property: 'lickedNum',
                },
                {
                    property: 'commentNum',
                },
                {
                    property: 'forwardNum',
                },
                {
                    property: 'noteRank',
                },
                {
                    property: 'lotteryStatusEnum',
                },
                {
                    property: 'rewardStatusEnum',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 160,
                items: [
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/topic-promotion-encourage/note/{{row.noteId}}',
                    },
                    {
                        type: 'component',
                        component: 'TopicEncourageActionButton',
                    },
                ],
            },
        },
        TopicDataContent: {
            component: 'FlexLayout',
            entity: 'TopicDataEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'topicDataContentFilter',
                    style: {
                        marginBottom: 16,
                    },
                },
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        style: {
                            width: '120px',
                            marginBottom: '20px',
                        },
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/topic_activities/:id/export',
                        requestMethod: 'get',
                    },
                },
                {
                    component: 'topicDataContentTable',
                },
            ],
        },
        TopicAddPage: {
            component: 'FlexLayout',
            entity: 'TopicEntity',
            direction: 'horizontal',
            items: [{ component: 'TopicPromotionEncourageAddFormView' }],
        },
        TopicEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'TopicEntity',
            direction: 'horizontal',
            items: [{ component: 'TopicPromotionEncourageEditFormView' }],
        },
        TopicDataPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<hdsj>>',
                            content: {
                                component: 'FlexLayout',
                                direction: 'vertical',
                                items: [
                                    {
                                        component: 'TopicDataContent',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/topic-promotion-encourage',
                    component: 'TopicPromotionEncourageView',
                    breadcrumbName: '<<topicPromotionEncourage>>',
                    privilege: {
                        path: 'topicPromotionEncourage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'TopicAddPage',
                            breadcrumbName: '<<xzhtjl>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'TopicEditPage',
                            breadcrumbName: '<<bjhtjl>>',
                        },
                        {
                            path: '/data/:id',
                            component: 'TopicDataPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/note/:id',
                            component: 'ContentNoteDetailPage',
                            breadcrumbName: '<<bjnr>>',
                        },
                        { path: '/', component: 'TopicPromotionEncouragePage' },
                    ],
                },
            ],
        },
    ],
};
