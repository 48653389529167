import { camelCase } from 'lodash';
import { builder } from '@comall-backend-builder/core';
import { RoutesMetadata } from '@comall-backend-builder/core/lib/parser/routes';
import { ComponentMetadata } from '@comall-backend-builder/core/lib/parser/component';

/**
 * 需要注册的页面
 */
const routeConfigs: Array<RouteConfig> = [
    {
        path: '/dashboard',
        origin: '/1/dashboard',
        breadcrumbName: 'Dashboard',
        privilege: {
            path: 'dashboard',
            level: 'full',
        },
    },
    // {
    //     path: '/subsite',
    //     origin: '/10001/subsite/show',
    //     breadcrumbName: '<<manageSubsiteInformation>>',
    //     privilege: {
    //         path: 'storeManager',
    //         level: 'full',
    //     },
    // },
    {
        path: '/virtual-category',
        origin: '/5001/virtualCategory/show',
        breadcrumbName: '<<virtualCategory>>',
        privilege: {
            path: 'virtualCategory',
            level: 'full',
        },
    },
    {
        path: '/virtual-category-self',
        origin: '/5001/virtualCategory/self/chooseSite',
        breadcrumbName: '<<selfMerchantVirtualCategory>>',
        privilege: {
            path: 'selfMerchantVirtualCategory',
            level: 'full',
        },
    },
    {
        path: '/guide',
        origin: '/5004/guide/show',
        breadcrumbName: '<<guideManager>>',
        privilege: {
            path: 'guide',
            level: 'full',
        },
    },
    {
        path: '/app-section',
        origin: '/5010/appSection/show',
        breadcrumbName: '<<appSection>>',
        privilege: {
            path: 'appSection',
            level: 'full',
        },
    },

    {
        path: '/region',
        origin: '/10006/region/show',
        breadcrumbName: '<<region>>',
        privilege: {
            path: 'region',
            level: 'full',
        },
    },
    {
        path: '/deliveryMode',
        origin: '/9005/deliverymode/show',
        breadcrumbName: '<<deliveryMode>>',
        privilege: {
            path: 'deliveryMode',
            level: 'full',
        },
    },
    {
        path: '/user',
        origin: '/10008/securityUser/show',
        breadcrumbName: '<<systemUserManagemen>>',
    },
    // {
    //     path: '/merchant-self',
    //     origin: '/11001/merchant/show/0',
    //     breadcrumbName: '<<merchantManager>>',
    //     privilege: {
    //         path: 'storeInfo',
    //         level: 'full',
    //     },
    // },
    {
        path: '/brand',
        origin: '/3001/brand/show',
        breadcrumbName: '<<brand>>',
        privilege: {
            path: 'brand',
            level: 'full',
        },
    },
    {
        path: '/specification',
        origin: '/3002/specification/show',
        breadcrumbName: '<<specification>>',
        privilege: {
            path: 'specification',
            level: 'full',
        },
    },
    {
        path: '/category',
        origin: '/3003/category/show',
        breadcrumbName: '<<category>>',
        privilege: {
            path: 'category',
            level: 'full',
        },
    },
    {
        path: '/product-info',
        origin: '/3004/productinfo/show',
        breadcrumbName: '<<merchantProductInfoMananger>>',
        privilege: {
            path: 'productinfo',
            level: 'full',
        },
    },
    {
        path: '/goods',
        origin: '/3005/goods/chooseSite',
        breadcrumbName: '<<goods>>',
        privilege: {
            path: 'goods',
            level: 'full',
        },
    },
    {
        path: '/virtual-product',
        origin: '/3004/productinfo/show_virtual_product_page',
        breadcrumbName: '<<xnspzlzx>>',
        privilege: {
            path: 'virtualProduct',
            level: 'full',
        },
    },
    {
        path: '/virtualGoods',
        origin: '/3005/virtual_goods/chooseSite',
        breadcrumbName: '<<xnspjygl>>',
        privilege: {
            path: 'virtualGoods',
            level: 'full',
        },
    },
    {
        path: '/weight-goods',
        origin: '/3005/weight_goods/chooseSite',
        breadcrumbName: '<<weightGoods>>',
        privilege: {
            path: 'weightGoods',
            level: 'full',
        },
    },
    {
        path: '/virtualCouponProductVirtualCategory',
        origin: '/5001/virtualCategory/virtual_coupon_product/show',
        breadcrumbName: '<<virtualCouponProductVirtualCategory>>',
        privilege: {
            path: 'virtualCouponProductVirtualCategory',
            level: 'full',
        },
    },
    {
        path: '/productTemplate',
        origin: '/3007/productTemplate/show',
        breadcrumbName: '<<productinfo>>',
        privilege: {
            path: 'productTemplate',
            level: 'full',
        },
    },
    // {
    //     path: '/inventoryRule',
    //     origin: '/5009/inventoryRule/show',
    //     breadcrumbName: '设置预警库存',
    //     privilege: {
    //         path: 'inventoryRule',
    //         level: 'full',
    //     },
    // },
    {
        path: '/goodsShelf',
        origin: '/3006/goodsShelf/show',
        breadcrumbName: '<<goodsShelf>>',
        privilege: {
            path: 'goodsShelf',
            level: 'full',
        },
    },
    {
        path: '/order',
        origin: '/8005/order/show',
        breadcrumbName: '<<orderManager>>',
        privilege: {
            path: 'order',
            level: 'full',
        },
    },
    {
        path: '/orderTest',
        origin: '/8008/orderTest',
        breadcrumbName: '<<orderTest>>',
        privilege: {
            path: 'orderTest',
            level: 'full',
        },
    },
    {
        path: '/orderProcessBill',
        origin: '/8013/orderprocessbill/show',
        breadcrumbName: '<<orderProcessBill>>',
        privilege: {
            path: 'orderProcessBill',
            level: 'full',
        },
    },
    {
        path: '/afterSalesApply',
        origin: '/8006/afterSalesApply/show',
        breadcrumbName: '<<afterSale>>',
        privilege: {
            path: 'afterSalesApply',
            level: 'full',
        },
    },
    {
        path: '/refund',
        origin: '/603/refund/refundList',
        breadcrumbName: '<<refund>>',
        privilege: {
            path: 'refund',
            level: 'full',
        },
    },
    {
        path: '/exceptionRefund',
        origin: '/11027/exceptionrefund/show',
        breadcrumbName: '<<exceptionRefund>>',
        privilege: {
            path: 'exceptionRefund',
            level: 'full',
        },
    },
    // {
    //     path: '/orderLogistics',
    //     origin: '/8012/orderLogistics/show',
    //     breadcrumbName: '批量发货',
    //     privilege: {
    //         path: 'orderLogistics',
    //         level: 'full',
    //     },
    // },
    // {
    //     path: '/member',
    //     origin: '/4001/member/show',
    //     breadcrumbName: '会员基本信息',
    //     privilege: {
    //         path: 'memberInfo',
    //         level: 'full',
    //     },
    // },
    // {
    //     path: '/memberLevel',
    //     origin: '/4002/memberLevel/show',
    //     breadcrumbName: '会员等级管理',
    //     privilege: {
    //         path: 'memberLevel',
    //         level: 'full',
    //     },
    // },
    {
        path: '/memberLevelRule',
        origin: '/4003/memberLevelRule/show',
        breadcrumbName: '<<memberLevelRule>>',
        privilege: {
            path: 'memberLevelRule',
            level: 'full',
        },
    },
    // {
    //     path: '/reviewAudit',
    //     origin: '/4007/reviewAudit/show',
    //     breadcrumbName: '会员评论',
    //     privilege: {
    //         path: 'reviewAudit',
    //         level: 'full',
    //     },
    // },
    {
        path: '/feedback',
        origin: '/4004/feedback/show',
        breadcrumbName: '<<feedback>>',
        privilege: {
            path: 'feedback',
            level: 'full',
        },
    },
    {
        path: '/orderExamine',
        origin: '/8002/orderExamine/show',
        breadcrumbName: '<<orderExamine>>',
        privilege: {
            path: 'orderExamine',
            level: 'full',
        },
    },
    // {
    //     path: '/filterWord',
    //     origin: '/4008/filterWord/show',
    //     breadcrumbName: '管理敏感字',
    //     privilege: {
    //         path: 'filterWord',
    //         level: 'full',
    //     },
    // },
    // {
    //     path: '/blacklist',
    //     origin: '/8003/blacklist/show',
    //     breadcrumbName: '管理黑名单',
    //     privilege: {
    //         path: 'blacklist',
    //         level: 'full',
    //     },
    // },
    {
        path: '/promotionPrice',
        origin: '/6001/promotionPrice/show',
        breadcrumbName: '<<promotionPrice>>',
        privilege: {
            path: 'promotionPrice',
            level: 'full',
        },
    },
    {
        path: '/timelimit',
        origin: '/6002/timeLimit/show',
        breadcrumbName: '<<msgl>>',
        privilege: {
            path: 'timelimit',
            level: 'full',
        },
    },
    {
        path: '/promotion',
        origin: '/6015/promotion/show',
        breadcrumbName: '<<reduction>>',
        privilege: {
            path: 'reduction',
            level: 'full',
        },
    },
    {
        path: '/checkoutPromotion',
        origin: '/6007/checkoutPromotion/show',
        breadcrumbName: '<<jsGroup>>',
        privilege: {
            path: 'checkoutPromotion',
            level: 'full',
        },
    },
    {
        path: '/promotionGroup',
        origin: '/6017/promotiongroup/show',
        breadcrumbName: '<<promotionGroup>>',
        privilege: {
            path: 'promotionGroup',
            level: 'full',
        },
    },
    {
        path: '/promotionPriority',
        origin: '/6018/promotionPriority/show',
        breadcrumbName: '<<promotionPriority>>',
        privilege: {
            path: 'promotionPriority',
            level: 'full',
        },
    },
    {
        path: '/presentLabel',
        origin: '/6003/presentLabel/labelManager',
        breadcrumbName: '<<zpbqgl>>',
        privilege: {
            path: 'presentLabel',
            level: 'full',
        },
    },
    {
        path: '/present',
        origin: '/6004/present/show',
        breadcrumbName: '<<present>>',
        privilege: {
            path: 'present',
            level: 'full',
        },
    },
    {
        path: '/menu-list/coupon/couponPro',
        origin: '/6016/couponPro/show',
        breadcrumbName: '<<onlineCoupon>>',
        privilege: {
            path: 'couponPro',
            level: 'full',
        },
    },
    {
        path: '/menu-list/coupon/couponBatch',
        origin: '/6013/couponBatch/show',
        breadcrumbName: '<<yhqpc>>',
        privilege: {
            path: 'couponBatch',
            level: 'full',
        },
    },
    {
        path: '/menu-list/coupon/coupon',
        origin: '/6014/coupon/show',
        breadcrumbName: '<<yhqsj>>',
        privilege: {
            path: 'couponData',
            level: 'full',
        },
    },
    {
        path: '/tag',
        origin: '/6008/tag/show',
        breadcrumbName: '<<promotionTag>>',
        privilege: {
            path: 'goodsTag',
            level: 'full',
        },
    },
    {
        path: '/tagShow',
        origin: '/6009/tagShow/show',
        breadcrumbName: '<<tagShow>>',
        privilege: {
            path: 'tagShow',
            level: 'full',
        },
    },
    {
        path: '/activity',
        origin: '/7001/activity/show',
        breadcrumbName: '<<cjhdsz>>',
        privilege: {
            path: 'activity',
            level: 'full',
        },
    },
    {
        path: '/prize',
        origin: '/7002/prize/show',
        breadcrumbName: '<<cjjpsz>>',
        privilege: {
            path: 'prize',
            level: 'full',
        },
    },
    {
        path: '/memberIntegral',
        origin: '/7003/memberIntegral/show',
        breadcrumbName: '<<cjzjjl>>',
        privilege: {
            path: 'memberIntegral',
            level: 'full',
        },
    },
    {
        path: '/turntableStatistics',
        origin: '/7004/turntableStatistics/showDraw',
        breadcrumbName: '<<cjdtsj>>',
        privilege: {
            path: 'turntableStatistics',
            level: 'full',
        },
    },
    {
        path: '/turntableStatisticsAll',
        origin: '/7005/turntableStatistics/showAllDraw',
        breadcrumbName: '<<cjhzsj>>',
        privilege: {
            path: 'turntableStatisticsAll',
            level: 'full',
        },
    },

    {
        path: '/seo',
        origin: '/7006/seo/show',
        breadcrumbName: '<<seogl>>',
        privilege: {
            path: 'seo',
            level: 'full',
        },
    },
    {
        path: '/articleCategory',
        origin: '/9002/articlecategory/show',
        breadcrumbName: '<<articleCategory>>',
        privilege: {
            path: 'articleCategory',
            level: 'full',
        },
    },
    {
        path: '/article',
        origin: '/9003/article/show',
        breadcrumbName: '<<glwz>>',
        privilege: {
            path: 'articleManager',
            level: 'full',
        },
    },
    {
        path: '/messageType',
        origin: '/12001/messageType/show',
        breadcrumbName: '<<messageType>>',
        privilege: {
            path: 'messageType',
            level: 'full',
        },
    },
    {
        path: '/messageChannel',
        origin: '/12002/messageChannel/show',
        breadcrumbName: '<<messageChannel>>',
        privilege: {
            path: 'messageChannel',
            level: 'full',
        },
    },
    {
        path: '/messageTemplate',
        origin: '/12003/messageTemplate/show',
        breadcrumbName: '<<messageTemplate>>',
        privilege: {
            path: 'messageTemplate',
            level: 'full',
        },
    },
    {
        path: '/messageHistory',
        origin: '/12005/messageHistory/show',
        breadcrumbName: '<<messageHistory>>',
        privilege: {
            path: 'messageHistory',
            level: 'full',
        },
    },
    {
        path: '/messageBatchDefinitionChannel',
        origin: '/12006/messageBatchDefinitionChannel/show',
        breadcrumbName: '<<batchMessage>>',
        privilege: {
            path: 'messageBatchDefinitionChannel',
            level: 'full',
        },
    },
    {
        path: '/messageBatchTemplate',
        origin: '/12007/messageBatchTemplate/show',
        breadcrumbName: '<<messageBatchTemplate>>',
        privilege: {
            path: 'messageBatchTemplate',
            level: 'full',
        },
    },
    // {
    //     path: '/escapeWord',
    //     origin: '/100501/escapeWord/show',
    //     breadcrumbName: '管理转译词',
    //     privilege: {
    //         path: 'escapeWord',
    //         level: 'full',
    //     },
    // },
    {
        path: '/salesReport',
        origin: '/8011/salesReport/show',
        breadcrumbName: '<<reportManager>>',
        privilege: {
            path: 'reportManager',
            level: 'full',
        },
    },
    {
        path: '/afterSalesReport',
        origin: '/8201/afterSalesReport/show',
        breadcrumbName: '<<ServiceApplyReport>>',
        privilege: {
            path: 'ServiceApplyReport',
            level: 'full',
        },
    },
    {
        path: '/paymentModeContent',
        origin: '/5007/paymentModeContent/show',
        breadcrumbName: '<<paymentModeManagement>>',
        privilege: {
            path: 'paymentModeContent',
            level: 'full',
        },
    },
    {
        path: '/paymentMode',
        origin: '/10003/paymentMode/show',
        breadcrumbName: '<<paymentModeManagement>>',
        privilege: {
            path: 'paymentMode',
            level: 'full',
        },
    },
    {
        path: '/version',
        origin: '/100401/version/show',
        breadcrumbName: '<<hotVersion>>',
        privilege: {
            path: 'version',
            level: 'full',
        },
    },
    {
        path: '/versionUpdate',
        origin: '/100301/versionUpdate/show',
        breadcrumbName: '<<versionUpdate>>',
        privilege: {
            path: 'versionUpdate',
            level: 'full',
        },
    },
    // {
    //     path: '/backinfo',
    //     origin: '/10097/backinfo/detail',
    //     breadcrumbName: '管理后台个性化',
    //     privilege: {
    //         path: 'backinfo',
    //         level: 'full',
    //     },
    // },
    // {
    //     path: '/frontinfo',
    //     origin: '/10096/frontinfo/detail',
    //     breadcrumbName: '商城名称及logo',
    //     privilege: {
    //         path: 'frontinfo',
    //         level: 'full',
    //     },
    // },
    // {
    //     path: '/reason',
    //     origin: '/4005/reason/show',
    //     breadcrumbName: '管理订单参数',
    //     privilege: {
    //         path: 'parameter',
    //         level: 'full',
    //     },
    // },
    {
        path: '/albumConfig',
        origin: '/10007/albumConfig/show',
        breadcrumbName: '<<albumConfig>>',
        privilege: {
            path: 'albumConfig',
            level: 'full',
        },
    },
    {
        path: '/album',
        origin: '/9008/album/show',
        breadcrumbName: '<<album>>',
        privilege: {
            path: 'album',
            level: 'full',
        },
    },
    {
        path: '/cacheStats',
        origin: '/10102/cacheStats/show',
        breadcrumbName: '<<cacheStats>>',
        privilege: {
            path: 'cacheStats',
            level: 'full',
        },
    },
    {
        path: '/primaryKey',
        origin: '/10105/primaryKey/show',
        breadcrumbName: '<<primaryKey>>',
        privilege: {
            path: 'primaryKey',
            level: 'full',
        },
    },
    {
        path: '/requestLimit',
        origin: '/100102/antiviolence/show',
        breadcrumbName: '<<requestLimit>>',
        privilege: {
            path: 'requestLimit',
            level: 'full',
        },
    },
    {
        path: '/index',
        origin: '/10098/index/createAll',
        breadcrumbName: '<<createIndex>>',
        privilege: {
            path: 'index',
            level: 'full',
        },
    },
    {
        path: '/orderPaymentManager',
        origin: '/16012050/order_payment',
        breadcrumbName: '<<orderPaymentManager>>',
        privilege: {
            path: 'orderPaymentManager',
            level: 'full',
        },
    },
    {
        path: '/department',
        origin: '/9009/department/show',
        breadcrumbName: '<<department>>',
        privilege: {
            path: 'department',
            level: 'full',
        },
    },
    {
        path: '/merchant-join',
        origin: '/11001/merchant/show/1',
        breadcrumbName: '<<zjgl>>',
        privilege: {
            path: 'merchant',
            level: 'full',
        },
    },
    {
        path: '/cashDepositDetail',
        origin: '/11002/cashDepositDetail/show',
        breadcrumbName: '<<cashDepositDetail>>',
        privilege: {
            path: 'cashDepositDetail',
            level: 'full',
        },
    },
    {
        path: '/merchantProductInfo',
        origin: '/11015/merchantProductInfo/show',
        breadcrumbName: '<<merchantProductInfo>>',
        privilege: {
            path: 'merchantProductInfo',
            level: 'full',
        },
    },
    {
        path: '/merchantGoods',
        origin: '/11016/merchantGoods/chooseSite',
        breadcrumbName: '<<merchantGoods>>',
        privilege: {
            path: 'merchantGoods',
            level: 'full',
        },
    },
    {
        path: '/merchantDelivery',
        origin: '/8004/delivery/show',
        breadcrumbName: '<<deliveryyfgl>>',
        privilege: {
            path: 'merchantDelivery',
            level: 'full',
        },
    },
    {
        path: '/merchantOrder',
        origin: '/11021/order/merchantshow',
        breadcrumbName: '<<zjddgl>>',
        privilege: {
            path: 'merchantOrder',
            level: 'full',
        },
    },
    {
        path: '/merchantOrderprocessbill',
        origin: '/11005/merchantOrderprocessbill/show',
        breadcrumbName: '<<zjqxgbcld>>',
        privilege: {
            path: 'merchantOrderProcessBill',
            level: 'full',
        },
    },
    {
        path: '/merchantAfterSalesApply',
        origin: '/11007/merchantAfterSalesApply/show',
        breadcrumbName: '<<zjshsqd>>',
        privilege: {
            path: 'merchantAfterSalesApply',
            level: 'full',
        },
    },
    {
        path: '/merchantRefund',
        origin: '/11008/merchantRefund/refundList',
        breadcrumbName: '<<zjshtk>>',
        privilege: {
            path: 'merchantRefund',
            level: 'full',
        },
    },
    {
        path: '/arbitrationbill',
        origin: '/11006/arbitrationbill/show',
        breadcrumbName: '<<arbitrationBillManager>>',
        privilege: {
            path: 'arbitrationBillManager',
            level: 'full',
        },
    },
    {
        path: '/merchantUser',
        origin: '/11010/merchantSecurityUser/show',
        breadcrumbName: '<<merchantUserManager>>',
        privilege: {
            path: 'merchantUser',
            level: 'full',
        },
    },
    {
        path: '/merchantRole',
        origin: '/11009/merchantSecurity/show',
        breadcrumbName: '<<zjjsqxgl>>',
        privilege: {
            path: 'merchantRole',
            level: 'full',
        },
    },
    {
        path: '/merchantReviewAudit',
        origin: '/11022/merchantReviewAudit/show',
        breadcrumbName: '<<zjhypl>>',
        privilege: {
            path: 'merchantReviewAudit',
            level: 'full',
        },
    },
    {
        path: '/orderStatement',
        origin: '/11003/orderStatement/show',
        breadcrumbName: '<<orderStatement>>',
        privilege: {
            path: 'orderStatement',
            level: 'full',
        },
    },
    {
        path: '/systemconfig',
        origin: '/11017/systemconfig/showMerchantSystemConfig',
        breadcrumbName: '<<merchantSystemConfig>>',
        privilege: {
            path: 'merchantSystemConfig',
            level: 'full',
        },
    },
    {
        path: '/shareRebate',
        origin: '/5999/shareRebate/show',
        breadcrumbName: '<<fxfl>>',
        privilege: {
            path: 'shareRebate',
            level: 'full',
        },
    },
    {
        path: '/merchantProductInfoMananger',
        origin: '/merchant/2004/productinfo/show',
        breadcrumbName: '<<productinfo>>',
        privilege: {
            path: 'merchantProductInfoMananger',
            level: 'full',
        },
    },
    {
        path: '/merchantGoodsManager',
        origin: '/merchant/2005/goods/chooseSite',
        breadcrumbName: '<<merchantGoodsManager>>',
        privilege: {
            path: 'merchantGoodsManager',
            level: 'full',
        },
    },
    {
        path: '/merchantPromotionPrice',
        origin: '/merchant/4001/promotionPrice/show',
        breadcrumbName: '<<merchantPromotionPrice>>',
        privilege: {
            path: 'merchantPromotionPrice',
            level: 'full',
        },
    },
    {
        path: '/operatorLog',
        origin: '/10104/handleLog/show',
        breadcrumbName: '<<operatorLog>>',
        privilege: {
            path: 'operatorLog',
            level: 'full',
        },
    },
];

/**
 * 创建原httl页面到当前路由的映射
 */
interface RouteConfig extends RoutesMetadata {
    /**
     * 原页面访问地址
     */
    origin: string;
}

/**
 * 动态注册路由和组件
 */
let routes: Array<RoutesMetadata> = [];
let components: Record<string, ComponentMetadata> = {};
routeConfigs.forEach((item) => {
    const componentName = 'IframePage-' + camelCase(item.path);
    components[componentName] = {
        component: 'IframeViewport',
        origin: item.origin,
    };
    routes.push({
        component: componentName,
        ...item,
    });
});

builder.registerConfig({
    components: components,
    routes: [
        {
            path: '/',
            component: 'SideNavFrameWrapper',
            childRoutes: routes,
        },
    ],
});
