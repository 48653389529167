import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { BalanceCoupon } from './balance-coupon-selector';
import { services } from '@comall-backend-builder/core';

interface BalanceCouponsDisplayProps {
    data: Array<BalanceCoupon>;
    onRemove: (data: Array<BalanceCoupon>) => void;
    disabled: boolean;
}
interface BalanceCouponsDisplayState {
    displayAll: boolean;
}

/**
 * 品牌展示和内容编辑
 */
class BalanceCouponsDisplay extends Component<
    BalanceCouponsDisplayProps,
    BalanceCouponsDisplayState
> {
    state: BalanceCouponsDisplayState = {
        displayAll: false,
    };
    selectedRows: BalanceCoupon[] = [];

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            scroll: {
                y: 390,
            },
            rowKey: 'code',
            columns: [
                {
                    title: services.language.getText('qbm'),
                    dataIndex: 'code',
                    key: 'code',
                    ellipsis: true,
                },
                {
                    title: services.language.getText('qlx'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: services.language.getText('common.tableAction'),
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled } = this.props;
        return (
            <div className="product-selected-footer">
                {!disabled && (
                    <div className="setting-item">
                        <Button
                            className="setting-item-ok"
                            type="primary"
                            onClick={this.onRemoveAll}
                        >
                            {services.language.getText('allClear')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    onRemoveAll = () => {
        const { onRemove } = this.props;
        onRemove([]);
    };

    onRemove = (row: BalanceCoupon) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (balance) => row.code === balance.code);
            onRemove(result);
        };
    };

    render() {
        const { data } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data || [],
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { BalanceCouponsDisplay };
