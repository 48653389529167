import { builder } from '@comall-backend-builder/core';

import { PageTemplateLoader } from './page-template-loader';
import { HomePageManageLoader } from './home-page-manage-loader';
import { TopicPageManageLoader } from './topic-page-manage-loader';
import { ProductsLoader } from './products-loader';
import { TopicsLoader } from './topics-loader';
import { NewTopicsLoader } from './new-topics-loader';
import { CouponsLoader } from './coupons-loader';
import { PresalesLoader } from './presales-loader';
import { MerchantPageLoader } from './merchant-page-loader';
import { NewMerchantPageLoader } from './new-merchant-page-loader';
import { MallActivitySelectorLoader } from './mall-activity-selector-loader';
import { CouponPackagesLoader } from './coupons-packages-loader';
import { LotteryCodeLoader } from './lottery-code-loader';
import { ScratchCardLoader } from './scratch-card-loader';
import { PlatformPageManageLoader } from './platform-page-manager-loader';
import { ProductStyleLoader } from './product-style-loader';
import { CategroyLoader } from './category-loader';
import { GuideLoader } from './guide-loader';
import { DesignPageLoader } from './design-page-loader';
import { VirtualCategroyLoader } from './virtual-category-loader';
import { CouponRulesLoader } from './coupon-rules-loader';
import { NewPageTemplateLoader } from './new-page-template-loader';
import { NewPageTemplateOptionsLoader } from './new-page-template-options-loader';
import { GroupProductLoader } from './group-product-loader';
import { MaotaiProductLoader } from './maotai-product-loader';
import { NormalProductsLoader } from './normal-products-loader';
import { MerchantBigCodesLoader } from './merchant-big-codes-loader';
import { FloatingActionButtonLoader } from './floating-action-button';
import { GoodsLoader } from './goods-loader';
import { CreditProductsLoader } from './credit-products-loader';

builder.registerLoader('/admin/templates', PageTemplateLoader);
builder.registerLoader('/admin/home_pages', HomePageManageLoader);
builder.registerLoader('/admin/platform_home_pages', PlatformPageManageLoader);
builder.registerLoader('/admin/topic_pages', TopicPageManageLoader);
builder.registerLoader('/admin/products', ProductsLoader);
builder.registerLoader('/admin/topics/options', TopicsLoader);
builder.registerLoader('/admin/pages/options', NewTopicsLoader);
builder.registerLoader('/admin/coupon_promotions', CouponsLoader);
builder.registerLoader('/admin/presales/options', PresalesLoader);
builder.registerLoader('/merchantPage', MerchantPageLoader);
builder.registerLoader('/new-merchantPage', NewMerchantPageLoader);
builder.registerLoader('/mall-activities-selector', MallActivitySelectorLoader);
builder.registerLoader('/admin/coupon_packages/select', CouponPackagesLoader);
builder.registerLoader('/admin/lottery_code/options', LotteryCodeLoader);
builder.registerLoader('/admin/scratch_card/options', ScratchCardLoader);
builder.registerLoader('/admin/product_style', ProductStyleLoader);
builder.registerLoader('/admin/category', CategroyLoader);
builder.registerLoader('/admin/guide', GuideLoader);
builder.registerLoader('/admin/design_page', DesignPageLoader);
builder.registerLoader('/admin/virtual_category', VirtualCategroyLoader);
builder.registerLoader('/admin/coupon_rules', CouponRulesLoader);
builder.registerLoader('/admin/decorate/templates', NewPageTemplateLoader);
builder.registerLoader('/admin/decorate/templates/options', NewPageTemplateOptionsLoader);
builder.registerLoader('/admin/group_buying_rules/goods', GroupProductLoader);
builder.registerLoader('/admin/maotai-pre-sale/goods', MaotaiProductLoader);
builder.registerLoader('/admin/normal-products', NormalProductsLoader);
builder.registerLoader('/admin/merchant_big_codes', MerchantBigCodesLoader);
builder.registerLoader('/admin/floating_action_button', FloatingActionButtonLoader);
builder.registerLoader('/admin/goods', GoodsLoader);
builder.registerLoader('/admin/credit_products', CreditProductsLoader);
