import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { refundStatusOptions, orderTypeOptions } from './types';
import { services, TypesManager } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { ForwardDataCenterModal } from '../../../../services';
const api = services.api;

export const config: Config = {
    entities: {
        CouponRefundEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupon_reports/refund_report',
            filters: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<shd>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<tkdcjsj>>',
                },
                refundStatus: {
                    type: 'string.options.select',
                    displayName: '<<tkdzt>>',
                    defaultValue: 'ALL',
                    options: refundStatusOptions,
                },
                couponName: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    defaultValue: 'ALL',
                    options: orderTypeOptions,
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
            },
            properties: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                refundTradeNo: {
                    type: 'string',
                    displayName: '<<tkjylsh>>',
                },
                refundMode: {
                    type: 'string',
                    displayName: '<<tkfs>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<tkdcjsj>>',
                },
                refundStatus: {
                    type: 'string',
                    displayName: '<<tkdzt>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                refundPointValue: {
                    type: 'string',
                    displayName: '<<thjf>>',
                },
                refundMoney: {
                    type: 'string',
                    displayName: '<<tkje>>',
                },
                orderType: {
                    type: 'string',
                    displayName: '<<ddlx>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<gmcqdddh>>',
                },
            },
        },
    },
    components: {
        CouponRefundView: {
            component: 'Viewport',
            entity: 'CouponRefundEntity',
        },
        CouponRefundPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponRefundFilter',
                },
                { component: 'CouponRefundTable' },
            ],
        },
        CouponRefundFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteCode',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<codeRequired>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'serviceApplyNo',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrshdh>>',
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<inputOrderNumber>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
                {
                    property: 'refundStatus',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                {
                    property: 'couponNo',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqqm>>',
                    },
                },
                {
                    property: 'orderType',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrhysjh>>',
                    },
                },
            ],
        },
        CouponRefundTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                TypesManager.formatParams(
                                    entityButtonProps.entity.metainfo.filters,
                                    entityButtonProps.entity.filters
                                ),
                                {
                                    exchangeStatus: entityButtonProps.entity.filters
                                        ? (entityButtonProps.entity.filters as any).redeemStatus
                                        : undefined,
                                    redeemStatus: undefined,
                                }
                            );
                            if (!isEmpty(params.dateRange)) {
                                params.refundCreateStartTime = params.dateRange.start + ' 00:00:00';
                                params.refundCreateEndTime = params.dateRange.end + ' 23:59:59';
                                delete params.dateRange;
                            }
                            if (params.refundStatus === 'ALL') {
                                delete params.refundStatus;
                            }
                            if (params.orderType === 'ALL') {
                                delete params.orderType;
                            }
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/coupon_reports/refund_report/download',
                            };

                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                {
                    component: 'DataTable',
                    scroll: {
                        x: true,
                    },
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteCode',
                            width: 120,
                        },
                        {
                            property: 'subsiteName',
                            width: 150,
                        },
                        {
                            property: 'serviceApplyNo',
                            width: 260,
                        },
                        {
                            property: 'refundTradeNo',
                            width: 260,
                        },
                        {
                            property: 'refundMode',
                            width: 150,
                        },
                        {
                            property: 'createTime',
                            width: 200,
                        },
                        {
                            property: 'refundStatus',
                            width: 150,
                        },
                        {
                            property: 'couponName',
                            width: 180,
                        },
                        {
                            property: 'couponNo',
                            width: 260,
                        },
                        {
                            property: 'refundPointValue',
                            width: 150,
                        },
                        {
                            property: 'refundMoney',
                            width: 150,
                        },
                        {
                            property: 'orderType',
                            width: 150,
                        },
                        {
                            property: 'mobile',
                            width: 150,
                        },
                        {
                            property: 'orderNo',
                            width: 150,
                        },
                    ],
                    actionColumn: {},
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-refund-report',
                    component: 'CouponRefundView',
                    breadcrumbName: '<<couponRefundReport>>',
                    privilege: {
                        path: 'couponRefundReport',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponRefundPage' }],
                },
            ],
        },
    ],
};
