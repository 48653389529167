import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const GuidesApplyRecordsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id, auditStatus } = data;
        if (auditStatus && auditStatus === 'ALL') {
            delete data.auditStatus;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/guides/wechatwork/apply_user_records';
        if (id) {
            config.apiPath += `/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        subsiteName: item.subsite.name,
                        merchantName: item.subsite.merchant.name,
                        canEdit: item.auditStatus === 'WAITING',
                        guideCode: item.guideCode || '',
                    };
                });
            }
            return res;
        });
    },
};
