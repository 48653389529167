import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import get from 'lodash/get';
const api = services.api;
export const MemberTagLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        if (!data.id) {
            config.apiPath = '/admin/tags';

            if (!data.name) delete data.name;
            const res: any = await api.get(data, config);
            res.result.forEach((i: any) => {
                i.mode = get(i, 'type.mode');
                i.typeName = get(i, 'type.name');
            });
            return res;
        } else {
            config.apiPath = `/admin/tags/${data.id}`;
            const res: any = await api.get(data, config);
            const reuslt = {
                name: res.name,
                typeId: get(res, 'type.id'),
                mode: get(res, 'type.mode'),
                icon:
                    get(res, 'tagIcon.iconId') && get(res, 'tagIcon.iconUrl')
                        ? [{ path: res.tagIcon.iconUrl, id: res.tagIcon.iconId }]
                        : [],
                cornerIcon:
                    get(res, 'tagIcon.cornerIconId') && get(res, 'tagIcon.cornerIconUrl')
                        ? [{ path: res.tagIcon.cornerIconUrl, id: res.tagIcon.cornerIconId }]
                        : [],
            };
            return reuslt;
        }
    },
    post: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = `/admin/tags`;
        const data: any = {
            name: params.name,
            typeId: params.typeId,
            tagIcon: {},
        };
        if (params.icon && params.icon.length) {
            data.tagIcon.iconId = params.icon[0]['id'];
            data.tagIcon.iconUrl = params.icon[0]['path'];
        }
        if (params.cornerIcon && params.cornerIcon.length) {
            data.tagIcon.cornerIconId = params.cornerIcon[0]['id'];
            data.tagIcon.cornerIconUrl = params.cornerIcon[0]['path'];
        }
        return await api.post(data, config);
    },
    put: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = `/admin/tags/${params.id}`;
        const data: any = {
            name: params.name,
            typeId: params.typeId,
            mode: params.mode,
            tagIcon: {},
        };
        if (params.icon && params.icon.length) {
            data.tagIcon.iconId = params.icon[0]['id'];
            data.tagIcon.iconUrl = params.icon[0]['path'];
        }
        if (params.cornerIcon && params.cornerIcon.length) {
            data.tagIcon.cornerIconId = params.cornerIcon[0]['id'];
            data.tagIcon.cornerIconUrl = params.cornerIcon[0]['path'];
        }
        return await api.put(data, config);
    },
    delete: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = `/admin/tags/${params.id}`;
        return await api.delete(params, config);
    },
};

export const MemberTagTypeLoader: Loader = {
    get: async function({ mode }, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = `/admin/tags/types/${mode}`;
        return await api.get({}, config);
    },
};
