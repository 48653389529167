import React, { Component } from 'react';
import { Input } from 'antd';

export class StringInputTip extends Component<any, any> {
    render() {
        const { addonafter, addonbefore, tip } = this.props;

        return (
            <div>
                <Input {...this.props} addonAfter={addonafter} addonBefore={addonbefore} />
                {tip && (
                    <div {...this.props} style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                        {tip}
                    </div>
                )}
            </div>
        );
    }
}
