import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, forEach, map, find, get, isEqual } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';
import { Subsite } from '../../containers/cgb-config-select-subsite';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const TableComponentId = 'SubsiteSelectorTable';
let TableComponentNo = 1;
let Entity: any = {};
let entity: any = {};
enum AllSubsiteCheckboxState {
    SELECTED = 'SELECTED',
    PART_SELECTED = 'PART_SELECTED',
    UNSELECTED = 'UNSELECTED',
}

/**
 * 选择分站
 */
class subsiteSelector extends PureComponent<{
    selectValues: Subsite[];
    onChange: (products: Subsite[]) => void;
    requestStatus: string;
    params?: any;
    type: 'radio' | undefined;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('SubsiteSelector');
        entity = new Entity({});
        this.tableComponentId = TableComponentId + TableComponentNo;
        TableComponentNo++;
    }
    tableComponentId: string = '';
    dispatch: any = {};
    state = {
        visible: false,
        allSubsites: [],
        allSubsiteCheckboxState: AllSubsiteCheckboxState.UNSELECTED,
    };

    selectedRows = new Array<Subsite>();

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            }
        ).then((response: any) => {
            this.setState({
                allSubsites: response.result,
            });
        });
        this.mapSelectValuesToSelectedRows(this.props.selectValues);
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        let tableRowSelectionChangeAction = false;
        //在选中列表中删除数据后更新弹窗选中状态
        if (!isEqual(nextProps.selectValues, this.props.selectValues)) {
            tableRowSelectionChangeAction = true;
            this.mapSelectValuesToSelectedRows(nextProps.selectValues);
            this.changeAllSubsiteCheckboxState();
        }
        if (
            (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') ||
            tableRowSelectionChangeAction
        ) {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            this.tableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
            }
        }
    }

    mapSelectValuesToSelectedRows = (selectValues?: Subsite[]) => {
        this.selectedRows = (selectValues || []).map((subsite: Subsite) => ({
            ...subsite,
            id: String(subsite.id),
        }));
    };

    onTableRowSelect = (record: Subsite, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { id: record.id })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
        this.changeAllSubsiteCheckboxState();
    };

    onTableRowSelectAll = (selected: boolean, rows: Subsite[], unSelectedRows: any) => {
        if (selected) {
            const idSet = new Set(this.selectedRows.map((row) => row.id));
            this.selectedRows = this.selectedRows.concat(rows.filter((row) => !idSet.has(row.id)));
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
        this.changeAllSubsiteCheckboxState();
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });

            onChange(this.selectedRows);
            this.toggleModal();
        }
    };
    changeAllSubsiteCheckboxState = () => {
        const { allSubsites } = this.state;
        if (
            this.selectedRows &&
            allSubsites &&
            this.selectedRows.length > 0 &&
            this.selectedRows.length === allSubsites.length
        ) {
            this.setState({
                allSubsiteCheckboxState: AllSubsiteCheckboxState.SELECTED,
            });
        } else if (this.selectedRows && this.selectedRows.length === 0) {
            this.setState({
                allSubsiteCheckboxState: AllSubsiteCheckboxState.UNSELECTED,
            });
        } else {
            this.setState({
                allSubsiteCheckboxState: AllSubsiteCheckboxState.PART_SELECTED,
            });
        }
    };
    selectAllSubsite = (selected: boolean, subsite: Subsite[]) => {
        const { type } = this.props;
        if (type === 'radio') {
            return;
        }
        const { allSubsiteCheckboxState, allSubsites } = this.state;
        switch (allSubsiteCheckboxState) {
            case AllSubsiteCheckboxState.PART_SELECTED:
            case AllSubsiteCheckboxState.UNSELECTED:
                this.selectedRows = allSubsites;
                this.setState({
                    allSubsiteCheckboxState: AllSubsiteCheckboxState.SELECTED,
                });
                break;
            default:
                this.selectedRows = [];
                this.setState({
                    allSubsiteCheckboxState: AllSubsiteCheckboxState.UNSELECTED,
                });
                break;
        }
        this.dispatch(
            actions.tableRowSelectionChangeAction(
                this.tableComponentId,
                map(this.selectedRows, (value) => {
                    return value.id + '';
                })
            )
        );
    };

    getConfig = () => {
        const { params, selectValues, type } = this.props;
        const { allSubsiteCheckboxState } = this.state;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'left' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    // onSubmit: (event: any) => {
                    //     event.stopPropagation();
                    //     entity.search(params);
                    // },
                    fields: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'status',
                            type: 'string.options.select',
                        },
                    ],
                },
                {
                    component: 'CheckboxGroup',
                    value:
                        allSubsiteCheckboxState === AllSubsiteCheckboxState.SELECTED ||
                        allSubsiteCheckboxState === AllSubsiteCheckboxState.PART_SELECTED
                            ? ['ALL']
                            : [],
                    options:
                        type === 'radio'
                            ? []
                            : [{ id: 'ALL', name: language.getText('allSubsites') }],
                    className:
                        allSubsiteCheckboxState === AllSubsiteCheckboxState.PART_SELECTED
                            ? 'ant-checkbox-indeterminate'
                            : '',
                    style: {
                        padding: 20,
                    },
                    onChange: this.selectAllSubsite,
                },
                {
                    component: 'DataTable',
                    componentId: this.tableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.id + '';
                        }),
                        onSelect: (record: Subsite, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (
                            selected: boolean,
                            rows: Subsite[],
                            unSelectedRows: Subsite[]
                        ) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'address',
                        },
                        {
                            property: 'status',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.filtersChange({
                    name: undefined,
                    status: undefined,
                });
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                // this.onClearAll();
            }
        });
    };

    getAllSubsite = () => {
        const { onChange } = this.props;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            }
        ).then((response: any) => {
            if (onChange) {
                forEach(response.result, (subsite) => {
                    subsite.select = true;
                });
                onChange(response.result);
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: language.getText('otherSelectSubsite'),
            visible: this.state.visible,
            okText: language.getText('common.ok'),
            cancelText: language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                {/* <Button type="primary" style={{ marginRight: 20 }} onClick={this.getAllSubsite}>
                    全部门店
                </Button> */}
                <Button type="link" onClick={this.toggleModal}>
                    {language.getText('otherSelectSubsite')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const SubsiteSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(subsiteSelector);
