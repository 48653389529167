import { Button } from '@comall-backend-builder/components-basis';
import { ComponentsManager, Entity } from '@comall-backend-builder/core/lib/parser';
import { api, language, navigation } from '@comall-backend-builder/core/lib/services';
import {
    Modal as AntModal,
    Input as AntInput,
    message as AntMessage,
    Tabs as AntTabs,
    Card as AntCard,
    Table as AntTable,
} from 'antd';
import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { trim } from 'lodash';
import './index.less';
import { services } from '@comall-backend-builder/core';

export interface contentNoteDetailProps {
    fields: any;
    entity: Entity;
    componentId: string;
    params: { id: string };
}

interface noteInfoVo {
    /**
     * 总访问量
     */
    clickCount: number;
    /**
     * 总搜藏数
     */
    collectCount: number;
    /**
     * 总评论数
     */
    commentCount: number;

    /**
     * 总关注数
     */
    followCount: number;

    /**
     * 总点赞数
     */
    likeCount: number;
    /**
     * 总分享数
     */
    shareCount: number;

    /**
     * 笔记标题
     */
    title: string;
    /**
     * 总得分
     */
    totalScore: number;
}
interface logDataVo {
    /**
     * 操作时间
     */
    createTime: string;
    /**
     * 操作类型
     */
    operationType: string;
    /**
     * 操作人
     */
    createUser: string;
    /**
     * 操作描述
     */
    description: string;
}
interface contentNoteState {
    visible: boolean;
    auditRemark: string;
    noteInfo: noteInfoVo;
    logData: logDataVo[];
}

const { TabPane: AntTabPane } = AntTabs;
class contentNoteDetail extends Component<contentNoteDetailProps, contentNoteState> {
    constructor(props: contentNoteDetailProps) {
        super(props);
        this.state = {
            visible: false,
            auditRemark: '',
            noteInfo: {
                /**
                 * 总访问量
                 */
                clickCount: 0,
                /**
                 * 总收藏数
                 */
                collectCount: 0,
                /**
                 * 总评论数
                 */
                commentCount: 0,

                /**
                 * 总关注数
                 */
                followCount: 0,

                /**
                 * 总点赞数
                 */
                likeCount: 0,
                /**
                 * 总分享数
                 */
                shareCount: 0,

                /**
                 * 笔记标题
                 */
                title: '-',
                /**
                 * 总得分
                 */
                totalScore: 0,
            },
            logData: [],
        };
    }

    componentDidMount() {
        const { id } = this.props.params;
        const apiPath = `/admin/score/${id}`;
        const apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        api.get({}, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                noteInfo: res,
            });
        });
        this.loadLogs();
    }
    loadLogs() {
        const { id } = this.props.params;
        const apiPath = `/admin/notes/${id}/operation_log`;
        const apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        api.get({}, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                logData: res,
            });
        });
    }

    renderDetail() {
        const { entity, componentId } = this.props;
        const detailProps = {
            entity,
            componentId,
            labelCol: 5,
            fields: [
                {
                    property: 'id',
                },
                {
                    property: 'authorMobile',
                },
                {
                    property: 'noteContent',
                },

                {
                    property: 'firstPublishTime',
                },
                {
                    property: 'editTime',
                    label: language.getText('zhbjsj'),
                },
                {
                    property: 'auditTypeEnum',
                },
                {
                    type: 'string.options.select',
                    property: 'auditStatus',
                    options: [
                        {
                            id: 'WAIT',
                            name: language.getText('wsh'),
                        },
                        {
                            id: 'SUCCESS',
                            name: language.getText('shcg'),
                        },
                        {
                            id: 'FAIL',
                            name: language.getText('shsb'),
                        },
                    ],
                },
                {
                    property: 'autoAuditFailDetail',
                    label: language.getText('jsjsyy'),
                    displayConfig: {
                        showInDetail: true,
                    },
                },
                {
                    property: 'manualAuditRemark',
                },
                {
                    property: 'detailStatus',
                },
                { property: 'deleteMark' },

                /* 
                {
                    property: 'manualAuditRemark',
                },
                {
                    property: 'editTime',
                },
                {
                    property: 'deleteTime',
                },
                {
                    property: 'autoAuditTime',
                },
                {
                    property: 'autoAuditFailDetail',
                    displayConfig: {
                        showInDetail: true,
                    },
                },
                {
                    property: 'manualAuditTime',
                },
                {
                    property: 'manualAuditRemark',
                },
                { property: 'deleteMark' }, */
            ],
        };
        return createElement(ComponentsManager.get('Detail'), detailProps);
    }

    // 打开 审核弹窗
    open = () => {
        this.setState({ visible: true });
    };

    // 关闭 审核弹窗
    close = () => {
        this.setState({ visible: false, auditRemark: '' });
    };

    // 填写审核备注
    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ auditRemark: value });
    };

    // 审核
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { auditRemark } = this.state;

        if (auditStatus === 'FAIL' && !trim(auditRemark)) {
            AntMessage.success(language.getText('qtxbz'));
            return;
        }

        const { fields } = this.props;
        api.put(
            {
                auditRemark,
                auditStatus,
                id: fields.id,
            },
            {
                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                apiPath: '/admin/notes/:id/audit_status',
            }
        ).then(() => {
            AntMessage.success(language.getText('shwc'));

            this.close();

            navigation.goBack();
        });
    }

    renderLogInfo = () => {
        const { logData } = this.state;
        const tableProps = logData
            ? {
                  loading: false,
                  columns: [
                      {
                          title: services.language.getText('czsj'),
                          key: 'createTime',
                          dataIndex: 'createTime',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: language.getText('czlx'),
                          key: 'operationType',
                          dataIndex: 'operationType',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('lastModifiedUser'),
                          key: 'createUser',
                          dataIndex: 'createUser',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: language.getText('czms'),
                          key: 'description',
                          dataIndex: 'description',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                  ],
              }
            : {};
        return logData ? <AntTable dataSource={logData} {...tableProps} /> : null;
    };

    render() {
        const { visible, auditRemark, noteInfo } = this.state;
        const {
            fields: { auditStatus, auditTypeEnum, detailStatus },
        } = this.props;
        //机审未通过，人审未审核
        const noteStatus =
            (auditStatus === 'FAIL' && auditTypeEnum === 'AUTO') ||
            (auditStatus === 'WAIT' && auditTypeEnum === 'MANUAL');
        return (
            <div className="content-note-detail">
                <AntTabs defaultActiveKey="detail">
                    <AntTabPane tab={language.getText('bjxq')} key="detail">
                        <div>
                            {this.renderDetail()}
                            <div style={{ textAlign: 'center' }}>
                                {noteStatus && detailStatus === 'NORMAL' && (
                                    <Button
                                        type="primary"
                                        onClick={this.open}
                                        style={{ marginRight: 24 }}
                                    >
                                        {services.language.getText('audity')}
                                    </Button>
                                )}
                                <Button route="goBack">{language.getText('common.cancel')}</Button>
                            </div>
                            <AntModal
                                visible={visible}
                                okText={language.getText('approved')}
                                cancelText={services.language.getText('shbtg')}
                                title={language.getText('shbz')}
                                onOk={this.submit.bind(this, 'SUCCESS')}
                                onCancel={this.close}
                                cancelButtonProps={{
                                    onClick: this.submit.bind(this, 'FAIL'),
                                }}
                            >
                                <div>
                                    <AntInput.TextArea
                                        autoSize={{ minRows: 4, maxRows: 4 }}
                                        onChange={this.inputChange}
                                        value={auditRemark}
                                    />
                                </div>
                            </AntModal>
                        </div>
                    </AntTabPane>
                    <AntTabPane tab={language.getText('viewData')} key="report">
                        <div className="report-wrap">
                            <div className="title">
                                {language.getText('bjbt')}：{noteInfo.title}
                            </div>
                            <div className="title">
                                {language.getText('bjpf')}：{noteInfo.totalScore}
                            </div>
                            <div>
                                <AntCard
                                    title={language.getText('hdsj_1')}
                                    type="inner"
                                    style={{ marginBottom: 32 }}
                                >
                                    <div className="data-wrap">
                                        <div className="report-item report-detail-item">
                                            <div className="report-item-title">
                                                {language.getText('dz_2')}
                                            </div>
                                            <div className="report-item-content">
                                                {noteInfo.likeCount}
                                            </div>
                                        </div>
                                        <div className="report-item report-detail-item">
                                            <div className="report-item-title">
                                                {language.getText('pl')}
                                            </div>
                                            <div className="report-item-content">
                                                {noteInfo.commentCount}
                                            </div>
                                        </div>
                                        <div className="report-item report-detail-item">
                                            <div className="report-item-title">
                                                {language.getText('fx')}
                                            </div>
                                            <div className="report-item-content">
                                                {noteInfo.shareCount}
                                            </div>
                                        </div>
                                        <div className="report-item report-detail-item">
                                            <div className="report-item-title">
                                                {language.getText('sz')}
                                            </div>
                                            <div className="report-item-content">
                                                {noteInfo.collectCount}
                                            </div>
                                        </div>
                                        <div className="report-item report-detail-item">
                                            <div className="report-item-title">
                                                {language.getText('zf')}
                                            </div>
                                            <div className="report-item-content">
                                                {noteInfo.followCount}
                                            </div>
                                        </div>
                                    </div>
                                </AntCard>
                                <AntCard
                                    title={language.getText('llsj')}
                                    type="inner"
                                    style={{ marginBottom: 32 }}
                                >
                                    <div className="data-wrap">
                                        <div className="report-item report-detail-item">
                                            <div className="report-item-title">
                                                {language.getText('fwl')}
                                            </div>
                                            <div className="report-item-content">
                                                {noteInfo.clickCount}
                                            </div>
                                        </div>
                                    </div>
                                </AntCard>
                            </div>
                        </div>
                    </AntTabPane>
                    <AntTabPane tab={language.getText('operatorLog')} key="log">
                        <div style={{ minHeight: 400 }}>{this.renderLogInfo()}</div>
                    </AntTabPane>
                </AntTabs>
            </div>
        );
    }
}
function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        fields: entity.fields || {},
    };
}
export const ContentNoteDetail = connect(mapStateToProps)(contentNoteDetail);
