import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Form, Select, Button, DatePicker, Table, Input, Badge } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const { RangePicker } = DatePicker;

interface Props {
    memberId: string;
}
interface State {
    total: number;
    pagination: {
        page: number;
        perPage: number;
    };
    searchParams: any;
    datasource: any[];
    loading: boolean;
}
enum CouponType {
    CASH = 'CASH',
    GIFT = 'GIFT',
    FREIGHT = 'FREIGHT',
    PARKING = 'PARKING',
    BALANCE = 'BALANCE',
    LOTTERY = 'LOTTERY',
    PACKAGE = 'PACKAGE',
    EFUTURE_CASH = 'EFUTURE_CASH',
    EFUTURE_BALANCE = 'EFUTURE_BALANCE',
    BFPLAT_COUPON = 'BFPLAT_COUPON',
}
enum EventCouponValueTypeEnum {
    No = 'NO',
    Amount = 'AMOUNT',
    EveryReduce = 'EVERY_REDUCE',
    Random = 'RANDOM',
    Discount = 'DISCOUNT',
    Hours = 'HOURS',
    Times = 'TIMES',
}
const couponStatusOptions = [
    { id: 'ALL', name: 'all' },
    { id: 'PREBINDING', name: 'jybd' },
    { id: 'ENABLED', name: 'dsy' },
    { id: 'USED', name: 'ysy_1' },
    { id: 'INVALID', name: 'ysx' },
    { id: 'EXPIRED', name: 'ygq' },
    { id: 'FREEZE', name: 'ydj' },
    { id: 'TRANSFERRING', name: 'zsz_1' },
];
const couponTypeOptions = [
    { id: 'ALL', name: 'all' },
    { id: 'CASH', name: 'cash' },
    { id: 'GIFT', name: 'gift' },
    { id: 'PARKING', name: 'parking' },
    { id: 'FREIGHT', name: 'freight' },
    { id: 'LOTTERY', name: 'lottery' },
    { id: 'BALANCE', name: 'balance' },
    { id: 'EFUTURE_CASH', name: 'efutureCash' },
    { id: 'EFUTURE_BALANCE', name: 'efutureBalance' },
    { id: 'BFPLAT_COUPON', name: 'bfplat' },
];
const typeValue = {
    [CouponType.CASH]: 'cashCoupon',
    [CouponType.GIFT]: 'gift',
    [CouponType.FREIGHT]: 'freight',
    [CouponType.PARKING]: 'parking',
    [CouponType.LOTTERY]: 'lottery',
    [CouponType.PACKAGE]: 'package',
    [CouponType.BALANCE]: 'balance',
    [CouponType.EFUTURE_CASH]: 'efutureCash',
    [CouponType.EFUTURE_BALANCE]: 'efutureBalance',
    [CouponType.BFPLAT_COUPON]: 'bfplat',
};
const consumeModeText = {
    E_SHOP: 'dsdhx',
    GUIDE: 'dgdhx',
    POS: 'poshx',
    MANAGE: 'yyhthx',
    ONLINE: 'xssy',
};

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline">
                    <Form.Item label={language.getText('couponName')}>
                        {getFieldDecorator('couponName')(
                            <Input
                                placeholder={services.language.getText('inputPlease')}
                                style={{ width: 190 }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('couponNum')}>
                        {getFieldDecorator('couponNo')(
                            <Input
                                placeholder={services.language.getText('inputPlease')}
                                style={{ width: 190 }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('executeStatus')}>
                        {getFieldDecorator('status', {
                            initialValue: 'ALL',
                        })(
                            <Select
                                style={{ width: '200px' }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                {couponStatusOptions.map((item: any) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {language.getText(item.name)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('qlx')}>
                        {getFieldDecorator('couponType', {
                            initialValue: 'ALL',
                        })(
                            <Select
                                style={{ width: '200px' }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                {couponTypeOptions.map((item: any) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {language.getText(item.name)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('dynamicEffectivePeriod')}>
                        {getFieldDecorator('dateRange')(
                            <RangePicker
                                placeholder={[
                                    services.language.getText('startTime'),
                                    services.language.getText('endTime'),
                                ]}
                                style={{ width: '300px' }}
                                className="find-filter-width"
                            />
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginLeft: 'auto' }}>
                        <Button type="primary" shape="round" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" shape="round" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export default class MemberInfoCouponPage extends PureComponent<Props, State> {
    state: State = {
        total: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        searchParams: {},
        datasource: [],
        loading: true,
    };

    componentDidMount() {
        this.onSearchData();
    }

    private onChangeParams = (searchParams: any) => {
        this.setState(
            {
                searchParams,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearchData();
            }
        );
    };

    private onSearchData = async () => {
        try {
            const { memberId } = this.props;
            const { searchParams, pagination } = this.state;
            const { couponName, couponNo } = searchParams || {};
            this.setState({
                loading: true,
            });
            const params: any = {
                ...pagination,
                couponName,
                couponNo,
            };

            if (searchParams && searchParams.status !== 'ALL') {
                params.status = searchParams.status;
            }
            if (searchParams && searchParams.couponType !== 'ALL') {
                params.couponType = searchParams.couponType;
            }
            if (searchParams && !isEmpty(searchParams.dateRange)) {
                let date = searchParams?.dateRange.map((i: any) => i.format('YYYY-MM-DD'));
                params.fromValidTime = date[0];
                params.toValidTime = date[1];
            }
            const res: any = await api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/members/${memberId}/coupons`,
            });
            this.setState({
                datasource: res.result,
                total: res.totalNum,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearchData();
        });
    };

    private renderFilter = () => {
        const classes = {
            filter: `${prefix}__filter`,
        };
        return (
            <div className={classes.filter}>
                <CollectionCreateForm onSearch={this.onChangeParams} />
            </div>
        );
    };

    private renderCoupon = (coupon: any) => {
        const classes = {
            coupon: `${prefix}__coupon`,
            couponLeft: `${prefix}__coupon__left`,
            couponLeftPrecond: `${prefix}__coupon__left__precond`,
            couponRight: `${prefix}__coupon__right`,
            couponRightType: `${prefix}__coupon__right__type`,
            rightTitle: `${prefix}__coupon__right__title`,
            rightTime: `${prefix}__coupon__right__time`,
        };
        const { couponType, condition } = coupon;
        const { conditionLowerValue, conditionType } = condition || {};
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };
        return (
            <div className={classes.coupon} style={style}>
                <div className={classes.couponLeft}>
                    {this.renderValue(coupon)}
                    {conditionType !== 'NOCONDITION' ? (
                        <div className={classes.couponLeftPrecond}>
                            {conditionType === 'MONEY'
                                ? `${language.getText(
                                      'm_3'
                                  )}${conditionLowerValue}${language.getText('yky')}`
                                : `${language.getText(
                                      'm_3'
                                  )}${conditionLowerValue}${language.getText('jky')}`}
                        </div>
                    ) : null}
                </div>
                <div className={classes.couponRight}>
                    <span className={classes.couponRightType}>
                        {language.getText(typeValue[couponType as CouponType])}
                    </span>
                    <div className={classes.rightTitle}>{coupon.couponName}</div>
                </div>
            </div>
        );
    };

    private renderValue = (coupon: any) => {
        const { couponType, value, valueType } = coupon;
        const classes = {
            itemPrice: `${prefix}__coupon__left__price`,
            itemPriceText: `${prefix}__coupon__left__price__text`,
            itemPriceNum: `${prefix}__coupon__left__price__num`,
            itemPriceSymbol: `${prefix}__coupon__left__price__symbol`,
        };

        if (couponType === CouponType.GIFT) {
            return (
                <div className={classes.itemPrice}>
                    <span className={classes.itemPriceText}>
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponType === CouponType.LOTTERY) {
            return (
                <div className={classes.itemPrice}>
                    <span className={classes.itemPriceNum}>{value}</span>
                </div>
            );
        } else if (couponType === CouponType.PACKAGE) {
            return (
                <div className={classes.itemPrice}>
                    <span className={classes.itemPriceText}>
                        {services.language.getText('gifts')}
                    </span>
                </div>
            );
        } else if (couponType === CouponType.PARKING && valueType === EventCouponValueTypeEnum.No) {
            return (
                <div className={classes.itemPrice}>
                    <span className={classes.itemPriceText}>
                        {services.language.getText('qm_2')}
                    </span>
                </div>
            );
        }
        if (!value) {
            return null;
        } else if (
            couponType === CouponType.PARKING &&
            valueType === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className={classes.itemPrice}>
                    <span className={classes.itemPriceNum}>{value}</span>
                    <span className={classes.itemPriceSymbol}>小时</span>
                </div>
            );
        }
        return (
            <div className={classes.itemPrice}>
                <span className={classes.itemPriceSymbol}>
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className={classes.itemPriceNum}>{value}</span>
            </div>
        );
    };

    private renderTable = () => {
        const { pagination, total, datasource, loading } = this.state;
        const statusConfig: {
            [str: string]: {
                color?: string;
                text?: string;
                status?: any;
            };
        } = {
            ENABLED: {
                text: language.getText('dsy'),
                status: 'default',
            },
            PREBINDING: {
                text: language.getText('jybd'),
                status: 'default',
            },
            USED: {
                text: language.getText('ysy_1'),
                status: 'success',
            },
            EXPIRED: {
                text: language.getText('ygq'),
                status: 'error',
            },
            INVALID: {
                text: language.getText('ysx'),
                status: 'error',
            },
            FREEZE: {
                text: language.getText('ydj'),
                status: 'warn',
            },
            TRANSFERRING: {
                text: language.getText('zsz_1'),
                status: 'default',
            },
            TRANSFERRED: {
                text: language.getText('transfered'),
                status: 'default',
            },
        };
        return (
            <div className={`${prefix}__table`}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record: any) => record.couponNo}
                    pagination={{
                        current: pagination.page,
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={[
                        {
                            title: services.language.getText('yhq'),
                            dataIndex: 'couponNo',
                            width: 350,
                            render: (val, row) => <div>{this.renderCoupon(row)}</div>,
                        },
                        {
                            title: language.getText('couponNum'),
                            dataIndex: 'couponNo',
                        },
                        {
                            title: language.getText('executeStatus'),
                            dataIndex: 'status',
                            width: 100,
                            render: (val: string) => (
                                <span>
                                    <Badge {...statusConfig[val]} />
                                </span>
                            ),
                        },
                        {
                            title: language.getText('jhqlylysj'),
                            dataIndex: 'originType',
                            width: 130,
                            align: 'center',
                            render: (val: string, row: any) => (
                                <div>
                                    <div>{val}</div>
                                    {row.originEventName ? (
                                        <div>({row.originEventName})</div>
                                    ) : null}
                                </div>
                            ),
                        },
                        {
                            title: language.getText('bindingTime'),
                            dataIndex: 'bindingTime',
                            width: 200,
                        },
                        {
                            title: services.language.getText('dynamicEffectivePeriod'),
                            dataIndex: 'fromValidTime',
                            width: 360,
                            render: (val, row) => (
                                <span>
                                    {val}~{row.toValidTime}
                                </span>
                            ),
                        },
                        {
                            title: language.getText('hxfs'),
                            dataIndex: 'consume.consumeMode',
                            render: (val: keyof typeof consumeModeText) => (
                                <span>{services.language.getText(consumeModeText[val])}</span>
                            ),
                        },
                        {
                            title: language.getText('jhxmdhxzg'),
                            dataIndex: 'consume',
                            width: 200,
                            render: (consume: any) => (
                                <span>
                                    {consume.consumeSubsiteName}
                                    {consume.consumeMerchantName
                                        ? `~${consume.consumeMerchantName}`
                                        : ''}
                                </span>
                            ),
                        },
                        {
                            title: language.getText('jhxtksj'),
                            width: 120,
                            dataIndex: 'consume.consumeTime',
                        },
                        {
                            title: language.getText('afterSalesOrderNo'),
                            dataIndex: 'serviceApplyNo',
                            width: 200,
                            render: (val, row: any) =>
                                val ? (
                                    <a
                                        href={`/#/coupon-service-applies/info/${row.serviceApplyId}/displayCouponStatus`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {val}
                                    </a>
                                ) : null,
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    };

    render() {
        return (
            <div className={prefix}>
                {this.renderFilter()}
                {this.renderTable()}
            </div>
        );
    }
}

const prefix = 'member-info-coupon-page';
