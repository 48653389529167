import React, { PureComponent } from 'react';
import { map, isEqual, isEmpty, cloneDeep, isUndefined, isNull } from 'lodash';
import { Checkbox, InputNumber, Radio, Select } from 'antd';
import { ActivityRewardItem } from '../../../../components/activity-reward-item';
import './index.less';
import { services } from '@comall-backend-builder/core';

const RadioGroup = Radio.Group;
const Option = Select.Option;

export interface SharePageReward {
    /**
     * 活动周期
     */
    activityPeriod?: ACTIVITY_PERIOD | undefined | null;
    /**
     * 活动周期内奖励上限
     */
    activityPeriodRewardLimit?: number | undefined | null;
    /**
     * 奖励上限（活动时间内获得n次奖励不再获得奖励）
     */
    activityRewardLimit?: number | undefined | null;
    /**
     * 事件类型
     */
    eventType: EVENT_TYPE;
    /**
     * 创建分享有礼活动奖励项
     */
    rewardItem: SharePageRewardItem;
    /**
     * 奖励规则
     */
    rewardRule: string;
}

export interface SharePageRewardItem {
    coupons?: any[] | undefined | null;
    point?: number | undefined | null;
    stepValue?: number | undefined | null;
}

export enum ACTIVITY_PERIOD {
    /**
     * 每日
     */
    DAY = 'DAY',
    /**
     * 每周
     */
    WEEK = 'WEEK',
    /**
     * 每月
     */
    MONTH = 'MONTH',
}

export enum EVENT_TYPE {
    /**
     * 新用户注册事件
     */
    SHARE_PAGE_MEMBER_REGISTER = 'SHARE_PAGE_MEMBER_REGISTER',
    /**
     * 游客访问事件
     */
    SHARE_PAGE_VISITOR_ACCESS = 'SHARE_PAGE_VISITOR_ACCESS',
}

export interface ActivityRewardProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: Array<SharePageReward> | null;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<SharePageReward> | null) => void;
    row: any;
    /**
     * 禁用状态
     */
    disabled?: boolean;
}

/**
 * 分享有礼奖励
 */
export class SharePageActivityRewards extends PureComponent<ActivityRewardProps> {
    componentWillReceiveProps(nextProps: any) {
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (!isEqual(nextSubsiteIds, currentSubsiteIds) && !isEmpty(nextProps.value)) {
            this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }
    }

    /**
     * 根据门店id的变更，处理数据
     * @param subsiteIds 新的门店ID集合
     * @param props 新的props
     */
    processDataAfterSubsiteChange = (subsiteIds: string[] | null, props: ActivityRewardProps) => {
        const { value, onChange } = props;
        const newValue = cloneDeep(value);
        if (isNull(subsiteIds)) {
            newValue &&
                newValue.forEach((item) => {
                    delete item.rewardItem.coupons;
                });
        } else {
            newValue &&
                newValue.forEach((item) => {
                    const newCoupons: any[] = [];
                    item.rewardItem?.coupons &&
                        item.rewardItem.coupons.forEach((coupon) => {
                            if (
                                !!coupon?.subsites?.find((subsite: any) => {
                                    return !!subsiteIds.find((id) => subsite.id.toString() === id);
                                })
                            ) {
                                newCoupons.push(coupon);
                            }
                        });
                    if (!!newCoupons && newCoupons.length > 0) {
                        item.rewardItem.coupons = newCoupons;
                    } else {
                        delete item.rewardItem.coupons;
                    }
                });
        }
        onChange && onChange(newValue);
    };

    /**
     * 获取门店id集合
     * @param props
     * @returns
     */
    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row && row.baseInfo && row.baseInfo.subsites ? map(row.baseInfo.subsites, 'id') : null;
        return subsiteIds;
    };

    /**
     * 选择发放奖励行为复选框
     * @param eventType 奖励行为类型
     * @param e 事件原型对象
     */
    onChangeRewardsCheckbox = (eventType: EVENT_TYPE, e: any) => {
        const { value, onChange } = this.props;
        const newValue = !!value ? cloneDeep(value) : [];
        if (e.target.checked) {
            newValue.push({
                eventType,
                rewardItem: {},
                rewardRule: 'CYCLE',
            });
        } else {
            const index = newValue
                ? newValue.findIndex((item) => item.eventType === eventType)
                : -1;
            if (index !== -1 && newValue) {
                newValue.splice(index, 1);
            }
        }
        onChange && onChange(newValue);
    };

    /**
     * 选择奖励内容复选框
     * @param reward 奖励明细
     * @param e 事件原型对象
     */
    onChangeRewardItemCheckbox = (reward: SharePageReward, e: any) => {
        const { value, onChange } = this.props;
        const newValue = !!value ? cloneDeep(value) : [];
        const index = newValue.findIndex((item) => item.eventType === reward.eventType);
        const newReward: any = newValue[index];
        if (newReward) {
            if (e.target.checked) {
                newReward.rewardItem[e.target.value] = null;
            } else {
                delete newReward.rewardItem[e.target.value];
            }
        }
        onChange && onChange(newValue);
    };

    /**
     * 页面输入框/下拉框值变更
     * @param inputValue 输入值
     * @param eventType 奖励类型
     * @param field 变更域
     * @returns
     */
    onInputValueChange = (inputValue: any, eventType: EVENT_TYPE, field: string) => {
        const { value, onChange } = this.props;
        const newValue = !!value ? cloneDeep(value) : [];
        const index = newValue.findIndex((item) => item.eventType === eventType);
        const newReward = newValue[index];
        if (newReward) {
            switch (field) {
                case 'stepValue':
                    newReward.rewardItem.stepValue = inputValue;
                    break;
                case 'point':
                    newReward.rewardItem.point = inputValue;
                    break;
                case 'coupons':
                    newReward.rewardItem.coupons = inputValue.map((item: any) => {
                        if (!item.couponType || item.couponType !== 'PACKAGE') {
                            item.quantity = item.quantity ? item.quantity : 1;
                        }
                        if (!item.couponRuleId) {
                            item.couponRuleId = item.id;
                        }
                        return item;
                    });
                    break;
                case 'activityPeriod':
                    newReward.activityPeriod = inputValue;
                    newReward.activityRewardLimit = null;
                    break;
                case 'activityPeriodRewardLimit':
                    newReward.activityPeriodRewardLimit = inputValue;
                    newReward.activityRewardLimit = null;
                    break;
                case 'activityRewardLimit':
                    newReward.activityRewardLimit = inputValue;
                    newReward.activityPeriod = null;
                    newReward.activityPeriodRewardLimit = null;
                    break;
            }
            onChange && onChange(newValue);
            return inputValue;
        }
    };

    /**
     * 奖励限制复选框变更
     * @param eventType 奖励类型
     * @param e 事件原型对象
     */
    onChangeLimitCheckbox = (eventType: EVENT_TYPE, e: any) => {
        const { value, onChange } = this.props;
        const newValue = !!value ? cloneDeep(value) : [];
        const index = newValue.findIndex((item) => item.eventType === eventType);
        const newReward = newValue[index];
        if (newReward) {
            if (e.target.checked) {
                newReward.activityPeriod = null;
                newReward.activityPeriodRewardLimit = null;
                newReward.activityRewardLimit = undefined;
            } else {
                delete newReward.activityPeriod;
                delete newReward.activityPeriodRewardLimit;
                delete newReward.activityRewardLimit;
            }
            onChange && onChange(newValue);
        }
    };

    /**
     * 奖励限制内容单选框变更
     * @param radioValue 单选框值
     * @param eventType 奖励类型
     */
    onRadioChange = (radioValue: 'all_time' | 'every_reduce', eventType: EVENT_TYPE) => {
        const { value, onChange } = this.props;
        const newValue = !!value ? cloneDeep(value) : [];
        const index = newValue.findIndex((item) => item.eventType === eventType);
        const newReward = newValue[index];
        switch (radioValue) {
            case 'all_time':
                newReward.activityPeriod = null;
                newReward.activityPeriodRewardLimit = null;
                newReward.activityRewardLimit = undefined;
                break;
            case 'every_reduce':
                newReward.activityPeriod = undefined;
                newReward.activityPeriodRewardLimit = undefined;
                newReward.activityRewardLimit = null;
                break;
        }
        onChange && onChange(newValue);
    };

    renderReward = (reward: SharePageReward | null | undefined) => {
        if (!reward) return null;
        const { disabled } = this.props;
        const subsiteIds = this.getSubsiteIds(this.props);
        const {
            rewardItem: { point, stepValue, coupons },
            activityPeriod,
            activityPeriodRewardLimit,
            activityRewardLimit,
        } = reward;
        const limitHide =
            isUndefined(activityPeriod) &&
            isUndefined(activityPeriodRewardLimit) &&
            isUndefined(activityRewardLimit);
        const radioValue =
            isNull(activityPeriod) && isNull(activityPeriodRewardLimit)
                ? 'all_time'
                : 'every_reduce';
        return (
            <div className="activity-reward">
                <div className="reward-item">
                    <div className="reward-title">{services.language.getText('fxrjl')}：</div>
                    <div className="reward-step">
                        <span>
                            {reward.eventType === EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER
                                ? services.language.getText('zcyh')
                                : services.language.getText('access')}
                            {services.language.getText('mdd')}
                        </span>
                        <InputNumber
                            precision={0}
                            type="number"
                            min={1}
                            className="reward-input"
                            onChange={(value) => {
                                this.onInputValueChange(value, reward.eventType, 'stepValue');
                            }}
                            disabled={disabled}
                            value={isNull(stepValue) ? undefined : stepValue}
                        />
                        <span>{services.language.getText('rhdyxjl')}</span>
                    </div>
                    <div className="reward-content-item">
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={this.onChangeRewardItemCheckbox.bind(this, reward)}
                            checked={!isUndefined(point)}
                            value="point"
                            disabled={disabled}
                        >
                            {services.language.getText('point')}
                        </Checkbox>
                        <span className="reward-item-text">
                            {services.language.getText('song')}
                        </span>
                        <InputNumber
                            precision={0}
                            type="number"
                            min={1}
                            value={isNull(point) ? undefined : point}
                            onChange={(value) => {
                                this.onInputValueChange(value, reward.eventType, 'point');
                            }}
                            disabled={disabled}
                        />
                        <span className="reward-item-text">
                            {services.language.getText('point')}
                        </span>
                    </div>
                    <div className="reward-content-item">
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={this.onChangeRewardItemCheckbox.bind(this, reward)}
                            value="coupons"
                            checked={!isUndefined(coupons)}
                            disabled={disabled}
                        >
                            {services.language.getText('yhq')}
                        </Checkbox>
                        <div className="reward-coupons">
                            <ActivityRewardItem
                                index={1}
                                value={reward.rewardItem.coupons || []}
                                subsiteIds={subsiteIds}
                                onChangeCoupon={(value) => {
                                    this.onInputValueChange(value, reward.eventType, 'coupons');
                                }}
                                type="checkbox"
                                packageSelectType="radio"
                                canChangeQuantity
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
                <div className="reward-item">
                    <div className="reward-title">{services.language.getText('jlxz')}：</div>
                    <div>
                        <Checkbox
                            onChange={this.onChangeLimitCheckbox.bind(this, reward.eventType)}
                            value="limit"
                            disabled={disabled}
                            checked={!limitHide}
                        >
                            {services.language.getText('xdmrffjlcs')}
                        </Checkbox>
                    </div>
                    <div className="reward-tip">{services.language.getText('jygxxdcsygbqzfx')}</div>
                </div>
                {!limitHide && (
                    <div className="reward-item">
                        <div className="reward-title">{services.language.getText('csxz')}：</div>
                        <div>
                            <RadioGroup
                                className="radio-group"
                                style={{ width: '400px' }}
                                value={radioValue}
                                onChange={(e) => {
                                    this.onRadioChange(e.target.value, reward.eventType);
                                }}
                                disabled={disabled}
                            >
                                <Radio className="reward-item-radio" value="all_time">
                                    <span>{services.language.getText('xzhdyxqn')}</span>
                                    <InputNumber
                                        precision={0}
                                        type="number"
                                        min={1}
                                        value={
                                            isNull(activityRewardLimit)
                                                ? undefined
                                                : activityRewardLimit
                                        }
                                        className="reward-input"
                                        onChange={(value) => {
                                            this.onInputValueChange(
                                                value,
                                                reward.eventType,
                                                'activityRewardLimit'
                                            );
                                        }}
                                        disabled={disabled}
                                    />
                                    <span>{services.language.getText('frequency')}</span>
                                </Radio>
                                <Radio value="every_reduce">
                                    <span>{services.language.getText('xzmr')}</span>
                                    <Select
                                        className="reward-input"
                                        value={activityPeriod}
                                        onChange={(value: any) => {
                                            this.onInputValueChange(
                                                value,
                                                reward.eventType,
                                                'activityPeriod'
                                            );
                                        }}
                                        disabled={disabled}
                                    >
                                        <Option value={ACTIVITY_PERIOD.DAY}>
                                            {services.language.getText('everyDay')}
                                        </Option>
                                        <Option value={ACTIVITY_PERIOD.WEEK}>
                                            {services.language.getText('everyWeek')}
                                        </Option>
                                        <Option value={ACTIVITY_PERIOD.MONTH}>
                                            {services.language.getText('everyMonth')}
                                        </Option>
                                    </Select>
                                    <span>，{services.language.getText('zdffjl')} </span>
                                    <InputNumber
                                        precision={0}
                                        type="number"
                                        min={1}
                                        value={
                                            isNull(activityPeriodRewardLimit)
                                                ? undefined
                                                : activityPeriodRewardLimit
                                        }
                                        className="reward-input"
                                        onChange={(value) => {
                                            this.onInputValueChange(
                                                value,
                                                reward.eventType,
                                                'activityPeriodRewardLimit'
                                            );
                                        }}
                                        disabled={disabled}
                                    />
                                    <span>{services.language.getText('frequency')}</span>
                                </Radio>
                            </RadioGroup>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { value, disabled } = this.props;
        const visitor = value
            ? value.find((reward) => reward.eventType === EVENT_TYPE.SHARE_PAGE_VISITOR_ACCESS)
            : null;
        const register = value
            ? value.find((reward) => reward.eventType === EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER)
            : null;
        return (
            <div className="share-page-activity-rewards" style={{ display: 'inline-block' }}>
                <div>
                    <Checkbox
                        checked={!!visitor}
                        onChange={this.onChangeRewardsCheckbox.bind(
                            this,
                            EVENT_TYPE.SHARE_PAGE_VISITOR_ACCESS
                        )}
                        disabled={disabled}
                    >
                        {services.language.getText('afkfjl')}
                    </Checkbox>
                </div>

                {this.renderReward(visitor)}
                <div>
                    <Checkbox
                        checked={!!register}
                        onChange={this.onChangeRewardsCheckbox.bind(
                            this,
                            EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER
                        )}
                        disabled={disabled}
                    >
                        {services.language.getText('axyhzcfjl')}
                    </Checkbox>
                </div>
                {this.renderReward(register)}
            </div>
        );
    }
}
