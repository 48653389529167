import { BaseConfig } from '../../../applications/cae/interfaces';

export let config: BaseConfig = {
    entities: {
        MemberPageManage: {
            apiPath: '/loader/admin/member_pages',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '门店/门店群',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '门店/门店群',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
            },
        },
    },
    components: {
        MemberPageManageView: {
            component: 'Viewport',
            entity: 'MemberPageManage',
        },
        MemberPageManageDesign: {
            component: 'Card',
            content: {
                component: 'DesignMemberCenter',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/member_pages/:id',
            },
        },
        MemberPageManage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'MemberPageManageFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<components.PageManage.createPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/design-member/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'MemberPageManageTable',
                },
            ],
        },
        MemberPageManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'groupSubsiteName',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        optionFilterProp: 'children',
                        style: { width: '160px' },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: '160px' },
                    },
                },
            ],
            submit: { text: '<<common.search>>' },
        },
        MemberPageManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'groupSubsiteName',
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'name',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<components.PageManage.setting>>',
                        path: '/design-member/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<components.PageManage.decorate>>',
                        path: '/design-member/design/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'PagePreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'delete',
                        text: '<<components.PageManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        MemberPageManageAdd: {
            component: 'Card',
            title: '<<components.PageManage.createPage>>',
            content: {
                component: 'MemberPageManageAddForm',
            },
        },
        MemberPageManageAddForm: {
            component: 'CreateForm',
            entity: 'MemberPageManage',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'enabled',
                },
            ],
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/design-member',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        MemberPageManageEdit: {
            component: 'Card',
            title: '<<components.PageManage.setPage>>',
            content: {
                component: 'MemberPageManageEditForm',
            },
        },
        MemberPageManageEditForm: {
            component: 'ModifyForm',
            entity: 'MemberPageManage',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'enabled',
                },
            ],
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/design-member',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/design-member',
                    component: 'MemberPageManageView',
                    breadcrumbName: '管理会员中心',
                    privilege: {
                        path: 'memberPages',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'MemberPageManageDesign',
                        },
                        {
                            path: '/add',
                            component: 'MemberPageManageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MemberPageManageEdit',
                        },
                        {
                            path: '/',
                            component: 'MemberPageManage',
                        },
                    ],
                },
            ],
        },
    ],
};
