import React, { Component } from 'react';
import { message } from 'antd';
import { privilege } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface ExtraElementProps {
    tips: string[];
    /**
     * 跳转文案
     */
    forwardText?: string;
    /**
     * 跳转地址
     */
    forwardPath?: string;
    /**
     * 跳转权限名称
     */
    forwardPrivilege?: string;
}

class ExtraForward extends Component<ExtraElementProps> {
    renderTip = (tip: string) => {
        const { forwardPath, forwardText, forwardPrivilege } = this.props;
        const style = {
            display: 'flex',
            color: 'rgb(204, 204, 204)',
            alignItems: 'center',
        };
        const onGoToStylePage = () => {
            if (forwardPrivilege && privilege.check(forwardPrivilege, 'full')) {
                window.open(forwardPath);
            } else {
                message.warning(services.language.getText('noPerationPermission'));
            }
        };
        if (tip.includes('{forwardText}')) {
            const [before, after] = tip.split('{forwardText}');
            return (
                <div style={style}>
                    <span>{before || ''}</span>
                    <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={onGoToStylePage}>
                        {forwardText}
                    </span>
                    <span>{after || ''}</span>
                </div>
            );
        }
        return <div style={style}>{tip}</div>;
    };
    render() {
        const { tips } = this.props;
        return <div>{tips.map(this.renderTip)}</div>;
    }
}

export const extraForward = (props: ExtraElementProps) => {
    return React.createElement(ExtraForward, props);
};
