import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { tools } from '@comall-backend-builder/design-components-basis';
import { customSelectorConfig } from '../../../applications/design/design';
import {
    DESIGN_PAGE_LINK_TYPES_LIST,
    PLATFORM_PAGE_LINK_TYPES_LIST,
} from '../../../applications/design/constants/page-design';

const { LinkType } = tools;

/**
 * 为了解决ObjectMode 给实体属性设置defaultValue无效
 * @param props
 */
const HocLinkType = (props: any) => {
    const newProps = { ...props };
    newProps.value = props.value || { linkType: 'none' };
    return (
        <LinkType
            selector={customSelectorConfig}
            linkTypeMode={'cascader'}
            linkTypes={
                props.usePlatForm ? PLATFORM_PAGE_LINK_TYPES_LIST : DESIGN_PAGE_LINK_TYPES_LIST
            }
            {...newProps}
        />
    );
};

/**
 * 可视化广告位链接选择器
 */
export class LinkTypeMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className } = displayInfo;
        return <div className={className}>{object}</div>;
    }
    /**
     * 获取输入组件
     */

    public getControlComponent(controlInfo: any) {
        return <HocLinkType {...controlInfo}></HocLinkType>;
    }
}
