import React, { PureComponent } from 'react';
import { Table, InputNumber, Button, message, Popconfirm } from 'antd';
import { cloneDeep, remove, find, isNumber, isNaN, forEach, includes } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface SaleProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    disabled?: boolean;
    bathTypeConfig?: string[];
}

interface SaleProductSelectedDisplayState {
    salePrice: undefined | number;
    saleStock: undefined | number;
    batchSalePrice: undefined | number;
    batchSaleStock: undefined | number;
}

enum batchType {
    salePrice = 'batchSalePrice',
    saleStock = 'batchSaleStock',
}
const FooterBath = [
    { id: 'batchSalePrice', name: 'flashPrice', min: 0.01, max: 9999999.99, precision: 2 },
    { id: 'batchSaleStock', name: 'flashStock', min: 1, max: 9999999, precision: 0 },
];
/**
 * 商品展示和内容编辑
 */
class SaleProductSelectedDisplay extends PureComponent<
    SaleProductSelectedDisplayProps,
    SaleProductSelectedDisplayState
> {
    state = {
        salePrice: undefined,
        saleStock: undefined,
        batchSalePrice: undefined,
        batchSaleStock: undefined,
    };
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable, disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('setFlashPrice'),
                    key: 'salePrice',
                    dataIndex: 'salePrice',
                    width: 150,
                    align: 'center' as const,
                    render: (v: number, r: any) => {
                        return modifiable ? (
                            <InputNumber
                                placeholder={language.getText('flashPriceRequired')}
                                value={v}
                                min={0.01}
                                max={999999999}
                                style={{ width: '130px' }}
                                onChange={(value: any) => {
                                    const reg = /^(-)*(\d+)\.(\d\d).*$/;
                                    let tansformVal = value;
                                    if (typeof value === 'number') {
                                        tansformVal = String(tansformVal).replace(reg, '$1$2.$3');
                                        tansformVal = Number(tansformVal);
                                    }
                                    this.changeValue('salePrice', tansformVal, r.id);
                                }}
                            ></InputNumber>
                        ) : (
                            v
                        );
                    },
                },
                {
                    title: language.getText('adjustFlashPrice'),
                    key: 'saleStock',
                    dataIndex: 'saleStock',
                    width: 120,
                    align: 'center' as const,
                    render: (v: number, r: any) => {
                        return modifiable ? (
                            <InputNumber
                                value={v}
                                min={1}
                                step={1}
                                precision={0}
                                max={r.groupLeftStock <= 999999 ? r.groupLeftStock : 999999}
                                onChange={(value: any) =>
                                    this.changeValue('saleStock', value, r.id)
                                }
                            ></InputNumber>
                        ) : (
                            v
                        );
                    },
                },
                {
                    title: language.getText('currentSaleStock'),
                    dataIndex: 'groupLeftStock',
                    key: 'groupLeftStock',
                    width: 100,
                    align: 'center' as const,
                },
                {
                    title: language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 120,
                },
                {
                    title: language.getText('goodNum'),
                    dataIndex: 'goodsCode',
                    key: 'goodsCode',
                    width: 120,
                },
                {
                    title: language.getText('goodBarCode'),
                    dataIndex: 'barCode',
                    key: 'barCode',
                    width: 120,
                },
                {
                    title: language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 120,
                },
                {
                    title: language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 120,
                },
                {
                    title: language.getText('profit'),
                    dataIndex: 'profit',
                    key: 'profit',
                    width: 100,
                    align: 'center' as const,
                    render: (v: number) => {
                        return isNumber(+v) && !isNaN(+v) ? (+v).toFixed(2) : '';
                    },
                },
            ],
            scroll: {
                x: 1900,
                y: 500,
            },
        };
        if (modifiable && !disabled) {
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
                fixed: true,
            };
            config.columns.push({
                title: language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => (
                    <Button type={'link'} disabled={disabled} onClick={this.onRemove(row.id)}>
                        {language.getText('common.delete')}
                    </Button>
                ),
            });
        }
        return config;
    };

    renderFooter = () => {
        const { bathTypeConfig } = this.props;
        return (
            <div className="product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                    {bathTypeConfig?.length ? this.renderFooterBath() : null}
                </div>
            </div>
        );
    };
    renderFooterBath = () => {
        const { bathTypeConfig } = this.props;
        return (
            <React.Fragment>
                {FooterBath.map((item) => {
                    if (includes(bathTypeConfig, item.id)) {
                        const placeholder =
                            language.getText('batchEdit') + language.getText(item.name);
                        // @ts-ignore：
                        const value = this.state[item.id];
                        const title = services.interpolate(language.getText('batchEditSeleced'), {
                            name: item.name,
                        });
                        return (
                            <div key={item.id} style={{ display: 'inline-flex' }}>
                                <InputNumber
                                    className="item-number"
                                    placeholder={placeholder}
                                    value={value}
                                    min={item.min}
                                    max={item.max}
                                    precision={item.precision}
                                    onChange={(e) => this.changeBatchInputValue(item.id, e)}
                                ></InputNumber>
                                <Popconfirm
                                    onConfirm={this.batchChangeValue(item.id)}
                                    title={title}
                                    okText={language.getText('common.ok')}
                                    cancelText={language.getText('common.cancel')}
                                >
                                    <Button className="setting-item-ok" type="default">
                                        {language.getText('confirmEdit')}
                                    </Button>
                                </Popconfirm>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </React.Fragment>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
            this.selectedRows = [];
        };
    };
    changeBatchInputValue = (field: string, value: any) => {
        // @ts-ignore：
        this.setState({ [field]: value });
    };
    batchChangeValue = (field: string) => {
        return () => {
            // @ts-ignore：
            const fieldValue = this.state[field];
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(language.getText('selectSetDataColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.id === j.id);
                if (item) {
                    if (field === batchType.salePrice) {
                        i.salePrice = fieldValue;
                    } else {
                        if (fieldValue <= item.groupLeftStock) {
                            i.saleStock = fieldValue;
                        } else {
                            i.saleStock = item.groupLeftStock;
                        }
                    }
                }
            });
            onChange(result);
        };
    };
    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    render() {
        const { data, modifiable, disabled } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div className="sales-plans-product-selected-display">
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && !disabled && this.renderFooter()}
            </div>
        );
    }
}

export { SaleProductSelectedDisplay };
