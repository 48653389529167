import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        InventoryRuleView: {
            component: 'Viewport',
        },
        InventoryRulePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [
                {
                    component: 'InventoryRulePageTabs',
                },
            ],
        },
        InventoryRulePageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<productInventoryRule>>',
                    content: {
                        id: '10000',
                        title: '<<productInventoryRule>>',
                        component: 'InventoryRuleForm',
                    },
                },
                {
                    title: '<<visualInventoryRule>>',
                    content: {
                        id: '20000',
                        title: '<<visualInventoryRule>>',
                        component: 'InventoryRuleForm',
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/inventory-rule',
                    component: 'InventoryRuleView',
                    breadcrumbName: '<<setInventoryRule>>',
                    privilege: {
                        path: 'inventoryRule',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'InventoryRulePage' }],
                },
            ],
        },
    ],
};
