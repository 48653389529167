import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Form, Spin, Input, InputNumber, Button, Modal, message } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { api, errorHandle, navigation } from '@comall-backend-builder/core/lib/services';
import { isNil, forEach } from 'lodash';
import { strip, plus } from 'number-precision';
import { CGBConfigSelectSubsite } from '../../../containers';
import { OrderFlowRule } from './order-flow';
import { PaymentResult } from './order-flow-selector';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export interface OfflineOrderReviewProps {
    id: string | number;
    row: any;
    entity: Entity;
    params: any;
    form: FormComponentProps['form'];
}

interface OfflineOrderReviewState {
    loading: boolean;
    isViewPage: boolean;
    paymentResults: Array<PaymentResult>;
    sendMsgDisabled: boolean;
    visible: boolean;
    smsCode: string;
    submitLoading: boolean;
    subsiteName: string;
    orderNo: string;
    totalPayableAmount: number;
}

class OfflineOrderReview extends PureComponent<OfflineOrderReviewProps, OfflineOrderReviewState> {
    state: OfflineOrderReviewState = {
        loading: false,
        isViewPage: false,
        paymentResults: [],
        sendMsgDisabled: false,
        visible: false,
        smsCode: '',
        submitLoading: false,
        subsiteName: '',
        orderNo: '',
        totalPayableAmount: 0,
    };
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const {
            params: { id },
        } = this.props;
        if (!id) {
            return;
        }
        this.setState({ loading: true, isViewPage: true });
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/offline_payment_bill/manual_review_records/${id}`,
            }
        )
            .then(async (data: any) => {
                this.setState({
                    loading: false,
                    paymentResults: data.offlinePaymentBills,
                    subsiteName: data.subsiteName,
                    orderNo: data.orderNo,
                    totalPayableAmount: data.totalPayableAmount,
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                errorHandle(error);
            });
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.judgmentRule(values);
            }
        });
    };

    judgmentRule = (values: any) => {
        const {
            form: { getFieldValue },
        } = this.props;
        const { paymentResults } = this.state;
        if (!paymentResults || !paymentResults?.length) {
            message.error(getText('qszxxzfjylsh'));
            return;
        }
        const errorArr = paymentResults.filter((i) => isNil(i.paidAmount));
        if (errorArr?.length) {
            message.error(getText('qszxxzfjylshje'));
            return;
        }
        let totalAmount = 0;
        forEach(paymentResults, (item) => {
            totalAmount = plus(totalAmount, item.paidAmount);
        });
        const payableAmount = Number(getFieldValue('payableAmount') || 0);
        if (strip(totalAmount) !== strip(payableAmount)) {
            message.error(getText('ddjeyxxzfjylsjebpp'));
            return;
        }
        this.sendMessage();
    };

    sendMessage = () => {
        const {
            form: { getFieldValue },
        } = this.props;
        let subsiteId = getFieldValue('subsites') ? getFieldValue('subsites')[0].id : '';
        if (!subsiteId) {
            return;
        }
        this.setState({ sendMsgDisabled: true });
        api.post(
            { subsiteId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/sms/OFFLINE_PAYMENT_BILL_MANUAL_REVIEW_SMS/backend_send`,
            }
        )
            .then(() => {
                this.setState({ visible: true, sendMsgDisabled: false });
            })
            .catch((error) => {
                this.setState({ sendMsgDisabled: false });
                errorHandle(error);
            });
    };

    onSelectorSubsiteChange = (subsite: any) => {
        const {
            form: { getFieldValue, setFieldsValue },
        } = this.props;
        let subSiteId = getFieldValue('subsites') ? getFieldValue('subsites')[0].id : '';
        let nexSubSiteId = subsite?.length ? subsite[0].id : '';
        if (subSiteId !== nexSubSiteId) {
            this.setState({
                paymentResults: [],
            });
            setFieldsValue({ order: [] });
        }
    };

    onRulesChange = (value: Array<PaymentResult>) => {
        this.setState({ paymentResults: value });
    };

    renderContent = () => {
        const { isViewPage } = this.state;
        if (isViewPage) {
            return this.renderViewContent();
        }
        return this.renderForm();
    };

    renderViewContent = () => {
        const { paymentResults, isViewPage, subsiteName, orderNo, totalPayableAmount } = this.state;
        return (
            <div className="offline-order-review-form" style={{ width: '100%' }}>
                <div className="form-title" style={{ marginBottom: 10 }}>
                    {getText('ddxx')}
                </div>
                <div className="subsite-name">
                    {getText('subsites')}：{subsiteName}
                </div>
                <div className="subsite-name">
                    {getText('ddh_1')}
                    {orderNo}
                </div>
                <div className="subsite-name">
                    {getText('orderAmount')}：{totalPayableAmount}
                </div>
                <div className="form-title mt10">{getText('xxzfjylsh')}</div>
                <OrderFlowRule
                    isViewPage={isViewPage}
                    value={paymentResults}
                    onChange={this.onRulesChange}
                />
                <div className="footer-btn">
                    <Button onClick={navigation.goBack}>{getText('back')}</Button>
                </div>
            </div>
        );
    };

    renderForm = () => {
        const { isViewPage, paymentResults, sendMsgDisabled } = this.state;
        const {
            form: { getFieldDecorator, getFieldValue },
        } = this.props;
        let subsiteId = getFieldValue('subsites') ? getFieldValue('subsites')[0].id : '';
        const subsiteProps = {
            name: 'subsites',
            type: 'radio',
            value: getFieldValue('subsites'),
            ...this.props,
        };
        return (
            <Form
                onSubmit={this.handleSubmit}
                className="offline-order-review-form"
                style={{ width: '100%' }}
            >
                <div className="form-title">{getText('ddxx')}</div>
                <Form.Item label={services.language.getText('otherSelectSubsite')}>
                    {getFieldDecorator('subsites', {
                        rules: [
                            {
                                required: true,
                                message: getText('selectSubsite'),
                            },
                        ],
                    })(
                        <CGBConfigSelectSubsite
                            {...subsiteProps}
                            onChange={this.onSelectorSubsiteChange}
                        />
                    )}
                </Form.Item>
                <Form.Item label={getText('ddh_1')}>
                    {getFieldDecorator('orderNo', {
                        rules: [
                            {
                                required: true,
                                message: getText('qsrddh'),
                            },
                        ],
                    })(<Input placeholder={getText('qsrddh')} maxLength={30} />)}
                </Form.Item>
                <Form.Item label={getText('orderAmount')}>
                    {getFieldDecorator('payableAmount', {
                        rules: [
                            {
                                required: true,
                                message: getText('qsrddje'),
                            },
                        ],
                    })(
                        <InputNumber
                            placeholder={getText('qsrddje')}
                            min={0.01}
                            max={99999999.99}
                            step={1}
                            precision={2}
                            style={{ width: '200px' }}
                        ></InputNumber>
                    )}
                </Form.Item>

                <div className="form-title mt10">{getText('xxzfjylsh')}</div>
                <OrderFlowRule
                    isViewPage={isViewPage}
                    value={paymentResults}
                    onChange={this.onRulesChange}
                    subsiteId={subsiteId}
                />
                <Form.Item className="footer-btn pdt20">
                    <Button
                        disabled={sendMsgDisabled}
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 10 }}
                    >
                        {getText('zffh')}
                    </Button>
                    <Button onClick={navigation.goBack}>{getText('qx')}</Button>
                </Form.Item>
            </Form>
        );
    };

    onInputChange = (e: any) => {
        this.setState({ smsCode: e.target.value });
    };
    onCancel = () => {
        this.setState({ visible: false, smsCode: '', submitLoading: false });
    };
    onOk = () => {
        const { smsCode } = this.state;
        if (!smsCode) {
            message.error(getText('qsrdxyzm'));
            return;
        }
        this.setState({ submitLoading: true });
        const params = this.dataParser();
        api.post(
            { ...params },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/offline_payment_bill/manual_review_records`,
            }
        )
            .then(() => {
                message.success(getText('fhcg_1'));
                this.onCancel();
                setTimeout(() => {
                    navigation.goBack();
                });
            })
            .catch((error) => {
                this.setState({ submitLoading: false });
                errorHandle(error);
            });
    };
    dataParser = () => {
        const { paymentResults, smsCode } = this.state;
        const {
            form: { getFieldValue },
        } = this.props;
        const orderNo = getFieldValue('orderNo') || '';
        const subsite = getFieldValue('subsites') ? getFieldValue('subsites')[0] : {};
        const payableAmount = Number(getFieldValue('payableAmount') || 0);
        return {
            businessType: 'OFFLINE_POS_ORDER',
            outTradeNos:
                paymentResults?.map((item) => {
                    return item.outTradeNo;
                }) || [],
            paymentMode: 'CHINA_MERCHANTS_BANK_TRANS_FER_PAY',
            orderNo,
            smsCode,
            subsiteId: subsite?.id,
            subsiteCode: subsite?.code,
            subsiteName: subsite?.name,
            payableAmount,
        };
    };

    renderMessageModal = () => {
        const { visible, smsCode, submitLoading } = this.state;
        return (
            <Modal
                title={getText('dxyzmhy')}
                width={600}
                visible={visible}
                className="offline-order-modal"
                onCancel={this.onCancel}
                confirmLoading={submitLoading}
                onOk={this.onOk}
            >
                <div className="form">
                    <div className="form__item">
                        <div className="form__item-label">{getText('qsrnsddyzm')}</div>
                        <Input
                            onChange={this.onInputChange}
                            className="form__item-input"
                            value={smsCode}
                            maxLength={6}
                        ></Input>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <Spin spinning={loading}>{this.renderContent()}</Spin>
                <div>{this.renderMessageModal()}</div>
            </Fragment>
        );
    }
}
function mapStateToProps(state: OfflineOrderReviewState, props: OfflineOrderReviewProps) {
    const { entity, row, params, id } = props;
    return {
        id,
        entity,
        row,
        params,
    };
}
export const OfflineOrderReviewForm = connect(mapStateToProps)(Form.create()(OfflineOrderReview));
