import React from 'react';
import { Modal as AntModal, Modal } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

export const ForwardDataCenterModal = (target?: string) => {
    let contentBefore = React.createElement('span', null, language.getText('subsiteExportInfo'));
    let contentAfter = React.createElement('span', null, language.getText('common.view'));
    let link = React.createElement(
        'a',
        {
            onClick: () => {
                AntModal.destroyAll();
                setTimeout(() => {
                    window.open('#/report-download-center', target ? target : '_self');
                }, 100);
            },
        },
        language.getText('dataExportAndImport')
    );
    let content = React.createElement('div', {}, [contentBefore, link, contentAfter]);
    Modal.info({
        content: content,
    });
};
