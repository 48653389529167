import React, { Component } from 'react';
import { Button, message as AntMessage } from 'antd';
import {
    WechatMpnewsarticle,
    WechatMsgType,
    WechatMsg,
    WechatMiniprogrampage,
    WechatText,
    WechatImage,
} from '../../components/wechant-content';
import { WechatOfficialAutoReplySettingPageImageModal } from '../wechat-official-auto-reply-setting-page-image-modal';
import { WechatOfficialAutoReplySettingPageTextModal } from '../wechat-official-auto-reply-setting-page-text-modal';
import { WechatOfficialAutoReplySettingPageMiniprogrampageModalVisibleModal } from '../wechat-official-auto-reply-setting-page-miniprogrampage-modal';
import { WechatOfficialAutoReplySettingPageMpnewsarticleModal } from '../wechat-official-auto-reply-setting-page-mpnewsarticle-modal';
import { cloneDeep, forEach } from 'lodash';
import { WechatOfficialAutoReplySettingPageMsgItem } from '../wechat-official-auto-reply-setting-page-msg-item';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { privilege, language } = services;

interface Props {
    appId: string;
    index: number;
    hide: boolean;
    msgList: Array<WechatMsg>;
    onChange: (index: number, msgList: Array<WechatMsg>) => void;
    disabled?: boolean;
    // 只读 只进行页面展示 编辑需要跳转关注公众号回复
    readOnly?: boolean;
}

interface States {
    /**
     * 正在编辑的项
     */
    currentMsgIndex?: number;
    /**
     * 图片弹层
     */
    imageModalVisible: boolean;
    /**
     * 文本弹层
     */
    textModalVisible: boolean;
    /**
     * 小程序卡片弹层
     */
    miniprogrampageModalVisible: boolean;
    /**
     * 图文弹层
     */
    mpnewsarticleModalVisible: boolean;
}

/**
 * 回复内容模块
 */
export class WechatOfficialAutoReplySettingPageMsgList extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentMsgIndex: undefined,
            imageModalVisible: false,
            textModalVisible: false,
            miniprogrampageModalVisible: false,
            mpnewsarticleModalVisible: false,
        };
    }

    navigationAutoReplyConfig = () => {
        if (!privilege.check('followReplyMessage', 'full')) {
            AntMessage.warning(language.getText('privilegeFollowReplyMessage'));
            return;
        }
        window.open('#/wechat-official-auto-reply');
    };

    onAddContent = (type: WechatMsgType) => {
        const { msgList, readOnly } = this.props;
        if (readOnly) {
            this.navigationAutoReplyConfig();
            return;
        }
        if (msgList && msgList.length >= 3) {
            AntMessage.warning(services.language.getText('mlrqzdszhf_3'));
            return false;
        }
        if (type === WechatMsgType.MINIPROGRAMPAGE) {
            this.handleMiniprogrampageModalVisible();
        }
        if (type === WechatMsgType.TEXT) {
            this.handleTextModalVisiblel();
        }
        if (type === WechatMsgType.IMAGE) {
            this.handleImageModalVisible();
        }
        if (type === WechatMsgType.MPNEWSARTICLE) {
            //图文最多只能有一个
            const newMsgList = msgList.filter(
                (item: WechatMsg) => item.msgtype === WechatMsgType.MPNEWSARTICLE
            );
            if (newMsgList.length > 0) {
                AntMessage.warning(services.language.getText('mlrqzdszhf_1'));
                return;
            }
            this.hardelMpnewsarticleModalVisible();
        }
    };

    handleImageModalVisible = () => {
        const { imageModalVisible } = this.state;
        this.setState({
            imageModalVisible: !imageModalVisible,
        });
    };

    handleTextModalVisiblel = () => {
        const { textModalVisible } = this.state;
        this.setState({
            textModalVisible: !textModalVisible,
        });
    };

    handleMiniprogrampageModalVisible = () => {
        const { miniprogrampageModalVisible } = this.state;
        this.setState({
            miniprogrampageModalVisible: !miniprogrampageModalVisible,
        });
    };

    hardelMpnewsarticleModalVisible = () => {
        const { mpnewsarticleModalVisible } = this.state;
        this.setState({
            mpnewsarticleModalVisible: !mpnewsarticleModalVisible,
        });
    };

    imageModalConfrim = (wechatImage: WechatImage) => {
        const { index, msgList, onChange } = this.props;
        const newValue: Array<WechatMsg> = cloneDeep(msgList);
        newValue.push({
            msgtype: WechatMsgType.IMAGE,
            image: wechatImage,
        });
        onChange(index, newValue);
        this.handleImageModalVisible();
    };

    textModalConfrim = (value: WechatText) => {
        const { index, msgList, onChange } = this.props;
        const newValue: Array<WechatMsg> = cloneDeep(msgList);
        newValue.push({
            msgtype: WechatMsgType.TEXT,
            text: value,
        });
        onChange(index, newValue);
        this.handleTextModalVisiblel();
    };

    mpnewsarticleModalConfrim = (wechatMpnewsarticle: WechatMpnewsarticle) => {
        const { index, msgList, onChange } = this.props;
        const newValue: Array<WechatMsg> = cloneDeep(msgList);
        newValue.push({
            msgtype: WechatMsgType.MPNEWSARTICLE,
            /**
             * 图文传参
             */
            mpnewsarticle: wechatMpnewsarticle,
        });
        onChange(index, newValue);
        this.hardelMpnewsarticleModalVisible();
    };

    miniprogrampageModalConfrim = (miniprogrampage: WechatMiniprogrampage) => {
        const { index, msgList, onChange } = this.props;
        const newValue: Array<WechatMsg> = cloneDeep(msgList);
        newValue.push({
            msgtype: WechatMsgType.MINIPROGRAMPAGE,
            /**
             * 图文传参
             */
            miniprogrampage: miniprogrampage,
        });
        onChange(index, newValue);
        this.handleMiniprogrampageModalVisible();
    };

    renderAddButtons = () => {
        const { msgList, disabled } = this.props;
        let miniCount = 0,
            textCount = 0,
            imageCount = 0,
            newsCount = 0;
        if (msgList && msgList.length > 0) {
            forEach(msgList, (msg) => {
                if (msg.msgtype === WechatMsgType.MINIPROGRAMPAGE) {
                    miniCount = miniCount + 1;
                }
                if (msg.msgtype === WechatMsgType.TEXT) {
                    textCount = textCount + 1;
                }
                if (msg.msgtype === WechatMsgType.IMAGE) {
                    imageCount = imageCount + 1;
                }
                if (msg.msgtype === WechatMsgType.MPNEWSARTICLE) {
                    newsCount = newsCount + 1;
                }
            });
        }
        return (
            <div>
                {services.language.getText('hfnr')}：
                <Button
                    className="type-button"
                    type="default"
                    icon="plus"
                    onClick={this.onAddContent.bind(this, WechatMsgType.MINIPROGRAMPAGE)}
                    hidden={disabled}
                >
                    {services.language.getText('xcxkp')}（{miniCount}）
                </Button>
                <Button
                    className="type-button"
                    type="default"
                    icon="plus"
                    onClick={this.onAddContent.bind(this, WechatMsgType.TEXT)}
                    hidden={disabled}
                >
                    {services.language.getText('wb_1')}（{textCount}）
                </Button>
                <Button
                    className="type-button"
                    type="default"
                    icon="plus"
                    onClick={this.onAddContent.bind(this, WechatMsgType.IMAGE)}
                    hidden={disabled}
                >
                    {services.language.getText('pictures')}（{imageCount}）
                </Button>
                <Button
                    className="type-button"
                    type="default"
                    icon="plus"
                    onClick={this.onAddContent.bind(this, WechatMsgType.MPNEWSARTICLE)}
                    hidden={disabled}
                >
                    {services.language.getText('tw')}（{newsCount}）
                </Button>
            </div>
        );
    };

    onDeleteItem = (msgIndex: number) => {
        const { index, msgList, onChange } = this.props;
        const newValue = msgList.filter((item: any, i: number) => i !== msgIndex);
        onChange(index, newValue);
    };

    onChangeMsgItem = (msgIndex: number, msg: WechatMsg) => {
        const { index, msgList, onChange } = this.props;
        const newValue = msgList.map((item: any, i: number) => {
            if (i === msgIndex) {
                item = msg;
            }
            return item;
        });
        onChange(index, newValue);
    };

    renderMsgList = () => {
        const { msgList, hide, disabled, appId, readOnly } = this.props;
        return (
            !hide && (
                <div className="msg-list">
                    {msgList &&
                        msgList.map((msg, msgIndex: number) => {
                            return (
                                <WechatOfficialAutoReplySettingPageMsgItem
                                    appId={appId}
                                    key={msgIndex}
                                    msgIndex={msgIndex}
                                    msg={msg}
                                    onChange={this.onChangeMsgItem}
                                    onDelete={this.onDeleteItem}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                />
                            );
                        })}
                </div>
            )
        );
    };
    render() {
        const {
            imageModalVisible,
            textModalVisible,
            miniprogrampageModalVisible,
            mpnewsarticleModalVisible,
        } = this.state;
        const { appId } = this.props;
        return (
            <div className="wechat-official-auto-reply-setting-page-msg-list">
                {this.renderAddButtons()}
                {this.renderMsgList()}
                {/*添加编辑小卡片弹层*/}
                {miniprogrampageModalVisible && (
                    <WechatOfficialAutoReplySettingPageMiniprogrampageModalVisibleModal
                        appId={appId}
                        visible={miniprogrampageModalVisible}
                        onConfrim={this.miniprogrampageModalConfrim}
                        onCancel={this.handleMiniprogrampageModalVisible}
                    />
                )}
                {/*添加编辑文本弹层*/}
                {textModalVisible && (
                    <WechatOfficialAutoReplySettingPageTextModal
                        visible={textModalVisible}
                        onConfrim={this.textModalConfrim}
                        onCancel={this.handleTextModalVisiblel}
                        appId={appId}
                    />
                )}
                {/*添加编辑图片弹层*/}
                {imageModalVisible && (
                    <WechatOfficialAutoReplySettingPageImageModal
                        appId={appId}
                        visible={imageModalVisible}
                        onConfrim={this.imageModalConfrim}
                        onCancel={this.handleImageModalVisible}
                    />
                )}
                {/*添加图文弹层*/}
                {mpnewsarticleModalVisible && (
                    <WechatOfficialAutoReplySettingPageMpnewsarticleModal
                        appId={appId}
                        visible={mpnewsarticleModalVisible}
                        onConfrim={this.mpnewsarticleModalConfrim}
                        onCancel={this.hardelMpnewsarticleModalVisible}
                    />
                )}
            </div>
        );
    }
}
