import React, { Component } from 'react';
import { Button, InputNumber, Popover as AntPopover } from 'antd';
import { cloneDeep, remove, map, get, isArray, forEach } from 'lodash';
// import { api } from '@comall-backend-builder/core/lib/services';
import DragTable from '../drag-table';
import { ColumnProps } from 'antd/lib/table';
import './index.less';
import { services } from '@comall-backend-builder/core';

export type Reward = {
    /**
     * id
     */
    id?: number;
    /**
     * 奖品序号
     */
    sequence: number;
    /**
     * 奖品名称
     */
    name: string;
    /**
     * 奖品类型
     */
    rewardType: string;
    /**
     * 奖品图片
     */
    picture: { id: string; path: string };
    /**
     * 奖品数量
     */
    quantity: number;
    /**
     * 中奖提示语
     */
    tip: string;
    /**
     * 奖品剩余数量
     */
    inventory: number;
    /**
     * 类型
     */
    type?: any;
    /**
     * 奖励状态
     */
    status?: string;
    /**
     * 中奖概率
     */
    conditionValue?: number;
};

export interface RewardSettingDisplayProps {
    data: Array<Reward>;
    onChange: (data: Array<Reward>) => void;
    disabled?: boolean;
    activityId?: number;
    eventType?: string;
    onEdit?(value: Reward): void;
    onView?(value: Reward): void;
    executeStatus: string;
    /**
     * 当前活动是否开启
     */
    isOpen?: boolean;
    /**
     * 当前活动时间是否已到
     */
    isBegin?: boolean;
}

/*
 *事件类型 LICKED-被点赞|COMMENT-被评论|FAVORITES-被收藏|WHEEL_DRAW-大转盘|BIRTHDAY_CARING-生日礼|LOTTERY_CODE=抽奖码|SCRATCH_CARD=刮刮卡|RED_PACKAGE_RAIN=红包雨｜SLOT_MACHINE-老虎机
 */
enum EventType {
    /**
     * 大转盘
     */
    WHEEL_DRAW = 'WHEEL_DRAW',
    /**
     * 刮刮卡
     */
    SCRATCH_CARD = 'SCRATCH_CARD',
    /**
     * 红包雨
     */
    RED_PACKAGE_RAIN = 'RED_PACKAGE_RAIN',
    /**
     * 老虎机
     */
    SLOT_MACHINE = 'SLOT_MACHINE',
    /**
     * 商城活动
     */
    MALL_ACTIVITY = 'MALL_ACTIVITY',
    /**
     * 开盒有礼
     */
    OPEN_BOX = 'OPEN_BOX',
}

export class RewardSettingDisplay extends Component<RewardSettingDisplayProps> {
    actionColumnFixed = false;
    onChange = (data: Array<Reward>) => {
        const { onChange } = this.props;
        data = data.map((item: any, idx: number) => ({ ...item, sequence: idx + 1 }));
        this.setState({ value: data });
        onChange && onChange(data);
    };

    handleEdit = (row: Reward) => {
        const { onEdit } = this.props;
        onEdit && onEdit(row);
    };

    handleView = (row: Reward) => {
        const { onView } = this.props;
        onView?.(row);
    };

    onRemove = (sequence: number) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => sequence === i.sequence);
            onChange && onChange(result);
        };
    };

    onDisabledRewardItem = (rewardItemId: number | undefined) => {
        const { eventType, data, onChange } = this.props;
        return () => {
            if (
                eventType === EventType.SCRATCH_CARD ||
                eventType === EventType.WHEEL_DRAW ||
                eventType === EventType.OPEN_BOX
            ) {
                const result = cloneDeep(data);
                let DisabledItem: any = {};
                let resultList: any = [];
                let sequence = 1;
                for (var i = 0; i < result.length; i++) {
                    const item = result[i];
                    if (rewardItemId === item.id) {
                        item.sequence = result.length - 1;
                        item.status = 'DISABLED';
                        DisabledItem = item;
                    } else {
                        item.sequence = sequence;
                        resultList.push(item);
                        sequence++;
                    }
                    if (i === result.length - 1) {
                        resultList.push(DisabledItem);
                    }
                }
                onChange && onChange(resultList);
            }
        };
    };

    onEnabledRewardItem = (rewardItemId: number) => {
        const { eventType, data, onChange } = this.props;
        return () => {
            if (
                eventType === EventType.SCRATCH_CARD ||
                eventType === EventType.WHEEL_DRAW ||
                eventType === EventType.OPEN_BOX
            ) {
                const result = cloneDeep(data);
                let resultList: any = [];
                for (var i = 0; i < result.length; i++) {
                    const item = result[i];
                    if (rewardItemId === item.id) {
                        item.status = 'ENABLED';
                        resultList.push(item);
                    } else {
                        resultList.push(item);
                    }
                }
                onChange && onChange(resultList);
            }
        };
    };

    changeConditionValue = (record: Reward, value: number) => {
        const { data, onChange } = this.props;
        if (!data) return;
        const newData = cloneDeep(data);
        newData.forEach((r) => {
            if (r.sequence === record.sequence) {
                r.conditionValue = value;
            }
        });
        onChange && onChange(newData);
    };

    private getDisplayColumns() {
        const { eventType, executeStatus } = this.props;
        const columns: ColumnProps<unknown>[] = [];
        if (eventType !== EventType.MALL_ACTIVITY) {
            columns.push({
                title: services.language.getText('jppx'),
                dataIndex: 'sequence',
                key: 'sequence',
            });
        }
        columns.push({
            title: services.language.getText('jpmc'),
            dataIndex: 'name',
            key: 'name',
        });
        if (
            !eventType ||
            !([
                // EventType.SCRATCH_CARD,
                EventType.SLOT_MACHINE,
                EventType.MALL_ACTIVITY,
            ] as string[]).includes(eventType)
        ) {
            columns.push({
                title: services.language.getText('zjgl_1'),
                dataIndex: 'conditionValue',
                key: 'conditionValue',
                width: 80,
                render: (val: any, data: any) => {
                    const { disabled } = this.props;
                    if (
                        !disabled &&
                        eventType &&
                        ([
                            EventType.WHEEL_DRAW,
                            EventType.SCRATCH_CARD,
                            EventType.OPEN_BOX,
                            EventType.RED_PACKAGE_RAIN,
                        ] as string[]).includes(eventType)
                    ) {
                        return (
                            <div>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={val}
                                    min={0}
                                    precision={2}
                                    max={100}
                                    onChange={(value: any) =>
                                        this.changeConditionValue(data, value)
                                    }
                                ></InputNumber>
                            </div>
                        );
                    }
                    return <div>{val ? val + '%' : ''}</div>;
                },
            });
        }
        columns.push({
            title: services.language.getText('jplx'),
            dataIndex: 'rewardType',
            key: 'rewardType',
        });
        if (eventType !== EventType.MALL_ACTIVITY) {
            columns.push(
                {
                    title: services.language.getText('pictures'),
                    dataIndex: 'picture',
                    key: 'picture',
                    width: 180,
                    render: (picture: any) => {
                        return <img className="right-icon-img" src={picture.path} alt=""></img>;
                    },
                },
                {
                    title: services.language.getText('zsl'),
                    dataIndex: 'quantity',
                    key: 'quantity',
                    render: (val: any, data: any) => {
                        return (
                            <div>
                                {data.quantityType === 'NO'
                                    ? services.language.getText('bxz')
                                    : data.quantity
                                    ? data.quantity
                                    : services.language.getText('bxz')}
                            </div>
                        );
                    },
                }
            );
        }
        if (executeStatus) {
            columns.push(
                {
                    title: services.language.getText('zjsl'),
                    dataIndex: 'issuedCount',
                    key: 'issuedCount',
                    render: (issuedCount: any) => {
                        return <span>{issuedCount ? issuedCount : 0}</span>;
                    },
                },
                {
                    title: services.language.getText('sysl'),
                    dataIndex: 'inventory',
                    key: 'inventory',
                    render: (val: any, data: any) => {
                        return <div>{data.quantityType === 'NO' ? '' : isNaN(val) ? '' : val}</div>;
                    },
                }
            );
        }
        if (
            !eventType ||
            !([
                // EventType.SCRATCH_CARD,
                EventType.SLOT_MACHINE,
                EventType.MALL_ACTIVITY,
            ] as string[]).includes(eventType)
        ) {
            if (eventType !== EventType.RED_PACKAGE_RAIN) {
                columns.push(
                    {
                        title: services.language.getText('mrfjsl'),
                        dataIndex: 'periodQuantity',
                        key: 'periodQuantity',
                        width: 80,
                        render: (val: any, data: any) => {
                            return (
                                <div>
                                    {data?.rewardPeriod?.periodQuantityType === 'YES'
                                        ? data?.rewardPeriod?.periodQuantity
                                        : services.language.getText('bxz')}
                                </div>
                            );
                        },
                    },
                    {
                        title: services.language.getText('myhkzjsl'),
                        dataIndex: 'memberRewardLimit',
                        key: 'memberRewardLimit',
                        width: 80,
                        render: (val: any, data: any) => {
                            return (
                                <div>
                                    {data?.rewardPeriod?.memberRewardLimitType === 'YES'
                                        ? data?.rewardPeriod?.memberRewardLimit
                                        : services.language.getText('bxz')}
                                </div>
                            );
                        },
                    }
                );
            }
            if (eventType !== EventType.OPEN_BOX) {
                columns.push({
                    title: services.language.getText('zjtsy'),
                    dataIndex: 'tip',
                    key: 'tip',
                    width: 180,
                });
            }
        }
        return columns;
    }

    private getActionColumn() {
        const { disabled, eventType } = this.props;
        if (
            eventType === EventType.SCRATCH_CARD ||
            eventType === EventType.WHEEL_DRAW ||
            eventType === EventType.OPEN_BOX
        ) {
            return this.getScratchCardActionColumn();
        }
        if (eventType === EventType.RED_PACKAGE_RAIN) {
            return this.getRedPackageRainActionColumn();
        }
        if (eventType === EventType.SLOT_MACHINE) {
            return this.getSlotMachineActionColumn();
        }
        if (eventType === EventType.MALL_ACTIVITY) {
            return this.getMallActivityActionColumn();
        }
        return {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: 220,
            render: (i: any, row: any) => {
                return (
                    <div className="operation-wrap" style={{ height: '75px' }}>
                        <Button
                            type="link"
                            onClick={() => this.handleEdit(row)}
                            disabled={disabled}
                        >
                            {services.language.getText('common.edit')}
                        </Button>

                        {!row.id && (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row.sequence)}
                                disabled={disabled}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        )}
                        <Button
                            type="link"
                            style={{ paddingLeft: 0 }}
                            onClick={() => this.handleView(row)}
                        >
                            {services.language.getText('common.view')}
                        </Button>
                    </div>
                );
            },
        };
    }

    private getMallActivityActionColumn() {
        const { isBegin, isOpen } = this.props;
        return {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: 220,
            fixed: 'right',
            render: (i: any, row: any) => {
                return (
                    <div className="operation-wrap">
                        <Button type="link" disabled={isBegin} onClick={() => this.handleEdit(row)}>
                            {services.language.getText('common.edit')}
                        </Button>

                        {!row.id || (row.id && !isBegin && !isOpen) ? (
                            <Button type={'link'} onClick={this.onRemove(row.sequence)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        };
    }

    private getSlotMachineActionColumn() {
        const { isBegin, isOpen } = this.props;
        return {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: 220,
            fixed: 'right',
            render: (i: any, row: any) => {
                if (!row.oldQuantity) {
                    row.oldQuantity = row.quantity;
                }
                if (!row.oldInventory) {
                    row.oldInventory = row.inventory;
                }
                return (
                    <div className="operation-wrap" style={{ height: '75px' }}>
                        <Button type="link" disabled={isBegin} onClick={() => this.handleEdit(row)}>
                            {services.language.getText('common.edit')}
                        </Button>

                        {!row.id || (row.id && !isBegin && !isOpen) ? (
                            <Button type={'link'} onClick={this.onRemove(row.sequence)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        };
    }

    private getRedPackageRainActionColumn() {
        const { disabled, executeStatus } = this.props;
        let pageStatus = false;
        const url = window.location.href;
        if (url && url.indexOf('market/red-package-rain/copy/')) {
            pageStatus = true;
        }
        return {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: 220,
            fixed: 'right',
            render: (i: any, row: any) => {
                return (
                    <div className="operation-wrap" style={{ height: '75px' }}>
                        <Button
                            type="link"
                            onClick={() => this.handleEdit(row)}
                            disabled={disabled}
                        >
                            {services.language.getText('common.edit')}
                        </Button>
                        {!row.id || pageStatus || executeStatus !== 'COMPLETE' ? (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row.sequence)}
                                disabled={disabled}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : (
                            <span className="delete">
                                {services.language.getText('common.delete')}
                            </span>
                        )}
                        <Button
                            type="link"
                            style={{ paddingLeft: 0 }}
                            onClick={() => this.handleView(row)}
                        >
                            {services.language.getText('common.view')}
                        </Button>
                    </div>
                );
            },
        };
    }

    private getScratchCardActionColumn() {
        const { disabled, executeStatus } = this.props;
        return {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            fixed: 'right',
            render: (i: any, row: any) => {
                return (
                    <AntPopover
                        placement="bottomRight"
                        trigger="click"
                        content={
                            <div className="operation-wrap">
                                <div>
                                    <Button
                                        type="link"
                                        onClick={() => this.handleEdit(row)}
                                        disabled={disabled}
                                    >
                                        {services.language.getText('common.edit')}
                                    </Button>
                                </div>

                                <div>
                                    {!row.id || executeStatus === 'NOT_STARTED' ? (
                                        <Button
                                            type={'link'}
                                            onClick={this.onRemove(row.sequence)}
                                            disabled={disabled}
                                        >
                                            {services.language.getText('common.delete')}
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <div>
                                    {row.id > 0 &&
                                    row.status === 'DISABLED' &&
                                    executeStatus !== 'NOT_STARTED' ? (
                                        <Button
                                            type={'link'}
                                            onClick={this.onEnabledRewardItem(row.id)}
                                            disabled={disabled}
                                        >
                                            {services.language.getText('enable')}
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                    {row.id > 0 &&
                                    row.status === 'ENABLED' &&
                                    executeStatus !== 'NOT_STARTED' ? (
                                        <Button
                                            type={'link'}
                                            onClick={this.onDisabledRewardItem(row.id)}
                                            disabled={disabled}
                                        >
                                            {services.language.getText('disabled')}
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div>
                                    <Button type="link" onClick={() => this.handleView(row)}>
                                        {services.language.getText('common.view')}
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <span style={{ color: '#1890ff', cursor: 'pointer' }}>
                            {services.language.getText('common.more')}
                        </span>
                    </AntPopover>
                );
            },
        };
    }

    private getTableColumns() {
        return [...this.getDisplayColumns(), this.getActionColumn()].filter(Boolean);
    }
    //奖品列表table
    getTableConfig = () => {
        let config: any = {
            rowKey: 'sequence',
            columns: this.getTableColumns(),
            scroll: { x: true },
        };
        return config;
    };

    renderTip = () => {
        const { eventType, data } = this.props;
        if (
            eventType !== EventType.WHEEL_DRAW &&
            eventType !== EventType.SCRATCH_CARD &&
            eventType !== EventType.OPEN_BOX &&
            eventType !== EventType.RED_PACKAGE_RAIN
        ) {
            return null;
        }
        if (!data || !isArray(data) || data.length <= 0) {
            return null;
        }
        let conditionValue = 0;
        forEach(data, (item: any) => {
            if (item.status === 'ENABLED' && item.conditionValue) {
                conditionValue += parseFloat(item.conditionValue);
            }
        });
        let tip = '';
        if (Number(conditionValue.toFixed(2)) > 100) {
            tip = `${services.language.getText('jpglzhbdcg')}${(
                Number(conditionValue.toFixed(2)) - 100
            ).toFixed(2)}%`;
        } else {
            tip = `${services.language.getText('jpglzh')}${Number(
                conditionValue.toFixed(2)
            )}%，${services.language.getText('syzjglw')}${(
                100 - Number(conditionValue.toFixed(2))
            ).toFixed(2)}%`;
        }

        return (
            <div style={{ color: 'red' }}>
                <span>{tip}</span>
            </div>
        );
    };

    render() {
        const { data, eventType } = this.props;
        const rewardTypes = {
            COUPON: services.language.getText('yhq'),
            POINT: services.language.getText('point'),
            NONE: services.language.getText('wzj'),
            CUSTOM: services.language.getText('custom'),
        };
        let pageStatus = false;
        const url = window.location.href;
        if (url && url.indexOf('market/slot-machines/edit/')) {
            pageStatus = true;
        }
        if (eventType === EventType.SLOT_MACHINE && pageStatus) {
            data.map((item: any) => {
                if (item.oldInventory && item.oldQuantity && item.oldQuantity < item.quantity) {
                    item.inventory = item.oldInventory + (item.quantity - item.oldQuantity);
                }
                return item;
            });
        }

        let newData: any;
        newData = map(data, (item: any) => ({
            ...item,
            rewardType: get(rewardTypes, item.type.checked, ''),
        }));
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: newData };
        return (
            <div className="reward-setting-display">
                <DragTable {...tableProps} onUpdateData={this.onChange} pagination={false} />
                {this.renderTip()}
            </div>
        );
    }
}
