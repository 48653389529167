import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { internalPurchaseActivityId } from '../../containers/internal-purchase/goods-manage-button';

const api = services.api;

export const InternalPurchaseGoodsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        let paramsFilter = config.paramsFilter;
        let tagsFilter = paramsFilter && paramsFilter.length > 0 && paramsFilter[0] === 'tags';
        if (tagsFilter) {
            delete config.paramsFilter;
            let activityId = data.activityId ? data.activityId : internalPurchaseActivityId;
            if (activityId) {
                config.apiPath = '/admin/internal_purchase_goods/' + activityId + '/tags';
            }
        } else if (parseInt(data.activityId) > 0) {
            config.apiPath = '/admin/internal_purchase_goods/' + data.activityId;
            delete data.activityId;
        } else if (data.id) {
            config.apiPath = '/admin/internal_purchase_goods/' + data.id;
        } else {
            config.apiPath = '/admin/internal_purchase_goods/page';
            if (internalPurchaseActivityId > 0) {
                data.activityId = internalPurchaseActivityId;
            }
        }

        if (config.apiPath === '/admin/internal_purchase_goods') return;

        const res: any = await api.get(data, config);

        res?.result?.forEach((item: any) => {
            item.barcode = item.code;
        });

        return res;
    },
    post: async function(params: any, config: any) {
        params.activityId = internalPurchaseActivityId;

        return await api.post(params, config);
    },
    put: async function(params: any, config: any) {
        config.apiPath = '/admin/internal_purchase_goods/' + params.id;
        params.tagIds = params.tagIds.map((item: any) => item.id);
        return await api.put(params, config);
    },
    delete: async function(params: any, config: any) {
        let ids = '';
        if (params.id) {
            ids = params.id;
        } else {
            ids = params.ids.join(',');
        }
        config.apiPath = `/admin/internal_purchase_goods?ids=${ids}`;
        return await api.delete(params, config);
    },
};
