import builder, { actions } from '@comall-backend-builder/core';
import { ComponentsManager, Entity } from '@comall-backend-builder/core/lib/parser';
import { Button as AntButton, message as AntMessage, Popover } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { debounce, defaults, get, mapValues } from 'lodash';
import React, { createElement, PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import './index.less';

interface WechatOfficialAccountConfigFormProps extends FormComponentProps {
    id: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}
class WechatOfficialAccountConfigForm extends PureComponent<WechatOfficialAccountConfigFormProps> {
    static defaultProps: any = {};

    componentWillUnmount() {
        this.props.unmountComponent();
    }

    private renderForm = () => {
        const { entity, onSubmit, wrappedComponentRef, id = '' } = this.props;
        const fields = [
            { property: 'subsites' },
            { property: 'headTitle' },
            { property: 'applyPages' },
            {
                property: 'topicPages',
                visible: (values: any) =>
                    get(values, 'applyPages') && get(values, 'applyPages').includes('TOPIC_PAGE'),
            },
            { property: 'backgroundPictureUrl' },
            { property: 'title' },
            { property: 'description' },
            { property: 'id', className: 'hide' },
            { property: 'widgetId', className: 'hide' },
            { property: 'qrCodeUrl', className: 'hide' },
        ];

        const formProps = {
            entity,
            componentId: 'WechatOfficialAccountConfigFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            // style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            width: 600,
            fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () => {
                    !id && window.location.reload();
                });
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    };

    render(): React.ReactNode {
        return (
            <div>
                <div className="wechat-official-account-config-form-hint">
                    {services.language.getText('tzzzcgzhewmy')}
                    <Popover
                        content={
                            <img
                                className="wechat-official-account-config-form-popup"
                                src="./images/editor/wechat-official-account-popup.png"
                                alt=""
                            />
                        }
                        placement="bottomRight"
                    >
                        <AntButton size="small" type="link">
                            悬浮窗样式
                        </AntButton>
                    </Popover>
                </div>
                {this.renderForm()}
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;

    let id = get(
        _dispatch,
        'components.DataFormPlus-WechatOfficialAccountConfigFormContainer.fields.id'
    );
    // let styleMode = get(
    //     _dispatch,
    //     'components.DataFormPlus-WechatOfficialAccountConfigFormContainer.fields.themeStyle.styleMode'
    // );
    let form: any;
    return defaults(
        {
            onSubmit,
            onFieldChange,
            id,
            // styleMode,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    entity.modify(entityFields, params, { route: 'goBack' });
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('WechatOfficialAccountConfigFormContainer')
                    );
            },
        }
    );
}

export const WechatOfficialAccountConfigFormView = connect(mapDispatchToProps)(
    WechatOfficialAccountConfigForm
);
