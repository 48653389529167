// 配送方式 EXPRESS=快递|CITYWIDE=同城配|PICK_UP=自提|NONE=其他
export enum DeliveryMode {
    EXPRESS = 'EXPRESS',
    CITYWIDE = 'CITYWIDE',
    PICK_UP = 'PICK_UP',
    NONE = 'NONE',
}

/**
 * 物流生成方式 AUTOMATIC：自动叫单  MANUAL：手动填单
 */
export enum LogisticsMethod {
    AUTOMATIC = 'AUTOMATIC',
    MANUAL = 'MANUAL',
}

/**
 * 发货表单
 */
export interface DeliveryFormData {
    // 选中的物流公司
    companyId: string;
    companyAccount: string;
    // 门店下的配送物流公司唯一标识
    deliveryLogisticsCompanyId: string;
    // 物流单号
    expressNumber: string;
    // 服务类型
    serviceType: string;
    // 是否需要保价
    insured: boolean;
    // 是否预约取件
    pickup: boolean;
    // 预约取件可选时间
    pickupTimeRange: {
        [key: string]: {
            value: string;
            name: string;
            date: string;
            timeRanges: string[];
        };
    };
    // 预约取件日期
    selectedPickupDay: string;
    // 预约取件时间段
    selectedPickupTimeRange: string;
    // 备注
    remark: string;
    // 服务类型
    serviceTypes: string[];
}

// 默认的表单数据
export const DefaultFormData: DeliveryFormData = {
    // 选中的物流公司
    companyId: '',
    companyAccount: '',
    // 门店下的配送物流公司唯一标识
    deliveryLogisticsCompanyId: '',
    // 物流单号
    expressNumber: '',
    // 服务类型
    serviceType: '',
    // 是否需要保价
    insured: false,
    // 是否预约取件
    pickup: false,
    // 预约取件可选时间
    pickupTimeRange: {
        today: {
            value: 'today',
            name: '今天',
            date: '',
            timeRanges: [],
        },
        tomorrow: {
            value: 'tomorrow',
            name: '明天',
            date: '',
            timeRanges: [],
        },
        afterTomorrow: {
            value: 'afterTomorrow',
            name: '后天',
            date: '',
            timeRanges: [],
        },
    },
    // 预约取件日期
    selectedPickupDay: 'today',
    // 预约取件时间段
    selectedPickupTimeRange: '',
    // 备注
    remark: '',
    // 服务类型
    serviceTypes: [],
};
