import React, { PureComponent, createElement, Fragment } from 'react';
import {
    ComponentsManager,
    services,
    Loader,
    builder,
    actions,
} from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal } from 'antd';
import { mapValues } from 'lodash';

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields };
}

export const ExpressCompanySettingModalForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('ModifyForm'), {
        ...props,
        componentId: `ExpressCompanySettingModalForm-${props.row.id}`,
        style: {
            width: '500px',
        },
        labelCol: 7,
        fields: [
            {
                property: 'subsiteCode',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
            {
                property: 'subsiteName',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
            {
                property: 'code',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
            {
                property: 'name',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
        ],
        onSubmit: (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            const companyId = props.params && props.params.companyId;
            const id = props.row.id;
            const params = {
                subsiteCode: entityFields['subsiteCode'],
                subsiteName: entityFields['subsiteName'],
                code: entityFields['code'],
                name: entityFields['name'],
            };
            Loader.load('put', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: `/admin/express_companies/${companyId}/subsite_configs/${id}`,
            })
                .then(() => {
                    props.onClose();
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success('操作成功', 1.5);
                })
                .catch(services.errorHandle);
        },
    });
});

export const ExpressCompanyCreateModalForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('CreateForm'), {
        ...props,
        componentId: `ExpressCompanyCreateModalForm`,
        style: {
            width: '500px',
        },
        labelCol: 7,
        fields: [
            {
                property: 'subsiteCode',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
            {
                property: 'subsiteName',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
            {
                property: 'code',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
            {
                property: 'name',
                rules: [{ required: true }],
                controlConfig: {
                    style: { width: 300 },
                    maxLength: 20,
                },
            },
        ],
        onSubmit: (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            const companyId = props.params && props.params.companyId;
            const params = {
                subsiteCode: entityFields['subsiteCode'],
                subsiteName: entityFields['subsiteName'],
                code: entityFields['code'],
                name: entityFields['name'],
            };
            Loader.load('post', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: `/admin/express_companies/${companyId}/subsite_configs`,
            })
                .then(() => {
                    props.onClose();
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success('操作成功', 1.5);
                    let { fields: entityFields } = entity;
                    const loaded = !!entityFields;
                    builder.getStore().dispatch(
                        actions.formInitAction('ExpressCompanyCreateModalForm', {
                            type: 'EditForm',
                            entityId: entity.id,
                            fields: { subsiteCode: '', subsiteName: '', code: '', name: '' },
                            loaded,
                        })
                    );
                })
                .catch(services.errorHandle);
        },
    });
});

export class ExpressCompanySettingModal extends PureComponent<any> {
    state = { visible: false };
    openModal = () => {
        this.setState({ visible: true });
        const { edit } = this.props;
        if (edit) {
            const { entity, row } = this.props;
            entity &&
                entity.setFields({
                    ...row,
                });
        }
    };

    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
    };
    render() {
        const { edit, triggerComponent } = this.props;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };
        const title = !!edit ? '编辑门店' : '新增门店';
        return (
            <Fragment>
                {createElement(ComponentsManager.get('Button'), buttonProps)}
                <Modal
                    visible={this.state.visible}
                    title={title}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="505px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        const { entity, row, edit } = this.props;
                        let { fields: entityFields } = entity;
                        const loaded = !!entityFields;
                        if (!!edit) {
                            builder.getStore().dispatch(
                                actions.formInitAction(`ExpressCompanySettingModalForm-${row.id}`, {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: {
                                        ...row,
                                    },
                                    loaded,
                                })
                            );
                        } else {
                            builder.getStore().dispatch(
                                actions.formInitAction('ExpressCompanyCreateModalForm', {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: {
                                        subsiteCode: '',
                                        subsiteName: '',
                                        code: '',
                                        name: '',
                                    },
                                    loaded,
                                })
                            );
                        }

                        this.setState({ visible: false });
                    }}
                >
                    {edit ? (
                        <ExpressCompanySettingModalForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    ) : (
                        <ExpressCompanyCreateModalForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    )}
                </Modal>
            </Fragment>
        );
    }
}
