import React, { PureComponent, createElement, Fragment } from 'react';

import {
    ComponentsManager,
    services,
    Loader,
    builder,
    actions,
} from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal } from 'antd';
import { mapValues } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields };
}

export const ModalEditMerchantFacilityTagForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('ModifyForm'), {
        ...props,
        componentId: `ModalEditMerchantFacilityTagForm-${props.row.id}`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [
            {
                property: 'name',
                controlConfig: {
                    style: { width: 220 },
                    maxLength: 6,
                },
            },
            { property: 'id', className: 'hidden' },
            {
                property: 'sequence',
                extra: language.getText('tagToFacility'),
                controlConfig: {
                    style: { width: 220 },
                    placeholder: language.getText('sortTip'),
                    max: 99999,
                    min: 0,
                },
            },
            {
                property: 'subsiteIds',
                controlConfig: {
                    style: { width: 220 },
                    placeholder: language.getText('selectSubsite'),
                },
            },
        ],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            const id = entityFields['id'];
            const params = {
                name: entityFields['name'],
                sequence: entityFields['sequence'],
                subsite: entityFields['subsiteIds'],
            };
            Loader.load('put', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/facility_tag/${id}`,
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(language.getText('actionSuccess'), 1.5);
                })
                .catch(services.errorHandle);
        },
    });
});

export const ModalCreateMerchantFacilityTagForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('CreateForm'), {
        ...props,
        componentId: `ModalCreateMerchantFacilityTagForm`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [
            {
                property: 'name',
                extra: language.getText('tagToFacility'),
                controlConfig: {
                    style: { width: 220 },
                    maxLength: 6,
                },
            },
            {
                property: 'sequence',
                controlConfig: {
                    style: { width: 220 },
                    placeholder: language.getText('sortTip'),
                    max: 99999,
                    min: 0,
                },
            },
            {
                property: 'subsiteIds',
                controlConfig: {
                    style: { width: 220 },
                    placeholder: language.getText('selectSubsite'),
                },
            },
        ],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            const params = {
                name: entityFields['name'],
                sequence: entityFields['sequence'],
                subsite: entityFields['subsiteIds'],
            };
            Loader.load('post', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/facility_tag',
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(language.getText('actionSuccess'), 1.5);

                    let { fields: entityFields } = entity;
                    const loaded = !!entityFields;
                    builder.getStore().dispatch(
                        actions.formInitAction('ModalCreateMerchantFacilityTagForm', {
                            type: 'EditForm',
                            entityId: entity.id,
                            fields: { name: '', sequence: '', subsite: [] },
                            loaded,
                        })
                    );
                })
                .catch(services.errorHandle);
        },
    });
});

export class SetMerchantFacilityTagModal extends PureComponent<any> {
    state = { visible: false };
    openModal = () => {
        this.setState({ visible: true });
        const { edit } = this.props;
        if (edit) {
            const { entity, row } = this.props;
            entity &&
                entity.setFields({
                    ...row,
                    subsiteIds: row.subsites,
                });
        }
    };

    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
    };
    render() {
        const { edit, triggerComponent } = this.props;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };
        const title = !!edit ? language.getText('editTag') : language.getText('addTags');
        return (
            <Fragment>
                {createElement(ComponentsManager.get('Button'), buttonProps)}
                <Modal
                    visible={this.state.visible}
                    title={title}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="580px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        const { entity, row, edit } = this.props;
                        let { fields: entityFields } = entity;
                        const loaded = !!entityFields;
                        if (!!edit) {
                            builder.getStore().dispatch(
                                actions.formInitAction(
                                    `ModalEditMerchantFacilityTagForm-${row.id}`,
                                    {
                                        type: 'EditForm',
                                        entityId: entity.id,
                                        fields: { ...row, subsiteIds: row.subsites },
                                        loaded,
                                    }
                                )
                            );
                        } else {
                            builder.getStore().dispatch(
                                actions.formInitAction('ModalCreateMerchantFacilityTagForm', {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: { name: '', sequence: '', subsiteIds: [] },
                                    loaded,
                                })
                            );
                        }

                        this.setState({ visible: false });
                    }}
                >
                    {edit ? (
                        <ModalEditMerchantFacilityTagForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    ) : (
                        <ModalCreateMerchantFacilityTagForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    )}
                </Modal>
            </Fragment>
        );
    }
}
