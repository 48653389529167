import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../../../services';
const api = services.api;

const CouponMerchantTableItem =
    ENV.TENANT === 'wfj'
        ? {
              component: 'PageTip',
              props: {
                  tips: ['<<dhxddsjzzc>>'],
                  style: {
                      width: '100%',
                      height: '380px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                  },
                  tipStyle: {
                      fontSize: '14px',
                  },
              },
          }
        : {
              component: 'DataTable',
              scroll: {
                  x: 1500,
              },
              loadFirstPage: true,
              pagination: {
                  pageSize: 10,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '15', '20'],
                  showTotal(total: number) {
                      return services.interpolate(services.language.getText('total'), {
                          total,
                      });
                  },
              },
              columns: [
                  {
                      property: 'couponName',
                      width: 180,
                  },
                  {
                      property: 'couponValue',
                  },
                  {
                      property: 'subsiteCode',
                  },
                  {
                      property: 'subSiteName',
                      width: 200,
                  },
                  {
                      property: 'merchantName',
                      width: 200,
                  },
                  {
                      property: 'merchantType',
                  },
                  {
                      property: 'quantity',
                  },
                  {
                      property: 'consumeNum',
                  },
                  {
                      property: 'totalCostPrice',
                  },
                  {
                      property: 'totalPoint',
                  },
                  {
                      property: 'totalCouponValue',
                  },
              ],
              actionColumn: {},
          };
const CouponMerchantTableSubmit =
    ENV.TENANT === 'wfj'
        ? {}
        : {
              submit: {
                  text: '<<common.search>>',
              },
              reset: {
                  text: '<<common.reset>>',
              },
          };

export const config: Config = {
    entities: {
        CouponMerchantEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupon_reports/coupon_merchant',
            filters: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<hxmdbm>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<hxmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectSubsite>>', style: { width: 240 } },
                },
                merchantId: {
                    displayName: '<<hxzj>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<pleaseSelectMerchant>>',
                        style: { width: 240 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hxsj>>',
                },
            },
            properties: {
                couponName: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                couponValue: {
                    type: 'string',
                    displayName: '<<mz_1>>',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<hxmdbm>>',
                },
                subSiteName: {
                    type: 'string',
                    displayName: '<<hxmdmc>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<hxzjmc>>',
                },
                merchantType: {
                    type: 'string',
                    displayName: '<<jyfs>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<hxzs>>',
                },
                consumeNum: {
                    type: 'string',
                    displayName: '<<hxrs>>',
                },
                totalCostPrice: {
                    type: 'string',
                    displayName: '<<hxbj>>',
                },
                totalPoint: {
                    type: 'string',
                    displayName: '<<hxjf>>',
                },
                totalCouponValue: {
                    type: 'string',
                    displayName: '<<hxzmz>>',
                },
            },
        },
    },
    components: {
        CouponMerchantView: {
            component: 'Viewport',
            entity: 'CouponMerchantEntity',
        },
        CouponMerchantPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponMerchantFilter',
                },
                { component: 'CouponMerchantTable' },
            ],
        },
        CouponMerchantFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            ...CouponMerchantTableSubmit,
            fields: [
                {
                    property: 'subsiteCode',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrhxmdbm>>',
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
            ],
        },
        CouponMerchantTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },

                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters,
                                {
                                    exchangeStatus: entityButtonProps.entity.filters
                                        ? (entityButtonProps.entity.filters as any).redeemStatus
                                        : undefined,
                                    redeemStatus: undefined,
                                }
                            );
                            if (params.dateRange) {
                                params.startTime = `${params.dateRange.start} 00:00:00`;
                                params.endTime = `${params.dateRange.end} 23:59:59`;
                                delete params.dateRange;
                            }
                            if (params.subsiteId) {
                                params.subsiteId = params.subsiteId.id;
                            }
                            if (params.merchantId) {
                                params.merchantId = params.merchantId.id;
                            }
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/coupon_reports/coupon_merchant/download',
                            };

                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                CouponMerchantTableItem,
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-merchant-report',
                    component: 'CouponMerchantView',
                    breadcrumbName: '<<couponMerchantReport>>',
                    privilege: {
                        path: 'couponMerchantReport',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponMerchantPage' }],
                },
            ],
        },
    ],
};
