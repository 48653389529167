import React, { Component } from 'react';
import { Button, Form, Input, message as AntMessage, Modal, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { find, forEach } from 'lodash';
import { errorHandle } from '@comall-backend-builder/core/lib/services';

interface Props {
    appId: string;
    defalutTemplateId?: string;
    onChange: (value: any) => void;
}

interface WechatTemplate {
    templateId: string;
    title: string;
}

interface States {
    visible: boolean;
    templateId?: string;
    datasourceResult: WechatTemplate[];
    datasource: WechatTemplate[];
    searchName: string;
}
const { api } = services;
export class WechatTemplatesChooseModal extends Component<Props, States> {
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            datasourceResult: [],
            datasource: [],
            searchName: '',
        };
    }

    onSearch = async () => {
        const { datasourceResult, searchName } = this.state;
        //模糊查询检索
        const newDatasource: any[] = [];
        if (datasourceResult && datasourceResult.length > 0) {
            forEach(datasourceResult, (item) => {
                if (item.title.includes(searchName)) {
                    newDatasource.push(item);
                }
            });
        }
        this.setState({
            datasource: newDatasource,
        });
    };

    onChangeSearchName = (name: string) => {
        this.setState({
            searchName: name,
        });
    };

    OK = () => {
        const { onChange, appId } = this.props;
        const { templateId, datasourceResult } = this.state;
        if (!templateId) {
            AntMessage.warning(services.language.getText('qxzygmb'));
            return false;
        }
        const template = find(datasourceResult, { templateId: templateId });
        if (template) {
            //走微信验证，模板是否是错误模板
            api.get(
                {
                    appId: appId,
                    templateId: templateId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: `/admin/wechat_official_account_templates/by_template_id`,
                }
            )
                .then((res) => {
                    onChange(res);
                    this.hardleVisible();
                })
                .catch((error) => {
                    errorHandle(error);
                });
        }
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        this.setState({ templateId: selected ? record.templateId : undefined });
    };

    renderList = () => {
        const { datasource, templateId } = this.state;
        return datasource && datasource.length > 0 ? (
            <Table
                scroll={{ y: 330 }}
                rowKey={(record: any) => record.templateId}
                pagination={false}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: templateId ? [templateId] : undefined,
                    onSelect: (record: any, selected: boolean) => {
                        this.onTableRowSelect(record, selected);
                    },
                }}
                columns={[
                    {
                        title: services.language.getText('mbbt'),
                        dataIndex: 'title',
                        key: 'title',
                    },
                    {
                        title: services.language.getText('templateId'),
                        dataIndex: 'templateId',
                        key: 'templateId',
                    },
                ]}
                dataSource={datasource}
            />
        ) : (
            <div className="empty-list">{services.language.getText('notData')}</div>
        );
    };

    hardleVisible = async () => {
        const { visible } = this.state;
        const { defalutTemplateId, appId } = this.props;
        if (!visible) {
            let res: any = await api.get(
                {
                    appId: appId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: '/admin/wechat_official_account_templates',
                }
            );
            if (res) {
                this.setState({
                    datasourceResult: res,
                    datasource: res,
                });
            }
        }
        this.setState({
            visible: !visible,
            templateId: defalutTemplateId,
        });
    };

    renderFind = () => {
        const { searchName } = this.state;
        return (
            <Form layout="inline" ref="searchFrom" style={{ marginBottom: '20px' }}>
                <Form.Item label={services.language.getText('mbbt')}>
                    <Input
                        className="find-filter-width"
                        placeholder={services.language.getText('qsrmbbt')}
                        value={searchName}
                        onChange={(e: any) => {
                            this.onChangeSearchName(e.target.value);
                        }}
                        onPressEnter={this.onSearch}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={this.onSearch}>
                        {services.language.getText('common.search')}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="reset" onClick={this.onChangeSearchName.bind(this, '')}>
                        {services.language.getText('common.reset')}
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    render() {
        const { visible } = this.state;
        const { defalutTemplateId } = this.props;
        return (
            <div>
                {defalutTemplateId && <div>{defalutTemplateId}</div>}
                <Button
                    type="link"
                    onClick={this.hardleVisible}
                    style={{ marginTop: '-10px', padding: '0' }}
                >
                    {services.language.getText('selectPlease')}
                </Button>
                <Modal
                    visible={visible}
                    width={1000}
                    title={services.language.getText('mblb')}
                    onCancel={this.hardleVisible}
                    onOk={this.OK}
                >
                    <div className="wechat-official-auto-reply-setting-page-image-modal">
                        {this.renderFind()}
                        {this.renderList()}
                    </div>
                </Modal>
            </div>
        );
    }
}
