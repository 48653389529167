import React, { PureComponent } from 'react';
import { message as AntMessage, Modal } from 'antd';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import './index.less';

interface OrderPickItemProps {
    id: number;
    delivery: any;
    freightRules: any;
    parentProps: any;
}

/**
 * 快递列表项
 */
export class DeliveryExpressItem extends PureComponent<OrderPickItemProps, {}> {
    changeStatus = () => {
        const { parentProps, delivery } = this.props;
        const { entity, params } = parentProps;
        const id = delivery.id;
        const status = delivery.status;
        Modal.confirm({
            title: status ? '是否关闭?' : '是否开启?',
            onOk: () => {
                api.put(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                        apiPath: status
                            ? `/admin/express_deliveries/${id}/disabled`
                            : `/admin/express_deliveries/${id}/enabled`,
                    }
                )
                    .then(() => {
                        let message = '开启成功';
                        if (status) {
                            message = '关闭成功';
                        }
                        AntMessage.success(message);
                        entity.search(params);
                    })
                    .catch(errorHandle);
            },
        });
    };

    render() {
        const props = this.props;
        let editHref = '';
        if (props.parentProps && props.parentProps.pageType) {
            if (props.parentProps.pageType === 'credit-shop') {
                editHref = `#/credit-shop/edit-express/${props.delivery.id}`;
            } else {
                editHref =
                    '#/' +
                    props.parentProps.pageType +
                    '-delivery/edit-express/' +
                    props.delivery.id;
            }
        }
        return (
            <div className="delivery-express-item-wrap">
                <div className="delivery-express-item-header">
                    <span className="font-weight-bold">规则名称：{props.delivery.name}</span>
                </div>
                <table
                    cellSpacing={0}
                    cellPadding={5}
                    style={{
                        border: '1px solid #e8e8e8',
                        borderCollapse: 'collapse',
                        textAlign: 'center',
                        fontSize: '14px',
                        width: '100%',
                    }}
                >
                    <tbody>
                        <tr className="table-tr">
                            <th className="table-tr-merchant font-weight-normal">
                                适用
                                {props.parentProps.pageType === 'subsite' ||
                                props.parentProps.pageType === 'credit-shop'
                                    ? '门店'
                                    : '专柜'}
                            </th>
                            <th className="table-tr-regions font-weight-normal">可配送地区</th>
                            <th className="table-tr-content font-weight-normal">规则内容</th>
                            <th className="table-tr-edit font-weight-normal">状态</th>
                            <th className="table-tr-edit font-weight-normal">操作</th>
                        </tr>
                        <tr>
                            <td className="table-tr-merchant font-weight-bold">
                                {props.parentProps.pageType === 'subsite' ||
                                props.parentProps.pageType === 'credit-shop' ? (
                                    <span>
                                        {props.delivery.subsite && props.delivery.subsite.name}
                                    </span>
                                ) : (
                                    <span>
                                        {props.delivery.merchant && props.delivery.merchant.name}
                                    </span>
                                )}
                            </td>
                            <td className="table-tr-freight-rules" colSpan={2}>
                                {props.freightRules &&
                                    props.freightRules.map((freightRule: any) => {
                                        return (
                                            <div
                                                className="delivery-express-item-freight-rules"
                                                key={freightRule.deliveryScopeDesc}
                                            >
                                                <div className="delivery-express-item-freight-areas">
                                                    {freightRule.deliveryAreas}
                                                </div>
                                                <div className="delivery-express-item-freight-freight-ranges">
                                                    {freightRule.freightRanges &&
                                                        freightRule.freightRanges.map(
                                                            (freightRange: any) => {
                                                                return (
                                                                    <div
                                                                        className="delivery-express-item-freight-freight-range-item"
                                                                        key={freightRange.id}
                                                                    >
                                                                        <span>
                                                                            [{freightRange.desc}]
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </td>
                            <td className="table-tr-edit">
                                {props.delivery.status ? '已启用' : '已关闭'}
                            </td>
                            <td className="table-tr-edit">
                                {props.delivery.status && (
                                    <span onClick={this.changeStatus} className="status">
                                        关闭
                                    </span>
                                )}
                                {!props.delivery.status && (
                                    <span onClick={this.changeStatus} className="status">
                                        开启
                                    </span>
                                )}
                                <a href={editHref} style={{ marginRight: '10px' }}>
                                    编辑
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
