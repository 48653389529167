import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, some, uniq } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 专柜/门店选择模式
 */
enum MerchantSelectorMode {
    /**
     * 按门店选择
     */
    SUBSITE = 'SUB_SITE',
    /**
     * 按专柜选择
     */
    MERCHANT = 'MERCHANT',
    /**
     * 按标签选择
     */
    TAG = 'TAG',
}

enum EffectivePeriodType {
    /**
     * 无限制
     */
    UNLIMITED = 'UNLIMITED',
    /**
     * 每月
     */
    DAY = 'DAY',
    /**
     * 每周
     */
    WEEK = 'WEEK',
}

export const config: Config = {
    entities: {
        MemberCardDiscountEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
            apiPath: '/loader/admin/member_card_discount_activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                effectivePeriod: {
                    type: 'string',
                    displayName: '<<zqxz>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                enabled: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<themeStyleTips>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        effectivePeriodType: {
                            type: 'string.options.radio',
                            displayName: '<<zqxz>>',
                            options: [
                                { id: EffectivePeriodType.UNLIMITED, name: '<<wxz>>' },
                                { id: EffectivePeriodType.DAY, name: '<<everyMonth>>' },
                                { id: EffectivePeriodType.WEEK, name: '<<everyWeek>>' },
                            ],
                            defaultValue: EffectivePeriodType.UNLIMITED,
                            rules: [{ required: true }],
                        },
                        dayPeriodCondition: {
                            type: 'object.dayPeriodCondition',
                            displayName: '<<zqsz>>',
                        },
                        weekPeriodCondition: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<zqsz>>',
                            defaultValue: [],
                            options: [
                                { id: '1', name: '<<zy>>' },
                                { id: '2', name: '<<ze>>' },
                                { id: '3', name: '<<zs>>' },
                                { id: '4', name: '<<zs_1>>' },
                                { id: '5', name: '<<zw>>' },
                                { id: '6', name: '<<zl_1>>' },
                                { id: '7', name: '<<zr>>' },
                            ],
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<applicableSubsiteAndMerchant>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                                { id: 'TAG', name: '<<selectTag>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },
                        tags: {
                            type: 'array.tags.selector',
                            displayName: '<<xzbq>>',
                            defaultValue: [],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                disableBand: true,
                                productDisplaySelectValueType: 'goods',
                                importConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                    apiPath: '/admin/products/data_parse_by_excel',
                                    importFileSource: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                                        apiPath: '/admin/files/locations/135/upload',
                                    },
                                    templateSource: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                        apiPath: '/admin/products/download_template',
                                    },
                                },
                            },
                        },
                        restrictionsType: {
                            type: 'string.options.radio',
                            displayName: '<<xztj>>',
                            defaultValue: 'UNLIMITED',
                            options: [
                                { id: 'UNLIMITED', name: '<<wxz>>' },
                                { id: 'MONEY', name: '<<jem>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        restrictions: {
                            type: 'number.tip',
                            displayName: '<<rewardInfos>>',
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<jem>>',
                                addonafter: '<<ydz>>',
                                precision: 2,
                                min: 0.01,
                            },
                        },
                        memberCardDiscountInfos: {
                            type: 'array.selectMemberCardWithDiscount',
                            displayName: '<<hykdjgz>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const selectMode = row.ruleInfo?.selectMode;
                                    let subsiteIds: string = '';
                                    if (selectMode === 'SUB_SITE') {
                                        subsiteIds =
                                            row.ruleInfo?.subsites?.length &&
                                            row.ruleInfo.subsites
                                                .map((item: any) => item.id)
                                                .join(',');
                                    }
                                    if (selectMode === 'MERCHANT') {
                                        subsiteIds = uniq(
                                            row.ruleInfo?.merchants?.length &&
                                                row.ruleInfo.merchants.map(
                                                    (item: any) => item.subSiteId
                                                )
                                        ).join(',');
                                    }
                                    if (selectMode === 'TAG') {
                                        subsiteIds = uniq(
                                            row.ruleInfo?.tags?.length &&
                                                row.ruleInfo.tags.map((item: any) => item.subsiteId)
                                        ).join(',');
                                    }
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        MemberCardDiscountView: {
            component: 'Viewport',
            entity: 'MemberCardDiscountEntity',
        },
        MemberCardDiscountPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MemberCardDiscountFilter',
                },
                {
                    component: 'GridLayout',
                    style: {
                        display: 'flex',
                    },
                    items: [
                        {
                            component: 'FlexLayout',
                            direction: 'vertical',
                            className: 'mt24 mb24',
                            items: [
                                {
                                    component: 'GridLayout',
                                    items: [
                                        {
                                            component: 'Button',
                                            text: '<<addActivity>>',
                                            type: 'primary',
                                            icon: 'plus',
                                            span: 4,
                                            route: '/member_card_discount_activities/add',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            component: 'MemberCardDiscountLevelStylesButton',
                        },
                        {
                            component: 'MemberCardDiscountCombinePromotionsButton',
                        },
                    ],
                },
                { component: 'MemberCardDiscountTable' },
            ],
        },
        MemberCardDiscountLevelStylesButton: {
            component: 'MemberCardDiscountLevelStyles',
        },
        MemberCardDiscountFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<selectActivityName>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        MemberCardDiscountTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'effectivePeriod',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/member_card_discount_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/member_card_discount_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/member_card_discount_activities/edit/{{row.id}}',
                    },
                ],
            },
        },
        MemberCardDiscountAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MemberCardDiscountAddForm' }],
        },
        MemberCardDiscountAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MemberCardDiscountEntity',
            labelCol: 6,
            controlCol: 18,
            style: { width: 850 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.effectivePeriodType',
                },
                {
                    property: 'baseInfo.dayPeriodCondition',
                    visible: (values: any) =>
                        get(values, 'baseInfo.effectivePeriodType') === EffectivePeriodType.DAY,
                },
                {
                    property: 'baseInfo.weekPeriodCondition',
                    visible: (values: any) =>
                        get(values, 'baseInfo.effectivePeriodType') === EffectivePeriodType.WEEK,
                },
                {
                    property: 'ruleInfo.selectMode',
                },
                {
                    property: 'ruleInfo.subsites',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.SUBSITE,
                },

                {
                    property: 'ruleInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'ruleInfo.tags',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.TAG,
                },
                {
                    property: 'ruleInfo.activityProductType',
                },
                {
                    property: 'ruleInfo.products',
                },
                {
                    property: 'ruleInfo.restrictionsType',
                },
                {
                    property: 'ruleInfo.restrictions',
                    visible: (values: any) => get(values, 'ruleInfo.restrictionsType') === 'MONEY',
                },
                {
                    property: 'ruleInfo.memberCardDiscountInfos',
                },
                {
                    property: 'ruleInfo.marketingSchedule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                const baseInfo = entity.baseInfo;
                if (baseInfo) {
                    const effectivePeriodType = baseInfo.effectivePeriodType;
                    const dayPeriodCondition = baseInfo.dayPeriodCondition;
                    const weekPeriodCondition = baseInfo.weekPeriodCondition;
                    if (
                        effectivePeriodType === EffectivePeriodType.DAY &&
                        (!dayPeriodCondition ||
                            !dayPeriodCondition.startDay ||
                            !dayPeriodCondition.endDay)
                    ) {
                        AntMessage.error(language.getText('qxzmyxzrq'));
                        return false;
                    }
                    if (
                        effectivePeriodType === EffectivePeriodType.WEEK &&
                        (!weekPeriodCondition || weekPeriodCondition.length === 0)
                    ) {
                        AntMessage.error(language.getText('qxzmzxzrq'));
                        return false;
                    }
                }
                if (ruleInfo) {
                    const selectMode = ruleInfo.selectMode;
                    switch (selectMode) {
                        case MerchantSelectorMode.SUBSITE:
                            if (!ruleInfo.subsites || ruleInfo.subsites.length === 0) {
                                AntMessage.error(services.language.getText('selectSubsite'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.MERCHANT:
                            if (!ruleInfo.merchants || ruleInfo.merchants.length === 0) {
                                AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.TAG:
                            if (!ruleInfo.tags || ruleInfo.tags.length === 0) {
                                AntMessage.error(language.getText('qxzbq'));
                                return false;
                            }
                            break;
                    }
                    const activityProductType = ruleInfo.activityProductType;
                    const products = ruleInfo.products;
                    if (
                        activityProductType !== 'ALL' &&
                        (!products || !products.values || products.values.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    const discounts = ruleInfo.memberCardDiscountInfos;
                    if (!discounts || discounts.length === 0) {
                        AntMessage.error(language.getText('qxzhykdj'));
                        return false;
                    }
                    if (some(discounts, (d) => !d.discount)) {
                        AntMessage.error(language.getText('qtxxfzk'));
                        return false;
                    }
                    const restrictionsType = ruleInfo.restrictionsType;
                    const restrictions = ruleInfo.restrictions;
                    if (restrictionsType === 'MONEY' && !restrictions) {
                        AntMessage.error(language.getText('qtxxzje'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MemberCardDiscountEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MemberCardDiscountEditForm' }],
        },
        MemberCardDiscountEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MemberCardDiscountEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 850 },
            fields: [
                {
                    property: 'baseInfo.name',
                    modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'baseInfo.dateRange',
                    modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'baseInfo.effectivePeriodType',
                    modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'baseInfo.dayPeriodCondition',
                    modifiable: (v: any) => !get(v, 'enabled'),
                    visible: (values: any) =>
                        get(values, 'baseInfo.effectivePeriodType') === EffectivePeriodType.DAY,
                },
                {
                    property: 'baseInfo.weekPeriodCondition',
                    modifiable: (v: any) => !get(v, 'enabled'),
                    visible: (values: any) =>
                        get(values, 'baseInfo.effectivePeriodType') === EffectivePeriodType.WEEK,
                },
                {
                    property: 'ruleInfo.selectMode',
                    // modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'ruleInfo.subsites',
                    // modifiable: (v: any) => !get(v, 'enabled'),
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.SUBSITE,
                },
                {
                    property: 'ruleInfo.merchants',
                    //  modifiable: (v: any) => !get(v, 'enabled'),
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'ruleInfo.tags',
                    //  modifiable: (v: any) => !get(v, 'enabled'),
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.TAG,
                },
                {
                    property: 'ruleInfo.activityProductType',
                    // modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'ruleInfo.products',
                    //  modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'ruleInfo.restrictionsType',
                    modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'ruleInfo.restrictions',
                    modifiable: (v: any) => !get(v, 'enabled'),
                    visible: (values: any) => get(values, 'ruleInfo.restrictionsType') === 'MONEY',
                },
                {
                    property: 'ruleInfo.memberCardDiscountInfos',
                    modifiable: (v: any) => !get(v, 'enabled'),
                },
                {
                    property: 'ruleInfo.marketingSchedule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                const baseInfo = entity.baseInfo;
                if (baseInfo) {
                    const effectivePeriodType = baseInfo.effectivePeriodType;
                    const dayPeriodCondition = baseInfo.dayPeriodCondition;
                    const weekPeriodCondition = baseInfo.weekPeriodCondition;
                    if (
                        effectivePeriodType === EffectivePeriodType.DAY &&
                        (!dayPeriodCondition ||
                            !dayPeriodCondition.startDay ||
                            !dayPeriodCondition.endDay)
                    ) {
                        AntMessage.error(language.getText('qxzmyxzrq'));
                        return false;
                    }
                    if (
                        effectivePeriodType === EffectivePeriodType.WEEK &&
                        (!weekPeriodCondition || weekPeriodCondition.length === 0)
                    ) {
                        AntMessage.error(language.getText('qxzmzxzrq'));
                        return false;
                    }
                }
                if (ruleInfo) {
                    const selectMode = ruleInfo.selectMode;
                    switch (selectMode) {
                        case MerchantSelectorMode.SUBSITE:
                            if (!ruleInfo.subsites || ruleInfo.subsites.length === 0) {
                                AntMessage.error(services.language.getText('selectSubsite'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.MERCHANT:
                            if (!ruleInfo.merchants || ruleInfo.merchants.length === 0) {
                                AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.TAG:
                            if (!ruleInfo.tags || ruleInfo.tags.length === 0) {
                                AntMessage.error(language.getText('qxzbq'));
                                return false;
                            }
                            break;
                    }
                    const activityProductType = ruleInfo.activityProductType;
                    const products = ruleInfo.products;
                    if (
                        activityProductType !== 'ALL' &&
                        (!products || !products.values || products.values.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    const discounts = ruleInfo.memberCardDiscountInfos;
                    if (!discounts || discounts.length === 0) {
                        AntMessage.error(language.getText('qxzhykdj'));
                        return false;
                    }
                    if (some(discounts, (d) => !d.discount)) {
                        AntMessage.error(language.getText('qtxxfzk'));
                        return false;
                    }
                    const restrictionsType = ruleInfo.restrictionsType;
                    const restrictions = ruleInfo.restrictions;
                    if (restrictionsType === 'MONEY' && !restrictions) {
                        AntMessage.error(language.getText('qtxxzje'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member_card_discount_activities',
                    component: 'MemberCardDiscountView',
                    breadcrumbName: '<<memberCardDiscount>>',
                    privilege: {
                        path: 'memberCardDiscount',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MemberCardDiscountAddPage',
                            breadcrumbName: '<<xzhyzk>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MemberCardDiscountEditPage',
                            breadcrumbName: '<<bjhyzk>>',
                        },
                        { path: '/', component: 'MemberCardDiscountPage' },
                    ],
                },
            ],
        },
    ],
};
