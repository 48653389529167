import React, { PureComponent } from 'react';
import { map, remove } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import {
    Button,
    DatePicker,
    Form,
    Icon,
    Input,
    message as AntMessage,
    Modal as AntModal,
    Popover,
    Radio,
    Select,
    Table,
    Tabs as AntTabs,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ForwardDataCenterModal } from '../../services';
import './index.less';

const { api } = services;
const AntTabPane = AntTabs.TabPane;
const activeKeyList: Array<any> = [
    {
        value: 'ALL',
        name: 'all',
    },
    {
        value: 'SHELF',
        name: 'alreadyShelf',
    },
    {
        value: 'NONE',
        name: 'notListed',
    },
    {
        value: 'OFF_SHELF',
        name: 'alreadyOffShelf',
    },
];

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('subsiteName')}>
                        {getFieldDecorator('subsiteIds')(
                            <Select
                                className="find-filter-width"
                                placeholder={services.language.getText('selectPlease')}
                            >
                                <Select.Option value="">{language.getText('all')}</Select.Option>
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('spmc')}>
                        {getFieldDecorator('name')(
                            <Input
                                className="find-filter-width"
                                placeholder={services.language.getText('inputPlease')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('productCode')}>
                        {getFieldDecorator('code')(
                            <Input
                                className="find-filter-width"
                                placeholder={services.language.getText('inputPlease')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class CombineGoodsFind extends PureComponent {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        selectedRows: new Array<any>(),
        activeKey: 'ALL',
        subsites: [],
        chooseVisible: false, //批量操作悬浮弹层展示
        chooseValue: '', //弹层类型（批量定时上架，批量定时下架，批量定时售卖）
        modalVisible: false, //弹层展示
        startDate: '', //弹层时间
        modalStatus: 'open', //弹层状态
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            this.setState({
                subsites,
            });
        });
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination, activeKey } = this.state;
        try {
            let res: any = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                    shelfStatus: activeKey !== 'ALL' ? activeKey : undefined,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/combine_goods',
                }
            );
            if (res && res.result) {
                res.result = res.result.map((good: any) => {
                    good.id = good.goodsId;
                    return good;
                });
                this.setState({ datasource: res.result, total: res.totalNum, current: res.page });
            }
        } catch (error) {
            throw error;
        }
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            const rows = [...selectedRows];
            rows.push(record);
            this.setState({ selectedRows: rows });
        } else {
            const rows = [...selectedRows];
            remove(rows, (i) => {
                return i.id === record.id;
            });
            this.setState({ selectedRows: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        const { selectedRows, datasource } = this.state;
        const selectedRowIds = map(selectedRows, 'id');
        if (selected) {
            const addRows = rows.filter((item: any) => {
                return !selectedRowIds.includes(item.id);
            });
            this.setState({ selectedRows: [...selectedRows, ...addRows] });
        } else {
            const deleteIds: any = map(datasource, 'id');
            const deleteRows = selectedRows.filter((item: any) => {
                return !deleteIds.includes(item.id);
            });
            this.setState({ selectedRows: [...deleteRows] });
        }
    };

    onChange = async (activeKey: any) => {
        this.setState(
            {
                chooseVisible: false,
                modalVisible: false,
                startDate: '',
                status: 'open',
                activeKey,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                const { searchParams } = this.state;
                this.onSearch(searchParams);
            }
        );
    };

    onChangeShelfStatus = (shelfStatus: string) => {
        const { selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(services.language.getText('productSelectRequired'));
            return;
        }
        const goodsIds = map(selectedRows, 'id');
        api.put(
            {
                goodsIds: goodsIds,
                shelf_status: shelfStatus,
                close_timing: false,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/combine_goods/shelf_status/batch`,
            }
        )
            .then(() => {
                AntMessage.success(
                    shelfStatus === 'OFF_SHELF'
                        ? language.getText('batchShelvesSuccess')
                        : language.getText('batchListingSuccess'),
                    1.5
                );
                const { searchParams } = this.state;
                this.onSearch(searchParams);
                this.setState({
                    selectedRows: [],
                });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    hardleChoose = () => {
        const { chooseVisible } = this.state;
        this.setState({
            chooseVisible: !chooseVisible,
        });
    };

    export = () => {
        const { activeKey, searchParams } = this.state;
        api.get(
            {
                ...searchParams,
                shelfStatus: activeKey !== 'ALL' ? activeKey : undefined,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/combine_goods/export',
            }
        )
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch((err) => {
                AntMessage.error(err.response.body.err_msg);
                throw err;
            });
    };

    renderButtons = () => {
        const { activeKey, chooseVisible } = this.state;
        return (
            <div className="find-buttons">
                {(activeKey === 'ALL' || activeKey === 'NONE' || activeKey === 'OFF_SHELF') && (
                    <Button
                        type="default"
                        onClick={this.onChangeShelfStatus.bind(this, 'SHELF')}
                        className="find-button"
                    >
                        {language.getText('upShelf')}
                    </Button>
                )}
                {(activeKey === 'ALL' || activeKey === 'SHELF') && (
                    <Button
                        type="default"
                        onClick={this.onChangeShelfStatus.bind(this, 'OFF_SHELF')}
                        className="find-button"
                    >
                        {language.getText('offShelf')}
                    </Button>
                )}
                <span className="find-choose-wrap">
                    <Button type="default" onClick={this.hardleChoose} className="find-button">
                        {language.getText('batchAction')}
                        {chooseVisible ? <Icon type="up" /> : <Icon type="down" />}
                    </Button>
                    {chooseVisible && (
                        <div className="find-choose-list">
                            {(activeKey === 'ALL' ||
                                activeKey === 'NONE' ||
                                activeKey === 'OFF_SHELF') && (
                                <div onClick={this.onChangeShelfStatusTime.bind(this, 'SHELF')}>
                                    {language.getText('batchTimingUpShelf')}
                                </div>
                            )}
                            {(activeKey === 'ALL' || activeKey === 'SHELF') && (
                                <div onClick={this.onChangeShelfStatusTime.bind(this, 'OFF_SHELF')}>
                                    {language.getText('batchTimingDownShelf')}
                                </div>
                            )}
                            <div onClick={this.onChangeShelfStatusTime.bind(this, 'SALE')}>
                                {language.getText('pldssm')}
                            </div>
                        </div>
                    )}
                </span>
                <Button type="default" onClick={this.export} className="find-button">
                    {language.getText('dczhsp')}
                </Button>
            </div>
        );
    };

    onChangeShelfStatusTime = (name: string) => {
        const { selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(services.language.getText('productSelectRequired'));
            return;
        }
        this.setState({
            chooseValue: name,
            modalVisible: true,
            startDate: '',
            status: 'open',
        });
    };

    renderButtonsModal = () => {
        const { chooseValue, modalVisible, startDate, modalStatus } = this.state;
        let title = '';
        if (chooseValue === 'SHELF') {
            title = language.getText('timingUpShelf');
        } else if (chooseValue === 'OFF_SHELF') {
            title = language.getText('timingDownShelf');
        } else if (chooseValue === 'SALE') {
            title = language.getText('timingSale');
        }
        return (
            <AntModal
                visible={modalVisible}
                okText={services.language.getText('common.ok')}
                title={title}
                onOk={this.submit}
                onCancel={this.closeModal}
                cancelButtonProps={{
                    onClick: this.closeModal,
                }}
            >
                <div className="modal-time-wrap">
                    <div>
                        <span className="modal-time-red">*</span>
                        {language.getText('dssj')}
                    </div>
                    <div className="modal-time-radio">
                        <Radio.Group onChange={this.onRadioChange} value={modalStatus}>
                            <div>
                                <Radio value="open">
                                    <span>{language.getText('common.opend')}</span>
                                    <DatePicker
                                        style={{ marginLeft: '20px' }}
                                        disabledDate={this.disabledDate}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime
                                        value={startDate ? moment(startDate) : undefined}
                                        onChange={(value) => this.handleDateChange(value)}
                                        placeholder={services.language.getText('selectPlease')}
                                    />
                                </Radio>
                            </div>
                            <div>
                                <Radio value="close">
                                    <span>{language.getText('common.close')}</span>
                                </Radio>
                            </div>
                        </Radio.Group>
                    </div>
                </div>
                {chooseValue === 'SALE' && (
                    <div className="modal-time-radio-tip">
                        {language.getText('jszhspsjhdsjchsm')}
                    </div>
                )}
            </AntModal>
        );
    };

    onRadioChange = (event: any) => {
        const status = event.target.value;
        this.setState({
            modalStatus: status,
        });
    };

    disabledDate(current: moment.Moment | null) {
        return !!(current && current < moment().subtract(1, 'days'));
    }

    handleDateChange = (data: any) => {
        const thisValue = moment(data || undefined).format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            startDate: thisValue,
        });
    };
    closeModal = () => {
        const { modalVisible } = this.state;
        this.setState({
            startDate: '',
            modalStatus: 'open',
            modalVisible: !modalVisible,
        });
    };

    submit = () => {
        const { chooseValue, modalStatus, startDate, selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(services.language.getText('productSelectRequired'));
            return;
        }
        if (modalStatus === 'open' && !startDate) {
            AntMessage.error(language.getText('qxzrq'));
            return;
        }
        const params: any = {};
        params.goodsIds = map(selectedRows, 'id');
        if (chooseValue === 'SHELF' || chooseValue === 'OFF_SHELF') {
            params.shelfStatus = chooseValue;
        }
        if (modalStatus === 'open' && startDate) {
            params.time = startDate;
        }
        params.closeTiming = modalStatus === 'open' ? false : true;
        api.put(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
            apiPath:
                chooseValue === 'SALE'
                    ? '/admin/combine_goods/sale_status/batch'
                    : '/admin/combine_goods/shelf_status/batch',
        })
            .then(() => {
                if (chooseValue === 'SHELF') {
                    AntMessage.success(
                        modalStatus === 'open'
                            ? language.getText('pldssjcg')
                            : language.getText('dssjgbcg')
                    );
                } else if (chooseValue === 'OFF_SHELF') {
                    AntMessage.success(
                        modalStatus === 'open'
                            ? language.getText('pldsxjcg')
                            : language.getText('dsxjgbcg')
                    );
                } else if (chooseValue === 'SALE') {
                    AntMessage.success(
                        modalStatus === 'open'
                            ? language.getText('pldssmcg')
                            : language.getText('dssmgb')
                    );
                }
                this.setState({
                    chooseVisible: false,
                    modalVisible: false,
                });
                const { searchParams } = this.state;
                this.onSearch(searchParams);
                this.setState({
                    selectedRows: [],
                });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    render() {
        const {
            datasource,
            pagination,
            total,
            current,
            selectedRows,
            activeKey,
            subsites,
        } = this.state;

        return (
            <div className="combine-goods-find">
                <div>
                    <CollectionCreateForm onSearch={this.onSearchFind} subsites={subsites} />
                </div>
                <div className="combine-goods-add">
                    <Button type="primary" href="#/combine-goods/add">
                        {language.getText('addCombinProduct')}
                    </Button>
                </div>
                <AntTabs
                    className="find-tabs"
                    activeKey={activeKey}
                    animated={false}
                    onChange={this.onChange}
                >
                    {activeKeyList.map((activeKeyVo) => {
                        return (
                            <AntTabPane
                                tab={language.getText(activeKeyVo.name)}
                                key={activeKeyVo.value}
                            >
                                <div></div>
                            </AntTabPane>
                        );
                    })}
                </AntTabs>
                {this.renderButtons()}
                {this.renderButtonsModal()}
                <Table
                    scroll={{ x: true }}
                    rowSelection={{
                        fixed: true,
                        type: 'checkbox',
                        selectedRowKeys: map(selectedRows, 'id'),
                        onSelect: (record: any, selected: boolean, selectedRow: any) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                    }}
                    rowKey={(record: any) => record.id}
                    pagination={{
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={[
                        {
                            title: language.getText('slt'),
                            dataIndex: 'pictureUrl',
                            key: 'pictureUrl',
                            render: (val) => {
                                return (
                                    <img
                                        alt=""
                                        style={{ width: '100px', height: '100px' }}
                                        src={val}
                                    />
                                );
                            },
                        },
                        {
                            title: language.getText('cobinName'),
                            dataIndex: 'productName',
                            key: 'productName',
                            render: (val) => {
                                const popoverContent = (
                                    <div style={{ maxWidth: '300px' }}>{val}</div>
                                );
                                return val && val.length > 11 ? (
                                    <Popover content={popoverContent}>
                                        <span title={val} className="goods-field-name">
                                            {val}
                                        </span>
                                    </Popover>
                                ) : (
                                    <span title={val} className="goods-field-name">
                                        {val}
                                    </span>
                                );
                            },
                        },
                        {
                            title: language.getText('combinCode'),
                            dataIndex: 'productCode',
                            key: 'productCode',
                        },
                        {
                            title: services.language.getText('xsj'),
                            key: 'salesPrice',
                            dataIndex: 'salesPrice',
                        },
                        {
                            title: services.language.getText('productDetail'),
                            dataIndex: 'combineItems',
                            key: 'combineItems',
                            width: 250,
                            render: (val: any) => {
                                return val && val.length > 0 ? (
                                    <Popover
                                        title={language.getText('productDetail')}
                                        content={
                                            <div>
                                                {val.map((item: any, index: number) => {
                                                    return (
                                                        <div
                                                            style={{ display: 'flex' }}
                                                            key={index}
                                                        >
                                                            <div
                                                                title={item.name}
                                                                style={{
                                                                    padding: '4px',
                                                                    margin: '0 10px 10px 0',
                                                                    background: '#f6f6f6',
                                                                    border: '1px solid #e3e3e3',
                                                                    borderRadius: '4px',
                                                                    maxWidth: '500px',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                            <div style={{ marginTop: '4px' }}>
                                                                <span>X{item.quantity}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        }
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{
                                                    maxWidth: '150px',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {val[0].name}
                                            </div>
                                            <span style={{ marginLeft: '5px', color: '#1890ff' }}>
                                                {language.getText('inAll')}
                                                {val.length}
                                                {language.getText('ge')}
                                            </span>
                                        </div>
                                    </Popover>
                                ) : null;
                            },
                        },
                        {
                            title: language.getText('status'),
                            dataIndex: 'shelfStatus',
                            key: 'shelfStatus',
                            render: (val) => {
                                let statusName = '';
                                if (val === 'NONE') {
                                    statusName = language.getText('notListed');
                                } else if (val === 'OFF_SHELF') {
                                    statusName = language.getText('alreadyOffShelf');
                                } else if (val === 'SHELF') {
                                    statusName = language.getText('alreadyShelf');
                                }
                                return <span>{statusName}</span>;
                            },
                        },
                        {
                            title: services.language.getText('subsiteName'),
                            key: 'subsiteName',
                            dataIndex: 'subsiteName',
                        },
                        {
                            title: services.language.getText('merchantName'),
                            key: 'merchantName',
                            dataIndex: 'merchantName',
                        },
                        {
                            title: language.getText('productCategory'),
                            key: 'categoryName',
                            dataIndex: 'categoryName',
                        },
                        {
                            title: language.getText('brand'),
                            key: 'brandName',
                            dataIndex: 'brandName',
                        },
                        {
                            title: services.language.getText('saleCount'),
                            key: 'stock',
                            dataIndex: 'stock',
                        },
                        {
                            title: services.language.getText('timingUpShelfTime'),
                            key: 'timingShelfTime',
                            dataIndex: 'timingShelfTime',
                        },
                        {
                            title: services.language.getText('timingDownShelfTime'),
                            key: 'timingOffShelfTime',
                            dataIndex: 'timingOffShelfTime',
                        },
                        {
                            title: services.language.getText('common.tableAction'),
                            className: 'action-column',
                            dataIndex: 'id',
                            key: 'id',
                            fixed: 'right',
                            width: 110,
                            render: (val: any) => {
                                const editHref = '#/combine-goods/edit/' + val;
                                const logsHref = '#/combine-goods/logs/' + val;
                                return (
                                    <div>
                                        <a href={editHref}>{language.getText('common.edit')}</a>
                                        <a href={logsHref} style={{ marginLeft: '10px' }}>
                                            {language.getText('common.log')}
                                        </a>
                                    </div>
                                );
                            },
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    }
}
