import React, { PureComponent } from 'react';
import { MemberCardSelector } from './member-card-selector';
import { MemberCardWithDiscountDisplay } from './member-card-with-discount-display';

import { MemberCardDiscountRule } from './member-card-with-discount-display';

export class SelectVirtualProduct extends PureComponent<
    {
        disabled: boolean | undefined;
        value: Array<MemberCardDiscountRule>;
        onChange: (value: Array<MemberCardDiscountRule> | undefined) => void;
    },
    any
> {
    state = { value: new Array<MemberCardDiscountRule>() };

    /**
     * 已选会员等级变更
     * @param selectValues
     */
    onChange = (selectValues: any[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 选择会员等级
     * @param values
     */
    onSelect = (values: any[]) => {
        const { value } = this.props;
        const data = value
            ? this.processSelectedData(values).filter(
                  (item) =>
                      value.findIndex((v) => v.memberCardLevelId === item.memberCardLevelId) === -1
              )
            : this.processSelectedData(values);
        const newValue: MemberCardDiscountRule[] = value ? [...value] : [];
        this.onChange(newValue.concat(data));
    };

    processSelectedData = (data: any[]) => {
        return data.map((item) => {
            return {
                memberCardLevelName: item.baseInfo.name,
                discount: null,
                memberCardLevelId: item.id,
            };
        });
    };

    render() {
        const { value, disabled } = this.props;
        const hasValue = value && value.length > 0;
        return (
            <div>
                {!disabled && <MemberCardSelector onChange={this.onSelect} />}
                {hasValue && (
                    <MemberCardWithDiscountDisplay
                        data={value}
                        onChange={this.onChange}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
}
