import React, { PureComponent } from 'react';
import { Button, InputNumber, DatePicker, Checkbox, Popconfirm, message } from 'antd';
import moment from 'moment';
import { map, get, cloneDeep, remove, find, forEach } from 'lodash';
import { TimeRangePickerPlus } from '..';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface RegistrationTime {
    selected?: boolean;
    startDate?: string;
    startTime?: string;
    endTime?: string;
    quantity?: number;
}

export interface ActivityRegistrationTimePickerProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 格式化日期
     */
    dateFormat: string;
    /**
     * 格式化时间
     */
    timeFormat: string;
    /**
     * 当前值
     */
    value: RegistrationTime[];
    /**
     * 禁用状态
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: RegistrationTime[], name: string) => void;
}
interface ActivityRegistrationTimePickerState {
    inputValue?: number;
}
export class ActivityRegistrationTimePicker extends PureComponent<
    ActivityRegistrationTimePickerProps,
    ActivityRegistrationTimePickerState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputValue: undefined,
        };
    }

    static defaultProps = {
        dateFormat: 'YYYY-MM-DD',
        timeFormat: 'HH:mm',
        value: [],
    };

    componentDidMount() {
        const { value } = this.props;

        if (value.length === 0) {
            this.handleAdd();
        }
    }

    disabledDate(current: moment.Moment | null) {
        return !!(current && current < moment().subtract(1, 'days'));
    }

    handleDateChange = (date: any, index: number) => {
        const { name, value, dateFormat, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    startDate: moment(date || undefined).format(dateFormat),
                };
            }
            return item;
        });
        onChange(newValue, name);
    };

    handleTimeChange = (time: any, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    startTime: time.start,
                    endTime: time.end,
                };
            }
            return item;
        });

        onChange(newValue, name);
    };

    hanldeQuantityChange = (quantity: number = 0, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    quantity,
                };
            }
            return item;
        });
        onChange(newValue, name);
    };

    handleAdd = () => {
        const { name, value, onChange } = this.props;
        const newValue = [
            ...value,
            {
                selected: false,
                startDate: undefined,
                startTime: undefined,
                endTime: undefined,
                quantity: undefined,
                enable: true,
            },
        ];
        onChange && onChange(newValue, name);
    };

    handleDelete = (index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.filter((_, i) => i !== index);
        onChange && onChange(newValue, name);
    };
    toggleEnable = (index: number) => {
        const { name, value, onChange } = this.props;

        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    enable: !get(value[index], 'enable'),
                };
            }
            return item;
        });
        onChange(newValue, name);
    };

    renderItem = (item: RegistrationTime, index: number) => {
        const { value, dateFormat, timeFormat, disabled } = this.props;
        const { startDate, startTime, endTime, quantity } = item;
        // 原始数据的条数
        const sourceCount = value && value.length ? value.filter((i: any) => i.id).length : 0;
        const datePicker = (
            <DatePicker
                style={{ width: 125 }}
                className="mrp-10"
                placeholder="请选择日期"
                showToday={false}
                allowClear={false}
                format={dateFormat}
                disabledDate={this.disabledDate}
                value={startDate ? moment(startDate) : undefined}
                disabled={!!get(value[index], 'id') && disabled}
                onChange={(value) => this.handleDateChange(value, index)}
            />
        );

        const timeRangePicker = (
            <TimeRangePickerPlus
                format={timeFormat}
                placeholder={['请选择', '请选择']}
                name="start"
                value={{
                    start: startTime,
                    end: endTime,
                }}
                style={{ width: 80 }}
                allowClear={false}
                disabled={!!get(value[index], 'id') && disabled}
                onChange={(value) => this.handleTimeChange(value, index)}
            />
        );

        const quantityInput = (
            <InputNumber
                className="mrp-10"
                placeholder={services.language.getText('inputPlease')}
                style={{ width: '100px' }}
                value={quantity}
                min={disabled ? get(value[index], 'min') || 0 : 0}
                precision={0}
                max={99999999999}
                step={1}
                onChange={(value) => this.hanldeQuantityChange(value ? value : undefined, index)}
            />
        );

        const deleteButton = (
            <Button type="link" onClick={() => this.handleDelete(index)}>
                {services.language.getText('common.delete')}
            </Button>
        );

        const isEdit = window.location.href.includes('/edit');

        return (
            <tr className="table-tr">
                <td>
                    <Checkbox
                        className="reward-content-item-checkbox"
                        onChange={this.onChangeItemCheckbox.bind(this, index)}
                        checked={item.selected}
                        value="checked"
                    ></Checkbox>
                </td>
                <td>{datePicker}</td>
                <td>{timeRangePicker}</td>
                <td>{quantityInput}</td>
                {isEdit && (
                    <td>
                        {!!get(value[index], 'id') && (
                            <span>
                                {/*{get(value[index], 'min')}/{get(value[index], 'roundMaxQuantity')}*/}
                                {get(value[index], 'min')}
                            </span>
                        )}
                    </td>
                )}
                <td>
                    {(!!(sourceCount && index > sourceCount - 1) || !disabled) && (
                        <span>{deleteButton}</span>
                    )}
                    {!!get(value[index], 'id') && (
                        <Button onClick={() => this.toggleEnable(index)} type="link">
                            {get(value[index], 'enable') === false ? '启用' : '关闭'}
                        </Button>
                    )}
                </td>
            </tr>
        );
    };

    batchRemove = () => {
        return () => {
            const { value, name, onChange } = this.props;
            const validSelectedRow = value.filter((j: any) => j.selected === true && j.id); //查历史
            if (validSelectedRow.length > 0) {
                message.info('只有新增的数据可以批量删除');
                return;
            }
            const selectedRow = value.filter((j: any) => j.selected === true && !j.id);
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const result = cloneDeep(value);
            remove(result, (j: any) => {
                return j.selected === true && !j.id;
            });
            onChange(result, name);
        };
    };

    changeBatchInputValue = (value: any) => {
        this.setState({
            inputValue: value,
        });
    };

    batchChangeValue = () => {
        return () => {
            const { value, name, onChange } = this.props;
            const { inputValue } = this.state;
            const selectedRow = value.filter((j: any) => j.selected === true);
            if (!inputValue) {
                message.info('请输入要修改的场次报名名额');
                return;
            }
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
            const result = cloneDeep(value);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.selected === true);
                item && (i.quantity = inputValue);
            });
            onChange(result, name);
        };
    };

    onSelectAll = () => {
        const { value, onChange, name } = this.props;
        const selectedRow = value.filter((j: any) => j.selected === true);
        const checked = selectedRow.length === value.length ? true : false;
        const result = cloneDeep(value);
        forEach(result, (item) => {
            item.selected = !checked;
        });
        onChange(result, name);
    };

    onChangeItemCheckbox = (index: number) => {
        const { value, onChange, name } = this.props;
        const checked = value[index].selected;
        const result = cloneDeep(value);
        result[index].selected = !checked;
        onChange(result, name);
    };

    renderTable = () => {
        const { value } = this.props;
        if (!value || value.length === 0) {
            return null;
        }
        const selectedRow = value.filter((j: any) => j.selected === true);
        const isEdit = window.location.href.includes('/edit');
        return (
            <div>
                <table
                    cellSpacing={0}
                    cellPadding={5}
                    style={{
                        borderCollapse: 'collapse',
                        textAlign: 'center',
                        width: '100%',
                        margin: '20px 0',
                    }}
                >
                    <tbody>
                        <tr className="table-title-tr">
                            <th>
                                <Checkbox
                                    onChange={this.onSelectAll}
                                    checked={selectedRow.length === value.length ? true : false}
                                    value="checked"
                                ></Checkbox>
                            </th>
                            <th>场次日期</th>
                            <th>场次时间</th>
                            <th>场次报名名额</th>
                            {isEdit && <th>已报名名额</th>}
                            <th>操作</th>
                        </tr>
                        {map(value, this.renderItem)}
                    </tbody>
                </table>
                {this.renderFooter()}
            </div>
        );
    };

    renderFooter = () => {
        const { inputValue } = this.state;

        return (
            <div className="product-selected-footer" style={{ height: '50px' }}>
                <div className="batch-remove"></div>
                <div className="setting-item">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                    <InputNumber
                        className="item-number"
                        style={{ width: 200 }}
                        placeholder="批量修改场次报名名额"
                        value={inputValue}
                        precision={0}
                        min={0}
                        max={99999999999}
                        step={1}
                        onChange={(e) => this.changeBatchInputValue(e)}
                    ></InputNumber>
                    <Popconfirm
                        onConfirm={this.batchChangeValue()}
                        title="确认批量修改选中的场次报名名额吗？"
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('confirmEdit')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="registration-time-picker-components">
                <div>
                    <Button type="default" className="mrp-10" onClick={this.handleAdd}>
                        {services.language.getText('tjcc')}
                    </Button>
                </div>
                {this.renderTable()}
            </div>
        );
    }
}
