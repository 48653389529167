import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';

export const config: Config = {
    entities: {
        purchaseLimitationEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/purchaseLimitation',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<gzmc>>',
                    controlConfig: { placeholder: '<<qsrgzmc>>' },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<gzmc>>',
                },
                goods: {
                    type: 'string.groupPopoverGoods',
                    displayName: '<<xgsp>>',
                },
                limitNum: {
                    type: 'string',
                    displayName: '<<limitCount>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<xgkssj>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<xgjssj>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<xgzt>>',
                },
                subsiteId: {
                    type: 'array.options.autoComplete',
                    className: 'custom-array-options',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<selectSubsite>>',
                        style: {
                            width: 450,
                        },
                    },
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<gzmc>>',
                            controlConfig: { placeholder: '<<l_qsrgzmc>>' },
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<xgsj_1>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'string.options.select',
                            // className: 'custom-array-options',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                    },
                },
                limitInfo: {
                    type: 'object.subForm',
                    displayName: '<<limitSet>>',
                    properties: {
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<xgsp>>',
                            options: [
                                { id: 'GOODS', name: '<<aspxz>>' },
                                { id: 'TAGS', name: '<<selectTag>>' },
                            ],
                            defaultValue: 'TAGS',
                            rules: [{ required: true }],
                        },
                        tags: {
                            type: 'array.pointTagsSelector',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [{ type: 'array', required: true }],
                            conctrolConfig: {
                                type: 'radio',
                            },
                        },
                        goods: {
                            type: 'array.goods.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [{ type: 'array', required: true }],
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteId = row.baseInfo && row.baseInfo.subsites;

                                    return { subsiteId };
                                },
                            },
                        },
                        limitNum: {
                            type: 'number.tip',
                            displayName: '<<xgzs>>',
                            controlConfig: {
                                addonafter: '<<ge>>',
                                tip: '',
                                style: {
                                    width: 100,
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<xgzssxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    components: {
        purchaseLimitationView: {
            component: 'Viewport',
            entity: 'purchaseLimitationEntity',
        },
        purchaseLimitationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'purchaseLimitationEntity',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'purchaseLimitationFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzxggz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    route: '/point-purchase-limitation/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'purchaseLimitationTable' },
            ],
        },
        purchaseLimitationFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
            ],
        },
        purchaseLimitationTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'goods',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                        propertyName: `goods`,
                        remark: '{{goodsRemark}}',
                        // tips: '共{{goods.length}}个商品',
                        tips: '{{goodsTips}}',
                    },
                },
                {
                    property: 'limitNum',
                    width: '120px',
                },
                {
                    property: 'startTime',
                    width: '200px',
                },
                {
                    property: 'endTime',
                    width: '200px',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLED' },
                                    value: 'ENABLED',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/credit_eshop_limit/:id/disabled',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 'ENABLED' },
                                    value: 'DISABLED',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/credit_eshop_limit/:id/enabled',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/point-purchase-limitation/edit/{{row.id}}',
                    },
                ],
            },
        },
        purchaseLimitationAddPage: {
            component: 'Card',
            entity: 'purchaseLimitationEntity',
            content: { component: 'purchaseLimitationForm' },
        },
        purchaseLimitationForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 720 },
            entity: 'purchaseLimitationEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'limitInfo.selectMode' },
                {
                    property: 'limitInfo.goods',
                    visible: (values: any) => get(values, 'limitInfo.selectMode') === 'GOODS',
                },
                {
                    property: 'limitInfo.tags',
                    visible: (values: any) => get(values, 'limitInfo.selectMode') === 'TAGS',
                    controlConfig: {
                        type: 'radio',
                    },
                },
                { property: 'limitInfo.limitNum' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        purchaseLimitationEditPage: {
            component: 'Card',
            content: { component: 'purchaseLimitationEditForm' },
        },
        purchaseLimitationEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            style: { width: 720 },
            entity: 'purchaseLimitationEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'limitInfo.selectMode' },
                {
                    property: 'limitInfo.goods',
                    visible: (values: any) => get(values, 'limitInfo.selectMode') === 'GOODS',
                },
                {
                    property: 'limitInfo.tags',
                    visible: (values: any) => get(values, 'limitInfo.selectMode') === 'TAGS',
                    controlConfig: {
                        type: 'radio',
                    },
                },
                { property: 'limitInfo.limitNum' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/point-purchase-limitation',
                    component: 'purchaseLimitationView',
                    breadcrumbName: '<<purchaseLimitation>>',
                    privilege: {
                        path: 'creditEshopLimit',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'purchaseLimitationAddPage',
                            breadcrumbName: '<<xzxggz>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'purchaseLimitationEditPage',
                            breadcrumbName: '<<bjxggz>>',
                        },
                        { path: '/', component: 'purchaseLimitationPage' },
                    ],
                },
            ],
        },
    ],
};
