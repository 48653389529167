import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import { SelectCategory } from './select-category';
import { SelectCategoryNav } from './select-category-nav';
import { SelectCategoryNavProduct } from './select-category-nav-product';

const language = services.language;
export class CustomSelectCategory extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
        type?: string;
        subsiteIds?: string[];
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: { id: number; name: string; type: string }) => {
        const { onChange } = this.props;

        this.hideModal();
        onChange({
            ...value,
        });
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
            type,
            subsiteIds,
        } = this.props;

        const { visibleModal } = this.state;
        const hasSingleTarget = linkParams && linkParams.name;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };

        return (
            <span className="link-name">
                {hasSingleTarget ? (
                    <span className="link-name-label">{linkParams.name}</span>
                ) : (
                    language.getText('notSelect')
                )}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>

                {type === 'categoryNav' ? (
                    <SelectCategoryNav
                        contentConfig={contentConfig}
                        modalConfig={modalConfig}
                        showModal={visibleModal}
                        onCancel={this.hideModal}
                        subsiteIds={subsiteIds}
                        {...selectProps}
                    />
                ) : type === 'categoryNavProduct' || type === 'merchantCategoryNavProduct' ? (
                    <SelectCategoryNavProduct
                        contentConfig={contentConfig}
                        modalConfig={modalConfig}
                        showModal={visibleModal}
                        onCancel={this.hideModal}
                        subsiteIds={subsiteIds}
                        {...selectProps}
                    />
                ) : (
                    <SelectCategory
                        contentConfig={contentConfig}
                        modalConfig={modalConfig}
                        showModal={visibleModal}
                        onCancel={this.hideModal}
                        {...selectProps}
                        subsiteIds={subsiteIds}
                    />
                )}
            </span>
        );
    }
}
