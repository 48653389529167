import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        GoldExchangeRuleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/loader/admin/gold_exchange_rules',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        goldAlias: {
                            type: 'string.text',
                            displayName: '<<jbmc>>',
                            controlConfig: {
                                style: { width: 150 },
                            },
                            rules: [{ required: true }],
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<gzsm>>',
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                                placeholder: '<<descriptionRule>>',
                            },
                            rules: [{ required: true, max: 20000, message: '<<notMoreThan2000>>' }],
                            extra: '<<descriptionExtra>>',
                        },
                    },
                },
                exchangeRule: {
                    type: 'object.subForm',
                    displayName: '<<dhgz>>',
                    properties: {
                        exchangePointValue: {
                            type: 'number.tip',
                            displayName: '<<dhxfjf>>',
                            controlConfig: {
                                style: { width: 100 },
                                addonbefore: '',
                                addonafter: '<<l_jb>>',
                                min: 1,
                                max: 9999,
                                precision: 0,
                                message: '<<l_numberRange_1>>',
                                tip: '',
                            },
                            rules: [{ required: true }],
                        },
                        exchangeParkingPointValue: {
                            type: 'number.tip',
                            displayName: '<<dhtcjf>>',
                            controlConfig: {
                                style: { width: 100 },
                                addonbefore: '',
                                addonafter: '<<l_jb>>',
                                min: 1,
                                max: 9999,
                                precision: 0,
                                message: '<<l_numberRange_1>>',
                                tip: '',
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
                deductionRule: {
                    type: 'object.subForm',
                    displayName: '<<dkgz>>',
                    properties: {
                        exchangeRule: {
                            type: 'object.PointExchangeRule',
                            displayName: '<<dkgz>>',
                            rules: [{ required: false }],
                            controlConfig: {
                                isNoDefault: true,
                                contentReg: true,
                                companyText: '<<jb>>',
                            },
                            extra: '<<deductionRuleExtra>>',
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<gzsm>>',
                            rules: [{ required: false }],
                            controlConfig: {
                                rows: 6,
                            },
                        },
                    },
                },
                clearRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<qlgz>>',
                    properties: {
                        clearRule: {
                            type: 'object.goldClearRule',
                            displayName: '<<ruleSetting>>',
                            controlConfig: {},
                        },
                        expirationDate: {
                            type: 'number.tip',
                            displayName: '<<gqtx>>',
                            controlConfig: {
                                style: { width: 100 },
                                addonbefore: '',
                                addonafter: '<<tnjjgqdjfjxtx>>',
                                min: 1,
                                max: 9999,
                                precision: 0,
                                message: '<<l_numberRange>>',
                                tip: '',
                            },
                            rules: [{ required: true }],
                            extra: '<<jfmxymzwyhzs>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        GoldExchangePointView: {
            component: 'Viewport',
        },
        GoldExchangePointRuleForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'GoldExchangeRuleEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 760 },
            fields: [
                { property: 'baseInfo.goldAlias' },
                { property: 'baseInfo.description' },
                { property: 'exchangeRule.exchangePointValue' },
                { property: 'exchangeRule.exchangeParkingPointValue' },
                { property: 'deductionRule.exchangeRule' },
                { property: 'deductionRule.description' },
                { property: 'clearRuleInfo.clearRule' },
                { property: 'clearRuleInfo.expirationDate' },
            ],
            submit: {
                text: '<<bcgz>>',
            },
            onValidate: (entity: any) => {
                const clearRule = entity.clearRuleInfo.clearRule;
                const deductionRule = entity.deductionRule;
                if (!clearRule || !clearRule.type) {
                    AntMessage.warning(services.language.getText('qzqtxqlgz'));
                    return false;
                }
                if (clearRule.type === 'FIXED_CLEAR') {
                    const fixedRule = clearRule.fixedRule;
                    if (!fixedRule || !fixedRule.yearDelay || !fixedRule.month || !fixedRule.day) {
                        AntMessage.warning(services.language.getText('qzqtxgdqlgz'));
                        return false;
                    }
                }
                if (clearRule.type === 'GRADUALLY_CLEAR') {
                    const graduallyRule = clearRule.graduallyRule;
                    if (!graduallyRule || !graduallyRule.dateType || !graduallyRule.dateValue) {
                        AntMessage.warning(services.language.getText('qzqtxgdqlgz'));
                        return false;
                    }
                }
                if (
                    (deductionRule?.exchangeRule?.exchangeRatio &&
                        !deductionRule.exchangeRule.exchangeAmount) ||
                    (deductionRule?.exchangeRule?.exchangeAmount &&
                        !deductionRule.exchangeRule.exchangeRatio)
                ) {
                    AntMessage.warning(services.language.getText('jbslydkjebxtscz'));
                    return false;
                }
                const reg = /^\+?[0-9]\d*$/;
                const reg2 = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
                if (
                    deductionRule?.exchangeRule?.exchangeRatio &&
                    !reg.test(deductionRule.exchangeRule.exchangeRatio)
                ) {
                    AntMessage.warning(services.language.getText('dkdwqtx'));
                    return false;
                }
                if (
                    deductionRule?.exchangeRule?.exchangeAmount &&
                    !reg2.test(deductionRule.exchangeRule.exchangeAmount)
                ) {
                    AntMessage.warning(services.language.getText('dkjeqtx'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'));
            },
        },
        GoldExchangePoint: {
            entity: 'GoldExchangeRuleEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'GoldExchangePointRuleForm' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/gold-exchange-point',
                    breadcrumbName: '<<goldExchangePoint>>',
                    component: 'GoldExchangePointView',
                    privilege: {
                        path: 'goldExchangeRule',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'GoldExchangePoint' }],
                },
            ],
        },
    ],
};
