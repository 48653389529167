import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { api } from '@comall-backend-builder/core/lib/services';
import {
    CrowdScopeEnum,
    AutoReplyVo,
    WechatMsgType,
} from '../../../../containers/wechat/components/wechant-content';
import { WechatOfficialAutoReplySettingPageData } from '../../../../containers/wechat/components/wechat-official-auto-reply-setting-page-data';
import './index.less';
import { encodePathToURL } from '../../../../containers/wechat/utils';

export default class WechatOfficialAutoReplyViewMode extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            appId: undefined,
            replyContents: [],
        };
    }

    componentWillReceiveProps(nextProps: any) {
        const { row: nextRow } = nextProps;
        const { row } = this.props;
        if (!row.appId && nextRow.appId) {
            this.loadMessages(nextProps);
        }
    }
    loadMessages = async (props: any) => {
        const { row } = props;
        const { appId } = row;
        if (!appId) {
            return;
        }
        const messageResult: any = await api.get(
            { appId: appId, replyType: 'FOLLOWED' },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages/by_app_id',
            }
        );
        if (
            messageResult &&
            messageResult.replyContents &&
            messageResult.replyContents.length > 0
        ) {
            this.setState({
                replyContents: messageResult.replyContents.map((content: any) => {
                    content.messageContent = JSON.parse(content.messageContent);
                    content.hide = true;
                    return content;
                }),
            });
        } else {
            //没数据的时候 给一个默认的
            this.setFirst();
        }
    };

    onChangeReplyDate = (replyData: Array<AutoReplyVo>) => {
        this.setState({
            replyContents: replyData,
        });
    };

    renderContent = () => {
        const { row } = this.props;
        const { appId } = row;
        const { replyContents } = this.state;
        if (!appId) {
            return null;
        }
        return (
            <div className="repaly-wrap">
                <WechatOfficialAutoReplySettingPageData
                    appId={appId}
                    replyData={replyContents}
                    onChange={this.onChangeReplyDate}
                    inputOnly
                    readOnly
                />
            </div>
        );
    };

    setFirst = () => {
        const { appName } = this.state;
        const { subsite } = this.state;
        const storeName = subsite ? subsite.name : '';
        const content = `${services.language.getText(
            'l_nh'
        )}${appName}!  \n\n${services.language.getText('nh')}${services.language.getText(
            'l_gdfl'
        )}<a href="pages/home/index" data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${encodePathToURL(
            'pages/home/index',
            subsite?.id
        )}">${services.language.getText('hyzc_1')}</a>\n${services.language.getText(
            'l_zsqy'
        )}<a  href="subpackages/member-card-level-rights/index" data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${encodePathToURL(
            'subpackages/member-card-level-rights/index',
            subsite?.id
        )}">${services.language.getText('member_membership')}</a>\n${services.language.getText(
            'l_yhhl'
        )}<a  href="subpackages/coupons-center/index" data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${encodePathToURL(
            'subpackages/coupons-center/index',
            subsite?.id
        )}">${services.language.getText('wdyhq')}</a>\n\n${storeName}，${services.language.getText(
            'qdyndxy'
        )}！`;
        this.setState({
            replyContents: [
                {
                    hide: true,
                    crowdScope: CrowdScopeEnum.ALL,
                    messageContent: [
                        {
                            msgtype: WechatMsgType.TEXT,
                            text: {
                                content: content,
                                targetList: [
                                    {
                                        href: 'pages/home/index',
                                        target: {
                                            linkName: services.language.getText(
                                                'linkTypes.loginStorePage'
                                            ),
                                            linkPath: encodePathToURL(
                                                'pages/home/index',
                                                subsite?.id
                                            ),
                                            linkType: 'loginStorePage',
                                            linkParams: null,
                                        },
                                    },
                                    {
                                        href: 'subpackages/member-card-level-rights/index',
                                        target: {
                                            linkName: services.language.getText('linkTypes.hyqy'),
                                            linkPath: encodePathToURL(
                                                'subpackages/member-card-level-rights/index',
                                                subsite?.id
                                            ),
                                            linkType: 'member.membership',
                                            linkParams: null,
                                        },
                                    },
                                    {
                                        href: 'subpackages/coupons-center/index',
                                        target: {
                                            linkName: services.language.getText('member_coupon'),
                                            linkPath: encodePathToURL(
                                                'subpackages/coupons-center/index',
                                                subsite?.id
                                            ),
                                            linkType: 'member.coupon',
                                            linkParams: null,
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            msgtype: WechatMsgType.MINIPROGRAMPAGE,
                            miniprogrampage: {
                                appid: '{miniprogram-appid}',
                                title: services.language.getText('memberCenterPage'),
                                target: {
                                    linkName: services.language.getText('memberCenterPage'),
                                    linkPath: encodePathToURL(
                                        'pages/member-center/index',
                                        subsite?.id
                                    ),
                                    linkType: 'member',
                                    linkParams: '',
                                },
                                pagepath: encodePathToURL('pages/member-center/index', subsite?.id),
                                thumbMediaId: '',
                                thumbMediaInfo: undefined,
                            },
                        },
                    ],
                },
            ],
        });
    };
    render() {
        const { row } = this.props;
        if (!row || !row.appId) {
            return null;
        }
        return this.renderContent();
    }
}
