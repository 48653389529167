import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MallActivityVerificationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/v2/consume_orders/items';
        if (data) {
            data.type = 'MALL_ACTIVITY';
        } else {
            data = {
                type: 'MALL_ACTIVITY',
            };
        }

        // 新商场活动复用老接口的数据隔离
        if (window.location.hash !== '#/mall-activity-verification') {
            data.type = 'MALL_ACTIVITY_NEW';
        }

        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    activityName: item.orderItem.mallActivity.name,
                    code: item.orderItem.coupon.code,
                    createTime: item.createTime,
                    merchantName: item.subsite.merchant.name,
                    merchantUserName: item.shoppingGuideName,
                    mobile: item.couponOrderReceive.mobile,
                    name: item.couponOrderReceive.name,
                    subsiteName: item.subsite.name,
                    consumeType: item.consumeType,
                };
            });
            return res;
        });
    },
};
