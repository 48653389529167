import { api, navigation } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import {
    Modal,
    message as AntMessage,
    Row as AntRow,
    Card as AntCard,
    Col as AntCol,
    Table as AntTable,
    Select,
    Popover,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, find, forEach } from 'lodash';
import { CouponOrderChooseAppliesSelector } from '../coupon-order-choose-applies-selector';
import { CouponOrderChooseAppliesSelectorDisplay } from '../coupon-order-choose-applies-selector-display';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import { services } from '@comall-backend-builder/core';
import { MultipleImageUpload } from '../../../components';

const { Option } = Select;

export interface PictureVo {
    id: number | string;
    url: string;
}
export interface CouponOrderApplyProps {
    params: any;
}

export interface CouponOrderApplyStates {
    data: any | null;
    sibilitiesData: any | null;
    reasons: any | null;
    reasonId: number;
    responsibilityId: number;
    remark: string;
    pictures: Array<PictureVo>;
    previewImage: string;
}

class couponOrderApplyForm extends Component<CouponOrderApplyProps, CouponOrderApplyStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
            sibilitiesData: null,
            reasons: null,
            reasonId: 0,
            responsibilityId: 0,
            remark: '',
            pictures: [],
            previewImage: '',
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/orders/${id}`,
            }
        ).then((response: any) => {
            response.itemList.map((item: any) => {
                item.coupons = [];
                return item;
            });
            this.setState({
                data: response,
            });
        });
        //获取选择原因
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/reasons/2`,
            }
        ).then((response: any) => {
            this.setState({
                reasons: response,
            });
        });
        //获取责任归属
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/responsibilities`,
            }
        ).then((response: any) => {
            this.setState({
                sibilitiesData: response,
                responsibilityId: 2, //默认客户责任
            });
        });
    }

    //加载会员信息
    renderMemberInfo = () => {
        const { data } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('hyxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('name')}:</AntCol>
                    <AntCol span={5}>{data.member.memberName}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('phoneNum')}:</AntCol>
                    <AntCol span={5}>{data.member.mobile}</AntCol>
                </AntRow>
            </AntCard>
        );
    };

    onChangeResponsibilityId = (e: any) => {
        this.setState({
            responsibilityId: e,
        });
    };

    onChangeReasonId = (e: any) => {
        this.setState({
            reasonId: e,
        });
    };

    onChangeRemark = (e: any) => {
        if (e.target.value) {
            const value = e.target.value;
            this.setState({
                remark: value,
            });
        }
    };

    onUploadImage = (value: PictureVo[]) => {
        this.setState({
            pictures: value,
        });
    };

    renderApplyInfo = () => {
        const { data, reasons, reasonId } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('shsqxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('orderNumber')}:</AntCol>
                    <AntCol span={5}>{data.orderNo}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqlx')}:</AntCol>
                    <AntCol span={5}>{services.language.getText('tqtk')}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3} style={{ display: 'flex' }}>
                        <div>
                            <span style={{ color: 'red', marginLeft: '-10px', fontSize: '18px' }}>
                                *
                            </span>
                        </div>
                        <div>{services.language.getText('sqyy')}:</div>
                    </AntCol>
                    <AntCol span={5}>
                        <Select
                            value={reasonId}
                            style={{ width: '200px' }}
                            onChange={(e: any) => this.onChangeReasonId(e)}
                        >
                            <Option value={0}>{services.language.getText('selectPlease')}</Option>
                            {map(reasons, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.reason}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AntCol>
                </AntRow>
            </AntCard>
        );
    };

    renderServiceInfo = () => {
        const { sibilitiesData, responsibilityId, pictures } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('kfjl')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfbz')}:</AntCol>
                    <AntCol span={15}>
                        <textarea
                            className="ant-input"
                            placeholder={services.language.getText('inputPlease')}
                            style={{
                                height: '73px',
                                minHeight: '73px',
                                maxHeight: '136px',
                                overflowY: 'hidden',
                            }}
                            onChange={(e: any) => this.onChangeRemark(e)}
                            maxLength={400}
                        ></textarea>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfpz')}:</AntCol>
                    <AntCol span={20}>
                        <MultipleImageUpload
                            maxCount={6}
                            value={pictures}
                            onChange={this.onUploadImage}
                        />
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('zrgs')}:</AntCol>
                    <AntCol span={5}>
                        <Select
                            value={responsibilityId}
                            style={{ width: '200px' }}
                            onChange={(e: any) => this.onChangeResponsibilityId(e)}
                        >
                            {map(sibilitiesData, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('auditStatus')}:</AntCol>
                    <AntCol span={5}></AntCol>
                </AntRow>
            </AntCard>
        );
    };

    onAddCoupons = (row: any, coupons: any, packageNum?: number) => {
        const { data } = this.state;
        let item = find(data.itemList, { id: row.id }) || {};
        if (!coupons || coupons.length === 0) {
            return;
        }
        if (row) {
            item.coupons = coupons; //需求：修改后的，覆盖之前选择的数据
            item.packageNum = packageNum;
            this.setState(
                {
                    data: data,
                },
                () => {
                    this.forceUpdate();
                }
            );
        }
    };

    getTableConfig = () => {
        const { params } = this.props;
        let newColumns = [
            {
                title: services.language.getText('couponName'),
                key: 'saleRuleName',
                dataIndex: 'saleRuleName',
                render: (text: any) => {
                    return (
                        <div style={{ width: '150px' }} className="ant-table-cell-ellipsis">
                            <span>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('zxxj'),
                key: 'executePrice',
                dataIndex: 'executePrice',
                render: (text: any) => {
                    return <span>{(+text || 0).toFixed(2)}</span>;
                },
            },
            {
                title: services.language.getText('zxjf'),
                key: 'pointExcutePrice',
                dataIndex: 'pointExcutePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('gmsl'),
                key: 'quantity',
                dataIndex: 'quantity',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('shsl'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any, row: any) => {
                    const hasCoupons = coupons && coupons.length > 0;
                    return (
                        <div>
                            {hasCoupons ? (
                                <label>
                                    {coupons.length}
                                    {services.language.getText('zhang')}
                                    <Popover
                                        title={services.language.getText('xzyhq')}
                                        content={
                                            <CouponOrderChooseAppliesSelectorDisplay
                                                disabled={false}
                                                orderId={params.id}
                                                onChange={(coupons, packageNum) =>
                                                    this.onAddCoupons(row, coupons, packageNum)
                                                }
                                                data={coupons}
                                                row={row}
                                            />
                                        }
                                    >
                                        <span
                                            style={{
                                                marginLeft: '10px',
                                                color: '#1890ff',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {services.language.getText('common.modify')}
                                        </span>
                                    </Popover>
                                </label>
                            ) : (
                                <CouponOrderChooseAppliesSelector
                                    orderId={params.id}
                                    selectValues={[]}
                                    onChange={(coupons, packageNum) =>
                                        this.onAddCoupons(row, coupons, packageNum)
                                    }
                                    row={row}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('tkxj'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any) => {
                    let money = 0;
                    const hasCoupons = coupons && coupons.length > 0;
                    if (hasCoupons) {
                        coupons.map((coupon: any) => {
                            money = money + coupon.executePrice;
                            return coupon;
                        });
                    }
                    return hasCoupons ? <span>{money.toFixed(2)}</span> : null;
                },
            },
            {
                title: services.language.getText('tkjf'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any) => {
                    let point = 0;
                    const hasCoupons = coupons && coupons.length > 0;
                    if (hasCoupons) {
                        coupons.map((coupon: any) => {
                            point = point + coupon.pointExecutePrice;
                            return coupon;
                        });
                    }
                    return hasCoupons ? <span>{point}</span> : null;
                },
            },
            {
                title: services.language.getText('sftqmzze'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any) => {
                    let amount = 0;
                    const hasCoupons = coupons && coupons.length > 0;
                    if (hasCoupons) {
                        coupons.map((coupon: any) => {
                            if (coupon.rechargeSuccess) {
                                amount = amount + coupon.amount;
                            }
                            return coupon;
                        });
                    }
                    return amount ? <span>{amount.toFixed(2)}</span> : null;
                },
            },
        ];
        let config: any = {
            rowKey: 'id',
            columns: [...newColumns],
            scroll: {
                y: 400,
            },
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderCouponInfo = () => {
        const { data } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data.itemList,
        };
        return <AntTable {...tableProps} pagination={false}></AntTable>;
    };

    // 审核
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { data, reasonId, remark, responsibilityId, pictures } = this.state;
        const { params } = this.props;
        if (auditStatus === 'SUCCESS') {
            if (!reasonId) {
                AntMessage.warning(services.language.getText('qxzsqyy'));
                return false;
            }
            let refundCoupons: any = [];
            let packageItems: any = [];
            if (data.itemList) {
                forEach(data.itemList, (item: any) => {
                    if (item.coupons && item.coupons.length > 0) {
                        forEach(item.coupons, (coupon: any) => {
                            refundCoupons.push(coupon);
                        });
                    }
                    if (item.packageNum) {
                        packageItems.push({
                            orderItemId: item.id,
                            quantity: item.packageNum,
                        });
                    }
                });
            }
            if (refundCoupons.length === 0) {
                AntMessage.warning(services.language.getText('qxzyshdyhq'));
                return false;
            }
            api.post(
                {
                    orderId: params.id,
                    reasonId: reasonId,
                    remark: remark,
                    responsibilityId: responsibilityId,
                    pictures: pictures,
                    refundCoupons: refundCoupons,
                    items: packageItems,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: `/admin/service_applies`,
                }
            )
                .then(() => {
                    AntMessage.success(services.language.getText('shtgcg'));
                    navigation.goBack();
                })
                .catch((err) => {
                    if (err.response.body.err_code === 300111010) {
                        let msg = err.response.body.err_msg;
                        Modal.error({
                            title: services.language.getText('tip'),
                            content: msg,
                            okText: services.language.getText('common.ok'),
                            onOk: () => {},
                        });
                    } else {
                        errorHandle(err);
                    }
                });
        }
    }

    render() {
        const { data } = this.state;
        return data ? (
            <div className="coupon-service-applies-edit-form">
                <AntCard>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderMemberInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderApplyInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderServiceInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderCouponInfo()}</AntRow>
                </AntCard>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button
                        type="primary"
                        onClick={this.submit.bind(this, 'SUCCESS')}
                        style={{ marginRight: 24 }}
                    >
                        {services.language.getText('bcbtgsh')}
                    </Button>
                    <Button route="goBack">{services.language.getText('qxshsqd')}</Button>
                </div>
            </div>
        ) : null;
    }
}
function mapStateToProps(_state: any, props: any) {
    const { params } = props;
    return {
        params,
    };
}
export const CouponOrderApplyForm = connect(mapStateToProps)(couponOrderApplyForm);
