import { BaseConfig } from '../../../cae/interfaces';
import { services } from '@comall-backend-builder/core';

enum ContentTopicStatus {
    /**
     * 启用
     */
    ENABLE = 'ENABLED',
    /**
     * 禁用
     */
    DISABLE = 'DISABLED',
}

export let config: BaseConfig = {
    entities: {
        OpenScreenAds: {
            apiPath: '/loader/admin/open_screen_ads',

            properties: {
                id: {
                    type: 'string',
                },
                information: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<advName>>',
                            rules: [
                                { required: true },
                                {
                                    max: 20,
                                    message: '<<advTitleNot20Character>>',
                                },
                            ],
                            extra: '<<advNameExtra>>',
                        },
                        showTime: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<showTime>>',
                            rules: [{ required: true, message: '<<selectShowTime>>' }],
                            controlConfig: {
                                style: {
                                    width: 380,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },

                rules: {
                    type: 'object.subForm',
                    displayName: '<<dispalyRule>>',

                    properties: {
                        info: {
                            type: 'object.openScreenInfo',
                            displayName: '<<data>>',
                            controlConfig: {
                                style: { width: 280 },
                            },
                        },
                        channels: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<applicableScope>>',
                            defaultValue: ['WECHAT'],
                            rules: [{ required: true }],
                            options: [
                                { id: 'WECHAT', name: '<<wxMiniP>>' },
                                { id: 'ALIPAY', name: '<<aliMiniP>>' },
                                { id: 'APP', name: 'APP' },
                            ],
                        },
                        contentType: {
                            type: 'string.options.radio',
                            displayName: '<<contentType>>',
                            defaultValue: 'PICTURE',
                            rules: [{ required: true }],
                            options: [
                                { id: 'PICTURE', name: '<<pictures>>' },
                                { id: 'VIDEO', name: '<<video>>' },
                            ],
                        },

                        picture: {
                            type: 'array.carparkImageAds',
                            className: 'open-screen-ads-pic',
                            displayName: '<<addContent>>',
                            rules: [{ required: true }],
                            defaultValue: [
                                {
                                    picture: [],
                                },
                            ],
                            controlConfig: {
                                imgTips: '<<pictureNotMore3Pic>>',
                                maxCount: 3,
                                maxSize: 1 * 1024,
                                // newClient: true,
                                style: {
                                    width: 400,
                                },
                                bussinessType: 'open-screen-ad',
                            },
                        },
                        video: {
                            displayName: '<<uploadVideo>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                accept: 'video/mp4',
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/910/upload`,
                                mode: 'text',
                                maxSize: 5 * 1024,
                                uploadLimitSizeErrorMessage: '<<video>>{{file.name}}<<videoLimit>>',
                            },
                            extra: '<<videoExtra>>',
                        },
                        target: {
                            type: 'object.subsiteLinkTypeSelector',
                            displayName: '<<linkConfig>>',
                            // rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                hideNeedUrlLink: true,
                                bussinessType: 'open-screen-ad',
                            },
                        },
                        carousel: {
                            type: 'string.options.radio',
                            displayName: '<<components.Products.showStyle>>',
                            defaultValue: 'CAROUSEL',
                            rules: [{ required: true }],
                            options: [
                                { id: 'CAROUSEL', name: '<<multScroll>>' },
                                { id: 'RANDOM', name: '<<multiCustom>>' },
                            ],
                        },
                        interval: {
                            type: 'number.tip',
                            defaultValue: 1,
                            displayName: '<<carouselInterval>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                addonbefore: '',
                                addonafter: '<<second>>',
                                style: { width: '80px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 999,
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        position: {
                            type: 'string.options.radio',
                            displayName: '<<position>>',
                            defaultValue: 'RIGHT_TOP',
                            rules: [{ required: true }],
                            options: [
                                { id: 'RIGHT_TOP', name: '<<rightTop>>' },
                                { id: 'RIGHT_BOTTOM', name: '<<rightBotton>>' },
                                { id: 'LEFT_TOP', name: '<<leftTop>>' },
                                { id: 'LEFT_BOTTOM', name: '<<leftBotton>>' },
                            ],
                        },
                        countdown: {
                            type: 'number.tip',
                            defaultValue: 3,
                            displayName: '<<countDown>>',
                            controlConfig: {
                                addonbefore: '',
                                addonafter: '<<second>>',
                                style: { width: '80px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 999,
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        mode: {
                            type: 'string.options.radio',
                            displayName: '<<triggerConditions>>',
                            defaultValue: 'DAILY',
                            rules: [{ required: true }],
                            options: [
                                { id: 'DAILY', name: '<<everyDay>>' },
                                { id: 'EACH_TIME', name: '<<eachTime>>' },
                                { id: 'PERMANENT', name: '<<permanent>>' },
                            ],
                        },
                        times: {
                            type: 'number.integer',
                            defaultValue: 3,
                            displayName: '<<triggerNumber>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                addonbefore: '',
                                addonafter: '<<frequency>>',
                                style: { width: '80px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 999,
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        status: {
                            type: 'string.options.radio',
                            displayName: '<<status>>',
                            defaultValue: 'ENABLED',
                            options: [
                                { id: 'ENABLED', name: '<<enable>>' },
                                { id: 'DISABLED', name: '<<disabled>>' },
                            ],
                        },
                        excuteStatus: {
                            type: 'string.options.radio',
                            displayName: '<<activityStatus>>',
                            options: [
                                { id: 'NOT_STARTED', name: '<<notStarted>>' },
                                { id: 'STARTED', name: '<<inprogress>>' },
                                { id: 'COMPLETE', name: '<<end>>' },
                            ],
                        },
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<advName>>',
                    rules: [
                        {
                            max: 16,
                            message: '<<advTitleRequired>>',
                        },
                    ],
                },

                channel: {
                    type: 'string.statusBadge',
                    displayName: '<<applicableScope>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                    ],
                    controlConfig: {
                        style: { width: 140 },
                    },
                },
                showTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<showTime>>',
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
            },
        },
    },
    components: {
        OpenScreenAdsView: {
            component: 'Viewport',
            entity: 'OpenScreenAds',
        },
        OpenScreenAdsPage: {
            component: 'FlexLayout',
            className: 'open-screen-channels table-content-viewport',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'channel',
                        },
                        {
                            property: 'showTime',
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '<<addAdv>>',
                    route: '/open-screen-ads/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'OpenScreenAdsTable' },
            ],
        },

        OpenScreenAdsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), { total });
                },
            },
            columns: [
                {
                    property: 'information.name',
                },
                {
                    property: 'information.showTime',
                },
                {
                    property: 'rules.channels',
                },
                {
                    property: 'rules.info',
                },
                {
                    property: 'rules.excuteStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'status',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: ContentTopicStatus.DISABLE,
                                    apiPath: '/admin/splash_ads/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: ContentTopicStatus.ENABLE,
                                    apiPath: '/admin/splash_ads/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/open-screen-ads/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/open-screen-ads/log/{{row.id}}/SPLASH_ADS',
                    },

                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            id: 'row.id',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },

        OpenScreenAdsAddPage: {
            entity: 'OpenScreenAds',
            component: 'FlexLayout',
            items: [
                { component: 'OpenScreenAdsPreview' },
                {
                    component: 'OpenScreenAdsAddFormView',
                },
            ],
        },

        OpenScreenAdsEditPage: {
            component: 'FlexLayout',
            entity: 'OpenScreenAds',
            loaderType: 'get',
            items: [
                { component: 'OpenScreenAdsPreview' },
                {
                    component: 'OpenScreenAdsEditFormView',
                },
            ],
        },
        OpenScreenAdsLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/open-screen-ads',
                    breadcrumbName: '<<splashAds>>',
                    component: 'OpenScreenAdsView',
                    privilege: {
                        path: 'splashAds',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'OpenScreenAdsEditPage',
                            breadcrumbName: '<<editAdv>>',
                        },
                        {
                            path: '/add',
                            component: 'OpenScreenAdsAddPage',
                            breadcrumbName: '<<addAdv>>',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'OpenScreenAdsLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'OpenScreenAdsPage' },
                    ],
                },
            ],
        },
    ],
};
