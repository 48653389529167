const ORDER_STATUS = {
    WAITING_PICK: '待拣货',
    PICKING: '拣货中',
    FINISH_PICK: '已拣货',
    CANCELLED: '取消拣货',
};

enum ORDER_STATUS_ENUM {
    WAITING_PICK = 'WAITING_PICK',
    PICKING = 'PICKING',
    FINISH_PICK = 'FINISH_PICK',
    CANCELLED = 'CANCELLED',
}

export { ORDER_STATUS, ORDER_STATUS_ENUM };
