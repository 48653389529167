import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { services } from '@comall-backend-builder/core';
import { cloneDeep } from 'lodash';
import OverViewPage from './over-view-page/OverViewPage';
import MemberInfoPointPage from './member-info-point-page/MemberInfoPointPage';
import MemberInfoExpenseRecordPage from './member-info-expense-record-page/MemberInfoExpenseRecordPage';
import MemberInfoCouponPage from './member-info-coupon-page/MemberInfoCouponPage';
import MemberInfoExperiencePage from './member-info-experience-page/MemberInfoExperiencePage';
import MemberInfoTagsPage from './member-info-tags-page/MemberInfoTagsPage';
import MemberInfoSubsitePage from './member-info-subsite-page/MemberInfoSubsitePage';
import MemberPayAuthorizationDetail from './member-pay-authorization-page/MemberPayAuthorizationPage';

import './index.less';

const { api } = services;

export enum TabKey {
    /**
     * 概况
     */
    Overview = 'overview',
    /**
     * 公众号
     */
    WechatOfficialAccount = 'wechatOfficialAccount',
    /**
     * 优惠券
     */
    Coupon = 'coupon',
    /**
     * 积分
     */
    Point = 'point',
    /**
     * 消费记录
     */
    ExpenseRecord = 'expenseRecord',
    /**
     * 经验值
     */
    Experience = 'experience',
    /**
     * 会员画像
     */
    Tags = 'tags',
    /**
     * 门店会员信息
     */
    Subsite = 'subsite',
    /**
     * 支付授权
     */
    PayAuthorization = 'payAuthorization',
}
interface TabItem {
    key: TabKey;
    name: string;
}
const defaultTabsOptions: TabItem[] = [
    {
        key: TabKey.Overview,
        name: 'gk',
    },
    {
        key: TabKey.Tags,
        name: 'bqhx',
    },
    {
        key: TabKey.Coupon,
        name: 'yhq',
    },
    {
        key: TabKey.Point,
        name: 'point',
    },
    {
        key: TabKey.ExpenseRecord,
        name: 'customRecord',
    },
];

interface State {
    memberId: string;
    tabActiveKey: TabKey;
    pageParams: any;
    tabsOptions: TabItem[];
}

class memberInfoDetailPage extends PureComponent<any, State> {
    state = {
        memberId: '',
        tabActiveKey: TabKey.Overview,
        pageParams: {},
        tabsOptions: [],
    };

    componentDidMount() {
        const { entity } = this.props;
        this.initTabsOptions();
        this.setState({
            memberId: entity.params.id,
        });
    }

    private initTabsOptions = async () => {
        const response = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                apiPath: `/roles/super/privilege_set/experienceManage/verify`,
            }
        );
        const tabsOptions = cloneDeep(defaultTabsOptions);
        if (Boolean(response)) {
            tabsOptions.push({
                key: TabKey.Experience,
                name: 'experienceManage',
            });
        }
        tabsOptions.push({
            key: TabKey.PayAuthorization,
            name: 'zfsq',
        });
        if (ENV.TENANT === 'wfj' || ENV.TENANT === 'localhost') {
            tabsOptions.push({
                key: TabKey.Subsite,
                name: 'mdhyxx',
            });
        }
        this.setState({
            tabsOptions,
        });
    };

    private onChangeTabActiveKey = (key: TabKey, pageParams?: any) => {
        this.setState({
            tabActiveKey: key,
            pageParams,
        });
    };

    private renderTabs = () => {
        const { tabActiveKey, tabsOptions } = this.state;
        const content = tabsOptions.map((item: TabItem) => {
            const itemClass = classnames(`${prefix}__tabs__item`, {
                [`${prefix}__tabs__item--active`]: item.key === tabActiveKey,
            });
            return (
                <div
                    className={itemClass}
                    key={item.key}
                    onClick={this.onChangeTabActiveKey.bind(this, item.key)}
                >
                    {services.language.getText(item.name)}
                </div>
            );
        });
        return <div className={`${prefix}__tabs`}>{content}</div>;
    };

    private renderMain = () => {
        const { tabActiveKey, memberId, pageParams } = this.state;
        if (!memberId) {
            return null;
        }
        if (tabActiveKey === TabKey.Overview) {
            return <OverViewPage memberId={memberId} onChangeTab={this.onChangeTabActiveKey} />;
        } else if (tabActiveKey === TabKey.Point) {
            return <MemberInfoPointPage memberId={memberId} />;
        } else if (tabActiveKey === TabKey.Coupon) {
            return <MemberInfoCouponPage memberId={memberId} />;
        } else if (tabActiveKey === TabKey.ExpenseRecord) {
            return <MemberInfoExpenseRecordPage memberId={memberId} pageParams={pageParams} />;
        } else if (tabActiveKey === TabKey.Experience) {
            return <MemberInfoExperiencePage memberId={memberId} />;
        } else if (tabActiveKey === TabKey.Tags) {
            return <MemberInfoTagsPage memberId={memberId} />;
        } else if (tabActiveKey === TabKey.Subsite) {
            return <MemberInfoSubsitePage memberId={memberId} />;
        } else if (tabActiveKey === TabKey.PayAuthorization) {
            return <MemberPayAuthorizationDetail memberId={memberId} />;
        }
    };

    render() {
        return (
            <div className={prefix}>
                {this.renderTabs()}
                {this.renderMain()}
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const MemberInfoDetailPage = connect(mapStateToProps)(memberInfoDetailPage);

const prefix = 'member-info-detail-page';
