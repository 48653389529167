import { api } from '@comall-backend-builder/core/lib/services';
import { addTenantPrivilege } from './tenant-privilege';

/**
 * 当前租户是否支持支付宝小程序
 * 重新获取数据需刷新页面
 */
let supportAlipay = false;
/**
 * 当前租户是否支持会员打标操作
 * 重新获取数据需刷新页面
 */
let supportMemberMarking = false;

const getAlipayMiniprogramAppId = async () => {
    const response = await api.get<{ alipayMiniprogramAppId: string }>(
        {},
        {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: `/admin/front_config`,
        }
    );
    return Boolean(response?.alipayMiniprogramAppId);
};

const getMemberMarkingMiniprogramAppId = async () => {
    const response = await api.get(
        {},
        {
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            apiPath: `/roles/super/privilege_set/memberv2Tag/verify`,
        }
    );
    return Boolean(response);
};

const initSupportAlipay = async () => {
    const result = await getAlipayMiniprogramAppId();
    supportAlipay = result;
};

const initSupportMemberMarking = async () => {
    const result = await getMemberMarkingMiniprogramAppId();
    supportMemberMarking = result;
};

const getSupportAlipay = () => {
    return supportAlipay;
};
const getSupportMemberMarking = () => {
    return supportMemberMarking;
};

// addTenantPrivilege({
//     name: 'supportAlipay',
//     source: {
//         apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
//         apiPath: `/admin/front_config`,
//         transform: (response: any) => Boolean(response?.alipayMiniprogramAppId),
//     },
// });

// addTenantPrivilege({
//     name: 'supportMemberMarking',
//     source: {
//         apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
//         apiPath: `/roles/super/privilege_set/memberv2Tag/verify`,
//     },
// });
addTenantPrivilege({
    code: 'wechatPayScoreParking',
});

export { getSupportAlipay, initSupportAlipay, getSupportMemberMarking, initSupportMemberMarking };
