import React, { Component } from 'react';
import { Card } from 'antd';
import { cloneDeep, isFunction } from 'lodash';
import { SingleImageUpload } from '../single-image-upload';
import { LinkTypePlus } from '../../applications/design/components';
import { services } from '@comall-backend-builder/core';

import './index.less';
import { newClientCustomSelectorConfig } from '../../applications/design/design';
import { getNewLinkTypeEffectPathConfigList } from '../link-type-effect-path/config';

const { getText } = services.language;

interface PictureLinkTypeTarget {
    linkType: string;
    linkParams: any;
}

export interface PictureLinkTypeValue {
    picture?: string;
    link?: PictureLinkTypeTarget;
}

interface Props {
    name?: string;
    value: PictureLinkTypeValue;
    onChange: (value: PictureLinkTypeValue, name?: string) => void;
    getSubsiteIds?: (row: any) => string[];
    row: any;
}

const prefix = 'pictrue-link-type';

export class PictureLinkType extends Component<Props> {
    private get needSubsiteIds() {
        const { getSubsiteIds } = this.props;
        return isFunction(getSubsiteIds);
    }
    onFieldChange: <T extends keyof PictureLinkTypeValue>(
        field: T,
        fieldValue: PictureLinkTypeValue[T]
    ) => {} = (field, fieldValue) => {
        const { value = {}, onChange, name } = this.props;
        const newValue = cloneDeep(value || {});
        newValue[field] = fieldValue;
        onChange(newValue, name);
        return {};
    };
    newLinkTypeEffectPathConfigList = getNewLinkTypeEffectPathConfigList();
    getSubsiteIds = () => {
        const { getSubsiteIds, row } = this.props;
        return getSubsiteIds?.(row);
    };

    renderLinkTypeSelector = () => {
        const subsiteIds = this.getSubsiteIds();
        if (this.needSubsiteIds && !subsiteIds?.length) {
            return <span>{getText('selectBeforeMerchant')}</span>;
        }
        const { value } = this.props;
        const link = value?.link || {
            linkType: '',
            linkParams: null,
        };
        return (
            <LinkTypePlus
                selector={newClientCustomSelectorConfig as any}
                linkTypes={this.newLinkTypeEffectPathConfigList}
                linkTypeMode="cascader"
                value={link}
                onChange={(target: PictureLinkTypeTarget) => this.onFieldChange('link', target)}
                subsiteIds={subsiteIds}
            />
        );
    };

    render() {
        const { value } = this.props;
        const classNames = {
            container: prefix,
            linkType: `${prefix}__lint-type`,
        };
        return (
            <Card size="small">
                <div className={classNames.container}>
                    <SingleImageUpload
                        value={value?.picture}
                        onChange={(src) => this.onFieldChange('picture', src)}
                    />
                    <div className={classNames.linkType}>{this.renderLinkTypeSelector()}</div>
                </div>
            </Card>
        );
    }
}
