import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { Table, Button, Modal, Pagination } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { find } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const navigation = services.navigation;

class memberCardLevelTemplateTable extends PureComponent<any, any> {
    state = {
        datasource: [],
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
    };
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { pagination } = this.state;
        const res: any = await api.get(
            {
                ...pagination,
                organizationType: 'TENANT',
                scenario: 'TRADE',
                planType: 'TEMPLATE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/level_plans',
            }
        );
        this.setState({
            datasource: this.transformData(res.result),
            total: res.totalNum,
            current: res.page,
        });
    };

    transformData = (data: any) => {
        const datasource: any[] = [];
        if (data && data.length) {
            data.forEach((item: any) => {
                const defaultLevel = find(item.levelItems, { isDefault: true });
                item.levelItems.forEach((leveItem: any, index: number) => {
                    datasource.push({
                        id: item.id,
                        name: item.name,
                        levelCode: leveItem.levelCode,
                        levelId: leveItem.levelId,
                        levelName: leveItem.levelName,
                        styleIconUrl: leveItem.styleIconUrl,
                        styleBackgroundPictureUrl: leveItem.styleBackgroundPictureUrl,
                        status: item.status,
                        rowSpan: index === 0 ? item.levelItems.length : 0,
                        defaultLevel,
                    });
                });
            });
        }
        return datasource;
    };

    private navigationToEdit = (id: string) => {
        navigation.goto(`/card-level-manage/edit/${id}`);
    };

    private navigationToCard = async (row: any) => {
        const query = `levelPlanId=${row.id}&levelPlanItemId=${row.defaultLevel.id}&levelName=${row.defaultLevel.levelName}&backgroundUrl=${row.defaultLevel.styleBackgroundPictureUrl}`;
        try {
            const id: string = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/channel_card_templates',
                }
            );
            if (id) {
                navigation.goto(`/card-level-manage/channel-card/${id}?${query}`);
            } else {
                navigation.goto(`/card-level-manage/channel-card/add?${query}`);
            }
        } catch (error) {
            navigation.goto(`/card-level-manage/channel-card/add?${query}`);
        }
    };

    renderTable() {
        const { datasource } = this.state;
        const columns: ColumnProps<any>[] = [
            {
                title: language.getText('kdjmbmc_1'),
                dataIndex: 'name',
                fixed: 'left',
                className: `${prefix}__border`,
                render: (value: string, row: any, index: number) => {
                    const obj = {
                        children: value,
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
            {
                title: language.getText('djbm'),
                fixed: 'left',
                dataIndex: 'levelCode',
            },
            {
                title: language.getText('djmc'),
                fixed: 'left',
                dataIndex: 'levelName',
            },
            {
                title: language.getText('djtb'),
                dataIndex: 'styleIconUrl',
                render: (iconUrl: string) => (
                    <div className={`${prefix}__icon`}>
                        <img className={`${prefix}__icon`} src={iconUrl} alt=""></img>
                    </div>
                ),
            },
            {
                title: language.getText('djkm'),
                dataIndex: 'styleBackgroundPictureUrl',
                className: `${prefix}__border`,
                render: (iconUrl: string) => (
                    <div className={`${prefix}__back`}>
                        <img className={`${prefix}__back`} src={iconUrl} alt=""></img>
                    </div>
                ),
            },
            {
                title: language.getText('status'),
                dataIndex: 'status',
                className: `${prefix}__border`,
                render: (value: string, row: any, index: number) => {
                    const obj = {
                        children:
                            value === 'ENABLE'
                                ? language.getText('beforeOpen')
                                : language.getText('beforeClose'),
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                dataIndex: 'id',
                className: `${prefix}__border`,
                width: 280,
                render: (id: string, row: any, index: number) => {
                    const children = (
                        <div>
                            <Button
                                className="table-action-column-item"
                                type="link"
                                onClick={this.onChangeStatus.bind(this, id, row.name, row.status)}
                            >
                                {row.status === 'ENABLE'
                                    ? language.getText('common.close')
                                    : language.getText('common.opend')}
                            </Button>
                            <Button
                                className="table-action-column-item"
                                type="link"
                                onClick={this.navigationToEdit.bind(this, id)}
                            >
                                {language.getText('common.edit')}
                            </Button>
                            <Button
                                className="table-action-column-item"
                                type="link"
                                onClick={this.navigationToCard.bind(this, row)}
                            >
                                {services.language.getText('wxhyk')}
                            </Button>
                        </div>
                    );
                    const obj = {
                        children,
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
        ];
        return (
            <Table
                className="no-hover"
                scroll={{ x: true }}
                columns={columns}
                dataSource={datasource}
                pagination={false}
            />
        );
    }

    private onChangeStatus = async (id: string, name: string, status: string) => {
        Modal.confirm({
            content: `${language.getText('sf_1')}${
                status === 'ENABLE'
                    ? language.getText('common.close')
                    : language.getText('common.opend')
            }${name}`,
            onOk: () => {
                api.put(
                    {
                        status: status === 'ENABLE' ? 'DISABLE' : 'ENABLE',
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                        apiPath: `/admin/level_plans/${id}/status`,
                    }
                ).then(() => {
                    this.loadData();
                });
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
            width: 602,
        });
    };

    private pageChange = (pageVal: object = {}) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.loadData();
        });
    };

    render() {
        const { pagination, total, current } = this.state;
        const paginationCongid = {
            pageSize: pagination.perPage,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '15', '20'],
            total,
            current,
            showTotal(total: number) {
                return services.interpolate(services.language.getText('total'), {
                    total,
                });
            },
            onChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
        };
        return (
            <div className={prefix}>
                {this.renderTable()}
                <div className={`${prefix}__pagination`}>
                    <Pagination {...paginationCongid} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const MemberCardLevelTemplateTable = connect(mapStateToProps)(memberCardLevelTemplateTable);

const prefix = 'member-card-level-template-table';
