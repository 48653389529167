import React, { Component } from 'react';
import { Input, InputNumber } from 'antd';
import { EfutureCoupon, EfutureCouponSelector, EfutureCouponType } from './efuture-coupon-selector';
import './index.less';
import { DateTimeRangePickerPlus } from '../../../../components';
import { services } from '@comall-backend-builder/core';

export interface EfutureCouponChooseProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: EfutureCoupon | null;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: EfutureCoupon | null, name: string) => void;
    showChoose?: boolean;
}
export class EfutureCouponChoose extends Component<EfutureCouponChooseProps> {
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let defalutValue: EfutureCoupon = {
                code: '',
                name: '',
                typeName: '',
                couponType: undefined,
                couponValue: undefined,
                startTime: '',
                endTime: '',
                thresholdDescription: '',
            };
            if (onChange) {
                onChange(defalutValue, name);
            }
        }
    }

    onSelect = (selectValues: EfutureCoupon[]) => {
        const { name, value, onChange } = this.props;
        if (selectValues && value) {
            const newValue = {
                code: selectValues[0].code,
                name: selectValues[0].name,
                typeName: selectValues[0].typeName,
                couponType: selectValues[0].couponType,
                couponValue:
                    selectValues[0].couponType === EfutureCouponType.EFUTURE_CASH
                        ? selectValues[0].couponValue
                        : undefined,
                startTime: selectValues[0].startTime,
                endTime: selectValues[0].endTime,
                thresholdDescription: selectValues[0].thresholdDescription,
            };
            if (onChange) {
                onChange(newValue, name);
            }
        }
    };

    changeValue = (couponValue: any, valueName: string) => {
        const { name, value, onChange } = this.props;
        if (value && onChange) {
            if (valueName === 'name') {
                value.name = couponValue;
            }
            if (valueName === 'couponValue') {
                value.couponValue = couponValue;
            }
            if (valueName === 'thresholdDescription') {
                value.thresholdDescription = couponValue;
            }
            onChange(value, name);
        }
    };

    changeTime = (time: any) => {
        const { name, value, onChange } = this.props;
        if (value && onChange) {
            value.startTime = time && time.start ? time.start : undefined;
            value.endTime = time && time.end ? time.end : undefined;
            onChange(value, name);
        }
    };

    render() {
        const { value, showChoose } = this.props;
        const selectValues: EfutureCoupon[] = [];
        if (value) {
            selectValues.push(value);
        }
        return (
            <div className="efuture-coupon-choose">
                {showChoose && (
                    <EfutureCouponSelector
                        selectValues={selectValues}
                        onChange={this.onSelect}
                        type="radio"
                    />
                )}
                <div className="efuture-coupon-choose__item">
                    <span className="efuture-coupon-choose__item-text">
                        {services.language.getText('qlx')}
                    </span>
                    <Input
                        style={{ width: '300px', margin: '0 10px' }}
                        disabled
                        type="string"
                        value={value ? value.typeName : ''}
                    />
                </div>
                <div className="efuture-coupon-choose__item">
                    <span className="efuture-coupon-choose__item-text">
                        {services.language.getText('qbm')}
                    </span>
                    <Input
                        style={{ width: '300px', margin: '0 10px' }}
                        disabled
                        type="string"
                        value={value ? value.code : ''}
                    />
                </div>
                <div className="efuture-coupon-choose__item">
                    <span className="efuture-coupon-choose__item-text">
                        <span className="efuture-coupon-choose__red">*</span>
                        {services.language.getText('qmc')}
                    </span>
                    <Input
                        style={{ width: '300px', margin: '0 10px' }}
                        type="string"
                        value={value ? value.name : ''}
                        onChange={(value: any) => this.changeValue(value.target.value, 'name')}
                    />
                </div>
                <div className="efuture-coupon-choose__item">
                    <span className="efuture-coupon-choose__item-text">
                        <span className="efuture-coupon-choose__red">*</span>
                        {services.language.getText('qmz')}
                    </span>
                    <InputNumber
                        style={{ width: '300px', margin: '0 10px' }}
                        disabled={
                            value && value.couponType === EfutureCouponType.EFUTURE_BALANCE
                                ? false
                                : true
                        }
                        type="number"
                        min={0}
                        max={999999.99}
                        step={0.01}
                        precision={2}
                        value={value ? value.couponValue : undefined}
                        onChange={(value: any) => this.changeValue(value, 'couponValue')}
                    />
                </div>
                <div className="efuture-coupon-choose__item">
                    <span className="efuture-coupon-choose__item-text">
                        {services.language.getText('useThreshold')}
                    </span>
                    <Input
                        style={{ width: '300px', margin: '0 10px' }}
                        type="string"
                        value={value ? value.thresholdDescription : ''}
                        maxLength={20}
                        onChange={(value: any) =>
                            this.changeValue(value.target.value, 'thresholdDescription')
                        }
                    />
                </div>
                <div className="efuture-coupon-choose__item">
                    <span className="efuture-coupon-choose__item-text">
                        <span className="efuture-coupon-choose__red">*</span>
                        {services.language.getText('effectiveTime')}
                    </span>
                    <DateTimeRangePickerPlus
                        allowClear
                        style={{
                            margin: '0 10px',
                            width: '400px',
                        }}
                        value={
                            value && value.startTime && value.endTime
                                ? { start: value.startTime, end: value.endTime }
                                : undefined
                        }
                        onChange={(value: any) => this.changeTime(value)}
                    />
                </div>
            </div>
        );
    }
}
