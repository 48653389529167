import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ObjectImageRadioPrint } from './image-radio-print';

export class ObjectImageRadioPrintMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ObjectImageRadioPrint {...displayInfo} />;
    }
}
