import React, { useState, useEffect, useCallback } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { message } from 'antd';
import { PrivilegeTree, PrivilegeTreeProps } from '../privilege-management/privilege-tree';
import { errorHandle } from '../../services/error-handle';
import './index.less';

const { navigation, api } = services;

interface EntityPrivilege extends PrivilegeTreeProps {
    id: string;
}

interface PrivilegeTreeNode {
    id: string;
    name: string;
    enable: boolean;
    isLeaf: boolean;
    children: Array<PrivilegeTreeNode> | null;
}

interface Entity {
    id: string;
    name: string;
}

function getValue(nodes: PrivilegeTreeNode[]) {
    let keys: string[] = [];
    for (let { id, enable, children } of nodes) {
        if (enable) {
            keys.push(id);
        }
        if (children?.length) {
            keys = keys.concat(getValue(children));
        }
    }
    return keys;
}

export const MerchantPrivilegeManagement: React.FunctionComponent<ExtendedParentComponentProps> = ({
    params,
}) => {
    const roleId = params?.id;
    const [initialized, setInitialized] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [entities, setEntities] = useState<Array<EntityPrivilege>>([
        {
            id: 'functionPrivilege',
            name: '功能权限',
            value: [],
            options: [],
            onChange: (value: string[]) => {
                setEntities((prevState) => {
                    let entity = prevState[0];
                    let nextState = prevState.concat();
                    nextState.splice(0, 1, { ...entity, value });
                    return nextState;
                });
            },
        },
    ]);

    useEffect(() => {
        const initializePromises: Promise<any>[] = [];
        const functionPrivilegePromise = api.get<PrivilegeTreeNode[]>(
            { roleId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                apiPath: '/admin/roles/mine/:roleId/function_privileges_tree',
            }
        );
        functionPrivilegePromise.then((data) => {
            setEntities((prevState) => {
                let entity = prevState[0];
                let nextState = prevState.concat();
                nextState.splice(0, 1, { ...entity, value: getValue(data), options: data });
                return nextState;
            });
        }, errorHandle);
        initializePromises.push(functionPrivilegePromise);

        api.get<Entity[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                apiPath: '/admin/entities/has_Record_privilege',
            }
        ).then((data) => {
            if (!data) {
                return;
            }

            setEntities((prevState) => {
                let nextState = prevState.concat();
                data.forEach(({ id, name }, index) => {
                    nextState.push({
                        id,
                        name: `数据权限-${name}`,
                        value: [],
                        options: [],
                        onChange: (value: string[]) => {
                            setEntities((prevState) => {
                                let entity = prevState[index + 2];
                                let nextState = prevState.concat();
                                nextState.splice(index + 2, 1, { ...entity, value });
                                return nextState;
                            });
                        },
                    });
                });
                return nextState;
            });

            data.forEach(({ id }, index) => {
                let promise = api.get<PrivilegeTreeNode[]>(
                    { roleId, entityId: id },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                        apiPath: '/admin/roles/:roleId/entities/:entityId/record_privileges',
                    }
                );
                promise.then((data) => {
                    setEntities((prevState) => {
                        let entity = prevState[index + 2];
                        let nextState = prevState.concat();
                        nextState.splice(index + 2, 1, {
                            ...entity,
                            value: getValue(data),
                            options: data,
                        });
                        return nextState;
                    });
                }, errorHandle);
                initializePromises.push(promise);
            });
            Promise.all(initializePromises).then(() => {
                setInitialized(true);
            });
        }, errorHandle);
    }, [roleId]);

    const handleSubmit = useCallback(() => {
        setSubmitting(true);
        const submitPromises = [];

        const functionPrivilegePromise = api.put(
            { roleId, ids: entities[0].value },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                apiPath: '/admin/roles/:roleId/function_privileges',
            }
        );
        functionPrivilegePromise.catch(errorHandle);
        submitPromises.push(functionPrivilegePromise);

        for (let i = 2; i < entities.length; i++) {
            const { id, value } = entities[i];
            const promise = api.put(
                { roleId, entityId: id, ids: value },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                    apiPath: '/admin/roles/:roleId/entities/:entityId/record_privileges',
                }
            );
            promise.catch(errorHandle);
            submitPromises.push(promise);
        }

        Promise.all(submitPromises).then(
            () => {
                message.success(services.language.getText('common.saveSuccess'));
                setSubmitting(false);
                navigation.goto('/merchant-role-manage');
            },
            () => {
                setSubmitting(false);
            }
        );
    }, [roleId, entities]);

    return (
        <>
            <div className="layout privilege-tree-container">
                {entities.map((entity) => (
                    <PrivilegeTree {...entity} key={entity.id}></PrivilegeTree>
                ))}
            </div>
            <div className="fixed-footer-toolbar">
                <Button type="default" onClick={navigation.goBack} style={{ marginRight: 12 }}>
                    取消
                </Button>
                <Button
                    type="primary"
                    disabled={!initialized}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    提交
                </Button>
            </div>
        </>
    );
};
