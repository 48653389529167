import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const { api } = services;

export const recruitDistributorLoader = {
    get: async function(data: any, config: ApiRequestConfig) {
        //TODO-获取风格，后面需要写入全局
        config.apiPath = '/admin/theme_style';
        const themeStyle = await api.get({}, config).then((res: any) => {
            if (res.id) {
                return JSON.parse(res.config);
            }
            return {};
        });

        config.apiPath = '/admin/page/distributor';
        data.relation_subsite = false;
        return api.get(data, config).then((res: any) => {
            if (res?.id && res?.config) {
                const config = JSON.parse(res.config) || {};

                res = {
                    id: res.id,
                    recruitDistributor: {
                        backgroundImage: config?.components?.recruitDistributorAdv?.title?.image,
                        show:
                            config?.components?.recruitDistributorAdv?.electronicProtocol?.show ||
                            config?.components?.recruitDistributorAdv?.electronicProtocolShow,
                        title: config?.components?.recruitDistributorAdv?.electronicProtocol?.title,
                        image: config?.components?.recruitDistributorAdv?.electronicProtocol?.image,
                    },
                    share: {
                        title: config?.root?.share?.title,
                        description: config?.root?.share?.description,
                        pic: config?.root?.share?.pic,
                    },
                    themeStyle: themeStyle,
                };
                return res;
            }
            //空数据兼容
            const temp = {
                recruitDistributor: {
                    backgroundImage: '',
                    show: 'show',
                    title: services.language.getText('zmfxy'),
                    image: '',
                },
                share: {
                    title: '',
                    description: '',
                    pic: '',
                },
                themeStyle: themeStyle,
            };
            return temp;
        });
    },
    put: async function(data: any, config: ApiRequestConfig) {
        if (data.id) {
            //获取原始数据
            config.apiPath = '/admin/page/distributor';
            const distributorData = await api
                .get({ relation_subsite: false }, config)
                .then((res: any) => {
                    return res;
                });

            config.apiPath = '/admin/pages/DISTRIBUTOR/' + data.id;
            let datalist = distributorData;
            let dataConfig: any = JSON.parse(distributorData.config) || {};

            dataConfig.components.recruitDistributorAdv.title.image =
                data?.recruitDistributor?.backgroundImage || '';
            dataConfig.components.recruitDistributorAdv.electronicProtocol.show =
                data?.recruitDistributor?.show || 'show';
            dataConfig.components.recruitDistributorAdv.electronicProtocol.title =
                data?.recruitDistributor?.title || '';
            dataConfig.components.recruitDistributorAdv.electronicProtocol.image =
                data?.recruitDistributor?.image || '';

            dataConfig.root.share.title = data.share.title || '';
            dataConfig.root.share.description = data.share.description || '';
            dataConfig.root.share.pic = data.share.pic || '';

            datalist.config = JSON.stringify(dataConfig);

            return api.put(datalist, config);
        } else {
            config.apiPath = '/admin/pages/DISTRIBUTOR';
            let datalist = {
                template: null,
                name: '招募分销员',
                type: 'distributor',
                subsites: [],
                group_subsite_name: '',
                config: {},
                enabled: true,
                decoration: false,
                create_user: 'guokeke',
                effective_time: null,
                effective_state: '2',
                structure_fixed: false,
                merchant_id: null,
                effective_start_time: null,
                effective_end_time: null,
                code: null,
                preview_mode: 'ONLY_PREVIEW',
                h5_domain: null,
            };
            let dataConfig: any = {
                metainfo: { default: false, type: 'distributor', title: '招募分销员' },
                components: {
                    recruitDistributorAdv: {
                        component: 'recruitDistributorAdv',
                        title: { image: '' },
                        electronicProtocol: { show: 'show', image: '', title: '' },
                    },
                },
                root: {
                    share: { title: '', description: '', pic: '' },
                    backgroundColor: { color: '#fff' },
                    items: [{ component: 'recruitDistributorAdv' }],
                },
            };
            dataConfig.components.recruitDistributorAdv.title.image =
                data?.recruitDistributor?.backgroundImage || '';
            dataConfig.components.recruitDistributorAdv.electronicProtocol.show =
                data?.recruitDistributor?.show || 'show';
            dataConfig.components.recruitDistributorAdv.electronicProtocol.title =
                data?.recruitDistributor?.title || '';
            dataConfig.components.recruitDistributorAdv.electronicProtocol.image =
                data?.recruitDistributor?.image || '';

            dataConfig.root.share.title = data.share.title || '';
            dataConfig.root.share.description = data.share.description || '';
            dataConfig.root.share.pic = data.share.pic || '';

            datalist.config = JSON.stringify(dataConfig);
            return api.post(datalist, config);
        }
    },
};
