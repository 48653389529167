import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { NewMallActivityTickets } from '../../../../components';

export class ObjectNewMallActivityTickets extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <NewMallActivityTickets {...controlInfo} />;
    }
}
