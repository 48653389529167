import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const DistributionActivitiesNewLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/distribution_activities';
        const { id, status, dateRange } = data;

        if (status && status === 'ALL') {
            delete data.status;
        }
        if (!isEmpty(dateRange)) {
            data.startTime = dateRange.start + ' 00:00:00';
            data.endTime = dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        const type =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (type) delete config.paramsFilter;
        if (type && !id) data.type = type;
        if (id) {
            config.apiPath += `/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                };
                res.ruleInfo = {
                    selectMode: res.merchants && res.merchants.length > 0 ? 'MERCHANT' : 'SUB_SITE',
                    merchants: res.merchants.map((merchant: any) => {
                        return {
                            ...merchant,
                            merchantId: merchant.id,
                            merchantName: merchant.merchantName,
                        };
                    }),
                    subsites: res.subsites,
                    activityProductType: 'INCLUDE_PRODUCT',
                };

                if (res.goodsDistributionInfoList && res.goodsDistributionInfoList.length > 0) {
                    res.ruleInfo.goodsDistributionInfoList = {
                        type: 'APPOINT_PRODUCT',
                        values: res.goodsDistributionInfoList.map((item: any) => {
                            return {
                                ...item,
                                name: item.productName,
                                id: item.id,
                                code: item.productCode,
                                commissionValue: (
                                    (item.salesPrice * item.commissionInfos[0].percentage * 100) /
                                    100
                                ).toFixed(2),
                            };
                        }),
                    };
                }
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        canEdit: !item.enabled,
                        nameStr: item.name,
                    };
                });
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        if (config.paramsFilter && config.paramsFilter.length > 0) {
            delete config.paramsFilter;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/distribution_activities/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        const type =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (type) {
            delete config.paramsFilter;
            params.type = type;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/distribution_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const { baseInfo, ruleInfo } = params;
    const newParams: any = {};
    newParams.type = 'DISTRIBUTOR';
    if (baseInfo) {
        newParams.name = baseInfo.name;
        newParams.startTime = baseInfo.dateRange.start;
        newParams.endTime = baseInfo.dateRange.end;
    }
    if (ruleInfo) {
        const selectMode = params.ruleInfo.selectMode;
        if (selectMode === 'SUB_SITE' && !isEmpty(params.ruleInfo.subsites)) {
            newParams.selectedSubsites = params.ruleInfo.subsites;
            newParams.subsiteIds = params.ruleInfo.subsites.map((value: any) => value.id);
        }
        if (selectMode === 'MERCHANT' && !isEmpty(params.ruleInfo.merchants)) {
            newParams.merchants = params.ruleInfo.merchants.map((merchant: any) => ({
                subsiteId: merchant.subSiteId,
                merchantId: merchant.merchantId,
            }));
        }
        if (selectMode === 'TAG' && !isEmpty(params.ruleInfo.tags)) {
            newParams.merchants = params.ruleInfo.tags.map((tag: any) => ({
                subsiteId: tag.subsiteId,
                merchantId: tag.id,
            }));
        }
        newParams.goodsDistributionInfoList = params.ruleInfo.goodsDistributionInfoList.values;
    }
    return newParams;
}
