import React, { PureComponent } from 'react';
import {
    Button as AntButton,
    Popover as AntPopover,
    DatePicker,
    message as AntMessage,
} from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';
import { ForwardDataCenterModal } from '../../services';
import './index.less';
const api = services.api;

const { RangePicker } = DatePicker;
export enum ExportFilterType {
    TIME = 'TIME',
}

/**
 * 导出数据
 */
export class ExportDataButtonByFilter extends PureComponent<any, any> {
    state = {
        time: [],
    };
    static defaultProps = {};
    onDownLoad = () => {
        const { params, row } = this.props;
        const { time } = this.state;
        let { apiRoot, apiPath, isAsync, getExtraParams } = params;
        apiPath = apiPath.replace('{{row.id}}', row.id);
        const data: any = { ...getExtraParams?.(row) };
        if (time[0] && time[1]) {
            data.startTime = time[0] + ' 00:00:00';
            data.endTime = time[1] + ' 23:59:59';
        }

        if (isAsync) {
            api.post(data, { apiRoot, apiPath })
                .then((res) => {
                    ForwardDataCenterModal();
                })
                .catch((err) => {
                    AntMessage.error(err.response.body.err_msg);
                    throw err;
                });
        } else {
            api.download(data, {
                apiRoot: apiRoot,
                apiPath: apiPath,
                customHeaders: { 'content-disposition': 'attachment;filename="filename.xls"' },
            }).catch(errorHandle);
        }
    };
    timeChange = (date: any, dateString: any) => {
        this.setState({ time: dateString });
    };
    renderContent = () => {
        const { filters } = this.props.params;
        const showTime = filters && filters.indexOf(ExportFilterType.TIME) >= 0;
        return (
            <div className="export-button-filter">
                {!!showTime && (
                    <div className="filter-item">
                        <div className="label">{services.language.getText('qxzsjsjd')}:</div>
                        <RangePicker size="small" onChange={this.timeChange} format="YYYY-MM-DD" />
                    </div>
                )}
                <div className="button">
                    <AntButton
                        type="primary"
                        onClick={this.onDownLoad}
                        style={{ padding: 0, width: 60 }}
                    >
                        {services.language.getText('dc')}
                        {showTime}
                    </AntButton>
                </div>
            </div>
        );
    };
    render() {
        const { row, params } = this.props;

        if (row.canExport === undefined) {
            row.canExport = true;
        }

        return row.canExport ? (
            <AntPopover placement="bottomRight" trigger="click" content={this.renderContent()}>
                <AntButton
                    type="link"
                    style={{ padding: 0 }}
                    className="table-action-column-item span-link"
                >
                    {params.text ? params.text : services.language.getText('dcbb')}
                </AntButton>
            </AntPopover>
        ) : (
            ''
        );
    }
}
