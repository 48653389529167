import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { Popover } from 'antd';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
/**
 *  商场活动详情
 */
export class OpenScreenInfo extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getDisplayComponent(value: any) {
        if (!value) return <div></div>;
        const actionType: any = {
            BROWSE: language.getText('browse'),
            CLICK: language.getText('click'),
        };
        const channelType: any = {
            WECHAT: language.getText('wxMiniP'),
            ALIPAY: language.getText('aliMiniP'),
            APP: 'APP',
        };
        return (
            <div className="mall-activity-info">
                <div className="mall-activity-info__detail">
                    {value &&
                        value.map((item: any) => {
                            const d = item.data;
                            if (d?.persons?.channelData || d?.times?.channelData) {
                                let timeslDom: any = null;
                                let personsDom: any = null;
                                if (d?.times?.channelData && d?.times?.channelData.length) {
                                    const timesPop =
                                        d?.times?.channelData &&
                                        d?.times?.channelData.map((t: any) => {
                                            return (
                                                <div>
                                                    {channelType[t.channel]}:{t.count}
                                                </div>
                                            );
                                        });

                                    timeslDom = (
                                        <div>
                                            <Popover
                                                style={{ zIndex: 1030 }}
                                                placement="right"
                                                content={timesPop}
                                            >
                                                <div style={{ color: '#1890ff' }}>
                                                    {actionType[item.action]}
                                                    {language.getText('frequencyNum')}
                                                    {d?.times?.total}
                                                </div>
                                            </Popover>
                                        </div>
                                    );
                                } else {
                                    timeslDom = (
                                        <div>
                                            {actionType[item.action]}
                                            {language.getText('frequencyNum')}
                                            {d?.times?.total}
                                        </div>
                                    );
                                }
                                if (d?.persons?.channelData && d?.persons?.channelData.length) {
                                    const persons =
                                        d?.persons?.channelData &&
                                        d?.persons?.channelData.map((p: any) => {
                                            return (
                                                <div>
                                                    {channelType[p.channel]}:{p.count}
                                                </div>
                                            );
                                        });

                                    personsDom = (
                                        <div>
                                            <Popover
                                                style={{ zIndex: 1030 }}
                                                placement="right"
                                                content={persons}
                                            >
                                                <div style={{ color: '#1890ff' }}>
                                                    {actionType[item.action]}
                                                    {language.getText('peopleNum')}
                                                    {d?.persons?.total}
                                                </div>
                                            </Popover>
                                        </div>
                                    );
                                } else {
                                    timeslDom = (
                                        <div>
                                            {actionType[item.action]}
                                            {language.getText('peopleNum')}
                                            {d?.persons?.total}
                                        </div>
                                    );
                                }
                                return (
                                    <div>
                                        {timeslDom}
                                        {personsDom}
                                    </div>
                                );
                            }
                            return (
                                <div>
                                    <div>
                                        {actionType[item.action]}
                                        {language.getText('peopleNum')}
                                        {d?.times?.total}
                                    </div>
                                    <div>
                                        {actionType[item.action]}
                                        {language.getText('peopleNum')}
                                        {d?.persons?.total}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}
