import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { ActivityTag } from '../mall-activity-tag-selector';
import { PaginationConfig } from 'antd/lib/table/interface';
import { services } from '@comall-backend-builder/core';

interface MallActivityTagDisplayProps {
    data: Array<ActivityTag>;
    onRemove: (data: Array<ActivityTag>) => void;
    disabled?: boolean;
}
interface MallActivityTagDisplayState {
    selectedRows: Array<ActivityTag>;
}

class MallActivityTagDisplay extends PureComponent<
    MallActivityTagDisplayProps,
    MallActivityTagDisplayState
> {
    getTableConfig = () => {
        const { disabled } = this.props;
        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: '标签名称',
                    dataIndex: 'name',
                    key: 'name',
                },

                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        return tableConfig;
    };

    onRemove = (row: ActivityTag) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onRemove(result);
        };
    };

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination,
        };

        return <Table {...tableProps}></Table>;
    }
}

export { MallActivityTagDisplay };
