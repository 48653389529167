import React, { PureComponent } from 'react';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { DesignPage } from '@comall-backend-builder/design-components-basis';
import { CustomComponentSelector } from '../../components/custom-component-selector';
import { DESIGN_PAGE_LINK_TYPES_LIST } from '../../applications/design/constants/page-design';
import './index.less';

const api = services.api;

const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;

export class RecruitDistributor extends PureComponent<any, any> {
    isNew = false;
    loadProfile = () => {
        return api
            .get({ relation_subsite: false }, { apiPath: '/admin/page/distributor' })
            .then((res: any) => {
                const temp = {
                    metainfo: {
                        title: '',
                        default: false,
                        type: 'distributor',
                    },
                    components: {
                        recruitDistributorAdv: {
                            component: 'recruitDistributorAdv',
                        },
                    },

                    root: {
                        share: {
                            title: '',
                            description: '',
                            pic: '',
                        },
                        backgroundColor: { color: '#fff' },
                        items: [{ component: 'recruitDistributorAdv' }],
                    },
                };
                if (!res) {
                    this.isNew = true;
                    res = {
                        config: JSON.stringify(temp),
                        type: 'distributor',
                    };
                }

                return res;
            });
    };
    onSave = (data: any) => {
        if (!this.isNew) {
            const id = data.id;
            api.put(data, { apiPath: `/admin/pages/DISTRIBUTOR/${id}` }).then(() => {
                const saveSuccess = services.language.getText('common.saveSuccess');
                message.success(saveSuccess);
            });
        } else {
            data.name = '招募分销员';
            data.enabled = true;
            api.post(data, { apiPath: '/admin/pages/DISTRIBUTOR' }).then(() => {
                const saveSuccess = services.language.getText('common.saveSuccess');
                message.success(saveSuccess);
            });
        }
    };
    onCancel = () => {
        services.navigation.goBack();
    };

    getClientUrl = (profile: any, fixed: boolean) => {
        const client = ENV.DESIGN_CLIENT_URL;
        let url = client + `?page=WYSIWG&mode=edit`;
        if (profile) {
            url =
                url +
                `&type=${profile.metainfo.type}&fixed=${true}&tenant=${encodeURIComponent(
                    tenantDomain
                )}`;
        }
        return url;
    };
    render() {
        const submitProps = {
            onClick: this.onSave,
        };
        const cancelProps = {
            onClick: this.onCancel,
        };
        // return <div>111111</div>;
        return (
            <DesignPage
                clientUrl={this.getClientUrl}
                loadProfile={this.loadProfile}
                componentsSelector={(props) => <CustomComponentSelector {...props} />}
                submit={submitProps}
                cancel={cancelProps}
                verbose={process.env.NODE_ENV === 'development'}
                isTemplate={false}
                linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                linkTypeMode={'cascader'}
                uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
            ></DesignPage>
        );
    }
}
