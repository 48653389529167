import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CollectionPointsPicture } from './collection-points-picture';

export class CollectionPointsPictureMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CollectionPointsPicture {...displayInfo} />;
    }
}
