import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ChargeBenefitLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const type = 'CHARGE_BENEFITS';
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/member_benefits?type=${type}`;
        const { id } = data;

        if (id) {
            config.apiPath = `/admin/member_benefits/${id}?type=${type}`;
        }
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const chargeBenefit = res?.chargeBenefitsVo;
                if (!chargeBenefit.chargeDiscountLimit?.memberCardLevelBenefit) {
                    chargeBenefit.chargeDiscountLimit = {
                        ...chargeBenefit.chargeDiscountLimit,
                        memberCardLevelBenefit: [
                            {
                                memberCardLevelId: null,
                                everydayBenefit: null,
                                conversionBenefit: null,
                                conversionMax: null,
                            },
                        ],
                    };
                } else {
                    if (
                        chargeBenefit.chargeDiscountLimit?.memberCardLevelBenefit &&
                        chargeBenefit.chargeDiscountLimit?.memberCardLevelBenefit.length
                    ) {
                        chargeBenefit.chargeDiscountLimit?.memberCardLevelBenefit.forEach(
                            (item: any) => {
                                item.memberCardLevelId = item.memberCardLevelId + '';
                            }
                        );
                    }
                }

                if (res) {
                    res.baseInfo = {
                        subsiteId: res.subsiteId,
                        subsiteName: res.subsiteName,
                        deductionMode: chargeBenefit.deductionMode,
                    };
                    res.benefitRule = {
                        firstBenefit: chargeBenefit.firstBenefit,
                        firstDiscountHour: chargeBenefit.firstDiscount,
                        firstDiscountMoney: chargeBenefit.firstDiscount,
                        gradeEquity: chargeBenefit.chargeDiscountLimit,
                    };
                    res.benefitInfo = {
                        explain: chargeBenefit.explain,
                    };
                }
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/member_benefits`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/member_benefits`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let chargeBenefits: any = {};

    chargeBenefits.chargeDiscountLimitCommand = {
        cardLevelBenefit: params.benefitRule.gradeEquity?.cardLevelBenefit,
        memberCardLevelBenefit: params.benefitRule.gradeEquity?.memberCardLevelBenefit,
    };
    chargeBenefits.deductionMode = params.baseInfo.deductionMode;
    chargeBenefits.explain = params.benefitInfo.explain;
    chargeBenefits.firstBenefit = params.benefitRule.firstBenefit;
    chargeBenefits.firstDiscount =
        params.baseInfo.deductionMode === 'MONEY'
            ? params.benefitRule.firstDiscountMoney
            : params.benefitRule.firstDiscountHour;
    const newParams = {
        consumeChargeBenefits: chargeBenefits,
        subsiteId: params.baseInfo.subsiteId,
        type: 'CHARGE_BENEFITS',
        id: params.id,
    };

    return newParams;
}
