import React, { PureComponent } from 'react';
import { Checkbox, Row, Col } from 'antd';

export class ShowContent extends PureComponent<any> {
    select = (showContent: any) => {
        const { onChange } = this.props;
        onChange(showContent);
    };

    render() {
        const { value } = this.props;
        return (
            <Checkbox.Group style={{ width: '100%' }} value={value} onChange={this.select}>
                <Row className="show-content">
                    <Col span={24}>
                        <Checkbox value="title">活动名称</Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="time">活动时间</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        );
    }
}
