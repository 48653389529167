import React, { Component } from 'react';
import { InputNumber, Button, Popconfirm, Checkbox, Table } from 'antd';
import {
    // findIndex,
    // toString,
    remove,
} from 'lodash';
import {
    PayWithGiftRewardActivitySelector,
    SelectType,
} from './pay-with-gift-reward-select-activity';
import { RewardItems } from './pay-with-gifts-reward';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export interface Activity {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    executeStatus: string;
    eventType: SelectType;
    quantity?: number;
}

interface ActivityRewardProps {
    index: number;
    subsiteIds: any;
    value: Array<Activity> | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<Activity>, index: number) => void;
    activityChecked: boolean;
    onCheckboxChange: (value: any, index: number, type: string) => void;
}

export class PayWithGiftRewardActivitys extends Component<ActivityRewardProps, any> {
    static defaultProps = {
        disabled: false,
    };
    PayWithGiftRewardActivitySelector: any;
    constructor(props: any) {
        super(props);
        this.state = {
            goNextpage: null,
        };
    }

    componentDidMount() {
        let { value, onChange, index } = this.props;
        if (!value) {
            onChange([], index);
        }
    }

    onChooseActivity = () => {
        this.PayWithGiftRewardActivitySelector.toggleModal();
    };
    showModel = (ref: any) => {
        this.PayWithGiftRewardActivitySelector = ref;
    };
    // setDisplayType = (row: Activity[]) => {
    //     let displayType = SelectType.WheelDraw;
    //     if (row.length && row[0]?.eventType) {
    //         displayType = row[0]?.eventType;
    //     }
    //     return displayType;
    // };
    changeActivitys = (activitys: Array<any>, type: string) => {
        //支持多个选择时
        // let { value, onChange, index } = this.props;
        // let newActivitys: any = value ? value : [];
        // if (this.setDisplayType(newActivitys) !== type) {
        //     newActivitys = [];
        // }
        // activitys.forEach((selectActivity: any) => {
        //     const hasIndex = findIndex(
        //         newActivitys,
        //         (activity: any) => toString(activity.id) === toString(selectActivity.id)
        //     );
        //     if (hasIndex === -1) {
        //         newActivitys.push(selectActivity);
        //     }
        // });
        // if (onChange) {
        //     onChange(newActivitys, index);
        // }
        //支持单个选择时
        let { onChange, index } = this.props;
        const newActivitys =
            activitys && activitys.length > 0
                ? activitys.map((activity: Activity) => {
                      return {
                          ...activity,
                          quantity: 1,
                      };
                  })
                : [];
        if (onChange) {
            onChange(newActivitys, index);
        }
    };
    changeModal = () => {
        const { value, subsiteIds } = this.props;
        const params = {
            subsiteIds: subsiteIds,
        };
        return (
            <PayWithGiftRewardActivitySelector
                onRef={this.showModel}
                selectValues={value || []}
                rewardIndex={0}
                onChange={this.changeActivitys}
                params={params}
            />
        );
    };
    //
    // onChangeActivity = (products: Activity[]) => {
    //     let { onChange, index } = this.props;
    //     if (onChange) {
    //         onChange(products, index);
    //     }
    // };

    changeStock = (activityIndex: number, valueNum: number) => {
        const { value, onChange, index } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[activityIndex];

        if (rowItem) {
            rowItem.quantity = valueNum;
            if (onChange) {
                onChange(value, index);
            }
        }
    };
    deleteActivityItem = (activityIndex: number) => {
        const { value, onChange, index } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[activityIndex];
        if (rowItem) {
            remove(value, function(rowItemActivity: Activity) {
                return rowItemActivity.id === rowItem.id;
            });
            if (onChange) {
                onChange(value, index);
            }
        }
    };
    showActivityItem = () => {
        const { value } = this.props;
        if (!value || value.length === 0) {
            return null;
        }
        const tableConfig: any = {
            dataSource: value,
            pagination: false as const,
            loading: false,
            columns: [
                {
                    title: services.language.getText('activityName'),
                    dataIndex: 'name',
                    key: 'name',
                    render: (value: any) => {
                        return (
                            <div className="ant-table-cell-ellipsis" style={{ width: '150px' }}>
                                {value}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('acitivityTime'),
                    dataIndex: 'startTime',
                    key: 'startTime',
                    render: (startTime: any, record: any) => {
                        return (
                            <div style={{ width: '170px' }}>
                                {startTime} ～ {record.endTime}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('mrhd'),
                    dataIndex: 'quantity',
                    key: 'quantity',
                    render: (experienceValue: any, record: any, index: number) => {
                        return (
                            <div>
                                <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={record.quantity}
                                    min={1}
                                    max={999999999}
                                    onChange={(value: any) => this.changeStock(index, value)}
                                ></InputNumber>
                                &nbsp;{services.language.getText('frequency')}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    key: 'action',
                    render: (value: any, record: any, index: number) => {
                        return (
                            <Popconfirm
                                title={`${services.language.getText('qrsc')}${record.name}?`}
                                onConfirm={() => {
                                    this.deleteActivityItem(index);
                                }}
                                okText={services.language.getText('common.confirm')}
                                cancelText={language.getText('common.cancel')}
                            >
                                <Button type="link">
                                    {services.language.getText('common.delete')}
                                </Button>
                            </Popconfirm>
                        );
                    },
                },
            ],
        };
        return (
            <div>
                <Table {...tableConfig}></Table>
            </div>
        );
    };

    render() {
        const { index, activityChecked, onCheckboxChange } = this.props;
        return (
            <div className="comment-activity-reward">
                <Checkbox
                    className="reward-content-item-checkbox"
                    onChange={(e) => onCheckboxChange(e, index, RewardItems.activitys)}
                    checked={activityChecked}
                    value="activitys"
                >
                    {services.language.getText('scjjh')}
                </Checkbox>
                {activityChecked && (
                    <Button type={'link'} onClick={this.onChooseActivity} style={{ padding: 0 }}>
                        {services.language.getText('xzcjhd')}
                    </Button>
                )}
                {activityChecked && (
                    <div className="comment-activity-reward__wrap">
                        {this.showActivityItem()}
                        {this.changeModal()}
                    </div>
                )}
            </div>
        );
    }
}
