import React from 'react';
import { Input, Checkbox } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

const CustomUrlLink: React.FC<any> = (props) => {
    const { value, onChange } = props;
    const handleChange = (url: string) => {
        onChange({
            ...value?.linkParams,
            url,
        });
    };
    const needAuthrizationChange = (needAuthrization: boolean) => {
        onChange({
            ...value?.linkParams,
            needAuthrization,
        });
    };
    return (
        <div className="urllink-form">
            <Input
                placeholder={getText('h_input_url')}
                value={value?.linkParams?.url}
                onChange={(e) => handleChange(e.target.value)}
            />
            <Checkbox
                checked={value?.linkParams?.needAuthrization}
                onChange={(e) => needAuthrizationChange(e.target.checked)}
            >
                {getText('qzdl')}
            </Checkbox>
            <p className="link-input-tips">{getText('rgxyzxcxnfwxcxzwdwyhwxwz')}</p>
        </div>
    );
};

export default CustomUrlLink;
