import React, { PureComponent } from 'react';
import { Checkbox } from 'antd';
import { some, get, isEqual, isUndefined } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface Value {
    /**
     * 过期自动退款
     */
    overdueAutoRefund: boolean;
    /**
     * 买家随时退
     */
    anytimeRefund: boolean;
    /**
     * 礼包整退
     */
    packageRefund: boolean;
}

interface SalesElectronicCouponAfterSalesState {
    // 是否为券包
    isCouponPackage: boolean;
    // 是否包含余额券
    isCouponBalance: boolean;
    // 是否为线上制券
    isOnline: boolean;
}

export class SalesElectronicCouponAfterSales extends PureComponent<
    any,
    SalesElectronicCouponAfterSalesState
> {
    constructor(props: any) {
        super(props);
        this.state = { isCouponPackage: false, isCouponBalance: false, isOnline: true };
    }

    componentDidMount() {
        //积分商城编辑页面回显处理
        const { seat } = this.props;
        const isEdit =
            window.location.href.includes('/edit') || window.location.href.includes('/copy');
        if (isEdit && seat.includes('row.coupon')) {
            const creditCurrentCoupon = get(this.props, 'row.coupon');
            const nextCouponItem =
                creditCurrentCoupon && creditCurrentCoupon.length > 0
                    ? creditCurrentCoupon[0]
                    : undefined;
            this.setCouponState(nextCouponItem, true);
        }
    }
    componentWillReceiveProps(nextProps: any) {
        const { seat } = nextProps;
        if (seat.includes('row.baseInfo.coupon')) {
            const currentCoupon = get(this.props, 'row.baseInfo.coupon');
            const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
            const nextCouponItem = nextCoupon && nextCoupon.length > 0 ? nextCoupon[0] : undefined;
            // 当前选择的优惠券是券礼包获取券礼包详情设置分摊
            if (!isEqual(currentCoupon, nextCoupon)) {
                this.setCouponState(nextCouponItem, isUndefined(currentCoupon));
            }
        }
        if (seat.includes('row.coupon')) {
            const creditCurrentCoupon = get(this.props, 'row.coupon');
            const creditNextCoupon = get(nextProps, 'row.coupon', []);
            const creditNextCouponItem =
                creditNextCoupon && creditNextCoupon.length > 0 ? creditNextCoupon[0] : undefined;
            // 当前选择的优惠券是券礼包获取券礼包详情设置分摊
            if (!isEqual(creditCurrentCoupon, creditNextCoupon)) {
                this.setCouponState(creditNextCouponItem, isUndefined(creditCurrentCoupon));
            }
        }
    }

    setCouponState = async (nextCouponItem: any, isFirstInit: boolean) => {
        if (nextCouponItem) {
            const isCouponPackage = nextCouponItem.type === 'PACKAGE';
            let isCouponBalance = false;
            let isOnline = true;
            if (isCouponPackage) {
                const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
                const apiPath = `/admin/coupon_package_rules/${nextCouponItem.id}`;
                const data: any = await api.get({}, { apiRoot, apiPath });
                if (data) {
                    isCouponBalance = some(
                        data.ruleItems,
                        (item: any) =>
                            item.couponRule.couponType === 'BALANCE' ||
                            item.couponRule.couponType === 'EFUTURE_CASH' ||
                            item.couponRule.couponType === 'EFUTURE_BALANCE'
                    );
                }
            } else {
                isCouponBalance =
                    nextCouponItem.type === 'BALANCE' ||
                    nextCouponItem.type === 'EFUTURE_CASH' ||
                    nextCouponItem.type === 'EFUTURE_BALANCE';
                if (!isCouponBalance) {
                    isOnline = nextCouponItem.source === 'ONLINE';
                }
            }
            this.setState(
                {
                    isCouponPackage,
                    isCouponBalance,
                    isOnline,
                },
                this.initValue.bind(this, isFirstInit)
            );
        }
    };

    initValue = (isFirstInit: boolean) => {
        const { onChange, isEditForm } = this.props;
        if (isFirstInit && isEditForm) {
            return;
        }
        const { isCouponPackage, isCouponBalance, isOnline } = this.state;
        const defaultValue: Value = {
            overdueAutoRefund: true,
            anytimeRefund: true,
            packageRefund: false,
        };
        if (isCouponPackage) {
            if (isCouponBalance) {
                defaultValue.overdueAutoRefund = false;
                defaultValue.anytimeRefund = false;
                defaultValue.packageRefund = true;
            } else {
                defaultValue.overdueAutoRefund = false;
                defaultValue.anytimeRefund = false;
                defaultValue.packageRefund = false;
            }
        } else {
            if (isCouponBalance) {
                defaultValue.overdueAutoRefund = false;
                defaultValue.anytimeRefund = false;
                defaultValue.packageRefund = false;
            } else {
                if (isOnline) {
                    defaultValue.overdueAutoRefund = true;
                    defaultValue.anytimeRefund = true;
                    defaultValue.packageRefund = false;
                } else {
                    defaultValue.overdueAutoRefund = false;
                    defaultValue.anytimeRefund = false;
                    defaultValue.packageRefund = false;
                }
            }
        }
        if (onChange) {
            onChange(defaultValue);
        }
    };

    // 随时退
    renderAnytimeRefundCheckbox = (disabled = false) => {
        const { value, row, selectMode } = this.props;
        let checked = value ? value.anytimeRefund : false;
        const onChangeValue = this.onChangeValue.bind(this, 'anytimeRefund');
        if (selectMode) {
            const salesChannels = row?.baseInfo?.salesChannels || '';
            if (salesChannels.includes(selectMode)) {
                checked = true;
                disabled = true;
            }
        }
        return (
            <div>
                <Checkbox checked={checked} disabled={disabled} onChange={onChangeValue}>
                    {services.language.getText('sst')}
                </Checkbox>
                <div
                    style={{
                        color: '#ccc',
                        fontSize: '14px',
                        marginLeft: '22px',
                        lineHeight: 'normal',
                    }}
                >
                    {services.language.getText('l_yhkzqtsqsh')}
                </div>
            </div>
        );
    };

    // 过期退
    renderOverdueAutoRefundCheckbox = (disabled = false) => {
        const { value, row, selectMode } = this.props;
        let checked = value ? value.overdueAutoRefund : false;

        if (selectMode) {
            const salesChannels = row?.baseInfo?.salesChannels || '';
            if (salesChannels.includes(selectMode)) {
                checked = true;
                disabled = true;
            }
        }
        const onChangeValue = this.onChangeValue.bind(this, 'overdueAutoRefund');
        return (
            <div>
                <Checkbox checked={checked} disabled={disabled} onChange={onChangeValue}>
                    {services.language.getText('gqt')}
                </Checkbox>
                <div
                    style={{
                        color: '#ccc',
                        fontSize: '14px',
                        marginLeft: '22px',
                        lineHeight: 'normal',
                    }}
                >
                    {services.language.getText('l_mjcggmdzkqh')}
                </div>
            </div>
        );
    };

    // 礼包整退
    renderPackageRefundCheckbox = (disabled = false) => {
        const { value } = this.props;
        const checked = value ? value.packageRefund : false;
        const onChangeValue = this.onChangeValue.bind(this, 'packageRefund');
        return (
            <div>
                <Checkbox checked={checked} disabled={disabled} onChange={onChangeValue}>
                    {language.getText('giftRefund')}
                </Checkbox>
                <div
                    style={{
                        color: '#ccc',
                        fontSize: '14px',
                        marginLeft: '22px',
                        lineHeight: 'normal',
                    }}
                >
                    {language.getText('salesElectronicTip')}
                </div>
            </div>
        );
    };

    onChangeValue = (changeKey: string, e: any) => {
        const { onChange, value } = this.props;
        const checked = e.target.checked;
        if (onChange) {
            onChange({
                ...value,
                [changeKey]: checked,
            });
        }
    };

    renderConditions = () => {
        const { isCouponPackage, isCouponBalance } = this.state;
        if (isCouponPackage) {
            if (isCouponBalance) {
                return (
                    <div>
                        {this.renderAnytimeRefundCheckbox()}
                        {this.renderPackageRefundCheckbox(true)}
                    </div>
                );
            } else {
                return (
                    <div>
                        {this.renderAnytimeRefundCheckbox()}
                        {this.renderOverdueAutoRefundCheckbox()}
                        {this.renderPackageRefundCheckbox()}
                    </div>
                );
            }
        } else {
            if (isCouponBalance) {
                return <div>{this.renderAnytimeRefundCheckbox()}</div>;
            } else {
                return (
                    <div>
                        {this.renderAnytimeRefundCheckbox()}
                        {this.renderOverdueAutoRefundCheckbox()}
                    </div>
                );
            }
        }
    };
    render() {
        const { selectMode = '', tip = '' } = this.props;
        return (
            <div>
                {selectMode && (
                    <div style={{ color: 'rgb(204, 204, 204)', fontSize: '14px' }}>{tip}</div>
                )}
                {this.renderConditions()}
            </div>
        );
    }
}
