import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        MallActivityRegistrationEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/mallActivityRegistration',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputOrderNumber>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '手机号' },
                },
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                    controlConfig: { placeholder: '姓名' },
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                activityName: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                orderAmount: {
                    type: 'number',
                    displayName: '<<xjzje>>',
                },
                pointDealPrice: {
                    type: 'number',
                    displayName: '<<jfze>>',
                },
                orderStatus: {
                    type: 'string',
                    displayName: '<<ddzt>>',
                },
                orderStatusStr: {
                    type: 'string',
                    displayName: '<<ddzt>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                joinTime: {
                    type: 'string',
                    displayName: '参与时间',
                },
                name: {
                    type: 'string.statusBadge',
                    displayName: '<<name>>',
                },
                mobile: {
                    type: 'string.date',
                    displayName: '<<phoneNum>>',
                },
                quantity: {
                    type: 'number',
                    displayName: '报名数量',
                },
            },
        },
    },
    components: {
        MallActivityRegistrationView: {
            component: 'Viewport',
            entity: 'MallActivityRegistrationEntity',
            className: 'table-content-viewport',
        },
        MallActivityRegistrationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityRegistrationFilter',
                },
                { component: 'MallActivityRegistrationTables' },
            ],
        },
        MallActivityRegistrationDetailPage: {
            entity: 'MallActivityRegistrationEntity',
            loaderType: 'get',
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'MallActivityRegistrationDetail',
                },
            ],
        },
        MallActivityRegistrationFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'activityName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        MallActivityRegistrationTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: '' },
                },
                {
                    title: '<<waiting>>',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'WAIT' },
                },
                {
                    title: '审核未通过',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'FAIL' },
                },
                {
                    title: '待付款',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'CREATE' },
                },
                {
                    title: '待参与',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'PAYMENT' },
                },
                {
                    title: '已完成',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'RECEIVE' },
                },
                {
                    title: '关闭/取消',
                    content: {
                        component: 'MallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'CANCEL' },
                },
            ],
        },
        MallActivityRegistrationApplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityRegistrationApplyForm',
                },
            ],
        },
        MallActivityRegistrationTable: {
            component: 'FlexLayout',
            direction: 'vertical',

            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    scroll: {
                        x: 1300,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            width: 160,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'activityName',
                            width: 180,
                        },
                        {
                            property: 'orderNo',
                            width: 200,
                        },
                        {
                            property: 'quantity',
                            width: 80,
                        },
                        {
                            property: 'orderAmount',
                            width: 100,
                        },
                        {
                            property: 'pointDealPrice',
                            width: 80,
                        },
                        {
                            property: 'orderStatusStr',
                            width: 80,
                        },
                        {
                            property: 'orderCreateTime',
                            width: 200,
                        },
                        {
                            property: 'joinTime',
                            width: 220,
                        },
                        {
                            property: 'name',
                            width: 120,
                        },
                        {
                            property: 'mobile',
                            width: 140,
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 160,
                        items: [
                            {
                                type: 'link',
                                text: '<<details>>',
                                path: '/mall-activity-registration/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ReviewActivityButton',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<audity>>',
                                    },
                                },
                            },
                            {
                                type: 'component',
                                component: 'MallActivituAfterSaleButton',
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mall-activity-registration',
                    component: 'MallActivityRegistrationView',
                    breadcrumbName: '报名记录',
                    privilege: {
                        path: 'mallActivityRegistration',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            breadcrumbName: '报名订单详情',
                            path: '/edit/:id',
                            component: 'MallActivityRegistrationDetailPage',
                        },
                        {
                            path: '/apply/:id',
                            component: 'MallActivityRegistrationApplyPage',
                            breadcrumbName: '售后',
                        },
                        { path: '/', component: 'MallActivityRegistrationPage' },
                    ],
                },
            ],
        },
    ],
};
