import { SelectAutoComplete } from '@comall-backend-builder/components-basis';
import { isEqual } from 'lodash';

/**
 * 重写SelectAutoComplete组件
 * 解决初始化-门店只有一个的情况下直接锁定一个门店
 */
export class StringOptionAutoCompletePLusMode extends SelectAutoComplete {
    isFrist = true;
    componentWillReceiveProps(nextProps: any) {
        const { options, name, onChange } = this.props;
        const { options: newOptions } = nextProps;
        if (
            !isEqual(options, newOptions) &&
            newOptions &&
            newOptions.length === 1 &&
            this.isFrist &&
            onChange &&
            name
        ) {
            onChange(newOptions[0], name);
        }
    }
}
