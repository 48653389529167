import React, { PureComponent, createRef } from 'react';
import { message, Select, Timeline, Icon, Empty } from 'antd';
import { services } from '@comall-backend-builder/core';
import { cloneDeep } from 'lodash';
import { Subsite } from '../../common';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface Props {
    memberId: string;
    registerSubsiteCode?: string;
}
enum EventType {
    date = 'Date',
    event = 'Event',
}
interface EventItem {
    type: EventType;
    time: string;
    content: string;
}
interface State {
    subsites: Subsite[];
    currentValue?: string;
    page: number;
    hasMore: boolean;
    dateList: string[];
    eventList: EventItem[];
    isEmpty: boolean;
}

export default class MemberInfoTimeLine extends PureComponent<Props, State> {
    state: State = {
        subsites: [] as Subsite[],
        currentValue: undefined,
        page: 1,
        hasMore: false,
        dateList: [],
        eventList: [],
        isEmpty: false,
    };

    private scrollRef = createRef<HTMLDivElement>();
    private isLoading = false;

    componentDidMount() {
        this.loadSubsites();
        this.listenerScroll();
    }

    componentWillUnmount() {
        const divElement = this.scrollRef.current;

        if (divElement) {
            divElement.removeEventListener('scroll', this.handleScroll);
        }
    }

    private listenerScroll = () => {
        const divElement = this.scrollRef.current;

        if (divElement) {
            divElement.addEventListener('scroll', this.handleScroll);
        }
    };

    private handleScroll = () => {
        const divElement = this.scrollRef.current;
        if (divElement) {
            if (divElement.scrollHeight - divElement.scrollTop - 20 <= divElement.clientHeight) {
                this.handleOnLoad();
            }
        }
    };

    private loadSubsites = async () => {
        const { registerSubsiteCode } = this.props;
        const subsites = await api.get<Subsite[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        if (subsites.length === 0) {
            message.error(services.language.getText('dqzhwmdqx'));
            return;
        }
        let currentSubsite: Subsite | undefined = undefined;
        if (registerSubsiteCode) {
            currentSubsite = subsites.find((item) => item.code === registerSubsiteCode);
        }
        if (!currentSubsite) {
            currentSubsite = subsites[0];
        }

        this.setState(
            {
                subsites,
                currentValue: currentSubsite.id,
            },
            this.loadTimeline
        );
    };

    private onChangeSubsite = (id: string) => {
        const { subsites } = this.state;
        const current = (subsites || []).find((item) => item.id === id);
        if (!current) {
            return;
        }
        this.setState(
            {
                currentValue: id,
                page: 1,
                dateList: [],
                eventList: [],
            },
            this.loadTimeline
        );
    };

    private renderSubsiteSelect = () => {
        const { subsites, currentValue } = this.state;
        return (
            <div className={classes.subsite}>
                <div>{language.getText('subsites')}：</div>
                <Select
                    style={{ width: '220px' }}
                    showSearch
                    placeholder={services.language.getText('selectPlease')}
                    filterOption={(input, option: any) =>
                        (option.props.children || '').toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                    value={currentValue}
                    onChange={this.onChangeSubsite}
                >
                    {subsites.map((item) => {
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        );
    };

    private loadTimeline = async () => {
        const { memberId } = this.props;
        this.isLoading = true;
        const { dateList, eventList, currentValue, page } = this.state;
        const currentDateList = cloneDeep(dateList);
        const currentEventList = cloneDeep(eventList);
        try {
            const res: any = await api.get<Subsite[]>(
                {
                    subsiteId: currentValue,
                    hasMore: false,
                    page,
                    perPage: 10,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2/`,
                    apiPath: `/admin/members/${memberId}/mall_visit_records/timeline`,
                }
            );
            (res.result || []).forEach((item: any) => {
                if (currentDateList.includes(item.mallVisitDate)) {
                    currentEventList.push({
                        type: EventType.event,
                        time: item.mallVisitTime,
                        content: item.eventContent,
                    });
                } else {
                    currentDateList.push(item.mallVisitDate);
                    currentEventList.push({
                        type: EventType.date,
                        time: '',
                        content: item.mallVisitDate,
                    });
                    currentEventList.push({
                        type: EventType.event,
                        time: item.mallVisitTime,
                        content: item.eventContent,
                    });
                }
            });
            this.setState(
                {
                    hasMore: res.page < res.totalPage,
                    dateList: currentDateList,
                    eventList: currentEventList,
                    isEmpty: res.page === 1 && (!res.result || res.result.length === 0),
                },
                () => {
                    this.isLoading = false;
                }
            );
        } catch (error) {
            this.isLoading = false;
            services.errorHandle(error as any);
        }
    };

    private handleOnLoad = () => {
        const { hasMore, page } = this.state;
        if (!this.isLoading && hasMore) {
            this.setState(
                {
                    page: page + 1,
                },
                this.loadTimeline
            );
        }
    };

    private renderContainer = () => {
        const { eventList, hasMore, isEmpty } = this.state;
        return (
            <div className={classes.container} ref={this.scrollRef}>
                {isEmpty ? (
                    <div className={classes.empty}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                ) : (
                    <Timeline pending={hasMore ? services.language.getText('jzz') + '...' : false}>
                        {eventList.map((item: EventItem, index: number) => {
                            if (item.type === EventType.date) {
                                return (
                                    <Timeline.Item
                                        key={`timeline-${index}`}
                                        dot={
                                            <Icon
                                                type="clock-circle-o"
                                                style={{ fontSize: '16px' }}
                                            />
                                        }
                                    >
                                        <span className={classes.containerDate}>
                                            {item.content}
                                        </span>
                                    </Timeline.Item>
                                );
                            }
                            return (
                                <Timeline.Item key={`timeline-${index}`} color="#cccccc">
                                    <div>
                                        <div className={classes.containerTime}>{item.time}</div>
                                        <span className={classes.containerContent}>
                                            {item.content}
                                        </span>
                                    </div>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                )}
            </div>
        );
    };
    render() {
        return (
            <div className={prefix}>
                <div className={classes.title}>{language.getText('jdddxyg')}</div>
                {this.renderSubsiteSelect()}
                {this.renderContainer()}
            </div>
        );
    }
}

const prefix = 'member-info-time-line';
const classes = {
    title: `${prefix}__title`,
    subsite: `${prefix}__subsite`,
    container: `${prefix}__container`,
    empty: `${prefix}__empty`,
    containerDate: `${prefix}__container__date`,
    containerContent: `${prefix}__container__content`,
    containerTime: `${prefix}__container__time`,
};
