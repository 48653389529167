import React, { Component } from 'react';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { connect } from 'react-redux';
import { get, chunk } from 'lodash';
import {
    isSimplicityTheme,
    hexToRgba,
    getColorByTheme,
    formatClassNamesByTheme,
} from '../theme-style';
import { ContentType as PageContentType } from '../../types/mode/array/carpark-page-content';
import { ModuleType as MenusModuleType } from '../../components/carpark-menus-config/common';
import { ModuleType as ButtonsModuleType } from '../../components/carpark-buttons-config/common';

import './index.less';

const IconClose = require('./image/icon_close.png');
const TabsImage = require('./image/tabs.png');
const IconLocation = require('./image/location.png');

const prefix = 'new-subsite-carpark-preview';

/**
 * 活动左侧
 * @param props
 */
class newSubsiteCarparkPreview extends Component<any, { themeStyle: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            themeStyle: {},
        };
    }
    componentDidMount(): void {
        this.getThemeStyle();
    }

    getThemeStyle = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/theme_style',
        };
        api.get({}, config).then((res: any) => {
            if (res.id) {
                this.setState({ themeStyle: JSON.parse(res.config) });
            }
        });
    };

    private get isSimplicityTheme() {
        const { themeStyle } = this.state;
        if (!themeStyle) {
            return false;
        }
        return isSimplicityTheme(themeStyle);
    }

    private get primaryColor() {
        const { themeStyle } = this.state;
        if (!themeStyle) {
            return '';
        }
        return getColorByTheme(themeStyle, 'primary');
    }

    private get isCheckoutTypeInternal() {
        return this.getField('carparkConfig.checkoutType.type') === 'INTERNAL';
    }

    private get showMenus() {
        const menusConfig = this.getField('otherConfig.menusConfig');
        return (
            menusConfig?.filter(
                (item: any) =>
                    item.visible &&
                    item.moduleType !==
                        (this.isCheckoutTypeInternal
                            ? MenusModuleType.QueryPayment
                            : MenusModuleType.CarparkOrder)
            ) || []
        );
    }

    getField = (field: string) => {
        const { preview } = this.props;
        return get(preview, field);
    };
    renderHeader = () => {
        const pageContent = this.getField('otherConfig.pageContent');
        const headerDisplay = pageContent?.includes(PageContentType.PageContentParkingSpaceFreeNum);
        if (!headerDisplay) {
            return null;
        }
        const name = this.getField('carparkConfig.name');
        const subsite = this.getField('carparkConfig.subsite');
        let carparkName = name || (subsite ? subsite.name : language.getText('ccmc'));
        const innerClassNames = {
            header: `${prefix}__header`,
            icon: `${prefix}__header__icon`,
            name: `${prefix}__header__name`,
            quantity: `${prefix}__header__quantity`,
        };
        return (
            <div className={innerClassNames.header}>
                <img className={innerClassNames.icon} src={IconLocation} alt="" />
                <div className={innerClassNames.name}>{carparkName}</div>
                <div className={innerClassNames.quantity}>
                    {language.getText('remainingParking')}
                </div>
            </div>
        );
    };
    renderBanner = () => {
        const url = this.getField('pageConfig.carousels[0].picture[0].path') || undefined;
        const className = `${prefix}__banner`;
        return url ? <img className={className} src={url} alt="" /> : null;
    };
    renderTabs = () => {
        if (!this.isCheckoutTypeInternal) {
            return null;
        }
        if (this.isSimplicityTheme) {
            const innerClassNames = {
                warp: `${prefix}__tabs`,
                left: `${prefix}__tabs__left`,
                right: `${prefix}__tabs__right`,
                point: `${prefix}__tabs__point`,
            };
            return (
                <div className={innerClassNames.warp}>
                    <div className={innerClassNames.left}>{language.getText('cgcp')}</div>
                    <div className={innerClassNames.right}>{language.getText('tscp')}</div>
                    <div className={innerClassNames.point}></div>
                </div>
            );
        }
        const className = `${prefix}__tabs-image`;
        return <img className={className} src={TabsImage} alt="" />;
    };
    renderPoints = () => {
        const pageContent = this.getField('otherConfig.pageContent');
        const pointsDisplay = pageContent?.includes(PageContentType.PointsDisplay);
        if (!pointsDisplay) {
            return null;
        }
        const pointAlias = this.getField('otherConfig.pointAlias');
        if (!pointAlias) {
            return null;
        }
        const className = `${prefix}__point`;
        return <div className={className}>{pointAlias}210</div>;
    };
    renderFormArea = () => {
        const pageContent = this.getField('otherConfig.pageContent');
        const showQueryPayment = pageContent?.includes(PageContentType.QueryPayment);
        const showAddVehicle = pageContent?.includes(PageContentType.AddVehicle);
        const isButtonSmall = showQueryPayment && showAddVehicle;
        const { themeStyle } = this.state;
        const innerClassNames = formatClassNamesByTheme(themeStyle, {
            warp: `${prefix}__form`,
            input: `${prefix}__form__input`,
            inputItem: `${prefix}__form__input__item`,
            inputSecond: `${prefix}__form__input__second`,
            inputLast: `${prefix}__form__input__last`,
            inputLastIcon: `${prefix}__form__input__last__icon`,
            inputLastText: `${prefix}__form__input__last__text`,
            buttons: `${prefix}__form__buttons`,
            button: `${prefix}__form__button ${prefix}__form__button--${
                isButtonSmall ? 'small' : 'big'
            }`,
        });
        const secondStyle = {
            borderColor: hexToRgba(this.primaryColor, 0.5),
            backgroundColor: hexToRgba(this.primaryColor, this.isSimplicityTheme ? 0.05 : 0.1),
        };
        const lastStyle = {
            borderColor: this.primaryColor,
            color: this.primaryColor,
        };
        const hiddenCarNumberInput = !this.isCheckoutTypeInternal;
        return (
            <div className={innerClassNames.warp}>
                <div hidden={hiddenCarNumberInput} className={innerClassNames.input}>
                    <div className={innerClassNames.inputItem}>{language.getText('beijing')}</div>
                    <div style={secondStyle} className={innerClassNames.inputSecond}></div>
                    {Array.from({ length: 5 }).map(() => (
                        <div className={innerClassNames.inputItem}></div>
                    ))}
                    <div style={lastStyle} className={innerClassNames.inputLast}>
                        <div className={innerClassNames.inputLastIcon}>+</div>
                        <div className={innerClassNames.inputLastText}>
                            {language.getText('xny')}
                        </div>
                    </div>
                </div>
                <div className={innerClassNames.buttons}>
                    <div
                        hidden={!showQueryPayment}
                        style={isButtonSmall ? this.buttonDisStyle : this.buttonStyle}
                        className={innerClassNames.button}
                    >
                        {language.getText('cxjf')}
                    </div>
                    <div
                        hidden={!showAddVehicle}
                        style={this.buttonStyle}
                        className={innerClassNames.button}
                    >
                        {language.getText('tjcl')}
                    </div>
                </div>
            </div>
        );
    };
    renderCarNumbers = () => {
        if (!this.showMenus.find((i: any) => i.moduleType === MenusModuleType.MyVehicle)) {
            return null;
        }
        const { themeStyle } = this.state;
        const innerClassNames = formatClassNamesByTheme(themeStyle, {
            warp: `${prefix}__car-numbers`,
            item: `${prefix}__car-numbers__item`,
            point: `${prefix}__car-numbers__point`,
            number: `${prefix}__car-numbers__number`,
            icon: `${prefix}__car-numbers__icon`,
        });
        const pointColor = { backgroundColor: this.primaryColor };
        return (
            <div className={innerClassNames.warp}>
                <div className={innerClassNames.item}>
                    <div style={pointColor} className={innerClassNames.point}></div>
                    <div className={innerClassNames.number}>
                        {language.getText('beijing')}A·088880
                    </div>
                    <img className={innerClassNames.icon} src={IconClose} alt="" />
                </div>
                <div className={innerClassNames.item}>
                    <div style={pointColor} className={innerClassNames.point}></div>
                    <div className={innerClassNames.number}>{language.getText('c_1')}C·227066</div>
                    <img className={innerClassNames.icon} src={IconClose} alt="" />
                </div>
            </div>
        );
    };
    renderMenus = () => {
        const menus = this.showMenus;
        const groups = chunk(menus, 2);
        const innerClassNames = {
            menus: `${prefix}__menus`,
            group: `${prefix}__menus__group`,
            item: `${prefix}__menus__group__item`,
        };
        return (
            <div className={innerClassNames.menus}>
                {groups.map((group) => (
                    <div className={innerClassNames.group}>
                        {group.map((i: any) => (
                            <img className={innerClassNames.item} src={i.picture} alt="" />
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    private get buttonDisStyle() {
        const { themeStyle } = this.state;
        const bottonStyles: any = {};
        if (!this.isSimplicityTheme) {
            bottonStyles.color = themeStyle?.button?.gradient?.from || '#007046';
            bottonStyles.borderColor = themeStyle?.button?.gradient?.from || '#007046';
        }
        return bottonStyles;
    }

    private get buttonStyle() {
        const { themeStyle } = this.state;
        const bottonStyles: any = {
            background: themeStyle?.button?.gradient?.from || '#007046',
        };
        if (themeStyle?.button?.colorType === 'gradient') {
            if (themeStyle?.button?.gradient.direction === 'vertical') {
                bottonStyles.background = `linear-gradient(0deg, ${hexToRgba(
                    themeStyle?.button?.gradient?.to || '#ab8e66'
                )} 0%, ${hexToRgba(themeStyle?.button?.gradient?.from || '#ab8e66')} 100%)`;
            } else {
                bottonStyles.background = `linear-gradient(270deg, ${hexToRgba(
                    themeStyle?.button?.gradient?.to || '#ab8e66'
                )} 0%, ${hexToRgba(themeStyle?.button?.gradient?.from || '#ab8e66')} 100%)`;
            }
        }
        if (this.isSimplicityTheme) {
            bottonStyles.color = '#000';
            bottonStyles.borderColor = '#000';
        } else {
            bottonStyles.color = '#fff';
            bottonStyles.border = '0 none';
        }
        return bottonStyles;
    }

    renderCarparkExplain = () => {
        const carparkExplain: string = this.getField('pageConfig.carparkExplain');
        if (!carparkExplain) {
            return null;
        }
        const carparkExplains = carparkExplain ? carparkExplain.split('\n') : [];

        const innerClassNames = {
            warp: `${prefix}__explain`,
            title: `${prefix}__explain__title`,
            content: `${prefix}__explain__content`,
        };
        return (
            <div className={innerClassNames.warp}>
                <div className={innerClassNames.title}>{language.getText('tcsm')}</div>
                {carparkExplains.map((item) => (
                    <div className={innerClassNames.content}>{item}</div>
                ))}
            </div>
        );
    };

    private get goldExchangeButton() {
        const buttonsConfig = this.getField('otherConfig.buttonsConfig') || [];
        return buttonsConfig.find(
            (item: any) => item.moduleType === ButtonsModuleType.NavigationButtonGoldExchangePoint
        );
    }

    renderNavigationButton = () => {
        const goldExchangeButton = this.goldExchangeButton;
        const className = `${prefix}__gold-exchange-point`;
        if (goldExchangeButton?.visible) {
            return <img className={className} src={goldExchangeButton.picture} alt="" />;
        }
        return null;
    };

    render() {
        const { themeStyle } = this.state;
        let backgroundImage: any = {
            backgroundImage: themeStyle?.pageBackground?.backgroundImage
                ? `url(${themeStyle?.pageBackground?.backgroundImage})`
                : '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        };

        if (!this.isSimplicityTheme) {
            backgroundImage = {
                backgroundColor: '#fff',
            };
        }
        const headStyle = {
            backgroundColor: this.isSimplicityTheme
                ? themeStyle?.navigation?.style?.backgroundColor
                : '#fff',
        };

        return (
            <div style={backgroundImage} className="new-subsite-carpark-preview">
                <div className="theme-style-preview-item" style={headStyle}>
                    <img style={{ width: '100%' }} alt="" src="./images/avatar/theme-head.png" />
                    <div className="theme-style-preview-cart-title">
                        {language.getText('tcjf_1')}
                    </div>
                </div>
                {this.renderHeader()}
                {this.renderBanner()}
                {this.renderTabs()}
                {this.renderPoints()}
                {this.renderFormArea()}
                {this.renderCarNumbers()}
                {this.renderMenus()}
                {this.renderCarparkExplain()}
                {this.renderNavigationButton()}
            </div>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }

    return { preview: preview };
};

export const NewSubsiteCarparkPreview = connect(mapStateToProps)(newSubsiteCarparkPreview);
