import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import './index.less';
import { services } from '@comall-backend-builder/core';

/**
 * 弹窗广告预览效果
 * @param props
 */
const popUpContentPreview = (props: any) => {
    const { preview } = props;
    const url = preview ? get(preview, '[0].path', '') : '';
    return (
        <div className="pop-up-content-preview">
            <img
                className="pop-up-content-background-img"
                alt={services.language.getText('preview')}
                src="./images/avatar/home-preview.png"
            ></img>
            {url && <img className="pop-up-content-img" alt="预览广告" src={url}></img>}
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields['pic'];
        }
    }
    return { preview: preview };
};

export const PopUpContentPreview = connect(mapStateToProps)(popUpContentPreview);
