import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const ReportDownloadCenterLoader: Loader = {
    async get(data, config: ApiRequestConfig) {
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }

        return api.get(
            { ...data, creator: 1 },
            {
                ...config,
                apiPath: '/admin/download',
            }
        );
    },
};
