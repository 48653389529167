import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, filter } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import {
    checkBusinessNotifications,
    checkNotificationLink,
} from '../../config/member/caring/birthday-caring';

import './index.less';

export interface LotteryCodeEditFormProps extends FormComponentProps {
    editExecuteStatus: string;
    editLotteryStatus: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    pointRule: string;
    noticeType: string;
    rewardType: any;
    drawMethod: string;
}

class lotteryCodeEditFormView extends PureComponent<LotteryCodeEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const {
            entity,
            onSubmit,
            wrappedComponentRef,
            editExecuteStatus,
            editLotteryStatus,
        } = this.props;
        const isDisabled = editExecuteStatus === 'COMPLETE' || editLotteryStatus === 'REWARDED';
        let { pointRule, noticeType, rewardType, drawMethod } = this.props;
        const showTime = get(rewardType, '[0].checked') !== 'COUPON';

        if (!pointRule) {
            pointRule = 'NO';
        }
        if (!noticeType) {
            noticeType = 'OFFLINE';
        }
        if (!drawMethod) {
            drawMethod = 'AUTOMATIC';
        }
        let fields = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: 450,
                    },
                },
                rules: [
                    { required: true, message: services.language.getText('acitivityTimeRequired') },
                    {
                        async validator(_: any, value: any) {
                            const originStartDate = get(entity.fields, 'baseInfo.dateRange.start');
                            if (!value) {
                                return;
                            }
                            const currentStartDate = value.start;
                            // const currentEndDate = value.end;
                            if (originStartDate !== currentStartDate) {
                                throw new Error(
                                    services.language.getText('ksrqbngg') + `${originStartDate}`
                                );
                            }
                            // if (new Date(currentEndDate).getTime() < new Date().getTime()) {
                            //     throw new Error(services.language.getText('jssjbnzydqsj'));
                            // }
                        },
                    },
                ],
            },
            {
                property: 'baseInfo.drawTime',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.drawMethod',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: 450,
                    },
                },
            },
            { property: 'baseInfo.marketingSchedule', modifiable: false },
            {
                property: 'baseInfo.userWhiteListScope',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.noticeType',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.businessNotifications',
                className: noticeType === 'ONLINE' ? '' : 'property-hide',
            },
            {
                property: 'baseInfo.shareContent',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            // { property: 'baseInfo.shareAssistContent' },
            {
                property: 'baseInfo.description',
            },
            {
                property: 'baseInfo.remark',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'numberInfo.activityRewardLimit',
                controlConfig: {
                    disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.shareRewardLimit',
                controlConfig: {
                    disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            // {
            //     property: 'numberInfo.shareAssistLimit',
            //     controlConfig: {
            //         max: 999999,
            //         disabled: isDisabled,
            //         addonafter: '张',
            //         style: {
            //             width: '120px',
            //         },
            //     },
            // },
            // {
            //     property: 'numberInfo.assistLimit',
            //     controlConfig: {
            //         max: 999999,
            //         disabled: isDisabled,
            //         addonafter: '张',
            //         style: {
            //             width: '120px',
            //         },
            //     },
            // },
            {
                property: 'numberInfo.pointRule',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'numberInfo.point',
                controlConfig: {
                    disabled: isDisabled,
                    style: { width: '120px' },
                    placeholder: '0',
                    addonafter: '积分',
                    tip: '',
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.pointExchangeLimit',
                controlConfig: {
                    disabled: isDisabled,
                    style: { width: '120px' },
                    placeholder: '0',
                    addonafter: services.language.getText('frequency'),
                    tip: '',
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.refundPoint',
                controlConfig: {
                    disabled: isDisabled,
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'prizeInfo.name',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'prizeInfo.type',
                controlConfig: {
                    disabled: isDisabled,
                    reqConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/activities/reward_items',
                    },
                    reqParams: { eventType: 'LOTTERY_CODE' },
                    baseConfig: {
                        isConditionsInput: false,
                        isItmesRadio: true,
                        isLadder: false,
                    },
                },
            },

            { property: 'prizeInfo.picture' },
            {
                property: 'prizeInfo.quantity',
                className: drawMethod === 'AUTOMATIC' ? '' : 'property-hide',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'prizeInfo.receiveRewardTime',
                className: showTime ? '' : 'property-hide',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: 450,
                    },
                    range: {
                        start: new Date(),
                    },
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'LotteryCodeEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'lottery-code-edit-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('qx'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    let editLotteryStatus = get(
        _dispatch,
        'components.LotteryCodeEditFormContainer.fields.editLotteryStatus'
    );
    let editExecuteStatus = get(
        _dispatch,
        'components.LotteryCodeEditFormContainer.fields.editExecuteStatus'
    );
    let pointRule = get(
        _dispatch,
        'components.LotteryCodeEditFormContainer.fields.numberInfo.pointRule'
    );

    let noticeType = get(
        _dispatch,
        'components.LotteryCodeEditFormContainer.fields.baseInfo.noticeType'
    );
    let rewardType = get(
        _dispatch,
        'components.LotteryCodeEditFormContainer.fields.prizeInfo.type'
    );
    let drawMethod = get(
        _dispatch,
        'components.LotteryCodeEditFormContainer.fields.baseInfo.drawMethod'
    );
    // let originDrawTime = get(
    //     _dispatch,
    //     'components.LotteryCodeEditFormContainer.fields.baseInfo.drawTime'
    // );
    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        // const activityRewardLimit = get(entityFields, 'baseInfo.activityRewardLimit', null);
        // const shareRewardLimit = get(entityFields, 'baseInfo.shareRewardLimit', null);
        // if (activityRewardLimit === 0 && shareRewardLimit === 0) {
        //     AntMessage.warning(
        //         '初始赠送抽奖码数量与分享获得抽奖码上限不能同时设置为0，请检查后重新提交'
        //     );
        //     return false;
        // }
        if (pointRule === 'YES') {
            const point = get(entityFields, 'numberInfo.point', null);
            const pointExchangeLimit = get(entityFields, 'numberInfo.pointExchangeLimit', null);
            const reg = /^([1-9]\d*)$/;
            if (!point || point === '0' || !reg.test(point)) {
                AntMessage.warning(services.language.getText('qzqtxmccjxhjf'));
                return false;
            }
            if (
                !pointExchangeLimit ||
                pointExchangeLimit === '0' ||
                !reg.test(pointExchangeLimit)
            ) {
                AntMessage.warning(services.language.getText('qzqtxzddhcs'));
                return false;
            }
        }
        if (noticeType === 'ONLINE') {
            // const noticeChannels = get(entityFields, 'baseInfo.noticeChannels', null);
            // if (!noticeChannels || noticeChannels.length === 0) {
            //     AntMessage.warning(services.language.getText('qxztzfs'));
            //     return false;
            // }
            const businessNotifications = get(entityFields, 'baseInfo.businessNotifications', []);
            const selectedInfo = filter(businessNotifications, ['status', 1]);
            if (selectedInfo.length > 0) {
                for (let index = 0; index < selectedInfo.length; index++) {
                    const item = selectedInfo[index];
                    if (!checkBusinessNotifications(item)) {
                        return false;
                    }
                    if (!checkNotificationLink(item)) {
                        return false;
                    }
                }
            }
        }
        if (
            get(entityFields, 'prizeInfo.type[0].checked') === 'COUPON' &&
            !get(entityFields, 'prizeInfo.type[0].assetRewards[0].rewardValue.length')
        ) {
            AntMessage.warning(services.language.getText('qxzyhqjp'));
            return false;
        }

        if (
            drawMethod === 'AUTOMATIC' &&
            (entityFields.prizeInfo.quantity === undefined ||
                entityFields.prizeInfo.quantity === null)
        ) {
            AntMessage.warning(services.language.getText('quantityErrorMessage'));
            return false;
        }

        if (
            get(entityFields, 'prizeInfo.type[0].checked') !== 'COUPON' &&
            !entityFields.prizeInfo.receiveRewardTime
        ) {
            AntMessage.warning(services.language.getText('qxzljsj'));
            return false;
        }

        if (
            entityFields.prizeInfo.receiveRewardTime &&
            entityFields.prizeInfo.receiveRewardTime.start &&
            new Date(entityFields.prizeInfo.receiveRewardTime.start) <
                new Date(entityFields.baseInfo.drawTime)
        ) {
            AntMessage.warning(services.language.getText('ljsjbnzykjsj'));
            return false;
        }
        // if (new Date(originDrawTime).getTime() < new Date().getTime()){
        //     AntMessage.warning('活动已开奖，无法保存');
        //     return false;
        // }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
            pointRule,
            noticeType,
            rewardType,
            drawMethod,
            editLotteryStatus,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            },
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('LotteryCodeEditFormContainer'));
            },
        }
    );
}
export const LotteryCodeEditFormView = connect(mapDispatchToProps)(lotteryCodeEditFormView);
