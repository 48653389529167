import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { cloneDeep, isEmpty } from 'lodash';

const api = services.api;
export const DistributionActivitiesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id, status, dateRange } = data;
        if (status && status === 'ALL') {
            delete data.status;
        }
        if (!isEmpty(dateRange)) {
            data.startTime = dateRange.start + ' 00:00:00';
            data.endTime = dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        const type =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (type) delete config.paramsFilter;
        if (type && !id) data.type = type;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/distribution_activities';
        if (id) {
            config.apiPath += `/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.dateRange = {
                    start: res.startTime,
                    end: res.endTime,
                };
                res.selectedSubsites = res.subsites;
                res.goodsDistributionInfoList = res.goodsDistributionInfoList.map((item: any) => {
                    return {
                        ...item,
                        commissionValue: (
                            (item.salesPrice * item.commissionInfos[0].percentage * 100) /
                            100
                        ).toFixed(2),
                    };
                });
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        canEdit: !item.enabled,
                        nameStr: item.name,
                    };
                });
            }
            return res;
        });
    },

    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        if (config.paramsFilter && config.paramsFilter.length > 0) {
            delete config.paramsFilter;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/distribution_activities/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        const type =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (type) {
            delete config.paramsFilter;
            params.type = type;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/distribution_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = cloneDeep(params);
    newParams.startTime = newParams.dateRange.start;
    newParams.endTime = newParams.dateRange.end;
    newParams.subsiteIds = newParams.selectedSubsites.map((subsite: any) => {
        return subsite.id;
    });
    return newParams;
}

export const DistributionActivityWithdrawalRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/distribution_activity_commission_withdrawal_records';
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        return await api.get(data, config);
    },
};
