import React, { Fragment, PureComponent } from 'react';
import { message as AntMessage } from 'antd';
import forEach from 'lodash/forEach';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { NewImportTable } from '../../../components';
import { SelectMerchantBigCodesModal } from '../../../components/select-merchant-big-codes-modal';
const api = services.api;

export class MerchantBigCodesButtons extends PureComponent<any> {
    state = { codesModalVisible: false };

    handleAddCodes = () => {
        this.setState({ codesModalVisible: true });
    };
    onCloseCodesModal = () => {
        const { entity } = this.props;
        this.setState({ codesModalVisible: false });
        entity.search();
    };
    onAddCodes = (codes: any) => {
        let ids: any = [];
        forEach(codes, (good: any) => {
            ids.push(good.id);
        });
        api.post(
            { ids },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/deduction_merchant_big_codes',
            }
        ).then(
            () => {
                AntMessage.success(services.language.getText('addSuccess'));
                this.onCloseCodesModal();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    render() {
        const { entity } = this.props;
        const { codesModalVisible } = this.state;
        const uploadprops: any = {
            controlTitle: '导入商家码',
            triggerType: 'default',
            triggerText: '导入商家码',
            triggerStyle: { textAlign: 'center', color: 'rgb(0 0 0 / 65%)' },
            controlUploadAccept: '.xlsx,.xls',
            controlDownloadTemplate: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/deduction_merchant_big_codes/export.xlsx`,
            },
            specialTips: ['4.请填写正确的专柜编码及对应商家码'],
            controlHandles: [
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                    apiPath: '/admin/files/locations/780/upload',
                    type: 'primary',
                    text: '导入商家码',
                },
            ],
        };

        return (
            <Fragment>
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                    <Button
                        text={services.language.getText('tjspm')}
                        type="primary"
                        icon="plus"
                        onClick={this.handleAddCodes}
                    />
                    <div style={{ marginLeft: '15px' }}>
                        <NewImportTable
                            row={null}
                            entity={entity}
                            props={uploadprops}
                        ></NewImportTable>
                    </div>
                </div>
                <SelectMerchantBigCodesModal
                    showModal={codesModalVisible}
                    onCancel={this.onCloseCodesModal}
                    onChange={this.onAddCodes}
                />
            </Fragment>
        );
    }
}
