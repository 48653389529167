import React, { Component } from 'react';
import { defaultsDeep, set } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import {
    ComponentFactory,
    DynamicComponent,
} from '@comall-backend-builder/core/lib/parser/component';

export interface SelectVirtualGoodValue {
    id: number;
    goodsId: number;
    name: string;
    subsiteId: string;
}

export interface SelectVirtualGoodProps {
    visible?: boolean;
    value?: SelectVirtualGoodValue;
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    onChange?(value: SelectVirtualGoodValue): void;
    onCancel?(): void;
}

export interface SelectVirtualGoodState {
    currentSelectValue?: SelectVirtualGoodValue;
}

function generateComponentId(name: string): string {
    return `${name}-${parseInt(Math.random() * 1e8 + '').toString(16)}`;
}

function createComponentConfig(componentName: string, entityName: string) {
    const baseComponentId = generateComponentId(componentName);

    return {
        componentId: baseComponentId,
        component: 'Card',
        entity: entityName,
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'subsiteName',
                            controlConfig: {
                                style: { width: 214 },
                            },
                        },
                        {
                            property: 'name',
                            controlConfig: {
                                style: { width: 200 },
                            },
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'name',
                            width: 280,
                        },
                        {
                            property: 'barcode',
                            width: 150,
                        },
                        {
                            property: 'goodsMvo.subsiteName',
                            width: 100,
                        },
                    ],
                },
            ],
        },
    };
}

function createDynamicComponent(
    contentConfig: Record<string, any> = {},
    componentConfig: Record<string, any> = {},
    onSelect?: (value: SelectVirtualGoodValue) => void
) {
    const config = defaultsDeep(contentConfig, componentConfig);

    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange() {},
        onSelect(_: any, __: any, [value]: [any]) {
            onSelect &&
                onSelect({
                    id: value.id,
                    goodsId: value.id || value.goodsMvo.id,
                    subsiteId: value.goodsMvo.subsiteId,
                    name: value.name,
                });
        },
    });

    return ComponentFactory(componentConfig.componentId, config);
}

export class SelectVirtualGood extends Component<SelectVirtualGoodProps, SelectVirtualGoodState> {
    public state: SelectVirtualGoodState = {
        currentSelectValue: undefined,
    };

    private VirtualProductSelectorComponent: typeof DynamicComponent;

    public constructor(props: SelectVirtualGoodProps) {
        super(props);

        const virtualProductSelectorConfig = createComponentConfig(
            'DesignVirtualGoodsSelector',
            'CustomDesignVirtualGoodsSelector'
        );
        this.VirtualProductSelectorComponent = createDynamicComponent(
            props.contentConfig,
            virtualProductSelectorConfig,
            this.handleCurrentSelectValueChange
        );
    }

    private handleOk = (): void => {
        const { onChange } = this.props;
        const { currentSelectValue } = this.state;

        if (!currentSelectValue) {
            AntMessage.error('请选择虚拟商品');
            return;
        }

        onChange && onChange(currentSelectValue);
    };

    private handleCancel = (): void => {
        const { onCancel } = this.props;

        onCancel && onCancel();
    };

    private handleCurrentSelectValueChange = (value: SelectVirtualGoodValue): void => {
        this.setState({
            currentSelectValue: value,
        });
    };

    public render(): JSX.Element {
        const { visible, modalConfig = {} } = this.props;

        return (
            <Modal
                title="请选择虚拟商品"
                width="800px"
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                {...modalConfig}
            >
                <this.VirtualProductSelectorComponent />
            </Modal>
        );
    }
}
