import React from 'react';
import { WechatOfficialAccount } from '.';

interface Props {
    value?: WechatOfficialAccount;
}
export default function AutoCompleteAppidBySubsiteDisplay(props: Props) {
    const { value } = props;
    return <div>{value?.name}</div>;
}
