import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get, isArray, forEach, some } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

enum RewardRule {
    /**
     * 每满设置
     */
    CYCLE = 'CYCLE',
    /**
     * 阶梯设置
     */
    LADDER = 'LADDER',
}

export const config: Config = {
    entities: {
        UserLoginEncourageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/activationPromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'ONLINE',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        rewardRule: {
                            type: 'string.options.radio',
                            displayName: '<<jllx>>',
                            defaultValue: 'CYCLE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'CYCLE', name: '<<mmjl>>' },
                                { id: 'LADDER', name: '<<jtjl>>' },
                            ],
                        },
                        cycleRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<cycleRewardRule>>',
                            options: [],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'ONLINE' },
                                rewardStartText: '<<lxdl>>',
                                rewardUnit: '<<day>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: false,
                                },
                            },
                        },
                        ladderRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<jtsz>>',
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'ONLINE' },
                                rewardStartText: '<<lxdl>>',
                                rewardUnit: '<<day>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: true,
                                    maxCount: 10,
                                },
                            },
                        },
                        rewardCalculation: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<jljs>>',
                            defaultValue: ['CONTINUOUS', 'CYCLIC'],
                            options: [
                                {
                                    id: 'CONTINUOUS',
                                    name: '<<bzxczddkszxffjl>>',
                                },
                                {
                                    id: 'CYCLIC',
                                    name: '<<yhlxdlhdzgjtjlh>>',
                                },
                            ],
                        },
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<jlsx_1>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdsjnhd>>',
                                addonafter: '<<bzhdjl_1>>',
                                tip: '',
                            },
                            extra: '<<dszmztjhdjl>>',
                        },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        UserLoginEncourageView: {
            component: 'Viewport',
        },
        UserLoginEncouragePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'UserLoginEncourageTables' }],
        },
        UserLoginEncourageTables: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<yhdl>>',
                    content: {
                        component: 'UserLoginPage',
                    },
                },
            ],
        },
        UserLoginPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'UserLoginEncourageEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    items: [
                        { component: 'UserLoginPageFilter', params: { eventType: 'ONLINE' } },
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzchjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/activation-promotion-encourage/add-ONLINE',
                                },
                            ],
                        },
                    ],
                },
                { component: 'UserLoginEncourageTable', params: { eventType: 'ONLINE' } },
            ],
        },
        UserLoginPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        UserLoginEncourageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/activation_activities/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/activation_activities/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path:
                                    '/activation-promotion-encourage/edit-{{row.eventType}}/{{row.id}}',
                            },
                            // {
                            //     type: 'component',
                            //     component: 'NotePromotionEncourageListExportButton',
                            // },
                        ],
                    },
                },
            ],
        },
        UserLoginEncourageAddPage: {
            component: 'Card',
            content: { component: 'UserLoginEncourageAddForm' },
        },
        UserLoginEncourageEditPage: {
            component: 'Card',
            content: { component: 'UserLoginEncourageEditForm' },
        },
        UserLoginEncourageAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 720 },
            entity: 'UserLoginEncourageEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.eventType', visible: () => false },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'encourageInfo.rewardRule', className: 'property-left' },
                {
                    property: 'encourageInfo.cycleRewardRule',
                    visible: (values: any) =>
                        get(values, 'encourageInfo.rewardRule') === RewardRule.CYCLE,
                },
                {
                    property: 'encourageInfo.ladderRewardRule',
                    visible: (values: any) =>
                        get(values, 'encourageInfo.rewardRule') === RewardRule.LADDER,
                },
                { property: 'encourageInfo.rewardCalculation', className: 'property-left' },
                { property: 'encourageInfo.activityRewardLimit', className: 'property-left' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const rewardRule = get(entityFields, 'encourageInfo.rewardRule', null);
                const cycleRewardRule = get(entityFields, 'encourageInfo.cycleRewardRule', null);

                if (rewardRule === RewardRule.CYCLE) {
                    const data = cycleRewardRule[0];
                    if (data.conditionValue && data.conditionValue > 0) {
                        if (data.assetRewards && isArray(data.assetRewards)) {
                            if (
                                data.assetRewards.some(
                                    (assetReward: any) => assetReward.rewardValue > 0
                                )
                            ) {
                                return true;
                            } else {
                                AntMessage.warning(language.getText('qtxmztjhdjlsz'));
                            }
                        } else {
                            AntMessage.warning(language.getText('qtxmztjhdjlsz'));
                        }
                    } else {
                        AntMessage.warning(language.getText('qtxjltjmzsz'));
                    }
                } else {
                    const ladderRewardRule = get(
                        entityFields,
                        'encourageInfo.ladderRewardRule',
                        null
                    );
                    if (ladderRewardRule && ladderRewardRule.length > 0) {
                        let hasValidate = true;
                        let maxConditionValue = 0;
                        forEach(ladderRewardRule, (reward: any) => {
                            //1.促活激励限制（连续登陆天数下一档需大于上一档天数）
                            if (reward.conditionValue) {
                                if (reward.conditionValue > maxConditionValue) {
                                    maxConditionValue = reward.conditionValue;
                                } else {
                                    AntMessage.warning('<<jbcsblxdltsxydxdysydts>>');
                                    hasValidate = false;
                                    return false;
                                }
                            } else {
                                AntMessage.warning(services.language.getText('qtxlxdld'));
                                hasValidate = false;
                                return false;
                            }

                            //2.验证已填写（必须选择一项）
                            if (!reward.assetRewards) {
                                AntMessage.warning(language.getText('qsrjlx'));
                                hasValidate = false;
                                return false;
                            } else {
                                if (
                                    !some(
                                        reward.assetRewards,
                                        (assetReward: any) => assetReward.rewardValue > 0
                                    )
                                ) {
                                    AntMessage.warning(language.getText('qsrjlx'));
                                    hasValidate = false;
                                    return false;
                                }
                            }
                        });
                        if (!hasValidate) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        UserLoginEncourageEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            style: { width: 720 },
            entity: 'UserLoginEncourageEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.eventType', visible: () => false },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                {
                    property: 'encourageInfo.rewardRule',
                    className: 'property-left',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'encourageInfo.cycleRewardRule',
                    visible: (values: any) =>
                        get(values, 'encourageInfo.rewardRule') === RewardRule.CYCLE,
                    controlConfig: {
                        disabled: true,
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'ONLINE' },
                        rewardStartText: '<<lxdl>>',
                        rewardUnit: '<<day>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: false,
                        },
                    },
                },
                {
                    property: 'encourageInfo.ladderRewardRule',
                    visible: (values: any) =>
                        get(values, 'encourageInfo.rewardRule') === RewardRule.LADDER,
                    controlConfig: {
                        disabled: true,
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'ONLINE' },
                        rewardStartText: '<<lxdl>>',
                        rewardUnit: '<<day>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: true,
                            maxCount: 10,
                        },
                    },
                },
                {
                    property: 'encourageInfo.rewardCalculation',
                    className: 'property-left',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'encourageInfo.activityRewardLimit', className: 'property-left' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const rewardRule = get(entityFields, 'encourageInfo.rewardRule', null);
                const cycleRewardRule = get(entityFields, 'encourageInfo.cycleRewardRule', null);

                if (rewardRule === RewardRule.CYCLE) {
                    const data = cycleRewardRule[0];
                    if (data.conditionValue && data.conditionValue > 0) {
                        if (data.assetRewards && isArray(data.assetRewards)) {
                            if (
                                data.assetRewards.some(
                                    (assetReward: any) => assetReward.rewardValue > 0
                                )
                            ) {
                                return true;
                            } else {
                                AntMessage.warning(language.getText('qtxmztjhdjlsz'));
                            }
                        } else {
                            AntMessage.warning(language.getText('qtxmztjhdjlsz'));
                        }
                    } else {
                        AntMessage.warning(language.getText('qtxjltjmzsz'));
                    }
                } else {
                    const ladderRewardRule = get(
                        entityFields,
                        'encourageInfo.ladderRewardRule',
                        null
                    );
                    if (ladderRewardRule && ladderRewardRule.length > 0) {
                        let hasValidate = true;
                        let maxConditionValue = 0;
                        forEach(ladderRewardRule, (reward: any) => {
                            //1.促活激励限制（连续登陆天数下一档需大于上一档天数）
                            if (reward.conditionValue) {
                                if (reward.conditionValue > maxConditionValue) {
                                    maxConditionValue = reward.conditionValue;
                                } else {
                                    AntMessage.warning(language.getText('jbcsblxdltsxydxdysydts'));
                                    hasValidate = false;
                                    return false;
                                }
                            } else {
                                AntMessage.warning(services.language.getText('qtxlxdld'));
                                hasValidate = false;
                                return false;
                            }

                            //2.验证已填写（必须选择一项）
                            if (!reward.assetRewards) {
                                AntMessage.warning(language.getText('qsrjlx'));
                                hasValidate = false;
                                return false;
                            } else {
                                if (
                                    !some(
                                        reward.assetRewards,
                                        (assetReward: any) => assetReward.rewardValue > 0
                                    )
                                ) {
                                    AntMessage.warning(language.getText('qsrjlx'));
                                    hasValidate = false;
                                    return false;
                                }
                            }
                        });
                        if (!hasValidate) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/activation-promotion-encourage',
                    component: 'UserLoginEncourageView',
                    breadcrumbName: '<<activationPromotionEncourage>>',
                    privilege: {
                        path: 'activationPromotionEncourage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-ONLINE',
                            component: 'UserLoginEncourageAddPage',
                            breadcrumbName: '<<xzchjl>>',
                        },
                        {
                            path: '/edit-ONLINE/:id',
                            component: 'UserLoginEncourageEditPage',
                            breadcrumbName: '<<bjchjl>>',
                        },
                        { path: '/', component: 'UserLoginEncouragePage' },
                    ],
                },
            ],
        },
    ],
};
