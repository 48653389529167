import React, { Component } from 'react';
import { InputNumber, Radio, Table, Button } from 'antd';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

export interface MallActivityPayValue {
    separateLevel: boolean;
    list: payListItem[];
}

export interface payListItem {
    /**
     * 会员卡等级id
     */
    mcCardLevelId: number;
    /**
     * 支付现金
     */
    payCash: number;
    /**
     * 支付积分
     */
    payPoint: number;
    /**
     * 支付金币
     */
    payGold: number;
    /**
     * 免费个数
     */
    quota: number;
}

export interface MallActivityPayListOption {
    id: number;
    name: string;
}

export interface MallActivityPayListProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: MallActivityPayValue | null;

    /**
     * 候选项集合
     */
    options: Array<MallActivityPayListOption>;

    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: MallActivityPayValue | null, name: string) => void;
}

const prefix = 'mall-activity-pay-list';
export class MallActivityPayList extends Component<MallActivityPayListProps> {
    onValueChange = (e: any) => {
        const { onChange, name, options } = this.props;
        const separateLevel: boolean = e.target.value;
        const list: payListItem[] = [];
        if (separateLevel) {
            options.forEach((item) => {
                list.push({
                    mcCardLevelId: item.id,
                    payCash: 0,
                    payPoint: 0,
                    payGold: 0,
                    quota: 0,
                });
            });
        } else {
            list.push({
                mcCardLevelId: -1,
                payCash: 0,
                payPoint: 0,
                payGold: 0,
                quota: 0,
            });
        }
        const newValue = {
            list,
            separateLevel,
        };
        onChange(newValue, name);
    };

    changePayValue = (record: payListItem, key: string, value: number, index: number) => {
        const { value: oldValue, onChange, name } = this.props;
        const list = cloneDeep(oldValue!.list);
        if (key === 'payPoint') {
            list.splice(index, 1, {
                ...record,
                payPoint: value,
            });
        } else if (key === 'payCash') {
            list.splice(index, 1, {
                ...record,
                payCash: value,
            });
        } else if (key === 'quota') {
            list.splice(index, 1, {
                ...record,
                quota: value,
            });
        }
        onChange(
            {
                separateLevel: oldValue!.separateLevel,
                list,
            },
            name
        );
    };

    resetPayValue = (index: number) => {
        const { value, onChange, name } = this.props;
        const list = cloneDeep(value!.list);
        const record: payListItem = list[index];
        list.splice(index, 1, {
            ...record,
            payCash: 0,
            payPoint: 0,
            payGold: 0,
            quota: 0,
        });
        onChange(
            {
                separateLevel: value!.separateLevel,
                list,
            },
            name
        );
    };

    getCardName = (id: number) => {
        const { options } = this.props;
        if (id === -1) {
            return '全部等级';
        }
        return options
            ? options.find((item: MallActivityPayListOption) => Number(item.id) === Number(id))
                  ?.name || ''
            : '';
    };

    getTableProps = () => {
        const { value, disabled } = this.props;
        const dataSource = value?.list || [];
        const changePayValue = this.changePayValue;
        const resetPayValue = this.resetPayValue;
        const getCardName = this.getCardName;
        return {
            dataSource,
            pagination: false as const,
            columns: [
                {
                    title: '会员等级',
                    dataIndex: 'mcCardLevelId',
                    key: 'mcCardLevelId',
                    align: 'center' as const,
                    render(value: number) {
                        return getCardName(value);
                    },
                },
                {
                    title: '每名额报名费',
                    dataIndex: 'payCash',
                    key: 'payCash',
                    align: 'center' as const,
                    render(value: any, record: payListItem, index: number) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <InputNumber
                                    disabled={disabled}
                                    value={record.payPoint}
                                    min={0}
                                    max={999999}
                                    onChange={(value: any) =>
                                        changePayValue(record, 'payPoint', value, index)
                                    }
                                ></InputNumber>
                                &nbsp;积分+&nbsp;
                                <InputNumber
                                    disabled={disabled}
                                    value={value}
                                    min={0}
                                    max={999999}
                                    precision={2}
                                    onChange={(value: any) =>
                                        changePayValue(record, 'payCash', value, index)
                                    }
                                ></InputNumber>
                                &nbsp;{services.language.getText('yuan')}
                            </div>
                        );
                    },
                },
                {
                    title: '每人每月可免费报名名额',
                    dataIndex: 'quota',
                    key: 'quota',
                    align: 'center' as const,
                    render(value: any, record: payListItem, index: number) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <InputNumber
                                    disabled={disabled}
                                    value={value}
                                    min={0}
                                    max={999999}
                                    onChange={(value: any) =>
                                        changePayValue(record, 'quota', value, index)
                                    }
                                ></InputNumber>
                                &nbsp;个
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    key: 'action',
                    align: 'center' as const,
                    render(value: any, record: payListItem, index: number) {
                        return disabled ? null : (
                            <Button type="link" onClick={resetPayValue.bind(this, index)}>
                                重置
                            </Button>
                        );
                    },
                },
            ],
        };
    };

    render() {
        const { value, disabled } = this.props;
        const tableProps = this.getTableProps();
        return (
            <div className={prefix}>
                <Radio.Group
                    disabled={disabled}
                    value={value ? value.separateLevel : false}
                    onChange={this.onValueChange}
                >
                    <Radio value={false}>所有等级，同一规则</Radio>
                    <Radio value={true} style={{ marginLeft: '50px' }}>
                        不同等级，不同规则
                    </Radio>
                </Radio.Group>
                <Table {...tableProps}></Table>
            </div>
        );
    }
}
