import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const CreditEshopPickUpDeliveryLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/pick_up_deliveries';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/pick_up_deliveries/${id}`;
        } else {
            data.deliveryRuleType = 'POINT';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.pickUpDelivery.id;
                res.baseInfo = {
                    name: 'ALL',
                    subsite: res.pickUpDelivery.subsite.id + '',
                    address: res.pickUpDelivery.address,
                    pickUpTime: res.pickUpDelivery.pickUpTime,
                    reminder: res.pickUpDelivery.reminder,
                };
                if (res.timeRule) {
                    res.chooseInfo = {
                        reservedTimeHour:
                            res.timeRule.reservedTime && res.timeRule.reservedTime.value,
                        topLimitDay: res.timeRule.topLimit,
                    };
                    if (res.timeRule.timePeriods && res.timeRule.timePeriods.length > 0) {
                        for (let i = 0; i < res.timeRule.timePeriods.length; i++) {
                            let time = res.timeRule.timePeriods[i];
                            if (time && time.startTime && time.endTime) {
                                time.start = time.startTime;
                                time.end = time.endTime;
                            }
                        }
                        res.chooseInfo.isDeliveryTimePeriods = 'YES';
                        res.chooseInfo.deliveryTimePeriods = res.timeRule.timePeriods;
                    } else {
                        res.chooseInfo.isDeliveryTimePeriods = 'NO';
                    }
                } else {
                    res.chooseInfo = {
                        isDeliveryTimePeriods: 'NO',
                    };
                }
                res.ruleInfo = {
                    orderHandleMode:
                        res.pickUpDelivery.orderHandleMode === 'NOT_HANDLE'
                            ? 'NOT_HANDLE'
                            : 'PART_HANDLE',
                    goods: [],
                    pickUpLimitDays: res.pickUpDelivery.pickUpLimitDays,
                    activityProductType:
                        res.pickUpDelivery.orderHandleMode === 'NOT_HANDLE'
                            ? 'ALL'
                            : orderHandleModeGet[res.pickUpDelivery.orderHandleMode],
                };
                if (res.pickUpDelivery.goodsList && res.pickUpDelivery.goodsList.length > 0) {
                    res.ruleInfo.goods = {
                        type: 'APPOINT_PRODUCT',
                        values: res.pickUpDelivery.goodsList.map((item: any) => {
                            return {
                                ...item,
                                name: item.productName,
                                id: Number(item.goodsId),
                                code: item.productCode,
                            };
                        }),
                    };
                } else if (res.pickUpDelivery.brands && res.pickUpDelivery.brands.length > 0) {
                    res.ruleInfo.goods = {
                        type: 'APPOINT_BRAND',
                        values: res.pickUpDelivery.brands.map((item: any) => {
                            item.id = Number(item.id);
                            return {
                                ...item,
                            };
                        }),
                    };
                }
            } else {
                res.result = res.result.map((item: any) => {
                    item.subsiteName = item.subsite.name;
                    item.executeStatus = item.status;
                    item.pickUpTime = item.pickUpTime ? item.pickUpTime : '';
                    item.reminder = item.reminder ? item.reminder : '';
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/pick_up_deliveries';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = `/admin/pick_up_deliveries/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};
/**
 * 过期未提货订单方式
 * NOT_HANDLE:不处理
 * PART_HANDLE:部分处理
 * ALL_HANDLE:全部处理
 * PART_NOT_HANDLE:部分不处理
 */
const orderHandleModeSubmit: { [key: string]: string } = {
    // NOT_HANDLE: 'NOT_HANDLE',
    INCLUDE_PRODUCT: 'PART_HANDLE',
    ALL: 'ALL_HANDLE',
    EXCLUDE_PRODUCT: 'PART_NOT_HANDLE',
};
const orderHandleModeGet: { [key: string]: string } = {
    PART_HANDLE: 'INCLUDE_PRODUCT',
    ALL_HANDLE: 'ALL',
    PART_NOT_HANDLE: 'EXCLUDE_PRODUCT',
};
/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo && params.ruleInfo) {
        newParams.pickUpDelivery = {
            deliveryRuleType: 'POINT',
            address: params.baseInfo.address,
            partNotHandle: params.ruleInfo?.activityProductType || 'ALL',
            promotionScopeIds:
                params.ruleInfo.orderHandleMode === 'PART_HANDLE' &&
                params.ruleInfo.goods &&
                params.ruleInfo.goods?.values.length
                    ? params.ruleInfo.goods.values.map((good: any) => {
                          return good.id;
                      })
                    : [],
            orderHandleMode:
                params.ruleInfo.orderHandleMode === 'PART_HANDLE'
                    ? orderHandleModeSubmit[params.ruleInfo.activityProductType]
                    : params.ruleInfo.orderHandleMode,
            rangeType: params.ruleInfo.goods?.type,
            pickUpTime: params.baseInfo.pickUpTime,
            pickUpLimitDays:
                params.ruleInfo.orderHandleMode === 'PART_HANDLE'
                    ? params.ruleInfo.pickUpLimitDays
                    : 0,
            reminder: params.baseInfo.reminder,
            subsite: {
                id: params.baseInfo.subsite,
            },
        };
    }
    if (params.chooseInfo) {
        newParams.timeRule = {
            reservedTime: {
                unit: 'HOUR',
                value: params.chooseInfo.reservedTimeHour ? params.chooseInfo.reservedTimeHour : 0,
            },
            topLimit: params.chooseInfo.topLimitDay ? params.chooseInfo.topLimitDay : 0,
        };
        if (params.chooseInfo.isDeliveryTimePeriods === 'YES') {
            forEach(params.chooseInfo.deliveryTimePeriods, (item) => {
                item.startTime = item.start;
                item.endTime = item.end;
            });
            newParams.timeRule.timePeriods = params.chooseInfo.deliveryTimePeriods;
        }
    }
    return newParams;
}
