import React, { PureComponent } from 'react';
import { Form, Radio, Slider } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';
import { ImageAdItem } from './image-ad-item';
import { ImageAdTips } from './image-ad-tips';
import './index.less';

const EntryList = tools.EntryList;
const FormItemLayout = tools.FormItemLayout;
const ConfigFormDecorator = tools.ConfigFormDecorator;
const language = services.language;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const MAX_ENTRY_COUNT = 10;
const interpolate = services.interpolate;
/**
 * 图文组件-配置表单
 */
class imageAd extends PureComponent<any> {
    WrapImageAdItem = (props: any) => {
        const { uploadAction, selector, sourceRoot } = this.props;
        return (
            <ImageAdItem
                {...props}
                sourceRoot={sourceRoot}
                selector={selector}
                uploadAction={uploadAction}
            />
        );
    };

    render() {
        const {
            form: { getFieldDecorator },
            value,
            linkTypes,
            linkTypeMode,
        } = this.props;
        const formItemLayout = FormItemLayout;
        //轮播图才展示填充方式
        const isCarousel = value.showStyle === '1';
        const isFill = isCarousel && value.fillStyle === '2';
        return (
            <Form className="editor-image-ad">
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.ImageAd.selectTemplate')}
                >
                    {getFieldDecorator(
                        'showStyle',
                        {}
                    )(
                        <RadioGroup className="show-style">
                            <Radio value={'1'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-carousel.png"
                                    alt=""
                                />
                                <span className="select-title">轮播图</span>
                            </Radio>
                            <Radio value={'2'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-one-line-1.png"
                                    alt=""
                                />
                                <span className="select-title">一行一个</span>
                            </Radio>
                            <Radio value={'3'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-one-line-2.png"
                                    alt=""
                                />
                                <span className="select-title">一行两个</span>
                            </Radio>
                            <Radio value={'4'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-one-line-3.png"
                                    alt=""
                                />
                                <span className="select-title">一行三个</span>
                            </Radio>
                        </RadioGroup>
                    )}
                </FormItem>
                {isCarousel && (
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.ImageAd.fillStyle')}
                    >
                        {getFieldDecorator(
                            'fillStyle',
                            {}
                        )(
                            <RadioGroup className="fill-style">
                                <Radio value={'1'}>
                                    {language.getText('components.ImageAd.blank')}
                                </Radio>
                                <Radio value={'2'}>
                                    {language.getText('components.ImageAd.fill')}
                                </Radio>
                            </RadioGroup>
                        )}
                    </FormItem>
                )}
                {isFill && (
                    <FormItem {...formItemLayout} label={'图片高度'}>
                        {getFieldDecorator('imageHeight')(<Slider max={550} min={200} />)}
                    </FormItem>
                )}
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.ImageAd.pageMargin')}
                >
                    {getFieldDecorator('margin')(<Slider max={48} min={0} />)}
                </FormItem>
                {!isCarousel && (
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.ImageAd.imageMargin')}
                    >
                        {getFieldDecorator('marginImage')(<Slider max={48} min={0} />)}
                    </FormItem>
                )}
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.ImageAd.borderRadius')}
                >
                    {getFieldDecorator('borderRadius')(<Slider max={48} min={0} />)}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('entry', {
                        initialValue: [],
                    })(
                        <EntryList
                            linkTypes={linkTypes}
                            linkTypeMode={linkTypeMode}
                            maxCount={MAX_ENTRY_COUNT}
                            renderItem={this.WrapImageAdItem}
                            renderTips={ImageAdTips}
                        />
                    )}
                </FormItem>
                <p className="entry-tip">
                    {interpolate(language.getText('components.ImageAd.tips'), {
                        max: MAX_ENTRY_COUNT,
                    })}
                </p>
            </Form>
        );
    }
}

export const ImageAd = ConfigFormDecorator(imageAd);
