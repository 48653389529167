import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface WfjLotteryCodeEditFormProps extends FormComponentProps {
    editExecuteStatus: string;
    editLotteryStatus: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    pointRule: string;
}

class wfjlotteryCodeEditFormView extends PureComponent<WfjLotteryCodeEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const {
            entity,
            onSubmit,
            wrappedComponentRef,
            editExecuteStatus,
            editLotteryStatus,
        } = this.props;
        const isDisabled = editExecuteStatus === 'COMPLETE' || editLotteryStatus === 'REWARDED';

        let { pointRule } = this.props;

        if (!pointRule) {
            pointRule = 'NO';
        }

        let fields = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: 450,
                    },
                },
                rules: [
                    { required: true, message: services.language.getText('acitivityTimeRequired') },
                    {
                        async validator(_: any, value: any) {
                            const originStartDate = get(entity.fields, 'baseInfo.dateRange.start');
                            if (!value) {
                                return;
                            }
                            const currentStartDate = value.start;
                            // const currentEndDate = value.end;
                            if (originStartDate !== currentStartDate) {
                                throw new Error(
                                    services.language.getText('ksrqbngg') + `${originStartDate}`
                                );
                            }
                            // if (new Date(currentEndDate).getTime() < new Date().getTime()) {
                            //     throw new Error(services.language.getText('jssjbnzydqsj'));
                            // }
                        },
                    },
                ],
            },
            {
                property: 'baseInfo.drawTime',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.shareContent',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.shareAssistContent',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'baseInfo.description',
            },
            {
                property: 'baseInfo.remark',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'numberInfo.activityRewardLimit',
                controlConfig: {
                    disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.shareRewardLimit',
                controlConfig: {
                    disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.shareAssistLimit',
                controlConfig: {
                    max: 999999,
                    disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.assistLimit',
                controlConfig: {
                    max: 999999,
                    disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.pointRule',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'numberInfo.point',
                controlConfig: {
                    disabled: isDisabled,
                    style: { width: '120px' },
                    placeholder: '0',
                    addonafter: '积分',
                    tip: '',
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.pointExchangeLimit',
                controlConfig: {
                    disabled: isDisabled,
                    style: { width: '120px' },
                    placeholder: '0',
                    addonafter: services.language.getText('frequency'),
                    tip: '',
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'prizeInfo.name',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'prizeInfo.type',
                controlConfig: {
                    disabled: isDisabled,
                    reqConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/activities/reward_items',
                    },
                    reqParams: { eventType: 'LOTTERY_CODE' },
                    baseConfig: {
                        isConditionsInput: false,
                        isItmesRadio: true,
                        isLadder: false,
                    },
                },
            },
            { property: 'prizeInfo.picture' },
            {
                property: 'prizeInfo.quantity',
                controlConfig: {
                    disabled: isDisabled,
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'otherInfo.marketingSchedule',
                modifiable: false,
            },
        ];

        let formProps = {
            entity,
            componentId: 'WfjLotteryCodeEditEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'wfj-lottery-code-edit-form-container',
            direction: 'horizontal',
            labelCol: 8,
            controlCol: 16,
            fields: fields,
            submit: { text: '保存' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    let editExecuteStatus = get(
        _dispatch,
        'components.WfjLotteryCodeEditEditFormContainer.fields.editExecuteStatus'
    );
    let editLotteryStatus = get(
        _dispatch,
        'components.WfjLotteryCodeEditEditFormContainer.fields.editLotteryStatus'
    );
    let pointRule = get(
        _dispatch,
        'components.WfjLotteryCodeEditEditFormContainer.fields.numberInfo.pointRule'
    );
    // let originDrawTime = get(
    //     _dispatch,
    //     'components.WfjLotteryCodeEditEditFormContainer.fields.baseInfo.drawTime'
    // );

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        // const activityRewardLimit = get(entityFields, 'baseInfo.activityRewardLimit', null);
        // const shareRewardLimit = get(entityFields, 'baseInfo.shareRewardLimit', null);
        // if (activityRewardLimit === 0 && shareRewardLimit === 0) {
        //     AntMessage.warning(
        //         '初始赠送抽奖码数量与分享获得抽奖码上限不能同时设置为0，请检查后重新提交'
        //     );
        //     return false;
        // }
        // const endTime = get(entityFields, 'baseInfo.dateRange.end');
        // const drawTime = get(entityFields, 'baseInfo.drawTime');
        // if (new Date(drawTime).getTime() < new Date(endTime).getTime()) {
        //     AntMessage.warning('开奖时间要晚于或等于结束时间');
        //     return false;
        // }
        if (pointRule === 'YES') {
            const point = get(entityFields, 'numberInfo.point', null);
            const pointExchangeLimit = get(entityFields, 'numberInfo.pointExchangeLimit', null);
            const reg = /^([1-9]\d*)$/;
            if (!point || point === '0' || !reg.test(point)) {
                AntMessage.warning('请正确填写每次抽奖消耗积分');
                return false;
            }
            if (
                !pointExchangeLimit ||
                pointExchangeLimit === '0' ||
                !reg.test(pointExchangeLimit)
            ) {
                AntMessage.warning('请正确填写最多兑换次数');
                return false;
            }
        }
        if (
            get(entityFields, 'prizeInfo.type[0].checked') === 'COUPON' &&
            !get(entityFields, 'prizeInfo.type[0].assetRewards[0].rewardValue.length')
        ) {
            AntMessage.warning('请选择优惠券奖品');
            return false;
        }
        // if (new Date(originDrawTime).getTime() < new Date().getTime()) {
        //     AntMessage.warning('活动已开奖，无法保存');
        //     return false;
        // }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
            editLotteryStatus,
            pointRule,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            },
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('WfjLotteryCodeEditFormContainer'));
            },
        }
    );
}
export const WfjLotteryCodeEditFormView = connect(mapDispatchToProps)(wfjlotteryCodeEditFormView);
