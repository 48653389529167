import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const CouponServiceAppliesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/service_applies';
        if (data.orderType === 'ALL') {
            delete data.orderType;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.createTime)) {
            data.startCreateTime = data.createTime.start + ' 00:00:00';
            data.endCreateTime = data.createTime.end + ' 23:59:59';
            delete data.createTime;
        }
        data.orderTypes = 'SALE_COUPON,CREDIT_ESHOP,GROUP_BUYING_COUPON,COUPON_PACKAGE';
        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    item.canEdit = item.status === 'APPLYING';
                    item.subsite = item && item.subsite.name;
                    item.goodsName = item.saleRuleName;
                    item.couponNo = item.couponVos;
                    item.quantity = item.couponVos;
                    return item;
                });
            return res;
        });
    },
};
