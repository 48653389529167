import React, { PureComponent } from 'react';
import { Modal, InputNumber, Table } from 'antd';
import { get, cloneDeep, map } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { services } from '@comall-backend-builder/core';

interface CouponPackageApportionPriceProps {
    type?: 'price' | '';
    visible: boolean;
    onOk: (value: any) => void;
    onCancel: () => void;
    title?: string;
    disabled?: boolean;
    data: any;
    field?: string;
    tips?: string;
    step?: number;
}

interface CouponPackageApportionPriceState {
    source: any;
    field: string;
}

export class CouponPackageApportionPrice extends PureComponent<
    CouponPackageApportionPriceProps,
    CouponPackageApportionPriceState
> {
    constructor(props: CouponPackageApportionPriceProps) {
        super(props);
        const field = props.field ? props.field : 'couponInfo';
        this.state = { source: cloneDeep(get(props, `data[0].${field}`)), field };
    }
    componentWillReceiveProps(props: CouponPackageApportionPriceProps) {
        const { field } = this.state;
        this.setState({
            source: cloneDeep(get(props, `data[0].${field}`)),
        });
    }
    onCancel = () => {
        const { onCancel, data } = this.props;
        const { field } = this.state;
        onCancel && onCancel();
        this.setState({ source: cloneDeep(get(data, `data[0].${field}`)) });
    };
    onChangePrice = (value: any, record: any) => {
        const { type } = this.props;
        const { source } = this.state;
        const newSource = map(source, (item) => {
            if (record.couponDefinitionId === item.couponDefinitionId) {
                if (type !== 'price') {
                    item.apportionPrice = value;
                } else {
                    item.price = value;
                }
            }
            return item;
        });
        this.setState({ source: newSource });
    };
    onOk = () => {
        const { onOk } = this.props;
        const { source } = this.state;
        onOk && onOk(source);
    };
    renderTable = () => {
        const { type, disabled, tips, step } = this.props;
        const { source } = this.state;
        const limitDecimalsF = (value: any) => {
            return value;
        };
        const limitDecimalsP = (value: any) => {
            let reg = /^(-)*(\d+)\.(\d\d).*$/;
            return value.replace(/\s?|(,*)/g, '').replace(reg, '$1$2.$3');
        };

        const columns: ColumnProps<any>[] = [
            {
                title: services.language.getText('couponName'),
                dataIndex: 'couponDefinitionName',
                key: 'couponDefinitionName',
                width: 200,
            },
            {
                title: services.language.getText('couponType'),
                dataIndex: 'couponTypeDesc',
                key: 'couponTypeDesc',
                width: 200,
            },
            {
                title: services.language.getText('ffsl'),
                dataIndex: 'quantity',
                key: 'quantity',
                width: 200,
            },
        ];

        if (type !== 'price') {
            columns.push({
                title: services.language.getText('ftj'),
                dataIndex: 'apportionPrice',
                key: 'apportionPrice',
                width: 300,
                render: (price: number, record: any) => {
                    return (
                        <div>
                            <InputNumber
                                style={{ width: 100 }}
                                step={step ? step : 0.01}
                                value={price}
                                min={0}
                                formatter={limitDecimalsF}
                                parser={limitDecimalsP}
                                onChange={(value) => this.onChangePrice(value, record)}
                                disabled={disabled}
                            ></InputNumber>
                            {tips && <span> {tips}</span>}
                        </div>
                    );
                },
            });
        } else {
            columns.push({
                title: services.language.getText('ftj'),
                dataIndex: 'price',
                key: 'price',
                width: 300,
                render: (price: any, record: any) => {
                    return (
                        <div>
                            <InputNumber
                                value={price.apportionPoints}
                                min={1}
                                step={1}
                                precision={0}
                                placeholder={services.language.getText('inputPlease')}
                                onChange={(value: any) =>
                                    this.onChangePrice(
                                        {
                                            ...price,
                                            apportionPoints: value,
                                        },
                                        record
                                    )
                                }
                            />
                            <span> {services.language.getText('point')} + </span>
                            <InputNumber
                                value={price.apportionPrice}
                                min={0}
                                step={0.01}
                                placeholder={services.language.getText('inputPlease')}
                                onChange={(value: any) =>
                                    this.onChangePrice(
                                        {
                                            ...price,
                                            apportionPrice:
                                                value === undefined
                                                    ? undefined
                                                    : (+value || 0).toFixed(2),
                                        },
                                        record
                                    )
                                }
                            />
                            <span> {services.language.getText('yuan')}</span>
                        </div>
                    );
                },
            });
        }

        return <Table scroll={{ x: true }} columns={columns} dataSource={source} rowKey={'id'} />;
    };

    render() {
        const { visible, title = '' } = this.props;
        return (
            <Modal
                visible={visible}
                width={900}
                title={title}
                onCancel={this.onCancel}
                onOk={this.onOk}
            >
                {this.renderTable()}
            </Modal>
        );
    }
}
