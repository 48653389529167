export interface NewMallActivityRoundItem {
    /**
     * 场次是否已启用
     */
    enable: boolean;
    id?: string;
    /**
     * 场次结束时间
     */
    endTime?: string;
    /**
     * 场次期间每用户共计最多可购票数量
     */
    maxPurchaseTotal?: number;
    /**
     * 场次结束日期-按场次报名时，开始结束时间是同一日
     */
    roundEndDate?: string;
    /**
     * 场次开始日期-按场次报名时，开始结束时间是同一日
     */
    roundStartDate?: string;
    /**
     * 场次开始时间
     */
    startTime?: string;
    /**
     * 本场次售卖的票集合
     */
    tickets: MallActivityTicket[];
    /**
     * key，前端唯一标识，非后端返回字段
     */
    rowKey?: string;
}

export interface MallActivityTicket {
    id?: string;
    /**
     * 场次是否已启用
     */
    enable: boolean;
    /**
     * 已报名/购买数量
     */
    soldQuantity?: number;
    /**
     * 票名称
     */
    name?: string;
    /**
     * 入场票售价免费额度规则信息
     */
    ticketPriceFreeRules?: MallActivityTicketPriceFreeRule[];
    /**
     * 价格信息集合
     */
    ticketPrices: MallActivityTicketPrice[];
    /**
     * 促销价
     */
    ticketPromotionPrice?: MallActivityTicketPromotionPrice;
    /**
     * 总库存
     */
    totalStock?: number;
    /**
     * key，前端唯一标识，非后端返回字段
     */
    rowKey?: string;
}

export interface MallActivityTicketPrice {
    /**
     * 免费周期单位
     */
    freePeriodUnit?: MallActivityFreePeriodUnit;
    /**
     * 免费周期值
     */
    freePeriodValue?: number;
    /**
     * 免费报名额度
     */
    freeQuota?: number;
    /**
     * 会员卡等级Id， -1代表全部卡等级
     */
    memberCardLevelId: number;
    /**
     * 积分价
     */
    pointPrice?: number;
    /**
     * 销售价
     */
    salePrice?: number;
}

export interface MallActivityTicketPromotionPrice {
    /**
     * 积分价
     */
    pointPrice?: number;
    /**
     * 销售价
     */
    salePrice?: number;
    /**
     * 活动价开始时间
     */
    startTime?: string;
    /**
     * 活动价结束时间
     */
    endTime?: string;
}
export interface MallActivityTicketPriceFreeRule {
    /**
     * 免费周期单位
     */
    freePeriodUnit: MallActivityFreePeriodUnit;
    /**
     * 免费周期值
     */
    freePeriodValue: number;
    /**
     * 免费报名额度
     */
    freeQuota?: number;
    /**
     * 会员卡等级Id， -1代表全部卡等级
     */
    memberCardLevelId: number;
}

/**
 * 免费周期单位
 */
export enum MallActivityFreePeriodUnit {
    Hour = 'HOUR',
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
}

export enum NewMallActivityType {
    /**
     * 宣传类活动
     */
    Notification = 'NOTIFICATION',
    /**
     * 报名类活动
     */
    SignUp = 'SIGN_UP',
    /**
     * 卖票类活动
     */
    Exhibition = 'EXHIBITION',
}

export interface NewMallActivityRow {
    baseInfo: {
        merchants?: {
            subSiteId: string;
        }[];
        dateRange?: {
            start?: string;
            end?: string;
        };
        editStartTime?: string;
        editEndTime?: string;
        dayTime?: {
            start?: string;
            end?: string;
        }[];
    };
    registrationInfo: {
        type: NewMallActivityType;
        targetUserLevels: string[];
        activityRounds?: NewMallActivityRoundItem[];
        activitySingleRoundTickets?: MallActivityTicket[];
        ticketType: NewMallActivityTicketType;
        registrationStartTime?: NewMallActivityRegistrationTimeValue;
        registrationEndTime?: NewMallActivityRegistrationTimeValue;
    };
}

export enum NewMallActivityTicketType {
    /**
     * 按活动设置票档
     */
    Activity = 'ACTIVITY',
    /**
     * 按场次设置票档
     */
    Round = 'ROUND',
}

export interface NewMallActivityMemberCardLevel {
    id: string;
    name: string;
}

export interface CardLevelChangeResult {
    /**
     * 会员卡等级列表是否发生了变更
     */
    changed: boolean;
    /**
     * 变更后当前门店所有的会员卡等级集合
     */
    allCardLevels?: NewMallActivityMemberCardLevel[];
    /**
     * 变更后购买限制卡等级集合
     */
    limitCardLevels?: string[];
}

export enum NewMallActivityRegistrationTimeType {
    /*
     *指定日期
     */
    DAY_TIME = 'DAY_TIME',
    /*
     *活动开始前
     */
    BEFORE_BEGIN = 'BEFORE_BEGIN',
    /*
     *活动开始后
     */
    AFTER_BEGIN = 'AFTER_BEGIN',
    /*
     *活动结束前
     */
    BEFORE_END = 'BEFORE_END',
}
export enum NewMallActivityRegistrationTimeUnit {
    /**
     * 天
     */
    DAY = 'DAY',
    /**
     * 小时
     */
    HOUR = 'HOUR',
}

export interface NewMallActivityRegistrationTimeValue {
    timeType?: NewMallActivityRegistrationTimeType;
    timeStr?: string;
    num?: number;
    timeUnit?: NewMallActivityRegistrationTimeUnit;
}

export type ChangeTicket = <T extends keyof MallActivityTicket>(
    field: T,
    fieldValue: MallActivityTicket[T],
    index: number
) => void;

export type ChangeTicketFiledBySettingTicketRowKeys = <T extends keyof MallActivityTicket>(
    field: T,
    fieldValue: MallActivityTicket[T]
) => void;

export type TicketChangeModalValueMap = Pick<
    MallActivityTicket,
    'ticketPrices' | 'ticketPromotionPrice' | 'ticketPriceFreeRules'
>;

export type TicketChangeModalType = keyof TicketChangeModalValueMap;

export type ShowTicketFiledChangeModal = <T extends TicketChangeModalType>(
    type: T,
    ticket?: MallActivityTicket
) => void;
export type TicketFieldShowModal<T extends MallActivityTicket[TicketChangeModalType]> = (
    value: T
) => void;
