import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';

/**
 * 会员中心 - 内容 - 互动组件
 */
class memberContentInteraction extends PureComponent<any> {
    render() {
        return (
            <Form className="editor-member-content-interaction">
                <div className="tips">固定样式暂不支持配置</div>
            </Form>
        );
    }
}

export const MemberContentInteraction = tools.ConfigFormDecorator(memberContentInteraction);
