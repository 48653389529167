import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { message } from 'antd';
import { EntityButtonProps } from '../../../../components';

export const config: Config = {
    entities: {
        couponUnConsumeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/couponUnConsumeLoader',
            filters: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<codeRequired>>',
                    },
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                    },
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrddh>>',
                    },
                },
                unconsumeTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hxsj>>',
                    defaultValue: undefined,
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                unconsumeTime: {
                    type: 'string',
                    displayName: '<<hxsj>>',
                },
                result: {
                    type: 'string',
                    displayName: '<<processStatus>>',
                },
            },
        },
    },
    components: {
        couponUnConsumeView: {
            component: 'Viewport',
        },
        couponUnConsumePage: {
            component: 'FlexLayout',
            entity: 'couponUnConsumeEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'couponUnConsumeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 16,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '导出数据',
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );

                                            if (
                                                entityButtonProps.entity.filters &&
                                                (entityButtonProps.entity.filters as any)
                                                    .unconsumeTime
                                            ) {
                                                Object.assign(params, {
                                                    startTime: (entityButtonProps.entity
                                                        .filters as any).unconsumeTime.start,
                                                    endTime: (entityButtonProps.entity
                                                        .filters as any).unconsumeTime.end,
                                                    unconsumeTime: undefined,
                                                });
                                            }

                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                                apiPath: '/admin/coupon_unconsume_records/export',
                                            };

                                            api.get(params, config)
                                                .then(() => {
                                                    message.success(
                                                        '数据生成中，请在下载优惠券数据导出'
                                                    );
                                                })
                                                .catch((error) => {
                                                    errorHandle(error);
                                                });
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'couponUnConsumeTable',
                },
            ],
        },
        couponUnConsumeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteCode',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'unconsumeTime',
                },
            ],
        },
        couponUnConsumeTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteCode',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'unconsumeTime',
                },
                {
                    property: 'result',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-un-consume',
                    component: 'couponUnConsumeView',
                    breadcrumbName: '线下退优惠券',
                    privilege: {
                        path: 'couponUnConsume',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'couponUnConsumePage',
                        },
                    ],
                },
            ],
        },
    ],
};
