import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { CustomForm } from '../../../components';

export class CustomFormMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CustomForm {...controlInfo} />;
    }
}
