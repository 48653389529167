import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';

export const config: Config = {
    entities: {
        CrossborderTradeConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
            apiPath: '/loader/admin/crossborder_trade_config',
            properties: {
                limitRule: {
                    type: 'object.subForm',
                    displayName: '<<kjspxzsz>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                yearTradeQuota: {
                    type: 'number.integerInputTitleTip',
                    displayName: '<<ndjyxz>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        addonafter: '<<yuan>>',
                        rules: [
                            {
                                type: 'number',
                                min: 1,
                                max: 999999,
                            },
                        ],
                        width: 150,
                    },
                },
                onceTradeQuota: {
                    type: 'number.integerInputTitleTip',
                    displayName: '<<dcjyxz>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        addonafter: '<<yuan>>',
                        rules: [
                            {
                                type: 'number',
                                min: 1,
                                max: 999999,
                            },
                        ],
                        width: 150,
                    },
                },
            },
        },
    },
    components: {
        CrossborderTradeConfigView: {
            component: 'Viewport',
        },
        CrossborderTradeConfigPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'CrossborderTradeConfigForm' }],
        },
        CrossborderTradeConfigForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'CrossborderTradeConfig',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 17,
            style: { width: 1000 },
            fields: [
                { property: 'limitRule' },
                { property: 'yearTradeQuota' },
                { property: 'onceTradeQuota' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/crossborder-trade',
                    component: 'CrossborderTradeConfigView',
                    breadcrumbName: '<<CrossborderTradeConfig>>',
                    privilege: {
                        path: 'CrossborderTradeConfig',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CrossborderTradeConfigPage' }],
                },
            ],
        },
    ],
};
