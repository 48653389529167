import React, { Component } from 'react';
import { Radio, Button, Select, Input } from 'antd';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';

const { Option } = Select;
const api = services.api;

interface MemberCardLevelBenefit {
    /**
     * 会员卡等级
     */
    memberCardLevelId?: number;
    /**
     * 每日赠送权益
     */
    everydayBenefit?: number;
    /**
     * 兑换权益
     */
    conversionBenefit?: number;
    /**
     * 日兑换上限值(积分)
     */
    conversionMax?: number;
}
interface GradeEquityValue {
    cardLevelBenefit: string;
    memberCardLevelBenefit: MemberCardLevelBenefit[];
}

interface CardLevel {
    id: string;
    name: string;
}

interface GradeEquityProps {
    onChange: (value: GradeEquityValue) => void;
    value: GradeEquityValue;
    disabled: boolean;
    row: any;
    options: CardLevel[];
    /**是否是新增 */
    isAdd: boolean;
}

interface State {
    cardLevelOptions: { id: string; name: string; disabled?: boolean }[];
}
/**
 * 等级权益
 */
export class GradeEquity extends Component<GradeEquityProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            cardLevelOptions: [],
        };
    }
    componentDidMount() {
        const { value, onChange, row } = this.props;
        if (!value) {
            const defaultValue = {
                cardLevelBenefit: 'ENABLE',
                memberCardLevelBenefit: [{}],
            };
            onChange(defaultValue);
        }
        const subsiteId = get(row, 'baseInfo.subsiteId');
        if (subsiteId) {
            this.setState(
                {
                    cardLevelOptions: [],
                },
                () => {
                    this.loadOptions(subsiteId);
                }
            );
        }
    }

    componentWillReceiveProps(nextProps: Readonly<GradeEquityProps>) {
        const { isAdd } = this.props;
        const nextSubsiteId = get(nextProps.row, 'baseInfo.subsiteId');
        const theSubsiteId = get(this.props.row, 'baseInfo.subsiteId');
        if (nextSubsiteId !== theSubsiteId) {
            this.loadOptions(nextSubsiteId);
            // 新增权益 切换门店的时候 等级权益置空
            if (isAdd) {
                const { onChange } = this.props;
                const defaultValue = {
                    cardLevelBenefit: 'ENABLE',
                    memberCardLevelBenefit: [{}],
                };
                onChange(defaultValue);
            }
        }
    }

    /**
     * @params subsiteIds 分站ID
     * 获取会员等级接口
     */
    loadOptions = (subsiteIds: string) => {
        const params = {
            subsiteIds,
            page: 1,
            perPage: 100,
            status: 'ENABLE',
        };
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/store_card_levels/legal',
        };
        api.get(params, config).then((res: any) => {
            const result: Array<{
                id: string;
                name: string;
            }> = [];
            res.result.forEach((level: any) => {
                result.push({
                    id: level.cardLevelId.toString(),
                    name: level.name,
                });
            });
            const {
                value: { memberCardLevelBenefit },
            } = this.props;

            // 当前选中的会员等级 置灰不可选
            if (memberCardLevelBenefit && memberCardLevelBenefit.length) {
                const disabledIds = new Set(
                    memberCardLevelBenefit.map((item) => item.memberCardLevelId)
                );
                result.forEach((item: any) => {
                    if (disabledIds.has(Number(item.id))) {
                        item.disabled = true;
                    }
                });
            }
            this.setState({
                cardLevelOptions: result,
            });
        });
    };

    getCommonChange = (name: string, v: any) => {
        const { value, onChange } = this.props;
        const newValue = Object.assign(value, { name: v });
        onChange && onChange(newValue);
    };

    /**
     * 切换是否区分等级
     */
    onRadioChange = (e: any) => {
        const { value, onChange } = this.props;
        const newValue = Object.assign(value, { cardLevelBenefit: e.target.value });
        onChange && onChange(newValue);
    };
    /**
     * 添加一组会员卡等级规则
     */
    onAddCardLevel = () => {
        const { value, onChange } = this.props;
        const newValue = Object.assign(value, {
            memberCardLevelBenefit: [...value.memberCardLevelBenefit, {}],
        });
        onChange && onChange(newValue);
    };
    /**
     * 每日赠送权益
     */
    everydayBenefitChange = (v: any, index: number) => {
        const { value } = this.props;
        const newMemberCardLevelBenefit = [...value.memberCardLevelBenefit];
        newMemberCardLevelBenefit[index].everydayBenefit = v;
        this.getCommonChange('memberCardLevelBenefit', newMemberCardLevelBenefit);
    };

    /**
     * 兑换权益
     */
    conversionBenefitChange = (v: any, index: number) => {
        const { value } = this.props;
        const newMemberCardLevelBenefit = [...value.memberCardLevelBenefit];
        newMemberCardLevelBenefit[index].conversionBenefit = v;
        this.getCommonChange('memberCardLevelBenefit', newMemberCardLevelBenefit);
    };
    /**
     * 日兑换上限值(积分)
     */
    conversionMaxChange = (v: any, index: number) => {
        const { value } = this.props;
        const newMemberCardLevelBenefit = [...value.memberCardLevelBenefit];
        newMemberCardLevelBenefit[index].conversionMax = v;
        this.getCommonChange('memberCardLevelBenefit', newMemberCardLevelBenefit);
    };

    selectCardLevel = () => {
        const { value } = this.props;
        const { cardLevelOptions } = this.state;
        const memberCardLevelIds = value.memberCardLevelBenefit.map(
            (item) => item.memberCardLevelId
        );
        const newcardLevelOptions = cardLevelOptions.map((item: any) => {
            if (memberCardLevelIds.includes(item.id)) {
                item.disabled = true;
            } else {
                item.disabled = false;
            }
            return item;
        });

        this.setState({
            cardLevelOptions: newcardLevelOptions,
        });
    };
    /**
     * 选择会员等级
     */
    onChangeCardLevelChange = (v: any, index: number) => {
        const { value } = this.props;
        const newMemberCardLevelBenefit = [...value.memberCardLevelBenefit];
        newMemberCardLevelBenefit[index].memberCardLevelId = v;
        this.getCommonChange('memberCardLevelBenefit', newMemberCardLevelBenefit);
    };
    /**
     * 删除选中的行
     */
    deleteCardLevel = (index: number) => {
        const { value, onChange } = this.props;
        const { cardLevelOptions } = this.state;
        const newMemberCardLevelBenefit = [...value.memberCardLevelBenefit];

        //删除的会员等级要释放出来
        const newcardLevelOptions = cardLevelOptions.map((item: any) => {
            if (Number(item.id) === Number(newMemberCardLevelBenefit[index].memberCardLevelId)) {
                item.disabled = false;
            }
            return item;
        });
        this.setState({
            cardLevelOptions: newcardLevelOptions,
        });
        newMemberCardLevelBenefit.splice(index, 1);
        const newValue = Object.assign(value, {
            memberCardLevelBenefit: newMemberCardLevelBenefit,
        });
        onChange && onChange(newValue);
    };

    render() {
        const { value, row } = this.props;
        const { cardLevelOptions } = this.state;
        if (!value) return null;
        const { cardLevelBenefit, memberCardLevelBenefit } = value;
        const benefitType = get(row, 'baseInfo.deductionMode');
        const showNeedSubsite = get(row, 'baseInfo.subsiteId') ? false : true;
        return (
            <div className="grade-equity">
                <div>
                    <Radio.Group
                        className="fill-style"
                        onChange={this.onRadioChange}
                        value={cardLevelBenefit}
                    >
                        <Radio value={'ENABLE'}>{language.getText('sy_1')}</Radio>
                        <Radio value={'DISABLE'}>{language.getText('bsy')}</Radio>
                    </Radio.Group>
                </div>
                {cardLevelBenefit === 'ENABLE' && (
                    <div className="member-card-level-benefit">
                        <div className="grade-list">
                            <div className="grade-title">
                                <div className="item item-first">{language.getText('hydj')}</div>
                                <div className="item item-sec">
                                    {language.getText('everydayBenefit')}
                                </div>
                                <div className="item item-three">
                                    {language.getText('conversionBenefit')}
                                </div>
                                <div className="item item-four">
                                    {language.getText('conversionMax')}
                                </div>
                                <div className="item">{language.getText('common.tableAction')}</div>
                            </div>
                            {memberCardLevelBenefit?.length > 0 &&
                                memberCardLevelBenefit.map((item: any, index: number) => (
                                    <div className="grade-content" key={item.memberCardLevelId}>
                                        <div className="item item-first">
                                            <Select
                                                allowClear
                                                value={item.memberCardLevelId?.toString()}
                                                style={{ width: 120 }}
                                                onDropdownVisibleChange={this.selectCardLevel}
                                                onChange={(value: any) =>
                                                    this.onChangeCardLevelChange(value, index)
                                                }
                                                disabled={showNeedSubsite}
                                            >
                                                {cardLevelOptions &&
                                                    cardLevelOptions.map((level: any) => {
                                                        return (
                                                            <Option
                                                                key={level.id}
                                                                value={level.id}
                                                                disabled={level.disabled}
                                                            >
                                                                {level.name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                        <div className="item item-sec">
                                            <Input
                                                style={{ width: 80 }}
                                                maxLength={6}
                                                value={item.everydayBenefit}
                                                onChange={(e) =>
                                                    this.everydayBenefitChange(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                            {benefitType === 'MONEY' ? (
                                                <span>{language.getText('yuan')}</span>
                                            ) : (
                                                <span>{language.getText('minutes')}</span>
                                            )}
                                        </div>
                                        <div className="item item-three">
                                            <Input
                                                style={{ width: 80 }}
                                                maxLength={6}
                                                value={item.conversionBenefit}
                                                onChange={(e) =>
                                                    this.conversionBenefitChange(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                            {benefitType === 'MONEY' ? (
                                                <span>{language.getText('scoreYuan')}</span>
                                            ) : (
                                                <span>{language.getText('scoreMinutes')}</span>
                                            )}
                                        </div>
                                        <div className="item item-four">
                                            <Input
                                                style={{ width: 80 }}
                                                maxLength={6}
                                                value={item.conversionMax}
                                                onChange={(e) =>
                                                    this.conversionMaxChange(e.target.value, index)
                                                }
                                            />
                                            <span>{language.getText('point')}</span>
                                        </div>
                                        <div className="item">
                                            <Button
                                                type="primary"
                                                onClick={() => this.deleteCardLevel(index)}
                                            >
                                                {language.getText('common.delete')}
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="grade-btn">
                            <Button type="default" onClick={() => this.onAddCardLevel()}>
                                {language.getText('addLevel')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
