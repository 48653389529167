/** 消息群发模块 loader */
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { sortBy } from 'lodash';
const { api } = services;

export const StyleGroupLoader = {
    get: function(data: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/style_group';
        if (config.paramsFilter) {
            data.searchEntryType = config.paramsFilter[0];
            delete config.paramsFilter;
        }
        if (data.id) {
            config.apiPath = `/admin/style_group/${data.id}`;
        }
        return api.get(data, config).then((res: any) => {
            if (data.id) {
                res.baseInfo = {
                    name: res.name,
                    subsite: res.subsiteVo,
                    merchant: res.merchantVo ? [res.merchantVo] : [],
                };
                const optionList = sortBy(res.styleVolist, 'sort');
                // 设置序号最小的商品为主款
                optionList[0].mainGoods = 1;
                res.styleGroupInfo = {
                    optionList,
                    attributeOptions: res.attributeOptions,
                };
                res.otherInfo = {
                    masterDetails: String(res.masterDetails),
                };
            }

            return res;
        });
    },

    /** 修改接口 */
    put: function(data: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/style_group/${data.id}`;
        if (config.paramsFilter) {
            delete config.paramsFilter;
        }
        const subsiteId = data.baseInfo.subsite
            ? data.baseInfo.subsite.id
            : data.baseInfo.merchant && data.baseInfo.merchant.length
            ? data.baseInfo.merchant[0].subSiteId
            : -1;
        const merchantId =
            data.baseInfo.merchant && data.baseInfo.merchant.length
                ? data.baseInfo.merchant[0].merchantId
                : 0;
        const optionList: any = [];
        data.styleGroupInfo.optionList.forEach((item: any) => {
            if (item.goodsId) {
                optionList.push({
                    ...item,
                    subsiteId,
                    merchantId,
                });
            }
        });
        const newParams = {
            name: data.baseInfo.name,
            subsiteId,
            merchantId,
            optionList,
            masterDetails: data.otherInfo.masterDetails === 'true',
        };
        return api.put(newParams, config);
    },

    /** 新增接口 */
    post: function(data: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/style_group';
        if (config.paramsFilter) {
            delete config.paramsFilter;
        }
        const subsiteId = data.baseInfo.subsite
            ? data.baseInfo.subsite.id
            : data.baseInfo.merchant && data.baseInfo.merchant.length
            ? data.baseInfo.merchant[0].subSiteId
            : -1;
        const merchantId =
            data.baseInfo.merchant && data.baseInfo.merchant.length
                ? data.baseInfo.merchant[0].merchantId
                : 0;
        const optionList: any = [];
        data.styleGroupInfo.optionList.forEach((item: any) => {
            if (item.goodsId) {
                optionList.push({
                    ...item,
                    subsiteId,
                    merchantId,
                });
            }
        });
        const newParams = {
            name: data.baseInfo.name,
            subsiteId,
            merchantId,
            optionList,
            masterDetails: data.otherInfo.masterDetails === 'true',
        };
        return api.post(newParams, config);
    },

    /** 新增接口 */
    delete: function(data: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/style_group/${data.id}`;
        return api.delete(data, config);
    },
};
