import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const applayStatusMap: any = {
    APPLYING: '未处理',
    CANCEL: '已取消',
    APPLY_SUCC: '审核通过',
    APPLY_FAIL: '审核未通过',
};
const applayEntryMap: any = {
    MEMBER: '前台申请',
    PLANTFORM: '后台创建',
    SYSTEM: '系统创建',
};
const applayRefundStatus: any = {
    APPLYING: '未处理',
    CANCEL: '已取消',
    APPLY_SUCC: '审核通过',
    REFUND_SUCC: '完成退款',
    REFUND_FAIL: '退款失败',
    REFUND_PART_SUCC: '退款部分成功',
};
const api = services.api;
export const MallActivityAfterSaleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;

        if (!data.id) {
            config.apiPath = '/admin/mall_activity_service_applies';
            // 新商场活动和老商场活动的数据隔离
            if (window.location.hash === '#/mall-activity-after-sale') {
                data.orderType = 'MALL_ACTIVITY';
            } else {
                data.orderType = 'MALL_ACTIVITY_NEW';
            }
        } else {
            config.apiPath = `/admin/mall_activity_orders/${data.id}`;
        }

        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    let statusName = '全部';
                    if (applayStatusMap[item.status]) {
                        statusName = applayStatusMap[item.status];
                    }
                    item.entryTypeName = applayEntryMap[item.entryType] || '';
                    item.refundStatusName = applayRefundStatus[item.refundStatus] || '';
                    item.statusName = statusName;
                    item.subsiteName = item.subsite.name;
                    return item;
                });

            return res;
        });
    },
};
