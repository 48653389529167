import React, { PureComponent, Fragment } from 'react';
import { Card, Row, Col } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface FixedPriceDataReportViewState {
    data: ReportData;
}
interface ReportData {
    id: string;
    memberCount: string;
    orderTotalAmount: string;
    orderTotalCount: string;
    salesCount: string;
    name: string;
}

export class FixedPriceDataReportView extends PureComponent<any, FixedPriceDataReportViewState> {
    state = {
        data: {
            id: '',
            memberCount: '',
            orderTotalAmount: '',
            orderTotalCount: '',
            salesCount: '',
            name: '',
        },
    };
    componentDidMount() {
        const { id } = this.props.params;
        const apiPath = '/admin/fixed_price_promotions/:id/statistical_data';
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                data: res,
            });
        });
    }

    render() {
        const { data } = this.state;
        if (!data) {
            return null;
        }
        return (
            <Fragment>
                <div className="fixed-price-report-header">
                    <span className="fixed-price-report-activity-name">{data.name}</span>
                    {/* <span className="fixed-price-report-activity-tips">数据每个整点更新</span> */}
                </div>
                <Card
                    title={language.getText('orderData')}
                    type="inner"
                    style={{ marginBottom: 32 }}
                >
                    <Row gutter={[16, 12]}>
                        <Col span={6}>
                            <div className="fixed-price-report-item">
                                <div className="fixed-price-report-item-title">
                                    {language.getText('orderTotal')}
                                </div>
                                <div className="fixed-price-report-item-content">
                                    {data.orderTotalCount}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="fixed-price-report-item">
                                <div className="fixed-price-report-item-title">
                                    {language.getText('orderTotalAmount')}
                                </div>
                                <div className="fixed-price-report-item-content">
                                    {data.orderTotalAmount}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="fixed-price-report-item">
                                <div className="fixed-price-report-item-title">
                                    {language.getText('salesCount')}
                                </div>
                                <div className="fixed-price-report-item-content">
                                    {data.salesCount}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="fixed-price-report-item">
                                <div className="fixed-price-report-item-title">
                                    {language.getText('memberCount')}
                                </div>
                                <div className="fixed-price-report-item-content">
                                    {data.memberCount}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Fragment>
        );
    }
}
