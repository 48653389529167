import React, { PureComponent, createElement } from 'react';
import { Modal, message as AntMessage } from 'antd';
import { remove } from 'lodash';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
import { services } from '@comall-backend-builder/core';

let SELECTED_ROW: any = [];

/**
 * 选择货品
 */
export class SelectMerchantBigCodesModal extends PureComponent<any, any> {
    renderComponent() {
        let config = {
            component: 'Card',
            entity: 'MerchantBigCodeSelector',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'FilterForm',
                        componentId: 'MerchantBigCodeSelector-FilterForm',
                        direction: 'inline',
                        fields: [
                            {
                                property: 'bigCode',
                                controlConfig: {
                                    style: { width: 180 },
                                },
                            },
                            {
                                property: 'name',
                                controlConfig: {
                                    style: { width: 180 },
                                },
                            },
                        ],
                        submit: {
                            text: services.language.getText('common.search'),
                        },
                        reset: {
                            text: services.language.getText('common.reset'),
                            style: {
                                marginLeft: 16,
                            },
                        },
                        style: {
                            marginBottom: 16,
                            float: 'right',
                        },
                    },
                    {
                        component: 'DataTable',
                        componentId: 'MerchantBigCodeSelector-table',
                        loadFirstPage: true,
                        rowSelection: {
                            onChange: () => {},
                            onSelect: (record: any, selected: boolean) => {
                                this.onTableRowSelect(record, selected);
                            },
                            onSelectAll: (selected: boolean, rows: any) => {
                                this.onTableRowSelectAll(selected, rows);
                            },
                        },
                        pagination: {
                            pageSize: 10,
                        },
                        columns: [
                            {
                                property: 'code',
                            },
                            {
                                property: 'name',
                            },
                            {
                                property: 'subsiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                        ],
                    },
                ],
            },
        };
        return createElement(ComponentFactory('MerchantBigCodeSelector', config));
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            SELECTED_ROW.push(record);
        } else {
            remove(SELECTED_ROW, (i: any) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            SELECTED_ROW = rows;
        } else {
            SELECTED_ROW = [];
        }
    };

    onCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    };

    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error('请选择商品码');
            return;
        }

        const { onChange } = this.props;

        onChange(SELECTED_ROW);
        SELECTED_ROW = [];
    };

    render() {
        const { showModal } = this.props;
        const props = {
            width: 1000,
            visible: showModal,
            title: services.language.getText('xzsjm'),
            onCancel: this.onCancel,
            onOk: this.onOk,
        };

        return (
            <Modal {...props}>
                <div>{this.renderComponent()}</div>
            </Modal>
        );
    }
}
