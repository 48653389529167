import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

enum ConsumeType {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * pos 核销
     */
    POS_CONSUME = 'POS_CONSUME',
    /**
     * 电商端核销
     */
    SCAN_CONSUME = 'SCAN_CONSUME',
    /**
     * 导购端核销
     */
    MINI_PROGRAM_GUIDE = 'MINI_PROGRAM_GUIDE',
}

const CONSUME_TYPE_OPYIONS = [
    {
        id: ConsumeType.ALL,
        name: '<<all>>',
    },
    {
        id: ConsumeType.POS_CONSUME,
        name: 'pos 核销',
    },
    {
        id: ConsumeType.SCAN_CONSUME,
        name: '<<dsdhx>>',
    },
    {
        id: ConsumeType.MINI_PROGRAM_GUIDE,
        name: '导购端核销',
    },
];

export const config: Config = {
    entities: {
        MallActivityVerificationEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/mallActivityVerification',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                couponNo: {
                    type: 'string',
                    displayName: '核销码',
                    controlConfig: { placeholder: '请输入核销码' },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '手机号' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                activityName: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                code: {
                    type: 'string',
                    displayName: '核销码',
                },
                merchantUserName: {
                    type: 'string',
                    displayName: '<<hxdy>>',
                },
                consumeType: {
                    type: 'string.options.select',
                    displayName: '<<hxfs>>',
                    options: CONSUME_TYPE_OPYIONS,
                },
                createTime: {
                    type: 'string',
                    displayName: '<<hxsj>>',
                },
            },
        },
    },
    components: {
        MallActivityVerificationView: {
            component: 'Viewport',
            entity: 'MallActivityVerificationEntity',
            className: 'table-content-viewport',
        },
        MallActivityVerificationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityVerificationFilter',
                },
                { component: 'MallActivityVerificationTable' },
            ],
        },
        MallActivityVerificationFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'activityName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'couponNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        MallActivityVerificationTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                marginTop: 18,
            },
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'merchantName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'activityName',
                            width: 180,
                        },
                        {
                            property: 'name',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'code',
                        },
                        {
                            property: 'merchantUserName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '130px',
                                },
                            },
                        },
                        {
                            property: 'consumeType',
                        },
                        {
                            property: 'createTime',
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mall-activity-verification',
                    component: 'MallActivityVerificationView',
                    breadcrumbName: '核销记录',
                    privilege: {
                        path: 'mallActivityVerification',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MallActivityVerificationPage' }],
                },
            ],
        },
    ],
};
