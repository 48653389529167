import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';
import { isPlainObject } from 'lodash';

export const PaymentAllocationOrderLoader: Loader = {
    get(params, config) {
        if (!params.id) {
            const { payTime, allocationTime } = params;
            if (allocationTime) {
                params.allocationStartTime = allocationTime.start + ' 00:00:00';
                params.allocationEndTime = allocationTime.end + ' 23:59:59';
                delete params.allocationTime;
            }
            if (payTime) {
                params.paidStartTime = payTime.start + ' 00:00:00';
                params.paidEndTime = payTime.end + ' 23:59:59';
                delete params.payTime;
            }
        }
        return api.get(params, config);
    },
};

export const PaymentAllocationOrderExportLoader: Loader = {
    get(params, config) {
        if (!params.id) {
            const { payTime, allocationTime, subsiteIds } = params;
            if (allocationTime) {
                params.allocationStartTime = allocationTime.start;
                params.allocationEndTime = allocationTime.end;
                delete params.allocationTime;
            }
            if (payTime) {
                params.paidStartTime = payTime.start;
                params.paidEndTime = payTime.end;
                delete params.payTime;
            }
            if (isPlainObject(subsiteIds)) {
                params.subsiteIds = subsiteIds.id;
            }
        }
        return api.get(params, config);
    },
};
