import React, { PureComponent } from 'react';
import { map, remove, find, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import {
    Button as AntButton,
    Button,
    DatePicker,
    Form,
    Input,
    message as AntMessage,
    Modal as AntModal,
    Popover as AntPopover,
    Select,
    Table,
} from 'antd';
import PropTypes from 'prop-types';
import { ColumnProps } from 'antd/lib/table';
import { ExportDataButtonByFilter, ExportFilterType } from '../../export-data-button-by-filter';
import { ForwardDataCenterModal } from '../../../services';
import { LinkTypePlus } from '../../../applications/design/components';
import {
    customSelectorConfig,
    newClientCustomSelectorConfig,
} from '../../../applications/design/design';
import get from 'lodash/get';
import { getMerchantNewLinkTypeEffectPathConfigList } from '../../../components/link-type-effect-path/config';
import {
    linkNameGen,
    linkPathGen,
    transformParams,
} from '../mini-program-code-default-link-edit-button';
import { MiniprogramV2PreviewButton } from '../../miniprogram-v2-preview-button';
import './index.less';

const { api } = services;
const { RangePicker } = DatePicker;

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        state = {
            subsiteSelect: '',
            merchantOptions: [],
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleChange = (value: string) => {
            this.setState({
                subsiteSelect: value,
            });
            this.getMerchantData(value);
        };
        getMerchantData = async (subsiteId: string) => {
            const res = await api.get<any[]>(
                {
                    subSiteName: subsiteId,
                    sequence: 'code',
                    order: 'asc',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/subsites/mine/merchants',
                }
            );
            this.setState({
                merchantOptions: res,
            });
        };
        render() {
            const { form, subsites } = this.props as any;
            const { subsiteSelect, merchantOptions } = this.state;
            const { getFieldDecorator } = form;
            const defaultValue = subsites && subsites.length === 1 ? subsites[0].id : undefined;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label="推广监控名称">
                        {getFieldDecorator('name')(
                            <Input className="find-filter-width" placeholder="请输入推广监控名称" />
                        )}
                    </Form.Item>
                    <Form.Item label="推广门店">
                        {getFieldDecorator('subsiteId', {
                            initialValue: defaultValue,
                        })(
                            <Select
                                className="find-filter-width"
                                allowClear
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={this.handleChange}
                            >
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="推广专柜">
                        {getFieldDecorator('merchantIds')(
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="merchantName"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={!merchantOptions.length || !subsiteSelect}
                            >
                                {merchantOptions &&
                                    merchantOptions.map((merchant: any) => {
                                        return (
                                            <Select.Option
                                                key={merchant.merchantId}
                                                value={merchant.merchantId}
                                            >
                                                {merchant.merchantName}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="链接地址">
                        {getFieldDecorator('urlLink')(
                            <Input className="find-filter-width" placeholder="请输入链接地址" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            查询
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class MiniprogramV2MerchantsCodePage extends PureComponent {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 20,
        },
        selectedRows: new Array<any>(),
        subsites: [],
        time: [], //批量导出时间弹层
        changeTargetVisible: false, //批量修改目标页面
        changeTargetSubsiteId: '0',
        changeTargetValue: {
            linkType: '',
            linkParams: null,
            linkName: '',
            linkPath: '',
        }, //批量修改目标页面target
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            this.setState({
                subsites,
            });
        });
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination } = this.state;
        try {
            let res: any = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                    applicationVersion: 'V2',
                    showTab: 'MERCHANT_CODE',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/mini_program_codes',
                }
            );
            if (res && res.result) {
                this.setState({ datasource: res.result, total: res.totalNum, current: res.page });
            }
        } catch (error) {
            throw error;
        }
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            const rows = [...selectedRows];
            rows.push(record);
            this.setState({ selectedRows: rows });
        } else {
            const rows = [...selectedRows];
            remove(rows, (i) => {
                return i.id === record.id;
            });
            this.setState({ selectedRows: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        const { selectedRows, datasource } = this.state;
        const selectedRowIds = map(selectedRows, 'id');
        if (selected) {
            const addRows = rows.filter((item: any) => {
                return !selectedRowIds.includes(item.id);
            });
            this.setState({ selectedRows: [...selectedRows, ...addRows] });
        } else {
            const deleteIds: any = map(datasource, 'id');
            const deleteRows = selectedRows.filter((item: any) => {
                return !deleteIds.includes(item.id);
            });
            this.setState({ selectedRows: [...deleteRows] });
        }
    };

    //批量导出员工码
    exportCodeFile = () => {
        const { searchParams, selectedRows } = this.state;
        const params = {
            ...searchParams,
            ids:
                selectedRows && selectedRows.length > 0
                    ? selectedRows
                          .map((row: any) => {
                              return row.id;
                          })
                          .join(',')
                    : undefined,
            applicationVersion: 'V2',
            showTab: 'MERCHANT_CODE',
        };
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = `/admin/mini_program_codes/export_code_file`;
        api.post(params, { apiRoot, apiPath })
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch((err) => {
                AntMessage.error(err.response.body.err_msg);
                throw err;
            });
    };

    onDownLoad = () => {
        const { selectedRows } = this.state;
        const params: any = {
            ids:
                selectedRows && selectedRows.length > 0
                    ? selectedRows
                          .map((row: any) => {
                              return row.id;
                          })
                          .join(',')
                    : undefined,
            applicationVersion: 'V2',
            showTab: 'MERCHANT_CODE',
        };
        const { time } = this.state;
        if (time[0] && time[1]) {
            params.startTime = time[0] + ' 00:00:00';
            params.endTime = time[1] + ' 23:59:59';
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = `/admin/mini_program_codes/batch_export`;
        api.post(params, { apiRoot, apiPath })
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch((err) => {
                AntMessage.error(err.response.body.err_msg);
                throw err;
            });
    };

    timeChange = (date: any, dateString: any) => {
        this.setState({ time: dateString });
    };

    renderExprotContent = () => {
        return (
            <div className="export-button-filter">
                <div className="filter-item">
                    <div className="label">请选择数据时间段:</div>
                    <RangePicker size="small" onChange={this.timeChange} format="YYYY-MM-DD" />
                </div>
                <div className="button">
                    <AntButton
                        type="primary"
                        onClick={this.onDownLoad}
                        style={{ padding: 0, width: 60 }}
                    >
                        导出
                    </AntButton>
                </div>
            </div>
        );
    };

    exportPath = () => {
        const { searchParams, selectedRows } = this.state;
        const params: any = {
            ...searchParams,
            ids:
                selectedRows && selectedRows.length > 0
                    ? selectedRows
                          .map((row: any) => {
                              return row.id;
                          })
                          .join(',')
                    : undefined,
            applicationVersion: 'V2',
            showTab: 'MERCHANT_CODE',
        };
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = `/admin/mini_program_codes/batch_export_path`;
        api.post(params, { apiRoot, apiPath })
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch((err) => {
                AntMessage.error(err.response.body.err_msg);
                throw err;
            });
    };

    renderButtons = () => {
        return (
            <div className="find-buttons">
                <Button type="primary" onClick={this.exportCodeFile} className="find-button">
                    批量下载专柜码
                </Button>
                <Button type="primary" onClick={this.exportPath} className="find-button">
                    批量导出路径
                </Button>
                <AntPopover
                    placement="bottomRight"
                    trigger="click"
                    content={this.renderExprotContent()}
                >
                    <Button type="primary" className="find-button">
                        批量导出数据
                    </Button>
                </AntPopover>
                <Button
                    type="primary"
                    onClick={this.hardleChangeTargetVisible}
                    className="find-button"
                >
                    批量修改目标页
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        window.location.href =
                            '#/menu-list/market/miniprogram-v2/edit-staff-link/MERCHANT_CODE';
                    }}
                    className="find-button"
                >
                    修改初始目标页链接
                </Button>
            </div>
        );
    };

    merchantNewLinkTypeEffectPathConfigList = getMerchantNewLinkTypeEffectPathConfigList();

    changeTargetValue = (val: any) => {
        const obj = find(this.merchantNewLinkTypeEffectPathConfigList, { id: val.linkType });
        if (obj) {
            const valObj = { ...val };
            const paramsObj = transformParams(val.linkParams, obj.paramsHandler);
            Object.assign(valObj, {
                linkName: linkNameGen(obj.name, valObj.linkParams),
                linkPath: linkPathGen(obj.basePath, obj.params, paramsObj),
            });
            this.setState({
                changeTargetValue: valObj,
            });
        }
    };
    renderButtonsModal = () => {
        const {
            changeTargetVisible,
            changeTargetValue,
            selectedRows,
            total,
            changeTargetSubsiteId,
        } = this.state;
        const linkTypeSelector = newClientCustomSelectorConfig;
        const linkTypes = this.merchantNewLinkTypeEffectPathConfigList;
        let num = 0;
        if (selectedRows && selectedRows.length > 0) {
            num = selectedRows.length;
        } else {
            num = total;
        }
        if (num === 0) {
            return;
        }
        return (
            <AntModal
                visible={changeTargetVisible}
                okText={services.language.getText('common.ok')}
                title="批量修改目标页"
                onOk={this.submitChangeTarget}
                onCancel={this.hardleChangeTargetVisible}
                cancelButtonProps={{
                    onClick: this.hardleChangeTargetVisible,
                }}
            >
                <div className="change-target-modal">
                    <div className="content">
                        <div>
                            <span className="tip">*</span>
                            <span>&nbsp;目标落地页：</span>
                        </div>
                        <div>
                            <LinkTypePlus
                                selector={linkTypeSelector as any}
                                linkTypes={linkTypes}
                                linkTypeMode="cascader"
                                value={changeTargetValue}
                                onChange={(val) => this.changeTargetValue(val)}
                                subsiteIds={[changeTargetSubsiteId]}
                            />
                            <div className="tip">
                                {services.interpolate(services.language.getText('dyscd'), {
                                    num: num,
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </AntModal>
        );
    };

    hardleChangeTargetVisible = async () => {
        const { changeTargetVisible } = this.state;
        if (!changeTargetVisible) {
            //判断门店验证是否通过，通过才可弹层,并记录门店id在弹层上
            const { searchParams, selectedRows } = this.state;
            const params = {
                ...searchParams,
                ids:
                    selectedRows && selectedRows.length > 0
                        ? selectedRows
                              .map((row: any) => {
                                  return row.id;
                              })
                              .join(',')
                        : undefined,
                applicationVersion: 'V2',
                showTab: 'MERCHANT_CODE',
            };
            const res: any = await services.api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/mini_program_code_subsites',
            });
            if (res) {
                if (res.length > 1) {
                    AntMessage.warning('请选择同一门店的员工码');
                    return;
                } else {
                    this.setState({
                        changeTargetVisible: !changeTargetVisible,
                        changeTargetSubsiteId: res && res[0] ? res[0].id : undefined,
                    });
                }
            }
        } else {
            this.setState({
                changeTargetVisible: false,
                changeTargetSubsiteId: undefined,
            });
        }
    };

    submitChangeTarget = () => {
        const { changeTargetValue } = this.state;
        const linkType = get(changeTargetValue, 'linkType');
        const linkParams = get(changeTargetValue, 'linkParams');
        const configKeys = Object.keys(customSelectorConfig) || [];
        let flag = true;
        const noLinkParams = ['subsite', 'newTopic'];
        map([...configKeys, ...noLinkParams], (item) => {
            if (linkType === item && isEmpty(linkParams)) {
                flag = false;
            }
        });
        if (!flag) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        const { searchParams, selectedRows } = this.state;
        const params: any = {
            ...searchParams,
            ids:
                selectedRows && selectedRows.length > 0
                    ? selectedRows
                          .map((row: any) => {
                              return row.id;
                          })
                          .join(',')
                    : undefined,
            applicationVersion: 'V2',
            showTab: 'MERCHANT_CODE',
            type: linkType,
            target: JSON.stringify(changeTargetValue),
        };
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = '/admin/mini_program_codes/batch_target_link';
        api.put(params, { apiRoot, apiPath })
            .then(() => {
                AntMessage.success(services.language.getText('plxgmbycg'));
                this.setState({
                    changeTargetVisible: false,
                });
                this.onSearch(searchParams);
                this.setState({
                    selectedRows: [],
                });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    getTableColumns = (): ColumnProps<any>[] => {
        return [
            {
                title: '推广监控ID',
                dataIndex: 'id',
            },
            {
                title: '推广监控名称',
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: '推广门店',
                key: 'subsiteName',
                dataIndex: 'subsiteName',
            },
            {
                title: '推广专柜',
                key: 'merchantName',
                dataIndex: 'merchantName',
            },
            {
                title: '推广页面',
                key: 'pageName',
                dataIndex: 'pageName',
            },
            {
                title: '创建时间',
                key: 'createTime',
                dataIndex: 'createTime',
            },
            {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                fixed: 'right',
                width: 200,
                render: (val: any, record: any) => {
                    const urlCopyProps = {
                        type: 'link' as const,
                        style: {
                            padding: 0,
                            marginRight: '10px',
                        },
                        children: services.language.getText('fzlj'),
                        onClick() {
                            let input = document.createElement('input');
                            input.value = `/subpackages/miniprogram-code/index?mid=${record.id}&cid=${record.merchantId}`;
                            document.body.appendChild(input);
                            input.select();
                            if (document.execCommand('Copy')) {
                                AntMessage.success(services.language.getText('ljdzyfz_1'));
                            } else {
                                AntMessage.warning(services.language.getText('ljdzfzsb'));
                            }
                            document.body.removeChild(input);
                        },
                    };
                    const shortCoyProps = {
                        type: 'link' as const,
                        style: {
                            padding: 0,
                            marginRight: '10px',
                        },
                        children: '复制短链',
                        onClick() {
                            let input = document.createElement('input');

                            const { urlLink, alipayUrlLink } = record;
                            let value = alipayUrlLink
                                ? services.language.getText('wx') +
                                  `：${urlLink}${services.language.getText(
                                      'zfb'
                                  )}：${alipayUrlLink}`
                                : `${services.language.getText('wx')}：${urlLink}`;
                            input.value = value;
                            document.body.appendChild(input);
                            input.select();
                            if (document.execCommand('Copy')) {
                                AntMessage.success(services.language.getText('dldzyfz'));
                            } else {
                                AntMessage.warning(services.language.getText('dldzfzsb'));
                            }
                            document.body.removeChild(input);
                        },
                    };
                    return (
                        <div>
                            <a
                                href={`#/menu-list/market/miniprogram-v2/edit-merchant/${record.id}`}
                                style={{ marginLeft: '8px' }}
                            >
                                {services.language.getText('common.edit')}
                            </a>
                            <MiniprogramV2PreviewButton
                                row={record}
                                applicationVersion="V2"
                            ></MiniprogramV2PreviewButton>
                            <ExportDataButtonByFilter
                                row={record}
                                params={{
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/mini_program_codes/{{row.id}}/export',
                                    text: services.language.getText('dcsj_1'),
                                    filters: [ExportFilterType.TIME],
                                    isAsync: true,
                                }}
                            />
                            <AntButton {...urlCopyProps}></AntButton>
                            <AntButton {...shortCoyProps}></AntButton>
                        </div>
                    );
                },
            },
        ];
    };

    render() {
        const { datasource, pagination, total, current, selectedRows, subsites } = this.state;
        const columns = this.getTableColumns();
        return (
            <div className="miniprogram-v2-staff-page-find">
                <div>
                    <CollectionCreateForm onSearch={this.onSearchFind} subsites={subsites} />
                </div>
                {this.renderButtons()}
                {this.renderButtonsModal()}
                <Table
                    scroll={{ x: true }}
                    rowSelection={{
                        fixed: true,
                        type: 'checkbox',
                        selectedRowKeys: map(selectedRows, 'id'),
                        onSelect: (record: any, selected: boolean, selectedRow: any) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                    }}
                    rowKey={(record: any) => record.id}
                    pagination={{
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20', '50', '100'],
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={columns}
                    dataSource={datasource}
                />
            </div>
        );
    }
}
