import * as React from 'react';
import classNames from 'classnames';
import { map, find } from 'lodash';

import { ArrayMode } from '@comall-backend-builder/types';
import { UserRules } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class ArrayUserRulesMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div
                    className={classNames('type-array mode-array-cgb-rule', className)}
                    style={style}
                >
                    {map(array, ({ ruleType, ruleCount }, index) => {
                        let ruleTypeOption = find(options, { id: ruleType }) || {};
                        return (
                            <div key={index}>
                                <span>
                                    {services.language.getText('yhxg')}：{ruleTypeOption.name}
                                </span>
                                <span>
                                    {services.interpolate(services.language.getText('moreBuyNum'), {
                                        ruleCount,
                                    })}
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            services.language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <UserRules {...controlInfo} />;
    }
}
