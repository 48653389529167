import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const ReplyCommonWordConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`;
        config.apiPath = '/admin/reply_common_words_configs';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/reply_common_words_configs/${id}`;
        }
        if (!id) {
            data.scene = 'POS_RECEIPT_ORDER';
            if (!isEmpty(data.subsite)) {
                data.subsiteIds = data.subsite.id;
                delete data.subsite;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                const listRes: any = {};
                listRes.page = 1;
                listRes.perPage = 100;
                listRes.totalNum = 1;
                listRes.result = res.map((item: any) => ({
                    ...item,
                    listSubsites: item.subsites,
                    listSequence: item.sequence,
                }));
                return listRes;
            } else {
                return res;
            }
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`;
        config.apiPath = '/admin/reply_common_words_configs';

        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`;
        if (params.id) {
            config.apiPath = `/admin/reply_common_words_configs/${params.id}`;
        }
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
    delete: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`;
        config.apiPath = `/admin/reply_common_words_configs/${params.id}`;
        return await api.delete(params, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.scene = 'POS_RECEIPT_ORDER';
    newParams.content = params.content;
    newParams.sequence = params.sequence;
    const subsiteIds: Array<number> = [];
    params.subsites.forEach((item: any) => {
        subsiteIds.push(item.id);
    });
    newParams.subsiteIds = subsiteIds;
    return newParams;
}
