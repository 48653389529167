import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal as AntModal, Button as AntButton } from 'antd';
import { get, forEach } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { ComponentsManager, actions, EntitiesManager } from '@comall-backend-builder/core';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 新增页面时选择模板。
 * 页面分类属性决定了可选模板的范围(如：类型为专题的页面，只能选择专题类型的模板)。
 * 如果页面分类属性变化，需要重置页面的模板选择。
 */
class pageTemplateSelector extends PureComponent<any, any> {
    state = { visible: false };
    entity: Entity = new (EntitiesManager.get(
        this.props.applicableChannels === 'NEW_CLIENT'
            ? 'NewPageTemplateForSelectEntity'
            : 'PageTemplateForSelectEntity'
    ))({});

    toggleModalVisible = () => {
        const { visible } = this.state;
        this.setState({ visible: !this.state.visible });
        const paging = this.entity.paging;
        if (!visible) {
            if (paging) {
                this.entity.search({ type: this.props.pageType, ...paging });
            }
        }
    };

    onSelected = (item: any) => {
        const { onChange, name } = this.props;
        onChange(item, name);
        this.toggleModalVisible();
    };

    renderSelectorList = () => {
        const config = {
            entity: this.entity,
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            grid: { gutter: 16, column: 4 },
            type: 'page',
            renderItem: 'PageTemplateListItem',
            onSelected: this.onSelected,
            applicableChannels: this.props.applicableChannels || 'ESHOP',
            params: {
                type: this.props.pageType,
                applicableChannels: this.props.applicableChannels || 'ESHOP',
            },
        };

        return React.createElement(ComponentsManager.get('DataList'), config);
    };

    componentWillReceiveProps(nextProps: any) {
        const nextType = get(nextProps, 'type.id', null);
        const thisType = get(this.props, 'type.id', null);
        if (thisType !== null && nextType !== thisType) {
            this.props.onFieldChange('template', null);
        }
    }

    render() {
        const { value, entity } = this.props;
        const isEdit = !get(entity, 'fields.id');
        return (
            <div className="page-template-selector">
                <span className="template-name">
                    {value ? value.name : language.getText('notSelect')}
                </span>
                {isEdit && (
                    <AntButton
                        type="link"
                        style={{ padding: 0 }}
                        className="span-link"
                        onClick={this.toggleModalVisible}
                    >
                        {language.getText('components.TemplateManage.select')}
                    </AntButton>
                )}
                <AntModal
                    title={language.getText('components.TemplateManage.select')}
                    onOk={this.toggleModalVisible}
                    onCancel={this.toggleModalVisible}
                    okText={language.getText('common.confirm')}
                    cancelText={language.getText('common.cancel')}
                    footer={null}
                    visible={this.state.visible}
                    width={900}
                >
                    {this.renderSelectorList()}
                </AntModal>
            </div>
        );
    }
}

let formComponentId: any = null;
export const PageTemplateSelector = connect(
    (state, props) => {
        let result = {};
        const entityId = get(props, 'entity.id');
        if (entityId) {
            //@ts-ignore
            forEach(state.components, (c, key) => {
                formComponentId = key;
                if (c.entityId === entityId) {
                    //@ts-ignore
                    result.type = c.fields.type;
                }
            });
        }
        return result;
    },
    (dispatch, props) => {
        return {
            onFieldChange: (name: string, status: any) => {
                dispatch(actions.formChangeAction(formComponentId, name, status));
            },
        };
    }
)(pageTemplateSelector);
