import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const ActivityLogsLoader: Loader = {
    get: async function(data) {
        const { apiPath, apiRoot, ...params } = data;
        const config = { apiRoot, apiPath: apiPath.replace('{id}', params.id) };
        delete params.id;
        return await api.get(params, config).then((res: any) => {
            return res;
        });
    },
};

export const OperationLogsLoader: Loader = {
    get: async function(data) {
        const { apiPath, apiRoot, ...params } = data;
        const config = { apiRoot, apiPath };
        return await api.get(params, config).then((res: any) => {
            return res;
        });
    },
};
