import React, { Component } from 'react';
import { Radio, Popover as AntPopover } from 'antd';
import { RadioGroup } from '@comall-backend-builder/components-basis';
import { TipsModal } from '../../../components';
import { modes } from '@comall-backend-builder/types';
import { TipsModalContent } from '../../../components/tips-modal/tips-modal-content';

const RadioGroups = Radio.Group;

export class StringRadioWithTipsModalMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringRadioWithTipsModal {...displayInfo} />;
    }
}

class StringRadioWithTipsModal extends Component<any, any> {
    renderContent = (index: number) => {
        const { tipsModalConfig } = this.props;

        return (
            <div className="new-preview-content">
                <TipsModalContent contentType={tipsModalConfig.contentType[index]} />
            </div>
        );
    };
    onCarparkCheckoutTypeChange = (e: any) => {
        const type = e.target.value;
        const { name, onChange, value } = this.props;
        if (!value) {
            return;
        }
        if (onChange) {
            onChange(type, name);
        }
    };

    render() {
        const { value, tipsModalConfig, options } = this.props;
        if (tipsModalConfig.type === 'noExtra') {
            const radioValue = value || undefined;
            return (
                <div>
                    <RadioGroups onChange={this.onCarparkCheckoutTypeChange} value={radioValue}>
                        {options.map((o: any, index: number) => {
                            return (
                                <AntPopover
                                    placement="bottom"
                                    content={this.renderContent(index)}
                                    trigger="hover"
                                >
                                    <Radio value={o.id}>{o.name}</Radio>
                                </AntPopover>
                            );
                        })}
                    </RadioGroups>
                </div>
            );
        }
        if (tipsModalConfig.type === 'extra') {
            const {
                extraConfig: { before, after },
                width = 1048,
            } = tipsModalConfig;
            return (
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '9px' }}>
                        <RadioGroup {...(this.props as any)} type="text" />
                    </div>
                    {tipsModalConfig && (
                        <div
                            style={{
                                width: width,
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 5,
                                color: '#ccc',
                            }}
                        >
                            {before && <span>{before}</span>}
                            <TipsModal {...tipsModalConfig} />
                            {after && <span>{after}</span>}
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '9px' }}>
                <RadioGroup {...(this.props as any)} type="text" />
                {tipsModalConfig && (
                    <div style={{ marginLeft: 5 }}>
                        <TipsModal {...tipsModalConfig} />
                    </div>
                )}
            </div>
        );
    }
}
