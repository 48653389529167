import * as React from 'react';
import { Tag, Popover } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { interpolate } from '@comall-backend-builder/core/lib/services';

import './index.less';
import { isEmpty, isNil } from 'lodash';
/**
 * 分组数据气泡展示
 * propertyName:参数名
 * remark：说明文字
 * tips 提示文字
 */
export class GroupPopoverModeGoods extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        if (isNil(value) || isEmpty(value))
            return this.getNotAvailableDisplayComponent(displayInfo);
        const remark = interpolate(displayInfo.remark, displayInfo.row);
        const tips = interpolate(displayInfo.tips, displayInfo.row);
        const saleRuleGoodsName = displayInfo.propertyName;
        const content = displayInfo.row[saleRuleGoodsName];
        return (
            <div>
                {remark}{' '}
                {content && content.length > 0 ? (
                    <Popover
                        style={{ zIndex: 1030 }}
                        className="group-popover"
                        placement={'right'}
                        content={
                            <div className="group-popover" style={{ maxWidth: 400 }}>
                                {content.map((i: any) => (
                                    <Tag key={i.saleRuleGoodsId} style={{ margin: 5 }}>
                                        {i.saleRuleGoodsName}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">{tips}</span>
                    </Popover>
                ) : (
                    <span className="group-tips">{tips}</span>
                )}
            </div>
        );
    }
}
