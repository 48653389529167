import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const reviewedOrdersLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/orders';
        if (data) {
            data.status = [3];
            data.queryType = 'PICK';
            if (data.pickingListStatus) delete data.pickingListStatus;
        } else {
            data = {
                status: [3],
                queryType: 'PICK',
            };
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    subsiteName: item.subsite.name,
                    merchantName:
                        item.subsite.merchant && item.subsite.merchant.id !== 0
                            ? item.subsite.merchant.name
                            : '',
                    receiverName: item.orderDelivery.receiverName,
                    receiverMobile: item.orderDelivery.mobile,
                    deliveryMode: item.orderDelivery.deliveryMode,
                    deliveryDate: item.orderDelivery.deliveryDate,
                    memberMobile: item.member.mobile,
                    orderNo: {
                        text: item.orderNo,
                        url: `/8005/order/viewDetail/${item.id}`,
                    },
                };
            });

            return res;
        });
    },
};
