import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const JQSaleBillConfig: Config = {
    components: {
        JQSaleBillView: {
            component: 'Viewport',
            entity: 'JiuQuSaleBillEntity',
        },
        JQSaleBillPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'JQSaleBillTable' }],
        },
        JQSaleBillTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'JQSaleBillFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [],
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: 'JQSaleBillDataTable',
                    loadFirstPage: true,
                    scroll: { x: true },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                displayType: 'dynamic',
                                label: 'arrayTreeNodeIdsTree',
                                maxLength: 3,
                            },
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'productCode',
                        },
                        {
                            property: 'orderTypeName',
                        },
                        {
                            property: 'serviceApplyNo',
                        },
                        {
                            property: 'supplierName',
                        },
                        {
                            property: 'tradeTypeName',
                        },
                        {
                            property: 'quantity',
                        },
                        {
                            property: 'price',
                        },
                        {
                            property: 'discountAmount',
                        },
                        {
                            property: 'couponAmount',
                        },
                        {
                            property: 'pointAmount',
                        },
                        {
                            property: 'tenantPayableFreight',
                        },
                        {
                            property: 'tenantFreightDiscountAmount',
                        },
                        {
                            property: 'tenantDeliverMoney',
                        },
                        {
                            property: 'paidAmount',
                        },
                        {
                            property: 'costIncludeTax',
                        },
                        {
                            property: 'costPrice',
                        },
                        {
                            property: 'costTaxMoney',
                        },
                        {
                            property: 'dutyDifferenceMoney',
                        },
                        {
                            property: 'dutyMoney',
                        },
                        {
                            property: 'supplierDeliverMoney',
                        },
                        {
                            property: 'advertisingExpenses',
                        },
                        {
                            property: 'revenueSharingRatio',
                        },
                        {
                            property: 'revenueSharingAmount',
                        },
                        {
                            property: 'returnGoodsCost',
                        },
                        {
                            property: 'returnFreight',
                        },
                        {
                            property: 'createTime',
                            width: 200,
                        },
                    ],
                },
            ],
        },
        JQSaleBillFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                    marginBottom: 20,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderNo',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/jq-sale-bill',
                    breadcrumbName: '<<JQSaleBill>>',
                    component: 'JQSaleBillView',
                    privilege: {
                        path: 'supplyChainTradeRecord',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'JQSaleBillPage' }],
                },
            ],
        },
    ],
};
