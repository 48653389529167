import { services } from '@comall-backend-builder/core';
import { Button as AntButton, Table as AntTable, Popover, Tag } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';

interface Topic {
    id: string;
    name: string;
    subsites: any;
    enabled: boolean;
}

interface Props {
    value?: Topic[];
    onRemove(value: Topic): void;
}
export default function TopicsDisplayTable(props: Props) {
    const { value, onRemove } = props;
    const tableProps: TableProps<Topic> = {
        dataSource: value,
        pagination: false,
        columns: [
            {
                dataIndex: 'name',
                title: services.language.getText('activityName'),
                render(name) {
                    return name;
                },
            },
            {
                dataIndex: 'subsites',
                title: services.language.getText('subsites'),
                render(name) {
                    return (
                        <div>
                            {name[0].name}{' '}
                            {name && (
                                <Popover
                                    placement={'right'}
                                    content={
                                        <div style={{ maxWidth: 400 }}>
                                            {name.map((i: any) => (
                                                <Tag key={i.id} style={{ marginBottom: 10 }}>
                                                    {i.name}
                                                </Tag>
                                            ))}
                                        </div>
                                    }
                                >
                                    <span className="group-tips">共{name.length}个门店</span>
                                </Popover>
                            )}
                        </div>
                    );
                },
            },
            {
                dataIndex: 'enabled',
                title: '状态',
                render(status: string) {
                    const statusMap: Record<string, string> = {
                        true: '启用',
                        false: '禁用',
                    };
                    return statusMap[status] || '未知';
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                render(_, subsite) {
                    return (
                        <AntButton type="link" onClick={() => onRemove(subsite)}>
                            {services.language.getText('common.delete')}
                        </AntButton>
                    );
                },
            },
        ],
        scroll: {
            y: 300,
        },
    };
    return <AntTable {...tableProps} />;
}
