import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const InternalPurchaseActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;

        let paramsFilter = config.paramsFilter;

        let searchGoodsImportResult =
            paramsFilter && paramsFilter.length > 0 && paramsFilter[0] === 'goodsImportResult';
        if (data.id && searchGoodsImportResult) {
            delete config.paramsFilter;
            config.apiPath = '/admin/internal_purchase_goods_files/:id';
        }

        return api.get(data, config).then((res: any) => {
            if (!data.id) {
                res.result.forEach((item: any) => {
                    // let canEdit = true;
                    // if (item.executeStatus === 'COMPLETE') {
                    //     canEdit = false;
                    // }
                    let nowTime = new Date().getTime();
                    let endTime = new Date(item.endTime).getTime();
                    if (nowTime > endTime && item.status) {
                        item.status = 'COMPLETE_OPEN';
                    } else if (nowTime > endTime && !item.status) {
                        item.status = 'COMPLETE_CLOSE';
                    } else {
                        item.status = item.status + '';
                    }
                    item.canEdit = true;
                    item.canExport =
                        item.executeStatus === 'STARTED' || item.executeStatus === 'COMPLETE';
                    item.urlZipUrl = item.activityQrUrl;
                    item.subsites = item.subsites[0];
                });
                return res;
            } else if (searchGoodsImportResult) {
                res.result.forEach((item: any) => {
                    item.canExport =
                        item.status === 'COMPLETE' ||
                        item.status === 'FAILURE' ||
                        item.status === 'PARTIAL_FAILURE';
                });
                return res;
            } else {
                res.subsites.forEach((subsite: any) => {
                    subsite.id = subsite.id + '';
                });
                res.enterprises.forEach((enterprise: any) => {
                    enterprise.id = enterprise.id + '';
                });
                res.baseInfo = {
                    name: res.name,
                    dateRange: { start: res.startTime, end: res.endTime },
                    picture: res.picture
                        ? [
                              {
                                  id: res.picture.id || '',
                                  path: res.picture.url || '',
                              },
                          ]
                        : null,
                    subsiteIds: res.subsites?.[0].id,
                    enterpriseIds: res.enterprises,
                };
                return res;
            }
        });
    },
    post: async function(params: any, config: any) {
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
            newParams.subsiteIds = [params.baseInfo.subsiteIds];
            const enterpriseIds = params.baseInfo.enterpriseIds.map((item: any) => {
                return item.id;
            });
            newParams.enterpriseIds = enterpriseIds;
            if (params.baseInfo.picture && params.baseInfo.picture.length > 0) {
                newParams.picture = params.baseInfo.picture[0];
            }
        }
        return await api.post(newParams, config);
    },
    put: async function(params: any, config: any) {
        let newParams: any = {};
        newParams.id = params.id;
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
            newParams.subsiteIds = [params.baseInfo.subsiteIds];
            const enterpriseIds = params.baseInfo.enterpriseIds.map((item: any) => {
                return item.id;
            });
            newParams.enterpriseIds = enterpriseIds;
            if (params.baseInfo.picture && params.baseInfo.picture.length > 0) {
                newParams.picture = params.baseInfo.picture[0];
            }
        }
        return await api.put(newParams, config);
    },
};
