import React, { PureComponent, createElement } from 'react';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

const TableComponentId = 'MessageParameterSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择变量
 */
export class MessageParameterSelector extends PureComponent<{
    params?: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('MessageParameterEntity');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    getConfig = () => {
        const { params } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 400,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                            displayConfig: {
                                onCopySuccess: this.toggleModal,
                            },
                        },
                        {
                            property: 'description',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('kybl'),
            visible: this.state.visible,
            footer: null,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button style={{ padding: '0 10px 0 0' }} type="link" onClick={this.toggleModal}>
                    {services.language.getText('ckbl')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
