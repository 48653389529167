import React, { PureComponent, createElement } from 'react';
import { remove, map, cloneDeep, uniqBy, isEqual, get } from 'lodash';
import { connect } from 'react-redux';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, Tag } from 'antd';

const TableComponentId = 'TagSelectorTable';
let TableComponentNo = 1;
let Entity: any = {};
let entity: any = {};

interface tagProps {
    id: string;
    tagName: string;
    subsiteId: string;
    typeName: string;
}

/**
 * 选择标签
 */
export class tagSelector extends PureComponent<{
    selectValues: tagProps[];
    onChange: (tags: tagProps[]) => void;
    params?: any;
    requestStatus: string;
    type?: 'checkbox';
    subsiteId?: string;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('LabelManageEntity');
        entity = new Entity({});
        this.tableComponentId = TableComponentId + TableComponentNo;
        TableComponentNo++;
    }
    tableComponentId: string = '';
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<tagProps>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        //在选中列表中删除数据后更新弹窗选中状态
        if (!isEqual(nextProps.selectValues, this.props.selectValues)) {
            this.selectedRows = nextProps?.selectValues || [];
            this.setSelection(nextProps?.selectValues || []);
        }
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
            }
        }
    }

    setSelection = (rows: any) => {
        const selectedRows = rows;
        if (selectedRows && selectedRows.length > 0) {
            setTimeout(() => {
                this.dispatch(
                    actions.tableRowSelectionChangeAction(TableComponentId, map(selectedRows, 'id'))
                );
            }, 300);
        } else {
            this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
        }
    };

    onTableRowSelect = (record: tagProps, selected: boolean) => {
        if (selected) {
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: tagProps[], unSelectedRows: any) => {
        if (selected) {
            const idSet = new Set(this.selectedRows.map((row) => row.id));
            this.selectedRows = this.selectedRows.concat(rows.filter((row) => !idSet.has(row.id)));
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            const rows = cloneDeep(this.selectedRows);
            const nRow = uniqBy(rows, 'id');
            onChange(nRow);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { type, subsiteId, selectValues } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: {
                subsiteId,
            },
            items: [
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 400,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.id + '';
                        }),
                        onSelect: (record: tagProps, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (
                            selected: boolean,
                            rows: tagProps[],
                            unSelectedRows: tagProps[]
                        ) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'tagName',
                        },
                        {
                            property: 'typeName',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { subsiteId } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    subsiteId,
                });
                this.onClearAll();
            }
            let selectedRows = cloneDeep(this.props?.selectValues || []);
            this.selectedRows = selectedRows;
            this.setSelection(selectedRows);
        });
    };
    removeTag = (tag: any) => {
        const { onChange, selectValues } = this.props;
        let tags = cloneDeep(selectValues || []);
        let newTags = tags.filter((t: any) => t.id !== tag.id);
        if (onChange) {
            const nRow = uniqBy(newTags, 'id');
            onChange(nRow);
        }
    };
    renderTag = (tag: any) => {
        return (
            <div key={tag.id} style={{ marginBottom: 5 }}>
                <Tag closable onClose={() => this.removeTag(tag)} className="category-i">
                    {tag.tagName}
                </Tag>
            </div>
        );
    };

    render() {
        const { subsiteId, selectValues } = this.props;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            zIndex: 1,
            width: 600,
            title: '选择标签',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="primary" disabled={!subsiteId} onClick={this.toggleModal}>
                    选择标签
                </Button>
                {subsiteId ? (
                    <div>
                        <div className="ant-form-extra">当前拥有以下任一标签的用户</div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                            {selectValues &&
                                selectValues?.length > 0 &&
                                selectValues.map(this.renderTag)}
                        </div>
                    </div>
                ) : (
                    <div className="ant-form-extra">请先选择门店</div>
                )}
                <Modal className="tag-modal" {...modalProps}>
                    {modalContent}
                </Modal>
            </div>
        );
    }
}
export const TagSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(tagSelector);
