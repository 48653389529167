import { isObject, isString } from 'lodash';

/**
 * 语言包处理函数，可用于翻译或替换处理语言包中的文本
 * @param language 语言包
 * @param handler 处理函数
 */
export function translater<T extends Record<string, any>>(
    language: T,
    handler: (text: string) => string
): T {
    const newObj: Record<string, any> = {};
    for (const k in language) {
        if (Object.prototype.hasOwnProperty.call(language, k)) {
            const value = language[k];
            if (isString(value)) {
                newObj[k] = handler(language[k]);
            } else if (isObject(value)) {
                newObj[k] = translater(value, handler);
            }
        }
    }
    return newObj as T;
}
