import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { BaseConfig } from '../../../cae/interfaces';
import { get } from 'lodash';
import {
    getConfigDefaultValue,
    FloatingActionButton,
} from '../../../../components/floating-action-buttons-setting/common';
import { FloatingActionButtonsIcon } from '../../../../types/mode/object/floating-action-control-icon';
import { language } from '@comall-backend-builder/core/lib/services';

export enum FloatingActionButtonSize {
    Small = 'SMALL',
    Base = 'BASE',
    Big = 'BIG',
}

enum Status {
    /**
     * 启用
     */
    ENABLE = 'ENABLED',
    /**
     * 禁用
     */
    DISABLE = 'DISABLED',
}

const sizeOptions = [
    {
        id: FloatingActionButtonSize.Small,
        name: '<<small>>',
    },
    {
        id: FloatingActionButtonSize.Base,
        name: '<<middle>>',
    },
    {
        id: FloatingActionButtonSize.Big,
        name: '<<big>>',
    },
];

const validater = (entity: any) => {
    const {
        baseInfo: { pageScopeIds },
        config,
    } = entity;
    if (!pageScopeIds.length) {
        message.warn(language.getText('selectPageRange'));
        return false;
    }
    const controlIcon: FloatingActionButtonsIcon = config.controlIcon;
    if (controlIcon.custom && (!controlIcon.inactiveUrl || !controlIcon.activeUrl)) {
        message.warn(language.getText('setCustomIcon'));
        return false;
    }
    if (!config.subWinNum) {
        message.warn(language.getText('pleaseSetTheMainWindowCustomNum'));
        return false;
    }
    if (!config.buttons.some((item: FloatingActionButton) => item.visible)) {
        message.warn(language.getText('selectedLeastWindow'));
        return false;
    }
    return true;
};

const defaultFloatingActionButtonsIcon: FloatingActionButtonsIcon = {
    custom: false,
};
const getFormConfig = (isAdd?: boolean, disabled?: boolean) => {
    return {
        direction: 'horizontal',
        labelCol: 6,
        controlCol: 18,
        style: { width: 720 },
        fields: [
            { property: 'baseInfo.title' },
            { property: 'baseInfo.dateRange' },
            { property: 'baseInfo.subsites' },
            {
                property: 'baseInfo.channels',
                controlConfig: {
                    isAdd: Boolean(isAdd),
                    disabled: Boolean(disabled),
                },
            },
            { property: 'baseInfo.pageScopeIds' },
            { property: 'config.controlIcon' },
            { property: 'config.subWinNum' },
            { property: 'config.buttons' },
            { property: 'config.size' },
        ],
        submit: { text: isAdd ? '<<common.submit>>' : '<<common.save>>' },
        footer: {
            items: [
                {
                    htmlType: 'button',
                    route: 'goBack',
                    text: '<<common.cancel>>',
                    type: 'default',
                    style: { marginLeft: 10 },
                },
            ],
        },
        onValidate: validater,
        onSubmitSuccess: () => {
            message.success(language.getText('common.saveSuccess'), () =>
                services.behaviorHandle({ route: 'goBack' })
            );
        },
    };
};

export let config: BaseConfig = {
    entities: {
        FloatingActionButton: {
            apiPath: '/loader/admin/floating_action_button',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                title: {
                    type: 'string',
                    displayName: '<<displayTitle>>',
                },
                startTime: {
                    type: 'string.dateTime',
                    displayName: '<<displayStartTime>>',
                },
                endTime: {
                    type: 'string.dateTime',
                    displayName: '<<displayEndTime>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        title: {
                            type: 'string',
                            displayName: '<<displayTitle>>',
                            rules: [
                                { required: true },
                                {
                                    max: 16,
                                    message: '<<displayTitleRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: { width: 360 },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<dispalyTime>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: { width: 360 },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.options.autoComplete',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            defaultValue: [],
                            rules: [{ required: true }],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: { width: 360 },
                            },
                        },
                        channels: {
                            type: 'array.channel.checkbox',
                            displayName: '<<syqd>>',
                            rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                        },
                        pageScopeIds: {
                            displayName: '<<applyPage>>',
                            type: 'array.checkboxPlus',
                            rules: [{ required: true }],
                            defaultValue: [],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/floating_action_button/page_scope',
                                },
                                convertFunction: (item: any) => item,
                                addDefaultSelectAll: false,
                            },
                        },
                    },
                },
                config: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        controlIcon: {
                            type: 'object.floatingActionControlIcon',
                            displayName: '<<mainWindow>>',
                            defaultValue: defaultFloatingActionButtonsIcon,
                            // extra: '<<mainWindowExtra>>',
                        },
                        subWinNum: {
                            type: 'number.tip',
                            defaultValue: 2,
                            displayName: '<<subWinNum>>',
                            controlConfig: {
                                addonbefore: '',
                                addonafter: '',
                                style: { width: '80px' },
                                rules: [
                                    { required: true },
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 5,
                            },
                            extra: '<<mainWindowExtra>>',
                        },
                        buttons: {
                            type: 'array.floatingActionButtons',
                            displayName: '<<childWindow>>',
                            defaultValue: getConfigDefaultValue(),
                            controlConfig: {
                                getSubsiteIds: (row: any) =>
                                    get(row, 'baseInfo.subsites', []).map((item: any) => item.id),
                            },
                            extra: '<<childWindowExtra>>',
                        },
                        size: {
                            type: 'string.options.radio',
                            displayName: '<<buttonSize>>',
                            defaultValue: FloatingActionButtonSize.Base,
                            extra: '<<buttonSizeExtra>>',
                            options: sizeOptions,
                        },
                    },
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    options: [
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                status: {
                    type: 'string.options.radio',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ENABLED',
                    options: [
                        { id: 'ENABLED', name: '<<enable>>' },
                        { id: 'DISABLED', name: '<<disabled>>' },
                    ],
                },
            },
            filters: {
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dispalyTime>>',
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        allowClear: true,
                        style: { width: 160 },
                    },
                },
            },
        },
    },
    components: {
        FloatingActionButtonView: {
            component: 'Viewport',
            entity: 'FloatingActionButton',
        },
        FloatingActionButtonPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        { property: 'subsite' },
                        { property: 'dateRange' },
                        { property: 'executeStatus' },
                    ],
                },
                {
                    component: 'Button',
                    text: '<<addFloatAction>>',
                    route: '/floating-action-button/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 130,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'FloatingActionButtonTable' },
            ],
        },

        FloatingActionButtonTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(language.getText('total'), { total });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                    },
                },
                { property: 'title' },
                { property: 'channels' },
                { property: 'startTime' },
                { property: 'endTime' },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        statusConfig: {
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'status',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT,
                            handles: [
                                {
                                    value: Status.DISABLE,
                                    apiPath: '/admin/floating_action_button/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: Status.ENABLE,
                                    apiPath: '/admin/floating_action_button/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/floating-action-button/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/floating-action-button/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/floating-action-button/log/{{row.id}}/FLOATING_ACTION_BUTTON',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            id: 'row.id',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },

        FloatingActionButtonAddPage: {
            entity: 'FloatingActionButton',
            component: 'FlexLayout',
            items: [
                { component: 'FloatingActionButtonPreview' },
                {
                    component: 'FloatingActionButtonAddForm',
                },
            ],
        },

        FloatingActionButtonAddForm: {
            component: 'CreateFormPlus',
            ...getFormConfig(true),
        },

        FloatingActionButtonEditPage: {
            component: 'FlexLayout',
            entity: 'FloatingActionButton',
            loaderType: 'get',
            items: [
                { component: 'FloatingActionButtonPreview' },
                {
                    component: 'FloatingActionButtonEditForm',
                },
            ],
        },
        FloatingActionButtonEditForm: {
            component: 'ModifyFormPlus',
            ...getFormConfig(false, true),
        },

        FloatingActionButtonCopyPage: {
            component: 'FlexLayout',
            entity: 'FloatingActionButton',
            loaderType: 'get',
            items: [
                { component: 'FloatingActionButtonPreview' },
                {
                    component: 'FloatingActionButtonCopyPageForm',
                },
            ],
        },
        FloatingActionButtonCopyPageForm: {
            component: 'CopyFormPlus',
            ...getFormConfig(),
        },
        FloatingActionLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/floating-action-button',
                    breadcrumbName: '<<floatingActionButton>>',
                    component: 'FloatingActionButtonView',
                    privilege: {
                        path: 'floatingActionButton',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'FloatingActionButtonEditPage',
                            breadcrumbName: '<<editFloatAction>>',
                        },
                        {
                            path: '/add',
                            component: 'FloatingActionButtonAddPage',
                            breadcrumbName: '<<addFloatAction>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'FloatingActionButtonCopyPage',
                            breadcrumbName: '<<copyFloatAction>>',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'FloatingActionLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'FloatingActionButtonPage' },
                    ],
                },
            ],
        },
    ],
};
