import React, { Component } from 'react';
import { Button, Select, message } from 'antd';
import { privilege, api, language } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';

const { Option } = Select;

export class MerchantStyleSelector extends Component<any> {
    state = { options: [] };

    componentDidMount(): void {
        const { row } = this.props;
        const subsite = get(row, 'baseInfo.subsite');
        if (subsite && subsite.id) {
            this.loadOptions(subsite.id);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { row } = this.props;
        const subsite = get(row, 'baseInfo.subsite');
        const nextSubsite = get(nextProps, 'row.baseInfo.subsite');
        if (!subsite && nextSubsite && nextSubsite.id) {
            this.loadOptions(nextSubsite.id);
        }
    }

    loadOptions = (subsiteId: string) => {
        const apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        const apiPath = `/admin/style_labels/by_subsite_id`;
        api.get(
            {
                subsiteId,
            },
            { apiRoot, apiPath }
        ).then((res: any) => {
            if (res) {
                this.setState({ options: res });
            }
        });
    };
    onChange = (value: { key: string; value: string }[]) => {
        const { name, onChange } = this.props;
        onChange(value, name);
    };

    onGoToStylePage = () => {
        if (privilege.check('showStyleLabel', 'full')) {
            window.open('#/show-style-label');
        } else {
            message.warning(language.getText('noPerationPermission'));
        }
    };
    render() {
        const { options } = this.state;
        const { style, value, placeholder } = this.props;
        return (
            <div>
                <div>
                    <Select
                        onChange={this.onChange}
                        mode="multiple"
                        value={value}
                        style={style}
                        placeholder={placeholder || language.getText('selectPlease')}
                        labelInValue
                        optionFilterProp="name"
                        filterOption={(input, option: any) =>
                            (option.props.children || '')
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {options.map((item: any) => (
                            <Option value={item.id} key={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                    <Button
                        type="link"
                        className="btn-data-down"
                        style={{ marginLeft: '10px' }}
                        onClick={this.onGoToStylePage}
                    >
                        {language.getText('createStyle')}
                    </Button>
                </div>
                <div className="ant-form-extra">{language.getText('onlyValidTenants')}</div>
                <div className="ant-form-extra">{language.getText('userChooseNotes')}</div>
            </div>
        );
    }
}
