import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { get, reduce, clone, mapValues, cloneDeep, isNil } from 'lodash';
import { getField } from '@comall-backend-builder/components-basis/lib/form';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { plus } from 'number-precision';
import { ConditionType } from '../maotai-presale-conditions/conditonItem';
export interface MaotaiPresaleAddFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
    onReloadOptions: (fieldName: any, fields: any) => void;
}

class maotaiPresaleAddFormView extends PureComponent<MaotaiPresaleAddFormProps> {
    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, data, onReloadOptions, params } = this.props;
        const roundType = get(data, 'roundsInfo.roundType');
        const dateRange = get(data, 'activityInfo.dateRange');
        const subsites = get(data, 'activityInfo.subsites');
        const drawType = get(data, 'prizeInfo.drawType');
        const pageType = params.pageType;
        let fields: any[] = [
            {
                property: 'activityInfo.code',
            },
            {
                property: 'activityInfo.name',
            },
            {
                property: 'activityInfo.dateRange',
                controlConfig: {
                    format: 'YYYY-MM-DD',
                    style: {
                        width: 450,
                    },
                    range: {
                        start: new Date(),
                    },
                },
            },
            {
                property: 'activityInfo.mode',
            },
            {
                property: 'activityInfo.subsites',
            },
            // {
            //     property: 'activityInfo.goods',
            //     controlConfig: {
            //         selectionType: mode === 'SINGLE' ? 'radio' : 'checkbox',
            //     },
            // },

            {
                property: 'roundsInfo.registerTimeRange',
            },
            {
                property: 'roundsInfo.roundType',
                controlConfig: {
                    onChange(val: string) {
                        const store = builder.getStore();

                        if (val === 'CUSTOM') {
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-MaotaiPresaleAddFormContainer',
                                    'roundsInfo.rounds',
                                    [
                                        {
                                            drawTime: undefined,
                                            drawDateTime: undefined,
                                            quantity: undefined,
                                            whiteListQuantity: undefined,
                                            goods: [],
                                        },
                                    ]
                                )
                            );
                        }
                    },
                },
            },
            {
                property: 'roundsInfo.purchaseMode',
                controlConfig: {
                    pageType: params.pageType,
                },
            },
            {
                property: 'roundsInfo.rounds',
                controlConfig: {
                    roundType,
                    dateRange,
                    pageType: params.pageType,
                },
                visible: (values: any) =>
                    get(values, 'roundsInfo.purchaseMode.mode') !== 'DIFFERENT',
            },
            {
                property: 'roundsInfo.purchaseTime',
            },
            {
                property: 'registrationConditionsInfo.conditions',
            },
            // {
            //     property: 'prizeInfo.prizeCondition',
            // },
            // {
            //     property: 'prizeInfo.memberLimitQuantity',
            // },
        ];
        if (!pageType || pageType !== 'normal') {
            fields.push({
                property: 'prizeInfo.whiteQuantity',
            });
            fields.push({
                property: 'prizeInfo.prizeQualityLimit',
            });
            fields.push({
                property: 'prizeInfo.drawType',
            });
            fields.push({
                property: 'prizeInfo.drawQuantitys',
                controlConfig: {
                    drawType,
                },
            });
        }
        fields.push({
            property: 'messageConfig.messageType',
            controlConfig: {
                subsites,
            },
        });

        const formProps = {
            entity,
            componentId: 'MaotaiPresaleAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {},
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data =
        get(_dispatch, 'components.["DataFormPlus-MaotaiPresaleAddFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(
                    actions.unmountComponentAction('DataFormPlus-MaotaiPresaleAddFormContainer')
                );
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });
            const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展
            if (get(newData, 'roundsInfo.purchaseMode.mode') === 'DIFFERENT') {
                if (!get(newData, 'roundsInfo.purchaseMode.rounds.length')) {
                    AntMessage.error(services.language.getText('qdrcc'));
                    return;
                }
            } else {
                if (!get(newData, 'roundsInfo.rounds.length')) {
                    AntMessage.error(services.language.getText('qtjcc'));
                    return;
                } else {
                    if (newData.roundsInfo.roundType === 'ROUND') {
                        if (
                            newData.roundsInfo.rounds.find(
                                (i: any) => !i.drawTime || !i.goods || !i.goods.length
                            )
                        ) {
                            AntMessage.error(services.language.getText('qjccsjtxwz'));
                            return;
                        }
                    } else {
                        if (
                            newData.roundsInfo.rounds.find(
                                (i: any) => !i.drawDateTime || !i.goods || !i.goods.length
                            )
                        ) {
                            AntMessage.error(services.language.getText('qjccsjtxwz'));
                            return;
                        }
                    }
                    const hasNilItem = newData.roundsInfo.rounds.find((i: any) =>
                        i.goods.find((j: any) => {
                            if (params.pageType === 'normal') {
                                return isNil(j.maotaiQuantity);
                            } else {
                                return isNil(j.maotaiQuantity) || isNil(j.maotaiWhiteListQuantity);
                            }
                        })
                    );
                    if (hasNilItem) {
                        AntMessage.error(services.language.getText('qjccsltxwz'));
                        return;
                    }
                }
            }

            if (get(newData, 'registrationConditionsInfo.conditions.length')) {
                const empty = newData.registrationConditionsInfo.conditions
                    .filter((i: any) => i.checked)
                    .find((i: any) => {
                        if (i.conditionType === ConditionType.AGE) {
                            return !get(i, 'conditionValue.value');
                        } else if (i.conditionType === ConditionType.LEVEL) {
                            return !get(i, 'conditionValue.value.length');
                        } else if (i.conditionType === ConditionType.REGISTRATION_TIME) {
                            if (!get(i, 'conditionValue.timeType')) {
                                return true;
                            } else if (get(i, 'conditionValue.timeType') === 'STATIC') {
                                return !get(i, 'conditionValue.num');
                            } else if (get(i, 'conditionValue.timeType') === 'CUSTOM') {
                                return !get(i, 'conditionValue.dateRange.length');
                            }
                        } else if (
                            [
                                ConditionType.TOTAL_POINT,
                                ConditionType.SUPERMARKET_POINT,
                                ConditionType.STORE_POINT,
                                ConditionType.CONSUME_AMOUNT,
                                ConditionType.TRANSACTION_QUANTITY,
                                ConditionType.UNIT_PRICE,
                            ].includes(i.conditionType)
                        ) {
                            if (!get(i, 'conditionValue.timeType')) {
                                return true;
                            } else if (!get(i, 'conditionValue.moreThan')) {
                                return true;
                            } else if (get(i, 'conditionValue.timeType') === 'CUSTOM') {
                                return !get(i, 'conditionValue.dateRange.length');
                            }
                        } else if (i.conditionType === ConditionType.REGION) {
                            return !get(i, 'conditionValue.value.province');
                        }

                        return false;
                    });

                if (empty) {
                    AntMessage.error(services.language.getText('qjyjtxwz'));
                    return;
                }
            }

            if (get(newData, 'prizeInfo.prizeCondition.length')) {
                const list = newData.prizeInfo.prizeCondition
                    .filter((i: any) => i.checked)
                    .find((i: any) => !i.day);
                if (list) {
                    AntMessage.error(services.language.getText('qjyjgxsggz'));
                    return;
                }
            }

            if (get(newData, 'prizeInfo.prizeQualityLimit.length')) {
                const list = newData.prizeInfo.prizeQualityLimit.find((i: any) => {
                    // if (i.conditionType === 'LIMIT1') {
                    //     return !i.num || !get(i, 'dateRange.length');
                    // }
                    // if (i.conditionType === 'LIMIT2') {
                    //     return !i.num;
                    // }
                    if (i.conditionType === 'LIMIT3') {
                        const isEmpty = i.rules.find((j: any) => {
                            if (!j.num) {
                                return true;
                            } else if (!get(j, 'conditionRules.length')) {
                                return true;
                            } else {
                                return !!j.conditionRules.find((k: any) => !k.length);
                            }
                        });
                        return !!isEmpty;
                    }
                    return false;
                });
                if (list) {
                    AntMessage.error(services.language.getText('qtxmyhsgsl'));
                    return;
                }
            }

            if (get(newData, 'prizeInfo.drawQuantitys.length')) {
                const list = newData.prizeInfo.drawQuantitys.find((i: any) => {
                    if (!i.quantity && get(newData, 'prizeInfo.drawType') === 'CUSTOM') {
                        return true;
                    } else if (i.ruleCommands && i.ruleCommands.length) {
                        const isEmpty = i.ruleCommands.find((j: any) => {
                            if (!get(j, 'length')) {
                                return true;
                            }
                            return false;
                        });
                        return !!isEmpty;
                    }
                    return false;
                });
                if (list) {
                    AntMessage.error(services.language.getText('qjsggztxwz'));
                    return;
                }
                const totalQuantity = newData.prizeInfo.drawQuantitys
                    .map((i: any) => {
                        return i.quantity;
                    })
                    .reduce((prev: number, next: number) => {
                        return plus(prev, next);
                    });
                if (totalQuantity > 100) {
                    AntMessage.error(services.language.getText('kzdslljbkcg'));
                    return;
                }
            }
            if (newData) {
                entity.add(newData, params);
            }
        },
        // 重新加载属性候选值
        onReloadOptions: (fieldName: any, fields: any) => {
            //? 因为在这个场景下reload时fields可能已经不在dataTable上了
            //? 会导致修改被依赖字段未触发获取依赖方获取数据
            //? 所以这里改成从entity上取需要的参数
            const isSubform = fieldName.split('.').length > 1;
            const fieldNamePath = isSubform ? fieldName.replace(/\./g, '.properties.') : fieldName;
            const field = get(entity, `metainfo.properties.${fieldNamePath}`);

            if (!field) return;
            const sourceDefination = field.source;
            let dependences = sourceDefination.dependences;
            let params = reduce(
                dependences,
                (values, dependence) => {
                    values[dependence] = getField(dependence, fields).value;
                    return values;
                },
                props.params ? clone(props.params) : {}
            );
            entity.loadPropertyOptions(fieldName, sourceDefination, params);
        },
    };
}

export const MaotaiPresaleAddFormView = connect(mapStateToProps)(maotaiPresaleAddFormView);
