import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { EfutureCouponChoose } from './efuture-coupon-choose';

/**
 *  余额券选择三方券
 */
export class EfutureCouponChooseMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <EfutureCouponChoose {...controlInfo} />;
    }
}
