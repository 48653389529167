import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectCouponSaleCategory } from '../../../containers';

export class CouponsSaleCategoriesSelector extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectCouponSaleCategory {...displayInfo} />;
    }
}
