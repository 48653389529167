import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const PaymentRewardLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/payment_reward';
        if (data.executeStatus === 'ALL') {
            delete data.executeStatus;
        }
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/payment_reward/${id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.activity.name,
                    dateRange: {
                        start: res.activity.startTime,
                        end: res.activity.endTime,
                    },
                };
                res.ruleInfo = {
                    activityRewardLimit: res.activity.rewardConfig.activityRewardLimit,
                    rewardRule: res.activity.rewardConfig.rewardRule,
                    crowdScope: res.activity.rewardConfig.crowdScope,
                    activityPeriodRewardLimit: res.activity.rewardConfig.activityPeriodRewardLimit,
                    conditionLowerValue: res.luckyRedPackage.conditionLowerValue,
                    totalAmount: res.luckyRedPackage.totalAmount,
                    stopReceiveDays: res.luckyRedPackage.stopReceiveDays,
                };
                let activityProductType = res.luckyRedPackage.luckyRedPackageCoupon.productScope;
                if (res.luckyRedPackage.luckyRedPackageCoupon.productScope === 'INCLUDE_PRODUCTS') {
                    activityProductType = 'INCLUDE_PRODUCT';
                } else if (
                    res.luckyRedPackage.luckyRedPackageCoupon.productScope === 'EXCLUDE_PRODUCTS'
                ) {
                    activityProductType = 'EXCLUDE_PRODUCT';
                }
                let productsValue: any = null;
                if (
                    res.luckyRedPackage.luckyRedPackageCoupon.goods &&
                    res.luckyRedPackage.luckyRedPackageCoupon.goods.length > 0
                ) {
                    productsValue = {
                        type: 'APPOINT_PRODUCT',
                        values: res.luckyRedPackage.luckyRedPackageCoupon.goods.map((item: any) => {
                            return {
                                ...item,
                                name: item.productName,
                                code: item.goodsCode,
                                subsiteName: item.subsite.name,
                                merchantName: item.merchant.name,
                            };
                        }),
                    };
                }
                if (
                    res.luckyRedPackage.luckyRedPackageCoupon.brands &&
                    res.luckyRedPackage.luckyRedPackageCoupon.brands.length > 0
                ) {
                    productsValue = {
                        type: 'APPOINT_BRAND',
                        values: res.luckyRedPackage.luckyRedPackageCoupon.brands,
                    };
                }
                let consumeChannel: any = [];
                forEach(res.luckyRedPackage.luckyRedPackageCoupon.dimensions, (dimension) => {
                    if (dimension.dimensionType === 'CHANNEL') {
                        consumeChannel = dimension.ids.map((value: any) => {
                            return value + '';
                        });
                    }
                });
                res.ruleInfo = {
                    couponName: res.luckyRedPackage.luckyRedPackageCoupon.couponName,
                    conditionLowerValue:
                        res.luckyRedPackage.luckyRedPackageCoupon.conditionLowerValue,
                    validityPeriod: res.luckyRedPackage.luckyRedPackageCoupon.validityPeriod,
                    selectMode: res.luckyRedPackage.luckyRedPackageCoupon.subSiteOrMerchant,
                    shop: res.luckyRedPackage.luckyRedPackageCoupon.subSiteMerchants,
                    subsite: res.luckyRedPackage.luckyRedPackageCoupon.subsites,
                    activityProductType: activityProductType,
                    products: productsValue,
                    consumeChannel: consumeChannel,
                };
                res.pictureInfo = {
                    shareTitle: res.luckyRedPackage.title,
                    paymentFinishPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .paymentFinishPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .paymentFinishPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .paymentFinishPicture.url,
                              },
                          ]
                        : null,
                    orderDetailPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .orderDetailPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow.orderDetailPicture
                                          .id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow.orderDetailPicture
                                          .url,
                              },
                          ]
                        : null,
                    forwardPicture: res.luckyRedPackage.luckyRedPackagePageShow.forwardPicture
                        ? [
                              {
                                  id: res.luckyRedPackage.luckyRedPackagePageShow.forwardPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow.forwardPicture
                                          .url,
                              },
                          ]
                        : null,
                    activityPageBottomPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .activityPageBottomPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageBottomPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageBottomPicture.url,
                              },
                          ]
                        : null,
                    activityPageButtonPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .activityPageButtonPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageButtonPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageButtonPicture.url,
                              },
                          ]
                        : null,
                    activityRuleBackgroundColor:
                        res.luckyRedPackage.luckyRedPackagePageShow.activityRuleBackgroundColor,
                    description: res.activity.description,
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.canExport =
                                item.executeStatus === 'STARTED' ||
                                item.executeStatus === 'COMPLETE';
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/payment_reward';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        if (params.id) {
            config.apiPath = `/admin/payment_reward/${params.id}`;
        }
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const shopArray: any = [];
    const subsiteArray: any[] = [];
    if (params.ruleInfo.merchants && params.ruleInfo.merchants.length > 0) {
        forEach(params.ruleInfo.merchants, (shop) => {
            shopArray.push(`${shop.subSiteId},${shop.merchantId}`);
        });
    }
    if (params.ruleInfo.subsites && params.ruleInfo.subsites.length > 0) {
        forEach(params.ruleInfo.subsites, (subsite) => {
            subsiteArray.push(subsite.id);
        });
    }
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
    }
    if (params.ruleInfo) {
        newParams.activityType = params.ruleInfo.activityType;
        newParams.merchants = params.ruleInfo.selectMode === 'MERCHANT' ? shopArray : [];
        newParams.subsites = params.ruleInfo.selectMode === 'SUB_SITE' ? subsiteArray : [];
        newParams.activityProductType = params.ruleInfo.activityProductType;

        if (params.ruleInfo.activityProductType) {
            if (
                params.ruleInfo.activityProductType === 'INCLUDE_PRODUCT' ||
                params.ruleInfo.activityProductType === 'EXCLUDE_PRODUCT'
            ) {
                newParams.rangeType = params.ruleInfo.products.type;
                newParams.promotionScopeIds =
                    params.ruleInfo.products && params.ruleInfo.products.values
                        ? params.ruleInfo.products.values.map((value: any) => value.id)
                        : [];
            }
        }
    }
    if (params.rewardInfo) {
        newParams.rewardTime = params.rewardInfo.rewardTime;
        newParams.rewardCondition = params.rewardInfo.rewardCondition;
        newParams.rewardRecycleCondition = params.rewardInfo.rewardRecycleCondition;

        let rewards: any = [];
        forEach(params.rewardInfo.rewards, (reward) => {
            const couponRewardsArray: any = [];
            forEach(reward.coupons, (coupon) => {
                couponRewardsArray.push({
                    couponDefinitionId: coupon.id,
                    count: coupon.quantity,
                });
            });
            rewards.push({
                lowerValue: reward.lowerValue,
                couponRewards: couponRewardsArray,
            });
        });
        newParams.rewards = rewards;
    }
    if (params.pictureInfo) {
        newParams.picture = params.pictureInfo.picture
            ? {
                  id: params.pictureInfo.picture[0].id,
                  url: params.pictureInfo.picture[0].path,
              }
            : null;
        newParams.description = params.pictureInfo.description;
    }

    return newParams;
}
