import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Table, Badge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import { GroupPopover } from '../../../../components';
import { MemberInfoStatus } from '../../common';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

interface Props {
    memberId: string;
}
interface State {
    datasource: any[];
    loading: boolean;
}

export default class MemberInfoSubsitePage extends PureComponent<Props, State> {
    state: State = {
        datasource: [],
        loading: true,
    };

    componentDidMount() {
        this.onSearchData();
    }

    private onSearchData = async () => {
        try {
            const { memberId } = this.props;
            this.setState({
                loading: true,
            });
            const res: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: `/admin/members/${memberId}/subsite_members`,
                }
            );
            this.setState({
                datasource: res,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    private renderTable = () => {
        const { datasource, loading } = this.state;
        const statusOption: {
            [str: string]: BadgeProps;
        } = {
            [MemberInfoStatus.NORMAL]: {
                text: language.getText('zc_1'),
                status: 'processing',
            },
            [MemberInfoStatus.INVALID]: {
                text: language.getText('invalid'),
                status: 'default',
            },
            [MemberInfoStatus.OFF]: {
                text: language.getText('off'),
                status: 'default',
            },
            [MemberInfoStatus.UNKNOWN]: {
                text: language.getText('wz_4'),
                status: 'default',
            },
        };

        return (
            <div className={`${prefix}__table`}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    loading={loading}
                    rowKey={(record: any, index: number) => `${prefix}-${index}`}
                    pagination={false}
                    scroll={{ y: 600 }}
                    columns={[
                        {
                            title: language.getText('rzmd'),
                            dataIndex: 'subsites',
                            render: (val: any[] = []) => {
                                const list = val.map((item: any) => ({
                                    id: item.id,
                                    name: item.subsiteName,
                                }));
                                return (
                                    <GroupPopover list={list} replaceEmpty="" countSuffix="门店" />
                                );
                            },
                        },
                        {
                            title: 'CID',
                            dataIndex: 'cid',
                        },
                        {
                            title: language.getText('rzsj'),
                            dataIndex: 'regTime',
                        },
                        {
                            title: language.getText('dqzt'),
                            dataIndex: 'status',
                            render: (val: MemberInfoStatus) => (
                                <span>
                                    <Badge {...statusOption[val]} />
                                </span>
                            ),
                        },
                        {
                            title: '会员等级',
                            dataIndex: 'cardLevelName',
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    };

    render() {
        return <div className={prefix}>{this.renderTable()}</div>;
    }
}

const prefix = 'member-info-subsite-page';
