import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach } from 'lodash';

const { getText } = services.language;

export const config: Config = {
    entities: {
        StoredValueCardProductEntity: {
            apiPath: '/loader/admin/stored_value_card_product',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        code: {
                            type: 'string',
                            displayName: '<<klbbm>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrklbbm>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxfklbdybm>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        name: {
                            type: 'string',
                            displayName: '<<klbmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrklbmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        addType: {
                            type: 'string.radio.StoredValueCardType',
                            displayName: '<<klx>>',
                            defaultValue: 'E_STORED_VALUE_CARD',
                            rules: [{ required: true }],
                        },
                        editType: {
                            type: 'string.options.radio',
                            displayName: '<<klx>>',
                            defaultValue: 'E_STORED_VALUE_CARD',
                            rules: [{ required: true }],
                            options: [
                                { id: 'E_STORED_VALUE_CARD', name: '<<dzmzk>>' },
                                { id: 'E_RECHARGE_STORED_VALUE_CARD', name: '<<dzczk>>' },
                                { id: 'P_STORED_VALUE_CARD', name: '<<stk>>' },
                            ],
                        },
                        pictures: {
                            displayName: '<<smtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'picture-card',
                                maxSize: 1 * 1024,
                            },
                            extra: '<<h_smtp_tip>>',
                        },
                        cardListPicture: {
                            displayName: '<<klbtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'picture-card',
                                maxSize: 1 * 1024,
                            },
                            extra: '<<h_klbtp_tip>>',
                        },
                    },
                },
                settingInfo: {
                    type: 'object.subForm',
                    displayName: '<<mzdy>>',
                    properties: {
                        amountDefinitions: {
                            type: 'array.storedValueCardAmountDefinitions',
                            displayName: '<<settings>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                useRulesInfo: {
                    type: 'object.subForm',
                    displayName: '<<instructions>>',
                    properties: {
                        description: {
                            type: 'string',
                            displayName: '<<ms>>',
                            controlConfig: {
                                placeholder: '<<h_value_card_ms_placeholder>>',
                                maxLength: 200,
                                style: {
                                    width: 550,
                                },
                            },
                            extra: '<<h_value_card_ms_tip>>',
                        },
                        details: {
                            type: 'string.text.paragraph',
                            displayName: '<<syxz>>',
                            rules: [{ max: 1000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 1000,
                            },
                        },
                    },
                },
                seniorSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        shareTitle: {
                            type: 'string',
                            displayName: '<<zzfxbt>>',
                            controlConfig: {
                                placeholder: '<<h_zzfxbt_placeholder>>',
                                maxLength: 30,
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<h_zzfxbt_tip>>',
                        },
                        sharePicture: {
                            displayName: '<<zzfxtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<h_zzfxtp_tip>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                    displayName: '<<xh_2>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<bm_1>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<klx>>',
                    options: [
                        { id: 'E_STORED_VALUE_CARD', name: '<<dzmzk>>' },
                        { id: 'E_RECHARGE_STORED_VALUE_CARD', name: '<<dzczk>>' },
                        { id: 'P_STORED_VALUE_CARD', name: '<<stk>>' },
                    ],
                },
            },
        },
    },
    components: {
        StoredValueCardProductView: {
            component: 'Viewport',
            entity: 'StoredValueCardProductEntity',
        },
        StoredValueCardProductPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'StoredValueCardProductEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    // style: {
                    //     marginTop: 18,
                    // },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzklb_1>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/stored-value-card-product/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'StoredValueCardProductTable' },
            ],
        },
        StoredValueCardProductTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'code',
                },
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '300px',
                        },
                    },
                },
                {
                    property: 'type',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 100,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/stored-value-card-product/edit/{{row.id}}',
                    },
                ],
            },
        },
        StoredValueCardProductAddPage: {
            component: 'Card',
            content: { component: 'StoredValueCardProductAddForm' },
        },
        StoredValueCardProductAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardProductEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.code' },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.addType' },
                { property: 'baseInfo.pictures' },
                { property: 'baseInfo.cardListPicture' },
                { property: 'settingInfo.amountDefinitions' },
                { property: 'useRulesInfo.description' },
                { property: 'useRulesInfo.details' },
                { property: 'seniorSetting.shareTitle' },
                { property: 'seniorSetting.sharePicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const amountDefinitions = entity.settingInfo.amountDefinitions;
                if (!amountDefinitions || amountDefinitions.length === 0) {
                    AntMessage.warning(getText('qtjmzdy'));
                    return false;
                }
                if (amountDefinitions.length > 0) {
                    //输入验证
                    let emptyAmountFlag = false;
                    let inputAmountFlag = false;
                    let emptyPriceFlag = false;
                    let inputPriceFlag = false;
                    let equalFlag = false;
                    forEach(amountDefinitions, (i) => {
                        if (i.amount === undefined || i.amount === null || i.amount === '') {
                            emptyAmountFlag = true;
                            return false;
                        }
                        if (i.amount <= 0) {
                            inputAmountFlag = true;
                            return false;
                        }
                        if (i.price === undefined || i.price === null || i.price === '') {
                            emptyPriceFlag = true;
                            return false;
                        }
                        if (i.price <= 0) {
                            inputPriceFlag = true;
                            return false;
                        }
                        //面值不能有相同的验证
                        const values = amountDefinitions.filter((j: any) => j.amount === i.amount);
                        if (values.length > 1) {
                            equalFlag = true;
                            return false;
                        }
                    });
                    if (emptyAmountFlag) {
                        AntMessage.warning(getText('qsrmz'));
                        return false;
                    }
                    if (inputAmountFlag) {
                        AntMessage.warning(services.language.getText('mzbnw'));
                        return false;
                    }
                    if (emptyPriceFlag) {
                        AntMessage.warning(getText('qsrsmj'));
                        return false;
                    }
                    if (inputPriceFlag) {
                        AntMessage.warning(services.language.getText('smjbnwZero'));
                        return false;
                    }
                    if (equalFlag) {
                        AntMessage.warning(getText('bnpzxtdmz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardProductEditPage: {
            component: 'Card',
            content: { component: 'StoredValueCardProductEditForm' },
        },
        StoredValueCardProductEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'StoredValueCardProductEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.code',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.editType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.pictures' },
                { property: 'baseInfo.cardListPicture' },
                {
                    property: 'settingInfo.amountDefinitions',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'useRulesInfo.description' },
                { property: 'useRulesInfo.details' },
                { property: 'seniorSetting.shareTitle' },
                { property: 'seniorSetting.sharePicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const amountDefinitions = entity.settingInfo.amountDefinitions;
                if (!amountDefinitions || amountDefinitions.length === 0) {
                    AntMessage.warning(getText('qtjmzdy'));
                    return false;
                }
                if (amountDefinitions.length > 0) {
                    //输入验证
                    let emptyAmountFlag = false;
                    let inputAmountFlag = false;
                    let emptyPriceFlag = false;
                    let inputPriceFlag = false;
                    let equalFlag = false;
                    forEach(amountDefinitions, (i) => {
                        if (i.amount === undefined || i.amount === null || i.amount === '') {
                            emptyAmountFlag = true;
                            return false;
                        }
                        if (i.amount <= 0) {
                            inputAmountFlag = true;
                            return false;
                        }
                        if (i.price === undefined || i.price === null || i.price === '') {
                            emptyPriceFlag = true;
                            return false;
                        }
                        if (i.price <= 0) {
                            inputPriceFlag = true;
                            return false;
                        }
                        //面值不能有相同的验证
                        const values = amountDefinitions.filter((j: any) => j.amount === i.amount);
                        if (values.length > 1) {
                            equalFlag = true;
                            return false;
                        }
                    });
                    if (emptyAmountFlag) {
                        AntMessage.warning(getText('qsrmz'));
                        return false;
                    }
                    if (inputAmountFlag) {
                        AntMessage.warning(services.language.getText('mzbnw'));
                        return false;
                    }
                    if (emptyPriceFlag) {
                        AntMessage.warning(getText('qsrsmj'));
                        return false;
                    }
                    if (inputPriceFlag) {
                        AntMessage.warning(services.language.getText('smjbnwZero'));
                        return false;
                    }
                    if (equalFlag) {
                        AntMessage.warning(getText('bnpzxtdmz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/stored-value-card-product',
                    component: 'StoredValueCardProductView',
                    breadcrumbName: '<<storedValueCardProduct>>',
                    privilege: {
                        path: 'storedValueCardProduct',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'StoredValueCardProductAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'StoredValueCardProductEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'StoredValueCardProductPage' },
                    ],
                },
            ],
        },
    ],
};
