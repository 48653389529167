import React, { PureComponent } from 'react';
import { api, localStorage } from '@comall-backend-builder/core/lib/services';
import { Button, message as AntMessage, Modal, Switch } from 'antd';
import { find, forEach, isNil } from 'lodash';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';
import {
    SceneType,
    SendStatus,
    targetOptions,
    WechantTemplatesOption,
    wechantTemplatesTargetOptions,
} from '../common';
import { WechatTemplatesFindListItemEditView } from '../wechat-templates-find-list-item-edit-view';
import { searchWebParse } from '../../../services/utils';
import './index.less';

/**
 * 模板消息列表项
 */
interface WechantTemplatesFindListItemInfo {
    code: string;
    serviceItem: string;
    id: number;
    name: string;
    params: Array<{ name: string; value: string; color?: string }>;
    targetAppId: string;
    targetUrl: string;
    sceneRemark: string;
    scene: SceneType;
    createTime: string;
    creator: string;
    templateId: string;
    supportManualSending: boolean;
    sendRecord: {
        id: string;
        sendStatus: SendStatus;
        reason: string;
        actualSendCount: number;
        expectedSendCount: number;
        successSendCount: number;
    };
    wechatTemplateId: string;
}

interface WechantTemplatesFindListItemProps extends WechantTemplatesFindListItemInfo {
    /**
     * 其他参数
     */
    parentProps: any;
}

interface WechantTemplatesFindListItemStates {
    visible: boolean;
}

/**
 * 模板消息Item
 */
export class WechantTemplatesFindListItem extends PureComponent<
    WechantTemplatesFindListItemProps,
    WechantTemplatesFindListItemStates
> {
    handleSwitchChange = (checked: boolean) => {
        const id = this.props.id;
        if (checked) {
            let storeInfo: any = localStorage.get('wechantTemplatesStore');
            let subsiteId = '';
            if (storeInfo && storeInfo.id) {
                subsiteId = storeInfo.id;
            }
            const params = this.getPostParams();
            api.post(
                {
                    subsiteId: subsiteId,
                    ...params,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: `/admin/wechat_templates`,
                }
            )
                .then(() => {
                    this.refreshList();
                })
                .catch((err: any) => {
                    const {
                        body: { err_msg },
                    } = err.response;
                    if (err_msg) {
                        AntMessage.warning(err_msg);
                    }
                });
        } else {
            api.delete(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: `/admin/wechat_templates/${id}`,
                }
            )
                .then(() => {
                    this.refreshList();
                })
                .catch((err: any) => {
                    const {
                        body: { err_msg },
                    } = err.response;
                    if (err_msg) {
                        AntMessage.warning(err_msg);
                    }
                });
        }
    };

    getPostParams = () => {
        const { code, scene, params, wechatTemplateId } = this.props;
        const top = params.find((item) => item.name === 'top');
        const bottom = params.find((item) => item.name === 'bottom');
        return {
            code,
            topContent: top ? top.value : '',
            bottomContent: bottom ? bottom.value : '',
            targetUrl:
                targetOptions[scene] && targetOptions[scene].length > 0
                    ? targetOptions[scene][0].path
                    : undefined,
            wechatTemplateId,
        };
    };

    refreshList = () => {
        const {
            parentProps: { entity },
        } = this.props;
        entity.search();
    };

    onDelete = () => {
        const { id } = this.props;
        if (!id) {
            return;
        }
        Modal.confirm({
            title: services.language.getText('sfqrscgmb'),
            onOk: () => {
                api.delete(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: '/admin/wechat_templates/manual/' + id,
                    }
                ).then(
                    () => {
                        this.refreshList();
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                );
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
        });
    };

    render() {
        const props = this.props;
        if (!props) {
            return;
        }
        let top, bottom;
        const other: any = [];
        if (props && props.params) {
            top = find(props.params, { name: 'top' });
            bottom = find(props.params, { name: 'bottom' });
            forEach(props.params, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    other.push(param);
                }
            });
        }
        let status = true;
        let className = '';
        if (!props.templateId) {
            status = false;
            className = 'wechat-templates-find-list-item__disabled';
        }
        return (
            props && (
                <div className={classNames('wechat-templates-find-list-item', className)}>
                    <div className="wechat-templates-find-list-item__switch">
                        {props.scene === SceneType.MANUAL_ADD ? (
                            <Button
                                type="link"
                                onClick={this.onDelete}
                                style={{ padding: 0, marginTop: '-10px' }}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : (
                            <Switch
                                checkedChildren={services.language.getText('kai')}
                                unCheckedChildren={services.language.getText('guan')}
                                onChange={this.handleSwitchChange.bind(this)}
                                checked={status}
                            />
                        )}
                    </div>
                    <div className="wechat-templates-find-list-item__content-wrap">
                        <div className="wechat-templates-find-list-item__header">
                            <div>
                                {services.language.getText('mbmc')}：{props.name}
                            </div>
                            {!isNil(props.code) ? (
                                <div>
                                    {props.code.includes('OPENTM') ? (
                                        <div>
                                            {services.language.getText('lsmb')}：{props.code}
                                        </div>
                                    ) : (
                                        <div>
                                            {services.language.getText('lmmb')}：{props.code}&nbsp;(
                                            {props.serviceItem})
                                        </div>
                                    )}
                                </div>
                            ) : null}
                            {props.scene === SceneType.MANUAL_ADD && props.createTime && (
                                <div className="wechat-templates-find-list-item__remark">
                                    <div>{services.language.getText('tjsj_1')}：</div>
                                    <div>
                                        {props.createTime}&nbsp;&nbsp;{props.creator}
                                    </div>
                                </div>
                            )}
                            <div className="wechat-templates-find-list-item__remark">
                                <div>{services.language.getText('cjsm')}：</div>
                                <div className="wechat-templates-find-list-item__remark__value">
                                    {props.sceneRemark}
                                </div>
                            </div>
                        </div>
                        <div className="wechat-templates-find-list-item__content">
                            <div
                                style={{
                                    whiteSpace: 'pre-line',
                                    // color: top && top.color ? top.color : '#747474'
                                }}
                            >
                                {top && top.value}
                            </div>
                            <div className="wechat-templates-find-list-item__text-disabled">
                                {other.map((o: any) => {
                                    return (
                                        <div
                                            key={o.name}
                                            className="wechat-templates-find-list-item__other-item"
                                        >
                                            <span>{o.name}：</span>
                                            <span
                                                style={{
                                                    flex: 1,
                                                    color: o && o.color ? o.color : '#000',
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {o.value}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div
                                style={{
                                    color: bottom && bottom.color ? bottom.color : '#D0021B',
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {bottom && bottom.value}
                            </div>
                        </div>
                    </div>
                    <div className="wechat-templates-find-list-item__footer">
                        {this.renderTargetText()}
                        {props.templateId && (
                            <div className="wechat-templates-find-list-item__footer-text">
                                <span>
                                    {services.language.getText('templateId')}：{props.templateId}
                                </span>
                            </div>
                        )}
                    </div>
                    {status && props.scene !== SceneType.NEW_ORDER_REMIND && (
                        <WechatTemplatesFindListItemEditView
                            itemProps={props}
                            refreshList={this.refreshList}
                        />
                    )}
                </div>
            )
        );
    }

    renderTargetText = () => {
        const props = this.props;
        if (!props) {
            return null;
        }
        const scene = props.scene;
        const options = wechantTemplatesTargetOptions[scene];
        if (!options || !options.length) {
            return null;
        }
        let targetUrl = props.targetUrl || '';
        let targetName = '';
        let targetLabel = '';
        options.forEach((option: WechantTemplatesOption) => {
            if (option.children) {
                option.children.forEach((o: WechantTemplatesOption) => {
                    if (targetUrl.includes(o.value)) {
                        targetLabel = o.label || '';
                    }
                });
            } else {
                if (targetUrl.includes(option.value)) {
                    targetLabel = option.label || '';
                }
            }
        });

        const urlVo = targetUrl.split('?');
        const pageParams: string = urlVo && urlVo.length >= 1 ? urlVo[1] : '';
        const params = urlVo && urlVo.length > 1 ? searchWebParse(pageParams) : null;
        if (params && params.name) {
            targetName = (params.name as string) || '';
        }

        return (
            targetLabel && (
                <div className="wechat-templates-find-list-item__footer-text">
                    <span>
                        {services.language.getText('tzldy')}：{targetLabel}
                    </span>
                    {targetName && <span>-{targetName}</span>}
                </div>
            )
        );
    };
}
