import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { IntegerInputTitleTip } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export class IntegerInputTitleTipMode extends modes.ObjectMode {
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style, title, addonbefore, addonafter } = displayInfo;
        if (object) {
            return (
                <div className={classNames('input-title-tip', className)} style={style}>
                    {title && <div>{title}</div>}
                    <div>
                        {object.eachPresale > 0 && (
                            <span>
                                {addonbefore}
                                <span>{object.maxBindQuantity} </span>
                                {addonafter}
                            </span>
                        )}
                    </div>
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <IntegerInputTitleTip {...displayInfo} />;
    }
}
