import React, { useCallback, useEffect, useState } from 'react';
import { Radio, Select, Input, InputNumber } from 'antd';
import { get, cloneDeep, find } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

const { api } = services;

const defaultMemberLevels = [{ id: 0, name: '全部等级' }];

/**
 * 会员等级设置值类型
 */
interface MemberLevelRuleValue {
    type: 'ALL' | 'LEVEL';
    levelId: string;
    levelName: string;
    pointDouble: string;
    point: string;
}
interface MemberLevelRuleValueProps {
    onChange: (value: any) => void;
    value: MemberLevelRuleValue | undefined;
    row: { baseInfo: { subsiteId: string } };
    rewardType: 'POINT' | 'POINT_DOUBLE';
}

export const MemberLevelRule = (props: MemberLevelRuleValueProps) => {
    const subsiteId = get(props, 'row.ruleInfo.subsiteId');
    const [memberLevels, setMemberLevels] = useState<any>(defaultMemberLevels);

    useEffect(() => {
        if (!subsiteId || props.value?.type === 'ALL' || !props.value) {
            return;
        }

        api.get(
            {
                subsiteId: subsiteId,
                page: 1,
                perPage: 100,
                status: 'ENABLE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/store_card_levels',
            }
        ).then((res: any) => {
            const data = res.result;
            const memberLevels = data && data.length > 0 ? data : cloneDeep(defaultMemberLevels);
            const currentLevel = props.value ? props.value : { levelId: 0, type: 'ALL' };
            let checkedLevel = find(memberLevels, { id: currentLevel.levelId });
            if (!checkedLevel) {
                currentLevel.levelId = 0;
                props.onChange(currentLevel);
            }
            setMemberLevels(memberLevels);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subsiteId, props.value?.type]);

    const changeValue = useCallback(
        (value: any) => {
            const currentValue = props.value ? props.value : {};
            const newValue = Object.assign({ type: 'ALL' }, { ...currentValue }, { ...value });
            props.onChange(newValue);
        },
        [props]
    );

    const onChangeRuleType = useCallback(
        (e: any) => {
            const { value } = props;
            const type = e.target.value;
            let levelId = value ? value.levelId : 0;
            let levelName = value ? value.levelName : '全部等级';
            if (type === 'ALL') {
                levelId = 0;
                levelName = '全部等级';
            }
            changeValue({ type, levelId, levelName });
        },
        [changeValue, props]
    );

    const onChangeLevel = useCallback(
        (levelId: any) => {
            changeValue({ levelId });
        },
        [changeValue]
    );
    const onChangePointDouble = useCallback(
        (e: any) => {
            const pointDouble = e.target.value;
            //const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
            if (pointDouble && pointDouble < 1) {
                AntMessage.warning('请填写>1的数字');
                return;
            }
            // if (!reg.test(pointDouble)) {
            //     AntMessage.warning('积分倍数最多支持两位小数');
            //     return;
            // }
            changeValue({ pointDouble });
        },
        [changeValue]
    );
    const onChangePoint = useCallback(
        (point: any) => {
            const reg = /^\+?[0-9]\d*$/;

            if (!reg.test(point)) {
                AntMessage.warning('仅支持正整数');
                return;
            }

            changeValue({ point });
        },
        [changeValue]
    );

    const { value, rewardType } = props;
    if (!value) changeValue({ point: 1, type: 'ALL' });
    const ruleType = value ? value.type : 'ALL';
    const levelId = value ? parseInt(value.levelId) : 0;
    return (
        <div className="member-level-rule">
            <Radio.Group onChange={onChangeRuleType} value={ruleType}>
                <Radio value={'ALL'} className="rule-type-item">
                    所有等级，同一规则
                </Radio>
                <Radio value={'LEVEL'} className="rule-type-item">
                    不同等级，不同规则
                </Radio>
            </Radio.Group>
            <div className="rule-setting-wrap">
                {ruleType === 'ALL' && <div className="member-levels">全部等级</div>}
                {ruleType === 'LEVEL' && (
                    <div className="member-levels">
                        <div>{services.language.getText('xzdj')}</div>
                        <Select value={levelId} style={{ width: '140px' }} onChange={onChangeLevel}>
                            <Select.Option value={0}>请选择</Select.Option>
                            {memberLevels &&
                                memberLevels.map((option: any) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                )}
                <div className="setting-items">
                    <div>赠送内容</div>
                    {rewardType === 'POINT' && (
                        <div className="setting-item">
                            <span className="item-text">送</span>
                            <InputNumber
                                className="item-input"
                                min={1}
                                step={1}
                                value={value && parseInt(value.point) ? parseInt(value.point) : 1}
                                onChange={onChangePoint}
                            />
                            <span className="item-text">积分</span>
                        </div>
                    )}
                    {rewardType === 'POINT_DOUBLE' && (
                        <div className="setting-item">
                            <span className="item-text">送</span>
                            <Input
                                className="item-input"
                                type="number"
                                value={value ? value.pointDouble : ''}
                                onChange={onChangePointDouble}
                                addonAfter="倍"
                            />
                            <span className="item-text">积分</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
