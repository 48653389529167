import React, { Component } from 'react';
import { ColorPickerPlus } from '../../../../components';

import './index.less';
import { services } from '@comall-backend-builder/core';

export class MemberCardDiscountTag extends Component<any, any> {
    componentDidMount() {
        const { params, onChange } = this.props;
        if (!params || !params.id) {
            const value = {
                cardNameBackgroundColor: '#1c2566',
                cardNameWordColor: '#f9d96a',
                memberDiscountBackgroundColor: '#f9d96a',
                memberDiscountWordColor: '#1c2566',
            };
            onChange(value);
        }
    }
    onChange = (
        color: string,
        type:
            | 'cardNameBackgroundColor'
            | 'cardNameWordColor'
            | 'memberDiscountBackgroundColor'
            | 'memberDiscountWordColor'
    ) => {
        const { value, onChange } = this.props;
        const newValue = Object.assign({}, value);
        newValue[type] = color;
        onChange(newValue);
    };
    render() {
        const {
            value = {
                cardNameBackgroundColor: null,
                cardNameWordColor: null,
                memberDiscountBackgroundColor: null,
                memberDiscountWordColor: null,
            },
            params,
        } = this.props;
        return (
            <div className="member-card-discount-tag">
                <div className="row">
                    <div className="title">卡名称背景色：</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'cardNameBackgroundColor');
                                },
                                value: value.cardNameBackgroundColor,
                                params,
                            } as any)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">卡名称文字色：</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'cardNameWordColor');
                                },
                                value: value.cardNameWordColor,
                                params,
                            } as any)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">折扣价格背景色：</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'memberDiscountBackgroundColor');
                                },
                                value: value.memberDiscountBackgroundColor,
                                params,
                            } as any)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">折扣价格文字色：</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'memberDiscountWordColor');
                                },
                                value: value.memberDiscountWordColor,
                                params,
                            } as any)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">{services.language.getText('ysyl')}：</div>
                    <div
                        className="preview"
                        style={{ backgroundColor: value.memberDiscountBackgroundColor }}
                    >
                        <div
                            className="card-name"
                            style={{
                                backgroundColor: value.cardNameBackgroundColor,
                                color: value.cardNameWordColor,
                            }}
                        >
                            黑卡9.5折
                        </div>
                        <div className="discount" style={{ color: value.memberDiscountWordColor }}>
                            {services.language.getText('monetaryUnit')}95.00
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
