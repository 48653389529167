import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { set } from 'lodash';
import {
    MallActivityTicketPriceFreeRule,
    NewMallActivityRow,
    NewMallActivityMemberCardLevel,
} from '../types';
import { TableProps, TableSize } from 'antd/lib/table';

import { NumberInputTip } from '../..';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityTicketPriceFreeRulesTableProps {
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange?: (value: MallActivityTicketPriceFreeRule[]) => void;
    /**
     * 当前值
     */
    value: MallActivityTicketPriceFreeRule[];
    disabled?: boolean;
    row?: NewMallActivityRow;
    cardLevels: NewMallActivityMemberCardLevel[];
    size?: TableSize;
}

/**
 * 新商场活动票档免费名额表格
 */
class NewMallActivityTicketPriceFreeRulesTable extends PureComponent<
    NewMallActivityTicketPriceFreeRulesTableProps
> {
    changeValue = (value: MallActivityTicketPriceFreeRule[]) => {
        const { onChange, disabled } = this.props;
        if (!disabled && onChange) {
            onChange(value);
        }
    };

    changeTicketPriceFreeRule = (
        field: keyof MallActivityTicketPriceFreeRule,
        fieldValue: MallActivityTicketPriceFreeRule[keyof MallActivityTicketPriceFreeRule],
        index: number
    ) => {
        const { value = [] } = this.props;
        const newValue = [...value];
        const rule = newValue[index];
        if (!rule) {
            return;
        }
        set(rule, field, fieldValue);
        this.changeValue(newValue);
    };

    getCardLevelName = (id: number) => {
        const levelId = Number(id);
        if (levelId === -1) {
            return language.getText('all');
        }
        const { cardLevels } = this.props;
        return cardLevels?.find((item) => Number(item.id) === levelId)?.name || '';
    };

    renderFreeQuota = (value: number, record: MallActivityTicketPriceFreeRule, index: number) => {
        const { disabled } = this.props;
        return (
            <div style={{ width: 90, margin: 'auto' }}>
                <NumberInputTip
                    value={value}
                    min={0}
                    max={999999}
                    precision={0}
                    disabled={disabled}
                    onChange={(num: number) =>
                        this.changeTicketPriceFreeRule('freeQuota', num, index)
                    }
                    addonbefor={language.getText('mrmymfbm')}
                    addonafter={language.getText('ge')}
                ></NumberInputTip>
            </div>
        );
    };

    getTableConfig = () => {
        const { size = 'default' } = this.props;
        let config: TableProps<MallActivityTicketPriceFreeRule> = {
            rowKey: 'memberCardLevelId',
            size,
            columns: [
                {
                    title: language.getText('cardLevel'),
                    dataIndex: 'memberCardLevelId',
                    width: 150,
                    render: this.getCardLevelName,
                },
                {
                    title: language.getText('mrmymfme'),
                    dataIndex: 'freeQuota',
                    width: 120,
                    render: this.renderFreeQuota,
                },
            ],
        };
        config.columns?.forEach((item) => {
            item.align = 'center';
            if (!item.key) {
                item.key = item.dataIndex;
            }
        });
        return config;
    };

    render() {
        const { value } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: value };
        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { NewMallActivityTicketPriceFreeRulesTable };
