import { enUS } from './en-US';
import { zhCN } from './zh-CN';
import { zhHK } from './zh-HK';
import { translater } from './utils';

const testLanguageHandler = (text: string) => {
    return `^${text}^`;
};

const testLanguage = translater(zhCN, testLanguageHandler);

export { enUS, zhCN, zhHK, testLanguage };
