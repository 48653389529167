// eslint-disable-next-line semi
import JsBarcode from 'jsbarcode';
import { ORDER_STATUS_ENUM } from './constant';
import { api } from '@comall-backend-builder/core/lib/services';

/**
 * 拣货单详情
 */
export interface PickingLists {
    /**
     * 拣货分类
     */
    categories: PickingListCategory[];
    /**
     * 拣货单倒计时信息
     */
    countdown: {
        countdownType: 'TIMEOUT' | 'NO_TIMEOUT';
        pickTime: string;
        totalPickTime: string;
    };
    /**
     * 拣货单分配类型[SELF:自行领取,ADMINISTRATOR:管理员分配]
     */
    distributeType: 'SELF' | 'ADMINISTRATOR';
    /**
     * 预计完成时间
     */
    estimatedFinishPickTime: string;
    /**
     * 实际拣货完成时间
     */
    finishPickTime: string;
    /**
     * 拣货单Id
     */
    id: string;
    /**
     * 拣货订单集合
     */
    orders: PickingListsOrders[];
    /**
     * 拣货单号
     */
    pickingListNo: string;
    /**
     * 打印类型
     */
    printType: PickingListPrintType;
    /**
     * 开始拣货时间
     */
    startPickTime: string;
    /**
     * 拣货单状态
     */
    status: ORDER_STATUS_ENUM;
    /**
     * 门店信息
     */
    subsite: {
        id: string;
        name: string;
        /**
         * 专柜信息
         */
        merchant: {
            id: string;
            name: string;
        };
    };
    /**
     * 最后操作人的信息
     */
    user: {
        id: string;
        name: string;
    };
    /**
     * 拣货单创建时间
     */
    createTime: string;
    /**
     * 条形码
     */
    pickingListNoCode?: string;
}

/**
 * 拣货单打印类型
 */
export enum PickingListPrintType {
    /**
     * A4打印
     */
    A4 = 'A4',
    /**
     * 小票
     */
    TICKET = 'TICKET',
    /**
     * 带开票二维码的小票
     */
    TICKET_INVOICE = 'TICKET_INVOICE',
}

/**
 * 拣货单分类信息
 */
export interface PickingListCategory {
    /**
     * 拣货单分类项信息
     */
    items: PickingListCategoryItem[];
    /**
     * 分类名称
     */
    name: string;
    /**
     * 分类code
     */
    code: string;
}

/**
 * 拣货单分类项
 */
export interface PickingListCategoryItem {
    /**
     * 实拣量(件/克)
     */
    actualPickQuantity: number;
    /**
     * 拣货单货品信息
     */
    goods: PickingListGoods;
    /**
     * 缺货量(件/克)
     */
    lackQuantity: number;
    /**
     * 亏损量(件/克)
     */
    lossQuantity: number;
    /**
     * 需拣量(件/克)
     */
    needPickQuantity: number;
    /**
     * 订单Id
     */
    orderId: string;
    /**
     * 订单项Id
     */
    orderItemId: string;
    /**
     * 订单编号
     */
    orderNo: string;
    /**
     * 包名
     */
    packageName: string;
    /**
     * 订单流水号
     */
    tradeNumber: string;
}

/**
 * 拣货单商品项
 */
export interface PickingListGoods {
    /**
     * 商品编码
     */
    productCode: string;
    /**
     * 货品条码
     */
    barCode: string;
    /**
     * 拣货单货品拣货路径
     */
    pickRoutes: {
        /**
         * 通道编号
         */
        passageway: string;
    };
    /**
     * 货品名称
     */
    name: string;
    /**
     * 货品款式名称
     */
    styleName: string;
    /**
     * 货品展示单位[G：克,KG：千克,PIECE：件]
     */
    displayUnit: 'G' | 'KG' | 'PIECE';
    /**
     * 需拣量（个/克）
     */
    needPickQuantity: number;
    /**
     * 货品ID
     */
    goodsId: number;
    /**
     * 商品类型
     */
    productType: ProductType;
    /**
     * 商品图片
     */
    imageUrl: string;
    /**
     * 商品价格
     */
    price: number;
}

/**
 * 商品分类
 */
enum ProductType {
    /**
     * 普通商品
     */
    NORMAL = 'NORMAL',
    /**
     * 虚拟商品
     */
    VIRTUAL_COUPON = 'VIRTUAL_COUPON',
    /**
     * 称重品
     */
    WEIGHT = 'WEIGHT',
}

/**
 * 拣货单订单信息
 */
export interface PickingListsOrders {
    /**
     * 订单渠道名称
     */
    channelName: string;
    /**
     * 拣货单关联订单关联关系Id
     */
    id: string;
    /**
     * 订单Id
     */
    orderId: string;
    /**
     * 订单项集合
     */
    orderItems: PickingListsOrderItems[];
    /**
     * 订单编号
     */
    orderNo: string;
    /**
     * 订单备注
     */
    orderRemark: string;
    /**
     * 包名
     */
    packageName: string;
    /**
     * 流水號前綴
     */
    sequencePrefix: string;
    /**
     * 订单流水号
     */
    tradeNumber: string;
}

/**
 * 拣货单订单项
 */
export interface PickingListsOrderItems {
    /**
     * 实拣量(件/克)
     */
    actualPickQuantity: number | undefined;
    /**
     * 拣货项条形码
     */
    barCode: string;
    /**
     * 拣货单货品信息
     */
    goods: PickingListGoods;
    /**
     * 拣货单订单项Id
     */
    id: string;
    /**
     * 缺货量(件/克)
     */
    lackQuantity: number | undefined;
    /**
     * 亏损量(件/克)
     */
    lossQuantity: number;
    /**
     * 需拣量(件/克)
     */
    needPickQuantity: number;
    /**
     * 订单项Id
     */
    orderItemId: string;
    /**
     * 订单项缺货差价
     */
    priceDifference: number;
    /**
     * 折后价格
     */
    afterFoldingPrice: string;
    /**
     * 标品是否拣货状态
     */
    isPicking?: boolean;
    /**
     * 订单类型
     */
    orderItemType: OrderItemType;
}

export enum OrderItemType {
    Normal = 1,
    Gift = 2,
}

/**
 * 完成拣货入参
 */
export interface FinishPickOrders {
    /**
     * 拣货单关联订单关联关系Id
     */
    id: string;
    /**
     * 订单Id
     */
    orderId: string;
    /**
     * 订单项集合
     */
    orderItems: FinishPickOrderItems[];
}

/**
 * 完成拣货订单项
 */
export interface FinishPickOrderItems {
    /**
     * 实拣量(件/克)
     */
    actualPickQuantity: number;
    /**
     * 拣货项条形码
     */
    barCode: string;
    /**
     * 货品Id
     */
    goodsId: number;
    /**
     * 拣货单订单项Id
     */
    id: string;
    /**
     * 订单项Id
     */
    orderItemId: string;
}

export const enum OrderType {
    /**
     * 购物小票
     */
    ShoppingReceipt = 'SHOPPING_RECEIPT',
    /**
     * 停车缴费
     */
    Parking = 'PARKING',
    /**
     * 卡券订单
     */
    SaleCoupon = 'SALE_COUPON',
    /**
     * 积分兑换
     */
    CreditEshop = 'CREDIT_ESHOP',
    /**
     * 商城活动
     */
    MallActivityNew = 'MALL_ACTIVITY_NEW',
    /**
     * 服务预约
     */
    ServiceReservedActivity = 'SERVICE_RESERVED_ACTIVITY',
    /**
     * 实物订单
     */
    Order = 'ORDER',
}

/**
 * 小票打印数据
 */
export interface ReceiptInfo {
    /**
     * 下单时间
     */
    createTime: string;
    /**
     * 物流信息
     */
    deliveryInfo: DeliveryInfo;
    /**
     * 货品项集合
     */
    goodsList: GoodsInfo[];
    /**
     * 订单id
     */
    id: string;
    /**
     * 订单编号
     */
    orderNo: string;
    /**
     * 订单类型
     */
    orderType: OrderType;
    /**
     * 订单支付相关信息
     */
    paymentInfo: PaymentInfo;
    /**
     * 打印信息
     */
    printInfo: {
        /**
         * 打印类型 A4-A4打印,TICKET-小票,TICKET_INVOICE-带开票二维码的小票
         */
        printType: PickingListPrintType;
        /**
         * 打印备注
         */
        remark: string;
    };
    /**
     * 订单备注
     */
    remark: string;
    /**
     * 门店信息
     */
    subsite: {
        id: string;
        name: string;
        address: string;
        mobile: string;
        merchant: {
            id: string;
            name: string;
        };
    };
    /**
     * 差价金额
     */
    totalPriceDifference: string;
    /**
     * 订单商品总数量
     */
    totalGoodsQuantity: string;
    /**
     * 订单重量
     */
    weight: string;
    /**
     * 订单条形码
     */
    orderNoCode: string;
    /**
     * 下单用户会员卡名称
     */
    memberCardLevelName: string;
    /**
     * 下单用户手机号
     */
    mobile: string;
}

/**
 * 订单物流信息
 */
export interface DeliveryInfo {
    /**
     * 配送地址
     */
    address: string;
    /**
     * 配送方式别名（用来区分配送方式是商家配送还是门店自提）
     */
    deliveryAliasName: string;
    /**
     * 配送方式, EXPRESS_DELIVERY 快递 | SELF_MENTION 自提 | CITYWIDE_DELIVERY 同城配
     */
    deliveryMode: 'EXPRESS_DELIVERY' | 'SELF_MENTION' | 'CITYWIDE_DELIVERY';
    /**
     * 配送方式名称
     */
    deliveryModeName: string;
    /**
     * 快递单号
     */
    expressNo: string;
    /**
     * 收货人手机号
     */
    mobile: string;
    /**
     * 收货人
     */
    receiverName: string;
    /**
     * 地区名称
     */
    regionName: string;
    /**
     * 自提码
     */
    selfExtractingCode: string;
    /**
     * 预计送达时间
     */
    expectedDeliveryTime: string;
}

/**
 * 订单物流信息
 */
export interface GoodsInfo {
    /**
     * 实拣量（拣货完毕后实重）,单位g
     */
    actualPickQuantity: number;
    /**
     * 货品条码，普通商品展示
     */
    barcode: string;
    /**
     * 执行积分价
     */
    executePointPrice: string;
    /**
     * 执行价格
     */
    executePrice: string;
    /**
     * 需拣量（下单实重）,单位g
     */
    needPickQuantity: number;
    /**
     * 货品条码，称重商品展示
     */
    pickingListOrderItemBarcode: string;
    /**
     * 缺货差价
     */
    priceDifference: string;
    /**
     * 商品编码
     */
    productCode: string;
    /**
     * 商品名称
     */
    productName: string;
    /**
     * 商品类型
     */
    productType: ProductType;
    /**
     * 商品数量
     */
    quantity: number;
    /**
     * 差重,单位g
     */
    quantityDifference: number;
    /**
     * 规格名称
     */
    styleName: string;
    /**
     * 称重品的真实折后价（折后价 - 缺货差价）
     */
    weightProductActualAfterFoldingPrice: string;
    /**
     * 货品展示单位[G：克,KG：千克,PIECE：件]
     */
    displayUnit: 'G' | 'KG' | 'PIECE';
    /**
     * 总计
     */
    totalAmountStr: string;
    /**
     * 订单类型
     */
    orderItemType: OrderItemType;
}

/**
 * 订单物流信息
 */
export interface PaymentInfo {
    /**
     * 优惠券折扣
     */
    couponAmount: string;
    /**
     * 实付运费
     */
    freightAmount: string;
    /**
     * 运费优惠
     */
    freightFeeDiscount: string;
    /**
     * 运费总额
     */
    totalFreightAmount: string;
    /**
     * 商品总额
     */
    goodsAmount: string;
    /**
     * 商品优惠金额
     */
    goodsDiscountAmount: string;
    /**
     * 已付金额
     */
    paidAmount: string;
    /**
     * 已付积分
     */
    paidPoint: number;
    /**
     * 应付金额
     */
    payableAmount: string;
    /**
     * 应付积分
     */
    payablePoint: number;
    /**
     * 支付记录
     */
    paymentRecords: PaymentRecords[];
    /**
     * 支付优惠
     */
    totalPaymentRecordDiscountAmount: string;
    /**
     * 会员卡折扣优惠金额
     */
    memberCardDiscountAmount: string;
    /**
     * 促销优惠金额
     */
    promotionDiscountAmount: string;
}

/**
 * 支付记录
 */
export interface PaymentRecords {
    /**
     * 金额
     */
    amount: string;
    /**
     * 支付方式名称
     */
    paymentModeName: string;
}

/**
 * 获取拣货单打印面单数据
 * @param ids
 * @param params
 * @returns
 */
function GetPrintOrders(ids: string[]) {
    const PromiseList = ids.map((id) => {
        return GetOrderInfo(id);
    });
    return Promise.all(PromiseList);
}

function GetOrderInfo(id: string) {
    return api
        .get<PickingLists>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
                apiPath: `/admin/picking_lists/${id}`,
            }
        )
        .then((res) => {
            res.pickingListNoCode = createBarcode(res.pickingListNo);
            if (
                res.status === ORDER_STATUS_ENUM.WAITING_PICK ||
                res.status === ORDER_STATUS_ENUM.PICKING
            ) {
                res.orders = res.orders.map((order) => {
                    order.orderItems = order.orderItems.map((item) => {
                        item.actualPickQuantity = undefined;
                        item.lackQuantity = undefined;
                        return item;
                    });
                    return order;
                });
            }
            return res;
        });
}

function GetPickingPriceDifference(params: {
    id: string;
    orderId: string;
    orderItemId: string;
    actualPickQuantity: number;
}) {
    return api.get<number>(params, {
        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
        apiPath: `/admin/picking_lists/${params.id}/price_difference`,
    });
}

function FinishPicking(id: string, orders: FinishPickOrders[]) {
    return api.put(
        { orders },
        {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
            apiPath: `/admin/picking_lists/${id}/pick_finished`,
        }
    );
}

function GetReceiptInfo(ids: string[]) {
    return api
        .get<ReceiptInfo[]>(
            { ids },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/orders/receipt`,
            }
        )
        .then((res) => {
            res = res.map((item) => {
                return {
                    ...item,
                    orderNoCode:
                        item.printInfo.printType === PickingListPrintType.A4
                            ? createBarcode(item.orderNo, true)
                            : createBarcode(item.orderNo),
                };
            });
            return res;
        });
}

/**
 *
 * @param {*} code 二维码字符串
 * return 二维码图片 url
 */
function createBarcode(code: string, needText = false) {
    const codeImg = window.document.createElement('img');
    window.document.body.appendChild(codeImg);
    JsBarcode(codeImg, code, { text: needText ? code : ' ' });
    window.document.body.removeChild(codeImg);
    return codeImg.src;
}

const toTwo = (_number: string) => {
    let number = _number || '0';
    let price = Math.round(parseFloat(number) * 100).toString();
    if (price.length === 1) {
        price = '0.0' + price;
    } else if (price.length === 2) {
        price = '0.' + price;
    } else {
        price = price.slice(0, -2) + '.' + price.slice(-2);
    }
    return price;
};

export {
    toTwo,
    GetPrintOrders,
    GetOrderInfo,
    ORDER_STATUS_ENUM,
    ProductType,
    FinishPicking,
    GetReceiptInfo,
    GetPickingPriceDifference,
};
