import React, { PureComponent } from 'react';
interface StringLinkProps {
    linkName: string;
    linkUrl: string;
}

/**
 * 区号-电话
 */
export class StringLink extends PureComponent<StringLinkProps, {}> {
    link = () => {
        const { linkUrl } = this.props;
        window.open(linkUrl);
    };
    render() {
        const { linkName } = this.props;
        if (!linkName) {
            return null;
        }
        return (
            <div>
                <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={this.link}>
                    {linkName}
                </span>
            </div>
        );
    }
}
