import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        WechatBusinessMallsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/BUSINESS-MALL`,
            apiPath: '/loader/admin/wechat-business-malls',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
                version: {
                    type: 'string.options.select',
                    displayName: '<<buinessVersion>>',
                    options: [
                        { id: 'V3', name: '<<versionThird>>' },
                        { id: 'V2', name: '2.0' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 120 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                version: {
                    type: 'string.options.radio',
                    displayName: '<<buinessVersion>>',
                    options: [
                        { id: 'V3', name: '<<versionThird>>' },
                        { id: 'V2', name: '2.0' },
                    ],
                    rules: [{ required: true, message: '<<qxzsqbb>>' }],
                },
                mallId: {
                    type: 'string',
                    displayName: '<<mallId>>',
                    rules: [
                        {
                            required: true,
                            message: '<<mallIdRequired>>',
                            validator: (rule: any, value: any) => {
                                if (!value || value.length > 10 || value.length < 8) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    extra: '<<wxMallIdExtra>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            className: 'custom-array-options',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        version: {
                            type: 'string.options.radio',
                            displayName: '<<buinessVersion>>',
                            defaultValue: 'V3',
                            options: [
                                { id: 'V3', name: '<<versionThird>>' },
                                { id: 'V2', name: '2.0' },
                            ],
                            rules: [{ required: true, message: '<<qxzsqbb>>' }],
                        },
                        mallId: {
                            type: 'string',
                            displayName: '<<mallId>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<mallIdRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value || value.length > 10 || value.length < 8) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            extra: '<<wxMallIdExtra>>',
                        },
                        mallBrandId: {
                            type: 'string',
                            displayName: '<<brandId>>',
                        },
                    },
                },
                merchantInfo: {
                    type: 'object.subForm',
                    displayName: '<<sqpz>>',
                    properties: {
                        merchants: {
                            type: 'array.merchant.selector',
                            displayName: '<<applyMerchants>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                tips: '<<qqrgzjycgtjzszmdsqhgx>>',
                            },
                        },
                    },
                },
                memberPointInfo: {
                    type: 'object.subForm',
                    displayName: '<<hyjfpz>>',
                    properties: {
                        pictureExampleButton2: {
                            displayName: '<<hyjf>>',
                            type: 'string.pictureExampleButton',
                            className: 'BusinessMallPictureExampleButton',
                        },
                        transactionNotificationUrl: {
                            type: 'string.copy',
                            displayName: '<<yhxfhddz>>',
                        },
                        authorizeNotificationUrl: {
                            type: 'string.copy',
                            displayName: '<<hyjffwkthddz>>',
                        },
                        pointDetailPage: {
                            type: 'string.copy',
                            displayName: '<<jfjllj>>',
                        },
                        creditShopPage: {
                            type: 'string.copy',
                            displayName: '<<jfdhlj>>',
                        },
                        pictureExampleButton: {
                            displayName: '<<jhyjftcfwpz>>',
                            type: 'string.pictureExampleButton',
                            className: 'BusinessMallPictureExampleButton',
                        },
                        myCarsPage: {
                            type: 'string.copy',
                            displayName: '<<clgllj>>',
                        },
                        parkingFeePage: {
                            type: 'string.copy',
                            displayName: '<<tcjflj>>',
                        },
                    },
                },
            },
        },
        AlipayBusinessMallsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/BUSINESS-MALL`,
            apiPath: '/loader/admin/alipay-business-malls',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            className: 'custom-array-options',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        mallId: {
                            type: 'string',
                            displayName: '<<mallId>>',
                            rules: [{ required: true, message: '<<qsrsqshh>>' }],
                            extra: '<<sqsqszfbfpdsq>>',
                        },
                    },
                },
                merchantInfo: {
                    type: 'object.subForm',
                    displayName: '<<sqpz>>',
                    properties: {
                        merchants: {
                            type: 'array.merchant.selector',
                            displayName: '<<applyMerchants>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                tips: '<<qqrgzjycgtjzszmdsqhgx>>',
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        BusinessMallsView: {
            component: 'Viewport',
        },
        BusinessMallsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 32px',
            },
            items: [{ component: 'BusinessMallsTables' }],
        },
        BusinessMallsTables: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<wx>>',
                    content: {
                        component: 'WechatBusinessMallsPage',
                    },
                },
                {
                    title: '<<zfb>>',
                    content: {
                        component: 'AlipayBusinessMallsPage',
                    },
                },
            ],
        },
        WechatBusinessMallsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'WechatBusinessMallsEntity',
            items: [
                {
                    component: 'WechatBusinessMallsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzsq>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/business-malls/add-wechat',
                                },
                            ],
                        },
                    ],
                },
                { component: 'WechatBusinessMallsTable' },
            ],
        },
        WechatBusinessMallsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [{ property: 'subsiteIds' }, { property: 'version' }],
        },
        WechatBusinessMallsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: { x: true },
            columns: [{ property: 'subsiteName' }, { property: 'version' }],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/business-malls/edit-wechat/{{row.id}}',
                        config: {
                            style: {
                                marginLeft: '10px',
                            },
                        },
                    },
                    {
                        type: 'link',
                        text: '<<pzzd>>',
                        path: '/business-malls/guide-wechat/{{row.id}}',
                    },
                ],
            },
        },
        WechatBusinessMallsAddPage: {
            component: 'Card',
            content: { component: 'WechatBusinessMallsAddForm' },
        },
        WechatBusinessMallsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'WechatBusinessMallsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: { style: { width: '200px' } },
                },

                {
                    property: 'baseInfo.mallId',
                    controlConfig: {
                        style: { width: '200px' },
                        placeholder: '<<mallIdRequired>>',
                    },
                },
                { property: 'baseInfo.version', controlConfig: { style: { width: '200px' } } },
                {
                    property: 'baseInfo.mallBrandId',
                    controlConfig: {
                        style: { width: '200px' },
                        maxLength: 15,
                        placeholder: '<<lqsrbcg15>>',
                    },
                },
                {
                    property: 'merchantInfo.merchants',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (entity.baseInfo.version === 'V3') {
                    if (!entity.baseInfo.mallBrandId) {
                        AntMessage.warning(services.language.getText('qtxpp'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WechatBusinessMallsEditPage: {
            component: 'Card',
            content: { component: 'WechatBusinessMallsEditForm' },
        },
        WechatBusinessMallsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'WechatBusinessMallsEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: { style: { width: '200px' }, disabled: true },
                },

                {
                    property: 'baseInfo.mallId',
                    controlConfig: {
                        style: { width: '200px' },
                        placeholder: '<<mallIdRequired>>',
                    },
                },
                { property: 'baseInfo.version', controlConfig: { style: { width: '200px' } } },
                {
                    property: 'baseInfo.mallBrandId',
                    controlConfig: {
                        style: { width: '200px' },
                        maxLength: 15,
                        placeholder: '<<lqsrbcg15>>',
                    },
                },
                {
                    property: 'merchantInfo.merchants',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (entity.baseInfo.version === 'V3') {
                    if (!entity.baseInfo.mallBrandId) {
                        AntMessage.warning(services.language.getText('qtxpp'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WechatBusinessMallsGuidePage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'WechatBusinessMallsEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'Detail',
                    labelCol: 6,
                    controlCol: 18,
                    style: { width: 1080 },
                    fields: [
                        {
                            property: 'subsiteName',
                        },
                        { property: 'mallId' },
                        { property: 'version' },
                        /*  {
                            property: 'memberPointInfo.pictureExampleButton1',
                            displayConfig: {
                                buttonName: '示例',
                                type: 'memberCard',
                                showDownload: false,
                            },
                        },
                        { property: 'memberPointInfo.transactionNotificationUrl' }, */
                        {
                            property: 'memberPointInfo.pictureExampleButton2',
                            displayConfig: {
                                buttonName: '<<example>>',
                                type: 'memberPoint',
                                showDownload: false,
                            },
                        },
                        { property: 'memberPointInfo.transactionNotificationUrl' },
                        { property: 'memberPointInfo.authorizeNotificationUrl' },
                        { property: 'memberPointInfo.pointDetailPage' },
                        { property: 'memberPointInfo.creditShopPage' },
                        {
                            property: 'memberPointInfo.pictureExampleButton',
                            displayConfig: {
                                buttonName: '<<example>>',
                                type: 'parkingFee',
                                showDownload: false,
                            },
                        },
                        { property: 'memberPointInfo.myCarsPage' },
                        { property: 'memberPointInfo.parkingFeePage' },
                    ],
                    footer: {
                        items: [
                            {
                                htmlType: 'button',
                                route: 'goBack',
                                type: 'default',
                                text: '<<common.close>>',
                            },
                        ],
                    },
                },
            ],
        },
        AlipayBusinessMallsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'AlipayBusinessMallsEntity',
            items: [
                {
                    component: 'AlipayBusinessMallsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzsq>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/business-malls/add-alipay',
                                },
                            ],
                        },
                    ],
                },
                { component: 'AlipayBusinessMallsTable' },
            ],
        },
        AlipayBusinessMallsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [{ property: 'subsiteIds' }],
        },
        AlipayBusinessMallsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: { x: true },
            columns: [{ property: 'subsiteName' }],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/business-malls/edit-alipay/{{row.id}}',
                        config: {
                            style: {
                                marginLeft: '10px',
                            },
                        },
                    },
                ],
            },
        },
        AlipayBusinessMallsAddPage: {
            component: 'Card',
            content: { component: 'AlipayBusinessMallsAddForm' },
        },
        AlipayBusinessMallsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'AlipayBusinessMallsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: { style: { width: '200px' } },
                },

                {
                    property: 'baseInfo.mallId',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                },
                {
                    property: 'merchantInfo.merchants',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        AlipayBusinessMallsEditPage: {
            component: 'Card',
            content: { component: 'AlipayBusinessMallsEditForm' },
        },
        AlipayBusinessMallsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'AlipayBusinessMallsEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: { style: { width: '200px' }, disabled: true },
                },

                {
                    property: 'baseInfo.mallId',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                },
                {
                    property: 'merchantInfo.merchants',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/business-malls',
                    component: 'BusinessMallsView',
                    breadcrumbName: '<<sqgl>>',
                    privilege: {
                        path: 'businessMall',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-wechat',
                            component: 'WechatBusinessMallsAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit-wechat/:id',
                            component: 'WechatBusinessMallsEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/guide-wechat/:id',
                            component: 'WechatBusinessMallsGuidePage',
                            breadcrumbName: '<<pzzd>>',
                        },
                        {
                            path: '/add-alipay',
                            component: 'AlipayBusinessMallsAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit-alipay/:id',
                            component: 'AlipayBusinessMallsEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'BusinessMallsPage' },
                    ],
                },
            ],
        },
    ],
};
