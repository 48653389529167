import React, { Component } from 'react';
import { Switch, Select, Input } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';
import { cloneDeep } from 'lodash';
import { api } from '@comall-backend-builder/core/lib/services';
const { getText } = services.language;

export class ArrayUserPositioningMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CheckboxPopovers {...displayInfo} />;
    }
}

class CheckboxPopovers extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { subsite: [] };
    }
    componentDidMount() {
        this.loadSubsiteInfo();
    }
    //获取选择的门店
    private loadSubsiteInfo = async () => {
        const subsiteListResult: any = await api.get({}, { apiPath: '/admin/v1/subsites/mine' });
        if (subsiteListResult && subsiteListResult.result && subsiteListResult.result.length > 0) {
            this.setState({
                subsite: subsiteListResult.result,
            });
        }
    };
    onChange = (v: any, names: string) => {
        const { name, onChange, value } = this.props;

        const newValue = cloneDeep(value || {});
        newValue[names] = v;

        if (onChange) {
            onChange(newValue, name);
        }
    };
    render() {
        const { value } = this.props;
        const { subsite = [] } = this.state;
        return (
            <div>
                <Switch
                    checkedChildren={getText('kai')}
                    unCheckedChildren={getText('guan')}
                    onChange={(e: any) => this.onChange(e, 'status')}
                    checked={value?.status}
                />
                <div style={{ display: 'flex', width: 400 }} hidden={!value?.status}>
                    <label style={{ width: 150, textAlign: 'right' }}>
                        {services.language.getText('dwjzjmd')}：
                    </label>
                    <Input
                        style={{ width: 200 }}
                        addonBefore={services.language.getText('cg_1')}
                        addonAfter={services.language.getText('gl_1')}
                        placeholder={services.language.getText('inputPlease')}
                        value={value?.locationToStoreRadius}
                        onChange={(e: any) => {
                            const { value } = e.target;
                            const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                            if (
                                (!isNaN(value) && reg.test(value)) ||
                                value === '' ||
                                value === '-'
                            ) {
                                this.onChange(e.target.value, 'locationToStoreRadius');
                            }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', width: 400 }} hidden={!value?.status}>
                    <label style={{ width: 150, textAlign: 'right' }}>
                        {services.language.getText('mrmd')}：
                    </label>
                    <Select
                        style={{ width: 200 }}
                        value={String(value?.defaultStore || '')}
                        onChange={(e: any) => this.onChange(e, 'defaultStore')}
                    >
                        <Select.Option key={''} value={''}>
                            {services.language.getText('selectPlease')}
                        </Select.Option>
                        {subsite &&
                            subsite.map((o: any) => (
                                <Select.Option key={o.id} value={o.id}>
                                    {o.name}
                                </Select.Option>
                            ))}
                    </Select>
                </div>
            </div>
        );
    }
}
