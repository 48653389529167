import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { get } from 'lodash';
import { extraElement } from '../../../services/extra-element';

export const config: Config = {
    entities: {
        EventMarketEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/eventMarket',
            filters: {
                eventName: {
                    type: 'string',
                    displayName: '<<sjmc>>',
                    controlConfig: { placeholder: '<<qsrsjmc>>' },
                },
                eventType: {
                    type: 'string.options.select',
                    displayName: '<<sjlx>>',
                    controlConfig: {
                        placeholder: '<<qsrsjlx>>',
                        style: {
                            width: 150,
                        },
                    },
                    options: [],
                    source: {
                        apiPath: '/loader/admin/eventMarketType',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<sjmc>>',
                },
                eventTypeName: {
                    type: 'string',
                    displayName: '<<sjlx>>',
                },
                /* sequence: {
                    type: 'number',
                    displayName: '排序值',
                }, */
                subsites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        typeId: {
                            type: 'string.options.select',
                            displayName: '<<sjyxlx>>',
                            rules: [{ required: true }],
                            options: [],
                            source: {
                                apiPath: '/loader/admin/eventMarketType',
                            },
                            defaultValue: '1',
                            controlConfig: {
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        name: {
                            displayName: '<<sjmc>>',
                            rules: [
                                { required: true },
                                {
                                    message: '<<sjmcbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            type: 'string',
                        },
                        time: {
                            type: 'object.dateTimeRangePlus',
                            rules: [{ required: true }],
                            displayName: '<<acitivityTime>>',
                        },
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<activityMerchant>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 380,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                                optionFilterProp: 'children',
                                filterOption: (input: any, option: any) =>
                                    (option?.name ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase()),
                            },
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<hdzj>>',
                            options: [
                                { id: '0', name: '<<qbzjcy>>' },
                                { id: '1', name: '<<zdzjcy>>' },
                                { id: '2', name: '<<zdzjbcy>>' },
                            ],
                            defaultValue: '0',
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.merchant.selector.tick',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],

                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                needDependencesText: '<<qxxzmdhzxzzj>>',
                                hideSubsiteColume: false,
                                customFields: [
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantTagId',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                                customColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'code',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<pleaseSelectMerchant>>',
                                },
                            ],
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<activityMerchant>>',
                            rules: [{ required: true }],
                        },
                        // subsiteIds: {
                        //     type: 'array.options.autoComplete',
                        //     displayName: '<<activityMerchant>>',
                        //     options: [],
                        //     source: {
                        //         apiPath: '/admin/defaults/subsite',
                        //     },
                        //     rules: [{ required: true }],
                        // },
                        status: {
                            type: 'string.options.radio',
                            displayName: '<<isEnable>>',
                            defaultValue: '1',
                            options: [
                                {
                                    id: '1',
                                    name: '<<enable>>',
                                },
                                {
                                    id: '2',
                                    name: '<<disabled>>',
                                },
                            ],
                        },
                        /* sequence: {
                            displayName: '<<sequence>>',
                            type: 'number.integer',
                            rules: [{ required: true, message: '<<sequenceRequired>>' }],
                            controlConfig: {
                                placeholder: '<<sequenceRequired>>',
                                max: 99999,
                                min: 0,
                            },
                            displayConfig: {
                                style: {
                                    width: 350,
                                },
                            },
                        }, */
                        awardAction: {
                            displayName: '<<jllx>>',
                            type: 'string.options.radio',
                            defaultValue: '0',
                            options: [
                                {
                                    id: '0',
                                    name: '<<all>>',
                                },
                                {
                                    id: '1',
                                    name: '<<yxyz>>',
                                },
                                {
                                    id: '2',
                                    name: '<<sjyz>>',
                                },
                            ],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    let subsiteIds;
                                    if (get(row, 'baseInfo.typeId') !== '10') {
                                        subsiteIds =
                                            row.baseInfo?.subsiteIds?.length &&
                                            row.baseInfo.subsiteIds
                                                .map((item: any) => item.id)
                                                .join(',');
                                    } else {
                                        subsiteIds = row.baseInfo?.subsiteId;
                                    }

                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                rewardConfig: {
                    type: 'object.subForm',
                    displayName: '<<jlgz>>',
                    properties: {
                        memberSource: {
                            type: 'string.options.radio.plus',
                            displayName: '<<jlrq>>',
                            defaultValue: '1',
                            rules: [{ required: true }],
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: '1',
                                        name: '<<xzcyh>>',
                                        tip: {
                                            text: '<<xzzccw>>',
                                        },
                                    },
                                    {
                                        value: '0',
                                        name: '<<scdlyh>>',
                                        tip: {
                                            text: '<<jscdlxcxhzcdl>>',
                                        },
                                    },
                                ],
                            },
                        },
                        registerMode: {
                            displayName: '<<jlfffs>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '0',
                            options: [
                                {
                                    id: '0',
                                    name: '<<xtzdff>>',
                                },
                                {
                                    id: '1',
                                    name: '<<tgtzymlq>>',
                                },
                            ],
                        },
                        target: {
                            type: 'object.subsiteLinkTypeSelector',
                            displayName: '<<zchtzdym>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        reward: {
                            displayName: '<<jpsz>>',
                            rules: [{ required: true, message: '<<qxzjpsz>>' }],
                            type: 'array.eventMarcketReward',
                        },
                        ladderConf: {
                            displayName: '<<jppz>>',
                            rules: [{ required: true }],
                            type: 'array.eventMarketPayCoupon',
                            controlConfig: {
                                extra: '<<bhjf>>',
                                initData: { totalMoneyMin: '', totalMoneyMax: '', coupons: [] },
                                // canShowLeftStock: true,
                            },
                        },
                    },
                },
                description: {
                    type: 'object.subForm',
                    displayName: '<<hdms>>',
                    properties: {
                        pictures: {
                            displayName: '<<dctp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<rbtxxtdqmrtp>>',
                        },
                        registerEventPicture: {
                            displayName: '<<registerEventPicture>>',
                            type: 'string.picture',
                            extra: extraElement({
                                tips: [
                                    '<<registerEventPictureTips.desc>>',
                                    '<<registerEventPictureTips.size>>',
                                ],
                            }),
                        },
                        systemPictures: {
                            displayName: '<<jldctp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/event-market/system-automatic-distribution-new.png',
                                },
                            ],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<rbtxxtdqmrtp>>',
                        },
                        auserPictures: {
                            displayName: '<<jldctp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/event-market/user-automatic-claim.png',
                                },
                            ],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<rbtxxtdqmrtp>>',
                        },
                        registerEventUseButton: {
                            displayName: '<<registerEventUseButton>>',
                            type: 'object.pictrueLinkType',
                            controlConfig: {
                                getSubsiteIds: (row: any) => {
                                    const subsites = get(row, 'baseInfo.subsiteIds');
                                    return (subsites || []).map((subsite: any) => subsite.id);
                                },
                            },
                        },
                        // picturesHelp: {
                        //     displayName: '弹窗示例',
                        //     type: 'string.pictureExampleButton',
                        //     controlConfig: {
                        //         type: 'eventMarkets',
                        //         buttonName: '<<viewExample>>',
                        //     },
                        // },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<hdsm>>',
                            controlConfig: {
                                placeholder: '<<remarkPlaceHolder>>',
                                rows: 4,
                                maxLength: 121,
                            },
                            extra: '<<srnrxz>>',
                            rules: [
                                {
                                    required: false,
                                    whitespace: true,
                                    max: 120,
                                    message: '<<hdsmzdccxz>>',
                                },
                            ],
                        },
                    },
                },
                conditionConfig: {
                    type: 'object.subForm',
                    displayName: '<<rewardInfos>>',
                    properties: {
                        range: {
                            displayName: '<<ddfw>>',
                            type: 'array.optionIds.checkbox.tip',
                            rules: [{ required: true, message: '<<zddfw>>' }],
                            defaultValue: [],
                            options: [
                                { id: '1', name: '<<xsdd>>' },
                                { id: '0', name: '<<xxdd>>' },
                            ],
                            extra: '<<xsyxxddsytyffxz>>',
                        },
                        condition: {
                            displayName: '<<jltj>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '0',
                            options: [
                                {
                                    id: '0',
                                    name: '<<dcjl>>',
                                },
                                // {
                                //     id: '1',
                                //     name: '累计奖励',
                                // },
                                {
                                    id: '2',
                                    name: '<<fwjl>>',
                                },
                            ],
                            extra: '<<dcjlspdmczfdjeddmjjjl>>',
                        },
                        cycle: {
                            type: 'number.tip',
                            displayName: '<<ljzq_1>>',
                            defaultValue: 1,
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<slbdw>>',
                                    validator: (rule: any, value: any) => {
                                        if (value !== undefined && value !== null && value <= 0) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '100px' },
                                precision: 0,
                                addonafter: '<<day>>',
                            },
                            extra: '<<mqjzcamtjxxflj>>',
                        },
                        time: {
                            displayName: '<<ffsj>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '0',
                            options: [
                                {
                                    id: '0',
                                    name: '<<zfhff>>',
                                },
                            ],
                        },
                        threshold: {
                            type: 'number.tip',
                            displayName: '<<cymj>>',
                            rules: [
                                {
                                    message: '<<thresholdMessage>>',
                                    validator: (rule: any, value: any) => {
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (value > 999999 || value < 0.01)
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                // placeholder: '<<period>>',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<dbxfm>>',
                                addonafter: '<<ykdjl>>',
                                tip: '',
                            },
                            extra: '<<bhjf>>',
                        },
                        limits: {
                            displayName: '<<ffxz>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '-1',
                            options: [
                                {
                                    id: '-1',
                                    name: '<<bxz>>',
                                },
                                {
                                    id: '0',
                                    name: '<<xzmrmtffcs>>',
                                },
                                {
                                    id: '1',
                                    name: '<<xzmrzhdnffcs>>',
                                },
                            ],
                        },
                        count: {
                            type: 'number.tip',
                            displayName: '<<ffcs>>',
                            rules: [
                                {
                                    message: '<<slbdw>>',
                                    validator: (rule: any, value: any) => {
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            value !== '' &&
                                            value <= 0
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '100px' },
                                precision: 0,
                                addonafter: '<<frequency>>',
                            },
                            extra: '<<txszyyxzmgyhffjldsx>>',
                        },
                        grantMode: {
                            displayName: '<<jlfffs>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '0',
                            options: [
                                {
                                    id: '0',
                                    name: '<<xtzdff>>',
                                },
                                {
                                    id: '1',
                                    name: '<<yhzdlq>>',
                                },
                            ],
                            extra: '<<mqjzcxxddkzdlq>>',
                        },
                        claimTime: {
                            type: 'number.tip',
                            displayName: '<<lqsx>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<jzczzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (reg.test(value) || !value) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                min: 1,
                                max: 99999,
                                style: { width: '80px' },
                                addonbefore: '<<jlff>>',
                                addonafter: '<<ccsjhbnlq>>',
                                tip: '<<jlffhkzgtsnlq>>',
                            },
                        },
                        pictureEntrance: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<tppz>>',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/127/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<yyzxpxqlwyhtglqrk>>',
                                    img: './images/avatar/event-market-ticket-picture.png',
                                    btnText: '<<xjyl>>',
                                    triggerType: 'click',
                                    style: {
                                        maxWidth: '375px',
                                        maxHeight: '400px',
                                    },
                                },
                            },
                        },
                        notification: {
                            type: 'object.payment.notificaton.content.checkbox.tip',
                            displayName: '<<tzfs>>',
                            controlConfig: {
                                showAgreementIn: 'false',
                            },
                        },
                        recover: {
                            displayName: '<<jlzh>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '1',
                            options: [
                                {
                                    id: '0',
                                    name: '<<tkhjlhs>>',
                                },
                                {
                                    id: '1',
                                    name: '<<tkhjlbhs>>',
                                },
                            ],
                            extra: '<<rxxddshwdjyzxdglgxs>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        EventMarketView: {
            component: 'Viewport',
            entity: 'EventMarketEntity',
        },
        EventMarketPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'EventMarketFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/eventMarket/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'EventMarketTable',
                },
            ],
        },
        EventMarketFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'eventName',
                },
                {
                    property: 'eventType',
                },
                {
                    property: 'status',
                },
            ],
        },
        EventMarketTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'eventTypeName',
                    displayConfig: {
                        style: {
                            maxWidth: '100px',
                        },
                    },
                },
                /*  {
                    property: 'sequence',
                }, */
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                    width: '200px',
                },
                {
                    property: 'endTime',
                    width: '200px',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            scroll: { x: true },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/event_markets/:id/status',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/event_markets/:id/status',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/eventMarket/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/eventMarket/copy/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<components.PageManage.delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'EventMarketPayCheckButton',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/market/eventMarket/logs/{{row.id}}',
                    },
                ],
            },
        },
        EventMarketAddPage: {
            component: 'FlexLayout',
            entity: 'EventMarketEntity',
            direction: 'horizontal',
            items: [{ component: 'EventMarketPreview' }, { component: 'EventMarketAddFormView' }],
        },
        EventMarketEditPage: {
            component: 'FlexLayout',
            entity: 'EventMarketEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'EventMarketPreview' }, { component: 'EventMarketEditFormView' }],
        },
        EventMarketCopyPage: {
            component: 'FlexLayout',
            entity: 'EventMarketEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'EventMarketPreview' }, { component: 'EventMarketCopyFormView' }],
        },
        EventMarketCheckPage: {
            component: 'Card',
            content: { component: 'EventMarketCheckView' },
        },
        EventMarketCheckView: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<hdsj>>',
                            content: { component: 'EventMarketPayCheckView' },
                        },
                    ],
                },
            ],
        },
        EventMarketLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/event_markets/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/eventMarket',
                    component: 'EventMarketView',
                    breadcrumbName: '<<sjyx>>',
                    privilege: {
                        path: 'eventMarket',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'EventMarketAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'EventMarketEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'EventMarketCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/check/:id',
                            component: 'EventMarketCheckPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'EventMarketLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'EventMarketPage' },
                    ],
                },
            ],
        },
    ],
};
