import React, { PureComponent, Fragment, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';

class TimeLimitActivityComponent extends PureComponent<any, any> {
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps: any = {
            component: 'DataTable',
            entity,
            componentId,
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'perPersonLimitNum',
                },
                {
                    property: 'perOrderLimitNum',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
            ],
            scroll: { x: 1300 },
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 180,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TimeLimitActivityListStatusBotton',
                    },
                    {
                        type: 'link',
                        text: '编辑',
                        path: '/timelimit-activity/edit/{{row.id}}',
                    },
                ],
            },
        };
        if (ENV.TENANT === 'xhd') {
            tableProps.columns.push({
                property: 'supApportionType',
            });
            tableProps.columns.push({
                property: 'supApportionRatio',
            });
        }
        tableProps.columns.push({
            property: 'subsites',
            displayConfig: {
                className: 'ant-table-cell-ellipsis',
                style: {
                    maxWidth: '140px',
                },
                countSuffix: '门店',
            },
        });
        tableProps.columns.push({
            property: 'frontRuleStatus',
        });

        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };

    render() {
        return (
            <Fragment>
                <div>{this.renderTable()}</div>
            </Fragment>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const TimeLimitActivityTable = connect(mapStateToProps)(TimeLimitActivityComponent);
