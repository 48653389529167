import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        cardCollectionEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/cardCollection',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'NOT_STARTED',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'STARTED',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 160 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<kqzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    max: 100,
                                    whitespace: true,
                                    message: '<<activityNameRequired100>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                rewardLimit: {
                    type: 'array.cardCollectionReward',
                    displayName: '<<jpsz>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        maxCount: 10,
                    },
                },
                activityIntro: {
                    type: 'string.text.paragraph',
                    displayName: '<<hdsm>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        maxLength: 1000,
                    },
                },
                cardConfig: {
                    type: 'object.subForm',
                    displayName: '<<kpsz>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                activityType: {
                    type: 'string.options.radio',
                    displayName: '<<hdxs>>',
                    defaultValue: 'CARD_COLLECTION',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [
                        { id: 'CARD_COLLECTION', name: '<<jk>>' },
                        { id: 'CARD_PIECE', name: '<<pt_1>>' },
                    ],
                },
                cardStandard: {
                    type: 'string.options.radio',
                    displayName: '<<ptgg>>',
                    defaultValue: 'FORMAT_MIX',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [
                        { id: 'FORMAT_MIX', name: '2X2' },
                        { id: 'FORMAT_MAX', name: '3X3' },
                    ],
                },
                cardBigPic: {
                    displayName: '<<jkdt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/collect-card/card-big.png',
                        },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardItemConfig: {
                    type: 'array.cardCollectionItemConfig',
                    displayName: '<<kpsz>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                ruleConfig: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                ruleConfig1: {
                    type: 'array.cardCollectionRuleConfig',
                    displayName: '<<rule>>1',
                    controlConfig: {
                        title: '<<mrcyhdlq>>',
                        hideCount1: true,
                        type: 'FIRST_ACCESS',
                        defaultChecked: true,
                    },
                },
                ruleConfig2: {
                    type: 'array.cardCollectionRuleConfig',
                    displayName: '<<rule>>2',
                    controlConfig: {
                        title: '<<mrsczskp>>',
                        type: 'FIRST_GIVE',
                        hideCount1: true,
                    },
                },
                ruleConfig3: {
                    type: 'array.cardCollectionRuleConfig',
                    displayName: '<<rule>>3',
                    controlConfig: {
                        title: '<<activityFriZl>>',
                        prefix: '<<lxyqd>>',
                        suffix: '<<ruleConfigSuffix>>',
                        type: 'INVITE_HELP',
                        stage: 1,
                        addManually: true,
                    },
                },
                ruleConfig4: {
                    type: 'array.cardCollectionRuleConfig',
                    displayName: '<<rule>>4',
                    controlConfig: {
                        title: '<<hdqnyqhyzc>>',
                        prefix: '<<lxyqd>>',
                        suffix: '<<ruleConfigSuffix>>',
                        type: 'INVITE_REGISTER',
                        stage: 1,
                        addManually: true,
                    },
                },
                ruleConfig5: {
                    type: 'array.cardCollectionRuleConfig',
                    displayName: '<<rule>>5',
                    controlConfig: {
                        title: '<<hdqnljxf>>',
                        prefix: '<<ljxf>>',
                        type: 'CONSUMPTION',
                        suffix: '<<yuan>>',
                        stage: 1,
                        addManually: true,
                        max: 999999,
                    },
                },
                picConfig: {
                    type: 'object.subForm',
                    displayName: '<<tpsz>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                cardBg: {
                    displayName: '<<jkbjt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/collect-card/card-bg.png',
                        },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardRulePic: {
                    displayName: '<<hdgztb>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    rules: [{ required: true }],
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/collect-card/card-rule-pic.png',
                        },
                    ],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardRewardPic: {
                    displayName: '<<jpzstb>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/collect-card/card-reward-pic.png',
                        },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardRewardBtnPic: {
                    displayName: '<<hcanbjt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/collect-card/card-reward-btn-pic.png',
                        },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardRuleBtnPic: {
                    displayName: '<<gzqanbjt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/collect-card/card-rule-btn-pic.png',
                        },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardPosterPic: {
                    displayName: '<<fxhb>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardSharePic: {
                    displayName: '<<fxxt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                cardHelpPic: {
                    displayName: '<<tpzy>>',
                    type: 'string.pictureExampleButton',
                    controlConfig: {
                        type: 'cardCollection',
                        showDownload: true,
                        downloadApiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        downloadApiPath: '/admin/card_collections/example_files',
                    },
                },
            },
        },
    },
    components: {
        cardCollectionView: {
            component: 'Viewport',
            entity: 'cardCollectionEntity',
        },
        cardCollectionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'cardCollectionFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzjkhd>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/cardCollection/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'cardCollectionTable' },
            ],
        },
        cardCollectionFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        cardCollectionTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLE' },
                                    value: 'ENABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/card_collections/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.baseInfo.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: 'ENABLE' },
                                    value: 'DISABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/card_collections/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/cardCollection/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/cardCollection/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<reportData>>',
                        path: '/menu-list/market/cardCollection/report/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/card_collections/{{row.id}}/export',
                            text: '<<dc>>',
                        },
                    },
                ],
            },
        },
        cardCollectionAddPage: {
            component: 'FlexLayout',
            entity: 'cardCollectionEntity',
            direction: 'horizontal',
            items: [
                { component: 'CardCollectionPreview' },
                { component: 'CardCollectionAddFormView' },
            ],
        },
        cardCollectionEditPage: {
            component: 'FlexLayout',
            entity: 'cardCollectionEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'CardCollectionPreview' },
                { component: 'CardCollectionEditFormView' },
            ],
        },
        cardCollectionCopyPage: {
            component: 'FlexLayout',
            entity: 'cardCollectionEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'CardCollectionPreview' },
                { component: 'CardCollectionCopyFormView' },
            ],
        },
        cardCollectionReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'CardCollectionReportView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/cardCollection',
                    component: 'cardCollectionView',
                    breadcrumbName: '<<jkhd>>',
                    privilege: {
                        path: 'cardCollectionActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'cardCollectionAddPage',
                            breadcrumbName: '<<xzjkhd>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'cardCollectionEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'cardCollectionCopyPage',
                            breadcrumbName: '<<copy>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'cardCollectionReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        { path: '/', component: 'cardCollectionPage' },
                    ],
                },
            ],
        },
    ],
};
