import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { uniq, get } from 'lodash';
import CrowdsSelector from './crowds-selector';
import { TextTips } from '../../../../components/text-tips';
import { services } from '@comall-backend-builder/core';

const { api } = services;

export interface ValueItem {
    id: string;
    name: string;
    peopleNumber: number;
    subsiteName: string;
    creationMode: string;
}
enum PageType {
    CashCouponRuleMemberLevel = 'CashCouponRuleMemberLevel',
    GiftCouponRuleMemberLevel = 'GiftCouponRuleMemberLevel',
    PackingCouponRuleMemberLevel = 'PackingCouponRuleMemberLevel',
    FrightCouponRuleMemberLevel = 'FrightCouponRuleMemberLevel',
    LotteryCouponRuleMemberLevel = 'LotteryCouponRuleMemberLevel',
    CouponPackageRuleMemberLevel = 'CouponPackageRuleMemberLevel',
    EfutureCouponRuleMemberLevel = 'EfutureCouponRuleMemberLevel',
    BfplatCouponRuleMemberLevel = 'BfplatCouponRuleMemberLevel',
    SalesElectronicCoupon = 'SalesElectronicCoupon',
    CreditShop = 'CreditShop',
    NewMallActivity = 'NewMallActivity',
}

export default class ReceiveLimitCrowdsSelect extends PureComponent<
    {
        value: ValueItem[];
        row: any;
        pageType: PageType;
        onChange: (v: ValueItem[]) => void;
    },
    any
> {
    constructor(props: any) {
        super(props);
        const dependencesValue = this.getDependencesValue(props);
        this.state = {
            //特殊详情页面
            pageType: props.pageType,
            // 依赖项的值
            dependencesValue: dependencesValue,
            //是否展示需要依赖的提示语
            showNeedDependencesMessage: true,
            supportMemberMarking: true,
        };
    }

    async componentDidMount() {
        const response = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                apiPath: `/roles/super/privilege_set/memberv2Crowds/verify`,
            }
        );
        this.setState({
            supportMemberMarking: Boolean(response),
        });
    }

    // 当props发生变化时，更新待选项数据
    componentWillReceiveProps(nextProps: any) {
        const { row, name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        const nextDependencesValue = this.getDependencesValue(nextProps);
        let showNeedDependencesMessage = false;
        // 如果props变更后，依赖项有值
        if (nextDependencesValue) {
            if (dependencesValue !== nextDependencesValue) {
                if (nextDependencesValue.length === 0) {
                    this.clearValue(nextProps);
                    return;
                }
                if (nextDependencesValue.length !== 1) {
                    if (
                        nextProps.pageType === PageType.CashCouponRuleMemberLevel ||
                        nextProps.pageType === PageType.GiftCouponRuleMemberLevel
                    ) {
                        //特殊页面处理-选择专柜-多个专柜是否不是同一门店；选择门店-是否选择了一个
                        const nextTypeselectMode = row && get(row, 'productInfo.selectMode');
                        const thisTypeselectMode =
                            this.props.row && get(this.props.row, 'productInfo.selectMode');
                        if (
                            thisTypeselectMode !== null &&
                            nextTypeselectMode !== thisTypeselectMode
                        ) {
                            this.clearValue(nextProps);
                            return;
                        }
                    }
                }
                //清空已选择的值
                if (dependencesValue) {
                    if (onChange) {
                        onChange(null, name);
                    }
                }
                //重新请求options
                this.setState({
                    options: [],
                    dependencesValue: nextDependencesValue,
                    showNeedDependencesMessage: showNeedDependencesMessage,
                });
            }
        } else if (dependencesValue) {
            this.clearValue(nextProps);
        }
    }

    clearValue = (nextProps: any) => {
        const { name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        if (dependencesValue) {
            //清空已选择的值
            if (onChange) {
                onChange(null, name);
            }
        }
        // 清楚保存的依赖项的值
        this.setState({
            dependencesValue: null,
            showNeedDependencesMessage: true,
        });
    };

    getDependencesValue = (props: any) => {
        const { row } = props;
        let dependencesValue = '';
        if (props.pageType === PageType.PackingCouponRuleMemberLevel) {
            dependencesValue = row && get(row, 'productInfo.subsites');
        } else if (
            props.pageType === PageType.FrightCouponRuleMemberLevel ||
            props.pageType === PageType.LotteryCouponRuleMemberLevel ||
            props.pageType === PageType.EfutureCouponRuleMemberLevel ||
            props.pageType === PageType.BfplatCouponRuleMemberLevel
        ) {
            dependencesValue = row && get(row, 'productInfo.subsite');
        } else if (
            props.pageType === PageType.CashCouponRuleMemberLevel ||
            props.pageType === PageType.GiftCouponRuleMemberLevel
        ) {
            const selectMode = row && get(row, 'productInfo.selectMode');
            if (selectMode === 'SUB_SITE') {
                dependencesValue = row && get(row, 'productInfo.subsite');
            }
            if (selectMode === 'MERCHANT') {
                dependencesValue = row && get(row, 'productInfo.shop');
            }
        } else if (props.pageType === PageType.CouponPackageRuleMemberLevel) {
            dependencesValue = row && get(row, 'baseInfo.subsiteIds');
        } else if (props.pageType === PageType.SalesElectronicCoupon) {
            dependencesValue = row && get(row, 'baseInfo.subsites');
        } else if (props.pageType === PageType.CreditShop) {
            dependencesValue = row && get(row, 'subsiteId');
        } else if (props.pageType === PageType.NewMallActivity) {
            dependencesValue = row && get(row, 'baseInfo.merchants');
        }
        return dependencesValue;
    };
    getSubsiteIds = () => {
        const { row, pageType } = this.props;
        if (
            pageType === PageType.CashCouponRuleMemberLevel ||
            pageType === PageType.GiftCouponRuleMemberLevel
        ) {
            if (row && row.productInfo) {
                const { selectMode, shop = [], subsite = [] } = row.productInfo;
                if (selectMode === 'SUB_SITE' && subsite && subsite.length > 0) {
                    return subsite.map((i: any) => i.id);
                }
                if (selectMode === 'MERCHANT' && shop && shop.length > 0) {
                    return uniq(shop.map((i: any) => i.subSiteId));
                }
            }
            return [];
        } else if (
            pageType === PageType.FrightCouponRuleMemberLevel ||
            pageType === PageType.LotteryCouponRuleMemberLevel ||
            pageType === PageType.EfutureCouponRuleMemberLevel ||
            pageType === PageType.BfplatCouponRuleMemberLevel
        ) {
            if (row && row.productInfo) {
                const { subsite } = row.productInfo;
                return subsite.map((i: any) => i.id);
            }
            return [];
        } else if (pageType === PageType.PackingCouponRuleMemberLevel) {
            if (row && row.productInfo) {
                const { subsites = [] } = row.productInfo;
                return subsites.map((i: any) => i.id);
            }
            return [];
        } else if (pageType === PageType.CouponPackageRuleMemberLevel) {
            if (row && row.baseInfo) {
                const { subsiteIds = [] } = row.baseInfo;
                return subsiteIds.map((i: any) => i.id);
            }
            return [];
        } else if (pageType === PageType.SalesElectronicCoupon) {
            if (row && row.baseInfo) {
                const { subsites = [] } = row.baseInfo;
                return subsites.map((i: any) => i.id);
            }
            return [];
        } else if (pageType === PageType.CreditShop) {
            const { subsiteId = [] } = row;
            return subsiteId.map((i: any) => i.id);
        } else if (pageType === PageType.NewMallActivity) {
            const { baseInfo } = row;
            const { merchants = [] } = baseInfo;
            return merchants.map((i: any) => i.id);
        }
        return [];
    };
    deleteSelected = (id: string) => {
        const { value, onChange } = this.props;
        onChange(value.filter((i) => i.id !== id));
    };

    renderSelectedDisplay = () => {
        const { value } = this.props;
        if (value && value.length > 0) {
            return (
                <Table
                    rowKey={(record: any) => record.id}
                    pagination={false}
                    columns={[
                        {
                            title: services.language.getText('peopleName'),
                            dataIndex: 'name',
                        },
                        {
                            title: services.language.getText('createType'),
                            dataIndex: 'creationMode',
                            render: (value: string) => {
                                return value === 'RULE_MODE'
                                    ? services.language.getText('gzcj')
                                    : services.language.getText('drzdyh');
                            },
                        },
                        {
                            title: services.language.getText('subsites'),
                            dataIndex: 'subsiteName',
                        },
                        {
                            title: (
                                <TextTips
                                    text={services.language.getText('peopleNum')}
                                    tips={services.language.getText('limitPeopleCount')}
                                />
                            ),
                            dataIndex: 'peopleNumber',
                        },
                        {
                            title: services.language.getText('common.tableAction'),
                            className: 'action-column',
                            align: 'center',
                            render: (text, record) => {
                                return (
                                    <Button
                                        type="link"
                                        onClick={() => this.deleteSelected(record.id)}
                                    >
                                        {services.language.getText('common.delete')}
                                    </Button>
                                );
                            },
                        },
                    ]}
                    dataSource={value}
                />
            );
        }
    };

    render() {
        const { value, onChange } = this.props;
        const { supportMemberMarking } = this.state;
        if (!supportMemberMarking) {
            return (
                <Button type="link" disabled>
                    {services.language.getText('contactManager')}
                </Button>
            );
        }
        return (
            <div>
                <CrowdsSelector
                    value={value}
                    onChange={onChange}
                    getSubsiteIds={this.getSubsiteIds}
                />
                {this.renderSelectedDisplay()}
            </div>
        );
    }
}
