import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, map, find, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

const TableComponentId = 'StoredValueCardProductSelectorTable';
let Entity: any = {};
let entity: any = {};
/**
 * 选择
 */
class storedValueCardProductSelector extends PureComponent<{
    selectValues: any[];
    onChange: (brands: any[]) => void;
    requestStatus: string;
}> {
    dispatch: any = {};
    state = {
        visible: false,
    };
    selectedRows = new Array<any>();

    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('StoredValueCardProductEntity');
        entity = new Entity({});
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.id;
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
            }
        }
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            if (find(this.selectedRows, { id: record.id })) {
                return;
            }
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[], unSelectedRows: any) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = async () => {
        const { onChange } = this.props;
        if (onChange) {
            // forEach(this.selectedRows, (brand) => {
            //     brand.select = true;
            // });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { selectValues } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    // reset: true,
                    // submit: {
                    //     text: services.language.getText('common.search'),
                    //     style: {
                    //         marginRight: 16,
                    //     },
                    // },
                    // fields: [
                    //     {
                    //         property: 'name',
                    //     },
                    // ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: 'checkbox',
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.id;
                        }),
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any[], unSelectedRows: any[]) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [{ property: 'code' }, { property: 'name' }, { property: 'type' }],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        if (!visible) {
            const { selectValues } = this.props;
            this.selectedRows = selectValues || [];
        }
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                // entity.filtersChange({
                //     name: undefined,
                // });
                entity.search({
                    ...entity.paging,
                });
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('qxzklb'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <span>
                <Button type="link" onClick={this.toggleModal}>
                    {services.language.getText('xzklb')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </span>
        );
    }
}

export const StoredValueCardProductSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(storedValueCardProductSelector);
