import React, { Component } from 'react';
import { api } from '@comall-backend-builder/core/lib/services';
import { connect } from 'react-redux';
import { chunk, forEach, get } from 'lodash';
import { isSimplicityTheme } from '../theme-style';
import { services } from '@comall-backend-builder/core';
import './index.less';
import moment from 'moment';

const prefix = 'collection-points-preview';

/**
 * 活动左侧
 * @param props
 */
const collectionPointNum = 2;
class collectionPrintingPreview extends Component<any, { themeStyle: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            themeStyle: {},
        };
    }
    componentDidMount(): void {
        this.getThemeStyle();
    }

    getThemeStyle = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/theme_style',
        };
        api.get({}, config).then((res: any) => {
            if (res.id) {
                this.setState({ themeStyle: JSON.parse(res.config) });
            }
        });
    };

    private get isSimplicityTheme() {
        const { themeStyle } = this.state;
        if (!themeStyle) {
            return false;
        }
        return isSimplicityTheme(themeStyle);
    }

    getField = (field: string) => {
        const { preview } = this.props;
        return get(preview, field);
    };
    renderRule = () => {
        const rule = this.getField('pageRuleInfo.description');
        if (!rule) {
            return null;
        }
        const className = `${prefix}__rule`;
        return <div className={className}>{services.language.getText('rule')}</div>;
    };

    renderActivityInfo = () => {
        const innerClassNames = {
            activity: `${prefix}__activity`,
            activityMember: `${prefix}__activity__member`,
            activityTop: `${prefix}__activity__top`,
            activityTopInfo: `${prefix}__activity__top__info`,
            activityTopNum: `${prefix}__activity__top__num`,
            activityTopNumTitle: `${prefix}__activity__top__num__title`,
            activityTopNumText: `${prefix}__activity__top__num__text`,
            activityTopTime: `${prefix}__activity__top__time`,
        };
        const timesRule = this.getField('obtainRuleInfo.timesRule');
        if (!timesRule) {
            return null;
        }
        let text = '';
        if (timesRule.type === 'CONSUMPTION') {
            const consumptionCount = timesRule.consumptionCount || '';
            text = services.language.getText('dbxfm') + consumptionCount;
        } else if (timesRule.type === 'CONSUMPTION_PER_FULL') {
            const consumptionPerFullCount = timesRule.consumptionPerFullCount || '';
            text = services.language.getText('dbxfmm') + consumptionPerFullCount;
        }
        const dateRange = this.getField('baseInfo.dateRange');
        return (
            <div className={innerClassNames.activity}>
                <div className={innerClassNames.activityTop}>
                    <div className={innerClassNames.activityTopInfo}>
                        {text}
                        {services.language.getText('ykjyd')}
                    </div>
                    <div className={innerClassNames.activityTopNum}>
                        <div className={innerClassNames.activityTopNumTitle}>
                            {services.language.getText('jds')}
                        </div>
                        <div className={innerClassNames.activityTopNumText}>
                            {collectionPointNum}
                        </div>
                    </div>
                </div>
                {this.renderPoints()}
                {dateRange && dateRange.start && dateRange.end && (
                    <div className={innerClassNames.activityTopTime}>
                        活动时间：
                        {`${moment(dateRange.start).format('YYYY-MM-DD HH:mm')}-${moment(
                            dateRange.end
                        ).format('YYYY-MM-DD HH:mm')}`}
                    </div>
                )}
            </div>
        );
    };

    renderPoints = () => {
        const innerClassNames = {
            activityContent: `${prefix}__activity__content`,
            activityPoints: `${prefix}__activity__points`,
            activityPointsPic: `${prefix}__activity__points__pic`,
            activityDians: `${prefix}__activity__dians`,
            activityDiansBlack: `${prefix}__activity__dians__black`,
            activityDiansDis: `${prefix}__activity__dians__black ${prefix}__activity__dians__dis`,
        };
        const collectedPointsPicture = this.getField('pageRuleInfo.collectedPointsPicture');
        let jpic = '';
        let wjpic = '';
        if (collectedPointsPicture.type === 'DEFALULT') {
            jpic = get(collectedPointsPicture.defalutCollectedPicture, 'path', undefined);
            wjpic = get(collectedPointsPicture.defalutUnCollectedPicture, 'path', undefined);
        } else if (collectedPointsPicture.type === 'CUSTOM') {
            jpic = get(collectedPointsPicture.customCollectedPicture, 'path', undefined);
            wjpic = get(collectedPointsPicture.customUnCollectedPicture, 'path', undefined);
        }
        const points: Array<{
            sort: number;
            picture: string;
        }> = [];
        const maxLength = 10;
        for (let i = 1; i <= maxLength; i++) {
            points.push({
                sort: i,
                picture: i > collectionPointNum ? wjpic : jpic,
            });
        }
        return (
            <div className={innerClassNames.activityContent}>
                <div className={innerClassNames.activityPoints}>
                    {points.map((p) => {
                        const zhengchu = p.sort % 5 === 0;
                        const style = {
                            margin: zhengchu ? '0 0 10px 0' : undefined,
                        };
                        return (
                            <img
                                style={style}
                                className={innerClassNames.activityPointsPic}
                                alt=""
                                src={p.picture}
                            />
                        );
                    })}
                </div>
                <div className={innerClassNames.activityDians}>
                    <div className={innerClassNames.activityDiansBlack}></div>
                    <div className={innerClassNames.activityDiansDis}></div>
                </div>
            </div>
        );
    };

    renderExchangeInfo = () => {
        const innerClassNames = {
            exchange: `${prefix}__exchange`,
            exchangeTop: `${prefix}__exchange__top`,
            exchangeTopTitle: `${prefix}__exchange__top__title`,
            exchangeTopTitleLeft: `${prefix}__exchange__top__title__left`,
            exchangeTopTitleRight: `${prefix}__exchange__top__title__right`,
            exchangeTopHref: `${prefix}__exchange__top__href`,
            exchangeTopHrefImg: `${prefix}__exchange__top__href__img`,
            exchangeGroups: `${prefix}__exchange__groups`,
            exchangeList: `${prefix}__exchange__list`,
            exchangeListItem: `${prefix}__exchange__list__item`,
            exchangeListItemPic: `${prefix}__exchange__list__item__pic`,
            exchangeListItemName: `${prefix}__exchange__list__item__name ant-table-cell-ellipsis`,
            exchangeListItemInfo: `${prefix}__exchange__list__item__info`,
            exchangeListItemNum: `${prefix}__exchange__list__item__num`,
            exchangeListItemNumText: `${prefix}__exchange__list__item__num__text`,
            exchangeListItemButton: `${prefix}__exchange__list__item__button`,
        };
        const couponTypeImgVo: Record<string, string> = {
            CASH: 'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_cash.png',
            GIFT: 'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_gift.png',
            FREIGHT: 'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_freight.png',
            PARKING: 'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_parking.png',
            PACKAGE: 'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_package.png',
            LOTTERY: 'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_lottery.png',
            BALANCE:
                'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_efuture_balance.png',
            EFUTURE_CASH:
                'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_efuture_cash.png',
            EFUTURE_BALANCE:
                'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_efuture_balance.png',
            BFPLAT_COUPON:
                'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_bfplat_coupon.png',
        };
        const list: Array<{
            pic: string;
            name: string;
            num: number;
        }> = [];
        const reward = this.getField('dhgzRuleInfo.reward');
        if (reward && reward.length > 0) {
            forEach(reward, (r: any) => {
                if (r.coupons && r.coupons.length > 0) {
                    forEach(r.coupons, (c: any) => {
                        const defalutImg = couponTypeImgVo[c.couponType];
                        list.push({
                            pic: c.pictureUrl || defalutImg,
                            name: c.couponName,
                            num: r.consumeValue || 0,
                        });
                    });
                }
            });
        }
        const groups = chunk(list, 2);
        return (
            <div className={innerClassNames.exchange}>
                <div className={innerClassNames.exchangeTop}>
                    <div className={innerClassNames.exchangeTopTitle}>
                        <div className={innerClassNames.exchangeTopTitleLeft}>
                            {services.language.getText('dhzq')}
                        </div>
                        <div className={innerClassNames.exchangeTopTitleRight}>
                            （{services.language.getText('kyds')}：{collectionPointNum}）
                        </div>
                    </div>
                    <div className={innerClassNames.exchangeTopHref}>
                        <div>{services.language.getText('jdmx')}</div>
                        <img
                            alt=""
                            className={innerClassNames.exchangeTopHrefImg}
                            src="https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-arrow-right.png"
                        />
                    </div>
                </div>
                <div className={innerClassNames.exchangeGroups}>
                    {groups.map((group) => {
                        return (
                            <div className={innerClassNames.exchangeList}>
                                {group.map((item) => {
                                    return (
                                        <div className={innerClassNames.exchangeListItem}>
                                            <img
                                                alt=""
                                                className={innerClassNames.exchangeListItemPic}
                                                src={item.pic}
                                            />
                                            <div className={innerClassNames.exchangeListItemName}>
                                                {item.name}
                                            </div>
                                            <div className={innerClassNames.exchangeListItemInfo}>
                                                <div
                                                    className={innerClassNames.exchangeListItemNum}
                                                >
                                                    <span
                                                        className={
                                                            innerClassNames.exchangeListItemNumText
                                                        }
                                                    >
                                                        {item.num}
                                                    </span>
                                                    {services.language.getText('jd_2')}
                                                </div>
                                                <div
                                                    className={
                                                        innerClassNames.exchangeListItemButton
                                                    }
                                                >
                                                    {services.language.getText('qdh')}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    render() {
        const { themeStyle } = this.state;
        const backgroundPicture = this.getField('pageRuleInfo.backgroundPicture');
        const backgroundImage = get(backgroundPicture, '[0].path', undefined);
        const contentStyle = {
            backgroundImage: `url(${backgroundImage})`,
        };
        const headStyle = {
            backgroundColor: this.isSimplicityTheme
                ? themeStyle?.navigation?.style?.backgroundColor
                : '#fff',
        };
        const pageName = this.getField('baseInfo.name');
        return (
            <div className={prefix}>
                <div className="theme-style-preview-item" style={headStyle}>
                    <img style={{ width: '100%' }} alt="" src="./images/avatar/theme-head.png" />
                    <div className="theme-style-preview-cart-title">{pageName}</div>
                </div>
                <div style={contentStyle} className="collection-points-preview__content">
                    {this.renderRule()}
                    {this.renderActivityInfo()}
                    {this.renderExchangeInfo()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }

    return { preview: preview };
};

export const CollectionPointsPreview = connect(mapStateToProps)(collectionPrintingPreview);
