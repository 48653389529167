import React, { Component } from 'react';
import { Form, Input, Button, Modal, Table, message, Select } from 'antd';
import PropTypes from 'prop-types';
import { services } from '@comall-backend-builder/core';
import './index.less';

const { getText } = services.language;

const { Option } = Select;
const api = services.api;

interface SecurityPolicyWhiteItem {
    id: string;
    mobile: string;
    userName: string;
    modifiedTime: string;
    modifiedUser: string;
    creatorName: string;
    creator: number;
    createTime: string;
}
interface Pagination<T> {
    page: number;
    perPage: number;
    totalNum: number;
    totalPage: number;
    result: T[];
}

interface VirtualNumberWhiteListFormState {
    securityPolicyWhiteList: SecurityPolicyWhiteItem[];
    total: number;
    pagination: {
        page: number;
        perPage: number;
    };
    searchParams: {
        mobile: string;
    };
    showModal: boolean;
    addNumber: string;
    securityPolicyType?: SecurityPolicyType;
}
enum SecurityPolicyType {
    LOGIN_POLICY = 'LOGIN_POLICY',
    REGISTER_POLICY = 'REGISTER_POLICY',
}

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Form.Item label={getText('phoneNum')}>
                        {getFieldDecorator('mobile')(
                            <Input
                                placeholder={services.language.getText('inputPlease')}
                                style={{ width: 190 }}
                            />
                        )}
                    </Form.Item>
                    <div>
                        <Form.Item style={{ marginLeft: 'auto' }}>
                            <Button type="primary" onClick={this.handleSearch}>
                                {getText('cx')}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="reset" onClick={this.handleReset}>
                                {getText('common.reset')}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            );
        }
    }
);

export class VirtualNumberWhiteListForm extends Component<any, VirtualNumberWhiteListFormState> {
    state: VirtualNumberWhiteListFormState = {
        securityPolicyWhiteList: [],
        total: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        searchParams: {
            mobile: '',
        },
        showModal: false,
        addNumber: '',
        securityPolicyType: undefined,
    };
    componentDidMount(): void {
        this.onSearchData();
    }
    onSearchData = async () => {
        try {
            const { searchParams, pagination } = this.state;
            const params: any = {
                ...pagination,
                ...searchParams,
            };
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: '/admin/securitypolicy/whitelist',
            };
            const res = await api.get<Pagination<SecurityPolicyWhiteItem>>(params, config);
            this.setState({
                securityPolicyWhiteList: res.result,
                total: res.totalNum,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    onChangeParams = (searchParams: { mobile: string }) => {
        this.setState(
            {
                searchParams,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearchData();
            }
        );
    };

    onShowAddNumberWhite = () => {
        this.setState({
            showModal: true,
        });
    };
    closeAddModal = () => {
        this.setState({
            showModal: false,
            addNumber: '',
            securityPolicyType: undefined,
        });
    };
    onAddNumber = () => {
        const { addNumber, securityPolicyType } = this.state;
        if (!/^1\d{10}$/.test(addNumber)) {
            message.warning(getText('qsrzqsjh'));
            return;
        }
        if (!securityPolicyType) {
            message.warning(getText('qxzsjlx'));
            return;
        }
        this.onAddWhiteList(addNumber, securityPolicyType);
    };
    onAddWhiteList = async (mobile: string, securityPolicyType: SecurityPolicyType) => {
        try {
            const params = {
                mobile,
                securityPolicyType,
            };
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: '/admin/securitypolicy/whitelist',
            };
            await api.post(params, config);
            await this.onSearchData();
            this.closeAddModal();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    onAddNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^1\d{0,10}$/.test(value)) {
            this.setState({
                addNumber: value,
            });
        }
    };
    onChangeSecurityPolicyType = (type: SecurityPolicyType) => {
        this.setState({
            securityPolicyType: type,
        });
    };
    onDeleteWhiteList = async (row: SecurityPolicyWhiteItem) => {
        try {
            const id = row.id;
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: `/admin/securitypolicy/whitelist/${id}`,
            };
            await api.delete({}, config);
            await this.onSearchData();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    renderFilter = () => {
        const classes = {
            filter: `${prefix}__filter`,
        };
        return (
            <div className={classes.filter}>
                <CollectionCreateForm onSearch={this.onChangeParams} />
            </div>
        );
    };

    renderButton = () => {
        const classes = {
            buttons: `${prefix}__buttons`,
        };
        return (
            <div className={classes.buttons}>
                <Button type="primary" onClick={this.onShowAddNumberWhite}>
                    {getText('tjbmd')}
                </Button>
            </div>
        );
    };

    renderMoadl = () => {
        const { showModal, addNumber, securityPolicyType } = this.state;
        const classes = {
            add: `${prefix}__add`,
            label: `${prefix}__add__label`,
        };
        return (
            <Modal
                width={400}
                onCancel={this.closeAddModal}
                onOk={this.onAddNumber}
                visible={showModal}
                title={getText('tjbmd')}
            >
                <div className={classes.add}>
                    <span className={classes.label}>{getText('phoneNum')}</span>
                    <Input
                        value={addNumber}
                        placeholder={getText('inputPlease')}
                        style={{ width: 230 }}
                        onChange={this.onAddNumberChange}
                    />
                </div>
                <div className={classes.add} style={{ marginTop: 10 }}>
                    <span className={classes.label}>{getText('sjlx')}</span>
                    <Select
                        value={securityPolicyType}
                        style={{ width: 230 }}
                        onChange={this.onChangeSecurityPolicyType}
                    >
                        <Option value={SecurityPolicyType.LOGIN_POLICY}>{getText('login')}</Option>
                        <Option value={SecurityPolicyType.REGISTER_POLICY}>
                            {getText('register')}
                        </Option>
                    </Select>
                </div>
            </Modal>
        );
    };

    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearchData();
        });
    };

    renderTable = () => {
        const { securityPolicyWhiteList, pagination, total } = this.state;
        return (
            <Table
                pagination={{
                    pageSize: pagination.perPage,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['10', '15', '20'],
                    total,
                    showTotal(total: number) {
                        return services.interpolate(getText('total'), {
                            total,
                        });
                    },
                    onChange: (page, perPage) => {
                        this.pageChange({ page, perPage });
                    },
                    onShowSizeChange: (page, perPage) => {
                        this.pageChange({ page, perPage });
                    },
                }}
                scroll={{ y: '600px' }}
                rowKey={'id'}
                dataSource={securityPolicyWhiteList}
                columns={[
                    {
                        title: getText('bmdsjh'),
                        dataIndex: 'mobile',
                        key: 'mobile',
                    },
                    {
                        title: services.language.getText('sjlx'),
                        dataIndex: 'securityPolicyType',
                        key: 'securityPolicyType',
                        render: (text: SecurityPolicyType) => {
                            return (
                                <span>
                                    {text === SecurityPolicyType.LOGIN_POLICY
                                        ? getText('login')
                                        : text === SecurityPolicyType.REGISTER_POLICY
                                        ? services.language.getText('register')
                                        : ''}
                                </span>
                            );
                        },
                    },
                    {
                        title: services.language.getText('lastModifiedUser'),
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                    },
                    {
                        title: services.language.getText('tjsj_1'),
                        dataIndex: 'modifiedTime',
                        key: 'modifiedTime',
                    },
                    {
                        title: services.language.getText('common.tableAction'),
                        className: 'action-column',
                        key: 'id',
                        width: 100,
                        render: (row: SecurityPolicyWhiteItem) => {
                            return (
                                <div>
                                    <Button
                                        type={'link'}
                                        onClick={this.onDeleteWhiteList.bind(this, row)}
                                        style={{ padding: '0' }}
                                    >
                                        {services.language.getText('common.delete')}
                                    </Button>
                                </div>
                            );
                        },
                    },
                ]}
            />
        );
    };
    render() {
        return (
            <div className={prefix}>
                {this.renderFilter()}
                {this.renderButton()}
                {this.renderTable()}
                {this.renderMoadl()}
            </div>
        );
    }
}

const prefix = 'virtual-number-white-list-form';
