import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty, isNull, endsWith, trimEnd } from 'lodash';
import {
    SchedulerAutoExecuteType,
    SchedulerExecuteType,
    SchedulerMethodsValue,
} from '../../components';

const api = services.api;

enum ContraposeType {
    AppointCrowd = 'APPOINT_CROWD',
    ImportCrowd = 'IMPORT_CROWD',
}
const contraposeCrowdType = {
    [ContraposeType.AppointCrowd]: '指定人群',
    [ContraposeType.ImportCrowd]: '导入指定用户',
};

function schedulerMethodsValueWriteInParams(
    params: any,
    schedulerMethods: SchedulerMethodsValue
): void {
    params.executeType = schedulerMethods.executeType;

    switch (schedulerMethods.executeType) {
        case SchedulerExecuteType.AUTO: {
            params.schedulerStartTime = schedulerMethods.schedulerStartTime;
            params.schedulerEndTime = schedulerMethods.schedulerEndTime;
            params.schedulerTime = schedulerMethods.schedulerTime;

            switch (schedulerMethods.autoExecuteType) {
                case SchedulerAutoExecuteType.DAY: {
                    params.schedulerDay = schedulerMethods.schedulerDay;
                    break;
                }
                case SchedulerAutoExecuteType.WEEK: {
                    params.schedulerWeekDay = schedulerMethods.schedulerWeekDay;
                    break;
                }
                case SchedulerAutoExecuteType.MONTH: {
                    params.schedulerDayOfMonth = schedulerMethods.schedulerDayOfMonth;
                    break;
                }
                default: {
                    params.schedulerDay = schedulerMethods.schedulerDay;
                }
            }

            break;
        }
        case SchedulerExecuteType.NOW: {
            // 不用处理
            break;
        }
        case SchedulerExecuteType.TIMING: {
            params.schedulerExecuteTime = schedulerMethods.schedulerExecuteTime + ':00';
            break;
        }
    }
}

function createSchedulerMethodsValue(data: any): SchedulerMethodsValue {
    const schedulerMethods: SchedulerMethodsValue = {
        executeType: data.executeType,
        schedulerStartTime: data.schedulerStartTime,
        schedulerEndTime: data.schedulerEndTime,
        schedulerTime: data.schedulerTime,
        schedulerDay: data.schedulerDay,
        schedulerWeekDay: data.schedulerWeekDay,
        schedulerDayOfMonth: data.schedulerDayOfMonth,
        schedulerExecuteTime: data.schedulerExecuteTime,
    };

    if (schedulerMethods.schedulerDay) {
        schedulerMethods.autoExecuteType = SchedulerAutoExecuteType.DAY;
    }

    if (schedulerMethods.schedulerWeekDay) {
        schedulerMethods.autoExecuteType = SchedulerAutoExecuteType.WEEK;
    }

    if (schedulerMethods.schedulerDayOfMonth) {
        schedulerMethods.autoExecuteType = SchedulerAutoExecuteType.MONTH;
    }

    return schedulerMethods;
}

function notificationContent(content: string, isGet?: boolean) {
    const isEnd = endsWith(content, '拒收请回复R');
    if (isGet) {
        if (isEnd) {
            return trimEnd(trimEnd(content, '拒收请回复R'));
        }
    } else {
        if (isEnd) {
            return content;
        }
        return `${content}  拒收请回复R`;
    }
}

export const GivingCouponSchedulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/giving_coupon_schedules';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/giving_coupon_schedules/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const crowdInfos = res.crowdInfos;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    subsiteIds: res.subsites,
                    couponCrowds: {
                        mode: crowdInfos.length > 0 ? 'Crowd' : 'Member',
                        crowds: crowdInfos.length > 0 ? crowdInfos : [],
                    },
                    schedulerMethods: createSchedulerMethodsValue(res),
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };

                let selectCoupon: any = [];
                if (res.couponInfos.length > 0) {
                    forEach(res.couponInfos, (item) => {
                        selectCoupon.push({
                            couponTypeDesc: item.couponTypeDesc,
                            batchNo: item.couponNo,
                            endTime: item.endTime,
                            id: item.id,
                            name: item.name,
                            quantity: item.quantity,
                            startTime: item.startTime,
                            couponType: item.couponType,
                            sourceDesc: item.sourceDesc,
                            consumeChannelDescription: item.consumeChannelDescription,
                        });
                    });
                }
                res.couponInfo = { selectCoupon };
                res.notificatonConfig = {
                    notificationChannel: Boolean(res.notificationChannel)
                        ? {
                              channel: Boolean(res.notificationChannel),
                              content: notificationContent(res.notificationContent, true),
                              //   agreement: true,
                          }
                        : undefined,
                };
            } else {
                forEach(res.result, (item) => {
                    item.subsiteName = item.subsites;
                    if (isNull(item.executeTime)) {
                        item.executeTime = '';
                    }
                    if (isNull(item.memberGroupName)) {
                        item.memberGroupName = '';
                    }
                    if (isNull(item.executeSuccessQuantity)) {
                        item.executeSuccessQuantity = '-';
                    }
                    if (item.resourceInfo && item.resourceInfo.id) {
                        item.resourceUrl = item.resourceInfo.resourceUrl;
                    }
                    switch (item.executeStatus) {
                        case 'UN_START':
                            item.status = true;
                            item.canBegin = true;
                            item.canEdit = true;
                            item.canImport = true;
                            item.canExport = false;
                            break;
                        case 'RUNNING':
                            item.status = false;
                            item.canBegin = true;
                            item.canEdit = false;
                            item.canImport = false;
                            item.canExport = false;
                            break;
                        case 'END':
                            item.status = false;
                            item.canBegin = false;
                            item.canEdit = false;
                            item.canImport = false;
                            item.canExport = true;
                            break;
                    }
                    if (item.contraposeType === ContraposeType.AppointCrowd) {
                        item.canImport = false;
                    }
                    item.contraposeCrowd = item.contraposeType
                        ? contraposeCrowdType[item.contraposeType as ContraposeType]
                        : '';
                });
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/giving_coupon_schedules';

        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            const subsiteIds = params.baseInfo.subsiteIds.map((subsite: any) => {
                return subsite.id;
            });
            newParams.subsiteIds = subsiteIds;
            params.baseInfo.schedulerMethods &&
                schedulerMethodsValueWriteInParams(newParams, params.baseInfo.schedulerMethods);
            if (params.baseInfo.couponCrowds && params.baseInfo.couponCrowds.mode === 'Crowd') {
                newParams.crowdIds = params.baseInfo.couponCrowds.crowds.map((i: any) => i.id);
            }
        }

        const couponInfoList: any = [];
        if (
            params.couponInfo &&
            params.couponInfo.selectCoupon &&
            params.couponInfo.selectCoupon.length > 0
        ) {
            forEach(params.couponInfo.selectCoupon, (coupon) => {
                couponInfoList.push({
                    id: coupon.id,
                    quantity: coupon.quantity ? coupon.quantity : 1,
                });
            });
        }
        newParams.couponInfoList = couponInfoList;
        if (
            params.notificatonConfig &&
            params.notificatonConfig.notificationChannel &&
            params.notificatonConfig.notificationChannel.channel
        ) {
            newParams.notificationChannel = params.notificatonConfig.notificationChannel.channel
                ? 'SMS'
                : '';
            newParams.notificationContent = notificationContent(
                params.notificatonConfig.notificationChannel.content
            );
        }
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        if (params.id) {
            config.apiPath = `/admin/giving_coupon_schedules/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            const subsiteIds = params.baseInfo.subsiteIds.map((subsite: any) => {
                return subsite.id;
            });
            newParams.subsiteIds = subsiteIds;
            params.baseInfo.schedulerMethods &&
                schedulerMethodsValueWriteInParams(newParams, params.baseInfo.schedulerMethods);
            if (params.baseInfo.couponCrowds && params.baseInfo.couponCrowds.mode === 'Crowd') {
                newParams.crowdIds = params.baseInfo.couponCrowds.crowds.map((i: any) => i.id);
            }
        }

        const couponInfoList: any = [];
        if (
            params.couponInfo &&
            params.couponInfo.selectCoupon &&
            params.couponInfo.selectCoupon.length > 0
        ) {
            forEach(params.couponInfo.selectCoupon, (coupon) => {
                couponInfoList.push({
                    id: coupon.id,
                    quantity: coupon.quantity ? coupon.quantity : 1,
                });
            });
        }
        newParams.couponInfoList = couponInfoList;
        if (
            params.notificatonConfig &&
            params.notificatonConfig.notificationChannel &&
            params.notificatonConfig.notificationChannel.channel
        ) {
            newParams.notificationChannel = params.notificatonConfig.notificationChannel.channel
                ? 'SMS'
                : '';
            newParams.notificationContent = notificationContent(
                params.notificatonConfig.notificationChannel.content
            );
        }
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.put(newParams, config);
    },
};
