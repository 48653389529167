import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ArticleManageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/management_article';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/management_article/${id}`;
            data = {};
        }
        if (!id && data.businessTypeId && data.businessTypeId.length > 0) {
            data.businessTypeId = data.businessTypeId[data.businessTypeId.length - 1];
        }
        return await api.get(data, config).then((result: any) => {
            if (id) {
                result.baseInfo = {
                    subSiteIds: result.subSiteList,
                    businessType:
                        result.businessTypeIds && result.businessTypeIds.length
                            ? result.businessTypeIds.map((id: number) => id.toString())
                            : [],
                    name: result.name,
                    version: result.version,
                    dateRange:
                        result.fromTimeStr && result.thruTimeStr
                            ? {
                                  start: result.fromTimeStr,
                                  end: result.thruTimeStr,
                              }
                            : undefined,
                    sequence: result.sequence,
                    content: !result.businessTypeIds
                        .map((item: number) => item.toString())
                        .includes('20006')
                        ? result.content
                        : '',
                    contentText: result.businessTypeIds
                        .map((item: number) => item.toString())
                        .includes('20006')
                        ? result.content
                        : '',
                };
            }
            if (!result.result) {
                result.result = [];
            }
            return result;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/management_article';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/management_article';
        const newParams: any = dataParser(params);
        return await api.put({ id: params.id, ...newParams }, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const { baseInfo } = params;
    const newParams: any = {};
    newParams.name = baseInfo.name;
    newParams.subSiteIds = (baseInfo?.subSiteIds || []).map((item: any) => item.id);
    newParams.sequence = baseInfo.sequence;
    newParams.content = baseInfo.content;
    if (baseInfo.dateRange) {
        newParams.fromTimeStr = baseInfo.dateRange.start;
        newParams.thruTimeStr = baseInfo.dateRange.end;
    }
    if (baseInfo && baseInfo.businessType && baseInfo.businessType.length) {
        newParams.businessTypeId = baseInfo.businessType[baseInfo.businessType.length - 1];
        if (baseInfo.businessType.includes('20003')) {
            newParams.version = baseInfo.version;
        }
        if (baseInfo.businessType.includes('20006')) {
            newParams.content = baseInfo.contentText;
        }
    }
    return newParams;
}
