import React, { Component } from 'react';
import { Input, Select, Button, InputNumber } from 'antd';
import { clone, map, concat, isEmpty, isArray, get } from 'lodash';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

type FloorValue = {
    merchantFloorAddress?: string;
    merchantFloorId?: string;
    merchantId?: string;
    subsiteId?: string;
    sequence?: number;
    sid?: string;
};

export interface MerchantManagerFloorsProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<FloorValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<FloorValue> | null;
    /**
     * 候选项集合
     */
    options: Array<any>;
    typeTitle?: string;
    defaultValue?: string;
}
export class MerchantManagerFloors extends Component<MerchantManagerFloorsProps> {
    onMemberLevelIdChange(id: string, index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.merchantFloorId = id;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: FloorValue = {};
            item.merchantFloorId = id;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onFloorAddressChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        const merchantFloorAddress = event.target.value;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.merchantFloorAddress = merchantFloorAddress;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: FloorValue = {};
            item.merchantFloorAddress = merchantFloorAddress;
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onFloorSortChange(sequence: number | undefined, index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.sequence = sequence;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: FloorValue = {};
            item.sequence = sequence;
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onFloorSidChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        const sid = event.target.value;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.sid = sid;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: FloorValue = {};
            item.sid = sid;
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onAddFloor() {
        let { name, value, onChange } = this.props;
        if (!value || value.length === 0) {
            value = [{}];
        }
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveFloor(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value } = this.props;
        return (
            <div className="merchant-manager-floors">
                {map(value, (floor, index) => this.renderFloor(floor, index))}
                {value && value.length ? null : this.renderFloor({}, 0)}
                <Button type="link" onClick={() => this.onAddFloor()}>
                    {language.getText('createFloor')}
                </Button>
            </div>
        );
    }

    renderFloor(floor: FloorValue, index: number) {
        const { options, defaultValue } = this.props;
        const onDelete = this.onRemoveFloor.bind(this, index);
        const showSid = get(this.props, 'row.baseInfo.showSid', 'false') === 'true';
        return (
            <div className="merchant-manager-floors__row" key={index}>
                <div className="item-text">{language.getText('floors')}</div>
                <div className="item-select" style={{ width: 150 }}>
                    <Select
                        allowClear
                        placeholder={language.getText('selectPlease')}
                        value={floor?.merchantFloorId?.toString() || defaultValue}
                        onChange={(id: any) => this.onMemberLevelIdChange(id, index)}
                    >
                        {isArray(options) &&
                            map(options, ({ id, name }) => {
                                return <Select.Option key={id}>{name}</Select.Option>;
                            })}
                    </Select>
                </div>
                <div className="item-text">{language.getText('floorPosition')}</div>
                <div className="item-select">
                    <Input
                        value={floor?.merchantFloorAddress || ''}
                        onChange={(e) => this.onFloorAddressChange(e, index)}
                        maxLength={100}
                    />
                </div>
                <div className="item-text">{language.getText('positionSort')}</div>
                <div className="item-select">
                    <InputNumber
                        value={floor?.sequence}
                        min={1}
                        max={999999999}
                        onChange={(e) => this.onFloorSortChange(e, index)}
                    />
                </div>
                {showSid ? (
                    <>
                        <div className="item-text">{language.getText('indoorSid')}</div>
                        <div className="item-select">
                            <Input
                                value={floor?.sid || ''}
                                onChange={(e) => this.onFloorSidChange(e, index)}
                                maxLength={128}
                            />
                        </div>
                    </>
                ) : null}

                {index > 0 ? (
                    <Button style={{ width: 60 }} type="link" onClick={onDelete}>
                        {language.getText('common.delete')}
                    </Button>
                ) : (
                    <div style={{ width: 60 }}></div>
                )}
            </div>
        );
    }
}
