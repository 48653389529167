import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const BackInfoLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/back_info/1';
        return await api.get(data, config).then((res: any) => {
            const { loginLogoFileUrl, picDomain, frameLogoFileUrl, frameLogoId, loginLogoId } = res;
            res.loginLogo = [{ id: loginLogoId, path: `${picDomain}${loginLogoFileUrl}` }];
            res.frameLogo = [{ id: frameLogoId, path: `${picDomain}${frameLogoFileUrl}` }];
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiPath = '/admin/back_info/1';
        const newParams = {
            loginLogoId: params.loginLogo[0].id,
            frameLogoId: params.frameLogo[0].id,
            loginCopyRight: params.loginCopyRight,
            frameCopyRight: params.frameCopyRight,
        };
        return await api.put(newParams, config);
    },
};
