import React, { PureComponent } from 'react';
import { OpenBoxActivitySelectedDisplay } from './open-box-activity-selected-display';
import { OpenBoxActivitySelector } from './open-box-activity-selector';
import { cloneDeep, findIndex } from 'lodash';
import { Radio } from 'antd';
import { services } from '@comall-backend-builder/core';

interface OpenBoxActivitySelectValue {
    type: string;
    activityList: any[];
}

interface OpenBoxActivitySelectProps {
    onChange: (value: OpenBoxActivitySelectValue) => void;
    value: OpenBoxActivitySelectValue;
    row: any;
    disabled: boolean;
}

export class OpenBoxActivitySelect extends PureComponent<OpenBoxActivitySelectProps, any> {
    constructor(props: OpenBoxActivitySelectProps) {
        super(props);
        this.state = { loading: false };
    }

    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange({
                type: 'NONE',
                activityList: [],
            });
        }
    }

    /**
     * 选择活动
     * @param selectValues
     */
    onChange = (selectValues: any[]) => {
        const { onChange, value } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange({
                    type: value.type,
                    activityList: [],
                });
            } else {
                onChange({
                    type: value.type,
                    activityList: selectValues,
                });
            }
        }
    };

    /**
     * 添加活动弹窗确认后回调
     * @param selectValues 选中活动
     */
    onSelect = (selectValues: any[]) => {
        const { value } = this.props;
        let newActivityList: any[] = [];
        newActivityList = cloneDeep(value.activityList || []);
        selectValues.forEach((i: any) => {
            const hasIndex = findIndex(value.activityList, (o: any) => o.id === i.id);
            if (hasIndex === -1) {
                newActivityList.unshift(i);
            }
        });
        this.onChange(newActivityList);
    };

    onChangeType = (type: string) => {
        const { onChange } = this.props;
        onChange({
            type: type,
            activityList: [],
        });
    };

    render() {
        const { value, disabled, row } = this.props;
        if (!value) {
            return null;
        }
        const { type, activityList } = value;
        const params: any = {};
        if (row.id) {
            params.exclude_activity_ids = row.id;
        }
        return (
            <div>
                <div>
                    <Radio.Group
                        className="fill-style"
                        value={type}
                        onChange={(e: any) => {
                            this.onChangeType(e.target.value);
                        }}
                    >
                        <Radio value={'NONE'}>{services.language.getText('bszpcgz')}</Radio>
                        <Radio value={'ALL'}>{services.language.getText('qbhdjpcchd')}</Radio>
                        <Radio value={'SOME'}>{services.language.getText('bfhdpcchd')}</Radio>
                    </Radio.Group>
                </div>
                {value && value.type === 'SOME' && (
                    <div style={{ display: 'flex', margin: '15px 0 0 -70px' }}>
                        <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                            <span>{services.language.getText('xzhd')}：</span>
                        </div>
                        <div style={{ flex: 1 }}>
                            {!disabled && (
                                <OpenBoxActivitySelector
                                    onChange={this.onSelect}
                                    selectValues={activityList}
                                    params={params}
                                />
                            )}
                            <OpenBoxActivitySelectedDisplay
                                data={activityList}
                                onRemove={this.onChange}
                                disabled={disabled}
                            ></OpenBoxActivitySelectedDisplay>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
