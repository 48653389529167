import React, { PureComponent } from 'react';
import {
    Table as AntTable,
    Row as AntRow,
    Col as AntCol,
    Card as AntCard,
    Table,
    Modal,
    Button as AntButton,
} from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface OrderDetail {
    statusName: string;
    orderNo: string;
    totalQuantity: number;
    createTime: string;
    memberInfo: {
        nickName: string;
        mobile: string;
    };
    paymentInfo: {
        paymentModeTypeName: string;
        totalAmount: number;
        paymentStatusName: string;
    };
    orderItems: Array<{
        id: number;
        goods: {
            id: number;
            string: string;
            quantity: number;
            price: string;
            totalMmount: string;
        };
    }>;
    confirmOperator: Array<{
        name: string;
        time: string;
    }>;
    invoiceInfo: {
        content: string;
        typeName: string;
        invoiceCode: string;
        invoiceNumber: string;
        title: string;
        invoiceStatusName: string;
        taxNo: string;
        invoiceTypeName: string;
        titleTypeName: string;
        files: Array<{
            type: 'PICTURE' | 'PDF';
            url: string;
        }>;
        receipt: {
            name: string;
            zipCode: string;
            address: string;
            email: string;
            mobile: string;
        };
    };
    paymentRecords: Array<any>;
}

interface Props {
    params: any;
}
interface State {
    detail?: OrderDetail;
    invoiceLogs: Array<any>;
    /**
     * 订单跟踪
     */
    tracks: Array<any>;
    /**
     * 发票弹层
     */
    invoiceImgVisible: boolean;
    invoiceImg?: string;
}

export class StoredValueCardOrderInfo extends PureComponent<Props, State> {
    state: State = {
        detail: undefined,
        invoiceLogs: [],
        tracks: [],
        invoiceImgVisible: false,
        invoiceImg: '',
    };
    componentDidMount() {
        const { params } = this.props;
        //获取订单详情
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/orders/${params.id}`,
            }
        ).then((response: any) => {
            this.setState(
                {
                    detail: response,
                },
                () => {
                    this.loadInvoiceInfo();
                    this.loadOrderLogistics();
                }
            );
        });
    }

    loadOrderLogistics = () => {
        const { params } = this.props;
        api.get(
            {
                orderId: params.id,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/order_logistics`,
            }
        ).then((response: any) => {
            this.setState({
                tracks: response ? response.tracks : [],
            });
        });
    };

    loadInvoiceInfo = () => {
        const { params } = this.props;
        api.get(
            { orderNo: params.orderNo, orderType: 'STORED_VALUE_CARD', page: 1, perPage: 100 },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: `/admin/invoices/logs`,
            }
        ).then((res: any) => {
            if (res && res.result) {
                res.result = res.result.map((log: any) => {
                    log.buyerType = log.buyer.type;
                    log.buyerName = log.buyer.name;
                    log.buyerTaxNo = log.buyer.taxNo;
                    return log;
                });
                this.setState({ invoiceLogs: res.result });
            }
        });
    };

    renderBaseInfo = () => {
        const { detail } = this.state;
        if (!detail) {
            return null;
        }
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('otherBaseInfo')}>
                    <AntRow style={{ marginBottom: 10 }}>
                        <AntCol span={8}>
                            {getText('orderNumber')}：{detail.orderNo}
                        </AntCol>
                        <AntCol span={8}>
                            {getText('gksl')}：{detail.totalQuantity}
                        </AntCol>
                        <AntCol span={8}>
                            {getText('ddzt')}：{detail.statusName}
                        </AntCol>
                    </AntRow>
                    <AntRow style={{ marginBottom: 10 }}>
                        <AntCol span={8}>
                            {getText('xdrq')}：{detail.createTime}
                        </AntCol>
                        <AntCol span={8}>
                            {getText('zffs')}：{detail.paymentInfo.paymentModeTypeName}
                        </AntCol>
                        <AntCol span={8}>
                            {getText('zfzt')}：{detail.paymentInfo.paymentStatusName}
                        </AntCol>
                    </AntRow>
                    <AntRow style={{ marginBottom: 10 }}>
                        <AntCol span={8}>
                            {getText('orderAmount')}：{detail.paymentInfo.totalAmount}
                        </AntCol>
                        <AntCol span={8}>
                            {getText('hyxm')}：{detail.memberInfo.nickName}
                        </AntCol>
                        <AntCol span={8}>
                            {getText('phoneNum')}：{detail.memberInfo.mobile}
                        </AntCol>
                    </AntRow>
                </AntCard>
            </AntRow>
        );
    };

    renderInvoiceInfo = () => {
        const { detail } = this.state;
        if (!detail || !detail.invoiceInfo || !detail.invoiceInfo.invoiceTypeName) {
            return null;
        }
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={getText('fpxx')}>
                    <AntCol span={16}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fplx')}：</span>
                                <span>{detail.invoiceInfo.invoiceTypeName}</span>
                            </AntCol>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fpnr')}：</span>
                                <span>{detail.invoiceInfo.content}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('ttlx')}：</span>
                                <span>{detail.invoiceInfo.titleTypeName}</span>
                            </AntCol>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('sh_1')}：</span>
                                <span>{detail.invoiceInfo.taxNo}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('fpttmc')}：</span>
                                <span>{detail.invoiceInfo.title}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('kpzt_1')}：</span>
                                <span>{detail.invoiceInfo.invoiceStatusName}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fphm')}：</span>
                                <span>{detail.invoiceInfo.invoiceNumber}</span>
                            </AntCol>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fpdm')}：</span>
                                <span>{detail.invoiceInfo.invoiceCode}</span>
                            </AntCol>
                        </AntRow>
                    </AntCol>
                    <AntCol span={6}>
                        {detail.invoiceInfo.files &&
                            detail.invoiceInfo.files.length > 0 &&
                            detail.invoiceInfo.files.map((file) => {
                                return file.type === 'PICTURE' ? (
                                    <div style={{ marginBottom: '10px' }}>
                                        <img
                                            width="40%"
                                            alt=""
                                            src={file.url}
                                            onClick={this.handleInvoiceInfo.bind(this, file.url)}
                                        />
                                    </div>
                                ) : null;
                            })}
                    </AntCol>
                </AntCard>
            </AntRow>
        );
    };

    renderInvoiceReceiptInfo = () => {
        const { detail } = this.state;
        if (!detail || !detail.invoiceInfo || !detail.invoiceInfo.receipt) {
            return null;
        }
        return (
            <AntRow style={{ marginBottom: '20px' }}>
                <AntCard type="inner" title={getText('fpyjxx')}>
                    <AntCol span={16}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('sjr')}：</span>
                                <span>{detail.invoiceInfo.receipt.name}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('phoneNum')}：</span>
                                <span>{detail.invoiceInfo.receipt.mobile}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('yb')}：</span>
                                <span>{detail.invoiceInfo.receipt.zipCode}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">
                                    {getText('detailAddress')}：
                                </span>
                                <span>{detail.invoiceInfo.receipt.address}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('email')}：</span>
                                <span>{detail.invoiceInfo.receipt.email}</span>
                            </AntCol>
                        </AntRow>
                    </AntCol>
                </AntCard>
            </AntRow>
        );
    };

    renderGoodsInfo = () => {
        const { detail } = this.state;
        if (!detail) {
            return null;
        }
        const tableProps = {
            loading: false,
            columns: [
                {
                    title: services.language.getText('goodId'),
                    key: 'goods',
                    dataIndex: 'goods',
                    render: (goods: any) => {
                        return <span>{goods.id}</span>;
                    },
                },
                {
                    title: services.language.getText('goodName'),
                    key: 'goods',
                    dataIndex: 'goods',
                    render: (goods: any) => {
                        return <span>{goods.productName}</span>;
                    },
                },
                {
                    title: services.language.getText('dj_1'),
                    key: 'price',
                    dataIndex: 'price',
                },
                {
                    title: services.language.getText('number'),
                    key: 'quantity',
                    dataIndex: 'quantity',
                },
                {
                    title: services.language.getText('zje'),
                    key: 'totalAmount',
                    dataIndex: 'totalAmount',
                    render: (totalAmount: any) => {
                        return <span>{(+totalAmount || 0).toFixed(2)}</span>;
                    },
                },
            ],
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('hpxx')}>
                    <AntTable
                        style={{ overflowX: 'scroll' }}
                        rowKey={(record, index) => index.toString()}
                        dataSource={detail.orderItems}
                        {...tableProps}
                        pagination={false}
                    />
                </AntCard>
            </AntRow>
        );
    };

    renderInvoiceRecord = () => {
        const { invoiceLogs } = this.state;
        if (invoiceLogs.length === 0) {
            return null;
        }
        const tableProps = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('kplx'),
                    dataIndex: 'type',
                    key: 'type',
                    width: 100,
                    render: (i: string) => {
                        const typesText: any = {
                            BLUE_INVOICE: services.language.getText('blueticket'),
                            RED_INVOICE: services.language.getText('redticket'),
                        };
                        return typesText[i];
                    },
                },
                {
                    title: services.language.getText('kpzt_1'),
                    dataIndex: 'status',
                    key: 'status',
                    width: 100,
                    render: (i: string) => {
                        const invoiceStatusText: any = {
                            INVOICED: services.language.getText('ykp'),
                            CHANGED: services.language.getText('ykp'),
                            RED_FLUSHED: services.language.getText('ykp'),
                            INVOICING_FAILED: services.language.getText('kpsb'),
                            RED_FLUSHED_FAILED: services.language.getText('kpsb'),
                        };
                        return invoiceStatusText[i];
                    },
                },
                {
                    title: services.language.getText('sqsj'),
                    dataIndex: 'applyTime',
                    key: 'applyTime',
                    width: 200,
                },
                {
                    title: services.language.getText('sqlsh'),
                    dataIndex: 'serialNumber',
                    key: 'serialNumber',
                    width: 200,
                },
                {
                    title: services.language.getText('fplx'),
                    dataIndex: 'invoiceType',
                    key: 'invoiceType',
                    width: 160,
                    render: (i: string) => {
                        const invoiceTypesText: any = {
                            NORMAL: services.language.getText('zzsdzptfp'),
                            SPECIAL: services.language.getText('zzszyfp'),
                        };
                        return invoiceTypesText[i];
                    },
                },
                {
                    title: services.language.getText('fpnr'),
                    dataIndex: 'content',
                    key: 'content',
                    width: 100,
                    render: (i: string) => {
                        const contentText: any = {
                            PRODUCT_DETAIL: services.language.getText('productDetail'),
                            PRODUCT_CATEGORY: services.language.getText('productCategory'),
                        };
                        return contentText[i];
                    },
                },
                {
                    title: services.language.getText('fptt'),
                    dataIndex: 'buyerType',
                    key: 'buyerType',
                    width: 150,
                    render: (i: any) => {
                        const buyerTypeText: any = {
                            PERSONAL: services.language.getText('fqydw'),
                            COMPANY: services.language.getText('qydw'),
                        };
                        return buyerTypeText[i];
                    },
                },
                {
                    title: services.language.getText('fpttmc'),
                    dataIndex: 'buyerName',
                    key: 'buyerName',
                    width: 200,
                },
                {
                    title: services.language.getText('sh_1'),
                    dataIndex: 'buyerTaxNo',
                    key: 'buyerTaxNo',
                    width: 200,
                },
                {
                    title: services.language.getText('sdphm'),
                    dataIndex: 'invoiceNumber',
                    key: 'invoiceNumber',
                    width: 230,
                },
                {
                    title: services.language.getText('fpdm'),
                    dataIndex: 'invoiceCode',
                    key: 'invoiceCode',
                    width: 200,
                },
            ],
            scroll: {
                x: 1850,
            },
            dataSource: invoiceLogs,
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('fpjl')}>
                    <Table {...tableProps} pagination={false}></Table>
                </AntCard>
            </AntRow>
        );
    };

    renderPayInfo = () => {
        const { detail } = this.state;
        if (!detail || !detail.paymentRecords || detail.paymentRecords.length === 0) {
            return null;
        }
        const tableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText('zffs'),
                    key: 'paymentModeName',
                    dataIndex: 'paymentModeName',
                },
                {
                    title: services.language.getText('zfje'),
                    key: 'amount',
                    dataIndex: 'amount',
                },
                {
                    title: services.language.getText('zflsh'),
                    key: 'mergePaymentNo',
                    dataIndex: 'mergePaymentNo',
                },
                {
                    title: services.language.getText('sflsh'),
                    key: 'tradeNo',
                    dataIndex: 'tradeNo',
                },
            ],
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('zfxx')}>
                    <AntTable
                        style={{ overflowX: 'scroll' }}
                        rowKey={(record, index) => index.toString()}
                        dataSource={detail.paymentRecords}
                        {...tableProps}
                        pagination={false}
                    />
                </AntCard>
            </AntRow>
        );
    };

    renderOrderInfo = () => {
        const { tracks } = this.state;
        if (!tracks || tracks.length === 0) {
            return null;
        }
        const tableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText('clsj'),
                    key: 'time',
                    dataIndex: 'time',
                },
                {
                    title: services.language.getText('clxx'),
                    key: 'content',
                    dataIndex: 'content',
                },
                {
                    title: services.language.getText('lastModifiedUser'),
                    key: 'operatorName',
                    dataIndex: 'operatorName',
                },
            ],
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('ddgz')}>
                    <AntTable
                        style={{ overflowX: 'scroll' }}
                        rowKey={(record, index) => index.toString()}
                        dataSource={tracks}
                        {...tableProps}
                        pagination={false}
                    />
                </AntCard>
            </AntRow>
        );
    };

    handleInvoiceInfo = (img?: string) => {
        const { invoiceImgVisible } = this.state;
        this.setState({
            invoiceImgVisible: !invoiceImgVisible,
            invoiceImg: img,
        });
    };

    renderInvoiceInfoModal = () => {
        const { detail, invoiceImgVisible, invoiceImg } = this.state;
        if (
            !detail ||
            !detail.invoiceInfo ||
            !detail.invoiceInfo.files ||
            detail.invoiceInfo.files.length === 0
        ) {
            return null;
        }
        const onCancel = this.handleInvoiceInfo.bind(this, '');
        return (
            <Modal
                title={services.language.getText('fp')}
                visible={invoiceImgVisible}
                width={700}
                onCancel={onCancel}
                footer={[
                    <AntButton key="submit" type="primary" onClick={onCancel}>
                        {services.language.getText('gb')}
                    </AntButton>,
                ]}
            >
                <div>
                    <img width="100%" alt="" src={invoiceImg} />
                </div>
            </Modal>
        );
    };

    render() {
        const { detail } = this.state;
        if (!detail) {
            return null;
        }
        return (
            <div className="stored-value-card-order-info">
                {this.renderBaseInfo()}
                {this.renderGoodsInfo()}
                {this.renderPayInfo()}
                {this.renderOrderInfo()}
                {this.renderInvoiceInfo()}
                {this.renderInvoiceReceiptInfo()}
                {this.renderInvoiceRecord()}
                {this.renderInvoiceInfoModal()}
            </div>
        );
    }
}
