import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { defaults } from 'lodash';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage, Tabs } from 'antd';
import './index.less';
const api = services.api;
interface MaotaiPresaleLimitRuleState {
    visible: boolean;
    limitRuleMode: LimitRuleMode;
}

/**
 * 限购规则类型
 */
enum LimitRuleMode {
    //飞天茅台
    SINGLE = 'SINGLE',
    //茅台系列酒
    MULTIPLE = 'MULTIPLE',
    //其他
    OTHER = 'OTHER',
}

/**
 * 设置限购规则
 */
class maotaiPresaleLimitRule extends PureComponent<any, any> {
    state: MaotaiPresaleLimitRuleState = {
        visible: false,
        limitRuleMode: LimitRuleMode.SINGLE,
    };

    componentDidMount() {
        const { entity } = this.props;
        entity.get({});
    }

    onSubmit = () => {
        const { getForm } = this.props;

        const form = getForm();
        form.validateFields(
            [
                'singleLimitSubsite',
                'singlePrizeCondition',
                'singlePrizeQualityLimit',
                'singleDescription',
                'multipleLimitSubsite',
                'multiplePrizeCondition',
                'multiplePrizeQualityLimit',
                'multipleDescription',
                'otherPrizeCondition',
                'otherPrizeQualityLimit',
                'otherDescription',
            ],
            { force: true }
        ).then((entityFields: any) => {
            if (entityFields) {
                let params: any = { rules: [] };
                if (
                    (entityFields.singleLimitSubsite && entityFields.singleLimitSubsite.length) ||
                    (entityFields.singlePrizeCondition &&
                        entityFields.singlePrizeCondition.length) ||
                    (entityFields.singlePrizeQualityLimit &&
                        entityFields.singlePrizeQualityLimit.length) ||
                    entityFields.singleDescription
                ) {
                    let singleInfo: any = {
                        mode: LimitRuleMode.SINGLE,
                        description: entityFields.singleDescription,
                    };
                    let flag = true;

                    entityFields.singlePrizeCondition.forEach((item: any) => {
                        if (item.conditionType === 'PRIZE' && item.checked) {
                            if (!item.day) {
                                flag = false;
                                return;
                            }
                            singleInfo.lastPrizeDays = item.day;
                        }
                        if (item.conditionType === 'CONSUME' && item.checked) {
                            if (!item.day) {
                                flag = false;
                            }
                            singleInfo.lastOffDays = item.day;
                        }
                    });
                    if (!flag) {
                        AntMessage.error(services.language.getText('qwztxyqxdsgtjyxzdsgsl'));
                        return;
                    }
                    entityFields.singlePrizeQualityLimit.forEach((item: any) => {
                        if (item.conditionType === 'LIMIT1' && item.checked) {
                            if (!item.dateRange || !item.dateRange.length || !item.num) {
                                flag = false;
                                return;
                            }
                            singleInfo.limitStartTime = item.dateRange[0];
                            singleInfo.limitEndTime = item.dateRange[1];
                            singleInfo.limitQuantity = item.num;
                        }
                        if (item.conditionType === 'LIMIT2' && item.checked) {
                            if (!item.num) {
                                flag = false;
                                return;
                            }
                            singleInfo.monthLimitQuantity = item.num;
                        }
                    });

                    if (!flag) {
                        AntMessage.error(services.language.getText('qwztxyqxdsgtjyxzdsgsl'));
                        return;
                    }
                    if (
                        singleInfo.limitQuantity &&
                        singleInfo.monthLimitQuantity &&
                        singleInfo.monthLimitQuantity > singleInfo.limitQuantity
                    ) {
                        AntMessage.error(services.language.getText('myxgsly'));
                        return;
                    }
                    const limitSubsite = entityFields.singleLimitSubsite.includes('true')
                        ? '1'
                        : '0';
                    singleInfo.limitSubsite = limitSubsite;
                    params.rules.push(singleInfo);
                }
                if (
                    (entityFields.multipleLimitSubsite &&
                        entityFields.multipleLimitSubsite.length) ||
                    (entityFields.multiplePrizeCondition &&
                        entityFields.multiplePrizeCondition.length) ||
                    (entityFields.multiplePrizeQualityLimit &&
                        entityFields.multiplePrizeQualityLimit.length) ||
                    entityFields.multipleDescription
                ) {
                    let multipleInfo: any = {
                        mode: LimitRuleMode.MULTIPLE,
                        description: entityFields.multipleDescription,
                    };
                    let flag = true;
                    entityFields.multiplePrizeCondition.forEach((item: any) => {
                        if (item.conditionType === 'PRIZE' && item.checked) {
                            if (!item.day) {
                                flag = false;
                                return;
                            }
                            multipleInfo.lastPrizeDays = item.day;
                        }
                        if (item.conditionType === 'CONSUME' && item.checked) {
                            if (!item.day) {
                                flag = false;
                                return;
                            }
                            multipleInfo.lastOffDays = item.day;
                        }
                    });
                    if (!flag) {
                        AntMessage.error(services.language.getText('qwztxyqxdsgtjyxzdsgsl'));
                        return;
                    }
                    entityFields.multiplePrizeQualityLimit.forEach((item: any) => {
                        if (item.conditionType === 'LIMIT1' && item.checked) {
                            if (!item.dateRange || !item.dateRange.length || !item.num) {
                                flag = false;
                                return;
                            }
                            multipleInfo.limitStartTime = item.dateRange[0];
                            multipleInfo.limitEndTime = item.dateRange[1];
                            multipleInfo.limitQuantity = item.num;
                        }
                        if (item.conditionType === 'LIMIT2' && item.checked) {
                            if (!item.num) {
                                flag = false;
                                return;
                            }
                            multipleInfo.monthLimitQuantity = item.num;
                        }
                    });
                    if (!flag) {
                        AntMessage.error(services.language.getText('qwztxyqxdsgtjyxzdsgsl'));
                        return;
                    }
                    if (
                        multipleInfo.limitQuantity &&
                        multipleInfo.monthLimitQuantity &&
                        multipleInfo.monthLimitQuantity > multipleInfo.limitQuantity
                    ) {
                        AntMessage.error(services.language.getText('myxgsly'));
                        return;
                    }
                    const limitSubsite = entityFields.multipleLimitSubsite.includes('true')
                        ? '1'
                        : '0';
                    multipleInfo.limitSubsite = limitSubsite;
                    params.rules.push(multipleInfo);
                }
                if (
                    (entityFields.otherPrizeCondition && entityFields.otherPrizeCondition.length) ||
                    (entityFields.otherPrizeQualityLimit &&
                        entityFields.otherPrizeQualityLimit.length) ||
                    entityFields.otherDescription
                ) {
                    let otherInfo: any = {
                        mode: LimitRuleMode.OTHER,
                        description: entityFields.otherDescription,
                    };
                    let flag = true;
                    entityFields.otherPrizeCondition.forEach((item: any) => {
                        if (item.conditionType === 'PRIZE' && item.checked) {
                            if (!item.day) {
                                flag = false;
                                return;
                            }
                            otherInfo.lastPrizeDays = item.day;
                        }
                        if (item.conditionType === 'CONSUME' && item.checked) {
                            if (!item.day) {
                                flag = false;
                                return;
                            }
                            otherInfo.lastOffDays = item.day;
                        }
                    });
                    if (!flag) {
                        AntMessage.error(services.language.getText('qwztxyqxdsgtjyxzdsgsl'));
                        return;
                    }
                    entityFields.otherPrizeQualityLimit.forEach((item: any) => {
                        if (item.conditionType === 'LIMIT1' && item.checked) {
                            if (!item.dateRange || !item.dateRange.length || !item.num) {
                                flag = false;
                                return;
                            }
                            otherInfo.limitStartTime = item.dateRange[0];
                            otherInfo.limitEndTime = item.dateRange[1];
                            otherInfo.limitQuantity = item.num;
                        }
                        if (item.conditionType === 'LIMIT2' && item.checked) {
                            if (!item.num) {
                                flag = false;
                                return;
                            }
                            otherInfo.monthLimitQuantity = item.num;
                        }
                    });
                    if (!flag) {
                        AntMessage.error(services.language.getText('qwztxyqxdsgtjyxzdsgsl'));
                        return;
                    }
                    if (
                        otherInfo.limitQuantity &&
                        otherInfo.monthLimitQuantity &&
                        otherInfo.monthLimitQuantity > otherInfo.limitQuantity
                    ) {
                        AntMessage.error(services.language.getText('myxgsly'));
                        return;
                    }
                    params.rules.push(otherInfo);
                }

                api.post(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                    apiPath: '/admin/rule',
                }).then(
                    () => {
                        AntMessage.success(services.language.getText('settingSucess'));
                        //设置成功后重新获取数据
                        const { entity } = this.props;
                        entity.get({});
                        this.toggleModal();
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                );
            }
        });

        return;
    };

    renderModal = () => {
        const { wrappedComponentRef, entity } = this.props;
        const { limitRuleMode } = this.state;

        let formProps = {
            entity,
            loaderType: 'get',
            wrappedComponentRef: wrappedComponentRef,
            componentId: 'maotaiPresaleLimitRule',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 16,
            style: { width: 850 },
            fields: [
                {
                    property: 'singleLimitSubsite',
                    className:
                        limitRuleMode !== LimitRuleMode.SINGLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'singlePrizeCondition',
                    className:
                        limitRuleMode !== LimitRuleMode.SINGLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'singlePrizeQualityLimit',
                    className:
                        limitRuleMode !== LimitRuleMode.SINGLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'singleDescription',
                    className:
                        limitRuleMode !== LimitRuleMode.SINGLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'multipleLimitSubsite',
                    className:
                        limitRuleMode !== LimitRuleMode.MULTIPLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'multiplePrizeCondition',
                    className:
                        limitRuleMode !== LimitRuleMode.MULTIPLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'multiplePrizeQualityLimit',
                    className:
                        limitRuleMode !== LimitRuleMode.MULTIPLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'multipleDescription',
                    className:
                        limitRuleMode !== LimitRuleMode.MULTIPLE
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'otherPrizeCondition',
                    className:
                        limitRuleMode !== LimitRuleMode.OTHER
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'otherPrizeQualityLimit',
                    className:
                        limitRuleMode !== LimitRuleMode.OTHER
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
                {
                    property: 'otherDescription',
                    className:
                        limitRuleMode !== LimitRuleMode.OTHER
                            ? 'maotai-presale-limit-rule-hidden'
                            : '',
                },
            ],
            submit: false,
        };

        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    };

    toggleModal = () => {
        const { visible } = this.state;

        setTimeout(() => {
            this.setState({
                visible: !visible,
            });
        }, 100);
    };

    changeLimitRuleMode = (limitRuleMode: string) => {
        this.setState({ limitRuleMode });
    };

    render() {
        const { visible, limitRuleMode } = this.state;
        const modalProps = {
            width: 950,
            title: services.language.getText('szxggz'),
            visible: this.state.visible,
            onCancel: this.toggleModal,
            onOk: this.onSubmit,
        };

        return (
            <div className="maotai-presale-limit-rule" style={{ display: 'inline-block' }}>
                <Button type="primary" style={{ marginRight: '10px' }} onClick={this.toggleModal}>
                    {services.language.getText('szxggz')}
                </Button>
                {visible ? (
                    <Modal {...modalProps}>
                        <Tabs
                            activeKey={limitRuleMode}
                            onTabClick={this.changeLimitRuleMode}
                            animated={false}
                        >
                            <Tabs.TabPane
                                tab={services.language.getText('ftmt')}
                                key={LimitRuleMode.SINGLE}
                            ></Tabs.TabPane>
                            <Tabs.TabPane
                                tab={services.language.getText('mtxlj')}
                                key={LimitRuleMode.MULTIPLE}
                            ></Tabs.TabPane>
                            <Tabs.TabPane
                                tab={services.language.getText('other')}
                                key={LimitRuleMode.OTHER}
                            ></Tabs.TabPane>
                        </Tabs>
                        <div className="maotai-presale-limit-rule-modal-form">
                            {this.renderModal()}
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const MaotaiPresaleLimitRule = connect(
    mapStateToProps,
    mapDispatchToProps
)(maotaiPresaleLimitRule);
