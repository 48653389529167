import React, { Component } from 'react';
import { Radio, Select, InputNumber } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface GoldClearRuleState {
    yearOptions: number[];
    monthOptins: number[];
    dayOptins: number[];
    maxGraduallyRuleValue: number;
}

enum GoldClearRuleValueType {
    /**
     * 永久有效
     */
    EVER_VALID = 'EVER_VALID',
    /**
     * 固定清零
     */
    FIXED_CLEAR = 'FIXED_CLEAR',
    /**
     * 逐步清零
     */
    GRADUALLY_CLEAR = 'GRADUALLY_CLEAR',
}

enum graduallyRuleUnit {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    DAY = 'DAY',
}

interface GoldClearRuleValue {
    type: GoldClearRuleValueType;
    fixedRule?: {
        yearDelay: number;
        month: number;
        day: number;
    };
    graduallyRule?: {
        dateType: graduallyRuleUnit;
        dateValue?: number;
    };
}

interface GoldClearRuleProps {
    onChange: (value: GoldClearRuleValue) => void;
    value: GoldClearRuleValue;
    disabled: boolean;
    row: any;
}

const guaduallyRuleLimit = {
    [graduallyRuleUnit.YEAR]: 10,
    [graduallyRuleUnit.MONTH]: 999,
    [graduallyRuleUnit.DAY]: 9999,
};

export class GoldClearRule extends Component<GoldClearRuleProps, GoldClearRuleState> {
    constructor(props: GoldClearRuleProps) {
        super(props);
        this.state = {
            yearOptions: Array.from({ length: 10 }, (v, k) => k + 1),
            monthOptins: Array.from({ length: 12 }, (v, k) => k + 1),
            dayOptins: [],
            maxGraduallyRuleValue: 10,
        };
    }
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defaultValue = {
                type: GoldClearRuleValueType.EVER_VALID,
                graduallyRule: { dateType: graduallyRuleUnit.YEAR },
            };
            onChange && onChange(defaultValue);
        }
    }

    componentWillReceiveProps(nextProps: GoldClearRuleProps) {
        const { value: nextValue } = nextProps;
        if (!!nextValue) {
            const { graduallyRule } = nextValue;
            if (!graduallyRule || !graduallyRule.dateType) {
                this.changeValue({
                    ...nextValue,
                    graduallyRule: Object.assign({}, graduallyRule, {
                        dateType: graduallyRuleUnit.YEAR,
                    }),
                });
            }
        }
    }

    onChageType = (e: any) => {
        const type = e.target.value;
        this.changeValue({ type });
    };

    onSelectYearDelay = (yearDelay: number) => {
        const { value } = this.props;
        const fixedRule = Object.assign({}, value.fixedRule, { yearDelay });
        this.changeValue({ fixedRule });
    };

    onSelectMonth = (month: number) => {
        const { value } = this.props;
        const day = this.handleDayOptions(month);
        const fixedRule = Object.assign({}, value.fixedRule, { month, day });
        this.changeValue({ fixedRule });
    };
    onSelectDay = (day: number) => {
        const { value } = this.props;
        const fixedRule = Object.assign({}, value.fixedRule, { day });
        this.changeValue({ fixedRule });
    };

    handleDayOptions = (month: number) => {
        const bigger = new Set([1, 3, 5, 7, 8, 10, 12]);
        const normal = new Set([4, 6, 9, 11]);
        let newDayOptins: number[] = [];
        if (bigger.has(month)) {
            newDayOptins = Array.from({ length: 31 }, (v, k) => k + 1);
        } else if (normal.has(month)) {
            newDayOptins = Array.from({ length: 30 }, (v, k) => k + 1);
        } else {
            newDayOptins = Array.from({ length: 28 }, (v, k) => k + 1);
        }
        const { dayOptins } = this.state;
        const {
            value: { fixedRule },
        } = this.props;
        let newDayVlue: number | undefined;
        if (fixedRule && fixedRule.day && dayOptins.length > 0) {
            if (!new Set(newDayOptins).has(fixedRule.day)) {
                newDayVlue = newDayOptins.pop();
            } else {
                newDayVlue = fixedRule.day;
            }
        }
        this.setState({ dayOptins: newDayOptins });
        return newDayVlue;
    };

    onInputGradually = (graduallyRuleValue: any) => {
        const { value } = this.props;
        const graduallyRule = Object.assign({}, value.graduallyRule, {
            dateValue: graduallyRuleValue,
        });
        this.changeValue({ graduallyRule });
    };

    onSelectGraduallyUnit = (dateType: graduallyRuleUnit) => {
        const { value } = this.props;
        const graduallyRule = Object.assign({}, value.graduallyRule, { dateType });
        this.changeValue({ graduallyRule });
    };

    changeValue = (obj: object) => {
        const { value, onChange } = this.props;
        const newValue = Object.assign({}, value, obj);
        onChange && onChange(newValue);
    };

    renderFixedClear = () => {
        const { yearOptions, monthOptins, dayOptins } = this.state;
        const { value } = this.props;
        const fixedRule = value && value.fixedRule;
        return (
            <span>
                <span>{language.getText('mnffdsyjbz')}</span>
                <Select
                    value={fixedRule && fixedRule.yearDelay}
                    onChange={this.onSelectYearDelay}
                    className="selecter"
                >
                    {yearOptions.map((year) => (
                        <Select.Option value={year}>{year}</Select.Option>
                    ))}
                </Select>
                <span>{language.getText('nhd')}</span>
                <Select
                    value={fixedRule && fixedRule.month}
                    onChange={this.onSelectMonth}
                    className="selecter"
                >
                    {monthOptins.map((month) => (
                        <Select.Option value={month}>{month}</Select.Option>
                    ))}
                </Select>
                <span>{language.getText('y_4')}</span>
                <Select
                    value={fixedRule && fixedRule.day}
                    onChange={this.onSelectDay}
                    className="selecter"
                >
                    {dayOptins.map((day) => (
                        <Select.Option value={day}>{day}</Select.Option>
                    ))}
                </Select>
                <span>{language.getText('rsx_1')}</span>
            </span>
        );
    };

    renderGraduallyClear = () => {
        const { value } = this.props;
        const graduallyRule = value && value.graduallyRule;
        const dateType = graduallyRule && graduallyRule.dateType;
        const max = dateType ? guaduallyRuleLimit[dateType] || 10 : 10;
        return (
            <span>
                <span>{language.getText('mbjbzhd')}</span>
                <InputNumber
                    min={1}
                    max={max}
                    precision={0}
                    value={graduallyRule && graduallyRule.dateValue}
                    className="number-input"
                    onChange={this.onInputGradually}
                />
                <Select
                    onChange={this.onSelectGraduallyUnit}
                    value={graduallyRule && graduallyRule.dateType}
                    className="input-select"
                >
                    <Select.Option value={graduallyRuleUnit.YEAR}>
                        {language.getText('n_1')}
                    </Select.Option>
                    <Select.Option value={graduallyRuleUnit.MONTH}>
                        {language.getText('y_4')}
                    </Select.Option>
                    <Select.Option value={graduallyRuleUnit.DAY}>
                        {language.getText('r_3')}
                    </Select.Option>
                </Select>
                <span>{language.getText('failureAfter')}</span>
            </span>
        );
    };

    render() {
        const { value = { type: GoldClearRuleValueType.EVER_VALID } } = this.props;
        return (
            <div className="gold-clear-rule">
                <Radio.Group value={value.type} onChange={this.onChageType}>
                    <Radio value={GoldClearRuleValueType.EVER_VALID}>
                        {language.getText('yjyx')}
                    </Radio>
                    <div className="tips">{language.getText('jjbyjyx')}</div>
                    <Radio value={GoldClearRuleValueType.FIXED_CLEAR}>
                        {this.renderFixedClear()}
                    </Radio>
                    <div className="tips">{language.getText('goldClearRuleTip1')}</div>
                    <Radio value={GoldClearRuleValueType.GRADUALLY_CLEAR}>
                        {this.renderGraduallyClear()}
                    </Radio>
                    <div className="tips">{language.getText('goldClearRuleTip2')}</div>
                </Radio.Group>
            </div>
        );
    }
}
