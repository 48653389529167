import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { WechatCardColor } from './wechat-card-color';

export class WechatCardColorMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <WechatCardColor {...displayInfo} />;
    }
}
