import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

enum channelType {
    /**
     * 全部
     */
    ALL = 'ALL',

    /**
     * 扫码积分
     */
    SCAN = 'SCAN',
    /**
     * 拍照积分
     */
    PHOTO = 'PHOTO',
}
export const config: Config = {
    entities: {
        selfCreditingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/selfCrediting',
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<jfhqqd>>',
                    options: [
                        { id: channelType.ALL, name: '<<all>>' },
                        { id: channelType.PHOTO, name: '<<pzjf>>' },
                        { id: channelType.SCAN, name: '<<smjf>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                method: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<hqjfqd>>',
                    options: [
                        { id: channelType.PHOTO, name: '<<pzjf>>' },
                        { id: channelType.SCAN, name: '<<smjf>>' },
                    ],
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                description: {
                    type: 'string.richTextPlus',
                    displayName: '<<zzjfzn>>',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    rules: [{ required: true, max: 20000, message: '<<notMoreThan2000>>' }],
                },
                status: {
                    type: 'string',
                    displayName: '<<status>>',
                },
                ruleConfig: {
                    type: 'object.subForm',
                    displayName: '<<zzjfgzsz>>',
                    properties: {
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<zzjfzn>>',
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 20000,
                                    message: '<<l_notMoreThan2000_3>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) return false;
                                        const reg = /<\/?.+?\/?>/g;
                                        const filterString = value.replace(reg, '').trim();
                                        if (filterString || value.indexOf('<img') > 0) {
                                            return true;
                                        }
                                        return false;
                                    },
                                },
                            ],
                        },
                        subsites: {
                            type: 'array.options.autoComplete',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [{ required: true }],
                        },
                        type: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<hqjfqd>>',
                            defaultValue: [],
                            options: [
                                {
                                    id: channelType.SCAN,
                                    name: '<<smjf>>',
                                },
                                {
                                    id: channelType.PHOTO,
                                    name: '<<pzjf>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
            },
        },
    },
    components: {
        selfCreditingView: {
            component: 'Viewport',
            entity: 'selfCreditingEntity',
        },
        selfCreditingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'selfCreditingEntity',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'selfCreditingFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzpz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/self-crediting-setting/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'selfCreditingTable' },
            ],
        },
        selfCreditingFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'channel',
                },
            ],
        },
        selfCreditingTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'method',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'description',
                    displayConfig: {
                        style: {
                            maxWidth: 500,
                            height: 80,
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLE' },
                                    value: 'ENABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                                    apiPath: '/admin/self_crediting/:id/status',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>？',
                                        content: '<<gbhqtymzsjsdyx>>',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: 'ENABLE' },
                                    value: 'DISABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                                    apiPath: '/admin/self_crediting/:id/status',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>?',
                                    },
                                },
                            ],
                        },
                    },

                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/self-crediting-setting/edit/{{row.id}}',
                    },
                ],
            },
        },
        selfCreditingAddPage: {
            component: 'Card',
            content: { component: 'selfCreditingAddForm' },
        },
        selfCreditingAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 720 },
            entity: 'selfCreditingEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'ruleConfig.subsites' },
                { property: 'ruleConfig.type' },
                { property: 'ruleConfig.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        selfCreditingEditPage: {
            component: 'Card',
            content: { component: 'selfCreditingEditForm' },
        },
        selfCreditingEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            style: { width: 720 },
            entity: 'selfCreditingEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'ruleConfig.subsites' },
                { property: 'ruleConfig.type' },
                { property: 'ruleConfig.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/self-crediting-setting',
                    component: 'selfCreditingView',
                    breadcrumbName: '<<components.PageDesign.pageSetting>>',
                    privilege: {
                        path: 'selfCrediting',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<xzymsz>>',
                            component: 'selfCreditingAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<bjymsz>>',
                            component: 'selfCreditingEditPage',
                        },
                        { path: '/', component: 'selfCreditingPage' },
                    ],
                },
            ],
        },
    ],
};
