import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        PlatformPageView: {
            component: 'Viewport',
            entity: 'PlatformPageEntity',
        },
        PlatformPageDesign: {
            component: 'Card',
            content: {
                component: 'PageDesign',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/pages/:type/:id',
                type: 'PLATFORM_PAGE',
            },
        },
        PlatformPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',

            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'FilterForm',
                            direction: 'inline',
                            submit: {
                                text: '<<common.search>>',
                                style: {
                                    marginRight: 16,
                                },
                            },
                            reset: {
                                text: '<<common.reset>>',
                            },
                            fields: [
                                {
                                    property: 'name',
                                },
                            ],
                        },
                        {
                            component: 'Button',
                            text: '<<components.PageManage.createPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/platform-page/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'PlatformPageTable',
                },
            ],
        },
        PlatformPageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'channels',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
                {
                    property: 'enabled',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: false,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'enabled',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: true,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'disable',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<components.PageManage.setting>>',
                        path: '/platform-page/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        config: {
                            url: `/design-editor/?type=PLATFORM_PAGE&id={{row.id}}&channels={{row.channels}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/platform-page/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/platform-page/log/{{row.id}}/DESIGN_PAGE',
                    },

                    {
                        type: 'delete',
                        text: '<<components.PageManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        PlatformPageAdd: {
            component: 'Card',
            title: '<<components.PageManage.createPage>>',
            entities: [{ name: 'PlatformPageEntity', entityName: 'PlatformPageEntity' }],
            content: {
                component: 'PlatformPageAddForm',
            },
        },
        PlatformPageAddForm: {
            component: 'CreateForm',
            entity: 'PlatformPageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'channels',
                    controlConfig: {
                        isAdd: true,
                    },
                },
                // {
                //     property: 'enabled',
                // },
                {
                    property: 'template',
                    controlConfig: {
                        pageType: 'platform',
                        applicableChannels: 'NEW_CLIENT',
                    },
                },
            ],
            labelCol: 8,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/platform-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
        PlatformPageEdit: {
            component: 'Card',
            title: '<<components.PageManage.setPage>>',
            content: {
                component: 'PlatformPageEditForm',
            },
        },
        PlatformPageEditForm: {
            component: 'ModifyForm',
            entity: 'PlatformPageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'channels',
                    controlConfig: {
                        disabled: true,
                    },
                },
                // {
                //     property: 'enabled',
                // },
                {
                    property: 'template',
                    controlConfig: {
                        pageType: 'platform',
                        applicableChannels: 'NEW_CLIENT',
                    },
                },
            ],
            loaderType: 'get',
            labelCol: 8,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/platform-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
        PlatformPageCopy: {
            component: 'Card',
            title: '<<components.PageManage.createPage>>',
            entities: [{ name: 'PlatformPageEntity', entityName: 'PlatformPageEntity' }],
            content: {
                component: 'PlatformPageCopyForm',
            },
        },
        PlatformPageCopyForm: {
            component: 'CopyFormPlus',
            entity: 'PlatformPageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'channels',
                },
                // {
                //     property: 'enabled',
                // },
                {
                    property: 'template',
                    controlConfig: {
                        pageType: 'platform',
                        applicableChannels: 'NEW_CLIENT',
                    },
                },
            ],
            loaderType: 'get',
            labelCol: 8,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/platform-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },

        PlatformLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/platform-page',
                    component: 'PlatformPageView',
                    breadcrumbName: '<<platformPage>>',
                    privilege: {
                        path: 'platformPage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'PlatformPageDesign',
                        },
                        {
                            path: '/add',
                            component: 'PlatformPageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'PlatformPageEdit',
                        },
                        {
                            path: '/copy/:id',
                            component: 'PlatformPageCopy',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'PlatformLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'PlatformPage',
                        },
                    ],
                },
            ],
        },
    ],
};
