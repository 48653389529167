import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { EntityButtonProps } from '../../../../components';
import { ForwardDataCenterModal } from '../../../../services';
import { services } from '@comall-backend-builder/core';

let todayTime: any = new Date();
todayTime =
    todayTime.getFullYear() +
    '-' +
    (todayTime.getMonth() + 1 > 9 ? todayTime.getMonth() + 1 : '0' + (todayTime.getMonth() + 1)) +
    '-' +
    (todayTime.getDate() > 9 ? todayTime.getDate() : '0' + todayTime.getDate());

export const config: Config = {
    entities: {
        miniProgramChargesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/miniProgramCharges',
            filters: {
                subsites: {
                    displayName: '<<subsiteName>>',
                    type: 'array.options.autoComplete',
                    className: 'custom-array-options',
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                        style: {
                            width: 250,
                        },
                    },
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                projectName: {
                    type: 'string.text.trim',
                    displayName: '<<xmmc>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrxmmc>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sksj>>',
                    defaultValue: { start: todayTime, end: todayTime },
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },

                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                projectName: {
                    type: 'string',
                    displayName: '<<xmmc>>',
                },
                paidAmount: {
                    type: 'number.price',
                    displayName: '<<wxzf>>',
                },
                paidPointAmount: {
                    type: 'string',
                    displayName: '<<jfzf>>',
                },
            },
        },
    },
    components: {
        miniProgramChargesView: {
            component: 'Viewport',
        },

        miniProgramChargesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            entity: 'miniProgramChargesEntity',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<bbsj>>',
                            content: {
                                component: 'FlexLayout',
                                direction: 'vertical',
                                items: [
                                    {
                                        component: 'miniProgramChargesContent',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
        miniProgramChargesContent: {
            component: 'FlexLayout',
            entity: 'miniProgramChargesEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'miniProgramChargesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<dcsj_1>>',
                                        style: { float: 'left' },
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params: any = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );

                                            if (params.dateRange) {
                                                params.startTime =
                                                    params.dateRange.start + ' 00:00:00';
                                                params.endTime = params.dateRange.end + ' 23:59:59';
                                                delete params.dateRange;
                                            }
                                            if (!params.dateRange) {
                                                delete params.dateRange;
                                            }
                                            if (params.subsites && params.subsites.length) {
                                                params.subsiteIds = params.subsites
                                                    .map((item: any) => item.id)
                                                    .join(',');
                                                delete params.subsites;
                                            }
                                            if (!params.projectName) {
                                                delete params.projectName;
                                            }

                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                                apiPath:
                                                    '/admin/coupon_dashboard/mini_program_charges/export',
                                            };

                                            api.get(params, config)
                                                .then(() => {
                                                    ForwardDataCenterModal();
                                                })
                                                .catch((error) => {
                                                    errorHandle(error);
                                                });
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'miniProgramChargesTable',
                },
            ],
        },
        miniProgramChargesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsites',
                },
                {
                    property: 'projectName',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        miniProgramChargesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'projectName',
                },
                {
                    property: 'paidAmount',
                },
                {
                    property: 'paidPointAmount',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mini-program-charges-dashboard',
                    component: 'miniProgramChargesView',
                    breadcrumbName: '<<miniProgramChargesDashboard>>',
                    privilege: {
                        path: 'miniProgramChargesDashboard',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'miniProgramChargesPage',
                        },
                    ],
                },
            ],
        },
    ],
};
