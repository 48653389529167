import { ArrayFormat } from '@comall-backend-builder/types';

export class ArrayExtendAttributes extends ArrayFormat {
    /**
     * 对数据进行校验
     */
    public validate(_rule: any, _value: any, callback: any) {
        callback();
    }
}
