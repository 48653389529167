import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { cloneDeep, get } from 'lodash';

interface hotWordItem {
    /**
     * 板块
     */
    applicationType: string;
    plate: string;
    /**
     * 页面名称
     */
    guidedName: string;
    pageName: string;
}

enum PageName {
    /**
     * 内容板块
     */
    SHOW = 'SHOW',
    /**
     * 电商板块
     */
    SHOP = 'SHOP',
}

const api = services.api;
export const HotWordsSearchLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id, status } = data;
        if (status && status === 'ALL') {
            delete data.status;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/hot_word';
        if (id) {
            config.apiPath += `/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.config = JSON.parse(res.config);
                res.selectMerchantGuide = get(res, 'guideVo.id');
                res.selectedSubsites = res.subsite;
            } else {
                res.result = res.result.map((item: hotWordItem) => {
                    return {
                        ...item,
                        plate: item.applicationType === PageName.SHOW ? '内容板块' : '电商板块',
                        pageName: item.guidedName,
                    };
                });
            }
            return res;
        });
    },

    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/hot_word/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/hot_word';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = cloneDeep(params);
    newParams.subsiteId = newParams.selectedSubsites.map((subsite: any) => {
        return Number(subsite.id);
    });
    newParams.merchantGuideId = newParams.selectMerchantGuide;
    newParams.config = JSON.stringify(newParams.config);

    delete newParams.selectedSubsites;
    delete newParams.selectMerchantGuide;
    return newParams;
}
