import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { get } from 'lodash';

import { TipsModalContentType } from '../../../components';
const {
    privilege,
    language: { getText },
} = services;

export enum SecurityCode {
    // 用户名密码模式
    USERNAME_PASSWORD = 'USERNAME_PASSWORD',
    // 移动登录模式
    PHONE_VERIFICATION_CODE = 'PHONE_VERIFICATION_CODE',
    // 移动或密码模式
    PHONE_OR_PASSWORD = 'PHONE_OR_PASSWORD',
    // 移动和密码模式
    PHONE_AND_PASSWORD = 'PHONE_AND_PASSWORD',
}

export const config: Config = {
    entities: {
        SecurityConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
            apiPath: '/loader/admin/security_config',
            properties: {
                limitRule: {
                    type: 'object.subForm',
                    displayName: '<<securityConfig>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                loginMode: {
                    type: 'array.checkboxWithModalTip',
                    displayName: '<<schtzhdlfs>>',
                    defaultValue: [
                        SecurityCode.USERNAME_PASSWORD,
                        SecurityCode.PHONE_VERIFICATION_CODE,
                    ],
                    rules: [{ required: true, message: '<<qxzschtzhdlfs>>' }],
                    options: [
                        {
                            name: '<<syzhmmdl>>',
                            id: SecurityCode.USERNAME_PASSWORD,
                        },
                        {
                            name: '<<sysjhyzmdl>>',
                            id: SecurityCode.PHONE_VERIFICATION_CODE,
                        },
                    ],
                    controlConfig: {
                        tipsModalConfig: {
                            type: 'extra',
                            extraConfig: {
                                before: '<<btddlfshdddlysbyz>>',
                            },
                            contentType: TipsModalContentType.LoginMethod,
                            showIcon: false,
                            text: '<<viewExample>>',
                            textColor: '#1890ff',
                            title: '',
                            width: 1348,
                        },
                    },
                },
                doubleFactor: {
                    type: 'array.checkboxWithModalTip',
                    displayName: '<<qysyzxydl>>',
                    defaultValue: [],
                    options: [
                        {
                            name: '<<qysyzxydl>>',
                            id: SecurityCode.PHONE_AND_PASSWORD,
                        },
                    ],
                    controlConfig: {
                        tipsModalConfig: {
                            type: 'extra',
                            extraConfig: {
                                before: '<<dzhmm>>',
                            },
                            contentType: TipsModalContentType.DoubleFactor,
                            showIcon: false,
                            text: '<<viewExample>>',
                            textColor: '#1890ff',
                            title: '',
                            width: 1048,
                        },
                    },
                },
                doubleFactorDisabled: {
                    type: 'array.checkboxWithModalTip',
                    displayName: '<<qysyzxydl>>',
                    defaultValue: [],
                    options: [
                        {
                            name: '<<qysyzxydl>>',
                            id: SecurityCode.PHONE_AND_PASSWORD,
                        },
                    ],
                    controlConfig: {
                        disabled: true,
                        tipsModalConfig: {
                            type: 'extra',
                            extraConfig: {
                                before: '<<dzhmm>>',
                            },
                            contentType: TipsModalContentType.DoubleFactor,
                            showIcon: false,
                            text: '<<viewExample>>',
                            textColor: '#1890ff',
                            title: '',
                            width: 1048,
                        },
                    },
                },

                allowedLoginFaluresNumber: {
                    type: 'number.tip',
                    displayName: '<<schtdlcsxz>>',
                    className: 'no-margin-field',
                    rules: [
                        { required: true, message: '<<qsrschtdlcsxz>>' },
                        {
                            message: '<<jzcsrzzs>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|[1-9]\d*)$/;
                                return reg.test(value);
                            },
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        style: {
                            width: 125,
                        },
                        addonbefore: '<<htdllxscmm>>',
                        addonafter: '<<chzdjcz>>',
                    },
                },
                allowedLoginFaluresTips: {
                    type: 'string.tips',
                    colon: false,
                    displayName: ' ',
                    controlConfig: {
                        tips: [
                            [
                                {
                                    text: '<<h_allow_login_falures_tips_before>>',
                                },
                                {
                                    type: 'link',
                                    text: '<<h_allow_login_falures_tips_link>>',
                                    onClick: () => {
                                        if (privilege.check('user_manage', 'full')) {
                                            window.open('#/new-user-manage');
                                        } else {
                                            message.warning(
                                                getText('h_allow_login_falures_tips_msg')
                                            );
                                        }
                                    },
                                },
                                {
                                    text: '<<h_allow_login_falures_tips_after>>',
                                },
                            ],
                        ],
                    },
                },
            },
        },
    },
    components: {
        SecurityConfigView: {
            component: 'Viewport',
        },
        SecurityConfigPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'SecurityConfigForm' }],
        },
        SecurityConfigForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SecurityConfig',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 17,
            style: { width: 1000 },
            fields: [
                { property: 'limitRule' },
                { property: 'loginMode' },
                {
                    property: 'doubleFactor',
                    visible: (values: any) => {
                        const loginMode = get(values, 'loginMode', []);
                        return (
                            loginMode.includes(SecurityCode.USERNAME_PASSWORD) &&
                            loginMode.includes(SecurityCode.PHONE_VERIFICATION_CODE)
                        );
                    },
                },
                {
                    property: 'doubleFactorDisabled',
                    visible: (values: any) => {
                        const loginMode = get(values, 'loginMode', []);
                        return !(
                            loginMode.includes(SecurityCode.USERNAME_PASSWORD) &&
                            loginMode.includes(SecurityCode.PHONE_VERIFICATION_CODE)
                        );
                    },
                },
                { property: 'allowedLoginFaluresNumber' },
                { property: 'allowedLoginFaluresTips' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/security-setting',
                    component: 'SecurityConfigView',
                    breadcrumbName: '<<securityConfig>>',
                    privilege: {
                        path: 'securityConfig',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'SecurityConfigPage' }],
                },
            ],
        },
    ],
};
