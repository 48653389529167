import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defaults, find } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage, InputNumber, Popover } from 'antd';
import './index.less';
const api = services.api;
interface CreditShopMemberCardPriceState {
    visible: boolean;
    memberPriceCommands: MemberCard[];
    resetVisible: boolean;
}
interface MemberCard {
    cardLevelId: number;
    points: number;
    salesPrice: number;
    subsiteCardLevelId: number;
    subsiteCardLevelName: string;
}

/**
 * 积分商城-设置会员价
 */
class creditShopMemberCardPrice extends Component<any, any> {
    state: CreditShopMemberCardPriceState = {
        visible: false,
        memberPriceCommands: [],
        resetVisible: false,
    };

    onSubmit = () => {
        const { row, entity } = this.props;
        const { memberPriceCommands } = this.state;
        let message = '';
        let regFlag = true;
        let newMemberPriceCommands: MemberCard[] = [];
        memberPriceCommands.forEach((item: MemberCard) => {
            if (item.salesPrice && !item.points) {
                message += item.subsiteCardLevelName + '、';
            }

            if (item.cardLevelId && item.points) {
                const reg = /(^[0-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;

                if (!reg.test(String(item.points))) {
                    regFlag = false;
                }
                if (item.salesPrice && !reg.test(String(item.salesPrice))) {
                    regFlag = false;
                }
                newMemberPriceCommands.push(item);
            }
        });
        if (message) {
            AntMessage.error(
                services.language.getText('please') +
                    message.substring(0, message.length - 1) +
                    services.language.getText('szjfjg')
            );
            return;
        }
        if (!regFlag) {
            AntMessage.error('积分价格仅支持两位小数');
            return;
        }
        const subsites = row.subsites;
        const params = {
            memberPriceCommands: newMemberPriceCommands,
            subsiteId: subsites[0].id,
        };
        api.post(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: `/admin/credit_eshop/${row.id}/member_price`,
        }).then(
            () => {
                AntMessage.success(services.language.getText('hyjgxcg'));
                this.toggleModal();
                entity.search();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };

    resetMemberPriceCommands = () => {
        const { memberPriceCommands } = this.state;
        memberPriceCommands.forEach((item: any) => {
            if (item.cardLevelId) {
                item.points = undefined;
                item.salesPrice = undefined;
            }
        });
        this.setState({ memberPriceCommands });
        this.handleResetVisibleChange();
    };

    changeValue = (field: string, id: number, value: any) => {
        const { memberPriceCommands } = this.state;
        let item: any = find(memberPriceCommands, (i) => id === i.subsiteCardLevelId);
        if (item) {
            item[field] = value === undefined ? undefined : value;
            this.setState({ memberPriceCommands });
        }
    };

    renderModal = () => {
        const { memberPriceCommands } = this.state;
        if (!memberPriceCommands || !memberPriceCommands.length) return null;
        return (
            <div className="member-cards-price-modal">
                <div className="member-cards-price-tip">用户下单时可享受对应身份的会员价</div>
                <table cellSpacing={0} cellPadding={5} className="member-cards-price-table">
                    <tbody>
                        <tr>
                            {memberPriceCommands.map((memberCard: MemberCard, index: number) => {
                                return (
                                    <th key={index}>
                                        {memberCard.subsiteCardLevelName
                                            ? memberCard.subsiteCardLevelName
                                            : '普通兑换价'}
                                    </th>
                                );
                            })}
                        </tr>
                        <tr style={{ textAlign: 'left' }}>
                            {memberPriceCommands.map((memberCard: MemberCard, index: number) => {
                                return (
                                    <td key={index}>
                                        {memberCard.subsiteCardLevelName ? (
                                            <div>
                                                <InputNumber
                                                    value={memberCard.points}
                                                    min={1}
                                                    step={0.01}
                                                    placeholder={services.language.getText(
                                                        'inputPlease'
                                                    )}
                                                    onChange={(value: any) =>
                                                        this.changeValue(
                                                            'points',
                                                            memberCard.subsiteCardLevelId,
                                                            value
                                                        )
                                                    }
                                                />
                                                <span>{services.language.getText('point')} + </span>
                                                <InputNumber
                                                    value={memberCard.salesPrice}
                                                    min={0}
                                                    step={0.01}
                                                    placeholder={services.language.getText(
                                                        'inputPlease'
                                                    )}
                                                    onChange={(value: any) =>
                                                        this.changeValue(
                                                            'salesPrice',
                                                            memberCard.subsiteCardLevelId,
                                                            value
                                                        )
                                                    }
                                                />
                                                <span> {services.language.getText('yuan')}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                {memberCard.points}积分+
                                                {services.language.getText('monetaryUnit')}
                                                {memberCard.salesPrice}
                                            </div>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    toggleModal = () => {
        const { row } = this.props;
        const { visible } = this.state;
        const subsites = row.subsites;
        if (!visible) {
            api.get(
                {
                    subsiteId: subsites[0].id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: `/admin/credit_eshop/${row.id}/member_prices`,
                }
            ).then(
                (res: any) => {
                    this.setState({
                        memberPriceCommands: res,
                    });
                },
                (error) => {
                    services.errorHandle(error);
                }
            );
        }

        this.setState({
            visible: !visible,
        });
    };
    handleResetVisibleChange = () => {
        this.setState({
            resetVisible: !this.state.resetVisible,
        });
    };
    renderModalFooter = () => {
        return (
            <div className="member-cards-price-actions">
                <div>
                    <Popover
                        overlayStyle={{ zIndex: 1000 }}
                        content={
                            <div className="member-cards-price-actions-reset">
                                清空所有自定义会员价吗？
                                <Button
                                    className="button"
                                    type="default"
                                    onClick={this.handleResetVisibleChange}
                                >
                                    取消
                                </Button>
                                <Button
                                    className="button"
                                    type="primary"
                                    onClick={this.resetMemberPriceCommands}
                                >
                                    确定
                                </Button>
                            </div>
                        }
                        trigger="click"
                        placement="right"
                        visible={this.state.resetVisible}
                        onVisibleChange={this.handleResetVisibleChange}
                    >
                        <Button className="button" type="link">
                            清空
                        </Button>
                    </Popover>
                </div>
                <div className="buttons">
                    <Button className="button" type="default" onClick={this.toggleModal}>
                        取消
                    </Button>
                    <Button className="button" type="primary" onClick={this.onSubmit}>
                        确定
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        const { params, row } = this.props;
        const { type } = params;
        const { visible } = this.state;
        const modalProps = {
            width: 800,
            title: '设置会员价',
            visible: this.state.visible,
            footer: this.renderModalFooter(),
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block', marginRight: 10 }}>
                <Button
                    key={row.id + type}
                    type={'link'}
                    style={{ marginLeft: 0, padding: 0 }}
                    onClick={this.toggleModal}
                >
                    会员价
                </Button>
                {visible ? (
                    <Modal {...modalProps}>
                        <div>{this.renderModal()}</div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const CreditShopMemberCardPrice = connect(
    mapStateToProps,
    mapDispatchToProps
)(creditShopMemberCardPrice);
