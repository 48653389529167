function formatIdNmae(obj: any) {
    const list = [];
    for (const key in obj) {
        list.push({ id: key, name: obj[key] });
    }
    return list;
}

function addConfig(array: any) {
    const idx = array.findIndex((item: any) => item.id === 'service');

    // 海信租户
    if (ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost') {
        if (idx > -1) {
            array[idx].children.push(
                {
                    id: 'dreamService',
                    name: '梦服务',
                },
                { id: 'member.coupon', name: '线上优惠券' },
                { id: 'member.couponOutline', name: '我的卡包券' },
                { id: 'member.hiDetail', name: 'Hi贝明细' },
                { id: 'member.groupGoldExchange', name: '兑换海信集团积分' },
                { id: 'gamePlatform', name: '游戏平台' },
                { id: 'anniversary', name: '周年庆报告' }
            );
        }
    } else {
        // 普通租户
        if (idx > -1) {
            array[idx].children.push({ id: 'member.coupon', name: '优惠券' });
        }
    }
    return array;
}

function addPlatformConfig(array: any) {
    const idx = array.findIndex((item: any) => item.id === 'service');

    // 海信租户
    if (ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost') {
        if (idx > -1) {
            array[idx].children.push(
                { id: 'gamePlatform', name: '游戏平台' },
                { id: 'anniversary', name: '周年庆报告' }
            );
        }
    }
    return array;
}

/**
 * 可视化页面类型
 */
const DESIGN_PAGE_TYPES = {
    platform: '<<platformPage>>',
    home: '<<sy_3>>',
    topic: '<<zthd>>',
    merchant: '<<merchantPage>>',
};

/**
 * 新C可视化类型
 */
const NEW_CLIENT_DESIGN_PAGE_TYPES = {
    platform: '<<platformPage>>',
    home: '<<sy_3>>',
    topic: '<<zthd>>',
    merchant: '<<merchantPage>>',
    member: '<<hyy>>',
};

let DESIGN_PAGE_LINK_TYPES_LIST = [
    { id: 'none', name: '无链接' },
    { id: 'home', name: '首页' },
    { id: 'topic', name: '专题活动' },
    { id: 'merchant', name: '专柜页' },
    { id: 'member.creditEshop', name: '积分换礼' },
    { id: 'product', name: '商品详情' },
    { id: 'couponProduct', name: '虚拟商品详情页' },
    { id: 'products', name: '商品列表' },
    { id: 'categoryNavProduct', name: '营销分类商品列表' },
    { id: 'categoryNav', name: '营销分类模板页' },
    { id: 'category', name: '分类' },
    { id: 'live', name: '直播列表' },
    { id: 'liveInfo', name: '直播间' },
    { id: 'urlLink', name: 'URL地址' },
    { id: 'miniprogram', name: '它方小程序' },
    { id: 'flashList', name: '秒杀列表' },
    { id: 'fullPaymentActivities', name: '全款预售' },
    { id: 'reserveActivities', name: '预约抢购活动' },
    { id: 'myReserveActivities', name: '预约记录' },
    { id: 'mallActivity', name: '商场活动' },
    { id: 'mallActivityList', name: '商场活动列表' },
    { id: 'mallActivityPage', name: '商场活动可视化' },
    { id: 'mallActivityCalendarPage', name: '商场活动日历' },
    { id: 'myMallActivityPage', name: '我的商场活动' },
    { id: 'couponProductList', name: '售卖优惠券' },
    { id: 'couponProductGroupList', name: '拼团卖券列表' },
    { id: 'wheelDraw', name: '大转盘活动' },
    { id: 'redPackageRain', name: '红包雨活动' },
    { id: 'lotteryCode', name: '抽奖码活动' },
    { id: 'myLotteryCode', name: '我的抽奖码' },
    { id: 'scratchCard', name: '刮刮卡抽奖' },
    { id: 'checkinIndex', name: '签到有礼' },
    { id: 'invite', name: '邀请有礼' },
    { id: 'groupBuyingList', name: '拼团列表' },
    { id: 'serviceReserved', name: '服务预约' },
    { id: 'serviceReservedList', name: '服务预约列表' },
    { id: 'exchangeCoupons', name: '兑换优惠券' },
    { id: 'member', name: '会员中心' },
    { id: 'subsite', name: '指定门店' },
    { id: 'subsiteByLocation', name: '门店定位' },
    { id: 'couponCenter', name: '领券中心' },
    { id: 'cardCollection', name: '集卡活动' },
    { id: 'watchword', name: '口令领券' },
    { id: 'distributor', name: '分销赚钱' },
    { id: 'blindBox', name: '盲盒活动' },
    { id: 'blindBoxList', name: '盲盒活动列表' },
    { id: 'fastIntegrate', name: '快速积分' },
    { id: 'fastIntegrateMerchant', name: '快速积分商家' },
    {
        id: 'service',
        name: '服务工具',
        children: [
            { id: 'member.couponOrder', name: '卡券订单' },
            { id: 'member.group', name: '社区团购' },
            { id: 'member.group.order', name: '拼团订单' },
            { id: 'member.identification', name: '会员认证' },
            { id: 'member.feedbackAdd', name: '意见反馈' },
            { id: 'member.feedback', name: '我的意见反馈' },
            { id: 'member.invoice', name: '发票开立' },
            { id: 'member.favorite', name: '收藏' },
            { id: 'member.consignee', name: '收货地址' },
            { id: 'contact', name: '在线客服' },
            { id: 'member.help', name: '帮助中心' },
            { id: 'member.membership', name: '会员权益' },
            { id: 'member.parking', name: '停车缴费' },
            { id: 'service.myCars', name: '我的车辆' },
            { id: 'member.parkingCredit', name: '停车积分' },
            { id: 'member.activity', name: '我的活动' },
            { id: 'member.birthdayCaring', name: '生日有礼' },
            { id: 'member.transactionHistory', name: '消费查询' },
            { id: 'member.transactionCredit', name: '消费积分' },
            { id: 'member.relegation', name: '保级查询' },
            { id: 'merchantGuide', name: '商场导览' },
            { id: 'coupon.couponConsume', name: '导购核销' },
            { id: 'fengmap', name: '地图导览' },
            { id: 'member.selfCrediting', name: '自助积分' },
            { id: 'member.serviceReservedOrderList', name: '我的预约' },
            { id: 'member.annualWelfare', name: '年终回馈' },
            { id: 'internalPurchase', name: '企业内购' },
            { id: 'member.authentication', name: '修改密码' },
            { id: 'member.offlineOrderComment', name: '线下订单' },
            { id: 'invoiceList', name: '我的发票' },
            { id: 'service.electronicBill', name: '电子小票' },
        ],
    },
];
let PLATFORM_PAGE_LINK_TYPES_LIST = [
    { id: 'none', name: '无链接' },
    { id: 'home', name: '首页' },
    { id: 'topic', name: '专题活动' },
    { id: 'merchant', name: '专柜页' },
    { id: 'member.creditEshop', name: '积分换礼' },
    { id: 'good', name: '商品详情' },
    { id: 'virtualGood', name: '虚拟商品详情页' },
    { id: 'products', name: '商品列表' },
    { id: 'categoryNavProduct', name: '营销分类商品列表' },
    { id: 'categoryNav', name: '营销分类模板页' },
    { id: 'category', name: '分类' },
    { id: 'live', name: '直播列表' },
    { id: 'liveInfo', name: '直播间' },
    { id: 'urlLink', name: 'URL地址' },
    { id: 'miniprogram', name: '它方小程序' },
    { id: 'flashList', name: '秒杀列表' },
    { id: 'fullPaymentActivities', name: '全款预售' },
    { id: 'reserveActivities', name: '预约抢购活动' },
    { id: 'myReserveActivities', name: '预约记录' },
    { id: 'mallActivity', name: '商场活动' },
    { id: 'mallActivityList', name: '商场活动列表' },
    { id: 'mallActivityPage', name: '商场活动可视化' },
    { id: 'mallActivityCalendarPage', name: '商场活动日历' },
    { id: 'myMallActivityPage', name: '我的商场活动' },
    { id: 'couponProductList', name: '售卖优惠券' },
    { id: 'couponProductGroupList', name: '拼团卖券列表' },
    { id: 'wheelDraw', name: '大转盘活动' },
    { id: 'redPackageRain', name: '红包雨活动' },
    { id: 'lotteryCode', name: '抽奖码活动' },
    { id: 'myLotteryCode', name: '我的抽奖码' },
    { id: 'scratchCard', name: '刮刮卡抽奖' },
    { id: 'checkinIndex', name: '签到有礼' },
    { id: 'invite', name: '邀请有礼' },
    { id: 'groupBuyingList', name: '拼团列表' },
    { id: 'serviceReserved', name: '服务预约' },
    { id: 'serviceReservedList', name: '服务预约列表' },
    { id: 'exchangeCoupons', name: '兑换优惠券' },
    { id: 'member', name: '会员中心' },
    { id: 'subsite', name: '指定门店' },
    { id: 'subsiteByLocation', name: '门店定位' },
    { id: 'distributor', name: '分销赚钱' },
    { id: 'couponCenter', name: '领券中心' },
    { id: 'blindBox', name: '盲盒活动' },
    { id: 'blindBoxList', name: '盲盒活动列表' },
    { id: 'fastIntegrate', name: '快速积分' },
    { id: 'fastIntegrateMerchant', name: '快速积分商家' },
    {
        id: 'service',
        name: '服务工具',
        children: [
            { id: 'member.couponOrder', name: '卡券订单' },
            { id: 'member.group', name: '社区团购' },
            { id: 'member.group.order', name: '拼团订单' },
            { id: 'member.identification', name: '会员认证' },
            { id: 'member.feedbackAdd', name: '意见反馈' },
            { id: 'member.feedback', name: '我的意见反馈' },
            { id: 'member.invoice', name: '发票开立' },
            { id: 'member.favorite', name: '收藏' },
            { id: 'member.coupon', name: '优惠券' },
            { id: 'member.consignee', name: '收货地址' },
            { id: 'contact', name: '在线客服' },
            { id: 'member.help', name: '帮助中心' },
            { id: 'member.membership', name: '会员权益' },
            { id: 'member.parking', name: '停车缴费' },
            { id: 'service.myCars', name: '我的车辆' },
            { id: 'member.parkingCredit', name: '停车积分' },
            { id: 'member.activity', name: '我的活动' },
            { id: 'member.birthdayCaring', name: '生日有礼' },
            { id: 'member.transactionHistory', name: '消费查询' },
            { id: 'member.transactionCredit', name: '消费积分' },
            { id: 'member.relegation', name: '保级查询' },
            { id: 'merchantGuide', name: '商场导览' },
            { id: 'coupon.couponConsume', name: '导购核销' },
            { id: 'fengmap', name: '地图导览' },
            { id: 'member.selfCrediting', name: '自助积分' },
            { id: 'member.serviceReservedOrderList', name: '我的预约' },
            { id: 'member.annualWelfare', name: '年终回馈' },
            { id: 'internalPurchase', name: '企业内购' },
            { id: 'member.offlineOrderComment', name: '线下订单' },
            { id: 'service.electronicBill', name: '电子小票' },
        ],
    },
];
let MERCHANT_DESIGN_PAGE_LINK_TYPES_LIST = [
    { id: 'none', name: '无链接' },
    { id: 'product', name: '商品详情' },
    { id: 'products', name: '商品列表' },
    { id: 'liveInfo', name: '直播间' },
    { id: 'flashList', name: '秒杀列表' },
    { id: 'contact', name: '在线客服' },
    { id: 'urlLink', name: 'URL地址' },
    { id: 'miniprogram', name: '它方小程序' },
    { id: 'merchantCategoryNavProduct', name: '选择门店分类导航' },
    { id: 'merchantCategory', name: '分类页' },
];

// 内容弹窗链接配置
let POP_UP_CONTENT_PAGE_LINK_TYPES_LIST = [
    { id: 'none', name: '无链接' },
    { id: 'mallActivity', name: '商场活动' },
    { id: 'product', name: '商品详情' },
    { id: 'live', name: '直播列表' },
    { id: 'liveInfo', name: '直播间' },
    { id: 'topic', name: '专题活动' },
    { id: 'topicContent', name: '话题' },
    { id: 'member.creditEshop', name: '积分换礼' },
    { id: 'mallActivityList', name: '商场活动列表' },
    { id: 'topic.promotionEncourage', name: '有奖话题' },
    { id: 'contentNoteDetail', name: '笔记' },
    { id: 'eShop.home', name: '电商首页' },
    { id: 'eShop.virtualCategory', name: '虚拟分类' },
    { id: 'eShop.promotionActivity', name: '促销活动页' },
    { id: 'checkinIndex', name: '签到有礼' },
    {
        id: 'service',
        name: '服务工具',
        children: [
            { id: 'member.parking', name: '停车缴费' },
            { id: 'merchantGuide', name: '商场导览' },
        ],
    },
];

/**
 * ｛id:1,name:'a'｝格式数据 页面类型
 */

const DESIGN_PAGE_TYPES_LIST = formatIdNmae(DESIGN_PAGE_TYPES);
const NEW_CLIENT_DESIGN_PAGE_TYPES_LIST = formatIdNmae(NEW_CLIENT_DESIGN_PAGE_TYPES);
DESIGN_PAGE_LINK_TYPES_LIST = addConfig(DESIGN_PAGE_LINK_TYPES_LIST);
MERCHANT_DESIGN_PAGE_LINK_TYPES_LIST = addConfig(MERCHANT_DESIGN_PAGE_LINK_TYPES_LIST);
POP_UP_CONTENT_PAGE_LINK_TYPES_LIST = addConfig(POP_UP_CONTENT_PAGE_LINK_TYPES_LIST);
PLATFORM_PAGE_LINK_TYPES_LIST = addPlatformConfig(PLATFORM_PAGE_LINK_TYPES_LIST);
export {
    DESIGN_PAGE_LINK_TYPES_LIST,
    DESIGN_PAGE_TYPES_LIST,
    DESIGN_PAGE_TYPES,
    NEW_CLIENT_DESIGN_PAGE_TYPES_LIST,
    NEW_CLIENT_DESIGN_PAGE_TYPES,
    MERCHANT_DESIGN_PAGE_LINK_TYPES_LIST,
    PLATFORM_PAGE_LINK_TYPES_LIST,
    POP_UP_CONTENT_PAGE_LINK_TYPES_LIST,
};
