import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';

export class MemberTypeTags extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(val: any, displayInfo: any) {
        if (val) {
            const keys = Object.keys(val);

            return keys.length ? (
                <div className="member-type-type">
                    {keys.map((i: any) => {
                        return (
                            <div>
                                <span>{val[i]['typeName']}</span>-
                                <span>{val[i]['list'].join('、')}</span>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <span>暂无标签</span>
            );
        }
        return <div>暂无标签</div>;
    }
}
