import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { forEach, get, mapValues } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { floatComputed } from '../../../../types/mode/object/credit-shop-exchange/credit-shop-exchange-apportion';

export interface CreditShopCopyFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
    onReloadOptions: (fieldName: any, fields: any) => void;
}

class creditShopCopyFormView extends PureComponent<CreditShopCopyFormProps> {
    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, onReloadOptions } = this.props;

        let fields: any[] = [
            {
                property: 'baseInfo.dateRange',
            },
            { property: 'subsiteId' },
            { property: 'coupon' },
            {
                property: 'name',
            },
            { property: 'stock' },
            {
                property: 'images',
            },
            {
                property: 'exchangeInfo.exchange',
                controlConfig: {
                    isEditForm: true,
                },
            },
            {
                property: 'showTag.afterSales',
                controlConfig: {
                    seat: 'row.coupon',
                    isEditForm: true,
                },
            },
            {
                property: 'showTag.notice',
            },
            {
                property: 'showTag.canShare',
            },
            {
                property: 'showTag.targetHideUserLevels',
            },
            {
                property: 'showTag.tagIdList',
            },
            { property: 'showTag.sequence' },
            { property: 'showTag.info' },
            { property: 'pictureInfo.description' },
        ];

        const formProps = {
            entity,
            componentId: 'CreditShopCopyFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data =
        get(_dispatch, 'components.["DataFormPlus-CreditShopCopyFormContainer"].fields') || {};

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        if (entityFields) {
            const stock = get(entityFields, 'stock');
            if (!stock.maxCount) {
                AntMessage.warning('请填写可兑换的最大库存');
                return false;
            }
            const exchange = get(entityFields, 'exchangeInfo.exchange');
            //普通兑换价
            const commonExchange = exchange.commonExchange[0];
            if (!commonExchange.points) {
                AntMessage.warning(services.language.getText('qtxdhdjfjg'));
                return false;
            }
            //校验分摊价
            const coupon = get(entityFields, 'coupon');
            if (coupon[0].type === 'PACKAGE') {
                const apportionInfo = commonExchange.apportionInfos[0];
                if (
                    apportionInfo.apportionPoints === null ||
                    apportionInfo.apportionPoints === ''
                ) {
                    AntMessage.warning('请设置（正常兑换价）的分摊价');
                    return false;
                }
                let memberLevelerrMsg = '';
                const cardLevelExchange = exchange.cardLevelExchange;
                if (cardLevelExchange.length > 0) {
                    for (let i = 0; i < cardLevelExchange.length; i++) {
                        const cardLevelExchangeInfo = cardLevelExchange[i];
                        const apportionInfos = cardLevelExchangeInfo.apportionInfos;
                        if (
                            cardLevelExchangeInfo.points &&
                            (apportionInfos[0].apportionPoints === null ||
                                apportionInfos[0].apportionPoints === '')
                        ) {
                            memberLevelerrMsg =
                                '请输入（' + cardLevelExchangeInfo.cardLevelName + '）的分摊价格';
                            break;
                        }
                        if (cardLevelExchangeInfo.points) {
                            let hasApportionPrice = true,
                                totalApportionPoints = 0,
                                totalApportionPrice = 0;
                            forEach(apportionInfos, (item) => {
                                if (item.apportionPoints === null || item.apportionPoints === '') {
                                    hasApportionPrice = false;
                                } else {
                                    totalApportionPoints +=
                                        (+item.apportionPoints || 0) * item.quantity;
                                }
                                if (
                                    cardLevelExchangeInfo.salesPrice &&
                                    (item.apportionPrice === null || item.apportionPrice === '')
                                ) {
                                    hasApportionPrice = false;
                                } else {
                                    totalApportionPrice = floatComputed.add(
                                        totalApportionPrice,
                                        floatComputed.multiply(
                                            +item.apportionPrice || 0,
                                            item.quantity,
                                            2
                                        ),
                                        2
                                    );
                                }
                            });
                            if (hasApportionPrice) {
                                if (
                                    totalApportionPoints !== (+cardLevelExchangeInfo.points || 0) ||
                                    totalApportionPrice !== (+cardLevelExchangeInfo.salesPrice || 0)
                                ) {
                                    memberLevelerrMsg =
                                        '（' +
                                        cardLevelExchangeInfo.cardLevelName +
                                        '）分摊价总和不等于售卖价格';
                                    break;
                                }
                            }
                        }
                    }
                    if (memberLevelerrMsg) {
                        AntMessage.warning(memberLevelerrMsg);
                        return false;
                    }
                }
            }
            const notice = get(entityFields, 'showTag.notice');
            if (notice.type === 'MANUAL_TIME') {
                if (!notice.time) {
                    AntMessage.warning('请填写活动开始预告时间');
                    return false;
                }
                const noticeTime = new Date(notice.time).getTime();
                const dateRange = get(entityFields, 'baseInfo.dateRange');
                const startTime = new Date(dateRange.start).getTime();
                if (noticeTime > startTime) {
                    if (!notice.time) {
                        AntMessage.warning('活动开始预告时间应早于兑换开始时间');
                        return false;
                    }
                }
            }
            return true;
        }
        return false;
    };

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(
                    actions.unmountComponentAction('DataFormPlus-CreditShopCopyFormContainer')
                );
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });

            if (entityFields) {
                const hasValidate = validate(entityFields);
                if (!hasValidate) {
                    return;
                }
                entity.add(entityFields, params);
            }
        },
    };
}

export const CreditShopCopyFormView = connect(mapStateToProps)(creditShopCopyFormView);
