import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const UserVassalageMerchantsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiPath = `/admin/user_vassalage_merchant/${id}`;
        return await api.get(data, config).then((res: any) => {
            const result = {
                vassalageMerchant: res
                    ? [
                          {
                              ...res,
                              subSiteName: res.subsiteName,
                              subSiteId: res.subsiteId,
                              id: `${res.subsiteId},${res.merchantId}`,
                          },
                      ]
                    : [],
            };
            return result;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        if (params.id) {
            config.apiPath = `/admin/user_vassalage_merchant/${params.id}`;
        }
        let newParams = {
            merchantId: params.vassalageMerchant[0].merchantId,
            subsiteId: params.vassalageMerchant[0].subSiteId,
        };
        return await api.put(newParams, config);
    },
};
