import React, { Component } from 'react';
import {
    Row as AntRow,
    InputNumber,
    Button,
    message,
    Checkbox,
    Popconfirm,
    Input,
    message as AntMessage,
} from 'antd';
import { clone, map, concat, isEmpty } from 'lodash';
import { ImageUpload } from '@comall-backend-builder/components-basis';

import './index.less';
import { ActivityRewardItem } from '../activity-reward-item';
import { Coupon } from '../../containers/select-coupon';
import { services } from '@comall-backend-builder/core';

type ActivityRewardValue = {
    /**
     * 连续邀请第X人
     */
    stepValue?: number;
    /**
     * 范围开始值
     */
    rangeStartValue?: number;
    /**
     * 范围结束值
     */
    rangeEndValue?: number;
    /**
     * 积分
     */
    pointChecked?: boolean;
    point?: number;
    /**
     * HI币
     */
    goldChecked?: boolean;
    gold?: number;
    goldTxtName?: string;
    /**
     * 经验值
     */
    experienceChecked?: boolean;
    experience?: number;
    /**
     * Hi贝
     */
    goldValueChecked?: boolean;
    goldValue?: number;
    /**
     * 优惠券
     */
    couponsChecked?: boolean;
    couponsValue?: any;
    /**
     * 自定义
     */
    customChecked?: boolean;
    custom?: string;
    /**
     * 礼物图
     */
    ladderGiftPicture?: any;
    /**
     * 奖励背景图
     */
    inviterRewardPicture?: any;
    /**
     * 优惠券奖品名称
     */
    couponRewardNameChecked?: boolean;
    couponRewardName?: string;
};

/**
 * 奖励的类型，multi: 阶梯奖励, single: 单个奖励
 */
enum RewardType {
    multi = 'multi',
    single = 'single',
}

/**
 * 后端接口返回的目前支持的选项
 */
enum RewardItems {
    stepValue = 'STEPVALUE',
    rangeStartValue = 'RANGESTARTVALUE',
    rangeEndValue = 'RANGEENDVALUE',
    point = 'POINT',
    gold = 'GOLD',
    experience = 'EXPERIENCE',
    coupons = 'COUPON',
    picture = 'PICTURE',
    pictureBg = 'PICTUREBG',
    custom = 'CUSTOM',
    Hi = 'HI',
    couponRewardName = 'COUPONREWARDNAME',
}

export interface ActivityRewardProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: Array<ActivityRewardValue> | null;

    /**
     * 奖励的类型，multi: 阶梯奖励, single: 单个奖励
     */
    rewardType: 'multi' | 'single';

    /**
     * 奖励最大数量
     */
    maxCount: number;

    /**
     * 候选项集合
     */
    options: Array<any>;
    /**
     * 是否是范围值
     */
    isRange?: boolean;

    /**
     * 阶梯值文本配置
     */
    stepValueTextConfig?: {
        /**
         * 起始文本
         */
        start: string;
        /**
         * 结束文本
         */
        end: string;
        /**
         * 阶梯值单位
         */
        unit: string;
    };
    /**
     * 奖励项文本配置
     */
    rewardTextConfig?: {
        /**
         * 起始文本
         */
        start?: string;
        /**
         * 结束文本
         */
        end?: string;
    };
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<ActivityRewardValue> | null, name: string) => void;
}
export class ActivityReward extends Component<ActivityRewardProps> {
    static defaultProps = {
        maxCount: 3,
        rewardType: 'multi',
        disabled: false,
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAddReward() {
        let { name, value, onChange, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning(
                    services.interpolate(services.language.getText('supportMaxCount'), { maxCount })
                );
                return;
            }
            value = concat([], value);
            value.splice(value.length, 0, { couponsValue: [] });
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: ActivityRewardValue = { couponsValue: [] };
                value = concat([], item);
            }
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        var reg = /^\+?[0-9]\d*$/;
        if (
            type !== RewardItems.custom &&
            type !== RewardItems.couponRewardName &&
            inputValue !== null &&
            inputValue !== '' &&
            !reg.test(inputValue)
        ) {
            AntMessage.warning(services.language.getText('inputNumber'));
            inputValue = null;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.rangeStartValue) {
                item.rangeStartValue = inputValue;
            }
            if (type === RewardItems.rangeEndValue) {
                item.rangeEndValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.gold) {
                item.gold = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.custom) {
                inputValue = event.target.value;
                item.custom = inputValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValue = inputValue;
            }
            if (type === RewardItems.couponRewardName) {
                item.couponRewardName = event.target.value;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.rangeStartValue) {
                item.rangeStartValue = inputValue;
            }
            if (type === RewardItems.rangeEndValue) {
                item.rangeEndValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.gold) {
                item.gold = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.custom) {
                inputValue = event.target.value;
                item.custom = inputValue;
            }
            if (type === RewardItems.couponRewardName) {
                inputValue = event.target.value;
                item.couponRewardName = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, index: number, type: string, txtName?: string) {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
                if (!checkboxValue) {
                    item.point = undefined;
                }
            }
            if (type === RewardItems.gold) {
                item.goldChecked = checkboxValue;
                item.goldTxtName = txtName;
                if (!checkboxValue) {
                    item.gold = undefined;
                }
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
                if (!checkboxValue) {
                    item.experience = undefined;
                }
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.custom) {
                item.customChecked = checkboxValue;
                if (!checkboxValue) {
                    item.custom = undefined;
                }
            }
            if (type === RewardItems.Hi) {
                item.goldValueChecked = checkboxValue;
                item.goldValue = undefined;
            }
            if (type === RewardItems.couponRewardName) {
                item.couponRewardNameChecked = checkboxValue;
                if (!checkboxValue) {
                    item.couponRewardName = undefined;
                }
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.gold) {
                item.goldChecked = checkboxValue;
                item.goldTxtName = txtName;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.custom) {
                item.customChecked = checkboxValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValueChecked = checkboxValue;
            }
            if (type === RewardItems.couponRewardName) {
                item.couponRewardNameChecked = checkboxValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value, rewardType, maxCount, disabled } = this.props;
        return (
            <div>
                {map(value, (reward, index) => this.renderReward(reward, index, disabled))}
                {value && value.length
                    ? null
                    : this.renderReward({ couponsValue: [] }, 0, disabled)}
                {rewardType === RewardType.multi && value && value.length < maxCount && !disabled && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {services.language.getText('addRule')}
                    </Button>
                )}
            </div>
        );
    }

    onChangeCoupon = (products: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.couponsValue = products;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            item.couponsValue = products;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    onPicChange = (picValue: any, index: number, picNmae: string) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (picNmae === 'ladderGiftPicture') {
                item.ladderGiftPicture = picValue;
            } else if (picNmae === 'inviterRewardPicture') {
                item.inviterRewardPicture = picValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };

            if (picNmae === 'ladderGiftPicture') {
                item.ladderGiftPicture = picValue;
            } else if (picNmae === 'inviterRewardPicture') {
                item.inviterRewardPicture = picValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    renderReward(reward: ActivityRewardValue, index: number, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const { options, rewardType, stepValueTextConfig, isRange, rewardTextConfig } = this.props;
        let _imageControlInfo = Object.assign(
            {},
            {
                name: 'ladderGiftPicture',
                fileName: 'fileName',
                value: reward.ladderGiftPicture,
                accept: 'image/*',
                uploadType: 'single',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                multiple: false,
                maxCount: 1,
                limitTips: {
                    limitText: services.language.getText('rewardPicLimit'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                },
            }
        );
        let _imageBgControlInfo = Object.assign(
            {},
            {
                name: 'inviterRewardPicture',
                fileName: 'fileName',
                value: reward.inviterRewardPicture,
                accept: 'image/*',
                uploadType: 'single',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                multiple: false,
                maxCount: 1,
                limitTips: {
                    limitText: services.language.getText('bscmrsyxtzdddct'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                },
            }
        );
        let stepValue: any;
        let point: any;
        let gold: any;
        let experience: any;
        let coupons: any;
        let custom: any;
        let picture: any;
        let pictureBg: any;
        let Hi: any;
        map(options, (option, index) => {
            if (option.code === RewardItems.stepValue) {
                stepValue = option;
            } else if (option.code === RewardItems.point) {
                point = option;
            } else if (option.code === RewardItems.gold) {
                gold = option;
            } else if (option.code === RewardItems.experience) {
                experience = option;
            } else if (option.code === RewardItems.coupons) {
                coupons = option;
            } else if (option.code === RewardItems.custom) {
                custom = option;
            } else if (option.code === RewardItems.picture) {
                picture = option;
            } else if (option.code === RewardItems.pictureBg) {
                pictureBg = option;
            } else if (option.code === RewardItems.Hi) {
                Hi = option;
            } else if (option.code === RewardItems.couponRewardName) {
                Hi = option;
            }
        });
        return (
            <div className="activity-rewards" key={index}>
                {stepValue && (
                    <AntRow>
                        <div className="reward-content">
                            {isRange ? (
                                <div className="reward-setting">
                                    <span className="reward-item-text">
                                        {stepValueTextConfig ? stepValueTextConfig.start : ''}
                                    </span>
                                    <div className="reward-count-input-wrap">
                                        <InputNumber
                                            disabled={isDisabled}
                                            type="number"
                                            min={1}
                                            value={reward.rangeStartValue}
                                            placeholder={services.language.getText('inputPlease')}
                                            onChange={(e) =>
                                                this.onInputValueChange(
                                                    e,
                                                    index,
                                                    RewardItems.rangeStartValue
                                                )
                                            }
                                        />
                                        <span className="reward-item-text"> ~ </span>
                                        <InputNumber
                                            disabled={isDisabled}
                                            type="number"
                                            placeholder={services.language.getText('inputPlease')}
                                            min={1}
                                            value={reward.rangeEndValue}
                                            onChange={(e) =>
                                                this.onInputValueChange(
                                                    e,
                                                    index,
                                                    RewardItems.rangeEndValue
                                                )
                                            }
                                        />
                                    </div>

                                    <span className="reward-item-text">
                                        {stepValueTextConfig ? stepValueTextConfig.unit : ''}
                                        {stepValueTextConfig ? stepValueTextConfig.end : ''}
                                    </span>
                                </div>
                            ) : (
                                <div className="reward-setting">
                                    <span className="reward-item-text">
                                        {stepValueTextConfig ? stepValueTextConfig.start : ''}
                                    </span>
                                    <div className="reward-count-input">
                                        <InputNumber
                                            disabled={isDisabled}
                                            type="number"
                                            min={1}
                                            value={reward.stepValue}
                                            onChange={(e) =>
                                                this.onInputValueChange(
                                                    e,
                                                    index,
                                                    RewardItems.stepValue
                                                )
                                            }
                                        />
                                    </div>
                                    <span className="reward-item-text">
                                        {stepValueTextConfig ? stepValueTextConfig.unit : ''}
                                        {stepValueTextConfig ? stepValueTextConfig.end : ''}
                                    </span>
                                </div>
                            )}
                            {rewardType === RewardType.multi && !isEmpty(reward) && !isDisabled && (
                                <Popconfirm
                                    onConfirm={this.onRemoveReward.bind(this, index)}
                                    title={services.language.getText('sfscggz')}
                                    okText={services.language.getText('common.ok')}
                                    cancelText={services.language.getText('common.cancel')}
                                >
                                    <Button type="default" className="reward-remove-reward-button">
                                        {services.language.getText('scgz')}
                                    </Button>
                                </Popconfirm>
                            )}
                        </div>
                    </AntRow>
                )}

                <div className="reward-content-list">
                    {point && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, index, RewardItems.point)}
                                checked={reward.pointChecked}
                                value="point"
                                disabled={isDisabled}
                            >
                                {point.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {rewardTextConfig && rewardTextConfig.start
                                    ? rewardTextConfig.start
                                    : services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.point}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.point)
                                }
                                disabled={isDisabled ? isDisabled : !reward.pointChecked}
                            />
                            <span className="reward-item-text">{point.name}</span>
                        </div>
                    )}
                    {gold && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.gold, gold.name)
                                }
                                checked={reward.goldChecked}
                                value="point"
                                disabled={isDisabled}
                            >
                                {gold.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {rewardTextConfig && rewardTextConfig.start
                                    ? rewardTextConfig.start
                                    : services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.gold}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.gold)
                                }
                                disabled={isDisabled ? isDisabled : !reward.goldChecked}
                            />
                            <span className="reward-item-text">{gold.name}</span>
                        </div>
                    )}
                    {experience && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.experience)
                                }
                                checked={reward.experienceChecked}
                                value="experience"
                            >
                                {experience.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {rewardTextConfig && rewardTextConfig.start
                                    ? rewardTextConfig.start
                                    : services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.experience}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.experience)
                                }
                                disabled={isDisabled ? isDisabled : !reward.experienceChecked}
                            />
                            <span className="reward-item-text">
                                {services.language.getText('value')}
                            </span>
                        </div>
                    )}
                    {Hi && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, index, RewardItems.Hi)}
                                checked={reward.goldValueChecked}
                                value="experience"
                            >
                                {Hi.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {rewardTextConfig && rewardTextConfig.start
                                    ? rewardTextConfig.start
                                    : services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.goldValue}
                                onChange={(e) => this.onInputValueChange(e, index, RewardItems.Hi)}
                                disabled={isDisabled ? isDisabled : !reward.goldValueChecked}
                            />
                            <span className="reward-item-text">
                                {services.language.getText('hellob')}
                            </span>
                        </div>
                    )}
                </div>
                {coupons && (
                    <div className="reward-content-list">
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.coupons)
                                }
                                checked={reward.couponsChecked}
                                value="coupons"
                                disabled={isDisabled}
                            >
                                {coupons.name}
                            </Checkbox>
                        </div>
                        <div className="reward-coupons">
                            <ActivityRewardItem
                                index={index}
                                value={reward.couponsValue || []}
                                subsiteIds={subsiteIds}
                                onChangeCoupon={this.onChangeCoupon}
                                type="checkbox"
                                packageSelectType="radio"
                                canChangeQuantity
                                disabled={isDisabled}
                                showLeftStock
                            />
                            {window.location.href.includes('/market/invitation-activity') && (
                                <div className="reward-content-coupon-reward-name-item">
                                    <Checkbox
                                        className="reward-content-item-checkbox"
                                        onChange={(e) =>
                                            this.onCheckboxChange(
                                                e,
                                                index,
                                                RewardItems.couponRewardName
                                            )
                                        }
                                        checked={reward.couponRewardNameChecked}
                                        value="coupons"
                                        disabled={isDisabled}
                                    >
                                        {services.language.getText('zszdyqljpmc')}
                                    </Checkbox>
                                    <Input
                                        className="reward-content-item-text"
                                        placeholder=""
                                        type="text"
                                        maxLength={60}
                                        value={reward.couponRewardName}
                                        onChange={(e) =>
                                            this.onInputValueChange(
                                                e,
                                                index,
                                                RewardItems.couponRewardName
                                            )
                                        }
                                        disabled={!reward.couponRewardNameChecked}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {custom && (
                    <div className="reward-content-list">
                        <div className="reward-content-custom-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.custom)
                                }
                                checked={reward.customChecked}
                                value="custom"
                            >
                                {custom.name}
                            </Checkbox>
                            <Input
                                className="reward-content-item-text"
                                placeholder={services.language.getText('trdwzjzszyqylhdyz')}
                                type="text"
                                maxLength={20}
                                value={reward.custom}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.custom)
                                }
                                disabled={!reward.customChecked}
                            />
                        </div>
                    </div>
                )}
                {picture && (
                    <div className="reward-pic">
                        {picture.name === services.language.getText('jlt') && (
                            <span style={{ marginRight: '2px', color: 'red' }}>*</span>
                        )}
                        <span className="reward-pic-text">{picture.name}</span>
                        <ImageUpload
                            {..._imageControlInfo}
                            onChange={(value: any, name: string) => {
                                this.onPicChange(value, index, name);
                                return {};
                            }}
                        />
                    </div>
                )}
                {pictureBg && (
                    <div className="reward-pic">
                        <span className="reward-picbg-text">
                            {services.language.getText('yqr') + pictureBg.name}{' '}
                        </span>
                        <ImageUpload
                            {..._imageBgControlInfo}
                            onChange={(value: any, name: string) => {
                                this.onPicChange(value, index, name);
                                return {};
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        let subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        if (row.encourageInfo && row.encourageInfo.subsiteIds) {
            subsiteIds =
                row.encourageInfo && row.encourageInfo.subsiteIds
                    ? map(row.encourageInfo.subsiteIds, 'id')
                    : null;
        }
        return subsiteIds;
    };
}
