import React, { Component } from 'react';
import { cloneDeep, forEach } from 'lodash';
import { message as AntMessage, Modal } from 'antd';
import { WechatText, WechatTextTargetVo } from '../../components/wechant-content';
import { WechatTextEditor } from '../wechat-text-editor';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface Props {
    visible: boolean;
    onConfrim: (value: WechatText) => void;
    onCancel: () => void;
    editInfo?: WechatText;
    appId: string;
}

interface States {
    chooseText: WechatText;
}

export class WechatOfficialAutoReplySettingPageTextModal extends Component<Props, States> {
    constructor(props: any) {
        super(props);

        this.state = {
            chooseText: {
                content: '',
                targetList: [],
            },
        };
    }

    componentDidMount() {
        //编辑回显
        const { editInfo } = this.props;
        if (editInfo) {
            this.setState({
                chooseText: cloneDeep(editInfo),
            });
        }
    }

    OK = () => {
        const { onConfrim } = this.props;
        const { chooseText } = this.state;
        if (!chooseText || !chooseText.content) {
            AntMessage.warning(services.language.getText('qsrwb'));
            return false;
        }
        //过滤一下无用的targetList里的链接项
        if (chooseText.content && chooseText.targetList && chooseText.targetList.length > 0) {
            const newTargetList: Array<WechatTextTargetVo> = [];
            forEach(chooseText.targetList, (o: any) => {
                if (chooseText.content.includes(o.href)) {
                    newTargetList.push(o);
                }
            });
            chooseText.targetList = newTargetList;
        }
        onConfrim(chooseText);
    };

    onChangeEditor = (value: string) => {
        const { chooseText } = this.state;
        chooseText.content = value;
        this.setState({
            chooseText: chooseText,
        });
    };
    onChangeTargetList = (list: Array<WechatTextTargetVo>) => {
        const { chooseText } = this.state;
        chooseText.targetList = list;
        this.setState({
            chooseText: chooseText,
        });
    };

    render() {
        const { visible, onCancel, appId } = this.props;
        const { chooseText } = this.state;
        return (
            <Modal
                visible={visible}
                width={500}
                title={services.language.getText('hfwz')}
                onCancel={onCancel}
                onOk={this.OK}
            >
                <WechatTextEditor
                    value={chooseText.content}
                    targetList={chooseText.targetList}
                    onChange={this.onChangeEditor}
                    onChangeTargetList={this.onChangeTargetList}
                    appId={appId}
                />
            </Modal>
        );
    }
}
