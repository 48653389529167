import React, { PureComponent } from 'react';
import { cloneDeep, forEach, find, isEmpty, get } from 'lodash';
import { PointTagSelector, GoodsDisplay, GoodsActivity } from '../../components';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

export interface PointTag {
    id: string;
    subSiteId: string;
    /**
     * 被选中的
     */
    select?: boolean;
    /**
     * 将要被删除的
     */
    delete?: boolean;
    /**
     * 商品id
     */
    saleRuleGoodsId: any;
    name: string;
    saleRuleGoodsName: string;
    status: boolean;
}
export interface PointTagsProperty {
    property: string;
}

interface SelectPointTagProps<T> {
    value: any[];
    onChange: (v: any) => void;
    type: 'radio' | undefined;
    disabled?: boolean;
    formColumns?: PointTagsProperty[];
    fields?: PointTagsProperty[];
    fieldParams?: any;
    isDeliveryShop?: boolean;
    row: any;
    getParams: any;
}

export class SelectPointTag<T> extends PureComponent<
    SelectPointTagProps<T>,
    {
        value: Array<PointTag>;
        tagId: string;
        goodsList: any;
    }
> {
    static defaultProps = {
        disabled: false,
    };
    state = {
        value: new Array<PointTag>(),
        tagId: '',
        goodsList: {},
    };

    /**
     * @param selectValues
     */
    onChange = (selectValues: GoodsActivity[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && isEmpty(selectValues)) {
                onChange({});
            } else {
                onChange(selectValues);
            }
            let forGoodsList: any = {};
            forEach(selectValues, (item: any) => {
                if (get(item, 'saleRuleGoodsId')) {
                    forGoodsList = selectValues;
                } else {
                    forGoodsList = undefined;
                }
            });

            this.setState({
                goodsList: forGoodsList,
            });
        }
    };

    /**
     * 改变已选择选中状态
     * @param selectValues
     */
    onChangeSelect = (selectValues: PointTag[]) => {
        const { onChange, value } = this.props;
        const newData: PointTag[] = cloneDeep(value);
        forEach(newData, (tag) => {
            const selectShop = find(selectValues, (selectShop) => {
                return selectShop.id === tag.id;
            });

            if (selectShop) {
                tag.select = true;
            } else {
                tag.select = false;
            }
        });
        if (onChange) {
            if (newData && newData.length === 0) {
                onChange(undefined);
            } else {
                onChange(newData);
            }
        }
    };

    /**
     * 添加弹窗确认后回调
     * @param selectValues
     */
    onSelect = (selectValues: GoodsActivity[]) => {
        const { row } = this.props;
        let subsitesId = '';
        if (row && !isEmpty(row) && !isEmpty(row.baseInfo) && !isEmpty(row.baseInfo.subsites)) {
            subsitesId = row.baseInfo.subsites;
        }
        if (selectValues && !isEmpty(selectValues)) {
            const tagId = selectValues[0].id;
            api.get(
                {
                    tagId: tagId,
                    page: 1,
                    perPage: 10,
                    subsiteId: subsitesId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_eshop_limit/goods',
                }
            ).then((res: any) => {
                const result = res.result;
                if (result && !isEmpty(result)) {
                    this.setState({ goodsList: result });
                    this.onChange(result);
                } else {
                    message.warning(language.getText('gbqxmyszspe'));
                }
            });
        }
        this.onChange(selectValues);
    };

    render() {
        const { type, disabled, formColumns, fields, fieldParams, row } = this.props;
        const { goodsList } = this.state;
        let subsites = '';
        if (row && !isEmpty(get(row, 'baseInfo.subsites'))) {
            subsites = row.baseInfo.subsites;
        }
        if (row && !isEmpty(get(row, 'limitInfo.tags'))) {
            let forGoodsList: any = {};
            forEach(row.limitInfo.tags, (item: any) => {
                if (get(item, 'saleRuleGoodsId')) {
                    forGoodsList = row.limitInfo.tags;
                } else {
                    forGoodsList = undefined;
                }
            });
            this.setState({
                goodsList: forGoodsList,
            });
        }

        return (
            <div>
                <PointTagSelector
                    type={type}
                    onChange={this.onSelect}
                    selectValues={goodsList}
                    columns={formColumns}
                    fields={fields}
                    params={fieldParams}
                    subsites={subsites}
                    disabled={disabled}
                />
                {!isEmpty(goodsList) && (
                    <GoodsDisplay
                        data={goodsList}
                        onRemove={this.onChange}
                        disabled={disabled}
                    ></GoodsDisplay>
                )}
            </div>
        );
    }
}
