import React, { Component } from 'react';
import { Textbox } from '@comall-backend-builder/components-basis';
import { QuestionPoprver } from '../../../components';
import { modes } from '@comall-backend-builder/types';

export class StringInputWithQuestionTipMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringInputWithQuestionTip {...displayInfo} />;
    }
}

class StringInputWithQuestionTip extends Component<any, any> {
    render() {
        const { questionTipsConfig } = this.props;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Textbox {...(this.props as any)} type="text" />
                {questionTipsConfig && (
                    <div style={{ marginLeft: 5 }}>
                        <QuestionPoprver {...questionTipsConfig} />
                    </div>
                )}
            </div>
        );
    }
}
