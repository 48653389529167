import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, remove } from 'lodash';
import {
    FloatingActionType,
    getDefaultValue,
    FloatingActionButton,
} from '../../../components/floating-action-buttons-setting/common';

const api = services.api;
export const FloatingActionButtonLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/floating_action_button';
        const { id, subsite } = data;
        if (id) {
            config.apiPath = `/admin/floating_action_button/${id}`;
        } else {
            if (subsite) {
                data.subsiteId = subsite.id;
                delete data.subsite;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const {
                    title,
                    subsites,
                    pageScopes,
                    config: configJson,
                    startTime: start,
                    endTime: end,
                    channels = [],
                } = res;
                res.baseInfo = {
                    title,
                    dateRange: {
                        start,
                        end,
                    },
                    subsites,
                    pageScopeIds: pageScopes.map((item: any) => item.id),
                    channels,
                };
                const config = JSON.parse(configJson);
                const buttons = [...config.staticEntities, ...config.entities];
                const newButtons = getDefaultValue().filter((i) =>
                    buttons.every((c: FloatingActionButton) => c.type !== i.type)
                );
                config.buttons = [...buttons, ...newButtons];
                config.subWinNum = config.subWinNum || 2;
                res.config = config;
            } else {
                res.result = res.result.map((item: any) => ({
                    ...item,
                    subsiteName: item.subsites,
                }));
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/floating_action_button';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/floating_action_button/${params.id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
    delete: function(params: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/floating_action_button/${params.id}`;
        return api.delete({}, config);
    },
};

const dataParser = (params: any) => {
    const {
        baseInfo: {
            dateRange: { start, end },
            ...baseInfo
        },
        config: { buttons, controlIcon, size, subWinNum },
    } = params;
    const entities = [...buttons];
    const staticEntities = remove(entities, (item) => item.type === FloatingActionType.ToTop);
    return {
        ...baseInfo,
        startTime: start,
        endTime: end,
        config: JSON.stringify({
            subWinNum,
            controlIcon,
            staticEntities,
            entities,
            size,
        }),
    };
};
