import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
interface Params {
    subsiteDto: any;
    afterSalesConfigDto: any;
    deliveryLogisticsSender?: any;
    afterSaleBackModes: string[];
}
enum IndoorMapProviderType {
    fengNiao = 'FENG_NIAO',
    qianMu = 'QIAN_MU',
    none = 'NONE',
}
export const SettingSubsiteMessage: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/subsite';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/subsite/${id}`;
            data = {};
        }
        return await api.get(data, config).then((result: any) => {
            if (id) {
                result.businessStatus = result.businessStatus + '';
                result.subsitePosition = [result.longitude, result.latitude];
                if (result.picUrl || result.picId) {
                    result.pic = [
                        {
                            id: result.picId,
                            path: result?.picUrl || '',
                        },
                    ];
                }
                if (result.logoPicId && result.logoPicUrl) {
                    result.logo = [
                        {
                            id: result.logoPicId,
                            path: result.logoPicUrl,
                        },
                    ];
                }
                if (result.servicePicId && result.servicePicUrl) {
                    result.servicePic = [
                        {
                            id: result.servicePicId,
                            path: result.servicePicUrl,
                        },
                    ];
                }
                result.afterSaleBackModes = result.afterSaleBackModes?.map((mode: number) =>
                    mode.toString()
                );
                result.region = {
                    id: result.regionId,
                    parentIds: result.parentRegionIds,
                };
                result.afterSalesSubsite = {
                    id: result.afterSalesSubsiteId,
                    parentIds: result.afterSalesParentRegionIds,
                };
                result.enabled = result.enabled + '';
                result.autoPosition = result.autoPosition + '';
                result.businessStatus = result.businessStatus + '';
                result.tips = result.tips ? result.tips : language.getText('tipsValue');
                if (!result.indoorMapProvider) {
                    if (result.mapId || result.mapKey || result.mapAppName) {
                        result.indoorMapProvider = IndoorMapProviderType.fengNiao;
                    } else if (result.mapUrl) {
                        result.indoorMapProvider = IndoorMapProviderType.qianMu;
                    } else {
                        result.indoorMapProvider = IndoorMapProviderType.none;
                    }
                }
                result.deliveryLogisticsSenderEnabled = result.deliveryLogisticsSender
                    ? 'true'
                    : 'false';
                if (result.deliveryLogisticsSender) {
                    result.deliveryLogisticsSenderName = result.deliveryLogisticsSender.name;
                    result.deliveryLogisticsSenderContactNumber =
                        result.deliveryLogisticsSender.contactNumber;
                    result.deliveryLogisticsSenderRegionId = {
                        id: result.deliveryLogisticsSender.regionId,
                        parentIds: result.deliveryLogisticsSender.parentRegionIds,
                    };
                    result.deliveryLogisticsSenderAddress = result.deliveryLogisticsSender.address;
                }
            } else {
                result.result =
                    result &&
                    result.result.map((item: any) => {
                        item.subsiteNameStr = item.subsiteName;
                        return item;
                    });
            }
            return result;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/subsite/${id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

export const BusinessMallsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        // const { id } = data;
        config.apiPath = `/admin/business_malls`;
        data.subsiteId = data.id;
        delete data.id;

        return await api.get(data, config).then((res: any) => {
            res.subsiteName = res.subsite.name;
            res.subsiteId = res.subsite.id;
            res.baseInfo = {
                wxId: res.wechatBusinessMall.id,
                wxMallId: res.wechatBusinessMall.mallId,
                wxBusinessMall: res.wechatBusinessMall?.mallId,
                version: res.wechatBusinessMall?.version ? res.wechatBusinessMall.version : 'V3',
                wxOpen: res.wechatBusinessMall?.open + '',
                mallBrandId: res.wechatBusinessMall?.mallBrandId,
                popup: res.wechatBusinessMall?.popup + '',
                transactionNotificationUrl: res.wechatBusinessMall?.transactionNotificationUrl,
                authorizeNotificationUrl: res.wechatBusinessMall?.authorizeNotificationUrl,
                pointDetailPage: 'subpackages/my-points/index',
                creditShopPage: 'subpackages/credit-shop/index',
                myCarsPage: 'subpackages/parking-fee/pages/my-cars/index',
                parkingFeePage: 'subpackages/parking-fee/index',
                aliId: res.alipayBusinessMall?.id,
                aliOpen: res.alipayBusinessMall?.open + '',
                alipayMallId: res.alipayBusinessMall?.mallId,
            };
            res.merchantInfo = {
                merchants:
                    res.merchants && res.merchants.length > 0
                        ? res.merchants.map((m: any) => {
                              return {
                                  ...m,
                                  id: res.subsite.id + ',' + m.merchantId,
                                  merchantId: m.merchantId + '',
                              };
                          })
                        : [],
            };
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiPath = `/admin/business_malls`;
        const newParams = {
            subsiteId: parseInt(params.subsiteId),
            alipayBusinessMall: {
                id: params.baseInfo.aliId,
                mallId: params.baseInfo.alipayMallId,
                open: params.baseInfo.aliOpen === 'true' ? true : false,
                version: 'V1',
            },
            wechatBusinessMall: {
                id: params.baseInfo.wxId,
                mallId: params.baseInfo.wxMallId,
                mallBrandId: params.baseInfo.mallBrandId,
                open: params.baseInfo.wxOpen === 'true' ? true : false,
                popup: params.baseInfo.popup === 'true' ? true : false,
                version: params.baseInfo.version,
            },
            merchantIds:
                params.merchantInfo.merchants && params.merchantInfo.merchants.length > 0
                    ? params.merchantInfo.merchants.map((item: any) => item.merchantId)
                    : [],
        };
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const {
        code,
        subsiteName,
        contactName,
        mobile,
        servicePhone,
        servicePic,
        mapId,
        mapKey,
        mapAppName,
        mapThemeId,
        pic,
        logo,
        region,
        address,
        subsitePosition,
        autoPosition,
        sequence,
        format,
        status,
        tips,
        businessStatus,
        businessTime,
        deliveryLogisticsSenderEnabled,
        deliveryLogisticsSenderName,
        deliveryLogisticsSenderContactNumber,
        deliveryLogisticsSenderRegionId,
        deliveryLogisticsSenderAddress,
        enabled,
        afterSalesContacts,
        afterSalesPhone,
        afterSalesSubsite,
        afterSalesAddress,
        indoorMapProvider,
        mapUrl,
        afterSaleBackModes,
        douyinPoiId,
    } = params;
    const newParams: Params = {
        subsiteDto: {
            code,
            subsiteName,
            servicePhone,
            regionId: region.id,
            address,
            longitude: subsitePosition[0],
            latitude: subsitePosition[1],
            autoPosition,
            sequence,
            format,
            status,
            businessStatus,
            tips,
            douyinPoiId,
        },
        afterSalesConfigDto: {
            enabled,
        },
        afterSaleBackModes,
    };
    contactName && (newParams.subsiteDto.contactName = contactName);
    mobile && (newParams.subsiteDto.mobile = mobile);
    switch (indoorMapProvider) {
        case IndoorMapProviderType.fengNiao:
            newParams.subsiteDto.mapId = mapId;
            newParams.subsiteDto.mapKey = mapKey;
            newParams.subsiteDto.mapAppName = mapAppName;
            newParams.subsiteDto.mapThemeId = mapThemeId;
            newParams.subsiteDto.indoorMapProvider = indoorMapProvider;
            break;
        case IndoorMapProviderType.qianMu:
            newParams.subsiteDto.mapUrl = mapUrl;
            newParams.subsiteDto.indoorMapProvider = indoorMapProvider;
            break;
        default:
            break;
    }
    if (pic && pic.length) {
        newParams.subsiteDto.picId = pic[0].id;
        newParams.subsiteDto.picUrl = pic[0].path;
    }
    if (logo && logo.length > 0) {
        newParams.subsiteDto.logoPicId = logo[0].id;
        newParams.subsiteDto.logoPicUrl = logo[0].path;
    }
    if (servicePic && servicePic.length > 0) {
        newParams.subsiteDto.servicePicId = servicePic[0].id;
        newParams.subsiteDto.servicePicUrl = servicePic[0].path;
    }
    businessTime && (newParams.subsiteDto.businessTime = businessTime);
    if (enabled === 'true') {
        newParams.afterSalesConfigDto.afterSalesContacts = afterSalesContacts;
        newParams.afterSalesConfigDto.afterSalesPhone = afterSalesPhone;
        newParams.afterSalesConfigDto.afterSalesAddress = afterSalesAddress;
        newParams.afterSalesConfigDto.afterSalesSubsiteId = afterSalesSubsite.id;
    }
    if (deliveryLogisticsSenderEnabled === 'true') {
        newParams.deliveryLogisticsSender = {
            name: deliveryLogisticsSenderName,
            contactNumber: deliveryLogisticsSenderContactNumber,
            regionId: deliveryLogisticsSenderRegionId.id,
            address: deliveryLogisticsSenderAddress,
        };
    }
    return newParams;
}
