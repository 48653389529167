import React, { PureComponent } from 'react';
import { Select } from 'antd';
import { services } from '@comall-backend-builder/core';
import find from 'lodash/find';

const api = services.api;

interface subSiteProps {
    value: any;
    needCode?: boolean;
    onChange: (value: any) => void;
}

interface Item {
    label: string;
    value: string;
}

class CustomSelectSubsite extends PureComponent<subSiteProps, { options: Array<Item> }> {
    constructor(props: subSiteProps) {
        super(props);
        this.state = {
            options: [],
        };
    }

    componentWillMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/defaults/subsite',
            }
        ).then((data) => {
            data = data || [];
            // @ts-ignore
            let options = data.map((item: any) => ({
                label: item.name,
                value: item.id,
                code: item.code,
            }));
            this.setState({ options });
        });
    }

    /** 下拉选择 */
    handleChange = (value: string) => {
        const { needCode } = this.props;
        const { options } = this.state;
        let item: any = find(options, { value }) || {};

        if (needCode) {
            this.props.onChange({ id: value, name: item.label, subsiteId: value, code: item.code });
            return;
        }
        this.props.onChange({ id: value, name: item.label, subsiteId: value });
    };

    render() {
        const { options } = this.state;
        const { value } = this.props;

        return (
            <div>
                <Select
                    style={{ width: 250 }}
                    defaultValue={value?.subsiteId}
                    onChange={this.handleChange}
                >
                    {options.map((item: Item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        );
    }
}

export { CustomSelectSubsite };
