import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const WechatOfficialAccountsGiftLoader: Loader = {
    get(params, config) {
        if (params.appId) {
            config.apiPath = `/admin/follow_wechat_official_account_activities/${params.appId}`;
        }
        return api.get(params, config).then((res: any) => {
            if (res) {
                res.subsites = res.wechatOfficialAccount?.subsites || [];
                res.appId = res.wechatOfficialAccount?.appId || '';
                res.baseInfo = {
                    status: Boolean(res.status) ? 'true' : 'false',
                    name: res.name,
                    dateRange:
                        res.startTime && res.endTime
                            ? {
                                  start: res.startTime,
                                  end: res.endTime,
                              }
                            : undefined,
                };
                const rewards = {
                    assetRewards: res.assetRewards || [],
                    couponRewards: res.couponRewards?.length
                        ? res.couponRewards.map((item: any) => {
                              item.id = item?.couponDefinitionId?.toString() || '';
                              item.quantity = item.count;
                              item.type = item.couponType;
                              return item;
                          })
                        : undefined,
                };
                res.rewardConfig = {
                    rewards,
                    followAgainHasGift:
                        res.followAgainHasGift || res.followAgainHasGift === null
                            ? ['NotReclaimable']
                            : [],
                    registerReward:
                        res.registerReward || res.registerReward === null ? ['RegisterReward'] : [],
                };
            }
            return res;
        });
    },
    put: async function(params, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/follow_wechat_official_account_activities`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

const dataParser = (params: any) => {
    const {
        appId,
        id,
        wechatOfficialAccountId,
        baseInfo: {
            dateRange: { start, end },
            name,
            status,
        },
        rewardConfig: {
            rewards: { assetRewards, couponRewards },
            followAgainHasGift,
            registerReward,
        },
    } = params;
    return {
        id: id,
        appId: appId,
        name,
        startTime: start,
        endTime: end,
        status: status === 'true',
        assetRewards,
        followAgainHasGift: (followAgainHasGift || []).includes('NotReclaimable'),
        registerReward: (registerReward || []).includes('RegisterReward'),
        wechatOfficialAccountId,
        couponRewards: couponRewards?.map((coupon: any) => ({
            couponDefinitionId: coupon.id,
            count: coupon.quantity,
        })),
    };
};
