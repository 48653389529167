import React, { Component } from 'react';
import { InputNumber, Button } from 'antd';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

export interface MallActivityPayValue {
    list: PointExchangeItem[];
}

export interface PointExchangeItem {
    /**
     * 兑换积分
     */
    point: string;
    /**
     * 兑换次数上限
     */
    limit: string;
}

export interface AffiliateMarketingPointExchangeProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: MallActivityPayValue | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: MallActivityPayValue | null, name: string) => void;
}

export class AffiliateMarketingPointExchange extends Component<
    AffiliateMarketingPointExchangeProps
> {
    harderChangeSetting = () => {
        const { onChange, name, value } = this.props;
        if (value && value.list && value.list.length > 0) {
            onChange(
                {
                    list: [],
                },
                name
            );
        } else {
            onChange(
                {
                    list: [
                        {
                            point: '',
                            limit: '',
                        },
                    ],
                },
                name
            );
        }
    };

    changeValue = (type: string, limitValue: string, index: number) => {
        const { value, onChange, name } = this.props;
        const list = cloneDeep(value!.list);
        const record: PointExchangeItem = list[index];
        if (type === 'point') {
            list.splice(index, 1, {
                ...record,
                point: limitValue,
            });
        }
        if (type === 'limit') {
            list.splice(index, 1, {
                ...record,
                limit: limitValue,
            });
        }
        onChange(
            {
                list,
            },
            name
        );
    };

    render() {
        const { value } = this.props;
        return (
            <div>
                <Button type="primary" onClick={this.harderChangeSetting}>
                    {value && value.list && value.list.length > 0
                        ? services.language.getText('clearSetting')
                        : services.language.getText('settings')}
                </Button>
                {value && value.list && value.list.length > 0 && (
                    <div>
                        {value.list.map((item: PointExchangeItem, index: number) => {
                            return (
                                <div style={{ marginTop: '5px' }}>
                                    <span>{services.language.getText('xh')}&nbsp;&nbsp;</span>
                                    <InputNumber
                                        placeholder={services.language.getText('inputPlease')}
                                        value={item.point ? parseInt(item.point) : undefined}
                                        min={1}
                                        max={999999}
                                        precision={0}
                                        onChange={(value: any) =>
                                            this.changeValue('point', value, index)
                                        }
                                    ></InputNumber>
                                    <span>
                                        &nbsp;&nbsp;{services.language.getText('jfdhyccjcs')}
                                        &nbsp;&nbsp;
                                    </span>
                                    <InputNumber
                                        placeholder={services.language.getText('inputPlease')}
                                        value={item.limit ? parseInt(item.limit) : undefined}
                                        min={1}
                                        max={999999}
                                        precision={0}
                                        onChange={(value: any) =>
                                            this.changeValue('limit', value, index)
                                        }
                                    ></InputNumber>
                                    <span>
                                        &nbsp;&nbsp;{services.language.getText('frequency')}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
