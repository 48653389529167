import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

enum AuditTypeEnum {
    Info = 'PRODUCT_INFO',
    Business = 'PRODUCT_BUSINESS',
}

const productSettingTable = {
    component: 'DataTable',
    loadFirstPage: true,
    pagination: {
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal(total: number) {
            return services.interpolate(services.language.getText('total'), {
                total,
            });
        },
    },
    columns: [
        {
            property: 'subsiteName',
        },
        {
            property: 'merchants',
            displayConfig: {
                className: 'ant-table-cell-ellipsis',
                style: {
                    maxWidth: '140px',
                },
                countSuffix: '<<merchant>>',
            },
        },
        {
            property: 'status',
        },
    ],
};

export const config: Config = {
    entities: {
        ProductSettingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/product_audit_rules',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                        placeholder: '<<selectPlease>>',
                        showSearch: true,
                    },
                },
                merchantIds: {
                    displayName: '<<applyMerchants>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        params: {
                            isContainsSelfMerchant: false,
                            //专柜默认正序排序
                            sequence: 'code',
                            order: 'asc',
                        },
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                        dependences: 'subsiteIds',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<qxxzsymd>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ENABLE', name: '<<beforeOpen>>' },
                        { id: 'DISABLE', name: '<<beforeClose>>' },
                    ],
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<sdshzjmc>>',
                },
                subsiteInfo: {
                    type: 'object.subForm',
                    displayName: '<<szmd>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                                optionFilterProp: 'children',
                                filterOption: (input: any, option: any) =>
                                    (option?.name ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase()),
                            },
                        },
                    },
                },
                merchantsInfo: {
                    type: 'object.subForm',
                    displayName: '<<szzj_1>>',
                    properties: {
                        merchantIds: {
                            type: 'array.merchant.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                            rules: [
                                {
                                    required: true,
                                    message: '<<pleaseSelectMerchant>>',
                                },
                            ],
                            controlConfig: {
                                subsiteField: 'subsiteInfo.subsiteId',
                                needDependencesMessage: '<<selectBeforeMerchant>>',
                                hideSubsiteColume: false,
                                customParams: {
                                    isContainsSelfMerchant: false,
                                },
                                customFields: [
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantTagId',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                                customColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'code',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        ProductSettingView: {
            component: 'Viewport',
            entity: 'ProductSettingEntity',
        },
        ProductSettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Text',
                    text: '<<mrspzlhjyxxsyzjdbxysh>>',
                    style: {
                        color: '#F00',
                        marginBottom: 10,
                    },
                },
                {
                    component: 'ProductSettingConfigsPageTabs',
                },
            ],
        },
        ProductSettingConfigsPageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<spzlsdsh>>',
                    content: {
                        component: 'ProductSettingInfoPage',
                    },
                    params: {
                        auditType: AuditTypeEnum.Info,
                    },
                },
                {
                    title: '<<spjysdsh>>',
                    content: {
                        component: 'ProductSettingBusinessPage',
                    },
                    params: {
                        auditType: AuditTypeEnum.Business,
                    },
                },
            ],
        },
        ProductSettingFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'merchantIds',
                },
            ],
        },
        ProductSettingInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ProductSettingFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 18,
                        paddingBottom: 24,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<zjsdshzj>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/productSetting/add/info',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ProductSettingInfoTable' },
            ],
        },
        ProductSettingInfoTable: {
            ...productSettingTable,
            params: {
                auditType: AuditTypeEnum.Info,
            },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'ENABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_audit_rules/:id/disable',
                                    config: {
                                        content: '<<ggzybgbcg>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<sfgbsdshgz>>?',
                                    },
                                },
                                {
                                    value: 'DISABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_audit_rules/:id/enable',
                                    config: {
                                        content: '<<ggzybkqcg>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<sdshgz>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/productSetting/edit/info/{{row.id}}',
                    },
                ],
            },
        },
        ProductSettingBusinessPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ProductSettingFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 18,
                        paddingBottom: 24,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<zjsdshzj>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/productSetting/add/business',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ProductSettingBusinessTable' },
            ],
        },
        ProductSettingBusinessTable: {
            ...productSettingTable,
            params: {
                auditType: AuditTypeEnum.Business,
            },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'ENABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_audit_rules/:id/disable',
                                    config: {
                                        content: '<<ggzybgbcg>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<sfgbsdshgz>>?',
                                    },
                                },
                                {
                                    value: 'DISABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_audit_rules/:id/enable',
                                    config: {
                                        content: '<<ggzybkqcg>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<sdshgz>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/productSetting/edit/business/{{row.id}}',
                    },
                ],
            },
        },
        ProductSettingInfoAddPage: {
            component: 'FlexLayout',
            entity: 'ProductSettingEntity',
            direction: 'horizontal',
            items: [
                {
                    component: 'ProductSettingAddFormView',
                    params: {
                        auditType: AuditTypeEnum.Info,
                    },
                },
            ],
        },
        ProductSettingBusinessAddPage: {
            component: 'FlexLayout',
            entity: 'ProductSettingEntity',
            direction: 'horizontal',
            items: [
                {
                    component: 'ProductSettingAddFormView',
                    params: {
                        auditType: AuditTypeEnum.Business,
                    },
                },
            ],
        },
        ProductSettingInfoEditPage: {
            component: 'FlexLayout',
            entity: 'ProductSettingEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                {
                    component: 'ProductSettingEditFormView',
                    params: {
                        auditType: AuditTypeEnum.Info,
                    },
                },
            ],
        },
        ProductSettingBusinessEditPage: {
            component: 'FlexLayout',
            entity: 'ProductSettingEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                {
                    component: 'ProductSettingEditFormView',
                    params: {
                        auditType: AuditTypeEnum.Business,
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/productSetting',
                    component: 'ProductSettingView',
                    breadcrumbName: '<<auditMerchantProduct>>',
                    privilege: {
                        path: 'productAuditRules',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/info',
                            component: 'ProductSettingInfoAddPage',
                            breadcrumbName: '<<xzsdshzj>>',
                        },
                        {
                            path: '/add/business',
                            component: 'ProductSettingBusinessAddPage',
                            breadcrumbName: '<<xzsdshzj>>',
                        },
                        {
                            path: '/edit/info/:id',
                            component: 'ProductSettingInfoEditPage',
                            breadcrumbName: '<<bjsdshzj>>',
                        },
                        {
                            path: '/edit/business/:id',
                            component: 'ProductSettingBusinessEditPage',
                            breadcrumbName: '<<bjsdshzj>>',
                        },
                        { path: '/', component: 'ProductSettingPage' },
                    ],
                },
            ],
        },
    ],
};
