import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import isFunction from 'lodash/isFunction';

/**
 *  自定义转换文本格式
 */
export class StringFormatterMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const { className, style, format } = displayInfo;
        let string = value;
        if (format && isFunction(format)) {
            string = format(value);
        }
        return (
            <span className={className} style={style}>
                {string}
            </span>
        );
    }
}
