import React from 'react';
import { modes } from '@comall-backend-builder/types';
import {
    GroupBuyingProductSelectorContainer,
    GroupBuyingProductSelectedDisplay,
} from '../../../components';

export class GroupBuyingProductsSelector extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <GroupBuyingProductSelectedDisplay
                data={value}
                modifiable={false}
                onChange={() => {}}
            />
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <GroupBuyingProductSelectorContainer {...displayInfo} />;
    }
}
