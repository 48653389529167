import React from 'react';
import { Select, InputNumber, DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import get from 'lodash/get';
import moment from 'moment';
import { ConditionType } from '../index';
import { MaotaiPresaleConditionsProps } from '../../index';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const dateFormat = 'YYYY-MM-DD';
interface RegistrationProps extends MaotaiPresaleConditionsProps {
    endText: string;
    index: number;
}
export default class Registration extends React.PureComponent<RegistrationProps> {
    conditionType = ConditionType.REGISTRATION_TIME;

    onSelectCardLevel = (val: number | undefined) => {
        const { onChange, value, index } = this.props;
        let list;
        if (
            !value.find(
                (item, itemIndex) =>
                    item.conditionType === this.conditionType && index === itemIndex
            )
        ) {
            list = [
                ...value,
                { conditionType: this.conditionType, conditionValue: { timeType: val } },
            ];
        } else {
            list = value.map((item, itemIndex) => {
                if (this.conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { ...item.conditionValue, timeType: val } };
                }
                return item;
            });
        }

        onChange(list);
    };

    inputChange = (val: number | undefined) => {
        const { onChange, value, index } = this.props;
        let list;
        if (
            !value.find(
                (item, itemIndex) =>
                    item.conditionType === this.conditionType && index === itemIndex
            )
        ) {
            list = [...value, { conditionType: this.conditionType, conditionValue: { num: val } }];
        } else {
            list = value.map((item, itemIndex) => {
                if (this.conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { ...item.conditionValue, num: val } };
                }
                return item;
            });
        }
        onChange(list);
    };

    dateChange = (val: any) => {
        const { onChange, value, index } = this.props;

        const dateRange =
            val && val.length
                ? [
                      moment(val[0] || undefined).format(dateFormat),
                      moment(val[1] || undefined).format(dateFormat),
                  ]
                : undefined;

        let list;
        if (
            !value.find(
                (item, itemIndex) =>
                    item.conditionType === this.conditionType && index === itemIndex
            )
        ) {
            list = [...value, { conditionType: this.conditionType, conditionValue: { dateRange } }];
        } else {
            list = value.map((item, itemIndex) => {
                if (this.conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { ...item.conditionValue, dateRange } };
                }
                return item;
            });
        }
        onChange(list);
    };
    options = [
        { id: 'STATIC', name: language.getText('gdrq') },
        { id: 'CUSTOM', name: language.getText('zdysj') },
    ];
    render() {
        const { value, endText } = this.props;
        const formData = value.find((i) => i.conditionType === this.conditionType);
        const timeType = get(formData, 'conditionValue.timeType', []);
        const num = get(formData, 'conditionValue.num', []);
        const dateRange = get(formData, 'conditionValue.dateRange', undefined);

        const dateValue: RangePickerValue | undefined =
            dateRange && dateRange.length
                ? [moment(dateRange[0]), moment(dateRange[1])]
                : undefined;
        return (
            <div>
                <Select
                    onChange={this.onSelectCardLevel}
                    placeholder={services.language.getText('selectPlease')}
                    style={{ width: 200 }}
                    value={timeType}
                >
                    {this.options.map((o) => (
                        <Select.Option key={o.id} value={o.id}>
                            {o.name}
                        </Select.Option>
                    ))}
                </Select>
                &nbsp; &nbsp;
                {timeType === 'STATIC' && (
                    <>
                        <span>{language.getText('hdksq')}</span> &nbsp; &nbsp;
                        <InputNumber
                            onChange={this.inputChange}
                            max={99999999}
                            min={1}
                            value={num}
                        ></InputNumber>
                        &nbsp; &nbsp;
                        <span>
                            {language.getText('tzqzcdyh')}
                            {endText}
                        </span>
                    </>
                )}
                {timeType === 'CUSTOM' && (
                    <>
                        <DatePicker.RangePicker
                            format={dateFormat}
                            onChange={this.dateChange}
                            value={dateValue}
                        ></DatePicker.RangePicker>
                        &nbsp; &nbsp;
                        <span>
                            {language.getText('UserRegisteredBetween')}
                            {endText}
                        </span>
                    </>
                )}
            </div>
        );
    }
}
