import { builder, Type } from '@comall-backend-builder/core';
import { formats } from '@comall-backend-builder/types';
import { ImageObjectArrayMode } from './array/image-object-array';
import { OptionsStringMode, OptionsStringFormat } from './array/options-string-array';
import { GroupSubsiteMode } from './string/group-subsite-mode';
import { GroupCouponMode } from './string/group-coupon-mode';
import { PageTemplateMode, PageTemplateFormat } from './object/page-template';
import { MerchantSelectMode, MerchantSelectFormat } from './object/merchant-select';
// Customized type example
builder.registerType('optionsString', new Type(new OptionsStringFormat(), new OptionsStringMode()));
builder.registerType(
    'groupSubsiteMode',
    new Type(new formats.StringFormat(), new GroupSubsiteMode())
);
builder.registerType(
    'groupCouponMode',
    new Type(new formats.StringFormat(), new GroupCouponMode())
);
builder.registerType(
    'imageObjectArray',
    new Type(new formats.ArrayNoValidationFormat(), new ImageObjectArrayMode())
);
builder.registerType('pageTemplate', new Type(new PageTemplateFormat(), new PageTemplateMode()));
builder.registerType(
    'merchantSelect',
    new Type(new MerchantSelectFormat(), new MerchantSelectMode())
);
