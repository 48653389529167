import React, { Component } from 'react';
import { actions } from '@comall-backend-builder/core';
import { connect } from 'react-redux';

import './index.less';

const CYBERSHOP_WEB_URL = ENV.CYBERSHOP_WEB_URL || window.location.origin;

/**
 * iframe 方式打开页面
 */
class IframeContent extends Component<{
    logout: () => void;
    origin: string;
}> {
    firstLoad = true;
    timer: any = null;
    readonly initIframeHeight = window.innerHeight - 150;

    onLoad = () => {
        const { logout } = this.props;
        const iframe: any = this.refs.iframe;
        // let maxContentRender = 10 * 1000;
        // const frequency = 500;
        if (!iframe.contentWindow) {
            return;
        }
        if (new URL(CYBERSHOP_WEB_URL).host !== window.location.host) {
            return;
        }
        /**
         * 处理iframe内登出
         */
        if (iframe.contentWindow.location.href.indexOf('unauthorized') !== -1) {
            logout();
            return;
        }
        /**
         * 处理iframe高度为内容撑开，避免react层有竖直滚动条
         */
        // this.timer = setInterval(() => {
        //     if (maxContentRender < 0 || !iframe.contentWindow) {
        //         clearInterval(this.timer);
        //         return;
        //     }
        //     maxContentRender = maxContentRender - frequency;
        //     let contentHeight = iframe.contentWindow.document.documentElement.scrollHeight + 30;
        //     const iframeHeight = parseInt(iframe.getAttribute('height'));
        //     if (contentHeight > this.initIframeHeight && iframeHeight !== contentHeight - 30) {
        //         iframe.setAttribute('height', contentHeight);
        //     }
        // }, frequency);

        setTimeout(() => {
            if (!iframe.contentWindow) {
                return;
            }
            let contentHeight = iframe.contentWindow.document.documentElement.scrollHeight + 30;
            const iframeHeight = parseInt(iframe.getAttribute('height'));
            if (contentHeight > this.initIframeHeight && iframeHeight !== contentHeight - 30) {
                iframe.setAttribute('height', contentHeight);
            }
        }, 3000);
    };

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    // shouldComponentUpdate(nextProps: any) {
    //     return nextProps.origin !== this.props.origin;
    // }

    render() {
        const { origin } = this.props;

        let src = CYBERSHOP_WEB_URL + origin;

        if (!this.firstLoad) {
            src += '?_=' + Math.random();
        } else {
            this.firstLoad = false;
        }

        return (
            <div className="iframe-box">
                <iframe
                    src={src}
                    frameBorder="0"
                    width="100%"
                    height={this.initIframeHeight}
                    ref="iframe"
                    onLoad={this.onLoad}
                    title={origin}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        logout() {
            dispatch(actions.logoutAction());
        },
    };
}

export const IframeViewport = connect(null, mapDispatchToProps)(IframeContent);
