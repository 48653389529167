import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { ObjectBargainProductOrderGoodsSelect } from './object-bargain-products-or-good-select';

export class ObjectBargainProductsOrGoodSelectMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ObjectBargainProductOrderGoodsSelect {...controlInfo} />;
    }
}
