import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { ThemeStyleNavigationSelect } from './theme-style-navigation-selector';

export class ObjectThemeStylenNavigationSelectorMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ThemeStyleNavigationSelect {...controlInfo} />;
    }
}
