import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { isEmpty, get, uniq } from 'lodash';

export const config: Config = {
    entities: {
        LuckyRedPackageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/lucky_red_package',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },

                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<gzpz>>',
                    properties: {
                        conditionLowerValue: {
                            type: 'number.tip',
                            displayName: '<<cymj>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<jzcohzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '<<xfm>>',
                                addonafter: '<<ykfx>>',
                                tip: '<<wsyddjnfx>>',
                            },
                        },
                        totalAmount: {
                            type: 'number.tip',
                            displayName: '<<hbzje>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 1,
                                    message: '<<jzczzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonafter: '<<yuan>>',
                                tip: '',
                            },
                        },
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<hbgs>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 1,
                                    max: 50,
                                    message: '<<jzcdzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (reg.test(value) && value <= 50) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonafter: '个',
                                tip: '',
                            },
                            extra: '<<ydzsbje>>',
                        },
                        crowdScope: {
                            type: 'string.options.radio',
                            displayName: '<<lqrxz>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<qbyh>>' },
                                { id: 'NEWMEMBER', name: '<<xk>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            extra: '<<tnwzfdddyh>>',
                        },
                        rewardRule: {
                            type: 'string.options.radio',
                            displayName: '<<fpfs>>',
                            defaultValue: 'RANDOM',
                            options: [
                                // { id: 'APPOINTORMAX', name: '指定第X人最大' },
                                // { id: 'AVERAGE', name: '平均分配' },
                                { id: 'RANDOM', name: '<<sjfp>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            //指定第X个人最大：则指定了第几个领取的人金额最大，其他人根据设置的最小红包和最大红包进行随机分配；平均分配：每个人分到的金额都是一样的；
                            extra: '<<gjhbzjehhbgs>>',
                        },
                        maxPosition: {
                            type: 'number.tip',
                            displayName: '<<zdhbwz>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '<<d_8>>',
                                addonafter: '<<ge>>',
                                tip: '',
                            },
                        },
                        maxRedPackageAmount: {
                            type: 'number.tip',
                            displayName: '<<maxRedPackageAmount>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '',
                                addonafter: '<<yuan>>',
                                tip: '',
                            },
                        },
                        activityPeriodRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<mrmtkqgs>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<jzcohzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '',
                                addonafter: '',
                                tip: '<<wbxzmtqsqhbsl>>',
                            },
                        },
                        stopReceiveDays: {
                            type: 'number.tip',
                            displayName: '<<stopReceiveDays>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<jzcohzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '',
                                addonafter: '<<thbkzlqhb>>',
                                tip: '<<szkyfxbzdhblwcjs>>',
                            },
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<qsypz>>',
                    properties: {
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const selectMode = row.productInfo?.selectMode;
                                    let subsiteIds: string = '';
                                    if (selectMode === 'SUB_SITE') {
                                        subsiteIds =
                                            row.productInfo?.subsite?.length &&
                                            row.productInfo.subsite
                                                .map((item: any) => item.id)
                                                .join(',');
                                    }
                                    if (selectMode === 'MERCHANT') {
                                        subsiteIds = uniq(
                                            row.productInfo?.shop?.length &&
                                                row.productInfo.shop.map(
                                                    (item: any) => item.subSiteId
                                                )
                                        ).join(',');
                                    }
                                    return { subsiteIds };
                                },
                            },
                        },
                        couponName: {
                            type: 'string',
                            displayName: '<<lqhdyhqmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrlqhdyhqmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrlqhdyhqmczcz>>',
                                maxLength: 10,
                                style: {
                                    width: 300,
                                },
                            },
                            extra: '',
                        },
                        conditionLowerValue: {
                            type: 'number.tip',
                            displayName: '<<useThreshold>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<jzcohzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '<<xfm>>',
                                addonafter: '<<yky>>',
                                tip: '<<zmrdyyhqjeky>>',
                            },
                        },
                        validityPeriod: {
                            type: 'number.tip',
                            displayName: '<<lqhyxq>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<jzcohzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                addonbefore: '',
                                addonafter: '<<day>>',
                                tip: '<<zdbyhqlqhdtyx>>',
                            },
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<merchantAndSubsiteSelect>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                        shop: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<qbspbksxmdhzjdsysp>>',
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL'],
                                productDisplaySelectValueType: 'goods',
                            },
                        },
                        consumeChannel: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<usedChannelLimit>>',
                            options: [
                                { id: '1', name: '<<xsscsy>>' },
                                { id: '2', name: '<<xxddhx>>' },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<scpz>>',
                    properties: {
                        shareTitle: {
                            type: 'string',
                            displayName: '<<zfbt>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrzfbt>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrzfbt28>>',
                                maxLength: 28,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        paymentFinishPicture: {
                            displayName: '<<zfwddctp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit300>>',
                        },
                        orderDetailPicture: {
                            displayName: '<<ddxqyrkt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit128>>',
                        },
                        forwardPicture: {
                            displayName: '<<zftp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<tpccjyw300>>',
                        },
                        activityPageBottomPicture: {
                            displayName: '<<hdydt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit1000>>',
                        },
                        activityPageButtonPicture: {
                            displayName: '<<hdyant>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit550>>',
                        },
                        activityRuleBackgroundColor: {
                            displayName: '<<hdgzbjs>>',
                            type: 'string.color',
                            controlConfig: {
                                originalColor: '#f00',
                            },
                        },
                        pictureExample: {
                            displayName: '<<tpzy>>',
                            type: 'string.pictureExampleButton',
                            controlConfig: {
                                type: 'luckyRedPackage',
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qsrhdgz>>',
                                    validator: (rule: any, value: any) => {
                                        if (isEmpty(value) || value === '<p><br></p>') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                                {
                                    whitespace: true,
                                    max: 20000,
                                    message: '<<notMoreThan2000>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<xdshbgnkq>>',
                        },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        LuckyRedPackageView: {
            component: 'Viewport',
            entity: 'LuckyRedPackageEntity',
        },
        LuckyRedPackagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'LuckyRedPackageFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/lucky-red-package/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'LuckyRedPackageTable' },
            ],
        },
        LuckyRedPackageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        LuckyRedPackageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/lucky_red_package_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/lucky_red_package_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/lucky-red-package/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/lucky_red_package_activities/{{row.id}}/export.xls',
                        },
                    },
                ],
            },
        },
        LuckyRedPackageAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'LuckyRedPackageAddForm' }],
        },
        LuckyRedPackageAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'LuckyRedPackageEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.conditionLowerValue' },
                { property: 'ruleInfo.totalAmount' },
                { property: 'ruleInfo.activityRewardLimit' },
                { property: 'ruleInfo.crowdScope' },
                { property: 'ruleInfo.rewardRule' },
                { property: 'ruleInfo.activityPeriodRewardLimit' },
                { property: 'ruleInfo.stopReceiveDays' },
                { property: 'productInfo.couponName' },
                { property: 'productInfo.conditionLowerValue' },
                { property: 'productInfo.validityPeriod' },
                { property: 'productInfo.selectMode' },
                {
                    property: 'productInfo.shop',
                    visible: (values: any) => get(values, 'productInfo.selectMode') === 'MERCHANT',
                    controlConfig: {
                        fields: [
                            { property: 'subSiteName' },
                            { property: 'merchantTagId' },
                            { property: 'merchantName' },
                        ],
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                {
                    property: 'productInfo.subsite',
                    visible: (values: any) => get(values, 'productInfo.selectMode') === 'SUB_SITE',
                },

                { property: 'productInfo.activityProductType' },
                {
                    property: 'productInfo.products',
                    visible: (values: any) => {
                        const activityProductType = get(values, 'productInfo.activityProductType');
                        if (
                            activityProductType === 'INCLUDE_PRODUCT' ||
                            activityProductType === 'EXCLUDE_PRODUCT'
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'productInfo.consumeChannel' },
                {
                    property: 'productInfo.marketingSchedule',
                },
                { property: 'pictureInfo.shareTitle' },
                { property: 'pictureInfo.paymentFinishPicture' },
                { property: 'pictureInfo.orderDetailPicture' },
                { property: 'pictureInfo.forwardPicture' },
                { property: 'pictureInfo.activityPageBottomPicture' },
                { property: 'pictureInfo.activityPageButtonPicture' },
                { property: 'pictureInfo.activityRuleBackgroundColor' },
                { property: 'pictureInfo.pictureExample' },
                { property: 'pictureInfo.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                // 根据门店/专柜选择
                const selectMode = entity.productInfo.selectMode;
                const merchants = entity.productInfo.shop;
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                const isMerchantsEmpty = !merchants || !merchants.length;
                if (selectMode === 'SUB_SITE' && isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                if (selectMode === 'MERCHANT' && isMerchantsEmpty) {
                    AntMessage.warn(services.language.getText('pleaseSelectMerchant'));
                    return false;
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        LuckyRedPackageEditPage: {
            component: 'FlexLayout',
            entity: 'LuckyRedPackageEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'LuckyRedPackageEditForm' }],
        },
        LuckyRedPackageEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'LuckyRedPackageEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.conditionLowerValue' },
                {
                    property: 'ruleInfo.totalAmount',
                    controlConfig: {
                        style: { width: '150px' },
                        addonafter: '<<yuan>>',
                        tip: '',
                        disabled: true,
                    },
                },
                {
                    property: 'ruleInfo.activityRewardLimit',
                    controlConfig: {
                        disabled: true,
                        style: { width: '150px' },
                        addonafter: '个',
                        tip: '',
                    },
                },
                { property: 'ruleInfo.crowdScope' },
                { property: 'ruleInfo.rewardRule' },
                { property: 'ruleInfo.activityPeriodRewardLimit' },
                { property: 'ruleInfo.stopReceiveDays' },
                {
                    property: 'productInfo.couponName',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'productInfo.conditionLowerValue',
                    controlConfig: {
                        disabled: true,
                        style: { width: '150px' },
                        addonbefore: '<<xfm>>',
                        ddonafter: '<<yky>>',
                        tip: '<<zmrdyyhqjeky>>',
                    },
                },
                {
                    property: 'productInfo.validityPeriod',
                    controlConfig: {
                        disabled: true,
                        style: { width: '150px' },
                        addonbefore: '',
                        addonafter: '<<day>>',
                        tip: '<<zdbyhqlqhdtyx>>',
                    },
                },
                {
                    property: 'productInfo.selectMode',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'productInfo.shop',
                    modifiable: false,
                    visible: (values: any) => get(values, 'productInfo.selectMode') === 'MERCHANT',
                },
                {
                    property: 'productInfo.subsite',
                    modifiable: false,
                    visible: (values: any) => get(values, 'productInfo.selectMode') === 'SUB_SITE',
                },

                { property: 'productInfo.activityProductType', modifiable: false },
                {
                    property: 'productInfo.products',
                    modifiable: false,
                    visible: (values: any) => {
                        const activityProductType = get(values, 'productInfo.activityProductType');
                        if (
                            activityProductType === 'INCLUDE_PRODUCT' ||
                            activityProductType === 'EXCLUDE_PRODUCT'
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'productInfo.consumeChannel',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'productInfo.marketingSchedule',
                },
                { property: 'pictureInfo.shareTitle' },
                { property: 'pictureInfo.paymentFinishPicture' },
                { property: 'pictureInfo.orderDetailPicture' },
                { property: 'pictureInfo.forwardPicture' },
                { property: 'pictureInfo.activityPageBottomPicture' },
                { property: 'pictureInfo.activityPageButtonPicture' },
                { property: 'pictureInfo.activityRuleBackgroundColor' },
                { property: 'pictureInfo.pictureExample' },
                { property: 'pictureInfo.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/lucky-red-package',
                    component: 'LuckyRedPackageView',
                    breadcrumbName: '<<psqhb>>',
                    privilege: {
                        path: 'luckyRedPackage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'LuckyRedPackageAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'LuckyRedPackageEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'LuckyRedPackagePage' },
                    ],
                },
            ],
        },
    ],
};
