import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ProductSettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/product_auto_audit_rules';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/product_auto_audit_rules/${id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    item.merchants.map((merchant: any) => {
                        merchant.name = merchant.merchantName;
                        return merchant;
                    });
                    return item;
                });
            } else {
                res = {
                    ...res,
                    merchantsInfo: {
                        shop: res.merchants || [],
                    },
                    ruleInfo: {
                        productAutoAudit: [res.productAutoAudit],
                        goodsAutoAudit: [res.goodsAutoAudit],
                    },
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/product_auto_audit_rules';
        let newParams: any = {};
        if (params.merchantsInfo) {
            newParams.merchants = params.merchantsInfo.shop.map(
                (item: { merchantId: string; name: string }) => ({ id: item.merchantId })
            );
        }
        if (params.ruleInfo) {
            newParams.productAutoAudit = params.ruleInfo.productAutoAudit[0] || 'NO';
            newParams.goodsAutoAudit = params.ruleInfo.goodsAutoAudit[0] || 'NO';
        }

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/product_auto_audit_rules/${id}`;
        let newParams: any = {};
        if (params.merchantsInfo) {
            newParams.merchants = params.merchantsInfo.shop.map(
                (item: { merchantId: string; name: string }) => ({ id: item.merchantId })
            );
        }
        if (params.ruleInfo) {
            newParams.productAutoAudit = params.ruleInfo.productAutoAudit[0] || 'NO';
            newParams.goodsAutoAudit = params.ruleInfo.goodsAutoAudit[0] || 'NO';
        }

        return await api.put(newParams, config);
    },
};
