import React, { Component } from 'react';
import { ColorPickerPlus } from '../../../../components';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export class ThemeStyleFontSelect extends Component<any, any> {
    componentDidMount() {
        const { params, onChange } = this.props;
        if (!params || !params.id) {
            const value = {
                primary: '#007046',
                secondary: '#ab8e66',
            };
            onChange(value);
        }
    }
    onChange = (color: string, type: 'primary' | 'secondary') => {
        const { value, onChange } = this.props;
        const newValue = Object.assign({}, value);
        newValue[type] = color;
        onChange(newValue);
    };
    render() {
        const {
            value = {
                primary: '#007046',
                secondary: '#ab8e66',
            },
            params,
        } = this.props;
        return (
            <div className="theme-style-font-selector">
                <div className="row">
                    <div className="title">{language.getText('mainColor')}</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'primary');
                                },
                                value: value.primary,
                                params,
                            } as any)}
                        />
                    </div>
                    <div className="color-txt">{value.primary}</div>
                </div>
                <div className="explain">{language.getText('applyIconFont')}</div>
                <div className="row">
                    <div className="title">{language.getText('auxiliaryColor')}</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'secondary');
                                },
                                value: value.secondary,
                                params,
                            } as any)}
                        />
                    </div>
                    <div className="color-txt">{value.secondary}</div>
                </div>
                <div className="explain">{language.getText('applyPriceFont')}</div>
            </div>
        );
    }
}
