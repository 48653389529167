import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

const api = services.api;
export const OrderCommentsLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/reviewAudit/find';
        if (isEmpty(data.mobile)) {
            delete data.mobile;
        }
        if (isEmpty(data.productName)) {
            delete data.productName;
        }
        if (isEmpty(data.orderNo)) {
            delete data.orderNo;
        }
        return await api.get(data, config);
    },
};
