import React, { Component, CSSProperties, Fragment } from 'react';
import { Button, message as AntMessage, Modal } from 'antd';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { WechatOfficialAutoReplySettingPageStoreSelector } from '../components/wechat-official-auto-reply-setting-page-store-selector';
import { cloneDeep, debounce, forEach } from 'lodash';
import { WechatCustomMenusSettingRightPage } from './wechat-custom-menus-setting-right-page';
import {
    AutoReplyVo,
    CrowdScopeEnum,
    MenuContentType,
    SubMenuChidrenVo,
    SubMenuVo,
} from '../components/wechant-content';
import { Subsite } from '../../cgb-config-select-subsite';
import { checkLinkTypeParamsRequired } from '../../../types/format';
import { FunctionIdType, getAuthRecordErrorTip } from '../wechat-official-auto-reply-setting-page';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface States {
    /**
     * 是否已选择门店
     */
    subsite?: {
        id: number;
        name: string;
    };
    /**
     * 公众号appId
     */
    appId?: string;
    appName?: string;
    /**
     * 回复内容设置
     */
    menuContents: Array<SubMenuVo>;
    /**
     * 权限不满足提示语
     */
    recordErrorTip: string;
    /**
     * 正在修改的内容
     */
    currentMenuContentIndex: number;
    currentMenuContentChidrenIndex: number;
    linkTypeSubsite: Subsite[];
}

const defalutMenuContent = {
    type: MenuContentType.MINIPROGRAM,
    appid: '{miniprogram-appid}',
    url: '',
    pagepath: '',
    target: {
        linkType: '',
        linkParams: '',
        linkName: '',
        linkPath: '',
    },
};

export class WechatCustomMenusSettingPage extends Component<any, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            appId: undefined,
            menuContents: [],
            recordErrorTip: '',
            currentMenuContentIndex: 0,
            currentMenuContentChidrenIndex: -1,
            linkTypeSubsite: [],
        };
    }

    componentDidMount() {
        this.loadSubsiteInfo();
    }

    //获取目前选择的门店，先查是否有门店权限，然后查是否之前选择过门店
    loadSubsiteInfo = async () => {
        const subsiteListResult: any = await api.get({}, { apiPath: '/admin/v1/subsites/mine' });
        if (subsiteListResult && subsiteListResult.result && subsiteListResult.result.length > 0) {
            this.setState(
                {
                    subsite: {
                        id: subsiteListResult.result[0].id,
                        name: subsiteListResult.result[0].name,
                    },
                },
                () => {
                    this.loadWechatOfficialAccount();
                }
            );
        }
    };

    loadLinkTypeSubsite = async () => {
        const { appId } = this.state;
        //1.获取公众号对应的门店信息
        const subsiteResult: any = await api.get(
            {
                appId: appId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: `/admin/wechat_official_accounts/subsites`,
            }
        );
        this.setState({
            linkTypeSubsite: subsiteResult,
        });
    };
    loadWechatOfficialAccount = async () => {
        const { subsite } = this.state;
        if (subsite && subsite.id) {
            //1.获取门店对应的公众号信息
            const amountResult: any = await api.get(
                {
                    subsiteId: subsite.id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                }
            );
            if (amountResult && amountResult.appId) {
                this.setState(
                    {
                        appId: amountResult.appId,
                        appName: amountResult.appName,
                    },
                    () => {
                        this.loadLinkTypeSubsite();
                    }
                );
                const validFunctions = [
                    FunctionIdType.MESSAGE,
                    FunctionIdType.NOTIFICATION,
                    FunctionIdType.SOURCEMATERIAL,
                    FunctionIdType.MENU,
                ];
                const tip = await getAuthRecordErrorTip(amountResult.appId, validFunctions);
                if (tip) {
                    this.setState({
                        recordErrorTip: tip,
                    });
                } else {
                    this.loadMenuContentsInfo();
                }
            } else {
                this.setState({
                    appId: '',
                    appName: '',
                    menuContents: [],
                });
            }
        }
    };

    loadMenuContentsInfo = async () => {
        const { appId } = this.state;
        if (!appId) {
            return;
        }
        //获取公众号是否已经配置自定义菜单
        const messageResult: any = await api.get(
            { appId: appId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_menus',
            }
        );
        if (messageResult && messageResult.length > 0) {
            const newMessageResult = messageResult.map((c: any) => {
                c.menuContent = JSON.parse(c.menuContent);
                c.subMenus = c.subMenus
                    ? c.subMenus.map((s: any) => {
                          s.menuContent = JSON.parse(s.menuContent);
                          return s;
                      })
                    : [];
                return c;
            });
            this.setState({
                menuContents: newMessageResult,
            });
        }
    };

    getErrorTip = (c: SubMenuVo | SubMenuChidrenVo) => {
        if (!c.name) {
            return services.language.getText('qsrcdmc');
        }
        if (!c.menuContent) {
            return services.language.getText('qsrcdnr');
        }
        if (c.menuContent.type === MenuContentType.VIEW) {
            if (!c.menuContent.url) {
                return services.language.getText('qsrcdwz');
            }
            if (c.menuContent.url.length > 1024) {
                return services.language.getText('wzzdzdxz_1');
            }
            const urlVerify = /^(https?:\/\/)/;
            const isUrl = urlVerify.test(c.menuContent.url);
            if (!isUrl) {
                return services.language.getText('sryhttp');
            }
        }
        if (c.menuContent.type === MenuContentType.MINIPROGRAM) {
            if (c.menuContent.target) {
                if (!c.menuContent.subsiteId) {
                    return services.language.getText('selectSubsite');
                }
                //我方小程序
                if (!c.menuContent.target.linkType) {
                    return services.language.getText('qxzljlx');
                }
                if (
                    !checkLinkTypeParamsRequired(
                        c.menuContent.target.linkType,
                        c.menuContent.target.linkParams
                    )
                ) {
                    return services.language.getText('selectLinkTarget');
                }
            } else {
                //它方小程序
                if (!c.menuContent.appid) {
                    return `${services.language.getText('qsrxcx')}APPID`;
                }
                if (!c.menuContent.pagepath) {
                    return services.language.getText('qsrxcxwz');
                }
            }
        }
        if (c.menuContent.type === MenuContentType.CLICK) {
            //1.判断回复内容
            if (!c.menuContent.replyContents || c.menuContent.replyContents.length === 0) {
                return services.language.getText('qtjhfnr');
            }
            //2.判断会员等级有待选择的
            let errTip = '';
            forEach(c.menuContent.replyContents, (c) => {
                if (!c.crowdScope) {
                    errTip = services.language.getText('qxzhfnrrq');
                    return false;
                }
                if (!c.messageContent || c.messageContent.length === 0) {
                    errTip = services.language.getText('qtjhfnr');
                    return false;
                }
            });
            if (errTip) {
                return errTip;
            }
            //3.判断人群有没有重复的
            const allList = c.menuContent.replyContents
                ? c.menuContent.replyContents.filter(
                      (vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.ALL
                  )
                : [];
            const memberList = c.menuContent.replyContents
                ? c.menuContent.replyContents.filter(
                      (vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.MEMBER
                  )
                : [];
            const noMemberList = c.menuContent.replyContents
                ? c.menuContent.replyContents.filter(
                      (vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.NON_MEMBER
                  )
                : [];
            if (allList.length > 1 || memberList.length > 1 || noMemberList.length > 1) {
                return services.language.getText('mlrqzdznszythfnr');
            }
            if (allList.length > 0 && (memberList.length > 0 || noMemberList.length > 0)) {
                return services.language.getText('xzlrqwqbfsh_1');
            }
        }
        return '';
    };

    submitVali = () => {
        const { menuContents } = this.state;
        const length = menuContents.length;
        if (length === 0) {
            AntMessage.warn(
                services.language.getText('qnxbjcd') + services.language.getText('zsyyygcd')
            );
            return false;
        }
        let errTip = '';
        forEach(menuContents, (c, index) => {
            //有子菜单时，无需校验主菜单右侧的配置
            if (c.subMenus && c.subMenus.length > 0) {
                forEach(c.subMenus, (j, jindex) => {
                    const tip = this.getErrorTip(j);
                    if (tip) {
                        errTip = `${services.language.getText('d_8')}${index +
                            1}${services.language.getText('gzcdxdd')}${jindex +
                            1}${services.language.getText('gzcdcwxx_1')}：${tip}`;
                        return false;
                    }
                });
            } else {
                const tip = this.getErrorTip(c);
                if (tip) {
                    errTip = `${services.language.getText('d_8')}${index +
                        1}${services.language.getText('gzcdcwxx')}：${tip}`;
                    return false;
                }
            }
        });
        if (errTip) {
            AntMessage.warn(errTip);
            return false;
        }
        return true;
    };

    isSubmitReply = false;

    submit = debounce(() => {
        const { appId, menuContents } = this.state;
        //判断添加条件
        const vali = this.submitVali();
        if (!vali) {
            return;
        }
        if (this.isSubmitReply) {
            return false;
        }
        this.isSubmitReply = true;
        const newMenuContents = cloneDeep(menuContents);
        const newCommands = newMenuContents.map((c: any) => {
            c.menuContent = JSON.stringify(c.menuContent);
            c.subMenus =
                c.subMenus && c.subMenus.length > 0
                    ? c.subMenus.map((s: any) => {
                          s.menuContent = JSON.stringify(s.menuContent);
                          return s;
                      })
                    : [];
            return c;
        });
        this.isSubmitReply = false;
        api.post(newCommands, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
            apiPath: `/admin/wechat_official_account_menus?app_id=${appId}`,
        })
            .then(() => {
                AntMessage.success(services.language.getText('fbcg'));
                setTimeout(() => {
                    this.loadMenuContentsInfo();
                }, 2000);
            })
            .catch(errorHandle)
            .finally(() => {
                setTimeout(() => {
                    this.isSubmitReply = false;
                }, 3000);
            });
    }, 500);

    onChangeSubsite = (subsites: any[]) => {
        if (subsites && subsites.length > 0) {
            this.setState(
                {
                    subsite: {
                        id: subsites[0].id,
                        name: subsites[0].name,
                    },
                    appId: undefined,
                    menuContents: [],
                    recordErrorTip: '',
                    currentMenuContentIndex: 0,
                    currentMenuContentChidrenIndex: -1,
                },
                () => {
                    this.loadWechatOfficialAccount();
                }
            );
        }
    };

    renderTip = () => {
        return (
            <div className="tips">
                <div>{services.language.getText('syxz')}</div>
                <div>
                    1.{services.language.getText('jhlkjdqzzsxdcdtbzht_1')},
                    {services.language.getText('jhlkjdqzzsxdcdtbzht')}
                </div>
                <div>
                    2.{services.language.getText('tzxcxs')},
                    {services.language.getText('xcxbxggzhsglgx')}。
                </div>
                <div>
                    3.{services.language.getText('rgxylkckxg')},
                    {services.language.getText('rgxylkckxg_1')}。
                </div>
            </div>
        );
    };

    renderFooter = () => {
        return (
            <div className="footer">
                <Button onClick={this.submit} className="footer__button" type="primary">
                    {services.language.getText('bcbfbcd')}
                </Button>
            </div>
        );
    };

    addMenuContents = () => {
        const { menuContents } = this.state;
        menuContents.push({
            name: services.language.getText('cdmc'),
            menuContent: defalutMenuContent,
            subMenus: [],
        });
        this.setState({
            menuContents: cloneDeep(menuContents),
            currentMenuContentIndex: menuContents.length - 1,
            currentMenuContentChidrenIndex: -1,
        });
    };

    addMenuContentsChidrenEvent = (index: number) => {
        const { menuContents } = this.state;
        menuContents[index].subMenus.push({
            name: services.language.getText('zcdmc'),
            menuContent: defalutMenuContent,
        });
        this.setState({
            menuContents: cloneDeep(menuContents),
            currentMenuContentIndex: index,
            currentMenuContentChidrenIndex: menuContents[index].subMenus.length - 1,
        });
    };
    addMenuContentsChidren = (index: number) => {
        const { menuContents } = this.state;
        if (!menuContents[index].subMenus || menuContents[index].subMenus.length === 0) {
            Modal.confirm({
                title: language.getText('tjzcdh'),
                onOk: () => {
                    this.addMenuContentsChidrenEvent(index);
                },
                okText: language.getText('common.confirm'),
                cancelText: language.getText('common.cancel'),
            });
        } else {
            this.addMenuContentsChidrenEvent(index);
        }
    };

    setCurrentIndex = (index: number, cindex: number) => {
        this.setState({
            currentMenuContentIndex: index,
            currentMenuContentChidrenIndex: cindex,
        });
    };

    deleteMenu = (type: string, index: number, cIndex: number) => {
        let { menuContents } = this.state;
        Modal.confirm({
            title: services.language.getText('sfqrscgcdxpzdqbnr'),
            onOk: () => {
                if (type === 'delete-parent') {
                    menuContents.splice(index, 1);
                } else if (type === 'delete-chidren' && cIndex > -1) {
                    menuContents[index].subMenus.splice(cIndex, 1);
                }
                this.setState({
                    menuContents: cloneDeep(menuContents),
                    currentMenuContentIndex: type === 'delete-parent' ? -1 : index,
                    currentMenuContentChidrenIndex: -1,
                });
            },
            okText: language.getText('common.confirm'),
            cancelText: language.getText('common.cancel'),
        });
    };

    changeMenu = (type: string, index: number, cIndex: number) => {
        let { menuContents } = this.state;
        let currentMenuContentIndex = -1;
        let currentMenuContentChidrenIndex = -1;
        if (type === 'qian-parent') {
            const newIndex = index - 1;
            const info = menuContents[index];
            menuContents.splice(index, 1);
            menuContents.splice(newIndex, 0, info);
            currentMenuContentIndex = newIndex;
        }
        if (type === 'hou-parent') {
            const info = menuContents.splice(index, 1)[0];
            menuContents.splice(index + 1, 0, info);
            currentMenuContentIndex = index + 1;
        }

        if (type === 'hou-chidren' && cIndex > -1) {
            const newcIndex = cIndex + 1;
            const info = menuContents[index].subMenus[cIndex];
            menuContents[index].subMenus.splice(cIndex, 1);
            menuContents[index].subMenus.splice(newcIndex, 0, info);
            currentMenuContentIndex = index;
            currentMenuContentChidrenIndex = newcIndex;
        }
        if (type === 'qian-chidren' && cIndex > -1) {
            const info = menuContents[index].subMenus.splice(cIndex, 1)[0];
            menuContents[index].subMenus.splice(cIndex - 1, 0, info);
            currentMenuContentIndex = index;
            currentMenuContentChidrenIndex = cIndex - 1;
        }
        this.setState(
            {
                menuContents: cloneDeep(menuContents),
            },
            () => {
                //必须在menuContents改变之后，不然栏目锁定不随着变
                this.setState({
                    currentMenuContentIndex: currentMenuContentIndex,
                    currentMenuContentChidrenIndex: currentMenuContentChidrenIndex,
                });
            }
        );
    };

    renderBox = () => {
        const {
            menuContents,
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
        } = this.state;
        if (!menuContents || menuContents.length === 0) {
            return (
                <div className="left-menu-main">
                    <div className="left-menu-bottom">
                        <div className="left-menu-item" onClick={this.addMenuContents}>
                            +
                        </div>
                    </div>
                </div>
            );
        }
        const deleteImage = require('./images/icon-delete.png');
        const qianImage = require('./images/icon-qian.png');
        const houImage = require('./images/icon-hou.png');
        const shangImage = require('./images/icon-shang.png');
        const xiaImage = require('./images/icon-xia.png');
        return (
            <div className="left-menu-main">
                {menuContents.map((menuContent, index) => {
                    let style: CSSProperties = {
                        border: '1px solid #eee',
                    };
                    if (
                        currentMenuContentIndex === index &&
                        currentMenuContentChidrenIndex === -1
                    ) {
                        style = {
                            border: '1px solid #07C160',
                            color: '#07C160',
                            backgroundColor: '#F2FCF7',
                        };
                    }
                    const lastIndex = menuContents.length - 1;
                    return (
                        <div className="left-menu-bottom" key={index}>
                            <div
                                className="left-menu-item"
                                style={style}
                                onClick={this.setCurrentIndex.bind(this, index, -1)}
                            >
                                <span className="left-menu-item-name">{menuContent.name}</span>
                                {currentMenuContentIndex === index &&
                                    currentMenuContentChidrenIndex === -1 && (
                                        <div
                                            className="left-menu-item-line"
                                            style={
                                                currentMenuContentIndex === 1
                                                    ? { left: '-25px' }
                                                    : undefined
                                            }
                                        >
                                            {index !== 0 && (
                                                <div
                                                    onClick={this.changeMenu.bind(
                                                        this,
                                                        'qian-parent',
                                                        index,
                                                        -1
                                                    )}
                                                >
                                                    <img
                                                        className="icon-image"
                                                        alt=""
                                                        src={qianImage}
                                                    />
                                                </div>
                                            )}
                                            <div
                                                onClick={this.deleteMenu.bind(
                                                    this,
                                                    'delete-parent',
                                                    index,
                                                    -1
                                                )}
                                            >
                                                <img
                                                    className="icon-image"
                                                    alt=""
                                                    src={deleteImage}
                                                />
                                            </div>
                                            {index !== lastIndex && (
                                                <div
                                                    onClick={this.changeMenu.bind(
                                                        this,
                                                        'hou-parent',
                                                        index,
                                                        -1
                                                    )}
                                                >
                                                    <img
                                                        className="icon-image"
                                                        alt=""
                                                        src={houImage}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                            </div>
                            {currentMenuContentIndex === index && (
                                <div className="left-submenu">
                                    {menuContent.subMenus &&
                                        menuContent.subMenus.map((chidren, cindex) => {
                                            let chidrenStyle: CSSProperties = {
                                                border: '1px solid #eee',
                                            };
                                            if (currentMenuContentChidrenIndex === cindex) {
                                                chidrenStyle = {
                                                    border: '1px solid #07C160',
                                                    color: '#07C160',
                                                    backgroundColor: '#F2FCF7',
                                                };
                                            }
                                            const lastCIndex = menuContent.subMenus.length - 1;
                                            return (
                                                <div key={cindex} className="left-menu-bottom">
                                                    <div
                                                        className="left-menu-item"
                                                        style={chidrenStyle}
                                                        onClick={this.setCurrentIndex.bind(
                                                            this,
                                                            index,
                                                            cindex
                                                        )}
                                                    >
                                                        <span className="left-menu-item-name">
                                                            {chidren.name}
                                                        </span>
                                                        {currentMenuContentChidrenIndex ===
                                                            cindex && (
                                                            <div className="left-menu-item-chidren-line-list">
                                                                <div className="left-menu-item-chidren-line">
                                                                    {currentMenuContentChidrenIndex !==
                                                                        0 && (
                                                                        <div
                                                                            onClick={this.changeMenu.bind(
                                                                                this,
                                                                                'qian-chidren',
                                                                                index,
                                                                                cindex
                                                                            )}
                                                                        >
                                                                            <img
                                                                                className="icon-image"
                                                                                alt=""
                                                                                src={shangImage}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        onClick={this.deleteMenu.bind(
                                                                            this,
                                                                            'delete-chidren',
                                                                            index,
                                                                            cindex
                                                                        )}
                                                                    >
                                                                        <img
                                                                            className="icon-image"
                                                                            alt=""
                                                                            src={deleteImage}
                                                                        />
                                                                    </div>
                                                                    {currentMenuContentChidrenIndex !==
                                                                        lastCIndex && (
                                                                        <div
                                                                            onClick={this.changeMenu.bind(
                                                                                this,
                                                                                'hou-chidren',
                                                                                index,
                                                                                cindex
                                                                            )}
                                                                        >
                                                                            <img
                                                                                className="icon-image"
                                                                                alt=""
                                                                                src={xiaImage}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {menuContent.subMenus && menuContent.subMenus.length < 5 && (
                                        <div className="left-menu-bottom">
                                            <div
                                                className="left-menu-item"
                                                onClick={this.addMenuContentsChidren.bind(
                                                    this,
                                                    index
                                                )}
                                            >
                                                +
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
                {menuContents.length < 3 && (
                    <div className="left-menu-bottom">
                        <div className="left-menu-item" onClick={this.addMenuContents}>
                            +
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderLeft = () => {
        const { appName } = this.state;
        return (
            <div className="left">
                <div className="left-top">{appName}</div>
                <div className="left-box">{this.renderBox()}</div>
            </div>
        );
    };

    onChangeMenuContents = (value: Array<SubMenuVo>) => {
        this.setState({
            menuContents: value,
        });
    };

    renderRight = () => {
        const {
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
            menuContents,
            appId,
            linkTypeSubsite,
            subsite,
        } = this.state;
        if (!appId) {
            return null;
        }
        return (
            <WechatCustomMenusSettingRightPage
                currentSubsite={subsite}
                appId={appId}
                value={menuContents}
                linkTypeSubsite={linkTypeSubsite}
                currentMenuContentIndex={currentMenuContentIndex}
                currentMenuContentChidrenIndex={currentMenuContentChidrenIndex}
                onChange={this.onChangeMenuContents}
            />
        );
    };

    pull = () => {
        const { appId } = this.state;
        Modal.confirm({
            title: services.language.getText('sfqrtbgzhnr_1'),
            okText: language.getText('common.confirm'),
            cancelText: language.getText('common.cancel'),
            onOk: () => {
                api.post(
                    {
                        appId: appId,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                        apiPath: `/admin/wechat_official_account_menus/pull`,
                    }
                )
                    .then((messageResult: any) => {
                        if (messageResult && messageResult.length > 0) {
                            const newMessageResult = messageResult.map((c: any) => {
                                c.menuContent = JSON.parse(c.menuContent);
                                c.subMenus = c.subMenus
                                    ? c.subMenus.map((s: any) => {
                                          s.menuContent = JSON.parse(s.menuContent);
                                          return s;
                                      })
                                    : [];
                                return c;
                            });
                            this.setState({
                                menuContents: newMessageResult,
                            });
                        } else {
                            AntMessage.warning(services.language.getText('gzhcdzwpznrsj'));
                        }
                    })
                    .catch(errorHandle);
            },
        });
    };

    renderRefreshButton = () => {
        return (
            <div className="pull">
                <Button type="default" onClick={this.pull}>
                    {services.language.getText('tbgzhcd')}
                </Button>
            </div>
        );
    };

    render() {
        const { subsite, appName, recordErrorTip, appId } = this.state;
        return (
            <Fragment>
                <WechatOfficialAutoReplySettingPageStoreSelector
                    appId={appId}
                    appName={appName}
                    currentSubsite={subsite}
                    recordErrorTip={recordErrorTip}
                    onChange={this.onChangeSubsite}
                />
                <div className="wechat-official-auto-reply-setting-page">
                    {this.renderTip()}
                    {appName && !recordErrorTip && (
                        <div>
                            {this.renderRefreshButton()}
                            <div className="info">
                                <div className="info-content">
                                    {this.renderLeft()}
                                    {this.renderRight()}
                                </div>
                                {this.renderFooter()}
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}
