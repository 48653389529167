import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Button as AntButton, Modal } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

export interface ParkingInvoicesButtonProps {
    row: any;
}

export interface ParkingInvoicesButtonStates {
    invoiceInfoVisible: boolean;
    invoiceOrderVisible: boolean;
    invoiceRecordsVisible: boolean;
    reasonVisible: boolean;
    records: Array<any>;
    orders: Array<any>;
}

export class ParkingInvoicesButton extends Component<
    ParkingInvoicesButtonProps,
    ParkingInvoicesButtonStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            invoiceInfoVisible: false,
            invoiceOrderVisible: false,
            invoiceRecordsVisible: false,
            reasonVisible: false,
            records: [],
            orders: [],
        };
    }
    handleInvoiceInfo = () => {
        const { invoiceInfoVisible } = this.state;
        this.setState({
            invoiceInfoVisible: !invoiceInfoVisible,
        });
    };

    handleInvoiceOrder = () => {
        const { invoiceOrderVisible } = this.state;
        const { row } = this.props;
        if (!invoiceOrderVisible) {
            api.get(
                {
                    invoiceId: row.id,
                    page: 1,
                    perPage: 100,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
                    apiPath: '/admin/carpark_orders',
                }
            ).then((response: any) => {
                this.setState({
                    orders: response.result,
                    invoiceOrderVisible: true,
                });
            });
        } else {
            this.setState({
                invoiceOrderVisible: false,
            });
        }
    };

    handleInvoiceRecords = () => {
        const { row } = this.props;
        window.location.href = `/#/carpark-order/logs/${row.id}`;
    };

    handleReason = () => {
        const { reasonVisible } = this.state;
        this.setState({
            reasonVisible: !reasonVisible,
        });
    };

    renderInvoiceInfoModal = () => {
        const { invoiceInfoVisible } = this.state;
        const { row } = this.props;
        return (
            <Modal
                title={language.getText('fp')}
                visible={invoiceInfoVisible}
                width={700}
                onCancel={this.handleInvoiceInfo}
                footer={[
                    <AntButton key="submit" type="primary" onClick={this.handleInvoiceInfo}>
                        {language.getText('common.close')}
                    </AntButton>,
                ]}
            >
                <div className="parking-picture-wrap">
                    {row.files &&
                        row.files.length > 0 &&
                        row.files.map((item: any) => {
                            return (
                                item.type === 'PICTURE' && (
                                    <div>
                                        <img alt="" src={item.url} />
                                    </div>
                                )
                            );
                        })}
                </div>
            </Modal>
        );
    };

    renderInvoiceOrderModal = () => {
        const { invoiceOrderVisible } = this.state;
        const { orders } = this.state;
        return (
            <Modal
                title={language.getText('gldd')}
                visible={invoiceOrderVisible}
                width={600}
                onCancel={this.handleInvoiceOrder}
                footer={[
                    <AntButton key="submit" type="primary" onClick={this.handleInvoiceOrder}>
                        {language.getText('common.close')}
                    </AntButton>,
                ]}
            >
                <div className="parking-item">
                    <div className="parking-item-title">{language.getText('ddh_1')}</div>
                    <div className="parking-item-value">{language.getText('moneyYuan')}</div>
                </div>
                {orders &&
                    orders.map((item: any) => {
                        return (
                            <div className="parking-item">
                                <div className="parking-item-title">{item.orderNumber}</div>
                                <div className="parking-item-value">{item.payableAmount}</div>
                            </div>
                        );
                    })}
            </Modal>
        );
    };

    renderReasonModal = () => {
        const { reasonVisible } = this.state;
        const { row } = this.props;
        return (
            <Modal
                title={language.getText('sbyy')}
                visible={reasonVisible}
                width={500}
                onCancel={this.handleReason}
                footer={[
                    <AntButton key="submit" type="primary" onClick={this.handleReason}>
                        {language.getText('common.close')}
                    </AntButton>,
                ]}
            >
                <span>{row.result}</span>
            </Modal>
        );
    };

    render() {
        const { row } = this.props;
        return (
            <span>
                <Button
                    className="parking-button"
                    disabled={row.files && row.files.length > 0 ? false : true}
                    type="link"
                    onClick={this.handleInvoiceInfo}
                >
                    {language.getText('ckfp')}
                </Button>
                <Button className="parking-button" type="link" onClick={this.handleInvoiceOrder}>
                    {language.getText('gldd')}
                </Button>
                <Button className="parking-button" type="link" onClick={this.handleInvoiceRecords}>
                    {language.getText('fpjl')}
                </Button>
                <Button
                    className="parking-button"
                    disabled={row.result ? false : true}
                    type="link"
                    onClick={this.handleReason}
                >
                    {language.getText('sbyy')}
                </Button>
                {this.renderInvoiceInfoModal()}
                {this.renderInvoiceOrderModal()}
                {this.renderReasonModal()}
            </span>
        );
    }
}
