/**
 * 主题风格模式配置
 */
export enum ThemeStyleModeConfig {
    /**
     * 经典
     */
    Classic = 0,
    /**
     * 简约，首见于海信广场
     */
    Simplicity = 1,
}
/**
 * 是否为简约主题
 * @returns
 */
export function isSimplicityTheme(themeStyle: any) {
    return Boolean(themeStyle && themeStyle.styleMode === ThemeStyleModeConfig.Simplicity);
}
/**
 * 是否为经典主题
 * @returns
 */
export function isClassicTheme(themeStyle: any) {
    return Boolean(themeStyle && themeStyle.styleMode === ThemeStyleModeConfig.Classic);
}

/**
 * hex转rgba格式
 * @param {string} hex 色值
 * @param {number} opacity 透明度
 * @returns rgba颜色
 */
export function hexToRgba(hex: string, opacity = 1) {
    const red = parseInt(hex?.slice(1, 3), 16);
    const green = parseInt(hex?.slice(3, 5), 16);
    const blue = parseInt(hex?.slice(5, 7), 16);
    const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    return rgba;
}

/**
 * 传入颜色标识符来获取某个主题下的16进制颜色
 * @param 颜色标识符
 * @returns 16进制颜色
 */
export function getColorByTheme(themeStyle: any, name: string): string {
    return themeStyle?.color?.[name];
}

/**
 * 获取按钮样式
 * @param themeStyle
 */
export function getButtonStyleByTheme(themeStyle: any) {
    const bottonStyles: any = {
        background: themeStyle?.button?.from || '#007046',
    };
    if (themeStyle?.button?.colorType === 'gradient') {
        if (themeStyle?.button?.gradient.direction === 'vertical') {
            bottonStyles.background = `linear-gradient(0deg, ${hexToRgba(
                themeStyle?.button?.gradient?.to || '#ab8e66'
            )} 0%, ${hexToRgba(themeStyle?.button?.gradient?.from || '#ab8e66')} 100%)`;
        } else {
            bottonStyles.background = `linear-gradient(270deg, ${hexToRgba(
                themeStyle?.button?.gradient?.to || '#ab8e66'
            )} 0%, ${hexToRgba(themeStyle?.button?.gradient?.from || '#ab8e66')} 100%)`;
        }
    }
    return bottonStyles;
}

/**
 * 将className拼接带上简约主题风格后缀
 * 例如：'home-page' 简约模式下会处理为 'home-page home-page--simplicity'
 * 再例如：'product-list style-one' 简约模式下会处理为 'product-list style-one product-list--simplicity'
 * 支持入参格式为对象
 */
export function formatClassNamesByTheme<T>(themeStyle: any, classNames: T): T {
    const fomatter = (className: string) => {
        const firstClassName = (className as string).split(' ').shift();
        return isSimplicityTheme(themeStyle)
            ? `${className} ${firstClassName}--simplicity`
            : className;
    };
    if (typeof classNames === 'string') {
        return fomatter(classNames) as any;
    }
    if (typeof classNames === 'object') {
        for (const key in classNames) {
            if (Object.prototype.hasOwnProperty.call(classNames, key)) {
                classNames[key] = fomatter(classNames[key] as any) as any;
            }
        }
    }
    return classNames;
}
