import React, { PureComponent } from 'react';
import { Button, Modal, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';

import './index.less';

const { api } = services;
/**
 * 商场活动 - 活动配置 - 列表活动数据按钮
 */
export class MallActivitySettingStatistic extends PureComponent<
    any,
    { showModal: boolean; statisticData: any[]; loading: boolean }
> {
    state = { showModal: false, statisticData: [], loading: false };

    tableConfig = {
        rowKey: 'id',
        columns: [
            {
                title: services.language.getText('activityName'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '活动名额',
                dataIndex: 'totalQuantity',
                key: 'totalQuantity',
                align: 'center' as const,
            },
            {
                title: '报名人数',
                dataIndex: 'registrationNum',
                key: 'registrationNum',
                align: 'center' as const,
            },
            {
                title: '参与人数',
                dataIndex: 'joinNum',
                key: 'joinNum',
                align: 'center' as const,
            },
        ],
        pagination: { pageSize: 6 },
    };

    toggleModal = () => () => {
        const { showModal } = this.state;
        if (showModal === false) {
            this.loadData();
        }
        this.setState({ showModal: !showModal });
    };

    loadData = () => {
        const { row, service } = this.props;
        const id = row.id;
        this.setState({ loading: true });
        if (service === 'MAGIC-MALL-ACTIVITY') {
            api.get(
                { id: id },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: '/admin/mall_activities/:id/statistic',
                }
            )
                .then((res: any) => {
                    const data = [];
                    const { joinQuantity, maxQuantity, name, registrationQuantity } = res;
                    if (res) {
                        data.push({
                            name,
                            totalQuantity: maxQuantity,
                            registrationNum: registrationQuantity,
                            joinNum: joinQuantity,
                        });
                    }
                    this.setState({ statisticData: data });
                }, errorHandle)
                .finally(() => {
                    this.setState({ loading: false });
                });
        } else {
            api.get(
                { id: id },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/mall_activities/:id/statistic',
                }
            )
                .then((res) => {
                    const data = [];
                    if (res) {
                        data.push(res);
                    }
                    this.setState({ statisticData: data });
                }, errorHandle)
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    };

    render() {
        const { showModal, statisticData, loading } = this.state;
        const { row } = this.props;
        const activityName = row ? row.name : '';

        return (
            <div className="mall-activity-setting-statistics">
                <Button type="link" className="btn-data-statistics" onClick={this.toggleModal()}>
                    活动数据
                </Button>
                <Modal
                    onCancel={this.toggleModal()}
                    footer={[
                        <Button type="primary" onClick={this.toggleModal()}>
                            确定
                        </Button>,
                    ]}
                    visible={showModal}
                    title={`「${activityName}」活动数据`}
                >
                    <Table
                        loading={loading}
                        dataSource={statisticData}
                        {...this.tableConfig}
                    ></Table>
                </Modal>
            </div>
        );
    }
}
