import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
const enum OrderStatus {
    APPLYING = '1',
    APPLY_SUCC = '2',
    APPLY_FAIL = '3',
}
export const DepositOrderAfterSaleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/deposit_order_service_applies';
        //状态-解决点击tab某一项，列表点击搜索，tab状态参数却没有的情况
        data.status = 'ALL';
        const href = window.location.href;
        const list = href.split('#');
        if (list && list.length >= 3) {
            const statusNum = href.split('#')[2];
            const statusEnum: any = {
                [OrderStatus.APPLYING]: 'APPLYING',
                [OrderStatus.APPLY_SUCC]: 'APPLY_SUCC',
                [OrderStatus.APPLY_FAIL]: 'APPLY_FAIL',
            };
            data.status = statusEnum[statusNum];
        }
        if (data.status === 'ALL') {
            delete data.status;
        }

        if (data.refundStatus === 'ALL') {
            delete data.refundStatus;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.amountRange)) {
            data.startOrderAmount = data.amountRange.start ? data.amountRange.start : undefined;
            data.endOrderAmount = data.amountRange.end ? data.amountRange.end : undefined;
            delete data.amountRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    depositOrderNo: item.depositOrder.orderNo,
                    subsiteName: item.depositOrder.subsite.name,
                    merchantName: item.depositOrder.merchant.name,
                    orderAmount: item.depositOrder.orderAmount,
                    mobile: item.depositOrder.memberInfo.mobile,
                    merchantBigCode: item.depositOrder.merchantBigCode,
                    guideName: item.depositOrder.guideInfo.name,
                    merchantTradeNo:
                        item.depositOrder.paymentRecords &&
                        item.depositOrder.paymentRecords.length > 0
                            ? item.depositOrder.paymentRecords[0].merchantTradeNo
                            : '',
                };
            });
            return res;
        });
    },
};
