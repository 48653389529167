import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import isEmpty from 'lodash/isEmpty';

const api = services.api;

export const FeedBacksLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data && data.replyStatus === 'ALL') {
            delete data.replyStatus;
        }
        if (data && data.feedbackStatus === 'ALL') {
            delete data.feedbackStatus;
        }
        if (data && data.type) {
            data.type = data.type.name;
        }
        if (data && data.typeInfo) {
            data.typeInfo = data.typeInfo.name;
        }
        if (!isEmpty(data.dateRange)) {
            data.feedbackStartTime = data.dateRange.start;
            data.feedbackEndTime = data.dateRange.end;
            delete data.dateRange;
        }
        if (!isEmpty(data.replyDateRange)) {
            data.replyStartTime = data.replyDateRange.start;
            data.replyEndTime = data.replyDateRange.end;
            delete data.replyDateRange;
        }
        const { id } = data;
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.canEdit = item.status === 'UNFINISHED';
                        return item;
                    });
            } else {
                res.type = res.typeInfo ? res.type + '  ' + res.typeInfo : res.type;
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        return await api.put(params, config);
    },
};

export const FeedBackTypesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data.id) {
            config.apiPath = '/admin/feedback/types/subsite/' + data.id;
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.subsite = [res.subsite];
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        if (params.subsite && params.subsite.length) {
            params.subsiteId = params.subsite[0].id;
            delete params.subsite;
        }
        return await api.put(params, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        if (params.subsite && params.subsite.length) {
            params.subsiteId = params.subsite[0].id;
            delete params.subsite;
        }
        return await api.post(params, config);
    },
};
