import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
// import { map } from 'lodash';

const api = services.api;

export const ManageGiftLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/gift_products`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/gift_products/${id}`;
            data = {};
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.images =
                    res.images && res.images.length > 0
                        ? res.images.map((item: any) => {
                              item.path = item.url;
                              delete item.url;
                              return item;
                          })
                        : undefined;
                res.goodsList =
                    res.goodsList &&
                    res.goodsList.length > 0 &&
                    res.goodsList.map((item: any) => {
                        item.id = item.subsiteId;
                        item.name = item.subsiteName;
                        delete item.subsiteId;
                        delete item.subsiteName;
                        return item;
                    });
                res.baseInfo = {
                    name: res.name,
                    code: res.code,
                    salesUnit: res.salesUnit,
                    weight: res.weight,
                    marketPrice: res.marketPrice,
                };

                res.picsConfig = {
                    images: res.images,
                };
                res.otherConfig = {
                    details: res.details,
                };
                res.advancedSetting = {
                    tag: res.tag,
                    receiveExplain: res.receiveExplain,
                };
                res.stockManage = {
                    subsiteIds: res.goodsList,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/gift_products';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/gift_products`;
        const newParams: any = dataParser(params);
        newParams.id = params.id;
        return await api.put(newParams, config);
    },
};

/**    newParams.receiveInfo = params.advancedSetting.receiveInfo;

 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.name = params.baseInfo.name;
    newParams.code = params.baseInfo.code;
    newParams.salesUnit = params.baseInfo.salesUnit;
    newParams.weight = params.baseInfo.weight;
    newParams.marketPrice = params.baseInfo.marketPrice;
    newParams.details = params.otherConfig.details;
    newParams.tag = params.advancedSetting.tag;
    newParams.goodsList =
        params.stockManage.subsiteIds &&
        params.stockManage.subsiteIds.length > 0 &&
        params.stockManage.subsiteIds.map((item: any) => {
            delete item.code;
            delete item.address;
            delete item.latitude;
            delete item.longitude;
            delete item.select;
            delete item.status;
            item.subsiteId = item.id;
            return item;
        });
    newParams.images =
        params.picsConfig.images && params.picsConfig.images.length > 0
            ? params.picsConfig.images.map((item: any) => {
                  item.url = item.path;
                  return item;
              })
            : [];
    newParams.receiveExplain = params.advancedSetting.receiveExplain;
    return newParams;
}
