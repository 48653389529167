import * as React from 'react';
import classNames from 'classnames';
import { map, get } from 'lodash';
import { ArrayMode } from '@comall-backend-builder/types';
import { getCouponStatus } from '../../format/utils';

export class ArrayStringLineFeed extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, showField } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div className={className} style={style}>
                    {map(array, (item, index) => {
                        return (
                            <div key={index + 'i'}>
                                <span>{get(item, `${showField}`, '')}</span>
                                {displayInfo &&
                                    displayInfo.displayCouponStatus &&
                                    item.couponBizStatus && (
                                        <span>（{getCouponStatus(item.couponBizStatus)}）</span>
                                    )}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取无数据状态展示组件
     */
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            ''
        );
    }
}
