import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { ServicePriceType } from '../../../../components/service-price';
import { language } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        serviceReservedEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/service_reserved_activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<fwmc>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<fwmc>>',
                },
                sequence: {
                    type: 'string',
                    displayName: '<<sequence>>',
                },
                stock: {
                    type: 'string',
                    displayName: '<<zkc>>',
                },
                availableStock: {
                    type: 'number',
                    displayName: '<<zsykc>>',
                },
                reservationCount: {
                    type: 'number',
                    displayName: '<<yyykc>>',
                },
                inProgressCount: {
                    type: 'number',
                    displayName: '<<yjckc>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<fwzt>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<kqzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<sxmd_1>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<applicableSubsiteRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                        name: {
                            type: 'string',
                            displayName: '<<fwmc>>',
                            controlConfig: {
                                style: { width: 300 },
                                placeholder: '<<jqsrfwmcbcg20gz>>',
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<jqsrfwmcbcg20gz>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateRangePlus',
                            displayName: '<<fwrq>>',
                            rules: [{ required: true, message: '<<qxzfwrq>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        sequence: {
                            type: 'number.tip',
                            displayName: '<<sequence>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<pxbkwk>>',
                                    validator: (rule: any, value: any) => {
                                        if (value) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<sortTip>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                        max: 100000000,
                                    },
                                ],
                            },
                        },
                        pictures: {
                            displayName: '<<fwtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        timeRange: {
                            type: 'object.timeRange',
                            displayName: '<<fwsj>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzfwsj>>',
                                },
                            ],
                        },
                        info: {
                            type: 'string.richTextPlus',
                            displayName: '<<yyxz>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
                serviceInfo: {
                    type: 'object.subForm',
                    displayName: '<<fwdd>>',
                    properties: {
                        totalQuantity: {
                            type: 'number.tip',
                            displayName: '<<zkc>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<kcsbkwk>>',
                                    validator: (rule: any, value: any) => {
                                        if (value) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrkcsl>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                        max: 100000000,
                                    },
                                ],
                            },
                        },
                        serviceReservedStock: {
                            type: 'object.serviceReservedStock',
                            displayName: '<<zkc>>',
                            defaultValue: {
                                totalQuantity: undefined,
                            },
                            rules: [{ required: true }],
                        },
                        serviceFacilities: {
                            type: 'object.serviceFacilities',
                            displayName: '<<fwss>>',
                        },
                    },
                },
                rulesInfo: {
                    type: 'object.subForm',
                    displayName: '<<gzpz>>',
                    properties: {
                        limit: {
                            type: 'number.tip',
                            displayName: '<<dczdky>>',
                            defaultValue: 1,
                            rules: [
                                {
                                    required: true,
                                    message: '<<dczdkybnwk>>',
                                    validator: (rule: any, value: any) => {
                                        if (value) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                disabled: true,
                                placeholder: '<<qsrkcsl>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                        max: 100000000,
                                    },
                                ],
                            },
                        },
                        timeLimit: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<yysjxz>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<kysjbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        if (value) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            displayConfig: {
                                addonbefore: '<<ky>>',
                                addonafter: '<<tn>>',
                            },
                            controlConfig: {
                                addonbefore: '<<ky>>',
                                addonafter: '<<tn>>',
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        servicePrice: {
                            type: 'object.servicePrice',
                            displayName: '<<yj_1>>',
                            defaultValue: { type: ServicePriceType.NONE, price: '' },
                            rules: [
                                { required: true },
                                {
                                    async validator(_: any, value: any) {
                                        if (value.type === ServicePriceType.PAY) {
                                            if (!value.price) {
                                                throw new Error(language.getText('qsryyjg'));
                                            }
                                        }
                                        if (value.type === ServicePriceType.POINT) {
                                            if (!value.point) {
                                                throw new Error(language.getText('qsryyjf'));
                                            }
                                        }
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    components: {
        serviceReservedView: {
            component: 'Viewport',
            entity: 'serviceReservedEntity',
        },
        serviceReservedPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'serviceReservedFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzfw>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/service-reserved-setting/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'serviceReservedTable' },
            ],
        },
        serviceReservedFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                // {
                //     property: 'executeStatus',
                // },
            ],
        },
        serviceReservedTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'sequence',
                    width: '100px',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOTSTARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'stock',
                    width: '100px',
                },
                {
                    property: 'availableStock',
                    width: '100px',
                },
                {
                    property: 'reservationCount',
                    width: '100px',
                },
                {
                    property: 'inProgressCount',
                    width: '100px',
                },
                {
                    property: 'startTime',
                    width: '200px',
                },
                {
                    property: 'endTime',
                    width: '200px',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/service-reserved-setting/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            //statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'disabled' },
                                    value: 'enabled',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/service_reserved_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: 'enabled' },
                                    value: 'disabled',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/service_reserved_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        serviceReservedAddPage: {
            component: 'Card',
            content: { component: 'serviceReservedAddForm' },
        },

        serviceReservedAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'serviceReservedEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.sequence' },
                { property: 'baseInfo.timeRange' },
                {
                    property: 'baseInfo.pictures',
                    extra: '<<jPicLimit700>>',
                },
                { property: 'baseInfo.info' },
                { property: 'serviceInfo.totalQuantity' },
                { property: 'serviceInfo.serviceFacilities' },
                { property: 'rulesInfo.limit' },
                { property: 'rulesInfo.timeLimit' },
                { property: 'rulesInfo.servicePrice' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const serviceFacilities = get(entity, 'serviceInfo.serviceFacilities', []);
                if (serviceFacilities.length === 0) {
                    AntMessage.warning(language.getText('qxzygfwdd'));
                    return false;
                } else {
                    const isNameEmpty = serviceFacilities.some((service: any) => !service.name);
                    const isPhoneEmpty = serviceFacilities.some((service: any) => !service.phone);
                    if (isNameEmpty) {
                        AntMessage.warning(language.getText('qsrfwdd'));
                        return false;
                    }
                    if (isPhoneEmpty) {
                        AntMessage.warning(language.getText('qsrfwdh'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(language.getText('createSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },

        serviceReservedEditPage: {
            component: 'Card',
            content: { component: 'serviceReservedEditForm' },
        },

        serviceReservedEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'serviceReservedEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId', modifiable: false },
                { property: 'baseInfo.name', modifiable: false },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.sequence' },
                { property: 'baseInfo.timeRange' },
                {
                    property: 'baseInfo.pictures',
                    extra: '<<jPicLimit700>>',
                },
                { property: 'baseInfo.info' },
                { property: 'serviceInfo.serviceReservedStock' },
                { property: 'serviceInfo.serviceFacilities' },
                { property: 'rulesInfo.limit' },
                { property: 'rulesInfo.timeLimit' },
                { property: 'rulesInfo.servicePrice' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const serviceFacilities = get(entity, 'serviceInfo.serviceFacilities', []);
                if (serviceFacilities.length === 0) {
                    AntMessage.warning(language.getText('qxzygfwdd'));
                    return false;
                } else {
                    const isNameEmpty = serviceFacilities.some((service: any) => !service.name);
                    const isPhoneEmpty = serviceFacilities.some((service: any) => !service.phone);
                    if (isNameEmpty) {
                        AntMessage.warning(language.getText('qsrfwdd'));
                        return false;
                    }
                    if (isPhoneEmpty) {
                        AntMessage.warning(language.getText('qsrfwdh'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/service-reserved-setting',
                    component: 'serviceReservedView',
                    breadcrumbName: '<<serviceReservedSetting>>',
                    privilege: {
                        path: 'serviceReservedSetting',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'serviceReservedAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'serviceReservedEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'serviceReservedPage' },
                    ],
                },
            ],
        },
    ],
};
