import React, { Component } from 'react';
import { Input, Select, Button, message } from 'antd';
import { find, clone, isEqual, map, concat, isEmpty, isArray } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

type RulesValue = {
    /**
     * 会员等级id
     */
    memberLevelId?: string;
    /**
     * 解绑次数
     */
    maxUnbindQuantity?: number;
};

export interface MemberLevelParkUnbindProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 候选项集合
     */
    options: Array<any>;
    typeTitle?: string;
    defaultValue?: string;
}
export class MemberLevelParkUnbind extends Component<MemberLevelParkUnbindProps> {
    onMemberLevelIdChange(memberLevelId: string, index: number) {
        let { name, value, onChange } = this.props;

        if (value !== null && !isEmpty(value)) {
            let userRule = find(value, { memberLevelId });
            if (userRule) {
                message.warning(language.getText('tylxdhyqyjzcsz'));
                return;
            }
            let item = clone(value[index]);
            item.memberLevelId = memberLevelId;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(memberLevelId)) {
                message.warning(language.getText('qxzhydj'));
                return;
            }
            let item: RulesValue = {};
            item.memberLevelId = memberLevelId;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onMaxUnbindQuantityChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        let maxUnbindQuantity = event.target.value;
        if (!isEmpty(maxUnbindQuantity) && maxUnbindQuantity < 0) {
            message.warning(language.getText('qtxdydydcs'));
            return;
        }
        if (isNaN(Number(maxUnbindQuantity))) {
            return;
        }
        if (maxUnbindQuantity && maxUnbindQuantity > 1000000) {
            message.warning(language.getText('jbcszdzw'));
            return;
        }
        if (/\s/.test(maxUnbindQuantity)) {
            return;
        }
        maxUnbindQuantity = maxUnbindQuantity.split('.')[0];
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.maxUnbindQuantity = maxUnbindQuantity;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(maxUnbindQuantity)) {
                message.warning(language.getText('qsrhydjjbcs'));
                return;
            }
            let item: RulesValue = {};
            item.maxUnbindQuantity = maxUnbindQuantity;
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = [{}];
        }
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: MemberLevelParkUnbindProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, typeTitle } = this.props;

        return (
            <div className="member-level-park-unbind">
                {typeTitle ? <div>{typeTitle}</div> : ''}
                {map(value, (rule, index) => this.renderRule(rule, index))}
                {value && value.length ? null : this.renderRule({}, 0)}
                <Button type="default" className="add-rule-button" onClick={() => this.onAddRule()}>
                    {language.getText('tjhydj')}
                </Button>
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number) {
        const { options, defaultValue } = this.props;
        const selectOption =
            isArray(options) &&
            options.filter((option) => Number(option.id) === Number(rule.memberLevelId));
        const ruleLevelName = selectOption && selectOption.length > 0 && selectOption[0].name;

        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text">{language.getText('cardLevel')}：</span>
                    <Select
                        className="rule-type-selection"
                        placeholder={services.language.getText('selectPlease')}
                        value={ruleLevelName || defaultValue}
                        onChange={(memberLevelId: any) =>
                            this.onMemberLevelIdChange(memberLevelId, index)
                        }
                    >
                        {isArray(options) &&
                            map(options, ({ id, name }) => (
                                <Select.Option key={id}>{name}</Select.Option>
                            ))}
                    </Select>
                    <span className="item-text">{language.getText('jb_2')}</span>
                    <div className="rule-count-input">
                        <Input
                            value={rule.maxUnbindQuantity}
                            onChange={(e) => this.onMaxUnbindQuantityChange(e, index)}
                        />
                    </div>
                    <span className="item-text">{language.getText('cs_1')}</span>
                    <Button
                        type="default"
                        className="remove-rule-button"
                        onClick={() => this.onRemoveRule(index)}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                </div>
            </div>
        );
    }
}
