import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, isEmpty, get } from 'lodash';
import moment from 'moment';

const api = services.api;

export const ReserveDrawLotsImportActivitiesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_draw_lots_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/reserve_draw_lots_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activity.id;
                res.activityInfo = {
                    ...res.activity,
                    executeStatus: res.activity.executeStatus,
                    mode: 'RESERVE_DRAW_LOTS',
                    name: res.activity.name,
                    dateRange: {
                        start: res.activity.startTime,
                        end: res.activity.endTime,
                    },
                    description: res.activity.description,
                    subsites: res.subsites,
                    goods: res.goods.map((item: any) => {
                        item.merchantName = item.merchant.name;
                        item.subsiteName = item.subsite.name;
                        item.subsiteId = item.subsite.id;
                        item.goodsDeductPoint = item.pointPrice;
                        return item;
                    }),
                };

                const round = res.rounds[0];
                res.roundInfo = {
                    ...round,
                    period: round.period,
                    periodValue: round.periodValue,
                    reserveTime: [
                        {
                            start: round.reserveStartTime,
                            end: round.reserveEndTime,
                        },
                    ],
                    quantityLimit: round.quantityLimit,
                    drawTime: round.drawTime,
                    purchaseTime: round.purchaseTime,
                    ballotLimit: {
                        whiteListCount: res.activity.whiteListCount
                            ? {
                                  checked: true,
                                  value: res.activity.whiteListCount,
                              }
                            : undefined,
                        memberCount: res.activity.memberCount
                            ? {
                                  checked: true,
                                  value: res.activity.memberCount,
                              }
                            : undefined,
                    },
                    maxDrawCount: res.limitCondition.maxDrawCount,
                };
                res.seniorInfo = {
                    participateCondition: [
                        {
                            code: 'MINREGISTERDAYS',
                            checked: res.participateCondition.minRegisterDays ? true : false,
                            value: res.participateCondition.minRegisterDays,
                        },
                        {
                            code: 'CONSUMPTIONT',
                            checked: res.participateCondition.consumptionCount ? true : false,
                            value: res.participateCondition.consumptionCount,
                        },
                        {
                            code: 'CERTIFICATION',
                            checked: res.participateCondition.certification,
                        },
                    ],
                    receiptCertification: res.participateCondition.receiptCertification
                        ? ['NEED']
                        : [],
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.id = item.activity.id;
                            item.mode = item.activity.mode;
                            item.name = item.activity.name;
                            item.dateRange = `${moment(item.activity.startTime).format(
                                'YYYY-MM-DD'
                            )} - ${moment(item.activity.endTime).format('YYYY-MM-DD')}`;
                            item.executeStatus = item.activity.executeStatus;
                            item.status = item.activity.status;

                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_draw_lots_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = `/admin/reserve_draw_lots_activities/${id}`;

        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
};

export const WhitelistManagerRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_list_activity';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/reserve_list_activity/${id}/WHITE`;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};
export const MaotaiWhitelistManagerRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_list_activity';
        const { id } = data;
        if (id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
            config.apiPath = `/admin/presale/listing/${id}/WHITE/import_records`;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};
export const RegistrationWhitelistManagerRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_list_activity';
        const { id } = data;
        if (id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
            // config.apiPath = `/admin/reserve_list_activity/${id}/REGISTRATION`;
            config.apiPath = `/admin/presale/listing/${id}/REGISTRATION/import_records`;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};

export const BlacklistManagerRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_list_activity';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/reserve_list_activity/${id}/BLACK`;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};
export const MaotaiBlacklistManagerListLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_list_activity';
        const { id } = data;
        if (id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
            config.apiPath = `/admin/presale/listing/${id}/BLACK`;
        }
        if (isEmpty(data.cid)) {
            delete data.cid;
        }
        if (isEmpty(data.idNumber)) {
            delete data.idNumber;
        }
        if (isEmpty(data.mobile)) {
            delete data.mobile;
        }
        return await api.get(data, config);
    },
};
export const MaotaiBlacklistManagerRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_list_activity';
        const { id } = data;
        if (id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
            config.apiPath = `/admin/presale/listing/${id}/BLACK/import_records`;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    //活动设置
    if (params.activityInfo) {
        const whiteListCount = get(params, 'roundInfo.ballotLimit.whiteListCount', {});
        const memberCount = get(params, 'roundInfo.ballotLimit.memberCount', {});
        newParams.activity = {
            deliveryEndDate: undefined,
            deliveryStartDate: undefined,
            description: params.activityInfo.description,
            executeStatus: undefined,
            mode: 'RESERVE_DRAW_LOTS',
            name: params.activityInfo.name,
            reserveType: 'ACTIVITY',
            startTime:
                moment(params.activityInfo.dateRange.start).format('YYYY-MM-DD') + ' 00:00:00',
            endTime: moment(params.activityInfo.dateRange.end).format('YYYY-MM-DD') + ' 23:59:59',
            status: undefined,
            stockMode: undefined,
            whiteListCount: whiteListCount.checked ? whiteListCount.value : undefined,
            memberCount: memberCount.checked ? memberCount.value : undefined,
        };
        newParams.subsites = params.activityInfo.subsites.map((item: any) => ({ id: item.id }));
        newParams.goods = params.activityInfo.goods;
    }
    //活动场次
    if (params.roundInfo) {
        newParams.rounds = [
            {
                drawTime: params.roundInfo.drawTime.match(/^\d{2}:\d{2}/).shift(),
                startTime: undefined,
                endTime: undefined,
                memberLimit: undefined,
                period: params.roundInfo.period,
                periodValue: 1,
                purchaseTime: params.roundInfo.purchaseTime,
                quantityLimit: params.roundInfo.quantityLimit,
                quantityLimitPerOrder: undefined,
                reserveEndTime: params.roundInfo.reserveTime[0].end,
                reserveStartTime: params.roundInfo.reserveTime[0].start,
            },
        ];
        newParams.limitCondition = {
            id: undefined,
            maxDrawCount: params.roundInfo.maxDrawCount,
            maxGoodsQuantity: undefined,
            maxOrderCount: undefined,
        };
    }
    //高级设置
    if (params.seniorInfo) {
        const participateCondition = params.seniorInfo.participateCondition;
        const minRegisterDays: any = find(participateCondition, { code: 'MINREGISTERDAYS' });
        const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
        const certification: any = find(participateCondition, { code: 'CERTIFICATION' });
        newParams.participateCondition = {
            minRegisterDays:
                minRegisterDays && minRegisterDays.checked ? minRegisterDays.value : null,
            consumptionCount: consumption && consumption.checked ? consumption.value : null,
            certification: certification && certification.checked ? true : false,
            receiptCertification: Boolean(
                params.seniorInfo.receiptCertification &&
                    params.seniorInfo.receiptCertification.length
            ),
        };
    }
    return newParams;
}
