import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get, map } from 'lodash';
import { getTypeName } from './coupon/coupon-sale-activity-loader';
import { ReceiveLimitEnum } from '../config/product/sales-electronic-coupon';

const api = services.api;
export const SalesElectronicCouponLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id, productId } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/virtual_products/${id}`;
        return await api.get({ productId }, config).then((res: any) => {
            const { couponDefinition } = res;
            const { couponPackagePrices, ...coupon } = couponDefinition || {};
            const newRes: any = {};
            if (res.marketingSchedule) {
                const { startTime, endTime } = res.marketingSchedule || {};
                res.marketingSchedule = {
                    ...res.marketingSchedule,
                    time:
                        startTime && endTime
                            ? `${startTime}-${endTime}`
                            : services.language.getText('longTerm'),
                };
            }
            newRes.baseInfo = {
                salesChannels: ['OWN', ...res.salesChannels],
                subsites: [res.subsiteVo],
                coupon: [
                    {
                        ...coupon,
                        type: coupon.couponType,
                        typeName: coupon.couponTypeName,
                    },
                ],
                name: res.name,
                marketPrice: res.marketPrice || null,
                salesPriceAndCouponPackagePrices: {
                    salesPrice: res.salesPrice,
                    couponPackagePrices: map(couponPackagePrices, (item: any) => {
                        return {
                            definitionId: item.couponDefinitionId,
                            quantity: item.quantity,
                            couponType: item.couponType,
                            couponTypeName: getTypeName(item.couponType),
                            couponName: item.couponName,
                            apportionPrice: item.apportionPrice,
                            principal: item.principal,
                        };
                    }),
                },
                stock: res.stock,
                pictures: map(res.pictures, (item: any) => ({
                    id: item.id,
                    path: item.url,
                })),
                marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
            };
            newRes.settingInfo = {
                afterSales: res.afterSales,
                saleStatus: res.saleStatus ? 'YES' : 'NO',
                saleTime: res.saleTime || '',
                limitQuantity: res.limitQuantity,
                virtualCategoryIds: map(res.virtualCategoryIds || [], (id: number) =>
                    id.toString()
                ),
                limitStock: res.limitStock,
                initSalesVolume: res.initSalesVolume,
                mobileDetails: res.mobileDetails,
                receiveLimit: res.saleLimitType,
                receiveLimitCrowds:
                    res.saleLimitType === ReceiveLimitEnum.CROWD_LIMIT
                        ? (res.crowdInfos || []).map((i: any) => ({
                              id: i.id,
                              name: i.name,
                              peopleNumber: i.peopleNumber,
                              subsiteName: i.subsiteName,
                          }))
                        : [],
                couponExpiredAutoShelfOff: res.couponExpiredAutoShelfOff ? 'true' : 'false',
            };

            return newRes;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/virtual_products`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id, productId } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/virtual_products`;
        const newParams = dataParser(params);
        newParams.goodsId = id;
        newParams.productId = productId;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};

    const salesChannels = get(params, 'baseInfo.salesChannels', []);
    const subsiteId = get(params, 'baseInfo.subsites[0].id', '');
    newParams.subsiteId = subsiteId;
    const coupon = get(params, 'baseInfo.coupon[0]', {});
    const couponPackagePrices =
        coupon.type === 'PACKAGE'
            ? get(params, 'baseInfo.salesPriceAndCouponPackagePrices.couponPackagePrices', [])
            : [];
    const receiveLimit = get(params, 'settingInfo.receiveLimit', ReceiveLimitEnum.NO_LIMIT);
    const receiveLimitCrowds = get(params, 'settingInfo.receiveLimitCrowds', []);
    const crowdIds =
        receiveLimit === ReceiveLimitEnum.CROWD_LIMIT
            ? receiveLimitCrowds.map((i: any) => i.id)
            : undefined;
    newParams.couponDefinition = {
        definitionId: coupon.id,
        definitionType: coupon.type === 'PACKAGE' ? 'COUPON_PACKAGE' : 'COUPON',
        couponPackagePrices,
    };
    newParams.name = get(params, 'baseInfo.name', '');
    newParams.salesPrice = get(
        params,
        'baseInfo.salesPriceAndCouponPackagePrices.salesPrice',
        null
    );
    newParams.couponExpiredAutoShelfOff =
        get(params, 'settingInfo.couponExpiredAutoShelfOff', '') === 'true' ? true : false;
    newParams.marketPrice = get(params, 'baseInfo.marketPrice', null);
    newParams.stock = get(params, 'baseInfo.stock', null);
    const pictures = get(params, 'baseInfo.pictures', []);
    newParams.picIds = map(pictures, (item: any) => item.id);
    newParams.mobileDetails = get(params, 'settingInfo.mobileDetails', '');
    newParams.saleStatus = get(params, 'settingInfo.saleStatus', 'YES') === 'YES';
    if (newParams.saleStatus === false) {
        newParams.saleTime = get(params, 'settingInfo.saleTime', '');
    }
    newParams.limitQuantity = get(params, 'settingInfo.limitQuantity', null);
    newParams.limitStock = get(params, 'settingInfo.limitStock', null);
    newParams.initSalesVolume = get(params, 'settingInfo.initSalesVolume', null);
    newParams.afterSales = get(params, 'settingInfo.afterSales', null);
    newParams.virtualCategoryIds = get(params, 'settingInfo.virtualCategoryIds', []);
    newParams.saleLimit = {
        type: receiveLimit,
        ids: crowdIds,
    };
    newParams.marketingScheduleId =
        (params.baseInfo?.marketingSchedule?.length && params.baseInfo.marketingSchedule[0].id) ||
        null;

    if (salesChannels.includes('DOUYIN')) {
        newParams.salesChannels = ['DOUYIN'];
        newParams.afterSales = {
            overdueAutoRefund: true,
            anytimeRefund: true,
            packageRefund: false,
        };
    }
    return newParams;
}
