import React, { PureComponent } from 'react';
// import { RangePickerValue } from 'antd/lib/date-picker/interface';
// import get from 'lodash/get';
import moment from 'moment';
import Rule from './rule';
import './index.less';
import { services } from '@comall-backend-builder/core';

const dateFormat = 'YYYY-MM-DD';

interface ValueItem {
    conditionType?: string;
    checked?: string;
    num?: number | undefined;
}

export interface MaotaiPresalePrizeQualityLimitProps {
    name: string;
    value: ValueItem[];
    onChange: (value: ValueItem[]) => void;
}
export class MaotaiPresalePrizeQualityLimit extends PureComponent<
    MaotaiPresalePrizeQualityLimitProps
> {
    static defaultProps = {
        value: [],
    };
    onCheckChange = (e: any) => {
        const { checked, value: conditionType } = e.target;
        const { onChange, value } = this.props;
        const item = value.find((i) => i.conditionType === conditionType);
        if (!item) {
            onChange([...value, { conditionType, checked }]);
        } else {
            const list = value.map((item) => {
                if (item.conditionType === conditionType) {
                    return { ...item, checked };
                }
                return item;
            });
            onChange(list);
        }
    };

    numChange = (val: number, conditionType: string) => {
        const { onChange, value } = this.props;
        const item = value.find((i) => i.conditionType === conditionType);
        if (!item) {
            onChange([...value, { conditionType, num: val }]);
        } else {
            const list = value.map((item) => {
                if (item.conditionType === conditionType) {
                    return { ...item, num: val };
                }
                return item;
            });
            onChange(list);
        }
    };

    dateChange = (val: any) => {
        const { onChange, value } = this.props;
        const conditionType = 'LIMIT1';
        const dateRange =
            val && val.length
                ? [
                      moment(val[0] || undefined).format(dateFormat),
                      moment(val[1] || undefined).format(dateFormat),
                  ]
                : undefined;

        let list;
        if (!value.find((item) => item.conditionType === conditionType)) {
            list = [...value, { conditionType, dateRange }];
        } else {
            list = value.map((item) => {
                if (conditionType === item.conditionType) {
                    return { ...item, dateRange };
                }
                return item;
            });
        }
        onChange(list);
    };

    render() {
        // const { value } = this.props;
        // const limit1Data = value.find((item) => item.conditionType === 'LIMIT1');
        // const limit2Data = value.find((item) => item.conditionType === 'LIMIT2');
        // const limit1Checked = !!limit1Data?.checked;
        // const limit1Num = limit1Data?.num;
        // const limit2Checked = !!limit2Data?.checked;
        // const limit2Num = limit2Data?.num;
        // const limit3Checked = !!value.find((item) => item.conditionType === 'LIMIT3')?.checked;

        // const dateRange = get(limit1Data, 'dateRange', undefined);

        // const dateValue: RangePickerValue | undefined =
        //     dateRange && dateRange.length
        //         ? [moment(dateRange[0]), moment(dateRange[1])]
        //         : undefined;
        return (
            <div className="maotai-presale-prize-quality-limit">
                {/* <div className="maotai-presale-prize-quality-limit-row">
                    <div className="maotai-presale-prize-quality-limit-checkbox">
                        <Checkbox
                            checked={limit1Checked}
                            value="LIMIT1"
                            onChange={this.onCheckChange}
                        ></Checkbox>
                    </div>
                    <span className="maotai-presale-prize-quality-limit-addon">自定义时间限购</span>
                    <DatePicker.RangePicker
                        onChange={this.dateChange}
                        value={dateValue}
                    ></DatePicker.RangePicker>
                    &nbsp;&nbsp;
                    <NumberInputTip
                        min={0}
                        value={limit1Num}
                        precision={0}
                        max={999999999}
                        onChange={(e: any) => {
                            this.numChange(e, 'LIMIT1');
                        }}
                        style={{ width: 120 }}
                        addonafter="瓶"
                        addonbefore="每用户最多申购"
                    ></NumberInputTip>
                </div> */}
                {/* <div className="maotai-presale-prize-quality-limit-row">
                    <div className="maotai-presale-prize-quality-limit-checkbox">
                        <Checkbox
                            checked={limit2Checked}
                            value="LIMIT2"
                            onChange={this.onCheckChange}
                        ></Checkbox>
                    </div>
                    <NumberInputTip
                        min={0}
                        value={limit2Num}
                        precision={0}
                        max={999999999}
                        onChange={(e: any) => {
                            this.numChange(e, 'LIMIT2');
                        }}
                        style={{ width: 120 }}
                        addonafter="瓶"
                        addonbefore="活动期间每用户最多申购"
                    ></NumberInputTip>
                </div> */}
                <div className="maotai-presale-prize-quality-limit-row">
                    {/* <div className="maotai-presale-prize-quality-limit-checkbox">
                        <Checkbox
                            checked={limit3Checked}
                            value="LIMIT3"
                            onChange={this.onCheckChange}
                        ></Checkbox>
                    </div>
                    &nbsp;&nbsp; */}
                    <span>{services.language.getText('mchdbttjyhsgslbt')}</span>
                </div>
                <div className="maotai-presale-prize-quality-limit-tip">
                    {services.language.getText('xtazcsxxdgzasxzx')}
                </div>
                <Rule {...this.props}></Rule>
            </div>
        );
    }
}
