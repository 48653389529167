import React, { PureComponent } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage, message } from 'antd';
import { mapValues, defaults, get, isUndefined, reduce, clone, debounce } from 'lodash';
import { connect } from 'react-redux';
import { BasicFormProps, getField } from '@comall-backend-builder/components-basis/lib/form';
import { CreateForm } from './create-form';
import './index.less';

/**
 * 活动模式
 */
enum RegistrationType {
    /**
     * 非报名类
     */
    NO = 'NO',
    /**
     * 报名类
     */
    YES = 'YES',
}

/**
 * 限购
 */
enum LimitCountType {
    /**
     * 非限购
     */
    NO = 'NO',
    /**
     * 限购
     */
    YES = 'YES',
}

/**
 * 按场次报名
 */
export enum SessionType {
    /**
     * 否
     */
    NO = 'NO',
    /**
     * 是
     */
    YES = 'YES',
}

/**
 * 参与人群
 */
enum TargetUserType {
    /**
     * 全部
     */
    ANY = 'ANY',
    /**
     * 指定
     */
    SOME = 'SOME',
}

/**
 * 奖品发放
 */
enum RewardType {
    /**
     * 非报名类
     */
    OFF_LINE = 'OFF_LINE',
    /**
     * 报名类
     */
    COUPON = 'COUPON',
}

export interface MallActivitySettingAddFormProps extends BasicFormProps {
    registrationType: RegistrationType;
    rewardType: RewardType;
    targetUserType: TargetUserType;
    limitCountType: LimitCountType;
    limitCount: number;
    totalQuantity: number;
    session: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    onReloadOptions: (fieldName: any, fields: any) => void;
}

class mallActivitySettingAddFormView extends PureComponent<MallActivitySettingAddFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, onReloadOptions } = this.props;

        let {
            registrationType,
            rewardType,
            targetUserType,
            limitCountType,
            limitCount,
            totalQuantity,
            session,
        } = this.props;
        let fields: any = [
            { property: 'baseInfo.name' },
            { property: 'baseInfo.pictures' },
            { property: 'baseInfo.dateRange' },
            { property: 'baseInfo.dayTime' },
            { property: 'baseInfo.address' },
            { property: 'baseInfo.phone' },
            { property: 'baseInfo.info' },
            { property: 'merchants', className: 'property-left' },
            { property: 'tagIdList', className: 'property-left' },
            { property: 'registrationInfo' },
            {
                property: 'registrationType',
                className: 'property-left',
            },
        ];

        // 如果是报名类
        if (registrationType === RegistrationType.YES) {
            fields = fields.concat([
                {
                    property: 'timeLimit',
                    className: 'property-left',
                },
                {
                    property: 'registrationDateRange',
                    className: 'property-left',
                },
                {
                    property: 'session',
                    className: 'property-left',
                },
            ]);

            if (session === SessionType.YES) {
                fields = fields.concat([
                    {
                        property: 'registrationTimes',
                        className: 'property-left',
                    },
                ]);
            } else {
                fields = fields.concat([
                    {
                        property: 'totalQuantity',
                        className: 'property-left',
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'limitCountType',
                    className: 'property-left',
                },
            ]);

            // 如果是限购数量
            if (limitCountType === LimitCountType.YES) {
                fields = fields.concat([
                    {
                        property: 'limitCount',
                        className: 'property-left',
                        controlConfig: {
                            min: 1,
                            max:
                                totalQuantity && totalQuantity < limitCount
                                    ? totalQuantity
                                    : 999999999,
                            style: {
                                width: 150,
                            },
                            addonbefore: '每用户',
                            addonafter: '个名额',
                            tip: '',
                        },
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'registrationPrice',
                    className: 'property-left',
                },
                {
                    property: 'targetUserType',
                    className: 'property-left',
                },
            ]);

            // 如果是指定参与人群
            if (targetUserType === TargetUserType.SOME) {
                fields = fields.concat([
                    {
                        property: 'targetUserLevels',
                        className: 'property-left',
                    },
                    {
                        property: 'targetUserBirthDay',
                        className: 'property-left',
                    },
                    {
                        property: 'targetUserSexuality',
                        className: 'property-left',
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'customInfo',
                    className: 'property-left',
                },
                {
                    property: 'info',
                    className: 'property-left',
                },
                {
                    property: 'rewardType',
                    className: 'property-left',
                },
            ]);

            // 如果发放奖品为优惠券
            if (rewardType === RewardType.COUPON) {
                fields = fields.concat([
                    {
                        property: 'selectCoupon',
                        className: 'property-left',
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'rewardName',
                    className: 'property-left',
                },
                {
                    property: 'reviewType',
                    className: 'property-left',
                },
            ]);
        }

        let formProps = {
            entity,
            componentId: 'MallActivitySettingAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'mall-activity-setting-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        // return createElement(ComponentsManager.get('CreateForm'), formProps);
        return <CreateForm {...formProps}></CreateForm>;
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, params } = props;
    const properties = entity.metainfo.properties;
    // 活动类型
    const registrationTypeDefaultValue = get(properties, 'registrationType.defaultValue');
    let registrationType: RegistrationType = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.registrationType',
        registrationTypeDefaultValue
    );

    // 奖励类型
    const rewardTypeDefaultValue = get(properties, 'rewardType.defaultValue');
    let rewardType: RewardType = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.rewardType'
    );

    // 参与人群
    const targetUserTypeDefaultValue = get(properties, 'targetUserType.defaultValue');
    let targetUserType: TargetUserType = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.targetUserType'
    );

    // 报名价格
    const registrationPriceDefaultValue = get(properties, 'registrationPrice.defaultValue');
    let registrationPrice: any = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.registrationPrice'
    );

    // 按场次报名
    const sessionDefaultValue = get(properties, 'session.defaultValue');
    let session: any = get(state, 'components.MallActivitySettingAddFormContainer.fields.session');

    // 报名限购
    const limitCountTypeDefaultValue = get(properties, 'limitCountType.defaultValue');
    let limitCountType: any = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.limitCountType'
    );

    // 限报数量
    const limitCountDefaultValue = get(properties, 'limitCount.defaultValue');
    let limitCount: any = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.limitCount'
    );

    // 报名审核
    const reviewTypeDefaultValue = get(properties, 'reviewType.defaultValue');
    let reviewType: any = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.reviewType'
    );
    // 报名总数
    const totalQuantityDefaultValue = get(properties, 'totalQuantity.defaultValue');
    let totalQuantity: any = get(
        state,
        'components.MallActivitySettingAddFormContainer.fields.totalQuantity'
    );

    // 为子级项设置默认值
    if (registrationType === RegistrationType.YES) {
        if (isUndefined(rewardType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingAddFormContainer',
                        'rewardType',
                        rewardTypeDefaultValue
                    )
                );
        }
        if (isUndefined(targetUserType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingAddFormContainer',
                        'targetUserType',
                        targetUserTypeDefaultValue
                    )
                );
        }
        if (isUndefined(limitCountType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingAddFormContainer',
                        'limitCountType',
                        limitCountTypeDefaultValue
                    )
                );
        }
        if (isUndefined(limitCount)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingAddFormContainer',
                        'limitCount',
                        limitCountDefaultValue
                    )
                );
        }

        if (isUndefined(reviewType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingAddFormContainer',
                        'reviewType',
                        reviewTypeDefaultValue
                    )
                );
        }

        if (isUndefined(session)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingAddFormContainer',
                        'session',
                        sessionDefaultValue
                    )
                );
        }

        if (isUndefined(totalQuantity)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'totalQuantity',
                        totalQuantityDefaultValue
                    )
                );
        }
    }
    if (isUndefined(registrationPrice)) {
        builder
            .getStore()
            .dispatch(
                actions.formChangeAction(
                    'MallActivitySettingAddFormContainer',
                    'registrationPrice',
                    registrationPriceDefaultValue
                )
            );
    }

    let form: any;
    return defaults(
        {
            registrationType,
            rewardType,
            targetUserType,
            registrationPrice,
            limitCountType,
            limitCount,
            totalQuantity,
            session,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });

                if (entityFields.session === SessionType.YES) {
                    // 过滤掉什么也没填的项
                    entityFields.registrationTimes = entityFields.registrationTimes.filter(
                        (item: any) =>
                            item.startDate || item.startTime || item.endTime || item.quantity
                    );
                    // 统计完整项的数量
                    const completeCount = entityFields.registrationTimes.reduce(
                        (count: number, item: any) =>
                            item.startDate && item.startTime && item.endTime && item.quantity
                                ? count + 1
                                : count,
                        0
                    );

                    if (
                        completeCount === 0 ||
                        completeCount !== entityFields.registrationTimes.length
                    ) {
                        message.error('报名场次日期、时间、名额不能为空');
                        return;
                    }
                }

                if (entityFields) {
                    entity.add(entityFields, params);
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('MallActivitySettingAddFormContainer')
                    );
            },
            // 重新加载属性候选值
            onReloadOptions: (fieldName: any, fields: any) => {
                //? 因为在这个场景下reload时fields可能已经不在dataTable上了
                //? 会导致修改被依赖字段未触发获取依赖方获取数据
                //? 所以这里改成从entity上取需要的参数
                const field = get(entity, `metainfo.properties.${fieldName}`);
                if (!field) return;
                const sourceDefination = field.source;
                let dependences = sourceDefination.dependences;
                let params = reduce(
                    dependences,
                    (values, dependence) => {
                        values[dependence] = getField(dependence, fields).value;
                        return values;
                    },
                    props.params ? clone(props.params) : {}
                );
                entity.loadPropertyOptions(fieldName, sourceDefination, params);
            },
        }
    );
}
export const MallActivitySettingAddFormView = connect(mapStateToProps)(
    mallActivitySettingAddFormView
);
