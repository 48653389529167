import React, { Component } from 'react';
import { api } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import { message as AntMessage, Modal as AntModal, Tabs as AntTabs } from 'antd';
import {
    DeliveryMode,
    LogisticsBillGenerationMethodValue,
    DeliveryCompanyConfig,
    DeliveryCompanyObj,
    LogisticsBillGenerationMethodEnum,
} from '../types';
import { AddDeliveryCompany } from '../add-company';
import './index.less';
import { services } from '@comall-backend-builder/core';

const AntTabPane = AntTabs.TabPane;

export interface DeliveryLogisticsCompaniesTableProps {
    params: any;
    location: any;
}

export enum DeliveryLogisticsCompaniesActiveKey {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 自动叫单
     */
    AUTOMATIC = 'AUTOMATIC',
    /**
     * 手动填单
     */
    MANUAL = 'MANUAL',
}

export interface DeliveryLogisticsCompaniesTableState {
    expressList: any | null;
    citywideList: any | null;
    companyModalVisible: boolean;
    modalType: 'add' | 'edit';
    modalDeliveryMode: DeliveryMode;
    editCompany: DeliveryCompanyConfig | null;
    subsiteName: string;
    currentExpressActiveKey: DeliveryLogisticsCompaniesActiveKey;
    currentCityWideActiveKey: DeliveryLogisticsCompaniesActiveKey;
}

export class DeliveryLogisticsCompaniesTableView extends Component<
    DeliveryLogisticsCompaniesTableProps,
    DeliveryLogisticsCompaniesTableState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            expressList: null,
            citywideList: null,
            companyModalVisible: false,
            modalType: 'add',
            modalDeliveryMode: DeliveryMode.EXPRESS,
            editCompany: null,
            subsiteName: '',
            currentExpressActiveKey: DeliveryLogisticsCompaniesActiveKey.ALL,
            currentCityWideActiveKey: DeliveryLogisticsCompaniesActiveKey.ALL,
        };
    }

    componentDidMount() {
        const { params, location } = this.props;
        const search = location.search;
        let { subsiteName, logisticsConfigId } =
            Object.fromEntries(new URLSearchParams(search).entries()) || {};
        this.setState({ subsiteName });
        // 加载快递配送数据
        api.get(
            {
                subsiteId: params.subsiteId,
                logisticsConfigId: logisticsConfigId,
                deliveryMode: DeliveryMode.EXPRESS,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: `/admin/delivery_logistics_companies`,
            }
        ).then((response: any) => {
            this.setState({
                expressList: response,
            });
        });
        // 加载同城配数据
        api.get(
            {
                subsiteId: params.subsiteId,
                logisticsConfigId: logisticsConfigId,
                deliveryMode: DeliveryMode.CITYWIDE,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: `/admin/delivery_logistics_companies`,
            }
        ).then((response: any) => {
            this.setState({
                citywideList: response,
            });
        });
    }
    addCompany = (type: DeliveryMode) => {
        this.setState({ companyModalVisible: true, modalType: 'add', modalDeliveryMode: type });
    };
    onCloseCompanyModal = (reload?: boolean) => {
        this.setState({
            companyModalVisible: false,
            editCompany: null,
            modalDeliveryMode: DeliveryMode.EXPRESS,
        });
        if (reload) {
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
    };

    // 删除配送公司
    deleteCompony = (item: DeliveryCompanyConfig) => {
        AntModal.confirm({
            title: services.language.getText('tip'),
            content: services.language.getText('qdsc') + item.companyName + '?',
            onOk: () => {
                const id = item.id;
                api.delete(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                        apiPath: `/admin/delivery_logistics_companies/${id}`,
                    }
                ).then(() => {
                    AntMessage.success(services.language.getText('sccg'), () => {
                        window.location.reload();
                    });
                });
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
        });
    };
    editCompany = (item: DeliveryCompanyConfig, type: DeliveryMode) => {
        this.setState({
            modalType: 'edit',
            modalDeliveryMode: type,
            companyModalVisible: true,
            editCompany: item,
        });
    };

    renderListItem = (item: DeliveryCompanyConfig, type: DeliveryMode) => {
        const companyObj = DeliveryCompanyObj[item.companyCode];
        const accountItem = companyObj ? companyObj.account : undefined;
        const passwordItem = companyObj ? companyObj.password : undefined;
        const logisticsBillGenerationMethodsNames = item.logisticsBillGenerationMethods
            ? item.logisticsBillGenerationMethods
                  .map((method) => {
                      return services.language.getText(LogisticsBillGenerationMethodValue[method]);
                  })
                  .join(',')
            : undefined;
        return (
            <div className="item-wrap">
                {item.defaulted && <div className="default-item">默认</div>}
                <div className="item">
                    <span>{services.language.getText('wlgs')}：</span>
                    <span>{item.companyName}</span>
                </div>
                <div className="item">
                    <span>{services.language.getText('scydfs')}：</span>
                    <span>{logisticsBillGenerationMethodsNames}</span>
                </div>
                {accountItem &&
                    item.logisticsBillGenerationMethods &&
                    item.logisticsBillGenerationMethods.includes(
                        LogisticsBillGenerationMethodEnum.AUTOMATIC
                    ) && (
                        <div className="item">
                            <span>{accountItem.name}：</span>
                            <span>{item.account}</span>
                        </div>
                    )}
                {passwordItem &&
                    item.logisticsBillGenerationMethods &&
                    item.logisticsBillGenerationMethods.includes(
                        LogisticsBillGenerationMethodEnum.AUTOMATIC
                    ) && (
                        <div className="item">
                            <span>{passwordItem.name}：</span>
                            <span>{item.password}</span>
                        </div>
                    )}

                <div className="item-btns">
                    <div
                        className="item-btn delete-btn"
                        onClick={() => {
                            this.deleteCompony(item);
                        }}
                    >
                        {services.language.getText('common.delete')}
                    </div>
                    <div
                        className="item-btn edit-btn"
                        onClick={() => {
                            this.editCompany(item, type);
                        }}
                    >
                        {services.language.getText('common.edit')}
                    </div>
                </div>
            </div>
        );
    };

    onChangeActiveKey = (type: DeliveryMode, key: string) => {
        if (type === DeliveryMode.EXPRESS) {
            this.setState({
                currentExpressActiveKey: key as DeliveryLogisticsCompaniesActiveKey,
            });
        } else if (type === DeliveryMode.CITYWIDE) {
            this.setState({
                currentCityWideActiveKey: key as DeliveryLogisticsCompaniesActiveKey,
            });
        }
    };

    getExpressList = () => {
        const { expressList, currentExpressActiveKey } = this.state;
        if (expressList && expressList.length > 0) {
            if (currentExpressActiveKey === DeliveryLogisticsCompaniesActiveKey.ALL) {
                return expressList;
            }
            const newExpressList = expressList.filter(
                (item: any) =>
                    item.logisticsBillGenerationMethods &&
                    item.logisticsBillGenerationMethods.includes(currentExpressActiveKey)
            );
            return newExpressList;
        }
        return null;
    };
    getCitywideList = () => {
        const { citywideList, currentCityWideActiveKey } = this.state;
        if (citywideList && citywideList.length > 0) {
            if (currentCityWideActiveKey === DeliveryLogisticsCompaniesActiveKey.ALL) {
                return citywideList;
            }
            const newCitywideList = citywideList.filter(
                (item: any) =>
                    item.logisticsBillGenerationMethods &&
                    item.logisticsBillGenerationMethods.includes(currentCityWideActiveKey)
            );
            return newCitywideList;
        }
        return null;
    };

    render() {
        const {
            companyModalVisible,
            modalType,
            modalDeliveryMode,
            editCompany,
            subsiteName,
            currentExpressActiveKey,
            currentCityWideActiveKey,
        } = this.state;
        const activeKeys = [
            {
                id: DeliveryLogisticsCompaniesActiveKey.ALL,
                name: services.language.getText('all'),
            },
            {
                id: DeliveryLogisticsCompaniesActiveKey.AUTOMATIC,
                name: services.language.getText('zdjd'),
            },
            {
                id: DeliveryLogisticsCompaniesActiveKey.MANUAL,
                name: services.language.getText('sdtd'),
            },
        ];
        const expressList = this.getExpressList();
        const citywideList = this.getCitywideList();
        return (
            <div className="delivery-company ant-form">
                <div className="subsite-name">
                    {services.language.getText('subsiteName')}：{subsiteName}
                </div>
                <div className="company-list">
                    <div className="list-title">{services.language.getText('kdps')}</div>
                    <div className="button-box">
                        <Button
                            icon="plus"
                            type="primary"
                            className="add-button"
                            onClick={this.addCompany.bind(this, DeliveryMode.EXPRESS)}
                        >
                            {services.language.getText('xzkdpsgs')}
                        </Button>
                    </div>
                    <AntTabs
                        activeKey={currentExpressActiveKey}
                        animated={false}
                        onChange={(key) => {
                            this.onChangeActiveKey(DeliveryMode.EXPRESS, key);
                        }}
                    >
                        {activeKeys.map((key) => {
                            return (
                                <AntTabPane tab={key.name} key={key.id}>
                                    <div></div>
                                </AntTabPane>
                            );
                        })}
                    </AntTabs>
                    <div className="list-wrap">
                        {expressList &&
                            expressList.map((item: any) => (
                                <div key={item.id}>
                                    {this.renderListItem(item, DeliveryMode.EXPRESS)}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="company-list">
                    <div className="list-title">{services.language.getText('tcps')}</div>
                    <div className="button-box">
                        <Button
                            icon="plus"
                            type="primary"
                            className="add-button"
                            onClick={this.addCompany.bind(this, DeliveryMode.CITYWIDE)}
                        >
                            {services.language.getText('xztcppsgs')}
                        </Button>
                    </div>
                    <AntTabs
                        activeKey={currentCityWideActiveKey}
                        animated={false}
                        onChange={(key) => {
                            this.onChangeActiveKey(DeliveryMode.CITYWIDE, key);
                        }}
                    >
                        {activeKeys.map((key) => {
                            return (
                                <AntTabPane tab={key.name} key={key.id}>
                                    <div></div>
                                </AntTabPane>
                            );
                        })}
                    </AntTabs>
                    <div className="list-wrap">
                        {citywideList &&
                            citywideList.map((item: any) => (
                                <div key={item.id}>
                                    {this.renderListItem(item, DeliveryMode.CITYWIDE)}
                                </div>
                            ))}
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button route="goBack">{services.language.getText('back')}</Button>
                </div>
                {companyModalVisible && (
                    <AddDeliveryCompany
                        visible={companyModalVisible}
                        onClose={this.onCloseCompanyModal}
                        type={modalType}
                        deliveryMode={modalDeliveryMode}
                        subsiteId={this.props.params.subsiteId}
                        editCompany={editCompany}
                    />
                )}
            </div>
        );
    }
}
