import { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
export class MemberLevelListStatusBotton extends PureComponent<any, any> {
    renderDisableButton = () => {
        const { row } = this.props;
        let buttonProps = {
            text: row.status === 'ENABLED' ? '禁用' : '启用',
            disabled: true,
            type: 'link',
            style: {
                marginRight: '10px',
            },
        };
        return createElement(ComponentsManager.get('Button'), buttonProps);
    };

    render() {
        const { row } = this.props;

        let buttonProps = {
            ...this.props,
            statusKey: 'status',
            renderComponent: 'Button',
            type: 'link',
            style: { display: 'inline-flex' },
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            handles: [
                {
                    params: { status: 'DISABLED' },
                    value: 'ENABLED',
                    apiPath: '/admin/member_levels/:id/status',
                    config: { content: '禁用操作', text: '禁用' },
                    confirm: {
                        text: '禁用等级',
                        okText: '禁用',
                        content: '确认禁用该等级？',
                    },
                },
                {
                    params: { status: 'ENABLED' },
                    value: 'DISABLED',
                    apiPath: '/admin/member_levels/:id/status',
                    config: { content: '启用操作', text: '启用' },
                    confirm: {
                        text: '启用等级',
                        okText: '启用',
                        content:
                            '启用该等级后，上一等级的会员可能会发生等级变动，请慎重操作！（此运算将会在会员再次触发规则时重新计算）',
                    },
                },
            ],
        };

        return row.click
            ? createElement(ComponentsManager.get('ChangeStatusButton'), buttonProps)
            : this.renderDisableButton();
    }
}
