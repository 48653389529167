import React, { Component, CSSProperties } from 'react';
import { services } from '@comall-backend-builder/core';
import { Cascader, Input } from 'antd';
import { get, last } from 'lodash';

const {
    api,
    language: { getText },
} = services;

interface CityAndAddressSelectValue {
    regionIds: string[];
    address: string;
}

interface CityAndAddressSelectProps {
    value: CityAndAddressSelectValue;
    style?: CSSProperties;
    isAdd: boolean;
    row: { baseInfo: { subsiteId: string } };
    onChange: (value: CityAndAddressSelectValue) => void;
}

export interface RegionOption {
    hasChild: boolean;
    id: string;
    level: number;
    name: string;
    parentId: number;
    parentPath: string;
    sequence: number;
}

export class CityAndAddressSelect extends Component<CityAndAddressSelectProps, any> {
    constructor(props: CityAndAddressSelectProps) {
        super(props);
        this.state = {
            options: [],
        };
    }

    async componentDidMount() {
        const { value } = this.props;
        if (value && value.regionIds) {
            this.loadAllData(value.regionIds);
        } else {
            const res = await this.loadData('0');
            this.setState({
                options: this.format(res),
            });
        }
    }

    componentWillReceiveProps(nextProps: CityAndAddressSelectProps) {
        const subsiteId = get(this.props, 'row.baseInfo.subsiteId');
        const nextSubsiteId = get(nextProps, 'row.baseInfo.subsiteId');
        if (nextProps && nextProps.isAdd && nextSubsiteId && subsiteId !== nextSubsiteId) {
            this.setInitRegions(nextSubsiteId);
        }
        if (nextProps && !nextProps.isAdd && !this.props.value && nextProps.value) {
            this.loadAllData(nextProps.value.regionIds);
        }
    }

    componentWillUnmount = () => {
        this.setState = () => false;
    };

    setInitRegions = async (subsiteId: string) => {
        const { onChange } = this.props;
        const subsiteInfo = await api.get<any>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: `/WEB-API/admin/subsite/${subsiteId}`,
            }
        );
        const parentRegionIds = subsiteInfo.parentRegionIds as string[];
        const lastRegionId =
            parentRegionIds && parentRegionIds.length ? last(parentRegionIds) || '0' : '0';
        const lastInfo = await this.loadData(lastRegionId);
        if (lastInfo && lastInfo.length === 0) {
            if (parentRegionIds && parentRegionIds.length) {
                this.loadAllData(parentRegionIds.slice(1));
                onChange({
                    regionIds: parentRegionIds.slice(1),
                    address: '',
                });
            }
        } else {
            onChange({
                regionIds: [],
                address: '',
            });
        }
    };

    loadData = (id: string) => {
        return api.get<RegionOption[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/regions/${id}/children`,
            }
        );
    };

    loadAllData = async (ids: string[]) => {
        const [parent, ...children] = await Promise.all([
            this.loadData('0'),
            ...ids.map((id) => this.loadData(id)),
        ]);
        const options = this.format(parent).map((item: any) => {
            return this.getRegionTree(item, 0, ids, children);
        });
        this.setState({
            options,
        });
    };

    getRegionTree = (item: any, index: number, ids: string[], children: RegionOption[][]): any => {
        if (item.value === ids[index]) {
            const current = this.format(children[index]);
            item.children = current.map((el) => {
                return this.getRegionTree(el, index + 1, ids, children);
            });
            return item;
        } else {
            return item;
        }
    };

    format = (data: RegionOption[]) => {
        return data.map((region) => ({
            value: region.id,
            label: region.name,
            isLeaf: !region.hasChild,
            loading: false,
        }));
    };
    onLoadData = async (selectedOptions: any) => {
        const { options } = this.state;
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const res = await this.loadData(targetOption.value);
        const childrenOption = this.format(res);
        targetOption.children = childrenOption;
        this.setState(
            {
                options: [...options],
            },
            () => {
                targetOption.loading = false;
            }
        );
    };
    onChange = (regionIds: string[]) => {
        const {
            onChange,
            value = {
                regionIds: [],
                address: '',
            },
        } = this.props;
        onChange &&
            onChange({
                regionIds,
                address: value.address,
            });
    };

    onChangeAddress = (e: any) => {
        const {
            onChange,
            value = {
                regionIds: [],
                address: '',
            },
        } = this.props;
        onChange &&
            onChange({
                regionIds: value.regionIds,
                address: e.target.value || '',
            });
    };

    render() {
        const { style, value } = this.props;
        const { options } = this.state;
        const regionIds = value ? value.regionIds : [];
        const address = value ? value.address : '';
        return (
            <div style={style}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Cascader
                        style={{ width: 300 }}
                        value={regionIds}
                        options={options}
                        loadData={this.onLoadData}
                        onChange={this.onChange}
                    />
                    <div style={{ marginLeft: 10 }}>({getText('qyxx')})</div>
                </div>
                <Input
                    placeholder={getText('h_select_city_address_placeholder')}
                    value={address}
                    onChange={this.onChangeAddress}
                />
            </div>
        );
    }
}
