import React, { PureComponent } from 'react';
import { cloneDeep, get } from 'lodash';
import { MerchantsSelector } from './merchants-selector';
import { MerchantsSelectorDisplay } from './merchants-selector-display';
import { services } from '@comall-backend-builder/core';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { Radio } from 'antd';
import './index.less';

const { getText } = services.language;

export interface MerchantsSelectorItem {
    id: string;
    name: string;
    status: string;
    type: string;
}

enum MerchantScope {
    ALL = 'ALL',
    PART = 'PART',
}

interface InvoiceInfoBySubsiteVo {
    merchantTypes: string[];
    merchantScope: MerchantScope;
    merchants: MerchantsSelectorItem[];
}

export const InvoiceMerchantTypes = [
    {
        id: 'MERCHANT_SELF',
        name: 'selfOperated',
        typeId: 4,
    },
    {
        id: 'JOINT',
        name: 'joint',
        typeId: 1,
    },

    {
        id: 'LEASE',
        name: 'lease',
        typeId: 2,
    },
    {
        id: 'LEAGUE',
        name: 'league',
        typeId: 3,
    },
    {
        id: 'OTHER',
        name: 'other',
        typeId: 99,
    },
];

interface InvoiceInfoBySubsiteProps {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: InvoiceInfoBySubsiteVo;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: InvoiceInfoBySubsiteVo, name: string) => void;
}
const defalutValue = {
    merchantTypes: [],
    merchantScope: MerchantScope.ALL,
    merchants: [],
};
let loadFirst = true;
export class InvoiceInfoBySubsite extends PureComponent<InvoiceInfoBySubsiteProps> {
    static defaultProps = {
        value: defalutValue,
    };

    first = true;

    componentDidMount() {
        const { value } = this.props;
        const isEdit =
            window.location.href.includes('/edit') || window.location.href.includes('/copy');
        //如果是编辑页面,并且是第一次加载返回内容，则不修改
        if (isEdit && loadFirst && value && value.merchantTypes && value.merchantTypes.length > 0) {
            loadFirst = false;
            return;
        }
        if (!value) {
            this.onChange(defalutValue);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const subsiteFieldName = 'baseInfo.subsite';
        const nextSubsites = get(nextProps.row, subsiteFieldName);
        const preSubsites = get(this.props.row, subsiteFieldName);
        this.handleSubsitesChange(nextSubsites, preSubsites);
    }

    handleSubsitesChange = (nextSubsites: any, preSubsites: any) => {
        if (!preSubsites) return;
        if (nextSubsites !== preSubsites) {
            this.onChange(defalutValue);
            return;
        }
    };
    onChangeMerchants = (selectValues: MerchantsSelectorItem[]) => {
        const { value } = this.props;
        value.merchants = selectValues && selectValues.length === 0 ? [] : selectValues;
        this.onChange(value);
    };

    onSelectMerchants = (selectValues: MerchantsSelectorItem[]) => {
        const { value } = this.props;
        value.merchants = selectValues;
        this.onChange(value);
    };

    onChange(value: any) {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(cloneDeep(value), name);
        }
    }

    onChangeMerchantTypes = (checkedValue: any[]) => {
        const { value } = this.props;
        value.merchantTypes = checkedValue;
        value.merchantScope = MerchantScope.ALL;
        value.merchants = [];
        this.onChange(value);
    };

    onChangeMerchantScope = (scope: MerchantScope) => {
        const { value } = this.props;
        value.merchantScope = scope;
        value.merchants = [];
        this.onChange(value);
    };

    renderMerchantTyppes = () => {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const options = InvoiceMerchantTypes.map((i) => ({ ...i, name: getText(i.name) }));
        return (
            <div>
                <CheckboxGroup
                    name="merchantTypes"
                    onChange={this.onChangeMerchantTypes}
                    options={options}
                    value={value.merchantTypes}
                />
                <div className="ant-form-extra">{getText('h_invoice_info_by_subsite_tip')}</div>
            </div>
        );
    };

    renderMerchants = () => {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const hasValue = value.merchants && value.merchants.length > 0;
        return (
            <div>
                <div className="merchant-scope">
                    <span>
                        <span className="tip">*</span>
                        {getText('kkpzj')}：
                    </span>
                    <Radio.Group
                        disabled={
                            !value || !value.merchantTypes || value.merchantTypes.length === 0
                        }
                        className="fill-style"
                        value={value.merchantScope}
                        onChange={(e: any) => {
                            this.onChangeMerchantScope(e.target.value);
                        }}
                    >
                        <Radio value={MerchantScope.ALL}>{getText('qbzj')}</Radio>
                        <Radio value={MerchantScope.PART}>{getText('zdzj')}</Radio>
                    </Radio.Group>
                </div>
                {value && value.merchantScope === MerchantScope.PART && (
                    <div className="merchants-wrap">
                        <div>
                            <span>
                                <span className="tip">*</span>
                                {getText('selectSubsites')}：
                            </span>
                            <MerchantsSelector
                                {...this.props}
                                onChange={this.onSelectMerchants}
                                selectValues={value.merchants}
                            />
                        </div>
                        {hasValue && (
                            <div className="merchants-display">
                                <MerchantsSelectorDisplay
                                    data={value.merchants || []}
                                    onRemove={this.onChangeMerchants}
                                ></MerchantsSelectorDisplay>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="invoice-info-by-subsite">
                {this.renderMerchantTyppes()}
                {this.renderMerchants()}
            </div>
        );
    }
}
