import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        WechatCustomMenusView: {
            component: 'Viewport',
        },
        WechatCustomMenusPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WechatCustomMenusSettingPage',
                    entity: 'storeSelect',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-custom-menus',
                    component: 'WechatCustomMenusView',
                    breadcrumbName: '<<customizeMenus>>',
                    privilege: {
                        path: 'customizeMenus',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'WechatCustomMenusPage',
                        },
                    ],
                },
            ],
        },
    ],
};
