import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { NumberInputTitleTip } from '../../../components/number-input-title-tip';

export class NumberInputTitleTipMode extends modes.ObjectMode {
    public getDisplayComponent(object: any, displayInfo: any) {
        const {
            className,
            style,
            pickupTimeConfig,
            pickupLimitTimeConfig,
            title,
            eachOrderConfig,
            eachPresaleConfig,
        } = displayInfo;
        if (object) {
            return (
                <div className={classNames('input-title-tip', className)} style={style}>
                    {title && <div>{title}</div>}
                    {pickupTimeConfig && pickupTimeConfig.labelTitle && (
                        <div>
                            {pickupTimeConfig.labelTitle}：
                            {object.pickupTime > 0 && (
                                <span>
                                    {pickupTimeConfig.addonbefore}
                                    <span>{object.pickupTime}</span> {pickupTimeConfig.addonafter}
                                </span>
                            )}
                        </div>
                    )}
                    {pickupLimitTimeConfig && pickupLimitTimeConfig.labelTitle && (
                        <div>
                            {pickupLimitTimeConfig.labelTitle}：
                            {object.pickupLimitTime > 0 && (
                                <span>
                                    {pickupLimitTimeConfig.addonbefore}
                                    <span>{object.pickupLimitTime} </span>
                                    {pickupLimitTimeConfig.addonafter}
                                </span>
                            )}
                        </div>
                    )}

                    {eachOrderConfig && (
                        <div>
                            {object.eachOrder > 0 && (
                                <span>
                                    {eachOrderConfig.addonbefore}
                                    <span>{object.eachOrder} </span>
                                    {eachOrderConfig.addonafter}
                                </span>
                            )}
                        </div>
                    )}
                    {eachPresaleConfig && (
                        <div>
                            {object.eachPresale > 0 && (
                                <span>
                                    {eachPresaleConfig.addonbefore}
                                    <span>{object.eachPresale} </span>
                                    {eachPresaleConfig.addonafter}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <NumberInputTitleTip {...displayInfo} />;
    }
}
