import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { message, Table, Input, Icon, Button, Select, InputNumber } from 'antd';
import { cloneDeep, set } from 'lodash';

import './index.less';

const {
    api,
    errorHandle,
    language: { getText },
} = services;

interface MerchantCategoryCodeTaxRate {
    code: string;
    codeName: string;
    id: number;
    name?: string;
    status: string | boolean;
    taxCode?: string;
    taxRate?: string;
    taxRateType?: TaxRateType;
}

enum TaxRateType {
    STANDARD = 'STANDARD',
    DUTY_FREE = 'DUTY_FREE',
    NO_TAX = 'NO_TAX',
    ZERO_RATE = 'ZERO_RATE',
    EXPORT_TAX_REBATE = 'EXPORT_TAX_REBATE',
}

export interface MerchantCategoryCodeTaxRatesEditViewState {
    merchantCategoryCodeTaxRates: MerchantCategoryCodeTaxRate[];
}

const booleanOptions = [
    { id: 'true', name: 'yes' },
    { id: 'false', name: 'no' },
];

const taxRateOptions = [
    { id: TaxRateType.STANDARD, name: 'standardTaxRate' },
    { id: TaxRateType.DUTY_FREE, name: 'dutyFree' },
    { id: TaxRateType.NO_TAX, name: 'nonTaxtion' },
    { id: TaxRateType.ZERO_RATE, name: 'zeroTaxRate' },
    { id: TaxRateType.EXPORT_TAX_REBATE, name: 'exportTaxRefund' },
];

export class MerchantCategoryCodeTaxRatesEditView extends Component<
    {},
    MerchantCategoryCodeTaxRatesEditViewState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            merchantCategoryCodeTaxRates: [],
        };
    }
    componentDidMount() {
        const arr = window.location.href.split('/');
        const merchantId = arr[arr.length - 1];
        this.merchantId = merchantId;
        this.loadData(merchantId);
    }

    merchantId: string | undefined;

    loadData = (merchantId: string) => {
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        const apiPath = '/admin/merchant_category_code_tax_rates';
        api.get<MerchantCategoryCodeTaxRate[]>({ merchantId }, { apiRoot, apiPath })
            .then((res) => {
                const merchantCategoryCodeTaxRates = res.map(
                    (item: MerchantCategoryCodeTaxRate) => {
                        return {
                            ...item,
                            status: item.status ? 'true' : 'false',
                        };
                    }
                );
                this.setState({ merchantCategoryCodeTaxRates });
            })
            .catch(errorHandle);
    };

    searchInput: any;

    getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={getText('srsjmsx')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    {services.language.getText('filter')}
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    {services.language.getText('common.clear')}
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: string, record: MerchantCategoryCodeTaxRate) => record.code === value,
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    });

    getTableColumns = () => {
        const columns = [
            {
                title: services.language.getText('bigCode'),
                dataIndex: 'code',
                key: 'code',
                width: '180px',
                ...this.getColumnSearchProps(),
                render: (value: string) => value,
            },
            {
                title: services.language.getText('bigCodeName'),
                dataIndex: 'codeName',
                key: 'codeName',
                width: '200px',
                render: (value: string) => value,
            },
            {
                title: getText('isSupportInvoice'),
                dataIndex: 'status',
                key: 'status',
                width: '40px',
                render: (value: string, row: MerchantCategoryCodeTaxRate) =>
                    this.renderSelect(value, booleanOptions, row, 'status'),
            },
            {
                title: getText('invoiceName'),
                dataIndex: 'name',
                key: 'name',
                width: '200px',
                render: (value: string, row: MerchantCategoryCodeTaxRate) =>
                    this.renderInput(value, row, 'name', { maxLength: 100 }),
            },
            {
                title: getText('taxCode'),
                dataIndex: 'taxCode',
                key: 'taxCode',
                width: '260px',
                render: (value: string, row: MerchantCategoryCodeTaxRate) =>
                    this.renderInput(value, row, 'taxCode', { maxLength: 19 }),
            },
            {
                title: services.language.getText('taxRateType'),
                dataIndex: 'taxRateType',
                key: 'taxRateType',
                width: '130px',
                render: (value: string, row: MerchantCategoryCodeTaxRate) =>
                    this.renderSelect(value, taxRateOptions, row, 'taxRateType'),
            },
            {
                title: getText('taxRate'),
                dataIndex: 'taxRate',
                key: 'taxRate',
                width: '80px',
                render: (value: number, row: MerchantCategoryCodeTaxRate) =>
                    this.renderNumberInput(value, row, 'taxRate'),
            },
        ];
        return columns;
    };

    renderSelect = (
        value: string,
        options: any[],
        row: MerchantCategoryCodeTaxRate,
        fields: keyof MerchantCategoryCodeTaxRate
    ) => {
        return (
            <Select
                value={value}
                onSelect={(newValue: string) => this.onChange(newValue, row.id, fields)}
            >
                {options.map((option) => (
                    <Select.Option key={option.id}>{getText(option.name)}</Select.Option>
                ))}
            </Select>
        );
    };

    renderInput = (
        value: string,
        row: MerchantCategoryCodeTaxRate,
        fields: keyof MerchantCategoryCodeTaxRate,
        ops?: Record<string, string | number>
    ) => {
        return (
            <Input
                value={value}
                onChange={(e) => this.onChange(e.target.value, row.id, fields)}
                {...ops}
            />
        );
    };

    renderNumberInput = (
        value: number,
        row: MerchantCategoryCodeTaxRate,
        fields: keyof MerchantCategoryCodeTaxRate
    ) => {
        const disabled = row.taxRateType !== TaxRateType.STANDARD;
        return (
            <div className="number-input">
                <InputNumber
                    value={value}
                    min={1}
                    max={99}
                    precision={0}
                    disabled={disabled}
                    onChange={(num) => this.onChange(num, row.id, fields)}
                />
                <span className="suffix">%</span>
            </div>
        );
    };

    onChange = (
        value: string | number | undefined,
        rowId: number,
        fields: keyof MerchantCategoryCodeTaxRate
    ) => {
        const { merchantCategoryCodeTaxRates } = this.state;
        const newValues = cloneDeep(merchantCategoryCodeTaxRates);
        const row = newValues.find((item) => item.id === rowId);
        if (!row) return;
        set(row, fields, value);
        if (fields === 'taxRateType' && value !== TaxRateType.STANDARD) {
            set(row, 'taxRate', undefined);
        }
        if (fields === 'status' && value === 'true' && !row.taxRateType) {
            set(row, 'taxRateType', TaxRateType.STANDARD);
        }
        this.setState({ merchantCategoryCodeTaxRates: newValues });
    };

    validateRow = (row: MerchantCategoryCodeTaxRate) => {
        if (row.status === 'false') {
            return true;
        }
        if (!row.name || !row.name.trim()) {
            message.warn(`${getText('bigCode')}:${row.code}${getText('qtxkpmc')}`);
            return false;
        }
        if (!row.taxCode || !row.taxCode.trim()) {
            message.warn(`${getText('bigCode')}:${row.code}${getText('qtxssflbm')}`);
            return false;
        }
        if (row.taxRateType === TaxRateType.STANDARD && !row.taxRate) {
            message.warn(`${getText('bigCode')}:${row.code}${getText('taxRateRequired')}`);
            return false;
        }
        return true;
    };

    onSubmit = () => {
        const { merchantCategoryCodeTaxRates } = this.state;
        if (merchantCategoryCodeTaxRates.some((row) => !this.validateRow(row))) {
            return;
        }
        const apiPath = '/admin/merchant_category_code_tax_rates/batch';
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        api.post(merchantCategoryCodeTaxRates, { apiPath, apiRoot })
            .then(() => {
                message.success(services.language.getText('common.saveSuccess'));
                this.goBack();
            })
            .catch(errorHandle);
    };

    goBack = () => {
        services.behaviorHandle({ route: 'goBack' });
    };

    renderTable = () => {
        const columns = this.getTableColumns();
        const { merchantCategoryCodeTaxRates } = this.state;
        return (
            <Table
                style={{ width: '100%' }}
                scroll={{ x: true }}
                columns={columns}
                dataSource={merchantCategoryCodeTaxRates}
                pagination={false}
                rowKey="id"
            ></Table>
        );
    };

    renderFooter = () => {
        return (
            <div className="buttons">
                <Button type="primary" onClick={this.onSubmit}>
                    {getText('submit')}
                </Button>
                <Button type="default" onClick={this.goBack}>
                    {services.language.getText('back')}
                </Button>
            </div>
        );
    };

    render() {
        return (
            <div className="merchant-big-code-config-edit-view">
                {this.renderTable()}
                {this.renderFooter()}
            </div>
        );
    }
}
