import React, { PureComponent, Fragment, createElement } from 'react';
import { connect } from 'react-redux';
import { api } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Dropdown, Menu, Button as AntButton, Icon as AntIcon } from 'antd';
import { errorHandle } from '../../services/error-handle';
import './index.less';
/**
 * 用户管理列表
 */
class roleManageList extends PureComponent<any, any> {
    state = { uploadModalVisible: false };
    selectedRowKeys: string[] = [];
    onChange = (selectedRowKeys: string[]) => {
        this.selectedRowKeys = selectedRowKeys;
    };
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps = {
            entity,
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'status',
                    width: 100,
                    title: '状态',
                    displayConfig: {
                        statusConfig: {
                            NORMAL: {
                                text: '启用',
                                status: 'success',
                            },
                            ABNORMAL: {
                                text: '禁用',
                                status: 'error',
                            },
                        },
                    },
                },
                { property: 'createTime', sorter: true, width: 260 },
                { property: 'createUserName', width: 200 },
                {
                    property: 'remark',
                    width: 260,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
            ],
            // rowSelection: {
            //     onChange: this.onChange,
            // },
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 200,
                style: { width: 200 },
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                            handles: [
                                {
                                    params: { status: 'ABNORMAL' },
                                    value: 'NORMAL',
                                    apiPath: '/admin/roles/:id/status',
                                    config: { content: '禁用', text: '禁用' },
                                    confirm: {
                                        text: '是否禁用{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 'NORMAL' },
                                    value: 'ABNORMAL',
                                    apiPath: '/admin/roles/:id/status',
                                    config: { content: '启用', text: '启用' },
                                    confirm: {
                                        text: '是否启用{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'Dropdown',
                        config: {
                            style: { display: 'inline-flex' },
                            content: {
                                contentType: 'link',
                                text: '更多',
                            },
                            menuItems: [
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '编辑',
                                        type: 'link',
                                        route: '/role-manage/role-edit/{{row.id}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '分配权限',
                                        type: 'link',
                                        route:
                                            '/role-manage/privilege-management/{{row.id}}/{{row.name}}',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
    handleBatch = (e: any) => {
        let roleIds = this.selectedRowKeys;
        if (roleIds.length < 1) return;
        api.put(
            { roleIds, status: e.key },
            { apiPath: '/admin/roles/status_batch', apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE` }
        )
            .then(() => {
                const { entity, params } = this.props;
                entity.search(params);
            })
            .catch(errorHandle);
    };
    renderBatchButton = () => {
        const menu = (
            <Menu onClick={this.handleBatch}>
                <Menu.Item key="NORMAL">批量启用</Menu.Item>
                <Menu.Item key="ABNORMAL">批量禁用</Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu}>
                <AntButton>
                    批量操作
                    <AntIcon type="down" />
                </AntButton>
            </Dropdown>
        );
    };
    render() {
        return (
            <Fragment>
                <div className="batch-button">
                    <Button
                        style={{ marginRight: 10 }}
                        text="新增角色"
                        type="primary"
                        icon="plus"
                        route="/role-manage/role-add"
                    />
                    {/* {this.renderBatchButton()} */}
                </div>
                <div>{this.renderTable()}</div>
            </Fragment>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const RoleManageList = connect(mapStateToProps)(roleManageList);
