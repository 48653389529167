import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find, cloneDeep } from 'lodash';
import { Line } from '@ant-design/charts';
import { services } from '@comall-backend-builder/core';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

class miniprogramCodeDatasCardView extends PureComponent<{
    entity: any;
    params: any;
    requestStatus: any;
}> {
    state = {
        value: [],
        overviewDatas: null,
        scanCounts: null,
        registerCounts: null,
        loginCounts: null,
        orderCounts: null,
        orderAmount: null,
    };

    componentWillReceiveProps(nextProps: any) {
        const nextParams = nextProps.params;
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            this.loadDatas(nextParams);
        }
    }

    loadDatas = (data: any) => {
        let params = cloneDeep(data);
        params.subsiteId =
            params.subsiteId && params.subsiteId.id ? params.subsiteId.id : params.subsiteId;
        if (params.dateRange) {
            params.startTime = params.dateRange.start;
            params.endTime = params.dateRange.end;
            params.dateRange = undefined;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        // 推广数据概览
        const overviewDatasPromise = api.get(params, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics/counts',
        });

        // 扫码数排行
        const scanCountsPromise = api.get(params, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics/SCAN/line_chart',
        });

        // 注册数排行
        const registerCountsPromise = api.get(params, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics/REGISTER/line_chart',
        });

        // 登录数排行
        const loginCountsPromise = api.get(params, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics/USER_COUNT/line_chart',
        });

        // 下单数排行
        const orderCountsPromise = api.get(params, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics/ORDER/line_chart',
        });

        // 下单交易额排行
        const orderAmountPromise = api.get(params, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics/AMOUNT/line_chart',
        });

        return Promise.all([
            overviewDatasPromise,
            scanCountsPromise,
            registerCountsPromise,
            loginCountsPromise,
            orderCountsPromise,
            orderAmountPromise,
        ]).then((res: any[]) => {
            this.setState({
                overviewDatas: res[0],
                scanCounts: res[1],
                registerCounts: res[2],
                loginCounts: res[3],
                orderCounts: res[4],
                orderAmount: res[5],
            });
        });
    };

    /**
     * 渲染概览
     */
    renderOverview = (data: any) => {
        if (!data) return;
        const scanCounts = find(data, (item) => item.type === 'SCAN');
        const registerCounts = find(data, (item) => item.type === 'REGISTER');
        const loginCounts = find(data, (item) => item.type === 'USER_COUNT');
        const orderCounts = find(data, (item) => item.type === 'ORDER');
        const orderAmountCounts = find(data, (item) => item.type === 'AMOUNT');
        return (
            <div className="data-panel-reprot">
                {scanCounts &&
                    this.renderOverviewItem(
                        language.getText('smzcs'),
                        language.getText('frequency'),
                        scanCounts
                    )}
                {registerCounts &&
                    this.renderOverviewItem(
                        language.getText('scdlyhszl'),
                        language.getText('people'),
                        registerCounts
                    )}
                {loginCounts &&
                    this.renderOverviewItem(
                        services.language.getText('fwyhszl'),
                        services.language.getText('people'),
                        loginCounts
                    )}
                {orderCounts &&
                    this.renderOverviewItem(
                        language.getText('ddzl'),
                        language.getText('dan'),
                        orderCounts
                    )}
                {orderAmountCounts &&
                    this.renderOverviewItem(
                        language.getText('jyze'),
                        language.getText('yuan'),
                        orderAmountCounts
                    )}
            </div>
        );
    };

    /**
     * 渲染概览子项
     */
    renderOverviewItem = (title: string, unit: string, data: any) => {
        const lastWeek = data.compareWithLastWeek;
        const lastMonth = data.compareWithLastMonth;
        return (
            <div className="col">
                <div className="title">{title}</div>
                <div className="date">{data.startTime + ' ~ ' + data.endTime}</div>
                <div className="data">
                    {data.statisticsCounts}
                    <span className="unit">{unit}</span>
                </div>
                <div className="contrast">
                    <div className="contrast-item">
                        <span className="text"> {language.getText('hb')}</span>
                        <span className="percent">
                            {!!lastWeek ? Math.abs(lastWeek) + '%' : '-'}
                        </span>
                        {/* {!!lastWeek && (
                            <Icon
                                type={lastWeek < 0 ? 'arrow-down' : 'arrow-up'}
                                className={lastWeek < 0 ? 'icon-green' : 'icon-red'}
                            />
                        )} */}
                    </div>
                </div>
                <div className="contrast">
                    <div className="contrast-item">
                        <span className="text">{language.getText('tb')} </span>
                        <span className="percent">
                            {!!lastMonth ? Math.abs(lastMonth) + '%' : '-'}
                        </span>
                        {/* {!!lastMonth && (
                            <Icon
                                type={lastMonth < 0 ? 'arrow-down' : 'arrow-up'}
                                className={lastMonth < 0 ? 'icon-green' : 'icon-red'}
                            />
                        )} */}
                    </div>
                </div>
            </div>
        );
    };

    /**
     * 渲染数据排行折线图
     */
    renderCountsLineChart = (data: any, type: string = 'SCAN') => {
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            seriesField: 'seriesFieldValue',
            yAxis: {
                label: {
                    formatter: function formatter(v: any) {
                        return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
                            return ''.concat(s, ',');
                        });
                    },
                },
            },
            color: ['#ff794b', '#bb2f2a', '#FAA219', '#7ca5fb', '#7868fd', '#FAA219'],
        };
        let title = language.getText('tgqdfwpx');
        switch (type) {
            case 'USER_COUNT':
                title = language.getText('tgqdfwyhpx');
                break;
            case 'REGISTER':
                title = language.getText('tgqdscdlyhspx');
                break;
            case 'ORDER':
                title = language.getText('tgqdxdpx');
                break;
            case 'AMOUNT':
                title = language.getText('tgqdjyepx');
                break;
            default:
                break;
        }

        return (
            <div className="half">
                <div className="title">{title}</div>
                <Line {...config} />
            </div>
        );
    };

    render() {
        const {
            overviewDatas,
            scanCounts,
            registerCounts,
            loginCounts,
            orderCounts,
            orderAmount,
        } = this.state;

        return (
            <div className="miniprogram-code-datas-view">
                {this.renderOverview(overviewDatas)}
                <div className="chart chart-double">
                    {this.renderCountsLineChart(scanCounts, 'SCAN')}
                    {this.renderCountsLineChart(registerCounts, 'REGISTER')}
                </div>
                <div className="chart chart-double">
                    {this.renderCountsLineChart(loginCounts, 'USER_COUNT')}
                    {this.renderCountsLineChart(orderCounts, 'ORDER')}
                </div>
                <div className="chart chart-single">
                    {this.renderCountsLineChart(orderAmount, 'AMOUNT')}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        params: entity.filters ? entity.filters : {},
        requestStatus: entity.requestStatus ? entity.requestStatus : null,
    };
}

export const MiniprogramCodeDatasCardView = connect(
    mapStateToProps,
    null
)(miniprogramCodeDatasCardView);
