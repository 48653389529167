import React, { Component } from 'react';
import { Row as AntRow, InputNumber, Button, message, Popconfirm, Radio } from 'antd';
import { clone, map, concat, isEmpty } from 'lodash';

import './index.less';
import { ActivityRewardItem } from '../activity-reward-item';
import { Coupon } from '../../containers/select-coupon';
import { services } from '@comall-backend-builder/core';

type ActivityRewardValue = {
    point?: number;
    count?: number;
    coupons?: number;
    rewardType?: number;
    couponsValue: Array<Coupon>;
};

enum RewardItems {
    point = 'POINT',
    coupons = 'COUPON',
    count = 'COUNT',
}

export interface CardCollectionRewardProps {
    name: string;
    value: Array<ActivityRewardValue> | null;
    maxCount: number;
    disabled: boolean;
    onChange: (value: Array<ActivityRewardValue> | null, name: string) => void;
}
export class CardCollectionReward extends Component<CardCollectionRewardProps> {
    static defaultProps = {
        maxCount: 3,
        disabled: false,
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAddReward() {
        let { name, value, onChange, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning(
                    services.language.getText('mostSupport') +
                        maxCount +
                        services.language.getText('notAdd')
                );
                return;
            }
            value = concat([], value);
            value.splice(value.length, 0, { couponsValue: [] });
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: ActivityRewardValue = { couponsValue: [] };
                value = concat([], item);
            }
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.point) {
                item.point = inputValue;
            } else if (type === RewardItems.count) {
                item.count = inputValue;
            } else if (type === RewardItems.coupons) {
                item.coupons = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };

            if (type === RewardItems.point) {
                item.point = inputValue;
            } else if (type === RewardItems.count) {
                item.count = inputValue;
            } else if (type === RewardItems.coupons) {
                item.coupons = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let rewardType = event.target.value;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);

            item.rewardType = rewardType;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            item.rewardType = rewardType;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value, maxCount, disabled } = this.props;
        return (
            <div className="card-collection-avtivity-reward">
                {map(value, (reward, index) => this.renderReward(reward, index, disabled))}
                {value && value.length
                    ? null
                    : this.renderReward({ couponsValue: [] }, 0, disabled)}
                {value && value.length < maxCount && !disabled && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {services.language.getText('tjjp')}
                    </Button>
                )}
            </div>
        );
    }

    onChangeCoupon = (products: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.couponsValue = products;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            item.couponsValue = products;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    renderReward(reward: ActivityRewardValue, index: number, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        return (
            <div className="reward" key={index}>
                <AntRow>
                    <div className="reward-content">
                        <span>
                            {services.language.getText('jp')}
                            {index + 1}:
                        </span>
                        {!isEmpty(reward) && !isDisabled && (
                            <Popconfirm
                                onConfirm={this.onRemoveReward.bind(this, index)}
                                title={services.language.getText('sfscggz')}
                                okText={services.language.getText('common.ok')}
                                cancelText={services.language.getText('common.cancel')}
                            >
                                <Button type="default" className="reward-remove-reward-button">
                                    {services.language.getText('scjp')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </AntRow>

                <div className="reward-content-list">
                    <Radio.Group
                        disabled={isDisabled}
                        onChange={(e) => this.onCheckboxChange(e, index, RewardItems.point)}
                        value={reward.rewardType}
                    >
                        <Radio className="radio" value="POINT">
                            <span className="reward-item-text">
                                {services.language.getText('point')}
                            </span>
                            <InputNumber
                                style={{ marginLeft: 45 }}
                                type="number"
                                disabled={isDisabled}
                                min={1}
                                precision={0}
                                max={99999}
                                value={reward.point}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.point)
                                }
                            />
                            <span className="reward-item-text">
                                {services.language.getText('point')}
                            </span>
                            <InputNumber
                                disabled={isDisabled}
                                type="number"
                                min={0}
                                precision={0}
                                max={99999}
                                value={reward.count}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.count)
                                }
                            />
                            <span className="reward-item-text">
                                {services.language.getText('f')}
                            </span>
                        </Radio>
                        <Radio className="radio" value="COUPON">
                            <span className="reward-item-text">
                                {services.language.getText('yhq')}
                            </span>
                        </Radio>
                    </Radio.Group>
                    <div className="reward-coupons">
                        <ActivityRewardItem
                            disabled={isDisabled}
                            index={index}
                            value={reward.couponsValue}
                            subsiteIds={subsiteIds}
                            onChangeCoupon={this.onChangeCoupon}
                            type="radio"
                            packageSelectType="radio"
                            canChangeQuantity
                            showLeftStock
                        />
                        <div className="reward-coupons-count">
                            <InputNumber
                                disabled={isDisabled}
                                type="number"
                                min={0}
                                precision={0}
                                max={99999}
                                value={reward.coupons}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.coupons)
                                }
                            />
                            <span className="reward-item-text">
                                {services.language.getText('f')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };
}
