import React, { PureComponent } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';

const ColorPicker = tools.ColorPicker;
const language = services.language;
export class ShowContent extends PureComponent<any> {
    select = (showContent: any) => {
        const { onChange } = this.props;
        onChange(showContent);
    };

    render() {
        const { value, getFieldDecorator } = this.props;
        return (
            <Checkbox.Group style={{ width: '100%' }} value={value} onChange={this.select}>
                <Row className="show-content">
                    <Col span={24}>
                        <Checkbox value="buy">
                            {language.getText('components.PlatformGoods.buy')}
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox value="name">
                            {language.getText('components.PlatformGoods.name')}
                        </Checkbox>
                    </Col>
                    <Col className="show-content-item" span={24}>
                        <Checkbox value="price">
                            {language.getText('components.PlatformGoods.price')}
                        </Checkbox>
                        {getFieldDecorator('showContentColor.price')(<ColorPicker />)}
                    </Col>
                    <Col className="show-content-item" span={24}>
                        <Checkbox value="marketPrice">
                            {language.getText('components.Products.marketPrice')}
                        </Checkbox>
                        {getFieldDecorator('showContentColor.marketPrice')(<ColorPicker />)}
                    </Col>
                </Row>
            </Checkbox.Group>
        );
    }
}
