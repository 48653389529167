import React, { PureComponent } from 'react';
import { Icon, Row, Col, Input, Radio, Layout } from 'antd';
import { cloneDeep, get } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';
import { CardNavSubItems } from './sub-items';

const LinkType = tools.LinkType;

//卡片导航项
export class CardNavItem extends PureComponent<{
    sourceRoot: {
        image?: string;
    };
    items?: Array<any>;
    index: number;
    onChange?: (value: any) => void;
    item?: any;
    uploadAction: string;
    linkTypes: Array<{ id: string; name: string }>;
    selector?: any;
}> {
    state = {
        uploadLoading: false,
    };
    change = (item: any) => {
        const { items, onChange, index } = this.props;
        let newItems: any = cloneDeep(items);
        newItems.splice(index, 1, item);
        onChange && onChange(newItems);
    };

    onNameChange = (event: any) => {
        const { item } = this.props;
        let newItem = cloneDeep(item);
        let newValue = { ...newItem, name: event.target.value };
        this.change(newValue);
    };

    onTypeChange = (event: any) => {
        const { item } = this.props;
        let newItem = cloneDeep(item);
        let newValue = { ...newItem, type: event.target.value };
        this.change(newValue);
    };

    onChildrensChange = (subNavs: any) => {
        const { item } = this.props;
        let newItem = cloneDeep(item);
        let newValue = { ...newItem, subNavs: subNavs };
        this.change(newValue);
    };

    removeItem = () => {
        const { items, onChange, index } = this.props;
        let newValue: any = cloneDeep(items);
        newValue.splice(index, 1);
        onChange && onChange(newValue);
    };

    render() {
        const { item, linkTypes, selector, sourceRoot, uploadAction } = this.props;
        let type = get(item, 'type');
        if (!type) {
            type = 'open';
        }
        let subNavs = get(item, 'subNavs');
        if (!subNavs) {
            subNavs = [];
        }
        let showSubItems = !get(item, 'type') || get(item, 'type') === 'open';
        return (
            <Row className="entry-item">
                <Icon type="close" className="item-remove" title="移除" onClick={this.removeItem} />

                <Col className="entry-item-content" span={19}>
                    <Layout>
                        <div className="link-item-field">
                            <label className="field-name">{'导航名称'}： </label>
                            <Input
                                className="field-control"
                                value={get(item, 'name')}
                                onChange={this.onNameChange}
                                minLength={1}
                                maxLength={8}
                            />
                        </div>
                        <div className="link-item-field">
                            <label className="field-name">{'导航类型'}： </label>
                            <Radio.Group
                                value={type}
                                defaultValue={'open'}
                                onChange={this.onTypeChange}
                            >
                                <Radio value={'target'}>跳转链接</Radio>
                                <Radio value={'open'}>开启目录</Radio>
                            </Radio.Group>
                        </div>
                        {get(item, 'type') === 'target' && (
                            <LinkType
                                linkTypes={linkTypes}
                                linkTypeMode="cascader"
                                value={item}
                                selector={selector}
                                onChange={(item: any) => {
                                    this.change(item);
                                }}
                            />
                        )}

                        {showSubItems && (
                            <div className="card-nav-item-childrens">
                                <CardNavSubItems
                                    linkTypes={linkTypes}
                                    maxCount={5}
                                    onChange={this.onChildrensChange}
                                    value={subNavs}
                                    sourceRoot={sourceRoot}
                                    uploadAction={uploadAction}
                                    selector={selector}
                                />
                                <div>最多添加 5 个目录项，可拖拽调整顺序</div>
                            </div>
                        )}
                    </Layout>
                </Col>
            </Row>
        );
    }
}
