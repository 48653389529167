import * as React from 'react';
import { Popover } from 'antd';
import { ArrayMode } from '@comall-backend-builder/types';

export class GoodsPopoverMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(
        value: {
            productName: string;
            quantity: number;
            productCode: string;
        }[],
        displayInfo: any
    ) {
        const { replaceEmpty = '' } = displayInfo;
        return (
            <div>
                {value && value.length > 0 ? value[0].productName : replaceEmpty}{' '}
                {value && value.length > 1 && (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {value.map((item, index: number) => (
                                    <div
                                        key={`${item.productCode}-${index}`}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <span style={{ marginRight: '5px' }}>
                                            {item.productName}
                                        </span>
                                        <span> *{item.quantity}</span>
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">共{value.length}种商品</span>
                    </Popover>
                )}
            </div>
        );
    }
}
