import React, { Component } from 'react';
import { DatePicker, Form, Button, Card as AntCard, Spin } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { api, language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { RangePicker } = DatePicker;

const prefix = 'event-market-check';

const operation = [
    { name: 'jlyhzs', id: 'memberCount' },
    { name: 'jlzdds', id: 'orderCount' },
    { name: 'orderTotalAmount', id: 'orderAmount' },
    { name: 'dxtzzcs', id: 'smsNoticeCount' },
    { name: 'shzdds', id: 'afterSaleCount' },
];

const award = [
    { name: 'ffcgzzs', id: 'sendCount' },
    { name: 'ffsbzzs', id: 'sendFailCount' },
    { name: 'lqcgzzs', id: 'receiveCount' },
    { name: 'lqsbzzs', id: 'receiveFailCount' },
    { name: 'dlqzzs', id: 'waitReceiveCount' },
    { name: 'jlzhzzs', id: 'rewardRecoveryCount' },
];
class eventMarketPayCheckView extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: undefined,
            activeData: null,
            loading: false,
        };
    }
    componentDidMount() {
        this.initPage();
    }
    initPage = () => {
        const loading = false;
        this.setState(
            {
                loading: !loading,
            },
            () => {
                const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
                const apiPath = '/admin/pay_award_event_market/statistics';
                const { date } = this.state;
                const eventMarketId = this.props.params.id;
                const param: { startTime?: string; endTime?: string; eventMarketId: string } = {
                    eventMarketId,
                };
                if (date) {
                    param.startTime = date[0];
                    param.endTime = date[1];
                }
                api.get<any>(param, { apiRoot, apiPath }).then((res) => {
                    this.setState({ activeData: res, loading });
                });
            }
        );
    };
    handleDateChange = (val: RangePickerValue) => {
        if (!val.length) {
            this.resetDate();
        } else {
            const dateRange =
                val && val.length
                    ? [
                          moment(val[0] || undefined).format('YYYY-MM-DD HH:mm:ss'),
                          moment(val[1] || undefined).format('YYYY-MM-DD HH:mm:ss'),
                      ]
                    : undefined;
            dateRange &&
                this.setState({
                    date: dateRange,
                });
        }
    };
    resetDate = () => {
        this.setState({
            date: undefined,
        });
    };
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.initPage();
    };
    renderOperation = () => {
        const { activeData } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('yysj_1')} bordered={false}>
                {operation.map((item) => {
                    return (
                        <AntCard.Grid hoverable={false} className={`${prefix}__operation_item`}>
                            <div>{services.language.getText(item.name)}</div>
                            <div className={`${prefix}__tem_number`}>
                                {activeData ? activeData[item.id] : '-'}
                            </div>
                        </AntCard.Grid>
                    );
                })}
            </AntCard>
        );
    };
    renderAaward = () => {
        const { activeData } = this.state;
        return (
            <AntCard
                type="inner"
                title={services.language.getText('jlsj')}
                bordered={false}
                className={`${prefix}__award`}
            >
                {award.map((item) => {
                    return (
                        <AntCard.Grid hoverable={false} className={`${prefix}__award_item`}>
                            <div>{language.getText(item.name)}</div>
                            <div className={`${prefix}__tem_number`}>
                                {activeData ? activeData[item.id] : '-'}
                            </div>
                        </AntCard.Grid>
                    );
                })}
            </AntCard>
        );
    };
    render() {
        const ClassName = {
            box: `${prefix}__box`,
            fileterBox: `${prefix}__time_box`,
            wrap: `${prefix}__wrapper`,
        };
        const { date, loading } = this.state;
        let dateVal: any = [];
        if (date) {
            const start = moment(date[0], 'YYYY-MM-DD HH:mm:ss');
            const end = moment(date[1], 'YYYY-MM-DD HH:mm:ss');
            dateVal = [start, end];
        }
        return (
            <div className={ClassName.box}>
                <div className={ClassName.fileterBox}>
                    <Form layout="inline" onSubmit={this.handleSubmit}>
                        <Form.Item label={services.language.getText('selectTime')}>
                            <RangePicker
                                className="mrp-10"
                                placeholder={[
                                    services.language.getText('startTime'),
                                    services.language.getText('endTime'),
                                ]}
                                allowClear={false}
                                format="YYYY-MM-DD HH:mm:ss"
                                value={dateVal}
                                onChange={this.handleDateChange}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {services.language.getText('common.search')}
                            </Button>
                            <Button style={{ marginLeft: '10px' }} onClick={this.resetDate}>
                                {services.language.getText('common.reset')}
                            </Button>
                        </Form.Item>
                    </Form>
                    <Spin className={ClassName.wrap} spinning={loading}>
                        {this.renderOperation()}
                        {this.renderAaward()}
                    </Spin>
                </div>
            </div>
        );
    }
}

export const EventMarketPayCheckView = eventMarketPayCheckView;
