import React, { PureComponent } from 'react';
import { Upload, Button, message as AntMessage, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';

interface Props {
    fileList: Array<any>;
    /**
     * 上传文件改变时状态
     */
    onAddFile: (file: any) => void;
    /**
     * 点击移除文件时的回调
     */
    removeFile: (file: any) => void;
    params: {
        apiRoot: string;
        apiPath: string;
    };
}

/**
 * 上传附件（png、jpg、gif,word、excel、pdf、MP3、WAV格式的音频文件和MP4、AVI）
 */
export class UploadFileAll extends PureComponent<Props> {
    /**
     * 上传到服务端
     */
    onImport = (file: any) => {
        const { params, onAddFile } = this.props;
        let { apiRoot, apiPath } = params;
        const formData = new FormData();
        formData.append('files', file);
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot,
                    apiPath,
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then((res) => {
                onAddFile(res);
            })
            .catch((err) => {
                if (err) {
                    message.error(err.response?.body?.err_msg || language.getText('uoloadFail'));
                }
            });
    };

    render() {
        const { fileList: files } = this.props;
        const newFileList =
            files && files.length
                ? files.map((item: any) => {
                      item.uid = item.id;
                      item.status = 'done';
                      return item;
                  })
                : [];
        const uploadProps = {
            accept:
                '.xlsx,.xls,.doc,.docx,image/jpeg,image/jpg,image/png,image/gif,.pdf,.mp3,.wav,.avi,.mp4',
            multiple: false,
            onRemove: (file: any) => {
                const { removeFile } = this.props;
                removeFile(file);
            },
            beforeUpload: (file: any) => {
                if (file) {
                    this.onImport(file);
                } else {
                    AntMessage.error(language.getText('pleaseUploadFile'));
                }
                return false;
            },
            fileList: newFileList,
        };
        return (
            <div className="upload-file-all-modal">
                <div className="upload-content">
                    <Upload {...uploadProps}>
                        <Button key="submit" type="primary">
                            {language.getText('addAnnex')}
                        </Button>
                    </Upload>
                </div>
            </div>
        );
    }
}
