import React, { Component } from 'react';
import { InputNumber, Radio } from 'antd';

import './index.less';
import { services } from '@comall-backend-builder/core';
export enum RegistrationPriceType {
    NONE = 'NONE',
    PAY = 'PAY',
}
type RegistrationPriceValue = {
    /**
     * 价格类型
     */
    type?: string;
    /**
     * 积分
     */
    integral?: number;
    /**
     * 价格
     */
    price?: number;
    /**
     * 价格
     */
    gold?: number;
};

export interface RegistrationPriceProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: RegistrationPriceValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: RegistrationPriceValue;
    /**
     * 当前值
     */
    disabled?: boolean;
}
export class RegistrationPrice extends Component<RegistrationPriceProps> {
    onValueChange = (type: any, e: any) => {
        let { onChange, value, name } = this.props;

        if (type === 'price') {
            onChange({ ...value, price: e }, name);
        } else if (type === 'integral') {
            onChange({ ...value, integral: e }, name);
        } else if (type === 'type') {
            const val = e.target.value;
            onChange({ ...value, type: val }, name);
        }
    };
    render() {
        const { value, disabled } = this.props;

        return (
            <div className="registration-price">
                <Radio.Group
                    disabled={disabled}
                    value={value ? value.type : ''}
                    onChange={(e) => this.onValueChange('type', e)}
                >
                    <Radio
                        className="block-radio"
                        style={{ marginTop: '8px' }}
                        value={RegistrationPriceType.NONE}
                    >
                        <span>免费报名</span>
                    </Radio>
                    <Radio className="block-radio" value={RegistrationPriceType.PAY}>
                        <span>需支付&nbsp;</span>
                        <div className="input-container">
                            <InputNumber
                                disabled={disabled}
                                className="input-item"
                                onChange={(e) => this.onValueChange('integral', e)}
                                min={0}
                                max={999999}
                                step={1}
                                precision={0}
                                value={value ? value.integral : 0}
                            />{' '}
                            &nbsp;&nbsp;积分+&nbsp;&nbsp;{' '}
                            <InputNumber
                                disabled={disabled}
                                className="input-item"
                                onChange={(e) => this.onValueChange('price', e)}
                                min={0}
                                max={999999}
                                step={1}
                                precision={2}
                                value={value ? value.price : 0}
                            />{' '}
                            {services.language.getText('yuan')}
                        </div>
                    </Radio>
                </Radio.Group>
            </div>
        );
    }
}
