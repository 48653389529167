import React, { PureComponent } from 'react';

export class DesignMallActivityCalendar extends PureComponent<any> {
    render() {
        return (
            <div
                style={{ color: '#C5C5C5', fontSize: 20, lineHeight: '300px', textAlign: 'center' }}
            >
                固定样式暂不支持配置
            </div>
        );
    }
}
