import React, { PureComponent } from 'react';
import './index.less';
import { services } from '@comall-backend-builder/core';
/**
 * 可视化组件选择器
 */
export class PlatformHomeComponentSelector extends PureComponent<{
    onSelect: (value: any) => void;
}> {
    baseComponents = [
        {
            id: 'searchBar',
            name: '搜索框',
        },
        {
            id: 'categoryNavigate',
            name: '分组导航',
        },
        {
            id: 'imageAd',
            name: '图片广告',
        },
        {
            id: 'nav',
            name: '导航',
        },
        {
            id: 'showcase',
            name: '橱窗',
        },
        {
            id: 'platformGoods',
            name: '商品',
        },
        {
            id: 'title',
            name: '标题',
        },
        {
            id: 'text',
            name: '文本',
        },
        {
            id: 'divider',
            name: services.language.getText('fzx'),
        },
        {
            id: 'space',
            name: '辅助空白',
        },
        {
            id: 'noticeBar',
            name: '通知栏',
        },
    ];
    marketingComponents = [
        { id: 'flashProducts', name: '秒杀' },
        { id: 'coupon', name: '优惠券' },
    ];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        const base = this.baseComponents;
        const marketing = this.marketingComponents;

        return (
            <div className="platform-index-component-selector">
                <div className="group">
                    <div className="group-title">基础组件</div>
                    <div className="group-content">
                        {base.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">营销组件</div>
                    <div className="group-content">
                        {marketing.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
