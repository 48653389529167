import React, { PureComponent } from 'react';
import { get, isEqual } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { TimeRangePickerPlus } from '../../../../components';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { InputNumber, Radio, Select } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface Value {
    /**
     * 是否开启预订服务
     */
    enable: boolean;
    /**
     * 可预订人群（不限制/限制）
     */
    limitCardLevels: boolean;
    /**
     * 可预订人群-限制会员等级
     */
    cardLevels?: Array<string>;
    /**
     * 可预订日期（不限制/限制）
     */
    limitReservationDay: boolean;
    /**
     * 需提前多少天预订
     */
    advanceReservationDays?: number;
    /**
     * 可定多少天内的订单
     */
    limitReservationDays?: number;
    /**
     * 可预订位置
     */
    spaceTypes: Array<SpaceType>;
    /**
     * 营业时间
     */
    times: Array<Time>;
}

interface Time {
    type: 'LUNCH' | 'DINNER';
    startTime: string;
    endTime: string;
}

interface State {
    memberLevels: Array<{
        id: string;
        name: string;
    }>;
}

interface Props {
    row: any;
    onChange: (value: Value) => void;
    value: Value;
}

const enum SpaceType {
    HALL = 'HALL',
    BOX = 'BOX',
}

const defalutValue: Value = {
    enable: false,
    limitCardLevels: false,
    cardLevels: undefined,
    limitReservationDay: false,
    advanceReservationDays: undefined,
    limitReservationDays: undefined,
    spaceTypes: [SpaceType.HALL, SpaceType.BOX],
    times: [
        {
            type: 'LUNCH',
            startTime: '',
            endTime: '',
        },
        {
            type: 'DINNER',
            startTime: '',
            endTime: '',
        },
    ],
};
export class RestaurantReservationSetting extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            memberLevels: [],
        };
    }

    onChangeDefalutValue = () => {
        const { onChange } = this.props;
        onChange(defalutValue);
    };
    componentDidMount() {
        const { value } = this.props;
        if (!value) {
            this.onChangeDefalutValue();
        }
        //获取会员等级
        setTimeout(() => {
            this.getMemberLevels();
        }, 1000);
    }
    getMemberLevels = () => {
        const { row } = this.props;
        if (!row.baseInfo || !row.baseInfo.subsite) {
            return;
        }
        const subsiteId = row.baseInfo.subsite.id;
        api.get(
            {
                subsiteId: subsiteId,
                page: 1,
                perPage: 100,
                status: 'ENABLE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/store_card_levels',
            }
        ).then((res: any) => {
            const data = res.result;
            this.setState({
                memberLevels: data,
            });
        });
    };

    componentWillReceiveProps(nextProps: any) {
        const currentIndustryId = get(this.props, 'row.manageSetting.industryId');
        const nextIndustryId = get(nextProps, 'row.manageSetting.industryId');
        if (nextIndustryId && !isEqual(currentIndustryId, nextIndustryId)) {
            this.onChangeDefalutValue();
        }
    }

    onChangeValue = (key: string, val: any) => {
        const { value, onChange } = this.props;
        if (value) {
            onChange({
                ...value,
                [key]: val,
            });
        }
    };

    renderSpaceTypes = () => {
        const { value } = this.props;
        const spaceTypes = [
            {
                id: SpaceType.HALL,
                name: language.getText('hall'),
            },
            {
                id: SpaceType.BOX,
                name: language.getText('privateRoom'),
            },
        ];
        return (
            <div className="item">
                <span className="item-title">
                    <span className="item-red-tip">*</span>
                    {language.getText('bookableLocation')}
                </span>
                <CheckboxGroup
                    className="item-group"
                    name="spaceTypes"
                    onChange={(value) => {
                        this.onChangeValue('spaceTypes', value);
                    }}
                    options={spaceTypes}
                    value={value.spaceTypes}
                />
            </div>
        );
    };

    handleTimeChange = (time: { start: string; end: string }, index: number) => {
        const { value, onChange } = this.props;
        value.times = value.times.map((item: Time, iindex: number) => {
            if (iindex === index) {
                return {
                    ...item,
                    startTime: time.start,
                    endTime: time.end,
                };
            }
            return item;
        });
        onChange(value);
    };

    renderTimes = () => {
        const { value } = this.props;
        if (!value.times || value.times.length === 0) {
            return null;
        }
        return (
            <div className="item">
                <div className="item-title">
                    <span className="item-red-tip">*</span>
                    {language.getText('businessTime')}：
                </div>
                <div>
                    <div className="item-tip">{language.getText('controlUserChooseTime')}</div>
                    <div>
                        {value.times.map((time: Time, index: number) => {
                            const nameEnum = {
                                LUNCH: language.getText('lunch'),
                                DINNER: language.getText('dinner'),
                            };
                            return (
                                <div className="item-time">
                                    <span>{nameEnum[time.type]} ：</span>
                                    <TimeRangePickerPlus
                                        format="HH:mm"
                                        placeholder={[
                                            language.getText('selectPlease'),
                                            language.getText('selectPlease'),
                                        ]}
                                        name="start"
                                        value={{
                                            start: time.startTime,
                                            end: time.endTime,
                                        }}
                                        style={{ width: 80 }}
                                        allowClear={false}
                                        onChange={(value) => this.handleTimeChange(value, index)}
                                        minuteStep={30}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    renderCardLevels = () => {
        const { memberLevels } = this.state;
        const { value } = this.props;
        return (
            <div className="item">
                <span className="item-title">可预订人群：</span>
                <div>
                    <Radio.Group
                        className="item-group"
                        value={value.limitCardLevels}
                        onChange={(e: any) => {
                            this.onChangeValue('limitCardLevels', e.target.value);
                        }}
                    >
                        <div>
                            <Radio value={false}>不限制</Radio>
                        </div>
                        <div>
                            <Radio value={true}>
                                限制可预订的会员等级
                                <Select
                                    mode="tags"
                                    value={value.cardLevels}
                                    style={{ marginLeft: '20px', width: '450px' }}
                                    onChange={(value: string[]) => {
                                        this.onChangeValue('cardLevels', value);
                                    }}
                                >
                                    <Select.Option value={'0'}>请选择</Select.Option>
                                    {memberLevels &&
                                        memberLevels.map((option: any) => (
                                            <Select.Option
                                                key={option.cardLevelId + ''}
                                                value={option.cardLevelId + ''}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Radio>
                        </div>
                    </Radio.Group>
                </div>
            </div>
        );
    };

    renderLimitDay = () => {
        const { value } = this.props;
        return (
            <div className="item">
                <span className="item-title">可预订日期：</span>
                <div>
                    <Radio.Group
                        className="item-group"
                        value={value.limitReservationDay}
                        onChange={(e: any) => {
                            this.onChangeValue('limitReservationDay', e.target.value);
                        }}
                    >
                        <div>
                            <Radio value={false}>不限制</Radio>
                        </div>
                        <div>
                            <Radio value={true}>
                                <span>需提前&nbsp;&nbsp;</span>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={value.advanceReservationDays}
                                    min={0}
                                    max={999999}
                                    precision={0}
                                    onChange={(value: any) =>
                                        this.onChangeValue('advanceReservationDays', value)
                                    }
                                ></InputNumber>
                                <span>&nbsp;&nbsp;天进行预订，可订&nbsp;&nbsp;</span>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={value.limitReservationDays}
                                    min={0}
                                    max={999999}
                                    precision={0}
                                    onChange={(value: any) =>
                                        this.onChangeValue('limitReservationDays', value)
                                    }
                                ></InputNumber>
                                <span>&nbsp;&nbsp;天内的订单</span>
                            </Radio>
                        </div>
                    </Radio.Group>
                    <div className="item-tip item-tip-line">
                        如配置提前2天预订，则用户在10月1日只能预订10月3日的订单
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div className="restaurant-reservation-setting">
                <div className="enable-item">
                    <Radio.Group
                        className="item-group"
                        value={value.enable}
                        onChange={(e: any) => {
                            this.onChangeValue('enable', e.target.value);
                        }}
                    >
                        <Radio value={true}>开启</Radio>
                        <Radio value={false}>关闭</Radio>
                    </Radio.Group>
                    <div className="item-tip item-tip-line">开启预订服务，需设置预订规则</div>
                </div>
                {value.enable && (
                    <div className="show-item">
                        {this.renderCardLevels()}
                        {this.renderLimitDay()}
                        {this.renderSpaceTypes()}
                        {this.renderTimes()}
                    </div>
                )}
            </div>
        );
    }
}
