import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';
import { ArrayChannelCheckbox } from './array-channel-checkbox';

export enum ChannelType {
    Wechat = 'WECHAT',
    Alipay = 'ALIPAY',
}

export class ArrayChannelCheckboxMode extends modes.ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: ChannelType[], displayInfo: any) {
        const channelOptions = [
            {
                id: ChannelType.Wechat,
                name: services.language.getText('wxorapp'),
            },
            {
                id: ChannelType.Alipay,
                name: services.language.getText('zfbxcx'),
            },
        ];
        const textList =
            value && value.length > 0
                ? value.map((i) => {
                      return channelOptions.find((item) => item.id === i)?.name || '';
                  })
                : [];
        return (
            <div>
                {textList.map((name: string) => (
                    <div key={name}>{name}</div>
                ))}
            </div>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ArrayChannelCheckbox {...displayInfo} />;
    }
}
