import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';

let SELECTED_ROW: any = [];

const getConfig = (componentName: string) => {
    return {
        component: 'Card',
        entity: 'CouponSaleActivitySelectedEntity',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    rowSelection: {
                        type: 'radio',
                        onChange: () => {},
                        onSelect: function() {
                            SELECTED_ROW = arguments[2];
                        },
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                statusConfig: {
                                    NOTSTARTED: {
                                        text: '未开始',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '进行中',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '已结束',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicComponent(contentConfig: any, componentName: any) {
    const config = defaultsDeep(contentConfig, getConfig(componentName));
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
        onSelectAll: function() {
            SELECTED_ROW = arguments[2];
        },
    });
    return ComponentFactory(componentName, config);
}

function generateDynamicComponentName() {
    return (
        'DesignCouponSaleActivitySelectedSelector-' +
        parseInt(Math.random() * 1e8 + '').toString(16)
    );
}

/**
 * 选择优惠券活动
 */
export class SelectCouponSaleActivitySelected extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    onChange: (data: any) => void;
    onCancel: () => void;
}> {
    componentName = '';
    renderComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { contentConfig } = props;
        this.componentName = generateDynamicComponentName();
        const dynamicComponent = initDynamicComponent(contentConfig, this.componentName);
        this.renderComponent = createElement(dynamicComponent);
        this.dispatch = builder.getStore().dispatch;
    }

    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error('请选择售卖优惠券活动');
            return;
        }

        const { onChange } = this.props;

        onChange(SELECTED_ROW);
        SELECTED_ROW = [];
        this.dispatch(actions.tableRowSelectionChangeAction(this.componentName + '-table', []));
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 800,
                visible: showModal,
                title: '请选择售卖优惠券活动',
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderComponent}</Modal>;
    }
}
