import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ButtonProps } from 'antd/lib/button';
import { ForwardDataCenterModal } from '../../services';

import 'antd/dist/antd.css';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;
const { Dragger } = Upload;

interface MerchantSelfPageImportButtonProps {
    /**
     * 按钮文字
     */
    triggerText?: ButtonProps['children'];
    /**
     * 上传提示文案
     */
    uploadText?: string;
    /**
     * 支持上传格式
     */
    acceptList: string[];
    /**
     * 隐藏下载模板
     */
    hideDownloadTemplate?: boolean;
    /**
     * 示例模板
     */
    controlDownloadTemplate?:
        | string
        | {
              apiRoot: string;
              apiPath: string;
          };
    /**
     * 上传配置
     */
    controlUpload: {
        apiRoot?: string;
        apiPath: string;
        fileName?: string;
    };
    /**
     * 导入规则
     * default 如果是true 则读取默认值
     */
    tips?: { text: string; highlight?: boolean; default?: boolean }[];
    /**
     * 显示导入后弹窗
     */
    showForwardDataCenterModal?: boolean;
}

/**
 * 专柜导入组件
 * 与通用导入不同之处在于只需要处理上传
 */
export class MerchantSelfPageImportButton extends PureComponent<
    MerchantSelfPageImportButtonProps,
    { fileList: any; showModal: boolean; statusMsg: string }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
    };

    static defaultProps = {
        triggerText: 'import',
        uploadText: 'selectFile',
        acceptList: ['.xlsx', '.xls', '.csv'],
        hideDownloadTemplate: false,
        tips: [
            {
                text: 'importRules',
                default: true,
            },
            {
                text: 'merchantBigCodeIntro1',
                default: true,
                // text: '1.在文件中填写需要导入的信息（样式见附件），然后上传该文件',
            },
            {
                text: 'merchantBigCodeNote',
                default: true,
                // text: '注：导入的内容格式正确，不含空格等其他特殊字符，否则会导入失败',
                highlight: true,
            },
            {
                text: 'merchantBigCodeIntro2',
                default: true,
                // text:
                //     '2.当前仅支持xlxs、xls、csv格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office 2010及以上版本，否则可能出现乱码',
            },
            {
                text: 'merchantBigCodeIntro3',
                default: true,
                // text: '3.请严格参考模版内的信息进行填写',
            },
        ],
    };

    toggleModal = () => {
        const { showModal } = this.state;
        if (showModal) {
            this.setState({
                fileList: [],
            });
        }
        this.setState({ showModal: !showModal });
    };

    onImport = (file: any) => {
        const { controlUpload, showForwardDataCenterModal } = this.props;
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        api.upload(
            { files: file },
            {
                apiRoot: controlUpload.apiRoot || `${ENV.AUTH_API_ROOT}/FILE`,
                apiPath: controlUpload.apiPath,
                fileName: controlUpload.fileName || 'files',
                progressCallBack: () => {},
            }
        )
            .then((res) => {
                if (showForwardDataCenterModal) {
                    ForwardDataCenterModal();
                }
                this.toggleModal();
            })
            .catch((err) => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList.length > 0) {
            const [file] = fileList;
            this.onImport(file);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    getUploadProps = () => {
        const { fileList } = this.state;
        const { acceptList } = this.props;

        return {
            accept: acceptList.join(','),
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file: any) => {
                this.setState((state) => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };
    };

    renderExportButton = () => {
        const { controlDownloadTemplate } = this.props;
        const props = {
            type: 'link',
            text: services.language.getText('clickDownloadTemplate'),
            showErrorMessage: true,
            source: controlDownloadTemplate,
        } as any;
        return <ExportButton {...props} />;
    };

    renderDragger = () => {
        const { uploadText, acceptList } = this.props;
        const uploadProps = this.getUploadProps();
        return (
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">{uploadText && language.getText(uploadText)}</p>
                <p className="ant-upload-hint">
                    {language.getText('zckzm')}：{acceptList.join('、')}
                </p>
            </Dragger>
        );
    };

    renderTips = () => {
        const { tips } = this.props;
        if (tips && tips.length > 0) {
            return (
                <div className="import-button-tips">
                    {tips.map((item) => {
                        return (
                            <p
                                key={item.text}
                                className={`import-button-tips-text${item.highlight ? '-red' : ''}`}
                            >
                                {item.default ? language.getText(item.text) : item.text}
                            </p>
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    render() {
        const { triggerText, hideDownloadTemplate } = this.props;
        const { showModal, statusMsg } = this.state;
        return (
            <div>
                <Button
                    type="link"
                    style={{ textAlign: 'center', color: 'rgb(0 0 0 / 65%)' }}
                    onClick={this.toggleModal}
                >
                    {triggerText === 'import' ? language.getText('triggerText') : triggerText}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={language.getText('batchImport')}
                    footer={[
                        <span className="import-button-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    {this.renderDragger()}
                    <div>
                        {hideDownloadTemplate ? null : (
                            <div
                                style={{
                                    height: 32,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {this.renderExportButton()}
                            </div>
                        )}
                        {this.renderTips()}
                    </div>
                </Modal>
            </div>
        );
    }
}
