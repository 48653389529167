import React, { Component } from 'react';
import { Select, Radio, Button } from 'antd';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { IntegerInputTitleTip } from '../../../../components';

import './carpark-deduction-rules.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface DeductionRule {
    amount?: number;
    deductionValue?: number;
}

interface ConsumeDeduction {
    memberLevelId?: string;
    deductionRules: DeductionRule[];
}

interface CarparkDeductionRulesValue {
    limitMemberLevel: boolean;
    consumeDeductions: ConsumeDeduction[];
}

interface CardLevel {
    id: string;
    name: string;
}

interface CarparkDeductionRulesProps {
    onChange: (value: CarparkDeductionRulesValue) => void;
    value: CarparkDeductionRulesValue;
    disabled: boolean;
    row: any;
    options: CardLevel[];
}

export class CarparkDeductionRules extends Component<CarparkDeductionRulesProps, any> {
    componentDidMount() {
        const { value, onChange, row } = this.props;
        if (!value) {
            const defaultValue = {
                limitMemberLevel: false,
                consumeDeductions: [{ deductionRules: [{}] }],
            };
            onChange(defaultValue);
        }
        const deductionType = get(row, 'benefitInfo.deductionType');
        this.setState({ deductionType });
    }

    componentWillReceiveProps(nextProps: CarparkDeductionRulesProps) {
        const deductionType = get(nextProps, 'row.benefitInfo.deductionType');
        if (this.state && deductionType !== this.state.deductionType) {
            this.setState({ deductionType });
        }
        const options = get(nextProps, 'options');
        if (!isEqual(options, get(this.props, 'options'))) {
            this.onCardLevelsChange(options);
        }
    }

    /**
     * 会员卡变更时处理数据
     * @param newOptions 新的会员卡列表
     */
    onCardLevelsChange = (newOptions: CardLevel[]) => {
        const { value, onChange } = this.props;
        // const consumeDeductions = [...value.consumeDeductions].map((c) => {
        //     return {
        //         ...c,
        //         memberLevelId: !!newOptions.find((o) => o.id === c.memberLevelId)
        //             ? c.memberLevelId
        //             : undefined,
        //     };
        // });
        const consumeDeductions = [...value.consumeDeductions].filter((c) => {
            return c.memberLevelId ? !!newOptions.find((o) => o.id === c.memberLevelId) : true;
        });
        if (consumeDeductions.length === 0) {
            consumeDeductions.push({
                deductionRules: [{}],
            });
        }
        const newValue = Object.assign(value, { consumeDeductions });
        onChange && onChange(newValue);
    };

    /**
     * 切换是否区分等级
     */
    onRadioChange = (e: any) => {
        const { value, onChange } = this.props;
        const limitMemberLevel = e.target.value;
        const consumeDeductions = !limitMemberLevel
            ? value.consumeDeductions.slice(0, 1).map((c) => {
                  return { ...c, memberLevelId: undefined };
              })
            : value.consumeDeductions;
        const newValue = Object.assign(value, { limitMemberLevel, consumeDeductions });
        onChange && onChange(newValue);
    };

    /**
     * 添加一组会员卡等级规则
     */
    onAddCardLevel = () => {
        const { value, onChange } = this.props;
        const newValue = Object.assign(
            value,
            value.consumeDeductions.push({ deductionRules: [{}] })
        );
        onChange && onChange(newValue);
    };

    /**
     * 删除一组会员卡等级规则
     * @param index 该规则下标
     */
    onRemoveCardLevel = (index: number) => {
        const { value, onChange } = this.props;
        const newConsumeDeductions =
            value.consumeDeductions.length > 1
                ? value.consumeDeductions.filter((v, i) => i !== index)
                : [{ deductionRules: [{}] }];
        const newValue = Object.assign(value, { consumeDeductions: newConsumeDeductions });
        onChange && onChange(newValue);
    };

    /**
     * 添加一条抵扣规则
     * @param index 该抵扣规则所属会员卡等级规则的下标
     */
    onAddRule = (index: number) => {
        const { value, onChange } = this.props;
        const newConsumeDeductions = [...value.consumeDeductions];
        newConsumeDeductions[index].deductionRules.push({});
        const newValue = Object.assign(value, { consumeDeductions: newConsumeDeductions });
        onChange && onChange(newValue);
    };

    /**
     * 删除一条抵扣规则
     * @param index 该抵扣规则所属会员卡等级规则的下标
     * @param ruleIndex 该抵扣规则在对应会员卡等级规则下的下标
     */
    onRemoveRule = (index: number, ruleIndex: number) => {
        const { value, onChange } = this.props;
        const newConsumeDeductions = [...value.consumeDeductions];
        newConsumeDeductions[ruleIndex].deductionRules = newConsumeDeductions[
            ruleIndex
        ].deductionRules.filter((v, i) => i !== index);
        const newValue = Object.assign(value, { consumeDeductions: newConsumeDeductions });
        onChange && onChange(newValue);
    };

    /**
     * 输入框输入内容
     * @param number 数字输入框的值
     * @param index 该抵扣规则所属会员卡等级规则的下标
     * @param ruleIndex 该抵扣规则在对应会员卡等级规则下的下标
     * @param field 修改的内容
     */
    onRuleValueChange = (
        number: number | undefined | null,
        index: number,
        ruleIndex: number,
        field: 'amount' | 'deductionValue'
    ) => {
        if (typeof number !== 'number') return;
        const { value, onChange } = this.props;
        const newConsumeDeductions = [...value.consumeDeductions];
        newConsumeDeductions[ruleIndex].deductionRules[index][field] = number;
        const newValue = Object.assign(value, { consumeDeductions: newConsumeDeductions });
        onChange && onChange(newValue);
    };

    onSelectCardLevel = (option: any, index: number) => {
        const { value, onChange } = this.props;
        const newConsumeDeductions = [...value.consumeDeductions];
        newConsumeDeductions[index].memberLevelId = option;
        const newValue = Object.assign(value, { consumeDeductions: newConsumeDeductions });
        onChange && onChange(newValue);
    };

    /**
     * 渲染抵扣规则
     * @param ruleValue 抵扣规则
     * @param index 该抵扣规则所属会员卡等级规则的下标
     * @param ruleIndex 该抵扣规则在对应会员卡等级规则下的下标
     * @param deductionType 抵扣类型
     */
    renderDeductionRule = (
        deductionRule: DeductionRule,
        index: number,
        ruleIndex: number,
        deductionType: string
    ) => {
        const isAmount = deductionType === 'SPECIFY_VALUE';
        const deductionTypeText = isAmount ? language.getText('ytcje') : language.getText('xstcsz');
        return (
            <div className="rule-values">
                <span>
                    {language.getText('rule')}
                    {index + 1}：{language.getText('xfm')}
                </span>
                <IntegerInputTitleTip
                    className="number-input"
                    addonafter={language.getText('yuan')}
                    value={deductionRule.amount}
                    onChange={(value, name) =>
                        this.onRuleValueChange(value, index, ruleIndex, 'amount')
                    }
                    allowFloat
                    width={100}
                    rules={{
                        // type: 'number',
                        precision: 2,
                        max: 9999999,
                        min: 0,
                    }}
                />
                <span>{language.getText('dk')}</span>
                <IntegerInputTitleTip
                    className="number-input"
                    addonafter={deductionTypeText}
                    value={deductionRule.deductionValue}
                    onChange={(value, name) =>
                        this.onRuleValueChange(value, index, ruleIndex, 'deductionValue')
                    }
                    allowFloat={isAmount}
                    width={100}
                    rules={{
                        // type: 'number',
                        precision: isAmount ? 2 : 0,
                        max: isAmount ? 9999999 : 24,
                        min: isAmount ? 0.01 : 1,
                    }}
                />
                {!!index && (
                    <Button
                        type="link"
                        className="remove-rule-button"
                        onClick={() => this.onRemoveRule(index, ruleIndex)}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                )}
            </div>
        );
    };

    /**
     * 渲染会员卡等级规则
     * @param consumeDeductions 会员卡等级规则
     * @param index 该会员卡等级规则下标
     * @param limitMemberLevel 是否区分会员卡等级
     * @param options 会员卡等级列表
     * @param deductionType 抵扣类型
     */
    renderConsumeDeductions(
        consumeDeductions: ConsumeDeduction,
        index: number,
        limitMemberLevel: boolean,
        options: CardLevel[],
        deductionType: string
    ) {
        const { options: allOptions } = this.props;
        const selected = allOptions.find((o) => o.id === consumeDeductions.memberLevelId);
        return (
            <div className="carpark-deduction-rule">
                {!!limitMemberLevel && (
                    <div className="member-level">
                        <div>
                            <span>{language.getText('cardLevel')}：</span>
                            <Select
                                value={consumeDeductions.memberLevelId}
                                onSelect={(value: any) => this.onSelectCardLevel(value, index)}
                                placeholder={services.language.getText('selectPlease')}
                                style={{ width: 200 }}
                            >
                                {!!selected && (
                                    <Select.Option value={selected.id}>
                                        {selected.name}
                                    </Select.Option>
                                )}
                                {!!options &&
                                    options.map((o) => (
                                        <Select.Option value={o.id}>{o.name}</Select.Option>
                                    ))}
                            </Select>
                        </div>
                        <Button
                            type="default"
                            className="remove-rule-button"
                            onClick={() => this.onRemoveCardLevel(index)}
                        >
                            {services.language.getText('common.delete')}
                        </Button>
                    </div>
                )}
                {consumeDeductions.deductionRules.map((v, i) =>
                    this.renderDeductionRule(v, i, index, deductionType)
                )}
                <Button type="link" onClick={() => this.onAddRule(index)}>
                    {language.getText('addRule')}
                </Button>
            </div>
        );
    }

    render() {
        const { value, row, options } = this.props;
        if (!value) return null;
        const deductionType = get(row, 'benefitInfo.deductionType');
        const subsiteId = get(row, 'subsiteId');
        const { limitMemberLevel, consumeDeductions } = value;
        const cardLevels = !!subsiteId
            ? options.filter((o) => !consumeDeductions.find((c) => c.memberLevelId === o.id))
            : [];

        return (
            <div className="carpark-deduction-rules">
                <Radio.Group onChange={this.onRadioChange} value={value.limitMemberLevel}>
                    <Radio value={false}>{language.getText('bqfdj')}</Radio>
                    <Radio value={true}>{language.getText('qfdj')}</Radio>
                </Radio.Group>
                {consumeDeductions.map((r, i) =>
                    this.renderConsumeDeductions(r, i, limitMemberLevel, cardLevels, deductionType)
                )}
                {!!limitMemberLevel && (
                    <Button type="default" onClick={() => this.onAddCardLevel()}>
                        {language.getText('tjhydj')}
                    </Button>
                )}
                <div className="carpark-deduction-rules-tips">
                    {services.language.getText('xfm1000dk2ytcf')}
                </div>
            </div>
        );
    }
}
