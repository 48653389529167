import React, { PureComponent } from 'react';
import { Radio, Input, Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import './index.less';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const UploadPicture = tools.UploadPicture;
const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
class customRecruitDistributorAdv extends PureComponent<any, any> {
    render() {
        const { form, sourceRoot, uploadAction, value } = this.props;
        const { getFieldDecorator } = form;
        const { electronicProtocol } = value;
        const electronicProtocolShow = electronicProtocol ? electronicProtocol.show : 'show';

        return (
            <div className="recruit-leader-adv">
                <Form {...FormItemLayout}>
                    <FormItem label={services.language.getText('bgPic')} required>
                        <div className="recruit-leader-adv-item">
                            <div className="image-wrap">
                                {getFieldDecorator('title.image')(
                                    <UploadPicture
                                        className="image-uploader"
                                        sourceRoot={sourceRoot}
                                        uploadAction={uploadAction}
                                    />
                                )}
                            </div>
                            <div className="notice">
                                <p className="notice-text">目前仅支持上传一张图片</p>
                                <p className="notice-text">尺寸：宽750*高不限</p>
                                <p className="notice-text">格式：jpg、jpeg</p>
                                <p className="notice-text">大小：小于1M</p>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem label={'电子协议'} required>
                        <div className="recruit-leader-adv-item">
                            <div className="image-wrap">
                                {getFieldDecorator(
                                    'electronicProtocol.show',
                                    {}
                                )(
                                    <RadioGroup className="fill-style">
                                        <Radio value={'show'}>展示</Radio>
                                        <Radio value={'hidden'}>不展示</Radio>
                                    </RadioGroup>
                                )}
                            </div>
                        </div>
                    </FormItem>
                    {electronicProtocolShow !== 'hidden' && (
                        <FormItem label={'协议图片'} required>
                            <div className="recruit-leader-adv-item">
                                <div className="image-wrap">
                                    {getFieldDecorator('electronicProtocol.image')(
                                        <UploadPicture
                                            className="image-uploader"
                                            sourceRoot={sourceRoot}
                                            uploadAction={uploadAction}
                                        />
                                    )}
                                </div>

                                <div className="notice">
                                    <p className="notice-text">图片尺寸建议为750*高不限</p>
                                </div>
                            </div>
                        </FormItem>
                    )}
                    {electronicProtocolShow !== 'hidden' && (
                        <FormItem label={'协议名称'} required>
                            <div className="recruit-leader-adv-item">
                                <div className="image-wrap">
                                    {getFieldDecorator(
                                        'electronicProtocol.title',
                                        {}
                                    )(<Input maxLength={20} className="show-style"></Input>)}
                                </div>
                            </div>
                        </FormItem>
                    )}
                </Form>
            </div>
        );
    }
}

export const CustomRecruitDistributorAdv = tools.ConfigFormDecorator(customRecruitDistributorAdv);
