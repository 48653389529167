import React, { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { AuditGuideUserModal } from '../audit-modal';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export interface AuditGuideUserFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

class AuditGuideUserFormView extends PureComponent<AuditGuideUserFormProps> {
    state = {
        visible: false,
    };
    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    addMerchantUser = async () => {
        const { data } = this.props;
        const organization = get(data, 'authInfo.organizationId');
        if (organization.organizationType !== 'merchant') {
            AntMessage.warning(language.getText('fzjzzjgzh'));
            return;
        }
        const mobile = get(data, 'baseInfo.mobile');
        const name = get(data, 'baseInfo.name');
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = '/admin/merchant_users/by_mobile';
        const merchantUser = await services.api.get<any>(
            { mobile, stateless: true },
            { apiRoot, apiPath }
        );
        if (!merchantUser) {
            services.api.post(
                {
                    name,
                    mobile,
                    subSiteId: organization.subsiteId,
                    merchantId: organization.originId,
                    status: true,
                },
                { apiRoot, apiPath: '/admin/merchant_users' }
            );
        } else {
            merchantUser.merchantId = organization.originId;
            merchantUser.subSiteId = organization.subsiteId;
            merchantUser.name = name;
            merchantUser.status = merchantUser.status === 'ENABLED';
            services.api.put(merchantUser, {
                apiRoot,
                apiPath: `/admin/merchant_users/${merchantUser.id}`,
            });
        }
    };

    onAudit = async (val: any) => {
        const id = this.props.params.id;
        try {
            services.loading.open();
            const params = { auditRemark: val.reason || '' };
            if (val.result) {
                await services.api.put(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/shopping_guide_application_records/${id}/approved`,
                });
                if (val.addMerchantUser) {
                    this.addMerchantUser();
                }
            } else {
                if (!val.reason) {
                    AntMessage.error(services.language.getText('qtxshyy'));
                    services.loading.close();

                    return;
                }
                await services.api.put(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/shopping_guide_application_records/${id}/rejected`,
                });
            }
            services.loading.close();

            AntMessage.success(services.language.getText('shcg'));
            setTimeout(() => {
                services.behaviorHandle({ route: 'goBack' });
            }, 1500);
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    onSaveAndAudit = (event: any) => {
        event.stopPropagation();
        const { getForm } = this.props;

        const form = getForm();
        form.validateFields().then(async (entityFields: any) => {
            const res = transformAndValidate(entityFields);

            if (res.data && res.data) {
                try {
                    services.loading.open();
                    await saveData(res.data, this.props);
                    services.loading.close();

                    AntMessage.success(services.language.getText('common.saveSuccess'));
                    this.setState({ visible: true });
                } catch (error) {
                    services.errorHandle(error as any);
                }
            }
        });
    };

    onSubmit = (event: any) => {
        event.stopPropagation();
        const { getForm } = this.props;

        const form = getForm();
        form.validateFields().then(async (entityFields: any) => {
            const res = transformAndValidate(entityFields);

            if (res.data && res.data) {
                try {
                    services.loading.open();
                    await saveData(res.data, this.props);
                    services.loading.close();

                    AntMessage.success(services.language.getText('common.saveSuccess'));
                    setTimeout(() => {
                        services.behaviorHandle({ route: 'goBack' });
                    }, 1500);
                } catch (error) {
                    services.errorHandle(error as any);
                }
            }
        });
    };
    close = () => {
        this.setState({ visible: false });
    };
    render() {
        const { entity, wrappedComponentRef } = this.props;
        const { visible } = this.state;
        let fields = [
            {
                property: 'baseInfo.mobile',
            },
            {
                property: 'baseInfo.loginName',
            },
            {
                property: 'baseInfo.name',
            },
            {
                property: 'baseInfo.code',
            },
            {
                property: 'authInfo.organizationId',
            },
            {
                property: 'authInfo.privilegeInfos',
            },
            {
                property: 'authInfo.roleInfos',
            },
            {
                property: 'authInfo.remark',
            },

            // 下一期做 界面已经写好了
            // {
            //     property: 'authInfo.isCreateMerchant',
            // },
        ];

        const formProps = {
            entity,
            componentId: 'AuditGuideUserFormContainer',
            wrappedComponentRef,
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        text: language.getText('common.save'),
                        type: 'primary',
                        style: { marginLeft: 10 },
                        onClick: this.onSubmit,
                    },
                    {
                        htmlType: 'button',
                        text: language.getText('bcbsh'),
                        type: 'primary',
                        style: { marginLeft: 10 },
                        onClick: this.onSaveAndAudit,
                    },
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                return true;
            },
        };
        return (
            <>
                {createElement(ComponentsManager.get('ModifyFormPlus'), formProps)}
                {visible && (
                    <AuditGuideUserModal
                        onCancel={this.close}
                        onAudit={this.onAudit}
                        visible={visible}
                    ></AuditGuideUserModal>
                )}
            </>
        );
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const data =
        get(_dispatch, 'components.["DataFormPlus-AuditGuideUserFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(
                    actions.unmountComponentAction('DataFormPlus-AuditGuideUserFormContainer')
                );
        },
    };
}

function transformAndValidate(params: any) {
    const {
        baseInfo: { code, loginName, mobile, name },
        authInfo: { organizationId, privilegeInfos, remark, roleInfos },
    } = params;
    if (!name) {
        AntMessage.error(language.getText('qsryhxm'));
        return { result: false };
    }
    if (!organizationId || !organizationId.organizationId) {
        AntMessage.error(language.getText('qxzsszzjg'));
        return { result: false };
    }
    if (!privilegeInfos || !privilegeInfos.length) {
        AntMessage.error(language.getText('selectCorreSubsiteAndMerchant'));
        return { result: false };
    }
    if (!roleInfos || !roleInfos.length) {
        AntMessage.error(language.getText('qxzgljs'));
        return { result: false };
    }
    return {
        result: true,
        data: {
            remark,
            code,
            loginName,
            mobile,
            name,
            organizationId: organizationId.organizationId,
            organizationName:
                (organizationId.parentNamePath || '') +
                organizationId.organizationName
                    .replace(language.getText('merchant') + '-', '')
                    .replace(language.getText('subsites') + '-', ''),
            privilegeInfos: privilegeInfos.map((i: any) => ({
                organizationId: i.id,
                organizationName: i.name,
            })),
            roleInfos: roleInfos.map((i: any) => ({
                roleId: i.id,
                roleName: i.name,
            })),
        },
    };
}

async function saveData(params: any, props: any) {
    return await services.api.put(params, {
        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
        apiPath: `/admin/shopping_guide_application_records/${props.params.id}`,
    });
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        { onSubmit },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
export const AuditGuideUserEditForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuditGuideUserFormView);
