import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MiniprogramCodeLoader: Loader = {
    get(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/mini_program_codes';

        if (data.id) {
            config.apiPath = `/admin/mini_program_codes/${data.id}`;
        }

        return api.get(data, config).then((res: any) => {
            if (data.id) {
                if (res.target) {
                    res.target = JSON.parse(res.target);
                }

                res.subsiteId = String(res.subsiteId);
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.nameStr = item.name;
                        return item;
                    });
            }
            return res;
        });
    },
    post(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/mini_program_codes';

        if (params.target) {
            params.pageName = params.target.linkName;
            params.needUrlLink = params.target.needUrlLink;
            params.target = JSON.stringify(params.target);
        }

        return api.post(params, config);
    },
    put(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/mini_program_codes/${id}`;

        if (params.target) {
            params.pageName = params.target.linkName;
            params.needUrlLink = params.target.needUrlLink;
            params.target = JSON.stringify(params.target);
        }

        return api.put(params, config);
    },
};
