import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        manageGiftConfigEntity: {
            apiPath: '/loader/admin/manage_gift',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<lpmc>>',
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 250 } },
                },
                code: {
                    type: 'string',
                    displayName: '<<lpbm>>',
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 250 } },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<lpxx>>',
                    // collapsed: true,
                    // defaultOpen: true,
                    properties: {
                        name: {
                            type: 'string.memberGiftManageName',
                            displayName: '<<lpmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<memberGiftManageNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<memberGiftManageNameRequired>>',
                                maxLength: 60,
                                style: {
                                    width: 450,
                                },
                                seat: 'row.baseInfo.name',
                            },
                        },
                        code: {
                            type: 'string',
                            displayName: '<<lpbm>>',
                            rules: [
                                {
                                    required: false,
                                    whitespace: true,
                                    message: '<<h_qsrlpbm_msg>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrlpbm>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<bmbnzf>>',
                        },
                        salesUnit: {
                            type: 'string',
                            displayName: '<<dw>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdw>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrdw>>',
                                maxLength: 10,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        weight: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<zl_3>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                addonafter: 'g',
                                rules: [
                                    {
                                        type: 'number',
                                        min: 0,
                                        max: 999999,
                                        precision: 2,
                                        step: 0.01,
                                    },
                                ],
                                allowFloat: true,
                                width: 100,
                            },
                        },
                        marketPrice: {
                            type: 'number.price',
                            displayName: '<<marketPrice>>',
                            displayConfig: { addonafter: '<<yuan>>' },
                            rules: [
                                {
                                    message: '<<scjsrgscw>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,6}(\.\d{1,2})?$/;
                                        if (value) {
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                allowFloat: true,
                                rules: [
                                    {
                                        precision: 2,
                                        type: 'number',
                                        min: 0,
                                    },
                                ],
                            },
                        },
                    },
                },

                picsConfig: {
                    type: 'object.subForm',
                    displayName: '<<lptp>>',
                    // collapsed: true,
                    // defaultOpen: true,
                    properties: {
                        images: {
                            displayName: '<<lptp>>',
                            type: 'array.imgaesUploadSortable',
                            className: 'sigle-image-field',
                            defaultValue: [],
                            controlConfig: {
                                maxCount: 10,
                                fileName: 'files',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'picture-card',
                                maxSize: 2 * 1024,
                                uploadLimitSizeErrorMessage: '<<h_lptp_msg>>',
                            },
                            extra: '<<h_lptp_tip>>',
                        },
                    },
                },

                otherConfig: {
                    type: 'object.subForm',
                    displayName: '<<otherSetting>>',
                    // collapsed: true,
                    // defaultOpen: true,
                    properties: {
                        details: {
                            type: 'string.richTextPlus',
                            displayName: '<<twxq>>',
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                placeholder: '<<qsrtwxq>>',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 1000,
                                    message: '<<h_qsrtwxq_msg>>',
                                },
                            ],
                        },
                    },
                },
                advancedSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    defaultOpen: false,
                    properties: {
                        tag: {
                            type: 'string.modalTips',
                            displayName: '<<tpbq>>',
                            controlConfig: {
                                placeholder: '<<qsrtpbq>>',
                                style: {
                                    width: 450,
                                },
                                // tipsModalConfig: {
                                //     contentType: TipsModalContentType.NewMallActivityType,
                                //     showIcon: false,
                                //     text: '<<viewExample>>',
                                //     textColor: '#1890ff',
                                //     title: '<<example>>',
                                //     width: 648,
                                // },
                            },
                            extra: '<<pzgxxhzlpyytpdyztzs>>',
                        },
                        receiveExplain: {
                            type: 'string.richTextPlus',
                            displayName: '<<lqsm>>',
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                placeholder: '<<qsrlqsm>>',
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 1000,
                                    message: '<<h_qsrlqsm_msg>>',
                                },
                            ],
                        },
                    },
                },

                stockManage: {
                    type: 'object.subForm',
                    displayName: '<<kcgl>>',
                    // collapsed: true,
                    // defaultOpen: true,
                    properties: {
                        subsiteIds: {
                            type: 'array.giftSubsiteselector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<selectCorreSubsite>>' }],
                            controlConfig: {
                                type: 'checkbox',
                            },
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<lpmc>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<lpbm>>',
                },
                salesUnit: {
                    type: 'string',
                    displayName: '<<dw>>',
                },
                weight: {
                    type: 'number',
                    displayName: '<<zl_3>>（g）',
                },
                marketPrice: {
                    type: 'number',
                    displayName: '<<marketPrice>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: '1', name: '<<yx_2>>' },
                        { id: '2', name: '<<h_wx>>' },
                    ],
                },
            },
        },
    },
    components: {
        ManageGiftConfigView: {
            component: 'Viewport',
            entity: 'manageGiftConfigEntity',
        },
        ManageGiftConfigPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ManageGiftConfigHeader',
                },
                {
                    component: 'ManageGiftConfigTable',
                },
            ],
        },
        ManageGiftConfigHeader: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'ManageGiftConfigFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<xzlp>>',
                            icon: 'plus',
                            type: 'primary',
                            route: '/manage-gift-config/add',
                            span: 4,
                            style: {
                                marginRight: 24,
                            },
                            className: 'mt24 mb24',
                        },
                    ],
                },
            ],
        },
        ManageGiftConfigFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'code',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        ManageGiftConfigTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: true,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'code',
                },
                {
                    property: 'name',
                },
                {
                    property: 'salesUnit',
                },
                {
                    property: 'weight',
                },
                {
                    property: 'marketPrice',
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 190,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 2 },
                                    value: 1,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                    apiPath: '/admin/gift_products/update_status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 1 },
                                    value: 2,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                    apiPath: '/admin/gift_products/update_status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/manage-gift-config/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/manage-gift-config/logs/{{row.id}}',
                    },
                ],
            },
        },
        ManageGiftLogPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/operation_logs/GIFT/{id}',
                },
                { component: 'PageBackFooter' },
            ],
        },
        ManageGiftConfigAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'manageGiftConfigEntity',
            items: [{ component: 'ManageGiftConfigAddForm' }],
        },
        ManageGiftConfigAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'baseInfo.name',
                },

                {
                    property: 'baseInfo.code',
                },
                {
                    property: 'baseInfo.salesUnit',
                },
                {
                    property: 'baseInfo.weight',
                },
                {
                    property: 'baseInfo.marketPrice',
                },
                {
                    property: 'stockManage.subsiteIds',
                },
                {
                    property: 'picsConfig.images',
                },
                {
                    property: 'otherConfig.details',
                },
                {
                    property: 'advancedSetting.tag',
                },
                {
                    property: 'advancedSetting.receiveExplain',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ManageGiftConfigEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'manageGiftConfigEntity',
            loaderType: 'get',
            items: [{ component: 'ManageGiftConfigEditForm' }],
        },
        ManageGiftConfigEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'baseInfo.name',
                },

                {
                    property: 'baseInfo.code',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.salesUnit',
                },
                {
                    property: 'baseInfo.weight',
                },
                {
                    property: 'baseInfo.marketPrice',
                },
                {
                    property: 'stockManage.subsiteIds',
                },
                {
                    property: 'picsConfig.images',
                },
                {
                    property: 'otherConfig.details',
                },
                {
                    property: 'advancedSetting.tag',
                },
                {
                    property: 'advancedSetting.receiveExplain',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/manage-gift-config',
                    component: 'ManageGiftConfigView',
                    breadcrumbName: '<<manageGift>>',
                    privilege: {
                        path: 'manageGift',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ManageGiftConfigAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ManageGiftConfigEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },

                        {
                            path: '/logs/:id',
                            component: 'ManageGiftLogPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'ManageGiftConfigPage',
                        },
                    ],
                },
            ],
        },
    ],
};
