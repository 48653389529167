import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        BatchDeliveryEntity: {
            apiPath: '/loader/admin/batch-delivery',
            filters: {
                deliveryModeId: {
                    type: 'string.options.select',
                    displayName: '<<deliveryType>>',
                    defaultValue: '1001',
                    options: [
                        { id: '1001', name: '<<kd_2>>' },
                        { id: '1004', name: '<<tcp>>' },
                    ],
                    controlConfig: {
                        style: { width: 80 },
                        esMessage: '<<selectBeforeMerchant>>',
                    },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantIds: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteIds',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },

                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<xdsj>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
            },
        },
    },
    components: {
        BatchDeliveryView: {
            component: 'Viewport',
        },
        BatchDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [
                {
                    component: 'BatchDeliveryPageTabs',
                },
            ],
        },
        BatchDeliveryPageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<tgdrbgfh>>',
                    content: {
                        component: 'BatchDeliveryByImportPage',
                    },
                },
                {
                    title: '<<tgxzddfh>>',
                    content: {
                        component: 'BatchDeliveryBySelectPage',
                    },
                },
            ],
        },
        BatchDeliveryByImportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BatchDeliveryButtons',
                },
            ],
        },
        BatchDeliveryBySelectPage: {
            entity: 'BatchDeliveryEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BatchDeliveryOrderList',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/batch-delivery',
                    component: 'BatchDeliveryView',
                    breadcrumbName: '<<orderLogistics>>',
                    privilege: {
                        path: 'orderLogistics',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'BatchDeliveryPage' }],
                },
            ],
        },
    ],
};
