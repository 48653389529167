import React, { PureComponent } from 'react';
import { message, Input, Button, Icon } from 'antd';
import { cloneDeep, find, remove } from 'lodash';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface TypeBase {
    id?: number;
    name: string;
}

interface FeedbackTypeObject extends TypeBase {
    feedBackTypeInfoList: TypeBase[] | undefined;
    typeInfoInputValue?: string;
}

export interface FeedbackTypeProps {
    value: FeedbackTypeObject[] | undefined;

    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: FeedbackTypeObject[] | undefined) => void;
}
export class FeedbackTypes extends PureComponent<FeedbackTypeProps, any> {
    constructor(props: any) {
        super(props);
        this.state = { typeInputValue: '' };
    }

    changeTypeInputValue = (e: any) => {
        this.setState({ typeInputValue: e.target.value });
    };

    // 增加一级类型
    onAddType = () => {
        const { value, onChange } = this.props;
        const { typeInputValue } = this.state;
        if (!typeInputValue) {
            message.error(language.getText('yjfklxbnwk'));
            return;
        }
        const newValue = value && value.length ? cloneDeep(value) : [];
        const hasValue = find(newValue, { name: typeInputValue.trim() });
        if (hasValue) {
            message.error(language.getText('yjfklxbnzf'));
            return;
        }
        newValue.push({ name: typeInputValue.trim(), feedBackTypeInfoList: [] });
        this.setState({ typeInputValue: '' });
        onChange(newValue);
    };
    // 删除一级类型
    deleteTypeItem = (name: string) => {
        const { value, onChange } = this.props;
        const newValue = value && value.length ? cloneDeep(value) : [];
        remove(newValue, { name });
        onChange(newValue);
    };

    changeTypeInfoInputValue = (e: any, typeName: string) => {
        const { value, onChange } = this.props;
        const newValue = value && value.length ? cloneDeep(value) : [];
        const typeItem = find(newValue, { name: typeName });
        if (typeItem) {
            typeItem.typeInfoInputValue = e.target.value.trim();
        }
        onChange(newValue);
    };

    //增加二级类型
    onAddTypeInfo = (typeName: string) => {
        const { value, onChange } = this.props;
        const newValue = value && value.length ? cloneDeep(value) : [];
        const typeItem = find(newValue, { name: typeName });
        if (typeItem) {
            if (!typeItem.typeInfoInputValue) {
                message.error(language.getText('ejfklxbnwk'));
                return;
            }

            if (!(typeItem.feedBackTypeInfoList && typeItem.feedBackTypeInfoList.length)) {
                typeItem.feedBackTypeInfoList = [];
            }
            const hasValue = find(typeItem.feedBackTypeInfoList, {
                name: typeItem.typeInfoInputValue,
            });
            if (hasValue) {
                message.error(language.getText('ejfklxbnzf'));
                return;
            }
            const typeInfo: TypeBase = { name: typeItem.typeInfoInputValue };
            typeItem.feedBackTypeInfoList.push(typeInfo);
            typeItem.typeInfoInputValue = '';
            onChange(newValue);
        }
    };

    //删除二级类型
    deleteTypeInfoItem = (typeName: string, typeInfoName: string) => {
        const { value, onChange } = this.props;
        const newValue = value && value.length ? cloneDeep(value) : [];
        const typeItem = find(newValue, { name: typeName });
        if (typeItem && typeItem.feedBackTypeInfoList) {
            remove(typeItem.feedBackTypeInfoList, { name: typeInfoName });
            onChange(newValue);
        }
    };

    render() {
        const { value } = this.props;
        const { typeInputValue } = this.state;
        return (
            <div className="feedback-types">
                <div className="types">
                    <div className="types-left">{language.getText('yjfklx')}：</div>
                    <div className="types-right">
                        {value &&
                            value.length > 0 &&
                            value.map((item: FeedbackTypeObject, index: number) => {
                                return (
                                    <div key={index} className="type-item">
                                        {item.name}
                                        <Icon
                                            type="close-circle"
                                            className="type-item-delete"
                                            onClick={() => {
                                                this.deleteTypeItem(item.name);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        <Input
                            className="type-input"
                            maxLength={20}
                            value={typeInputValue}
                            onChange={this.changeTypeInputValue}
                        />
                        <Button type="link" onClick={this.onAddType}>
                            {language.getText('zj_2')}
                        </Button>
                    </div>
                </div>
                <div className="type-infos">
                    <div className="type-infos-left">{language.getText('ejfklx')}：</div>
                    <div className="type-infos-right">
                        {value &&
                            value.length > 0 &&
                            value.map((item: FeedbackTypeObject, index: number) => {
                                return (
                                    <div key={index} className="type-item">
                                        <div className="type-item-name">{item.name}</div>
                                        {item.feedBackTypeInfoList &&
                                            item.feedBackTypeInfoList.length > 0 &&
                                            item.feedBackTypeInfoList.map(
                                                (typeInfo: TypeBase, index: number) => {
                                                    return (
                                                        <div key={index} className="type-info-item">
                                                            {typeInfo.name}
                                                            <Icon
                                                                type="close-circle"
                                                                className="type-info-item-delete"
                                                                onClick={() => {
                                                                    this.deleteTypeInfoItem(
                                                                        item.name,
                                                                        typeInfo.name
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        <Input
                                            className="type-info-input"
                                            maxLength={20}
                                            value={item.typeInfoInputValue}
                                            onChange={(e: any) => {
                                                this.changeTypeInfoInputValue(e, item.name);
                                            }}
                                        />
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                this.onAddTypeInfo(item.name);
                                            }}
                                        >
                                            {language.getText('zj_2')}
                                        </Button>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}
