import React, { FunctionComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { TipsModalContentType } from './index';
import { Button, message } from 'antd';

interface TipsModalContentProps {
    contentType: TipsModalContentType;
}

const CarparkAmapPoiIdTipsModalContent: FunctionComponent = () => {
    const prefix = 'carpark-amp-poi-id';
    const classNames = {
        title: `${prefix}-title`,
        text: `${prefix}-text`,
        img: `${prefix}-img`,
    };
    return (
        <div className={prefix}>
            <div className={classNames.title}>一、车场POI查询</div>
            <div className={classNames.text}>
                (1) 登录
                <a target="_blank" rel="noopener noreferrer" href="https://ditu.amap.com/">
                    高德地图
                </a>
            </div>
            <div className={classNames.text}>(2) 输入你想查找的停车场名称，点击【搜索】</div>
            <div className={classNames.text}>
                (3) 点击地图停车场（点击前为红色水滴状图标，点击后变为蓝色）
            </div>
            <div className={classNames.text}>
                (4) 查看停车场名称下方灰色字体的场地属性（搜索栏下方）
            </div>
            <div className={classNames.text}>
                (5)
                如停车场属性为非“停车场”，则需在高德上新建车场；如场地属性为“停车场”则页面，地址栏“palce/”后显示B开头的10位字符就是停车场对应POI
            </div>
            <div className={classNames.text}>(6) POI获取后，用于停车场注册页面的填写。</div>
            <img className={classNames.img} alt="" src="./images/editor/carpark-image-1.png" />
            <div className={classNames.title}>二、POI新增</div>
            <div className={classNames.text}>(1) 手机打开高德APP，点击【更多工具】-【上报】</div>
            <div className={classNames.text}>(2) 依次点击【新增地点】，填写车场的相关信息</div>
            <div className={classNames.text}>
                (3) 进入实体认证：勾选【我是热心用户】，上传车场出入口全景照，提交注册申请
            </div>
            <div className={classNames.text}>
                (4) 查询进度：点击【我的】下拉选择【我的反馈】，可查询新增地点的注册进度 
            </div>
            <img className={classNames.img} alt="" src="./images/editor/carpark-image-2.png" />
        </div>
    );
};

const ExperienceExamplePictureContent: FunctionComponent = () => {
    const imageUrl = require('./images/experience_example.png');
    return <img src={imageUrl} width="600px" alt="" />;
};

const DoubleRuleParticipateCardinalityCalculateContent: FunctionComponent = () => {
    const imageUrl = require('./images/double_rule_example.png');
    return <img src={imageUrl} width="600px" alt="" />;
};
const ExperienceDoubleRuleContent: FunctionComponent = () => {
    const imageUrl = require('./images/exprience_double_rule_example.png');
    return <img src={imageUrl} width="600px" alt="" />;
};
const LoginTipContent: FunctionComponent = () => {
    const imageUrl = require('./images/login_tip.png');
    return <img src={imageUrl} width="600px" alt="" />;
};
const OrderAllowAfterSalesContent: FunctionComponent = () => {
    const imageUrl = require('./images/order_allow_after_sales.png');
    return <img src={imageUrl} width="1000px" alt="" />;
};

const DeliveryReminderContent: FunctionComponent = () => {
    const imageUrl = require('./images/delivery_reminder.png');
    return <img src={imageUrl} width="600px" alt="" />;
};

const OpenInvoiceTabSequencesContent: FunctionComponent = () => {
    const imageUrl = require('./images/open-invoice-tab-sequence.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="400px" alt="" />
        </div>
    );
};

const OrderMergeTabSequencesContent: FunctionComponent = () => {
    const imageUrl = require('./images/order-merge-tab-sequences.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="400px" alt="" />
        </div>
    );
};

const SubsiteSuspendTipsContent: FunctionComponent = () => {
    const imageUrl = require('./images/subsite-suspend-tips.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="400px" alt="" />
        </div>
    );
};

const BalanceCouponQrCodeContent: FunctionComponent = () => {
    const imageUrl = require('./images/balance_coupon_qrcode.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="1000px" alt="" />
        </div>
    );
};

const CommonSettingMerchantTypeTagContent: FunctionComponent = () => {
    const imageUrl = require('./images/common_setting_merchant_type_tag.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="500px" alt="" />
        </div>
    );
};

const CommonSettingProductDetailsContent: FunctionComponent = () => {
    const imageUrl = require('./images/common_setting_product_details.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="1000px" alt="" />
        </div>
    );
};

const CheckinActivityRecordShare: FunctionComponent = () => {
    const imageUrl = require('./images/checkin_activity_record_share.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="1000px" alt="" />
        </div>
    );
};

const LoginMethodRecordShare: FunctionComponent = () => {
    const imageUrl = require('./images/login-method.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="1300px" alt="" />
        </div>
    );
};

const DoubleFactorRecordShare: FunctionComponent = () => {
    const imageUrl = require('./images/double-factor.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="1000px" alt="" />
        </div>
    );
};
const CommonSettingStyle1: FunctionComponent = () => {
    const imageUrl = require('./images/commom_style1.jpg');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="480px" alt="" />
        </div>
    );
};
const CommonSettingStyle2: FunctionComponent = () => {
    const imageUrl = require('./images/commom_style2.jpg');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="480px" alt="" />
        </div>
    );
};

const EstimatePriceRecordShare: FunctionComponent = () => {
    const imageUrl = require('./images/forecast-price.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="1000px" alt="" />
        </div>
    );
};

const UploadWechat: FunctionComponent = () => {
    const imageUrl = require('./images/upload_wechat.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="755px" alt="" />
        </div>
    );
};

const NoteCommentInlet: FunctionComponent = () => {
    const imageUrl = require('./images/note_comment_example.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="800px" alt="" />
        </div>
    );
};

const NewMallActivityTypeContent: FunctionComponent = () => {
    const imageUrl = require('./images/new-mall-activity-type.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="453px" alt="" />
        </div>
    );
};

const ScorePopupContent: FunctionComponent = () => {
    const imageUrl = require('./images/score-popup.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="400px" alt="" />
        </div>
    );
};

const SubsiteServicePicContent: FunctionComponent = () => {
    const imageUrl = require('./images/subsite-service-pic.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="800px" alt="" />
        </div>
    );
};
const DouYinLaiKeiPicContent: FunctionComponent = () => {
    const imageUrl = require('./images/dylk-1.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="700px" alt="" />
        </div>
    );
};

const RewardsPopupPicContent: FunctionComponent = () => {
    const imageUrl = require('./images/rewards-popup-pic.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="800px" alt="" />
        </div>
    );
};
const BigCodesDatePicContent: FunctionComponent = () => {
    const imageUrl = require('./images/big-code-date.png');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="380px" alt="" />
        </div>
    );
};
const ShoppingListTipsContent: FunctionComponent = () => {
    const imageUrl = require('./images/shopping-list-tips.jpg');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="380px" alt="" />
        </div>
    );
};
const MerchantBigCodesTipsContent: FunctionComponent = () => {
    const imageUrl = require('./images/merchant-big-codes-tips.jpg');
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="880px" alt="" />
        </div>
    );
};

const ArticleBusinessType: FunctionComponent<{ contentType: string }> = (props) => {
    const imageUrl = require(`./images/articleType/${props.contentType}.png`);
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} width="500px" alt="" />
        </div>
    );
};

const copy = (url: any) => {
    let input = document.createElement('input');
    input.value = url;
    document.body.appendChild(input);
    input.select();
    if (document.execCommand('Copy')) {
        message.success(services.language.getText('copySuccess'));
    } else {
        message.warning(services.language.getText('copyError'));
    }
    document.body.removeChild(input);
};

const ViewVariableTipsContent = () => {
    const variable = '{orderNo}';
    return (
        <div className="view-variable-modal">
            <div className="variable-name">
                <div>{services.language.getText('bl_2')}</div>
                <div>{services.language.getText('blsm')}</div>
            </div>
            <div className="variable-value">
                <div>
                    {variable}
                    <Button onClick={() => copy(variable)} type="link">
                        {services.language.getText('copy')}
                    </Button>
                </div>
                <div>{services.language.getText('variableIntro')}</div>
            </div>
        </div>
    );
};

export const TipsModalContent: FunctionComponent<TipsModalContentProps> = (props) => {
    const { contentType } = props;
    if (contentType === TipsModalContentType.CarparkAmapPoiId) {
        return <CarparkAmapPoiIdTipsModalContent />;
    }
    if (contentType === TipsModalContentType.ExperienceExamplePicture) {
        return <ExperienceExamplePictureContent />;
    }
    if (contentType === TipsModalContentType.DoubleRuleParticipateCardinalityCalculate) {
        return <DoubleRuleParticipateCardinalityCalculateContent />;
    }
    if (contentType === TipsModalContentType.LoginTip) {
        return <LoginTipContent />;
    }
    if (contentType === TipsModalContentType.OrderAllowAfterSales) {
        return <OrderAllowAfterSalesContent />;
    }
    if (contentType === TipsModalContentType.ExprienceDoubleRule) {
        return <ExperienceDoubleRuleContent />;
    }
    if (contentType === TipsModalContentType.DeliveryReminder) {
        return <DeliveryReminderContent />;
    }
    if (contentType === TipsModalContentType.OrderMergeTabSequences) {
        return <OrderMergeTabSequencesContent />;
    }
    if (contentType === TipsModalContentType.OpenInvoiceTabSequences) {
        return <OpenInvoiceTabSequencesContent />;
    }
    if (contentType === TipsModalContentType.SubsiteSuspendTips) {
        return <SubsiteSuspendTipsContent />;
    }
    if (contentType === TipsModalContentType.BalanceCouponQrCode) {
        return <BalanceCouponQrCodeContent />;
    }
    if (contentType === TipsModalContentType.CommonSettingMerchantTypeTag) {
        return <CommonSettingMerchantTypeTagContent />;
    }
    if (contentType === TipsModalContentType.CommonSettingProductDetails) {
        return <CommonSettingProductDetailsContent />;
    }
    if (contentType === TipsModalContentType.CheckinActivityRecordShare) {
        return <CheckinActivityRecordShare />;
    }
    if (contentType === TipsModalContentType.LoginMethod) {
        return <LoginMethodRecordShare />;
    }
    if (contentType === TipsModalContentType.DoubleFactor) {
        return <DoubleFactorRecordShare />;
    }
    if (contentType === TipsModalContentType.CommonSettingStyle1) {
        return <CommonSettingStyle1 />;
    }
    if (contentType === TipsModalContentType.CommonSettingStyle2) {
        return <CommonSettingStyle2 />;
    }
    if (contentType === TipsModalContentType.EstimatePrice) {
        return <EstimatePriceRecordShare />;
    }
    if (contentType === TipsModalContentType.UploadWechat) {
        return <UploadWechat />;
    }
    if (contentType === TipsModalContentType.NoteComment) {
        return <NoteCommentInlet />;
    }
    if (contentType === TipsModalContentType.NewMallActivityType) {
        return <NewMallActivityTypeContent />;
    }
    if (contentType === TipsModalContentType.ScorePopup) {
        return <ScorePopupContent />;
    }
    if (contentType === TipsModalContentType.SubsiteServicePic) {
        return <SubsiteServicePicContent />;
    }
    if (contentType === TipsModalContentType.RewardsPopup) {
        return <RewardsPopupPicContent />;
    }
    if (contentType === TipsModalContentType.DouYingLaiKeyPicture) {
        return <DouYinLaiKeiPicContent />;
    }
    if (contentType === TipsModalContentType.BigCodesDate) {
        return <BigCodesDatePicContent />;
    }
    if (contentType === TipsModalContentType.ShoppingListTips) {
        return <ShoppingListTipsContent />;
    }
    if (contentType === TipsModalContentType.MerchantBigCodesTips) {
        return <MerchantBigCodesTipsContent />;
    }
    if (contentType === TipsModalContentType.ViewVariable) {
        return <ViewVariableTipsContent />;
    }
    const articleList = [
        TipsModalContentType.WeighingGoodsService,
        TipsModalContentType.UserPrivacyAgreement,
        TipsModalContentType.HelpCenterArticle,
        TipsModalContentType.ProductDetailPagePriceDescription,
        TipsModalContentType.ServiceReservationRentalAgreement,
        TipsModalContentType.InvoiceIssuanceRules,
        TipsModalContentType.CheckoutPageInstructions,
        TipsModalContentType.CardBindingPageInstructions,
        TipsModalContentType.RealNameAuthenticationServiceAgreement,
        TipsModalContentType.CheckoutPageUsageInstructions,
        TipsModalContentType.OfflineBalanceVoucherPaymentCodeInstructions,
        TipsModalContentType.InvoiceTaxNumberExplanation,
        TipsModalContentType.InvoiceNotice,
        TipsModalContentType.AuthorizationAgreement,
        TipsModalContentType.CrossBorderDataExplanation,
        TipsModalContentType.DepositPurchaseAuthorizationAgreement,
        TipsModalContentType.PersonalizedContentRecommendation,
        TipsModalContentType.UserAgreement,
        TipsModalContentType.TermsOfServiceAndPrivacyPolicy,
        TipsModalContentType.MemberCodeDisclaimer,
        TipsModalContentType.AccountCancellationNotice,
        TipsModalContentType.CardPurchasePageInstructions,
    ];
    if (articleList.includes(contentType)) {
        return <ArticleBusinessType contentType={contentType} />;
    }
    return <div></div>;
};
