import React, { PureComponent } from 'react';
import { Button, Modal, Row, Col, Select, Tag, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { find, cloneDeep, remove } from 'lodash';
import { Subsite, FilterParams } from '../../common';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface Props {
    subsites: Subsite[];
    type?: 'batch' | 'alone';
    memberId?: string;
    searchParams?: FilterParams;
}
interface State {
    defaultSubsiteId: string;
    visible: boolean;
    subsiteId: string;
    tags: any[];
    inputVisible: boolean;
    tagsOptions: any[];
    searchKeyword?: string;
}

export default class memberTagImportButton extends PureComponent<Props, State> {
    state: State = {
        defaultSubsiteId: '',
        visible: false,
        subsiteId: '',
        tags: [],
        inputVisible: false,
        tagsOptions: [],
        searchKeyword: '',
    };

    componentWillReceiveProps(nextProps: any) {
        const { subsites } = this.props;
        const { defaultSubsiteId } = this.state;
        if (subsites && subsites.length > 0 && !defaultSubsiteId) {
            const [defaultSubsite] = subsites;
            this.setState({
                defaultSubsiteId: defaultSubsite?.id,
                subsiteId: defaultSubsite?.id,
            });
        }
    }

    onOk = async () => {
        const { memberId, type, searchParams } = this.props;
        const { tags = [], subsiteId } = this.state;
        try {
            if (type === 'batch') {
                await api.post(
                    {
                        ...searchParams,
                        tagIds: tags.map((i) => i.id),
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                        apiPath: '/admin/member_tags/batch',
                    }
                );
            } else {
                await api.post(
                    {
                        subsiteId,
                        memberId,
                        tagIds: tags.map((i) => i.id),
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                        apiPath: '/admin/member_tags',
                    }
                );
            }
            message.success(language.getText('bccg') + '！');
            this.closeModal();
        } catch (error) {
            message.error(language.getText('bcsb') + '！');
        }
    };

    openModal = () => {
        this.setState(
            {
                visible: true,
            },
            this.getMemberTags
        );
    };

    getMemberTags = async () => {
        const { memberId, type } = this.props;
        const { subsiteId } = this.state;
        if (type === 'batch') {
            return;
        }
        const params: any = {
            memberId,
            subsiteId,
        };
        const res: any = await api.get(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: `/admin/member_tags`,
        });
        this.setState({
            tags: res.map((tag: any) => ({
                ...tag,
                id: tag.tagId,
            })),
        });
    };

    closeModal = () => {
        const { defaultSubsiteId } = this.state;
        this.setState({
            subsiteId: defaultSubsiteId,
            tags: [],
            inputVisible: false,
            tagsOptions: [],
            searchKeyword: '',
            visible: false,
        });
    };

    onChangeSubsit = (id: string) => {
        this.setState(
            {
                subsiteId: id,
            },
            this.getMemberTags
        );
    };

    onCloseTag = (tag: any) => {
        const { tags } = this.state;
        const newTags = cloneDeep(tags);
        remove(newTags, { id: tag.id });
        this.setState({
            tags: newTags,
        });
    };

    showInput = async () => {
        const { subsiteId } = this.state;
        if (subsiteId) {
            try {
                const tagsOptions = await this.getTags(subsiteId);
                this.setState({ inputVisible: true, tagsOptions });
            } catch (error) {
                this.setState({ inputVisible: false, tagsOptions: [] });
            }
        } else {
            message.warn(services.language.getText('selectBeforeMerchant'));
        }
    };

    getTags = async (subsiteId: string) => {
        const res: any = await api.get(
            {
                page: 1,
                perPage: 500,
                subsiteId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/tags',
            }
        );
        return res.result || [];
    };

    loadAttributes = (name?: string) => {
        this.setState({
            searchKeyword: name,
        });
    };

    onChangeTag = async (id: string) => {
        const { tagsOptions, tags, searchKeyword } = this.state;
        if (id === '-1') {
            if (!searchKeyword) {
                return;
            }
            const newItem = await this.createNewTag(searchKeyword);
            const newTags = cloneDeep(tags);
            newTags.push(newItem);
            this.setState({
                tags: newTags,
                searchKeyword: '',
                inputVisible: false,
            });
        } else {
            const hasItem = find(tags, { id: id });
            if (hasItem) {
                message.warn(language.getText('bqmczf'));
                return;
            }
            const tagItem = find(tagsOptions, { id: id });
            const newTags = cloneDeep(tags);
            newTags.push(tagItem);
            this.setState({
                tags: newTags,
                inputVisible: false,
            });
        }
    };

    createNewTag = async (name: string) => {
        const { subsiteId } = this.state;
        const id = await api.post(
            {
                remark: '',
                subsiteId: subsiteId,
                tagName: name,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/tags',
            }
        );
        return {
            tagName: name,
            id,
        };
    };

    renderSelect = (styleOption?: any, index?: number) => {
        const { tagsOptions, searchKeyword } = this.state;
        const hasSearchKeyword = find(tagsOptions, { tagName: searchKeyword });
        return (
            <Select
                style={{ width: 200 }}
                className="style-selector"
                placeholder={services.language.getText('selectPlease')}
                onChange={this.onChangeTag}
                value={styleOption?.id}
                autoClearSearchValue
                showSearch
                optionFilterProp="tagName"
                filterOption={(input, option: any) =>
                    (option.props.children || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSearch={this.loadAttributes}
                onFocus={this.loadAttributes}
            >
                {!hasSearchKeyword && searchKeyword && (
                    <Select.Option value={'-1'}>{searchKeyword}</Select.Option>
                )}

                {tagsOptions.map((tag: any) => {
                    return (
                        <Select.Option key={tag.id} value={tag.id}>
                            {tag.tagName}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };

    renderModal = () => {
        const { subsites } = this.props;
        const { tags, inputVisible, subsiteId } = this.state;
        return (
            <div>
                <Row type="flex">
                    <Col span={4} className={`${prefix}__label`}>
                        {language.getText('subsites')}：
                    </Col>
                    <Col span={20}>
                        <Select
                            value={subsiteId}
                            placeholder={services.language.getText('selectPlease')}
                            allowClear
                            showSearch
                            optionFilterProp="name"
                            filterOption={(input, option: any) =>
                                (option.props.children || '')
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 200 }}
                            onChange={this.onChangeSubsit}
                        >
                            {subsites.map((item) => {
                                return (
                                    <Select.Option key={`key-${item.id}`} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row style={{ marginTop: 8 }}>
                    <Col span={4} className={`${prefix}__label`}>
                        {language.getText('tagName')}：
                    </Col>
                    <Col span={20}>
                        <div className={`${prefix}__tags`}>
                            {tags.map((tag: any) => (
                                <Tag
                                    className={`${prefix}__tags__item`}
                                    key={tag.id}
                                    closable
                                    onClose={this.onCloseTag.bind(this, tag)}
                                >
                                    {tag.tagName}
                                </Tag>
                            ))}
                        </div>
                        <div>
                            {inputVisible && this.renderSelect()}
                            {!inputVisible && (
                                <Tag
                                    className={`${prefix}__tags__item`}
                                    onClick={this.showInput}
                                    style={{ background: '#fff', borderStyle: 'dashed' }}
                                >
                                    <Icon type="plus" /> {language.getText('addTag')}
                                </Tag>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    render() {
        const { type } = this.props;
        const { visible } = this.state;
        const modalProps = {
            width: 800,
            visible: visible,
            title: type === 'batch' ? language.getText('pldb') : language.getText('db_1'),
            onCancel: this.closeModal,
            onOk: this.onOk,
        };
        return (
            <>
                {type === 'batch' ? (
                    <Button type="default" onClick={this.openModal}>
                        {language.getText('pldb')}
                    </Button>
                ) : (
                    <Button
                        className="table-action-column-item"
                        style={{ padding: 0 }}
                        type="link"
                        onClick={this.openModal}
                    >
                        {language.getText('db_1')}
                    </Button>
                )}
                <Modal {...modalProps}>{this.renderModal()}</Modal>
            </>
        );
    }
}

const prefix = 'member-add-tag-button';
