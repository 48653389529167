import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Tree, Button, Input, InputNumber, Select, message as AntMessage } from 'antd';
import { isEmpty, isArray } from 'lodash';
import './index.less';
import { errorHandle } from '../../../applications/cae/services/error-handle';

const { getText } = services.language;

const api = services.api;

enum CategoryRateType {
    STANDARD = 'STANDARD',
    DUTY_FREE = 'DUTY_FREE',
    NO_TAX = 'NO_TAX',
    ZERO_RATE = 'ZERO_RATE',
    EXPORT_TAX_REBATE = 'EXPORT_TAX_REBATE',
}

const CategoryRateTypeText: any = {
    [CategoryRateType.STANDARD]: 'standardTaxRate',
    [CategoryRateType.DUTY_FREE]: 'dutyFree',
    [CategoryRateType.NO_TAX]: 'nonTaxtion',
    [CategoryRateType.ZERO_RATE]: 'zeroTaxRate',
    [CategoryRateType.EXPORT_TAX_REBATE]: 'exportTaxRefund',
};

export interface NormalInvoiceTaxrateCategoryEditFormStates {
    /**
     * 还有多少个分类未配置
     */
    taxRatesData: any | null;
    /**
     * 分类树
     */
    categoriesData: Array<any>;
    allKeys?: Array<string>;
    /**
     * 目前展开的分类下标
     */
    expandedKeys?: Array<string>;
    /**
     * 目前点击的分类key
     */
    currentCategoriesKey: any;
    /**
     * 目前点击的分类信息
     */
    currentCategories: any;
    submitParams: any;
    isloading: boolean;
}

export class NormalInvoiceTaxrateCategoryEditForm extends Component<
    {},
    NormalInvoiceTaxrateCategoryEditFormStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            taxRatesData: null,
            categoriesData: [],
            allKeys: undefined,
            expandedKeys: undefined,
            currentCategoriesKey: '',
            currentCategories: null,
            submitParams: {
                id: undefined,
                taxCode: '',
                taxRate: undefined,
                taxRateType: '',
            },
            isloading: false,
        };
    }
    loadTaxRates = () => {
        //获取还有多少个分类没配置税率
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/category_tax_rates/statistics`,
            }
        ).then((response: any) => {
            this.setState({
                taxRatesData: response,
            });
        });
    };

    loadCategories = () => {
        //获取分类树
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/category_tax_rates`,
            }
        ).then((response: any) => {
            let categoriesData = [response];
            const keys: string[] = [];
            categoriesData.forEach((item, i) => {
                this.format(item, [i], keys);
            });
            this.setState({
                categoriesData: categoriesData,
                allKeys: keys,
            });
        });
    };
    componentDidMount() {
        this.loadTaxRates();
        this.setState(
            {
                expandedKeys: ['0'],
            },
            () => {
                this.loadCategories();
            }
        );
    }

    format = (data: any, indexs: number[], keys: string[]) => {
        if (data.children && isArray(data.children) && data.children.length) {
            data.children.forEach((item: any, i: number) => {
                this.format(item, [...indexs, i], keys);
            });
        }
        let title = data.name;
        if (data.taxRate) {
            if (data.taxRate.rate) {
                title += `(${data.taxRate.code}，${data.taxRate.rate}%)`;
            }
            if (data.taxRate.type && data.taxRate.type !== 'STANDARD') {
                const typeText = getText(CategoryRateTypeText[data.taxRate.type]);
                title += `(${data.taxRate.code}，${typeText})`;
            }
        }
        data.title = title;
        if (indexs.length < 5) {
            data.key = indexs.join('-');
            keys.push(data.key);
        }
    };

    onSelect = (selectedKeys: React.Key[], info: any) => {
        const currentCategories = info.node.props;
        this.setState({
            currentCategoriesKey: selectedKeys,
            currentCategories: currentCategories,
            submitParams: {
                id: currentCategories.id,
                taxCode: currentCategories.taxRate && currentCategories.taxRate.code,
                taxRate: currentCategories.taxRate && currentCategories.taxRate.rate,
                taxRateType: currentCategories.taxRate && currentCategories.taxRate.type,
            },
        });
    };

    onExpand = (expandedKeys: any) => {
        this.setState({
            expandedKeys: expandedKeys,
        });
    };

    selectAll = () => {
        const { allKeys } = this.state;
        this.setState({
            isloading: true,
            expandedKeys: allKeys,
        });
        setTimeout(() => {
            this.setState({
                isloading: false,
            });
        }, 3000);
    };

    submit = async () => {
        const { submitParams } = this.state;
        if (!submitParams.taxCode) {
            AntMessage.warning(getText('qsrspssflbm'));
            return;
        }
        if (
            !submitParams.taxRate &&
            (!submitParams.taxRateType || submitParams.taxRateType === CategoryRateType.STANDARD)
        ) {
            AntMessage.warning(getText('qsrspslhxztssl'));
            return;
        }
        if (!submitParams.taxRateType) {
            submitParams.taxRateType = null; //后端不要''值 做一下处理
        }
        const result = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: '/admin/category_tax_rates/' + submitParams.id,
            }
        );
        if (result) {
            api.put(submitParams, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: '/admin/category_tax_rates',
            })
                .then(() => {
                    AntMessage.success(services.language.getText('common.saveSuccess'));
                    this.loadCategories();
                    this.loadTaxRates();
                })
                .catch(errorHandle);
        } else {
            api.post(submitParams, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: '/admin/category_tax_rates',
            })
                .then(() => {
                    AntMessage.success(services.language.getText('common.saveSuccess'));
                    this.loadCategories();
                    this.loadTaxRates();
                })
                .catch(errorHandle);
        }
    };

    onFiledChange(filed: string, e: any) {
        const { submitParams } = this.state;
        if (filed === 'taxCode') {
            submitParams[filed] = e.target.value;
        } else {
            submitParams[filed] = e;
        }
        this.setState(
            {
                submitParams,
            },
            () => {
                this.setTaxRateTypeDisabled();
            }
        );
    }

    setTaxRateTypeDisabled = () => {
        //主要选择特殊税率，那前面填写税率就清空并不可编辑
        const { submitParams } = this.state;
        if (
            !isEmpty(submitParams.taxRateType) &&
            submitParams.taxRateType !== CategoryRateType.STANDARD
        ) {
            submitParams.taxRate = undefined;
            this.setState({
                submitParams,
            });
        }
    };

    render() {
        const {
            taxRatesData,
            categoriesData,
            currentCategories,
            submitParams,
            expandedKeys,
            isloading,
        } = this.state;
        let taxRateDisabled = true;
        if (!submitParams.taxRateType || submitParams.taxRateType === CategoryRateType.STANDARD) {
            taxRateDisabled = false;
        }
        return (
            <div className="normal-invoice-taxrate-category-edit-form">
                <div>
                    {taxRatesData && taxRatesData.noTaxCodeCount && (
                        <div className="content">
                            <span>{getText('mqy')}</span>
                            <span className="content-value-red">
                                {taxRatesData.noTaxCodeCount}
                                {getText('ge')}
                            </span>
                            <span>{getText('bzflhwpzssflbm')}</span>
                        </div>
                    )}
                    <div className="tip">{getText('h_spbsssbm_spzjflssbm')}</div>
                    <Button type="primary" onClick={this.selectAll}>
                        {getText('h_zkfl')}
                    </Button>
                    {isloading && (
                        <span style={{ color: '#999', marginLeft: '10px' }}>
                            {getText('jzz')}...
                        </span>
                    )}
                    <div style={{ height: '500px', overflow: 'auto' }}>
                        <Tree
                            showLine
                            showIcon
                            onSelect={this.onSelect}
                            onExpand={this.onExpand}
                            expandedKeys={expandedKeys}
                            treeData={categoriesData}
                        />
                    </div>
                </div>
                {currentCategories && currentCategories.id !== 1 && (
                    <div className="edit-wrap">
                        <div className="edit-title">{getText('bjflsl')}</div>
                        <div style={{ padding: '10px 10px 0px 10px' }}>
                            <div className="edit-input-wrap">
                                <div className="edit-input-title">{getText('ssfl')}：</div>
                                <span>{currentCategories.name}</span>
                            </div>
                            <div className="edit-input-wrap">
                                <div className="edit-input-title">{getText('spssflbm')}：</div>
                                <Input
                                    className="edit-input-no-width"
                                    placeholder={services.language.getText('inputPlease')}
                                    value={submitParams.taxCode}
                                    onInput={this.onFiledChange.bind(this, 'taxCode')}
                                    max={100}
                                />
                            </div>
                            <div className="edit-input-wrap">
                                <span style={{ marginLeft: '20px' }}>
                                    {services.language.getText('taxRateType')}：
                                </span>
                                <Select
                                    placeholder={services.language.getText('selectPlease')}
                                    style={{ width: '120px' }}
                                    value={submitParams.taxRateType}
                                    onChange={this.onFiledChange.bind(this, 'taxRateType')}
                                >
                                    <Select.Option value="">
                                        {getText('selectPlease')}
                                    </Select.Option>
                                    <Select.Option value="STANDARD">
                                        {getText('standardTaxRate')}
                                    </Select.Option>
                                    <Select.Option value="DUTY_FREE">
                                        {getText('dutyFree')}
                                    </Select.Option>
                                    <Select.Option value="NO_TAX">
                                        {getText('nonTaxtion')}
                                    </Select.Option>
                                    <Select.Option value="ZERO_RATE">
                                        {getText('zeroTaxRate')}
                                    </Select.Option>
                                    <Select.Option value="EXPORT_TAX_REBATE">
                                        {getText('exportTaxRefund')}
                                    </Select.Option>
                                </Select>
                                <div className="edit-input-title">{getText('taxRate')}：</div>
                                <InputNumber
                                    className="edit-input-taxrate-width"
                                    placeholder={services.language.getText('inputPlease')}
                                    value={submitParams ? submitParams.taxRate : undefined}
                                    disabled={taxRateDisabled}
                                    min={0}
                                    max={100}
                                    onChange={(value: any) => {
                                        this.onFiledChange('taxRate', value);
                                    }}
                                />
                                <span style={{ marginLeft: '5px' }}>%</span>
                            </div>
                            <div className="edit-input-wrap">
                                <div className="edit-input-title"></div>
                                <span className="edit-input-title-tip">
                                    {getText('h_qtxsz_lsl')}
                                </span>
                            </div>
                        </div>
                        <Button className="edit-button" type="primary" onClick={this.submit}>
                            {getText('submit')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}
