import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const FestivalMarketingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/festival_marketing_activities';
        const { id } = data;
        if (data.executeStatus === 'ALL') {
            delete data.executeStatus;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (id) {
            config.apiPath = `/admin/festival_marketing_activities/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res) {
                    res.baseInfo = {
                        name: res.name,
                        dateRange: {
                            start: res.startTime,
                            end: res.endTime,
                        },
                    };
                    const rewardInfos: any[] = [];
                    res.rewardInfos.forEach((item: any) => {
                        const index = rewardInfos.findIndex(
                            (i) => i.cardLevelId === item.rewardConditionValue
                        );
                        if (index < 0) {
                            if (item.rewardType === 'POINT_DOUBLE') {
                                rewardInfos.push({
                                    cardLevelId: item.rewardConditionValue,
                                    rewardValue: item.rewardValue,
                                    cardLevelName: item.rewardConditionName,
                                });
                            }
                            if (item.rewardType === 'EXPERIENCE_DOUBLE') {
                                rewardInfos.push({
                                    cardLevelId: item.rewardConditionValue,
                                    experienceValue: item.rewardValue,
                                    cardLevelName: item.rewardConditionName,
                                });
                            }
                        } else {
                            if (item.rewardType === 'POINT_DOUBLE') {
                                rewardInfos[index].rewardValue = item.rewardValue;
                            }
                            if (item.rewardType === 'EXPERIENCE_DOUBLE') {
                                rewardInfos[index].experienceValue = item.rewardValue;
                            }
                        }
                    });
                    if (res.marketingSchedule) {
                        const { startTime, endTime } = res.marketingSchedule || {};
                        res.marketingSchedule = {
                            ...res.marketingSchedule,
                            time:
                                startTime && endTime
                                    ? `${startTime}-${endTime}`
                                    : services.language.getText('longTerm'),
                        };
                    }
                    res.ruleInfo = {
                        subsiteId: res.subsite ? res.subsite.id + '' : '',
                        merchants: res.merchants
                            ? res.merchants.map((m: any) => ({
                                  ...m,
                                  merchantId: m.id + '',
                                  merchantName: m.name,
                                  code: m.code,
                                  merchantType: m.type,
                                  merchantStatus: m.status,
                              }))
                            : [],
                        activityProductType: res.activityProductType || 'ALL',
                        channel: res.channel,
                        memberLevelRule: {
                            unifiedReward: res.unifiedReward,
                            rewardInfos,
                        },
                        marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                    };
                    if (res.products && res.products.length > 0) {
                        res.ruleInfo.products = {
                            type: 'APPOINT_PRODUCT',
                            values: res.products,
                        };
                    } else if (res.brands && res.brands.length > 0) {
                        res.ruleInfo.products = {
                            type: 'APPOINT_BRAND',
                            values: res.brands,
                        };
                    }
                }
            } else {
                if (res && res.result) {
                    res.result.forEach((item: any) => {
                        item.subsite = item.subsite.name;
                        item.activityProductType = item.activityProductType || '';
                        item.canImport = item.enableImportProducts;
                    });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/festival_marketing_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/festival_marketing_activities/${params.id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
    }
    const ruleInfo = params.ruleInfo;
    if (ruleInfo) {
        newParams.subsiteId = ruleInfo.subsiteId;
        newParams.merchantIds = ruleInfo.merchants
            ? ruleInfo.merchants.map((m: any) => m.merchantId)
            : [];
        newParams.channel = ruleInfo.channel;
        if (ruleInfo.channel === 'ONLINE') {
            const products = params.ruleInfo.products;
            if (products && products.type) {
                newParams.rangeType = products.type;
            }
            if (products && products.values) {
                newParams.promotionScopeIds = products.values.map((value: any) => value.id);
            }
            newParams.activityProductType = params.ruleInfo.activityProductType;
        }
        const memberLevelRule = ruleInfo.memberLevelRule;
        if (memberLevelRule) {
            newParams.unifiedReward = memberLevelRule.unifiedReward;
            newParams.rewardInfos = [];
            memberLevelRule.rewardInfos.forEach((reward: any) => {
                if (reward.rewardValue) {
                    newParams.rewardInfos.push({
                        rewardConditionValue: memberLevelRule.unifiedReward
                            ? 0
                            : reward.cardLevelId,
                        rewardType: 'POINT_DOUBLE',
                        rewardValue: reward.rewardValue,
                    });
                }
                if (reward.experienceValue) {
                    newParams.rewardInfos.push({
                        rewardConditionValue: memberLevelRule.unifiedReward
                            ? 0
                            : reward.cardLevelId,
                        rewardType: 'EXPERIENCE_DOUBLE',
                        rewardValue: reward.experienceValue,
                    });
                }
            });
        }
        newParams.marketingScheduleId =
            (ruleInfo.marketingSchedule?.length && ruleInfo.marketingSchedule[0].id) || null;
    }
    return newParams;
}
