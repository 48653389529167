import React, { Component } from 'react';
import { Form, Input, Switch, Divider } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';

const FormItem = Form.Item;

const language = services.language;
const ColorPicker = tools.ColorPicker;
const UploadPicture = tools.UploadPicture;
/**
 * 页面基本信息-配置表单
 */
class root extends Component<any> {
    render() {
        const {
            form: { getFieldDecorator },
            uploadAction,
            sourceRoot,
        } = this.props;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="root-form">
                <Divider orientation="left">
                    {language.getText('components.PageDesignRoot.shareInformation')}
                </Divider>
                <div className="divider-box">
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.title')}
                    >
                        {getFieldDecorator('share.title', {})(<Input />)}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.description')}
                    >
                        {getFieldDecorator('share.description', {})(<Input />)}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.image')}
                    >
                        {getFieldDecorator(
                            'share.pic',
                            {}
                        )(<UploadPicture sourceRoot={sourceRoot} uploadAction={uploadAction} />)}
                    </FormItem>
                </div>
                <Divider orientation="left">
                    {language.getText('components.PageDesignRoot.basicInformation')}{' '}
                </Divider>
                <div className="divider-box">
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.backgroundColor')}
                    >
                        {getFieldDecorator('backgroundColor', {})(<ColorPicker />)}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.backgroundImage')}
                    >
                        {getFieldDecorator(
                            'backgroundImage',
                            {}
                        )(<UploadPicture sourceRoot={sourceRoot} uploadAction={uploadAction} />)}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.topNavigation')}
                    >
                        {getFieldDecorator('topNavigation', { valuePropName: 'checked' })(
                            <Switch />
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.miniCart')}
                    >
                        {getFieldDecorator('showMiniCart', { valuePropName: 'checked' })(
                            <Switch />
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.PageDesignRoot.backToTop')}
                    >
                        {getFieldDecorator('showBackToTop', { valuePropName: 'checked' })(
                            <Switch />
                        )}
                    </FormItem>
                </div>
            </Form>
        );
    }
}

export const Root = tools.ConfigFormDecorator(root);
