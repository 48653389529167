import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { get, defaults, map, set } from 'lodash';
import { message as AntMessage } from 'antd';
import {
    ComponentsManager,
    EntitiesManager,
    actions,
    services,
} from '@comall-backend-builder/core';
import { FormComponentProps } from 'antd/lib/form';
import { api } from '@comall-backend-builder/core/lib/services';

let Entity: any = {};
let entity: any = {};
const componentId = 'PhotoCreditReviewDisgreeForm';

export interface PhotoCreditReviewDisagreeFormProps extends FormComponentProps {
    onSubmit: (e: any, fields: any) => void;
    onInit: () => void;
    getForm: () => any;
    visible: boolean;
    id: string;
    changeVisible: () => void;
    refreshList: () => void;
    params: any;
    submitRef: {
        current: any;
    };
}
/**
 * 添加奖励
 */
class photoCreditReviewDisagreeForm extends PureComponent<PhotoCreditReviewDisagreeFormProps> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('photoCreditReviewFromEntity');
        entity = new Entity({});
    }
    state = { visible: false };

    componentDidMount() {
        const { submitRef } = this.props;
        if (submitRef) {
            submitRef.current = this.onSubmit;
        }
    }

    onSubmit = () => {
        this.props.getForm().validateFields((errors: any, entityFields: any) => {
            if (errors) {
                return;
            }

            const { params, refreshList } = this.props;

            if (entityFields) {
                const hasValidate = this.validate(entityFields);
                if (hasValidate) {
                    if (entityFields.reasonSelect !== '其他') {
                        entityFields.reason = entityFields.reasonSelect;
                    }
                    api.put(
                        {
                            ...params,
                            ...entityFields,
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                            apiPath: '/admin/v2/photo_crediting',
                        }
                    )
                        .then((res) => {
                            if (!!res) {
                                AntMessage.success(services.language.getText('shcg'));
                            } else {
                                AntMessage.error('审核失败，请联系客服处理');
                            }
                        })
                        .catch((error) => {
                            services.errorHandle(error);
                        })
                        .finally(() => {
                            refreshList();
                        });
                }
            }
        });
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = (entityFields: any) => {
        if (get(entityFields, 'reasonSelect') === '其他' && !get(entityFields, 'reason')) {
            AntMessage.warning('其他原因不能为空，且限制在20字以内');
            return false;
        }
        return true;
    };

    renderModal = () => {
        const { wrappedComponentRef, params } = this.props;
        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: componentId + params.id,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 18,
            style: { width: 800 },
            fields: [
                {
                    property: 'reasonSelect',
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                },
                {
                    property: 'reason',
                    visible: (values: any) => get(values, 'reasonSelect') === '其他',
                    controlConfig: {
                        style: {
                            width: 500,
                        },
                        placeholder: '输入的审核不通过原因，将显示给前端用户，限制在20字以内……',
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    };

    render() {
        return <div className="photo-credit-review-agree-form">{this.renderModal()}</div>;
    }
}

function mapStateToProps(state: any, props: any) {
    return state;
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onInit, onSubmitStart } = props;
    let form: any;

    const handleInit = () => {
        let {
            id: entityId,
            metainfo: { properties },
            fields: fieldsFromProps,
        } = entity;
        let fields: { [key: string]: any } = {};
        map(fieldsFromProps, (field) => {
            let { property } = field;

            // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
            let entityProperty = get(properties, property.replace('.', '.properties.'));
            if (entityProperty) {
                set(fields, property, entityProperty.defaultValue || undefined);
            } else {
                throw new Error(`Property ${property} not found in Entity ${entityId}`);
            }
        });
        _dispatch(
            actions.formInitAction(componentId, {
                type: 'CreateForm',
                entityId: entity.id,
                fields,
            })
        );
    };

    return defaults(
        {
            onSubmit,
            onInit,
            onSubmitStart,
        },
        {
            onInit: handleInit,
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}

export const PhotoCreditReviewDisagreeForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(photoCreditReviewDisagreeForm);
