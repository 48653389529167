import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PaymentRewards } from './payment-rewards';

/**
 *  世间营销，奖品配置
 */
export class PaymentRewardsPlusMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PaymentRewards {...controlInfo} />;
    }
}
