import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const ShoppingListRuleLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/shopping_list_rule';
        const res: any = await api.get(data, config);
        if (res) {
            res.orderDelayedSync = {
                allowOrderDelayedSync: res.allowOrderDelayedSync ? 'true' : 'false',
                orderDelayedAlias: res.orderDelayedAlias,
                allowChangesOrderDelayedSyncTime: res.allowChangesOrderDelayedSyncTime
                    ? 'true'
                    : 'false',
            };
            res.guideShoppingListCustomFreight = res.guideShoppingListCustomFreight + '';
            res.specialProductSettlementTips = res.specialProductSettlementTips || '';
        }
        return res;
    },
    put: async function(params, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/shopping_list_rule';
        const { orderDelayedSync, ...other } = params;
        const { allowOrderDelayedSync } = orderDelayedSync;
        const newParams = {
            ...other,
            ...(allowOrderDelayedSync === 'true' ? orderDelayedSync : { allowOrderDelayedSync }),
        };
        return await api.put(newParams, config);
    },
};
