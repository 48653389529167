import React, { Component } from 'react';
import { Button, message as AntMessage, Modal, Select } from 'antd';
import { WechatOfficialAutoReplySettingPageMsgList } from '../wechat-official-auto-reply-setting-page-msg-list';
import {
    WechatText,
    WechatImage,
    WechatMiniprogrampage,
    WechatMpnewsarticle,
} from '../../components/wechant-content';
import './index.less';
import { services } from '@comall-backend-builder/core';
const { privilege, language } = services;

export enum WechatMsgType {
    /**
     * 文本
     */
    TEXT = 'text',
    /**
     * 图片
     */
    IMAGE = 'image',
    /**
     * 小程序卡片
     */
    MINIPROGRAMPAGE = 'miniprogrampage',
    /**
     * 图文
     */
    MPNEWSARTICLE = 'mpnewsarticle',
}

export interface WechatMsg {
    /**
     * 回复消息类型
     */
    msgtype: WechatMsgType;
    /**
     * 文本传参
     */
    text?: WechatText;
    /**
     * 图片传惨
     */
    image?: WechatImage;
    /**
     * 小程序卡片传参
     */
    miniprogrampage?: WechatMiniprogrampage;
    /**
     * 图文传参
     */
    mpnewsarticle?: WechatMpnewsarticle;
}

export enum CrowdScopeEnum {
    ALL = 'ALL',
    MEMBER = 'MEMBER',
    NON_MEMBER = 'NON_MEMBER',
}

export interface AutoReplyVo {
    hide: boolean;
    crowdScope: CrowdScopeEnum | string;
    messageContent: Array<WechatMsg>;
}

interface Props {
    appId: string;
    replyData: Array<AutoReplyVo>;
    onChange: (replyData: Array<AutoReplyVo>) => void;
    disabled?: boolean;
    inputOnly?: boolean;
    // 只读 只进行页面展示 编辑需要跳转关注公众号回复
    readOnly?: boolean;
}

/**
 * 回复内容模块
 */
export class WechatOfficialAutoReplySettingPageData extends Component<Props> {
    navigationAutoReplyConfig = () => {
        if (!privilege.check('followReplyMessage', 'full')) {
            AntMessage.warning(language.getText('privilegeFollowReplyMessage'));
            return;
        }
        window.open('#/wechat-official-auto-reply');
    };

    onChangeCrowdScope = (index: number, crowdScope: string) => {
        const { replyData, onChange, readOnly } = this.props;
        if (readOnly) {
            this.navigationAutoReplyConfig();
            return;
        }
        const newValue = replyData.map((item: any, i: number) => {
            if (i === index) {
                item.crowdScope = crowdScope;
            }
            return item;
        });
        onChange(newValue);
    };

    handleAdd = () => {
        const { replyData, onChange } = this.props;
        const havaAll = replyData.find((item) => item.crowdScope === CrowdScopeEnum.ALL);
        if (havaAll) {
            AntMessage.warning(services.language.getText('setMessageWfxzxrq'));
            return false;
        }
        if (replyData.length >= 2) {
            AntMessage.warning(services.language.getText('zdznszlg'));
            return false;
        }
        const newValue: Array<AutoReplyVo> = [
            ...replyData,
            {
                hide: false,
                crowdScope: '',
                messageContent: [],
            },
        ];
        onChange(newValue);
    };

    handleDelete = (index: number) => {
        const { readOnly } = this.props;
        if (readOnly) {
            this.navigationAutoReplyConfig();
            return;
        }
        Modal.confirm({
            title: services.language.getText('sfqrsccthf'),
            onOk: () => {
                const { replyData, onChange } = this.props;
                const newValue = replyData.filter((item: any, i: number) => i !== index);
                onChange(newValue);
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
        });
    };

    changeHide = (index: number) => {
        const { replyData, onChange } = this.props;
        const newValue = replyData.map((item: any, i: number) => {
            if (i === index) {
                return {
                    ...item,
                    hide: !item.hide,
                };
            }
            return item;
        });
        onChange(newValue);
    };

    onChangeMessageContentList = (index: number, messageContent: Array<WechatMsg>) => {
        const { replyData, onChange } = this.props;
        const newValue = replyData.map((item: any, i: number) => {
            if (i === index) {
                return {
                    ...item,
                    messageContent: messageContent,
                };
            }
            return item;
        });
        onChange(newValue);
    };

    private get replyStyle() {
        const { inputOnly } = this.props;
        if (inputOnly) {
            return { padding: 20 };
        }
        return {};
    }

    private get replyAddStyle() {
        const { inputOnly } = this.props;
        if (inputOnly) {
            return { paddingLeft: 20 };
        }
        return {};
    }

    render() {
        const { replyData, inputOnly, disabled, appId, readOnly } = this.props;
        const zhanImage = require('../images/icon-zhan.png');
        const guanImage = require('../images/icon-guan.png');
        const deleteImage = require('../images/icon-delete.png');
        return (
            <div className="wechat-official-auto-reply-setting-page-data">
                {inputOnly || (
                    <div>
                        <span className="red">*&nbsp;</span>
                        {services.language.getText('hfnr')}：
                    </div>
                )}
                <div className="replys-info">
                    <div className="replys">
                        {replyData &&
                            replyData.map((reply, index: number) => {
                                return (
                                    <div className="reply" style={this.replyStyle} key={index}>
                                        <div className="content">
                                            <div>
                                                <span>{services.language.getText('hfrq')}：</span>
                                                <Select
                                                    style={{
                                                        width: '200px',
                                                    }}
                                                    placeholder={services.language.getText(
                                                        'selectPlease'
                                                    )}
                                                    className="link-type"
                                                    value={reply.crowdScope}
                                                    onChange={(value: string) =>
                                                        this.onChangeCrowdScope(index, value)
                                                    }
                                                    disabled={disabled}
                                                >
                                                    <Select.Option value="">
                                                        {services.language.getText('selectPlease')}
                                                    </Select.Option>
                                                    <Select.Option value={CrowdScopeEnum.ALL}>
                                                        {services.language.getText('qbfs')}
                                                    </Select.Option>
                                                    <Select.Option value={CrowdScopeEnum.MEMBER}>
                                                        {services.language.getText('hyfs')}
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={CrowdScopeEnum.NON_MEMBER}
                                                    >
                                                        {services.language.getText('fhyfs')}
                                                    </Select.Option>
                                                </Select>
                                            </div>
                                            <WechatOfficialAutoReplySettingPageMsgList
                                                appId={appId}
                                                index={index}
                                                hide={reply.hide}
                                                msgList={reply.messageContent}
                                                onChange={this.onChangeMessageContentList}
                                                disabled={disabled}
                                                readOnly={readOnly}
                                            />
                                        </div>
                                        <div className="buttons" hidden={disabled}>
                                            <div
                                                className="buttons-button"
                                                style={{ padding: 0 }}
                                                onClick={this.changeHide.bind(this, index)}
                                            >
                                                <img
                                                    width={14}
                                                    height={14}
                                                    alt=""
                                                    src={reply.hide ? guanImage : zhanImage}
                                                />
                                                {reply.hide
                                                    ? services.language.getText('launch')
                                                    : services.language.getText('putAway')}
                                            </div>
                                            <div
                                                className="buttons-button"
                                                onClick={this.handleDelete.bind(this, index)}
                                            >
                                                <img
                                                    width={16}
                                                    height={16}
                                                    alt=""
                                                    src={deleteImage}
                                                />
                                                {services.language.getText('common.delete')}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div
                        className="replys-add"
                        style={this.replyAddStyle}
                        hidden={disabled || readOnly}
                    >
                        <Button type="default" className="type-button" onClick={this.handleAdd}>
                            {services.language.getText('tjrqhfnr')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
