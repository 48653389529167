import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { CardCollectionReward } from '../../../components';

export class ArrayCardCollectRewardMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CardCollectionReward {...controlInfo} />;
    }
}
