import React from 'react';
import { Avatar } from 'antd';
import { modes } from '@comall-backend-builder/types';
export class ImageAvatarMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getNotAvailableDisplayComponent(displayInfo: any): JSX.Element {
        return <Avatar src="./images/avatar/avatar_1.png" />;
    }
    getAvailableDisplayComponent(value: string, displayInfo: any): JSX.Element {
        return <Avatar src={value} />;
    }
}
