import React, { Component } from 'react';
import { DndProvider as DragDropContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UploadPreviewImage from './drag-upload-preview-image';
import { Modal } from 'antd';
import update from 'immutability-helper';
import { remove, cloneDeep } from 'lodash';
import './index.less';

interface DragUploadPreviewState {
    previewVisible: boolean;
    previewImage: string;
}

export default class DragUploadPreview extends Component<any, DragUploadPreviewState> {
    constructor(props: any) {
        super(props);
        this.state = { previewVisible: false, previewImage: '' };
    }
    // 拖拽后更新数据
    moveRow = (dragIndex: number, hoverIndex: number) => {
        const { value, onChange } = this.props;
        if (!!value) {
            const dragRow = value[dragIndex];
            const newDataSource = update(value, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });
            if (onChange) {
                onChange(newDataSource);
            }
        }
    };

    handlePreview = (id: string, path: string) => {
        this.setState({
            previewVisible: true,
            previewImage: path,
        });
    };

    handleDelete = (id: string) => {
        const { value, onChange } = this.props;
        if (value && value.length > 0) {
            const newValue = cloneDeep(value);
            remove(newValue, (item: any) => item.id === id);
            if (onChange) {
                onChange(newValue);
            }
        }
    };

    handleCancel = () => {
        this.setState({
            previewVisible: false,
        });
    };
    render() {
        const { value = [], children, disabled } = this.props;
        const { previewVisible, previewImage } = this.state;
        return (
            <div className="drag-upload-preview">
                <DragDropContext backend={HTML5Backend}>
                    {value.map((item: any, index: number) => {
                        return (
                            <UploadPreviewImage
                                image={item.path}
                                id={item.id}
                                index={index}
                                moveRow={this.moveRow}
                                handlePreview={this.handlePreview}
                                handleDelete={this.handleDelete}
                                disabled={disabled}
                            />
                        );
                    })}
                    {children ? children : null}
                </DragDropContext>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}
