import React, { PureComponent } from 'react';
import { Table, InputNumber, Button, message, Popconfirm } from 'antd';
import { cloneDeep, remove, find, isNaN, isNull } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface KeywordsProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    disabled?: boolean;
}

/**
 * 商品展示和内容编辑
 */
class KeywordsProductSelectedDisplay extends PureComponent<
    KeywordsProductSelectedDisplayProps,
    {}
> {
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable, disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 120,
                },
                {
                    title: services.language.getText('goodCode'),
                    dataIndex: 'code',
                    key: 'code',
                    width: 120,
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 120,
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 120,
                },
                {
                    title: services.language.getText('sequence'),
                    key: 'sequence',
                    dataIndex: 'sequence',
                    width: 120,
                    align: 'center' as const,
                    sorter: (a: any, b: any) => a.sequence - b.sequence,
                    render: (v: any, r: any) => {
                        return modifiable ? (
                            <InputNumber
                                value={v}
                                min={1}
                                step={1}
                                precision={0}
                                max={99999}
                                onChange={(value: any) => this.changeSequence(value, r)}
                            ></InputNumber>
                        ) : (
                            v
                        );
                    },
                },
            ],
            scroll: {
                x: 1900,
                y: 500,
            },
        };
        if (modifiable && !disabled) {
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
                fixed: true,
            };
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => (
                    <Button type={'link'} disabled={disabled} onClick={this.onRemove(row.id)}>
                        {services.language.getText('common.delete')}
                    </Button>
                ),
            });
        }
        return config;
    };

    renderFooter = () => {
        return (
            <div className="product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    changeSequence = (value: any, row: any) => {
        if (isNaN(value) || isNull(value) || typeof value === 'string') {
            message.warning(services.language.getText('inputNumber'));
            value = 1;
        }
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => row.id === i.id);
        if (item) {
            item.sequence = value;
            onChange(result);
        }
    };

    render() {
        const { data, modifiable, disabled } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && !disabled && this.renderFooter()}
            </>
        );
    }
}

export { KeywordsProductSelectedDisplay };
