import React, { PureComponent } from 'react';
import { Modal, Icon, Avatar, Button, Upload } from 'antd';
import classnames from 'classnames';
import { imgMap } from './img';
import './index.less';
interface AvatarModalProps {
    visible: boolean;
    avatar: string;
    onCancel: () => void;
    onOk: (avatar: string) => void;
}
export class AvatarModal extends PureComponent<AvatarModalProps, any> {
    state = { uploadLoading: false };
    uplodaChange = (info: any) => {
        const { onOk, onCancel } = this.props;
        if (info.file.status === 'uploading') {
            this.setState({ uploadLoading: true });
        }
        if (info.file.status === 'done') {
            const pic = info.file.response;
            this.setState({
                uploadLoading: false,
            });
            onOk(pic);
            onCancel();
        }
    };
    selectDefault = (item: string) => {
        const { onOk, onCancel } = this.props;
        onOk(item);
        onCancel();
    };
    render() {
        const uploadProps = {
            name: 'fileName',
            showUploadList: false,
            action: 'http://localhost:8080/images',
            onChange: this.uplodaChange,
        };
        const { avatar, onCancel, visible } = this.props;
        return (
            <Modal visible={visible} className="avatar-modal" onCancel={onCancel} footer={null}>
                <div className="avatar-current">
                    <div className="title">当前头像</div>
                    <div>
                        <Avatar size={64} src={avatar} />
                        <Upload {...uploadProps}>
                            <Button type="link">
                                <Icon type={'upload'} />
                                更换头像
                            </Button>
                        </Upload>
                    </div>
                </div>
                <div className="avatar-default">
                    <div className="title">默认头像</div>
                    <div>
                        {imgMap.map((item: string) => {
                            return (
                                <div
                                    onClick={this.selectDefault.bind(this, item)}
                                    key={item}
                                    className={classnames('avatar-default-img')}
                                >
                                    <Avatar size={64} src={item} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        );
    }
}
