import { builder } from '@comall-backend-builder/core';
import { config as entities } from './entites';
import { config as PageTemplateConfig } from './page-template-manage';
import { config as PageManageConfig } from './page-manage';
import { config as TopicManageConfig } from './topic-manage';
import { config as MerchantPageConfig } from './merchant-page-manage';
import { config as PlatformPageManageConfig } from './platform-page-manage';
import '../design';

//新增一套新C端可视化页面
import { config as designEntities } from './decorate/entites';
import { config as PlatformPageConfig } from './decorate/platform-page';
import { config as StorePageConfig } from './decorate/store-page';
import { config as MerchantPageNewConfig } from './decorate/merchant-page';
import { config as TopicPageConfig } from './decorate/topic-page';
import { config as MemberCenterPageConfig } from './decorate/member-center-page';
import { config as NewPopUpAdsConfig } from './decorate/new-pop-up-ads';
import { config as OpenScreenAdsConfig } from './decorate/open-screen-ads';
import { config as FloatingActionButtonConfig } from './decorate/floating-action-button';
import { config as ThemeStyleConfig } from './decorate/theme-style';
import { config as CustomStoreTabbarPageConfig } from './decorate/custom-store-tabbar';
import { config as NewPageTemplateConfig } from './decorate/page-template-manage';
import { config as PayResultPageConfig } from './decorate/pay-result-page';
import { config as ElectronicBill } from './decorate/electronic-bill';
import { config as PlatformSearchPageConfig } from './decorate/platform-search-page';
import { config as StoreSearchPageConfig } from './decorate/store-search-page';
import { config as CarparkPageNewConfig } from './decorate/carpark-page';

builder.registerConfig(entities);

builder.registerConfig(PageTemplateConfig);
builder.registerConfig(PageManageConfig);
builder.registerConfig(TopicManageConfig);
builder.registerConfig(MerchantPageConfig);
builder.registerConfig(PlatformPageManageConfig);

//新增一套新C端可视化页面
builder.registerConfig(designEntities);
builder.registerConfig(PlatformPageConfig);
builder.registerConfig(StorePageConfig);
builder.registerConfig(MerchantPageNewConfig);
builder.registerConfig(TopicPageConfig);
builder.registerConfig(MemberCenterPageConfig);
builder.registerConfig(NewPopUpAdsConfig);
builder.registerConfig(OpenScreenAdsConfig);
builder.registerConfig(ThemeStyleConfig);
builder.registerConfig(CustomStoreTabbarPageConfig);
builder.registerConfig(NewPageTemplateConfig);
builder.registerConfig(PayResultPageConfig);
builder.registerConfig(ElectronicBill);
builder.registerConfig(FloatingActionButtonConfig);
builder.registerConfig(PlatformSearchPageConfig);
builder.registerConfig(StoreSearchPageConfig);
builder.registerConfig(CarparkPageNewConfig);
