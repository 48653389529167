import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { get, defaults, uniq } from 'lodash';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Modal, message as AntMessage } from 'antd';
const api = services.api;
export interface InternalPurchaseGoodsAddState {
    visible: boolean;
}

/**
 * 添加/编辑商品
 */
class addRelationMobileModal extends PureComponent<any, any> {
    state: InternalPurchaseGoodsAddState = {
        visible: false,
    };

    onSubmit = (event: any, fields: any) => {
        event.stopPropagation();
        const { getForm, params } = this.props;

        const form = getForm();
        form.validateFields(['mobiles'], { force: true }).then((entityFields: any) => {
            if (entityFields) {
                const hasValidate = this.validate(entityFields);
                if (hasValidate) {
                    let addParams: any = {};
                    addParams = entityFields;
                    addParams.enterpriseId = params.enterpriseId;
                    addParams.mobiles = uniq(addParams.mobiles.split(',')).join(',');
                    api.post(addParams, {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/enterprises/relation_mobile',
                    }).then(
                        () => {
                            AntMessage.success(services.language.getText('common.saveSuccess'));
                            this.toggleModal();
                        },
                        (error) => {
                            services.errorHandle(error);
                        }
                    );
                }
            }
        });

        return;
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = (entityFields: any) => {
        const mobiles: any = get(entityFields, 'mobiles', '');
        const regular = /^1\d{10}$/;
        const mobileArr = mobiles.split(',');
        if (mobileArr && mobileArr.length) {
            for (const mobile of mobileArr) {
                if (mobile && !regular.test(mobile)) {
                    AntMessage.warning(services.language.getText('rightMobileInput'));
                    return false;
                }
            }
        }
        return true;
    };

    renderModal = () => {
        const { wrappedComponentRef, entity } = this.props;

        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: 'addRelationMobileModal',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 14,
            style: { width: 520 },
            fields: [{ property: 'mobiles' }],
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: '确定',
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.toggleModal,
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmit: this.onSubmit,
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    };

    toggleModal = () => {
        const { onClose } = this.props;
        onClose();
    };

    render() {
        const { visible } = this.props;
        const modalProps = {
            width: 600,
            title: services.language.getText('tjsjh'),
            visible: visible,
            footer: null,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                {visible ? <Modal {...modalProps}>{this.renderModal()}</Modal> : null}
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const AddRelationMobileModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(addRelationMobileModal);
