import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';

const api = services.api;
export const ContentNotesLoader: Loader = {
    get: async function(params: any, config: ApiRequestConfig) {
        if (!params.id) {
            // 时间段查询
            const { publishTime, editTime, topTime } = params;
            if (publishTime) {
                params.publishStartTime = publishTime.start;
                params.publishEndTime = publishTime.end;
            }
            delete params.publishTime;
            if (editTime) {
                params.editStartTime = editTime.start;
                params.editEndTime = editTime.end;
            }
            delete params.editTime;
            if (topTime) {
                params.topStartTime = topTime.start;
                params.topEndTime = topTime.end;
            }
            delete params.topTime;
        }

        const data: any = await api.get(params, config);

        if (params.id) {
            // 笔记作者
            data.authorMobile =
                data.memberInfo && data.memberInfo.mobile ? data.memberInfo.mobile : '-';

            // 作者昵称
            data.authorNickName =
                data.memberInfo && data.memberInfo.nickName ? data.memberInfo.nickName : '-';

            // 作者ID
            data.authorId =
                data.memberInfo && data.memberInfo.memberId ? data.memberInfo.memberId : '-';

            // 笔记内容，需要渲染为组件需要的数据结构
            data.noteContent = {
                content: {
                    type: data.contentType,
                    pictures: data.pictures,
                    video: data.video,
                    title: data.title ? data.title : '-',
                    content: data.content ? data.content : '-',
                },
                type: 'NOTE',
            };

            // 自动审核时间
            data.autoAuditTime =
                data.auditInfo && data.auditInfo.autoAuditTime ? data.auditInfo.autoAuditTime : '-';

            // 人工审核时间
            data.manualAuditTime =
                data.auditInfo && data.auditInfo.manualAuditTime
                    ? data.auditInfo.manualAuditTime
                    : '-';

            // 审核备注
            data.manualAuditRemark =
                data.auditInfo && data.auditInfo.manualAuditRemark
                    ? data.auditInfo.manualAuditRemark
                    : '-';

            // 审核方式
            data.auditTypeEnum = get(data, 'auditTypeEnum') || '-';
            //编辑时间
            data.editTime = get(data, 'editTime') || '-';
            //删除时间
            data.deleteTime = get(data, 'deleteTime') || '-';
            // 客服删除备注
            data.deleteMark = get(data, 'deleteMark') || '-';
        } else {
            data.result.forEach((item: any) => {
                // 笔记作者
                item.authorMobile =
                    item.memberInfo && item.memberInfo.mobile ? item.memberInfo.mobile : '-';

                // 作者昵称
                item.authorNickName =
                    item.memberInfo && item.memberInfo.nickName ? item.memberInfo.nickName : '-';

                if (item.topics && item.topics.length) {
                    const topicNames: any = [];
                    const plateNames: any = [];
                    item.topics.forEach((topic: any, index: number) => {
                        topicNames.push(topic.name);
                        if (topic.plateNames && topic.plateNames.length) {
                            topic.plateNames.forEach((plate: any, pIndex: number) => {
                                plateNames.push(plate);
                            });
                        }
                    });
                    const topicNamesStr = topicNames.join('、');
                    const plateNamesStr = plateNames.join('、');
                    item.topicNames = topicNamesStr;
                    item.plateNames = plateNamesStr;
                }
                if (item.plateName) {
                    item.plateNames = item.plateName;
                }

                // 作者ID
                item.authorId =
                    item.memberInfo && item.memberInfo.memberId ? item.memberInfo.memberId : '-';

                // 列表中仅 审核通过 可以删除笔记
                item.showDeleteBtn = item.auditStatus === 'SUCCESS';
            });
        }

        return data;
    },
};
