import React from 'react';
import { Select, InputNumber, DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import get from 'lodash/get';
import moment from 'moment';
import { ConditionType } from '../index';
import { MaotaiPresaleConditionsProps } from '../../index';
import { services } from '@comall-backend-builder/core';

const dateFormat = 'YYYY-MM-DD';

interface CommonFormProps extends MaotaiPresaleConditionsProps {
    conditionType: ConditionType;
    frontText: string;
    middleText: string;
    endText: string;
    index: number;
}

export default class CommonForm extends React.PureComponent<CommonFormProps> {
    conditionType = ConditionType.REGISTRATION_TIME;

    onSelect = (val: number | undefined) => {
        const { onChange, value, conditionType, index } = this.props;
        let list;
        if (
            !value.find(
                (item, itemIndex) => item.conditionType === conditionType && index === itemIndex
            )
        ) {
            list = [...value, { conditionType, conditionValue: { timeType: val } }];
        } else {
            list = value.map((item, itemIndex) => {
                if (conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { ...item.conditionValue, timeType: val } };
                }
                return item;
            });
        }

        onChange(list);
    };

    inputChange = (val: number | undefined) => {
        const { onChange, value, conditionType, index } = this.props;

        let list;
        if (
            !value.find(
                (item, itemIndex) => item.conditionType === conditionType && index === itemIndex
            )
        ) {
            list = [...value, { conditionType, conditionValue: { moreThan: val } }];
        } else {
            list = value.map((item, itemIndex) => {
                if (conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { ...item.conditionValue, moreThan: val } };
                }
                return item;
            });
        }
        onChange(list);
    };

    dateChange = (val: any) => {
        const { onChange, value, conditionType, index } = this.props;
        const dateRange =
            val && val.length
                ? [
                      moment(val[0] || undefined).format(dateFormat),
                      moment(val[1] || undefined).format(dateFormat),
                  ]
                : undefined;

        let list;
        if (
            !value.find(
                (item, itemIndex) => item.conditionType === conditionType && index === itemIndex
            )
        ) {
            list = [...value, { conditionType: conditionType, conditionValue: { dateRange } }];
        } else {
            list = value.map((item, itemIndex) => {
                if (conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { ...item.conditionValue, dateRange } };
                }
                return item;
            });
        }
        onChange(list);
    };
    options = [
        { id: 'CURRENT_MOUTH', name: '本月' },
        { id: 'CUSTOM', name: '自定义时间' },
    ];
    render() {
        const { value, frontText, middleText, conditionType, endText } = this.props;
        const formData = value.find((i) => i.conditionType === conditionType);
        const timeType = get(formData, 'conditionValue.timeType', []);
        const moreThan = get(formData, 'conditionValue.moreThan', undefined);
        const dateRange = get(formData, 'conditionValue.dateRange', undefined);

        const dateValue: RangePickerValue | undefined =
            dateRange && dateRange.length
                ? [moment(dateRange[0]), moment(dateRange[1])]
                : undefined;

        return (
            <div>
                <span>{frontText}</span>&nbsp; &nbsp;
                <Select
                    onChange={this.onSelect}
                    placeholder={services.language.getText('selectPlease')}
                    style={{ width: 200 }}
                    value={timeType}
                >
                    {this.options.map((o) => (
                        <Select.Option key={o.id} value={o.id}>
                            {o.name}
                        </Select.Option>
                    ))}
                </Select>
                &nbsp; &nbsp;
                {timeType === 'CUSTOM' && (
                    <>
                        <DatePicker.RangePicker
                            format={dateFormat}
                            onChange={this.dateChange}
                            value={dateValue}
                        ></DatePicker.RangePicker>
                    </>
                )}
                &nbsp; &nbsp;
                <span>{middleText}</span>
                &nbsp; &nbsp;
                <InputNumber
                    onChange={this.inputChange}
                    max={999999999}
                    min={1}
                    precision={0}
                    value={moreThan}
                ></InputNumber>
                &nbsp; &nbsp;
                <span>{endText}</span>
            </div>
        );
    }
}
