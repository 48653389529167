import React, { PureComponent, createElement } from 'react';
import { Modal as AntModal, Tag } from 'antd';
import { remove, forEach } from 'lodash';
import { ComponentsManager, actions, EntitiesManager, builder } from '@comall-backend-builder/core';
import './index.less';
import { Shop } from '../../../../containers/select-shop';
import { language } from '@comall-backend-builder/core/lib/services';

const TableComponentId = 'MerchantPageSelector';
let Entity: any = {};
let entity: any = {};

export class MerchantSelector extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('MerchantPageSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    selectedRows = new Array<Shop>();
    selectedRowsCurrent = new Array<Shop>();
    state = { visible: false, selectedRowKeys: [] };

    onDelete = (id: string) => {
        remove(this.selectedRows, (i) => {
            return i.id === id;
        });
        let selectIdRows: Array<string> = [];
        for (let item of this.selectedRows) {
            selectIdRows.push(item.id);
        }
        this.setState({ selectedRowKeys: [] });
        this.selectedRowsCurrent = [];
        this.props.onChange(this.selectedRowsCurrent);
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, selectIdRows));
    };

    componentWillReceiveProps(nextProps: any) {
        // 页面初始化
        if (
            JSON.stringify(this.props.row) === '{}' &&
            JSON.stringify(nextProps.row) !== '{}' &&
            nextProps.pageType === 'merchantSelectEdit'
        ) {
            this.selectedRowsCurrent = [
                {
                    id: nextProps.row.id,
                    merchantId: nextProps.row.merchantId,
                    merchantName: nextProps.row.groupSubsiteName,
                    select: true,
                    subSiteId: nextProps.row.subsites[0]['id'],
                    subSiteName: nextProps.row.subsites[0]['name'],
                    merchantType: '',
                    merchantStatus: '',
                },
            ];
            this.setState({ selectedRowKeys: [`${nextProps.row.merchantId}`] });
            this.dispatch(
                actions.tableRowSelectionChangeAction(TableComponentId, [
                    `${nextProps.row.merchantId}`,
                ])
            );
            this.props.onChange(this.selectedRowsCurrent);
        }
    }

    onTableRowSelect = (record: Shop, selected: boolean) => {
        if (selected) {
            this.selectedRows = [record];
        }
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });

            onChange(this.selectedRows);
            this.selectedRowsCurrent = this.selectedRows;
            this.toggleModal();
        }
    };
    initEntityFilters = () => {
        entity.filtersChange({
            merchantName: undefined,
            sorter: undefined,
        });
        entity.pageChange({ page: 1 });
        const store = builder.getStore();
        store.dispatch(actions.filtersChangeAction(entity, {}));
        store.dispatch(
            actions.tableSortChangeAction(TableComponentId, { field: undefined, order: undefined })
        );
    };

    getConfig = () => {
        const { params } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    onReset: () => {
                        this.initEntityFilters();
                    },
                    fields: [
                        {
                            property: 'merchantName',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    key: Math.random(),
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowKey: 'id',
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: this.state.selectedRowKeys,
                        onSelect: (record: Shop, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onChange: (selectedRowKeys: any, selectedRows: any) => {
                            this.setState({ selectedRowKeys });
                        },
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'subSiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'code',
                            sorter: true,
                        },
                        {
                            property: 'merchantType',
                        },
                        {
                            property: 'merchantStatus',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        //判断filters 当前是否有排序字段 没有则更新页面显示（解决同一组件多场景使用下！.filters.order页面排序却高亮的问题）
        if (entity && (!entity.filters || (entity.filters && !entity.filters.order))) {
            const store = builder.getStore();
            store.dispatch(
                actions.tableSortChangeAction(TableComponentId, {
                    field: undefined,
                    order: undefined,
                })
            );
        }
        this.setState({ visible: !visible });
    };

    onCancel = () => {
        if (this.selectedRowsCurrent.length > 0) {
            this.setState({ selectedRowKeys: [this.selectedRowsCurrent[0]['merchantId']] });
            this.dispatch(
                actions.tableRowSelectionChangeAction(TableComponentId, [
                    this.selectedRowsCurrent[0]['merchantId'],
                ])
            );
        }

        this.toggleModal();
    };

    render() {
        const { visible } = this.state;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: language.getText('selectSubsites'),
            visible,
            okText: language.getText('common.ok'),
            cancelText: language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.onCancel,
        };
        return (
            <div className="merchant-selector">
                <div className="btn" onClick={this.toggleModal}>
                    {this.selectedRowsCurrent.map((item) => {
                        return (
                            <Tag key={item.id} closable onClose={() => this.onDelete(item.id)}>
                                {item.merchantName}
                            </Tag>
                        );
                    })}
                </div>
                <AntModal {...modalProps}>{modalContent}</AntModal>
            </div>
        );
    }
}
