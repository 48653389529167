import React, { PureComponent } from 'react';
import { map, cloneDeep, remove, uniqBy, isEmpty, get } from 'lodash';
import { Tag, Popconfirm, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ProductSelector } from './product-selector';
import { language } from '@comall-backend-builder/core/lib/services';

export class ShowActivitys extends PureComponent<any> {
    removeProduct = (item: any) => {
        const { onChange, value } = this.props;
        let newValue = cloneDeep(value);
        remove(newValue, { id: item.id });
        onChange(newValue);
    };

    clearAll = () => {
        const { onChange } = this.props;
        onChange([]);
    };

    changeTarget = (selected: any) => {
        const { onChange, value } = this.props;
        const data = map(selected, (item) => {
            /**
             * 过滤掉不需要的接口数据
             */
            return {
                ...item.mallActivity,
                ...item.mallActivityState,
            };
        });

        onChange(uniqBy(value.concat(data), 'id'));
    };

    renderProducts(products: any) {
        const isDragDropDisabled = products.length === 1;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable isDropDisabled={isDragDropDisabled} droppableId="product-list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {map(products, (item, index) =>
                                this.renderProduct(item, index, isDragDropDisabled)
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    renderProduct = (item: any, index: any, isDragDisabled: any) => {
        const removeProduct = this.removeProduct;
        return (
            <Draggable
                isDragDisabled={isDragDisabled}
                draggableId={'product-' + index}
                key={index}
                index={index}
            >
                {(provided: any) => {
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Tag
                                key={item.id}
                                closable
                                onClose={() => removeProduct(item)}
                                className="product-i"
                            >
                                {item.name}
                            </Tag>
                        </div>
                    );
                }}
            </Draggable>
        );
    };
    onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const { value, onChange } = this.props;
        let newValue = cloneDeep(value);
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = newValue.splice(startIndex, 1);
        newValue.splice(endIndex, 0, removed);

        onChange(cloneDeep(newValue));
    };
    render() {
        const { value, selector = {} } = this.props;
        const hasProducts = !isEmpty(value);
        const productsProps = {
            value: value,
            onChange: this.changeTarget,
            type: 'Multi' as const,
        };
        return (
            <div className="products-show">
                {this.renderProducts(value)}
                {hasProducts && (
                    <Popconfirm
                        title="确定清空活动吗?"
                        onConfirm={this.clearAll}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="show-action" type={'link'}>
                            清空活动
                        </Button>
                    </Popconfirm>
                )}

                <ProductSelector
                    modalConfig={get(selector, 'multiActivity.modalConfig', {})}
                    contentConfig={get(selector, 'multiActivity.contentConfig', {})}
                    {...productsProps}
                />
            </div>
        );
    }
}
