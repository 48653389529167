import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export let config: Config = {
    entities: {
        SubsiteMemberCardLevelEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: '/loader/admin/subsiteMemberCardLevel',
            properties: {
                organizationType: {
                    type: 'string',
                },
                organizationId: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<kdjmbmc>>',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<qsrkdjmbmc>>',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrkdjmbmcbcg20z>>',
                        maxLength: 20,
                        style: {
                            width: 450,
                        },
                    },
                },
                cardLevelItems: {
                    type: 'array.cardLevelItems',
                    displayName: '<<djsz>>',
                    rules: [{ required: true }],
                },
                bgPicture: {
                    type: 'array.imageObjectArrayForTopPic',
                    displayName: '<<bjt>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        extra: {
                            tip: '<<bgPictureExtra>>',
                            img: './images/avatar/card-level-bg.jpg',
                            btnText: '<<example>>',
                            triggerType: 'hover',
                            style: {
                                width: 500,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        SubsiteMemberCardLevelView: {
            component: 'Viewport',
            entity: 'SubsiteMemberCardLevelEntity',
        },
        SubsiteMemberCardLevelManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SubsiteMemberCardLevelPage',
                },
            ],
        },
        SubsiteMemberCardLevelManageEditPage: {
            component: 'FlexLayout',
            entity: 'SubsiteMemberCardLevelEntity',
            direction: 'vertical',
            items: [{ component: 'SubsiteMemberCardLevelManageEditForm' }],
        },
        SubsiteMemberCardLevelManageEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteMemberCardLevelEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'cardLevelItems',
                    controlConfig: {
                        isEdit: true,
                        isSubsite: true,
                    },
                    extra: '<<xtdjbbtdkmbyyrkm>>',
                },
                {
                    property: 'bgPicture',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const cardLevelItems = get(entity, 'cardLevelItems', []);
                for (let index = 0; index < cardLevelItems.length; index++) {
                    const item = cardLevelItems[index];
                    if (!item.levelName) {
                        AntMessage.warning(language.getText('qsrdjmc'));
                        return false;
                    }
                    // const regex = /^[0-9a-zA-Z\u4e00-\u9fa5]{1,20}$/;
                    // if (!regex.test(item.levelName)) {
                    //     AntMessage.warning('等级名称仅支持输入英文+数字+中文汉字');
                    //     return false;
                    // }
                    if (!item.styleIconUrl) {
                        AntMessage.warning(language.getText('djtbbnwk'));
                        return false;
                    }
                    if (!item.styleBackgroundPictureUrl) {
                        AntMessage.warning(language.getText('djkmbnwk'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subsite-member-card-level-manage',
                    breadcrumbName: '<<storeCardLevel>>',
                    component: 'SubsiteMemberCardLevelView',
                    privilege: {
                        path: 'subsiteMemberCardLevelManage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'SubsiteMemberCardLevelManageEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'SubsiteMemberCardLevelManagePage' },
                    ],
                },
            ],
        },
    ],
};
