import React, { PureComponent } from 'react';
import { Form, Radio } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { CardNavItems } from './card-nav-items';
import './index.less';

const FormItem = Form.Item;

const ColorPicker = tools.ColorPicker;
/**
 * 卡片导航组件-配置表单
 */
class cardNav extends PureComponent<any> {
    render() {
        const { form, linkTypes, uploadAction, selector, sourceRoot } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="card-nav-from">
                <FormItem {...formItemLayout} label={'导航数量'}>
                    {getFieldDecorator('rowCount', {
                        initialValue: 3,
                    })(
                        <Radio.Group>
                            <Radio value={3}>一行三个</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={'导航颜色'}>
                    {getFieldDecorator('textColor')(<ColorPicker />)}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('navs', {
                        initialValue: [],
                    })(
                        <CardNavItems
                            linkTypes={linkTypes}
                            maxCount={getFieldValue('rowCount')}
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                            selector={selector}
                        />
                    )}
                </FormItem>
                <p className="entry-tip">
                    最多添加 {getFieldValue('rowCount')} 个卡片导航项，可拖拽调整顺序
                </p>
            </Form>
        );
    }
}

export const CardNav = tools.ConfigFormDecorator(cardNav);
