import React, { Component } from 'react';
import { EditableColumnProps, ScrmLevelUpgradeDowngradeRuleViewVo } from '..';
import { cloneDeep, get, set } from 'lodash';
import { TypesManager } from '@comall-backend-builder/core';

interface EditableCellProps extends EditableColumnProps {
    record: ScrmLevelUpgradeDowngradeRuleViewVo;
    onChange(record: ScrmLevelUpgradeDowngradeRuleViewVo): void;
}
export default class EditableCell extends Component<EditableCellProps> {
    private onChange = (value: any) => {
        const { dataIndex, record, onChange } = this.props;
        const newRecord = cloneDeep(record);
        set(newRecord, dataIndex!, value);
        onChange(newRecord);
    };
    private renderCell = () => {
        const { record, type, dataIndex, options, config } = this.props;
        return TypesManager.get(type!).getControlComponent({
            ...config,
            disabled: config?.getDisabled?.(record),
            name: dataIndex,
            value: get(record, dataIndex!),
            options,
            row: record,
            onChange: this.onChange,
        });
    };
    render() {
        const { editable, children } = this.props;
        return <td>{editable ? this.renderCell() : children}</td>;
    }
}
