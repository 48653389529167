import React, { PureComponent } from 'react';
import { PickingLists, PickingListCategoryItem } from '../../../services/order-print';
import { forEach } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface PickingListCategoryItemGoods extends PickingListCategoryItem {
    /**
     * 分类
     */
    name: string;
}

const formatQuantity = (quantity: number) => {
    const quantities = quantity.toString().split('.');
    if (quantities.length < 2) {
        return quantity;
    } else {
        return quantities[0] + '.' + quantities[1].slice(0, 3);
    }
};

const border = { border: '1px solid #999' };

const getPickQuantity = (item: PickingListCategoryItemGoods) => {
    if (!item.goods) {
        return '';
    }
    const quantity =
        item.goods.displayUnit === 'KG'
            ? formatQuantity(Number(item.goods.needPickQuantity) / 1000)
            : item.goods.needPickQuantity;
    const unit =
        item.goods.displayUnit === 'PIECE' ? '件' : item.goods.displayUnit === 'KG' ? 'kg' : 'g';
    return quantity + '' + unit;
};

export class PickintOrderTemplate extends PureComponent<
    {
        order: PickingLists;
    },
    any
> {
    render() {
        const order = this.props.order || {};
        const goods: PickingListCategoryItemGoods[] = [];
        forEach(order.categories, (category) => {
            forEach(category.items, (item) => {
                goods.push({
                    ...item,
                    name: category.name,
                });
            });
        });
        const firstPageGoods = goods.slice(0, 18);
        const pages = [];
        if (goods.length > 18) {
            for (let i = 18; i < goods.length; i += 24) {
                pages.push(goods.slice(i, i + 24));
            }
        }
        return (
            <div className="print-paper">
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '27px',
                        fontSize: '36px',
                        lineHeight: '36px',
                    }}
                >
                    {services.language.getText('jhd')}
                </div>
                <div
                    style={{
                        width: '260px',
                        height: '102px',
                        marginTop: '-63px',
                        float: 'right',
                    }}
                >
                    <img
                        src={order.pickingListNoCode}
                        style={{
                            width: '260px',
                            height: '90px',
                        }}
                        alt=""
                    />
                    <div
                        style={{
                            fontSize: '12px',
                            paddingLeft: '12px',
                            marginTop: '-18px',
                        }}
                    >
                        <span>
                            {services.language.getText('jhdh')}：{order.pickingListNo}
                        </span>
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div
                    style={{
                        fontSize: '14px',
                        marginBottom: '20px',
                    }}
                >
                    {services.language.getText('jhdcjsj')}：{order.createTime}
                </div>

                <div style={{ marginTop: '10px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bolder' }}>
                        {services.language.getText('productInformation')}
                    </div>
                    <table
                        cellSpacing={0}
                        cellPadding={5}
                        style={{
                            border: '1px solid #999',
                            borderCollapse: 'collapse',
                            textAlign: 'center',
                            fontSize: '12px',
                            width: '100%',
                        }}
                    >
                        <tbody>
                            <tr>
                                <th style={{ width: '5%', border: '1px solid #999' }}>
                                    {services.language.getText('xh_1')}
                                </th>
                                <th style={border}>{services.language.getText('productCode')}</th>
                                <th style={border}>{services.language.getText('goodBarCode')}</th>
                                <th style={border}>{services.language.getText('pl_2')}</th>
                                <th style={border}>{services.language.getText('passagewayNo')}</th>
                                <th style={{ width: '23%', border: '1px solid #999' }}>
                                    {services.language.getText('spmc')}
                                </th>
                                <th style={border}>
                                    {services.language.getText('specifications')}
                                </th>
                                <th style={border}>
                                    {services.language.getText('numberAndWeight')}
                                </th>
                            </tr>
                            {firstPageGoods.map((item, index) => (
                                <tr style={{ textAlign: 'left' }} key={index}>
                                    <td style={{ textAlign: 'center', border: '1px solid #999' }}>
                                        {index + 1}
                                    </td>
                                    <td style={border}>{item.goods && item.goods.productCode}</td>
                                    <td style={border}>{item.goods && item.goods.barCode}</td>
                                    <td style={border}>{item.name}</td>
                                    <td style={border}>
                                        {item.goods &&
                                            item.goods.pickRoutes &&
                                            item.goods.pickRoutes.passageway}
                                    </td>
                                    <td style={border}>{item.goods && item.goods.name}</td>
                                    <td style={border}>{item.goods && item.goods.styleName}</td>
                                    <td style={border}>{getPickQuantity(item)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {pages.length > 0 &&
                        pages.map((page, pageNum) => {
                            return (
                                <table
                                    cellSpacing={0}
                                    cellPadding={5}
                                    style={{
                                        marginTop: '50px',
                                        border: '1px solid #999',
                                        borderCollapse: 'collapse',
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        width: '100%',
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '5%', border: '1px solid #999' }}>
                                                {services.language.getText('xh_1')}
                                            </th>
                                            <th style={border}>
                                                {services.language.getText('productCode')}
                                            </th>
                                            <th style={border}>
                                                {services.language.getText('goodBarCode')}
                                            </th>
                                            <th style={border}>
                                                {services.language.getText('pl_2')}
                                            </th>
                                            <th style={border}>
                                                {services.language.getText('passagewayNo')}
                                            </th>
                                            <th style={{ width: '23%', border: '1px solid #999' }}>
                                                {services.language.getText('spmc')}
                                            </th>
                                            <th style={border}>
                                                {services.language.getText('specifications')}
                                            </th>
                                            <th style={border}>
                                                {services.language.getText('numberAndWeight')}
                                            </th>
                                        </tr>
                                        {page.map((item, index) => (
                                            <tr style={{ textAlign: 'left' }} key={index}>
                                                <td
                                                    style={{
                                                        textAlign: 'center',
                                                        border: '1px solid #999',
                                                    }}
                                                >
                                                    {index + 19 + pageNum * 24}
                                                </td>
                                                <td style={border}>
                                                    {item.goods && item.goods.productCode}
                                                </td>
                                                <td style={border}>
                                                    {item.goods && item.goods.barCode}
                                                </td>
                                                <td style={border}>{item.name}</td>
                                                <td style={border}>
                                                    {item.goods &&
                                                        item.goods.pickRoutes &&
                                                        item.goods.pickRoutes.passageway}
                                                </td>
                                                <td style={border}>
                                                    {item.goods && item.goods.name}
                                                </td>
                                                <td style={border}>
                                                    {item.goods && item.goods.styleName}
                                                </td>
                                                <td style={border}>{getPickQuantity(item)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            );
                        })}
                </div>
                <div style={{ pageBreakAfter: 'always' }} />
            </div>
        );
    }
}
