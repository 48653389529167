import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { TopicSeletor } from './topic-selector';

export class TopicSelectorMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return (
            object && (
                <div>
                    <span> {object.name}</span>
                </div>
            )
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <TopicSeletor {...controlInfo} />;
    }
}
