import * as React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';

import { ObjectMode } from '@comall-backend-builder/types';
import { SelectTimepickerTip } from '../../../components';

export class ObjectSelectTimepickerMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        if (object && object.type) {
            let typeOption = find(options, { id: object.type }) || {};
            return (
                <div
                    className={classNames('type-object mode-object-winning-lots-number', className)}
                    style={style}
                >
                    <div>
                        <span> {typeOption.name} </span>
                        <span>{object.drawTime} 开始抽签</span>
                    </div>
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */

    public getControlComponent(controlInfo: any) {
        return <SelectTimepickerTip {...controlInfo} />;
    }
}
