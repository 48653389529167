import React, { Component } from 'react';
import { Input, Tabs } from 'antd';
import { UploadFile } from '@comall-backend-builder/components-basis';

import './index.less';

const { TabPane } = Tabs;

interface VideoModalContentProps {
    onChange: (url?: string) => void;
}

interface VideoModalContentState {
    url?: string;
    uploadUrl?: string;
    activeTabKey: TabKeys;
}

enum TabKeys {
    URL = 'URL',
    UPLOAD = 'UPLOAD',
}

interface VideoInfo {
    id: string;
    path: string;
}

export class VideoModalContent extends Component<VideoModalContentProps, VideoModalContentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTabKey: TabKeys.URL,
        };
    }

    onTabChange = (activeTabKey: string) => {
        const { onChange } = this.props;
        const { url, uploadUrl } = this.state;
        this.setState({ activeTabKey: activeTabKey as TabKeys });
        switch (activeTabKey) {
            case TabKeys.URL:
                onChange(url);
                break;
            case TabKeys.UPLOAD:
                onChange(uploadUrl);
                break;
        }
    };

    onUrlChange = (e: any) => {
        const url = e.target.value;
        this.setState({ url });
        const { onChange } = this.props;
        onChange(url);
    };

    onUploadUrlChange = (videos: VideoInfo[]) => {
        const { onChange } = this.props;
        if (!videos || !videos.length) {
            this.setState({ uploadUrl: undefined });
            onChange(undefined);
            return;
        }
        const video = videos.pop();
        this.setState({ uploadUrl: video && video.path });
        onChange(video && video.path);
    };

    render() {
        const { activeTabKey } = this.state;
        const uploadProps = {
            accept: '.mp4',
            maxCount: 1,
            multiple: false,
            fileName: 'files',
            uploadType: 'single',
            uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/5000/upload`,
            mode: 'text',
            maxSize: 100 * 1024,
            uploadLimitSizeErrorMessage: '视频{{file.name}}超出限制，最大上传 100M 视频',
            onChange: this.onUploadUrlChange,
            limitTips: {
                className: 'upload-tips-hide',
            },
        } as any;
        return (
            <Tabs onChange={this.onTabChange} activeKey={activeTabKey}>
                <TabPane tab="视频链接" key={TabKeys.URL}>
                    <div className="video-modal-input-container">
                        <span className="video-modal-input-title">视频url: </span>
                        <Input className="video-modal-input" onChange={this.onUrlChange} />
                    </div>
                </TabPane>
                <TabPane tab="选择视频" key={TabKeys.UPLOAD}>
                    <UploadFile {...uploadProps} />
                </TabPane>
            </Tabs>
        );
    }
}
