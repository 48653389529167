import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { ExternalCouponChoose } from './external-coupon-choose';

/**
 *  余额券选择三方券
 */
export class ExternalCouponChooseMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ExternalCouponChoose {...controlInfo} />;
    }
}
