export const config = {
    entities: {
        RewardEntity: {
            apiPath: '',
            properties: {
                id: {
                    type: 'number',
                    displayName: '<<jp>>id',
                    style: {
                        display: 'none',
                    },
                    // rules: [{ required: true }],
                },
                name: {
                    type: 'string',
                    displayName: '<<jpmc>>',
                    rules: [{ required: true }],
                },
                type: {
                    type: 'object.rewardRules',
                    displayName: '<<jplx>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'WHEEL_DRAW' },
                        baseConfig: {
                            isConditionsInput: false,
                            isItmesRadio: true,
                            isLadder: false,
                        },
                    },
                },
                picture: {
                    type: 'imageObjectArray',
                    displayName: '<<pictures>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    rules: [{ required: true }],
                },
                quantityType: {
                    type: 'string.options.select.rewardSetting',
                    displayName: '<<jpslxz>>',
                    defaultValue: 'YES',
                    rules: [{ required: true }],
                    options: [
                        { id: 'NO', name: '<<bxz>>' },
                        { id: 'YES', name: '<<xz>>' },
                    ],
                },
                quantity: {
                    type: 'number.integer',
                    displayName: '<<jpsl>>',
                    // defaultValue: '9999',
                    /*  rules: [
                        {
                            required: true,
                            type: 'number',
                            message: '奖品数量不能为空、不能超过9位数，并且仅支持到正整数',
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|\+?[1-9][0-9]*)$/;
                                var regLength = /^([0-9]{1,9})?$/g;
                                if (reg.test(value) && regLength.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ], */

                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                tip: {
                    type: 'string.text.input',
                    displayName: '<<zjtsy>>',
                    rules: [{ required: true }],
                },
                conditionValue: {
                    type: 'number.tip',
                    displayName: '<<zjgl_1>>',
                    extra: '<<l_dgjpdddrfjsl>>',
                    rules: [
                        {
                            required: true,
                            type: 'number',
                            message: '<<l_zjglbnwk>>',
                            validator: (rule: any, value: any) => {
                                // const reg = /^(-)?(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/; //&& reg.test(value)
                                if (value && value <= 100 && value > 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],

                    controlConfig: {
                        max: 100,
                        addonafter: '%',
                        style: { width: 100 },
                    },
                },
                rewardPeriod: {
                    type: 'array.rewardPeriod',
                    displayName: '<<zjxz>>',
                    // rules: [{ required: true }],
                    extra: '<<l_yhzcjpddslxz>>',
                },
            },
        },
    },
};
