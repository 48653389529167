import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Cascader } from 'antd';

const { api } = services;

export interface RegionOption {
    hasChild: boolean;
    id: number;
    level: number;
    name: string;
    parentId: number;
    parentPath: string;
    sequence: number;
}

export class RegionsSelect extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }

    async componentDidMount() {
        const { value } = this.props;
        if (value) {
            this.loadAllData(value);
        } else {
            const res = await this.loadData(0);
            this.setState({
                options: this.format(res),
            });
        }
    }

    componentWillUnmount = () => {
        this.setState = () => false;
    };

    loadData = (id: number) => {
        return api.get<RegionOption[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/regions/${id}/children`,
            }
        );
    };

    loadAllData = async (ids: number[]) => {
        const [parent, ...children] = await Promise.all([
            this.loadData(0),
            ...ids.map((id) => this.loadData(id)),
        ]);
        const options = this.format(parent).map((item: any) => {
            return this.getRegionTree(item, 0, ids, children);
        });
        this.setState({
            options,
        });
    };

    getRegionTree = (item: any, index: number, ids: number[], children: RegionOption[][]): any => {
        if (item.value === ids[index]) {
            const current = this.format(children[index]);
            item.children = current.map((el) => {
                return this.getRegionTree(el, index + 1, ids, children);
            });
            return item;
        } else {
            return item;
        }
    };

    format = (data: RegionOption[]) => {
        return data.map((region) => ({
            value: region.id,
            label: region.name,
            isLeaf: !region.hasChild,
            loading: false,
        }));
    };
    onLoadData = async (selectedOptions: any) => {
        const { options } = this.state;
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const res = await this.loadData(targetOption.value);
        const childrenOption = this.format(res);
        targetOption.children = childrenOption;
        this.setState(
            {
                options: [...options],
            },
            () => {
                targetOption.loading = false;
            }
        );
    };
    onChange = (value: string[]) => {
        const { onChange, name } = this.props;
        onChange && onChange(value, name);
    };

    render() {
        const { style, value } = this.props;
        const { options } = this.state;
        return (
            <div style={style}>
                <Cascader
                    defaultValue={value}
                    options={options}
                    loadData={this.onLoadData}
                    onChange={this.onChange}
                    changeOnSelect
                />
            </div>
        );
    }
}
