import { Entity } from '@comall-backend-builder/core/lib/parser';
import { get } from 'lodash';
import React from 'react';
import SubsitesDisplayTable from './subsites-display-table';
import SubsiteSelectoraModal from './subsites-selector-modal';

interface Subsite {
    id: string;
    name: string;
    address: string;
    status: number;
}
interface Props {
    value?: Subsite[];
    onChange?(value: Subsite[]): void;
    entity: Entity;
}

export function WechatOfficialAccountSubsiteSelector(props: Props) {
    const { entity, value, onChange } = props;
    const id = get(entity, 'params.id');
    const hasValue = Boolean(value?.length);
    const onRemove = (subsite: Subsite) => {
        const newValue = value!.filter(({ id }) => id !== subsite.id);
        onChange?.(newValue);
    };
    return (
        <div>
            <SubsiteSelectoraModal id={id} value={value} onChange={onChange} />
            {hasValue ? <SubsitesDisplayTable value={value} onRemove={onRemove} /> : null}
        </div>
    );
}
