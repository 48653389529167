import React, { PureComponent } from 'react';
import { Cascader } from 'antd';
import { services } from '@comall-backend-builder/core';

const { api } = services;

export interface StringCascaderSelectStates {
    options: any;
    selectedKeys: string[];
}
export class StringCascader extends PureComponent<
    {
        apiRoot: string;
        apiPath: string;
        value: string[];
        onChange: (value: string[]) => void;
        style: any;
    },
    StringCascaderSelectStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
            selectedKeys: [],
        };
    }
    componentDidMount(): void {
        this.loadData();
    }

    componentDidUpdate(
        prevProps: Readonly<{
            apiRoot: string;
            apiPath: string;
            value: string[];
            onChange: (value: string[]) => void;
            style: any;
        }>
    ): void {
        if (prevProps.value && !this.props.value) {
            this.setState({
                selectedKeys: [],
            });
        }
    }

    loadData = () => {
        const { apiRoot, apiPath } = this.props;
        const config = {
            apiRoot,
            apiPath,
        };
        api.get({}, config).then((res: any) => {
            if (res && res.result && res.result.length) {
                let options = res.result;
                this.setState({
                    options,
                });
            }
        });
    };

    onRegionChange = (selectedKeys: any[]) => {
        let { onChange, value } = this.props;
        if (!value) {
            value = [];
        }
        this.setState({ selectedKeys });
        if (selectedKeys && selectedKeys.length) {
            value = selectedKeys[selectedKeys.length - 1];
        }
        onChange && onChange(value);
    };

    render() {
        const { options, selectedKeys } = this.state;
        const { style } = this.props;

        return (
            <div>
                <Cascader
                    fieldNames={{ label: 'name', value: 'id' }}
                    value={selectedKeys}
                    options={options}
                    onChange={this.onRegionChange}
                    style={style}
                    changeOnSelect
                    placeholder={services.language.getText('selectPlease')}
                />
            </div>
        );
    }
}
