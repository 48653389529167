import React, { Component, CSSProperties } from 'react';
import { InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

interface CouponRuleStockValue {
    maxCount: string;
    changeCount: string;
    surplusCount: string;
    defalutMaxCount: string;
    defalutSurplusCount: string;
}

interface CouponRuleStockProps {
    row: any;
    /**
     * 选中值
     */
    value: CouponRuleStockValue;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: CouponRuleStockValue) => void;
}

export class CouponRuleStock extends Component<CouponRuleStockProps, {}> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange({
                maxCount: '',
                changeCount: '',
                surplusCount: '',
                defalutMaxCount: '',
                defalutSurplusCount: '',
            });
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { value } = nextProps;
        if (window.location.href.includes('/edit')) {
            if (value.maxCount && value.surplusCount) {
                this.setState({
                    defalutMaxCount: value.defalutMaxCount,
                    defalutSurplusCount: value.surplusCount,
                });
            }
            return;
        }
    }

    changeValue = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        if (inputValue && newValue.defalutSurplusCount + inputValue >= 0) {
            newValue.changeCount = inputValue;
            newValue.maxCount = newValue.defalutMaxCount + inputValue;
            newValue.surplusCount = newValue.defalutSurplusCount + inputValue;
            onChange(newValue);
        } else {
            newValue.changeCount = '';
            newValue.maxCount = newValue.defalutMaxCount;
            newValue.surplusCount = newValue.defalutSurplusCount;
            onChange(newValue);
        }
    };
    changeMaxCountValue = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        newValue.maxCount = inputValue;
        onChange(newValue);
    };
    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const textCss: CSSProperties = {
            border: '1px solid #d9d9d9',
            padding: '7px 11px',
            backgroundColor: '#fafafa',
            borderLeft: '0',
            borderRadius: '4px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        };
        const isAddCopy =
            window.location.href.includes('/add') || window.location.href.includes('/copy');
        if (isAddCopy) {
            return (
                <div>
                    <InputNumber
                        placeholder={services.language.getText('zdksz1yz')}
                        style={{ width: '150px' }}
                        value={value.maxCount ? parseInt(value.maxCount) : undefined}
                        min={0}
                        max={100000000}
                        step={1}
                        precision={0}
                        onChange={(value: any) => this.changeMaxCountValue(value)}
                    ></InputNumber>
                    <span style={textCss}>{services.language.getText('zhang')}</span>
                </div>
            );
        }
        const tip = services.language.getText('rsjkczsr');
        const leftStockTip = services.language.getText('xcxdsqdhszgxsffsqddsykygxkcs');
        return (
            <div>
                <div>
                    <InputNumber
                        style={{ width: '150px' }}
                        disabled={true}
                        value={value.maxCount !== '' ? parseInt(value.maxCount) : undefined}
                    ></InputNumber>
                    <span style={textCss}>{services.language.getText('zhang')}</span>
                </div>
                <div
                    style={{
                        margin: '30px 0 0px -101px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >
                    <span>{services.language.getText('zjjskc')}：</span>
                    <InputNumber
                        placeholder={services.language.getText('zdksz1yz')}
                        style={{ width: '150px' }}
                        value={value.changeCount !== '' ? parseInt(value.changeCount) : undefined}
                        max={100000000}
                        step={1}
                        precision={0}
                        onChange={(value: any) => this.changeValue(value)}
                    ></InputNumber>
                    <span style={textCss}>{services.language.getText('zhang')}</span>
                    <div style={{ margin: '0px 0 0 102px', color: '#ccc', lineHeight: '24px' }}>
                        {tip}
                    </div>
                </div>
                <div
                    style={{
                        margin: '30px 0 0px -70px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >
                    <span>{services.language.getText('sykc')}：</span>
                    <InputNumber
                        style={{ width: '150px' }}
                        disabled={true}
                        value={value.surplusCount !== '' ? parseInt(value.surplusCount) : undefined}
                    ></InputNumber>
                    <span style={textCss}>{services.language.getText('zhang')}</span>
                    <div style={{ margin: '0px 0 0 71px', color: '#ccc', lineHeight: '24px' }}>
                        {leftStockTip}
                    </div>
                </div>
            </div>
        );
    }
}
