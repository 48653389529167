import React, { Component } from 'react';
import { defaultsDeep, set } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import {
    ComponentFactory,
    DynamicComponent,
} from '@comall-backend-builder/core/lib/parser/component';
import { services } from '@comall-backend-builder/core';

export interface SelectProductValue {
    id: number;
    goodsId: number;
    name: string;
}

export interface SelectProductProps {
    visible?: boolean;
    value?: SelectProductValue;
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    subsiteIds?: string[];
    onChange?(value: SelectProductValue): void;
    onCancel?(): void;
}

export interface SelectProductState {
    currentSelectValue?: SelectProductValue;
}

function generateDynamicComponentName() {
    return 'DesignProductSelector-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

function createComponentConfig(componentName: string, entityName: string) {
    return {
        componentId: componentName,
        component: 'Card',
        params: {
            subsiteIds: '',
        },
        entity: entityName,
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    componentId: `${componentName}-FilterForm`,
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right', marginBottom: '20px' },
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'productType',
                            controlConfig: {
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        { property: 'keywords' },
                    ],
                },
                {
                    componentId: `${componentName}-table`,
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'productInfo',
                            width: 350,
                        },
                        {
                            property: 'brandVo.name',
                        },
                        {
                            property: 'goodsMvo.subsiteName',
                        },
                        {
                            property: 'goodsMvo.merchantName',
                        },
                    ],
                },
            ],
        },
    };
}

function createDynamicComponent(
    contentConfig: Record<string, any> = {},
    componentConfig: Record<string, any> = {},
    onSelect?: (value: SelectProductValue) => void,
    subsiteIds?: string[]
) {
    const config = defaultsDeep(contentConfig, componentConfig);
    set(config, 'params.subsiteIds', subsiteIds);
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange() {},
        onSelect(_: any, __: any, [value]: [any]) {
            onSelect &&
                onSelect({
                    id: value.id,
                    goodsId: value.goodsId || value.goodsMvo.id,
                    name: value.name,
                });
        },
    });

    return ComponentFactory(componentConfig.componentId, config);
}

export class SelectProduct extends Component<SelectProductProps, SelectProductState> {
    componentName = '';
    public state: SelectProductState = {
        currentSelectValue: undefined,
    };

    private ProductSelectorComponent: typeof DynamicComponent;

    public constructor(props: SelectProductProps) {
        super(props);
        const { contentConfig, subsiteIds } = props;
        this.componentName = generateDynamicComponentName();
        const productSelectorConfig = createComponentConfig(
            this.componentName,
            'CustomDesignNormalProductSelector'
        );
        this.ProductSelectorComponent = createDynamicComponent(
            contentConfig,
            productSelectorConfig,
            this.handleCurrentSelectValueChange,
            subsiteIds
        );
    }
    componentWillReceiveProps(nextProps: any) {
        const { subsiteIds } = this.props;
        if (nextProps?.subsiteIds?.join(',') !== subsiteIds?.join(',')) {
            const { contentConfig, subsiteIds } = nextProps;
            this.componentName = generateDynamicComponentName();
            const productSelectorConfig = createComponentConfig(
                this.componentName,
                'CustomDesignNormalProductSelector'
            );
            this.ProductSelectorComponent = createDynamicComponent(
                contentConfig,
                productSelectorConfig,
                this.handleCurrentSelectValueChange,
                subsiteIds
            );
        }
    }
    private handleOk = (): void => {
        const { onChange } = this.props;
        const { currentSelectValue } = this.state;

        if (!currentSelectValue) {
            AntMessage.error(services.language.getText('productSelectRequired'));
            return;
        }

        onChange && onChange(currentSelectValue);
    };

    private handleCancel = (): void => {
        const { onCancel } = this.props;

        onCancel && onCancel();
    };

    private handleCurrentSelectValueChange = (value: SelectProductValue): void => {
        this.setState({
            currentSelectValue: value,
        });
    };

    public render(): JSX.Element {
        const { visible, modalConfig = {} } = this.props;
        return (
            <Modal
                title={services.language.getText('productSelectRequired')}
                width="950px"
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                {...modalConfig}
            >
                <this.ProductSelectorComponent />
            </Modal>
        );
    }
}
