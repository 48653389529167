import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const orderStatusMap: any = {
    CREATE: '待付款',
    EXAMINE: '待审核',
    EXAMINE_FAILED: '审核未通过',
    PAYMENT: '待使用',
    COMMENT: '待评论',
    RECEIVE: '已完成',
    REFUND: '退款中',
    REFUND_FINISH: '退款完成',
    CANCEL: '已取消',
    CLOSE: '已关闭',
    GROUP_BUY: '拼团中',
};
const api = services.api;
export const MallActivityRegistrationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;

        if (!data.id) {
            config.apiPath = '/admin/mall_activity_orders';
            data.orderType = 'MALL_ACTIVITY';
        } else {
            config.apiPath = `/admin/mall_activity_orders/${data.id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (!data.id) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        let orderStatus = '全部';
                        if (orderStatusMap[item.orderStatus]) {
                            orderStatus = orderStatusMap[item.orderStatus];
                        }
                        item.orderStatusStr = orderStatus;
                        return item;
                    });
            } else {
                let orderStatusStr = '全部';
                if (orderStatusMap[res.orderStatus]) {
                    orderStatusStr = orderStatusMap[res.orderStatus];
                }
                res.orderStatusStr = orderStatusStr;
            }

            return res;
        });
    },
};
