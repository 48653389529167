// @ts-nocheck
import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ForwardDataCenterModal } from '../../services';

import './index.less';
import 'antd/dist/antd.css';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    resourceId: number;
    resourceName: string;
    resourceUrl: string;
    type: string;
}

/**
 * 导入excal按钮
 */
export class ImportButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = (resource: any) => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
            entity,
            params,
        } = this.props;
        let { apiRoot, apiPath } = params;
        apiPath = apiPath.replace('{{row.id}}', id);

        api.put(resource, { apiRoot, apiPath })
            .then(
                (res) => {
                    ForwardDataCenterModal();

                    entity.search();
                    this.toggleModal();
                },
                (err) => {
                    if (err.response.body.err_msg) {
                        message.error(err.response.body.err_msg);
                    } else {
                        this.setState({
                            statusMsg: services.language.getText('importFailPrivity'),
                        });
                    }
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.resourceId) {
            this.onImport(resource);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const {
            row,
            params: { text, templateSource, importFileSource, specialTips },
        } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            resourceId: 0,
                            resourceName: '',
                            resourceUrl: '',
                            type: 'IMPORT',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                api.upload(
                    { files: file },
                    {
                        apiRoot: importFileSource.apiRoot,
                        apiPath: importFileSource.apiPath,
                        fileName: 'files',
                        progressCallBack: () => {},
                    }
                )
                    .then((res) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
        return (
            <div className="import-button-data-statistics">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {text}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={text}
                    footer={[
                        <span className="import-button-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div className="import-button-tips">
                        <p className="import-button-tips-text">
                            {services.language.getText('importRules')}
                        </p>
                        <p className="import-button-tips-text">
                            {services.language.getText('uploadIntro1')}
                        </p>
                        <p className="import-button-tips-text">
                            {services.language.getText('uploadIntro2')}
                        </p>
                        <p className="import-button-tips-text">
                            {services.language.getText('uploadIntro3')}
                        </p>
                        {specialTips &&
                            specialTips.length > 0 &&
                            specialTips.map((tip) => {
                                return <p className="import-button-tips-text">{tip}</p>;
                            })}
                    </div>
                    <div className="upload-tips">
                        <ExportButton
                            type="link"
                            text={services.language.getText('downloadTemplate')}
                            showErrorMessage={true}
                            source={{
                                apiRoot: templateSource.apiRoot,
                                apiPath: templateSource.apiPath,
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
