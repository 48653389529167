import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';
import { merchantId } from '../../../containers/design-merchant';

export const GuideLoader = {
    get: async function(data: any, config: any) {
        if (merchantId >= 0) {
            data.merchantIds = merchantId;
        }
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        const categoryType =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (categoryType === 'categoryNavProduct') {
            data.levels = '1,2,3';
        } else if (categoryType === 'categoryNav') {
            data.levels = '2,3';
        }

        if (categoryType) {
            delete config.paramsFilter;
        }

        return services.api.get(data, config);
    },
};
