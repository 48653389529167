import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, forEach } from 'lodash';

export const config: Config = {
    entities: {
        DeliveryBenefitsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/delivery_benefits',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                subsiteName: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<kqzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                rulesInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        deliveryMode: {
                            type: 'string.options.select',
                            displayName: '<<deliveryType>>',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/delivery_modes',
                                dependences: ['subsiteIds'],
                                loadOnInit: false,
                            },
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        rewardType: {
                            type: 'string.options.radio',
                            displayName: '<<yhfs>>',
                            defaultValue: 'SUBTRACT_FREIGHT',
                            options: [{ id: 'SUBTRACT_FREIGHT', name: '<<subtractFreight>>' }],
                            rules: [{ required: true }],
                        },
                        promotionConditionType: {
                            type: 'string.options.radio',
                            displayName: '<<tjlx>>',
                            defaultValue: 'MONEY',
                            options: [{ id: 'MONEY', name: '<<orderAmount>>' }],
                            rules: [{ required: true }],
                        },
                        createRewards: {
                            type: 'array.FullReductionLadder',
                            displayName: '<<mjsz>>',
                            rules: [{ required: true }],
                        },
                    },
                },
                subsiteIds: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    rules: [{ required: true, type: 'array' }],
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        DeliveryBenefitsView: {
            component: 'Viewport',
            entity: 'DeliveryBenefitsEntity',
        },
        DeliveryBenefitsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'DeliveryBenefitsFilter',
                },
                { component: 'DeliveryBenefitsTable' },
            ],
        },
        DeliveryBenefitsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteName',
                },
            ],
        },
        DeliveryBenefitsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<xzyfhd>>',
                    route: '/delivery-benefits/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'PresaleDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    // statusKey: 'baseInfo.status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: 'DISABLE' },
                                            value: 'ENABLE',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                            apiPath: '/admin/delivery_benefits/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                                style: { color: 'red' },
                                            },
                                            confirm: {
                                                text: '<<qrgbhd>>{{row.name}}?',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLE' },
                                            value: 'DISABLE',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                            apiPath: '/admin/delivery_benefits/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/delivery-benefits/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        DeliveryBenefitsAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'DeliveryBenefitsAddForm' }],
        },
        DeliveryBenefitsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'DeliveryBenefitsEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'subsiteIds' },
                { property: 'rulesInfo.deliveryMode' },
                { property: 'rulesInfo.rewardType' },
                {
                    property: 'rulesInfo.promotionConditionType',
                    extra: '<<zspcycxyhhsyyhqh>>',
                },
                { property: 'rulesInfo.createRewards' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const createRewards: any = get(entityFields, 'rulesInfo.createRewards', null);
                let hasValidate = true;
                if (createRewards && createRewards.length > 0) {
                    let maxStepValue = 0,
                        maxRewardValue = 0;
                    forEach(createRewards, (item: any) => {
                        if (hasValidate) {
                            if (!item.stepValue || !item.rewardValue) {
                                AntMessage.warning(services.language.getText('qtxwzdyfljgz'));
                                hasValidate = false;
                            }
                            if (item.stepValue) {
                                if (Number(item.stepValue) > Number(maxStepValue)) {
                                    maxStepValue = item.stepValue;
                                } else {
                                    AntMessage.warning(
                                        services.language.getText('ddyfjexydxdysyd')
                                    );
                                    hasValidate = false;
                                }
                            }
                            if (item.rewardValue) {
                                if (Number(item.rewardValue) > Number(maxRewardValue)) {
                                    maxRewardValue = item.rewardValue;
                                } else {
                                    AntMessage.warning(
                                        services.language.getText('yfljjejexydxdysyd')
                                    );
                                    hasValidate = false;
                                }
                            }
                        }
                    });
                } else {
                    AntMessage.warning(services.language.getText('qtxyfljgz'));
                    return false;
                }
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        DeliveryBenefitsEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'DeliveryBenefitsEditForm' }],
        },
        DeliveryBenefitsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'DeliveryBenefitsEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'subsiteIds' },
                {
                    property: 'rulesInfo.deliveryMode',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 200,
                        },
                    },
                },
                {
                    property: 'rulesInfo.rewardType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'rulesInfo.promotionConditionType',
                    extra: '<<zspcycxyhhsyyhqh>>',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'rulesInfo.createRewards',
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const createRewards: any = get(entityFields, 'rulesInfo.createRewards', null);
                let hasValidate = true;
                if (createRewards && createRewards.length > 0) {
                    let maxStepValue = 0,
                        maxRewardValue = 0;
                    forEach(createRewards, (item: any) => {
                        if (hasValidate) {
                            if (!item.stepValue || !item.rewardValue) {
                                AntMessage.warning(services.language.getText('qtxwzdyfljgz'));
                                hasValidate = false;
                            }
                            if (item.stepValue) {
                                if (Number(item.stepValue) > Number(maxStepValue)) {
                                    maxStepValue = item.stepValue;
                                } else {
                                    AntMessage.warning(
                                        services.language.getText('ddyfjexydxdysyd')
                                    );
                                    hasValidate = false;
                                }
                            }
                            if (item.rewardValue) {
                                if (Number(item.rewardValue) > Number(maxRewardValue)) {
                                    maxRewardValue = item.rewardValue;
                                } else {
                                    AntMessage.warning(
                                        services.language.getText('yfljjejexydxdysyd')
                                    );
                                    hasValidate = false;
                                }
                            }
                        }
                    });
                } else {
                    AntMessage.warning(services.language.getText('qtxyfljgz'));
                    return false;
                }
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/delivery-benefits',
                    component: 'DeliveryBenefitsView',
                    breadcrumbName: '<<deliveryBenefit>>',
                    privilege: {
                        path: 'deliveryBenefit',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'DeliveryBenefitsEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/add',
                            component: 'DeliveryBenefitsAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        { path: '/', component: 'DeliveryBenefitsPage' },
                    ],
                },
            ],
        },
    ],
};
