import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';
import { merchantId } from '../../../containers/design-merchant';

export const ProductsLoader = {
    get: async function(data: any, config: any) {
        if (merchantId >= 0) {
            data.merchantId = merchantId;
        }
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        if (data.subsiteId) {
            data.subsiteIds = [data.subsiteId];
            delete data.subsiteId;
        }
        if (data.merchantId) {
            data.merchantIds = [data.merchantId];
            delete data.merchantId;
        }
        if (data.activityProductTypes) {
            if (data.activityProductTypes === 'ALL') {
                data.productTypes = ['NORMAL', 'COMBINE'];
            } else {
                data.productTypes = data.activityProductTypes;
            }
            delete data.activityProductTypes;
        }
        if (data.groupBuyingProductType === 'VIRTUAL_GOODS') {
            data.productType = 'VIRTUAL_COUPON';
        } else if (data.groupBuyingProductType === 'GENERAL_PRODUCT') {
            data.productType = 'NORMAL';
        }

        const res: any = await services.api.get(data, config);
        res.result &&
            res.result.length &&
            res.result.forEach((item: any) => {
                item.brandName = item.brandVo?.name;
                item.isProduct = true;
            });
        return res;
    },
};
