import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { get } from 'lodash';

export enum TaxRateType {
    // 简易征收
    SIMPLETAX = 'SIMPLE_TAX',
    // 按3%简易征收
    SIMPLETAX3PERCENT = 'SIMPLE_TAX_3_PERCENT',
    // 按5%简易征收
    SIMPLETAX5PERCENT = 'SIMPLE_TAX_5_PERCENT',
    // 按5%简易征收减按1.5%计征
    SIMPLETAX5PERCENTREDUCEDTO1DROP5PERCENT = 'SIMPLE_TAX_5_PERCENT_REDUCED_TO_1_5_PERCENT',
}

interface TaxRateNumberState {
    /**
     * 停车缴费税率
     */
    taxRate: number | undefined;
    /**
     * 停车缴费税率
     */
    disabeld: boolean;
}
/**
 * 停车发票税率输入框
 */
export class TaxRateNumber extends Component<any, TaxRateNumberState> {
    constructor(props: any) {
        super(props);

        this.state = {
            taxRate: undefined,
            disabeld: false,
        };
    }
    componentWillReceiveProps(nextProps: any) {
        const { row: nextRow } = nextProps;
        const { row } = this.props;
        const taxPreferentialPoliciesType = get(row, 'baseInfo.taxPreferentialPoliciesType');
        const nextTaxPreferentialPoliciesType = get(
            nextRow,
            'baseInfo.taxPreferentialPoliciesType'
        );
        const taxPreferentialPolicies = get(row, 'baseInfo.taxPreferentialPolicies');
        const nextTaxPreferentialPolicies = get(nextRow, 'baseInfo.taxPreferentialPolicies');

        // 如果税收优惠政策类型发生变化，则更新税率
        if (taxPreferentialPoliciesType !== nextTaxPreferentialPoliciesType) {
            // 调用设置税率的方法，传入新的税收优惠政策类型和行数据
            this.setTaxRate(nextTaxPreferentialPoliciesType, nextRow);
        }

        // 如果税收优惠政策发生变化，则重置税率和禁用状态
        if (taxPreferentialPolicies !== nextTaxPreferentialPolicies) {
            if (nextTaxPreferentialPolicies === 'false') {
                // 当不选择享受税收优惠政策，将税率设为未定义，禁用状态设为false
                const { taxRate } = this.state;
                this.setState({
                    taxRate: taxRate ? undefined : get(nextRow, 'baseInfo.taxRate'),
                    disabeld: false,
                });
            } else {
                this.setTaxRate(nextTaxPreferentialPoliciesType, nextRow);
            }
        }
    }

    /**
     * 设置税率
     * 根据指定的税种类型，设置相应的税率和是否禁用的状态
     *
     * @param taxType 税种类型，决定了要设置的税率
     */
    setTaxRate = (taxType: TaxRateType, row: any) => {
        // 当税种类型为3%简易税率时，设置税率和禁用状态
        if (taxType === TaxRateType.SIMPLETAX3PERCENT) {
            this.setState({
                taxRate: 3,
                disabeld: true,
            });
            // 当税种类型为5%简易税率时，设置税率和禁用状态
        } else if (taxType === TaxRateType.SIMPLETAX5PERCENT) {
            this.setState({
                taxRate: 5,
                disabeld: true,
            });
            // 当税种类型为5%简易税率减至1.5%时，设置税率和禁用状态
        } else if (taxType === TaxRateType.SIMPLETAX5PERCENTREDUCEDTO1DROP5PERCENT) {
            this.setState({
                taxRate: 1.5,
                disabeld: true,
            });
            // 当税种类型不匹配上述任何一种时，清除税率并启用状态
        } else {
            const { taxRate } = this.state;
            this.setState({
                taxRate: taxRate ? undefined : get(row, 'baseInfo.taxRate'),
                disabeld: false,
            });
        }
    };

    numberChange = (val: any) => {
        const { onChange } = this.props;
        this.setState({
            taxRate: val,
        });

        if (onChange) {
            onChange(val);
        }
    };
    render() {
        const { placeholder, min, max, suffix } = this.props;
        const { taxRate, disabeld } = this.state;
        return (
            <div>
                <InputNumber
                    onChange={this.numberChange}
                    disabled={disabeld}
                    style={{ width: 450 }}
                    placeholder={placeholder}
                    value={taxRate}
                    min={min}
                    max={max}
                />
                <span style={{ marginLeft: '3px' }}>{suffix}</span>
            </div>
        );
    }
}
