import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ExperienceDoublePromotionsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/shopping_reward_double_promotions';
        const { id } = data;

        if (data.enable === 'ALL') {
            delete data.enable;
        }
        if (id) {
            config.apiPath = `/admin/shopping_reward_double_promotions/${id}`;
        } else {
            data.rewardType = 'CARD_EXPERIENCE';
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                };
                res.ruleInfo = {
                    subsites: res.subsite && res.subsite.id,
                    channels: res.channels,
                    merchants: res.merchants
                        ? res.merchants.map((m: any) => ({
                              ...m,
                              merchantId: m.id,
                              merchantName: m.name,
                              merchantType: m.type,
                              merchantStatus: m.status,
                          }))
                        : [],
                    memberPointRewardInfos: {
                        unifiedReward: res.unifiedReward,
                        rewardInfos: res.rewardInfos.map((reward: any) => ({
                            cardLevelId: reward.rewardCondition,
                            cardLevelName: reward.rewardConditionName,
                            rewardValue: reward.rewardValue
                        })),
                    },
                };
            } else {
                res.result = res.result ? res.result.map((r: any) => {
                    return {
                        ...r,
                        subsite: r.subsite.name,
                    };
                }) : [];
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = `/admin/shopping_reward_double_promotions/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/shopping_reward_double_promotions';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
    }
    if (params.ruleInfo) {
        newParams.subsiteId = params.ruleInfo.subsites;
        newParams.merchantIds = params.ruleInfo.merchants.map((m: any) => m.merchantId);
        newParams.channels = params.ruleInfo.channels;
        const memberPointRewardInfos = params.ruleInfo.memberPointRewardInfos;
        if (memberPointRewardInfos) {
            newParams.unifiedReward = memberPointRewardInfos.unifiedReward;
            newParams.rewardInfos = memberPointRewardInfos.rewardInfos.filter(
                (reward: any) => !!reward.rewardValue
            ).map((reward: any) => ({
                rewardConditionValue: reward.cardLevelId,
                rewardValue: reward.rewardValue,
                rewardType: 'CARD_EXPERIENCE'
            }));
        }
    }

    return newParams;
}
