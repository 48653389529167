import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, forEach } from 'lodash';

const api = services.api;
export const TopicPromotionEncourageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/topic_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/topic_activities/${id}`;
        } else {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.baseInfo = {
                    eventType: res.activityVo.eventType,
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    ruleIllustrate: res.topicActivityVo.ruleIllustrate,
                };
                res.encourageInfo = {
                    rewardBehavior: res.topicActivityVo.rewardBehavior,
                    lotteryType: res.topicActivityVo.lotteryType,
                    topic: res.topicActivityVo.activityTopicListVos.map((i: any) => ({
                        ...i.topicVo,
                        plateNames: i.topicVo.plateNames ? i.topicVo.plateNames.join('、') : '',
                    })),
                };
                if (res.topicActivityVo.lotteryTime) {
                    res.encourageInfo.lotteryTime = res.topicActivityVo.lotteryTime;
                }

                if (res.topicActivityVo.showEndTime && res.topicActivityVo.showStartTime) {
                    res.encourageInfo.showTime = {
                        end: res.topicActivityVo.showEndTime,
                        start: res.topicActivityVo.showStartTime,
                    };
                }
                if (res.activityVo.rewardConfig) {
                    let list: any = [];
                    forEach(res.activityVo.rewardConfig.activityRewards, (item) => {
                        let rewards: any = {};
                        rewards.rangeStartValue = item.conditionValue;
                        rewards.rangeEndValue = item.conditionValueMax;
                        forEach(item.assetRewards, (assetRewardItem) => {
                            if (assetRewardItem.rewardType === 'GOLD') {
                                rewards.goldChecked = true;
                                rewards.gold = assetRewardItem.rewardValue;
                            }
                            if (assetRewardItem.rewardType === 'POINT') {
                                rewards.pointChecked = true;
                                rewards.point = assetRewardItem.rewardValue;
                            }
                        });

                        if (item.couponRewards && item.couponRewards.length > 0) {
                            item.couponRewards.map((coupon: any) => {
                                coupon.quantity = coupon.count;
                                coupon.id = coupon.couponDefinitionId;
                                return coupon;
                            });
                            rewards.couponsValue = item.couponRewards;
                            rewards.couponsChecked = true;
                        }
                        list.push(rewards);
                    });
                    res.encourageInfo.ladderRewardRule = list;
                }
                if (!isEmpty(res.topicActivityVo.subsiteIds)) {
                    res.encourageInfo.subsiteIds = res.topicActivityVo.subsiteIds;
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/topic_activities';
        let newParams = tranformData(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/topic_activities/${params.id}`;
        }
        let newParams = tranformData(params);
        return await api.put(newParams, config);
    },
};

function tranformData(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.activityVo = {
            eventType: params.baseInfo.eventType,
            name: params.baseInfo.name,
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
        };
    }
    if (params.encourageInfo) {
        let activityRewards: any = [];
        params.encourageInfo.ladderRewardRule.forEach((item: any) => {
            const rewardItem: any = {
                conditionValue: item.rangeStartValue,
                conditionValueMax: item.rangeEndValue,
                assetRewards: [],
                couponRewards: [],
            };
            if (item.goldChecked) {
                rewardItem.assetRewards.push({ rewardType: 'GOLD', rewardValue: item.gold });
            }

            if (item.pointChecked) {
                rewardItem.assetRewards.push({ rewardType: 'POINT', rewardValue: item.point });
            }
            const idArray = new Array<{
                couponDefinitionId: number;
                count: number;
            }>();
            if (item.couponsChecked) {
                forEach(item.couponsValue, (coupon: any) => {
                    idArray.push({
                        couponDefinitionId: coupon.id,
                        count: coupon.quantity ? coupon.quantity : 1,
                    });
                });
            }
            rewardItem.couponRewards = idArray;
            activityRewards.push(rewardItem);
        });
        newParams.activityVo.rewardConfig = {
            activityRewards,
            rewardRule: 'CYCLE',
        };
        newParams.topicActivityVo = {
            activityTopicListVos: params.encourageInfo.topic.map((i: any) => ({
                topicId: i.id,
            })),
            lotteryType: params.encourageInfo.lotteryType,
            rewardBehavior: params.encourageInfo.rewardBehavior,
            ruleIllustrate: params.baseInfo.ruleIllustrate,
        };

        if (params.encourageInfo.lotteryTime) {
            newParams.topicActivityVo.lotteryTime = params.encourageInfo.lotteryTime;
        }
        if (
            params.encourageInfo.showTime &&
            params.encourageInfo.showTime.start &&
            params.encourageInfo.showTime.end
        ) {
            newParams.topicActivityVo.showStartTime = params.encourageInfo.showTime.start;
            newParams.topicActivityVo.showEndTime = params.encourageInfo.showTime.end;
        }
        if (params.encourageInfo.subsiteIds) {
            let subsiteIds: any = [];
            params.encourageInfo.subsiteIds.forEach((item: any) => {
                let subsiteIdsObj: any = {};
                subsiteIdsObj.id = item.id;
                subsiteIds.push(subsiteIdsObj);
            });
            newParams.topicActivityVo.subsiteIds = subsiteIds;
        }
    }
    return newParams;
}
