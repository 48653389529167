import React, { PureComponent } from 'react';
import xssFilters from 'xss-filters';
import { ButtonProps as AntButtonProps } from 'antd/lib/button';
import { services } from '@comall-backend-builder/core';

import { CustomStyleComponentProps, Text } from '@comall-backend-builder/components-basis';

/**
 * 实际组件暴露的 Props
 */
export interface LinkProps extends CustomStyleComponentProps {
    /**
     * 目标 URL
     */
    url: string;

    /**
     * 链接文本
     */
    text?: string;

    /**
     * 是否禁用
     */
    disabled?: boolean;

    /**
     * 点击处理
     */
    onClick?: AntButtonProps['onClick'];

    [key: string]: any;
}

export class CustomLink extends PureComponent<LinkProps, never> {
    render() {
        const { url, text, ...otherProps } = this.props;
        let sanitizedUrl = services.interpolate(url, this.props);
        sanitizedUrl = xssFilters.uriInDoubleQuotedAttr(sanitizedUrl);

        let props = {
            ...otherProps,
            href: sanitizedUrl,
        };

        // 根据当前是否有imageurl来判断当前的超链接为文本OR图片
        return (
            <a {...props}>
                <Text text={text || ''} />
            </a>
        );
    }
}
