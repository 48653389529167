import { NEW_CLIENT_DESIGN_PAGE_TYPES_LIST } from '../../constants/page-design';

export const PREVIEW_MODE = [
    {
        id: 'OPEN_MINI_PROGRAM',
        name: '<<yes>>',
    },
    {
        id: 'ONLY_PREVIEW',
        name: '<<no>>',
    },
];
export const config = {
    entities: {
        PlatformPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['PLATFORM_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
        },
        StorePageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['STORE_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
        },
        MerchantPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['MERCHANT_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                merchantName: {
                    type: 'string',
                },
                merchantId: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsites>>',
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 100, message: '<<rules.PageManage.nameLongLength>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<effectiveTime>>',
                    rules: [{ required: true, message: '<<effectiveTimeRequired>>' }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        range: {
                            start: new Date(),
                        },
                    },
                },
                merchantSelect: {
                    type: 'merchantSelect',
                    displayName: '<<merchant>>',
                    rules: [
                        {
                            required: true,
                            message: '<<rules.MerchantPageManage.nameRequired>>',
                        },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                merchantCode: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
            },
        },
        CarparkPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['CARPARK_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<status>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                subsites: {
                    type: 'string.formatter',
                    displayName: '<<subsites>>',
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 100, message: '<<rules.PageManage.nameLongLength>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },

                status: {
                    type: 'string',
                    displayName: '<<status>>',
                },
            },
        },
        TopicPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['CUSTOM_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                previewMode: {
                    type: 'string.options.radio',
                    displayName: '<<previewMode>>',
                    defaultValue: 'ONLY_PREVIEW',
                    extra: '<<previewModeExtra>>',
                    options: PREVIEW_MODE,
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
        },
        CreditEshopPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['CREDIT_ESHOP_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ENABLED', name: '<<beforeOpen>>' },
                        { id: 'DISABLE', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                previewMode: {
                    type: 'string.options.radio',
                    displayName: '<<previewMode>>',
                    defaultValue: 'ONLY_PREVIEW',
                    extra: '<<previewModeExtra>>',
                    options: PREVIEW_MODE,
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
            },
        },
        ElectronicBillEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['POS_RECEIPTS_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ENABLED', name: '<<beforeOpen>>' },
                        { id: 'DISABLE', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
            },
        },
        MemberCenterPage: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['MEMBER_CENTER_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
        },
        PayResultPage: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['PAY_RESULT_PAGE'],
            filters: {
                subsiteIds: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { defaultValueIndex: 0, style: { width: 250 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
            },
        },
        CustomTabbarPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['TAB_BAR'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<components.Nav.name>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
        },
        NewPageTemplateEntity: {
            apiPath: '/loader/admin/decorate/templates',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<entities.TemplateManage.templateName>>',
                    rules: [
                        { required: true, message: '<<rules.TemplateManage.nameRequired>>' },
                        { max: 16, message: '<<rules.TemplateManage.nameLength>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<rules.TemplateManage.nameRequired>>',
                    },
                },
                type: {
                    type: 'optionsString',
                    displayName: '<<entities.TemplateManage.templateType>>',
                    rules: [{ required: true }],
                    defaultValue: NEW_CLIENT_DESIGN_PAGE_TYPES_LIST[0],
                    options: NEW_CLIENT_DESIGN_PAGE_TYPES_LIST,
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        optionFilterProp: 'children',
                    },
                },
                order: {
                    type: 'number.positive',
                    displayName: '<<entities.TemplateManage.sorting>>',
                    defaultValue: '0',
                },
                preview: {
                    type: 'imageObjectArray',
                    rules: [{ required: false }],
                    displayName: '<<entities.TemplateManage.previewImage>>',
                },
                structureFixed: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.FixedPageStructure>>',
                    defaultValue: false,
                },
                isDefault: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.isDefault>>',
                    defaultValue: true,
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.enabled>>',
                    defaultValue: false,
                },
            },
        },
        NewPageTemplateForSelectEntity: {
            apiPath: '/loader/admin/decorate/templates/options',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<entities.TemplateManage.templateName>>',
                },
                type: {
                    type: 'optionsString',
                    displayName: '<<entities.TemplateManage.templateType>>',
                },
                order: {
                    type: 'number.positive',
                    displayName: '<<entities.TemplateManage.sorting>>',
                },
                preview: {
                    type: 'imageObjectArray',
                    displayConfig: {
                        style: {
                            width: 50,
                        },
                        preview: {
                            visible: true,
                            maxCount: 3,
                            autoplay: true,
                            size: 300,
                        },
                    },
                },
                structureFixed: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.FixedPageStructure>>',
                    defaultValue: false,
                },
            },
        },
        PlatformSearchPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['PLATFORM_SEARCH_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ENABLED', name: '<<beforeOpen>>' },
                        { id: 'DISABLE', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
            },
        },
        StoreSearchPageEntity: {
            apiPath: '/loader/admin/design_page',
            paramsFilter: ['STORE_SEARCH_PAGE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<businessStatus>>',
                    options: [
                        { id: 'ENABLED', name: '<<beforeOpen>>' },
                        { id: 'DISABLE', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
            },
        },
    },
};
