import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;

export const CouponTransferCoverLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = `/admin/member_assets_transfer_configs`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/member_assets_transfer_configs/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    subsites: res.subsites || [],
                    pictures: res.pictures.map((p: any) => {
                        return {
                            ...p,
                            path: p.url,
                        };
                    }),
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = '/admin/member_assets_transfer_configs';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = `/admin/member_assets_transfer_configs/${params.id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
    delete: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = `/admin/member_assets_transfer_configs/${id}`;
        return await api.delete(data, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.name = params.baseInfo.name;
    newParams.pictures = params.baseInfo.pictures;
    newParams.subsites = params.baseInfo.subsites;
    return newParams;
}
