import React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { Table } from 'antd';
import { RoleSelector } from '../../../containers/role-manage/role-selector';
import { services } from '@comall-backend-builder/core';
export class RoleSelectorMode extends ArrayMode {
    public getDisplayComponent(value: any[], displayInfo: any): JSX.Element {
        return (
            <Table
                pagination={false}
                rowKey={'id'}
                bordered={false}
                dataSource={value}
                columns={[
                    {
                        title: services.language.getText('jsmc'),
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: services.language.getText('jsms'),
                        dataIndex: 'remark',
                        key: 'remark',
                    },
                ]}
            />
        );
    }
    public getControlComponent(controlInfo: any) {
        return <RoleSelector {...controlInfo} />;
    }
}
