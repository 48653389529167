import React, { PureComponent } from 'react';
import { Form, Radio } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
/**
 * 会员卡组件-配置表单
 */
class memberCard extends PureComponent<any> {
    render() {
        const { form, sourceRoot, uploadAction } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;

        return (
            <Form className="editor-member-card">
                <FormItem {...formItemLayout} label={services.language.getText('bgPic')}>
                    {getFieldDecorator('bgImage')(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                    <p className="upload-picture-tips">图片建议宽度为750px，高度为170px</p>
                </FormItem>
                <FormItem {...formItemLayout} label={'会员卡等级'}>
                    {getFieldDecorator(
                        'level',
                        {}
                    )(
                        <Radio.Group>
                            <Radio value={'show'}>{services.language.getText('display')}</Radio>
                            <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={'会员码'}>
                    {getFieldDecorator(
                        'code',
                        {}
                    )(
                        <Radio.Group>
                            <Radio value={'show'}>{services.language.getText('display')}</Radio>
                            <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={'会员身份'}>
                    {getFieldDecorator(
                        'identity',
                        {}
                    )(
                        <Radio.Group>
                            <Radio value={'show'}>{services.language.getText('display')}</Radio>
                            <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                {(ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost') && (
                    <FormItem {...formItemLayout} label="会员等级">
                        {getFieldDecorator('memberLevel', {
                            initialValue: 'hide',
                        })(
                            <Radio.Group>
                                <Radio value="show">{services.language.getText('display')}</Radio>
                                <Radio value="hide">{services.language.getText('hidden')}</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                )}
                {(ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost') && (
                    <FormItem {...formItemLayout} label="会员个人简介">
                        {getFieldDecorator('profile', {
                            initialValue: 'hide',
                        })(
                            <Radio.Group>
                                <Radio value="show">{services.language.getText('display')}</Radio>
                                <Radio value="hide">{services.language.getText('hidden')}</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                )}
            </Form>
        );
    }
}

export const MemberCard = tools.ConfigFormDecorator(memberCard);
