import { Config } from '@comall-backend-builder/core/lib/parser';
import { api } from '@comall-backend-builder/core/lib/services';
import { EntityButtonProps } from '../../../../components';
import { ForwardDataCenterModal } from '../../../../services';
import { services } from '@comall-backend-builder/core';
/**
 * 数据处理结果
 */
export enum FileStatus {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 正在处理
     */
    PROCESSING = 'PROCESSING',
    /**
     * 处理完成
     */
    PROCESS_FINISHED = 'PROCESS_FINISHED',
    /**
     * 处理失败（系统异常）
     */
    PROCESS_FAILED = 'PROCESS_FAILED',
}
export const config: Config = {
    entities: {
        photoCreditReviewEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/photoCreditReview',
            filters: {
                memberName: {
                    type: 'string',
                    displayName: '<<yhnc>>',
                    controlConfig: { placeholder: '<<qsryhnc>>' },
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                id: {
                    type: 'string',
                    displayName: '<<jlbm>>',
                    controlConfig: { placeholder: '<<qsrjlbm>>' },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<xtzt>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'PROCESSING', name: '<<ddsh>>' },
                        { id: 'SUCCESS', name: '<<tgsh>>' },
                        { id: 'FAIL', name: '<<wtgsh>>' },
                        { id: 'APPROVED', name: '<<zxcg_1>>' },
                        { id: 'EXCEPTION', name: '<<zxsb>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<yhsjh>>',
                    controlConfig: { placeholder: '<<qsryhsjh>>' },
                },
                cardNo: {
                    type: 'string',
                    displayName: '<<hykh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                cardLevelId: {
                    type: 'string.options.select',
                    displayName: '<<hydj>>',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                        apiPath: '/admin/card_levels?status=ENABLE',
                    },
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<submitTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<jlbm>>',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<yh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<yhsjh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                statusText: {
                    type: 'string.statusBadge',
                    displayName: '<<xtzt>>',
                },
                createTime: {
                    type: 'string.dateTime',
                    displayName: '<<submitTime>>',
                },
                reason: {
                    type: 'string',
                    displayName: '<<shyy>>',
                },
                approvedUser: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                cardNo: {
                    type: 'string',
                    displayName: '<<hykh>>',
                },
                cardLevelName: {
                    type: 'string',
                    displayName: '<<hydj>>',
                },
            },
        },
        photoCreditReviewFromEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/photoCreditReview',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteId: {
                    type: 'array.shop.selector',
                    displayName: '<<subsiteName>>',
                    controlConfig: {
                        type: 'radio',
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'code',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                        valueColumns: [
                            {
                                title: '<<subsiteName>>',
                                dataIndex: 'subSiteName',
                                key: 'subSiteName',
                                width: 80,
                            },
                            {
                                title: '<<merchantName>>',
                                dataIndex: 'code',
                                key: 'code',
                                width: 100,
                            },
                            {
                                title: '<<merchantName>>',
                                dataIndex: 'merchantName',
                                key: 'merchantName',
                                width: 80,
                            },
                            {
                                title: '<<merchantType>>',
                                dataIndex: 'merchantType',
                                key: 'merchantType',
                                width: 80,
                            },
                            {
                                title: '<<merchantStatus>>',
                                dataIndex: 'merchantStatus',
                                key: 'merchantStatus',
                                width: 80,
                            },
                        ],
                        fields: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                        ],
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<pleaseSelectMerchant>>',
                            validator: (rule: any, value: any) => {
                                if (value) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                goodsNum: {
                    type: 'number.integer',
                    displayName: '<<spsl>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                        min: 1,
                        max: 99999,
                    },
                },
                consumptionTime: {
                    type: 'string.dateTime',
                    displayName: '<<xfsj>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                        range: {
                            end: new Date(),
                        },
                    },
                },
                amount: {
                    type: 'number.price',
                    displayName: '<<xfje>>',
                    rules: [
                        { required: true },
                        {
                            validator: (_rule: any, value: any, callback: any) => {
                                if (/^\d+(?:\.\d{1,2})?$/.test(value) && value !== 0) {
                                    return callback();
                                }
                                callback('<<l_zcsr_1>>。');
                            },
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                        max: 99999999999999,
                    },
                },
                reasonSelect: {
                    type: 'string.options.select',
                    displayName: '<<shbtgyy>>',
                    defaultValue: '<<other>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: '/loader/admin/photoCreditReviewReasons',
                    },
                },
                reason: {
                    type: 'string.text.paragraph',
                    displayName: '<<qtyy>>',
                    rules: [
                        {
                            whitespace: true,
                            max: 20,
                            message: '<<l_shbtgyybnwk_1>>',
                        },
                    ],
                    controlConfig: {
                        rows: 4,
                        maxLength: 20,
                        placeholder: '<<l_srdshbtgyy_1>>',
                    },
                },
            },
        },
        photoCreditReviewLogsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/photoCreditReviewLogs',
            properties: {
                id: {
                    type: 'string',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<czsj>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<czzt_1>>',
                    options: [
                        { id: 'PROCESSING', name: '<<ddsh>>' },
                        { id: 'SUCCESS', name: '<<tgsh>>' },
                        { id: 'FAIL', name: '<<wtgsh>>' },
                        { id: 'EXCEPTION', name: '<<zxsb>>' },
                        { id: 'APPROVED', name: '<<zxcg_1>>' },
                    ],
                },
                operatorName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
            },
        },
    },
    components: {
        photoCreditReviewView: {
            component: 'Viewport',
            entity: 'photoCreditReviewEntity',
        },
        photoCreditReviewPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'photoCreditReviewFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<exportSearchResult>>',
                                        onClick: async (
                                            _: any,
                                            entityButtonProps: EntityButtonProps
                                        ): Promise<void> => {
                                            try {
                                                const params: Record<string, any> = Object.assign(
                                                    {},
                                                    entityButtonProps.entity.params,
                                                    entityButtonProps.entity.filters
                                                );

                                                if (params.status === 'ALL') {
                                                    delete params.status;
                                                }

                                                if (params.dateRange) {
                                                    params.startTime = `${params.dateRange.start} 00:00:00`;
                                                    params.endTime = `${params.dateRange.end} 23:59:59`;
                                                    params.dateRange = undefined;
                                                }

                                                if (params.subsiteName && params.subsiteName.id) {
                                                    params.subsiteId = params.subsiteName.id;
                                                    delete params.subsiteName;
                                                }

                                                if (params.registrationSource === FileStatus.ALL) {
                                                    params.registrationSource = undefined;
                                                }

                                                const config = {
                                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                                                    apiPath: '/admin/photo_crediting/export',
                                                };

                                                await api.get(params, config);
                                                ForwardDataCenterModal();
                                            } catch (err) {
                                                // services.errorHandle(err);
                                            }
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'photoCreditReviewTable' },
            ],
        },
        photoCreditReviewFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'memberName',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'id',
                },
                {
                    property: 'status',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'cardNo',
                },
                {
                    property: 'cardLevelId',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        photoCreditReviewTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'id',
                },
                {
                    property: 'memberName',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'cardNo',
                },
                {
                    property: 'cardLevelName',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'statusText',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'reason',
                },
                {
                    property: 'approvedUser',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'PhotoCreditReviewActionComponent',
                    },
                    {
                        type: 'component',
                        component: 'PhotoCraditReviewListSubmitButton',
                    },
                    {
                        type: 'component',
                        component: 'PhotoCreditReviewDisplayButton',
                    },
                    {
                        statusKey: 'row.canEdit',
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/photo-credit-review/logs/{{row.id}}',
                    },
                ],
            },
        },
        photoCreditReviewLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'photoCreditReviewLogsEntity',
            items: [{ component: 'photoCreditReviewLogsTable' }],
        },
        photoCreditReviewLogsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'createTime',
                },
                {
                    property: 'status',
                },
                {
                    property: 'operatorName',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/photo-credit-review',
                    component: 'photoCreditReviewView',
                    breadcrumbName: '<<photoCreditReview>>',
                    privilege: {
                        path: 'photoToCreditApprove',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/logs/:id',
                            component: 'photoCreditReviewLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'photoCreditReviewPage',
                        },
                    ],
                },
            ],
        },
    ],
};
