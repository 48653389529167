import React, { PureComponent } from 'react';
import { Icon, Row, Col, Input } from 'antd';
import { cloneDeep, get } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';

const UploadPicture = tools.UploadPicture;
const LinkType = tools.LinkType;

//卡片导航目录项
export class CardNavSubItem extends PureComponent<{
    sourceRoot: {
        image?: string;
    };
    items?: Array<any>;
    index: number;
    onChange?: (value: any) => void;
    item?: any;
    uploadAction: string;
    linkTypes: Array<{ id: string; name: string }>;
    selector?: any;
}> {
    state = {
        uploadLoading: false,
    };
    change = (item: any) => {
        const { items, onChange, index } = this.props;
        let newItems: any = cloneDeep(items);
        newItems.splice(index, 1, item);
        onChange && onChange(newItems);
    };

    onNameChange = (event: any) => {
        const { item } = this.props;
        let newItem = cloneDeep(item);
        let newValue = { ...newItem, name: event.target.value };
        this.change(newValue);
    };

    removeItem = () => {
        const { items, onChange, index } = this.props;
        let newValue: any = cloneDeep(items);
        newValue.splice(index, 1);
        onChange && onChange(newValue);
    };

    onUpload = (path: any) => {
        const { item } = this.props;
        let newItem = cloneDeep(item);
        newItem.pic = path;
        this.change(newItem);
    };

    render() {
        const { item, linkTypes, uploadAction, selector, sourceRoot } = this.props;
        let type = get(item, 'type');
        if (!type) {
            type = 'open';
        }
        return (
            <Row className="entry-item">
                <Icon type="close" className="item-remove" title="移除" onClick={this.removeItem} />
                <Col className="entry-item-image" span={4}>
                    <UploadPicture
                        value={item.pic}
                        sourceRoot={sourceRoot}
                        onChange={this.onUpload}
                        uploadAction={uploadAction}
                    />
                </Col>
                <Col className="entry-item-content" span={19}>
                    <div className="link-item-field item-children">
                        <label className="field-name">{'目录名称'}： </label>
                        <Input
                            className="field-control"
                            value={get(item, 'name')}
                            onChange={this.onNameChange}
                            minLength={1}
                            maxLength={8}
                        />
                    </div>
                    <LinkType
                        linkTypes={linkTypes}
                        value={item}
                        linkTypeMode="cascader"
                        selector={selector}
                        onChange={(item: any) => {
                            this.change(item);
                        }}
                    />
                </Col>
            </Row>
        );
    }
}
