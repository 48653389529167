import * as React from 'react';
import { Tag, Popover } from 'antd';
import { modes } from '@comall-backend-builder/types';
import './index.less';
/**
 * 优惠券分组查看
 */
export class GroupCouponMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const {
            row: { couponInfos },
        } = displayInfo;
        let couponType = '优惠券';
        if (couponInfos && couponInfos.length && couponInfos[0]['couponType'] === 'PACKAGE') {
            couponType = '优惠券礼包';
        }
        return (
            <div>
                {value}{' '}
                {couponInfos && (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {couponInfos.map((i: any) => (
                                    <Tag key={i.id} style={{ marginBottom: 10 }}>
                                        {i.name}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            共{couponInfos.length}个{couponType}
                        </span>
                    </Popover>
                )}
            </div>
        );
    }
}
