import React, { PureComponent } from 'react';
import { Button, message, Popconfirm, Popover } from 'antd';
import { cloneDeep, isArray, remove, set } from 'lodash';
import { ButtonProps } from 'antd/lib/button';
import moment from 'moment';
import {
    MallActivityTicket,
    MallActivityTicketPrice,
    NewMallActivityRow,
    NewMallActivityMemberCardLevel,
    MallActivityTicketPromotionPrice,
    MallActivityTicketPriceFreeRule,
    ChangeTicket,
    ChangeTicketFiledBySettingTicketRowKeys,
    TicketChangeModalValueMap,
    ShowTicketFiledChangeModal,
    TicketFieldShowModal,
} from '../types';
import { getRowDateTime } from '../common';

import { NewMallActivityTicketsTable } from './new-mall-activity-tickets-table';
import { TableSize } from 'antd/lib/table';

import { NewMallActivityTicketPricesModal } from '../new-mall-activity-ticket-price/new-mall-activity-ticket-price-modal';
import { NewMallActivityTicketPriceFreeRulesModal } from '../new-mall-activity-ticket-price/new-mall-activity-ticket-price-free-rule-modal';
import { NewMallActivityTicketPromotionPriceModal } from '../new-mall-activity-ticket-price/new-mall-activity-ticket-promotion-price-modal';
import { NewMallActivityTicketPriceFreeRulesTable } from '../new-mall-activity-ticket-price/new-mall-activity-ticket-price-free-rules-table';

import {
    PopoverNumberInputButton,
    getCardLevelChangeResult,
    defaultMallActivityTicketPrice,
    defaultMallActivityTicketPriceFreeRule,
    getNewTicketsOnCardlevelsChange,
    handleKeys,
    cloneTicket,
    newTicket,
} from '../common';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface NewMallActivityTicketsProps {
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: MallActivityTicket[] | undefined) => void;
    /**
     * 当前值
     */
    value: MallActivityTicket[];
    disabled?: boolean;
    /**
     * 在其他组件中使用时不传入
     */
    row?: NewMallActivityRow;
    /**
     * 在其他组件内部使用此组件需要传入cardLevels
     */
    cardLevels?: NewMallActivityMemberCardLevel[];
    isAdd: boolean;
    size?: TableSize;
}

interface NewMallActivityTicketsState {
    settingTicketRowKeys?: string[];
    selectedTicketRowKeys: string[];
    allCardLevels?: NewMallActivityMemberCardLevel[];
    limitCardLevels?: string[];
}

/**
 * 新商场活动票档管理
 */
class NewMallActivityTickets extends PureComponent<
    NewMallActivityTicketsProps,
    NewMallActivityTicketsState
> {
    constructor(props: NewMallActivityTicketsProps) {
        super(props);
        this.state = {
            selectedTicketRowKeys: [],
        };
        if (props.row && !props.value?.length) {
            this.onAddTicket();
        }
    }
    componentDidMount(): void {
        const { value = [], row } = this.props;
        if (row) {
            this.checkRowKeys(value);
        }
        this.checkCardLevelChange(row);
    }
    componentWillReceiveProps(nextProps: NewMallActivityTicketsProps) {
        const { row: prevRow } = this.props;
        const { value: nextValue = [], row: nextRow } = nextProps;
        if (nextRow) {
            this.checkRowKeys(nextValue);
        }
        this.checkSelectedRowKeys(nextValue);
        this.checkCardLevelChange(nextRow, prevRow);
    }

    modalRefMap = {
        ticketPrices: React.createRef<NewMallActivityTicketPricesModal>(),
        ticketPriceFreeRules: React.createRef<NewMallActivityTicketPriceFreeRulesModal>(),
        ticketPromotionPrice: React.createRef<NewMallActivityTicketPromotionPriceModal>(),
    };

    checkRowKeys = (value: MallActivityTicket[]) => {
        if (!value.some((i) => !i.rowKey)) {
            return;
        }
        this.changeValue(handleKeys(value));
    };

    checkCardLevelChange = async (nextRow?: NewMallActivityRow, prevRow?: NewMallActivityRow) => {
        if (!nextRow) {
            return;
        }
        const { changed, ...other } = await getCardLevelChangeResult(nextRow, prevRow);
        if (!changed) {
            return;
        }
        this.setState((state) => ({ ...state, ...other }), this.changeTicketsPriceByCardlevels);
    };
    checkSelectedRowKeys = (value: MallActivityTicket[]) => {
        if (value.every((i) => !i.rowKey)) {
            return;
        }
        const { selectedTicketRowKeys } = this.state;
        const keys = value.map((i) => i.rowKey);
        this.onSelectedRowKeysChange(selectedTicketRowKeys.filter((i) => keys.includes(i)));
    };

    onSelectedRowKeysChange = (selectedTicketRowKeys: string[]) => {
        this.setState({ selectedTicketRowKeys });
    };

    private get cardLevels() {
        const { cardLevels } = this.props;
        if (cardLevels) {
            return cardLevels;
        }
        const { allCardLevels = [], limitCardLevels = [] } = this.state;
        return limitCardLevels.length
            ? allCardLevels.filter((level) => limitCardLevels.some((id) => id === level.id))
            : allCardLevels;
    }
    private get disabled() {
        const { disabled, row, isAdd } = this.props;
        if (isAdd) {
            return false;
        }
        if (!row) {
            return disabled;
        }
        const { startDate, startTime } = getRowDateTime(row, isAdd);
        return moment() > moment(`${startDate} ${startTime}`);
    }

    changeTicketsPriceByCardlevels = () => {
        const { value = [] } = this.props;
        const newValue = getNewTicketsOnCardlevelsChange(value, this.cardLevels);
        this.changeValue(newValue);
    };

    changeValue = (value: MallActivityTicket[]) => {
        const { onChange } = this.props;
        // 操作拦固定右侧后，每行的高度变化会导致右侧不对齐，通过触发resize事件来让table重新计算高度
        const event = new Event('resize');
        window.dispatchEvent(event);
        onChange(value);
    };

    changeTicket: ChangeTicket = (field, fieldValue, index) => {
        const { value = [] } = this.props;
        const newValue = cloneDeep(value);
        const ticket = newValue[index];
        if (!ticket) {
            return;
        }
        set(ticket, field, fieldValue);
        this.changeValue(newValue);
    };
    changeTicketFiledBySettingTicketRowKeys: ChangeTicketFiledBySettingTicketRowKeys = (
        field,
        fieldValue
    ) => {
        const { settingTicketRowKeys } = this.state;
        if (!settingTicketRowKeys?.length) {
            return;
        }
        const { value = [] } = this.props;
        const newValue = cloneDeep(value);
        newValue.forEach((item) => {
            if (settingTicketRowKeys?.includes(item.rowKey!)) {
                item[field] = cloneDeep(fieldValue);
            }
        });
        this.changeValue(newValue);
    };

    setSettingTicketRowKeys = (ticket?: MallActivityTicket) => {
        const { selectedTicketRowKeys } = this.state;
        if (ticket) {
            this.setState({ settingTicketRowKeys: [ticket.rowKey!] });
        } else if (!selectedTicketRowKeys?.length) {
            message.warn(language.getText('qxxzpd'));
            return false;
        } else {
            this.setState({ settingTicketRowKeys: [...selectedTicketRowKeys] });
        }
        return true;
    };
    defaultModalValueMap: Required<TicketChangeModalValueMap> = {
        ticketPrices: [{ ...defaultMallActivityTicketPrice }],
        ticketPriceFreeRules: [{ ...defaultMallActivityTicketPriceFreeRule }],
        ticketPromotionPrice: {},
    };
    showModal: ShowTicketFiledChangeModal = (type, ticket) => {
        if (!this.setSettingTicketRowKeys(ticket)) {
            return;
        }
        let value: TicketChangeModalValueMap[typeof type] | undefined = ticket?.[type];
        if (!value || (isArray(value) && !value.length)) {
            value = this.defaultModalValueMap[type];
        }
        const showModal = this.modalRefMap[type].current?.showModal as TicketFieldShowModal<
            TicketChangeModalValueMap[typeof type]
        >;
        if (showModal) {
            showModal(cloneDeep(value));
        }
    };
    /**
     * 票档价格变更
     * @param prices 票档价格信息
     */
    changeTicketPrices = (prices: MallActivityTicketPrice[]) => {
        this.changeTicketFiledBySettingTicketRowKeys('ticketPrices', prices);
    };
    /**
     * 票档活动价格变更
     * @param promotionPrices 票档活动价格信息
     */
    changeTicketPromotionPrice = (promotionPrices: MallActivityTicketPromotionPrice) => {
        this.changeTicketFiledBySettingTicketRowKeys('ticketPromotionPrice', promotionPrices);
    };
    /**
     * 票档价格变更
     * @param prices 票档价格信息
     */
    changeTicketPriceFreeRules = (ticketPriceFreeRules: MallActivityTicketPriceFreeRule[]) => {
        this.changeTicketFiledBySettingTicketRowKeys('ticketPriceFreeRules', ticketPriceFreeRules);
    };

    onRemove = (index: number) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        remove(newValue, (i, idx) => idx === index);
        this.changeValue(newValue);
    };
    onCopy = (index: number) => {
        const { value } = this.props;
        this.changeValue([...cloneDeep(value), cloneTicket(value[index])]);
    };
    changeStatus = (index: number) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        newValue[index].enable = !newValue[index].enable;
        this.changeValue(newValue);
    };

    renderTicketPriceFreeRulesButton = (record: MallActivityTicket) => {
        const style = {
            padding: '0 3px',
        };
        const { ticketPriceFreeRules } = record;
        const hasValue = ticketPriceFreeRules?.some((i) => i.freeQuota);
        const buttonProps: ButtonProps = {
            style,
            type: 'link',
            onClick: () => this.showModal('ticketPriceFreeRules', record),
        };
        if (!hasValue) {
            return <Button {...buttonProps}>{language.getText('szmfme')}</Button>;
        }
        const popoverContent = (
            <div className="new-mall-activity__tickets-table__price-popover">
                <NewMallActivityTicketPriceFreeRulesTable
                    disabled
                    value={ticketPriceFreeRules!}
                    cardLevels={this.cardLevels}
                    size="small"
                />
            </div>
        );
        return (
            <Popover content={popoverContent}>
                <Button {...buttonProps}>{language.getText('xgmfme')}</Button>
            </Popover>
        );
    };

    renderActionColum = (_: any, record: MallActivityTicket, index: number) => {
        const buttonStyle = {
            padding: '0 3px',
        };
        // 如果历史票档有销量则不可删除，没有id则是本次编辑新增的票档可以删除
        // const undeletable = Boolean(record.id) && (this.disabled || Boolean(record.soldQuantity));
        const disabled = Boolean(record.id) && this.disabled;
        const onClick = Boolean(record.id)
            ? () => this.changeStatus(index)
            : () => this.onRemove(index);
        return (
            <>
                <span className="new-mall-activity__flex">
                    <Button style={buttonStyle} type={'link'} onClick={() => this.onCopy(index)}>
                        {language.getText('copy')}
                    </Button>
                    <Button disabled={disabled} style={buttonStyle} type={'link'} onClick={onClick}>
                        {Boolean(record.id)
                            ? record.enable
                                ? language.getText('disabled')
                                : language.getText('enable')
                            : language.getText('common.delete')}
                    </Button>
                </span>
                {this.renderTicketPriceFreeRulesButton(record)}
            </>
        );
    };

    getActionColum = () => {
        const { size } = this.props;
        return {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: size === 'small' ? 108 : 123,
            fixed: 'right' as const,
            render: this.renderActionColum,
        };
    };

    onAddTicket = () => {
        const { value = [] } = this.props;
        this.changeValue([...cloneDeep(value), newTicket()]);
    };

    batchRemove = () => {
        const { selectedTicketRowKeys: selectedRowKeys } = this.state;
        if (!selectedRowKeys?.length) {
            message.info(language.getText('qxzxyplscdpd'));
            return;
        }
        const { value } = this.props;
        const newValue = cloneDeep(value);
        const removeList = remove(newValue, (i) => selectedRowKeys.includes(String(i.rowKey)));
        if (removeList.some((i) => Boolean(i.soldQuantity))) {
            message.info(language.getText('cssmjldpdwfsc'));
            return;
        }
        this.changeValue(newValue);
        this.setState({ selectedTicketRowKeys: [] });
    };

    batchChangeTicket = (
        field: keyof MallActivityTicket,
        fieldValue: MallActivityTicket[keyof MallActivityTicket]
    ) => {
        const { value = [] } = this.props;
        const { selectedTicketRowKeys: selectedRowKeys } = this.state;
        const newValue = cloneDeep(value);
        newValue.forEach((i) => {
            if (selectedRowKeys.includes(i.rowKey!)) {
                set(i, field, fieldValue);
            }
        });
        this.changeValue(newValue);
    };

    private get batchChangeStockMin() {
        const { value = [] } = this.props;
        const { selectedTicketRowKeys: selectedRowKeys } = this.state;
        const selectedRows = selectedRowKeys?.length
            ? value?.filter((i) => selectedRowKeys.includes(i.rowKey!))
            : [];
        return selectedRows.length ? Math.min(...selectedRows.map((i) => i.soldQuantity || 0)) : 0;
    }

    renderFooter = () => {
        const { selectedTicketRowKeys: selectedRowKeys } = this.state;
        const batchChangeStockPorps = {
            disabled: !selectedRowKeys?.length,
            disabledMsg: language.getText('qxxzpd'),
            min: this.batchChangeStockMin,
        };
        return (
            <div className="new-mall-activity__footer">
                <Button onClick={this.onAddTicket} type="primary">
                    {language.getText('zjpd')}
                </Button>
                {!this.disabled ? (
                    <Popconfirm
                        onConfirm={this.batchRemove}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                ) : null}
                <Button onClick={() => this.showModal('ticketPrices')} type="default">
                    {language.getText('plszsj')}
                </Button>
                <Button onClick={() => this.showModal('ticketPromotionPrice')} type="default">
                    {language.getText('plszhdj')}
                </Button>
                <PopoverNumberInputButton
                    text={language.getText('plszsl')}
                    onSubmit={(num) => this.batchChangeTicket('totalStock', num)}
                    {...batchChangeStockPorps}
                />
            </div>
        );
    };

    render() {
        const { value, size, isAdd } = this.props;
        const actionColum = this.getActionColum();
        return (
            <>
                <NewMallActivityTicketsTable
                    value={value}
                    cardLevels={this.cardLevels}
                    changeTicket={this.changeTicket}
                    disabled={this.disabled}
                    actionColum={actionColum}
                    showModal={this.showModal}
                    onSelectedRowKeysChange={this.onSelectedRowKeysChange}
                    size={size}
                    showSalesQuantity={!isAdd}
                />
                {this.renderFooter()}
                <NewMallActivityTicketPricesModal
                    ref={this.modalRefMap.ticketPrices}
                    cardLevels={this.cardLevels}
                    onSubmit={this.changeTicketPrices}
                />
                <NewMallActivityTicketPriceFreeRulesModal
                    ref={this.modalRefMap.ticketPriceFreeRules}
                    cardLevels={this.cardLevels}
                    onSubmit={this.changeTicketPriceFreeRules}
                />
                <NewMallActivityTicketPromotionPriceModal
                    ref={this.modalRefMap.ticketPromotionPrice}
                    onSubmit={this.changeTicketPromotionPrice}
                />
            </>
        );
    }
}

export { NewMallActivityTickets };
