import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const SharePageActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/share_page_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/share_page_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsites: res.subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.ruleInfo = {
                    pageInfo: res.pageInfo,
                    rewards: res.rewards,
                };
                const picture = res.backgroundPicture;
                res.pageConfig = {
                    backgroundPicture:
                        picture && picture.id
                            ? [
                                  {
                                      id: picture.id,
                                      path: picture.url,
                                  },
                              ]
                            : [
                                  {
                                      id: '0',
                                      path:
                                          'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/magic-backend/share-page-activity/default-background.png',
                                  },
                              ],
                    description: res.description,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/share_page_activities';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const picutre = params.pageConfig.backgroundPicture[0];
    let newParams: any = {
        ...params.baseInfo,
        ...params.ruleInfo,
        ...params.pageConfig,
        startTime: params.baseInfo.dateRange.start,
        endTime: params.baseInfo.dateRange.end,
        backgroundPicture: {
            id: picutre.id,
            url: picutre.path,
        },
        marketingScheduleId:
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null,
    };
    delete newParams.dateRange;
    return newParams;
}
