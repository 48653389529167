import React, { PureComponent } from 'react';
import { map, cloneDeep, remove, isEmpty } from 'lodash';
import { Tag, Popconfirm, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SelectMallActivity } from '../custom-select-mall-activity/select-mall-activity';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export class SelectActivities extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    removeActivity = (item: any) => {
        const { onChange, value } = this.props;
        let newValue = cloneDeep(value);
        remove(newValue, { id: item.id });
        onChange(newValue);
    };

    clearAll = () => {
        const { onChange } = this.props;
        onChange([]);
    };

    renderActivities(activities: any) {
        const isDragDropDisabled = activities.length === 1;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable isDropDisabled={isDragDropDisabled} droppableId="activity-list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {map(activities, (item, index) =>
                                this.renderActivity(item, index, isDragDropDisabled)
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    renderActivity = (item: any, index: any, isDragDisabled: any) => {
        const removeActivity = this.removeActivity;
        return (
            <Draggable
                isDragDisabled={isDragDisabled}
                draggableId={'activity-' + index}
                key={index}
                index={index}
            >
                {(provided: any) => {
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Tag key={item.id} closable onClose={() => removeActivity(item)}>
                                {item.name}
                            </Tag>
                        </div>
                    );
                }}
            </Draggable>
        );
    };
    onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const { value, onChange } = this.props;
        let newValue = cloneDeep(value);
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = newValue.splice(startIndex, 1);
        newValue.splice(endIndex, 0, removed);

        onChange(cloneDeep(newValue));
    };
    showModal = () => {
        this.setState({ showModal: true });
    };
    hideModal = () => {
        this.setState({ showModal: false });
    };
    onOk = (value: any[] = []) => {
        const { onChange, value: oldValue = [] } = this.props;
        const newValue = Array.from(
            [oldValue, value.map(({ id, name }: any) => ({ id, name }))]
                .flat()
                .reduce(
                    (map: Map<number, any>, item) => map.set(item.id, item),
                    new Map<number, any>()
                )
                .values()
        );
        onChange(newValue);
        this.hideModal();
    };
    render() {
        const { value } = this.props;
        const { showModal } = this.state;
        const hasActivities = !isEmpty(value);
        const activitiesProps = {
            value: value,
        };
        return (
            <div>
                {this.renderActivities(value)}
                {hasActivities && (
                    <Popconfirm
                        title="确定清空活动吗?"
                        onConfirm={this.clearAll}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button type={'link'}>清空活动</Button>
                    </Popconfirm>
                )}

                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    选择活动
                </Button>

                <SelectMallActivity
                    onChange={this.onOk}
                    onCancel={this.hideModal}
                    showModal={showModal}
                    selectType="checkbox"
                    {...activitiesProps}
                />
            </div>
        );
    }
}
