import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { find, isNumber, isPlainObject } from 'lodash';

export const config: Config = {
    entities: {
        ReserveDrawLotsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/reserveDrawLotsActivities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                dateRange: {
                    type: 'string',
                    displayName: '<<acitivityTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdsz>>',
                    properties: {
                        executeStatus: {
                            type: 'string',
                            displayName: '<<status>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                                format: 'YYYY-MM-DD',
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'array',
                                },
                            ],
                        },
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                selectionType: 'radio',
                            },
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<gzsm>>',
                            rules: [
                                {
                                    max: 5000,
                                },
                            ],
                            controlConfig: {
                                rows: 4,
                                maxLength: 5000,
                            },
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                subsiteField: 'activityInfo.subsites',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.activityInfo?.subsites?.length &&
                                        row.activityInfo.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                roundInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdcc>>',
                    properties: {
                        period: {
                            type: 'string.options.radio',
                            displayName: '<<cczq>>',
                            defaultValue: 'DAY',
                            options: [
                                {
                                    id: 'DAY',
                                    name: '<<mryc>>',
                                },
                            ],
                        },
                        reserveTime: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<bmsj>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxbmsj>>',
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                            },
                            extra: '<<cybmdyhckycycj>>',
                        },
                        drawTime: {
                            type: 'string.time',
                            displayName: '<<xtzxcqsj>>',
                            rules: [
                                {
                                    required: true,
                                    displayName: '<<qtxxtzxcqsj>>',
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                            },
                            extra: '<<xtdcgbmdyhjxzdcq>>',
                        },
                        purchaseTime: {
                            type: 'number.tip',
                            displayName: '<<gmsj>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxgmsj>>',
                                },
                                {
                                    message: '<<qtxdydy>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isNumber(value)) {
                                            return true;
                                        }
                                        return /^[1-9][0-9]*$/.test(String(value));
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<cqjsh>>',
                                addonafter: '<<xsngm>>',
                                style: {
                                    width: '140px',
                                },
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<pzhdgmsjzjzhbdcgdr>>',
                        },
                        ballotLimit: {
                            type: 'object.checkboxWithValueGroup',
                            displayName: '<<zqrs>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxzqrs>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isPlainObject(value)) {
                                            return false;
                                        }
                                        const keys = Object.keys(value).filter((key) =>
                                            Boolean(value[key] && value[key].checked)
                                        );
                                        if (!keys.length) {
                                            return false;
                                        }
                                        return keys.every((key) => isNumber(value[key].value));
                                    },
                                },
                                {
                                    message: '<<qtxdydydzzs>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isPlainObject(value)) {
                                            return true;
                                        }
                                        const keys = Object.keys(value).filter((key) =>
                                            Boolean(value[key] && value[key].checked)
                                        );
                                        if (!keys.length) {
                                            return true;
                                        }
                                        return keys.every(
                                            (key) =>
                                                !isNumber(value[key].value) ||
                                                /^[1-9][0-9]*$/.test(String(value[key].value))
                                        );
                                    },
                                },
                            ],
                            controlConfig: {
                                properties: [
                                    {
                                        type: 'number.tip',
                                        key: 'memberCount',
                                        controlConfig: {
                                            min: 0,
                                            style: { width: '140px' },
                                            addonbefore: '<<bmyhzq>>',
                                            addonafter: '<<people>>',
                                            rules: [
                                                {
                                                    type: 'number',
                                                },
                                            ],
                                            placeholder: '<<inputPlease>>',
                                        },
                                    },
                                ],
                            },
                        },
                        quantityLimit: {
                            type: 'number.tip',
                            displayName: '<<mcxg>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxmcxg>>',
                                },
                                {
                                    message: '<<qtxdydy>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isNumber(value)) {
                                            return true;
                                        }
                                        return /^[1-9][0-9]*$/.test(String(value));
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<mchdmyhzdgm>>',
                                addonafter: '<<jian>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 10,
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        maxDrawCount: {
                            type: 'number.tip',
                            displayName: '<<myhzqcs>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxmyhzqcs>>',
                                },
                                {
                                    message: '<<qtxdydy>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isNumber(value)) {
                                            return true;
                                        }
                                        return /^[1-9][0-9]*$/.test(String(value));
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdqjmyhzq>>',
                                addonafter: '<<frequency>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 10,
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<yhzhdqjmzzqcsh>>',
                        },
                    },
                },
                seniorInfo: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        participateCondition: {
                            type: 'object.participateCondition',
                            displayName: '<<bmtj>>',
                            controlConfig: {
                                codes: [
                                    {
                                        code: 'MINREGISTERDAYS',
                                        tip: '<<dckbmcg>>',
                                    },
                                    {
                                        code: 'CONSUMPTIONT',
                                        tip: '<<zbsyhmyzsxf>>',
                                    },
                                    {
                                        code: 'CERTIFICATION',
                                    },
                                ],
                            },
                        },
                        receiptCertification: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<gmtj>>',
                            defaultValue: [],
                            options: [
                                {
                                    id: 'NEED',
                                    name: '<<shrxxwyhsmrzdxx>>',
                                },
                            ],
                            extra: '<<zshrxxywyh>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        ReserveDrawLotsView: {
            component: 'Viewport',
        },
        ReserveDrawLotsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ReserveDrawLotsEntity',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ReserveDrawLotsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/reserve-draw-lots/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'ReserveDrawLotsTable',
                },
            ],
        },
        ReserveDrawLotsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        ReserveDrawLotsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'dateRange',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOTSTARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath:
                                                '/admin/reserve_draw_lots_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath:
                                                '/admin/reserve_draw_lots_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/menu-list/market/reserve-draw-lots/edit/{{row.id}}',
                            },
                            {
                                text: '<<common.log>>',
                                type: 'link',
                                path: '/menu-list/market/reserve-draw-lots/logs/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        ReserveDrawLotsAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ReserveDrawLotsEntity',
            items: [
                {
                    component: 'ReserveDrawLotsAddForm',
                },
            ],
        },
        ReserveDrawLotsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 920 },
            fields: [
                {
                    property: 'activityInfo.name',
                },
                {
                    property: 'activityInfo.dateRange',
                },
                {
                    property: 'activityInfo.subsites',
                },
                {
                    property: 'activityInfo.marketingSchedule',
                },
                {
                    property: 'activityInfo.goods',
                },
                {
                    property: 'activityInfo.description',
                },

                {
                    property: 'roundInfo.period',
                },
                {
                    property: 'roundInfo.reserveTime',
                },
                {
                    property: 'roundInfo.drawTime',
                },
                {
                    property: 'roundInfo.purchaseTime',
                },
                {
                    property: 'roundInfo.ballotLimit',
                },
                {
                    property: 'roundInfo.quantityLimit',
                },
                {
                    property: 'roundInfo.maxDrawCount',
                },
                {
                    property: 'seniorInfo.participateCondition',
                },
                {
                    property: 'seniorInfo.receiptCertification',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //校验报名条件配置输入值
                const participateCondition = entity.seniorInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const minRegisterDays: any = find(participateCondition, {
                        code: 'MINREGISTERDAYS',
                    });
                    const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
                    if (minRegisterDays.checked && !minRegisterDays.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                    if (consumption.checked && !consumption.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ReserveDrawLotsEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ReserveDrawLotsEditForm',
                },
            ],
        },
        ReserveDrawLotsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ReserveDrawLotsEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 920 },
            loaderType: 'get',
            fields: [
                {
                    property: 'activityInfo.name',
                },
                {
                    property: 'activityInfo.dateRange',
                },
                {
                    property: 'activityInfo.subsites',
                },
                {
                    property: 'activityInfo.marketingSchedule',
                },
                {
                    property: 'activityInfo.goods',
                },
                {
                    property: 'activityInfo.description',
                    controlConfig: {
                        disabled: true,
                    },
                },

                {
                    property: 'roundInfo.period',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.reserveTime',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.drawTime',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.purchaseTime',
                    controlConfig: {
                        addonbefore: '<<cqjsh>>',
                        addonafter: '<<xsngm>>',
                        style: { width: '140px' },
                        placeholder: '<<inputPlease>>',
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.ballotLimit',
                },
                {
                    property: 'roundInfo.quantityLimit',
                },
                {
                    property: 'roundInfo.maxDrawCount',
                },
                {
                    property: 'seniorInfo.participateCondition',
                },
                {
                    property: 'seniorInfo.receiptCertification',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //校验报名条件配置输入值
                const participateCondition = entity.seniorInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const minRegisterDays: any = find(participateCondition, {
                        code: 'MINREGISTERDAYS',
                    });
                    const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
                    if (minRegisterDays.checked && !minRegisterDays.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                    if (consumption.checked && !consumption.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ReserveDrawLotsLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                    apiPath: '/admin/reserve_draw_lots_activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/reserve-draw-lots',
                    component: 'ReserveDrawLotsView',
                    breadcrumbName: '<<yycq>>',
                    privilege: {
                        path: 'reserveDrawLots',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ReserveDrawLotsAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ReserveDrawLotsEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'ReserveDrawLotsLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'ReserveDrawLotsPage',
                        },
                    ],
                },
            ],
        },
    ],
};
