import { connect } from 'react-redux';
import { defaults, mapValues, map, get, set, debounce } from 'lodash';
import { DataForm } from '@comall-backend-builder/components-basis';
import { actions } from '@comall-backend-builder/core';

function mapDispatchToProps(dispatch: any, props: any) {
    const {
        componentId,
        entity,
        fields: fieldsFromProps,
        onInit,
        onSubmit,
        onReset,
        onReloadOptions,
    } = props;

    const handleInit = () => {
        let {
            id: entityId,
            metainfo: { properties },
            fields: entityFields,
        } = entity;
        const loaded = !!entityFields;

        let fields: { [key: string]: any } = {};
        map(fieldsFromProps, (field) => {
            let { property } = field;

            // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
            let entityProperty = get(properties, property.replace(/\./g, '.properties.'));
            if (entityProperty) {
                set(fields, property, get(entityFields, property));
            } else {
                throw new Error(`Property ${property} not found in Entity ${entityId}`);
            }
        });

        dispatch(
            actions.formInitAction(componentId, {
                type: 'EditForm',
                entityId: entity.id,
                fields,
                loaded,
            })
        );
    };

    return defaults(
        {
            onInit,
            onSubmit,
            onReset,
            onReloadOptions,
        },
        {
            // 初始化表单结构
            onInit: handleInit,

            // 提交表单
            onSubmit: debounce((_event: React.SyntheticEvent | undefined, fields: any) => {
                if (_event) {
                    _event.stopPropagation();
                }

                const entityFields = mapValues(fields, (field, _name) => {
                    return field.value;
                });
                entity.modify(entityFields, props.params);
            }, 300),
            onSubmitStart: () => {
                dispatch(actions.componentSubmitAction(componentId, 'modify'));
            },
            onReset: handleInit,
        }
    );
}

export const ModifyForm = connect(null, mapDispatchToProps)(DataForm);
