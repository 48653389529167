import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { SharePageActivityRewards } from './share-page-activity-rewards';
import { SharePageActivityRewardsDisplay } from './share-page-activity-rewards-display';

export class SharePageActivityRewardsMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return object && <SharePageActivityRewardsDisplay value={object} {...displayInfo} />;
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SharePageActivityRewards {...controlInfo} />;
    }
}
