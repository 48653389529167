import React, { PureComponent } from 'react';
import { some, get } from 'lodash';
import { CouponPackageSelectorDisplay } from '../coupon-package-selector-display';
import { CouponSelectedDisplay } from '../coupon-selected-display';

/**
 * 优惠券/礼包展示和内容编辑
 */
export class CouponSelectorWithPackageDisplay extends PureComponent<any> {
    render() {
        const {
            data,
            disabled,
            onChange,
            disablePrice,
            xScrollWidth,
            canChangeQuantity,
            canChangeQuantityTitle,
            hideIssuedNum,
            showLeftStock,
        } = this.props;
        const isPackage = some(data, (item) => get(item, 'couponType') === 'PACKAGE');
        return (
            <div>
                {isPackage ? (
                    <CouponPackageSelectorDisplay
                        disablePrice={disablePrice}
                        data={data}
                        disabled={disabled}
                        onChange={onChange}
                        xScrollWidth={xScrollWidth}
                        hideIssuedNum={hideIssuedNum}
                    />
                ) : (
                    <CouponSelectedDisplay
                        canChangeQuantity={!!canChangeQuantity}
                        canChangeQuantityTitle={canChangeQuantityTitle}
                        data={data}
                        onRemove={onChange}
                        disabled={disabled}
                        xScrollWidth={xScrollWidth}
                        hideIssuedNum={hideIssuedNum}
                        showLeftStock={showLeftStock}
                    />
                )}
            </div>
        );
    }
}
