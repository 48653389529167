import React, { PureComponent } from 'react';
import { InputNumber, Select } from 'antd';
import { get, isEqual } from 'lodash';
import { SessionType } from '../../../../containers';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const { Option } = Select;

export enum TimeUnit {
    /**
     * 小时
     */
    HOUR = 'HOUR',
    /**
     * 分钟
     */
    MINUTE = 'MINUTE',
}

interface MallActivitySubscriptMessageSettingValue {
    timeUnit: TimeUnit;
    num: number | undefined;
}

interface MallActivitySubscriptMessageSettingProps {
    row: any;
    onChange: (value: MallActivitySubscriptMessageSettingValue) => void;
    value: MallActivitySubscriptMessageSettingValue;
}

const defalutNum = 24;
export class MallActivitySubscriptMessageSetting extends PureComponent<
    MallActivitySubscriptMessageSettingProps
> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange({
                timeUnit: TimeUnit.HOUR,
                num: defalutNum,
            });
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { onChange } = nextProps;
        const currentSession = get(this.props, 'row.registrationInfo.session', SessionType.NO);
        const nextSession = get(nextProps, 'row.registrationInfo.session', SessionType.NO);
        if (!isEqual(currentSession, nextSession) && nextSession) {
            onChange({
                timeUnit: TimeUnit.HOUR,
                num: defalutNum,
            });
        }
    }

    numChange = (val: any) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ timeUnit: TimeUnit.HOUR, num: val });
        } else {
            onChange && onChange({ ...value, num: val });
        }
    };
    unitChange = (val: any) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ timeUnit: val, num: defalutNum });
        } else {
            onChange && onChange({ ...value, timeUnit: val });
        }
    };
    render() {
        const { value } = this.props;
        const timeUnit = get(value, 'timeUnit');
        const num = get(value, 'num');
        const currentSession = get(this.props, 'row.registrationInfo.session', SessionType.NO);
        const text =
            currentSession === SessionType.NO
                ? language.getText('hdksq')
                : language.getText('ccksq');
        return (
            <div className="registration-time-components">
                <span>{text}</span>
                <InputNumber
                    value={num}
                    onChange={this.numChange}
                    placeholder={services.language.getText('inputPlease')}
                    style={{ width: '140px', margin: '0 5px' }}
                    min={1}
                    max={99999999999}
                    step={1}
                    precision={0}
                />
                <Select
                    placeholder={services.language.getText('selectPlease')}
                    style={{ width: '90px', marginRight: '5px' }}
                    onChange={this.unitChange}
                    value={timeUnit}
                >
                    <Option value={TimeUnit.HOUR}>{language.getText('hour')}</Option>
                    <Option value={TimeUnit.MINUTE}>{services.language.getText('minutes')}</Option>
                </Select>
                <span>{language.getText('tx_2')}</span>
            </div>
        );
    }
}
