import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';

const TableComponentId = 'CGBProductSelectorDataTable';
let Entity: any = {};
let entity: any = {};

/**
 * 筛选项集合
 */
const filterFieldsList = [
    { property: 'activityProductTypes' },
    { property: 'subsiteId' },
    { property: 'merchantId' },
    { property: 'keywords' },
    { property: 'supplyChain' },
    { property: 'tradeType' },
];

/**
 * 列表项集合
 */
const tableColumnsList = [
    {
        property: 'productInfo',
        width: 350,
    },
    {
        property: 'brandName',
    },
    {
        property: 'goodsMvo.subsiteName',
    },
    {
        property: 'goodsMvo.merchantName',
    },
    {
        property: 'goodsMvo.stock',
    },
    {
        property: 'goodsMvo.productStyleName',
    },
    {
        property: 'tradeType',
    },
];

const defaultFilterFields = [
    { property: 'keywords' },
    { property: 'subsiteId' },
    { property: 'merchantId' },
];

const defaultTableColumns = [
    {
        property: 'productInfo',
        width: 350,
    },
    {
        property: 'brandName',
    },
    {
        property: 'goodsMvo.subsiteName',
    },
    {
        property: 'goodsMvo.merchantName',
    },
];

/**
 * 选择商品(共用弹层)
 * modalTitle 弹层标题 默认值 选择商品
 * buttonText 按钮文案
 * needSubsites 是否需要先选择门店 默认不需要false
 * icon 按钮图标
 * columns 列表项
 * filterFields 筛选项内容
 */
class productSelector extends PureComponent<{
    onChange: (values: any[]) => void;
    type?: 'link' | 'default' | 'ghost' | 'primary' | 'dashed' | 'danger' | undefined;
    params?: any;
    modalTitle?: string;
    buttonText?: string;
    icon?: any;
    needSubsites?: boolean;
    requestStatus: string;
    rowSelectionType?: string;
    filterFields?: any;
    columns?: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CustomDesignProductSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows: any[] = [];

    onTableRowSelect = (record: any, selected: boolean) => {
        const { rowSelectionType } = this.props;
        if (rowSelectionType === 'radio') {
            this.selectedRows = [record];
        } else {
            if (selected) {
                this.selectedRows.push(record);
            } else {
                remove(this.selectedRows, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            setTimeout(() => {
                this.dispatch(
                    actions.tableRowSelectionChangeAction(TableComponentId, map(selectedRows, 'id'))
                );
            }, 300);
        }
    }

    componentWillUnmount() {
        // this.dispatch(actions.unmountEntityAction(entity));
    }

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        let { params, rowSelectionType, filterFields, columns } = this.props;
        const productColumns =
            columns && columns.map((i: any) => tableColumnsList.find((j: any) => j.property === i));
        const productFields =
            filterFields &&
            filterFields.map((i: any) => filterFieldsList.find((j: any) => j.property === i));
        if (!rowSelectionType) {
            rowSelectionType = 'checkbox';
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right', marginBottom: '20px' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, params, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields: productFields || defaultFilterFields,
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,

                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: rowSelectionType,
                        selectedRowKeys: this.selectedRows,
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: productColumns || defaultTableColumns,
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;

        const { params = {}, needSubsites } = this.props;
        const { subsiteIds } = params;
        if (needSubsites && (!subsiteIds || subsiteIds.length === 0)) {
            message.warning(services.language.getText('selectBeforeMerchant'));
            return;
        }

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const {
            icon,
            modalTitle = services.language.getText('productSelectRequired'),
            buttonText = services.language.getText('xzsp_1'),
            type = 'link',
        } = this.props;
        const { visible } = this.state;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 1000,
            title: modalTitle,
            visible: visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                <Button icon={icon} type={type} onClick={this.toggleModal}>
                    {buttonText}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const ProductSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(productSelector);
