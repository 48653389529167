let time = new Date().getTime() - 24 * 60 * 60 * 1000;
let yesterday: any = new Date(time);
let yesterdayDate =
    yesterday.getFullYear() +
    '-' +
    (yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : '0' + (yesterday.getMonth() + 1)) +
    '-' +
    (yesterday.getDate() > 9 ? yesterday.getDate() : '0' + yesterday.getDate());

export const config = {
    entities: {
        MerchantSaleBillEntity: {
            apiPath: '/loader/admin/merchant_bill',
            paramsFilter: ['saleBill'],
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<jysj>>',
                    defaultValue: { start: yesterdayDate, end: yesterdayDate },
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            },
            properties: {
                id: { type: 'string' },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                totalSalesAmount: {
                    type: 'number',
                    displayName: '<<xsze>>',
                },
                totalRefundAmount: {
                    type: 'number',
                    displayName: '<<tkze>>',
                },
                totalGoodsAmount: {
                    type: 'number',
                    displayName: '<<spze>>',
                },
                totalFreightAmount: {
                    type: 'number',
                    displayName: '<<yfze>>',
                },
                totalCouponAmount: {
                    type: 'number',
                    displayName: '<<yhqze>>',
                },
                totalBalanceCouponAmount: {
                    type: 'number',
                    displayName: '<<sfyhqze>>',
                },
                totalPointAmount: {
                    type: 'number',
                    displayName: '<<jfze>>',
                },
                totalGoldAmount: {
                    type: 'number',
                    displayName: '<<jbze>>',
                },
                totalPlatformIncome: {
                    type: 'number',
                    displayName: '<<ptsr>>',
                },
                totalProcedureFee: {
                    type: 'number',
                    displayName: '<<zfsxf>>',
                },
                totalMerchantSettlementAmount: {
                    type: 'number',
                    displayName: '<<zjjsje>>',
                },
            },
        },
        MerchantBookkeepingBillEntity: {
            apiPath: '/loader/admin/merchant_bill',
            paramsFilter: ['bookkeepingBill'],
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<createTime>>',
                    defaultValue: { start: yesterdayDate, end: yesterdayDate },
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            },
            properties: {
                id: { type: 'string' },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                totalSalesAmount: {
                    type: 'number',
                    displayName: '<<xsze>>',
                },
                totalRefundAmount: {
                    type: 'number',
                    displayName: '<<tkze>>',
                },
                totalGoodsAmount: {
                    type: 'number',
                    displayName: '<<spze>>',
                },
                totalFreightAmount: {
                    type: 'number',
                    displayName: '<<yfze>>',
                },
                totalCouponAmount: {
                    type: 'number',
                    displayName: '<<yhqze>>',
                },
                totalBalanceCouponAmount: {
                    type: 'number',
                    displayName: '<<sfyhqze>>',
                },
                totalPointAmount: {
                    type: 'number',
                    displayName: '<<jfze>>',
                },
                totalGoldAmount: {
                    type: 'number',
                    displayName: '<<jbze>>',
                },
                totalPlatformIncome: {
                    type: 'number',
                    displayName: '<<ptsr>>',
                },
                totalProcedureFee: {
                    type: 'number',
                    displayName: '<<zfsxf>>',
                },
                totalMerchantSettlementAmount: {
                    type: 'number',
                    displayName: '<<zjjsje>>',
                },
            },
        },
        JiuQuSaleBillEntity: {
            // apiPath: '/loader/admin/merchant_bill',
            apiPath: '/loader/admin/supply_chain/trade_records',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 250 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 250 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<bookkeepTime>>',
                    defaultValue: { start: yesterdayDate, end: yesterdayDate },
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputOrderNumber>>', style: { width: 223 } },
                },
            },
            properties: {
                id: { type: 'string' },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                productCode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                orderTypeName: {
                    type: 'string',
                    displayName: '<<orderTypeName>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<serviceApplyNo>>',
                },
                supplierName: {
                    type: 'string',
                    displayName: '<<supplierName>>',
                },
                tradeTypeName: {
                    type: 'string',
                    displayName: '<<tradeTypeName>>',
                },
                quantity: {
                    type: 'number',
                    displayName: '<<number>>',
                },
                price: {
                    type: 'number',
                    displayName: '<<singlePrice>>',
                },
                discountAmount: {
                    type: 'number',
                    displayName: '<<discountAmount>>',
                },
                couponAmount: {
                    type: 'number',
                    displayName: '<<couponAmount>>',
                },
                pointAmount: {
                    type: 'number',
                    displayName: '<<pointAmount>>',
                },
                tenantPayableFreight: {
                    type: 'number',
                    displayName: '<<tenantPayableFreight>>',
                },
                tenantFreightDiscountAmount: {
                    type: 'number',
                    displayName: '<<deliveryBenefit>>',
                },
                tenantDeliverMoney: {
                    type: 'number',
                    displayName: '<<tenantDeliverMoney>>',
                },
                paidAmount: {
                    type: 'number',
                    displayName: '<<paidAmount>>',
                },
                costIncludeTax: {
                    type: 'string',
                    displayName: '<<costIncludeTax>>',
                },
                costPrice: {
                    type: 'number',
                    displayName: '<<costPrice>>',
                },
                costTaxMoney: {
                    type: 'number',
                    displayName: '<<costTaxMoney>>',
                },
                dutyDifferenceMoney: {
                    type: 'number',
                    displayName: '<<dutyDifferenceMoney>>',
                },
                dutyMoney: {
                    type: 'number',
                    displayName: '<<dutyMoney>>',
                },
                supplierDeliverMoney: {
                    type: 'number',
                    displayName: '<<supplierDeliverMoney>>',
                },
                advertisingExpenses: {
                    type: 'number',
                    displayName: '<<advertisingExpenses>>',
                },
                revenueSharingRatio: {
                    type: 'number',
                    displayName: '<<revenueSharingRatio>>',
                },
                revenueSharingAmount: {
                    type: 'number',
                    displayName: '<<revenueSharingAmount>>',
                },
                returnGoodsCost: {
                    type: 'number',
                    displayName: '<<returnGoodsCost>>',
                },
                returnFreight: {
                    type: 'string',
                    displayName: '<<returnFreight>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTime>>',
                },
            },
        },
    },
};
