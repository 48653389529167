import React, { useState, useEffect, useCallback } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { message } from 'antd';
import { PrivilegeTree, PrivilegeTreeProps } from '../privilege-management/privilege-tree';
import { errorHandle } from '../../services/error-handle';
import '../privilege-management/index.less';

const { navigation, api, language } = services;

interface EntityPrivilege extends PrivilegeTreeProps {
    id: string;
}

interface PrivilegeTreeNode {
    id: string;
    name: string;
    enable: boolean;
    isLeaf: boolean;
    children: Array<PrivilegeTreeNode> | null;
}

interface Entity {
    id: string;
    name: string;
}

function getValue(nodes: PrivilegeTreeNode[]) {
    let keys: string[] = [];
    for (let { id, enable, children } of nodes) {
        if (enable) {
            keys.push(id);
        }
        if (children?.length) {
            keys = keys.concat(getValue(children));
        }
    }
    return keys;
}

export const UserPrivilegeManagement: React.FunctionComponent<ExtendedParentComponentProps> = ({
    params,
}) => {
    const userId = params?.id;
    const [initialized, setInitialized] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [entities, setEntities] = useState<Array<EntityPrivilege>>([]);

    useEffect(() => {
        const initializePromises: Promise<any>[] = [];

        let data = [
            {
                id: '99900001',
                name: '门店',
            },
        ];

        setEntities((prevState) => {
            let nextState = prevState.concat();
            data.forEach(({ id, name }, index) => {
                nextState.push({
                    id,
                    name: `${name}`,
                    value: [],
                    options: [],
                    onChange: (value: string[]) => {
                        setEntities((prevState) => {
                            let entity = prevState[index];
                            let nextState = prevState.concat();
                            nextState.splice(index, 1, { ...entity, value });
                            return nextState;
                        });
                    },
                });
            });
            return nextState;
        });

        data.forEach(({ id }, index) => {
            let promise = api.get<PrivilegeTreeNode[]>(
                { userId, entityId: id },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                    apiPath: '/admin/users/:userId/entities/:entityId/record_privileges',
                }
            );
            promise.then((data) => {
                setEntities((prevState) => {
                    let entity = prevState[index];
                    let nextState = prevState.concat();
                    nextState.splice(index, 1, {
                        ...entity,
                        value: getValue(data),
                        options: data,
                    });
                    return nextState;
                });
            }, errorHandle);
            initializePromises.push(promise);
        });
        Promise.all(initializePromises).then(() => {
            setInitialized(true);
        });
    }, [userId]);

    const handleSubmit = useCallback(() => {
        setSubmitting(true);
        const submitPromises = [];

        for (let i = 0; i < entities.length; i++) {
            const { id, value } = entities[i];
            const promise = api.put(
                { userId, entityId: id, ids: value },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                    apiPath: '/admin/users/:userId/entities/:entityId/record_privileges',
                }
            );
            promise.catch(errorHandle);
            submitPromises.push(promise);
        }

        Promise.all(submitPromises).then(
            () => {
                message.success(language.getText('common.saveSuccess'));
                setSubmitting(false);
                navigation.goto('/user-manage');
            },
            () => {
                setSubmitting(false);
            }
        );
    }, [userId, entities]);

    return (
        <>
            <div className="layout privilege-tree-container">
                {entities.map((entity) => (
                    <PrivilegeTree {...entity} key={entity.id}></PrivilegeTree>
                ))}
            </div>
            <div className="fixed-footer-toolbar">
                <Button type="default" onClick={navigation.goBack} style={{ marginRight: 12 }}>
                    取消
                </Button>
                <Button
                    type="primary"
                    disabled={!initialized}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    提交
                </Button>
            </div>
        </>
    );
};
