import React, { Component, Fragment } from 'react';
import { cloneDeep, forEach } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Button, message as AntMessage } from 'antd';
import { api, localStorage, language } from '@comall-backend-builder/core/lib/services';
import { CrowdScopeEnum, AutoReplyVo, WechatMsgType } from '../components/wechant-content';
import { WechatOfficialAutoReplySettingPageStoreSelector } from '../components/wechat-official-auto-reply-setting-page-store-selector';
import { WechatOfficialAutoReplySettingPageData } from '../components/wechat-official-auto-reply-setting-page-data';
import { FunctionIdType, getAuthRecordErrorTip } from '../wechat-official-auto-reply-setting-page';
import { getUrlParam } from '../../../applications/cae/services/utils';
import './index.less';

interface States {
    /**
     * 是否是编辑
     */
    isEdit: boolean;
    /**
     * 是否已选择门店
     */
    subsite?: {
        id: number;
        name: string;
    };
    /**
     * 公众号appId
     */
    appId?: string;
    appName?: string;
    /**
     * 回复内容设置
     */
    replyContents: Array<AutoReplyVo>;
    /**
     * 权限不满足提示语
     */
    recordErrorTip: string;
}

export class WechatOfficialParametricQrcodeDefaultReplySettingPage extends Component<{}, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            isEdit: false,
            appId: '',
            replyContents: [],
            recordErrorTip: '',
        };
    }

    componentDidMount() {
        const appId = getUrlParam(window.location.href, 'appId');
        const appName = getUrlParam(window.location.href, 'appName');
        if (appId && appName) {
            this.setState(
                {
                    appId,
                    appName,
                },
                () => {
                    this.loadMessageByAppIdWithCheck();
                }
            );
        }
    }

    private async loadMessageByAppIdWithCheck() {
        const { appId } = this.state;
        if (!appId) {
            return;
        }
        const validFunctions = [
            FunctionIdType.ACCOUNT,
            FunctionIdType.NOTIFICATION,
            FunctionIdType.SOURCEMATERIAL,
        ];
        const tip = await getAuthRecordErrorTip(appId, validFunctions);
        if (tip) {
            this.setState({
                recordErrorTip: tip,
            });
        } else {
            this.loadMessagesByAppId();
        }
    }

    /**
     * 获取目前选择的公众号的详细信息，以及是否有支持的权限开通
     */
    loadWechatOfficialAccountByStoreId = async () => {
        const { subsite } = this.state;
        if (subsite && subsite.id) {
            //1.获取门店对应的公众号信息
            const amountResult: any = await api.get(
                {
                    subsiteId: subsite.id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                }
            );
            if (amountResult && amountResult.appId) {
                this.setState({
                    appId: amountResult.appId,
                    appName: amountResult.appName,
                });
                localStorage.set('wechatOffcialAutoReplySettingStoreAppId', amountResult.appId);
                this.loadMessageByAppIdWithCheck();
            } else {
                this.setState({
                    appId: '',
                    appName: '',
                    replyContents: [],
                });
            }
        }
    };

    /**
     * 获取公众号是否已经配置的自动回复信息
     */
    loadMessagesByAppId = async () => {
        const { appId } = this.state;
        if (!appId) {
            return;
        }
        const messageResult: any = await api.get(
            { appId: appId, replyType: 'SCAN_QR_CODE' },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages/by_app_id',
            }
        );
        if (
            messageResult &&
            messageResult.replyContents &&
            messageResult.replyContents.length > 0
        ) {
            this.setState({
                isEdit: true,
                replyContents: messageResult.replyContents.map((content: any) => {
                    content.messageContent = JSON.parse(content.messageContent);
                    return content;
                }),
            });
        }
    };

    submitVali = () => {
        const { replyContents } = this.state;
        const length = replyContents.length;
        //1.至少一个回复内容
        if (length === 0) {
            AntMessage.warn(services.language.getText('qtjhfnr'));
            return false;
        }
        //2.判断会员等级有待选择的
        let hasValidate = true;
        let hasContentValidate = true;
        let hasImageValidata = true;
        forEach(replyContents, (c) => {
            if (!c.crowdScope) {
                hasValidate = false;
                return false;
            }
            if (!c.messageContent || c.messageContent.length === 0) {
                hasContentValidate = false;
                return false;
            }
            forEach(c.messageContent, (c) => {
                if (
                    c.msgtype === WechatMsgType.MINIPROGRAMPAGE &&
                    (!c.miniprogrampage || !c.miniprogrampage.thumbMediaId)
                ) {
                    hasImageValidata = false;
                    return false;
                }
            });
        });
        if (!hasValidate) {
            AntMessage.warn(services.language.getText('qxzhfrq'));
            return false;
        }
        if (!hasImageValidata) {
            AntMessage.warn(services.language.getText('qxzxcxkptp'));
            return false;
        }
        //3.判断人群有没有重复的
        const allList = replyContents
            ? replyContents.filter((vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.ALL)
            : [];
        const memberList = replyContents
            ? replyContents.filter((vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.MEMBER)
            : [];
        const noMemberList = replyContents
            ? replyContents.filter((vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.NON_MEMBER)
            : [];
        if (allList.length > 1 || memberList.length > 1 || noMemberList.length > 1) {
            AntMessage.warn(services.language.getText('mlrqzdznszythfnr'));
            return false;
        }
        if (allList.length > 0 && (memberList.length > 0 || noMemberList.length > 0)) {
            AntMessage.warn(
                services.language.getText('xzlrqwqbfsh') +
                    ',' +
                    services.language.getText('bnztjqtrqkp')
            );
            return false;
        }
        //4.每个回复人群至少要添加一个回复内容
        if (!hasContentValidate) {
            AntMessage.warn(services.language.getText('mghfrqzsytjyghfnr'));
            return false;
        }
        return true;
    };

    submit = () => {
        const { isEdit, appId, replyContents } = this.state;
        //判断添加条件
        const vali = this.submitVali();
        if (!vali) {
            return;
        }
        const newReplyContents = cloneDeep(replyContents);
        const params = {
            appId,
            replyContents: newReplyContents
                ? newReplyContents.map((content: any) => {
                      content.messageContent = JSON.stringify(content.messageContent);
                      return content;
                  })
                : [],
            replyType: 'SCAN_QR_CODE',
            status: true,
        };
        if (isEdit) {
            api.put(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages/by_app_id',
            }).then(
                () => {
                    this.submitSuccess();
                },
                (error) => {
                    services.errorHandle(error);
                }
            );
        } else {
            api.post(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages',
            }).then(
                () => {
                    this.submitSuccess();
                },
                (error) => {
                    services.errorHandle(error);
                }
            );
        }
    };

    submitSuccess = () => {
        AntMessage.success(language.getText('common.saveSuccess'));
        setTimeout(() => {
            const appId = getUrlParam(window.location.href, 'appId');
            if (appId) {
                services.navigation.goBack();
            } else {
                this.loadMessagesByAppId();
            }
        }, 2000);
    };

    onChangeReplyDate = (replyData: Array<AutoReplyVo>) => {
        this.setState({
            replyContents: replyData,
        });
    };

    onChangeSubsite = (subsites: any[]) => {
        if (subsites && subsites.length > 0) {
            this.setState(
                {
                    isEdit: false,
                    subsite: {
                        id: subsites[0].id,
                        name: subsites[0].name,
                    },
                    appId: undefined,
                    replyContents: [],
                    recordErrorTip: '',
                },
                () => {
                    this.loadWechatOfficialAccountByStoreId();
                }
            );
        }
    };

    renderTip = () => {
        return (
            <div className="wechat-official-parametric-qrcode-default-reply-setting-page__tips">
                <div> {services.language.getText('syxz')}</div>
                <div>{services.language.getText('yhsmhrdyewmyszwmrhfnr_1')}</div>
            </div>
        );
    };

    renderFooter = () => {
        return (
            <div className="wechat-official-parametric-qrcode-default-reply-setting-page__footer">
                <Button
                    onClick={this.submit}
                    className="wechat-official-parametric-qrcode-default-reply-setting-page__footer__button"
                    type="primary"
                >
                    {services.language.getText('bc')}
                </Button>
            </div>
        );
    };

    renderContent = () => {
        const { replyContents, appId } = this.state;
        if (!appId) {
            return null;
        }
        return (
            <WechatOfficialAutoReplySettingPageData
                appId={appId}
                replyData={replyContents}
                onChange={this.onChangeReplyDate}
            />
        );
    };

    render() {
        const { subsite, appName, recordErrorTip, appId } = this.state;
        return (
            <Fragment>
                <div className="wechat-official-parametric-qrcode-default-reply-setting-page">
                    {this.renderTip()}
                    <WechatOfficialAutoReplySettingPageStoreSelector
                        appId={appId}
                        appName={appName}
                        currentSubsite={subsite}
                        recordErrorTip={recordErrorTip}
                        onChange={this.onChangeSubsite}
                        isWechatOffcialParametricQrcode
                    />
                    {appName && !recordErrorTip && (
                        <div className="reply-wrap">
                            {this.renderContent()}
                            {this.renderFooter()}
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}
