import React, { Component } from 'react';
import { Button, Radio } from 'antd';
import { newClientCustomSelectorConfig } from '../../applications/design/design';
import { getThirdLinkTypeEffectPathConfigList } from '../link-type-effect-path/config';
import { NavigationLink } from '../navigation-link';
import { LinkTypePlus } from '../../applications/design/components';
import { services } from '@comall-backend-builder/core';

const RadioGroup = Radio.Group;

type LinkValue = { linkType: string; linkParams: any } | null;

enum InvoiceChannelType {
    INTERNAL_INVOICE = 'INTERNAL_INVOICE',
    EXTERNAL_INVOICE = 'EXTERNAL_INVOICE',
}

type CarparkInvoiceConfigValue = {
    channel: InvoiceChannelType;
    content: string | null;
} | null;

export interface CarparkInvoiceConfigProps {
    name: string;
    value: CarparkInvoiceConfigValue | null;
    onChange: (value: CarparkInvoiceConfigValue | null, name: string) => void;
}

const DEFAULT_VALUE = {
    channel: InvoiceChannelType.INTERNAL_INVOICE,
    content: null,
};

const DEFAULT_TRIPARTITE_TARGET = {
    linkType: '',
    linkParams: null,
};

export class CarparkInvoiceConfig extends Component<CarparkInvoiceConfigProps> {
    onInvoiceChannelTypeChange = (e: any) => {
        const channel = e.target.value;
        const { value } = this.props;
        if (!value) {
            return;
        }
        this.changeValue({
            ...value,
            channel: channel,
        });
    };
    onTripartiteTargetChange = (linkValue: LinkValue) => {
        const { value } = this.props;
        if (!value || value.channel !== InvoiceChannelType.EXTERNAL_INVOICE) {
            return;
        }
        this.changeValue({
            ...value,
            content: linkValue ? JSON.stringify(linkValue) : null,
        });
    };
    changeValue = (value: CarparkInvoiceConfigValue) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };

    private get valueStatus() {
        const { value } = this.props;
        return Boolean(value);
    }

    private get linkValue() {
        const { value } = this.props;
        if (!value || !value.content) {
            return DEFAULT_TRIPARTITE_TARGET;
        }
        return JSON.parse(value.content);
    }

    onStatusButtonClilk = () => {
        if (this.valueStatus) {
            this.changeValue(null);
        } else {
            this.changeValue(DEFAULT_VALUE);
        }
    };

    renderStatusButton = () => {
        return (
            <div>
                <Button type="primary" onClick={this.onStatusButtonClilk}>
                    {this.valueStatus
                        ? services.language.getText('clearSetting')
                        : services.language.getText('settings')}
                </Button>
            </div>
        );
    };

    renderInvoiceChannelTypeRadio = () => {
        if (!this.valueStatus) {
            return null;
        }
        const { value } = this.props;
        const radioValue = value?.channel || undefined;
        return (
            <RadioGroup onChange={this.onInvoiceChannelTypeChange} value={radioValue}>
                <Radio value={InvoiceChannelType.INTERNAL_INVOICE}>小程序后台设置发票</Radio>
                <Radio value={InvoiceChannelType.EXTERNAL_INVOICE}>跳转到三方开票</Radio>
            </RadioGroup>
        );
    };

    renderTips = () => {
        const { value } = this.props;
        if (value?.channel !== InvoiceChannelType.INTERNAL_INVOICE) {
            return null;
        }
        return (
            <div>
                <span style={{ color: '#ccc' }}>请确认门店配置发票信息，否则点击入口没有反应</span>
                <NavigationLink
                    href="#/invoice-config"
                    text="查看设置"
                    privilege={{ path: 'invoiceConfig', level: 'full' }}
                    disabledType="tips"
                />
            </div>
        );
    };

    thirdLinkTypeEffectPathConfigList = getThirdLinkTypeEffectPathConfigList();

    renderLinkType = () => {
        const { value } = this.props;
        if (value?.channel !== InvoiceChannelType.EXTERNAL_INVOICE) {
            return null;
        }
        const linkValue = this.linkValue;

        return (
            <LinkTypePlus
                selector={newClientCustomSelectorConfig as any}
                linkTypes={this.thirdLinkTypeEffectPathConfigList}
                linkTypeMode="cascader"
                value={linkValue}
                onChange={this.onTripartiteTargetChange}
            />
        );
    };

    render() {
        return (
            <div className="carpark-linkType">
                {this.renderStatusButton()}
                {this.renderInvoiceChannelTypeRadio()}
                {this.renderTips()}
                {this.renderLinkType()}
            </div>
        );
    }
}
