import { services } from '@comall-backend-builder/core/';
import { isEmpty, forEach } from 'lodash';

export const PageTemplateLoader = {
    get: async function(data: any, config: any) {
        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.preview) {
                if (res.preview.length === 1 && res.preview[0]['id'] === '0') {
                    delete res.preview;
                } else {
                    forEach(res.preview, (item) => {
                        if (isEmpty(item.path)) {
                            item.path = '';
                        }
                    });
                }
            }
            return res;
        });
    },
};
