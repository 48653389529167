import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { SelectVirtualProduct } from './select-member-card-with-discount';

export class SelectMemberCardWithDiscountMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SelectVirtualProduct {...controlInfo} />;
    }
}
