import React, { PureComponent } from 'react';
import { Form, Radio, Checkbox, Row, Col } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import './index.less';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;

/**
 * 专柜详情组件 - 配置专柜信息
 */
class merchantInfo extends PureComponent<any> {
    render() {
        const { form } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="editor-merchant-info">
                <FormItem {...formItemLayout} label={'显示样式'}>
                    {getFieldDecorator('showStyle', {
                        initialValue: '1',
                    })(
                        <Radio.Group>
                            <Radio value={'1'}>样式1</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={'显示内容'}>
                    {getFieldDecorator('showContent', {
                        initialValue: [],
                    })(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Row className="show-content">
                                <Col span={24}>
                                    <Checkbox value="shoppingGuide">导购咨询</Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox value="makeCall">拨打电话</Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox value="collectMerchant">
                                        {services.language.getText('szzj')}
                                    </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox value="mapGuide">地图导览</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    )}
                </FormItem>
                <div className="tips">专柜信息读取后台配置的专柜内容</div>
            </Form>
        );
    }
}

export const MerchantInfo = tools.ConfigFormDecorator(merchantInfo);
