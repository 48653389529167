import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';

import { MerchantBusinessGuideDisplay } from './merchant-business-guide-display';

export class MerchantBusinessGuideMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MerchantBusinessGuideDisplay {...controlInfo} />;
    }
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        return <MerchantBusinessGuideDisplay {...displayInfo} />;
    }
}
