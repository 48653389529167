import React, { Component } from 'react';
import { DateTimeRangePicker, DateRangePicker } from '@comall-backend-builder/components-basis';
import { isEqual, isEmpty } from 'lodash';

export class DateTimeRangePickerPlus extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            range: props.range,
        };
    }
    componentWillReceiveProps(nextProps: any) {
        const { range, row } = this.props;
        const { range: newRange, row: newRow, value } = nextProps;
        if (isEmpty(range) && isEmpty(newRange)) {
            return;
        }
        const isEditPageLoaded = newRow.id && !row.id;
        const rangeStart = newRange.start ? new Date(newRange.start) : undefined;
        const valueStart = value?.start ? new Date(value.start) : undefined;
        if (
            isEditPageLoaded &&
            rangeStart &&
            valueStart &&
            valueStart?.getTime() < rangeStart.getTime()
        ) {
            this.setState({
                range: { ...newRange, start: valueStart },
            });
        } else if (!isEqual(range, newRange)) {
            this.setState({ range: newRange });
        }
    }
    render() {
        const { name, hideTime } = this.props;
        const { range } = this.state;
        return hideTime ? (
            <DateRangePicker name={name} {...this.props} range={range} />
        ) : (
            <DateTimeRangePicker name={name} {...this.props} range={range} />
        );
    }
}
