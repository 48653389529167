import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Row as AntRow,
    Col as AntCol,
    Card as AntCard,
    Modal,
    message as AntMessage,
    Button as AntButton,
    Table,
} from 'antd';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { invoiceTypesText, invoiceContentsText } from '../invoice/normal-invoice-configs-header';
import { InvoiceModeEnum } from '../../types/mode/string/invoice-mode/invoice-mode';
import './index.less';

const api = services.api;

const { getText } = services.language;

interface couponOrderInfoState {
    pos: any | undefined;
    invoice?: any;
    previewPictureVisible: boolean;
    previewPictureImage: string;
    remark: string;
    invoiceLogs: Array<any>;
}

const invoiceNameType: any = {
    PERSONAL: 'fqydw',
    COMPANY: 'qydw',
};

const invoiceStatusText: any = {
    NOT_INVOICED: 'wkp',
    INVOICING: 'kpz',
    INVOICED: 'ykp',
    INVOICING_FAILED: 'kpsb',
};

class posReceiptDetail extends PureComponent<
    {
        fields: any | undefined;
        entity: Entity;
        params: { id: string };
    },
    couponOrderInfoState
> {
    state: couponOrderInfoState = {
        pos: undefined,
        previewPictureVisible: false,
        previewPictureImage: '',
        remark: '',
        invoiceLogs: [],
    };

    isReInvoicing = false;

    componentDidMount() {
        this.loadPosReceiptInfo(this.props.params.id);
    }

    loadPosReceiptInfo = (objectId: string) => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-POS-RECEIPT`,
                apiPath: '/admin/pos_receipts/' + objectId,
            }
        ).then((res: any) => {
            this.setState({ pos: res });
            const {
                invoice: { id },
            } = res;
            if (id) {
                this.loadInvoiceInfo(id);
            }
        });
    };

    loadInvoiceInfo = (id: string) => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: '/admin/invoices/' + id,
            }
        ).then((res: any) => {
            this.setState({ invoice: res });
        });
        api.get(
            { id, page: 1, perPage: 100 },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: `/admin/invoices/logs`,
            }
        ).then((res: any) => {
            if (res && res.result) {
                res.result = res.result.map((log: any) => {
                    log.buyerType = log.buyer.type;
                    log.buyerName = log.buyer.name;
                    log.buyerTaxNo = log.buyer.taxNo;
                    return log;
                });
                this.setState({ invoiceLogs: res.result });
            }
        });
    };

    handlePicturePreview = (url: string) => {
        if (url) {
            this.setState({
                previewPictureImage: url,
                previewPictureVisible: true,
            });
        }
    };

    handlePictureCancel = () => this.setState({ previewPictureVisible: false });

    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ remark: value });
    };

    reInvoicing = () => {
        const { pos, invoice } = this.state;

        if (this.isReInvoicing || !pos || !invoice) {
            return;
        }

        this.isReInvoicing = true;

        api.put(
            {
                buyer: invoice.buyer,
                content: invoice.content,
                invoiceType: invoice.invoiceType,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-POS-RECEIPT`,
                apiPath: `/admin/pos_receipts/${pos.id}/invoice/${pos.invoice.id}`,
            }
        )
            .then(() => {
                AntMessage.success(getText('zxkpcg'));
                this.loadInvoiceInfo(pos.invoice.id);
                this.isReInvoicing = false;
            })
            .catch((error) => {
                errorHandle(error);
                this.isReInvoicing = false;
            });
    };

    renderInvoiceInfo = () => {
        const { invoice = { files: [], buyer: {} }, pos } = this.state;
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={getText('fpxx')}>
                    <AntCol span={16}>
                        {pos && pos.invoice && pos.invoice.mobile && (
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={12}>
                                    <span className="invoice-info-title">
                                        {getText('kprsjh')}：
                                    </span>
                                    <span>{pos.invoice.mobile}</span>
                                </AntCol>
                            </AntRow>
                        )}
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fplx')}：</span>
                                <span>{getText(invoiceTypesText[invoice.invoiceType])}</span>
                            </AntCol>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fpnr')}：</span>
                                <span>{getText(invoiceContentsText[invoice.content])}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('fptt')}：</span>
                                <span>{getText(invoiceNameType[invoice.buyer.type])}</span>
                            </AntCol>
                            <AntCol span={12}>
                                <span className="invoice-info-title">{getText('sh_1')}：</span>
                                <span>{invoice.buyer.taxNo}</span>
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('fpttmc')}：</span>
                                <span>{invoice.buyer.name}</span>
                            </AntCol>
                        </AntRow>
                        {(invoice.buyer.address || invoice.buyer.bank) && (
                            <AntRow style={{ marginBottom: 10 }}>
                                {invoice.buyer.address && (
                                    <AntCol span={12}>
                                        <span className="invoice-info-title">
                                            {getText('zcdz')}：
                                        </span>
                                        <span>{invoice.buyer.address}</span>
                                    </AntCol>
                                )}
                                {invoice.buyer.bank && (
                                    <AntCol span={12}>
                                        <span className="invoice-info-title">
                                            {getText('khyx')}：
                                        </span>
                                        <span>{invoice.buyer.bank}</span>
                                    </AntCol>
                                )}
                            </AntRow>
                        )}
                        {(invoice.buyer.phone || invoice.buyer.bankAccount) && (
                            <AntRow style={{ marginBottom: 10 }}>
                                {invoice.buyer.phone && (
                                    <AntCol span={12}>
                                        <span className="invoice-info-title">
                                            {getText('zcdh')}：
                                        </span>
                                        <span>{invoice.buyer.phone}</span>
                                    </AntCol>
                                )}
                                {invoice.buyer.bankAccount && (
                                    <AntCol span={12}>
                                        <span className="invoice-info-title">
                                            {getText('yxzh')}：
                                        </span>
                                        <span>{invoice.buyer.bankAccount}</span>
                                    </AntCol>
                                )}
                            </AntRow>
                        )}
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol>
                                <span className="invoice-info-title">{getText('kpzt_1')}：</span>
                                <span>
                                    {getText(invoiceStatusText[invoice.status])}
                                    {invoice.result ? `(${invoice.result})` : ''}
                                </span>
                                {invoice.status === 'INVOICING_FAILED' && (
                                    <AntButton type="link" onClick={this.reInvoicing}>
                                        {getText('zxkp')}
                                    </AntButton>
                                )}
                            </AntCol>
                        </AntRow>
                        {invoice.invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC ? (
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={12}>
                                    <span className="invoice-info-title">
                                        {getText('qdfphm')}：
                                    </span>
                                    <span>{invoice.invoiceNumber}</span>
                                </AntCol>
                            </AntRow>
                        ) : (
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={12}>
                                    <span className="invoice-info-title">{getText('fphm')}：</span>
                                    <span>{invoice.invoiceNumber}</span>
                                </AntCol>
                                <AntCol span={12}>
                                    <span className="invoice-info-title">{getText('fpdm')}：</span>
                                    <span>{invoice.invoiceCode}</span>
                                </AntCol>
                            </AntRow>
                        )}
                    </AntCol>
                    <AntCol span={8}>
                        {invoice.files && this.renderInvoicePictures(invoice.files)}
                    </AntCol>
                </AntCard>
            </AntRow>
        );
    };

    renderInvoicePictures = (files: any[]) => {
        return files
            .filter((i) => i.type === 'PICTURE')
            .map((item) => (
                <img
                    style={{
                        width: '210px',
                        height: '210px',
                        margin: '0 10px 10px 0',
                    }}
                    key={item.url}
                    src={item.url}
                    alt={getText('kdjckyt')}
                    onClick={this.handlePicturePreview.bind(this, item.url)}
                />
            ));
    };

    renderInvoiceLogs = () => {
        const { invoiceLogs } = this.state;
        if (invoiceLogs.length === 0) {
            return null;
        }
        const tableProps = {
            rowKey: 'id',
            columns: [
                {
                    title: getText('kplx'),
                    dataIndex: 'type',
                    key: 'type',
                    width: 100,
                    render: (i: string) => {
                        const typesText: any = {
                            BLUE_INVOICE: getText('blueticket'),
                            RED_INVOICE: getText('redticket'),
                        };
                        return typesText[i];
                    },
                },
                {
                    title: getText('kpzt_1'),
                    dataIndex: 'status',
                    key: 'status',
                    width: 100,
                    render: (i: string) => {
                        const invoiceStatusText: any = {
                            INVOICED: getText('ykp'),
                            CHANGED: getText('ykp'),
                            RED_FLUSHED: getText('ykp'),
                            INVOICING_FAILED: getText('kpsb'),
                            RED_FLUSHED_FAILED: getText('kpsb'),
                        };
                        return invoiceStatusText[i];
                    },
                },
                {
                    title: getText('sqsj'),
                    dataIndex: 'applyTime',
                    key: 'applyTime',
                    width: 200,
                },
                {
                    title: getText('sqlsh'),
                    dataIndex: 'serialNumber',
                    key: 'serialNumber',
                    width: 200,
                },
                {
                    title: getText('fplx'),
                    dataIndex: 'invoiceType',
                    key: 'invoiceType',
                    width: 160,
                    render: (i: string) => {
                        const invoiceTypesText: any = {
                            NORMAL: getText('zzsdzptfp'),
                            SPECIAL: getText('zzszyfp'),
                        };
                        return invoiceTypesText[i];
                    },
                },
                {
                    title: getText('fpnr'),
                    dataIndex: 'content',
                    key: 'content',
                    width: 100,
                    render: (i: string) => {
                        const contentText: any = {
                            PRODUCT_DETAIL: getText('productDetail'),
                            PRODUCT_CATEGORY: getText('productCategory'),
                        };
                        return contentText[i];
                    },
                },
                {
                    title: getText('fptt'),
                    dataIndex: 'buyerType',
                    key: 'buyerType',
                    width: 150,
                    render: (i: any) => {
                        const buyerTypeText: any = {
                            PERSONAL: getText('fqydw'),
                            COMPANY: getText('qydw'),
                        };
                        return buyerTypeText[i];
                    },
                },
                {
                    title: getText('fpttmc'),
                    dataIndex: 'buyerName',
                    key: 'buyerName',
                    width: 200,
                },
                {
                    title: getText('sh_1'),
                    dataIndex: 'buyerTaxNo',
                    key: 'buyerTaxNo',
                    width: 200,
                },
                {
                    title: getText('sdphm'),
                    dataIndex: 'invoiceNumber',
                    key: 'invoiceNumber',
                    width: 230,
                },
                {
                    title: getText('fpdm'),
                    dataIndex: 'invoiceCode',
                    key: 'invoiceCode',
                    width: 200,
                },
            ],
            scroll: {
                x: 1850,
            },
            dataSource: invoiceLogs,
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={getText('fpjl')}>
                    <Table {...tableProps} pagination={false}></Table>
                </AntCard>
            </AntRow>
        );
    };

    render() {
        const { pos, previewPictureVisible, previewPictureImage } = this.state;

        return pos ? (
            <div className="pos-receipt-detail">
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCard type="inner" title={getText('hyxx')}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={6}>
                                {getText('xphysjh')}：{pos.mobile}
                            </AntCol>
                        </AntRow>
                    </AntCard>
                </AntRow>
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCol span={12} style={{ paddingRight: 10 }}>
                        <AntCard type="inner" title={getText('ddxx')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {getText('xpbh')}：{pos.posReceiptNo}
                                </div>
                            </AntRow>
                            {pos.originPosReceiptNo && (
                                <AntRow style={{ marginBottom: 10 }}>
                                    <div>
                                        {getText('yxpbh')}：{pos.originPosReceiptNo}
                                    </div>
                                </AntRow>
                            )}
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {getText('xdsj')}：{pos.createTime}
                                </div>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {getText('xfmd')}：{pos.subsiteVo && pos.subsiteVo.name}
                                </div>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {getText('xfje')}：{pos.orderAmount}
                                </div>
                            </AntRow>
                        </AntCard>
                    </AntCol>
                    <AntCol span={12} style={{ paddingLeft: 10 }}>
                        <AntCard type="inner" title={getText('ddspxx')}>
                            {pos.products &&
                                pos.products.map((product: any) => {
                                    return (
                                        <AntRow style={{ marginBottom: 10 }}>
                                            <AntCol key={product.productName}>
                                                {product.productName} *{product.num}
                                            </AntCol>
                                        </AntRow>
                                    );
                                })}
                        </AntCard>
                    </AntCol>
                </AntRow>
                {this.renderInvoiceInfo()}
                {this.renderInvoiceLogs()}
                <Modal
                    visible={previewPictureVisible}
                    footer={null}
                    onCancel={this.handlePictureCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewPictureImage} />
                </Modal>
                <Button route="goBack" text={getText('back')} />
            </div>
        ) : null;
    }
}

function mapStateToProps(state: any, props: any) {
    const {
        entity: { fields },
        componentId,
    } = props;
    return {
        fields,
        componentId,
    };
}

export const PosReceiptDetail = connect(mapStateToProps)(posReceiptDetail);
