import * as React from 'react';
import { Tag, Popover } from 'antd';
import { ArrayMode } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

export class ArrayPopoverListMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const { countSuffix, replaceEmpty = '' } = displayInfo;
        return (
            <div>
                {value && value.length > 0
                    ? value.map((v: any) => {
                          return (
                              <div>
                                  {v.name}
                                  {v.contents && v.contents.length > 0 && (
                                      <Popover
                                          placement={'right'}
                                          overlayStyle={{ zIndex: 1000 }}
                                          content={
                                              <div
                                                  style={{
                                                      maxWidth: 400,
                                                  }}
                                              >
                                                  {v.contents.map((item: any, index: number) => (
                                                      <Tag
                                                          key={index}
                                                          style={{
                                                              maxWidth: 400,
                                                              marginBottom: 10,
                                                              whiteSpace: 'normal',
                                                              wordBreak: 'break-word',
                                                              wordWrap: 'break-word',
                                                          }}
                                                      >
                                                          {item}
                                                      </Tag>
                                                  ))}
                                              </div>
                                          }
                                      >
                                          <span className="group-tips">
                                              &nbsp;{' '}
                                              {services.interpolate(
                                                  services.language.getText('contentNum'),
                                                  { total: v.contents.length }
                                              )}
                                              {countSuffix ? countSuffix : ''}
                                          </span>
                                      </Popover>
                                  )}
                              </div>
                          );
                      })
                    : replaceEmpty}
            </div>
        );
    }
}
