import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { isEmpty, map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const CheckboxGroup = Checkbox.Group;
const { api } = services;

export interface SubsiteMerchantDeliveryValue {
    delivery: any[];
}

export interface SubsiteMerchantDeliveryProps {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * value值
     */
    value: SubsiteMerchantDeliveryValue;
    /**
     * onchange
     */
    onChange: (value: SubsiteMerchantDeliveryValue, name: string) => void;
}

export interface SubsiteMerchantDeliveryStates {
    deliveryOptions: any[];
}

export class SubsiteMerchantDelivery extends Component<
    SubsiteMerchantDeliveryProps,
    SubsiteMerchantDeliveryStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            deliveryOptions: [],
        };
    }

    componentDidMount() {
        let { value } = this.props;
        if (!value) {
            this.resetValue();
        }
        this.loadDeliveryOptions(this.props);
    }

    componentWillReceiveProps(nextProps: any) {
        const { row } = nextProps;
        const currentRow = this.props.row;
        const selectMode = row.baseInfo.selectMode;
        const currentSelectMode = currentRow.baseInfo.selectMode;
        let nextSubsiteIds: any = this.getSubsiteIds(nextProps);
        if (nextSubsiteIds) {
            nextSubsiteIds = nextSubsiteIds.toString();
        }
        let currentSubsiteIds: any = this.getSubsiteIds(this.props);
        if (currentSubsiteIds) {
            currentSubsiteIds = currentSubsiteIds.toString();
        }
        let nextMerchantIds: any = this.getMerchantIds(nextProps);
        if (nextMerchantIds) {
            nextMerchantIds = nextMerchantIds.toString();
        }
        let currentMerchantIds: any = this.getMerchantIds(this.props);
        if (currentMerchantIds) {
            currentMerchantIds = currentMerchantIds.toString();
        }
        let nextMerchantSubsiteIds: any = this.getMerchantSubsiteIds(nextProps);
        if (nextMerchantSubsiteIds) {
            nextMerchantSubsiteIds = nextMerchantSubsiteIds.toString();
        }
        let currentMerchantSubsiteIds: any = this.getMerchantSubsiteIds(this.props);
        if (currentMerchantSubsiteIds) {
            currentMerchantSubsiteIds = currentMerchantSubsiteIds.toString();
        }
        if (selectMode !== currentSelectMode) {
            this.loadDeliveryOptions(nextProps);
            this.resetValue();
        } else {
            //如果门店或者门店专柜改变时
            if (row.baseInfo.selectMode === 'SUB_SITE') {
                if (nextSubsiteIds !== null && nextSubsiteIds !== currentSubsiteIds) {
                    this.loadDeliveryOptions(nextProps);
                    this.resetValue();
                }
            }
            if (row.baseInfo.selectMode === 'MERCHANT') {
                if (
                    nextMerchantSubsiteIds !== null &&
                    (nextMerchantSubsiteIds !== currentMerchantSubsiteIds ||
                        nextMerchantIds !== currentMerchantIds)
                ) {
                    this.loadDeliveryOptions(nextProps);
                    this.resetValue();
                }
            }
        }
    }

    resetValue = () => {
        let { name, onChange } = this.props;
        let defalutValue: SubsiteMerchantDeliveryValue = {
            delivery: [],
        };
        if (onChange) {
            onChange(defalutValue, name);
        }
    };

    loadDeliveryOptions = (props: any) => {
        //重置配送方式
        this.setState(
            {
                deliveryOptions: [],
            },
            () => {
                const { row } = props;
                const subsiteIds = this.getSubsiteIds(props);
                const merchantIds = this.getMerchantIds(props);
                const merchantSubsiteIds = this.getMerchantSubsiteIds(props);
                const selectMode = row.baseInfo.selectMode;
                const params: any = {};
                if (selectMode === 'SUB_SITE') {
                    if (!subsiteIds) {
                        return;
                    }
                    params.subsiteIds = subsiteIds;
                }
                if (selectMode === 'MERCHANT') {
                    if (!merchantSubsiteIds) {
                        return;
                    }
                    //如果专柜不是自营0，就不要传门店id给后端
                    params.subsiteIds =
                        merchantIds && merchantIds[0] === '0' ? merchantSubsiteIds : null;
                    params.merchantIds = merchantIds && merchantIds[0] !== '0' ? merchantIds : null;
                }
                api.get(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                    apiPath: '/admin/delivery_modes',
                }).then((response: any) => {
                    this.setState({
                        deliveryOptions: response,
                    });
                });
            }
        );
    };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        let subsites = row.subsites;
        if (row.baseInfo && row.baseInfo.subsites) {
            subsites = row.baseInfo.subsites;
        }
        const subsiteIds = subsites && subsites.length > 0 ? map(subsites, 'id') : null;
        return subsiteIds;
    };

    getMerchantIds = (props: any) => {
        const { row } = props;
        let merchants = row.merchants;
        if (row.baseInfo && row.baseInfo.merchants) {
            merchants = row.baseInfo.merchants;
        }
        const merchantIds =
            merchants && merchants.length > 0
                ? map(merchants, (merchant) => {
                      //部分组件id为"门店id,专柜id"组合，需要截取获得专柜id
                      let merchantId = merchant.id.split(',')[1];
                      //如果id不为组合时，直接取专柜id
                      if (!merchantId) {
                          merchantId = merchant.merchantId;
                      }
                      if (!merchantId) {
                          merchantId = merchant.id;
                      }
                      return merchantId;
                  })
                : null;
        return merchantIds;
    };

    getMerchantSubsiteIds = (props: any) => {
        const { row } = props;
        let merchants = row.merchants;
        if (row.baseInfo && row.baseInfo.merchants) {
            merchants = row.baseInfo.merchants;
        }
        const subsite =
            merchants && merchants.length > 0
                ? map(merchants, (merchant) => {
                      let subsiteId = merchant.subsiteId || merchant.subSiteId || '';
                      //部分组件id为"门店id,专柜id"组合，需要截取获得门店id
                      let merchantId = merchant.id.split(',')[1];
                      if (merchantId) {
                          subsiteId = merchant.id.split(',')[0];
                      }
                      return subsiteId;
                  }).filter((s) => s !== '')
                : null;
        const subsiteIds = subsite && subsite.length > 0 ? Array.from(new Set(subsite)) : null;
        return subsiteIds;
    };

    OnChangeDelivery = (deliverys: any[]) => {
        const { value, onChange, name } = this.props;
        value.delivery = deliverys;
        if (name && onChange) {
            onChange(value, name);
        }
    };

    render() {
        const { value, row } = this.props;
        const { deliveryOptions } = this.state;
        if (row.baseInfo && row.baseInfo.selectMode) {
            const selectMode = row.baseInfo.selectMode;
            if (selectMode === 'SUB_SITE') {
                const subsiteIds = this.getSubsiteIds(this.props);
                if (subsiteIds === null || subsiteIds.length === 0) {
                    return <div style={{ color: 'red' }}>{language.getText('selectSubsite')}</div>;
                }
                if (isEmpty(deliveryOptions)) {
                    return (
                        <div style={{ color: 'red' }}>
                            {language.getText('subsiteNonDeleveryType')}
                        </div>
                    );
                }
            }
            if (selectMode === 'MERCHANT') {
                const merchantIds = this.getMerchantIds(this.props);
                if (merchantIds === null || merchantIds.length === 0) {
                    return (
                        <div style={{ color: 'red' }}>
                            {language.getText('pleaseSelectMerchant')}
                        </div>
                    );
                }
                if (isEmpty(deliveryOptions)) {
                    return (
                        <div style={{ color: 'red' }}>
                            {language.getText('merchanteNonDeleveryType')}
                        </div>
                    );
                }
            }
        }
        return (
            <div>
                <CheckboxGroup
                    onChange={(checkedValue) => {
                        this.OnChangeDelivery(checkedValue);
                    }}
                    value={value.delivery}
                >
                    {deliveryOptions.map((option: any) => {
                        return (
                            <Checkbox key={option.id} style={{ marginLeft: 5 }} value={option.id}>
                                {option.name}
                            </Checkbox>
                        );
                    })}
                </CheckboxGroup>
            </div>
        );
    }
}
