import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const pointExchangeRecordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (!data.mobile) {
            delete data.mobile;
        }
        if (data.subsiteId) {
            data.subsiteIds = data.subsiteId.id ? data.subsiteId.id : data.subsiteId;
            delete data.subsiteId;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            if (res && res.result) {
                res.result = res.result.map((item: any) => {
                    if (item.pointExchangeAwardList.length > 0) {
                        item.pointExchangeAwardList = item.pointExchangeAwardList.map((i: any, index: number) => ({
                            id: index,
                            name: `${i.couponName}/${i.couponBalance}/${i.couponDate}`
                        }))
                    }
                    return item;
                })
            }
            return res;
        });
    },
};
