import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const couponRefundOrderLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/refunds';
        const { id } = data;
        if (!id) {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (data.orderType === 'ALL') {
                delete data.orderType;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
            if (!isEmpty(data.refundDateRange)) {
                data.refundedStartTime = data.refundDateRange.start + ' 00:00:00';
                data.refundedEndTime = data.refundDateRange.end + ' 23:59:59';
                delete data.refundDateRange;
            }
            if (!isEmpty(data.subsites)) {
                data.subsiteId = data.subsites;
                delete data.subsites;
            }
            data.orderTypes = 'SALE_COUPON,CREDIT_ESHOP,GROUP_BUYING_COUPON,COUPON_PACKAGE';
        } else {
            config.apiPath = `/admin/refunds/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    item.couponName = item.couponVos;
                    item.couponNo = item.couponVos;
                    item.canRefund = item.interfaceStatus === 'REFUND_FAIL';
                    return item;
                });
            return res;
        });
    },
};
