import { language } from '@comall-backend-builder/core/lib/services';
import { Button } from 'antd';
import React, { PureComponent } from 'react';
import { SelectInternalPurchase } from './select-internal-purchase';

export class CustomSelectInternalPurchase extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
    },
    { modalVisible: boolean }
> {
    state = {
        modalVisible: false,
    };
    showModal = () => {
        this.setState({ modalVisible: true });
    };
    hideModal = () => {
        this.setState({ modalVisible: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;
        this.hideModal();
        onChange({
            subsiteId: value[0].subsites?.id,
            id: value[0].id,
            name: value[0].name,
        });
    };
    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
        } = this.props;
        const { modalVisible } = this.state;
        const hasSingleTarget = linkParams && linkParams.name;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        return (
            <span className="link-name">
                {hasSingleTarget ? (
                    <span className="link-name-label">{linkParams.name}</span>
                ) : (
                    language.getText('notSelect')
                )}
                <Button type="link" onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <SelectInternalPurchase
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    visible={modalVisible}
                    onCancel={this.hideModal}
                    {...selectProps}
                />
            </span>
        );
    }
}
