import React, { PureComponent } from 'react';
import { localStorage } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export interface CmWebsocketProps {
    socketUrl: string;
    onMessageChange: (msg: any) => void;
    id?: string;
}

export interface CmWebsocketState {
    socketUrl: string;
}

const isSetCheckTime = 5000; //设置延迟避免请求过多
export default class CmWebsocket extends PureComponent<CmWebsocketProps, CmWebsocketState> {
    websocket: any = null;
    isCheckTime: any = null;
    timeObj: any = null;

    componentDidMount() {
        const { socketUrl } = this.props;
        this.setState(
            {
                socketUrl: socketUrl,
            },
            () => {
                this.connection();
            }
        );
    }
    componentWillReceiveProps(nextProps: any) {
        //当路径关键id改变时，重新websocket连接
        if (nextProps.id) {
            const newSocketUrlId = nextProps.id;
            const oldSocketUrlId = this.props.id;
            if (newSocketUrlId && oldSocketUrlId && newSocketUrlId !== oldSocketUrlId) {
                this.setState(
                    {
                        socketUrl: nextProps.socketUrl,
                    },
                    () => {
                        this.connection();
                    }
                );
            }
        }
    }

    connection = () => {
        const { socketUrl } = this.state;
        const userinfo: any = localStorage.get('USER_INFO');
        if (!userinfo) {
            return;
        }
        const user: any = localStorage.get('user');
        if (!user) {
            return;
        }
        let root = `${ENV.AUTH_API_ROOT}`;
        root = root.replace('https://', ' wss://');

        const token = encodeURI(`Bearer ${user.accessToken}`);
        const webSocketUrl = `${root}${socketUrl}&Authorization=${token}&userId=${userinfo.id}`;
        console.log('webSocket链接:', webSocketUrl);
        if (typeof WebSocket == 'undefined') {
            console.log(services.language.getText('ndllqbzc'));
        } else {
            console.log(services.language.getText('ndllqzc'));
        }
        this.websocket = new WebSocket(webSocketUrl);
        //打开事件
        this.websocket.onopen = this.open;
        //发现消息进入
        this.websocket.onmessage = this.message;
        //关闭事件
        this.websocket.onclose = this.close;
        //发生了错误事件
        this.websocket.onerror = this.error;
    };

    reconnect = () => {
        if (this.websocket && this.websocket.readyState === 2) {
            return;
        }
        this.websocket = null;
        var _this = this;
        //没连接上会一直重连，设置延迟避免请求过多
        this.isCheckTime && clearTimeout(this.isCheckTime);
        this.isCheckTime = setTimeout(function() {
            _this.connection();
        }, isSetCheckTime);
    };

    open = () => {
        console.log('websocket已打开');
        this.start();
    };

    close = () => {
        console.log('websocket已关闭');
        this.reconnect();
    };

    message = (msg: any) => {
        console.log('websocket返回信息msg', msg, 'msg.data', msg.data);
        const { onMessageChange } = this.props;
        onMessageChange && onMessageChange(msg);
    };

    error = () => {
        console.log('连接失败,重新连接中');
        this.reconnect();
    };

    onopen = () => {
        console.log('连接成功');
    };

    start() {
        //开启心跳-（添加心跳的原因：不加心跳的话，一分钟内websocket没收到信息，就会自动关闭）
        let context = this;
        clearInterval(context.timeObj);
        context.timeObj = setInterval(function() {
            //这里发送一个心跳，后端收到后，返回一个心跳消息
            if (context.websocket && context.websocket.readyState === 1) {
                //如果连接正常
                context.websocket.send(
                    JSON.stringify({ content: services.language.getText('heartbeat'), type: 0 })
                );
            } else {
                //否则重连
                context.reconnect();
            }
        }, 50000);
    }

    render() {
        return <div></div>;
    }
}
