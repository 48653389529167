import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
import { services } from '@comall-backend-builder/core';

const language = services.language;

let SELECTED_ROW: Array<{ id: number; name: string }> = [];

const getVirtualCategoryConfig = (componentName: string, subsiteId: string) => {
    return {
        component: 'Card',
        entity: 'CustomDesignVirtualCategorySelector',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                    params: { subsiteId },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                    },
                    params: { subsiteId },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'id',
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicVirtualCategoryComponent(
    contentConfig: any,
    componentName: any,
    subsiteId: string
) {
    let config = defaultsDeep(contentConfig, getVirtualCategoryConfig(componentName, subsiteId));
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
    });
    return ComponentFactory(componentName, config);
}

function generateDynamicVirtualCategoryComponentName() {
    return 'DesignVirtualCategorySelector-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

/**
 * 选择虚拟分类
 */
export class SelectVirtualCategory extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    onChange: (data: { id: number; name: string; type: string }) => void;
    onCancel: () => void;
    value: any;
}> {
    virtualCategoryComponentName = '';
    renderVirtualCategoryComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);

        this.virtualCategoryComponentName = generateDynamicVirtualCategoryComponentName();

        this.createTable(props.value.subsiteId);
        this.dispatch = builder.getStore().dispatch;
    }

    createTable = (subsiteId: string) => {
        const { contentConfig } = this.props;
        const dynamicVirtualCategoryComponent = initDynamicVirtualCategoryComponent(
            contentConfig,
            this.virtualCategoryComponentName,
            subsiteId
        );

        this.renderVirtualCategoryComponent = createElement(dynamicVirtualCategoryComponent);
    };

    componentWillReceiveProps(nextProps: any) {
        if (this.props.value.subsiteId !== nextProps.value.subsiteId) {
            this.createTable(nextProps.value.subsiteId);
        }
    }

    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(language.getText('components.Tools.categorySelectRequired'));
            return;
        }

        const { onChange } = this.props;

        onChange({
            id: SELECTED_ROW[0].id,
            name: SELECTED_ROW[0].name,
            type: 'virtualCategory',
        });
        this.dispatch(
            actions.tableRowSelectionChangeAction(this.virtualCategoryComponentName + '-table', [])
        );
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 800,
                visible: showModal,
                title: '选择分类',
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderVirtualCategoryComponent}</Modal>;
    }
}
