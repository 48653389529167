import React, { CSSProperties } from 'react';
import { Button, Modal, message as AntMessage } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../applications/cae/services/error-handle';
import './index.less';
export class PictureExampleButton extends React.PureComponent<any, any> {
    state = { visible: false };
    toggle = () => {
        this.setState({ visible: !this.state.visible });
    };
    download = () => {
        const { downloadApiRoot, downloadApiPath } = this.props;
        AntMessage.warning(language.getText('downLoadingWaitMoment'));
        api.download(
            {},
            {
                apiRoot: downloadApiRoot,
                apiPath: downloadApiPath,
                customHeaders: {
                    'content-disposition': 'attachment;filename="filename.zip"',
                },
            }
        ).catch(errorHandle);
    };
    render() {
        const { type, buttonName, showDownload, downloadButtonName, row, extraText } = this.props;
        const { share = {} } = row;
        let picUrl = '',
            picUrlTwo = '',
            width = 600;
        const picStyle: CSSProperties = {
            position: 'absolute',
            top: '79px',
            left: '67px',
        };
        const titleStyle: CSSProperties = {
            position: 'absolute',
            top: '52px',
            left: '67px',
        };
        let style = {};
        switch (type) {
            case 'luckyRedPackage':
                picUrl = require('./lucky-red-package-example.png');
                break;
            case 'cardCollection':
                picUrl = require('./card-collection-reference.jpg');
                break;
            case 'combineGoodsExample':
                picUrl = require('./combine-goods-example.png');
                break;
            case 'businessMall':
                picUrl = require('./business_mall.png');
                width = 900;
                break;
            case 'memberCard':
                picUrl = require('./business_mall.png');
                width = 900;
                break;
            case 'recruitDistributorShareExample':
                picUrl = require('./recruit_distributor_share_example.png');
                width = 300;
                style = { position: 'relative' };
                break;
            case 'memberPoint':
                picUrl = require('./member_point1.png');
                picUrlTwo = require('./member_point2.png');
                width = 900;
                style = { height: '600px', overflow: 'hidden', 'overflow-y': 'auto' };
                break;
            case 'parkingFee':
                picUrl = require('./parking_fee1.png');
                picUrlTwo = require('./parking_fee2.png');
                width = 900;
                style = { height: '600px', overflow: 'hidden', 'overflow-y': 'auto' };
                break;
            case 'eventMarkets':
                picUrl = require('./event_markets.png');
                width = 300;
                break;
            case 'executionResult':
                picUrl = require('./security-policy.jpg');
                width = 300;
                break;
            case 'autoReply':
                picUrl = require('./autoR-reply-pic.png');
                width = 900;
                break;
            default:
                break;
        }
        return (
            <div>
                <div>
                    {extraText ? <span>{extraText}</span> : null}
                    <Button onClick={this.toggle} type="link">
                        {buttonName ? buttonName : language.getText('clickPicViewRelation')}
                    </Button>
                </div>
                {showDownload && (
                    <div>
                        <Button type="link" onClick={this.download}>
                            {downloadButtonName
                                ? downloadButtonName
                                : language.getText('clickDownActivityExample')}
                        </Button>
                    </div>
                )}

                <Modal
                    zIndex={9999}
                    footer={null}
                    title={language.getText('picRelation')}
                    onCancel={this.toggle}
                    visible={this.state.visible}
                    width={`${width + 50}px`}
                >
                    <div style={style}>
                        <img src={picUrl} width={`${width}px`} alt="" />
                        {share.title && <div style={titleStyle}>{share.title}</div>}
                        {share.pic &&
                            share.pic.length &&
                            type === 'recruitDistributorShareExample' && (
                                <img
                                    src={share.pic[0].path || share.pic}
                                    width={`160px`}
                                    height={`130px`}
                                    alt=""
                                    style={picStyle}
                                />
                            )}
                        {picUrlTwo && <img src={picUrlTwo} width={`${width}px`} alt="" />}
                    </div>
                </Modal>
            </div>
        );
    }
}
