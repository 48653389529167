import { Config } from '@comall-backend-builder/core/lib/parser';
import { forEach, isEmpty, map, get, isNull } from 'lodash';
import { RegistrationPriceType } from '../../../../components/registration-price';
import { ActivityTagType } from '../../../cms/mall-activity-tag';
import { RegistrationTimeType } from '../../../../components';
import { config as newMallActivityAfterSale } from './new-mall-activity-after-sale';
import { config as newMallActivityRefundOrder } from './new-mall-activity-refund-order';
import { config as newMallActivityVerificationConfig } from './new-mall-activity-verification';
import isNil from 'lodash/isNil';
import { TimeUnit } from '../../../../types/mode/object/mall-activity-subscript-message-setting/mall-activity-subscript-message-setting';

const GENDER_OPTIONS = [
    {
        id: 'MAN',
        name: '男',
    },
    {
        id: 'WOMAN',
        name: '女',
    },
];
const REGISTRATION_STATUS_OPTIONS = [
    {
        id: 'STARTED',
        name: '<<inprogress>>',
    },
    {
        id: 'COMPLETE',
        name: '<<end>>',
    },
    {
        id: 'NOT_STARTED',
        name: '<<notStarted>>',
    },
    {
        id: 'NULL',
        name: '非报名',
    },
];

const EXECUTE_STATUS_OPTIONS = [
    {
        id: 'STARTED',
        name: '<<inprogress>>',
    },
    {
        id: 'COMPLETE',
        name: '<<end>>',
    },
    {
        id: 'NOT_STARTED',
        name: '<<notStarted>>',
    },
];

export const config: Config = {
    entities: {
        MallActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
            apiPath: '/loader/admin/mallActivity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteId: {
                    type: 'array.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                registrationStatus: {
                    type: 'string.options.select',
                    displayName: '报名状态',
                    options: REGISTRATION_STATUS_OPTIONS,
                },
                mallActivityStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: EXECUTE_STATUS_OPTIONS,
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                info: {
                    type: 'object.mallActivityInfo',
                    displayName: '<<hdxx>>',
                },
                editRegistrationStatus: {
                    type: 'string',
                },
                startTime: {
                    displayName: '<<startTime>>',
                    type: 'string',
                },
                endTime: {
                    displayName: '<<endTime>>',
                    type: 'string',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<merchantName>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                registrationStatus: {
                    type: 'string',
                    displayName: '报名状态',
                },
                enable: {
                    type: 'boolean',
                    displayName: '<<kqzt>>',
                },
                mallActivityStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                blackUserListFileUrl: {
                    type: 'string.activityBlackImportExport',
                    displayName: '<<hmd>>',
                },

                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdxx>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        name: {
                            type: 'string.text.paragraph',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<cymdjzj>>',
                            rules: [{ required: true, type: 'array' }],
                            controlConfig: {
                                uniqueSubsite: true,
                            },
                        },

                        dateRange: {
                            type: 'object.dateRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            extra: '<<hddksjsrq>>',
                        },
                        editStartTime: {
                            type: 'string.date',
                            displayName: '活动开始日期',
                            rules: [{ required: true, message: '请选择开始日期' }],
                        },
                        editEndTime: {
                            type: 'string.date',
                            displayName: '活动结束日期',
                            rules: [{ required: true, message: '<<qxzjsrq>>' }],
                        },
                        dayTime: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<acitivityTime>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd > 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart < 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        } else {
                                            return false;
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            extra: '活动每天的开始结束时间',
                        },
                        addressName: {
                            type: 'string.text.paragraph',
                            displayName: '活动地点名称',
                            rules: [{ required: true }],
                            controlConfig: {
                                placeholder: '请输入活动地点名称，不超过100个字',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        address: {
                            type: 'string.text.paragraph',
                            displayName: '<<detailAddress>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                placeholder: '请输入详细地址，不超过100个字',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        phone: {
                            type: 'string',
                            displayName: '<<servicePhone>>',
                            rules: [
                                {
                                    pattern: /^[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]{11,20}$/,
                                    message: '<<qsrzqgsdsjh>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '请输入咨询电话',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        merchantUserPhone: {
                            type: 'string.merchantUserPhoneInput',
                            displayName: '咨询导购',
                            controlConfig: {
                                placeholder: '请输入店员手机号',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        tagIdList: {
                            type: 'array.MallActivityTag.selector',
                            displayName: '活动标签',
                            controlConfig: {
                                placeholder: '<<activityTagRequired>>',
                                style: {
                                    width: '450px',
                                },
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds = get(row, 'baseInfo.merchants.length')
                                        ? map(row.baseInfo.merchants, 'id').join(',')
                                        : undefined;
                                    return {
                                        type: ActivityTagType.MALL_ACTIVITY,
                                        subsiteIds,
                                    };
                                },
                            },
                        },

                        canShare: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<fxsz>>',
                            defaultValue: ['true'],
                            options: [{ id: 'true', name: '允许将该活动分享给好友' }],
                        },
                        hide: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '隐藏设置',
                            defaultValue: ['false'],
                            options: [{ id: 'true', name: '该活动在活动列表、活动日历不展示' }],
                        },
                    },
                },
                mediaInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    properties: {
                        pictures: {
                            displayName: '<<hdtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '建议尺寸:750 * 422  大小:不超过1M  格式:JPG,PNG,JPEG',
                        },
                        poster: {
                            displayName: '<<fxhb>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '建议尺寸:560*560   大小:不超过1M  格式:JPG,PNG,JPEG',
                        },
                        info: {
                            type: 'string.richTextPlus',
                            displayName: '活动详情',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '建议传宽度为750px的图片；建议字号选择为14px',
                        },
                    },
                },
                registrationInfo: {
                    type: 'object.subForm',
                    displayName: '报名设置',
                    collapsed: true,
                    properties: {
                        registrationType: {
                            type: 'string.options.radio',
                            displayName: '是否需要报名',
                            defaultValue: 'NO',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'NO',
                                    name: '否',
                                },
                                {
                                    id: 'YES',
                                    name: '是',
                                },
                            ],
                            extra: '选择[是]后，用户需要先报名后参与活动',
                        },
                        session: {
                            type: 'string.options.radio',
                            displayName: '<<sfaccbm>>',
                            defaultValue: 'NO',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'NO',
                                    name: '报名后活动期间均可参与',
                                },
                                {
                                    id: 'YES',
                                    name: '按场次报名活动',
                                },
                            ],
                        },
                        registrationTimes: {
                            type: 'array.activityRegistrationTimePicker',
                            displayName: '<<hdcc>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    message: '活动场次的每个时间段不可重复',
                                    validator: (rule: any, value: any) => {
                                        const completeStartWithEnd = value
                                            .filter(
                                                (item: any) =>
                                                    item.startDate && item.startTime && item.endTime
                                            )
                                            .map((item: any) => ({
                                                start: `${item.startDate} ${item.startTime}`,
                                                end: `${item.startDate} ${item.endTime}`,
                                            }))
                                            .sort((a: any, b: any) => (a.start > b.start ? 1 : -1));

                                        return completeStartWithEnd.every(
                                            (item: any, index: number) => {
                                                if (index === 0) {
                                                    return true;
                                                }

                                                const prev = completeStartWithEnd[index - 1];

                                                return item.start >= prev.end;
                                            }
                                        );
                                    },
                                },
                            ],
                        },
                        registrationStartTime: {
                            type: 'object.mallActivityRegistrationTime',
                            defaultValue: { timeType: RegistrationTimeType.DAY_TIME },
                            displayName: '报名开始时间',
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, cb: any) => {
                                        if (value.timeType === 'DAY_TIME') {
                                            if (!value.timeStr) {
                                                return false;
                                            }
                                        } else {
                                            if (!value.num || !value.timeUnit) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    message: '报名开始时间必填',
                                },
                            ],
                            controlConfig: {
                                mode: 'BEGIN',
                            },
                            extra: '<<rgxzaccbm>>',
                        },
                        registrationEndTime: {
                            defaultValue: { timeType: RegistrationTimeType.DAY_TIME },
                            type: 'object.mallActivityRegistrationTime',
                            displayName: '报名结束时间',
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, cb: any) => {
                                        if (value.timeType === 'DAY_TIME') {
                                            if (!value.timeStr) {
                                                return false;
                                            }
                                        } else {
                                            if (isNil(value.num) || isNil(value.timeUnit)) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    message: '报名结束时间必填',
                                },
                            ],
                        },
                        totalQuantity: {
                            type: 'number.tip',
                            displayName: '活动总报名名额',
                            rules: [
                                { required: true },
                                {
                                    type: 'number',
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 120,
                                },
                                min: 1,
                                max: 999999999,
                                addonafter: '人',
                                tip: '',
                            },
                        },
                        limitCountType: {
                            type: 'string.options.radio',
                            displayName: '每用户报名名额',
                            defaultValue: 'NO',
                            rules: [{ required: true }],
                            options: [],
                            source: {
                                apiPath: '/loader/admin/mallActivity/limitCountType',
                                dependences: ['registrationInfo.session'],
                            },
                        },
                        limitCount: {
                            type: 'number.tip',
                            displayName: '每用户限制报名名额',
                            defaultValue: 1,
                            extra: '对于按场次报名的活动，用户只能报名同一个场次',
                            rules: [
                                { required: true },
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '请输入大于1的正整数',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                min: 1,
                                max: (row: any) => {
                                    return get(row, 'registrationInfo.totalQuantity') || 999999999;
                                },
                                style: {
                                    width: 120,
                                },
                                addonafter: '人',
                                tip: '',
                            },
                        },
                        limitSession: {
                            type: 'string.options.radio',
                            displayName: '每用户报名场次',
                            defaultValue: 'NO',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'NO',
                                    name: '无限制',
                                },
                                {
                                    id: 'YES',
                                    name: '限制场次',
                                },
                            ],
                        },
                        limitSessionCount: {
                            type: 'object.limitSessionCount',
                            displayName: '每用户限制报名场次',
                            rules: [
                                { required: true },
                                {
                                    message: '<<qtxccgz>>',
                                    validator: (rule: any, value: any) => {
                                        if (get(value, 'activityCount') || get(value, 'dayCount')) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                        priceType: {
                            type: 'string.options.radio',
                            displayName: '报名价格',
                            defaultValue: RegistrationPriceType.NONE,
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: RegistrationPriceType.NONE,
                                    name: '免费报名',
                                },
                                {
                                    id: RegistrationPriceType.PAY,
                                    name: '付费报名',
                                },
                            ],
                        },
                        payList: {
                            type: 'array.payList',
                            displayName: '会员等级付费规则',
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, cb: any) => {
                                        if (value.list && value.list.length > 0) {
                                            if (
                                                value.list.some(
                                                    (item: any) =>
                                                        isNull(item.payCash) ||
                                                        isNull(item.payPoint)
                                                )
                                            ) {
                                                return cb('请输入每名额报名费用');
                                            }
                                            if (
                                                value.list.some(
                                                    (item: any) =>
                                                        item.payCash === 0 && item.payPoint === 0
                                                )
                                            ) {
                                                return cb('报名积分、支付金额，不能同时为0');
                                            }
                                        }
                                        cb();
                                    },
                                },
                            ],
                            defaultValue: {
                                separateLevel: false,
                                list: [
                                    {
                                        mcCardLevelId: -1,
                                        payCash: 0,
                                        payPoint: 0,
                                        payGold: 0,
                                        quota: 0,
                                    },
                                ],
                            },
                            options: [],
                            source: {
                                apiPath: '/loader/admin/mallActivity/userCardLevels',
                                dependences: ['baseInfo.merchants'],
                            },
                        },
                        // registrationPrice: {
                        //     type: 'object.registrationPrice',
                        //     displayName: '报名价格',
                        //     defaultValue: {
                        //         type: RegistrationPriceType.NONE,
                        //         price: '',
                        //         integral: '',
                        //     },
                        //     rules: [
                        //         { required: true },
                        //         {
                        //             message: '请输入报名价格',
                        //             validator: (rule: any, value: any) => {
                        //                 if (value.type === RegistrationPriceType.PAY) {
                        //                     if (!value.price && !value.integral) {
                        //                         return false;
                        //                     }
                        //                 }
                        //                 return true;
                        //             },
                        //         },
                        //     ],
                        // },
                        targetUserType: {
                            type: 'string.options.radio',
                            displayName: '参与用户身份限制',
                            defaultValue: 'ANY',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'ANY',
                                    name: '无限制',
                                },
                                {
                                    id: 'SOME',
                                    name: '限制指定人群',
                                },
                            ],
                        },
                        targetUserLevels: {
                            type: 'array.options.select',
                            displayName: '卡等级',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/mallActivity/userCardLevels',
                                dependences: ['baseInfo.merchants'],
                            },
                            controlConfig: {
                                placeholder: '请选择用户等级',
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        targetUserBirthDay: {
                            type: 'object.monthDayRange',
                            displayName: '<<srfw>>',
                            controlConfig: {
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        targetUserSexuality: {
                            type: 'string.options.select',
                            displayName: '<<xb_1>>',
                            options: GENDER_OPTIONS,
                            controlConfig: {
                                placeholder: '请选择性别',
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        customInfo: {
                            type: 'array.customForm',
                            displayName: '报名信息',
                            defaultValue: [],
                        },
                        info: {
                            type: 'string.text.paragraph',
                            displayName: '报名须知',
                            rules: [
                                {
                                    whitespace: true,
                                    max: 500,
                                    message: '请输入报名须知，不超过500个字',
                                },
                            ],
                            controlConfig: {
                                placeholder: '请输入报名须知',
                                rows: 4,
                                maxLength: 500,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                        reviewType: {
                            type: 'string.options.radio',
                            displayName: '报名审核',
                            defaultValue: 'FALSE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                {
                                    id: 'FALSE',
                                    name: '不需要审核',
                                },
                                {
                                    id: 'TRUE',
                                    name: '需要审核',
                                },
                            ],
                            extra:
                                '选择[不需要审核]，报名即成功；选择[需要审核]，报名后需要在后台进行审核，审核不通过或者活动结束仍未审核则自动退款',
                        },
                        cancelableRule: {
                            type: 'object.mallActivityCelable',
                            displayName: '报名取消',
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, cb: any) => {
                                        if (value.cancelable && !value.cancelableRule.duration) {
                                            return false;
                                        }
                                        return true;
                                    },
                                    message: '请输入取消时间',
                                },
                            ],
                            defaultValue: {
                                cancelable: false,
                                cancelableRule: {
                                    duration: undefined,
                                    unit: 'HOUR',
                                },
                            },
                        },

                        excludeRule: {
                            type: 'string.options.radio',
                            displayName: '商场活动排斥规则',
                            defaultValue: 'NO',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'NO',
                                    name: '<<bszpcgz>>',
                                },
                                {
                                    id: 'YES',
                                    name: '设置排斥规则',
                                },
                            ],
                            extra: `选择【不设置排斥规则】，报名任何活动后都可以报名此活动选择
                                    选择【设置排斥规则】，报名指定活动后不能再报名此活动`,
                        },
                        excludeType: {
                            type: 'string.options.radio',
                            displayName: '全部活动均排斥此活动',
                            defaultValue: 'ALL',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'ALL',
                                    name: '全部活动均排斥此活动',
                                },
                                {
                                    id: 'SOME',
                                    name: '部分活动均排斥此活动',
                                },
                            ],
                        },
                        excludeTypeActivity: {
                            rules: [{ required: true }],
                            type: 'array.activities.selector',
                            displayName: '选择活动',
                        },
                    },
                },
                contentInfo: {
                    type: 'object.subForm',
                    displayName: '内容相关设置',
                    collapsed: true,
                    properties: {
                        creator: {
                            type: 'object.activityCreatorPhoneInput',
                            displayName: '活动发起人',
                            extra: '活动发起人必须是内容平台的注册用户',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        creatorId: {
                            type: 'string',
                            displayName: '活动发起人ID',
                        },
                        activityTopics: {
                            type: 'array.MallActivityTopic.selector',
                            displayName: '活动话题',
                            defaultValue: [],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        rewardDistributionTime: {
                            type: 'string.options.radio',
                            displayName: '奖品发放',
                            defaultValue: 'VERIFY_ACTIVITY',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'VERIFY_ACTIVITY',
                                    name: '核销活动后发放',
                                },
                                {
                                    id: 'SIGN_UP_SUCCESS',
                                    name: '报名成功后发放',
                                },
                            ],
                            extra:
                                '奖品发放后不追回，如必须用户参加活动才能得到奖品，请选择核销活动后发放',
                        },
                        reward: {
                            type: 'array.rewardSetting',
                            displayName: '<<hdjp>>',
                            controlConfig: {
                                eventType: 'MALL_ACTIVITY',
                                canChangeQuantity: false,
                            },
                        },
                    },
                },
                seniorSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        enableNotice: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '消息通知',
                            defaultValue: ['true'],
                            options: [{ id: 'true', name: '开启消息通知' }],
                            extra:
                                '通过订阅消息发送活动报名开始提醒、报名名额空缺提醒、报名成功提醒、活动参与提醒，' +
                                '需要开启小程序订阅消息功能，并提前配置订阅消息模版id，未配置则不能发送消息',
                        },
                        subscriptMessage: {
                            type: 'object.mallActivity.subscriptMessage.setting',
                            displayName: '活动参与提醒',
                            defaultValue: {
                                timeUnit: TimeUnit.HOUR,
                                num: 24,
                            },
                            extra:
                                '用户报名时间早于提醒时间，到提醒时间可收到提示，否则用户无法收到开始提醒',
                        },
                        marking: {
                            type: 'object.memberMarking',
                            displayName: '同步打标签',
                        },
                    },
                },
                mallActivityStatisticTotal: {
                    type: 'string',
                    displayName: '参与人数/报名人数/活动名额',
                },
            },
        },
    },
    components: {
        MagicMallActivityView: {
            component: 'Viewport',
            entity: 'MallActivityEntity',
            className: 'tabs-content-viewport',
        },
        MagicMallActivityTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<hdsz>>',
                    content: {
                        component: 'MagicMallActivityTablePage',
                    },
                },
            ],
        },
        MagicMallActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'MagicMallActivityTabs' }],
        },
        MagicMallActivityTablePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '创建活动',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 2,
                                    route: '/mall-activity/add',
                                },
                                {
                                    type: 'component',
                                    component: 'PageNewPreviewCodeButton',
                                    codeTypes: 'all',
                                    codeModalType: 'list',
                                    codePageType: 'mallActivityListPage',
                                    buttonTitle: '推广活动列表',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MallActivityTable' },
            ],
        },
        MallActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'registrationStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'mallActivityStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        MallActivityAddPage: {
            component: 'FlexLayout',
            entity: 'MallActivityEntity',
            direction: 'horizontal',
            items: [
                { component: 'MallActivityLeftInfoView' },
                { component: 'MallActivityAddFormView' },
            ],
        },
        MallActivityEditPage: {
            component: 'FlexLayout',
            entity: 'MallActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'MallActivityLeftInfoView' },
                { component: 'MallActivityEditFormView' },
            ],
        },
        MallActivityCopyPage: {
            component: 'FlexLayout',
            entity: 'MallActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'MallActivityLeftInfoView' },
                { component: 'MallActivityCopyFormView' },
            ],
        },
        MallActivityCopyFormView: {
            component: 'MallActivityAddFormView',
            formComponent: 'CopyFormPlus',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mall-activity',
                    component: 'MagicMallActivityView',
                    breadcrumbName: '新商场活动',
                    privilege: {
                        path: 'newMallActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MallActivityAddPage',
                            breadcrumbName: '创建活动',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MallActivityEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'MallActivityCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/record/:activityId',
                            component: 'MagicMallActivityRegistrationPage',
                            breadcrumbName: '报名记录',
                        },
                        {
                            path: '/record-detail/:recordId',
                            component: 'MagicMallActivityRegistrationDetailPage',
                            breadcrumbName: '报名订单详情',
                        },
                        {
                            path: '/apply/:recordId',
                            component: 'MagicMallActivityRegistrationApplyPage',
                            breadcrumbName: '售后',
                        },
                        { path: '/', component: 'MagicMallActivityPage' },
                    ],
                },
            ],
        },
    ],
};

/**
 * 1. 将三个功能的源码各拷贝一份出来：报名售后单、退款单、核销记录。最后合并到上面的 config 中。
 * -- import { config as newMallActivityAfterSale } from './new-mall-activity-after-sale';
 * -- import { config as newMallActivityRefundOrder } from './new-mall-activity-refund-order';
 * -- import { config as newMallActivityVerificationConfig } from './new-mall-activity-verification';
 * 2. 将相关的依赖组件源码拷贝一份出来：报名售后单-详情页
 * -- export * from './new-mall-activity-after-sale-info';
 * 3. 拷贝出的功能中涉及的 entity 名称、component 名称、path 路径需要改名以区分原功能。
 * -- 如增加 entity 名称、component 名称的前缀 (如 "New"), 如增加一级 route.path 的前缀 (如 "new-")
 * 4. 合并[1]中的 entities、components、routes 配置到总 config 中。
 * 5. 替换拷贝出的源码配置中的 component、entity、path
 */
class ConfigDeepMerging {
    /**
     * 构造函数中执行主程序
     * @param rootConfig
     * @param configItems
     */
    constructor(public rootConfig: Config, public configItems: ConfigItem[]) {
        // 1. 扫描需要替换的额外依赖的组件名称, 如上述步骤 2 中提及的模块
        configItems.forEach(this.scanComponents);
        // 2. 合并拷贝出的源码配置，合并过程中记录出现过的组件、实体、路由并设置转化名称到 keyMapp
        configItems.forEach((item) => {
            this.mergeRoutes(rootConfig, item);
            this.mergeEntities(rootConfig, item);
            this.mergeComponents(rootConfig, item);
        });
        // 3. 根据 2 中设置的转化名称，替换 routes 中的组件名称
        this.replaceOld(rootConfig.routes, 'component');
        // 4. 根据 2 中设置的转化名称，替换 components 中的 组件、实体、路由
        this.replaceOld(rootConfig.components, 'component', 'entity', 'path');
    }

    /**
     * 合并路由配置
     * @param rootConfig
     * @param configItem
     */
    mergeRoutes = (rootConfig: Config, configItem: ConfigItem) => {
        const rootRoutes = (rootConfig.routes = rootConfig.routes || []);
        const itemRoutes = configItem.config.routes || [];
        // 1. 获取所有一级子路由
        const tabRoutes = itemRoutes.map((route) => route.childRoutes || []).flat();
        // 2. 转化一级子路由 path
        tabRoutes.forEach((route) => this.mapping(route.path, true));
        // 3. 将页面配置插入页签 [MagicMallActivityTabs.items] 中
        this.insertTabItem(rootConfig, configItem);
        // 4. 将路由插入 /mall-activity routes[0].childRoutes[0].childRoutes
        rootRoutes[0].childRoutes?.[0]?.childRoutes?.unshift(
            ...tabRoutes
                .map((tabRoute) => {
                    return (tabRoute.childRoutes || [])
                        .filter(({ path }) => path !== '/')
                        .map((tabSubRoute) => {
                            const path = `${tabRoute.path}${tabSubRoute.path}`
                                .split('/')
                                .filter(Boolean)
                                .join('/');
                            tabSubRoute.path = `/${path}`;
                            return tabSubRoute;
                        });
                })
                .flat()
        );
    };

    /**
     * 合并实体配置
     * @param rootConfig
     * @param configItem
     */
    mergeEntities = (rootConfig: Config, configItem: ConfigItem) => {
        const obj = rootConfig.entities;
        const merge = configItem.config.entities;
        if (obj && merge) {
            Object.keys(merge).forEach((key) => (obj[this.mapping(key, true)] = merge[key]));
        }
    };

    /**
     * 合并组件配置
     * @param rootConfig
     * @param configItem
     */
    mergeComponents = (rootConfig: Config, configItem: ConfigItem) => {
        const obj = rootConfig.components;
        const merge = configItem.config.components;
        if (obj && merge) {
            Object.keys(merge).forEach((key) => {
                const mappedKey = this.mapping(key, true);
                obj[mappedKey] = merge[key];
            });
        }
    };

    /**
     * 转化各种索引：组件名、实体名、路径
     * @param key
     * @param ifCreate
     * @returns
     */
    mapping = (key: string, ifCreate = false) => {
        if (key.startsWith('/')) {
            // 1. 替换路由
            return this.mappingPath(key, ifCreate);
        } else {
            // 2. 替换实体、组件名称
            return this.mappingName(key, ifCreate);
        }
    };

    /**
     * 转化路径
     * @param key
     * @param ifCreate
     * @returns
     */
    mappingPath = (key: string, ifCreate: boolean) => {
        const paths = key.split('/').filter(Boolean);
        const prefix = this.keyMap.get(paths[0]);
        if (prefix) {
            paths[0] = prefix;
            return `/${paths.join('/')}`;
        } else if (ifCreate) {
            const tabRootPath = this.getTabRootPath();
            this.keyMap.set(paths[0], (paths[0] = `${tabRootPath}/${paths[0]}`));
            return `/${paths.join('/')}`;
        } else {
            return key;
        }
    };

    getTabRootPath = () => {
        return this.rootConfig.routes?.[0]?.childRoutes?.[0].path
            .split('/')
            .filter(Boolean)
            .join('/');
    };

    /**
     * 转化组件名、实体名
     * @param key
     * @param ifCreate
     * @returns
     */
    mappingName = (key: string, ifCreate: boolean) => {
        let randomKey = this.keyMap.get(key);
        if (randomKey) {
            // skip
        } else if (ifCreate) {
            randomKey = `New${key}`;
            this.keyMap.set(key, randomKey);
        } else {
            randomKey = key;
        }
        return randomKey;
    };

    /**
     * 转化用的缓存
     */
    keyMap = new Map<string, string>();

    /**
     * 将转化前的索引 深替换为 转化后的索引
     * @param obj
     * @param keys
     */
    replaceOld = (obj: any, ...keys: string[]) => {
        if (Array.isArray(obj)) {
            obj.forEach((item) => this.replaceOld(item, ...keys));
        } else if (obj !== null && typeof obj === 'object') {
            Object.entries(obj).forEach(([replaceKey, value]) => {
                if (keys.includes(replaceKey) && typeof value === 'string') {
                    obj[replaceKey] = this.mapping(value);
                } else {
                    this.replaceOld(value, ...keys);
                }
            });
        }
    };
    /**
     * 插入页面到页签 [MagicMallActivityTabs.items] 中
     * @param rootConfig
     * @param configItem
     */
    insertTabItem(rootConfig: Config, configItem: ConfigItem) {
        const itemRoutes = configItem.config.routes || [];
        // 1. 获取所有一级子路由 routes[].childRoutes (一般只有一个)
        const tabRoutes = itemRoutes.map((route) => route.childRoutes || []).flat();
        // 2. 获取页签配置
        const tabItems: any[] = rootConfig.components?.MagicMallActivityTabs?.items || [];
        // 3. 插入页面配置到页签(每个一级子路由都被插入到一个页签，但目前一级子路由只有一个，暂不考虑多个的情况)
        tabRoutes
            // 3.1. 从一级子路由的二级子路由中查找 path==="/" 的路由以获取页面组件 component
            // ---- routes[].childRoutes[].childRoutes[path==="/"].component
            .map((route) => route.childRoutes?.find(({ path }) => path === '/')?.component)
            // 3.2. 根据 component、configItem.name 生成页签配置并插入
            .forEach((component) => {
                const tabItem = { title: configItem.name, content: { component } };
                // 顺便将 entity 绑定到 Tab Page 上
                if (component) {
                    const componentSetting = configItem?.config?.components?.[component];
                    const entityName = Object.getOwnPropertyNames(
                        configItem?.config?.entities || {}
                    )[0];
                    if (componentSetting && entityName) {
                        componentSetting.entity = entityName;
                    }
                }
                if (tabItems[configItem.tabIndex]) {
                    tabItems.splice(configItem.tabIndex, 0, tabItem);
                } else {
                    tabItems[configItem.tabIndex] = tabItem;
                }
            });
    }

    scanComponents = (item: any) => {
        if (Array.isArray(item)) {
            item.forEach((item) => this.scanComponents(item));
        } else if (item !== null && typeof item === 'object') {
            Object.entries(item).forEach(([replaceKey, value]) => {
                if (
                    replaceKey === 'component' &&
                    typeof value === 'string' &&
                    !this.componentExcluded.has(value)
                ) {
                    this.mapping(value, true);
                } else {
                    this.scanComponents(value);
                }
            });
        }
    };
    componentExcluded = new Set([
        'FlexLayout',
        'Viewport',
        'FilterFormPlus',
        'Tabs',
        'DataTable',
        'ChangeStatusButton',
    ]);
}
type ConfigItem = { config: Config; name: string; tabIndex: number };
new ConfigDeepMerging(config, [
    { config: newMallActivityAfterSale, name: '报名售后单', tabIndex: 1 },
    { config: newMallActivityRefundOrder, name: '退款单', tabIndex: 2 },
    { config: newMallActivityVerificationConfig, name: '核销记录', tabIndex: 3 },
]);
