import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        contentScore: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/admin/score/config',
            properties: {
                likeScore: {
                    type: 'number.tip',
                    displayName: '<<bjdzd>>',
                    controlConfig: {
                        addonafter: '<<fen>>',
                        placeholder: '<<qsrbjdzdf>>',
                        maxLength: 10,
                    },
                },
                commentScore: {
                    type: 'number.tip',
                    displayName: '<<bjpld>>',
                    controlConfig: {
                        addonafter: '<<fen>>',
                        placeholder: '<<qsrbjpldf>>',
                        maxLength: 10,
                    },
                },
                shareScore: {
                    type: 'number.tip',
                    displayName: '<<bjfxd>>',
                    controlConfig: {
                        addonafter: '<<fen>>',
                        placeholder: '<<qsrbjfxdf>>',
                        maxLength: 10,
                    },
                },
                collectScore: {
                    type: 'number.tip',
                    displayName: '<<bjszd>>',
                    controlConfig: {
                        addonafter: '<<fen>>',
                        placeholder: '<<qsrbjszdf>>',
                        maxLength: 10,
                    },
                },
                followScore: {
                    type: 'number.tip',
                    displayName: '<<xzfsd>>',
                    controlConfig: {
                        addonafter: '<<fen>>',
                        placeholder: '<<qsrxzfsdf>>',
                        maxLength: 10,
                    },
                    extra: '<<followScoreExtra>>',
                },
            },
        },
    },
    components: {
        ContentScoreView: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'contentScore',
            items: [
                {
                    component: 'ContentScoreForm',
                },
            ],
        },
        ContentScoreForm: {
            component: 'ModifyForm',
            entity: 'contentScore',
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'likeScore',
                },
                {
                    property: 'commentScore',
                },
                {
                    property: 'shareScore',
                },
                {
                    property: 'collectScore',
                },
                {
                    property: 'followScore',
                },
            ],
            submit: { text: '<<common.save>>' },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () => {
                    window.location.reload();
                });
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/content-score',
                    breadcrumbName: '<<ontentScore>>',
                    component: 'ContentScoreView',
                    privilege: {
                        path: 'showNoteScoreConfig',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
