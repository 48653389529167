import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        voiceSettingView: {
            component: 'Viewport',
        },
        voiceSettingPage: {
            component: 'Card',
            content: { component: 'VoiceSettingForm' },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/voiceSetting',
                    component: 'voiceSettingView',
                    breadcrumbName: '<<broadcastConfig>>',
                    privilege: {
                        path: 'broadcastConfig',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'voiceSettingPage' }],
                },
            ],
        },
    ],
};
