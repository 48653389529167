import React from 'react';
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import './index.less';

export enum AUDIT_STATUS {
    未审核 = 'NOT_AUDIT',
    审核中 = 'AUDIT',
    审核未通过 = 'FAIL',
    已入库 = 'SUCCESS',
}

export enum PRICE_TYPE {
    一口价 = 'FIXED',
    价格区间 = 'INTERVAL',
    折扣价 = 'DISCOUNT',
}

export const validate = (product: LiveRoomProductForSubmit & Object) => {
    if (product.hasOwnProperty('name') && !product.name) {
        throw new Error(services.language.getText('spmcbnwk'));
    } else if ((product.name?.length || 0) > 14) {
        throw new Error(services.language.getText('spmcbncg'));
    } else if (product.priceType === PRICE_TYPE.一口价 && !product.price) {
        throw new Error(services.language.getText('spmcbncg'));
    } else if (
        product.priceType === PRICE_TYPE.价格区间 &&
        !(product.price2 && product.price > 0 && product.price < product.price2)
    ) {
        throw new Error(services.language.getText('jgqjzzyxyyz'));
    } else if (
        product.priceType === PRICE_TYPE.折扣价 &&
        !(product.price2 && product.price2 > 0 && product.price2 < product.price)
    ) {
        throw new Error(services.language.getText('zkjyxyscj'));
    }
};

export const formatPrice = (price: any) => {
    try {
        if (typeof price === 'number' || typeof price === 'string') {
            const priceStr = `${price}`.replace(/[^0-9.]/g, '');
            const dotIndex = priceStr.indexOf('.');
            let newPrice: number;
            if (dotIndex > 0) {
                newPrice = parseFloat(priceStr.substring(0, dotIndex + 3));
            } else {
                newPrice = parseFloat(`${price}`);
            }
            if (!isNaN(newPrice)) {
                return newPrice;
            } else {
                throw new Error();
            }
        } else {
            throw new Error();
        }
    } catch (e) {
        return price;
    }
};

export interface LiveRoomProduct {
    auditStatus: AUDIT_STATUS;
    coverImgUrl: string;
    createTime: string;
    id: number;
    merchantName: string;
    subsiteName: string;
    name: string;
    pageUrl: string;
    price: number;
    price2?: number;
    priceType: PRICE_TYPE;
    goodsCode: string;
    goodsId: number;
    page: string;
    scene: string;
}

export type LiveRoomProductForSubmit = Pick<LiveRoomProduct, 'price' | 'price2' | 'priceType'> & {
    name?: string;
};

const statuses: { id: string; name: string }[] = Object.getOwnPropertyNames(AUDIT_STATUS).map(
    (key) => {
        const id = (AUDIT_STATUS as any)[key];
        return { id, name: key };
    }
);
const ingStatuses = statuses.filter((status) => status.id !== AUDIT_STATUS.已入库);
const LiveRoomProductWarehousingFilter = {
    component: 'FilterFormPlus',
    direction: 'inline',
    submit: { text: '<<common.search>>' },
    reset: { text: '<<common.reset>>' },
    fields: [
        { property: 'goodsName' },
        { property: 'subsiteId' },
        { property: 'merchantId' },
        { property: 'auditStatus' },
    ],
};
const LiveRoomProductWarehousedFilter = {
    ...LiveRoomProductWarehousingFilter,
    fields: [...LiveRoomProductWarehousingFilter.fields.slice(0, -1), { property: 'audit_status' }],
};

const selectPlease = '<<selectPlease>>';

/**
 * 未联调的接口
 */
const importAddParams = {
    apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
    apiPath: '/admin/files/locations/79/upload',
    text: '+ <<importProduct>>',
    templateSource: {
        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
        apiPath: '/admin/live_stream/template.xlsx?add=true',
    },
};
const importRemoveParams = {
    apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
    apiPath: '/admin/files/locations/81/upload',
    text: '+ <<drscsp>>',
    templateSource: {
        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
        apiPath: '/admin/live_stream/template.xlsx?add=false',
    },
};

export const config: Config = {
    entities: {
        LiveRoomProduct: {
            apiPath: '/loader/admin/live_room_product',
            filters: {
                goodsName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<productNameRequired>>',
                        style: { width: 180 },
                    },
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: { apiPath: '/admin/defaults/subsite' },
                    controlConfig: { style: { width: 180 } },
                },
                merchantId: {
                    type: 'string.filterOptions.select',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        dependences: 'subsiteId',
                        paramsName: 'subsiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                        style: { width: 180 },
                    },
                },
                // 用于『未入库』页签的『审核状态』下拉查询条件
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    defaultValue: ingStatuses.map((status) => status.id).join(','),
                    options: [
                        {
                            id: ingStatuses.map((status) => status.id).join(','),
                            name: (<span style={{ color: '#bfbfbf' }}>{selectPlease}</span>) as any,
                        },
                        ...ingStatuses,
                    ],
                    controlConfig: { style: { width: 180 } },
                },
                // 用于『已入库』页签的隐藏默认查询条件
                audit_status: {
                    type: 'string.text',
                    defaultValue: AUDIT_STATUS.已入库,
                    className: 'live-room-product-form-item-hidden',
                },
            },
            properties: {
                product: {
                    type: 'object.LiveRoomProduct',
                    displayName: '<<productInformation>>',
                    controlConfig: { style: { width: 450 } },
                },
                merchant: {
                    type: 'object.merchant',
                    displayName: '<<mdzj>>',
                    controlConfig: { style: { width: 400 } },
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [...statuses],
                    controlConfig: { style: { width: 100 } },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                    // displayName: services.language.getText('createTimeStr'),
                    controlConfig: { style: { width: 200 } },
                },
                // API 中定义的字段名吃: live_goods_list
                liveGoodsList: { type: 'array.LiveRoomProducts' },
            },
        },
        LiveRoomData: {
            apiPath: '/loader/admin/live_broadcast',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<zbjmc>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 180 } },
                },
                liveStatus: {
                    type: 'string.options.select',
                    displayName: '<<zbjzt>>',
                    options: [
                        {
                            id: 'LIVE_NO_START',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'LIVE_GOING',
                            name: '<<zbz>>',
                        },
                        {
                            id: 'LIVE_END',
                            name: '<<end>>',
                        },
                        {
                            id: 'LIVE_EXPIRE',
                            name: '<<ygq>>',
                        },
                        {
                            id: 'LIVE_FORBID',
                            name: '<<jb_1>>',
                        },
                        {
                            id: 'LIVE_STOP',
                            name: '<<zt_1>>',
                        },
                        {
                            id: 'LIVE_EXCEPTION',
                            name: '<<yc_1>>',
                        },
                    ],
                    controlConfig: { style: { width: 120 } },
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<zbsj>>',
                    controlConfig: {
                        placeholder: ['<<ksrq>>', '<<jsrq>>'],
                    },
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<zbjmc>>',
                },
                dateStr: {
                    type: 'array.liveRoomDate',
                    displayName: '<<kbsj>>',
                },
                liveStatusDes: {
                    type: 'string',
                    displayName: '<<zbzt>>',
                    controlConfig: { style: { width: 100 } },
                },
            },
        },
    },
    components: {
        LiveRoomProductView: {
            component: 'Viewport',
        },
        LiveRoomProductListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'LiveRoomProductListPageTabs' }],
        },
        LiveRoomProductListPageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                { title: '<<glzbsp>>', content: { component: 'LiveRoomProductWarehousePage' } },
                { title: '<<zbjsj>>', content: { component: 'LiveRoomDataPage' } },
            ],
        },
        LiveRoomProductWarehousePage: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                { title: '<<wrk>>', content: { component: 'LiveRoomProductWarehousingList' } },
                { title: '<<yrk>>', content: { component: 'LiveRoomProductWarehousedList' } },
            ],
        },
        LiveRoomProductWarehousingList: {
            entity: 'LiveRoomProduct',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'LiveRoomProductWarehousingFilter' },
                { component: 'LiveRoomProductWarehousingButtons' },
                { component: 'LiveRoomProductWarehousingTable' },
            ],
        },
        LiveRoomProductWarehousingFilter: LiveRoomProductWarehousingFilter,
        LiveRoomProductWarehousingButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            className: 'mt24 mb24',
            items: [
                {
                    component: 'Button',
                    text: '<<addProduct>>',
                    type: 'primary',
                    route: '/menu-list/market/live-room-product/add/{{row.id}}',
                    style: { flexGrow: 0, marginRight: '15px' },
                },
                {
                    component: 'LiveRoomProductImportButton',
                    params: importAddParams,
                },
            ],
        },
        LiveRoomProductWarehousingTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                { property: 'product', displayConfig: { style: { width: 450 } } },
                { property: 'merchant', displayConfig: { style: { width: 400 } } },
                { property: 'auditStatus', displayConfig: { style: { width: 100 } } },
                { property: 'createTime', displayConfig: { style: { width: 200 } } },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'LiveRoomProductWarehousingTableOperations',
                    },
                ],
            },
        },
        LiveRoomProductWarehousedList: {
            entity: 'LiveRoomProduct',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'LiveRoomProductWarehousedFilter' },
                { component: 'LiveRoomProductWarehousedButtons' },
                { component: 'LiveRoomProductWarehousedTable' },
            ],
        },
        LiveRoomProductWarehousedFilter: LiveRoomProductWarehousedFilter,
        LiveRoomProductWarehousedButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            className: 'mt24 mb24',
            items: [
                {
                    component: 'LiveRoomProductImportButton',
                    params: importRemoveParams,
                },
            ],
        },
        LiveRoomProductWarehousedTable: { component: 'LiveRoomProductWarehousingTable' },
        LiveRoomProductAdd: {
            component: 'CreateForm',
            entity: 'LiveRoomProduct',
            direction: 'horizontal',
            labelCol: 0,
            fields: [{ property: 'liveGoodsList' }],
            submit: { text: '<<tjsh>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('spxzcg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        LiveRoomDataPage: {
            entity: 'LiveRoomData',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'LiveRoomDataPageFilter' },
                { component: 'LiveRoomDataPageTable' },
            ],
        },
        LiveRoomDataPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'liveStatus',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        LiveRoomDataPageTable: {
            component: 'DataTable',
            className: 'mt24',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [{ property: 'name' }, { property: 'dateStr' }, { property: 'liveStatusDes' }],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                items: [
                    {
                        type: 'link',
                        text: '<<viewData>>',
                        path: '/menu-list/market/live-room-product/live-room-data/{{row.roomId}}',
                    },
                ],
            },
        },
        LiveRoomDataPageDetailPage: {
            entity: 'LiveRoomData',
            loaderType: 'get',
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'LiveRoomCurrentDetail',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/live-room-product',
                    component: 'LiveRoomProductView',
                    breadcrumbName: '<<zbj>>',
                    privilege: {
                        path: 'liveStream',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'LiveRoomProductAdd',
                            breadcrumbName: '<<createProduct>>',
                        },
                        {
                            breadcrumbName: '<<zbjsj>>',
                            path: '/live-room-data/:id',
                            component: 'LiveRoomDataPageDetailPage',
                        },
                        {
                            path: '/',
                            component: 'LiveRoomProductListPage',
                        },
                    ],
                },
            ],
        },
    ],
};
