import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';
import { PictureLinkTypeValue } from '../../components/picture-link-type';
const prefix = 'event-market-preview';
const innerClassNames = {
    register: `${prefix}__register`,
    pay: `${prefix}__pay`,
};
const enum EventCouponStyleType {
    SinnglePoint = 'sinngle-point',
    SinngleCoupon = 'sinngle-coupon',
    Medium = 'medium',
    Big = 'big',
    Package = 'package',
    Custom = 'custom',
}

const enum CouponType {
    Cash = 'CASH',
    Gift = 'GIFT',
    Parking = 'PARKING',
    Package = 'PACKAGE',
    Freight = 'FREIGHT',
    Lottery = 'LOTTERY',
    Balance = 'BALANCE',
}

const enum EventCouponValueTypeEnum {
    No = 'NO',
    Amount = 'AMOUNT',
    EveryReduce = 'EVERY_REDUCE',
    Random = 'RANDOM',
    Discount = 'DISCOUNT',
    Hours = 'HOURS',
    Times = 'TIMES',
}

const POPUP_BG_IMG_CONFIG: any = {
    [EventCouponStyleType.SinnglePoint]: 'sinngle_point_bg.png',
    [EventCouponStyleType.SinngleCoupon]: 'sinngle-coupon_bg.png',
    [EventCouponStyleType.Medium]: 'medium_coupon_bg.png',
    [EventCouponStyleType.Big]: 'big_coupon_bg.png',
    [EventCouponStyleType.Package]: 'package_coupon_bg.png',
};

const POPUP_BG_BOTTOM_IMG_CONFIG: any = {
    [EventCouponStyleType.SinnglePoint]: 'event_coupon_bottom_bg.png',
    [EventCouponStyleType.SinngleCoupon]: 'event_coupon_bottom_bg.png',
    [EventCouponStyleType.Medium]: 'event_coupon_bottom_bg.png',
    [EventCouponStyleType.Big]: 'event_coupon_bottom_bg.png',
    [EventCouponStyleType.Package]: 'package_coupon_bottom_bg.png',
};
/**
 * 营销活动预览效果
 * @param props
 */
const eventMarketPreviewPreview = (props: any) => {
    const { preview = {} } = props;
    const typeId = preview?.baseInfo.typeId || '';
    if (typeId === '10') {
        const imageUrl = require('./images/order.png');
        return (
            <div className={prefix}>
                <div className="event-market-preview__pay">
                    <img alt="" width="100%" src={imageUrl} />
                </div>
            </div>
        );
    }
    if (typeId !== '1') {
        return null;
    }
    const registerMode = preview?.rewardConfig.registerMode + '' || '0';
    if (!registerMode) {
        return null;
    }
    //系统自动发放
    if (registerMode === '0') {
        return (
            <div className={prefix}>
                <div className={innerClassNames.register}>{renderContent(preview)}</div>
            </div>
        );
    }
    //跳转页面领取
    if (registerMode === '1') {
        let auserPictures =
            preview &&
            preview.description &&
            preview.description.auserPictures &&
            preview.description.auserPictures.length > 0
                ? preview.description.auserPictures[0].path
                : '';
        if (!auserPictures) {
            auserPictures = 'https://b-puzhehei-cdn.co-mall.net/global/user_claimbg.png';
        }
        const systemInnerClassNames = {
            couponPopup: `big-coupon-popup`,
            couponPopupContent: `big-coupon-popup__content`,
            couponPopupList: `big-coupon-popup__list`,
        };
        const style = {
            backgroundImage: `url(${auserPictures})`,
            borderRadius: '20px',
        };
        return (
            <div className={prefix}>
                <div className={innerClassNames.register}>
                    <div>
                        <div className={systemInnerClassNames.couponPopup} style={style}>
                            <div className={systemInnerClassNames.couponPopupContent}></div>
                        </div>
                        <img
                            alt=""
                            className="newcomer-gift-popup__close"
                            src="https://b-puzhehei-cdn.co-mall.net/global/btn_close.png"
                        />
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

const getBackgroundImage = (preview: any, styleType: any) => {
    let settingBackgroundPicture =
        preview &&
        preview.description &&
        preview.description.systemPictures &&
        preview.description.systemPictures.length > 0
            ? preview.description.systemPictures[0].path
            : '';
    if (settingBackgroundPicture) {
        return settingBackgroundPicture;
    }
    return 'https://b-puzhehei-cdn.co-mall.net/global/' + POPUP_BG_IMG_CONFIG[styleType];
};

const renderUseButton = (preview: any) => {
    const registerEventUseButton: PictureLinkTypeValue = preview.description.registerEventUseButton;
    if (!registerEventUseButton?.picture) {
        return null;
    }
    return (
        <img
            alt=""
            className="newcomer-gift-popup__use-button"
            src={registerEventUseButton.picture}
        />
    );
};

const renderContent = (preview: any) => {
    if (!preview) {
        return null;
    }
    const registerMode = preview.rewardConfig.registerMode;
    if (registerMode === '0') {
        const styleType = getNewcomerGiftPopupStyleType(preview);
        const backgroundImage = getBackgroundImage(preview, styleType);
        const systemInnerClassNames = {
            couponPopup: `${styleType}-coupon-popup`,
            couponPopupContent: `${styleType}-coupon-popup__content`,
            couponPopupList: `${styleType}-coupon-popup__list`,
        };
        const style = {
            backgroundImage: `url(${backgroundImage})`,
        };
        return (
            <div>
                <div className={systemInnerClassNames.couponPopup} style={style}>
                    <div className={systemInnerClassNames.couponPopupContent}>
                        {renderTitle(styleType)}
                        <div className={systemInnerClassNames.couponPopupList}>
                            {renderPoint(preview, styleType)}
                            {renderExperience(preview, styleType)}
                            {renderGold(preview, styleType)}
                            {renderCoupons(preview, styleType)}
                        </div>
                        {renderBottom(preview, styleType)}
                    </div>
                </div>
                {renderUseButton(preview)}
                <img
                    alt=""
                    className="newcomer-gift-popup__close"
                    src="https://b-puzhehei-cdn.co-mall.net/global/btn_close.png"
                />
            </div>
        );
    }
    return null;
};

const getNewcomerGiftPopupStyleType = (preview: any) => {
    if (!preview.rewardConfig.reward || preview.rewardConfig.reward.length === 0) {
        return null;
    }
    const {
        couponsValue,
        couponsChecked,
        pointChecked,
        pointMax,
        experienceMax,
        experienceChecked,
        goldChecked,
        reward,
    } = preview.rewardConfig.reward[0];
    let systemPicturesUrl =
        preview &&
        preview.description &&
        preview.description.systemPictures &&
        preview.description.systemPictures.length > 0
            ? preview.description.systemPictures[0].path
            : '';
    if (systemPicturesUrl) {
        return EventCouponStyleType.Custom;
    }
    const couponLen = couponsChecked && couponsValue ? couponsValue.length : 0;
    const itemNumMapType: any = {
        1: EventCouponStyleType.SinngleCoupon,
        2: EventCouponStyleType.Medium,
        3: EventCouponStyleType.Big,
    };
    if (couponLen && couponsChecked) {
        if (couponsValue[0]?.type === 'PACKAGE') {
            return EventCouponStyleType.Package;
        }
        const itemNum: number =
            couponLen +
            (pointChecked && pointMax ? 1 : 0) +
            (experienceChecked && experienceMax ? 1 : 0) +
            (goldChecked && reward ? 1 : 0);
        return itemNumMapType[itemNum > 3 ? 3 : itemNum];
    }
    return EventCouponStyleType.SinnglePoint;
};

const renderTitle = (styleType: any) => {
    const titleClassName = `${styleType}-coupon-popup__title`;
    if (styleType === EventCouponStyleType.Package) {
        return <div className={titleClassName}>{services.language.getText('hdyxyhq')}</div>;
    }
    return null;
};

const renderCoupons = (preview: any, styleType: any) => {
    if (!preview.rewardConfig.reward || preview.rewardConfig.reward.length === 0) {
        return null;
    }
    const { couponsValue, couponsChecked } = preview.rewardConfig.reward[0];
    if (!couponsChecked || !couponsValue || couponsValue.length === 0) {
        return null;
    }
    return (
        <div>
            {couponsValue.map((coupon: any, i: number) => {
                const innerClassName = `${styleType}-coupon-popup__list__item`;
                const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
                const style = {
                    backgroundImage: `url(${bg})`,
                };
                const enum ConsumeCondition {
                    /**
                     * 无条件
                     */
                    NoCondition = 'NOCONDITION',
                    /**
                     * 金额满
                     */
                    Money = 'MONEY',
                    /**
                     * 重量满
                     */
                    Weight = 'WEIGHT',
                    /**
                     * 数量满
                     */
                    Quantity = 'QUANTITY',
                }
                let text = '';
                if (
                    coupon.consumeRule &&
                    coupon.consumeRule.condition &&
                    coupon.consumeRule.conditionLowerValue
                ) {
                    const conditionMapRuleText: any = {
                        [ConsumeCondition.NoCondition]: ``,
                        [ConsumeCondition.Money]:
                            services.language.getText('m_3') +
                            `${
                                coupon.consumeRule.conditionLowerValue
                                    ? coupon.consumeRule.conditionLowerValue
                                    : 0
                            }` +
                            services.language.getText('yky'),
                        [ConsumeCondition.Quantity]:
                            services.language.getText('m_3') +
                            `${
                                coupon.consumeRule.conditionLowerValue
                                    ? coupon.consumeRule.conditionLowerValue
                                    : 0
                            }` +
                            services.language.getText('jky'),
                    };
                    text = conditionMapRuleText[coupon.consumeRule.condition];
                }
                return (
                    <div className={innerClassName} key={i}>
                        <div className="event-coupon" style={style}>
                            <div className="event-coupon__left">
                                {renderValue(coupon)}
                                {text && <div className="event-coupon__rule">{text}</div>}
                            </div>
                            <div className="event-coupon__right">
                                <div className="event-coupon__right__title">{coupon.name}</div>
                                <div className="event-coupon__right__time">
                                    {coupon.startDate}-{coupon.endDate}
                                </div>
                                {coupon.quantity && (
                                    <div className="event-coupon__right__quantity">
                                        x{coupon.quantity}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const renderValue = (coupon: any) => {
    const { couponValue, type, couponType } = coupon;
    const couponRuleType = type || couponType;
    if (couponRuleType === CouponType.Gift) {
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__price__text">
                    {services.language.getText('presents')}
                </span>
            </div>
        );
    }
    if (couponRuleType === CouponType.Package) {
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__price__text">
                    {services.language.getText('gifts')}
                </span>
            </div>
        );
    } else if (
        couponRuleType === CouponType.Parking &&
        couponValue.type === EventCouponValueTypeEnum.No
    ) {
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__price__text">
                    {services.language.getText('qm_2')}
                </span>
            </div>
        );
    }
    if (!couponValue || !couponValue.value) {
        return null;
    } else if (
        couponRuleType === CouponType.Parking &&
        couponValue.type === EventCouponValueTypeEnum.Hours
    ) {
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__price__num">{couponValue.value}</span>
                <span className="event-coupon__symbol">{services.language.getText('hour')}</span>
            </div>
        );
    }
    return (
        <div className="event-coupon__price">
            <span className="event-coupon__symbol">
                {services.language.getText('monetaryUnit')}
            </span>
            <span className="event-coupon__price__num">{couponValue.value}</span>
        </div>
    );
};

const renderPoint = (preview: any, styleType: any) => {
    if (!preview.rewardConfig.reward || preview.rewardConfig.reward.length === 0) {
        return null;
    }
    const {
        couponsValus,
        doublePoint,
        pointChecked,
        pointMin,
        pointMax,
    } = preview.rewardConfig.reward[0];
    if (!pointChecked || !pointMin || !pointMax) {
        return null;
    }
    const point = doublePoint ? pointMax * 2 : pointMax;
    const onlyPoint = !(couponsValus && couponsValus.length) && !point;
    const pointClassName = classNames({
        [`newcomer-gift-popup__point-normal`]: !onlyPoint,
        [`newcomer-gift-popup__point-bigger`]: onlyPoint,
    });
    const url =
        styleType === EventCouponStyleType.Custom
            ? 'https://b-puzhehei-cdn.co-mall.net/global/custom_point_bg.png'
            : !onlyPoint
            ? 'https://b-puzhehei-cdn.co-mall.net/global/small_point_bg.png'
            : 'https://b-puzhehei-cdn.co-mall.net/global/bigger_point_bg.png';
    const style = {
        backgroundImage: `url(${url})`,
    };
    const imageSrc = 'https://b-puzhehei-cdn.co-mall.net/global/event_point_icon.png';
    return (
        <div className={pointClassName} style={style}>
            <img alt="" src={imageSrc} />
            <span>
                {point}
                {services.language.getText('point')}
            </span>
        </div>
    );
};

const renderExperience = (preview: any, styleType: any) => {
    if (!preview.rewardConfig.reward || preview.rewardConfig.reward.length === 0) {
        return null;
    }
    const {
        couponsValus,
        doubleExperience,
        experienceChecked,
        experienceMin,
        experienceMax,
    } = preview.rewardConfig.reward[0];
    if (!experienceChecked || !experienceMin || !experienceMax) {
        return null;
    }
    const experience = doubleExperience ? experienceMax * 2 : experienceMax;
    const onlyExperience = !(couponsValus && couponsValus.length) && !experience;
    const pointClassName = classNames({
        [`newcomer-gift-popup__point-normal`]: !onlyExperience,
        [`newcomer-gift-popup__point-bigger`]: onlyExperience,
    });
    const url =
        styleType === EventCouponStyleType.Custom
            ? 'https://b-puzhehei-cdn.co-mall.net/global/custom_point_bg.png'
            : !onlyExperience
            ? 'https://b-puzhehei-cdn.co-mall.net/global/small_point_bg.png'
            : 'https://b-puzhehei-cdn.co-mall.net/global/bigger_point_bg.png';
    const style = {
        backgroundImage: `url(${url})`,
    };
    const imageSrc = 'https://b-puzhehei-cdn.co-mall.net/global/event_point_icon.png';
    return (
        <div className={pointClassName} style={style}>
            <img alt="" src={imageSrc} />
            <span>
                {experience}
                {services.language.getText('experienceManage')}
            </span>
        </div>
    );
};
const renderGold = (preview: any, styleType: any) => {
    if (!preview.rewardConfig.reward || preview.rewardConfig.reward.length === 0) {
        return null;
    }
    const {
        couponsValus,
        goldChecked,
        reward,
        goldTxtName = services.language.getText('jb'),
    } = preview.rewardConfig.reward[0];
    if (!goldChecked || !reward) {
        return null;
    }
    const onlyExperience = !(couponsValus && couponsValus.length) && !reward;
    const pointClassName = classNames({
        [`newcomer-gift-popup__point-normal`]: !onlyExperience,
        [`newcomer-gift-popup__point-bigger`]: onlyExperience,
    });
    const url =
        styleType === EventCouponStyleType.Custom
            ? 'https://b-puzhehei-cdn.co-mall.net/global/custom_point_bg.png'
            : !onlyExperience
            ? 'https://b-puzhehei-cdn.co-mall.net/global/small_point_bg.png'
            : 'https://b-puzhehei-cdn.co-mall.net/global/bigger_point_bg.png';
    const style = {
        backgroundImage: `url(${url})`,
    };
    const imageSrc = 'https://b-puzhehei-cdn.co-mall.net/global/event_point_icon.png';
    return (
        <div className={pointClassName} style={style}>
            <img alt="" src={imageSrc} />
            <span>
                {reward}
                {goldTxtName}
            </span>
        </div>
    );
};

const renderBottom = (preview: any, styleType: any) => {
    if (!preview.rewardConfig.reward || preview.rewardConfig.reward.length === 0) {
        return null;
    }
    const {
        couponsValue,
        couponsChecked,
        pointMax,
        pointChecked,
        experienceMax,
        experienceChecked,
        goldChecked,
        reward,
        goldTxtName = services.language.getText('jb'),
    } = preview.rewardConfig.reward[0];
    const textArr: string[] = [];
    if (couponsChecked && couponsValue && couponsValue.length) {
        textArr.push(services.language.getText('yhq'));
    }
    if (pointMax && pointChecked) {
        textArr.push(services.language.getText('point'));
    }
    if (experienceMax && experienceChecked) {
        textArr.push(services.language.getText('experienceManage'));
    }
    if (goldChecked && reward) {
        textArr.push(goldTxtName);
    }
    const text = textArr.join('、');
    const sbg =
        'https://b-puzhehei-cdn.co-mall.net/global/' + POPUP_BG_BOTTOM_IMG_CONFIG[styleType];
    const style =
        styleType === EventCouponStyleType.Custom
            ? undefined
            : {
                  backgroundImage: `url(${sbg})`,
              };
    const bg = 'https://b-puzhehei-cdn.co-mall.net/global/package_coupon_bottom_btn.png';
    const btnStyle = {
        backgroundImage: `url(${bg})`,
    };
    const innerClassNames = {
        wrap: `${styleType}-coupon-popup__bottom`,
        button: `${styleType}-coupon-popup__bottom__btn`,
        buttonText: `${styleType}-coupon-popup__bottom__text`,
    };
    return (
        <div className={innerClassNames.wrap} style={style}>
            {styleType === EventCouponStyleType.Package ? (
                <div className={innerClassNames.button} style={btnStyle}></div>
            ) : (
                <div className={innerClassNames.buttonText}>
                    {text}
                    {services.language.getText('kzhyzxck')}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const EventMarketPreview = connect(mapStateToProps)(eventMarketPreviewPreview);
