import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import './index.less';
import { services } from '@comall-backend-builder/core';

export class PicAndNameCodeMode extends ObjectMode {
    /**
     * 获取展示组件
     * 货品展示价格
     * pageType 类型 product 商品数据 good 货品数据
     */
    getDisplayComponent(value: any, displayInfo: any) {
        const { row } = displayInfo;
        return (
            <div className="pic-name-code-box">
                <div className="info-flex">
                    <img
                        className="pic"
                        alt={services.language.getText('productPic')}
                        src={row.pic}
                    ></img>
                    <div>
                        <div className="name">{row.name}</div>
                        <div className="barcode">{row.barcode}</div>
                        {!row.isProduct && (
                            <div className="price">
                                {services.language.getText('monetaryUnit')}
                                {row.goodsMvo?.salesPrice?.value}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
