import React, { Component } from 'react';
import { Input } from 'antd';
import { RadioGroup } from '@comall-backend-builder/components-basis';
import { TipsModal } from '../../../components';
import { modes } from '@comall-backend-builder/types';
import { TipsModalContent } from '../../../components/tips-modal/tips-modal-content';
import { services } from '@comall-backend-builder/core';
const { TextArea } = Input;

export class StringTextParagraphTip extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringRadioWithTipsModal {...displayInfo} />;
    }
}

class StringRadioWithTipsModal extends Component<any, any> {
    renderContent = (index: number) => {
        const { tipsModalConfig } = this.props;

        return (
            <div className="new-preview-content">
                <TipsModalContent contentType={tipsModalConfig.contentType[index]} />
            </div>
        );
    };
    onCarparkCheckoutTypeChange = (e: any) => {
        const type = e.target.value;
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(type, name);
        }
    };

    render() {
        const { value, tipsModalConfig, rows = 4, maxLength = 2000 } = this.props;
        if (tipsModalConfig.type === 'noExtra') {
            const radioValue = value || undefined;
            return (
                <div>
                    <TextArea
                        onChange={this.onCarparkCheckoutTypeChange}
                        value={radioValue}
                        className="audit-guide-user-form-input"
                        rows={4}
                        placeholder={services.language.getText('inputPlease')}
                    ></TextArea>
                </div>
            );
        }
        if (tipsModalConfig.type === 'extra') {
            const {
                extraConfig: { before, after },
                width = 1048,
            } = tipsModalConfig;
            const radioValue = value || undefined;
            return (
                <div>
                    <TextArea
                        style={{ width: 600 }}
                        onChange={this.onCarparkCheckoutTypeChange}
                        value={radioValue}
                        maxLength={maxLength}
                        className="audit-guide-user-form-input"
                        rows={rows}
                        placeholder={services.language.getText('inputPlease')}
                    ></TextArea>
                    {tipsModalConfig && (
                        <div
                            style={{
                                width: width,
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 5,
                                color: '#ccc',
                                fontSize: 13,
                            }}
                        >
                            {before && <span>{before}</span>}
                            <TipsModal {...tipsModalConfig} />
                            {after && <span>{after}</span>}
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '9px' }}>
                <RadioGroup {...(this.props as any)} type="text" />
                {tipsModalConfig && (
                    <div style={{ marginLeft: 5 }}>
                        <TipsModal {...tipsModalConfig} />
                    </div>
                )}
            </div>
        );
    }
}
