import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import get from 'lodash/get';
import { services } from '@comall-backend-builder/core';
import { TipsModalContentType } from '../../../components';

const { getText } = services.language;

export const config: Config = {
    entities: {
        CommonSettingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/common-setting',
            properties: {
                market: {
                    type: 'object.subForm',
                    displayName: '<<promotion>>',
                    properties: {
                        showEstimatePrice: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<spygjzs>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<kqhspygj>>',
                                    },
                                    contentType: TipsModalContentType.EstimatePrice,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                        },
                        couponStackedLimitCount: {
                            type: 'number.tip',
                            displayName: '<<yhqkdjsyzsxz>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    message: '<<h_yhqkdjsyzsxz_msg>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            value !== '' &&
                                            (!reg.test(value) || value > 99 || value === 0)
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                            },
                            extra: '<<h_yhqkdjsyzsxz_tip>>',
                        },
                    },
                },
                cart: {
                    type: 'object.subForm',
                    displayName: '<<gwc>>',
                    properties: {
                        checkoutInMultiSubsite: {
                            type: 'string.options.radio',
                            displayName: '<<gwcyzxxs>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<dmddzjzs_1>>' },
                                { id: 'false', name: '<<dmddzjzs>>' },
                            ],
                            extra: '<<h_gwcyzxxs_tip>>',
                        },
                        showMerchantTypeTag: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<gwcyzszjlxbq>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<h_gwcyzszjlxbq_tip_before>>',
                                    },
                                    contentType: TipsModalContentType.CommonSettingMerchantTypeTag,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                        },
                    },
                },
                userPositioning: {
                    type: 'object.subForm',
                    displayName: '<<yhdw>>',
                    properties: {
                        defaultLocationStore: {
                            type: 'array.userPositioning',
                            displayName: '<<mryhdw>>',
                            extra: '<<dmryhdw>>',
                        },
                    },
                },
                login: {
                    type: 'object.subForm',
                    displayName: '<<dlzc>>',
                    properties: {
                        mobileLogin: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<xcxdlxx>>',
                            defaultValue: ['1'],
                            options: [
                                {
                                    name: '<<wxdl>>',
                                    id: '1',
                                    disabled: true,
                                },
                                {
                                    name: '<<sjhdl>>',
                                    id: '2',
                                },
                            ] as any,
                            extra: '<<ktskqsjhdl>>',
                        },
                        loginTip: {
                            type: 'string.modalTips',
                            displayName: '<<dlhyy>>',
                            defaultValue: '<<h_dlhyy_default>>',

                            rules: [{ required: true, message: '<<dlhyybnwk>>' }],
                            controlConfig: {
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.LoginTip,
                                    showIcon: false,
                                    text: '<<example>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<h_dlhyy_placeholder>>',
                                maxLength: 10,
                            },
                        },
                        privacyAgreementStyle: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<dlyysxyys>>',
                            defaultValue: 'STYLE1',
                            options: [
                                {
                                    id: 'STYLE1',
                                    name: '<<h_style>> 1',
                                },
                                {
                                    id: 'STYLE2',
                                    name: '<<h_style>> 2',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'noExtra',
                                    contentType: [
                                        TipsModalContentType.CommonSettingStyle1,
                                        TipsModalContentType.CommonSettingStyle2,
                                    ],
                                },
                            },
                        },
                        headerContent: {
                            type: 'string.text.paragraph',
                            displayName: '<<nrtb>>',
                            controlConfig: {
                                rows: 4,
                            },
                        },
                        agreements: {
                            type: 'object.commonSetting.option',
                            displayName: '<<xyxx>>',
                        },
                        logoutMode: {
                            type: 'string.options.radio',
                            displayName: '<<zxfs_2>>',
                            defaultValue: 'NOT_SHOW',
                            options: [
                                { id: 'NOT_SHOW', name: '<<bxszx>>' },
                                { id: 'ONLINE', name: '<<xszx>>' },
                                { id: 'BELOW', name: '<<xxzx>>' },
                            ],
                        },
                        logoutModeTip: {
                            type: 'string.richTextPlus',
                            displayName: '<<zxts>>',
                            rules: [
                                { required: true },
                                { whitespace: true, max: 20000, message: '<<notMoreThan2000>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<zxtsRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                        logoutAgreement: {
                            type: 'object.logout.agreement',
                            displayName: '<<zxxy>>',
                        },
                    },
                },
                productDetails: {
                    type: 'object.subForm',
                    displayName: '<<spxqy>>',
                    properties: {
                        showAppraise: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<spxqyzspjrk>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<h_spxqyzspjrk_tip_before>>',
                                    },
                                    contentType: TipsModalContentType.CommonSettingProductDetails,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                        },
                    },
                },
                order: {
                    type: 'object.subForm',
                    displayName: '<<h_order_invoice>>',
                    properties: {
                        orderMergeTabSequences: {
                            type: 'array.orderMergeTabSequences.tip',
                            displayName: '<<ddjhyyqsx>>',
                            controlConfig: {
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.OrderMergeTabSequences,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                            },
                        },
                        invoiceOrderMergTabSequences: {
                            type: 'array.openInvoiceTabSequences.tip',
                            displayName: '<<kjfpyyqsx>>',
                            controlConfig: {
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.OpenInvoiceTabSequences,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                            },
                        },
                    },
                },
                assets: {
                    type: 'object.subForm',
                    displayName: '<<hyzc>>',
                    properties: {
                        memberCardExperienceAlias: {
                            type: 'string.modalTips',
                            displayName: '<<jyzzdymc>>',
                            controlConfig: {
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.ExperienceExamplePicture,
                                    showIcon: false,
                                    text: '<<example>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<h_jyzzdymc_placeholder>>',
                                maxLength: 4,
                            },
                            extra: '<<h_jyzzdymc_tip>>',
                        },
                    },
                },
                parking: {
                    type: 'object.subForm',
                    displayName: '<<tcjf_1>>',
                    properties: {
                        allowCarNumberMultiUserBind: {
                            type: 'string.options.radio',
                            displayName: '<<tycpbdhybd>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<allow>>' },
                                { id: 'false', name: '<<notAllow>>' },
                            ],
                        },
                    },
                },
                /*  credit: {
                    type: 'object.subForm',
                    displayname: '<<creditEShop>>',
                    properties: {
                        bgPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '背景图',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '片尺寸建议为宽750px*高320px',
                                    img: './images/avatar/credit-bg.jpg',
                                    btnText: '示例',
                                    triggerType: 'hover',
                                    style: {
                                        width: 500,
                                    },
                                },
                            },
                        },
                    },
                }, */
                noteComment: {
                    type: 'object.subForm',
                    displayName: '<<bjplsz>>',
                    properties: {
                        showNoteComment: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<bjplrk>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hidden>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<h_bjplrk_tip_before>>',
                                    },
                                    contentType: TipsModalContentType.NoteComment,
                                    showIcon: false,
                                    text: '<<example>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 850,
                                },
                            },
                        },
                    },
                },
                codeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jhmty>>logo',
                    properties: {
                        miniProgramCodeSystemPicture: {
                            type: 'imageObjectArray',
                            displayName: '<<tppz>>',
                            accept: 'image/jpg, image/png',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/109/upload`,
                                mode: 'picture-card',
                                maxSize: 1 * 1024,
                                preview: {
                                    size: 500,
                                },
                                uploadLimitSizeErrorMessage:
                                    '<<pictures>>{{file.name}}<<pictureUploadLimit1>>',
                                limitTips: {
                                    limitUnit: '<<h_jhmty_limit>>',
                                },
                            },
                            extra: '<<h_jhmty_tip>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        CommonSettingView: {
            component: 'Viewport',
        },
        CommonSettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'CommonSettingForm' }],
        },
        CommonSettingForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'CommonSettingEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: {
                height: '480px',
            },
            fields: [
                {
                    property: 'market.showEstimatePrice',
                },
                {
                    property: 'market.couponStackedLimitCount',
                },
                {
                    property: 'cart.checkoutInMultiSubsite',
                },
                {
                    property: 'cart.showMerchantTypeTag',
                },
                {
                    property: 'userPositioning.defaultLocationStore',
                },
                {
                    property: 'login.mobileLogin',
                },
                {
                    property: 'login.loginTip',
                },
                {
                    property: 'login.privacyAgreementStyle',
                },
                {
                    property: 'login.headerContent',
                    visible: (values: any) =>
                        get(values, 'login.privacyAgreementStyle') === 'STYLE2',
                },
                {
                    property: 'login.agreements',
                    visible: (values: any) =>
                        get(values, 'login.privacyAgreementStyle') === 'STYLE2',
                },
                {
                    property: 'login.logoutMode',
                },
                {
                    property: 'login.logoutModeTip',
                    visible: (values: any) => get(values, 'login.logoutMode') !== 'NOT_SHOW',
                },
                {
                    property: 'login.logoutAgreement',
                    visible: (values: any) => get(values, 'login.logoutMode') === 'ONLINE',
                },
                {
                    property: 'productDetails.showAppraise',
                },
                {
                    property: 'order.orderMergeTabSequences',
                },
                {
                    property: 'order.invoiceOrderMergTabSequences',
                },
                {
                    property: 'assets.memberCardExperienceAlias',
                },
                {
                    property: 'parking.allowCarNumberMultiUserBind',
                },
                // {
                //     property: 'credit.bgPicture',
                // },
                {
                    property: 'noteComment.showNoteComment',
                },
                // {
                //     property: 'assets.balanceCouponQrCode',
                // },
                // {
                //     property: 'assets.balanceCouponQrCodeName',
                // },
                // {
                //     property: 'assets.balanceCouponShowMode',
                // },
                {
                    property: 'codeInfo.miniProgramCodeSystemPicture',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onValidate: (entity: any) => {
                const balanceCouponQrCode = get(entity, 'assets.balanceCouponQrCode', []);
                if (balanceCouponQrCode.length) {
                    const balanceCouponQrCodeName = get(
                        entity,
                        'assets.balanceCouponQrCodeName',
                        []
                    );
                    if (!balanceCouponQrCodeName) {
                        message.error(getText('qsryeqxxzfewmmc'));
                        return false;
                    }
                }
                const agreements = get(entity, 'login.agreements');
                if (agreements && agreements.length) {
                    let hasValidate = true;
                    agreements.forEach((reward: any) => {
                        if (!reward.agreementPrefix && !reward.agreementSuffix && !reward.name) {
                            hasValidate = false;
                        }
                    });
                    if (!hasValidate) {
                        message.error(getText('xyxxsjbwz'));
                        return false;
                    }
                }

                const defaultLocationStore = get(entity, 'userPositioning.defaultLocationStore');
                if (defaultLocationStore && defaultLocationStore.status) {
                    if (!defaultLocationStore.defaultStore) {
                        message.error(getText('qxzmrmd'));
                        return false;
                    }
                    if (!defaultLocationStore.locationToStoreRadius) {
                        message.error(getText('qtxdwjzjmd'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/common-setting',
                    component: 'CommonSettingView',
                    breadcrumbName: '<<commonSetting>>',
                    privilege: {
                        path: 'commonSetting',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CommonSettingPage' }],
                },
            ],
        },
    ],
};
