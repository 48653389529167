import React, { Component } from 'react';
import { Radio, Select } from 'antd';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, find, forEach, isEmpty, isEqual, map } from 'lodash';
import AffiliateMarketingMemberLevelTable from './affiliate-marketing-member-level-table';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { Option } = Select;
const { api } = services;

export interface MemberLevelInfos {
    cardLevelId: number;
    cardLevelName: string;
}
export interface MemberLevelRewardInfos {
    cardLevelId: number;
    cardLevelName: string;
    rewardValue: string;
    experienceValue: string;
}
interface MemberCardLevelPointRewardValue {
    unifiedLevel: boolean;
    levelInfos: MemberLevelInfos[];
    unifiedReward: boolean;
    rewardInfos: MemberLevelRewardInfos[];
    editSubsiteId?: Array<string>;
}
interface AffiliateMarketingMemberLevelProps {
    onChange: (value: any) => void;
    value: MemberCardLevelPointRewardValue | undefined;
    row: { baseInfo: { subsiteId: string } };
    subsiteField: string;
    enableExperienceValue?: boolean;
    rewardValueTitle?: string;
}
interface AffiliateMarketingMemberLevelStates {
    loading: boolean;
    memberLevelsData?: Array<{
        cardLevelId: number;
        name: string;
    }>;
}

const defaultRewardInfosValue = {
    cardLevelId: -1,
    cardLevelName: 'qbcyyhdj',
    rewardValue: '',
    experienceValue: '',
};

const defaultValue = {
    unifiedLevel: true,
    levelInfos: [],
    unifiedReward: true,
    rewardInfos: [defaultRewardInfosValue],
};
function getDefaultValue() {
    defaultValue.rewardInfos = defaultValue.rewardInfos.map((i) => ({
        ...i,
        cardLevelName: services.language.getText('qbcyyhdj'),
    }));
    return defaultValue;
}

export default class AffiliateMarketingMemberLevel extends Component<
    AffiliateMarketingMemberLevelProps,
    AffiliateMarketingMemberLevelStates
> {
    constructor(props: AffiliateMarketingMemberLevelProps) {
        super(props);
        this.state = { loading: false };
    }

    componentDidMount() {
        this.loadInfo();
    }

    loadInfo = async () => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange(getDefaultValue());
        } else {
            const subsiteId = this.getSubsiteIds(this.props);
            //编辑复制查看页面 - 判断用户有没有更改过门店，如果不同则清空，相同则和编辑查看页一样回显
            if (
                value.editSubsiteId &&
                value.editSubsiteId.length > 0 &&
                !isEqual(value.editSubsiteId, subsiteId)
            ) {
                onChange(getDefaultValue());
                return;
            }
            if (value && subsiteId) {
                if (value.unifiedLevel && value.unifiedReward) {
                    return;
                }
                let params = {};
                let config = {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                    apiPath: '/admin/card_levels?status=ENABLE',
                };
                if (subsiteId.length === 1) {
                    params = {
                        subsiteIds: subsiteId.join(','),
                        page: 1,
                        perPage: 100,
                        status: 'ENABLE',
                    };
                    config = {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                        apiPath: '/admin/store_card_levels/legal',
                    };
                }
                const res: any = await api.get(params, config);
                if (res) {
                    let memberLevelsData: any[] = [];
                    if (subsiteId.length === 1) {
                        memberLevelsData = res.result;
                    } else {
                        const result: Array<any> = [];
                        forEach(res, (level: any) => {
                            result.push({
                                cardLevelId: level.id,
                                name: level.name,
                            });
                        });
                        memberLevelsData = result;
                    }
                    this.setState(
                        {
                            memberLevelsData: memberLevelsData,
                            loading: false,
                        },
                        () => {
                            if (!value.unifiedReward) {
                                const levelInfos: Array<MemberLevelRewardInfos> =
                                    value.rewardInfos || [];
                                if (memberLevelsData.length > 0) {
                                    //后端没返回等级name，前端遍历赋值normal_conditions
                                    forEach(levelInfos, (levelInfo) => {
                                        let row: any = find(memberLevelsData, {
                                            cardLevelId: levelInfo.cardLevelId,
                                        });
                                        levelInfo.cardLevelName = row.name;
                                    });
                                    //如果门店的会员等级是新增，编辑查看页面要展示最新,让用户将新增的填写完整
                                    if (value.unifiedLevel) {
                                        forEach(memberLevelsData, (level) => {
                                            let row: any = find(levelInfos, {
                                                cardLevelId: level.cardLevelId,
                                            });
                                            if (!row) {
                                                levelInfos.push({
                                                    cardLevelId: level.cardLevelId,
                                                    cardLevelName: level.name,
                                                    rewardValue: '',
                                                    experienceValue: '',
                                                });
                                            }
                                        });
                                    }
                                    value.rewardInfos = levelInfos;
                                    onChange(cloneDeep(value));
                                }
                            }
                        }
                    );
                }
            }
        }
    };

    componentWillReceiveProps(nextProps: AffiliateMarketingMemberLevelProps) {
        const { value, onChange } = nextProps;
        if (!value) {
            return;
        }
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (!isEqual(nextSubsiteIds, currentSubsiteIds) && !isEmpty(nextProps.value)) {
            onChange(getDefaultValue());
        }
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row && row.baseInfo && row.baseInfo.subsiteIds
                ? map(row.baseInfo.subsiteIds, 'id')
                : null;
        return subsiteIds;
    };

    updataMemberCardLevels = async (
        props: AffiliateMarketingMemberLevelProps,
        value?: MemberCardLevelPointRewardValue
    ) => {
        const { onChange } = props;
        const subsiteId = this.getSubsiteIds(props);
        const curDefaultRewardInfosValue = {
            ...defaultRewardInfosValue,
            cardLevelName: language.getText(defaultRewardInfosValue.cardLevelName),
        };
        if (!subsiteId || subsiteId.length === 0) {
            if (value) {
                value.levelInfos = [];
                if (!value.unifiedReward) {
                    value.rewardInfos = [];
                } else {
                    value.rewardInfos = [curDefaultRewardInfosValue];
                }
                onChange(value);
            }
            return;
        }
        this.setState({ loading: true });
        let params = {};
        let config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/card_levels?status=ENABLE',
        };
        if (subsiteId.length === 1) {
            params = {
                subsiteIds: subsiteId.join(','),
                page: 1,
                perPage: 100,
                status: 'ENABLE',
            };
            config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/store_card_levels/legal',
            };
        }
        const res: any = await api.get(params, config);
        if (res) {
            let memberLevelsData: any[] = [];
            if (subsiteId.length === 1) {
                memberLevelsData = res.result;
            } else {
                const result: Array<any> = [];
                forEach(res, (level: any) => {
                    result.push({
                        cardLevelId: level.id,
                        name: level.name,
                    });
                });
                memberLevelsData = result;
            }
            this.setState(
                {
                    memberLevelsData: memberLevelsData,
                    loading: false,
                },
                () => {
                    if (value) {
                        if (value.unifiedLevel && !value.unifiedReward) {
                            const rewardInfos: Array<MemberLevelRewardInfos> = [];
                            forEach(memberLevelsData, (levelInfo) => {
                                rewardInfos.push({
                                    cardLevelId: levelInfo.cardLevelId,
                                    cardLevelName: levelInfo.name,
                                    rewardValue: '',
                                    experienceValue: '',
                                });
                            });
                            value.rewardInfos = rewardInfos;
                        }
                        onChange && onChange(cloneDeep(value));
                    }
                }
            );
        }
    };

    choose = (e: any) => {
        const { memberLevelsData } = this.state;
        const { value, onChange } = this.props;
        const levelInfos: Array<MemberLevelInfos> = [];
        const rewardInfos: Array<MemberLevelRewardInfos> = [];
        forEach(e, (rewardInfoId) => {
            let item = value?.levelInfos
                ? find(value.levelInfos, { cardLevelId: parseInt(rewardInfoId) })
                : undefined;
            let levelInfo = memberLevelsData
                ? find(memberLevelsData, { cardLevelId: parseInt(rewardInfoId) })
                : undefined;
            let cardLevelId = 0;
            let cardLevelName = '';
            if (item) {
                cardLevelId = item.cardLevelId;
                cardLevelName = item.cardLevelName;
            } else if (levelInfo) {
                cardLevelId = levelInfo.cardLevelId;
                cardLevelName = levelInfo.name;
            }
            levelInfos.push({
                cardLevelId: cardLevelId,
                cardLevelName: cardLevelName,
            });
            rewardInfos.push({
                cardLevelId: cardLevelId,
                cardLevelName: cardLevelName,
                rewardValue: '',
                experienceValue: '',
            });
        });

        if (value) {
            let newValue = cloneDeep(value);
            newValue.levelInfos = levelInfos;
            const curDefaultRewardInfosValue = {
                ...defaultRewardInfosValue,
                cardLevelName: language.getText(defaultRewardInfosValue.cardLevelName),
            };
            if (!value.unifiedReward) {
                newValue.rewardInfos = rewardInfos;
            } else {
                newValue.rewardInfos = [curDefaultRewardInfosValue];
            }
            onChange && onChange(cloneDeep(newValue));
        }
    };

    changeUnifiedLevel = (e: any) => {
        const { value, onChange } = this.props;
        if (!value) return;
        const unifiedLevel: boolean = e.target.value;
        const curDefaultRewardInfosValue = {
            ...defaultRewardInfosValue,
            cardLevelName: language.getText(defaultRewardInfosValue.cardLevelName),
        };
        const newValue = {
            unifiedLevel,
            levelInfos: [],
            unifiedReward: true,
            rewardInfos: [curDefaultRewardInfosValue],
        };
        const subsiteId = this.getSubsiteIds(this.props);
        if (value && !unifiedLevel && subsiteId) {
            this.updataMemberCardLevels(this.props, newValue);
        } else {
            onChange && onChange(cloneDeep(newValue));
        }
    };

    changeUnifiedReward = (e: any) => {
        const { value, onChange } = this.props;
        if (!value) return;
        const unifiedReward: boolean = e.target.value;
        const curDefaultRewardInfosValue = {
            ...defaultRewardInfosValue,
            cardLevelName: language.getText(defaultRewardInfosValue.cardLevelName),
        };
        let newValue = {
            unifiedLevel: value.unifiedLevel,
            levelInfos: value.levelInfos,
            unifiedReward,
            rewardInfos: unifiedReward ? [curDefaultRewardInfosValue] : [],
        };
        const subsiteId = this.getSubsiteIds(this.props);
        if (value && !unifiedReward && subsiteId) {
            if (value.levelInfos.length > 0) {
                const rewardInfos: Array<MemberLevelRewardInfos> = [];
                forEach(value.levelInfos, (level) => {
                    rewardInfos.push({
                        cardLevelId: level.cardLevelId,
                        cardLevelName: level.cardLevelName,
                        rewardValue: '',
                        experienceValue: '',
                    });
                });
                newValue.rewardInfos = rewardInfos;
                onChange && onChange(cloneDeep(newValue));
            } else {
                this.updataMemberCardLevels(this.props, newValue);
            }
        } else {
            onChange && onChange(cloneDeep(newValue));
        }
    };

    changeRewardInfos = (rewardInfos: MemberLevelRewardInfos[]) => {
        const { value, onChange } = this.props;
        if (!value) return;
        value.rewardInfos = rewardInfos;
        onChange && onChange(cloneDeep(value));
    };

    renderMemberLevel = () => {
        const { memberLevelsData } = this.state;
        const { value } = this.props;
        const options: any =
            memberLevelsData &&
            memberLevelsData.map((rewardInfo) => {
                return <Option key={rewardInfo.cardLevelId}>{rewardInfo.name}</Option>;
            });
        const selectvalue: any =
            value &&
            value.levelInfos &&
            value.levelInfos.map((rewardInfo: MemberLevelInfos) => {
                return rewardInfo.cardLevelId + '';
            });
        const subsiteId = this.getSubsiteIds(this.props);
        const selectedSubsiteMore = subsiteId && subsiteId.length > 0 ? false : true;
        return (
            <Select
                disabled={selectedSubsiteMore}
                value={selectvalue}
                mode="multiple"
                allowClear
                style={{ width: '400px' }}
                placeholder={services.language.getText('selectPlease')}
                defaultValue={['a10', 'c12']}
                onChange={this.choose}
            >
                {options}
            </Select>
        );
    };

    render() {
        const { value = getDefaultValue(), enableExperienceValue, rewardValueTitle } = this.props;
        const { unifiedLevel, unifiedReward, rewardInfos } = value;
        const { loading } = this.state;
        const subsiteId = this.getSubsiteIds(this.props);
        const selectedSubsiteMore = subsiteId && subsiteId.length > 0 ? false : true;
        return (
            <div className="member-card-level-point-reward">
                <div>
                    <Radio.Group onChange={this.changeUnifiedLevel} value={unifiedLevel}>
                        <Radio value={true}>{services.language.getText('qbdj')}</Radio>
                        <Radio value={false} disabled={selectedSubsiteMore}>
                            {services.language.getText('zddj')}
                        </Radio>
                    </Radio.Group>
                </div>
                {!unifiedLevel && (
                    <div className="item">
                        <div>
                            <span style={{ color: 'red', marginRight: '5px', marginLeft: '-11px' }}>
                                *
                            </span>
                            {services.language.getText('hydj')}：
                        </div>
                        {this.renderMemberLevel()}
                    </div>
                )}
                <div className="item">
                    <div>{services.language.getText('cjcs')}：</div>
                    <div>
                        <Radio.Group onChange={this.changeUnifiedReward} value={unifiedReward}>
                            <Radio value={true}>{services.language.getText('sydjtygz')}</Radio>
                            <Radio value={false} disabled={selectedSubsiteMore}>
                                {services.language.getText('btdjbtgz')}
                            </Radio>
                        </Radio.Group>
                        <div>
                            <AffiliateMarketingMemberLevelTable
                                loading={loading}
                                data={rewardInfos}
                                enableExperienceValue={enableExperienceValue}
                                rewardValueTitle={rewardValueTitle}
                                onChange={this.changeRewardInfos}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
