import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
interface Options {
    id: string;
    name: string;
}
export const UserRoleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/users/:id/roles';
        const result: Options[] = await api.get(data, config);
        return {
            roleIds: result.map((item: Options) => item.id),
        };
    },
};
