import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { CouponSaleCategorySelector } from '../../components';
import { CouponSaleCategorySelectorDisplay } from '../../components';

import { CouponCategory } from '../../components/coupon-sale-category-selector';

export class SelectCouponSaleCategory extends PureComponent<
    any,
    {
        value: Array<CouponCategory>;
    }
> {
    state = { value: new Array<CouponCategory>() };

    onChange = (selectValues: CouponCategory[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                const newValue = selectValues.map((item) => ({
                    ...item,
                }));
                onChange(newValue);
            }
        }
    };

    /**
     * 添加优惠券弹窗确认后回调
     * @param selectValues 选中优惠券
     */
    onSelect = (selectValues: CouponCategory[]) => {
        const { value } = this.props;
        let newData: CouponCategory[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            newData = selectValues;
        }
        this.onChange(newData);
    };
    getParams = () => {
        const { getParams } = this.props;

        if (typeof getParams === 'function') {
            return getParams(this.props);
        }

        return {};
    };
    render() {
        const { value, disabled, style } = this.props;
        const hasValue = value && value.length > 0;
        const params = this.getParams();

        return (
            <div style={style}>
                {!disabled && (
                    <CouponSaleCategorySelector
                        onChange={this.onSelect}
                        selectValues={value}
                        type="checkbox"
                        params={params}
                    />
                )}
                {hasValue && (
                    <CouponSaleCategorySelectorDisplay
                        data={value}
                        onRemove={this.onChange}
                        disabled={disabled}
                    ></CouponSaleCategorySelectorDisplay>
                )}
            </div>
        );
    }
}
