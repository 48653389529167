import React, { PureComponent } from 'react';

import { Button, Modal, Form, Radio, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import find from 'lodash/find';
import get from 'lodash/get';

import { CustomSelectSubsite } from '../custom-select-subsite-select';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export interface CustomSelectCouponProductValue {
    linkType: string;
    linkParams?: any;
}

const language = services.language;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const options = [
    {
        label: '现金券',
        value: 0,
    },
    {
        label: '礼品券',
        value: 1,
    },
    {
        label: '抽奖券',
        value: 2,
    },
];

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: {
        id: 0,
        current: 0,
        name: '',
    },
};

export interface CustomSelectCouponProps {
    value: any;
    contentConfig: Record<string, any>;
    modalConfig: Record<string, any>;
    onChange(value: any): void;
}

class CustomSelectCoupon extends PureComponent<
    CustomSelectCouponProps,
    { visible: boolean; linkParams: any; current: number }
> {
    constructor(props: CustomSelectCouponProps) {
        super(props);
        const linkParams = get(props.value, 'linkParams') || DEFAULT_VALUE.linkParams;
        const current = get(props.value, 'linkParams.current') || 0;

        this.state = {
            visible: false,
            linkParams: linkParams,
            current: current,
        };
    }

    visibleModalChange = (visible: boolean): void => {
        this.setState({
            visible,
        });
    };

    handleChange = (params: { id: number; name: string }): void => {
        const { linkParams } = this.state;

        this.setState({
            linkParams: { ...linkParams, ...params },
        });
    };

    onOk = () => {
        const { linkParams, current } = this.state;
        const { onChange } = this.props;

        if (!linkParams.id) return message.error('请选择门店');
        onChange && onChange({ ...linkParams, current });

        this.handleCancel();
    };

    handleShowModal = (): void => {
        this.visibleModalChange(true);
    };

    handleCancel = (): void => {
        this.visibleModalChange(false);
    };

    radioChange = (current: number): void => {
        this.setState({
            current,
        });
    };

    render() {
        const { value } = this.props;
        const { visible, linkParams, current } = this.state;

        const linkName = get(value, 'linkParams.name');
        const currentName =
            find(options, (item) => item.value === get(value, 'linkParams.current'))?.label || '';

        return (
            <span className="link-name">
                {linkName ? (
                    <span className="link-name-label">
                        {linkName}&nbsp;{currentName}
                    </span>
                ) : (
                    '未选择'
                )}

                <Button type="link" className="link-type-edit" onClick={this.handleShowModal}>
                    {linkName
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <Modal
                    title="请选择"
                    width="800px"
                    visible={visible}
                    onOk={this.onOk}
                    onCancel={this.handleCancel}
                >
                    <Form {...FormItemLayout}>
                        <FormItem label={'选择门店'} required>
                            <CustomSelectSubsite
                                value={{ ...linkParams }}
                                onChange={this.handleChange}
                            />
                        </FormItem>
                        <FormItem label={'选择页签'} required>
                            <RadioGroup
                                options={options}
                                value={current}
                                onChange={(e) => this.radioChange(e.target.value)}
                            />
                        </FormItem>
                    </Form>
                </Modal>
            </span>
        );
    }
}

export { CustomSelectCoupon };
