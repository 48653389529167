import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const StoredValueCardSettingSubsitePickUpDeliveryLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/stored_value_card_pickups';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/stored_value_card_pickups/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: 'ALL',
                    subsite: res.subsite.id + '',
                    address: res.address,
                    time: res.time,
                    reminder: res.reminder,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    item.subsiteName = item.subsite.name;
                    item.time = item.time ? item.time : '';
                    item.reminder = item.reminder ? item.reminder : '';
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/stored_value_card_pickups';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = `/admin/stored_value_card_pickups/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
    delete: function(data: any, config: ApiRequestConfig) {
        const id = data.id;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = `/admin/stored_value_card_pickups/${id}`;
        return api.delete({}, config);
    },
};
/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.address = params.baseInfo.address;
        newParams.time = params.baseInfo.time;
        newParams.reminder = params.baseInfo.reminder;
        newParams.subsiteId = params.baseInfo.subsite;
    }
    return newParams;
}
