import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ForwardDataCenterModal } from '../../services';

import 'antd/dist/antd.css';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    resourceId: number;
    resourceName: string;
    resourceUrl: string;
    type: string;
}

/**
 * 导入excal按钮
 */
export class MerchantCategoryCodeTaxRatesImportButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = (resource: any) => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
        } = this.props;
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
            apiPath: `/admin/merchant_category_code_tax_rates/import`,
        };
        const params = {
            resourceId: resource.resourceId,
            merchantId: id,
        };

        api.put(params, config)
            .then(
                (res) => {
                    ForwardDataCenterModal();
                    this.toggleModal();
                },
                (err) => {
                    if (err.response.body.err_msg) {
                        message.error(err.response.body.err_msg);
                    } else {
                        this.setState({
                            statusMsg: services.language.getText('importFailPrivity'),
                        });
                    }
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.resourceId) {
            this.onImport(resource);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    getUploadProps = () => {
        const { fileList } = this.state;

        return {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            resourceId: 0,
                            resourceName: '',
                            resourceUrl: '',
                            type: 'IMPORT',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                api.upload(
                    { files: file },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                        apiPath: '/admin/files/locations/62/upload',
                        fileName: 'files',
                        progressCallBack: () => {},
                    }
                )
                    .then((res) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
    };

    renderExportButton = () => {
        const props = {
            type: 'link',
            text: services.language.getText('clickDownloadTemplate'),
            showErrorMessage: true,
            source: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: '/admin/merchant_category_code_tax_rates/template.xlsx',
            },
        } as any;
        return <ExportButton {...props} />;
    };

    render() {
        const { showModal, statusMsg } = this.state;
        const { row } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const uploadProps = this.getUploadProps();
        return (
            <div className="import-button-data-statistics">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {services.language.getText('batchImport')}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={services.language.getText('batchImport')}
                    footer={[
                        <span className="import-button-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            导入
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div>
                        {this.renderExportButton()}
                        <div className="import-button-tips">
                            <p className="import-button-tips-text">
                                {services.language.getText('importRules')}
                            </p>
                            <p className="import-button-tips-text">
                                {services.language.getText('merchantBigCodeIntro1')}
                            </p>
                            <p className="import-button-tips-text-red">
                                {services.language.getText('merchantBigCodeNote')}
                            </p>
                            <p className="import-button-tips-text">
                                2.当前仅支持xlxs、xls、csv格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office
                                2010及以上版本，否则可能出现乱码
                            </p>
                            <p className="import-button-tips-text">
                                3.请严格参考模版内的信息进行填写
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
