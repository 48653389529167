import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { forEach } from 'lodash';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export class LimitSessionCount extends Component<any> {
    onActivityCountChange(val: any) {
        let { name, value, onChange } = this.props;

        if (!value) {
            value = {};
        }
        value.activityCount = val;

        if (onChange) {
            onChange(value, name);
        }
    }

    onDayCountChange(val: any) {
        let { name, value, onChange } = this.props;

        if (!value) {
            value = {};
        }
        value.dayCount = val;

        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, rules, disabled } = this.props;
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="point-deduction">
                <div className="point-deduction-content">
                    <span className="item-text">{language.getText('mrmtkcy_1')} </span>
                    <div className="number-input">
                        <InputNumber
                            disabled={disabled}
                            min={0}
                            max={999999999}
                            precision={0}
                            value={value && value.dayCount}
                            onChange={this.onDayCountChange.bind(this)}
                            {...ruleInfo}
                        />
                        {'  '}
                        <span>{language.getText('c')}，</span>
                    </div>

                    <span>{language.getText('hdqjkcy')} </span>
                    <div className="number-input">
                        <InputNumber
                            disabled={disabled}
                            min={0}
                            max={999999999}
                            precision={0}
                            value={value && value.activityCount}
                            onChange={this.onActivityCountChange.bind(this)}
                            {...ruleInfo}
                        />{' '}
                        <span>{language.getText('c')}</span>
                    </div>
                </div>
            </div>
        );
    }
}
