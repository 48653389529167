import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, forEach } from 'lodash';

const api = services.api;
export const CollectionPointsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/collection_points';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/collection_points/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                let selectMode = '0';
                if (res.merchants && res.merchants.length > 0) {
                    selectMode = res.merchants[0].include === true ? '1' : '2';
                }
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsites: res.subsites,
                    selectMode: selectMode,
                    merchants:
                        selectMode !== '0'
                            ? res.merchants.map((m: any) => {
                                  return {
                                      ...m,
                                      id: m.subsiteId + ',' + m.id,
                                      merchantId: m.id,
                                      merchantName: m.name,
                                      merchantType: m.type,
                                      merchantStatus: m.status,
                                      subSiteName: m.subsiteName,
                                  };
                              })
                            : [],
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.obtainRuleInfo = {
                    orderScope: res.collectionRule.orderScope.split(','),
                    limitEveryDay: res.collectionRule.limitEveryDay,
                    timesRule: {
                        id: res.timesRule.id,
                        type: res.timesRule.type,
                        consumptionCount:
                            res.timesRule.type === 'CONSUMPTION'
                                ? res.timesRule.conditionValue
                                : undefined,
                        consumptionPerFullCount:
                            res.timesRule.type === 'CONSUMPTION_PER_FULL'
                                ? res.timesRule.conditionValue
                                : undefined,
                    },
                };

                const reward: Array<any> = [];
                const consumeValues: Array<string> = [];
                forEach(res.rewards, (reward) => {
                    if (!consumeValues.includes(reward.consumeValue)) {
                        consumeValues.push(reward.consumeValue);
                    }
                });
                forEach(consumeValues, (consumeValue) => {
                    const rewardList = res.rewards.filter((item: any) => {
                        return item.consumeValue === consumeValue;
                    });
                    reward.push({
                        consumeValue: consumeValue,
                        coupons: rewardList.map((r: any) => {
                            return {
                                id: r.coupon.id,
                                couponName: r.coupon.name,
                                couponType: r.coupon.couponType,
                                startDate: r.coupon.startDate,
                                endDate: r.coupon.endDate,
                                couponTypeDes: r.coupon.couponTypeDes,
                                couponValue: r.coupon.couponValue,
                                quantity: r.quantity,
                                maxStock: r.maxStock,
                                stock: r.stock,
                                pictureUrl: r.coupon.pictureUrl,
                                leftStock: r.coupon.leftStock,
                                couponDefinitionId: r.coupon.id,
                                subsites: r.coupon.subsites,
                                rewardId: r.id,
                            };
                        }),
                    });
                });
                res.dhgzRuleInfo = {
                    reward: reward,
                };
                const pageConfigJson = JSON.parse(res.pageConfig);
                res.pageRuleInfo = {
                    backgroundPicture: [pageConfigJson.backgroundPicture],
                    collectedPointsPicture: pageConfigJson.collectedPointsPicture,
                    description: res.description,
                };
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/collection_points`;
        const newParams = tranformData(params);
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/collection_points/${params.id}`;
        }
        const newParams = tranformData(params);

        return await api.put(newParams, config);
    },
};

function tranformData(params: any) {
    const isEdit = window.location.href.includes('/edit');
    let newParams: any = {};
    newParams.type = 'COLLECTION_POINT';
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
        const subsiteIds = params.baseInfo.subsites.map((subsite: any) => {
            return subsite.id;
        });
        newParams.subsiteIds = subsiteIds;
        const selectMode = params.baseInfo.selectMode;
        if (selectMode === '1' || selectMode === '2') {
            const merchants: Array<any> = [];
            forEach(params.baseInfo.merchants, (merchant) => {
                merchants.push({
                    id: merchant.merchantId,
                    include: selectMode === '1' ? true : false,
                });
            });
            newParams.merchants = merchants;
        }
        newParams.marketingScheduleId =
            (params.baseInfo.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
    }
    if (params.obtainRuleInfo) {
        newParams.collectionRule = {
            orderScope: params.obtainRuleInfo.orderScope.join(','),
            limitEveryDay: params.obtainRuleInfo.limitEveryDay,
        };
        newParams.timesRule = {
            id: isEdit ? params.obtainRuleInfo.timesRule.id : undefined,
            conditionValue:
                params.obtainRuleInfo.timesRule.type === 'CONSUMPTION'
                    ? params.obtainRuleInfo.timesRule.consumptionCount
                    : params.obtainRuleInfo.timesRule.consumptionPerFullCount,
            rewardValue: 1,
            type: params.obtainRuleInfo.timesRule.type,
        };
    }
    const rewards: Array<any> = [];
    forEach(params.dhgzRuleInfo.reward, (r) => {
        forEach(r.coupons, (coupon) => {
            rewards.push({
                id: isEdit ? coupon.rewardId : undefined,
                consumeValue: r.consumeValue,
                definitionId: coupon.couponDefinitionId,
                quantity: coupon.quantity || 1,
                maxStock: coupon.maxStock,
                type: 'COUPON',
            });
        });
    });
    newParams.rewards = rewards;
    const pageConfig: any = {
        backgroundPicture: {
            id: params.pageRuleInfo.backgroundPicture[0].id,
            path: params.pageRuleInfo.backgroundPicture[0].path,
        },
        collectedPointsPicture: params.pageRuleInfo.collectedPointsPicture,
    };
    newParams.pageConfig = JSON.stringify(pageConfig);
    newParams.description = params.pageRuleInfo.description;

    return newParams;
}

export const CollectionPointsMemberPointLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/collection_points/member_points';
        if (data.id) {
            data.activityId = data.id;
            delete data.id;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    return {
                        ...item,
                        orderNo: `${item.orderNo}（订单金额：${item.orderAmount}）`,
                        refundInfos:
                            item.refundInfos &&
                            item.refundInfos.map((info: any) => {
                                return {
                                    name: `${info.refundNo}（售后金额：${info.orderAmount}）`,
                                };
                            }),
                    };
                });
            return res;
        });
    },
};

export const CollectionPointsRewardRecordsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/collection_points/reward_records';
        if (data.id) {
            data.activityId = data.id;
            delete data.id;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
};
