import React, { PureComponent } from 'react';
import { CouponPackageSelector } from '../coupon-package-selector';
import { CouponSelector } from '../coupon-selector';
import './index.less';
/**
 * 选择优惠券/礼包
 */
export class CouponSelectorWithPackage extends PureComponent<any> {
    render() {
        const {
            onChange,
            params,
            type,
            packageSelectType,
            value,
            disabled,
            checkSubsiteOnSelect,
            hideCouponPackageSelector,
        } = this.props;
        return (
            <div className="coupon-selector-with-package">
                <div className="coupon-selector-wrap">
                    <CouponSelector
                        buttonProps={{ disabled }}
                        onChange={onChange}
                        selectValues={value}
                        type={type}
                        params={params}
                        checkSubsiteOnSelect={checkSubsiteOnSelect}
                    />
                </div>
                {!hideCouponPackageSelector && (
                    <div className="coupon-package-selector-wrap">
                        <CouponPackageSelector
                            buttonProps={{ disabled }}
                            onChange={onChange}
                            selectValues={value}
                            type={packageSelectType}
                            params={params}
                            checkSubsiteOnSelect={checkSubsiteOnSelect}
                        />
                    </div>
                )}
            </div>
        );
    }
}
