import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import { services } from '@comall-backend-builder/core';
const prefix = 'wheel-draw-preview';
const innerClassNames = {
    top: `${prefix}__tpp`,
    title: `${prefix}__title`,
    box: `${prefix}__box`,
    ruler: `${prefix}__ruler`,
    rulerText: `${prefix}__ruler__text`,
    luckDraw: `${prefix}__luck-draw`,
    lotteryTip: `${prefix}__lottery_tip`,
    lotteryTipLeft: `${prefix}__lottery_tip__left`,
    lotteryTipLeftNologin: `${prefix}__lottery_tip__left ${prefix}__lottery_tip__left--no`,
    lotteryTipMid: `${prefix}__lottery_tip__mid`,
    lotteryTipRight: `${prefix}__lottery_tip__right`,
    shareBtn: `${prefix}__share-btn`,
    footer: `${prefix}__footer`,
    footerTitle: `${prefix}__footer__title`,
    footerList: `${prefix}__footer__list`,
    footerItem: `${prefix}__footer__item`,
};
/**
 * 全民分销预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const name = preview?.baseInfo.name || '';
    return (
        <div className={prefix}>
            <div className={innerClassNames.top}>
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className={innerClassNames.title}>{name}</div>
            </div>
            {backgroundImage && (
                <div className={innerClassNames.box} style={backgroundImage}>
                    {renderRuler(preview)}
                    {renderLuckDraw(preview)}
                    {renderLotteryTip(preview)}
                    {renderShareBtn(preview)}
                    {renderFooterArea(preview)}
                </div>
            )}
        </div>
    );
};
const renderRuler = (preview: any) => {
    const description =
        preview && preview.pictureInfo && preview.pictureInfo.description
            ? preview.pictureInfo.description
            : '';
    if (!description) {
        return null;
    }
    return (
        <div className={innerClassNames.ruler}>
            <div className={innerClassNames.rulerText}>{services.language.getText('g')}</div>
            <div className={innerClassNames.rulerText}>{services.language.getText('z')}</div>
        </div>
    );
};

const renderLuckDraw = (preview: any) => {
    const prefix = 'luck-draw';
    const innerClassNames = {
        content: `${prefix}__content`,
        turntable: `${prefix}__content__turntable`,
        pointer: `${prefix}__content__pointer`,
        luckDraw: `${prefix}__luck-draw`,
    };
    const pointPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.pointPicture &&
        preview.pictureInfo.pointPicture.length > 0
            ? preview.pictureInfo.pointPicture[0].path
            : '';
    const wheelPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.wheelPicture &&
        preview.pictureInfo.wheelPicture.length > 0
            ? preview.pictureInfo.wheelPicture[0].path
            : '';
    return (
        <div className={innerClassNames.luckDraw}>
            <div className={prefix}>
                <div className={innerClassNames.content}>
                    <img
                        alt=""
                        width="100%"
                        src={wheelPictureUrl}
                        className={innerClassNames.turntable}
                    />
                    <img
                        alt=""
                        width="100%"
                        src={pointPictureUrl}
                        className={innerClassNames.pointer}
                    />
                </div>
            </div>
        </div>
    );
};

const renderLotteryTip = (preview: any) => {
    const wheelPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.wheelPicture &&
        preview.pictureInfo.wheelPicture.length > 0
            ? preview.pictureInfo.wheelPicture[0].path
            : '';
    if (!wheelPictureUrl) {
        return null;
    }
    const url = require('./luck_draw_share_gift_bg.png');
    return (
        <div className={innerClassNames.lotteryTip}>
            <div className={innerClassNames.lotteryTipLeft}>
                {services.language.getText('sycjcs')}：0
            </div>
            <img className={innerClassNames.lotteryTipMid} width="100%" alt="" src={url}></img>
            <div className={innerClassNames.lotteryTipRight}>
                {services.language.getText('zjjl')}
            </div>
        </div>
    );
};

const renderShareBtn = (preview: any) => {
    const wheelPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.wheelPicture &&
        preview.pictureInfo.wheelPicture.length > 0
            ? preview.pictureInfo.wheelPicture[0].path
            : '';
    if (!wheelPictureUrl) {
        return null;
    }
    const shareBtnUrl = require('./luck_draw_share_btn_bg.png');
    const btnStyle = {
        backgroundImage: `url(${shareBtnUrl})`,
    };
    return (
        <div className={innerClassNames.shareBtn} style={btnStyle}>
            {services.language.getText('yqhyyqcyb')}
        </div>
    );
};

const renderFooterArea = (preview: any) => {
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    if (!backgroundImageUrl) {
        return null;
    }
    if (!preview || !preview.pictureInfo || !preview.pictureInfo.showRewardRecord) {
        return null;
    }
    const bgUrl = require('./images/scratch-ticket-reward-new.png');
    const style: any = {
        backgroundImage: `url(${bgUrl})`,
    };
    const showRewardRecord = preview.pictureInfo.showRewardRecord.includes('true') ? true : false;
    return (
        showRewardRecord && (
            <div>
                <div className={innerClassNames.footer} style={style}>
                    <div className={innerClassNames.footerTitle}>
                        {services.language.getText('zjmd')}
                    </div>
                    <div className={innerClassNames.footerList}>
                        <div className={innerClassNames.footerItem}>
                            <div>182****7899</div>
                            <div>{services.language.getText('jp')}1</div>
                        </div>
                        <div
                            className={innerClassNames.footerItem}
                            style={{ backgroundColor: ' #fff' }}
                        >
                            <div>182****7898</div>
                            <div>{services.language.getText('jp')}2</div>
                        </div>
                        <div className={innerClassNames.footerItem}>
                            <div>182****7897</div>
                            <div>{services.language.getText('jp')}3</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const WheelDrawPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
