import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const NotePromotionEncourageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/creation_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/creation_activities/${id}`;
        } else {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.baseInfo = {
                    eventType: res.activityVo.eventType,
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                };
                res.encourageInfo = {
                    noteTypes: res.noteTypes,
                    creationRewardType: res.creationRewardType,
                };
                if (res.activityVo.rewardConfig) {
                    res.rewardRule = res.activityVo.rewardConfig.rewardRule;
                    res.cycleRewardRule = res.activityVo.rewardConfig.activityRewards;
                    res.ladderRewardRule = res.activityVo.rewardConfig.activityRewards;
                    res.activityRewardLimit = res.activityVo.rewardConfig.activityRewardLimit;
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/creation_activities';
        let activityRewards;
        if (params.rewardRule === 'CYCLE') {
            activityRewards = params.cycleRewardRule;
        } else {
            activityRewards = params.ladderRewardRule;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                eventType: params.baseInfo.eventType,
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                rewardConfig: {
                    rewardRule: params.rewardRule,
                    activityRewards: activityRewards,
                    activityRewardLimit: params.activityRewardLimit,
                },
            };
        }
        if (params.encourageInfo) {
            newParams.noteTypes = params.encourageInfo.noteTypes;
            newParams.creationRewardType = params.encourageInfo.creationRewardType;
        }
        newParams.rewardCalculation = params.rewardCalculation;
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/creation_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
        }
        if (params.encourageInfo) {
            newParams.noteTypes = params.encourageInfo.noteTypes;
            newParams.creationRewardType = params.encourageInfo.creationRewardType;
            let activityRewards;
            if (params.rewardRule === 'CYCLE') {
                activityRewards = params.cycleRewardRule;
            } else {
                activityRewards = params.ladderRewardRule;
            }
            newParams.rewardConfig = {
                rewardRule: params.rewardRule,
                activityRewards: activityRewards,
                activityRewardLimit: params.activityRewardLimit,
            };
            newParams.rewardCalculation = params.rewardCalculation;
        }
        return await api.put(newParams, config);
    },
};
