import React, { Component, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { AuditGuideUserModal } from '../audit-modal';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface AuditGuideUserButtonProps {
    onChange: (val: boolean) => void;
    value: boolean;
    row: any;
    entity: any;
}

interface AuditGuideUserButtonState {
    visible: boolean;
}

export class AuditGuideUserButton extends Component<
    AuditGuideUserButtonProps,
    AuditGuideUserButtonState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
        };
    }
    renderAddBtn = () => {
        const { row } = this.props;
        const btnProps: any = {
            text: services.language.getText('audity'),
            type: 'link',
            style: {
                marginRight: 10,
            },
            disabled: !row.canEdit,
            onClick: this.openModal,
        };
        return createElement(ComponentsManager.get('Button'), btnProps);
    };
    openModal = () => {
        this.setState({ visible: true });
    };

    close = () => {
        this.setState({ visible: false });
    };

    addMerchantUser = async () => {
        const { row } = this.props;
        const organization = get(row, 'userOrganizationInfo');
        const { subsiteId, merchantId, type } = organization;
        if (type !== 'MERCHANT') {
            AntMessage.warning(language.getText('fzjzzjgzh'));
            return;
        }
        const mobile = get(row, 'mobile');
        const name = get(row, 'name');
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = '/admin/merchant_users/by_mobile';
        const merchantUser = await services.api.get<any>(
            { mobile, stateless: true },
            { apiRoot, apiPath }
        );
        if (!merchantUser) {
            services.api.post(
                {
                    name,
                    mobile,
                    subSiteId: subsiteId,
                    merchantId,
                    status: true,
                },
                { apiRoot, apiPath: '/admin/merchant_users' }
            );
        } else {
            merchantUser.merchantId = merchantId;
            merchantUser.subSiteId = subsiteId;
            merchantUser.name = name;
            merchantUser.status = merchantUser.status === 'ENABLED';
            services.api.put(merchantUser, {
                apiRoot,
                apiPath: `/admin/merchant_users/${merchantUser.id}`,
            });
        }
    };

    onAudit = async (val: any) => {
        const { entity, row } = this.props;

        try {
            services.loading.open();
            const params = { auditRemark: val.reason || '' };
            if (val.result) {
                await services.api.put(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/shopping_guide_application_records/${row.id}/approved`,
                });
                if (val.addMerchantUser) {
                    this.addMerchantUser();
                }
            } else {
                if (!val.reason) {
                    AntMessage.error(services.language.getText('qtxshyy'));
                    services.loading.close();

                    return;
                }
                await services.api.put(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/shopping_guide_application_records/${row.id}/rejected`,
                });
            }
            services.loading.close();
            this.setState({ visible: false });

            AntMessage.success(services.language.getText('shcg'));
            entity.search();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    render() {
        const { visible } = this.state;
        return (
            <>
                {this.renderAddBtn()}
                <AuditGuideUserModal
                    onCancel={this.close}
                    onAudit={this.onAudit}
                    visible={visible}
                ></AuditGuideUserModal>
            </>
        );
    }
}
