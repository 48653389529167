import React, { PureComponent } from 'react';
import { Button, InputNumber, message as AntMessage, Modal as AntModal, Radio } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
interface Props {
    row: any;
    entity: any;
}
interface States {
    visible: boolean;
    type: Type;
    quantity?: number;
}
enum Type {
    /**
     * 不限制
     */
    NO = 'NO',
    /**
     * 限制
     */
    YES = 'YES',
}
const { api } = services;
export class BargainActivitySettingEditButton extends PureComponent<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            type: Type.NO,
            quantity: undefined,
        };
    }

    handleModal = () => {
        const { visible } = this.state;
        const { row } = this.props;
        let quantity = 0;
        if (
            row.helpBargainCountEachMember !== language.getText('wxz') &&
            row.helpBargainCountEachMember
        ) {
            quantity = row.helpBargainCountEachMember;
        }
        this.setState({
            visible: !visible,
            type: quantity ? Type.YES : Type.NO,
            quantity: quantity,
        });
    };

    Ok = () => {
        const { entity, row } = this.props;
        const { type, quantity } = this.state;
        if (type === Type.YES && !quantity) {
            AntMessage.warning(language.getText('qsrqdzdxkcs'));
            return;
        }
        api.put(
            {
                helpBargainCountEachMember: type === Type.YES ? quantity : undefined,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                apiPath: `/admin/bargain_configs/${row.id}`,
            }
        )
            .then(() => {
                entity.search();
                this.handleModal();
            })
            .catch((error) => {
                errorHandle(error);
                this.handleModal();
            });
    };

    onChangeType = (type: Type) => {
        this.setState({
            type: type,
        });
    };
    onChangeQuantity = (num?: number) => {
        this.setState({
            quantity: num,
        });
    };

    renderModal = () => {
        const { visible, type, quantity } = this.state;
        return (
            <AntModal
                visible={visible}
                okText={services.language.getText('common.ok')}
                title={language.getText('kjsz')}
                onOk={this.Ok}
                onCancel={this.handleModal}
            >
                <div style={{ display: 'flex' }}>
                    <div>
                        <span style={{ color: 'red' }}>*</span>
                        <span>{language.getText('qdbkcs')}：</span>
                    </div>
                    <div>
                        <Radio.Group
                            value={type}
                            onChange={(e) => this.onChangeType(e.target.value)}
                        >
                            <div>
                                <Radio value={Type.NO}>{language.getText('wxz')}</Radio>
                            </div>
                            <div style={{ marginTop: '5px;' }}>
                                <Radio value={Type.YES}>
                                    {language.getText('qdzdxk')}&nbsp;&nbsp;
                                    <InputNumber
                                        onChange={(value) => this.onChangeQuantity(value)}
                                        min={1}
                                        step={0}
                                        precision={0}
                                        max={999}
                                        size="small"
                                        value={quantity ? quantity : undefined}
                                    />
                                    &nbsp;&nbsp;{language.getText('frequency')}
                                </Radio>
                            </div>
                        </Radio.Group>
                        <div style={{ marginTop: '5px', color: '#b1b7bd' }}>
                            {language.getText('ygyhzbdbtdkjhdz')}
                        </div>
                    </div>
                </div>
            </AntModal>
        );
    };

    render() {
        return (
            <div>
                <Button type="link" onClick={this.handleModal}>
                    {language.getText('common.edit')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}
