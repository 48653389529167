// @ts-nocheck
import React, { PureComponent } from 'react';
import { Modal, Upload, Icon, Button, message as AntMessage, message } from 'antd';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../services';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { Dragger } = Upload;
export class UploadFileModal extends PureComponent<any, any> {
    state = {
        visible: false,
        confirmLoading: false,
        statusMsg: language.getText('uploadFileClickButton'),
        fileList: [],
    };
    onImport = () => {
        this.setState({
            statusMsg: language.getText('importBeforeNotPage'),
        });
        const { entity, params } = this.props;
        const { fileList } = this.state;
        let { apiRoot, apiPath } = params;
        const formData = new FormData();
        formData.append('files', fileList[0]);
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot,
                    apiPath,
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then(() => {
                ForwardDataCenterModal();
                entity.search();
                this.closeModal();
            })
            .catch((err) => {
                if (err) {
                    message.error(err.response?.body?.err_msg || language.getText('uoloadFail'));
                    this.setState({
                        statusMsg: language.getText('importFail'),
                    });
                }
            });
    };
    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport();
        } else {
            AntMessage.error(language.getText('pleaseUploadFile'));
            return;
        }
    };
    closeModal = () => {
        this.props.onClose();
    };
    render() {
        const { fileList, statusMsg } = this.state;
        const {
            params: { templateSource },
        } = this.props;
        const uploadProps = {
            accept: '.xlsx,.xls',
            multiple: false,
            onRemove: () => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        const { visible } = this.props;
        return (
            <div className="upload-file-modal">
                <Modal
                    onCancel={this.closeModal}
                    visible={visible}
                    title={language.getText('batchImport')}
                    footer={[
                        <span className="upload-file-modal-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.closeModal}>
                            {language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {language.getText('txccdxgxx')}：.xlsx、.xls
                        </p>
                    </Dragger>
                    <div className="upload-file-modal-tips">
                        <p className="upload-file-modal-tips-text">
                            {language.getText('importRules')}
                        </p>
                        <p className="upload-file-modal-tips-text">
                            {language.getText('zwjztxxydrdxx')}
                        </p>
                        <p className="upload-file-modal-tips-text-red">
                            {language.getText('btmcbxatstx')}
                        </p>
                        <p className="upload-file-modal-tips-text">
                            {language.getText('merchantBigCodeIntro2')}
                        </p>
                        <p className="upload-file-modal-tips-text">
                            {language.getText('uploadIntro3')}
                        </p>
                        <p className="upload-file-modal-tips-text">{language.getText('qzqtxsp')}</p>
                    </div>
                    <div className="upload-tips">
                        <ExportButton
                            type="link"
                            text={language.getText('xzpldrmb')}
                            showErrorMessage={true}
                            source={{
                                apiRoot: templateSource.apiRoot,
                                apiPath: templateSource.apiPath,
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
