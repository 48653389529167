import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';
import { PaginationResult } from '../../services/api';

interface PaymentAllocationAccount {
    accountName: string;
    accountNumber: string;
    accountType: 'DOMESTIC' | 'FOREIGN';
    allocationRatio: number;
    sequence: number;
}
interface PaymentAllocationConfigVO {
    accounts: PaymentAllocationAccount[];
    allocationType: string;
    enabled: boolean;
    id: number;
    lastModifyTime: string;
    paymentModeCode: string;
    paymentModeId: number;
    paymentModeName: string;
    subsiteVo: {
        subsiteId: number;
        subsiteName: string;
    };
}

interface AllocationConfigFormData {
    basicConfig: {
        subsiteId: {
            id: string;
            name: string;
        };
        paymentMode: {
            code: string;
            id: string;
            name: string;
        };
        allocationType: string;
    };
    allocationConfigA: Omit<PaymentAllocationAccount, 'sequence'>;
    allocationConfigB: Omit<PaymentAllocationAccount, 'sequence'>;
}

interface AllocationConfigRequestBody {
    accounts: PaymentAllocationAccount[];
    allocationType: string;
    paymentModeCode: string;
    paymentModeId: number;
    subsiteId: number;
}

export const PaymentAllocationConfigLoader: Loader = {
    get(params, config) {
        if (params.id) {
            return api.get<PaymentAllocationConfigVO>(params, config).then(transformDetailResponse);
        }
        return api.get<PaginationResult<PaymentAllocationConfigVO>>(params, config);
    },
    post(params, config) {
        return api.post(transformRequestBody(params as AllocationConfigFormData), config);
    },
    put(params, config) {
        return api.put(
            { id: params.id, ...transformRequestBody(params as AllocationConfigFormData) },
            config
        );
    },
};

function transformRequestBody(formData: AllocationConfigFormData): AllocationConfigRequestBody {
    const accounts: PaymentAllocationAccount[] = [];
    const { allocationConfigA, allocationConfigB } = formData;
    if (allocationConfigA.accountName && allocationConfigA.accountNumber) {
        accounts.push({ ...allocationConfigA, sequence: 1 });
    }
    if (allocationConfigB?.accountName && allocationConfigB.accountNumber) {
        accounts.push({ ...allocationConfigB, sequence: 2 });
    }
    return {
        accounts,
        allocationType: formData.basicConfig.allocationType,
        paymentModeCode: formData.basicConfig.paymentMode.code,
        paymentModeId: Number(formData.basicConfig.paymentMode.id),
        subsiteId: Number(formData.basicConfig.subsiteId),
    };
}

function transformDetailResponse(response: PaymentAllocationConfigVO): AllocationConfigFormData {
    const {
        accounts,
        subsiteVo: { subsiteId, subsiteName },
        allocationType,
        paymentModeCode,
        paymentModeId,
        paymentModeName,
    } = response;
    let [accountA, accountB] = accounts;
    if (!accountB && accountA) {
        accountB = {
            accountName: '',
            accountNumber: '',
            accountType: accountA.accountType === 'DOMESTIC' ? 'FOREIGN' : 'DOMESTIC',
            allocationRatio: 100 - accountA.allocationRatio,
            sequence: 2,
        };
    }
    return {
        basicConfig: {
            subsiteId: {
                id: String(subsiteId),
                name: subsiteName,
            },
            paymentMode: {
                code: paymentModeCode,
                id: String(paymentModeId),
                name: paymentModeName,
            },
            allocationType,
        },
        allocationConfigA: accountA,
        allocationConfigB: accountB,
    };
}
