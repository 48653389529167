import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';

import { get, mapValues, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface RoleEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
}

enum RoleType {
    administrator = 'administrator',
    tenantAdministrator = 'tenant_administrator',
    predefine = 'predefined',
    custom = 'custom',
}

class RoleEditFormView extends PureComponent<RoleEditFormProps> {
    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, data } = this.props;
        const extendAttributes = data.extendAttributes || [];
        const roleTypeData = extendAttributes.find((i: any) => i.attributeCode === 'role_type');
        const roleType = get(roleTypeData, 'attributeValue');

        let fields = [
            {
                property: 'baseInfo.parentName',
                controlConfig: {
                    value: get(data, 'baseInfo.parentName'),
                },
            },
            {
                property: 'baseInfo.name',
                controlConfig: {
                    style: {
                        width: 500,
                    },
                    disabled:
                        roleType === RoleType.tenantAdministrator ||
                        roleType === RoleType.administrator ||
                        roleType === RoleType.predefine,
                },
            },
            // {
            //     property: 'baseInfo.sequence',
            //     controlConfig: {
            //         min: 0,
            //         max: 999999999,
            //         precision: 0,
            //         style: {
            //             width: 100,
            //         },
            //         disabled:
            //             roleType === RoleType.administrator ||
            //             roleType === RoleType.tenantAdministrator ||
            //             roleType === RoleType.predefine,
            //     },
            // },
            {
                property: 'baseInfo.remark',
                controlConfig: {
                    placeholder: services.language.getText('qsrjsms'),
                    rows: 4,
                    style: {
                        width: 500,
                    },
                    disabled:
                        roleType === RoleType.tenantAdministrator ||
                        roleType === RoleType.administrator,
                },
            },
            {
                property: 'authInfo.privilegeSetIds',
                className: 'auth-label-hidden',
                controlConfig: {
                    disabled:
                        roleType === RoleType.tenantAdministrator ||
                        roleType === RoleType.administrator,
                },
            },
            {
                property: 'extendAttributes',
                className: 'auth-item-hidden',
            },
        ];

        const formProps = {
            entity,
            componentId: 'RoleEditFormContainer',
            wrappedComponentRef,
            loaderType: 'get',
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data = get(_dispatch, 'components.["DataFormPlus-RoleEditFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(actions.unmountComponentAction('DataFormPlus-RoleEditFormContainer'));
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });
            const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展

            if (newData) {
                try {
                    entity.modify(newData, params);
                } catch (error) {
                    console.error(error);
                }
            }
        },
    };
}
export const NewRoleManageEditForm = connect(mapStateToProps)(RoleEditFormView);
