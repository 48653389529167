import React, { Component } from 'react';
import { default as Radio, RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';
import { find, isEqual } from 'lodash';
import { message } from 'antd';
import { privilege } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export interface GroupOption {
    disabled?: boolean;
    /**
     * 候选项id
     */
    id: string;
    /**
     * 候选项显示名称
     */
    name: string;
}

export interface RadioGroupProps extends Pick<AntRadioGroupProps, 'value' | 'disabled'> {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string, name: string) => void;
    /**
     * 候选项集合
     */
    options: Array<GroupOption>;
    illustrate: {
        [str: string]: any;
    };
    /**
     * 自定义样式类
     */
    className?: string;
    /**
     * 样式
     */
    style?: React.CSSProperties;

    /**
     * 跳转文案
     */
    forwardText?: string;
    /**
     * 跳转地址
     */
    forwardPath?: string;

    /**
     * 跳转权限名称
     */
    forwardPrivilege?: string;
}

export class StringRadioIllustrate extends Component<RadioGroupProps> {
    onChange = (e: any) => {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(e.target.value, name);
        }
    };
    componentWillReceiveProps(nextProps: any) {
        const { name, value, options, onChange, defaultValueIndex } = nextProps;

        if (isEqual(this.props.options, options)) {
            return;
        }

        if (value) {
            if (!find(options, { id: value })) {
                onChange(undefined, name);
            }
        } else if (defaultValueIndex !== undefined) {
            onChange(options[defaultValueIndex].id, name);
        }
    }

    shouldComponentUpdate(nextProps: any) {
        return !isEqual(this.props, nextProps);
    }

    renderTip = (value: string) => {
        const { forwardText, forwardPath, forwardPrivilege } = this.props;

        const onGoToStylePage = () => {
            if (forwardPrivilege && privilege.check(forwardPrivilege, 'full')) {
                window.open(forwardPath);
            } else {
                message.warning(services.language.getText('noPerationPermission'));
            }
        };
        if (forwardText) {
            if (value.includes('{forwardText}')) {
                const [before, after] = value.split('{forwardText}');
                return (
                    <div>
                        <span>{before || ''}</span>
                        <span
                            style={{ color: '#1890ff', cursor: 'pointer' }}
                            onClick={onGoToStylePage}
                        >
                            {forwardText}
                        </span>
                        <span>{after || ''}</span>
                    </div>
                );
            }
        }
        return value;
    };
    render() {
        const { value, className, style, disabled, options, illustrate } = this.props;

        const radioGroupProps = {
            value,
            className,
            style,
            disabled,
            illustrate,
        };

        return (
            <Radio.Group {...radioGroupProps} onChange={this.onChange}>
                {options.map((option) => {
                    return (
                        <div>
                            <div>
                                <Radio
                                    value={option.id}
                                    disabled={option.disabled ? option.disabled : false}
                                >
                                    {option.name}
                                </Radio>
                            </div>

                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: 5,
                                    color: 'rgb(204, 204, 204)',
                                    fontSize: 12,
                                }}
                            >
                                {this.renderTip(illustrate[option.id])}
                            </div>
                        </div>
                    );
                })}
            </Radio.Group>
        );
    }
}
