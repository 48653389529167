import React, { PureComponent } from 'react';
import { Card, Table } from 'antd';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import PayAuthorizationButton from './PayAuthorizationButton';

const prefix = 'payment-authorization';

/**
 * 会员个人信息-支付授权
 */
export class PaymentAuthorizationDetail extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            stateData: [],
            logData: [],
            loading: false,
        };
    }
    componentDidMount() {
        this.loadData();
    }
    private loadData = () => {
        const { params } = this.props;
        const id = params.id;
        this.setState({ loading: true });
        //获取详细信息
        api.get(
            { memberId: id },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/payment_member_authorization`,
            }
        )
            .then((res: any) => {
                const { authorizationList, logList } = res;
                this.setState({
                    stateData: authorizationList,
                    logData: logList,
                });
            })
            .catch(errorHandle)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { stateData, logData, loading } = this.state;
        const {
            params: { id },
        } = this.props;
        const inClassName = {
            box: `${prefix}__box`,
        };
        const _that = this;
        const logColumns = [
            {
                title: language.getText('sqzffsmc'),
                dataIndex: 'payModeName',
                key: 'payModeName',
            },
            {
                title: language.getText('common.tableAction'),
                dataIndex: 'operation',
                key: 'operation',
            },
            {
                title: language.getText('czsj'),
                dataIndex: 'createTime',
                key: 'createTime',
                render(text: string) {
                    return <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>;
                },
            },
            {
                title: language.getText('lastModifiedUser'),
                dataIndex: 'operationUserName',
                key: 'operationUserName',
            },
            {
                title: language.getText('remark'),
                dataIndex: 'remark',
                key: 'remark',
            },
        ];
        const stateColumns = [
            {
                title: language.getText('sqzffsmc'),
                dataIndex: 'payModeName',
                key: 'payModeName',
            },
            {
                title: language.getText('yhsqzt'),
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: language.getText('zhycczsj'),
                dataIndex: 'lastModifiedTime',
                key: 'lastModifiedTime',
                render(text: string) {
                    return <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>;
                },
            },
            {
                title: language.getText('common.tableAction'),
                dataIndex: 'operation',
                key: 'operation',
                render(text: string, record: any) {
                    return (
                        <PayAuthorizationButton
                            memberId={id}
                            row={record}
                            OnRefresh={_that.loadData}
                        />
                    );
                },
            },
        ];
        return (
            <div className={inClassName.box} style={{ marginTop: 20 }}>
                <Table
                    dataSource={stateData}
                    columns={stateColumns}
                    pagination={false}
                    loading={loading}
                />
                <div style={{ marginTop: 40 }}>
                    <Card
                        title={language.getText('zfsqrz')}
                        bordered={false}
                        bodyStyle={{ paddingLeft: 0 }}
                    >
                        <Table
                            dataSource={logData}
                            columns={logColumns}
                            pagination={false}
                            loading={loading}
                        />
                    </Card>
                </div>
            </div>
        );
    }
}
