import { api, errorHandle, navigation } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import {
    message as AntMessage,
    Row as AntRow,
    Card as AntCard,
    Col as AntCol,
    Table as AntTable,
    Select,
} from 'antd';
import React, { Component } from 'react';
import { map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { getDepostOrderStatusName } from './common';
import { MultipleImageUpload } from '../../components';

const { Option } = Select;

interface PictureVo {
    id: number | string;
    url: string;
}
interface Props {
    params: any;
}

interface States {
    info: any | null;
    reasons: any | null;
    reasonId: number;
    remark: string;
    pictures: Array<PictureVo>;
}

export class DepositOrderApply extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: null,
            reasons: null,
            reasonId: 0,
            remark: '',
            pictures: [],
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/deposit_orders/${id}`,
            }
        ).then((response: any) => {
            this.setState({
                info: response,
            });
        });
        //获取选择原因
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/reasons/2`,
            }
        ).then((response: any) => {
            this.setState({
                reasons: response,
            });
        });
    }

    //加载会员信息
    renderMemberInfo = () => {
        const { info } = this.state;
        if (!info) {
            return null;
        }
        return (
            <div>
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCard type="inner" title={services.language.getText('hyxx')}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={6}>
                                {services.language.getText('hysjh')}：{info.memberInfo.mobile}
                            </AntCol>
                            <AntCol span={6}>
                                {services.language.getText('hyxm')}：{info.memberInfo.name}
                            </AntCol>
                            <AntCol span={6}>
                                {services.language.getText('nickName')}：{info.memberInfo.nickName}
                            </AntCol>
                            <AntCol span={6}>
                                {services.language.getText('hydj')}：
                                {info.memberInfo.memberCardLevelName}
                            </AntCol>
                        </AntRow>
                    </AntCard>
                </AntRow>
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCard type="inner" title={services.language.getText('ddxx')}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={8}>
                                {services.language.getText('djdh')}：
                                <span style={{ color: '#1890ff' }}>{info.orderNo}</span>
                            </AntCol>
                            <AntCol span={8}>
                                {services.language.getText('subsites')}：{info.subsite.name}
                            </AntCol>
                            <AntCol span={8}>
                                {services.language.getText('merchant')}：{info.merchant.name}
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={8}>
                                {services.language.getText('ddzt')}：
                                {getDepostOrderStatusName(info.status)}
                            </AntCol>
                            <AntCol span={8}>
                                {services.language.getText('xdsj')}：{info.orderCreateTime}
                            </AntCol>
                            <AntCol span={8}>
                                {services.language.getText('kddg')}：{info.guideInfo.name}
                            </AntCol>
                        </AntRow>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={8}>
                                {services.language.getText('orderAmount')}：
                                <span style={{ color: 'red' }}>{info.orderAmount}</span>
                            </AntCol>
                        </AntRow>
                    </AntCard>
                </AntRow>
            </div>
        );
    };

    onChangeReasonId = (e: any) => {
        this.setState({
            reasonId: e,
        });
    };

    onChangeRemark = (e: any) => {
        if (e.target.value) {
            const value = e.target.value;
            this.setState({
                remark: value,
            });
        }
    };

    renderApplyInfo = () => {
        const { reasons, reasonId } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('applyInfo')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3} style={{ display: 'flex' }}>
                        <div>
                            <span style={{ color: 'red', marginLeft: '-10px', fontSize: '18px' }}>
                                *
                            </span>
                        </div>
                        <div>{services.language.getText('sqyy')}:</div>
                    </AntCol>
                    <AntCol span={5}>
                        <Select
                            value={reasonId}
                            style={{ width: '200px' }}
                            onChange={(e: any) => this.onChangeReasonId(e)}
                        >
                            <Option value={0}>{services.language.getText('pleaseSelect')}</Option>
                            {map(reasons, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.reason}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AntCol>
                </AntRow>
                {this.renderTable()}
            </AntCard>
        );
    };

    renderTable = () => {
        const { info } = this.state;
        const list = [
            {
                merchantBigCode: info.items[0].merchantBigCode,
                orderAmount: info.orderAmount,
                mode: '原路返回',
            },
        ];
        const tableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText(''),
                    key: 'merchantBigCode',
                    dataIndex: 'merchantBigCode',
                },
                {
                    title: services.language.getText('sfje'),
                    key: 'orderAmount',
                    dataIndex: 'orderAmount',
                },
                {
                    title: services.language.getText('tkje'),
                    dataIndex: 'orderAmount',
                    key: 'orderAmount',
                },
                {
                    title: services.language.getText('tkfs'),
                    dataIndex: 'mode',
                    key: 'mode',
                },
            ],
        };
        return (
            <AntTable
                style={{ overflowX: 'scroll' }}
                rowKey={(record, index) => index.toString()}
                dataSource={list}
                {...tableProps}
            />
        );
    };

    onUploadImage = (value: PictureVo[]) => {
        this.setState({
            pictures: value,
        });
    };

    renderServiceInfo = () => {
        const { pictures } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('kfjl')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfbz')}:</AntCol>
                    <AntCol span={15}>
                        <textarea
                            className="ant-input"
                            placeholder={services.language.getText('common.pleaseInput')}
                            style={{
                                height: '73px',
                                minHeight: '73px',
                                maxHeight: '136px',
                                overflowY: 'hidden',
                            }}
                            onChange={(e: any) => this.onChangeRemark(e)}
                            maxLength={400}
                        ></textarea>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfpz')}:</AntCol>
                    <AntCol span={20}>
                        <MultipleImageUpload
                            maxCount={6}
                            value={pictures}
                            onChange={this.onUploadImage}
                        />
                    </AntCol>
                </AntRow>
            </AntCard>
        );
    };

    // 提交
    isSubmit = false;
    submit = () => {
        const { reasonId, remark, pictures } = this.state;
        const { params } = this.props;
        if (!reasonId) {
            AntMessage.warning(services.language.getText('qxzsqyy'));
            return false;
        }
        if (this.isSubmit) {
            return;
        }
        this.isSubmit = true;
        api.post(
            {
                orderId: params.id,
                reasonId: reasonId,
                remark: remark,
                pictures: pictures,
                applyEntryType: 'PLANTFORM',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/deposit_order_service_applies`,
            }
        )
            .then(() => {
                AntMessage.success(services.language.getText('tjcg_1'));
                setTimeout(() => {
                    navigation.goBack();
                    this.isSubmit = false;
                }, 2000);
            })
            .catch((err) => {
                this.isSubmit = false;
                errorHandle(err);
            });
    };

    render() {
        const { info } = this.state;
        if (!info) {
            return null;
        }
        return (
            <div className="coupon-service-applies-edit-form">
                <AntRow style={{ marginBottom: 20 }}>{this.renderMemberInfo()}</AntRow>
                <AntRow style={{ marginBottom: 20 }}>{this.renderApplyInfo()}</AntRow>
                <AntRow style={{ marginBottom: 20 }}>{this.renderServiceInfo()}</AntRow>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button type="primary" onClick={this.submit} style={{ marginRight: 24 }}>
                        {services.language.getText('common.save')}
                    </Button>
                    <Button route="goBack">{services.language.getText('common.cancel')}</Button>
                </div>
            </div>
        );
    }
}
