import React, { PureComponent } from 'react';
import { Button, Checkbox } from 'antd';
import { cloneDeep, remove } from 'lodash';
import {
    NewMallActivityRoundItem,
    NewMallActivityMemberCardLevel,
    NewMallActivityRow,
} from '../types';
import { NewMallActivityRound } from './new-mall-activity-round';
import {
    getCardLevelChangeResult,
    getNewTicketsOnCardlevelsChange,
    handleKeys,
    cloneRound,
    newRound,
} from '../common';

import { NewMallActivityRoundsAddModal } from './new-mall-activity-rounds-add-modal';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityRoundsProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: NewMallActivityRoundItem[] | undefined, name: string) => void;
    /**
     * 当前值
     */
    value: NewMallActivityRoundItem[];
    disabled?: boolean;
    row: NewMallActivityRow;
    isAdd: boolean;
}

interface NewMallActivityRoundsState {
    allCardLevels?: NewMallActivityMemberCardLevel[];
    limitCardLevels?: string[];
    showTickets: boolean;
}

/**
 * 新商场活动场次管理
 */
class NewMallActivityRounds extends PureComponent<
    NewMallActivityRoundsProps,
    NewMallActivityRoundsState
> {
    constructor(props: NewMallActivityRoundsProps) {
        super(props);
        this.state = {
            showTickets: true,
        };
        // 默认生成一个空场次
        if (!props?.value?.length) {
            this.addDefaultEmptyRound();
        }
    }
    componentDidMount(): void {
        const { value = [], row } = this.props;
        if (row) {
            this.checkRowKeys(value);
        }
        this.checkCardLevelChange(row);
    }
    componentWillReceiveProps(nextProps: NewMallActivityRoundsProps) {
        const { row: prevRow } = this.props;
        const { value = [], row: nextRow } = nextProps;
        if (nextRow) {
            this.checkRowKeys(value);
        }
        this.checkCardLevelChange(nextRow, prevRow);
    }

    /**
     * 如果当前value中存在没有rowKey的数据，需要生成
     * rowKey是前端生成的唯一标识，非后端返回字段
     */
    checkRowKeys = (value: NewMallActivityRoundItem[]) => {
        if (value.every((i) => Boolean(i.rowKey))) {
            return;
        }
        const valueWithKeys = handleKeys(value).map((item) => ({
            ...item,
            tickets: handleKeys(item.tickets),
        }));
        this.changeValue(valueWithKeys);
    };

    /**
     * 不同门店卡等级列表可能不同，所以当门店发生变更时，需要重新获取卡等级列表，并且重新处理不同卡等级的价格
     * 当限制购买卡等级发生变更时，需要根据变更后的可购买的卡等级重新生成卡等级价格列表
     */
    checkCardLevelChange = async (nextRow: NewMallActivityRow, prevRow?: NewMallActivityRow) => {
        if (!nextRow) {
            return;
        }
        const { changed, ...other } = await getCardLevelChangeResult(nextRow, prevRow);
        if (!changed) {
            return;
        }
        this.setState((state) => ({ ...state, ...other }), this.changeTicketsPriceByCardlevels);
    };

    /**
     * 根据当前门店卡等级+购买限制卡等级，重新生成场次票档的不同卡等级价格
     */
    changeTicketsPriceByCardlevels = () => {
        const { value = [] } = this.props;
        const newValue = cloneDeep(value);
        newValue.forEach((item) => {
            item.tickets = getNewTicketsOnCardlevelsChange(item.tickets, this.cardLevels);
        });
        this.changeValue(newValue);
    };

    /**
     * 当前可以设置价格的门店卡等级列表
     * 如果存在卡等级购买限制的配置，则只有可以购买的卡等级可以设置价格
     */
    private get cardLevels() {
        const { allCardLevels = [], limitCardLevels = [] } = this.state;
        return limitCardLevels.length
            ? allCardLevels.filter((level) => limitCardLevels.some((id) => id === level.id))
            : allCardLevels;
    }

    changeValue = (value: NewMallActivityRoundItem[]) => {
        const { name, onChange } = this.props;
        onChange(value, name);
    };

    /**
     * 删除单个场次
     */
    onRemove = (round: NewMallActivityRoundItem) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        remove(newValue, (i) => i.rowKey === round.rowKey);
        this.changeValue(newValue);
    };
    /**
     * 复制场次
     */
    onCopy = (round: NewMallActivityRoundItem) => {
        const { value } = this.props;
        this.changeValue([...cloneDeep(value), cloneRound(round)]);
    };

    /**
     * 编辑场次
     */
    onRoundChange = (round: NewMallActivityRoundItem) => {
        // 如果编辑的是默认生成的空场次，取消记录该场次，批量生成场次时不删除该场次
        if (round.rowKey === this.defaultEmptyRoundRoyKey) {
            this.defaultEmptyRoundRoyKey = undefined;
        }
        const { value } = this.props;
        const newValue = cloneDeep(value);
        const index = newValue.findIndex((i) => i.rowKey === round.rowKey);
        if (index !== -1) {
            newValue[index] = round;
            this.changeValue(newValue);
        }
    };

    /**
     * 新增场次
     */
    onAddRound = () => {
        const { value = [] } = this.props;
        this.changeValue([...value, newRound()]);
    };

    /**
     * 记录默认初始化的空场次，当该场次用户操作后取消记录。用于批量生成场次时删除用户未编辑的空场次
     */
    private defaultEmptyRoundRoyKey: string | undefined;

    /**
     * 新增场次
     */
    addDefaultEmptyRound = () => {
        const round = newRound();
        this.defaultEmptyRoundRoyKey = round.rowKey;
        this.changeValue([round]);
    };

    /**
     * 控制票档展示隐藏
     */
    toggleShowTickets = () => {
        const { showTickets } = this.state;
        this.setState({ showTickets: !showTickets });
    };

    renderHeader = () => {
        const { value = [] } = this.props;
        const { showTickets } = this.state;
        return (
            <div className="new-mall-activity__rounds__header">
                {Boolean(value.length) && (
                    <Checkbox onChange={this.toggleShowTickets} checked={showTickets}>
                        {language.getText('xspdxx')}
                    </Checkbox>
                )}
            </div>
        );
    };

    /**
     * 批量生成场次
     */
    batchAddRounds = (rounds: NewMallActivityRoundItem[]) => {
        const { value = [] } = this.props;
        const newValue = cloneDeep(value)
            .filter((i) => i.rowKey !== this.defaultEmptyRoundRoyKey)
            .concat(rounds);
        this.changeValue(newValue);
        this.defaultEmptyRoundRoyKey = undefined;
    };

    renderFooter = () => {
        const { disabled, isAdd, row } = this.props;
        return (
            <div className="new-mall-activity__rounds__footer">
                <Button onClick={this.onAddRound} className="setting-item-ok" type="primary">
                    {language.getText('zjccxx')}
                </Button>
                <NewMallActivityRoundsAddModal
                    disabled={disabled}
                    row={row}
                    isAdd={isAdd}
                    onSubmit={this.batchAddRounds}
                    cardLevels={this.cardLevels}
                />
            </div>
        );
    };

    render() {
        const { value = [], isAdd, row } = this.props;
        const { showTickets } = this.state;
        return (
            <>
                {this.renderHeader()}
                {value.map((item) => (
                    <NewMallActivityRound
                        row={row}
                        value={item}
                        cardLevels={this.cardLevels}
                        onChange={this.onRoundChange}
                        onCopy={this.onCopy}
                        onRemove={this.onRemove}
                        showTickets={showTickets}
                        isAdd={isAdd}
                    />
                ))}
                {this.renderFooter()}
            </>
        );
    }
}

export { NewMallActivityRounds };
