import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ForwardDataCenterModal } from '../../services';

import 'antd/dist/antd.css';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    resourceId: number;
    resourceName: string;
    resourceUrl: string;
    type: string;
}

interface ApiConfig {
    apiPath: string;
    apiRoot: string;
    requestType?: 'get' | 'post' | 'put';
}

interface ButtonConfig {
    type?: 'link' | 'primary' | 'default' | 'ghost' | 'dashed' | 'danger';
    text?: string;
}

interface ModalConfig {
    title?: string;
}

interface BatchImportButtonProps {
    uploadConfig: ApiConfig;
    templateResource: ApiConfig;
    buttonConfig?: ButtonConfig;
    modalConfig?: ModalConfig;
    onImportSuccess?: () => void;
}

const defaultButtonConfig: ButtonConfig = {
    type: 'default',
    text: '批量导入',
};

const defaultModalConfig: ModalConfig = {
    title: '批量导入',
};

/**
 * 批量导入excal按钮
 */
export class BatchImportButton extends PureComponent<
    BatchImportButtonProps,
    { fileList: any; showModal: boolean; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
    };

    toggleModal = () => {
        const { showModal } = this.state;
        if (showModal) {
            this.setState({
                resource: {
                    resourceId: 0,
                    resourceName: '',
                    resourceUrl: '',
                    type: 'IMPORT',
                },
                fileList: [],
            });
        }
        this.setState({ showModal: !showModal });
    };

    onImport = (resource: any) => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            uploadConfig: { requestType = 'post', ...config },
            onImportSuccess,
        } = this.props;
        const params = {
            resourceId: resource.resourceId,
        };

        api[requestType](params, config)
            .then(
                () => {
                    ForwardDataCenterModal();
                    this.toggleModal();
                    if (typeof onImportSuccess === 'function') {
                        onImportSuccess();
                    }
                },
                (err: any) => {
                    if (err.response.body.err_msg) {
                        message.error(err.response.body.err_msg);
                    } else {
                        this.setState({
                            statusMsg: services.language.getText('importFailPrivity'),
                        });
                    }
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.resourceId) {
            this.onImport(resource);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    getUploadProps = () => {
        const { fileList } = this.state;

        return {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            resourceId: 0,
                            resourceName: '',
                            resourceUrl: '',
                            type: 'IMPORT',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                api.upload(
                    { files: file },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                        apiPath: '/admin/files/locations/62/upload',
                        fileName: 'files',
                        progressCallBack: () => {},
                    }
                )
                    .then((res) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
    };

    renderExportButton = () => {
        const {
            templateResource: { requestType, ...source },
        } = this.props;
        const props = {
            type: 'link',
            text: services.language.getText('clickDownloadTemplate'),
            showErrorMessage: true,
            source: source,
        } as any;
        return <ExportButton {...props} />;
    };

    render() {
        const { showModal, statusMsg } = this.state;
        const uploadProps = this.getUploadProps();
        const { buttonConfig = defaultButtonConfig, modalConfig = defaultModalConfig } = this.props;
        const buttonClassName = buttonConfig.type === 'link' ? 'btn-data-statistics' : '';
        return (
            <div className="import-button-data-statistics">
                <Button
                    type={buttonConfig.type}
                    className={buttonClassName}
                    onClick={this.toggleModal}
                >
                    {buttonConfig.text}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={modalConfig.title}
                    footer={[
                        <span className="import-button-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div>
                        {this.renderExportButton()}
                        <div className="import-button-tips">
                            <p className="import-button-tips-text">
                                {services.language.getText('importRules')}
                            </p>
                            <p className="import-button-tips-text">
                                {services.language.getText('merchantBigCodeIntro1')}
                            </p>
                            <p className="import-button-tips-text-red">
                                {services.language.getText('merchantBigCodeNote')}
                            </p>
                            <p className="import-button-tips-text">
                                {services.language.getText('merchantBigCodeIntro2')}
                            </p>
                            <p className="import-button-tips-text">
                                {services.language.getText('merchantBigCodeIntro3')}
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
