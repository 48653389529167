import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { Mode } from '../../types/mode/array/array-deposit-mode-menus/deposit-mode-menus';
import { MerchantSelfType } from '../../config/merchant/merchant-manager/merchant-self';

const api = services.api;
export const GuideShoppingConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/guide_shopping_configs';
        if (data.mode === 'ALL') {
            delete data.mode;
        }
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/guide_shopping_configs/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const merchantSelfTypeOptions: Record<any, any> = {
                    [MerchantSelfType.JOINT]: '联营',
                    [MerchantSelfType.MERCHANT_SELF]: '自营',
                    [MerchantSelfType.LEASE]: '租赁',
                    [MerchantSelfType.LEAGUE]: '加盟',
                    [MerchantSelfType.OTHER]: '其他',
                    [MerchantSelfType.SELF]: '自营',
                };
                res.baseInfo = {
                    modes: res.modes,
                    subsite: res.subsite,
                    subsiteMerchants: {
                        selectMode: res.merchants && res.merchants.length > 0 ? 'false' : 'true',
                        merchants: res.merchants
                            ? res.merchants.map((m: any) => ({
                                  ...m,
                                  id: m.subsiteId + ',' + m.id,
                                  merchantId: m.id,
                                  merchantName: m.name,
                                  merchantType: merchantSelfTypeOptions[m.type],
                                  merchantStatus: m.status,
                                  subSiteId: m.subsiteId + '',
                                  subSiteName: m.subsiteName,
                              }))
                            : [],
                    },
                };
                console.log('****', res);
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        return {
                            ...item,
                            subsiteName: item.subsite.name,
                            modes: item.modes
                                .map((mode: Mode) => {
                                    const modeName = {
                                        [Mode.SHOP]: services.language.getText('scspkd'),
                                        [Mode.BIG_CODE]: services.language.getText('spkd'),
                                        [Mode.DEPOSIT]: services.language.getText('djkd'),
                                    };
                                    return modeName[mode];
                                })
                                .join(' / '),
                        };
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/guide_shopping_configs';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = `/admin/guide_shopping_configs/${params.id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
    delete: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = `/admin/guide_shopping_configs/${id}`;
        return await api.delete(data, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {
        modes: params.baseInfo.modes.map((m: any, index: number) => {
            return {
                ...m,
                sequence: index + 1,
            };
        }),
        subsiteId: params.baseInfo.subsite.id,
        conditions:
            params.baseInfo.subsiteMerchants &&
            params.baseInfo.subsiteMerchants.selectMode === 'false' &&
            params.baseInfo.subsiteMerchants.merchants &&
            params.baseInfo.subsiteMerchants.merchants.length > 0
                ? [
                      {
                          type: 'MERCHANT',
                          values: params.baseInfo.subsiteMerchants.merchants.map((m: any) => {
                              return m.merchantId;
                          }),
                      },
                  ]
                : undefined,
    };
    return newParams;
}
