import * as React from 'react';
import { Popover } from 'antd';
import { ArrayMode } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

export class OrdersPopoverMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const { replaceEmpty = '' } = displayInfo;
        return (
            <div>
                {value && value.length > 0 ? value[0].orderNo : replaceEmpty}{' '}
                {value && value.length > 0 && (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {value.map((item: any, index: number) => (
                                    <div key={item.orderNo} style={{ marginBottom: 10 }}>
                                        <span style={{ marginRight: '5px' }}>{item.orderNo}</span>
                                        <span>{item.status}</span>
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            {services.interpolate(services.language.getText('orderNum'), {
                                total: value.length,
                            })}
                        </span>
                    </Popover>
                )}
            </div>
        );
    }
}
