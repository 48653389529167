// @ts-nocheck
import React, { PureComponent } from 'react';
import { Button, Modal, message, Input } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';

const { api } = services;

enum Status {
    Active = '开通',
    Un_Active = '未开通',
    Close = '关闭',
}

/**
 * 开通/取消
 */
export default class PayAuthorizationButton extends PureComponent<
    any,
    {
        showModal: boolean;
        remark: string;
    }
> {
    state = {
        showModal: false,
        remark: '',
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal, remark: '' });
    };
    openModal = () => {
        this.setState({ showModal: true });
    };
    onInputChange = (e) => {
        this.setState({
            remark: e.target.value,
        });
    };

    handleOk = () => {
        const { row, OnRefresh, memberId } = this.props;
        const { remark } = this.state;
        if (!remark) {
            message.warning('请填写操作原因');
            return;
        }
        let statusText = row?.status !== Status.Active ? '成功' : '已关闭';
        const params = {
            memberId,
            operationType: row?.status !== Status.Active ? 'OPEN' : 'CLOSE',
            paymentModeCode: row.paymentModeCode,
            remark,
        };
        message.loading();
        api.post(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: `/admin/payment_member_authorization`,
        })
            .then(() => {
                message.destroy();
                this.toggleModal();
                message.success(`${row.payModeName}授权${statusText}`);
                OnRefresh();
            })
            .catch((error) => {
                message.destroy();
                errorHandle(error);
            });
    };

    render() {
        const { showModal, remark } = this.state;
        const { row } = this.props;
        let statusText = row?.status !== Status.Active ? '开通' : '关闭';
        return (
            <div className="deliver-button">
                <Button type="link" className="btn-data-statistics" onClick={this.openModal}>
                    {statusText}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={`${row.payModeName}${statusText}授权`}
                    footer={[
                        <Button key="back" onClick={this.toggleModal}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {statusText}
                        </Button>,
                    ]}
                >
                    <div style={{ display: 'flex' }}>
                        <div>备注:</div>
                        <div style={{ flex: 1, marginLeft: 10 }}>
                            <Input.TextArea
                                value={remark}
                                maxLength={400}
                                placeholder="请填写操作原因"
                                onChange={this.onInputChange}
                                autoSize={{ minRows: 6, maxRows: 10 }}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
