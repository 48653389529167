import React, { PureComponent, Fragment, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import './index.less';
/**
 * 团购活动管理列表
 */
class cgbManageList extends PureComponent<any, any> {
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps = {
            entity,
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'endTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOTSTARTED: {
                                text: services.language.getText('notStarted'),
                                status: 'success',
                            },
                            STARTED: {
                                text: services.language.getText('inprogress'),
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: services.language.getText('end'),
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'CgbManageListStatusButton',
                    },
                    {
                        type: 'link',
                        text: services.language.getText('editActivity'),
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/cgb-manage/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: services.language.getText('copyActivity'),
                        path: '/menu-list/market/cgb-manage/copy/{{row.id}}',
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
    render() {
        return (
            <Fragment>
                <div className="batch-button">
                    <Button
                        style={{ marginRight: 10 }}
                        text={services.language.getText('addActivity')}
                        type="primary"
                        className="mt24"
                        icon="plus"
                        route="/menu-list/market/cgb-manage/add"
                    />
                </div>
                <div>{this.renderTable()}</div>
            </Fragment>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const CGBManageList = connect(mapStateToProps)(cgbManageList);
