import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

export const config: Config = {
    entities: {
        BlackListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/blacklist',
            filters: {
                memberName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                    controlConfig: {
                        placeholder: '<<qsryhm>>',
                        style: { width: 150 },
                    },
                },
                receiveName: {
                    type: 'string',
                    displayName: '<<shrxm>>',
                    controlConfig: {
                        placeholder: '<<qsrshrxm>>',
                        style: { width: 150 },
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<shrsjh>>',
                    controlConfig: {
                        placeholder: '<<qsrshrsjh>>',
                        style: { width: 150 },
                    },
                },
                regionName: {
                    type: 'string',
                    displayName: '<<shdq>>',
                    controlConfig: {
                        placeholder: '<<qsrshdq>>',
                        style: { width: 150 },
                    },
                },
                address: {
                    type: 'string',
                    displayName: '<<detailAddress>>',
                    controlConfig: {
                        placeholder: '<<detailAddressRequired>>',
                        style: { width: 150 },
                    },
                },
            },
            properties: {
                member: {
                    displayName: '<<member>>',
                    type: 'object.select.member',
                },
                receiveName: {
                    displayName: '<<shrmc>>',
                    type: 'string',
                    rules: [{ max: 20, message: '<<receiveNameRule>>' }],
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                    },
                },
                mobile: {
                    type: 'string.phone',
                    displayName: '<<shrsjh>>',
                    // rules: [{ required: true, message: <<qsrsjh>> }],
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                        maxLength: 20,
                        style: {
                            width: 400,
                        },
                    },
                },
                region: {
                    displayName: '<<shdq>>',
                    type: 'object.select.city.code',
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                    },
                },
                address: {
                    displayName: '<<detailAddress>>',
                    type: 'string',
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                    },
                },
                ip: {
                    displayName: 'IP',
                    type: 'string',
                    rules: [{ max: 30, message: '<<inputIpplease>>' }],
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                    },
                },
                remark: {
                    displayName: '<<remark>>',
                    type: 'string.text.paragraph',
                    rules: [
                        { required: true, message: '<<qsrbz>>' },
                        {
                            message: '<<moreThan120>>',
                            validator: (rule: any, value: any) => {
                                if (value && value.trim().length > 120) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrbz>>',
                        rows: 4,
                        style: {
                            width: 400,
                        },
                    },
                },
            },
        },
        MemberSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/members',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<hym_1>>',
                },
            },
            filters: {
                mobile: {
                    type: 'string',
                    // format: 'trimString',
                    displayName: '<<hym_1>>',
                },
            },
        },
    },
    components: {
        BlackListView: {
            component: 'Viewport',
            entity: 'BlackListEntity',
        },
        BlackListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'BlackListEntity',
            items: [
                {
                    component: 'BlackListFilter',
                },
                {
                    component: 'Button',
                    text: '<<xzhmd>>',
                    route: '/blacklist/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 120,
                        marginBottom: 10,
                    },
                },
                {
                    component: 'BlackListTable',
                },
            ],
        },
        BlackListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                { property: 'memberName' },
                { property: 'receiveName' },
                { property: 'mobile' },
                { property: 'regionName' },
                { property: 'address' },
            ],
        },
        BlackListAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BlackListAddForm',
                },
            ],
        },
        BlackListAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'member' },
                { property: 'receiveName' },
                { property: 'mobile' },
                { property: 'region' },
                { property: 'address' },
                { property: 'ip' },
                { property: 'remark' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BlackListEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'BlackListEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'BlackListEditForm',
                },
            ],
        },
        BlackListEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'member' },
                { property: 'receiveName' },
                { property: 'mobile' },
                { property: 'region' },
                { property: 'address' },
                { property: 'ip' },
                { property: 'remark' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/blacklist',
                    component: 'BlackListView',
                    breadcrumbName: '<<blacklist>>',
                    privilege: {
                        path: 'blacklist',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'BlackListEditPage',
                            breadcrumbName: '<<bjhmd>>',
                        },
                        {
                            path: '/add',
                            component: 'BlackListAddPage',
                            breadcrumbName: '<<xzhmd>>',
                        },
                        { path: '/', component: 'BlackListPage' },
                    ],
                },
            ],
        },
    ],
};
