import * as React from 'react';
import { Tag, Popover } from 'antd';
import { ArrayMode } from '@comall-backend-builder/types';

export class ArrayRefuseReasonMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const { showInDetail } = displayInfo;
        return showInDetail ? (
            <div>
                {value && value.length > 0 ? value[0].name : '-'}
                {value && (
                    <div>
                        {value.map((item: any) => (
                            <div>
                                {item.type} ({item.detail.join('、')})
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ) : (
            <div>
                {value && value.length > 0 ? value[0].name : '-'}{' '}
                {value &&
                    value.map((item: any, index: number) => (
                        <Popover
                            key={item.type + index}
                            placement={'right'}
                            content={
                                <div style={{ maxWidth: 400 }}>
                                    {item.detail.map((i: any, index: number) => (
                                        <Tag key={i + index} style={{ marginBottom: 10 }}>
                                            {i}
                                        </Tag>
                                    ))}
                                </div>
                            }
                        >
                            <span style={{ marginRight: 5 }} className="group-tips">
                                {item.type}
                            </span>
                        </Popover>
                    ))}
            </div>
        );
    }
}
