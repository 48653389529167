import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { forEach } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        SalesPlansEntity: {
            apiPath: '/loader/admin/sales-plans',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<activityNameRequired>>',
                    },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<acitivityBeginTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<acitivityEndTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        dimension: {
                            type: 'string.options.radio',
                            displayName: '<<inventoryDimension>>',
                            defaultValue: 'PERIOD',
                            options: [
                                { id: 'PERIOD', name: '<<cycle>>' },
                                // { id: 'CUSTOM', name: '<<custom>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        period: {
                            type: 'string.options.radio',
                            displayName: '<<inventoryCycle>>',
                            defaultValue: 'DAY',
                            options: [
                                { id: 'DAY', name: '<<everyDay2>>' },
                                { id: 'WEEK', name: '<<everyWeek>>' },
                                { id: 'MONTH', name: '<<everyMonth>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<applicableSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        goods: {
                            type: 'array.salesPlansProducts',
                            displayName: '<<activityProduct>>',
                            controlConfig: {
                                showQuantityLimit: true,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        SalesPlansView: {
            component: 'Viewport',
            entity: 'SalesPlansEntity',
        },
        SalesPlansPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SalesPlansFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<developPlan>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/salesPlans/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SalesPlansTable' },
            ],
        },
        SalesPlansFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        SalesPlansTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'SalesPlansDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '140px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        key: 'operation',
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                            apiPath: '/admin/sales_plans/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                            apiPath: '/admin/sales_plans/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/salesPlans/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                text: '<<importProduct>>',
                                component: 'SalesPlansImportButton',
                            },
                        ],
                    },
                },
            ],
        },
        SalesPlansAddPage: {
            component: 'Card',
            content: { component: 'SalesPlansAddForm' },
        },
        SalesPlansAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SalesPlansEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.dimension' },
                { property: 'ruleInfo.period' },
                { property: 'ruleInfo.subsites' },
                { property: 'ruleInfo.goods' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const goods = entity.ruleInfo.goods;
                let hasValidate = true;
                forEach(goods, (i) => {
                    if (i.stock === null) {
                        AntMessage.warning(language.getText('fillInInventory'));
                        hasValidate = false;
                        return false;
                    }
                    if (i.stock !== null && i.stock <= 0) {
                        AntMessage.warning(language.getText('inventorySupport'));
                        hasValidate = false;
                        return false;
                    }
                });
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SalesPlansEditPage: {
            component: 'Card',
            content: { component: 'SalesPlansEditForm' },
        },
        SalesPlansEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SalesPlansEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.dimension' },
                {
                    property: 'ruleInfo.period',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'ruleInfo.subsites' },
                { property: 'ruleInfo.goods' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const goods = entity.ruleInfo.goods;
                let hasValidate = true;
                forEach(goods, (i) => {
                    if (i.stock === null) {
                        AntMessage.warning(language.getText('fillInInventory'));
                        hasValidate = false;
                        return false;
                    }
                    if (i.stock !== null && i.stock <= 0) {
                        AntMessage.warning(language.getText('inventorySupport'));
                        hasValidate = false;
                        return false;
                    }
                });
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/salesPlans',
                    component: 'SalesPlansView',
                    breadcrumbName: '<<salesPlan>>',
                    privilege: {
                        path: 'salesPlan',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'SalesPlansAddPage',
                            breadcrumbName: '<<developPlan>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'SalesPlansEditPage',
                            breadcrumbName: '<<editPlan>>',
                        },
                        { path: '/', component: 'SalesPlansPage' },
                    ],
                },
            ],
        },
    ],
};
