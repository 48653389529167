import React, { PureComponent } from 'react';
import { map, forEach, isEqual, isEmpty } from 'lodash';
import { VirtualProductSelectorDisplay, VirtualProductSelector } from '../../components';

export class SelectVirtualProduct extends PureComponent<
    any,
    {
        value: Array<any>;
    }
> {
    state = { value: new Array<any>() };

    componentWillReceiveProps(nextProps: any) {
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (
            !isEqual(nextSubsiteIds, currentSubsiteIds) &&
            nextSubsiteIds &&
            !isEmpty(nextProps.value)
        ) {
            this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }
        if (
            !isEqual(nextSubsiteIds, currentSubsiteIds) &&
            !nextSubsiteIds &&
            !isEmpty(nextProps.value)
        ) {
            this.onChange([]);
        }
    }

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        forEach(value, (i) => {
            if (subsiteIds.indexOf(i.subsiteId + '') > -1) {
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds = row && row.subsiteId ? map(row.subsiteId, 'id') : null;
        return subsiteIds;
    };

    /**
     * 选中虚拟产品变更
     * @param selectValues
     */
    onChange = (selectValues: any[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                forEach(selectValues, (i) => {
                    i.profit = (i.groupBuyPrice - i.groupPrice).toFixed(2);
                });
                onChange(selectValues);
            }
        }
    };

    /**
     * 选择虚拟商品
     * @param values
     */
    onSelect = (values: any[]) => {
        const data = this.processSelectedData(values);
        this.onChange(data);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        productId: product.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteId: goods.subsiteId + '',
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        salesPrice: (+goods.salesPrice.value).toFixed(2),
                        goodsDeductPoint: goods.points.value,
                        price: {
                            points: undefined,
                            salesPrice: undefined,
                        },
                        goodsStock: goods.stock,
                        apportionPrices:
                            product.apportionPrices &&
                            product.apportionPrices.map((item: any) => {
                                return {
                                    ...item,
                                    price: {
                                        apportionPoints: undefined,
                                        apportionPrice: undefined,
                                    },
                                };
                            }),
                        virtualProductCouponDefinitionId: product.virtualProductCouponDefinitionId,
                        virtualProductCouponDefinitionType:
                            product.virtualProductCouponDefinitionType,
                        limitNumber: goods.stock && goods.stock > 0 ? goods.stock : 0,
                    });
                });
            });
        }
        return items;
    };

    render() {
        const { value, disabled, getParams } = this.props;
        const hasValue = value && value.length > 0;
        const subsiteIds = this.getSubsiteIds(this.props);
        let params = { subsiteIds };
        if (getParams && typeof getParams === 'function') {
            params = getParams(this.props);
        }
        return (
            <div>
                {!disabled && (
                    <VirtualProductSelector
                        onChange={this.onSelect}
                        // selectValues={value}
                        rowSelectionType="radio"
                        params={params}
                    />
                )}
                {hasValue && (
                    <VirtualProductSelectorDisplay
                        data={value}
                        onChange={this.onChange}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
}
