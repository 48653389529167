import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions, services } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';

const { getText } = services.language;

let SELECTED_ROW: any = [];

const getConfig = (componentName: string) => {
    return {
        component: 'Card',
        entity: 'BargainActivityEntity',
        componentId: componentName,
        params: {
            subsiteIds: '',
        },
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'executeStatus',
                            controlConfig: {
                                style: { width: 200 },
                            },
                        },
                        {
                            property: 'name',
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                    },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    rowSelection: {
                        type: 'radio',
                        onChange: () => {},
                        onSelect: function() {
                            SELECTED_ROW = arguments[2];
                        },
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: getText('notStarted'),
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: getText('inprogress'),
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: getText('end'),
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicComponent(contentConfig: any, componentName: any, subsiteIds?: string[]) {
    const config = defaultsDeep(contentConfig, getConfig(componentName));
    set(config, 'params.subsiteIds', subsiteIds);
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
        onSelectAll: function() {
            SELECTED_ROW = arguments[2];
        },
    });
    return ComponentFactory(componentName, config);
}

function generateDynamicComponentName() {
    return 'DesignBargainActivityEntitySelector-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

/**
 * 选择盲盒活动
 */
export class SelectBargain extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    onChange: (data: any) => void;
    subsiteIds?: string[];
    onCancel: () => void;
}> {
    componentName = '';
    renderComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { contentConfig, subsiteIds } = props;
        this.componentName = generateDynamicComponentName();
        const dynamicComponent = initDynamicComponent(
            contentConfig,
            this.componentName,
            subsiteIds
        );
        this.renderComponent = createElement(dynamicComponent);
        this.dispatch = builder.getStore().dispatch;
    }
    componentWillReceiveProps(nextProps: any) {
        const { subsiteIds } = this.props;
        if (nextProps?.subsiteIds?.join(',') !== subsiteIds?.join(',')) {
            const { contentConfig, subsiteIds } = nextProps;
            this.componentName = generateDynamicComponentName();
            const dynamicComponent = initDynamicComponent(
                contentConfig,
                this.componentName,
                subsiteIds
            );
            this.renderComponent = createElement(dynamicComponent);
        }
    }
    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(services.language.getText('qxzhd'));
            return;
        }

        const { onChange } = this.props;

        onChange(SELECTED_ROW);
        SELECTED_ROW = [];
        this.dispatch(actions.tableRowSelectionChangeAction(this.componentName + '-table', []));
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 800,
                visible: showModal,
                title: getText('xzhd'),
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderComponent}</Modal>;
    }
}
