import React from 'react';
import { Input } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const CustomBalanceCouponTitle: React.FC<any> = (props) => {
    const { value, onChange } = props;
    const handleChange = (value: string) => {
        onChange({
            title: value,
        });
    };
    return (
        <div className="custom-balance-coupon-title-form">
            <Input
                maxLength={6}
                placeholder={language.getText('lbbt')}
                value={value?.linkParams?.title || language.getText('yeq')}
                onChange={(e) => handleChange(e.target.value)}
            />
        </div>
    );
};

export default CustomBalanceCouponTitle;
