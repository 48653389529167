import { BaseConfig } from '../interfaces';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
export let config: BaseConfig = {
    entities: {
        merchantRoleManage: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
            apiPath: '/loader/admin/merchant-roles',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '角色名',
                    rules: [{ required: true, whitespace: true, message: '请输入角色名' }],
                    controlConfig: {
                        placeholder: '请输入角色名',
                        maxLength: 100,
                    },
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '角色状态',
                    defaultValue: 'NORMAL',
                    options: [
                        {
                            id: 'NORMAL',
                            name: '启用',
                        },
                        {
                            id: 'ABNORMAL',
                            name: '禁用',
                        },
                    ],
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<createTimeStr>>',
                },
                createUserName: { type: 'string', displayName: '创建人' },
                remark: {
                    type: 'string.introduction',
                    displayName: '角色描述',
                    rules: [{ max: 500 }],
                    controlConfig: {
                        placeholder: '请输入角色描述',
                        maxLength: 500,
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '角色名',
                    controlConfig: { placeholder: '请输入角色名' },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '状态',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '全部',
                        },
                        {
                            id: 'NORMAL',
                            name: '启用',
                        },
                        {
                            id: 'ABNORMAL',
                            name: '禁用',
                        },
                    ],
                },
            },
        },
    },
    components: {
        MerchantRoleView: {
            component: 'Viewport',
            entity: 'merchantRoleManage',
        },
        MerchantRoleManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MerchantRoleManageFilter',
                },
                { component: 'MerchantRoleManageTable' },
            ],
        },
        MerchantRoleManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
            ],
        },
        MerchantRoleManageTable: { component: 'MerchantRoleManageList' },
        MerchantPrivilegeManagementPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MerchantPrivilegeManagement' }],
        },
        MerchantRoleEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MerchantRoleEditForm' }],
        },
        MerchantRoleEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'roleManage',
            labelCol: 6,
            controlCol: 10,
            style: { width: 720 },
            fields: [
                { property: 'name', controlConfig: { disabled: true } },
                { property: 'remark' },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantRoleAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MerchantRoleAddForm' }],
        },
        MerchantRoleAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            entity: 'roleManage',
            labelCol: 6,
            controlCol: 10,
            style: { width: 720 },
            fields: [{ property: 'name' }, { property: 'status' }, { property: 'remark' }],
            submit: true,
            footer: {
                items: [
                    // {
                    //     htmlType: 'button',
                    //     text: '提交并分配权限',
                    //     type: 'primary',
                    //     style: { marginLeft: 10 },
                    // },
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success('角色新增成功', () => services.behaviorHandle({ route: 'goBack' }));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-role-manage',
                    breadcrumbName: '管理角色',
                    category: '系统设置',
                    component: 'MerchantRoleView',
                    privilege: {
                        path: 'merchantRole',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/merchant-role-add',
                            component: 'MerchantRoleAddPage',
                            breadcrumbName: '新增角色',
                        },
                        {
                            path: '/merchant-role-edit/:id',
                            component: 'MerchantRoleEditPage',
                            breadcrumbName: '编辑角色',
                        },
                        {
                            path: '/privilege-management/:id/:name',
                            component: 'MerchantPrivilegeManagementPage',
                            breadcrumbName: '分配权限',
                            title: '分配权限 【{{name}}】',
                        },
                        { path: '/', component: 'MerchantRoleManagePage' },
                    ],
                },
            ],
        },
    ],
};
