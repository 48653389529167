import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { TaxRateNumber } from './tax-rate-number';

export class NumberTaxMode extends modes.NumberIntegerMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <TaxRateNumber {...displayInfo} />;
    }
}
