import React, { PureComponent, Fragment } from 'react';
import { List } from 'antd';
import { localStorage } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';
import Password from './password';
type Unpacked<T> = T extends (infer U)[] ? U : T;

const passwordStrength = {
    STRONG: <span className="strong">强</span>,
    MIDDLE: <span className="medium">中</span>,
    WEAK: <span className="weak">弱</span>,
};

const DefaultComponent = ({ click }: { click: (type: string) => void }) => {
    const userInfo = localStorage.get('USER_INFO');
    const passwordType = get(
        passwordStrength,
        get(userInfo, 'passwordStrengthName', 'WEAK'),
        passwordStrength['WEAK']
    );
    const getData = () => [
        {
            title: '账户密码',
            description: <Fragment>当前密码强度： {passwordType}</Fragment>,
            actions: [
                <span className="ant-btn-link" key="Modify" onClick={() => click('password')}>
                    修改
                </span>,
            ],
        },
    ];
    const data = getData();
    return (
        <Fragment>
            <div className="title">安全设置</div>
            <List<Unpacked<typeof data>>
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item actions={item.actions}>
                        <List.Item.Meta title={item.title} description={item.description} />
                    </List.Item>
                )}
            />
        </Fragment>
    );
};
export default class SecurityView extends PureComponent<any, any> {
    state = {
        component: 'default',
    };
    changeComponent = (type: string) => {
        this.setState({ component: type });
    };
    render() {
        const { component } = this.state;
        switch (component) {
            case 'default':
                return <DefaultComponent click={this.changeComponent} />;
            case 'password':
                return <Password click={this.changeComponent} />;
            default:
        }
    }
}
