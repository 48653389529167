type check = 'weak' | 'strong' | 'middle';
export const checkPassword: (value: string) => check = (value: string) => {
    const len = value.length; //长度
    const num = /\d/.test(value); //匹配数字
    const letter = /[a-zA-Z]/.test(value); //匹配小大写字母
    const corps = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/.test(
        value
    ); //特殊符号
    const reg = Number(num) + Number(letter) + Number(corps);
    if (len > 7) {
        if (reg === 1) {
            return 'weak';
        } else if (reg === 2) {
            return 'middle';
        } else {
            return 'strong';
        }
    } else {
        return 'weak';
    }
};
export const passwrodReg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$)[\da-zA-Z`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{8,16}$/;
export const newPasswrodReg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$)[\da-zA-Z`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{8,40}$/;
export const getUrlParam = (url: string, name: string) => {
    try {
        const urlParams: any = new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(url);
        return decodeURIComponent((urlParams[1] || '').replace(/\+/g, '%20'));
    } catch (e) {
        return null;
    }
};
