import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage, message } from 'antd';
import { TipsModalContentType } from '../../../components';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';

export const config: Config = {
    entities: {
        StoredValueCardSettingEntity: {
            apiPath: '/loader/admin/stored_value_card_settting',
            properties: {
                showCardPayCode: {
                    type: 'string.options.radio',
                    displayName: '<<fkmzsfs>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'false', name: '<<zhfkm>>' },
                        { id: 'true', name: '<<kfkm>>' },
                    ],
                    rules: [{ required: true }],
                },
                showTransfer: {
                    type: 'string.options.radio',
                    displayName: '<<sfzczz>>',
                    defaultValue: 'PERIOD',
                    options: [
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                    rules: [{ required: true }],
                },
                enablePStoredValueCard: {
                    type: 'string.options.radio',
                    displayName: '<<sfqystkgm>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                    rules: [{ required: true }],
                },
                pStoredValueCardAlias: {
                    type: 'string',
                    displayName: '<<stkbm>>',
                    controlConfig: {
                        placeholder: '<<h_mk>>',
                        style: {
                            width: 150,
                        },
                        maxLength: 4,
                    },
                },
                pStoredValueCardDescription: {
                    className: 'stored-value-card-description',
                    type: 'string',
                    displayName: '<<ms>>',
                    controlConfig: {
                        placeholder: '<<h_stkxxztjsy>>',
                        style: {
                            width: 300,
                        },
                        maxLength: 40,
                    },
                },
                eStoredValueCardAlias: {
                    className: 'stored-value-card-alias',
                    type: 'string',
                    displayName: '<<dzkbm>>',
                    controlConfig: {
                        placeholder: 'E<<h_mk>>',
                        style: {
                            width: 150,
                        },
                        maxLength: 4,
                    },
                },
                eStoredValueCardDescription: {
                    className: 'stored-value-card-description',
                    type: 'string',
                    displayName: '<<ms>>',
                    controlConfig: {
                        placeholder: '<<h_dzkxxxssy>>',
                        style: {
                            width: 300,
                        },
                        maxLength: 40,
                    },
                },
                rechargeAmountLowerLimit: {
                    className: 'stored-value-card-alias',
                    type: 'number.tip',
                    displayName: '<<dcczxx>>',
                    rules: [
                        {
                            type: 'number',
                            min: 1,
                            max: 999999,
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (
                                    value !== undefined &&
                                    value !== null &&
                                    (!reg.test(value) || value === 0)
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        placeholder: '10',
                        style: {
                            width: 150,
                        },
                        addonbefore: '',
                        addonafter: '',
                        tip: '',
                    },
                },
                rechargeAmountUpperLimit: {
                    className: 'stored-value-card-description-limit',
                    type: 'number.tip',
                    displayName: '<<sx_2>>',
                    rules: [
                        {
                            type: 'number',
                            min: 1,
                            max: 999999,
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (
                                    value !== undefined &&
                                    value !== null &&
                                    (!reg.test(value) || value === 0)
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        placeholder: '1000',
                        style: {
                            width: 150,
                        },
                        addonbefore: '',
                        addonafter: '',
                        tip: '',
                    },
                },
                enableCertification: {
                    className: 'stored-value-card-alias',
                    type: 'string.options.radio',
                    displayName: '<<sfqysmrz>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                    rules: [{ required: true }],
                },
                link: {
                    type: 'string.link',
                    displayName: '<<qwpz>>',
                    controlConfig: {
                        linkName: `"<<smrzfwxy>>"`,
                        linkUrl: '#/app-section',
                    },
                },
                certificationAmount: {
                    type: 'number.tip',
                    displayName: '<<smrzxmzje>>',
                    rules: [
                        {
                            required: true,
                            type: 'number',
                            min: 0,
                            precision: 2,
                            message: '<<priceErrorMessage>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                if (value && Number(value) >= 0.01 && reg.test(value)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                        addonafter: '',
                        tip: '',
                    },
                    extra: '<<h_smrzxmzje_tip>>',
                },
                supportInvoiceMail: {
                    className: 'stored-value-card-alias',
                    type: 'string.options.radio',
                    displayName: '<<sfzcfpyj>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                    rules: [{ required: true }],
                },
            },
        },
        StoredValueCardSettingSubsitePickUpDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/stored_value_card_setting_subsite_pick_up_delivery',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztdxx>>',
                    properties: {
                        subsite: {
                            type: 'string.options.select',
                            displayName: '<<subsites>>',
                            rules: [{ required: true }],
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        address: {
                            type: 'string.tip',
                            displayName: '<<ztdz>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxztdxxdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qtxztdxxdz>>',
                                minLength: 5,
                                maxLength: 50,
                            },
                        },
                        time: {
                            type: 'string',
                            displayName: '<<ztsjsm>>',
                            controlConfig: {
                                placeholder: '<<qtxztsjsm>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        reminder: {
                            type: 'string.modalTips',
                            displayName: '<<wxts>>',
                            controlConfig: {
                                placeholder: '<<jytxrqnsdztdxhddzte>>',
                                style: {
                                    width: 450,
                                },
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.DeliveryReminder,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                            },
                            extra: '<<reminderExtra>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<ztdz>>',
                },
                time: {
                    type: 'string',
                    displayName: '<<ztsj>>',
                },
                reminder: {
                    type: 'string',
                    displayName: '<<wxts>>',
                },
            },
        },
    },
    components: {
        StoredValueCardSettingView: {
            component: 'Viewport',
        },
        StoredValueCardSettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 32px',
            },
            items: [{ component: 'StoredValueCardSettingTabs' }],
        },
        StoredValueCardSettingTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<jccspz>>',
                    content: {
                        component: 'StoredValueCardSettingPageTab',
                    },
                },
                {
                    title: '<<ztdpz>>',
                    content: {
                        component: 'StoredValueCardSettingSubsitePickUpDeliveryPage',
                    },
                },
            ],
        },
        StoredValueCardSettingPageTab: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'StoredValueCardSettingForm' }],
        },
        StoredValueCardSettingForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardSettingEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: {
                height: '480px',
            },
            fields: [
                {
                    property: 'showCardPayCode',
                },
                {
                    property: 'showTransfer',
                },
                {
                    property: 'enablePStoredValueCard',
                },
                {
                    property: 'pStoredValueCardAlias',
                },
                {
                    property: 'pStoredValueCardDescription',
                },
                {
                    property: 'eStoredValueCardAlias',
                },
                {
                    property: 'eStoredValueCardDescription',
                },
                {
                    property: 'rechargeAmountLowerLimit',
                },
                {
                    property: 'rechargeAmountUpperLimit',
                },
                // {
                //     property: 'afterSale',
                // },
                // {
                //     property: 'wholeAfterSale',
                // },
                {
                    property: 'enableCertification',
                },
                {
                    property: 'link',
                    visible: (values: any) => get(values, 'enableCertification') === 'true',
                },
                {
                    property: 'certificationAmount',
                    visible: (values: any) => get(values, 'enableCertification') === 'true',
                },
                {
                    property: 'supportInvoiceMail',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onValidate: (entity: any) => {
                const rechargeAmountLowerLimit = entity.rechargeAmountLowerLimit;
                const rechargeAmountUpperLimit = entity.rechargeAmountUpperLimit;
                if (
                    (rechargeAmountLowerLimit && !rechargeAmountUpperLimit) ||
                    (!rechargeAmountLowerLimit && rechargeAmountUpperLimit)
                ) {
                    message.warning(services.language.getText('qszdysx'));
                    return false;
                }
                if (
                    rechargeAmountUpperLimit &&
                    rechargeAmountLowerLimit &&
                    rechargeAmountUpperLimit <= rechargeAmountLowerLimit
                ) {
                    message.warning(services.language.getText('sxxdyxx'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'));
            },
        },
        StoredValueCardSettingSubsitePickUpDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'StoredValueCardSettingSubsitePickUpDeliveryEntity',
            items: [
                {
                    component: 'StoredValueCardSettingSubsitePickUpDeliveryFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdztpz_1>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/stored-value-card-setting/add-pick-up',
                                },
                            ],
                        },
                    ],
                },
                { component: 'StoredValueCardSettingSubsitePickUpDeliveryTable' },
            ],
        },
        StoredValueCardSettingSubsitePickUpDeliveryFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
            ],
        },
        StoredValueCardSettingSubsitePickUpDeliveryTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'address',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'time',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'reminder',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/stored-value-card-setting/edit-pick-up/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        StoredValueCardSettingSubsitePickUpDeliveryAddPage: {
            component: 'Card',
            content: { component: 'StoredValueCardSettingSubsitePickUpDeliveryAddForm' },
        },
        StoredValueCardSettingSubsitePickUpDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardSettingSubsitePickUpDeliveryEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                // { property: 'baseInfo.name' },
                { property: 'baseInfo.subsite' },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.time' },
                { property: 'baseInfo.reminder' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardSettingSubsitePickUpDeliveryEditPage: {
            component: 'Card',
            content: { component: 'StoredValueCardSettingSubsitePickUpDeliveryEditForm' },
        },
        StoredValueCardSettingSubsitePickUpDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardSettingSubsitePickUpDeliveryEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                // { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.subsite',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 300,
                        },
                    },
                },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.time' },
                { property: 'baseInfo.reminder' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/stored-value-card-setting',
                    component: 'StoredValueCardSettingView',
                    breadcrumbName: '<<storedValueCardParamConfig>>',
                    privilege: {
                        path: 'storedValueCardParamConfig',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-pick-up',
                            component: 'StoredValueCardSettingSubsitePickUpDeliveryAddPage',
                            breadcrumbName: '<<xzztd>>',
                        },
                        {
                            path: '/edit-pick-up/:id',
                            component: 'StoredValueCardSettingSubsitePickUpDeliveryEditPage',
                            breadcrumbName: '<<bjztd>>',
                        },
                        { path: '/', component: 'StoredValueCardSettingPage' },
                    ],
                },
            ],
        },
    ],
};
