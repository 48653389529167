import React, { Component } from 'react';
import { Radio, DatePicker } from 'antd';
import moment from 'moment';
import { services } from '@comall-backend-builder/core';

interface CreditShopNoticeValue {
    type: String;
    time: string;
}
interface CreditShopNoticeProps {
    /**
     * 选中值
     */
    value: CreditShopNoticeValue;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: CreditShopNoticeValue) => void;
}

export class CreditShopNotice extends Component<CreditShopNoticeProps> {
    onTimeChange = (val: any) => {
        const { onChange } = this.props;

        const timeStr = moment(val || undefined).format('YYYY-MM-DD HH:mm:ss');
        onChange && onChange({ time: timeStr, type: 'MANUAL_TIME' });
    };

    onTypeChange = (e: any) => {
        const { value, onChange } = this.props;
        onChange && onChange({ time: value && value.time ? value.time : '', type: e.target.value });
    };
    render() {
        const { value } = this.props;
        const itemStyle = { padding: '5px 0' };

        return (
            <div>
                <Radio.Group
                    value={value ? value.type : 'NOT_REMAIN'}
                    defaultValue={'NOT_REMAIN'}
                    onChange={this.onTypeChange}
                >
                    <div style={itemStyle}>
                        <Radio value={'CREATE_AND_ENABLED'}>
                            {services.language.getText('hdcjbkqh')}
                        </Radio>
                    </div>
                    <div style={itemStyle}>
                        <Radio value={'MANUAL_TIME'}>
                            <span>{services.language.getText('hdksygsj')}</span>
                            <DatePicker
                                style={{ marginLeft: '20px' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                value={value && value.time ? moment(value.time) : undefined}
                                onChange={this.onTimeChange}
                                placeholder={services.language.getText('selectPlease')}
                            />
                        </Radio>
                    </div>
                    <div style={itemStyle}>
                        <Radio value={'NOT_REMAIN'}>{services.language.getText('bjxhdyg')}</Radio>
                    </div>
                </Radio.Group>
            </div>
        );
    }
}
