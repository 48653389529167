import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const ContentComplainLoader: Loader = {
    get: async function(params, config: ApiRequestConfig) {
        if (!params.id) {
            const { complainTime } = params;
            if (complainTime) {
                params.startTime = complainTime.start;
                params.endTime = complainTime.end;
            }
            delete params.complainTime;
        }
        const result: any = await api.get(params, config);

        if (params.id) {
            result.complainContent = result.complainContent ? result.complainContent : '';
            result.auditRemark = result.auditRemark ? result.auditRemark : '';
            if (result.contentType === 'NOTE') {
                result.complainDetail = {
                    content: result.noteInfo,
                    type: result.contentType,
                };
            } else {
                result.complainDetail = {
                    content: result.complainContent,
                    type: result.contentType,
                };
            }
        } else {
            result.result.forEach((item: any) => {
                item.complainContent = item.complainContent ? item.complainContent : '';
                item.showBlacklist = !item.blacklistUser;
            });
        }
        return result;
    },
};
