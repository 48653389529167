import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, find } from 'lodash';

const api = services.api;

export const WatchwordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/watchword_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/watchword_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
            if (data.subsiteName) {
                data.subSiteIds = data.subsiteName?.id;
                delete data.subsiteName;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                let subsites = [];
                if (res.normalConditions && res.normalConditions.length) {
                    const normalConditionsSubsites = find(res.normalConditions, {
                        normalConditionType: 'SUB_SITE',
                    });
                    subsites = normalConditionsSubsites ? normalConditionsSubsites.includeIds : [];
                }
                if (res.couponReward) {
                    res.couponReward.id = res.couponReward.couponDefinitionId?.toString() || ''; //用于组件去重;
                }
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.rulesInfo = {
                    watchword: res.watchword,
                    couponReward: [res.couponReward],
                    periodQuantity: res.couponReward?.rewardPeriod?.periodQuantity,
                    memberRewardLimit: res.couponReward?.rewardPeriod?.memberRewardLimit,
                    watchwordEntrance: res.watchwordEntrance,
                };

                res.pageRule = {
                    activityBackgroundPicture: [
                        {
                            id: res.activityBackgroundPicture?.id,
                            path: res.activityBackgroundPicture?.url,
                        },
                    ],
                    buttonBackgroundPicture: [
                        {
                            id: res.buttonBackgroundPicture?.id
                                ? res.buttonBackgroundPicture?.id
                                : 0,
                            path: res.buttonBackgroundPicture?.url
                                ? res.buttonBackgroundPicture?.url
                                : 'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/watchword/btn-default.png',
                        },
                    ],
                    description: res.description,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/watchword_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/watchword_activities/${id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

function dataParser(params: any) {
    const newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
        newParams.subsiteIds = params.baseInfo.subsites?.map((item: any) => ({
            id: item.id,
            name: item.name,
        }));
    }
    if (params.rulesInfo) {
        newParams.couponReward = {
            count:
                params.rulesInfo.couponReward &&
                params.rulesInfo.couponReward.length &&
                params.rulesInfo.couponReward[0].count,
            couponDefinitionId:
                params.rulesInfo.couponReward &&
                params.rulesInfo.couponReward.length &&
                params.rulesInfo.couponReward[0].couponDefinitionId,

            rewardPeriod: {
                periodQuantity:
                    params.rulesInfo.couponReward &&
                    params.rulesInfo.couponReward.length &&
                    params.rulesInfo.couponReward[0].rewardPeriod?.periodQuantity,
                memberRewardLimit: params.rulesInfo.memberRewardLimit,
                period: 'ACTIVITY',
            },
        };
        newParams.watchword = params.rulesInfo.watchword;
        newParams.watchwordEntrance = params.rulesInfo.watchwordEntrance;
    }
    if (params.pageRule) {
        newParams.activityBackgroundPictureId = params.pageRule.activityBackgroundPicture[0].id;
        newParams.buttonBackgroundPictureId = params.pageRule.buttonBackgroundPicture[0].id;
        newParams.description = params.pageRule.description;
    }
    newParams.marketingScheduleId =
        (params.baseInfo?.marketingSchedule?.length && params.baseInfo.marketingSchedule[0].id) ||
        null;
    return newParams;
}
