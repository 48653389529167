import React, { PureComponent } from 'react';
import { find, remove, cloneDeep } from 'lodash';
import { Button, InputNumber, message as AntMessage, Form, Popconfirm } from 'antd';
import { language, privilege } from '@comall-backend-builder/core/lib/services';
import { CouponSelector, CouponType, EventCouponValueTypeEnum } from './select-coupon';
import { services } from '@comall-backend-builder/core';

interface PaymentRewardsProps {
    onChange: (value: any) => void;
    value: Array<PaymentReward> | undefined;
    row: { [key: string]: any };
    extra?: string;
    columns: Array<any> | [];
    initData: any;
    canShowLeftStock?: boolean;
}
interface PaymentReward {
    id: number;
    totalMoneyMin: number | undefined;
    totalMoneyMax: number | undefined;
    coupons: Array<Coupon>;
}
interface Coupon {
    id: string;
    type: string;
    name: number;
    startTime: string;
    endTime: string;
    batchNo: string;
    couponTypeDes: string;
    cooperationPatternDesc: string;
    quantity: number;
    stock: number;
    apportionPrice?: number;
    leftStock?: number;
}
//金额下限totalMoneyMin 上限totalMoneyMax 优惠券数据coupons
const defaultPaymentReward = [{ id: 0, totalMoneyMin: '', totalMoneyMax: '', coupons: [] }];

export class PaymentRewards extends PureComponent<PaymentRewardsProps, any> {
    CouponSelector: any;
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
            rewardIndex: 0,
            displayType: '',
            filterCouponParams: null,
            goNextpage: null,
        };
    }
    componentDidMount() {
        const { value } = this.props;
        this.setState({
            data: value && value.length > 0 ? value : cloneDeep(defaultPaymentReward).slice(0),
            rewardIndex: value && value.length > 0 ? value.length - 1 : 0,
        });
    }
    componentWillReceiveProps(nextProps: any) {
        const oldData = JSON.stringify(this.props.value);
        const newData = JSON.stringify(nextProps.value);
        if (oldData !== newData) {
            this.setState({ data: nextProps.value });
        }
        const filterCouponParams = {
            subsiteIds: nextProps.row.baseInfo?.subsiteId
                ? [nextProps.row.baseInfo.subsiteId]
                : null,
        };
        this.setState({ filterCouponParams });
    }
    showModel = (ref: any) => {
        this.CouponSelector = ref;
    };

    setDisplayType = (row: Coupon[]) => {
        let displayType = 'selectCoupon';
        if (row.length && row[0]?.type === 'PACKAGE') {
            displayType = 'selectCouponPackage';
        }
        return displayType;
    };

    modalToCoupon = (item: any) => {
        return {
            id: item.id,
            type: item.couponType || item.type,
            name: item.name,
            startTime: item.startTime,
            endTime: item.endTime,
            startDate: item.startDate,
            endDate: item.endDate,
            batchNo: item.code || item.batchNo,
            couponTypeDes: item.typeName || item.couponTypeDes,
            cooperationPatternDesc:
                (item.cooperationPattern && item.cooperationPattern.description) ||
                item.cooperationPatternDesc,
            consumeChannelDescription: item.consumeChannelDescription,
            quantity: item.quantity || 1,
            stock: item.stock,
            leftStock: item.leftStock,
            couponValue: item.couponValue,
            consumeRule: item.consumeRule,
        };
    };
    onRewardIndex = (rewardIndex: number) => {
        this.setState(
            {
                rewardIndex: rewardIndex,
            },
            () => {
                this.CouponSelector.toggleModal();
            }
        );
    };
    changeCoupons = (coupons: Array<any>, type: string) => {
        //历史-支持普通券多个，新需求：只能选择保存一个
        // const { data, rewardIndex } = this.state;
        // let newData = data ? data : [];
        // let row: any = newData[rewardIndex];
        // let newCoupons: any = row.coupons ? [...row.coupons] : [];
        // if (this.setDisplayType(newCoupons) !== type) {
        //     newCoupons = [];
        // }
        // if (type === 'selectCoupon') {
        //     coupons.forEach((selectCuopon: any) => {
        //         const hasIndex = findIndex(
        //             newCoupons,
        //             (coupon: any) => toString(coupon.id) === toString(selectCuopon.id)
        //         );
        //         if (hasIndex === -1) {
        //             newCoupons.push(selectCuopon);
        //         }
        //     });
        // } else {
        //     newCoupons = [...coupons];
        // }
        // row.coupons = newCoupons.map(this.modalToCoupon);
        // this.props.onChange(newData);
        //支持单个选择时
        const { data, rewardIndex } = this.state;
        const newData = data ? data : [];
        const row: any = newData[rewardIndex];
        const newCoupons: any = [...coupons];
        row.coupons = newCoupons.map(this.modalToCoupon);
        this.props.onChange(newData);
    };

    changeLowerValue = (index: number, value: any, name: any) => {
        const { data } = this.state;
        let newData = data ? data : [];
        let row: any = newData[index];
        if (row) {
            const reg = /(^[0-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
            if (value <= 0) {
                AntMessage.warning(services.language.getText('inputThanZero'));
                return false;
            }
            if (!reg.test(value)) {
                AntMessage.warning(services.language.getText('jezdzclwxs'));
                return false;
            }
            row[name] = value;
            this.props.onChange(newData);
        }
    };

    addPaymentReward = () => {
        const { data, rewardIndex } = this.state;
        let newData = data ? data : [];
        const index = rewardIndex + 1;
        this.setState(
            {
                rewardIndex: index,
            },
            () => {
                newData.push({ ...this.props.initData, id: undefined });
                this.props.onChange(newData);
            }
        );
    };
    deleteCoupon = (rewardIndex: number) => {
        const { data } = this.state;
        const newData = [...data];
        newData.splice(rewardIndex, 1);
        this.props.onChange(newData);
    };
    changeStock = (rewardIndex: number, item: Coupon, value: number) => {
        const { data } = this.state;
        const newData = [...data];
        let rowDate: any = newData[rewardIndex];
        let rowItem: any = find(rowDate.coupons, { id: item.id });

        if (rowItem) {
            rowItem.quantity = value;
            this.props.onChange(newData);
        }
    };
    deleteCouponItem = (rewardIndex: number, item: Coupon) => {
        const { data } = this.state;
        const newData = [...data];
        let rowDate: any = newData[rewardIndex];
        let rowItem: any = find(rowDate.coupons, { id: item.id });

        if (rowItem) {
            remove(rowDate.coupons, function(rowItemCoupon: Coupon) {
                return rowItemCoupon.id === item.id;
            });
            this.props.onChange(newData);
        }
    };
    CheckCoupon = (item: Coupon) => {
        const { id } = item;
        let hasPrivilege = true;
        let route = '';
        const baseUrl = '#/menu-list/coupon';
        switch (item.type) {
            case CouponType.PACKAGE:
                route = `${baseUrl}/coupon-package-rules/edit/${id}`;
                hasPrivilege = privilege.check('couponPackageRule', 'full');
                break;
            case CouponType.CASH:
                route = `${baseUrl}/cash-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('cashCouponRule', 'full');
                break;
            case CouponType.GIFT:
                route = `${baseUrl}/gift-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('giftCouponRule', 'full');
                break;
            case CouponType.FREIGHT:
                route = `${baseUrl}/freight-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('freightCouponRule', 'full');
                break;
            case CouponType.PARKING:
                route = `${baseUrl}/parking-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('parkingCouponRule', 'full');
                break;
            case CouponType.LOTTERY:
                route = `${baseUrl}/lottery-coupon/edit/${id}`;
                hasPrivilege = privilege.check('lotteryCouponRule', 'full');
                break;
            case CouponType.BALANCE:
                route = `${baseUrl}/balance-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('balanceCouponRule', 'full');
                break;

            default:
                break;
        }
        if (hasPrivilege && route) {
            window.open(`${route}`, '_blank');
        } else {
            this.setState({
                goNextpage: {
                    id,
                    hasPrivilege,
                },
            });
        }
    };
    renderValue = (coupon: any) => {
        const { couponValue, type } = coupon;
        const couponRuleType = type;

        if (couponRuleType === CouponType.GIFT) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponRuleType === CouponType.LOTTERY) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                </div>
            );
        } else if (couponRuleType === CouponType.PACKAGE) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('gifts')}
                    </span>
                </div>
            );
        } else if (
            couponRuleType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.No
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">{language.getText('qm_2')}</span>
                </div>
            );
        }
        if (!couponValue || !couponValue.value) {
            return null;
        } else if (
            type === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                    <span className="event-coupon__symbol">{language.getText('hour')}</span>
                </div>
            );
        }
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__symbol">
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className="event-coupon__price__num">{couponValue.value}</span>
            </div>
        );
    };
    renderCoupons = (coupon: any) => {
        const innerClassName = `${coupon.type}-coupon-popup__list__item`;
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };
        // const enum ConsumeCondition {
        //     /**
        //      * 无条件
        //      */
        //     NoCondition = 'NOCONDITION',
        //     /**
        //      * 金额满
        //      */
        //     Money = 'MONEY',
        //     /**
        //      * 重量满
        //      */
        //     Weight = 'WEIGHT',
        //     /**
        //      * 数量满
        //      */
        //     Quantity = 'QUANTITY',
        // }
        // let text = '';
        // if (
        //     coupon.consumeRule &&
        //     coupon.consumeRule.condition &&
        //     coupon.consumeRule.conditionLowerValue
        // ) {
        //     const conditionMapRuleText: any = {
        //         [ConsumeCondition.NoCondition]: ``,
        //         [ConsumeCondition.Money]: `满${
        //             coupon.consumeRule.conditionLowerValue
        //                 ? coupon.consumeRule.conditionLowerValue
        //                 : 0
        //         }{language.getText('yuan')}可用`,
        //         [ConsumeCondition.Quantity]: `满${
        //             coupon.consumeRule.conditionLowerValue
        //                 ? coupon.consumeRule.conditionLowerValue
        //                 : 0
        //         }件可用`,
        //     };
        //     text = conditionMapRuleText[coupon.consumeRule.condition];
        // }

        return (
            <div className={innerClassName}>
                <div className="event-coupon" style={style}>
                    <div className="event-coupon__left">
                        {this.renderValue(coupon)}
                        {/* {text && <div className="event-coupon__rule">{text}</div>} */}
                    </div>
                    <div className="event-coupon__right">
                        <div className="event-coupon__right__title">{coupon.name}</div>
                        <div className="event-coupon__right__time">
                            {coupon.startDate}-{coupon.endDate}
                        </div>
                        {/* {coupon.quantity && (
                            <div className="event-coupon__right__quantity">x{coupon.quantity}</div>
                        )} */}
                    </div>
                </div>
            </div>
        );
    };

    showCouponItem = (row: any, rewardIndex: number) => {
        if (row.coupons.length) {
            const { goNextpage } = this.state;
            return row.coupons.map((item: Coupon, index: number) => {
                const unitTxt =
                    item.type === CouponType.PACKAGE
                        ? services.language.getText('ge')
                        : services.language.getText('zhang');
                return (
                    <div
                        key={item.id}
                        style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
                    >
                        <div>{this.renderCoupons(item)}</div>
                        <div style={{ minWidth: '150px', padding: '0 20px' }}>
                            {services.language.getText('stock')}：{item.leftStock}
                        </div>
                        <div style={{ width: '205px', flexShrink: 0 }}>
                            {services.language.getText('mrmcff')}：
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={item.quantity}
                                min={1}
                                max={999999999}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeStock(rewardIndex, item, value)
                                }
                            ></InputNumber>
                            {unitTxt}
                        </div>
                        <Popconfirm
                            title={`${language.getText('qrsc')}${item.name}?`}
                            onConfirm={() => this.deleteCouponItem(rewardIndex, item)}
                            okText={language.getText('common.confirm')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="link">{language.getText('common.delete')}</Button>
                        </Popconfirm>

                        <Button type="link" onClick={() => this.CheckCoupon(item)}>
                            {services.language.getText('ckqgz')}
                        </Button>
                        {goNextpage && !goNextpage.hasPrivilege && goNextpage.id === item.id && (
                            <div style={{ color: 'red', flexShrink: 0 }}>
                                {services.language.getText('zwckqgzqx')}
                            </div>
                        )}
                    </div>
                );
            });
        }
    };
    getItem = () => {
        const { data } = this.state;
        if (!data) return;
        const formItemLayout = {
            labelCol: {
                xs: { span: 6 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 21 },
            },
        };

        return data.map((item: PaymentReward, rewardIndex: number) => (
            <div key={item.id} style={{ minWidth: '530px', position: 'relative' }}>
                <Form {...formItemLayout} labelAlign="left">
                    <Form.Item
                        label={`${language.getText('jt')}${rewardIndex + 1}`}
                        colon={false}
                    ></Form.Item>
                    <Form.Item label={language.getText('jexz')} hasFeedback>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={item.totalMoneyMin}
                                    min={0.01}
                                    max={999999999.99}
                                    onChange={(value: any) =>
                                        this.changeLowerValue(rewardIndex, value, 'totalMoneyMin')
                                    }
                                    style={{ width: '125px' }}
                                ></InputNumber>
                                &nbsp;&nbsp;{language.getText('yuan')}
                            </div>
                            &nbsp; —— &nbsp;
                            <div>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={item.totalMoneyMax}
                                    min={0.01}
                                    max={999999999.99}
                                    onChange={(value: any) =>
                                        this.changeLowerValue(rewardIndex, value, 'totalMoneyMax')
                                    }
                                    style={{ width: '125px' }}
                                ></InputNumber>
                                &nbsp;&nbsp;{language.getText('yuan')}
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item label={language.getText('jpxz')}>
                        <Button type={'link'} onClick={() => this.onRewardIndex(rewardIndex)}>
                            {language.getText('xzyhq')}
                        </Button>
                    </Form.Item>
                </Form>
                {this.showCouponItem(item, rewardIndex)}

                <Popconfirm
                    title={`${language.getText('qrscjt')}${rewardIndex + 1}?`}
                    onConfirm={() => this.deleteCoupon(rewardIndex)}
                    okText={language.getText('common.confirm')}
                    cancelText={language.getText('common.cancel')}
                >
                    <Button style={{ position: 'absolute', top: '10px', left: '350px' }}>
                        {services.language.getText('common.delete')}
                    </Button>
                </Popconfirm>
            </div>
        ));
    };
    changeModal = () => {
        const { filterCouponParams, data, rewardIndex } = this.state;
        if (!data || !data[rewardIndex]?.coupons) return;
        return (
            <CouponSelector
                onRef={this.showModel}
                selectValues={data[rewardIndex].coupons}
                rewardIndex={rewardIndex}
                onChange={this.changeCoupons}
                params={filterCouponParams}
            />
        );
    };
    render() {
        const { extra, value } = this.props;
        return (
            <div className="payment-rewards" style={{ overflowX: 'scroll', minWidth: '78%' }}>
                {this.getItem()}
                {!!extra && <div className="ant-form-extra">{extra}</div>}
                {value && value.length < 4 && (
                    <div style={{ marginTop: 10 }}>
                        <Button type="default" onClick={this.addPaymentReward}>
                            {language.getText('tjjtjl')}
                        </Button>
                        <span style={{ color: '#ccc' }}>
                            &nbsp;&nbsp;{language.getText('zdzcsgjt')}
                        </span>
                    </div>
                )}
                {this.changeModal()}
            </div>
        );
    }
}
