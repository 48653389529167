import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { NavigationLink } from '../../../components';

export class StringNavigationLinkMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        return <NavigationLink {...displayInfo} />;
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <NavigationLink {...controlInfo} />;
    }
}
