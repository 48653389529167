import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        ScratchCardEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/scratchCard',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                pageShowId: {
                    type: 'number',
                },
                shareConfigId: {
                    type: 'number',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<hdlx>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                        shareStatus: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<fxsz>>',
                            defaultValue: ['true'],
                            options: [
                                {
                                    id: 'true',
                                    name: '<<yxjghdfxjhy>>',
                                },
                            ],
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    collapsed: true,
                    properties: {
                        memberLevel: {
                            type: 'object.affiliateMarketing.memberLevel',
                            displayName: '<<cyyhhydj>>',
                            extra: '<<rhdsxmdwdmd>>',
                        },
                        shareReward: {
                            type: 'object.affiliateMarketing.shareReward',
                            displayName: '<<fxhdcjcs>>',
                        },
                        pointExchange: {
                            type: 'object.affiliateMarketing.pointExchange',
                            displayName: '<<jfdhcjcs>>',
                        },
                        periodLimit: {
                            type: 'object.affiliateMarketing.periodLimit',
                            displayName: '<<zjcsxz>>',
                            extra: '<<jpbkjfhyhq>>',
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<hdbz>>',
                            rules: [
                                {
                                    whitespace: true,
                                    max: 500,
                                    message: '<<remarkMessage>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrhdbz>>',
                                rows: 4,
                                maxLength: 500,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    properties: {
                        backgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/scratch-card/background-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<l_picLimit_310>>',
                        },

                        showRewardRecord: {
                            type: 'string.options.radio',
                            displayName: '<<zjmd>>',
                            defaultValue: 'true',
                            rules: [{ required: true }],
                            options: [
                                { id: 'true', name: '<<show>>' },
                                { id: 'false', name: '<<hide>>' },
                            ],
                        },
                        sharePicture: {
                            displayName: '<<fxtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<sharePictureExtra>>',
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfxwa50Rule>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxwa>>',
                                maxLength: 50,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [
                                { whitespace: true, max: 20000, message: '<<notMoreThan2000>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<showContentExtra>>',
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        assetRewards: {
                            type: 'array.rewardSetting',
                            displayName: '<<jpsz>>',
                            controlConfig: {
                                eventType: 'SCRATCH_CARD',
                            },
                        },
                        finishWithNoPrize: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<byxyhjxcj>>',
                            defaultValue: [],
                            options: [
                                {
                                    name: '<<djpzsx>>',
                                    id: 'true',
                                },
                            ],
                            extra: '<<yhzccjhtsyh>>',
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        ScratchCardView: {
            component: 'Viewport',
            entity: 'ScratchCardEntity',
        },
        ScratchCardPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ScratchCardFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/scratchCard/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ScratchCardTable' },
            ],
        },
        ScratchCardFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        ScratchCardTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'endTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/scratch_card_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/scratch_card_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/scratchCard/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/scratchCard/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/scratch_card_activities/{{row.id}}/export.xls',
                        },
                    },
                ],
            },
        },
        ScratchCardAddPage: {
            component: 'FlexLayout',
            entity: 'ScratchCardEntity',
            direction: 'horizontal',
            items: [{ component: 'ScratchCardPreview' }, { component: 'ScratchCardAddFormView' }],
        },
        ScratchCardEditPage: {
            component: 'FlexLayout',
            entity: 'ScratchCardEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ScratchCardPreview' }, { component: 'ScratchCardEditFormView' }],
        },
        ScratchCardCopyPage: {
            component: 'FlexLayout',
            entity: 'ScratchCardEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ScratchCardPreview' }, { component: 'ScratchCardECopyFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/scratchCard',
                    component: 'ScratchCardView',
                    breadcrumbName: '<<scratchCard>>',
                    privilege: {
                        path: 'scratchCard',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ScratchCardAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ScratchCardEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'ScratchCardCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        { path: '/', component: 'ScratchCardPage' },
                    ],
                },
            ],
        },
    ],
};
