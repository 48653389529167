import React, { Component, CSSProperties } from 'react';
import './index.less';

interface WechatCardColorVo {
    name: string;
    color: string;
    text: string;
}
export interface WechatCardColorProps {
    name: string;
    value: string;
    onChange: (value: string, name: string) => void;
}

const colorArray: Array<WechatCardColorVo> = [
    {
        name: 'Color010',
        color: '#63B359',
        text: 'R99 G179 B89',
    },
    {
        name: 'Color020',
        color: '#2C9F67',
        text: 'R44 G159 B103',
    },
    {
        name: 'Color030',
        color: '#509FC9',
        text: 'R80 G159 B201',
    },
    {
        name: 'Color040',
        color: '#5885CF',
        text: 'R88 G133 B207',
    },
    {
        name: 'Color050',
        color: '#9062C0',
        text: 'R144 G98 B192',
    },
    {
        name: 'Color060',
        color: '#D09A45',
        text: 'R208 G154 B69',
    },
    {
        name: 'Color070',
        color: '#E4B138',
        text: 'R228 G177 B56',
    },
    {
        name: 'Color080',
        color: '#EE903C',
        text: 'R238 G144 B60',
    },
    {
        name: 'Color090',
        color: '#DD6549',
        text: 'R221 G101 B73',
    },
    {
        name: 'Color100',
        color: '#CC463D',
        text: 'R204  G70 B61',
    },
];

export class WechatCardColor extends Component<WechatCardColorProps> {
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            if (onChange) {
                onChange('#63B359', name);
            }
        }
    }
    onChange = (color: string) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(color, name);
        }
    };
    render() {
        const { value } = this.props;
        return (
            <div className="wechat-card-color">
                {colorArray &&
                    colorArray.map((colorInfo) => {
                        const style: CSSProperties = {
                            backgroundColor: colorInfo.color,
                            border: value && value === colorInfo.color ? '3px solid red' : '0',
                        };
                        return (
                            <div
                                className="color-wrap"
                                onClick={this.onChange.bind(this, colorInfo.color)}
                            >
                                <div className="color-bg" style={style}></div>
                                <div className="color-text">颜色名称：{colorInfo.name}</div>
                                <div className="color-text">{colorInfo.text}</div>
                                <div className="color-text">{colorInfo.color}</div>
                            </div>
                        );
                    })}
            </div>
        );
    }
}
