import React, { PureComponent } from 'react';
import { Col, Row, Radio, InputNumber, Select } from 'antd';
import { cloneDeep, get, isNil } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { Option } = Select;
interface DisplayProps {
    value: any;
    onChange: (data: any[]) => void;
}
export enum MemberLevelAccessTypes {
    ExperienceValue = 'ExperienceValue',
    GrowthRules = 'GrowthRules',
}

export enum MemberLevelRelationTypes {
    Perhaps = 'OR',
    Also = 'AND',
}

const MaxNum = 999999;

class MemberLevelUpRule extends PureComponent<DisplayProps> {
    /*
     *默认值模板
      defaultValue: {
            accessConditionType: MemberLevelAccessTypes.ExperienceValue,
            level: 0,
            expLower: 0,
            expUpper: 0,
            totalNote: 0,
            videoNote: 0,
            topicNote: 0,
            totalLiked: 0,
            relation: MemberLevelRelationTypes.Perhaps,
            totalCollect: 0,
        },
     */

    onChange(e: any, type: any) {
        const { onChange } = this.props;
        let value: any = '';
        if (isNil(e)) {
            value = 0;
        } else {
            value = e.target ? e.target.value : e;
        }

        this.setState({
            [type]: value,
        });
        let newData: any = cloneDeep(this.props.value || {});
        newData[type] = value;
        onChange(newData);
    }
    render() {
        const { value } = this.props;
        let accessConditionType = get(value, 'accessConditionType');
        return (
            <div className="member-level-up-rule-container">
                <Row>
                    <Col>
                        <Radio.Group
                            onChange={(e) => this.onChange(e, 'accessConditionType')}
                            value={accessConditionType || ''}
                        >
                            <Radio value={MemberLevelAccessTypes.ExperienceValue}>
                                {language.getText('experienceManage')}
                            </Radio>
                            <Radio value={MemberLevelAccessTypes.GrowthRules}>
                                {language.getText('czgz')}
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {accessConditionType === MemberLevelAccessTypes.ExperienceValue && (
                    <div className="experience-value-form">
                        <Row>
                            <Col span={6}>{language.getText('jyzsxx')}：</Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            value={get(value, 'expLower')}
                                            className="full-width-input"
                                            onChange={(e) => this.onChange(e, 'expLower')}
                                        />
                                    </Col>
                                    <Col span={8} className="line">
                                        —
                                    </Col>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            defaultValue={0}
                                            value={get(value, 'expUpper')}
                                            className="full-width-input"
                                            onChange={(e) => this.onChange(e, 'expUpper')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                {accessConditionType === MemberLevelAccessTypes.GrowthRules && (
                    <div className="experience-value-form" style={{ minWidth: '750px' }}>
                        <Row>
                            <Col span={8}>
                                <Row>
                                    <Col span={16} className="right-label">
                                        {language.getText('bjzs')}:
                                    </Col>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            value={get(value, 'totalNote')}
                                            onChange={(e) => this.onChange(e, 'totalNote')}
                                            className="full-width-input"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={16} className="right-label">
                                        {language.getText('jhspbj')}:
                                    </Col>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            value={get(value, 'videoNote')}
                                            onChange={(e) => this.onChange(e, 'videoNote')}
                                            className="full-width-input"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={16} className="right-label">
                                        {language.getText('hhtbj')}:
                                    </Col>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            value={get(value, 'topicNote')}
                                            onChange={(e) => this.onChange(e, 'topicNote')}
                                            className="full-width-input"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={16} className="right-label">
                                        {language.getText('mpbjjhzs')}:
                                    </Col>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            value={get(value, 'totalLiked')}
                                            onChange={(e) => this.onChange(e, 'totalLiked')}
                                            className="full-width-input"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={16} className="right-label">
                                        {language.getText('gx')}:
                                    </Col>
                                    <Col span={8}>
                                        <Select
                                            value={get(value, 'relation')}
                                            onChange={(e: any) => this.onChange(e, 'relation')}
                                            className="full-width-input"
                                        >
                                            <Option value={MemberLevelRelationTypes.Perhaps}>
                                                {language.getText('hz')}
                                            </Option>
                                            <Option value={MemberLevelRelationTypes.Also}>
                                                {language.getText('jbq')}
                                            </Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={16} className="right-label">
                                        {language.getText('mpbjjbszs')}:
                                    </Col>
                                    <Col span={8}>
                                        <InputNumber
                                            min={0}
                                            max={MaxNum}
                                            value={get(value, 'totalCollect')}
                                            onChange={(e) => this.onChange(e, 'totalCollect')}
                                            className="full-width-input"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

export { MemberLevelUpRule };
