import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty } from 'lodash';

const api = services.api;

export const BalanceCouponRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/balance_coupon_rules`;
        const { id, type = '' } = data;
        if (id) {
            config.apiPath = `/admin/balance_coupon_rules/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    externalCoupon: {
                        code: res.externalCouponRule && res.externalCouponRule.code,
                        name: res.externalCouponRule && res.externalCouponRule.name,
                    },
                    dateRange: {
                        start: res.consumeEffectivePeriodRule.period.startTime,
                        end: res.consumeEffectivePeriodRule.period.endTime,
                    },
                    dynamicEffectivePeriod: res.consumeEffectivePeriodRule.dynamicEffectivePeriod,
                    stock: {
                        maxCount: res.couponRule.stock,
                        changeCount: '',
                        surplusCount: res.couponRule.leftStock,
                        defalutMaxCount: res.couponRule.stock,
                        defalutSurplusCount: res.couponRule.leftStock,
                    },
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.productInfo = {
                    subsite: res.couponRule.subsites || [],
                };
                res.ruleInfo = {
                    couponValue: res.couponValue.value,
                };
                let usedChannelLimit: any = [];
                forEach(res.consumeRule.dimensions, (dimension) => {
                    if (dimension.dimensionType === 'CHANNEL') {
                        usedChannelLimit = dimension.ids.map((value: any) => {
                            return value + '';
                        });
                    }
                });
                res.useRuleInfo = {
                    usedChannelLimit: usedChannelLimit,
                };
                if (
                    res.pictureConfigs &&
                    res.pictureConfigs.length > 0 &&
                    res.pictureConfigs[0].pictureId
                ) {
                    res.pictureInfo = {
                        couponPicture: [
                            {
                                id: res.pictureConfigs[0].pictureId || '',
                                path: res.pictureConfigs[0].url || '',
                            },
                        ],
                    };
                }
                res.instructions = {
                    info: res.couponRule.instructions,
                };

                if (type === 'copydata') {
                    delete res.baseInfo.batchNo;
                    delete res.baseInfo.leftStock;
                }
                res.id = res.couponRule.id;
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        value: item.couponValue.value,
                        dynamicEffectivePeriod:
                            item.consumeEffectivePeriodRule.dynamicEffectivePeriod,
                        info: {
                            name: item.name,
                            url:
                                item?.pictureConfigs?.[0]?.url ||
                                `https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_efuture_balance.png`,
                            startTime: item.startTime,
                            endTime: item.endTime,
                        },
                    };
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/balance_coupon_rules';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const newParams: any = dataParser(params);
        newParams.couponRule.id = params.id;
        if (params?.type === 'copydata') {
            config.apiPath = '/admin/balance_coupon_rules';
            return await api.post(newParams, config);
        } else if (params.id) {
            config.apiPath = `/admin/balance_coupon_rules/${params.id}`;
        }
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const subsiteArray: any[] = [];
    if (params.productInfo.subsite && params.productInfo.subsite.length > 0) {
        forEach(params.productInfo.subsite, (subsite) => {
            subsiteArray.push(subsite);
        });
    }
    newParams.couponRule = {
        couponType: 'BALANCE',
        code: '',
        name: params.baseInfo.externalCoupon.name,
        instructions: params.instructions.info,
        merchants: [],
        subSiteOrMerchant: 'SUB_SITE',
        subsites: subsiteArray,
        stock: params.baseInfo.stock.maxCount,
        remark: '',
        source: 'ONLINE',
        mode: 'GENERATE_COUPON_CODE',
    };
    newParams.externalCouponRule = {
        code: params.baseInfo.externalCoupon.code,
        name: params.baseInfo.externalCoupon.name,
    };
    newParams.consumeEffectivePeriodRule = {
        dynamicEffectivePeriod: params.baseInfo.dynamicEffectivePeriod,
        period: {
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
        },
    };
    newParams.couponValue = {
        type: 'AMOUNT',
        value: params.ruleInfo.couponValue,
    };
    if (
        params.pictureInfo &&
        params.pictureInfo.couponPicture &&
        params.pictureInfo.couponPicture[0]
    ) {
        newParams.pictureConfigs = [
            {
                pictureId: params.pictureInfo.couponPicture[0].id,
                type: 'COVER',
            },
        ];
    }
    newParams.consumeRule = {
        canBeConsumed: true,
        condition: 'NOCONDITION',
        conditionLowerValue: null,
        conditionUpperValue: null,
        dimensions: [
            {
                all: false,
                dimensionType: 'CHANNEL',
                ids: params.useRuleInfo.usedChannelLimit.map((value: any) => parseInt(value)),
            },
        ],
        orderLimitQuantity: null,
    };
    newParams.receiveRule = {
        canBeReceiveWhenLackOfStock: false,
        canBeReceived: true,
        memberLimitQuantity: null,
        orderLimitQuantity: null,
        period: {
            endTime: params.baseInfo.dateRange.end,
            startTime: params.baseInfo.dateRange.start,
        },
        receiveQuantityLimitType: 'NO_LIMIT',
    };
    newParams.showRules = [];
    newParams.expirationNoticeRule = null;
    newParams.guideDistributionRule = null;
    newParams.marketingScheduleId =
        (params.baseInfo?.marketingSchedule?.length && params.baseInfo.marketingSchedule[0].id) ||
        null;
    return newParams;
}
