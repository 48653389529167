import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { forEach } from 'lodash';
import { CardLevelSelector } from '../../components';
const api = services.api;

/**
 * 门店会员等级列表弹层筛选
 */
class storeCardLevelButton extends PureComponent<any, any> {
    addStoreCardLevel = (values: any[]) => {
        const { entity, params } = this.props;
        const data = this.processSelectedData(values);
        api.post(data, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/store_card_levels',
        }).then(() => {
            const paging = Object.assign({}, entity.paging, { page: 1 });
            entity.search({ ...params, ...paging });
        });
    };
    processSelectedData = (data: any[]) => {
        const { entity } = this.props;
        const subsiteId = entity.filters && entity.filters.subsiteId && entity.filters.subsiteId.id;
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (item) => {
                items.push({
                    cardLevelId: item.id,
                    subsiteId: subsiteId,
                });
            });
        }
        return items;
    };

    render() {
        const { filters } = this.props;
        const subsiteId = filters && filters.subsiteId;
        return (
            <div className="batch-button" style={{marginBottom:24 }}>
                <CardLevelSelector onChange={this.addStoreCardLevel} subsiteId={subsiteId} />
            </div>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;

    return {
        entity,
        filters: entity.filters,
        componentId,
    };
}
export const StoreCardLevelButton = connect(mapStateToProps)(storeCardLevelButton);
