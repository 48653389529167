import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

const api = services.api;

export const purchaseLimitationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/credit_eshop_limit';
        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        } else {
            config.apiPath = `/admin/credit_eshop_limit/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsites: get(res, 'subsites[0].id') + '',
                };
                res.limitInfo = {
                    goods: get(res, 'selectMode') === 'GOODS' ? res.goods : undefined,
                    limitNum: res.limitNum,
                    selectMode: get(res, 'selectMode') ? res.selectMode : undefined,
                    tags: get(res, 'selectMode') === 'TAGS' ? res.goods : undefined,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    item.goodsRemark = item && item.goods[0] ? item.goods[0].saleRuleGoodsName : '';
                    item.goodsTips = `共${item.goods.length}个商品`;
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/credit_eshop_limit';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.subsites = [params.baseInfo.subsites];
            if (params.baseInfo.dateRange) {
                newParams.startTime = params.baseInfo.dateRange.start;
                newParams.endTime = params.baseInfo.dateRange.end;
            }
        }
        if (params.limitInfo) {
            newParams.limitNum = params.limitInfo.limitNum;
            newParams.selectMode = params.limitInfo.selectMode;
            let forGoods: string[] = [];
            if (params.limitInfo.selectMode === 'GOODS') {
                params.limitInfo.goods.forEach((item: { saleRuleGoodsId: string }) => {
                    forGoods.push(item.saleRuleGoodsId);
                });
            } else {
                forEach(params.limitInfo.tags, (item: any) => {
                    if (get(item, 'saleRuleGoodsId')) {
                        forGoods.push(item.saleRuleGoodsId);
                    } else {
                        forGoods = [];
                    }
                });
            }
            newParams.goods = forGoods;
        }
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = `/admin/credit_eshop_limit/${id}`;
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.subsites = [params.baseInfo.subsites];

            if (params.baseInfo.dateRange) {
                newParams.startTime = params.baseInfo.dateRange.start;
                newParams.endTime = params.baseInfo.dateRange.end;
            }
        }
        if (params.limitInfo) {
            newParams.selectMode = params.limitInfo.selectMode;
            let forGoods: string[] = [];
            if (params.limitInfo.selectMode === 'GOODS') {
                params.limitInfo.goods.forEach((item: { saleRuleGoodsId: string }) => {
                    forGoods.push(item.saleRuleGoodsId);
                });
            } else {
                forEach(params.limitInfo.tags, (item: any) => {
                    if (get(item, 'saleRuleGoodsId')) {
                        forGoods.push(item.saleRuleGoodsId);
                    } else {
                        forGoods = [];
                    }
                });
            }
            newParams.goods = forGoods;
            newParams.limitNum = params.limitInfo.limitNum;
        }
        return await api.put(newParams, config);
    },
};
