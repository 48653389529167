import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        newStoreSelect: {
            apiPath: '/admin/v1/subsites/mine',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<mdbh>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                    },
                    displayName: '<<subsiteName>>',
                },
            },
        },
    },
    components: {
        WechatOfficialAutoReplyView: {
            component: 'Viewport',
        },
        WechatOfficialAutoReplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WechatOfficialAutoReplySettingPage',
                    entity: 'storeSelect',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-official-auto-reply',
                    component: 'WechatOfficialAutoReplyView',
                    breadcrumbName: '<<followReplyMessage>>',
                    privilege: {
                        path: 'followReplyMessage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'WechatOfficialAutoReplyPage',
                        },
                    ],
                },
            ],
        },
    ],
};
