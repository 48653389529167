import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Button as AntButton, Icon as AntIcon } from 'antd';
import { get, map, cloneDeep, findIndex, forEach, isEqual, isEmpty, isArray } from 'lodash';
import { EntitiesManager, builder, actions, services } from '@comall-backend-builder/core';
import { GroupBuyingProductSelectedDisplay } from '../group-buying-product-selected-display';
import { GroupBuyingProductType } from '../group-buying-goods-selected-display';
import { ProductSelector } from '../../components';
const api = services.api;

const TableComponentId = 'GroupBuyingProductSelectorDataTable';
let entity: any = {};

type Props = {
    onChange: (products: any[]) => void;
    requestStatus: string;
    value: any[];
    row: any;
    result: any[];
    fields: any;
    filterFields: any;
};

type State = {
    visible: boolean;
    groupBuyingProductType: keyof typeof GroupBuyingProductType;
};

/**
 * 选择商品
 */
class GroupBuyingProductSelectorLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { visible: false, groupBuyingProductType: 'GENERAL_PRODUCT' };
        this.dispatch = builder.getStore().dispatch;
        entity = new (EntitiesManager.get('GroupBuyingGoodsSelector'))({});
    }
    // 是否已经切换商品类型
    hasChangeGroupBuyingProductType = false;
    dispatch: any = {};
    selectedRows: any[] = [];

    componentWillReceiveProps(nextProps: any) {
        const { result = [], fields } = nextProps;
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);

        if (
            fields &&
            fields.goodsSettings &&
            fields.goodsSettings.goods &&
            fields.goodsSettings.goods.length
        ) {
            // 编辑、详情加载时更新goodsType
            if (!result.length && !this.hasChangeGroupBuyingProductType) {
                this.setState({
                    groupBuyingProductType: fields.goodsSettings.goods[0].groupBuyingProductType,
                });
            }
        }

        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const { selectedRows = [] } = this;

            if (selectedRows.length)
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, 'id')
                        )
                    );
                }, 300);
        }

        if (
            !isEqual(nextSubsiteIds, currentSubsiteIds) &&
            nextSubsiteIds &&
            // !isEqual(currentSubsiteIds, [null]) &&
            !isEmpty(nextProps.value)
        ) {
            // groupBuyingProductType === 'VIRTUAL_GOODS'
            //     ? this.onChange([])
            //     : this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
            this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    getSubsiteIds = (props: any) => {
        const {
            row: { baseSettings = {} },
        } = props;
        const subsiteIds = baseSettings.subsites ? map(baseSettings.subsites, 'id') : [null];
        return subsiteIds;
    };

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        forEach(value, (i) => {
            if (subsiteIds.indexOf(i.subsiteId) > -1) {
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    onChange = (goods: any[]) => {
        this.props.onChange(goods);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        goodsId: goods.id,
                        productId: product.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteId: goods.subsiteId + '',
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        salesPrice: (+goods.salesPrice.value).toFixed(2),
                        goodsDeductPoint: goods.points.value,
                        goodsStock: goods.stock,
                        apportionPrices: product.apportionPrices,
                        groupBuyingProductType: this.state.groupBuyingProductType,
                        virtualProductCouponDefinitionType:
                            product.virtualProductCouponDefinitionType,
                        virtualProductCouponDefinitionId: product.virtualProductCouponDefinitionId,
                    });
                });
            });
        }
        return items;
    };

    onSelect = (values: any[]) => {
        const { value = [] } = this.props;
        const { groupBuyingProductType } = this.state;

        let newData: any[];

        const handleValues = (values: any[]) => {
            const data = this.processSelectedData(cloneDeep(values));
            newData = [...data];
            this.setState({
                groupBuyingProductType: newData[0]?.groupBuyingProductType,
            });
            this.onChange(newData);
        };

        // 如果切换商品类型或者只能单选的，数据要覆盖
        if (groupBuyingProductType === 'VIRTUAL_GOODS') {
            if (
                isArray(values) &&
                values.length > 0 &&
                values[0].virtualProductCouponDefinitionType === 'COUPON_PACKAGE'
            ) {
                const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
                const apiPath = `/admin/coupon_package_rules/${values[0].virtualProductCouponDefinitionId}`;
                api.get({}, { apiRoot, apiPath }).then((res: any) => {
                    values[0].apportionPrices = res.ruleItems.map((coupon: any) => {
                        coupon = {
                            couponDefinitionId: coupon.couponRule.id,
                            couponDefinitionName: coupon.couponRule.name,
                            couponTypeDesc: coupon.couponRule.typeName,
                            quantity: coupon.quantity,
                            stock: 0,
                        };
                        return coupon;
                    });
                    handleValues(values);
                });
            } else {
                handleValues(values);
            }
        } else {
            const data = this.processSelectedData(cloneDeep(values));
            if (
                data.some((o: any) =>
                    value.some((v: any) => v.groupBuyingProductType !== o.groupBuyingProductType)
                )
            ) {
                newData = [...data];
            } else {
                newData = cloneDeep(value || []);
                data.forEach((i: any) => {
                    const hasIndex = findIndex(value, (o: any) => o.id === i.id);
                    if (hasIndex === -1) {
                        newData.unshift(i);
                    }
                });
            }
            this.setState({
                groupBuyingProductType: newData[0]?.groupBuyingProductType,
            });
            this.onChange(newData);
        }
    };

    handleSelectGoodsType = ({ key }: any) => {
        this.hasChangeGroupBuyingProductType = true;
        this.setState({ groupBuyingProductType: key });
    };

    renderGoodsSelector = () => {
        const { row, filterFields } = this.props;
        const subsiteIds =
            row.baseSettings && row.baseSettings.subsites
                ? map(row.baseSettings.subsites, 'id')
                : null;
        let shelfStatus = null;
        const normalFilterFields = [
            'keywords',
            'subsiteId',
            'merchantId',
            'supplyChain',
            'tradeType',
        ];
        const normalColumns = [
            'productInfo',
            'goodsMvo.stock',
            'goodsMvo.productStyleName',
            'goodsMvo.subsiteName',
            'goodsMvo.merchantName',
            'tradeType',
        ];
        const normalParams = {
            subsiteIds: subsiteIds,
            shelfStatus: shelfStatus,
            productTypes: ['NORMAL'],
        };
        const virtualParams = {
            subsiteIds: subsiteIds,
            shelfStatus: 'ALL',
            productTypes: 'VIRTUAL_COUPON',
        };

        const menu = (
            <Menu onClick={this.handleSelectGoodsType}>
                {Object.entries(GroupBuyingProductType).map(([key, v]) =>
                    v !== GroupBuyingProductType.COUPON ? (
                        <Menu.Item key={key}>
                            <ProductSelector
                                buttonText={v}
                                onChange={this.onSelect}
                                filterFields={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? normalFilterFields
                                        : filterFields
                                }
                                columns={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? normalColumns
                                        : undefined
                                }
                                params={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? normalParams
                                        : virtualParams
                                }
                                rowSelectionType={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? 'checkbox'
                                        : 'radio'
                                }
                            />
                        </Menu.Item>
                    ) : null
                )}
            </Menu>
        );

        return (
            <div style={{ display: 'inline-block' }}>
                {subsiteIds ? (
                    <Dropdown overlay={menu}>
                        <AntButton>
                            {services.language.getText('selectPlease')}
                            <AntIcon type="down" />
                        </AntButton>
                    </Dropdown>
                ) : (
                    <div>{services.language.getText('selectBeforeMerchant')}</div>
                )}
            </div>
        );
    };

    render() {
        const { value = [] } = this.props;
        const { groupBuyingProductType } = this.state;

        return (
            <>
                {this.renderGoodsSelector()}
                {value.length ? (
                    <GroupBuyingProductSelectedDisplay
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                        groupBuyingProductType={groupBuyingProductType}
                    />
                ) : null}
            </>
        );
    }
}

function mapStateTopProps(_state: any, props: any) {
    const { entity: parentEntity } = props;

    return {
        requestStatus: get(entity, 'requestStatus', null),
        result: entity.result,
        fields: parentEntity.fields,
    };
}

export const GroupBuyingProductSelectorContainer = connect(mapStateTopProps)(
    GroupBuyingProductSelectorLayout
);
