import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

const { getText } = services.language;

export interface MyCouponsSettingEditFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

class myCouponsSettingEditFormView extends PureComponent<MyCouponsSettingEditFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let fields = [
            {
                property: 'tips',
                className: 'my-coupons-setting-tips',
            },
            {
                property: 'subsiteIds',
            },
            {
                property: 'couponSources',
            },
            {
                property: 'couponModes',
                visible: (values: any) => {
                    return get(values, 'couponSources', []).includes('ONLINE');
                },
            },
            {
                property: 'couponCenterDefaultSource',
                visible: (values: any) => {
                    return (
                        get(values, 'couponSources', []).includes('ONLINE') &&
                        get(values, 'couponSources', []).includes('OFFLINE')
                    );
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'MyCouponsSettingEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'my-coupons-setting-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: getText('qx'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    entity.modify(entityFields, params);
                }
            }, 300),
            data:
                get(
                    _dispatch,
                    'components.["DataFormPlus-MyCouponsSettingEditFormContainer"].fields'
                ) || {},

            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction(
                            'DataFormPlus-MyCouponsSettingEditFormContainer'
                        )
                    );
            },
        }
    );
}
export const MyCouponsSettingEditFormView = connect(mapDispatchToProps)(
    myCouponsSettingEditFormView
);
