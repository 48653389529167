import * as React from 'react';
import { message } from 'antd';
import { privilege } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface NavigationLinkProps {
    text: string;
    href: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    privilege?: {
        path: string;
        level: 'none' | 'view' | 'modify' | 'full';
    };
    disabledType?: 'hide' | 'tips';
}

export class NavigationLink extends React.Component<NavigationLinkProps, any> {
    tips = () => {
        message.warning(services.language.getText('noPerationPermission'));
    };
    render() {
        const {
            privilege: componentPrivilege,
            disabledType = 'hide',
            text,
            href,
            target = '_blank',
        } = this.props;
        if (componentPrivilege) {
            const { path, level } = componentPrivilege;
            if (!privilege.check(path, level)) {
                return disabledType === 'tips' ? (
                    <span style={{ color: '#1890ff' }} onClick={this.tips}>
                        {text}
                    </span>
                ) : null;
            }
        }
        return (
            <a rel="noopener noreferrer" href={href} target={target}>
                {text}
            </a>
        );
    }
}
