import React, { PureComponent } from 'react';
import { some, get, map, isEqual, isNumber, isUndefined } from 'lodash';
import { Button, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { IntegerInputTitleTip } from '../../../../components';
import { CouponPackagePricesDisplay } from './coupon-package-prices-display';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export interface SalesPriceAndCouponPackagePricesValue {
    salesPrice: number | undefined;
    couponPackagePrices: CouponPackagePricesItem[];
}
interface CouponPackagePricesItem {
    definitionId: string;
    quantity: number;
    couponType: string;
    couponTypeName: string;
    couponName: string;
    apportionPrice: number | undefined;
    principal: number | undefined;
}

interface SalesPriceAndCouponPackagePricesState {
    visible: boolean;
}

export class SalesPriceAndCouponPackagePrices extends PureComponent<
    any,
    SalesPriceAndCouponPackagePricesState
> {
    constructor(props: any) {
        super(props);
        this.state = { visible: false };
    }
    componentWillReceiveProps(nextProps: any) {
        const currentCoupon = get(this.props, 'row.baseInfo.coupon');
        const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
        const nextCouponItem = nextCoupon && nextCoupon.length > 0 ? nextCoupon[0] : undefined;
        // 当前选择的优惠券是券礼包获取券礼包详情设置分摊
        if (!isUndefined(currentCoupon) && !isEqual(currentCoupon, nextCoupon)) {
            if (nextCouponItem) {
                if (nextCouponItem.type === 'PACKAGE') {
                    this.onSetCouponPackagePrices(nextCouponItem.id, nextProps);
                } else if (nextCouponItem.type === 'BFPLAT_COUPON') {
                    this.onSetBfplatCouponPrices(nextCouponItem.id, nextProps);
                } else {
                    this.onClearPrice();
                }
            } else {
                this.onClearPrice();
            }
        }
    }
    onClearPrice = () => {
        const { onChange } = this.props;
        onChange({
            salesPrice: undefined,
            couponPackagePrices: [],
        });
    };
    onSetBfplatCouponPrices = (id: string, nextProps: any) => {
        const { onChange } = nextProps;
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const apiPath = `/admin/bfplat_coupon_rules/${id}`;
        api.get({}, { apiRoot, apiPath }).then((res: any) => {
            if (res && onChange) {
                onChange({
                    salesPrice: res.principal,
                    couponPackagePrices: [],
                });
            }
        });
    };
    onChangeSalesPrice = (salesPrice: number | undefined | null) => {
        const { onChange, value } = this.props;
        const changeValue: SalesPriceAndCouponPackagePricesValue = {
            salesPrice: isNumber(salesPrice) ? salesPrice : undefined,
            couponPackagePrices: map(value.couponPackagePrices, (item: any) => {
                return {
                    ...item,
                    apportionPrice:
                        item.couponType === 'BFPLAT_COUPON' ? item.apportionPrice : undefined,
                };
            }),
        };
        if (onChange) {
            onChange(changeValue);
        }
    };

    onSetCouponPackagePrices = (id: string, nextProps: any) => {
        const { onChange } = nextProps;
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const apiPath = `/admin/coupon_package_rules/${id}`;
        api.get({}, { apiRoot, apiPath }).then((res: any) => {
            const couponPackagePrices: CouponPackagePricesItem[] = map(
                res.ruleItems,
                (item: any) => {
                    return {
                        definitionId: item.couponRule.id,
                        quantity: item.quantity,
                        couponType: item.couponRule.couponType,
                        couponTypeName: item.couponRule.typeName,
                        couponName: item.couponRule.name,
                        apportionPrice:
                            item.couponRule.couponType === 'BFPLAT_COUPON'
                                ? item.couponRule.principal
                                : undefined,
                        principal: item.couponRule.principal,
                    };
                }
            );
            if (onChange) {
                onChange({
                    salesPrice: undefined,
                    couponPackagePrices,
                });
            }
        });
    };

    toggleModal = () => {
        const { value } = this.props;
        const salesPrice = value ? value.salesPrice : undefined;
        if (isNumber(salesPrice)) {
            this.setState({
                visible: true,
            });
        } else {
            AntMessage.warning(language.getText('beforeSalePrice'));
        }
    };

    onOk = (value: SalesPriceAndCouponPackagePricesValue) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
        this.onCancel();
    };

    onCancel = () => {
        this.setState({
            visible: false,
        });
    };
    getInputDisabled = () => {
        const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
        const bfplatCoupon = currentCoupon.find((t: any) => t.type === 'BFPLAT_COUPON');
        if (bfplatCoupon && bfplatCoupon.principal && bfplatCoupon.principal > 0) {
            return true;
        }
        return false;
    };
    render() {
        const { value } = this.props;
        const { visible } = this.state;
        const inputRule = {
            min: 0,
            max: 999999,
            precision: 2,
        };
        const salesPrice = value ? value.salesPrice : undefined;
        const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
        const isPackage = some(currentCoupon, (item: any) => get(item, 'type') === 'PACKAGE');
        const inputDisabled = this.getInputDisabled();
        return (
            <div className="sales-price-and-coupon-package-prices">
                <IntegerInputTitleTip
                    {...this.props}
                    value={salesPrice}
                    onChange={this.onChangeSalesPrice}
                    addonafter={language.getText('yuan')}
                    rules={inputRule}
                    allowFloat
                    disabled={inputDisabled}
                />
                {isPackage ? (
                    <Button type="link" onClick={this.toggleModal}>
                        {language.getText('setSharePrice')}
                    </Button>
                ) : null}
                <CouponPackagePricesDisplay
                    visible={visible}
                    value={value}
                    onOk={this.onOk}
                    onCancel={this.onCancel}
                />
            </div>
        );
    }
}
