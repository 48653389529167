import React, { Component } from 'react';
import { find } from 'lodash';

import { modes } from '@comall-backend-builder/types';
import { Select } from '@comall-backend-builder/components-basis';
import { SelectValue as AntSelectValue } from 'antd/lib/select';

/**
 * 下拉框 最后保存的是选中对象
 */
export class OptionSelectObjectMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SelectPlus {...controlInfo} />;
    }
}

class SelectPlus extends Component<any, any> {
    onChange = (selectValue: AntSelectValue) => {
        if (selectValue) {
            const { name, onChange, options } = this.props;
            const selectValueObject = find(options, { id: selectValue + '' });
            onChange(selectValueObject, name);
        }
    };
    render() {
        const props: any = this.props;
        let value: any;
        if (props && props.value && props.value) {
            value = props.value.id + '';
        }
        return (
            <div>
                <Select {...props} value={value} onChange={this.onChange} />
            </div>
        );
    }
}
