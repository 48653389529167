import { Modal, message as AntMessage } from 'antd';
import { defaultsDeep, set, assign, remove } from 'lodash';
import { builder, actions } from '@comall-backend-builder/core';
import React, { createElement, PureComponent } from 'react';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
import { services } from '@comall-backend-builder/core';
const { language } = services;
let SELECTED_ROW: Array<{ id: number; name: string }> = [];

function getSubsiteConfig(componentName: string) {
    return {
        component: 'Card',
        entity: 'CustomDesignSubsiteSelector',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    pagination: false,
                    scroll: { y: true },
                    style: { height: 500, 'overflow-y': 'scroll' },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'id',
                        },
                    ],
                },
            ],
        },
    };
}

function initDynamicSubsiteComponent(contentConfig: any, componentName: any) {
    let config = defaultsDeep(contentConfig, getSubsiteConfig(componentName));
    set(config, 'content.items[0].componentId', componentName + '-table');
    set(config, 'content.items[0].rowSelection', {
        type: 'Multi',
        onChange: () => {},
        onSelect: (record: any, selected: boolean) => {
            if (selected) {
                SELECTED_ROW.push(record);
            } else {
                remove(SELECTED_ROW, (i) => {
                    return i.id === record.id;
                });
            }
        },
        onSelectAll: (selected: boolean, rows: any) => {
            if (selected) {
                SELECTED_ROW = SELECTED_ROW.concat(rows);
            } else {
                SELECTED_ROW = [];
            }
        },
    });
    return ComponentFactory(componentName, config);
}
function generateDynamicSubsiteComponentName() {
    return 'DesignSubsiteSelector-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

class SelectMultiSubsite extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    onChange: (data: any) => void;
    onCancel: () => void;
}> {
    subsiteComponentName = '';
    renderSubsiteComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { contentConfig } = props;
        this.subsiteComponentName = generateDynamicSubsiteComponentName();
        const dynamicSubsiteComponent = initDynamicSubsiteComponent(
            contentConfig,
            this.subsiteComponentName
        );
        this.renderSubsiteComponent = createElement(dynamicSubsiteComponent);
        this.dispatch = builder.getStore().dispatch;
    }
    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(language.getText('components.Tools.multiSubsiteSelectRequired'));
            return;
        }
        const { onChange } = this.props;
        onChange(SELECTED_ROW);
        builder
            .getStore()
            .dispatch(
                actions.tableRowSelectionChangeAction(this.subsiteComponentName + '-table', [])
            );
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 800,
                visible: showModal,
                title: services.language.getText('otherSelectSubsite'),
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderSubsiteComponent}</Modal>;
    }
}

export { SelectMultiSubsite };
