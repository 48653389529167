import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { isEmpty, forEach } from 'lodash';

export const config: Config = {
    entities: {
        PreSaleListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/presales',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    // defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOTSTARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                    rules: [{ required: true }],
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        presaleMode: {
                            type: 'string.options.radio',
                            displayName: '预售模式',
                            defaultValue: 'FULL',
                            options: [{ id: 'FULL', name: '全款预售' }],
                            rules: [{ required: true }],
                        },
                    },
                },
                extraActivityMode: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<hdms_1>>',
                    options: [
                        { id: 'SIGN_UP', name: '需要报名' },
                        { id: 'DRAW_LOTS', name: '需要抽签' },
                    ],
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    rules: [{ required: true, type: 'array' }],
                },
                goods: {
                    type: 'array.PresaleProducts',
                    displayName: '<<components.Tools.selectProduct>>',
                    rules: [
                        { required: true, message: '<<components.Tools.productSelectRequired>>' },
                    ],
                },
                registeredDays: {
                    type: 'number.tip',
                    displayName: '用户注册时间',
                    displayConfig: { addonafter: '天之前注册的用户可参与活动' },
                    rules: [
                        {
                            message: '仅支持输入正整数',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (value && !reg.test(value)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        addonafter: '天之前注册的用户可参与活动',
                        style: { width: '140px' },
                        rules: [
                            {
                                type: 'number',
                                min: 1,
                            },
                        ],
                    },
                },
                monthlyConsumedTimes: {
                    type: 'number.tip',
                    displayName: '月消费次数',
                    displayConfig: { addonbefore: '用户月消费次数至少为', addonafter: '<<cys>>' },
                    rules: [
                        {
                            message: '仅支持输入正整数',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (value && !reg.test(value)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        addonbefore: '用户月消费次数至少为',
                        addonafter: '<<cys>>',
                        style: { width: '140px' },
                        rules: [
                            {
                                type: 'number',
                                min: 1,
                            },
                        ],
                    },
                },
                minPointThreshold: {
                    type: 'number.positive',
                    displayName: '报名所需积分',
                    controlConfig: {
                        style: { width: '140px' },
                    },
                },
                certification: {
                    type: 'string.options.radio',
                    displayName: '是否需要实名认证',
                    defaultValue: 'CERTIFICATION',
                    options: [
                        { id: 'CERTIFICATION', name: '需要实名认证' },
                        { id: 'NON_CERTIFICATION', name: '不需要实名认证' },
                    ],
                },
                ballotDate: {
                    name: 'drawTime',
                    type: 'object.selectTimepicker',
                    displayName: '抽签时间',
                    options: [{ id: 'EACH_DAY', name: '每天' }],
                    rules: [
                        {
                            message: '抽签时间不能为空',
                            validator: (rule: any, value: any) => {
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        defaultTypeValue: 'EACH_DAY',
                        defaultDrawTime: '00:00',
                        typeOptions: [{ id: 'EACH_DAY', name: '每天' }],
                        tip: '开始抽签',
                    },
                },
                ballotedPeopleCount: {
                    type: 'object.winningLotsNumber',
                    format: 'HH:mm',
                    displayName: '<<zqrs>>',
                    controlConfig: {
                        defaultTypeValue: 'EACH_DAY',
                        addonbefore: '最多中签',
                        addonafter: '人',
                        tip: '配置后，在活动期间内每天会有多少用户中签',
                        rules: [
                            {
                                type: 'number',
                                min: 1,
                            },
                        ],
                    },
                    options: [{ id: 'EACH_DAY', name: '每天' }],
                },
                perballotedCount: {
                    type: 'number.tip',
                    displayName: '<<myhzqcs>>',
                    displayConfig: { addonafter: '<<frequency>>' },
                    controlConfig: {
                        addonafter: '<<frequency>>',
                        tip: '配置后，每个用户在活动期间内可以被抽中的次数',
                        style: { width: '140px' },
                        rules: [
                            {
                                type: 'number',
                                min: 1,
                            },
                        ],
                    },
                },
                activityTimes: {
                    type: 'array.presaleTimepicker',
                    displayName: '<<gmsj>>',
                    className: 'presale-range-timepicker',
                    rules: [
                        {
                            message: '活动场次的每个时间段不可重复',
                            validator: (rule: any, value: any) => {
                                let result: Array<boolean> = [];
                                if (value !== null && !isEmpty(value)) {
                                    // 如果是交叉时间 就提示
                                    forEach(value, (outerItem, outerIndex) => {
                                        forEach(value, (item, index) => {
                                            if (
                                                !isEmpty(outerItem) &&
                                                !isEmpty(item) &&
                                                outerIndex !== index
                                            ) {
                                                let valueStart = new Date(
                                                    '2020-01-01 ' + outerItem.start
                                                ).getTime();
                                                let valueEnd = new Date(
                                                    '2020-01-01 ' + outerItem.end
                                                ).getTime();
                                                let itemStart = new Date(
                                                    '2020-01-01 ' + item.start
                                                ).getTime();
                                                let itemEnd = new Date(
                                                    '2020-01-01 ' + item.end
                                                ).getTime();

                                                if (valueStart - itemEnd > 0) {
                                                    result.push(true);
                                                } else if (valueEnd - itemStart < 0) {
                                                    result.push(true);
                                                } else {
                                                    result.push(false);
                                                }
                                            }
                                        });
                                    });
                                }
                                if (result.indexOf(false) > -1) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        tip: '<<zzhdsjdmtd>>',
                        warnTip: '<<warnTip>>',
                        deleteButtonText: '<<common.delete>>',
                        addButtonText: '<<tjcc>>',
                    },
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                rulesInfo: {
                    type: 'object.subForm',
                    displayName: '限购规则',
                    properties: {
                        limitRules: {
                            type: 'string.options.radio',
                            displayName: '<<xgtj>>',
                            defaultValue: 'LIMIT_NO',
                            options: [
                                { id: 'LIMIT_NO', name: '不限购' },
                                { id: 'LIMIT_USER', name: '用户限购' },
                                { id: 'LIMIT_USER_LEVEL', name: '会员等级限购' },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
                limitUser: {
                    type: 'number.presaleTitleTip',
                    displayName: '按用户限购',
                    displayConfig: {
                        eachPresaleConfig: {
                            addonbefore: '活动期间每人最多购买',
                            addonafter: '<<jian>>',
                        },
                        eachOrderConfig: {
                            addonbefore: '<<mrmdzdgm>>',
                            addonafter: '<<jian>>',
                        },
                    },
                    controlConfig: {
                        configInfo: [
                            {
                                addonbefore: '活动期间每人最多购买',
                                addonafter: '<<jian>>',
                                type: 'EACH_PRESALE',
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                            {
                                addonbefore: '<<mrmdzdgm>>',
                                addonafter: '<<jian>>',
                                type: 'EACH_ORDER',
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        ],
                    },
                },
                limitUserLevel: {
                    type: 'array.MemberLevelRules',
                    displayName: '按会员等级限购',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                        apiPath: '/admin/members_levels',
                    },
                },
                checkoutRules: {
                    type: 'object.subForm',
                    displayName: '结算规则',
                    properties: {
                        orderRules: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '下单可享权益',
                            options: [{ id: 'USE_POINT', name: '使用积分' }],
                        },
                        rules: {
                            type: 'number.presaleTitleTip',
                            displayName: '发货设置',
                            displayConfig: {
                                pickupTimeConfig: {
                                    labelTitle: '发货时间',
                                    addonbefore: '下单成功后第',
                                    addonafter: '天开始发货',
                                },
                            },
                            controlConfig: {
                                configInfo: [
                                    {
                                        labelTitle: '发货时间',
                                        addonbefore: '下单成功后第',
                                        addonafter: '天开始发货',
                                        tip:
                                            '如配置发货时间请按配置的发货时间进行发货，避免用户投诉',
                                        type: 'pickupTime',
                                        rules: [
                                            {
                                                type: 'number',
                                                min: 1,
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    },
                },
                advertisement: {
                    type: 'object.subForm',
                    displayName: '<<hdxc>>',
                    properties: {
                        activityPicture: {
                            displayName: '<<hdtpz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                className: 'sigle-image-field',
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        rulePicture: {
                            displayName: '活动规则说明',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        PreSaleListView: {
            component: 'Viewport',
            entity: 'PreSaleListEntity',
        },
        PreSaleListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'PreSaleListFilter',
                },
                { component: 'PreSaleListTable' },
            ],
        },
        PreSaleListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        PreSaleListTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<addActivity>>',
                    route: '/presale-list/add',
                    type: 'primary',
                    icon: 'plus',
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'PresaleDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'baseInfo.name',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '140px',
                                },
                            },
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOTSTARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<details>>',
                                path: '/presale-list/info/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    // statusKey: 'baseInfo.status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath: '/admin/presales/:id/status',
                                            config: {
                                                content: '活动{{row.baseInfo.name}}已被关闭',
                                                text: '<<common.close>>',
                                                style: { color: 'red' },
                                            },
                                            confirm: {
                                                text: '确认关闭活动{{row.baseInfo.name}}?',
                                                okText: '继续',
                                                content: '禁用后已产生的订单不受影响',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath: '/admin/presales/:id/status',
                                            config: {
                                                content: '活动{{row.baseInfo.name}}已被开启',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>活动{{row.baseInfo.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/presale-list/edit/{{row.id}}',
                            },
                            // {
                            //     type: 'delete',
                            //     text: '<<common.delete>>',
                            //     statusKey: 'row.canDelete',
                            //     config: {
                            //         type: 'link',
                            //         id: 'row.id',
                            //         tooltip: null,
                            //         title: '<<confirmDelete>>',
                            //         okText: '<<common.ok>>',
                            //         cancelText: '<<common.cancel>>',
                            //     },
                            // },
                            {
                                type: 'component',
                                text: '活动数据',
                                component: 'CgbManageDataStatistics',
                            },
                        ],
                    },
                },
            ],
        },
        PreSaleListAddPage: {
            component: 'FlexLayout',
            entity: 'PreSaleListEntity',
            direction: 'horizontal',
            items: [{ component: 'PreSaleListAddFormView' }],
        },
        PreSaleListEditPage: {
            component: 'FlexLayout',
            entity: 'PreSaleListEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'PreSaleListEditFormView' }],
        },
        PreSaleDetailPage: {
            component: 'Card',
            content: {
                component: 'PreSaleDetailView',
            },
        },
        PreSaleDetailView: {
            component: 'Detail',
            entity: 'PreSaleListEntity',
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },

                { property: 'subsites' },
                { property: 'activityInfo.presaleMode' },
                {
                    property: 'extraActivityMode',
                    extra: '两项均勾选后，则用户需要先报名参加活动，中签后才能购买商品',
                },

                { property: 'goods' },
                {
                    property: 'registeredDays',
                    extra:
                        '如填写30天，活动日期为1日-30日，若用户是25号参加的活动，则从25号当天开始计算，用户的注册时间必须为上月26日24:00及之前注册（若上月为30天）的才可报名成功',
                },
                {
                    property: 'monthlyConsumedTimes',
                    extra: '如填写3次，则表示用户每月至少消费>=3次才可报名成功',
                },
                {
                    property: 'minPointThreshold',
                    extra: '报名时校验用户是否符合该积分，如不符合则不允许报名',
                },
                {
                    property: 'certification',
                    extra: '勾选实名认证后，则用户需要进行姓名和身份证的认证',
                },
                {
                    property: 'ballotDate',
                },
                {
                    property: 'ballotedPeopleCount',
                },
                {
                    property: 'perballotedCount',
                },
                { property: 'activityTimes' },
                {
                    property: 'rulesInfo.limitRules',
                },
                {
                    property: 'limitUser',
                },
                {
                    property: 'limitUserLevel',
                },
                {
                    property: 'checkoutRules.orderRules',
                    extra: '勾选后，在结算页时可同时使用',
                },
                {
                    property: 'checkoutRules.rules',
                },
                {
                    property: 'advertisement.activityPicture',
                    extra: '将会在活动投放页顶部显示，建议尺寸：750px * 560px',
                },
                {
                    property: 'advertisement.rulePicture',
                    extra: '将会在活动投放页规则说明处显示，建议尺寸：宽度为750px 高度不限制',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'primary',
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/presale-list',
                    component: 'PreSaleListView',
                    breadcrumbName: '预售活动',
                    privilege: {
                        path: 'presale',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'PreSaleListEditPage',
                            breadcrumbName: '编辑预售',
                        },
                        {
                            path: '/add',
                            component: 'PreSaleListAddPage',
                            breadcrumbName: '新增预售',
                        },
                        {
                            path: '/info/:id',
                            component: 'PreSaleDetailPage',
                            breadcrumbName: '查看预售',
                        },
                        { path: '/', component: 'PreSaleListPage' },
                    ],
                },
            ],
        },
    ],
};
