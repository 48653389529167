import { forEach } from 'lodash';
import { services } from '@comall-backend-builder/core/';
export const PlatformPageManageLoader = {
    get: async function(data: any, config: any) {
        if (data && data.type === 'all') {
            delete data.type;
        }
        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.result) {
                forEach(res.result, (i) => {
                    i.canDelete = !i.enabled;
                });
            }
            return res;
        });
    },
    post: function(data: any, config: any) {
        data.type = 'platform';
        return services.api.post(data, config);
    },
    put: function(data: any, config: any) {
        data.subsites = [];
        return services.api.put(data, config);
    },
};
