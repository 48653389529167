import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const WechatTemplatesLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`;
        config.apiPath = '/admin/wechat_templates';
        const { id } = data;
        if (!id) {
            if (data.tab === 'ALL') {
                delete data.tab;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
            } else {
                res.result = res.result.map((item: any) => ({
                    ...item,
                    status: item.id ? true : false,
                }));
            }
            return res;
        });
    },
};
