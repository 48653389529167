import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        MemberProfileItemsEntity: {
            apiPath: '',
            filters: {},
            properties: {},
        },
    },
    components: {
        MemberProfileItemsView: {
            component: 'Viewport',
            entity: 'MemberProfileItemsEntity',
        },
        MemberProfileItemsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'MemberProfileItemsTable' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/memberProfileItem',
                    component: 'MemberProfileItemsView',
                    breadcrumbName: '<<hyzlxgl>>',
                    privilege: {
                        path: 'memberProfileItem',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MemberProfileItemsPage' }],
                },
            ],
        },
    ],
};
