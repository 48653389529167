import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const newOpenScreenLoader: Loader = {
    get(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/splash_ads';

        if (data.id) {
            config.apiPath = `/admin/splash_ads/${data.id}`;
        } else {
            if (!data.page) {
                data.page = 1;
            }
            if (!isEmpty(data.showTime)) {
                data.startTime = data.showTime.start + ' 00:00:00';
                data.endTime = data.showTime.end + ' 23:59:59';
                delete data.showTime;
            }
        }

        return api.get(data, config).then((res: any) => {
            if (data.id) {
                const item: any = res;
                item.information = {};
                item.information.name = item.name;
                item.information.showTime = {
                    start: item.startTime,
                    end: item.endTime,
                };
                item.rules = {};
                item.rules.channels = item.channels;
                item.rules.contentType = item.contentType;
                item.rules.mode = item.triggerConfig?.mode;
                item.rules.times = item.triggerConfig?.times;

                if (item.config) {
                    const configs = JSON.parse(item.config);
                    item.rules.picture = configs.pictureContent;
                    item.rules.video = configs.videoContent?.video;
                    item.rules.target = configs.videoContent?.config;
                    item.rules.carousel = configs.carousel;
                    item.rules.interval = configs.interval;
                    item.rules.position = configs.position;
                    item.rules.countdown = configs.countdown;
                }
                return item;
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.information = {};
                        item.information.name = item.name;
                        item.information.showTime = {
                            start: item.startTime,
                            end: item.endTime,
                        };
                        item.rules = {};
                        item.rules.channels = item.channels;
                        item.rules.status = item.status;
                        item.rules.excuteStatus = item.excuteStatus;
                        item.rules.info = item.adStatisticsData;
                        return item;
                    });
            }
            return res;
        });
    },
    post(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/splash_ads';

        params.name = params.information.name;
        if (params.information.showTime) {
            params.startTime = params.information.showTime.start;
            params.endTime = params.information.showTime.end;
        }
        params.channels = params.rules.channels;
        params.contentType = params.rules.contentType;
        params.triggerConfig = {
            mode: params.rules.mode,
            times: params.rules.mode === 'DAILY' ? params.rules.times : null,
        };
        let configParams: any = {
            pictureContent: null,
            videoContent: null,
            interval: params.rules.interval,
            carousel: params.rules.carousel,
            countdown: params.rules.countdown,
            position: params.rules.position,
        };
        if (params.rules.contentType === 'PICTURE') {
            configParams.pictureContent = params.rules.picture;
            configParams.pictureContent.forEach((p: any) => {
                if (p.config && !p.config.linkType) {
                    p.config = null;
                }
            });
        } else if (params.rules.contentType === 'VIDEO') {
            configParams.videoContent = {
                config:
                    params?.rules?.target && params.rules.target.linkType
                        ? params.rules.target
                        : null,
                video: params.rules.video,
            };
        }
        params.config = JSON.stringify(configParams);
        delete params.information;
        delete params.rules;
        return api.post(params, config);
    },
    put(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/splash_ads/${id}`;

        params.name = params.information.name;
        if (params.information.showTime) {
            params.startTime = params.information.showTime.start;
            params.endTime = params.information.showTime.end;
        }
        params.channels = params.rules.channels;
        params.contentType = params.rules.contentType;
        params.triggerConfig = {
            mode: params.rules.mode,
            times: params.rules.mode === 'DAILY' ? params.rules.times : null,
        };
        let configParams: any = {
            pictureContent: null,
            videoContent: null,
            interval: params.rules.interval,
            carousel: params.rules.carousel,
            countdown: params.rules.countdown,
            position: params.rules.position,
        };
        if (params.rules.contentType === 'PICTURE') {
            configParams.pictureContent = params.rules.picture;
            configParams.pictureContent.forEach((p: any) => {
                if (p.config && !p.config.linkType) {
                    p.config = null;
                }
            });
        } else if (params.rules.contentType === 'VIDEO') {
            configParams.videoContent = {
                config:
                    params?.rules?.target && params.rules.target.linkType
                        ? params.rules.target
                        : null,
                video: params.rules.video,
            };
        }
        params.config = JSON.stringify(configParams);
        delete params.information;
        delete params.rules;

        return api.put(params, config);
    },
    delete: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/splash_ads/${id}`;

        return await api.delete(params, config);
    },
};
