import React, { Component } from 'react';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { GroupOption } from '@comall-backend-builder/components-basis/lib/radio-group';

export class ArrayOptionIdsCheckboxTip extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(value: any) {
        const { name, onChange, checkboxType = '', checkboxTypeDisabled = [] } = this.props;

        if (onChange) {
            //目前Multiple 模式只支持2个选择，多个时需要做处理
            if (checkboxType === 'Multiple') {
                onChange([...checkboxTypeDisabled, ...value], name);
            } else {
                onChange(value, name);
            }
        }
    }

    render() {
        const {
            value,
            name,
            options,
            tip,
            checkboxType = '',
            checkboxTypeDisabled = [],
            disabled,
        } = this.props;
        const tipType = typeof tip;
        return (
            <div>
                {checkboxType === 'Multiple' ? (
                    <div>
                        {options.map((o: GroupOption) => {
                            let disableds = disabled ? disabled : false;
                            if (checkboxTypeDisabled.includes(o.id)) {
                                disableds = true;
                            }
                            return (
                                <CheckboxGroup
                                    name={name}
                                    onChange={this.onChange}
                                    options={[o]}
                                    value={value}
                                    disabled={disableds}
                                    // {...this.props}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <CheckboxGroup
                        name={name}
                        onChange={this.onChange}
                        options={options}
                        value={value}
                        {...this.props}
                    />
                )}
                {tip && tipType === 'string' ? (
                    <div {...this.props} style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                        {tip}
                    </div>
                ) : tip && tipType === 'object' ? (
                    <div>
                        {tip.map((t: string) => {
                            return (
                                <div
                                    {...this.props}
                                    style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}
                                >
                                    {t}
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        );
    }
}
