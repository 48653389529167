import React from 'react';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import './index.less';

/**
 * 活动左侧
 * @param props
 */
const blindBoxSettingLeftView = () => {
    const blindBoxBgUrl = require('./blind-box-bg.png');
    const blindBoxPopupUrl = require('./blind-box-popup.png');
    return (
        <div className="blind-box-left-view">
            <img
                className="blind-box-left-view"
                alt={services.language.getText('preview')}
                src={blindBoxBgUrl}
            ></img>
            <img
                className="blind-box-left-view"
                alt={services.language.getText('preview')}
                src={blindBoxPopupUrl}
            ></img>
        </div>
    );
};

export const BlindBoxSettingLeftView = connect()(blindBoxSettingLeftView);
