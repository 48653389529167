import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove, some, get } from 'lodash';
import { PaginationConfig } from 'antd/lib/table/interface';
import { language } from '@comall-backend-builder/core/lib/services';

interface CouponPackageSelectorDisplayProps {
    data: Array<any>;
    onChange: (value: any) => void;
    disabled?: boolean;
}

/**
 * 优惠券展示和内容编辑
 */
export class CouponPackageSelectorDisplay extends PureComponent<CouponPackageSelectorDisplayProps> {
    getTableConfig = () => {
        const { data, disabled } = this.props;
        const isPackage = some(data, (item) => get(item, 'type') === 'PACKAGE');
        let columns: any[] = [];
        if (isPackage) {
            columns = [
                {
                    title: language.getText('couponGiftName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                },
                {
                    title: language.getText('giftsxTime'),
                    dataIndex: 'startTime',
                    key: 'startTime',
                    width: 180,
                },
                {
                    title: language.getText('giftInvalidTime'),
                    dataIndex: 'endTime',
                    key: 'endTime',
                    width: 180,
                },
                {
                    title: language.getText('status'),
                    dataIndex: 'executeStatus',
                    key: 'executeStatus',
                    render: (executeStatus: string) => {
                        return (
                            <span>
                                {executeStatus === 'NOT_STARTED'
                                    ? language.getText('notStarted')
                                    : executeStatus === 'STARTED'
                                    ? language.getText('inprogress')
                                    : language.getText('end')}
                            </span>
                        );
                    },
                },
            ];
        } else {
            columns = [
                {
                    title: language.getText('components.Coupon.name'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                },
                {
                    title: language.getText('couponBaCode'),
                    dataIndex: 'batchNo',
                    key: 'batchNo',
                },
                {
                    title: language.getText('couponType'),
                    dataIndex: 'typeName',
                    key: 'typeName',
                },
                {
                    title: language.getText('setCouponType'),
                    dataIndex: 'source',
                    key: 'source',
                    render: (source: string) => {
                        return (
                            <span>
                                {source === 'ONLINE'
                                    ? language.getText('setOnline')
                                    : language.getText('setOffline')}
                            </span>
                        );
                    },
                },
                {
                    title: language.getText('couponStartTime'),
                    dataIndex: 'startTime',
                    key: 'startTime',
                },
                {
                    title: language.getText('couponEndTime'),
                    dataIndex: 'endTime',
                    key: 'endTime',
                },
                {
                    title: language.getText('status'),
                    dataIndex: 'executeStatus',
                    key: 'executeStatus',
                    render: (executeStatus: string) => {
                        return (
                            <span>
                                {executeStatus === 'NOT_STARTED'
                                    ? language.getText('notStarted')
                                    : executeStatus === 'STARTED'
                                    ? language.getText('inprogress')
                                    : language.getText('end')}
                            </span>
                        );
                    },
                },
            ];
        }
        if (!disabled) {
            columns = [
                ...columns,
                {
                    title: language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    fixed: 'right',
                    render: (i: any, row: any) => {
                        return (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                                disabled={disabled}
                            >
                                {language.getText('common.delete')}
                            </Button>
                        );
                    },
                },
            ];
        }
        return {
            rowKey: 'id',
            columns,
            scroll: { x: true },
            style: {
                marginTop: 20,
            },
        };
    };

    onRemove = (row: any) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onChange(result);
        };
    };

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: cloneDeep(data),
            pagination,
        };

        return (
            <div>
                <Table {...tableProps}></Table>
            </div>
        );
    }
}
