import * as React from 'react';
import { Tag, Popover } from 'antd';
import { ArrayMode } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayPopoverMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const { countSuffix, replaceEmpty = '', popoverStyle } = displayInfo;
        return (
            <div>
                {value && value.length > 0 ? value[0].name : replaceEmpty}{' '}
                {value && value.length > 0 && (
                    <Popover
                        placement={'right'}
                        overlayStyle={{ zIndex: 1000 }}
                        content={
                            <div
                                style={{
                                    maxWidth: 400,
                                    ...popoverStyle,
                                }}
                            >
                                {value.map((item: any, index: number) => (
                                    <Tag
                                        key={item.id}
                                        style={{
                                            maxWidth: 400,
                                            marginBottom: 10,
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        {item.name}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            {language.getText('inAll')}
                            {value.length}
                            {language.getText('ge')}
                            {countSuffix ? countSuffix : ''}
                        </span>
                    </Popover>
                )}
            </div>
        );
    }
}
