import React, { PureComponent, CSSProperties } from 'react';
import { TreeSelect, Spin } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { TreeNodeSimpleMode } from 'antd/lib/tree-select/interface';

import './index.less';

const { api } = services;

interface CityTreeSelectValue {
    id: string;
    name: string;
    /**
     * 父级id集合
     */
    parentIds?: string[];
}

interface CityTreeSelectProps {
    value: CityTreeSelectValue;
    style?: CSSProperties;
    onChange: (value: CityTreeSelectValue) => void;
}
export interface Region {
    /**
     * 地区id
     */
    id: number;
    /**
     * 地区名称
     */
    name: string;
    /**
     * 父级id
     */
    parentId: number;
    /**
     * 是否含有地区子级
     */
    hasChild: boolean;
    /**
     * 几级
     */
    level: number;
}
interface CityTreeSelectStates {
    /**
     * 展示的省市内容
     */
    treeNodes: TreeNodeSimpleMode[];
    loading: boolean;
    /**
     * 用于记录编辑页面时是否已经根据value的id获取所有父级信息
     */
    inited: boolean;
}

/**
 * 城市下拉选择框
 */
export class CityTreeSelect extends PureComponent<CityTreeSelectProps, CityTreeSelectStates> {
    state: CityTreeSelectStates = {
        treeNodes: [],
        loading: false,
        inited: false,
    };

    /**
     * 获取所有城市
     */
    componentDidMount() {
        const { value } = this.props;
        if (value?.id) {
            this.checkValueInTreeNodes(value);
        } else {
            this.getTreeNodes(0).then((treeNodes) => {
                this.setState({ treeNodes });
            });
        }
    }
    async componentWillReceiveProps(nextProps: CityTreeSelectProps) {
        const { value } = this.props;
        const { value: nextValue } = nextProps;
        if (!value?.id && nextValue?.id) {
            this.checkValueInTreeNodes(nextValue);
        }
    }
    /**
     * 用于编辑页面时，根据后端返回的地区父级信息来加载当前value对应的所有上级地区信息
     */
    checkValueInTreeNodes = async (value: CityTreeSelectValue) => {
        const { treeNodes, inited } = this.state;
        if (inited) {
            return;
        }
        if (treeNodes.some((node) => node.value === value.id)) {
            this.setState({ inited: true });
            return;
        }
        const { parentIds = [], id } = value;
        const ids = parentIds?.filter((i) => i !== id);
        if (!ids?.length) {
            return;
        }
        this.setState({ loading: true });
        const results = (await Promise.all(ids.map((id) => this.getTreeNodes(id)))).reduce(
            (t, i) => t.concat(i),
            []
        );
        this.setState({ treeNodes: results, inited: true, loading: false });
        const item = results.find((i) => i.value === value.id);
        if (!value.name && item) {
            const { onChange } = this.props;
            onChange({ ...value, name: item.title as string });
        }
    };
    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }
    onLoadData = async (treeNode: { props: { id: number | string } }) => {
        const { id } = treeNode.props;
        const treeNodes = await this.getTreeNodes(id);
        const { treeNodes: originTreeNodes } = this.state;
        if (treeNodes.length && !originTreeNodes.find((i) => i.value === treeNodes[0].value)) {
            this.setState({ treeNodes: [...originTreeNodes, ...treeNodes] });
        }
    };

    getTreeNodes = async (id: string | number) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: `/admin/regions/${id}/children`,
        };
        const result = await api.get<Region[]>({}, config).catch(errorHandle);
        return result ? this.mapRegionsToTreeNodes(result) : [];
    };
    mapRegionsToTreeNodes = (regions: Region[]): TreeNodeSimpleMode[] => {
        return regions.map((region) => ({
            ...region,
            pId: region.parentId,
            value: region.id + '',
            title: region.name,
            isLeaf: !region.hasChild,
            isload: true,
        }));
    };
    getParentIds = (id: string) => {
        const { treeNodes } = this.state;
        const ids: string[] = [];
        const item = treeNodes.find((node) => String(node.value) === String(id));
        if (!item) {
            return [];
        }
        const findParentId = (id: string) => {
            const item = treeNodes.find((node) => String(node.value) === String(id));
            if (!item) {
                return;
            }
            const pId = String(item.pId);
            ids.unshift(pId);
            if (pId !== '0') {
                findParentId(pId);
            }
        };
        findParentId(String(item.value));
        return ids;
    };
    changeCity = (value: string, label: any[]) => {
        const { onChange } = this.props;
        onChange({
            id: value,
            name: label[0],
            parentIds: this.getParentIds(value),
        });
    };

    render() {
        const { treeNodes, loading } = this.state;
        const { value, style } = this.props;
        const cityCode = value?.id || '';
        return (
            <div className="select-city-code-wrap">
                <Spin spinning={loading}>
                    <TreeSelect
                        className="select-city-code"
                        treeDataSimpleMode
                        style={style}
                        value={cityCode}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={services.language.getText('selectPlease')}
                        onChange={this.changeCity}
                        loadData={this.onLoadData}
                        treeData={treeNodes}
                    />
                </Spin>
            </div>
        );
    }
}
