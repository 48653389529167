import React, { Component } from 'react';
import { Modal, message as AntMessage } from 'antd';
import {
    WechatMpnewsarticle,
    WechatMsgType,
    WechatMsg,
    WechatMiniprogrampage,
    WechatText,
    WechatImage,
} from '../../components/wechant-content';
import { WechatOfficialAutoReplySettingPageMiniprogrampageModalVisibleModal } from '../wechat-official-auto-reply-setting-page-miniprogrampage-modal';
import { WechatOfficialAutoReplySettingPageTextModal } from '../wechat-official-auto-reply-setting-page-text-modal';
import { WechatOfficialAutoReplySettingPageImageModal } from '../wechat-official-auto-reply-setting-page-image-modal';
import { WechatOfficialAutoReplySettingPageMpnewsarticleModal } from '../wechat-official-auto-reply-setting-page-mpnewsarticle-modal';
import { cloneDeep } from 'lodash';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { privilege, language } = services;

interface Props {
    appId: string;
    msgIndex: number;
    msg: WechatMsg;
    onChange: (msgIndex: number, msg: WechatMsg) => void;
    onDelete: (msgIndex: number) => void;
    disabled?: boolean;
    // 只读 只进行页面展示 编辑需要跳转关注公众号回复
    readOnly?: boolean;
}

interface States {
    /**
     * 图片弹层
     */
    imageModalVisible: boolean;
    /**
     * 文本弹层
     */
    textModalVisible: boolean;
    /**
     * 小程序卡片弹层
     */
    miniprogrampageModalVisible: boolean;
    /**
     * 图文弹层
     */
    mpnewsarticleModalVisible: boolean;
}

/**
 * 回复内容模块
 */
export class WechatOfficialAutoReplySettingPageMsgItem extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            imageModalVisible: false,
            textModalVisible: false,
            miniprogrampageModalVisible: false,
            mpnewsarticleModalVisible: false,
        };
    }
    navigationAutoReplyConfig = () => {
        if (!privilege.check('followReplyMessage', 'full')) {
            AntMessage.warning(language.getText('privilegeFollowReplyMessage'));
            return;
        }
        window.open('#/wechat-official-auto-reply');
    };

    onDelete = () => {
        const { readOnly } = this.props;
        if (readOnly) {
            this.navigationAutoReplyConfig();
            return;
        }
        Modal.confirm({
            content: services.language.getText('sfqrsccthf'),
            iconType: 'warning',
            okType: 'danger',
            onOk: () => {
                const { msgIndex, onDelete } = this.props;
                onDelete(msgIndex);
            },
        });
    };

    onEdit = () => {
        const { msg, readOnly } = this.props;
        if (readOnly) {
            this.navigationAutoReplyConfig();
            return;
        }
        if (msg.msgtype === WechatMsgType.TEXT) {
            this.hardelTextModalVisiblel();
        }
        if (msg.msgtype === WechatMsgType.IMAGE) {
            this.hardelImageModalVisible();
        }
        if (msg.msgtype === WechatMsgType.MINIPROGRAMPAGE) {
            this.hardelMiniprogrampageModalVisible();
        }
        if (msg.msgtype === WechatMsgType.MPNEWSARTICLE) {
            this.hardelMpnewsarticleModalVisible();
        }
    };

    renderInfo = () => {
        const { msg } = this.props;
        if (msg.msgtype === WechatMsgType.TEXT) {
            //文本渲染
            if (!msg.text) {
                return null;
            }
            return (
                <div className="text-left">
                    <div
                        className="text-left-info"
                        dangerouslySetInnerHTML={{ __html: msg.text?.content }}
                    ></div>
                </div>
            );
        }
        if (msg.msgtype === WechatMsgType.IMAGE) {
            //图片渲染
            const image =
                msg.image && msg.image.url
                    ? msg.image.url
                    : require('../images/icon-background.png');
            return (
                <div className="image-left">
                    <div>
                        <img className="image" alt="" src={image} />
                    </div>
                </div>
            );
        }
        if (msg.msgtype === WechatMsgType.MINIPROGRAMPAGE) {
            //小程序卡片渲染
            const thumbMediaInfoUrl =
                msg.miniprogrampage && msg.miniprogrampage.thumbMediaInfo
                    ? msg.miniprogrampage.thumbMediaInfo.url
                    : require('../images/icon-background.png');
            const logoImage = require('../images/icon-logo.png');
            const miniImage = require('../images/icon-mini.png');
            return (
                <div className="miniprogrampage-left">
                    <div className="miniprogrampage-left-info">
                        <div>
                            <img width={18} height={18} alt="" src={logoImage} />
                            &nbsp;&nbsp;{services.language.getText('xcx')}
                        </div>
                        <div className="title">
                            <span>{msg.miniprogrampage?.title}</span>
                        </div>
                        <div className="img-wrap">
                            <div className="img-wrap-background">
                                <div
                                    style={{
                                        width: '210px',
                                        height: '171px',
                                        backgroundSize: 'cover',
                                        backgroundImage: 'url(' + thumbMediaInfoUrl + ')',
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="miniprogrampage-left-footer">
                            <img width={10} height={10} alt="" src={miniImage} />
                        </div>
                    </div>
                </div>
            );
        }
        if (msg.msgtype === WechatMsgType.MPNEWSARTICLE) {
            //图文渲染
            return (
                <div className="mpnewsarticle-left">
                    <div>
                        <img
                            className="mpnewsarticle-image"
                            alt=""
                            src={msg.mpnewsarticle?.thumbUrl}
                        />
                    </div>
                    <div className="mpnewsarticle-info">
                        <div className="mpnewsarticle-title">{msg.mpnewsarticle?.title}</div>
                        <div className="mpnewsarticle-text">{msg.mpnewsarticle?.digest}</div>
                        <div className="mpnewsarticle-time">
                            {services.language.getText('gxsj_1')}&nbsp;&nbsp;
                            <span className="mpnewsarticle-time-bold">
                                {msg.mpnewsarticle?.updateTime}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
    };

    hardelImageModalVisible = () => {
        const { imageModalVisible } = this.state;
        this.setState({
            imageModalVisible: !imageModalVisible,
        });
    };

    hardelTextModalVisiblel = () => {
        const { textModalVisible } = this.state;
        this.setState({
            textModalVisible: !textModalVisible,
        });
    };

    hardelMiniprogrampageModalVisible = () => {
        const { miniprogrampageModalVisible } = this.state;
        this.setState({
            miniprogrampageModalVisible: !miniprogrampageModalVisible,
        });
    };

    hardelMpnewsarticleModalVisible = () => {
        const { mpnewsarticleModalVisible } = this.state;
        this.setState({
            mpnewsarticleModalVisible: !mpnewsarticleModalVisible,
        });
    };

    imageModalConfrim = (wechatImage: WechatImage) => {
        const { msgIndex, msg, onChange } = this.props;
        const newMsg: WechatMsg = cloneDeep(msg);
        if (newMsg) {
            newMsg.image = wechatImage;
        }
        onChange(msgIndex, newMsg);
        this.hardelImageModalVisible();
    };

    textModalConfrim = (value: WechatText) => {
        const { msgIndex, msg, onChange } = this.props;
        const newMsg: WechatMsg = cloneDeep(msg);
        if (newMsg) {
            newMsg.text = value;
        }
        onChange(msgIndex, newMsg);
        this.hardelTextModalVisiblel();
    };

    mpnewsarticleModalConfrim = (wechatMpnewsarticle: WechatMpnewsarticle) => {
        const { msgIndex, msg, onChange } = this.props;
        const newMsg: WechatMsg = cloneDeep(msg);
        if (newMsg) {
            newMsg.mpnewsarticle = wechatMpnewsarticle;
        }
        onChange(msgIndex, newMsg);
        this.hardelMpnewsarticleModalVisible();
    };

    miniprogrampageModalConfrim = (miniprogrampage: WechatMiniprogrampage) => {
        const { msgIndex, msg, onChange } = this.props;
        const newMsg: WechatMsg = cloneDeep(msg);
        if (newMsg) {
            newMsg.miniprogrampage = miniprogrampage;
        }
        onChange(msgIndex, newMsg);
        this.hardelMiniprogrampageModalVisible();
    };

    render() {
        const {
            imageModalVisible,
            textModalVisible,
            miniprogrampageModalVisible,
            mpnewsarticleModalVisible,
        } = this.state;
        const { msg, disabled, appId } = this.props;
        const deleteImage = require('../images/icon-delete.png');
        const editImage = require('../images/icon-edit.png');
        return (
            <div className="wechat-official-auto-reply-setting-page-msg-item">
                {this.renderInfo()}
                <div className="right" hidden={disabled}>
                    <div>
                        <img onClick={this.onEdit} width={20} alt="" src={editImage} />
                        <img
                            onClick={this.onDelete}
                            style={{ marginLeft: '10px' }}
                            width={20}
                            alt=""
                            src={deleteImage}
                        />
                    </div>
                </div>
                {miniprogrampageModalVisible && (
                    <WechatOfficialAutoReplySettingPageMiniprogrampageModalVisibleModal
                        appId={appId}
                        visible={miniprogrampageModalVisible}
                        onConfrim={this.miniprogrampageModalConfrim}
                        onCancel={this.hardelMiniprogrampageModalVisible}
                        editInfo={msg.miniprogrampage}
                    />
                )}
                {/*添加编辑文本弹层*/}
                {textModalVisible && (
                    <WechatOfficialAutoReplySettingPageTextModal
                        visible={textModalVisible}
                        onConfrim={this.textModalConfrim}
                        onCancel={this.hardelTextModalVisiblel}
                        editInfo={msg.text}
                        appId={appId}
                    />
                )}
                {/*添加编辑图片弹层*/}
                {imageModalVisible && (
                    <WechatOfficialAutoReplySettingPageImageModal
                        appId={appId}
                        visible={imageModalVisible}
                        onConfrim={this.imageModalConfrim}
                        onCancel={this.hardelImageModalVisible}
                        editInfo={msg.image}
                    />
                )}
                {/*添加图文弹层*/}
                {mpnewsarticleModalVisible && (
                    <WechatOfficialAutoReplySettingPageMpnewsarticleModal
                        appId={appId}
                        visible={mpnewsarticleModalVisible}
                        onConfrim={this.mpnewsarticleModalConfrim}
                        onCancel={this.hardelMpnewsarticleModalVisible}
                        editInfo={msg.mpnewsarticle}
                    />
                )}
            </div>
        );
    }
}
