import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

const { getText } = services.language;

export interface ProductSettingEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class productSettingEditFormView extends PureComponent<ProductSettingEditFormProps> {
    static defaultProps: any = {};

    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef, params } = this.props;

        let fields = [
            { property: 'subsiteInfo.subsiteId' },
            { property: 'merchantsInfo.merchantIds' },
        ];

        let formProps = {
            entity,
            componentId: 'ProductSettingEditFormContainer',
            params,
            wrappedComponentRef,
            onSubmit,
            className: 'product-setting-edit-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 850 },
            fields: fields,
            submit: { text: getText('bc') },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: getText('qx'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const subsiteId = get(entityFields, 'subsiteInfo.subsiteId');
        const merchantIds = get(entityFields, 'merchantsInfo.merchantIds');
        const isMerchantsEmpty = !merchantIds || !merchantIds.length;
        if (!subsiteId) {
            AntMessage.warn(getText('mdbnwk'));
            return false;
        }
        if (isMerchantsEmpty) {
            AntMessage.warn(getText('zjbnwk'));
            return false;
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('ProductSettingEditFormContainer'));
            },
        }
    );
}
export const ProductSettingEditFormView = connect(mapDispatchToProps)(productSettingEditFormView);
