import { message } from 'antd';
import { isNil } from 'lodash';
import { services } from '@comall-backend-builder/core';

function numberWithCommas(number: number) {
    var numberString = number.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(numberString)) numberString = numberString.replace(pattern, '$1,$2');
    return numberString;
}

export function formatNumber(amount: number, tips: string) {
    if (amount > 10000000) return (amount / 10000000).toFixed(2) + '千万' + tips;
    if (amount > 10000) return (amount / 10000).toFixed(2) + '万' + tips;
    if (amount > 1000) {
        return numberWithCommas(Math.floor(amount)) + tips;
    }

    if (amount >= 0) return amount + tips;
    return '-' + tips;
}

export function copyToClipboard(text: string) {
    const inputEl = document.createElement('input');
    inputEl.value = text;
    document.body.appendChild(inputEl);
    inputEl.select();
    if (document.execCommand('copy')) {
        message.success(services.language.getText('copySuccess'));
    } else {
        message.warn('复制失败');
    }
    document.body.removeChild(inputEl);
}

export interface URLKeys {
    [key: string]: string | number | undefined;
}

export const searchStringify = (params: URLKeys): string => {
    let search = '';
    let count = 0;
    const keys = Object.keys(params);
    const total = keys.length;
    for (const key of keys) {
        count++;
        if (isNil(params[key])) {
            continue;
        }
        if (count === total) {
            search += `${key}=${params[key]}`;
            break;
        }
        search += `${key}=${params[key]}&`;
    }
    return search;
};

export const searchWebParse = (search: string): URLKeys => {
    return search.split('&').reduce((memo, param) => {
        const indexes = param.split('=');
        return {
            ...memo,
            [indexes[0]]: indexes[1],
        };
    }, {});
};
