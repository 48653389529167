import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectProductGoods } from './select-product-goods';
import { ProductGoodsSelectDisplay } from './product-goods-select-display';

/**
 * 选择货品
 */
export class ProductGoodsSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any, displayInfo: any): JSX.Element {
        return <ProductGoodsSelectDisplay data={value} modifiable={false} onChange={() => {}} />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectProductGoods {...displayInfo} />;
    }
}
