import React, { Component } from 'react';
import { find, isEqual, map } from 'lodash';
import { Select } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ActivityTagType } from '../../../../config/cms/mall-activity-tag';

const { api } = services;

interface Option {
    /**
     * 候选项id
     */
    id: string;
    /**
     * 候选项显示名称
     */
    name: string;
}
interface ArrayTagIdsSelectProps {
    row: any;
    name: string;
    /**
     * 选中值
     */
    value: Array<Option>;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<Option>, name: string) => void;
    style: any;
}

interface ArrayTagIdsSelectStates {
    options: Array<Option>;
    allOptions: Array<Option>;
    searchKeyword: string;
}

export class ArrayTagIdsSelect extends Component<ArrayTagIdsSelectProps, ArrayTagIdsSelectStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
            searchKeyword: '',
            allOptions: [],
        };
    }
    componentWillReceiveProps(nextProps: Readonly<ArrayTagIdsSelectProps>): void {
        const prevSubsiteIds = this.getSubsiteIds(this.props);
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        this.onSubsiteIdsChange(prevSubsiteIds, nextSubsiteIds);
    }
    changeValue = (value: Option[]) => {
        const { name, onChange } = this.props;
        onChange(value, name);
    };
    /**
     * 门店发生变更时，处理候选项和过滤标签
     */
    onSubsiteIdsChange = async (
        prevSubsiteIds: string | undefined,
        nextSubsiteIds: string | undefined
    ) => {
        if (prevSubsiteIds === nextSubsiteIds) {
            return;
        }
        if (nextSubsiteIds) {
            const options = await this.loadOptions(nextSubsiteIds);
            const { value } = this.props;
            const newValue = (value || []).filter((item) =>
                options.some((o) => o.id === String(item.id))
            );
            if (!isEqual(newValue, value)) {
                this.changeValue(newValue);
            }
        } else {
            // 门店清空时要清空已选tag和options
            this.clearOptions();
        }
    };
    clearOptions = () => {
        this.changeValue([]);
        this.setState({ options: [], allOptions: [] });
    };
    getSubsiteIds = (props: ArrayTagIdsSelectProps) => {
        const { row } = props;
        return map(row.subsiteId || [], 'id').join(',') || undefined;
    };
    /**
     * 加载候选项
     */
    loadOptions = async (subsiteIds: string, keyword?: string) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/activity_tags/find_all',
        };
        const result: Option[] = await api.get(
            { name: keyword, subsiteIds, type: ActivityTagType.CREDIT_ESHOP },
            config
        );
        const options = result.map(({ id, ...item }) => ({ ...item, id: String(id) }));
        this.setState({
            options,
            allOptions: !keyword ? options : this.state.allOptions,
            searchKeyword: keyword || '',
        });
        return options;
    };
    /**
     * 下拉框选中tag
     * @param optionIds
     */
    selectedOption = (optionIds: string[]) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/activity_tags',
        };
        let { options, allOptions } = this.state;
        const newValues: any[] = [];
        let addName = '';
        optionIds.forEach((optionId: string) => {
            const hasOption = find(allOptions, { id: optionId });
            /**
             * 如果选项不存在则新增
             */
            if (!hasOption) {
                addName = optionId.trim();
            } else {
                newValues.push(hasOption);
            }
        });
        if (addName) {
            api.post(
                {
                    name: addName,
                    subsiteIds: this.getSubsiteIds(this.props),
                    type: ActivityTagType.CREDIT_ESHOP,
                },
                config
            ).then((res: any) => {
                allOptions.push({ id: String(res), name: addName });
                options = allOptions;
                newValues.push({ id: String(res), name: addName });

                this.setState({ options, allOptions, searchKeyword: '' }, () => {
                    this.changeValue(newValues);
                });
            });
        } else {
            this.setState({ searchKeyword: '' });
            this.changeValue(newValues);
        }
    };
    onSearch = (keyword: string) => {
        const susbiteIds = this.getSubsiteIds(this.props);
        if (!susbiteIds) {
            return null;
        }
        this.loadOptions(susbiteIds, keyword);
    };
    render() {
        const subsiteIds = this.getSubsiteIds(this.props);
        if (!subsiteIds) {
            return (
                <div style={{ color: 'red' }}>
                    {services.language.getText('selectBeforeMerchant')}
                </div>
            );
        }
        const { value, style } = this.props;
        const { options, allOptions, searchKeyword } = this.state;

        const hasSearchKeyword = find(allOptions, { name: searchKeyword });

        return (
            <Select
                style={style}
                placeholder={services.language.getText('selectPlease')}
                mode="multiple"
                onChange={(ids: string[]) => {
                    this.selectedOption(ids);
                }}
                autoClearSearchValue
                showSearch
                filterOption={false}
                value={value ? value.map(({ id }) => String(id)) : undefined}
                onSearch={this.onSearch}
            >
                {!hasSearchKeyword && searchKeyword && (
                    <Select.Option value={searchKeyword}>{searchKeyword}</Select.Option>
                )}

                {options &&
                    options.map((attribute: any) => {
                        return (
                            <Select.Option key={attribute.id} value={attribute.id}>
                                {attribute.name}
                            </Select.Option>
                        );
                    })}
            </Select>
        );
    }
}
