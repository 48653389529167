import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../../../services';
const api = services.api;

export const config: Config = {
    entities: {
        PointExchangeRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/POINT-EXCHANGE-COUPON`,
            apiPath: '/loader/admin/point_exchange_record',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 240 } },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dhsj>>',
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                memberId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                },
                changeCent: {
                    type: 'string',
                    displayName: '<<changeCent>>',
                },
                procType: {
                    type: 'string',
                    displayName: '<<dhlx_1>>',
                },
                pointExchangeAwardList: {
                    type: 'array.popover',
                    displayName: '<<dhjp>>',
                },
                billId: {
                    type: 'string',
                    displayName: '<<djlsh>>',
                },
                exchangeTime: {
                    type: 'string',
                    displayName: '<<dhsj>>',
                },
            },
        },
    },
    components: {
        PointExchangeRecordView: {
            component: 'Viewport',
            entity: 'PointExchangeRecordEntity',
        },
        PointExchangeRecordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'PointExchangeRecordFilter',
                },
                { component: 'PointExchangeRecordTable' },
            ],
        },
        PointExchangeRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        PointExchangeRecordTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginTop: 17,
                            marginBottom: 24,
                        },
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters
                            );
                            if (!params.mobile) {
                                delete params.mobile;
                            }
                            if (params.subsiteId) {
                                params.subsiteIds = params.subsiteId.id
                                    ? params.subsiteId.id
                                    : params.subsiteId;
                                delete params.subsiteId;
                            }
                            if (params.dateRange) {
                                params.startTime = params.dateRange.start + ' 00:00:00';
                                params.endTime = params.dateRange.end + ' 23:59:59';
                                delete params.dateRange;
                            }
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/POINT-EXCHANGE-COUPON`,
                                apiPath: '/admin/point_exchange_record/export',
                            };

                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'memberId',
                        },
                        {
                            property: 'changeCent',
                        },
                        {
                            property: 'procType',
                        },
                        {
                            property: 'pointExchangeAwardList',
                            displayConfig: {
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'billId',
                        },
                        {
                            property: 'exchangeTime',
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/point-exchange-record',
                    component: 'PointExchangeRecordView',
                    breadcrumbName: '<<pointExchangeRecord>>',
                    privilege: {
                        path: 'pointExchangeRecord',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'PointExchangeRecordPage' }],
                },
            ],
        },
    ],
};
