import React, { PureComponent } from 'react';
import { api, localStorage } from '@comall-backend-builder/core/lib/services';
import { Button, message as AntMessage, Modal, Switch } from 'antd';
import { find, forEach, isNil } from 'lodash';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';
import {
    manualOptions,
    SceneType,
    SendStatus,
    specialOptions,
    targetOptions,
    WechantTemplatesOption,
    wechantTemplatesTargetOptions,
    SpecialType,
    linkTypeList,
} from '../common';
import { searchWebParse } from '../../../services/utils';
import { MessagesSubscriptionFindListItemEditView } from '../messages-subscription-find-list-item-edit-view';
import './index.less';

import { MessagesSubscriptioEmail } from './message-subscription-email';
import { MessagesSubscriptioWechatAndAlipay } from './message-subscription-wechat-alipay';
import { MessagesSubscriptioSms } from './message-subscription-sms';
import { MessagesSubscriptioPushAndSiteMail } from './message-subscription-push-sitemail';
import { getI18NLinkNameById } from '../../../components/link-type-effect-path/config';

export enum MsgChannelType {
    /**
     * 微信订阅消息
     */
    WEAPP = 'WEAPP',
    /**
     * 微信公众号模板消息
     */
    WECHAT_OFFICIAL_ACCOUNT = 'WECHAT_OFFICIAL_ACCOUNT',
    /**
     * 支付宝小程序订阅消息
     */
    ALIPAY_SUBSCRIBE_MSG = 'ALIPAY_SUBSCRIBE_MSG',
    /**
     * 邮件
     */
    EMAIL = 'EMAIL',
    /**
     * 站内信
     */
    SITEMAIL = 'SITEMAIL',
    /**
     * 短信
     */
    SMS = 'SMS',
    /**
     * apppush
     */
    APP_PUSH = 'PUSH',
}
/**
 * 模板消息列表项
 */
export interface MessagesSubscriptionFindListItemInfo {
    msgChannelType: MsgChannelType;
    code: string;
    serviceItem: string;
    id: number;
    name: string;
    params: Array<{ name: string; value: string; color?: string }>;
    targetAppId: string;
    targetUrl: string;
    sceneRemark: string;
    scene: SceneType;
    createTime: string;
    creator: string;
    templateId: string;
    subscribeMemberCount: number;
    supportManualSending: boolean;
    sendRecord: {
        id: string;
        sendStatus: SendStatus;
        reason: string;
        actualSendCount: number;
        expectedSendCount: number;
        successSendCount: number;
    };
    wechatTemplateId: string;
    templateDefinitionId: number;
    showEditButton: boolean;
    /**
     * 区分展示字段的类型 mediumtext表示邮件正文
     */
    fieldType: string;

    status: string;

    /**
     * 链接类型
     */
    linkType: {
        type: string;
        params: any;
    };
}

interface MessagesSubscriptionFindListItemProps extends MessagesSubscriptionFindListItemInfo {
    /**
     * tab名称
     */
    activeKeyName: string;
    /**
     * 其他参数
     */
    refreshList: () => void;
}

interface MessagesSubscriptionFindListItemStates {
    visible: boolean;
}

/**
 * 模板消息Item
 */
export class MessagesSubscriptionFindListItem extends PureComponent<
    MessagesSubscriptionFindListItemProps,
    MessagesSubscriptionFindListItemStates
> {
    isSubscriptionMessage = () => {
        const { msgChannelType } = this.props;
        return (
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG
        );
    };

    isSubmit = false;
    handleSwitchChange = (checked: boolean) => {
        const { msgChannelType } = this.props;
        if (this.isSubmit) {
            return;
        }
        this.isSubmit = true;
        if (checked) {
            let storeInfo: any = localStorage.get('wechantTemplatesStore');
            let subsiteId = '';
            if (storeInfo && storeInfo.id) {
                subsiteId = storeInfo.id;
            }
            let params = this.getPostParams() as any;

            // 如果不是小程序和公众号模板消息 则传message_channel_type 参数
            if (
                msgChannelType !== MsgChannelType.WEAPP &&
                msgChannelType !== MsgChannelType.WECHAT_OFFICIAL_ACCOUNT
            ) {
                params.msgChannelType = msgChannelType;
            }
            api.post(
                {
                    subsiteId: subsiteId,
                    ...params,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: `/admin/template_definitions`,
                }
            )
                .then(() => {
                    this.refreshList();
                    setTimeout(() => {
                        this.isSubmit = false;
                    }, 1000);
                })
                .catch((err: any) => {
                    this.isSubmit = false;
                    const {
                        body: { err_msg },
                    } = err.response;
                    if (err_msg) {
                        AntMessage.warning(err_msg);
                    }
                });
        } else {
            if (this.isSubscriptionMessage()) {
                Modal.confirm({
                    title: services.language.getText('wxts'),
                    content: services.language.getText('rgbzydyyhjhbqk'),
                    onOk: () => {
                        this.onClose();
                    },
                    onCancel: () => {
                        this.isSubmit = false;
                    },
                    okText: services.language.getText('common.confirm'),
                    cancelText: services.language.getText('common.cancel'),
                });
            } else {
                this.onClose();
            }
        }
    };

    onClose = () => {
        const { msgChannelType } = this.props;
        const id = this.props.id;
        if (!id) {
            return;
        }
        // 如果不是小程序和公众号模板消息 则传message_channel_type 参数
        let type: string = '';
        if (
            msgChannelType !== MsgChannelType.WEAPP &&
            msgChannelType !== MsgChannelType.WECHAT_OFFICIAL_ACCOUNT
        ) {
            type = msgChannelType;
        }

        api.delete(
            {
                message_channel_type: type,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/template_definitions/${id}?message_channel_type=${type}`,
            }
        )
            .then(() => {
                this.refreshList();
                setTimeout(() => {
                    this.isSubmit = false;
                }, 1000);
            })
            .catch((err: any) => {
                this.isSubmit = false;
                const {
                    body: { err_msg },
                } = err.response;
                if (err_msg) {
                    AntMessage.warning(err_msg);
                }
            });
    };

    getPostParams = () => {
        const { code, scene, params, wechatTemplateId } = this.props;
        const top = params.find((item) => item.name === 'top');
        const bottom = params.find((item) => item.name === 'bottom');
        return {
            code,
            topContent: top ? top.value : '',
            bottomContent: bottom ? bottom.value : '',
            targetUrl:
                targetOptions[scene] && targetOptions[scene].length > 0
                    ? targetOptions[scene][0].path
                    : undefined,
            wechatTemplateId,
        };
    };

    refreshList = () => {
        const { refreshList } = this.props;
        refreshList();
    };

    onDelete = () => {
        const { templateDefinitionId } = this.props;
        if (!templateDefinitionId) {
            return;
        }
        const store: any = localStorage.get('wechantTemplatesStore');
        Modal.confirm({
            title: services.language.getText('sfqrscgmb'),
            onOk: () => {
                api.delete(
                    {
                        templateDefinitionId: templateDefinitionId,
                        subsiteId: store.id,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: `/admin/template_definitions/manual/by_template_definition_id?template_definition_id=${templateDefinitionId}&subsite_id=${store.id}`,
                    }
                ).then(
                    () => {
                        this.refreshList();
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                );
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
        });
    };

    renderTargetText = () => {
        const props = this.props;
        if (!props) {
            return null;
        }
        let targetUrl = props.targetUrl || '';
        //1.如果后台返回的是文字，而不是页面路径则直接展示
        if (
            !targetUrl.includes('pages') &&
            !targetUrl.includes('sub-packages') &&
            !targetUrl.includes('subpackages') &&
            targetUrl
        ) {
            return (
                <div className={innerClass.footerText}>
                    <span>
                        {services.language.getText('tzldy')}：{targetUrl}
                    </span>
                </div>
            );
        }
        //2.如果是页面路径,通过编辑层下拉项反查
        const scene = props.scene;
        let options = wechantTemplatesTargetOptions[scene];
        //后续模版类型越来越多，找不到对应的，赋值manualOptions 查找试试
        if (!options || !options.length) {
            options = manualOptions;
        }
        let targetName = '';
        let targetLabel = '';
        options.forEach((option: WechantTemplatesOption) => {
            if (option.children) {
                option.children.forEach((o: WechantTemplatesOption) => {
                    if (targetUrl.includes(o.value)) {
                        targetLabel = o.label || '';
                    }
                });
            } else {
                if (targetUrl.includes(option.value)) {
                    targetLabel = option.label || '';
                }
            }
        });

        const urlVo = targetUrl.split('?');
        const pageParams: string = urlVo && urlVo.length >= 1 ? urlVo[1] : '';
        const params = urlVo && urlVo.length > 1 ? searchWebParse(pageParams) : null;
        if (params && params.name) {
            targetName = (params.name as string) || '';
        }

        //3.如果以上还是找不到，有一些后台返回的特殊链接定义，返回名称
        if (!targetLabel) {
            const nameVo = find(specialOptions, (option) => targetUrl.includes(option.value));
            if (nameVo) {
                targetLabel = nameVo.label;
            }
        }
        //4.输出看看有哪些模板是找不到的
        if (!targetLabel) {
            console.log(props.name, props.scene, props.targetUrl);
        }
        return targetLabel ? (
            <div className={innerClass.footerText}>
                <span>
                    {services.language.getText('tzldy')}：{targetLabel}
                </span>
                {targetName && <span>-{targetName}</span>}
            </div>
        ) : null;
    };

    renderSwitch = (status: boolean) => {
        const props = this.props;
        if (!props) {
            return null;
        }
        if (props.scene === SceneType.ACTIVITY_NOTICE) {
            return null;
        }
        return (
            <div className={innerClass.switch}>
                {props.scene === SceneType.MANUAL_ADD ? (
                    <Button
                        type="link"
                        onClick={this.onDelete}
                        style={{ padding: 0, marginTop: '-10px' }}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                ) : (
                    <Switch
                        checkedChildren={services.language.getText('kai')}
                        unCheckedChildren={services.language.getText('guan')}
                        onChange={this.handleSwitchChange.bind(this)}
                        checked={status}
                    />
                )}
            </div>
        );
    };

    renderHeaderItem = (title: string, value: string) => {
        return (
            <div className={innerClass.remark}>
                <div className={innerClass.remarkTitle}>{title}</div>
                <div className={innerClass.remarkValue}>{value}</div>
            </div>
        );
    };

    renderContent = () => {
        const props = this.props;
        if (!props) {
            return;
        }
        let top, bottom;
        const other: any = [];
        if (props && props.params) {
            top = find(props.params, { name: 'top' });
            bottom = find(props.params, { name: 'bottom' });
            forEach(props.params, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    other.push(param);
                }
            });
        }
        const goImage = require('../components/images/icon-miniprogram-right.png');
        const miniImage = require('../components/images/icon-miniprogram.png');
        const pictureUrl = {
            [MsgChannelType.WEAPP]: require('./images/weapp.jpg'),
            [MsgChannelType.WECHAT_OFFICIAL_ACCOUNT]: require('./images/wechat.jpg'),
            [MsgChannelType.ALIPAY_SUBSCRIBE_MSG]: require('./images/alipay.jpg'),
            [MsgChannelType.EMAIL]: require('./images/email.png'),
            [MsgChannelType.SITEMAIL]: require('./images/mail.png'),
            [MsgChannelType.SMS]: require('./images/ms.png'),
            [MsgChannelType.APP_PUSH]: require('./images/apppush.png'),
        };
        const typeName = {
            [MsgChannelType.WEAPP]: services.language.getText('wxxcxdyxx'),
            [MsgChannelType.WECHAT_OFFICIAL_ACCOUNT]: services.language.getText('wxgzhmbxx'),
            [MsgChannelType.ALIPAY_SUBSCRIBE_MSG]: services.language.getText('zfbxcxdyxx'),
            [MsgChannelType.EMAIL]: services.language.getText('mail'),
            [MsgChannelType.SITEMAIL]: services.language.getText('siteEmail'),
            [MsgChannelType.SMS]: services.language.getText('dx'),
            [MsgChannelType.APP_PUSH]: 'Apppush',
        };

        // 如果是微信消息订阅或者微信公众号模板消息
        const msgChannelType = props.msgChannelType;
        const isMessageAndWechat =
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT;
        // 如果是微信消息订阅 、支付宝、微信公众号模板消息
        const isWechantAndAlipay =
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT ||
            msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG;

        const messageStyle = {
            innerClass,
            top,
            bottom,
            goImage,
            miniImage,
        };
        return (
            <div className={innerClass.contentWrap}>
                <div className={innerClass.title}>
                    <img
                        className={innerClass.titlePic}
                        alt=""
                        src={pictureUrl[props.msgChannelType]}
                    />
                    {typeName[props.msgChannelType]}
                </div>
                <div className={innerClass.header}>
                    {props.sceneRemark &&
                        this.renderHeaderItem(services.language.getText('cjsm'), props.sceneRemark)}
                    {isMessageAndWechat && !isNil(props.code) ? (
                        <div>
                            {props.code.includes('OPENTM')
                                ? this.renderHeaderItem(
                                      services.language.getText('lsmb'),
                                      props.code
                                  )
                                : this.renderHeaderItem(
                                      services.language.getText('lmmb'),
                                      `${props.code}${
                                          props.serviceItem ? ` (${props.serviceItem})` : ''
                                      }`
                                  )}
                        </div>
                    ) : null}
                    {props.templateId &&
                        this.renderHeaderItem(
                            services.language.getText('templateId'),
                            props.templateId
                        )}
                    {this.getStatus() &&
                        this.isSubscriptionMessage() &&
                        this.renderHeaderItem(
                            services.language.getText('dyrs'),
                            `${props.subscribeMemberCount || 0}`
                        )}
                    {props.scene === SceneType.MANUAL_ADD &&
                        props.createTime &&
                        this.renderHeaderItem(
                            services.language.getText('tjsj_1'),
                            ` ${props.createTime}  ${props.creator}`
                        )}
                </div>
                {/** 微信公众号  小程序 支付宝小程序 */}
                {isWechantAndAlipay && (
                    <MessagesSubscriptioWechatAndAlipay
                        messageStyle={messageStyle}
                        messageList={other}
                        msgChannelType={msgChannelType}
                        name={props.name}
                    />
                )}
                {/** 邮件 */}
                {msgChannelType === MsgChannelType.EMAIL && (
                    <MessagesSubscriptioEmail messageStyle={messageStyle} messageList={other} />
                )}

                {/** 短信 */}
                {msgChannelType === MsgChannelType.SMS && (
                    <MessagesSubscriptioSms messageStyle={messageStyle} messageList={other} />
                )}
                {/** 站内信和Apppush */}
                {(msgChannelType === MsgChannelType.APP_PUSH ||
                    msgChannelType === MsgChannelType.SITEMAIL) && (
                    <MessagesSubscriptioPushAndSiteMail
                        messageStyle={messageStyle}
                        messageList={other}
                        name={props.name}
                    />
                )}
            </div>
        );
    };

    getStatus = () => {
        const props = this.props;
        if (
            props.msgChannelType === MsgChannelType.WEAPP ||
            props.msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT
        ) {
            return !props.templateId ? false : true;
        } else {
            return props.status === 'ENABLE' ? true : false;
        }
    };

    getPushAndSiteMailTargetName = () => {
        const { linkType, scene } = this.props;
        if (linkType) {
            if (linkType.type === SpecialType.PathRedirect) {
                return services.language.getText(linkTypeList[scene]);
            } else if (linkType.type === SpecialType.ParameterConcatenation) {
                if (linkType.params) {
                    if (linkType.params.includes('subpackages/electronic-bill/pages/list/index')) {
                        return services.language.getText('posReceipt');
                    }
                    if (
                        linkType.params.includes(
                            'sub-packages/offline-order-comment/pages/offline-order-comment/index'
                        )
                    ) {
                        return services.language.getText('fbpjy');
                    }
                }
            } else {
                return getI18NLinkNameById(linkType.type);
            }
        }
        return '';
    };

    render() {
        const props = this.props;
        if (!props) {
            return;
        }
        let status = this.getStatus();
        let className = !status ? innerClass.disabled : '';
        const style = {
            height: props.activeKeyName === '手动发送' ? '578px' : '500px',
        };
        let isEditMessage = true;
        const { msgChannelType } = props;

        if (
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT
        ) {
            isEditMessage = status;
        }
        /**
         * 站内信和push 的跳转落地页判断逻辑和微信公众号不同
         */
        const isPushAndSiteMail =
            msgChannelType === MsgChannelType.APP_PUSH ||
            msgChannelType === MsgChannelType.SITEMAIL;
        const pushAndSiteMailTargetName = this.getPushAndSiteMailTargetName();

        return (
            props && (
                <div className={prefix}>
                    <div className={classNames(innerClass.wrap, className)} style={style}>
                        {this.renderSwitch(status)}
                        {this.renderContent()}
                        {!isPushAndSiteMail ? (
                            <div className={innerClass.footer}>{this.renderTargetText()}</div>
                        ) : (
                            <div className={innerClass.footer}>
                                {pushAndSiteMailTargetName && (
                                    <div className={innerClass.footerText}>
                                        <span>
                                            {services.language.getText('tzldy')}：
                                            {pushAndSiteMailTargetName}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        {isEditMessage && (
                            <MessagesSubscriptionFindListItemEditView
                                itemProps={props}
                                refreshList={this.refreshList}
                            />
                        )}
                    </div>
                </div>
            )
        );
    }
}

const prefix = 'messages-subscription-find-list-item';
const innerClass = {
    wrap: `${prefix}__wrap`,
    disabled: `${prefix}__disabled`,
    switch: `${prefix}__switch`,
    contentWrap: `${prefix}__content-wrap`,
    title: `${prefix}__title`,
    titlePic: `${prefix}__title__pic`,
    header: `${prefix}__header`,
    remark: `${prefix}__remark`,
    remarkTitle: `${prefix}__remark__title`,
    remarkValue: `${prefix}__remark__value`,
    content: `${prefix}__content`,
    contentTitle: `${prefix}__content__title`,
    otherItems: `${prefix}__other-items`,
    emailItems: `${prefix}__email-items`,
    mailItems: `${prefix}__mail-items`,
    otherItem: `${prefix}__other-item`,
    otherItemTitle: `${prefix}__other-item__title`,
    otherItemValue: `${prefix}__other-item__value`,
    otherPicItemValue: `${prefix}__other-item__pic-value`,
    footer: `${prefix}__footer`,
    footerText: `${prefix}__footer-text`,
    wechat: `${prefix}__wechat`,
    richText: `${prefix}__rich-text`,
};
