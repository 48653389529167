import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { defaults, find } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Modal, message as AntMessage, Button, TreeSelect, message } from 'antd';
import { OrganizationType } from '../common';
import { TreeData } from '../../select-tree';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

interface Organization {
    children: Organization[];
    name: string;
    id: string;
    type: OrganizationTypeEnum;
}

enum OrganizationTypeEnum {
    ROOT = 'ROOT',
    TENANT = 'TENANT',
    SUBSITE_CUSTOM = 'SUBSITE_CUSTOM',
    SUBSITE = 'SUBSITE',
    MERCHANT_CUSTOM = 'MERCHANT_CUSTOM',
    MERCHANT_CUSTOM2 = 'MERCHANT_CUSTOM2',
    MERCHANT = 'MERCHANT',
}

export interface ChangeParentModalState {
    parentOrganizations: TreeData[];
    selectedParentNode: Organization | undefined;
}

/**
 * 变更组织
 */
class changeParentModal extends PureComponent<any, ChangeParentModalState> {
    state: ChangeParentModalState = {
        parentOrganizations: [],
        selectedParentNode: undefined,
    };

    componentDidMount() {
        const { selectedRows } = this.props;
        if (selectedRows && selectedRows.length) {
            // 所选数据均为统一父级，所以可以取第一条数据的父级属性
            const tempData = selectedRows[0];
            api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/organizations/${tempData.id}/optional_parent_organizations`,
                }
            ).then(
                (res: any) => {
                    const parentOrganizations = this.handleOrganizationTreeData(res);
                    this.setState({ parentOrganizations });
                },
                (error) => {
                    services.errorHandle(error);
                }
            );
        }
    }

    handleOrganizationTreeData = (organizations: Organization[]) => {
        const treeData: TreeData[] = [];
        organizations.forEach((item) => {
            const treeNode: TreeData = { ...item, key: item.id, value: item.id, title: item.name };
            switch (treeNode.type) {
                case OrganizationTypeEnum.SUBSITE:
                    treeNode.name = language.getText('subsites') + '-' + treeNode.name;
                    break;
                case OrganizationTypeEnum.MERCHANT:
                    treeNode.name = language.getText('merchant') + '-' + treeNode.name;
                    break;
                default:
                    break;
            }
            if (item.children && item.children.length > 0) {
                treeNode.children = this.handleOrganizationTreeData(item.children);
            }
            treeData.push(treeNode);
        });
        return treeData;
    };

    onSubmit = (event: any) => {
        event.stopPropagation();
        const { selectedRows } = this.props;
        const { selectedParentNode, parentOrganizations } = this.state;
        if (!selectedParentNode) {
            message.error(services.language.getText('qxzsjzzjg'));
            return;
        }
        const isSelf = find(selectedRows, { id: selectedParentNode.id });
        if (isSelf && isSelf.id) {
            message.error(services.language.getText('bkxzdqzzbgzz'));
            return;
        }

        // 需要变更的组织如果是自定义组织则不可以变更到第五级自定义组织下
        // 所选数据均为统一父级，所以可以取第一条数据的父级属性
        const tempData = selectedRows[0];
        const tempOrganizationTypeObj = find(tempData.extendAttributes, {
            attributeCode: 'organization_type',
        });
        const tempOrganizationType = tempOrganizationTypeObj
            ? tempOrganizationTypeObj.attributeValue
            : '';
        if (
            tempOrganizationType !== OrganizationType.MERCHANT &&
            selectedParentNode.type === OrganizationTypeEnum.MERCHANT_CUSTOM2
        ) {
            message.error(services.language.getText('mdxzdejzzjg'));
            return;
        }
        if (
            tempOrganizationType === OrganizationType.SUBSITE_CUSTOM &&
            selectedParentNode.type === OrganizationTypeEnum.SUBSITE_CUSTOM
        ) {
            message.error(services.language.getText('zbxzdyjzzjg'));
            return;
        }

        // 将树扁平化方便后续操作
        let flatParentOrganizations: Organization[] = [];
        this.flatList(parentOrganizations, flatParentOrganizations);
        const organizationInfos: any = [];
        let canUpdate: boolean = true;
        selectedRows.forEach((item: any) => {
            //选中的组织如果子级是自定义组织则不可变更到自定义组织下（自定义组织会超过最大层级）
            //首先判断选中的父级组织的组织类型
            if (selectedParentNode.type === OrganizationTypeEnum.MERCHANT_CUSTOM) {
                // 从树中找到选中的组织，如果能找到代表是自定义组织
                const selectedRow = find(flatParentOrganizations, { id: item.id });
                if (selectedRow && selectedRow.children && selectedRow.children.length) {
                    selectedRow.children.forEach((childrenItem: any) => {
                        const childrenOrganizationTypeObj = find(childrenItem.extendAttributes, {
                            attributeCode: 'organization_type',
                        });
                        const childrenOrganizationType = childrenOrganizationTypeObj
                            ? childrenOrganizationTypeObj.attributeValue
                            : '';
                        //如果子级含有专柜自定义组织，则不可变更
                        if (childrenOrganizationType === OrganizationType.MERCHANT_CUSTOM) {
                            canUpdate = false;
                        }
                    });
                }
            }
            const organizationInfo = {
                organizationId: item.id,
                extendAttributes: item.extendAttributes,
            };
            organizationInfos.push(organizationInfo);
        });
        if (!canUpdate) {
            message.error(services.language.getText('xzdzzzjbhzdyzz'));
            return;
        }

        const params = {
            targetOrganizationId: selectedParentNode.id,
            organizationInfos,
        };

        api.put(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            apiPath: '/organizations/parent_id',
        }).then(
            () => {
                AntMessage.success(services.language.getText('bgzzjgcg'));
                this.onOk();
            },
            (error) => {
                services.errorHandle(error);
            }
        );

        return;
    };
    flatList = (data: any[], flatData: any[]) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            flatData.push(node);
            if (node.children) {
                this.flatList(node.children, flatData);
            }
        }
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };
    onOk = () => {
        const { onOk } = this.props;
        onOk && onOk();
    };

    selectParent = (value: any, node: any, extra: any) => {
        const selectedNodes = extra.selectedNodes;
        if (selectedNodes && selectedNodes.length > 0) {
            const selectedParentNode = selectedNodes[0].props;
            this.setState({ selectedParentNode });
        }
    };

    renderModalFooter = () => {
        return (
            <div className="buttons">
                <Button className="button" type="default" onClick={this.onCancel}>
                    {services.language.getText('common.cancel')}
                </Button>
                <Button className="button" type="default" onClick={this.onSubmit}>
                    {services.language.getText('common.save')}
                </Button>
            </div>
        );
    };

    render() {
        const { visible } = this.props;
        const { parentOrganizations, selectedParentNode } = this.state;
        const modalProps = {
            width: 600,
            title: services.language.getText('bgsjzz'),
            visible: visible,
            footer: this.renderModalFooter(),
            onCancel: this.onCancel,
        };

        return (
            <div className="add-organization-modal">
                <Modal {...modalProps}>
                    <div>
                        <div className="form ant-form ant-form-horizontal">
                            <div className="ant-row ant-form-item">
                                <span className="ant-col ant-col-6 ant-form-item-label">
                                    {services.language.getText('zzjgmc')}：
                                </span>
                                <span
                                    className="ant-col ant-col-14 ant-form-item-label"
                                    style={{ textAlign: 'left' }}
                                >
                                    <TreeSelect
                                        placeholder={services.language.getText('selectPlease')}
                                        style={{ width: '100%' }}
                                        value={selectedParentNode ? selectedParentNode.id : ''}
                                        treeData={parentOrganizations}
                                        onSelect={this.selectParent}
                                    ></TreeSelect>
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const ChangeParentModal = connect(mapStateToProps, mapDispatchToProps)(changeParentModal);
