import React, { PureComponent } from 'react';
import './index.less';
/**
 * 可视化内容服务首页组件选择器
 */
export class CustomComponentSelectorContentService extends PureComponent<{
    onSelect: (value: any) => void;
}> {
    baseComponents = [
        {
            id: 'serviceSubsite',
            name: '门店信息',
        },
        {
            id: 'imageAd',
            name: '图片广告',
        },
        {
            id: 'nav',
            name: '导航',
        },
        {
            id: 'space',
            name: '辅助空白',
        },
    ];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        const base = this.baseComponents;

        return (
            <div className="custom-component-selector-content-service">
                <div className="group">
                    <div className="group-title">基础组件</div>
                    <div className="group-content">
                        {base.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
