import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, forEach } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface InvitationActivityEditFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class invitationActivityEditFormView extends PureComponent<InvitationActivityEditFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;

        const fields: Array<object> = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    style: { width: 300 },
                },
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
                rules: [
                    { required: true, message: '请选择活动时间' },
                    {
                        async validator(_: any, value: any) {
                            const originStartDate = get(entity.fields, 'baseInfo.dateRange.start');
                            if (!value) {
                                return;
                            }
                            const currentStartDate = value.start;
                            const currentEndDate = value.end;
                            if (originStartDate !== currentStartDate) {
                                throw new Error(
                                    services.language.getText('ksrqbngg') + `${originStartDate}`
                                );
                            }
                            if (new Date(currentEndDate).getTime() < new Date().getTime()) {
                                throw new Error(services.language.getText('jssjbnzydqsj'));
                            }
                        },
                    },
                ],
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    style: { width: 450 },
                    disabled: true,
                },
            },
            {
                property: 'baseInfo.marketingSchedule',
                className: 'property-left',
            },
            {
                property: 'rewardInfo.id',
                className: 'property-hide',
            },
            {
                property: 'rewardLimit',
                className: 'property-left',
            },
            {
                property: 'rewardMethod',
                className: 'property-left',
            },
            {
                property: 'inviterRewards',
                className: 'property-left',
            },
            {
                property: 'invitedToReward',
                className: 'property-left',
            },
            {
                property: 'rewardCondition',
                className: 'property-left',
            },
            {
                property: 'memberType',
                className: 'property-hide',
            },
            { property: 'pictureInfo.bottomPictureUrl', extra: '图片尺寸建议为宽750px*高1134px' },
            // { property: 'pictureInfo.giftPictureUrl', extra: '图片尺寸建议为宽72px*高80px' },
            { property: 'pictureInfo.buttonPictureUrl', extra: '图片尺寸建议为宽532px*高60px' },
            { property: 'pictureInfo.markPictureUrl', extra: '图片尺寸建议为宽60px*高24px' },
            {
                property: 'pictureInfo.description',
                className: 'property-left',
            },
            {
                property: 'pictureInfo.sharePosterPictureId',
                extra: '图片尺寸建议为宽608px*896px',
            },
        ];
        if (ENV.TENANT !== 'hxgc') {
            fields.push(
                {
                    property: 'pictureInfo.shareSmallPictureId',
                    extra: '图片尺寸建议为宽30px*30px',
                },
                { property: 'pictureInfo.shareContent' }
            );
        }

        let formProps = {
            entity,
            componentId: 'InvitationActivityEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'invitation-activity-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: { text: '保存' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                //获取奖励配置解析数据验证
                const inviteeRewards = get(entityFields, 'inviterRewards', null);
                const invitedToReward = get(entityFields, 'invitedToReward');
                if (inviteeRewards && inviteeRewards.length > 0) {
                    let hasValidate = true;
                    let maxStepValue = 0;
                    forEach(inviteeRewards, (reward: any) => {
                        //1.验证邀请人数限制（连续邀请人数下一档需大于上一档人数）
                        if (reward.stepValue) {
                            if (reward.stepValue > maxStepValue) {
                                maxStepValue = reward.stepValue;
                            } else {
                                AntMessage.warning(services.language.getText('lxyqrsxydxdysydrs'));
                                hasValidate = false;
                                return false;
                            }
                        } else {
                            AntMessage.warning('请填写连续邀请第X人获得以下奖励的数值');
                            hasValidate = false;
                            return false;
                        }
                        //2.验证五项选中的值是否已填写或选择（五项必须选择一项）
                        let checkoutNum = 0;
                        if (reward.pointChecked && reward.pointChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.point || reward.point === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.experienceChecked && reward.experienceChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.experience || reward.experience === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.couponsChecked && reward.couponsChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.couponsValue || reward.couponsValue.length === 0) {
                                AntMessage.warning(services.language.getText('qxzyhq'));
                                hasValidate = false;
                                return false;
                            }
                            if (
                                reward.couponRewardNameChecked &&
                                reward.couponRewardNameChecked === true &&
                                !reward.couponRewardName
                            ) {
                                AntMessage.warning('已选择展示自定义券奖品文案，请输入内容');
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.goldChecked && reward.goldChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.gold || reward.gold === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.customChecked && reward.customChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.custom || reward.custom === '') {
                                AntMessage.warning('请填写自定义内容');
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (checkoutNum === 0) {
                            AntMessage.warning(services.language.getText('qgxjlx'));
                            hasValidate = false;
                            return false;
                        }
                        if (!reward.ladderGiftPicture || reward.ladderGiftPicture.length === 0) {
                            AntMessage.warning('请上传奖励图');
                            hasValidate = false;
                            return false;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                }
                if (invitedToReward.registerRewardChecked) {
                    let checkoutNum = 0;
                    if (invitedToReward.pointChecked && invitedToReward.pointChecked === true) {
                        checkoutNum = checkoutNum + 1;
                        if (!invitedToReward.point || invitedToReward.point === '') {
                            AntMessage.warning(services.language.getText('qtxxzhdz'));
                            return false;
                        }
                    }
                    if (
                        invitedToReward.experienceChecked &&
                        invitedToReward.experienceChecked === true
                    ) {
                        checkoutNum = checkoutNum + 1;
                        if (!invitedToReward.experience || invitedToReward.experience === '') {
                            AntMessage.warning(services.language.getText('qtxxzhdz'));
                            return false;
                        }
                    }
                    if (invitedToReward.couponsChecked && invitedToReward.couponsChecked === true) {
                        checkoutNum = checkoutNum + 1;
                        if (
                            !invitedToReward.couponsValue ||
                            invitedToReward.couponsValue.length === 0
                        ) {
                            AntMessage.warning(services.language.getText('qxzyhq'));
                            return false;
                        }
                    }
                    if (invitedToReward.goldChecked && invitedToReward.goldChecked === true) {
                        checkoutNum = checkoutNum + 1;
                        if (!invitedToReward.gold || invitedToReward.gold === '') {
                            AntMessage.warning(services.language.getText('qtxxzhdz'));
                            return false;
                        }
                    }
                    if (invitedToReward.customChecked && invitedToReward.customChecked === true) {
                        checkoutNum = checkoutNum + 1;
                        if (!invitedToReward.custom || invitedToReward.custom === '') {
                            AntMessage.warning('请填写被邀请人奖励自定义内容');
                            return false;
                        }
                    }
                    if (checkoutNum === 0) {
                        AntMessage.warning(services.language.getText('qgxbyqrjlx'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('InvitationActivityEditFormContainer')
                    );
            },
        }
    );
}
export const InvitationActivityEditFormView = connect(mapDispatchToProps)(
    invitationActivityEditFormView
);
