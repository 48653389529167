import React, { Component } from 'react';
import { Icon, Button } from 'antd';

//图片广告提示
export class ImageAdTips extends Component<{ items: Array<any>; onChange: (value: any) => void }> {
    addItem = () => {
        const { items, onChange } = this.props;
        onChange([...items, {}]);
    };

    render() {
        return (
            <div className="entry-item item-create">
                <p>
                    <Button type={'link'} onClick={this.addItem}>
                        <Icon type="plus" /> 新增广告位
                    </Button>
                </p>
                <p>'图片尺寸建议为384px*553px'</p>
            </div>
        );
    }
}
