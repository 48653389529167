import React, { PureComponent } from 'react';
import { cloneDeep, findIndex, get, forEach, find, map } from 'lodash';
import { SelectMerchant } from './select-merchant';
import { Button, message } from 'antd';
import { ShopSelectedDisplay } from '../../../../components';
import { Shop } from '../../../../containers/select-shop';
import { UploadFile } from './upload-file';

import './index.less';
import { services } from '@comall-backend-builder/core';

// export interface Merchant {
//     id: string;
//     merchantName: string;
//     merchantType: string;
//     merchantStatus: string;
// }

interface MerchantSelecterPlusProps {
    onChange: (value: Shop[] | undefined, name?: string) => void;
    value: Shop[] | undefined;
    row: any;
    name: string;
    disabled: boolean;
    tips?: string;
    subsiteField: string;
    needDependencesMessage?: string;
    customFields?: {
        property: string;
    }[];
    customColumns?: {
        property: string;
    }[];
    customParams?: {
        [key: string]: any;
    };
    hideSubsiteColume?: boolean;
    // 是否展示批量导入专柜按钮
    showImportButton?: boolean;
    //关联门店查询组件是否是多选
    multipleSubsite?: boolean;
}

interface MerchantSelecterPlusStates {
    visible: boolean;
    //导入专柜弹窗是否展示
    importModalVisible: boolean;
}

/**
 * 选择专柜组件，表单上必须有单选门店组件/多选门店组件
 */
export class MerchantSelecterPlus extends PureComponent<
    MerchantSelecterPlusProps,
    MerchantSelecterPlusStates
> {
    constructor(props: MerchantSelecterPlusProps) {
        super(props);
        this.state = { visible: false, importModalVisible: false };
    }
    componentWillReceiveProps(nextProps: MerchantSelecterPlusProps) {
        const { subsiteField = '' } = this.props;
        const nextSubsites = get(nextProps.row, subsiteField);
        const preSubsites = get(this.props.row, subsiteField);
        this.handleSubsitesChange(nextSubsites, preSubsites);
    }

    handleSubsitesChange = (nextSubsites: any, preSubsites: any) => {
        const { onChange } = this.props;
        if (!preSubsites) return;
        if (nextSubsites !== preSubsites) {
            onChange([]);
            return;
        }
    };

    /**
     * 选择专柜
     * @param selectValues
     */
    onChange = (selectValues: Shop[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: Shop[], isImprt?: boolean) => {
        const { value, name, onChange } = this.props;
        let newData: Shop[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        selectValues.forEach((shop: any) => {
            const hasIndex = findIndex(value, (s: any) => s.merchantId === shop.merchantId);
            if (hasIndex === -1) {
                shop.select = true;
                newData.unshift(shop);
            }
        });
        if (onChange) {
            onChange(newData, name);
        }
        if (!isImprt) {
            this.toggleModal();
        }
    };

    /**
     * 改变已选择分站选中状态
     * @param selectValues
     */
    onChangeSelect = (selectValues: Shop[]) => {
        const { onChange, value } = this.props;
        if (!value) return;
        const newData: Shop[] = cloneDeep(value);
        forEach(newData, (shop) => {
            const selectShop = find(selectValues, (selectShop) => {
                return selectShop.merchantId === shop.merchantId;
            });

            if (selectShop) {
                shop.select = true;
            } else {
                shop.select = false;
            }
        });
        if (onChange) {
            if (newData && newData.length === 0) {
                onChange(undefined);
            } else {
                onChange(newData);
            }
        }
    };

    toggleModal = () => {
        const { visible } = this.state;
        if (!visible) {
            const { row, subsiteField, needDependencesMessage } = this.props;
            const subsites = get(row, subsiteField);
            if (!subsites || subsites.length === 0) {
                message.warn(
                    needDependencesMessage || services.language.getText('selectBeforeMerchant')
                );
                return;
            }
        }
        this.setState({ visible: !visible });
    };

    toggleImportModal = () => {
        const { importModalVisible } = this.state;
        if (!importModalVisible) {
            const { row, subsiteField, needDependencesMessage } = this.props;
            const subsites = get(row, subsiteField);
            if (!subsites || subsites.length === 0) {
                message.warn(
                    needDependencesMessage || services.language.getText('selectBeforeMerchant')
                );
                return;
            }
        }
        this.setState({ importModalVisible: !importModalVisible });
    };

    render() {
        const {
            value,
            disabled,
            row,
            tips,
            subsiteField,
            customFields,
            customColumns,
            customParams,
            hideSubsiteColume = true,
            showImportButton,
            multipleSubsite,
        } = this.props;
        const { visible, importModalVisible } = this.state;
        const subsites = get(row, subsiteField);
        const params = { ...customParams, subSiteName: subsites ? subsites : undefined };
        if (multipleSubsite && subsites) {
            params.subSiteName = map(subsites, 'id');
        }
        const hasValue = value && value.length > 0;

        const uploadFileProps = {
            visible: importModalVisible,
            onClose: this.toggleImportModal,
            ...this.props,
        };
        return (
            <div className="merchant-selecter-plus">
                <div className="buttons">
                    <Button type="link" onClick={this.toggleModal}>
                        {services.language.getText('selectSubsites')}
                    </Button>
                    {showImportButton && (
                        <Button type="link" onClick={this.toggleImportModal}>
                            {services.language.getText('pldrzj')}
                        </Button>
                    )}
                </div>

                {!!tips && <div className="tips">{tips}</div>}
                <SelectMerchant
                    onChange={this.onSelect}
                    onCancel={this.toggleModal}
                    customFields={customFields}
                    customColumns={customColumns}
                    visible={visible}
                    params={params}
                />
                <UploadFile {...uploadFileProps} subsites={subsites} onChange={this.onSelect} />
                {hasValue && (
                    <ShopSelectedDisplay
                        data={value || []}
                        onChange={this.onChangeSelect}
                        onRemove={this.onChange}
                        disabled={disabled}
                        hideSubsiteColume={hideSubsiteColume}
                    ></ShopSelectedDisplay>
                )}
            </div>
        );
    }
}
