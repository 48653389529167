import React, { PureComponent } from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { SendOutChannelSelect, SendOutChennel } from './send-out-channel-select';
import { SendOutChannelSelectDisplay } from './send-out-channel-select-display';

export interface ArraySendOutChannelStates {
    value: Array<SendOutChennel>;
}
export class ArraySendOutChannel extends PureComponent<any, ArraySendOutChannelStates> {
    state = { value: new Array<SendOutChennel>() };

    /**
     * 选择渠道
     * @param selectValues
     */
    onChange = (selectValues: SendOutChennel[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加渠道弹窗确认后回调
     * @param selectValues 选中渠道
     */
    onSelect = (selectValues: SendOutChennel[]) => {
        const { value } = this.props;
        let newData: SendOutChennel[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            selectValues.forEach((selectChannel: any) => {
                const hasIndex = findIndex(
                    newData,
                    (channel: any) => channel.channelId === selectChannel.channelId
                );
                if (hasIndex === -1) {
                    newData.push(selectChannel);
                }
            });
        }
        this.onChange(newData);
    };

    render() {
        const { value, style } = this.props;
        const hasValue = value && value.length > 0;
        return (
            <div style={style}>
                <SendOutChannelSelect
                    onChange={this.onSelect}
                    selectValues={value}
                    type="checkbox"
                />
                {hasValue && <SendOutChannelSelectDisplay onChange={this.onChange} data={value} />}
            </div>
        );
    }
}
