import React, { Component } from 'react';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { isSimplicityTheme } from '../theme-style';

import './index.less';

const prefix = 'watchword-preview';
const titleLeft = require('./image/watchword-title-left.png');
const titleRight = require('./image/watchword-title-right.png');

/**
 * 口令红包左侧
 * @param props
 */
class watchwordPreview extends Component<any, { themeStyle: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            themeStyle: {},
        };
    }
    componentDidMount(): void {
        this.getThemeStyle();
    }

    getThemeStyle = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/theme_style',
        };
        api.get({}, config).then((res: any) => {
            if (res.id) {
                this.setState({ themeStyle: JSON.parse(res.config) });
            }
        });
    };

    private get isSimplicityTheme() {
        const { themeStyle } = this.state;
        if (!themeStyle) {
            return false;
        }
        return isSimplicityTheme(themeStyle);
    }

    private get isCheckoutTypeInternal() {
        return this.getField('carparkConfig.checkoutType.type') === 'INTERNAL';
    }

    getField = (field: string) => {
        const { preview } = this.props;
        return get(preview, field);
    };
    renderContent = () => {
        const backgroundPicture = this.getField('pageRule.activityBackgroundPicture');
        const buttonBackgroundPicture = this.getField('pageRule.buttonBackgroundPicture');
        const watchwordEntrance = this.getField('rulesInfo.watchwordEntrance');
        const innerClassNames = {
            content: `${prefix}__content`,
            icon: `${prefix}__icon`,
            btnBox: `${prefix}__btn-box`,
            title: `${prefix}__title`,
            watchword: `${prefix}__watchword`,
            btn: `${prefix}__btn`,
            clickMe: `${prefix}__click-me`,
            myCard: `${prefix}__mycard`,
            activityRule: `${prefix}__activity-rule`,
        };
        const backgroundPictureStyle: any = {
            backgroundImage:
                backgroundPicture && backgroundPicture.length
                    ? `url(${backgroundPicture[0].path})`
                    : '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
        };
        const buttonBackgroundPictureStyle: any = {
            backgroundImage:
                buttonBackgroundPicture && buttonBackgroundPicture.length
                    ? `url(${buttonBackgroundPicture[0].path})`
                    : '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
        };

        return (
            <div className={innerClassNames.content} style={backgroundPictureStyle}>
                <div className={innerClassNames.activityRule}>{language.getText('rule')}</div>
                <div className={innerClassNames.btnBox}>
                    <div className={innerClassNames.title}>
                        <img className={innerClassNames.icon} src={titleLeft} alt="" />
                        {language.getText('watchwordTitle')}
                        <img className={innerClassNames.icon} src={titleRight} alt="" />
                    </div>
                    <div className={innerClassNames.watchword}>
                        {language.getText('pleaseWatchword')}
                    </div>
                    <div className={innerClassNames.btn} style={buttonBackgroundPictureStyle}></div>
                    <div className={innerClassNames.myCard}>
                        {watchwordEntrance === 'SHOW' && (
                            <div className={innerClassNames.clickMe}>
                                {language.getText('noWatchwordToclick')}
                            </div>
                        )}
                        {language.getText('viewMyCard')}&gt;
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { themeStyle } = this.state;
        let backgroundImage: any = {
            backgroundImage: themeStyle?.pageBackground?.backgroundImage
                ? `url(${themeStyle?.pageBackground?.backgroundImage})`
                : '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        };

        if (!this.isSimplicityTheme) {
            backgroundImage = {
                backgroundColor: '#fff',
            };
        }
        const headStyle = {
            backgroundColor: this.isSimplicityTheme
                ? themeStyle?.navigation?.style?.backgroundColor
                : '#fff',
        };

        return (
            <div style={backgroundImage} className="watchword-preview">
                <div className="theme-style-preview-item" style={headStyle}>
                    <img style={{ width: '100%' }} alt="" src="./images/avatar/theme-head.png" />
                    <div className="theme-style-preview-cart-title">
                        {language.getText('watchwordCouponCollection')}
                    </div>
                </div>
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }

    return { preview: preview };
};

export const WatchwordPreview = connect(mapStateToProps)(watchwordPreview);
