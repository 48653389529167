import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export const MarketingScheduleManagementLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/marketing_schedules`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/marketing_schedules/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.subsite = res.subsite.id;
                res.thirdActivityId = res.thirdId;
                res.dateRange =
                    res.startTime && res.endTime
                        ? {
                              start: res.startTime,
                              end: res.endTime,
                          }
                        : undefined;
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        time:
                            item.startTime && item.endTime
                                ? `${item.startTime}-${item.endTime}`
                                : language.getText('longTerm'),
                    };
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/marketing_schedules';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/marketing_schedules/${params.id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
    delete: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/marketing_schedules/${params.id}`;
        return await api.delete(params, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.name = params.name;
    newParams.startTime =
        params.dateRange && params.dateRange.start ? params.dateRange.start : undefined;
    newParams.endTime = params.dateRange && params.dateRange.end ? params.dateRange.end : undefined;
    newParams.subsite = {
        id: params.subsite,
    };
    newParams.thirdId = params.thirdActivityId ? params.thirdActivityId.trim() : undefined;
    return newParams;
}
