import React, { PureComponent } from 'react';

import { Button, Modal, Form, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';

import { CustomSelectSubsite } from '../custom-select-subsite-select';
import { PromotionActivitySelector } from './select-promotion-activity-modal';

const FormItem = Form.Item;

const language = services.language;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: {
        id: 0,
        name: '',
    },
};

export interface CustomSelectPromotionActivityProps {
    value: any;
    contentConfig: Record<string, any>;
    modalConfig: Record<string, any>;
    onChange(value: any): void;
}

interface CustomSelectPromotionActivityState {
    visible: boolean;
    linkParams: any;
}

class CustomSelectPromotionActivity extends PureComponent<
    CustomSelectPromotionActivityProps,
    CustomSelectPromotionActivityState
> {
    constructor(props: CustomSelectPromotionActivityProps) {
        super(props);
        const linkParams = get(props.value, 'linkParams') || DEFAULT_VALUE.linkParams;

        this.state = {
            visible: false,
            linkParams: linkParams,
        };
    }

    visibleModalChange = (visible: boolean): void => {
        this.setState({ visible });
    };

    /** 参数设置 */
    handleChange = (params: any, type: any): void => {
        let { linkParams } = this.state;
        if (type === 'subSite') {
            linkParams = {
                ...linkParams,
                id: params.id,
                subsiteId: params.id,
                name: params.name,
                promotionId: '',
                promotionName: '',
            };
        } else if (type === 'promotion') {
            linkParams = {
                ...linkParams,
                promotionId: params.id,
                promotionName: params.name,
                endTime: params.endTime,
            };
        }

        this.setState({ linkParams });
    };

    onOk = () => {
        const { linkParams } = this.state;
        const { onChange } = this.props;

        if (linkParams.id === '' || linkParams.id === undefined) return message.error('请选择门店');

        if (linkParams.promotionId === '' || linkParams.promotionId === undefined)
            return message.error('请选择促销活动');

        onChange && onChange({ ...linkParams });

        this.handleCancel();
    };

    handleShowModal = (): void => {
        this.visibleModalChange(true);
    };

    handleCancel = (): void => {
        this.visibleModalChange(false);
    };

    render() {
        const { visible, linkParams } = this.state;
        const linkName = get(linkParams, 'name');
        const subSiteId = get(linkParams, 'subsiteId');
        const promotionName = get(linkParams, 'promotionName');
        const promotionId = get(linkParams, 'promotionId');

        return (
            <span className="link-name">
                {linkName ? <span className="link-name-label">{linkName}</span> : '未选择'}

                <Button type="link" className="link-type-edit" onClick={this.handleShowModal}>
                    {linkName
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <Modal
                    title="请选择"
                    width="800px"
                    visible={visible}
                    onOk={this.onOk}
                    onCancel={this.handleCancel}
                >
                    <Form {...FormItemLayout}>
                        <FormItem label={'选择门店'} required>
                            <CustomSelectSubsite
                                value={{ name: linkName, id: subSiteId, subsiteId: subSiteId }}
                                onChange={(value) => this.handleChange(value, 'subSite')}
                            />
                        </FormItem>
                        <FormItem label={'单品/多品促销'} required>
                            <PromotionActivitySelector
                                {...this.props}
                                onChange={(value) => this.handleChange(value, 'promotion')}
                                value={{
                                    linkParams: {
                                        name: promotionName,
                                        id: promotionId,
                                        subsiteId: subSiteId,
                                    },
                                }}
                            />
                        </FormItem>
                    </Form>
                </Modal>
            </span>
        );
    }
}

export { CustomSelectPromotionActivity };
