import React, { Component } from 'react';
import { Icon, Button } from 'antd';
import { services } from '@comall-backend-builder/design-components-basis';
import { services as coreServices } from '@comall-backend-builder/core';

const language = coreServices.language;

const { utils } = services;
//图片广告提示
export class ImageAdTips extends Component<{ items: Array<any>; onChange: (value: any) => void }> {
    addItem = () => {
        const { items, onChange } = this.props;
        onChange([...items, { uuid: utils.uuid() }]);
    };

    render() {
        return (
            <div className="entry-item item-create">
                <p>
                    <Button type={'link'} onClick={this.addItem}>
                        <Icon type="plus" /> {language.getText('components.ImageAd.addImage')}
                    </Button>
                </p>
                <p>{language.getText('components.ImageAd.imageSize')}</p>
            </div>
        );
    }
}
