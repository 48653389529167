import React from 'react';
import { modes } from '@comall-backend-builder/types';
import {
    GroupBuyingGoodsSelectorContainer,
    GroupBuyingGoodsSelectedDisplay,
} from '../../../components';

export class GroupBuyingGoodsSelector extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <GroupBuyingGoodsSelectedDisplay data={value} modifiable={false} onChange={() => {}} />
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <GroupBuyingGoodsSelectorContainer {...displayInfo} />;
    }
}
