import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';
interface MallActivityRegistrationDetailProps {
    fields: any;
}
export class MallActivityRegistrationDetailView extends Component<
    MallActivityRegistrationDetailProps
> {
    renderReview = () => {
        const { fields } = this.props;

        return get(fields, 'activityOrderAuditVo.auditStatus') === 'WAIT' ? (
            <div className="item-content">
                <Row>
                    <Col span={8}>
                        <span>审核人：空</span>
                    </Col>
                    <Col span={8}>
                        <span>审核时间：空</span>
                    </Col>
                    <Col span={8}>
                        <span>审核结果：未审核</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <span>原因：空</span>
                    </Col>
                </Row>
            </div>
        ) : (
            <div className="item-content">
                <Row>
                    <Col span={8}>
                        <span>
                            审核人：
                            {get(fields, 'activityOrderAuditVo.auditCreator') || ''}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            审核时间：
                            {get(fields, 'activityOrderAuditVo.auditTime') || ''}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            审核结果：
                            {get(fields, 'activityOrderAuditVo.auditStatusStr') || ''}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <span>原因：{get(fields, 'activityOrderAuditVo.failReason') || ''}</span>
                    </Col>
                </Row>
            </div>
        );
    };
    render() {
        const { fields } = this.props;
        const couponList = get(fields, 'orderItemRecord.coupons');
        const point = get(fields, 'orderProduct.point');
        const price = get(fields, 'orderProduct.price');
        let priceStr = '';
        if (!point && !price) {
            priceStr = '0';
        } else if (point && price) {
            priceStr = `${point}积分+${price}${services.language.getText('yuan')}`;
        } else if (!point && price) {
            priceStr = `${price}${services.language.getText('yuan')}`;
        } else if (point && !price) {
            priceStr = `${point}积分`;
        }

        const orderPoint = get(fields, 'pointAmount');
        const orderPrice = get(fields, 'payableAmount');
        let orderPriceStr = '';
        if (!orderPoint && !orderPrice) {
            orderPriceStr = '0';
        } else if (orderPoint && orderPrice) {
            orderPriceStr = `${orderPoint}积分+${orderPrice}${services.language.getText('yuan')}`;
        } else if (!orderPoint && orderPrice) {
            orderPriceStr = `${orderPrice}${services.language.getText('yuan')}`;
        } else if (orderPoint && !orderPrice) {
            orderPriceStr = `${orderPoint}积分`;
        }

        if (get(fields, 'activity.dayStartTime')) {
            const startTime = fields.activity.dayStartTime.split(':');
            fields.activity.dayStartTime = startTime[0] + ':' + startTime[1];
        }
        if (get(fields, 'activity.dayEndTime')) {
            const endTime = fields.activity.dayEndTime.split(':');
            fields.activity.dayEndTime = endTime[0] + ':' + endTime[1];
        }
        if (get(fields, 'activity.startTime')) {
            fields.activity.startTime = fields.activity.startTime.slice(0, 10);
        }
        if (get(fields, 'activity.endTime')) {
            fields.activity.endTime = fields.activity.endTime.slice(0, 10);
        }

        if (get(fields, 'memberRegistration.joinDayEndTime')) {
            const endTime = fields.memberRegistration.joinDayEndTime.split(':');
            fields.memberRegistration.joinDayEndTime = endTime[0] + ':' + endTime[1];
        }
        if (get(fields, 'memberRegistration.joinDayStartTime')) {
            const startTime = fields.memberRegistration.joinDayStartTime.split(':');
            fields.memberRegistration.joinDayStartTime = startTime[0] + ':' + startTime[1];
        }

        if (get(fields, 'memberRegistration.extension')) {
            fields.memberRegistration.extension = JSON.parse(fields.memberRegistration.extension);
        }

        return (
            <div className="mall-activity-registration-detail">
                <div className="page-title">订单信息</div>
                <div className="part-container">
                    <div className="title">活动信息</div>
                    <div>
                        活动名称： <span>{get(fields, 'activity.name') || ''}</span>
                    </div>
                    {get(fields, 'activity.startTime') ? (
                        <div>
                            {`活动时间： ${get(fields, 'activity.startTime')}至${get(
                                fields,
                                'activity.endTime'
                            )} ${get(fields, 'activity.dayStartTime')}-${get(
                                fields,
                                'activity.dayEndTime'
                            )}`}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="part-container">
                    <div className="title">报名信息</div>
                    <div className="item-content">
                        <Row>
                            <Col span={8}>
                                <span>
                                    参与时间：{get(fields, 'memberRegistration.joinDate') || ''}{' '}
                                    {get(fields, 'memberRegistration.joinDayStartTime') || ''}-
                                    {get(fields, 'memberRegistration.joinDayEndTime') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>下单人：{get(fields, 'mobile') || ''}</span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    报名人姓名：{get(fields, 'memberRegistration.name') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    报名人手机号：{get(fields, 'memberRegistration.mobile') || ''}
                                </span>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="part-container">
                    <div className="title">订单信息</div>
                    <div className="item-content">
                        <Row>
                            <Col span={8}>
                                <span>订单编号：{get(fields, 'orderNo') || ''}</span>
                            </Col>
                            <Col span={8}>
                                <span>报名时间：{get(fields, 'orderCreateTime') || ''}</span>
                            </Col>
                            <Col span={8}>
                                <span>订单金额：{orderPriceStr}</span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {services.language.getText('fksj')}：
                                    {get(fields, 'payCreateTime') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>报名人数：{get(fields, 'orderProduct.quantity') || ''}</span>
                            </Col>
                            <Col span={8}>
                                <span>报名金额：{priceStr || ''}</span>
                            </Col>
                            <Col span={8}>
                                <span>订单状态：{get(fields, 'orderStatusStr') || ''}</span>
                            </Col>

                            {fields.memberRegistration &&
                                fields.memberRegistration.extension &&
                                fields.memberRegistration.extension.map((item: any) => {
                                    return (
                                        <>
                                            <Col span={8}>
                                                <div className="custom-detail">
                                                    {item.title}：
                                                    {typeof item.value === 'string'
                                                        ? item.value
                                                        : item.value.join('、')}
                                                </div>
                                            </Col>
                                        </>
                                    );
                                })}
                        </Row>
                    </div>
                </div>

                <div className="part-container">
                    <div className="title">审核信息</div>

                    {!get(fields, 'memberRegistration.review') ? (
                        <span>
                            {typeof get(fields, 'memberRegistration.review') === 'boolean'
                                ? '报名即为审核通过'
                                : ''}
                        </span>
                    ) : (
                        this.renderReview()
                    )}
                </div>

                {couponList && couponList.length ? (
                    <div className="part-container">
                        <div className="title">券码信息</div>
                        <div className="item-content">
                            <Row>
                                {couponList.map((item: any, index: number) => {
                                    return (
                                        <Col span={24}>
                                            {`券码${index + 1}：${item.couponNo}(${
                                                item.statusName
                                            })`}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        fields: entity.fields || {},
    };
}
export const MallActivityRegistrationDetail = connect(mapStateToProps)(
    MallActivityRegistrationDetailView
);
