import React, { Component } from 'react';
import { Input } from 'antd';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface CarparkPointAliasProps {
    name: string;
    value: string | null;
    onChange: (value: string | null, name: string) => void;
}

export class CarparkPointAlias extends Component<CarparkPointAliasProps> {
    changeValue = (value: string | null) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };

    renderInput = () => {
        const { value } = this.props;
        return (
            <div>
                <Input
                    className="string-input"
                    value={value as string}
                    onChange={(e) => this.changeValue(e.target.value)}
                    placeholder={language.getText('rkyjf')}
                    addonAfter={language.getText('wahpjjfz')}
                />
            </div>
        );
    };

    renderExtra = () => {
        return (
            <div className="extra">
                {language.getText('wahpjjfz')}
                {language.getText('zhjsksytcjfhxfjfdk')}
            </div>
        );
    };

    render() {
        return (
            <div className="carpark-available-rule-entrances">
                {/* {this.renderStatusButton()} */}
                {this.renderInput()}
                {this.renderExtra()}
            </div>
        );
    }
}
