import React, { useMemo } from 'react';
import { Tag, Popover } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { getCouponStatus } from '../../format/utils';
import { language } from '@comall-backend-builder/core/lib/services';

const useContainerId = ((id) => () => useMemo(() => `group-popover-container-${id++}`, []))(0);

export const CouponNoPopover: React.FC<any> = (props) => {
    const { value = [], displayInfo } = props;
    const name = displayInfo.propertyName || displayInfo.name;
    const content = displayInfo.row[name] || value;
    const containerId = useContainerId();

    function getPopupContainer(): HTMLElement {
        return document.getElementById(containerId) || document.body;
    }

    return (
        <div id={containerId}>
            {content && content.length > 0 ? (
                content.map((item: any, i: number) => {
                    return (
                        <div key={i}>
                            {item.name}
                            <Popover
                                autoAdjustOverflow
                                placement={'right'}
                                content={
                                    <div style={{ maxWidth: 400 }}>
                                        {item.values &&
                                            item.values.length &&
                                            item.values.map((i: any, innerIndex: number) => (
                                                <Tag key={innerIndex} style={{ margin: 5 }}>
                                                    {i.couponNo}
                                                    {i.bizStatus
                                                        ? `（${getCouponStatus(i.bizStatus)}${
                                                              i.transferred
                                                                  ? language.getText('zstr')
                                                                  : ''
                                                          }）`
                                                        : ''}
                                                </Tag>
                                            ))}
                                    </div>
                                }
                                getPopupContainer={getPopupContainer}
                            >
                                <span className="group-tips">
                                    {item.values && item.values.length && item.values.length + '个'}
                                </span>
                            </Popover>
                        </div>
                    );
                })
            ) : (
                <span className="group-tips">NA</span>
            )}
        </div>
    );
};
/**
 * 分组数据气泡展示
 * propertyName:参数名
 * remark：说明文字
 * tips 提示文字
 */
export class CouponNoPopoverMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        return <CouponNoPopover value={value} displayInfo={displayInfo} />;
    }
}
