import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { isEmpty, get, forEach } from 'lodash';

export const config: Config = {
    entities: {
        PaymentRewardEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
            apiPath: '/loader/admin/payment_reward',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '范围设置',
                    properties: {
                        activityType: {
                            type: 'string.options.radio',
                            displayName: '活动类型',
                            defaultValue: 'UNION_ACTIVITY',
                            options: [{ id: 'UNION_ACTIVITY', name: '联合活动' }],
                            rules: [{ required: true }],
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<applicableSubsiteAndMerchant>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },

                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                            extra:
                                '全部商品包括所选门店和专柜的所有商品；若设置部分商品不参加，则默认其余商品参加',
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        rewardTime: {
                            type: 'string.options.radio',
                            displayName: '奖励时机',
                            options: [{ id: 'CONFIRM_RECEIVE', name: '确认收货' }],
                            defaultValue: 'CONFIRM_RECEIVE',
                            rules: [{ required: true }],
                        },
                        rewardCondition: {
                            type: 'string.options.radio',
                            displayName: '奖励时机',
                            options: [{ id: 'ONCE_CONSUMPTION', name: '<<dcxfm>>' }],
                            defaultValue: 'ONCE_CONSUMPTION',
                            rules: [{ required: true }],
                        },
                        rewardRecycleCondition: {
                            type: 'string.options.radio',
                            displayName: '<<jlzh>>',
                            options: [
                                { id: 'REWARD_RECYCLE', name: '<<tkhjlhs>>' },
                                { id: 'REWARD_NO_RECYCLE', name: '退款后奖励不回收' },
                            ],
                            defaultValue: 'REWARD_RECYCLE',
                            rules: [{ required: true }],
                            extra: '只回收未使用的奖励，例如未使用的优惠券',
                        },
                        rewards: {
                            type: 'array.paymentRewards',
                            displayName: '<<jppz>>',
                            rules: [{ required: true }],
                        },
                    },
                },

                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdms>>',
                    properties: {
                        picture: {
                            displayName: '<<hdtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra:
                                '不限宽高。未上传或多活动同时生效使用缺省图。单活动生效时使用上传的图。展示在支付后或签收后的确认页内',
                        },

                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<hdsm>>',
                            rules: [
                                {
                                    required: true,
                                    message: '请输入活动说明',
                                    validator: (rule: any, value: any) => {
                                        if (isEmpty(value) || value === '<p><br></p>') {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                                {
                                    whitespace: true,
                                    max: 20000,
                                    message: '<<notMoreThan2000>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        PaymentRewardView: {
            component: 'Viewport',
            entity: 'PaymentRewardEntity',
        },
        PaymentRewardPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'PaymentRewardFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/payment-reward/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'PaymentRewardTable' },
            ],
        },
        PaymentRewardFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        PaymentRewardTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath: '/AFFILIATE-MARKETING/admin/payment_reward/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath: '/AFFILIATE-MARKETING/admin/payment_reward/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/payment-reward/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/payment_reward/{{row.id}}/export',
                        },
                    },
                ],
            },
        },
        PaymentRewardAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'PaymentRewardAddForm' }],
        },
        PaymentRewardAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'PaymentRewardEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.activityType' },
                {
                    property: 'ruleInfo.selectMode',
                },
                {
                    property: 'ruleInfo.subsites',
                    visible: (values: any) => get(values, 'ruleInfo.selectMode') === 'SUB_SITE',
                },
                {
                    property: 'ruleInfo.merchants',
                    visible: (values: any) => get(values, 'ruleInfo.selectMode') === 'MERCHANT',
                },

                { property: 'ruleInfo.activityProductType' },
                { property: 'ruleInfo.products' },
                { property: 'rewardInfo.rewardTime' },
                { property: 'rewardInfo.rewardCondition' },
                { property: 'rewardInfo.rewardRecycleCondition' },
                { property: 'rewardInfo.rewards' },
                { property: 'pictureInfo.picture' },
                { property: 'pictureInfo.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                const rewardInfo = entity.rewardInfo;
                if (ruleInfo) {
                    const selectMode = ruleInfo.selectMode;
                    switch (selectMode) {
                        case 'SUB_SITE':
                            if (!ruleInfo.subsites || ruleInfo.subsites.length === 0) {
                                AntMessage.error(services.language.getText('selectSubsite'));
                                return false;
                            }
                            break;
                        case 'MERCHANT':
                            if (!ruleInfo.merchants || ruleInfo.merchants.length === 0) {
                                AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                                return false;
                            }
                            break;
                    }
                    const rewards = rewardInfo.rewards;

                    if (!rewards || rewards.length === 0) {
                        AntMessage.error('请完整填写奖品配置');
                        return false;
                    } else {
                        let flag = true;

                        forEach(rewards, (reward) => {
                            if (
                                !reward.lowerValue ||
                                reward.lowerValue <= 0 ||
                                !reward.coupons ||
                                reward.coupons.length === 0
                            ) {
                                flag = false;
                            }
                        });
                        if (!flag) {
                            AntMessage.error('请完整填写奖品配置');
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        PaymentRewardEditPage: {
            component: 'FlexLayout',
            entity: 'PaymentRewardEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'PaymentRewardEditForm' }],
        },
        PaymentRewardEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'PaymentRewardEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                {
                    property: 'ruleInfo.activityType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'ruleInfo.selectMode',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'ruleInfo.subsites',
                    modifiable: false,
                    visible: (values: any) => get(values, 'ruleInfo.selectMode') === 'SUB_SITE',
                },
                {
                    property: 'ruleInfo.merchants',
                    modifiable: false,
                    visible: (values: any) => get(values, 'ruleInfo.selectMode') === 'MERCHANT',
                },

                {
                    property: 'ruleInfo.activityProductType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'ruleInfo.products', modifiable: false },
                {
                    property: 'rewardInfo.rewardTime',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'rewardInfo.rewardCondition',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'rewardInfo.rewardRecycleCondition',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'rewardInfo.rewards', modifiable: false },
                { property: 'pictureInfo.picture' },
                { property: 'pictureInfo.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/payment-reward',
                    component: 'PaymentRewardView',
                    breadcrumbName: '支付有礼',
                    privilege: {
                        path: 'paymentReward',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'PaymentRewardAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'PaymentRewardEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'PaymentRewardPage' },
                    ],
                },
            ],
        },
    ],
};
