import { forEach } from 'lodash';
import { services } from '@comall-backend-builder/core/';
const api = services.api;
export const DesignPageLoader = {
    get: async function(data: any, config: any) {
        if (data && data.type === 'all') {
            delete data.type;
        }
        let type = '';
        if (config.paramsFilter) {
            type = config.paramsFilter[0];
            delete config.paramsFilter;
        }
        config.apiPath = '/admin/pages/' + type;
        if (data.id) {
            config.apiPath = '/admin/pages/' + type + '/' + data.id;
        }
        if (type === 'PAY_RESULT_PAGE' && !data.subsiteIds) {
            let subsitesConfig = {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/defaults/subsite',
            };
            const subsites: any = await api.get({}, subsitesConfig);
            if (subsites?.length) {
                data.subsiteIds = subsites[0].id;
            }
        }

        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.result) {
                forEach(res.result, (i) => {
                    i.canDelete = !i.enabled;
                    i.pageName = i.name;
                });
            }

            if (type === 'MERCHANT_PAGE') {
                res.dateRange = {
                    start: res.effectiveStartTime || res.createTime,
                    end: res.effectiveEndTime || '2099-12-31 23:59:59',
                };
            }

            return res;
        });
    },
    post: function(data: any, config: any) {
        let type = '';
        if (config.paramsFilter) {
            type = config.paramsFilter[0];
            delete config.paramsFilter;
        }
        data.type = type;
        config.apiPath = '/admin/pages/' + type;

        let params = data;

        if (type === 'MERCHANT_PAGE') {
            params = {
                enabled: data.enabled,
                effectiveStartTime: data.dateRange.start,
                effectiveEndTime: data.dateRange.end,
                template: data.template,
                name: data.name,
                merchantId: data.merchantSelect[0]['merchantId'],
                groupSubsiteName: data.merchantSelect[0]['merchantName'],
                subsites: [
                    {
                        id: data.merchantSelect[0]['subSiteId'],
                        name: data.merchantSelect[0]['subSiteName'],
                    },
                ],
                type: 'MERCHANT_PAGE',
            };
        }
        // copy逻辑
        if (data && data.id) {
            config.apiPath = `/admin/pages/${type}/copy/${data.id}`;
        }

        return services.api.post(params, config);
    },
    put: function(data: any, config: any) {
        let type = '';
        if (config.paramsFilter) {
            type = config.paramsFilter[0];
            delete config.paramsFilter;
        }
        data.type = type;
        config.apiPath = '/admin/pages/' + type + '/' + data.id;
        let params = data;

        if (type === 'MERCHANT_PAGE') {
            params = {
                id: data.id,
                enabled: data.enabled,
                effectiveStartTime: data.dateRange.start,
                effectiveEndTime: data.dateRange.end,
                template: data.template,
                name: data.name,
                merchantId: data.merchantSelect[0]['merchantId'],
                groupSubsiteName: data.merchantSelect[0]['merchantName'],
                subsites: [
                    {
                        id: data.merchantSelect[0]['subSiteId'],
                        name: data.merchantSelect[0]['subSiteName'],
                    },
                ],
                type: 'MERCHANT_PAGE',
            };
        }

        return services.api.put(params, config);
    },
    delete: async function(data: any, config: any) {
        let type = '';
        if (config.paramsFilter) {
            type = config.paramsFilter[0];
            delete config.paramsFilter;
        }
        data.type = type;
        config.apiPath = '/admin/pages/' + type + '/' + data.id;
        return services.api.delete(data, config);
    },
};
