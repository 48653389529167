import React, { useCallback, useMemo } from 'react';
import { get, find, sumBy, findIndex, remove, cloneDeep } from 'lodash';
import { Table, Button, Popover, InputNumber, message as AntMessage } from 'antd';
import { CouponSelector } from '../../../../components/coupon-selector';
import { CouponPackageSelector } from '../../../../components/coupon-package-selector';
import { CouponSelectorWithDisplay } from '../../../../components/coupon-selector-with-display';
import { CouponPackageSelectorDisplay } from '../../../../components/coupon-package-selector-display';
import { services } from '@comall-backend-builder/core';

interface PaymentRewardsProps {
    onChange: (value: any) => void;
    value: Array<PaymentReward> | undefined;
    row: { baseInfo: { subsiteId: string } };
    extra?: string;
    columns: Array<any> | [];
    initData: any;
    canShowLeftStock?: boolean;
}
interface PaymentReward {
    id: number;
    totalMoneyMin: number | string;
    totalMoneyMax: number | string;
    coupons: Array<Coupon>;
}
interface Coupon {
    id: string;
    type: string;
    name: number;
    startTime: string;
    endTime: string;
    batchNo: string;
    couponTypeDes: string;
    cooperationPatternDesc: string;
    quantity: number;
    stock: number;
    apportionPrice?: number;
}
const defaultPaymentReward = [{ id: 0, totalMoneyMin: '', totalMoneyMax: '', coupons: [] }];

export const PaymentRewards = (props: PaymentRewardsProps) => {
    const filterCouponParams = {
        subsiteIds: props.row.baseInfo ? [props.row.baseInfo.subsiteId] : null,
    };
    const data =
        props.value && props.value.length > 0
            ? props.value
            : cloneDeep(defaultPaymentReward).slice(0);
    let rewardIndex = props.value && props.value.length > 0 ? props.value.length - 1 : 0;

    const onDelete = useCallback(
        (record) => () => {
            let newData = data ? data : [];
            remove(newData, record);
            if (!newData || newData.length === 0) {
                newData = cloneDeep(defaultPaymentReward).slice(0);
            }
            props.onChange(newData);
        },
        [props, data]
    );

    const modalToCoupon = useCallback((item: any) => {
        return {
            id: item.id,
            type: item.couponType || item.type,
            name: item.name,
            startTime: item.startTime,
            endTime: item.endTime,
            batchNo: item.code || item.batchNo,
            couponTypeDes: item.typeName || item.couponTypeDes,
            cooperationPatternDesc:
                (item.cooperationPattern && item.cooperationPattern.description) ||
                item.cooperationPatternDesc,
            consumeChannelDescription: item.consumeChannelDescription,
            quantity: item.quantity || 1,
            stock: item.stock,
            leftStock: item.leftStock,
        };
    }, []);

    const changeCoupons = (record: any, coupons: Array<any>) => {
        let newData = data ? data : [];
        let row: any = find(newData, { id: record.id });
        if (row) {
            let newCoupons: any = [];
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => coupon.id === selectCuopon.id
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
            row.coupons = newCoupons.map(modalToCoupon);
            props.onChange(newData);
        }
    };

    const changeLowerValue = (record: any, value: any, name: any) => {
        let newData = data ? data : [];
        let row: any = find(newData, { id: record.id });
        if (row) {
            const reg = /(^[0-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
            if (value <= 0) {
                AntMessage.warning(services.language.getText('inputThanZero'));
                return false;
            }
            if (!reg.test(value)) {
                AntMessage.warning(services.language.getText('jezdzclwxs'));
                return false;
            }
            row[name] = value;
            props.onChange(newData);
        }
    };

    const onAddCoupons = (record: any, coupons: any) => {
        if (!coupons || coupons.length === 0) {
            return;
        }
        let newData = data ? data : [];
        let row: any = find(newData, { id: record.id });
        if (row) {
            let newCoupons: any = [];
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => coupon.id === selectCuopon.id
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
            row.coupons = newCoupons.map(modalToCoupon);
            props.onChange(newData);
        }
    };

    const addPaymentReward = () => {
        let newData = data ? data : [];
        rewardIndex++;
        newData.push({ ...props.initData, id: rewardIndex++ });
        props.onChange(newData);
    };
    const renderColumn = (value: any, record: any, item: any) => {
        const { type } = item;
        if (type === 'InputNumber') {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputNumber
                        placeholder={services.language.getText('inputPlease')}
                        value={value}
                        min={0.01}
                        max={999999999}
                        onChange={(value: any) => changeLowerValue(record, value, item.key)}
                    ></InputNumber>
                    &nbsp;&nbsp;
                </div>
            );
        }
        if (type === 'coupons') {
            let coupons = value;
            const hasCoupons = coupons && coupons.length > 0;
            const isPackage = get(record, 'coupons[0].type') === 'PACKAGE';
            return (
                <div>
                    {hasCoupons ? (
                        <label>
                            {sumBy(coupons, (c: any) => c.quantity)}
                            {isPackage
                                ? services.language.getText('gifts')
                                : services.language.getText('zhang')}
                            {isPackage ? (
                                <Popover
                                    title={`【应付金额满${
                                        record[item.displayValue]
                                    }${services.language.getText('yuan')}】 送优惠券规则设置`}
                                    content={
                                        <CouponPackageSelectorDisplay
                                            onChange={(coupons) => changeCoupons(record, coupons)}
                                            disablePrice={true}
                                            data={coupons}
                                        />
                                    }
                                >
                                    <Button type="link">设置</Button>
                                </Popover>
                            ) : (
                                <Popover
                                    title={`【应付金额满${
                                        record[item.displayValue]
                                    }${services.language.getText('yuan')}】 送优惠券规则设置`}
                                    content={
                                        <CouponSelectorWithDisplay
                                            onChange={(coupons) => changeCoupons(record, coupons)}
                                            data={coupons}
                                            filterParams={filterCouponParams}
                                            canShowApportionPrice={false}
                                            canShowLeftStock
                                        />
                                    }
                                >
                                    <Button type="link">设置</Button>
                                </Popover>
                            )}
                        </label>
                    ) : (
                        <>
                            <CouponSelector
                                type={'checkbox'}
                                selectValues={[]}
                                onChange={(coupons) => onAddCoupons(record, coupons)}
                                buttonProps={{ text: '设置优惠券', type: 'link' }}
                                params={filterCouponParams}
                            ></CouponSelector>
                            <CouponPackageSelector
                                type="radio"
                                selectValues={[]}
                                onChange={(coupons) => onAddCoupons(record, coupons)}
                                buttonProps={{ text: '设置优惠券礼包', type: 'link' }}
                                params={filterCouponParams}
                            ></CouponPackageSelector>
                        </>
                    )}
                </div>
            );
        }
        if (type === 'operations') {
            return (
                <Button type="link" onClick={onDelete(record)}>
                    {services.language.getText('common.delete')}
                </Button>
            );
        }
        return null;
    };
    const renderColumns = () => {
        const { columns } = props;
        const nC = cloneDeep(columns);
        if (columns.length) {
            let c: any = [];
            nC.forEach((item: any) => {
                c.push({
                    title: item.title,
                    dataIndex: item.key,
                    key: item.key,
                    align: item.align,
                    render: (lowerValue: any, record: any) =>
                        renderColumn(lowerValue, record, item),
                });
            });
            return c;
        }
        return [];
    };

    const tableProps = useMemo(() => {
        return {
            dataSource: data,
            pagination: false as const,
            columns: renderColumns(),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filterCouponParams]);
    const { extra } = props;

    return (
        <div className="payment-rewards">
            <Table {...tableProps}></Table>
            {!!extra && <div className="ant-form-extra">{extra}</div>}
            <Button type="default" style={{ marginTop: 10 }} onClick={addPaymentReward}>
                添加阶梯奖励
            </Button>
        </div>
    );
};
