import { Config } from '@comall-backend-builder/core/lib/parser';
import { REFUND_ORDER_STATUS_OPTIONS } from '../../member/member-service/mall-activity/mall-activity-refund-order';
import { services } from '@comall-backend-builder/core';
export const config: Config = {
    entities: {
        DepositRefundEntity: {
            apiPath: '/loader/admin/deposit_order_refund',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<ddshdh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sqsj>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                depositOrderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                depositOrderSubsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                depositOrderMerchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                refundAmount: {
                    type: 'string',
                    displayName: '<<tkje>>',
                },
                interfaceStatus: {
                    type: 'string.options.select',
                    displayName: '<<tkzt>>',
                    options: REFUND_ORDER_STATUS_OPTIONS,
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                remark: {
                    type: 'string',
                    displayName: '<<scyy>>',
                },
            },
        },
        PosReceiptRefundEntity: {
            apiPath: '/loader/admin/pos_reciept_refund',
            filters: {
                posReceiptNo: {
                    type: 'string',
                    displayName: '<<xpdh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<ddshdh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
                merchantId: {
                    type: 'string.filterOptions.select',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/delivery_subsites_merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                    },
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                posReceiptNo: {
                    type: 'string',
                    displayName: '<<xpdh>>',
                },
                depositOrderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                depositOrderSubsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                depositOrderMerchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                },
                refundAmount: {
                    type: 'string',
                    displayName: '<<tkje>>',
                },
                interfaceStatus: {
                    type: 'string.options.select',
                    displayName: '<<tkzt>>',
                    options: REFUND_ORDER_STATUS_OPTIONS,
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                remark: {
                    type: 'string',
                    displayName: '<<scyy>>',
                },
            },
        },
    },
    components: {
        DepositRefundView: {
            component: 'Viewport',
        },
        DepositRefundPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [
                {
                    component: 'DepositRefundPageTabs',
                },
            ],
        },
        DepositRefundPageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<djdtk>>',
                    content: {
                        component: 'DepositRefundPageView',
                    },
                },
                {
                    title: '<<xsdtdj>>',
                    content: {
                        component: 'PosReceiptRefundPageView',
                    },
                },
            ],
        },
        DepositRefundPageView: {
            entity: 'DepositRefundEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DepositRefundFilter',
                },
                { component: 'DepositRefundTables' },
            ],
        },
        DepositRefundFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'orderNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'serviceApplyNo',
                },
                {
                    property: 'merchantBigCode',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'guideName',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        DepositRefundTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'DepositRefundTable',
                    },
                    params: {},
                },
                {
                    title: '<<wcl>>',
                    content: {
                        component: 'DepositRefundTable',
                    },
                    params: { interfaceStatus: 'APPLYING' },
                },
                {
                    title: '<<tkcg>>',
                    content: {
                        component: 'DepositRefundTable',
                    },
                    params: { interfaceStatus: 'REFUND_SUCC' },
                },
                {
                    title: '<<tksb>>',
                    content: {
                        component: 'DepositRefundTable',
                    },
                    params: { interfaceStatus: 'REFUND_FAIL' },
                },
            ],
        },
        DepositRefundTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'depositOrderNo',
                        },
                        {
                            property: 'depositOrderSubsiteName',
                        },
                        {
                            property: 'depositOrderMerchantName',
                        },
                        { property: 'guideName' },
                        {
                            property: 'refundAmount',
                        },
                        {
                            property: 'interfaceStatus',
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'merchantBigCode',
                        },
                        {
                            property: 'serviceApplyNo',
                        },
                        {
                            property: 'remark',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 100,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'canRefund',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                                            apiPath: '/admin/deposit_order_refund_items/:id',
                                            config: {
                                                content: '<<zctk>>',
                                                text: '<<zctk>>',
                                            },
                                            confirm: {
                                                text: '<<qdyzxfqtk>>',
                                            },
                                        },
                                        {
                                            value: false,
                                            config: {
                                                content: '<<zctk>>',
                                                text: '<<zctk>>',
                                                disabled: true,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },

        PosReceiptRefundPageView: {
            entity: 'PosReceiptRefundEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'PosReceiptRefundFilter',
                },
                { component: 'PosReceiptRefundTables' },
            ],
        },
        PosReceiptRefundFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'posReceiptNo',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'serviceApplyNo',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'guideName',
                },
            ],
        },
        PosReceiptRefundTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'PosReceiptRefundTable',
                    },
                    params: {},
                },
                {
                    title: '<<tkcg>>',
                    content: {
                        component: 'PosReceiptRefundTable',
                    },
                    params: { interfaceStatus: 'REFUND_SUCC' },
                },
                {
                    title: '<<tksb>>',
                    content: {
                        component: 'PosReceiptRefundTable',
                    },
                    params: { interfaceStatus: 'REFUND_FAIL' },
                },
            ],
        },
        PosReceiptRefundTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'posReceiptNo',
                        },
                        {
                            property: 'depositOrderNo',
                        },
                        {
                            property: 'depositOrderSubsiteName',
                        },
                        {
                            property: 'depositOrderMerchantName',
                        },
                        {
                            property: 'guideName',
                        },
                        {
                            property: 'refundAmount',
                        },
                        {
                            property: 'interfaceStatus',
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'merchantBigCode',
                        },
                        {
                            property: 'serviceApplyNo',
                        },
                        {
                            property: 'remark',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 100,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'canRefund',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                                            apiPath: '/admin/deposit_order_refund_items/:id',
                                            config: {
                                                content: '<<zctk>>',
                                                text: '<<zctk>>',
                                            },
                                            confirm: {
                                                text: '<<qdyzxfqtk>>',
                                            },
                                        },
                                        {
                                            value: false,
                                            config: {
                                                content: '<<zctk>>',
                                                text: '<<zctk>>',
                                                disabled: true,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/deposit-refund',
                    component: 'DepositRefundView',
                    breadcrumbName: '<<depositOrderRefund>>',
                    privilege: {
                        path: 'depositOrderRefund',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'DepositRefundPage' }],
                },
            ],
        },
    ],
};
