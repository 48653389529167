import * as React from 'react';
import classNames from 'classnames';
import { map, find } from 'lodash';
import { ArrayMode } from '@comall-backend-builder/types';
import { MemberLevelRules } from '../../../components';

export class ArrayMemberLevelRulesMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div
                    className={classNames('type-array mode-array-cgb-rule', className)}
                    style={style}
                >
                    {map(array, ({ userLevelId, ruleCount }, index) => {
                        let userLevelIdOption = find(options, { id: userLevelId }) || {};
                        return (
                            <div key={index}>
                                <span>会员等级： {userLevelIdOption.name} </span>
                                <span>最多购买{ruleCount}件</span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberLevelRules {...controlInfo} />;
    }
}
