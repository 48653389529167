import React, { PureComponent, createElement, Fragment } from 'react';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import PropTypes from 'prop-types';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal, Form, Radio, Input, Descriptions, Row, Col } from 'antd';
import { get } from 'lodash';
import { NewMallActivityTicketType } from '../../../components';
import './index.less';
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const ReviewNewMallActivityForm = Form.create({ name: 'review' })(
    class extends React.Component {
        static propTypes: {
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                const { auditStatus, auditMark } = values;
                if (auditStatus === 'REJECTED' && !auditMark.trim()) {
                    return AntMessage.error('请填写审核不通过的原因~');
                }

                onSubmit(values);
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator, getFieldValue } = form;
            return (
                <Form
                    style={{ marginTop: 15 }}
                    ref="reviewFrom"
                    {...formItemLayout}
                    onSubmit={this.handleSubmit}
                >
                    <FormItem label={'审核结果'}>
                        {getFieldDecorator('auditStatus', {
                            initialValue: 'APPROVED',
                            rules: [{ required: true }],
                        })(
                            <Radio.Group>
                                <Radio value={'APPROVED'}>审核通过</Radio>
                                <Radio value={'REJECTED'}>
                                    {services.language.getText('shbtg')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    {getFieldValue('auditStatus') === 'REJECTED' && (
                        <FormItem label={'原因'}>
                            {getFieldDecorator('auditMark', {
                                initialValue: '',
                            })(
                                <Input.TextArea
                                    maxLength={500}
                                    placeholder="请填写审核不通过的原因，审核不通过的原因会展示给用户~   不超过500个字"
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                            )}
                        </FormItem>
                    )}
                </Form>
            );
        }
    }
);

class ReviewNewMallActivityView extends PureComponent<any> {
    state = { visible: false, reviewInfo: {} };
    openModal = () => {
        this.setState({ visible: true });
        this.loadData();
    };

    loadData = async () => {
        const { id } = this.props.row;
        try {
            let reviewInfo: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${id}`,
                }
            );
            const {
                roundStartDate,
                roundEndDate,
                roundStartTime,
                roundEndTime,
            } = reviewInfo.ticketInfo;
            reviewInfo.participateDate =
                reviewInfo.mallActivityInfo?.ticketType === NewMallActivityTicketType.Activity
                    ? `${roundStartDate}至${roundEndDate}`
                    : `${roundStartDate} ${roundStartTime}${
                          roundEndTime ? `-${roundEndTime}` : ''
                      }`;
            const orderPoint = get(reviewInfo, 'orderInfo.totalPointAmount');
            const orderPrice = get(reviewInfo, 'orderInfo.totalSalesAmount');
            let orderPriceStr = '';
            if (
                (!orderPoint || parseFloat(orderPoint) === 0) &&
                (!orderPrice || parseFloat(orderPrice) === 0)
            ) {
                orderPriceStr = '0';
            } else if (orderPoint && orderPrice) {
                orderPriceStr = `${orderPoint}积分+${orderPrice}${services.language.getText(
                    'yuan'
                )}`;
            } else if (!orderPoint && orderPrice) {
                orderPriceStr = `${orderPrice}${services.language.getText('yuan')}`;
            } else if (orderPoint && !orderPrice) {
                orderPriceStr = `${orderPoint}积分`;
            }
            reviewInfo.orderPriceStr = orderPriceStr;

            this.setState({ reviewInfo });
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
    };
    onSubmit = async (value: any) => {
        const { id } = this.props.row;
        const { entity, params: entityParams } = this.props;

        try {
            await api.put(
                { ...value },
                {
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${id}/audit`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                }
            );
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
        AntMessage.success(services.language.getText('actionSuccess'));
        entity && entity.search(entityParams);
        this.listenRef.handleReset();
        this.setState({ visible: false });
    };
    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    render() {
        const { triggerComponent, row } = this.props;
        const { reviewInfo, visible } = this.state;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };

        const showAudit = row.orderStatus === 'WAIT_AUDIT';

        return (
            <Fragment>
                {showAudit ? createElement(ComponentsManager.get('Button'), buttonProps) : null}
                <Modal
                    visible={visible}
                    className="review-new-mall-activity-view"
                    title="审核参与资格"
                    mask={false}
                    maskClosable
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                    width="680px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        this.listenRef.handleReset();
                        this.setState({ visible: false });
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <Descriptions layout="vertical" bordered>
                                <Descriptions.Item label="买家信息">
                                    <Row>
                                        <Col span={24}>
                                            <span>
                                                下单人手机号：
                                                {get(reviewInfo, 'purchaseMemberInfo.mobile') || ''}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                下单人报名成功的次数：
                                                {get(
                                                    reviewInfo,
                                                    'purchaseMemberInfo.successTicketPurchaseCount'
                                                )}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                下单人卡等级：
                                                {get(
                                                    reviewInfo,
                                                    'purchaseMemberInfo.memberCardLevelName'
                                                ) || ''}
                                            </span>
                                        </Col>

                                        <Col span={24} className="mt">
                                            <span>
                                                未参与活动的次数：
                                                {get(reviewInfo, 'purchaseMemberInfo.unJoinCount')}
                                            </span>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={12}>
                            <Descriptions
                                layout="vertical"
                                bordered
                                style={{ marginLeft: 20 }}
                                className="participant-box"
                            >
                                <Descriptions.Item label="参与者信息">
                                    <Row>
                                        <Col span={24}>
                                            <span>
                                                参与人姓名：
                                                {get(reviewInfo, 'joinMemberInfo.name') || ''}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                参与人手机号：
                                                {get(reviewInfo, 'joinMemberInfo.mobile') || ''}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                参与时间：{get(reviewInfo, 'participateDate') || ''}
                                            </span>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={24}>
                            <Descriptions layout="vertical" bordered style={{ marginTop: 20 }}>
                                <Descriptions.Item label="票档信息">
                                    <Row>
                                        <Col span={12}>
                                            <span>
                                                门票名称：{get(reviewInfo, 'ticketInfo.name') || ''}
                                            </span>
                                        </Col>
                                        <Col span={8}>
                                            <span>
                                                总价：
                                                {get(reviewInfo, 'orderPriceStr') || '-'}
                                            </span>
                                        </Col>
                                        <Col span={4}>
                                            <span>
                                                数量：
                                                {get(reviewInfo, 'ticketInfo.purchasedQuantity')}
                                            </span>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <ReviewNewMallActivityForm
                        wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                        onSubmit={this.onSubmit}
                    />
                </Modal>
            </Fragment>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields, entity };
}
export const ReviewNewMallActivityButton = connect(
    mapStateToProps,
    null
)(ReviewNewMallActivityView);
