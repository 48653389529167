import React, { Component, FC } from 'react';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import {
    SharePageReward,
    EVENT_TYPE,
    SharePageRewardItem,
} from '../../types/mode/array/share-page-activity-rewards/share-page-activity-rewards';
import { EventCouponValueTypeEnum } from '../../components/new-coupon-selector/select-coupon';
import { isSimplicityTheme } from '../theme-style';

import './index.less';
import { services } from '@comall-backend-builder/core';

const RecordsImage = require('./image/records.png');
const RewardRecordsImage = require('./image/reward-records.png');
const IconSharePageVisitorAccess = require('./image/icon-share-page-visitor-access.png');
const IconSharePageMemberRegister = require('./image/icon-share-page-member-register.png');
const IconPoint = require('./image/share-page-icon-point.png');
const IconPackage = require('./image/share-page-icon-package.png');
const IconCoupon = require('./image/share-page-icon-coupon.png');

const prefix = 'share-page-activity-preview';

/**
 * 活动左侧
 * @param props
 */
class sharePageActivityPreview extends Component<any, { themeStyle: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            themeStyle: {},
        };
    }
    componentDidMount(): void {
        this.getThemeStyle();
    }

    getThemeStyle = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/theme_style',
        };
        api.get({}, config).then((res: any) => {
            if (res.id) {
                this.setState({ themeStyle: JSON.parse(res.config) });
            }
        });
    };

    private get isSimplicityTheme() {
        const { themeStyle } = this.state;
        if (!themeStyle) {
            return false;
        }
        return isSimplicityTheme(themeStyle);
    }

    getField = (field: string) => {
        const { preview } = this.props;
        return get(preview, field);
    };
    private get rewards(): Array<SharePageReward> {
        const rewards = this.getField('ruleInfo.rewards');
        if (!rewards || !rewards.length) {
            return [];
        }
        return rewards;
    }

    renderRuleEntra = () => {
        const rule = this.getField('pageConfig.description');
        if (!rule) {
            return null;
        }
        const className = `${prefix}__rule`;
        return <div className={className}>{services.language.getText('rule')}</div>;
    };

    /**
     * 按访客发奖励
     */
    private get sharePageVisitorAccessRewards(): SharePageReward | undefined {
        return this.rewards.find(
            (reward) => reward.eventType === EVENT_TYPE.SHARE_PAGE_VISITOR_ACCESS
        );
    }
    /**
     * 按新用户注册发奖励
     */
    private get sharePageMemberRegisterRewards(): SharePageReward | undefined {
        return this.rewards.find(
            (reward) => reward.eventType === EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER
        );
    }
    renderActivityInfo = () => {
        return (
            <ContentArea title={services.language.getText('fxhydjl')}>
                {this.renderRewards(this.sharePageVisitorAccessRewards)}
                {this.renderRewards(this.sharePageMemberRegisterRewards)}
                {this.renderActivityDate()}
            </ContentArea>
        );
    };

    renderActivityDate = () => {
        const dateRange = this.getField('baseInfo.dateRange');
        if (!dateRange) {
            return null;
        }
        const { start, end } = dateRange;
        const dateTime = (str: string) => moment(str).format('YYYY.MM.DD HH:MM');
        return (
            <div className="share-page-activity-preview__date">
                {language.getText('acitivityTime')}：{dateTime(start)}-{dateTime(end)}
            </div>
        );
    };

    private get pageName() {
        const pageInfo = this.getField('ruleInfo.pageInfo');
        return pageInfo ? pageInfo.name : '';
    }

    couponReward = (coupon: any) => {
        const {
            couponValue: { type } = { type: '' },
            couponTypeDesc,
            quantity,
            amountValue,
            couponType,
        } = coupon;
        if (couponType === 'PACKAGE') {
            return { icon: IconPackage, text: language.getText('zhjl') };
        }
        const unit: any = {
            [EventCouponValueTypeEnum.Amount]: language.getText('yuan'),
            [EventCouponValueTypeEnum.Hours]: language.getText('hour'),
        };
        const unitText = unit[type];
        const valueText = amountValue && unitText ? `${amountValue}${unitText}` : '';
        const quantityText = quantity && quantity > 1 ? `*${quantity}` : '';
        return { icon: IconCoupon, text: `${valueText}${couponTypeDesc}${quantityText}` };
    };

    getRewards = (rewardItem: SharePageRewardItem) => {
        const { point, coupons } = rewardItem;
        const rewards: Array<{ icon: string; text: string }> = [];
        if (point) {
            rewards.push({ icon: IconPoint, text: `${point}` + language.getText('point') });
        }
        if (coupons && coupons.length) {
            rewards.push(...coupons.map((coupon) => this.couponReward(coupon)));
        }
        return rewards;
    };

    rewardInfo = (reward: SharePageReward) => {
        const {
            eventType,
            rewardItem: { stepValue },
        } = reward;
        if (eventType === EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER) {
            return {
                title:
                    services.language.getText('myq') +
                    `${stepValue || 'n'}` +
                    services.language.getText('whyzcbll'),
                icon: IconSharePageMemberRegister,
                num: 0,
            };
        }
        return {
            title: services.interpolate(language.getText('shareToFriend'), {
                stepValue: stepValue || 'n',
            }),
            icon: IconSharePageVisitorAccess,
            num: 5,
        };
    };

    renderRewards = (reward: SharePageReward | undefined) => {
        if (!reward) {
            return null;
        }
        const {
            rewardItem: { stepValue },
            rewardItem,
        } = reward;
        const { title, icon, num } = this.rewardInfo(reward);
        const rewards = this.getRewards(rewardItem);
        const innerClassNames = {
            reward: `${prefix}__reward`,
            top: `${prefix}__reward__top`,
            left: `${prefix}__reward__left`,
            center: `${prefix}__reward__center`,
            right: `${prefix}__reward__right`,
            title: `${prefix}__reward__title`,
            desc: `${prefix}__reward__desc`,
            rate: `${prefix}__reward__rate`,
            rateNum: `${prefix}__reward__rate__num`,
            share: `${prefix}__reward__share`,
            bottom: `${prefix}__reward__bottom`,
            bottomScroll: `${prefix}__reward__bottom__scroll`,
            bottomItem: `${prefix}__reward__bottom__item`,
            bottomIcon: `${prefix}__reward__bottom__icon`,
        };
        return (
            <div className={innerClassNames.reward}>
                <div className={innerClassNames.top}>
                    <img className={innerClassNames.left} alt="" src={icon} />
                    <div className={innerClassNames.center}>
                        <div className={innerClassNames.title}>{title}</div>
                        <div className={innerClassNames.desc}>{this.pageName}</div>
                    </div>
                    <div className={innerClassNames.right}>
                        <div className={innerClassNames.rate}>
                            <span className={innerClassNames.rateNum}>{num}</span>
                            <span>/{stepValue || 'n'}</span>
                        </div>
                        <div className={innerClassNames.share}>
                            {services.language.getText('qfx')}
                        </div>
                    </div>
                </div>
                {!!rewards.length && (
                    <div className={innerClassNames.bottom}>
                        <div className={innerClassNames.bottomScroll}>
                            {rewards.map(({ icon, text }) => (
                                <div className={innerClassNames.bottomItem}>
                                    <img className={innerClassNames.bottomIcon} alt="" src={icon} />
                                    <span>{text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderRecords = () => {
        return (
            <ContentArea title={services.language.getText('hylljl')}>
                <img src={RecordsImage} alt="" className="share-page-activity-preview__records" />
            </ContentArea>
        );
    };

    renderRewardRecords = () => {
        return (
            <ContentArea title={services.language.getText('yhdyxjl')}>
                <img
                    src={RewardRecordsImage}
                    alt=""
                    className="share-page-activity-preview__reward-records"
                />
            </ContentArea>
        );
    };

    render() {
        const { themeStyle } = this.state;
        const backgroundPicture = this.getField('pageConfig.backgroundPicture');
        const backgroundImage = get(backgroundPicture, '[0].path', undefined);
        const contentStyle = {
            backgroundImage: `url(${backgroundImage})`,
        };
        const headStyle = {
            backgroundColor: this.isSimplicityTheme
                ? themeStyle?.navigation?.style?.backgroundColor
                : '#fff',
        };
        return (
            <div className="share-page-activity-preview">
                <div className="theme-style-preview-item" style={headStyle}>
                    <img style={{ width: '100%' }} alt="" src="./images/avatar/theme-head.png" />
                    <div className="theme-style-preview-cart-title">
                        {services.language.getText('fxyl')}
                    </div>
                </div>
                <div style={contentStyle} className="share-page-activity-preview__content">
                    {this.renderRuleEntra()}
                    {this.renderActivityInfo()}
                    {this.renderRecords()}
                    {this.renderRewardRecords()}
                </div>
            </div>
        );
    }
}

const ContentArea: FC<{ title: string }> = (props) => {
    const { title, children } = props;
    const innerClassNames = {
        area: `${prefix}__area`,
        header: `${prefix}__area__header`,
    };
    return (
        <div className={innerClassNames.area}>
            <div className={innerClassNames.header}>{title}</div>
            <div>{children}</div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }

    return { preview: preview };
};

export const SharePageActivityPreview = connect(mapStateToProps)(sharePageActivityPreview);
