import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CreditShopNotice } from './credit-shop-notice';

export class CreditShopNoticeMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CreditShopNotice {...displayInfo} />;
    }
}
