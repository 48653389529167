import * as React from 'react';
import { map } from 'lodash';
import classNames from 'classnames';

import { modes } from '@comall-backend-builder/types';
import { ImagePreview } from '@comall-backend-builder/components-basis';
import { ImageUploadAndCrop } from './image-upload-and-crop';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayImageUploadAndCrop extends modes.ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, preview } = displayInfo;

        if (array && array.length) {
            // 原数据集array需要格式化，在preview中显示
            let formatArray: Array<any> = [];
            map(array, (item, key) => {
                formatArray.push({
                    id: item.id,
                    imageurl: item.path,
                    text: item.path,
                    component: 'Image',
                });
            });

            // 图片配置信息
            const imageConfig = {
                className: classNames('type-array mode-array-images', className),
                style: style || {},
            };

            // 预览组件配置信息
            const previewProps = {
                ...preview,
                type: 'modal',
                imageConfig,
                items: map(formatArray, 'imageurl'),
            };
            return <ImagePreview {...previewProps} />;
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ImageUploadAndCrop {...controlInfo} />;
    }
}
