import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

const SUBSCRIBE_CHANNEL_OPTIONS = [
    {
        id: 'ADD_SCENE_SEARCH',
        name: '<<gzhss>>',
    },
    {
        id: 'ADD_SCENE_ACCOUNT_MIGRATION',
        name: '<<gzhqy>>',
    },
    {
        id: 'ADD_SCENE_PROFILE_CARD',
        name: '<<mpfx>>',
    },
    {
        id: 'ADD_SCENE_QR_CODE',
        name: '<<smewm>>',
    },
    {
        id: 'ADD_SCENE_PROFILE_LINK',
        name: '<<twynmcdj>>',
    },
    {
        id: 'ADD_SCENE_PROFILE_ITEM',
        name: '<<twyysjcd>>',
    },
    {
        id: 'ADD_SCENE_PAID',
        name: '<<zfhgz>>',
    },
    {
        id: 'ADD_SCENE_WECHAT_ADVERTISEMENT',
        name: '<<wxgg>>',
    },
    {
        id: 'ADD_SCENE_REPRINT',
        name: '<<trzz>>',
    },
    {
        id: 'ADD_SCENE_LIVESTREAM',
        name: '<<sphzb>>',
    },
    {
        id: 'ADD_SCENE_CHANNELS',
        name: '<<sph>>',
    },
    {
        id: 'ADD_SCENE_OTHERS',
        name: '<<other>>',
    },
];

const USER_IDENTITY_OPTIONS = [
    {
        id: 'ALL',
        name: '<<all>>',
    },
    {
        id: 'MEMBER',
        name: '<<member>>',
    },
    {
        id: 'WECHAT_OFFICIAL_ACCOUNT_FAN',
        name: '<<fs>>',
    },
];

const SUBSCRIBED_OPTIONS = [
    {
        id: 'true',
        name: '<<ygz>>',
    },
    {
        id: 'false',
        name: '<<wgz>>',
    },
];

const now = new Date();
const yesterday = new Date(now.getTime() - 1000 * 60 * 60 * 24);
function getDate(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const monthStr = month > 9 ? month : '0' + month;
    const dayStr = day > 9 ? day : '0' + day;
    return `${year}-${monthStr}-${dayStr}`;
}

const start = getDate(yesterday);
const end = getDate(now);

export const config: Config = {
    entities: {
        WechatOfficialAccountFanEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
            apiPath: '/loader/admin/wechat_official_accounts_fan',
            filters: {
                wechatOfficialAccountName: {
                    type: 'string',
                    displayName: '<<gzhmc>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<gzsj>>',
                    defaultValue: {
                        start,
                        end,
                    },
                    startField: 'subscribeStartTime',
                    endField: 'subscribeEndTime',
                },
                subscribeChannel: {
                    type: 'string.options.select',
                    displayName: '<<yhgzqdly>>',
                    options: SUBSCRIBE_CHANNEL_OPTIONS,
                },
                mobile: {
                    type: 'string.text.trim',
                    displayName: '<<sjhm>>',
                    rules: [
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (value && value.trim()) {
                                    if (!/^[0-9]*$/.test(value.trim())) {
                                        return callback(services.language.getText('jzcsrsz'));
                                    }
                                }
                                callback();
                            },
                        },
                    ],
                },
                userIdentity: {
                    type: 'string.options.select',
                    displayName: '<<yhsf>>',
                    options: USER_IDENTITY_OPTIONS,
                },
                qrSceneName: {
                    type: 'string',
                    displayName: '<<smly>>',
                },
                unsubscribeDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<qxgzsj>>',
                    startField: 'unsubscribeStartTime',
                    endField: 'unsubscribeEndTime',
                },
                subscribed: {
                    type: 'string.options.select',
                    displayName: '<<gzzt>>',
                    options: SUBSCRIBED_OPTIONS,
                },
            },
            properties: {
                openId: {
                    type: 'string',
                    displayName: '<<fs>>Openid',
                },
                wechatOfficialAccountName: {
                    type: 'string',
                    displayName: '<<gzhmc>>',
                },
                subscribeTime: {
                    type: 'string',
                    displayName: '<<gzsj>>',
                },
                subscribeChannel: {
                    type: 'string.options.select',
                    displayName: '<<gzqdly>>',
                    options: SUBSCRIBE_CHANNEL_OPTIONS,
                },
                subsite: {
                    type: 'array.popover',
                    displayName: '<<gzhbdmdxx>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<fssjh>>',
                },
                identity: {
                    type: 'string.options.select',
                    displayName: '<<yhsf>>',
                    options: USER_IDENTITY_OPTIONS,
                },
                memberCreateTime: {
                    type: 'string',
                    displayName: '<<cwhysj>>',
                },
                qrSceneName: {
                    type: 'string',
                    displayName: '<<smly>>',
                },
                unsubscribeTime: {
                    type: 'string',
                    displayName: '<<qxgzsj>>',
                },
                subscribed: {
                    type: 'string.options.select',
                    displayName: '<<gzzt>>',
                    options: SUBSCRIBED_OPTIONS,
                },
            },
        },
    },
    components: {
        WechatOfficialAccountFanView: {
            component: 'Viewport',
            entity: 'WechatOfficialAccountFanEntity',
        },
        WechatOfficialAccountFanPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'WechatOfficialAccountFanFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<dcfssj>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                                apiPath: '/admin/wechat_official_accounts_fan/download',
                                requestMethod: 'get',
                                target: '_blank',
                                tips: '<<jzcdc>>2w<<yndsj>>',
                            },
                        },
                    ],
                },
                { component: 'WechatOfficialAccountFanTable' },
            ],
        },
        WechatOfficialAccountFanFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'wechatOfficialAccountName',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<inputPlease>>',
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<inputPlease>>',
                    },
                },
                {
                    property: 'subscribeChannel',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },

                {
                    property: 'qrSceneName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'userIdentity',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'unsubscribeDateRange',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'subscribed',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
            ],
        },
        WechatOfficialAccountFanTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'openId',
                },
                {
                    property: 'wechatOfficialAccountName',
                },
                {
                    property: 'subscribeTime',
                },
                {
                    property: 'unsubscribeTime',
                },
                {
                    property: 'subscribed',
                },
                {
                    property: 'subscribeChannel',
                },
                {
                    property: 'qrSceneName',
                },
                {
                    property: 'subsite',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'identity',
                    width: '120px',
                },
                {
                    property: 'memberCreateTime',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-official-account-fan',
                    component: 'WechatOfficialAccountFanView',
                    breadcrumbName: '<<wechatOfficialAccountFan>>',
                    privilege: {
                        path: 'wechatOfficialAccountFan',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'WechatOfficialAccountFanPage' }],
                },
            ],
        },
    ],
};
