import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const shoppingListLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/shopping_list_union_order_records';
        const { id } = data;
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            delete data.dateRange;
        }
        data.orderType = 'NORMAL';
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    item.orderInfo.externalOrderNo = item.orderInfo.externalOrderNo || '';
                    item.guideCode = item.guideCode || '';
                    return item;
                });
            }
            return res;
        });
    },
};
