import React, { PureComponent } from 'react';
import { Upload, Icon, message as AntMessage } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { services } from '@comall-backend-builder/core';
import { get, isEqual } from 'lodash';
import { language, localStorage } from '@comall-backend-builder/core/lib/services';
import DragUploadPreview from '../../../../components/drag-upload-preview';

export class ArrayImgaesUploadSortable extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { loading: false };
    }
    componentWillReceiveProps(nextProps: any) {
        const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
        const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
        const nextCouponItem = nextCoupon && nextCoupon.length > 0 ? nextCoupon[0] : undefined;
        const nextPictures = get(nextProps, 'row.baseInfo.pictures', []);
        // 如果有选择优惠券且当前图片没有上传带入优惠券主图
        if (
            !isEqual(currentCoupon, nextCoupon) &&
            nextCouponItem &&
            nextCouponItem.pictureConfigs &&
            nextCouponItem.pictureConfigs.length > 0 &&
            nextPictures.length === 0
        ) {
            const [couponItemPic] = nextCouponItem.pictureConfigs;
            if (nextProps.onChange) {
                nextProps.onChange([
                    {
                        id: couponItemPic.pictureId,
                        path: couponItemPic.url,
                    },
                ]);
            }
        }
        const creditCurrentCoupon = get(this.props, 'row.coupon', []);
        const creditNextCoupon = get(nextProps, 'row.coupon', []);
        const creditNextCouponItem =
            creditNextCoupon && creditNextCoupon.length > 0 ? creditNextCoupon[0] : undefined;
        // 如果有选择优惠券且当前图片没有上传带入优惠券主图
        if (
            !isEqual(creditCurrentCoupon, creditNextCoupon) &&
            creditNextCouponItem &&
            creditNextCouponItem.pictureConfigs &&
            creditNextCouponItem.pictureConfigs.length > 0
        ) {
            const [couponItemPic] = creditNextCouponItem.pictureConfigs;
            if (nextProps.onChange) {
                nextProps.onChange([
                    {
                        id: couponItemPic.pictureId,
                        path: couponItemPic.url,
                    },
                ]);
            }
        }

        const { autoFillDependencies } = nextProps;
        if (autoFillDependencies) {
            const currentImages = get(this.props, autoFillDependencies, []);
            const nextImages = get(nextProps, autoFillDependencies, []);
            if (!isEqual(currentImages, nextImages)) {
                let images = [];
                if (nextImages && nextImages.length) {
                    images = nextImages.map((img: any) => {
                        return {
                            id: img.id,
                            path: img.url,
                        };
                    });
                } else {
                    AntMessage.warn(language.getText('qwhlptp'));
                }
                nextProps.onChange(images);
            }
        }
    }
    beforeUpload = (file: any) => {
        const { maxSize, uploadLimitSizeErrorMessage } = this.props;
        if (file.size > maxSize * 1024) {
            var errorMessage =
                uploadLimitSizeErrorMessage === undefined
                    ? language.getText('pictures') + '{{file.name}}' + language.getText('thanLimit')
                    : uploadLimitSizeErrorMessage;
            AntMessage.error(services.interpolate(errorMessage, { file: file, maxSize: maxSize }));
            return false;
        }
        return true;
    };
    handleChange = (info: UploadChangeParam) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            const { value = [], onChange } = this.props;
            if (info.file && info.file.response) {
                if (onChange) {
                    onChange([...value, info.file.response]);
                }
            }
            this.setState({
                loading: false,
            });
        }
    };
    handlePreview = (file: UploadFile) => {
        if (file && file.response && file.response.path) {
            this.setState({
                previewImage: file.response.path,
                previewVisible: true,
            });
        }
    };
    render() {
        const { fileName, accept, uploadUrl, mode, value = [], maxCount, disabled } = this.props;
        const user: any = localStorage.get('user');
        const token = encodeURI(`Bearer ${user.accessToken}`);
        const _controlInfo = {
            name: fileName || 'fileName',
            accept: accept || 'image/*',
            action: uploadUrl,
            listType: mode || 'picture-card',
            showUploadList: false,
            headers: {
                Authorization: token,
            },
        };
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{language.getText('common.upload')}</div>
            </div>
        );
        return (
            <div>
                <DragUploadPreview {...this.props}>
                    {value.length < maxCount ? (
                        <Upload
                            {..._controlInfo}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChange}
                            onPreview={this.handlePreview}
                            disabled={disabled}
                        >
                            {uploadButton}
                        </Upload>
                    ) : null}
                </DragUploadPreview>
            </div>
        );
    }
}
