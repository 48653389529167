import React, { PureComponent, Fragment, createElement } from 'react';
import { connect } from 'react-redux';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Button as AntButton } from 'antd';
import { errorHandle } from '../../services/error-handle';
import { UploadFile } from './components/upload-file';
import './index.less';
/**
 * 用户管理列表
 */
export interface userManageListProps {
    entity: Entity;
    componentId: string;
    params: any;
}

class userManageList extends PureComponent<any, any> {
    state = { uploadModalVisible: false };
    selectedRowKeys: string[] = [];
    onChange = (selectedRowKeys: string[]) => {
        this.selectedRowKeys = selectedRowKeys;
    };
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps = {
            entity,
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'userName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'email',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'realName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'passwordValidityPeriodStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '60px',
                        },
                    },
                },
                {
                    property: 'createTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '160px',
                        },
                    },
                },
                {
                    property: 'createUserName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
            ],
            rowSelection: {
                onChange: this.onChange,
            },
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'ABNORMAL' },
                                    value: 'NORMAL',
                                    apiPath: '/admin/users/:id/status',
                                    config: { content: '禁用', text: '禁用' },
                                    confirm: {
                                        text: '是否禁用{{row.userName}}?',
                                    },
                                },
                                {
                                    params: { status: 'NORMAL' },
                                    value: 'ABNORMAL',
                                    apiPath: '/admin/users/:id/status',
                                    config: { content: '启用', text: '启用' },
                                    confirm: {
                                        text: '是否启用{{row.userName}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'Dropdown',
                        config: {
                            style: { display: 'inline-flex' },
                            content: {
                                contentType: 'link',
                                text: '更多',
                            },
                            menuItems: [
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '查看',
                                        type: 'link',
                                        route: '/user-manage/user-info/{{row.id}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '编辑',
                                        type: 'link',
                                        route: '/user-manage/user-info-edit/{{row.id}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '重置密码',
                                        type: 'link',
                                        route: '/user-manage/reset-password/{{row.id}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '关联门店',
                                        type: 'link',
                                        route:
                                            '/user-manage/user-privilege-management/{{row.id}}/{{row.userName}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '分配角色',
                                        type: 'link',
                                        route:
                                            '/user-manage/assign-role/{{row.id}}/{{row.userName}}',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
    handleBatch = (status: any) => {
        let userIds = this.selectedRowKeys;
        if (userIds.length < 1) return;
        api.put(
            { userIds, status },
            {
                apiPath: '/admin/users/status_batch',
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
            }
        )
            .then(() => {
                const { entity, params } = this.props;
                entity.search(params);
            })
            .catch(errorHandle);
    };
    renderBatchButton = () => {
        return createElement(ComponentsManager.get('Dropdown'), {
            content: {
                contentType: 'button',
                text: language.getText('batchAction'),
                showIcon: true,
            },
            style: { display: 'inline-flex' },
            menuItems: [
                {
                    component: 'Button',
                    configs: {
                        text: language.getText('plqy'),
                        type: 'link',
                        onClick: this.handleBatch.bind(this, 'NORMAL'),
                    },
                },
                {
                    component: 'Button',
                    configs: {
                        text: language.getText('pljy'),
                        type: 'link',
                        onClick: this.handleBatch.bind(this, 'ABNORMAL'),
                    },
                },
            ],
        });
    };
    handleUpload = () => {
        this.setState({ uploadModalVisible: true });
    };
    onClose = () => {
        this.setState({ uploadModalVisible: false });
    };
    render() {
        const { uploadModalVisible } = this.state;
        const uploadFilePrps = {
            visible: uploadModalVisible,
            onClose: this.onClose,
        };
        return (
            <Fragment>
                <div className="batch-button">
                    <Button
                        style={{ marginRight: 10 }}
                        text={language.getText('addUser')}
                        type="primary"
                        icon="plus"
                        route="/user-manage/user-info-add"
                    />
                    {this.renderBatchButton()}
                    <AntButton
                        type="primary"
                        style={{
                            marginLeft: 10,
                            backgroundColor: '#52c41a',
                            borderColor: '#52c41a',
                            width: '80px',
                        }}
                        onClick={this.handleUpload}
                    >
                        {language.getText('import')}
                    </AntButton>
                </div>
                <div>{this.renderTable()}</div>
                <UploadFile {...uploadFilePrps} />
            </Fragment>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const UserManageList = connect(mapStateToProps)(userManageList);
