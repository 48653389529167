import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Icon } from 'antd';
import { Area, Column, Bar, Pie } from '@ant-design/charts';

import './index.less';
import { formatNumber } from '../../services/utils';
import { services } from '@comall-backend-builder/core';

class dataPanelsPaymentsCardView extends PureComponent<{
    entity: any;
    fields: any;
}> {
    state = {
        value: [],
    };

    // /**
    //  * 格式化数字显示
    //  */
    // formatNumber = (amount: number, tips: string) => {
    //     if (amount > 10000000) return (amount / 10000000).toFixed(2) + '千万' + tips;
    //     if (amount > 10000) return (amount / 10000).toFixed(2) + '万' + tips;
    //     if (amount > 1000)
    //         return Math.floor(amount / 1000) + ',' + Math.floor(amount % 1000) + tips;
    //     if (amount >= 0) return amount + tips;
    //     return '-' + tips;
    // };

    /**
     * 渲染堆积面积图
     */
    renderStackingArea = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            seriesField: 'seriesFieldValue',
            colorField: 'seriesFieldValue',
            color: ['#fc8a66', '#fbc124', '#acbc2e'],
            // meta: {
            //     date: {
            //         type: 'timeCat',
            //         ticks: ['2021-03-10 04:00', '2021-03-11 13:00'],
            //     },
            // },
            tooltip: {
                // fields: ['date', 'value', 'type'],
                // formatter: (datum: any) => {
                //     return {
                //         name: '日期',
                //         value: datum.date,
                //     };
                // },
                // title: 'day',
            },
        };

        return (
            <div className="chart">
                <Area {...config} />
            </div>
        );
    };

    /**
     * 渲染支付订单数柱状图
     */
    renderHistogram = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            meta: {
                xfieldValue: { alias: services.language.getText('date') },
                yfieldValue: { alias: services.language.getText('orderCount') },
            },
            color: '#ff7346',
            label: {
                position: 'middle' as any,
                style: {
                    fill: '#FFFFFF',
                    opacity: 0.6,
                },
            },
        };

        return (
            <div className="chart">
                <div className="column-chart-title">{services.language.getText('zfdds')}</div>
                <Column {...config} />
            </div>
        );
    };

    /**
     * 渲染门店支付排行条形图
     */
    renderPayBarhart = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'yfieldValue',
            yField: 'xfieldValue',
            barWidthRatio: 0.6,
            meta: {
                xfieldValue: { alias: services.language.getText('subsites') },
                yfieldValue: { alias: services.language.getText('xse') },
            },
            scrollbar: { type: 'vertical' as any },
            color: '#bbe2ff',
        };
        return (
            <div className="half">
                <div className="column-chart-title">{services.language.getText('mdzfpx')}</div>
                <Bar {...config} />
            </div>
        );
    };

    /**
     * 渲染门店支付订单排行条形图
     */
    renderOrderBarhart = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'yfieldValue',
            yField: 'xfieldValue',
            barWidthRatio: 0.6,
            meta: {
                xfieldValue: { alias: services.language.getText('subsites') },
                yfieldValue: { alias: services.language.getText('orderCount') },
            },
            scrollbar: { type: 'vertical' as any },
            color: '#bbe2ff',
        };
        return (
            <div className="half">
                <div className="column-chart-title">{services.language.getText('mdzfddpx')}</div>
                <Bar {...config} />
            </div>
        );
    };

    /**
     * 渲染专柜支付占比饼图
     */
    renderPayPiehart = (data: any) => {
        const config = {
            data: data ? data : [],
            appendPadding: 10,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,
            label: {
                type: 'inner',
                offset: '-50%',
                content: function content(_ref: any) {
                    var percent = _ref.percent;
                    return ''.concat(Math.round(percent * 100) + '', '%');
                },
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                },
            },
            interactions: [{ type: 'element-selected' }],
            meta: {
                value: {
                    formatter: function formatter(v: any) {
                        return ''.concat('\xA5 ', v ? Number(v).toFixed(2) : '0');
                    },
                },
            },
        };

        return (
            <div className="half">
                <div className="column-chart-title">{services.language.getText('zjzfzb')}</div>
                <Pie {...config} />
            </div>
        );
    };

    /**
     * 渲染专柜支付订单占比饼图
     */
    renderOrderPiehart = (data: any) => {
        const config = {
            data: data ? data : [],
            appendPadding: 10,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,
            label: {
                type: 'inner',
                offset: '-50%',
                content: function content(_ref: any) {
                    var percent = _ref.percent;
                    return ''.concat(Math.round(percent * 100) + '', '%');
                },
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                },
            },
            interactions: [{ type: 'element-selected' }],
            meta: {
                value: {
                    formatter: function formatter(v: any) {
                        return ''.concat(v ? v : 0, ' ' + services.language.getText('dan'));
                    },
                },
            },
        };

        return (
            <div className="half">
                <div className="column-chart-title">{services.language.getText('zjzfddzb')}</div>
                <Pie {...config} />
            </div>
        );
    };

    /**
     * 渲染类目支付排行柱状图
     */
    renderMerchantPayHistogram = (data: any) => {
        if (data && data.length > 0) {
            data = data.map((item: any) => {
                return {
                    ...item,
                    title: item.xfieldValue,
                };
            });
        }
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            meta: {
                xfieldValue: {
                    alias: services.language.getText('subsites'),
                    formatter: function formatter(v: any) {
                        return v && v.length > 6 ? v.slice(0, 6) + '...' : v;
                    },
                },
                yfieldValue: { alias: services.language.getText('xse') },
            },
            tooltip: {
                title: 'title',
            },
            xAxis: { label: { autoRotate: true } },
            scrollbar: { type: 'horizontal' as any },
            color: '#a2f5cc',
        };
        return (
            <div className="half">
                <div className="column-chart-title">{services.language.getText('lmzfpx')}</div>
                <Column {...config} />
            </div>
        );
    };

    /**
     * 渲染类目支付订单排行柱状图
     */
    renderMerchantOrderHistogram = (data: any) => {
        if (data && data.length > 0) {
            data = data.map((item: any) => {
                return {
                    ...item,
                    title: item.xfieldValue,
                };
            });
        }
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            meta: {
                xfieldValue: {
                    alias: services.language.getText('subsites'),
                    formatter: function formatter(v: any) {
                        return v && v.length > 6 ? v.slice(0, 6) + '...' : v;
                    },
                },
                yfieldValue: { alias: services.language.getText('xsddl') },
            },
            tooltip: {
                title: 'title',
            },
            xAxis: { label: { autoRotate: true } },
            scrollbar: { type: 'horizontal' as any },
            color: '#a2f5cc',
        };
        return (
            <div className="half">
                <div className="column-chart-title">{services.language.getText('lmzfddpx')}</div>
                <Column {...config} />
            </div>
        );
    };

    /**
     * 渲染支付概览子项
     */
    renderOverviewItem = (title: string, data: string, yesterday: number, lastWeek: number) => {
        return (
            <div className="col">
                <div className="title">{title}</div>
                <div className="data">{data}</div>
                <div className="contrast">
                    <span className="text">{services.language.getText('dbzr')}：</span>
                    <span className="percent">{!!yesterday ? Math.abs(yesterday) + '%' : '-'}</span>
                    {!!yesterday && (
                        <Icon
                            type={yesterday < 0 ? 'arrow-down' : 'arrow-up'}
                            className={yesterday < 0 ? 'icon-green' : 'icon-red'}
                        />
                    )}
                </div>
                <div className="contrast">
                    <span className="text">{services.language.getText('jsztq')}：</span>
                    <span className="percent">{!!lastWeek ? Math.abs(lastWeek) + '%' : '-'}</span>
                    {!!lastWeek && (
                        <Icon
                            type={lastWeek < 0 ? 'arrow-down' : 'arrow-up'}
                            className={lastWeek < 0 ? 'icon-green' : 'icon-red'}
                        />
                    )}
                </div>
            </div>
        );
    };

    /**
     * 渲染支付概览
     */
    renderOverview = (data: any[]) => {
        const paidTotalAmount = find(data, (item) => item.overviewType === 'PAID_TOTAL_AMOUNT');
        const paidMemberCount = find(data, (item) => item.overviewType === 'PAID_MEMBER_COUNT');
        const paidOrderCount = find(data, (item) => item.overviewType === 'PAID_ORDER_COUNT');
        const averageMemberValue = find(
            data,
            (item) => item.overviewType === 'AVERAGE_MEMBER_VALUE'
        );
        return (
            <div className="data-panel-reprot">
                {paidTotalAmount &&
                    this.renderOverviewItem(
                        services.language.getText('zfje'),
                        formatNumber(
                            paidTotalAmount.overviewValue,
                            services.language.getText('yuan')
                        ),
                        paidTotalAmount.compareWithYesterday,
                        paidTotalAmount.compareWithLastWeek
                    )}
                {paidMemberCount &&
                    this.renderOverviewItem(
                        services.language.getText('zfrs'),
                        formatNumber(
                            paidMemberCount.overviewValue,
                            services.language.getText('people')
                        ),
                        paidMemberCount.compareWithYesterday,
                        paidMemberCount.compareWithLastWeek
                    )}
                {paidOrderCount &&
                    this.renderOverviewItem(
                        services.language.getText('zfdds'),
                        formatNumber(
                            paidOrderCount.overviewValue,
                            services.language.getText('dan')
                        ),
                        paidOrderCount.compareWithYesterday,
                        paidOrderCount.compareWithLastWeek
                    )}
                {averageMemberValue &&
                    this.renderOverviewItem(
                        services.language.getText('kdj'),
                        formatNumber(
                            averageMemberValue.overviewValue,
                            services.language.getText('yuan')
                        ),
                        averageMemberValue.compareWithYesterday,
                        averageMemberValue.compareWithLastWeek
                    )}
            </div>
        );
    };

    render() {
        const { fields } = this.props;

        return (
            <div className="data-panel-view">
                {this.renderOverview(fields.overviewStatistics)}
                {this.renderStackingArea(fields.salesStatistics)}
                {this.renderHistogram(fields.paidOrderCount)}
                <div className="chart chart-double">
                    {this.renderPayBarhart(fields.subsitePaidAmount)}
                    {this.renderOrderBarhart(fields.subsitePaidCount)}
                </div>
                <div className="chart chart-double">
                    {this.renderPayPiehart(fields.merchantPaidAmount)}
                    {this.renderOrderPiehart(fields.merchantPaidCount)}
                </div>
                <div className="chart chart-double">
                    {this.renderMerchantPayHistogram(fields.categoryPaidAmount)}
                    {this.renderMerchantOrderHistogram(fields.categoryPaidCount)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.result ? entity.result : entity.fields ? entity.fields : [],
    };
}

export const DataPanelsPaymentsCardView = connect(
    mapStateToProps,
    null
)(dataPanelsPaymentsCardView);
