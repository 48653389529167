import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

interface ScrmLevelUpgradeDowngradeRuleListVO {
    scrmLevelUpgradeDowngradeRuleViewVos: {
        scrmLevelUpgradeDowngradeRule?: {
            canDowngrade: boolean;
            id?: number;
            maintainAnnualExpenditure?: number;
            maintainMonth?: number;
            upgradeAnnualExpenditure?: number;
            upgradeDailyOrMonthlyExpenditure?: number;
        };
        scrmLevelVo: {
            code: string;
            id: number;
            isDefault: boolean;
            name: string;
            status: 'ENABLE' | 'DISABLE' | 'DELETE';
        };
    }[];
    upgradeExpenditureType: 'AMOUNT' | 'POINT';
    levelCalculationMethod:
        | 'MONTHLY_LEVEL_CALCULATION'
        | 'ANNUAL_LEVEL_CALCULATION'
        | 'HYBRID_LEVEL_CALCULATION';
}

export const ScrmLevelsUpgradeDowngradeRulesLoader: Loader = {
    async get(params, config) {
        const response = await api.get<ScrmLevelUpgradeDowngradeRuleListVO>(params, config);
        const { scrmLevelUpgradeDowngradeRuleViewVos } = response;
        scrmLevelUpgradeDowngradeRuleViewVos.forEach((vo) => {
            if (!vo.scrmLevelUpgradeDowngradeRule && vo.scrmLevelVo.isDefault) {
                vo.scrmLevelUpgradeDowngradeRule = {
                    canDowngrade: false,
                    maintainMonth: 0,
                    maintainAnnualExpenditure: 0,
                };
            }
        });
        return response;
    },
};
