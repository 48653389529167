import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const ActivityRewardItemLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        let rewardItemType = config.paramsFilter ? config.paramsFilter[0] : '';
        let rewardItems: any = [];
        switch (rewardItemType) {
            case 'MALL_ACTIVITY':
                rewardItems.push({ code: 'POINT', name: '积分' });
                rewardItems.push({ code: 'COUPON', name: '优惠券' });
                break;
            case 'CHECKIN_DAYS':
                config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
                config.apiPath = '/admin/activities/reward_items?event_type=SIGN_IN';
                api.get(data, config).then((res: any) => {
                    if (res) {
                        res.map((item: any) => {
                            rewardItems.push(item);
                            return item;
                        });
                    }
                });
                break;
            case 'CHECKIN_CUMULATIVE':
                rewardItems.push({ code: 'STEPVALUE', name: '阶梯值' });
                rewardItems.push({ code: 'POINT', name: '积分' });
                rewardItems.push({ code: 'EXPERIENCE', name: '经验值' });
                rewardItems.push({ code: 'COUPON', name: '优惠券' });
                break;
            case 'INVITE':
                config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
                config.apiPath = '/admin/activities/reward_items?event_type=INVITATION_AWARD';
                api.get(data, config).then((res: any) => {
                    if (res) {
                        res.map((item: any) => {
                            rewardItems.push(item);
                            return item;
                        });
                        rewardItems.push({ code: 'PICTURE', name: '奖励图' });
                        rewardItems.push({ code: 'PICTUREBG', name: '奖励弹层图' });
                        rewardItems.push({ code: 'STEPVALUE', name: '阶梯值' });
                    }
                });
                break;
            case 'CHECKIN_SPECIAL':
                rewardItems.push(
                    { code: 'STEPVALUE', name: '阶梯值' },
                    { code: 'POINT', name: '积分' },
                    { code: 'EXPERIENCE', name: '经验值' },
                    { code: 'COUPON', name: '优惠券' }
                );
                break;
            case 'TOPIC_ACTIVITY':
                config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
                config.apiPath = '/admin/activities/reward_items?event_type=TOPIC_ACTIVITY';
                api.get(data, config).then((res: any) => {
                    if (res) {
                        res.map((item: any) => {
                            rewardItems.push(item);
                            return item;
                        });
                        rewardItems.push({ code: 'STEPVALUE', name: '阶梯值' });
                    }
                });
                break;
            case 'SIGN_IN':
                config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
                config.apiPath = '/admin/activities/reward_items?event_type=SIGN_IN';
                api.get(data, config).then((res: any) => {
                    if (res) {
                        res.map((item: any) => {
                            rewardItems.push(item);
                            return item;
                        });
                    }
                });
                break;
            case 'ORDER_COMMENT':
                config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
                config.apiPath = '/admin/activities/reward_items?event_type=ORDER_COMMENT';
                api.get(data, config).then((res: any) => {
                    if (res) {
                        res.map((item: any) => {
                            rewardItems.push(item);
                            return item;
                        });
                    }
                });
                break;
            default:
                break;
        }

        return await Promise.resolve(rewardItems);
    },
};
