import React, { PureComponent } from 'react';
import get from 'lodash/get';
import { ContentTopicSelectedDisplay } from '../../components';
import { ContentTopicType } from '../select-content-topic';

export class DisplayContentTopic extends PureComponent<
    any,
    {
        value: Array<ContentTopicType>;
    }
> {
    state = { value: new Array<ContentTopicType>() };

    render() {
        const { row } = this.props;
        let value: any = [];
        const selectContentTopic = get(row, 'contentTopicInfo.selectContentTopic');
        if (selectContentTopic) {
            value = selectContentTopic;
        }
        return (
            <div>
                <ContentTopicSelectedDisplay
                    data={value}
                    onRemove={() => {}}
                ></ContentTopicSelectedDisplay>
            </div>
        );
    }
}
