import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

export enum MerchantTagStatus {
    ENABLE = 'ENABLED',
    DISABLE = 'DISABLED',
    ALL = 'ALL',
}
export const config: Config = {
    entities: {
        MerchantFacilityEntity: {
            apiPath: '/loader/admin/facility',
            filters: {
                subsites: {
                    type: 'object.option.select',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                name: {
                    type: 'string',
                    displayName: '<<facilityName>>',
                },
            },
            properties: {
                name: {
                    displayName: '<<facilityName>>',
                    type: 'string',
                    rules: [{ required: true }, { max: 20, message: '<<facilityRules>>' }],
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                subsiteName: {
                    displayName: '<<subsites>>',
                    type: 'string',
                },
                facilityTags: {
                    type: 'array.options.autoComplete.properties',
                    displayName: '<<facilityTag>>',
                    options: [],
                    controlConfig: {
                        pageType: 'facilityTagModes',
                        source: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/facility_tag',
                        },
                        placeholder: '<<selectPlease>>',
                        style: { width: 450 },
                        dependences: 'subsiteId',
                        // dependencesProperties: 'subsiteId',
                        paramsName: 'subsiteId',
                        needDependences: true,
                        needDependencesMessage: '<<selectSubsite>>',
                    },
                    rules: [{ required: true }],
                },
                positionDescribe: {
                    displayName: '<<facilityPosition>>',
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<selectFillin>>',
                    },
                },
                subsiteFloorObj: {
                    type: 'string.options.autoComplete.url',
                    displayName: '<<belongFloor>>',
                    options: [],
                    controlConfig: {
                        pageType: 'facilityModes',
                        source: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/subsites/getFloors/{id}',
                        },
                        placeholder: '<<selectPlease>>',
                        style: { width: 450 },
                        dependences: 'subsiteId',
                        needDependences: true,
                        needDependencesMessage: '<<selectSubsite>>',
                    },
                    rules: [{ required: true }],
                },
                floorPosition: {
                    displayName: '<<floorBelongPosition>>',
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<selectFillin>>',
                    },
                },
                sid: {
                    displayName: '<<indoorSid>>',
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<selectFillin>>',
                    },
                },
                sequence: {
                    displayName: '<<sequence>>',
                    type: 'number.integer',
                    rules: [{ required: true, message: '<<selectFillin>>' }],
                },
                iconPicture: {
                    displayName: '<<facilityPictures>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: '<<iconPictureExtra>>',
                    rules: [{ required: true, message: '<<iconPictureNotEmpty>>' }],
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    defaultValue: 'ENABLED',
                    options: [
                        {
                            id: 'ENABLED',
                            name: '<<enable>>',
                        },
                        {
                            id: 'DISABLED',
                            name: '<<disabled>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        MerchantFacilityView: {
            component: 'Viewport',
            entity: 'MerchantFacilityEntity',
        },
        MerchantFacilityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'MerchantFacilityFilter' },
                { component: 'MerchantFacilityTable' },
            ],
        },
        MerchantFacilityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsites',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        MerchantFacilityTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<addFacility>>',
                    route: '/merchant-facility/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                        marginBottom: 10,
                    },
                },
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    ENABLED: {
                                        text: '<<enable>>',
                                        status: 'success',
                                    },
                                    DISABLED: {
                                        text: '<<disabled>>',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: 'DISABLED' },
                                            value: 'ENABLED',
                                            apiPath: '/admin/facility/:id/status',
                                            config: {
                                                content: '<<disabled>>',
                                                text: '<<disabled>>',
                                            },
                                            confirm: {
                                                text: '<<isDisabled>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLED' },
                                            value: 'DISABLED',
                                            apiPath: '/admin/facility/:id/status',
                                            config: { content: '<<enable>>', text: '<<enable>>' },
                                            confirm: {
                                                text: '<<isEnable>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/merchant-facility/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MerchantFacilityAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MerchantFacilityEntity',
            items: [
                {
                    component: 'MerchantFacilityAddForm',
                },
            ],
        },
        MerchantFacilityAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'name',
                },
                { property: 'subsiteId' },
                { property: 'facilityTags' },
                { property: 'positionDescribe' },
                {
                    property: 'subsiteFloorObj',
                },
                {
                    property: 'floorPosition',
                },
                {
                    property: 'sid',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'iconPicture',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantFacilityEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MerchantFacilityEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'MerchantFacilityEditForm',
                },
            ],
        },
        MerchantFacilityEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'name',
                },
                { property: 'subsiteId' },
                { property: 'facilityTags' },
                { property: 'positionDescribe' },
                {
                    property: 'subsiteFloorObj',
                },
                {
                    property: 'floorPosition',
                },
                {
                    property: 'sid',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'iconPicture',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-facility',
                    component: 'MerchantFacilityView',
                    breadcrumbName: '<<merchantManagerFacility>>',
                    privilege: {
                        path: 'facility',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'MerchantFacilityEditPage',
                            breadcrumbName: '<<editFacility>>',
                        },
                        {
                            path: '/add',
                            component: 'MerchantFacilityAddPage',
                            breadcrumbName: '<<addFacility>>',
                        },
                        { path: '/', component: 'MerchantFacilityPage' },
                    ],
                },
            ],
        },
    ],
};
