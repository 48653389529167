import React, { Component } from 'react';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export class Countdown extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            second: undefined,
            minute: undefined,
            total: undefined,
        };
        this.timer = null;
    }

    componentWillReceiveProps(props: any) {
        const { status, time, total } = props;
        const prevTime = this.props.time;
        if (status === 'disabled') {
            if (time < 0 && time !== prevTime) {
                this.setState({
                    minute: Math.floor(Math.abs(time) / 60),
                });
            }
            return;
        }
        if (status && status !== 'countdown' && status !== 'timeout' && this.timer) {
            clearInterval(this.timer);
        } else if (status === 'countdown' || status === 'timeout') {
            if (prevTime === time) {
                this.setState({
                    second: this.state.second !== undefined ? this.state.second : Number(time),
                    total: this.state.total !== undefined ? this.state.total : Number(total),
                    minute:
                        this.state.minute !== undefined
                            ? this.state.minute
                            : status === 'countdown'
                            ? Math.ceil(time / 60)
                            : Math.floor(Math.abs(time) / 60),
                });
            } else {
                this.setState({
                    second: time,
                    total: Number(total),
                    minute:
                        status === 'countdown'
                            ? Math.ceil(time / 60)
                            : Math.floor(Math.abs(time) / 60),
                });
            }
        }
    }
    timer: any;

    onTimeChange = (second: number) => {
        const { onTimeChange } = this.props;
        if (onTimeChange) {
            onTimeChange(second);
        }
    };
    componentDidMount() {
        const { time, status, total } = this.props;
        if (status === 'disabled') {
            if (time < 0) {
                this.setState({
                    minute: Math.floor(Math.abs(time) / 60),
                });
            }
            return;
        }
        this.setState({
            second: Number(time),
            total: Number(total),
            minute: status === 'countdown' ? Math.ceil(time / 60) : Math.floor(Math.abs(time) / 60),
        });
        this.timer = setInterval(() => {
            this.countdown();
            this.onTimeChange(this.state.second);
        }, 1000);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    countdown = () => {
        const { second } = this.state;
        this.setState({
            second: second - 1,
            minute: second <= 1 ? Math.floor(Math.abs(second) / 60) : Math.ceil(second / 60),
        });
    };
    render() {
        const { title, color } = this.props;
        const { total, second, minute } = this.state;
        const deg = total && total > 0 && second && second >= 0 ? 360 - (second / total) * 360 : 0;
        return (
            <div className="countdown-box">
                <div className="left_box">
                    <div
                        className="left_item"
                        style={{
                            transform: `rotate(${deg > 180 ? deg - 180 : 0}deg)`,
                            backgroundColor: color,
                        }}
                    ></div>
                </div>
                <div className="right_box">
                    <div
                        className="right_item"
                        style={{
                            transform: `rotate(${deg > 180 ? 180 : deg}deg)`,
                            backgroundColor: color,
                        }}
                    ></div>
                </div>
                <div className="mask" style={{ color: color }}>
                    <div>{title}</div>
                    {!!minute && (
                        <div>
                            {minute}
                            {language.getText('minutes')}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
