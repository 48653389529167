import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs } from 'antd';
import isNull from 'lodash/isNull';
import { services } from '@comall-backend-builder/core';

import './index.less';

const api = services.api;
const { TabPane } = Tabs;

interface InvitationReportCollection {
    id: number;
    name: string;
    totalNumberOfInviter: string;
    totalNumberOfNewMembersInvited: string;
    orderCreatedInviteeQuantity: string;
    orderReceivedInviteeQuantity: string;
    assetRewards?: Array<AssetReward>;
}

interface InvitationReport {
    invitedBy: InvitationReportCollection;
    invitedTo: InvitationReportCollection;
    total: InvitationReportCollection;
}

interface AssetReward {
    name: string;
    value: number;
    type: string;
}

enum InvitationRewardType {
    'POINT' = 'point',
    'EXPERIENCE' = 'experienceManage',
    'GOLD' = 'jb',
    'COUPON' = 'yhq',
    'CUSTOM' = 'zdyjl',
}

class invitationActivityReportView extends PureComponent<
    any,
    { reportData: InvitationReport | undefined }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            reportData: undefined,
        };
    }
    componentDidMount() {
        const { id } = this.props.params;
        const apiPath = '/admin/invitations/:id/statistic';
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                reportData: res,
            });
        });
    }

    renderCard = (title: string, collection: InvitationReportCollection, colums: any[]) => {
        const valueColumns: (keyof typeof InvitationRewardType)[] = colums.filter(
            (colum) =>
                ![
                    'totalNumberOfInviter',
                    'totalNumberOfNewMembersInvited',
                    'orderCreatedInviteeQuantity',
                    'orderReceivedInviteeQuantity',
                ].includes(colum)
        );
        return (
            <Card title={title} type="inner" style={{ marginBottom: 32 }}>
                <div className="invitataion-activity-report-wrap">
                    {colums.includes('totalNumberOfInviter') && (
                        <div className="invitataion-activity-report-item invitataion-activity-report-detail-item">
                            <div className="invitataion-activity-report-item-title">
                                {services.language.getText('yqrzs')}
                            </div>
                            <div className="invitataion-activity-report-item-content">
                                {collection.totalNumberOfInviter}
                            </div>
                        </div>
                    )}
                    {colums.includes('totalNumberOfNewMembersInvited') && (
                        <div className="invitataion-activity-report-item invitataion-activity-report-detail-item">
                            <div className="invitataion-activity-report-item-title">
                                {services.language.getText('xyhs')}
                            </div>
                            <div className="invitataion-activity-report-item-content">
                                {collection.totalNumberOfNewMembersInvited}
                            </div>
                        </div>
                    )}
                    {colums.includes('orderCreatedInviteeQuantity') &&
                        !isNull(collection.orderCreatedInviteeQuantity) && (
                            <div className="invitataion-activity-report-item invitataion-activity-report-detail-item">
                                <div className="invitataion-activity-report-item-title">
                                    {services.language.getText('xdyhs')}
                                </div>
                                <div className="invitataion-activity-report-item-content">
                                    {collection.orderCreatedInviteeQuantity}
                                </div>
                            </div>
                        )}
                    {colums.includes('orderReceivedInviteeQuantity') &&
                        !isNull(collection.orderReceivedInviteeQuantity) && (
                            <div className="invitataion-activity-report-item invitataion-activity-report-detail-item">
                                <div className="invitataion-activity-report-item-title">
                                    {services.language.getText('qsyhs')}
                                </div>
                                <div className="invitataion-activity-report-item-content">
                                    {collection.orderReceivedInviteeQuantity}
                                </div>
                            </div>
                        )}
                    {valueColumns.map((valueColumn) => {
                        const item = collection.assetRewards?.find(
                            (reward) => reward.type === valueColumn
                        );
                        const name =
                            item?.name ??
                            services.language.getText(InvitationRewardType[valueColumn]);
                        const value = item?.value ?? 0;
                        return (
                            <div className="invitataion-activity-report-item invitataion-activity-report-detail-item">
                                <div className="invitataion-activity-report-item-title">{name}</div>
                                <div className="invitataion-activity-report-item-content">
                                    {Math.floor(value)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Card>
        );
    };

    render() {
        const { reportData } = this.state;
        if (!reportData) return null;
        const { invitedBy, invitedTo, total } = reportData;
        return (
            <Fragment>
                <Tabs type="card" className="invitataion-activity-report">
                    {!!reportData ? (
                        <TabPane tab={services.language.getText('yqyl')}>
                            <div className="invitataion-activity-report-header">
                                <span className="invitataion-activity-report-activity-name">
                                    {total.name}
                                </span>
                                <span className="invitataion-activity-report-activity-tips">
                                    {services.language.getText('sjmgzdgx')}
                                </span>
                            </div>
                            {this.renderCard(services.language.getText('yysj_1'), total, [
                                'totalNumberOfInviter',
                                'totalNumberOfNewMembersInvited',
                                'orderCreatedInviteeQuantity',
                                'orderReceivedInviteeQuantity',
                            ])}
                            {this.renderCard(services.language.getText('yqrjl'), invitedBy, [
                                'POINT',
                                'EXPERIENCE',
                                'GOLD',
                            ])}
                            {this.renderCard(services.language.getText('byqrjl'), invitedTo, [
                                'POINT',
                                'EXPERIENCE',
                                'GOLD',
                            ])}
                        </TabPane>
                    ) : (
                        ''
                    )}
                </Tabs>
            </Fragment>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const InvitationActivityReportView = connect(mapStateToProps)(invitationActivityReportView);
