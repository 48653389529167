import React, { PureComponent } from 'react';
import { map, isEqual } from 'lodash';
import { GiftProductSelector } from '../../../../components/gift-product-selector';
import { GiftProductSelectorDisplay } from './gift-product-selector-display';

export interface GiftProduct {
    id: string;
    name: string;
    weight: number;
    salesUnit: string;
    marketPrice: number;
    images: { id: string; url: string }[];
    receiveExplain: string;
    details: string;
}

interface GiftProductSelectProps {
    name: string;
    value?: GiftProduct;
    disabled: boolean;
    type?: string;
    getSubsiteIds?: (row: Record<string, any>) => string;
    onChange?: (value?: GiftProduct, name?: string) => void;
    row: Record<string, any>;
}

export class GiftProductSelect extends PureComponent<GiftProductSelectProps> {
    componentWillReceiveProps(nextProps: any) {
        const { clearValueOnSubsiteChange } = nextProps;
        if (!clearValueOnSubsiteChange) {
            return;
        }
        const preSubsiteIds = this.getSubsiteIds(this.props);
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        if (preSubsiteIds && preSubsiteIds.length && !isEqual(preSubsiteIds, nextSubsiteIds)) {
            nextProps?.onChange(undefined);
        }
    }
    private onChange = (values: GiftProduct[]) => {
        const { onChange, name } = this.props;
        if (onChange && values && values.length) {
            const [value] = values;
            onChange(
                {
                    id: value.id,
                    name: value.name,
                    weight: value.weight,
                    salesUnit: value.salesUnit,
                    marketPrice: value.marketPrice,
                    images: value.images,
                    receiveExplain: value.receiveExplain,
                    details: value.details,
                },
                name
            );
        }
    };

    getSubsiteIds = (props: any) => {
        const { row, getSubsiteIds } = props;
        if (typeof getSubsiteIds === 'function') {
            return getSubsiteIds(props);
        }
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsites ? map(row.baseInfo.subsites, 'id') : null;
        return subsiteIds;
    };

    render() {
        const { type, value, disabled } = this.props;
        const subsiteIds = this.getSubsiteIds(this.props);
        const params: any = {
            subsiteId: subsiteIds,
            status: 1,
        };
        return (
            <div>
                <GiftProductSelector
                    onChange={this.onChange}
                    needSubsites
                    rowSelectionType={type}
                    params={params}
                    disabled={disabled}
                />
                {value && <GiftProductSelectorDisplay data={value} />}
            </div>
        );
    }
}
