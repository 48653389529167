import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const CombineGoodsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/combine_goods/${id}`;
        return await api.get(data, config).then((res: any) => {
            res.id = res.product.id;
            res.baseInfo = {
                name: res.product.name,
                code: res.product.code.replace('zhsp', ''),
                categories: res.product.categories.map((item: any) => {
                    return parseInt(item.id);
                }),
                selectMode: res.goods.subsite && res.goods.subsite.id ? 'SUB_SITE' : 'MERCHANT',
                subsites: res.goods.subsite ? [res.goods.subsite] : [],
                merchants: res.goods.subsiteMerchant ? [res.goods.subsiteMerchant] : [],
                brand: [res.product.brand],
            };
            const combineItemsLength = res.combineItems.length;
            res.productInfo = {
                goods: res.combineItems.map((combineItem: any) => {
                    combineItem.id = combineItem.goodsId + '';
                    combineItem.productStyleName = combineItem.styleName;
                    return combineItem;
                }),
            };
            res.pictureInfo = {
                isDanUsedCombineItemPictures:
                    combineItemsLength === 1
                        ? '' + res.combineConfig.usedCombineItemPictures
                        : 'false',
                isDuoUsedCombineItemPictures:
                    combineItemsLength > 1
                        ? '' + res.combineConfig.usedCombineItemPictures
                        : 'false',
                pictures:
                    !res.combineConfig.usedCombineItemPictures && res.product.pictures
                        ? res.product.pictures.map((picture: any) => {
                              picture.path = picture.url;
                              return picture;
                          })
                        : [],
                video:
                    !res.combineConfig.usedCombineItemPictures && res.product.videos
                        ? res.product.videos.map((video: any) => {
                              video.path = video.url;
                              return video;
                          })
                        : [],
                isDanUsedCombineItemDetails:
                    combineItemsLength === 1
                        ? '' + res.combineConfig.usedCombineItemDetails
                        : 'false',
                isDuoUsedCombineItemDetails:
                    combineItemsLength === 1
                        ? '' + res.combineConfig.usedCombineItemDetails
                        : 'false',
                details: !res.combineConfig.usedCombineItemDetails ? res.product.details : null,
            };
            res.settingInfo = {
                saleTimeMode: res.goods.saleTime ? 'NO' : 'YES',
                saleTime: res.goods.saleTime,
                deliveryModeType: res.goods.delivery.deliveryModeType,
                subsiteMerchantDelivery: {
                    delivery:
                        res.goods.delivery && res.goods.delivery.deliveryModes
                            ? res.goods.delivery.deliveryModes.map((id: any) => '' + id)
                            : null,
                },
                limitMode: res.goods.buyLimit ? 'YES' : 'NO',
                buyLimit: {
                    type: res.goods.buyLimit ? res.goods.buyLimit.period : null,
                    number: res.goods.buyLimit ? res.goods.buyLimit.quantity : null,
                },
                afterSaleType: res.combineConfig.afterSaleType,
                showItems: '' + res.combineConfig.showItems,
                shelfStatus: res.goods.shelfStatus,
            };
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/combine_goods`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/combine_goods/${id}`;
        const newParams = dataParser(params);
        newParams.product.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.product = {
        brandId: params.baseInfo.brand[0].id,
        categoryId: params.baseInfo.categories[params.baseInfo.categories.length - 1],
        code: params.baseInfo.code,
        details: params.pictureInfo.details,
        name: params.baseInfo.name,
        pictureIds:
            params.pictureInfo.pictures &&
            params.pictureInfo.pictures.map((picture: any) => {
                return picture.id;
            }),
        usedCombineItemPictures: false,
        videoIds:
            params.pictureInfo.video &&
            params.pictureInfo.video.map((picture: any) => {
                return picture.id;
            }),
    };
    newParams.combineItems =
        params.productInfo.goods &&
        params.productInfo.goods.map((good: any) => {
            good.goodsId = good.id;
            return good;
        });
    let usedCombineItemDetails = false;
    let usedCombineItemPictures = false;
    if (params.productInfo.goods.length > 1) {
        usedCombineItemDetails = params.pictureInfo.isDuoUsedCombineItemDetails;
        usedCombineItemPictures = params.pictureInfo.isDuoUsedCombineItemPictures;
    } else {
        usedCombineItemDetails = params.pictureInfo.isDanUsedCombineItemDetails;
        usedCombineItemPictures = params.pictureInfo.isDanUsedCombineItemPictures;
    }
    newParams.combineConfig = {
        afterSaleType: params.settingInfo.afterSaleType,
        priceAutoCalculate: true,
        shelfStatusSync: false,
        showItems: params.settingInfo.showItems,
        usedCombineItemDetails: usedCombineItemDetails,
        usedCombineItemPictures: usedCombineItemPictures,
    };
    let merchantId = null;
    let subsiteId = null;
    if (params.baseInfo.selectMode === 'SUB_SITE') {
        subsiteId = params.baseInfo.subsites[0].id;
    }
    if (params.baseInfo.selectMode === 'MERCHANT') {
        subsiteId = params.baseInfo.merchants[0].subSiteId;
        merchantId = params.baseInfo.merchants[0].merchantId;
    }
    newParams.goods = {
        buyLimit:
            params.settingInfo.limitMode === 'YES'
                ? {
                      period: params.settingInfo.buyLimit.type,
                      quantity: params.settingInfo.buyLimit.number,
                  }
                : null,
        delivery: {
            deliveryModeType: params.settingInfo.deliveryModeType,
            deliveryModes:
                params.settingInfo.subsiteMerchantDelivery &&
                params.settingInfo.subsiteMerchantDelivery.delivery,
        },
        subsiteId: subsiteId,
        merchantId: merchantId,
        saleTime: params.settingInfo.saleTimeMode === 'NO' ? params.settingInfo.saleTime : null,
        shelfStatus: params.settingInfo.shelfStatus ? params.settingInfo.shelfStatus : null,
    };
    return newParams;
}
