import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { CouponOrderChooseAppliesSelector } from '../coupon-order-choose-applies-selector';
import { getCouponStatus } from '../../../types/format/utils';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

interface CouponOrderChooseAppliesSelectorDisplayProps {
    orderId: number;
    id: number;
    data: Array<any>;
    onChange: (data: Array<any>, packageNum?: number) => void;
    disabled?: boolean;
    apiPath?: any;
    params?: any;
    row?: any;
    displayCouponStatus?: boolean | undefined;
}
interface CouponOrderChooseAppliesSelectorDisplayState {
    selectedRows: Array<any>;
}

/**
 * 优惠券选择并且展示选择内容
 */
export class CouponOrderChooseAppliesSelectorDisplay extends PureComponent<
    CouponOrderChooseAppliesSelectorDisplayProps,
    CouponOrderChooseAppliesSelectorDisplayState
> {
    static defaultProps = {
        orderId: 0,
        id: 0,
        disabled: false,
    };

    getTableConfig = () => {
        let columns = [
            {
                title: services.language.getText('couponName'),
                key: 'name',
                dataIndex: 'name',
                render: (text: any) => {
                    return (
                        <div style={{ width: '150px' }} className="ant-table-cell-ellipsis">
                            <span>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('couponBaCode'),
                key: 'couponNo',
                dataIndex: 'couponNo',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '优惠券状态',
                key: 'couponStatus',
                dataIndex: 'couponStatus',
                render: (text: any, row: any) => {
                    let couponStatusStr = '';
                    if (text === 'ENABLED') {
                        couponStatusStr = '待使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'USED') {
                        couponStatusStr = '已使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'EXPIRED') {
                        couponStatusStr = '已过期';
                    } else if (text === 'REFUND_APPLY') {
                        couponStatusStr = '退款中';
                    } else if (text === 'REFUND_FINISH') {
                        couponStatusStr = '退款完成';
                    }
                    return couponStatusStr;
                },
            },
            {
                title: services.language.getText('cllx'),
                key: 'refundMoney',
                dataIndex: 'refundMoney',
                render: (text: any, row: any) => {
                    let refundMoneyStr = '';
                    text = text + '';
                    if (text === 'true') {
                        refundMoneyStr = '退款退券';
                    } else {
                        refundMoneyStr = services.language.getText('ztq');
                    }
                    if (row.rechargeSuccess === null) {
                        return refundMoneyStr;
                    } else {
                        //三方余额券
                        return row.rechargeSuccess ? '退款退券' : '仅退款';
                    }
                },
            },
            {
                title: services.language.getText('zxxj'),
                key: 'executePrice',
                dataIndex: 'executePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('zxjf'),
                key: 'pointExecutePrice',
                dataIndex: 'pointExecutePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '结束有效期',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '三方券退券金额',
                key: 'amount',
                dataIndex: 'amount',
                render: (text: any, row: any) => {
                    if (text === null || row.rechargeSuccess === null) {
                        return null;
                    }
                    return <span>{Math.abs(text)}</span>;
                },
            },
            {
                title: '三方券充值状态',
                key: 'rechargeSuccess',
                dataIndex: 'rechargeSuccess',
                render: (text: any) => {
                    if (text === null) {
                        return null;
                    }
                    return text ? <span>已充值</span> : <span>充值失败</span>;
                },
            },
        ];
        let arr = cloneDeep(columns);
        if (this.props?.displayCouponStatus) {
            arr.splice(2, 0, {
                title: '优惠券状态',
                key: 'bizStatus',
                dataIndex: 'bizStatus',
                render: (text: any, row: any) => {
                    return getCouponStatus(text);
                },
            });
            arr[3].title = '退款状态';
        }
        let tableConfig: any = {
            columns: arr,
            style: {
                marginTop: 10,
            },
        };
        return tableConfig;
    };

    onSelectCoupon = (coupons: Array<any>, packageNum: undefined | number) => {
        //覆盖之前的选择
        const { onChange } = this.props;
        onChange(coupons, packageNum);
    };

    render() {
        const { data, id, orderId, disabled, params, row } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination: false,
        };

        return (
            <div className="coupon-order-applies-selector-display">
                <CouponOrderChooseAppliesSelector
                    disabled={disabled}
                    orderId={orderId}
                    params={params || { orderItemId: id }}
                    selectValues={row && row.coupons ? row.coupons : []}
                    onChange={this.onSelectCoupon}
                    row={row}
                />
                <Table {...tableProps}></Table>
            </div>
        );
    }
}
