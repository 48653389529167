import { Type } from '@comall-backend-builder/core';
import {
    BooleanFormat,
    NumberPositiveFormat,
    ObjectFormat,
    formats,
} from '@comall-backend-builder/types';

import { NumberInputTipMode } from './mode/number/input-tip';
import { NumberInputTipGroupBuying } from './mode/number/input-tip-group-buying';
import { StoreStatusMode } from './mode/number/store-status';
import { StringInputTipMode } from './mode/string/input-tip';
import { StringInputMaxLengthTipMode } from './mode/string/input-tip-maxlength';
import { StatusBadgeMode } from './mode/string/status-badge';
import { StringImageRadioMode } from './mode/string/radio-image';
import { SubsitesSelectorMode } from './mode/array/subsites-selector';
import { MallActivityMode } from './mode/array/activities-selector';
import { OpenBoxActivityMode } from './mode/array/open-box-activity';
import { TagsSelectorMode } from './mode/array/tags-selector';
import { ShopSelectorMode } from './mode/array/shop-selector';
import { MerchantSelectorPlusMode } from './mode/array/merchant-selecter-plus';
import { CouponSelectorMode } from './mode/array/coupon-selector';
import { MallActivityTagSelector } from './mode/array/mall-activity-tag-selector';
import { MallActivityTopics } from './mode/array/mall-activity-topic-selector';
import { MallActivityTags } from './mode/array/mall-activity-tags';

import { CouponsSelectorWithQuantityMode } from './mode/array/coupons-selector-with-quantity';
import { CouponSelectorWithPackageMode } from './mode/array/coupon-selector-with-package';
import { VirtualProductSelectorMode } from './mode/array/virtual-product-selector';

import { CouponsSaleCategoriesSelector } from './mode/array/coupons-sale-categories-selector';
import { ArrayObjectFormat } from './format/array/array-object-format';
import { ArrayAndObjectFormat } from './format/array/object';
import { ArrayUserRulesMode } from './mode/array/user-rules';
import { ImageObjectArrayForTopPicMode } from './mode/array/image-object-array-for-top-pic';
import { MaotaiPresaleRoundsMode } from './mode/array/maotai-presale-rounds-mode';
import { MaotaiPresaleConditionsMode } from './mode/array/maotai-presale-conditions-mode';
import { MaotaiPresalePrizeConditionsMode } from './mode/array/maotai-presale-prize-conditions-mode';
import { MaotaiPresalePrizeQualityLimitMode } from './mode/array/maotai-presale-prize-quality-limit-mode';
import { MaotaiPresaleQuantityRuleMode } from './mode/array/maotai-presale-quantity-rule-mode';
import { MaotaiDrawQualityLimitMode } from './mode/array/maotai-presale-draw-quality-limit-mode';
import { MaotaiDrawMessageTypeMode } from './mode/array/maotai-presale-message-type-mode';
import { MaotaiPresalePurchaseModeMode } from './mode/object/maotai-presale-purchase-mode';

import { ArrayMemberLevelRulesMode } from './mode/array/member-level-rules';
import { ArrayMemberLevelParkBenefitMode } from './mode/array/member-level-park-benefit';
import { ArrayCreditShopMemberLimitMode } from './mode/array/credit-shop-member-limit';
import { ArrayMemberLevelParkUnbindMode } from './mode/array/member-level-park-unbind';
import { ArrayCGBProductsFormat } from './format/array/array-cgb-products';
import { ArrayActivityTagFormat } from './format/array/array-activity-tag';
import { CGBProductSelectorMode } from './mode/array/cgb-product-selector';
import { SaleProductSelectorMode } from './mode/array/sale-product-selector';
import { ArrayGroupBuyingFormat } from './format/array/group-buying-goods';
import { GroupBuyingGoodsSelector } from './mode/array/group-buying-goods-selector';
import { GroupBuyingProductsSelector } from './mode/array/group-buying-products-selector';
import { ArrayRefuseReasonMode } from './mode/array/refuse-reason-text';
import {
    ArrayUserRulesFormat,
    ArrayMemberLevelRulesFormat,
    ArrayActivityRewardFormat,
    ArrayCardLevelRightsFormat,
    ArrayMemberLevelParkBenefitFormat,
    ArrayMemberLevelParkUnbindFormat,
    ArrayCarparkImageAdsFormat,
    SubsiteLinkTypeSelectorFormat,
} from './format';
import { ObjectDateTimeRangeTipMode } from './mode/object/date-time-range-tip';
import { MallActivityRegistrationTimeMode } from './mode/object/mall-activity-registration-time';
import { NewMallActivityRegistrationTimeMode } from './mode/object/new-mall-activity-registration-time';

import { LinkTypeMode } from './mode/object/link-type';
import { LinkTypePopUpContent } from './mode/object/link-type-pop-up-content';
import { BirthdayMemberLevelCouponRuleMode } from './mode/object/birthday-member-level-coupon-rule';
import { BirthdayMemberLevelCouponRuleFormat } from './format/object/birthday-member-level-coupon-rule';

import { PresaleProductSelectorMode } from './mode/array/presale-product-selector';
import { SelfDeliveryProductSelectorMode } from './mode/array/self-delivery-product-selector';
import { ArrayTimepickerRangeTipsMode } from './mode/array/timepicker-range-tips';
import { ArrayOptionIdsCheckboxTipMode } from './mode/array/option-Ids-checkbox-tip';
import { CheckboxPlusMode } from './mode/array/checkbox-plus';
import { ObjectWinningLotsNumberMode } from './mode/object/winning-lots-number';
import { ObjectRegistrationPriceMode } from './mode/object/registration-price';
import { ObjectServicePriceMode } from './mode/object/service-price';
import { ObjectSelectTimepickerMode } from './mode/object/select-timepicker';
import { NumberInputTitleTipMode } from './mode/number/input-title-tip';
import { IntegerInputTitleTipMode } from './mode/number/integer-input-title-tip';
import { MemberSelectorMode } from './mode/object/member-selector';
import { GroupPopoverModeGoods } from './mode/string/group-popover-goods';
import { GroupPopoverMode } from './mode/string/group-popover';
import { CouponNoPopoverMode } from './mode/array/coupon-no-popover';
import { PictureExampleButtonMode } from './mode/string/picture-example-button';
import { ValueRangeMode } from './mode/string/value-range';
import { CardLevelRightsMode } from './mode/array/card-level-rights';
import { ObjectPointDeductionMode } from './mode/object/point-deduction';
import { ObjectLimitSessionCountMode } from './mode/object/limit-session-count';
import { RewardRulesMode } from './mode/object/reward-rules';
import { RewardSettingMode } from './mode/array/reward-setting';
import { RewardReriodMode } from './mode/array/reward-period';
import { CustomFormMode } from './mode/array/custom-form';
import { ArrayActivityRewardMode } from './mode/array/avtivity-reward';
import { ArrayCommentActivityRewardMode } from './mode/array/comment-avtivity-reward';
import { ArrayCardCollectRewardMode } from './mode/array/card-colllection-reward';
import { ArrayCardCollectItemConfigMode } from './mode/array/card-colllection-item-config';
import { ArrayCardCollectRuleConfigMode } from './mode/array/card-colllection-rule-config';
import { CitywideDeliveryFreightLadderMode } from './mode/object/citywide-delivery-freight-ladder';

import { ObjectMonthDayRangeFormat } from './format/object/month-day-range';
import { ObjectMonthDayRangeMode } from './mode/object/month-day-range';
import { GroupBuyingCheckbox } from './mode/array/group-buying-checkbox';
import { MemberTypeTags } from './mode/array/member-type-tags';
import { ContentComplainDetailMode } from './mode/object/content-complain-detail';
import { MemberLevelUpRuleMode } from './mode/object/member-level-up-rule';
import { RadioIllustrate } from './mode/string/radio-illustrate';
import { GeofencesMode } from './mode/array/geofences';
import { ArrayFullReductionLadderMode } from './mode/array/full-reduction-ladder';
import { ArrayFixedPriceRulesMode } from './mode/array/fixed-price-rules';
import { ObjectActivityRewardInvitedMode } from './mode/object/activity-reward-invited';
import { ObjectActivityRewardInvitedFormat } from './format/object/activiry-reward-invited';
import { LotteryCodeImportExportMode } from './mode/string/lottery-code-import-export';
import { ActivityBlackImportMode } from './mode/string/activity-black-import-export';
import { MerchantUserPhoneInputMode } from './mode/string/merchant-user-phone-input';
import { ActivityCreatorPhoneInputMode } from './mode/string/acitivity-creator-phone-input';
import { CarparkSupportAlipayMode } from './mode/array/carpark-support-alipay';
import { RegistrationTimePickerMode } from './mode/array/registration-time-picker';
import { ActivityRegistrationTimePickerMode } from './mode/array/activity-registration-time-picker';
import { EntityButtonMode } from './mode/string/entity-button';
import { FilterSelectorMode } from './mode/string/filter-selector';
import { ParticipateConditionMode } from './mode/object/participate-condition';
import { LinkTypeEffectPathMode } from './mode/object/link-type-effect-path';
import { ArrayMailboxSuffixMode } from './mode/array/mailbox-suffixes';
import { ObjectLinkMode } from './mode/object/link';
import { ArrayPopoverMode } from './mode/array/popover';
import { DistributionProductSelectorMode } from './mode/array/distribution-product-selector';
import { SchedulerMethodsMode } from './mode/object/scheduler-methods';
import { ProductStyleSelector } from './mode/array/product-style-selector';
import { ArrayEventMarketRewardMode } from './mode/array/event-market-reward';
import { KeywordsProductSelectorMode } from './mode/array/keywords-product-selector';
import { SalesPlansProductSelectorMode } from './mode/array/sales-plans-product-selector';
import { ProductCategorySelector } from './mode/array/product-category-selector';
import { PointRewardContentMode } from './mode/object/point-reward-content';
import { MemberPointDoubleRuleMode } from './mode/object/member-point-double-rule';
import { MemberLevelRuleMode } from './mode/object/member-level-rule';
import { MallActivityInfo } from './mode/object/mall-activity-info';
import { OpenScreenInfo } from './mode/object/open-screen-info';
import { StringCopyMode } from './mode/string/string-copy';
import { StringDescMode } from './mode/string/string-desc';
import { ContentTopicSelectorMode } from './mode/array/content-topic-selector';
import { StringPopoverMode } from './mode/string/string-popover';
import { PointValidRuleMode } from './mode/object/point-valid-rule';
import { PointExchangeRuleMode } from './mode/object/point-exchange-rule';
import { MerchantCodeTaxrateRuleMode } from './mode/object/merchant-code-taxrate-rule';
import { StringColorMode } from './mode/string/color';
import { TopicSelectorMode } from './mode/object/topic-selector';
import { SharePageActivityRewardsMode } from './mode/array/share-page-activity-rewards';
import { GoodsSelector } from './mode/array/goods-selector';
import { StringOptionsRadioPlusMode } from './mode/string/string-options-radio-plus';
import { DeliveryFreightRulesMode } from './mode/object/delivery-freight-rules';
import { ObjectCheckboxWithValueGroupMode } from './mode/object/checkout-group';
import { StringOptionSelectPlusMode } from './mode/string/options-select-plus';
import { RewardSettingOptionsSelectMode } from './mode/string/reward-setting-options-select';
import { ObjectActivityRewardCheckinMode } from './mode/object/activity-reward-checkin';
import { PointTagsSelectorMode } from './mode/array/point-tags-seletor';
import { PaymentRewardsMode } from './mode/array/payment-rewards';
import { ObjectMemberCardDiscountTagMode } from './mode/object/member-card-discount-tag';
import { ObjectThemeStyleFontSelectorMode } from './mode/object/theme-style-font-selector';
import { ObjectThemeStylenNavigationSelectorMode } from './mode/object/theme-style-navigation-selector';
import { ObjectThemeStylenAdvancedSelectorMode } from './mode/object/theme-style-advanced-selector';
import { ObjectThemeStyleButtonSelectorMode } from './mode/object/theme-style-button-selector';

import { SelectMemberCardWithDiscountMode } from './mode/array/select-member-card-with-discount';
import { ObjectDayPeriodConditionMode } from './mode/object/day-period-condition';
import {
    FilterAutoCompleteSelectorMode,
    FilterAutoCompleteSelectorObjectMode,
} from './mode/string/filter-auto-complete-selector';
import { ArrayOptionsAutoCompletePropertiesMode } from './mode/array/array-options-auto-complete-properties';
import { ArrayOptionsAutoCompletePropertiesCouponMode } from './mode/array/array-options-auto-complete-properties-coupon';
import { ArrayStringLineFeed } from './mode/array/array-string-line-feed';
import { ProductCategoriesMode } from './mode/array/product-categories';
import { ProductBrandsSelectorMode } from './mode/array/product-brands-selector';
import { ProductGoodsSelectorMode } from './mode/array/product-goods-selector';
import { SubsiteMerchantDeliveryMode } from './mode/array/subsite-merchant-delivery';
import { ArrayCarparkImageAdsMode } from './mode/array/carpark-image-ads';
import { ObjectCarparkDeductionRulesMode } from './mode/object/carpark-deduction-rules';
import { ObjectDiscountLimitMode } from './mode/object/discount-limit';
import { ObjectTimeRangePlusMode } from './mode/object/time-range-plus';
import { ObjectGoldClearRuleMode } from './mode/object/gold-clear-rule';
import { StringFormatterMode } from './mode/string/string-formatter';
import { PhoneMode } from './mode/string/phone';
import { StringNavigationLinkMode } from './mode/string/navigation-link';
import { StringRadioWithTipsModalMode } from './mode/string/radio-with-modal-tip';
import { StringTextParagraphTip } from './mode/string/string-text-paragraph-tip';
import { OptionSelectObjectMode } from './mode/object/options-select-object';
import { LiveRoomProductMode } from './mode/object/live-room-product';
import { MerchantMode } from './mode/object/merchant';
import { ArrayLiveRoomProductMode } from './mode/array/live-room-product-mode';
import { ArrayActivitySignInRewardMode } from './mode/array/acivity-signin-reward';
import { MemberCardLevelPointRewardInfoMode } from './mode/object/member-card-level-point-reward';
import { ExternalCouponChooseMode } from './mode/object/external-coupon-choose';
import { StringInputWithQuestionTipMode } from './mode/string/input-with-question';
import { StringInputWithTipsModalMode } from './mode/string/input-with-modal-tip';
import { ObjectCreditCycleLimitMode } from './mode/object/credit-cycle-limit';
import { ObjectMallActivityPayList } from './mode/object/mall-activity-pay-list';
import { ObjectMallActivityCelable } from './mode/object/mall-activity-celable';
import { SubsiteLinkTypeSelectorMode } from './mode/object/subsite-link-type-selector';
import { StringRichTextPlusMode } from './mode/string/rich-text-plus';
import { StringPasswordPlusMode } from './mode/string/password-plus';
import { MerchantBigCodesNameMode } from './mode/string/merchant-big-codes-name';
import { RegionSelectMode } from './mode/object/region-select';
import { OrdersPopoverMode } from './mode/array/orders-popover';
import { WechatOfficialAccountSubsitesArrayMode } from './mode/array/wechat-official-account-subsites';
import { WechatOfficialAccountTopicsArrayMode } from './mode/array/wechat-official-account-topic';
import { ArrayFeedbackTypesMode } from './mode/array/feedback-types';
import { ArrayMerchantBarCodeMode } from './mode/array/merchant-bar-code';
import { InvoiceSystemMode } from './mode/object/invoice-system';
import { ArrayAlipayOptionIdsCheckoutTipMode } from './mode/array/array-alipay-option-ids-checkout-tip';
import { ObjectNotificatonContentCheckboxTipModeMode } from './mode/object/object-notificaton-content-checkbox-tip';
import { PaymentNotificatonContentCheckboxTipModeMode } from './mode/object/payment-notificaton-content-checkbox-tip';
import { MerchantSelectorTickMode } from './mode/array/merchant-selector';
import { ArraySentOutChannelMode } from './mode/array/array-sent-out-channel';
import { StringAutoCompleteSelectorMode } from './mode/string/auto-complete-selector';
import { PaymentRewardsPlusMode } from './mode/array/payment-rewards-plus';
import { OrderMergeTabSequencesMode } from './mode/array/order-merge-tab-sequences';
import { OpenInvoiceTabSequencesMode } from './mode/array/open-invoice-tab-sequences';
import { ObjectImageRadioPrintMode } from './mode/object/image-radio-print';
import { ArrayBalanceCouponsMode } from './mode/array//array-balance-coupons-mode';
import { ArrayCheckboxWithTipsModalMode } from './mode/array/checkbox-with-modal-tip';
import { ArrayStyleGroupMode } from './mode/array/style-group';
import { ArticleSelectorMode } from './mode/array/article-selector';
import { AffiliateMarketingShareRewardMode } from './mode/object/affiliate-marketing/affiliate-marketing-share-reward';
import { AffiliateMarketingPointExchangeMode } from './mode/object/affiliate-marketing/affiliate-marketing-point-exchange';
import { AffiliateMarketingPeriodLimitMode } from './mode/object/affiliate-marketing/affiliate-marketing-period-limit';
import { AffiliateMarketingMemberLevelMode } from './mode/object/affiliate-marketing/affiliate-marketing-member-level';
import { AffiliateMarkingInviteFriendsMode } from './mode/object/affiliate-marketing/affiliate-marketing-invite-friends';
import { ServiceFacilitiesMode } from './mode/object/service-reserved-setting/service-facilities';
import { SelectCityCodeMode } from './mode/object/select-city-code';
import { SelectMemberMode } from './mode/object/select-member';
import { SalesElectronicCouponSelectMode } from './mode/object/sales-electronic-coupon-select';
import { SalesElectronicCouponNameMode } from './mode/string/sales-electronic-coupon-name';
import { SalesPriceAndCouponPackagePricesMode } from './mode/object/sales-price-and-coupon-package-prices';
import { ArrayImgaesUploadSortableMode } from './mode/array/array-imgaes-upload-sortable';
import { ArrayVirtualCategoryMode } from './mode/array/array-virtual-category';
import { SalesElectronicCouponAfterSalesMode } from './mode/object/sales-electronic-coupon-after-sales';
import { ScalSelectMode } from './mode/object/scalSelect';
import { MemberGiftManageNameMode } from './mode/string/member-gift-manage-name';

import { TargetHideUserLevelsMode } from './mode/object/target-hide-user-levels';
import { ArrayTagIdsSelectMode } from './mode/array/array-add-select';
import { CreditShopNoticeMode } from './mode/object/credit-shop-notice';
import { CreditShopStockMode } from './mode/object/credit-shop-stock';
import { CreditShopExchangeMode } from './mode/object/credit-shop-exchange';
import { SalesElectronicCouponStockMode } from './mode/number/sales-electronic-coupon-stock';
import { StringRadioPopoverMode } from './mode/string/radio-popover';
import { ArrayMerchantTagSelector } from './mode/array/merchant-tag-selector';
import { ArrayMerchantManagerFloors } from './mode/array/array-merchant-manager-floors';
import { MerchantManagerCategory } from './mode/array/array-merchant-manager-category';
import { RegionsSelectorMode } from './mode/array/array-regions-selector';
import { MerchantBigCodesMode } from './mode/array/array-big-codes';
import { ArrayWifiEquipmentMode } from './mode/array/array-wifi-equipment';
import { StringThirdLinkMode } from './mode/string/third-link';
import { CarparkPointAliasMode } from './mode/string/carpark-point-alias';
import { ObjectCarparkInvoiceConfigMode } from './mode/object/carpark-invoice-config';
import { ObjectCarparkCheckoutTypeMode } from './mode/object/carpark-checkout-type-config';
import { CarparkRuleEntrancesMode } from './mode/array/carpark-rule-entrances';
import { CarparkPageContentMode } from './mode/array/carpark-page-content';
import { CarparkMenusConfigMode } from './mode/array/carpark-menus-config';
import { CarparkButtonsConfigMode } from './mode/array/carpark-buttons-config';
import { OptionAutoCompletePlusMode } from './mode/string/option-auto-complete-plus';
import { liveRoomDateMode } from './mode/array/live-room-date';
import { MerchantsSelectorBySubsitesMode } from './mode/array/array-merchants-selector-by-subsites';
import { ArrayMerchantStyleSelector } from './mode/array/merchant-style-selector';
import { WechatCardColorMode } from './mode/string/wechat-card-color';
import { CreditEshopAssignDeliveryModesMode } from './mode/array/credit-eshop-assign-delivery-modes';
import { EventMarketPayCouponMode } from './mode/array/event-market-pay-coupon';

import { MallActivitySubscriptMessageSettingMode } from './mode/object/mall-activity-subscript-message-setting';
import { OpenBoxDrawLimitRuleMode } from './mode/object/open-box-draw-rule/index';
import AutoCompleteAppIdBySubsiteObjectMode from './mode/object/auto-complete-appid-by-subsite';
import { ObjectDateTimeRangePlusMode } from './mode/object/date-time-range-plus';
import { ObjectDateRangePlusMode } from './mode/object/date-range-plus';
import { FloatingActionControlIconMode } from './mode/object/floating-action-control-icon';
import ObjectSubsiteMode from './mode/object/subsite';
import WechatOfficalUseDefaultReplyBooleanMode from './mode/boolean/wechat-official-use-default-reply';
import WechatOfficialQRcodeReplyArrayMode from './mode/array/wechat-official-qrcode-reply';
import CardLevelItemsMode from './mode/array/array-card-level-items';
import { FloatingActionButtonsMode } from './mode/array/floating-action-buttons';
import { InvoiceInfoBySubsiteMode } from './mode/object/invoice-info-by-subsite';
import { InvoiceModeMode } from './mode/string/invoice-mode';
import { ServiceReservedStockMode } from './mode/object/service-reserved-stock';
import { StringTipsMode } from './mode/string/string-tips';
import { ImageObjectArrayForWechatCardBackgroundMode } from './mode/array/array-wechat-card-background';
import { ObjectMemberMarkingContentMode } from './mode/object/object-member-marking';
import { ObjectActivityRewardSingleMode } from './mode/object/activity-reward-single';

import { ListItemProductInfo } from './mode/object/list-item-product-info';
import { ArrayBargainCouponSelectModa } from './mode/array/array-bargain-coupon-select';
import { ObjectBargainProductsOrGoodSelectMode } from './mode/object/object-bargain-products-or-good-select';
import { ListItemTexts } from './mode/object/list-item-texts';
import { GivingCouponCrowdsMode } from './mode/object/giving-coupon-crowds';
import CheckboxTipsBooleanMode from './mode/boolean/checkbox-tips';
import { ArrayStoredValueCardMenus } from './mode/array/array-stored-value-card-menus';
import { ArrayStoredValueCardAmountDefinitionsMode } from './mode/array/array-stored-value-card-amount-definitions';
import { ArrayStoredValueCardSettingMode } from './mode/array/array-stored-value-card-setting';
import { ArrayOptionIdsCheckboxPopoverMode } from './mode/array/array-checkbox-popover/checkbox-popover';
import AllocationAccountRatioArrayMode from './mode/array/allocation-account-ratio';
import NumberPlusMode from './mode/number/number-plus';
import { StringRadioStoredValueCardTypeMode } from './mode/string/string-radio-stored-value-card-type';
import { ObjectOptionAutoCompletePlusMode } from './mode/object/object-option-auto-complete-plus';
import { ArrayStoredValueCardRewardMode } from './mode/array/stored-value-card-reward';
import { StackedUseMode } from './mode/object/stacked-use';
import { CommonSettingOptionMode } from './mode/object/common-setting/common-setting-option';
import { EfutureCouponChooseMode } from './mode/object/efuture-coupon-choose';
import { CouponRuleStockMode } from './mode/object/coupon-rule-stock';
import { RestaurantReservationSettingMode } from './mode/object/restaurant-reservation-setting';
import { GoodsPopoverMode } from './mode/array/goods-popover';
import { PayWithGiftsLimitsMode } from './mode/object/pay-with-gifts-limits';
import { PayWithGiftsRewardMode } from './mode/object/pay-with-gifts-reward';
import { PayWithGiftRewardNotificatonCheckboxMode } from './mode/object/pay-with-gift-reward-notificaton-checkbox';
import { ArrayReceiveLimitCrowdsMode } from './mode/array/array-receive-limit-crowds';
import { ObjectNewMallActivityRounds } from './mode/object/new-mall-activity-rounds';
import { ObjectNewMallActivityTickets } from './mode/object/new-mall-activity-tickets';
import { ArrayOptionsAutoCompleteStoreCardLevelsMode } from './mode/array/array-options-auto-complete-store-card-levels';
import { ArrayPopoverListMode } from './mode/array/popover-list';
import { DeliveryLogisticsConfigsMerchantsMode } from './mode/object/delivery-logistics-configs-merchant';
import { SelectCityAndAddressMode } from './mode/object/select-city-and-address';
import { ArrayImageAndOperatorMode } from './mode/array/array-image-and-operator';
import { CouponInfoMode } from './mode/object/coupon-info';
import { ArrayImageUploadAndCrop } from './mode/array/array-image-upload-and-crop';
import { StringLinkMode } from './mode/string/string-link';
import { ObjectDeliverySceneMode } from './mode/object/delivery-scene';
import { GiftSubsiteSelectorMode } from './mode/array/gift-subsite-selector';
import { ArrayCouponRuleStackMode } from './mode/array/array-coupon-rule-stack';
import { MerchantBusinessGuideMode } from './mode/object/merchant-business-guide';
import { SecurityPolicyConditionMode } from './mode/object/security-policy-condition';
import { PicAndNameCodeMode } from './mode/object/pic-name-code';
import { ArrayUpgradeGiftMode } from './mode/array/upgrade-gift';
import { WechatOfficialAutoReplyViewMode } from './mode/object/wechat-official-auto-reply-view';
import { BirthdayCaringMessageMode } from './mode/object/birthday-caring-message';
import { StringOptionsRadioHasLinkMode } from './mode/string/string-options-radio-has-link';
import { ArrayUserPositioningMode } from './mode/array/array-user-positioning/array-user-positioning';
import { ArrayDepositModeMenusMode } from './mode/array/array-deposit-mode-menus';
import { GiftProductSelectMode } from './mode/object/gift-product-select';
import { IntegerInputForWeightMode } from './mode/number/integer-input-for-weight/index';
import { BfplatCouponChooseMode } from './mode/object/bfplat-coupon-choose';
import { LogoutAgreementMode } from './mode/object/logout-agreement';
import { AmountRangeMode } from './mode/object/amount-range';
import { CouponDetailGuideFilterMode } from './mode/object/coupon-details-guide-filter';
import { ArticleTypeMode } from './mode/array/article-type';
import { CarparkModuleInfoMode } from './mode/array/carpark-module-info';
import { CollectionPointsTimesRuleMode } from './mode/object/collection-points-times-rule';
import { CollectionPointsPictureMode } from './mode/object/collection-points-picture';
import { CollectionPointsRewardMode } from './mode/array/collection-points-reward';
import { ObjectGradeEquityMode } from './mode/object/grade-equity';
import { MarketingScheduleMode } from './mode/array/marketing-schedule';
import { MessageNotificationMethodMode } from './mode/array/array-message-notification-method';
import { StringPictureUrlMode } from './mode/string/pictrue-url';
import { PictureLinkTypeMode } from './mode/object/picture-lint-type';
import { BirthdayMessageTimeMode } from './mode/object/birthday-message-time';
import { ArrayChannelCheckboxMode } from './mode/array/array-channel-checkbox';
import { NumberTaxMode } from './mode/number/number-tax';

const Types = new Map<string, Type>();
const {
    StringFormat,
    NumberFormat,
    ArrayFormat,
    ObjectNoValidationFormat,
    ArrayNoValidationFormat,
    StringOptionsAutoCompleteFormat,
} = formats;
Types.set('string.maxLength', new Type(new StringFormat(), new StringInputMaxLengthTipMode()));
Types.set('string.tip', new Type(new StringFormat(), new StringInputTipMode()));
Types.set('string.copy', new Type(new StringFormat(), new StringCopyMode()));
Types.set('string.imageRadio', new Type(new StringFormat(), new StringImageRadioMode()));
Types.set('number.tip', new Type(new NumberFormat(), new NumberInputTipMode()));
Types.set('number.tip.groupBuying', new Type(new NumberFormat(), new NumberInputTipGroupBuying()));
Types.set(
    'number.presaleTitleTip',
    new Type(new ObjectNoValidationFormat(), new NumberInputTitleTipMode())
);
Types.set(
    'number.integerInputTitleTip',
    new Type(new ObjectNoValidationFormat(), new IntegerInputTitleTipMode())
);
Types.set('number.store.status', new Type(new NumberFormat(), new StoreStatusMode()));
Types.set('string.statusBadge', new Type(new StringFormat(), new StatusBadgeMode()));
Types.set('array.subsites.selector', new Type(new ArrayObjectFormat(), new SubsitesSelectorMode()));
Types.set(
    'array.activities.selector',
    new Type(new ArrayAndObjectFormat(), new MallActivityMode())
);
Types.set(
    'array.carparkRuleEntrances',
    new Type(new ArrayAndObjectFormat(), new CarparkRuleEntrancesMode())
);
Types.set(
    'array.carparkPageContent',
    new Type(new ArrayAndObjectFormat(), new CarparkPageContentMode())
);
Types.set(
    'array.carparkMenusConfig',
    new Type(new ArrayAndObjectFormat(), new CarparkMenusConfigMode())
);
Types.set(
    'array.carparkButtonsConfig',
    new Type(new ArrayAndObjectFormat(), new CarparkButtonsConfigMode())
);
Types.set(
    'array.activities.openBox.selector',
    new Type(new ArrayAndObjectFormat(), new OpenBoxActivityMode())
);
Types.set('array.tags.selector', new Type(new ArrayAndObjectFormat(), new TagsSelectorMode()));
Types.set('array.shop.selector', new Type(new ArrayObjectFormat(), new ShopSelectorMode()));
Types.set(
    'array.merchant.selector',
    new Type(new ArrayAndObjectFormat(), new MerchantSelectorPlusMode())
);
Types.set('array.coupon.selector', new Type(new ArrayAndObjectFormat(), new CouponSelectorMode()));
Types.set(
    'array.activityTag.selector',
    new Type(new ArrayActivityTagFormat(), new MallActivityTagSelector())
);
Types.set(
    'array.MallActivityTag.selector',
    new Type(new ArrayActivityTagFormat(), new MallActivityTags())
);
Types.set(
    'array.MallActivityTopic.selector',
    new Type(new ArrayActivityTagFormat(), new MallActivityTopics())
);
Types.set('array.goods.selector', new Type(new ArrayActivityTagFormat(), new GoodsSelector())); //
Types.set(
    'array.couponsSelectorWithQuantity',
    new Type(new ArrayAndObjectFormat(), new CouponsSelectorWithQuantityMode())
);
Types.set(
    'array.virtualProductSelector',
    new Type(new ArrayAndObjectFormat(), new VirtualProductSelectorMode())
);
Types.set(
    'array.couponSelectorWithPackage',
    new Type(new ArrayAndObjectFormat(), new CouponSelectorWithPackageMode())
);

Types.set(
    'array.couponsSaleCategories',
    new Type(new ArrayAndObjectFormat(), new CouponsSaleCategoriesSelector())
);
Types.set('array.UserRules', new Type(new ArrayUserRulesFormat(), new ArrayUserRulesMode()));
Types.set('array.checkboxPlus', new Type(new ArrayFormat(), new CheckboxPlusMode()));
Types.set(
    'array.creditEshopAssignDeliveryModes',
    new Type(new ArrayFormat(), new CreditEshopAssignDeliveryModesMode())
);
Types.set(
    'array.checkboxWithModalTip',
    new Type(new ArrayFormat(), new ArrayCheckboxWithTipsModalMode())
);
Types.set('array.rewardSetting', new Type(new ArrayNoValidationFormat(), new RewardSettingMode()));
Types.set(
    'array.floatingActionButtons',
    new Type(new ArrayNoValidationFormat(), new FloatingActionButtonsMode())
);
Types.set('array.rewardPeriod', new Type(new ArrayNoValidationFormat(), new RewardReriodMode()));
Types.set('array.customForm', new Type(new ArrayNoValidationFormat(), new CustomFormMode()));
Types.set(
    'array.MemberLevelRules',
    new Type(new ArrayMemberLevelRulesFormat(), new ArrayMemberLevelRulesMode())
);
Types.set(
    'array.MemberLevelParkBenefit',
    new Type(new ArrayMemberLevelParkBenefitFormat(), new ArrayMemberLevelParkBenefitMode())
);
Types.set(
    'array.CreditShopMemberLimit',
    new Type(new ArrayAndObjectFormat(), new ArrayCreditShopMemberLimitMode())
);
Types.set(
    'array.MemberLevelParkUnbind',
    new Type(new ArrayMemberLevelParkUnbindFormat(), new ArrayMemberLevelParkUnbindMode())
);

Types.set(
    'array.FullReductionLadder',
    new Type(new ArrayMemberLevelParkUnbindFormat(), new ArrayFullReductionLadderMode())
);
Types.set(
    'array.FixedPriceRules',
    new Type(new ArrayMemberLevelParkUnbindFormat(), new ArrayFixedPriceRulesMode())
);

Types.set(
    'array.CGBProducts',
    new Type(new ArrayCGBProductsFormat(), new CGBProductSelectorMode())
);
Types.set(
    'array.SaleProducts',
    new Type(new ArrayCGBProductsFormat(), new SaleProductSelectorMode())
);
Types.set(
    'object.dateTimeRange.tip',
    new Type(new ObjectNoValidationFormat(), new ObjectDateTimeRangeTipMode())
);
Types.set(
    'object.dateTimeRangePlus',
    new Type(new ObjectNoValidationFormat(), new ObjectDateTimeRangePlusMode())
);
Types.set(
    'object.dateRangePlus',
    new Type(new ObjectNoValidationFormat(), new ObjectDateRangePlusMode())
);
Types.set(
    'object.floatingActionControlIcon',
    new Type(new ObjectNoValidationFormat(), new FloatingActionControlIconMode())
);
Types.set(
    'object.mallActivityRegistrationTime',
    new Type(new ObjectNoValidationFormat(), new MallActivityRegistrationTimeMode())
);
Types.set(
    'object.newMallActivityRegistrationTime',
    new Type(new ObjectNoValidationFormat(), new NewMallActivityRegistrationTimeMode())
);
Types.set(
    'object.businessGuide',
    new Type(new ObjectNoValidationFormat(), new MerchantBusinessGuideMode())
);

Types.set(
    'array.presaleTimepicker',
    new Type(new ObjectNoValidationFormat(), new ArrayTimepickerRangeTipsMode())
);
Types.set(
    'array.PresaleProducts',
    new Type(new ArrayCGBProductsFormat(), new PresaleProductSelectorMode())
);
Types.set(
    'array.SelfDeliveryProducts',
    new Type(new ArrayCGBProductsFormat(), new SelfDeliveryProductSelectorMode())
);
Types.set(
    'array.optionIds.checkbox.tip',
    new Type(new ArrayFormat(), new ArrayOptionIdsCheckboxTipMode())
);

Types.set(
    'array.alipay.optionIds.checkbox.tip',
    new Type(new ArrayFormat(), new ArrayAlipayOptionIdsCheckoutTipMode())
);
Types.set(
    'object.birthdayMemberLevelCouponRuleMode',
    new Type(new BirthdayMemberLevelCouponRuleFormat(), new BirthdayMemberLevelCouponRuleMode())
);
Types.set(
    'object.memberLevelRule',
    new Type(new ObjectNoValidationFormat(), new MemberLevelRuleMode())
);
Types.set(
    'object.mallActivityInfo',
    new Type(new ObjectNoValidationFormat(), new MallActivityInfo())
);
Types.set('object.openScreenInfo', new Type(new ObjectNoValidationFormat(), new OpenScreenInfo()));
Types.set(
    'object.winningLotsNumber',
    new Type(new ObjectNoValidationFormat(), new ObjectWinningLotsNumberMode())
);

Types.set(
    'object.registrationPrice',
    new Type(new ObjectNoValidationFormat(), new ObjectRegistrationPriceMode())
);
Types.set(
    'object.servicePrice',
    new Type(new ObjectNoValidationFormat(), new ObjectServicePriceMode())
);
Types.set(
    'object.selectTimepicker',
    new Type(new ObjectNoValidationFormat(), new ObjectSelectTimepickerMode())
);
Types.set('object.linkType', new Type(new ObjectNoValidationFormat(), new LinkTypeMode()));
Types.set(
    'object.carparkInvoiceConfig',
    new Type(new ObjectNoValidationFormat(), new ObjectCarparkInvoiceConfigMode())
);
Types.set(
    'object.carparkCheckoutType',
    new Type(new ObjectNoValidationFormat(), new ObjectCarparkCheckoutTypeMode())
);

Types.set(
    'object.linkTypePopUpContent',
    new Type(new ObjectNoValidationFormat(), new LinkTypePopUpContent())
);
Types.set('string.groupPopoverGoods', new Type(new StringFormat(), new GroupPopoverModeGoods()));
Types.set('string.groupPopover', new Type(new StringFormat(), new GroupPopoverMode()));
Types.set('string.thirdLink', new Type(new StringFormat(), new StringThirdLinkMode()));
Types.set('string.carparkPointAlias', new Type(new StringFormat(), new CarparkPointAliasMode()));

Types.set(
    'array.couponNoPopover',
    new Type(new formats.ArrayNoValidationFormat(), new CouponNoPopoverMode())
);
Types.set('string.popover', new Type(new StringFormat(), new StringPopoverMode()));
Types.set(
    'string.pictureExampleButton',
    new Type(new StringFormat(), new PictureExampleButtonMode())
);
Types.set(
    'string.lotteryCodeImportExport',
    new Type(new StringFormat(), new LotteryCodeImportExportMode())
);
Types.set(
    'string.activityBlackImportExport',
    new Type(new StringFormat(), new ActivityBlackImportMode())
);
Types.set(
    'string.merchantUserPhoneInput',
    new Type(new StringFormat(), new MerchantUserPhoneInputMode())
);
Types.set(
    'object.activityCreatorPhoneInput',
    new Type(new ObjectNoValidationFormat(), new ActivityCreatorPhoneInputMode())
);
Types.set('string.valueRange', new Type(new StringFormat(), new ValueRangeMode()));

Types.set(
    'array.cardCollectionReward',
    new Type(new ArrayActivityRewardFormat(), new ArrayCardCollectRewardMode())
);
Types.set(
    'array.cardCollectionItemConfig',
    new Type(new ArrayActivityRewardFormat(), new ArrayCardCollectItemConfigMode())
);
Types.set(
    'array.cardCollectionRuleConfig',
    new Type(new ArrayActivityRewardFormat(), new ArrayCardCollectRuleConfigMode())
);
Types.set(
    'array.activityReward',
    new Type(new ArrayActivityRewardFormat(), new ArrayActivityRewardMode())
);
Types.set(
    'array.activitySignInReward',
    new Type(new ArrayActivityRewardFormat(), new ArrayActivitySignInRewardMode())
);
Types.set(
    'object.activityRewardInvited',
    new Type(new ObjectActivityRewardInvitedFormat(), new ObjectActivityRewardInvitedMode())
);
Types.set(
    'object.member.selector',
    new Type(new ObjectNoValidationFormat(), new MemberSelectorMode())
);
Types.set(
    'array.cardLevelRights',
    new Type(new ArrayCardLevelRightsFormat(), new CardLevelRightsMode())
);

Types.set(
    'object.pointDeduction',
    new Type(new ObjectNoValidationFormat(), new ObjectPointDeductionMode())
);
Types.set(
    'object.limitSessionCount',
    new Type(new ObjectNoValidationFormat(), new ObjectLimitSessionCountMode())
);
Types.set('object.rewardRules', new Type(new ObjectNoValidationFormat(), new RewardRulesMode()));
Types.set(
    'array.groupBuyingGoods',
    new Type(new ArrayGroupBuyingFormat(), new GroupBuyingGoodsSelector())
);
Types.set(
    'array.groupBuyingProducts',
    new Type(new ArrayGroupBuyingFormat(), new GroupBuyingProductsSelector())
);
Types.set(
    'object.monthDayRange',
    new Type(new ObjectMonthDayRangeFormat(), new ObjectMonthDayRangeMode())
);
Types.set(
    'array.groupBuyingCheckbook',
    new Type(new ArrayGroupBuyingFormat(), new GroupBuyingCheckbox())
);
Types.set('array.memberTypeTags', new Type(new ArrayFormat(), new MemberTypeTags()));
Types.set(
    'object.content.complainDetail',
    new Type(new ObjectNoValidationFormat(), new ContentComplainDetailMode())
);
Types.set(
    'object.memberLevelUpRule',
    new Type(new ObjectNoValidationFormat(), new MemberLevelUpRuleMode())
);
Types.set('string.radioIllustrate', new Type(new StringFormat(), new RadioIllustrate()));

Types.set('array.geofences', new Type(new ArrayAndObjectFormat(), new GeofencesMode()));
Types.set(
    'array.registrationTimePicker',
    new Type(new ObjectNoValidationFormat(), new RegistrationTimePickerMode())
);
Types.set(
    'array.activityRegistrationTimePicker',
    new Type(new ObjectNoValidationFormat(), new ActivityRegistrationTimePickerMode())
);
Types.set('string.entityButton', new Type(new StringFormat(), new EntityButtonMode()));
Types.set('string.filterOptions.select', new Type(new StringFormat(), new FilterSelectorMode()));
Types.set(
    'object.participateCondition',
    new Type(new ObjectNoValidationFormat(), new ParticipateConditionMode())
);
Types.set(
    'object.linkTypeEffectPath',
    new Type(new ObjectNoValidationFormat(), new LinkTypeEffectPathMode())
);

Types.set(
    'object.subsiteLinkTypeSelector',
    new Type(new SubsiteLinkTypeSelectorFormat(), new SubsiteLinkTypeSelectorMode())
);

Types.set(
    'array.DistributionProducts',
    new Type(new ArrayCGBProductsFormat(), new DistributionProductSelectorMode())
);

Types.set(
    'array.KeywordsProducts',
    new Type(new ArrayCGBProductsFormat(), new KeywordsProductSelectorMode())
);

Types.set(
    'array.mailboxSuffix',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayMailboxSuffixMode())
);
Types.set('object.link', new Type(new ObjectNoValidationFormat(), new ObjectLinkMode()));
Types.set('array.popover', new Type(new formats.ArrayNoValidationFormat(), new ArrayPopoverMode()));
Types.set(
    'array.popover.list',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayPopoverListMode())
);
Types.set(
    'array.refuse.text',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayRefuseReasonMode())
);

Types.set(
    'object.schedulerMethods',
    new Type(new ObjectNoValidationFormat(), new SchedulerMethodsMode())
);

Types.set(
    'array.productStyle.selector',
    new Type(new ArrayCGBProductsFormat(), new ProductStyleSelector())
);
Types.set(
    'object.productCategory.selector',
    new Type(new ObjectNoValidationFormat(), new ProductCategorySelector())
);
Types.set(
    'array.eventMarcketReward',
    new Type(new ArrayActivityRewardFormat(), new ArrayEventMarketRewardMode())
);
Types.set(
    'object.citywideDeliveryFreightLadder',
    new Type(new ObjectNoValidationFormat(), new CitywideDeliveryFreightLadderMode())
);
Types.set(
    'array.salesPlansProducts',
    new Type(new ArrayCGBProductsFormat(), new SalesPlansProductSelectorMode())
);
Types.set(
    'object.PointRewardContent',
    new Type(new ObjectNoValidationFormat(), new PointRewardContentMode())
);
Types.set(
    'object.MemberPointDoubleRule',
    new Type(new ObjectNoValidationFormat(), new MemberPointDoubleRuleMode())
);
Types.set('string.stringDesc', new Type(new StringFormat(), new StringDescMode()));
Types.set(
    'array.ContentTopicSelector',
    new Type(new ArrayAndObjectFormat(), new ContentTopicSelectorMode())
);
Types.set(
    'object.PointExchangeRule',
    new Type(new ObjectNoValidationFormat(), new PointExchangeRuleMode())
);
Types.set(
    'object.PointValidRule',
    new Type(new ObjectNoValidationFormat(), new PointValidRuleMode())
);
Types.set(
    'object.MerchantCodeTaxrateRule',
    new Type(new ObjectNoValidationFormat(), new MerchantCodeTaxrateRuleMode())
);
Types.set('string.color', new Type(new StringFormat(), new StringColorMode()));
Types.set(
    'string.options.radio.plus',
    new Type(new StringFormat(), new StringOptionsRadioPlusMode())
);
Types.set(
    'string.options.radio.hasLink',
    new Type(new StringFormat(), new StringOptionsRadioHasLinkMode())
);
Types.set(
    'object.delivery.rules',
    new Type(new ObjectNoValidationFormat(), new DeliveryFreightRulesMode())
);

Types.set(
    'object.TopicSelector',
    new Type(new ObjectNoValidationFormat(), new TopicSelectorMode())
);

Types.set(
    'array.sharePageActivityReward',
    new Type(new ArrayActivityRewardFormat(), new SharePageActivityRewardsMode())
);
Types.set(
    'object.checkboxWithValueGroup',
    new Type(new ObjectNoValidationFormat(), new ObjectCheckboxWithValueGroupMode())
);
Types.set(
    'string.options.selectPlus',
    new Type(new StringFormat(), new StringOptionSelectPlusMode())
);
Types.set(
    'string.options.select.rewardSetting',
    new Type(new StringFormat(), new RewardSettingOptionsSelectMode())
);
Types.set(
    'object.activityRewardCheckin',
    new Type(new ObjectNoValidationFormat(), new ObjectActivityRewardCheckinMode())
);
Types.set(
    'array.pointTagsSelector',
    new Type(new ArrayAndObjectFormat(), new PointTagsSelectorMode())
);
Types.set('array.paymentRewards', new Type(new ArrayAndObjectFormat(), new PaymentRewardsMode()));
Types.set(
    'object.memberCardDiscountTag',
    new Type(new ObjectNoValidationFormat(), new ObjectMemberCardDiscountTagMode())
);
Types.set(
    'object.themeStyleFontSelector',
    new Type(new ObjectNoValidationFormat(), new ObjectThemeStyleFontSelectorMode())
);
Types.set(
    'object.themeStyleNavigationSelector',
    new Type(new ObjectNoValidationFormat(), new ObjectThemeStylenNavigationSelectorMode())
);
Types.set(
    'object.themeStyleAdvancedSelector',
    new Type(new ObjectNoValidationFormat(), new ObjectThemeStylenAdvancedSelectorMode())
);
Types.set(
    'object.themeStyleButtonSelector',
    new Type(new ObjectNoValidationFormat(), new ObjectThemeStyleButtonSelectorMode())
);
Types.set(
    'object.gradeEquity',
    new Type(new ObjectNoValidationFormat(), new ObjectGradeEquityMode())
);

Types.set(
    'array.selectMemberCardWithDiscount',
    new Type(new ArrayAndObjectFormat(), new SelectMemberCardWithDiscountMode())
);
Types.set(
    'object.dayPeriodCondition',
    new Type(new ObjectNoValidationFormat(), new ObjectDayPeriodConditionMode())
);

Types.set(
    'string.filterOptions.autoComplete',
    new Type(new StringOptionsAutoCompleteFormat(), new FilterAutoCompleteSelectorMode())
);
Types.set(
    'object.filterOptions.autoComplete',
    new Type(new ObjectNoValidationFormat(), new FilterAutoCompleteSelectorObjectMode())
);

Types.set(
    'array.options.autoComplete.properties',
    new Type(new ArrayNoValidationFormat(), new ArrayOptionsAutoCompletePropertiesMode())
);
Types.set(
    'array.options.autoComplete.properties.coupon',
    new Type(new ArrayNoValidationFormat(), new ArrayOptionsAutoCompletePropertiesCouponMode())
);

Types.set('array.stringLineFeed', new Type(new ArrayFormat(), new ArrayStringLineFeed()));

Types.set(
    'array.product.categories',
    new Type(new ArrayAndObjectFormat(), new ProductCategoriesMode())
);
Types.set(
    'array.product.brands.selector',
    new Type(new ArrayCGBProductsFormat(), new ProductBrandsSelectorMode())
);
Types.set(
    'array.product.goods.selector',
    new Type(new ArrayCGBProductsFormat(), new ProductGoodsSelectorMode())
);
Types.set(
    'subsite.merchant.delivery',
    new Type(new ObjectNoValidationFormat(), new SubsiteMerchantDeliveryMode())
);
Types.set(
    'array.carparkImageAds',
    new Type(new ArrayCarparkImageAdsFormat(), new ArrayCarparkImageAdsMode())
);
Types.set(
    'array.carparkSupportAlipay',
    new Type(new formats.ArrayNoValidationFormat(), new CarparkSupportAlipayMode())
);
Types.set(
    'object.carparkDeductionRules',
    new Type(new ObjectNoValidationFormat(), new ObjectCarparkDeductionRulesMode())
);
Types.set(
    'object.discountLimit',
    new Type(new ObjectNoValidationFormat(), new ObjectDiscountLimitMode())
);
Types.set(
    'object.timeRangePlus',
    new Type(new ObjectNoValidationFormat(), new ObjectTimeRangePlusMode())
);
Types.set(
    'array.imageObjectArrayForTopPic',
    new Type(new formats.ArrayNoValidationFormat(), new ImageObjectArrayForTopPicMode())
);
Types.set(
    'array.maotaiPresaleRounds',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiPresaleRoundsMode())
);
Types.set(
    'array.maotaiPresaleRegistrationConditions',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiPresaleConditionsMode())
);
Types.set(
    'array.maotaiPresalePrizeConditions',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiPresalePrizeConditionsMode())
);
Types.set(
    'array.maotaiPresalePrizeQualityLimit',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiPresalePrizeQualityLimitMode())
);
Types.set(
    'array.maotaiPresaleQuantityRule',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiPresaleQuantityRuleMode())
);
Types.set(
    'array.maotaiPresaleDrawQualityLimit',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiDrawQualityLimitMode())
);
Types.set(
    'array.maotaiPresaleMessageType',
    new Type(new formats.ArrayNoValidationFormat(), new MaotaiDrawMessageTypeMode())
);
Types.set(
    'object.maotaiPresalePurchaseMode',
    new Type(new ObjectNoValidationFormat(), new MaotaiPresalePurchaseModeMode())
);
Types.set(
    'object.goldClearRule',
    new Type(new ObjectNoValidationFormat(), new ObjectGoldClearRuleMode())
);
Types.set('string.formatter', new Type(new StringFormat(), new StringFormatterMode()));

Types.set('string.phone.input', new Type(new StringFormat(), new PhoneMode()));
Types.set(
    'options.select.object',
    new Type(new ObjectNoValidationFormat(), new OptionSelectObjectMode())
);
Types.set(
    'object.LiveRoomProduct',
    new Type(new ObjectNoValidationFormat(), new LiveRoomProductMode())
);
Types.set('object.merchant', new Type(new ObjectNoValidationFormat(), new MerchantMode()));
Types.set(
    'array.LiveRoomProducts',
    new Type(new ObjectNoValidationFormat(), new ArrayLiveRoomProductMode())
);
Types.set(
    'object.memberCardLevelPointReward',
    new Type(new ObjectNoValidationFormat(), new MemberCardLevelPointRewardInfoMode())
);
Types.set(
    'object.external.coupon.choose',
    new Type(new ObjectNoValidationFormat(), new ExternalCouponChooseMode())
);
Types.set(
    'object.bfplat.coupon.choose',
    new Type(new ObjectNoValidationFormat(), new BfplatCouponChooseMode())
);
Types.set('string.navigationLink', new Type(new StringFormat(), new StringNavigationLinkMode()));
Types.set(
    'string.radioWithModalTip',
    new Type(new StringFormat(), new StringRadioWithTipsModalMode())
);
Types.set('string.text.paragraphTip', new Type(new StringFormat(), new StringTextParagraphTip()));
Types.set(
    'string.questionTips',
    new Type(new StringFormat(), new StringInputWithQuestionTipMode())
);
Types.set('string.modalTips', new Type(new StringFormat(), new StringInputWithTipsModalMode()));
Types.set(
    'object.creditCycleLimit',
    new Type(new ObjectNoValidationFormat(), new ObjectCreditCycleLimitMode())
);
Types.set(
    'array.payList',
    new Type(new ObjectNoValidationFormat(), new ObjectMallActivityPayList())
);

Types.set(
    'object.mallActivityCelable',
    new Type(new ObjectNoValidationFormat(), new ObjectMallActivityCelable())
);
Types.set('string.richTextPlus', new Type(new StringFormat(), new StringRichTextPlusMode()));
Types.set(
    'string.MerchantBigCodesName',
    new Type(new StringFormat(), new MerchantBigCodesNameMode())
);
Types.set('string.passwordPlus', new Type(new StringFormat(), new StringPasswordPlusMode()));
Types.set('object.regionSelect', new Type(new ObjectNoValidationFormat(), new RegionSelectMode()));

Types.set(
    'array.orders.popover',
    new Type(new formats.ArrayNoValidationFormat(), new OrdersPopoverMode())
);

Types.set(
    'array.wechatOfficialSubsites',
    new Type(new ArrayAndObjectFormat(), new WechatOfficialAccountSubsitesArrayMode())
);
Types.set(
    'array.wechatOfficialTopics',
    new Type(new ArrayAndObjectFormat(), new WechatOfficialAccountTopicsArrayMode())
);
Types.set(
    'array.feedbackTypes',
    new Type(new ArrayAndObjectFormat(), new ArrayFeedbackTypesMode())
);
Types.set(
    'array.merchantBarCode',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayMerchantBarCodeMode())
);

Types.set(
    'object.invoice.system',
    new Type(new ObjectNoValidationFormat(), new InvoiceSystemMode())
);

Types.set(
    'object.notificaton.content.checkbox.tip',
    new Type(new ObjectNoValidationFormat(), new ObjectNotificatonContentCheckboxTipModeMode())
);
Types.set(
    'object.payment.notificaton.content.checkbox.tip',
    new Type(new ObjectNoValidationFormat(), new PaymentNotificatonContentCheckboxTipModeMode())
);
Types.set(
    'array.merchant.selector.tick',
    new Type(new ArrayAndObjectFormat(), new MerchantSelectorTickMode())
);
Types.set(
    'array.sendOutChannel',
    new Type(new ObjectNoValidationFormat(), new ArraySentOutChannelMode())
);
Types.set(
    'string.options.autoComplete.url',
    new Type(new StringOptionsAutoCompleteFormat(), new StringAutoCompleteSelectorMode())
);
Types.set(
    'array.paymentRewards.plus',
    new Type(new ArrayAndObjectFormat(), new PaymentRewardsPlusMode())
);
Types.set(
    'array.orderMergeTabSequences.tip',
    new Type(new ArrayAndObjectFormat(), new OrderMergeTabSequencesMode())
);
Types.set(
    'array.openInvoiceTabSequences.tip',
    new Type(new ArrayAndObjectFormat(), new OpenInvoiceTabSequencesMode())
);
Types.set(
    'object.imageRadio.print',
    new Type(new ObjectNoValidationFormat(), new ObjectImageRadioPrintMode())
);
Types.set(
    'array.balanceCouponsMode',
    new Type(new ArrayAndObjectFormat(), new ArrayBalanceCouponsMode())
);
Types.set('array.styleGroup', new Type(new ArrayAndObjectFormat(), new ArrayStyleGroupMode()));
Types.set('array.article.seletor', new Type(new ArrayAndObjectFormat(), new ArticleSelectorMode()));

Types.set(
    'object.select.city.code',
    new Type(new ObjectNoValidationFormat(), new SelectCityCodeMode())
);
Types.set('object.select.member', new Type(new ObjectNoValidationFormat(), new SelectMemberMode()));
Types.set(
    'object.salesElectronicCouponSelect',
    new Type(new ObjectNoValidationFormat(), new SalesElectronicCouponSelectMode())
);
Types.set(
    'string.salesElectronicCouponName',
    new Type(new StringFormat(), new SalesElectronicCouponNameMode())
);
Types.set(
    'number.salesElectronicCouponStock',
    new Type(new ObjectNoValidationFormat(), new SalesElectronicCouponStockMode())
);
Types.set(
    'object.salesPriceAndCouponPackagePrices',
    new Type(new ObjectNoValidationFormat(), new SalesPriceAndCouponPackagePricesMode())
);
Types.set(
    'array.imgaesUploadSortable',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayImgaesUploadSortableMode())
);
Types.set(
    'array.virtualCategory',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayVirtualCategoryMode())
);
Types.set(
    'object.salesElectronicCouponAfterSales',
    new Type(new ObjectNoValidationFormat(), new SalesElectronicCouponAfterSalesMode())
);
Types.set('object.map.scalSelect', new Type(new ObjectNoValidationFormat(), new ScalSelectMode()));
Types.set(
    'object.targetHideUserLevels',
    new Type(new ObjectNoValidationFormat(), new TargetHideUserLevelsMode())
);
Types.set('array.tagIds.select', new Type(new ArrayAndObjectFormat(), new ArrayTagIdsSelectMode()));
Types.set(
    'object.affiliateMarketing.memberLevel',
    new Type(new ObjectNoValidationFormat(), new AffiliateMarketingMemberLevelMode())
);
Types.set(
    'object.affiliateMarketing.shareReward',
    new Type(new ObjectNoValidationFormat(), new AffiliateMarketingShareRewardMode())
);
Types.set(
    'object.affiliateMarketing.pointExchange',
    new Type(new ObjectNoValidationFormat(), new AffiliateMarketingPointExchangeMode())
);
Types.set(
    'object.affiliateMarketing.periodLimit',
    new Type(new ObjectNoValidationFormat(), new AffiliateMarketingPeriodLimitMode())
);
Types.set(
    'object.affiliateMarketing.inviteFriends',
    new Type(new ObjectNoValidationFormat(), new AffiliateMarkingInviteFriendsMode())
);
Types.set(
    'object.commonSetting.option',
    new Type(new ArrayAndObjectFormat(), new CommonSettingOptionMode())
);
Types.set(
    'object.serviceFacilities',
    new Type(new ObjectNoValidationFormat(), new ServiceFacilitiesMode())
);

Types.set(
    'object.creditShopNotice',
    new Type(new ObjectNoValidationFormat(), new CreditShopNoticeMode())
);
Types.set(
    'object.creditShopStock',
    new Type(new ObjectNoValidationFormat(), new CreditShopStockMode())
);
Types.set(
    'object.creditShopExchange',
    new Type(new ObjectNoValidationFormat(), new CreditShopExchangeMode())
);

Types.set('string.radio.popover', new Type(new StringFormat(), new StringRadioPopoverMode()));
Types.set(
    'array.merchantTagSelector',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayMerchantTagSelector())
);
Types.set(
    'array.arrayMerchantManagerFloors',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayMerchantManagerFloors())
);
Types.set(
    'array.merchantManagerCategory',
    new Type(new formats.ArrayNoValidationFormat(), new MerchantManagerCategory())
);
Types.set(
    'array.regionsSelector',
    new Type(new formats.ArrayNoValidationFormat(), new RegionsSelectorMode())
);
Types.set(
    'array.merchantBigCodes',
    new Type(new formats.ArrayNoValidationFormat(), new MerchantBigCodesMode())
);

Types.set(
    'string.options.autoComplete.plus',
    new Type(new StringOptionsAutoCompleteFormat(), new OptionAutoCompletePlusMode())
);
Types.set(
    'array.wifiEquipment',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayWifiEquipmentMode())
);
Types.set(
    'array.liveRoomDate',
    new Type(new formats.ArrayNoValidationFormat(), new liveRoomDateMode())
);
Types.set(
    'array.merchants.selector.bySubsites',
    new Type(new ArrayAndObjectFormat(), new MerchantsSelectorBySubsitesMode())
);
Types.set(
    'array.merchantStyleSelector',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayMerchantStyleSelector())
);
Types.set(
    'object.mallActivity.subscriptMessage.setting',
    new Type(new ObjectNoValidationFormat(), new MallActivitySubscriptMessageSettingMode())
);
Types.set('string.wechat.card.color', new Type(new StringFormat(), new WechatCardColorMode()));
Types.set(
    'array.eventMarketPayCoupon',
    new Type(new ArrayAndObjectFormat(), new EventMarketPayCouponMode())
);
Types.set(
    'object.openBox.drawLimit',
    new Type(new ObjectNoValidationFormat(), new OpenBoxDrawLimitRuleMode())
);
Types.set(
    'array.commentActivityReward',
    new Type(new ArrayActivityRewardFormat(), new ArrayCommentActivityRewardMode())
);
Types.set('object.subsite', new Type(new ObjectFormat(), new ObjectSubsiteMode()));
Types.set(
    'object.autoCompleteAppIdBySubsite',
    new Type(new ObjectNoValidationFormat(), new AutoCompleteAppIdBySubsiteObjectMode())
);
Types.set(
    'boolean.wechatOfficalUseDefaultReplyBooleanMode',
    new Type(new BooleanFormat(), new WechatOfficalUseDefaultReplyBooleanMode())
);
Types.set(
    'array.wechatOfficialQRcodeReply',
    new Type(new ArrayAndObjectFormat(), new WechatOfficialQRcodeReplyArrayMode())
);
Types.set(
    'array.cardLevelItems',
    new Type(new formats.ArrayNoValidationFormat(), new CardLevelItemsMode())
);
Types.set(
    'object.invoiceInfo.selector.bySubsite',
    new Type(new ObjectNoValidationFormat(), new InvoiceInfoBySubsiteMode())
);
Types.set('string.invoice.mode', new Type(new StringFormat(), new InvoiceModeMode()));
Types.set(
    'object.serviceReservedStock',
    new Type(new ObjectNoValidationFormat(), new ServiceReservedStockMode())
);
Types.set('string.tips', new Type(new StringFormat(), new StringTipsMode()));
Types.set(
    'array.imageObjectArrayForWechatCardBackground',
    new Type(
        new formats.ArrayNoValidationFormat(),
        new ImageObjectArrayForWechatCardBackgroundMode()
    )
);
Types.set(
    'object.memberMarking',
    new Type(new ObjectNoValidationFormat(), new ObjectMemberMarkingContentMode())
);
Types.set(
    'object.list.item.productInfo',
    new Type(new ObjectNoValidationFormat(), new ListItemProductInfo())
);
Types.set('array.list.item.texts', new Type(new ArrayNoValidationFormat(), new ListItemTexts()));
Types.set(
    'array.bargain.couponsSelector',
    new Type(new ArrayNoValidationFormat(), new ArrayBargainCouponSelectModa())
);
Types.set(
    'object.bargain.productsOrGoodsSelector',
    new Type(new ObjectNoValidationFormat(), new ObjectBargainProductsOrGoodSelectMode())
);
Types.set(
    'object.GivingCouponCrowds',
    new Type(new ObjectNoValidationFormat(), new GivingCouponCrowdsMode())
);
Types.set(
    'object.activityReward.single',
    new Type(new ObjectNoValidationFormat(), new ObjectActivityRewardSingleMode())
);
Types.set('boolean.checkbox.tips', new Type(new BooleanFormat(), new CheckboxTipsBooleanMode()));
Types.set(
    'array.storedValueCardMenus',
    new Type(new ArrayAndObjectFormat(), new ArrayStoredValueCardMenus())
);
Types.set(
    'array.storedValueCardAmountDefinitions',
    new Type(new ArrayAndObjectFormat(), new ArrayStoredValueCardAmountDefinitionsMode())
);
Types.set(
    'array.storedValueCardSetting',
    new Type(new ObjectNoValidationFormat(), new ArrayStoredValueCardSettingMode())
);
Types.set(
    'array.allocationAccountRatio',
    new Type(new ArrayNoValidationFormat(), new AllocationAccountRatioArrayMode())
);
Types.set('number.positive.plus', new Type(new NumberPositiveFormat(), new NumberPlusMode()));
Types.set('array.userPositioning', new Type(new ArrayFormat(), new ArrayUserPositioningMode()));
Types.set(
    'array.optionIds.checkbox.popover',
    new Type(new ArrayFormat(), new ArrayOptionIdsCheckboxPopoverMode())
);
Types.set(
    'string.radio.StoredValueCardType',
    new Type(new StringFormat(), new StringRadioStoredValueCardTypeMode())
);
Types.set(
    'object.option.autoComplete.plus',
    new Type(new ObjectNoValidationFormat(), new ObjectOptionAutoCompletePlusMode())
);
Types.set(
    'array.storedValueCardReward',
    new Type(new ArrayNoValidationFormat(), new ArrayStoredValueCardRewardMode())
);
Types.set('object.stackedUse', new Type(new ObjectNoValidationFormat(), new StackedUseMode()));
Types.set(
    'object.efuture.coupon.choose',
    new Type(new ObjectNoValidationFormat(), new EfutureCouponChooseMode())
);
Types.set(
    'object.couponRuleStock',
    new Type(new ObjectNoValidationFormat(), new CouponRuleStockMode())
);
Types.set(
    'object.restaurantReservationSetting',
    new Type(new ObjectNoValidationFormat(), new RestaurantReservationSettingMode())
);
Types.set(
    'array.goods.popover',
    new Type(new formats.ArrayNoValidationFormat(), new GoodsPopoverMode())
);
Types.set(
    'object.payWithGiftsLimits',
    new Type(new ObjectNoValidationFormat(), new PayWithGiftsLimitsMode())
);
Types.set(
    'array.payWithGiftsReward',
    new Type(new ObjectNoValidationFormat(), new PayWithGiftsRewardMode())
);
Types.set(
    'object.payWithGiftsReward.notificaton.checkbox',
    new Type(new ObjectNoValidationFormat(), new PayWithGiftRewardNotificatonCheckboxMode())
);
Types.set(
    'array.receiveLimitationsCrowds',
    new Type(new ArrayNoValidationFormat(), new ArrayReceiveLimitCrowdsMode())
);
Types.set(
    'object.newMallAcitivityRounds',
    new Type(new ObjectNoValidationFormat(), new ObjectNewMallActivityRounds())
);
Types.set(
    'object.newMallAcitivityTickets',
    new Type(new ObjectNoValidationFormat(), new ObjectNewMallActivityTickets())
);
Types.set(
    'array.options.autoComplete.storeCardLevels',
    new Type(new ArrayNoValidationFormat(), new ArrayOptionsAutoCompleteStoreCardLevelsMode())
);

Types.set(
    'object.deliveryLogisticsConfigsMerchants',
    new Type(new ObjectNoValidationFormat(), new DeliveryLogisticsConfigsMerchantsMode())
);
Types.set(
    'object.select.city.address',
    new Type(new ObjectNoValidationFormat(), new SelectCityAndAddressMode())
);
Types.set(
    'array.arrayImageAndOperatorMode',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayImageAndOperatorMode())
);
Types.set('object.couponInfo', new Type(new ObjectNoValidationFormat(), new CouponInfoMode()));
Types.set(
    'array.imageUploadAndCrop',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayImageUploadAndCrop())
);
Types.set('string.link', new Type(new StringFormat(), new StringLinkMode()));
Types.set(
    'object.discountScene',
    new Type(new ObjectNoValidationFormat(), new ObjectDeliverySceneMode())
);

Types.set(
    'string.memberGiftManageName',
    new Type(new StringFormat(), new MemberGiftManageNameMode())
);

Types.set(
    'array.giftSubsiteselector',
    new Type(new ArrayObjectFormat(), new GiftSubsiteSelectorMode())
);
Types.set(
    'array.couponRuleStackCheckbox',
    new Type(new ArrayObjectFormat(), new ArrayCouponRuleStackMode())
);
Types.set(
    'object.securityPolicyCondition',
    new Type(new ObjectNoValidationFormat(), new SecurityPolicyConditionMode())
);

Types.set('object.picAndNameAndCode', new Type(new StringFormat(), new PicAndNameCodeMode()));

Types.set('array.upgradeGift', new Type(new ArrayObjectFormat(), new ArrayUpgradeGiftMode()));

Types.set(
    'object.WechatOfficialAutoReplyView',
    new Type(new ObjectNoValidationFormat(), new WechatOfficialAutoReplyViewMode())
);
Types.set(
    'object.birthdayCaringMessage',
    new Type(new ObjectNoValidationFormat(), new BirthdayCaringMessageMode())
);
Types.set(
    'array.depositModeMenus',
    new Type(new ArrayAndObjectFormat(), new ArrayDepositModeMenusMode())
);
Types.set(
    'object.giftProductSelect',
    new Type(new ObjectNoValidationFormat(), new GiftProductSelectMode())
);
Types.set(
    'number.integerInputForWeight',
    new Type(new ObjectNoValidationFormat(), new IntegerInputForWeightMode())
);
Types.set(
    'object.logout.agreement',
    new Type(new ArrayAndObjectFormat(), new LogoutAgreementMode())
);
Types.set('object.amountRange', new Type(new ObjectNoValidationFormat(), new AmountRangeMode()));
Types.set(
    'object.couponDetailsGuideFilter',
    new Type(new ObjectNoValidationFormat(), new CouponDetailGuideFilterMode())
);
Types.set('array.article.type', new Type(new ArrayFormat(), new ArticleTypeMode()));
Types.set(
    'array.carparkModuleInfo',
    new Type(new ArrayObjectFormat(), new CarparkModuleInfoMode())
);
Types.set(
    'object.collectionPointsTimesRule',
    new Type(new ObjectNoValidationFormat(), new CollectionPointsTimesRuleMode())
);
Types.set(
    'object.collectionPointsPicture',
    new Type(new ObjectNoValidationFormat(), new CollectionPointsPictureMode())
);
Types.set(
    'array.collectionPointsReward',
    new Type(new ArrayObjectFormat(), new CollectionPointsRewardMode())
);

Types.set(
    'array.marketing.schedule',
    new Type(new ArrayAndObjectFormat(), new MarketingScheduleMode())
);

Types.set(
    'array.messageNotificationMethod',
    new Type(new ArrayAndObjectFormat(), new MessageNotificationMethodMode())
);

Types.set('string.picture', new Type(new StringFormat(), new StringPictureUrlMode()));
Types.set(
    'object.pictrueLinkType',
    new Type(new ObjectNoValidationFormat(), new PictureLinkTypeMode())
);
Types.set(
    'object.birthdayMessageTime',
    new Type(new ObjectNoValidationFormat(), new BirthdayMessageTimeMode())
);
Types.set('array.channel.checkbox', new Type(new ArrayFormat(), new ArrayChannelCheckboxMode()));
Types.set('number.taxRate', new Type(new NumberFormat(), new NumberTaxMode()));
export default Types;
