import React, { useEffect } from 'react';
import { Radio, DatePicker, Form, TimePicker, Checkbox, Select } from 'antd';
import moment from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import './index.less';

export enum SchedulerExecuteType {
    /**
     * 自动长期
     */
    AUTO = 'AUTO',
    /**
     * 立即执行
     */
    NOW = 'NOW',
    /**
     * 定时执行
     */
    TIMING = 'TIMING',
}

export enum SchedulerAutoExecuteType {
    /**
     * 每日
     */
    DAY = 'DAY',
    /**
     * 每周
     */
    WEEK = 'WEEK',
    /**
     * 每月
     */
    MONTH = 'MONTH',
}

export interface SchedulerMethodsValue {
    /**
     * 执行类型
     */
    executeType?: SchedulerExecuteType;
    /**
     * 自动执行类型
     */
    autoExecuteType?: SchedulerAutoExecuteType;
    /**
     * 执行计划开始时间
     */
    schedulerStartTime?: string;
    /**
     * 执行计划结束时间
     */
    schedulerEndTime?: string;
    /**
     * 执行时间，小时:分钟 17:05
     */
    schedulerTime?: string;
    /**
     * 每日 *
     */
    schedulerDay?: string;
    /**
     * 每周  1,2,3,4,5,6,7
     */
    schedulerWeekDay?: string;
    /**
     * 每月 1-30
     */
    schedulerDayOfMonth?: string;
    /**
     * 定时执行时间
     */
    schedulerExecuteTime?: string;
}

export interface SchedulerMethodsProps {
    value?: SchedulerMethodsValue;
    name?: string;
    executeTypeDisabled?: boolean;
    onChange?(value: SchedulerMethodsValue, name: string): void;
}

export interface SchedulerMethodsCheckoutProps {
    label?: string;
    value?: SchedulerExecuteType | SchedulerAutoExecuteType;
    executeType?: SchedulerExecuteType | SchedulerAutoExecuteType;
    disabled?: boolean;
    onChange?(value: SchedulerExecuteType | SchedulerAutoExecuteType): void;
}

export interface SchedulerMethodsAutoSettingProps {
    startDate?: string;
    endDate?: string;
    time?: string;
    week?: string;
    month?: string;
    autoExecuteType?: SchedulerAutoExecuteType;
    onDateChange?(startDate: string, endDate: string): void;
    onTimeChange?(value: string): void;
    onDayChange?(value: string): void;
    onWeekChange?(value: string): void;
    onMonthChange?(value: string): void;
}

export interface SchedulerMethodsTimingSettingProps {
    value?: string;
    onChange?(value: string): void;
}

export interface SchedulerMethodsMonthSelectProps {
    value?: string;
    onChange?(value: string): void;
}

export const SCHEDULER_METHODS_DEFAULT_VALUE: SchedulerMethodsValue = {
    executeType: SchedulerExecuteType.AUTO,
    autoExecuteType: SchedulerAutoExecuteType.DAY,
    schedulerDay: undefined,
    schedulerStartTime: undefined,
    schedulerEndTime: undefined,
    schedulerTime: undefined,
    schedulerWeekDay: undefined,
    schedulerDayOfMonth: undefined,
    schedulerExecuteTime: undefined,
};

function createOption(
    label: string,
    value: string
): {
    label: string;
    value: string;
} {
    return { label, value };
}

const WEEK_SELECT_OPTIONS = [
    createOption('周一', '1'),
    createOption('周二', '2'),
    createOption('周三', '3'),
    createOption('周四', '4'),
    createOption('周五', '5'),
    createOption('周六', '6'),
    createOption('周日', '7'),
];

const MONTH_SELECT_OPTIONS = Array.from(new Array(31)).map((_, index) =>
    createOption(`${index + 1}`.padStart(2, '0'), `${index + 1}`)
);

export const SchedulerMethodsCheckout: React.FC<SchedulerMethodsCheckoutProps> = (props) => {
    const {
        label = '',
        value = SchedulerExecuteType.AUTO,
        executeType = SchedulerExecuteType.AUTO,
        disabled,
        onChange,
        children,
    } = props;
    const checked = value === executeType;

    function handleChange(): void {
        !disabled && onChange && onChange(executeType);
    }

    return (
        <div className="scheduler-methods-heckout">
            <div className="scheduler-methods-checkout__label">
                <Radio checked={checked} disabled={disabled} onClick={handleChange} />
                {label}
            </div>
            {checked && <div className="scheduler-methods-checkout__value">{children}</div>}
        </div>
    );
};

export const SchedulerMethodsMonthSelect: React.FC<SchedulerMethodsMonthSelectProps> = (props) => {
    const { value, onChange } = props;
    const [startValue, endValue] = value ? value.split('-') : ['1', '1'];

    function triggerChange(start: string, end: string): void {
        onChange && onChange(`${start}-${end}`);
    }

    function handleStartChange(value: string): void {
        if (+value > +endValue) {
            triggerChange(value, value);
            return;
        }

        triggerChange(value, endValue);
    }

    function handleEndChange(value: string): void {
        if (+value < +startValue) {
            triggerChange(value, value);
            return;
        }

        triggerChange(startValue, value);
    }

    return (
        <div className="scheduler-methods-month-select">
            <Select
                className="scheduler-methods-month-select__select"
                suffixIcon="日"
                value={startValue}
                onChange={handleStartChange}
            >
                {MONTH_SELECT_OPTIONS.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
            <span className="scheduler-methods-month-select__division">至</span>
            <Select
                className="scheduler-methods-month-select__select"
                suffixIcon="日"
                value={endValue}
                onChange={handleEndChange}
            >
                {MONTH_SELECT_OPTIONS.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export const SchedulerMethodsAutoSetting: React.FC<SchedulerMethodsAutoSettingProps> = (props) => {
    const {
        startDate,
        endDate,
        time,
        week,
        month,
        autoExecuteType,
        onDateChange,
        onTimeChange,
        onDayChange,
        onWeekChange,
        onMonthChange,
    } = props;

    const DATE_FORMAT = 'YYYY-MM-DD';
    const TIME_FORMAT = 'HH:mm';
    const dateValue: RangePickerValue | undefined =
        startDate && endDate ? [moment(startDate), moment(endDate)] : undefined;
    const weekValue = week ? week.split(',') : undefined;
    const HHmm = time ? time.split(':') : [];
    const timeValue =
        HHmm.length === 2
            ? moment()
                  .set('h', +HHmm[0])
                  .set('m', +HHmm[1])
            : undefined;

    function handleDateChange(_: RangePickerValue, dateStrings: [string, string]): void {
        onDateChange && onDateChange(...dateStrings);
    }

    function handleTimeChange(_: moment.Moment | null, timeString: string): void {
        onTimeChange && onTimeChange(timeString);
    }

    function handleDayChange(): void {
        onDayChange && onDayChange('*');
    }

    function handleWeekChange(value: CheckboxValueType[]): void {
        if (!value.length) {
            return;
        }

        onWeekChange && onWeekChange(value.join(','));
    }

    function handleMonthChange(month: string): void {
        onMonthChange && onMonthChange(month);
    }

    function disabledDate(current: moment.Moment | null): boolean {
        return !!(
            current &&
            current <
                moment()
                    .endOf('day')
                    .subtract(1, 'day')
        );
    }

    useEffect(handleDayChange, []);

    return (
        <React.Fragment>
            <Form.Item label="执行日期" required>
                <DatePicker.RangePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    value={dateValue}
                    format={DATE_FORMAT}
                    onChange={handleDateChange}
                />
            </Form.Item>
            <SchedulerMethodsCheckout
                label="每日"
                value={autoExecuteType}
                executeType={SchedulerAutoExecuteType.DAY}
                onChange={handleDayChange}
            />
            <SchedulerMethodsCheckout
                label="每周"
                value={autoExecuteType}
                executeType={SchedulerAutoExecuteType.WEEK}
                onChange={() => handleWeekChange(['1'])}
            >
                <Checkbox.Group
                    options={WEEK_SELECT_OPTIONS}
                    value={weekValue}
                    onChange={handleWeekChange}
                />
            </SchedulerMethodsCheckout>
            <SchedulerMethodsCheckout
                label="每月"
                value={autoExecuteType}
                executeType={SchedulerAutoExecuteType.MONTH}
                onChange={() => handleMonthChange('1-1')}
            >
                <SchedulerMethodsMonthSelect value={month} onChange={handleMonthChange} />
            </SchedulerMethodsCheckout>
            <Form.Item label="执行时间" required>
                <TimePicker
                    allowClear={false}
                    value={timeValue}
                    format={TIME_FORMAT}
                    onChange={handleTimeChange}
                />
            </Form.Item>
        </React.Fragment>
    );
};

export const SchedulerMethodsTimingSetting: React.FC<SchedulerMethodsTimingSettingProps> = (
    props
) => {
    const { value, onChange } = props;

    const FORMAT = 'YYYY-MM-DD HH:mm';

    const current = value ? moment(value) : undefined;

    function handleChange(_: moment.Moment | null, dateString: string): void {
        onChange && onChange(dateString);
    }

    function disabledDate(current: moment.Moment | null): boolean {
        return !!(
            current &&
            current <
                moment()
                    .endOf('day')
                    .subtract(1, 'day')
        );
    }

    return (
        <Form.Item label="执行时间" required>
            <DatePicker
                showTime
                showToday
                allowClear={false}
                format={FORMAT}
                value={current}
                disabledDate={disabledDate}
                onChange={handleChange}
            />
        </Form.Item>
    );
};

export const SchedulerMethods: React.FC<SchedulerMethodsProps> = (props) => {
    const { value, executeTypeDisabled, name = '', onChange } = props;

    const current = Object.assign({}, SCHEDULER_METHODS_DEFAULT_VALUE, value);

    function triggerChange(value: SchedulerMethodsValue): void {
        onChange && onChange(Object.assign({}, current, value), name);
    }

    function handleExecuteTypeChange(value: SchedulerExecuteType): void {
        triggerChange({
            executeType: value,
        });
    }

    function handleSchedulerDateChange(startDate: string, endDate: string): void {
        triggerChange({
            schedulerStartTime: startDate,
            schedulerEndTime: endDate,
        });
    }

    function handleSchedulerTimeChange(value: string): void {
        triggerChange({
            schedulerTime: value,
        });
    }

    function handleSchedulerDayChange(value: string): void {
        triggerChange({
            autoExecuteType: SchedulerAutoExecuteType.DAY,
            schedulerDay: value,
        });
    }

    function handleSchedulerWeekDayChange(value: string): void {
        triggerChange({
            autoExecuteType: SchedulerAutoExecuteType.WEEK,
            schedulerWeekDay: value,
        });
    }

    function handleSchedulerDayOfMonthChange(value: string): void {
        triggerChange({
            autoExecuteType: SchedulerAutoExecuteType.MONTH,
            schedulerDayOfMonth: value,
        });
    }

    function handleSchedulerExecuteTimeChange(value: string): void {
        triggerChange({
            schedulerExecuteTime: value,
        });
    }

    return (
        <Form className="scheduler-methods" layout="inline">
            <SchedulerMethodsCheckout
                label="自动长期"
                value={current.executeType}
                executeType={SchedulerExecuteType.AUTO}
                disabled={executeTypeDisabled}
                onChange={handleExecuteTypeChange}
            >
                <SchedulerMethodsAutoSetting
                    startDate={current.schedulerStartTime}
                    endDate={current.schedulerEndTime}
                    time={current.schedulerTime}
                    week={current.schedulerWeekDay}
                    month={current.schedulerDayOfMonth}
                    autoExecuteType={current.autoExecuteType}
                    onDateChange={handleSchedulerDateChange}
                    onTimeChange={handleSchedulerTimeChange}
                    onDayChange={handleSchedulerDayChange}
                    onWeekChange={handleSchedulerWeekDayChange}
                    onMonthChange={handleSchedulerDayOfMonthChange}
                />
            </SchedulerMethodsCheckout>
            <SchedulerMethodsCheckout
                label="立即执行"
                value={current.executeType}
                executeType={SchedulerExecuteType.NOW}
                disabled={executeTypeDisabled}
                onChange={handleExecuteTypeChange}
            />
            <SchedulerMethodsCheckout
                label="定时执行"
                value={current.executeType}
                executeType={SchedulerExecuteType.TIMING}
                disabled={executeTypeDisabled}
                onChange={handleExecuteTypeChange}
            >
                <SchedulerMethodsTimingSetting
                    value={current.schedulerExecuteTime}
                    onChange={handleSchedulerExecuteTimeChange}
                />
            </SchedulerMethodsCheckout>
        </Form>
    );
};
