import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { LinkTypeEffectPathValue } from '../../components/link-type-effect-path';
import { defaults, get } from 'lodash';
import './index.less';
import { subsiteLinkTypeSelectorValidate } from '../../types/format';

export interface MiniprogramV2AddFormProps extends FormComponentProps {
    entity: Entity;
    statisticType: string;
    applicationVersion?: string;
    hideNeedUrlLink?: boolean;
    getForm: () => any;
    onSubmit: (e: any, fields: any) => void;
}

class miniprogramV2AddForm extends PureComponent<MiniprogramV2AddFormProps> {
    static defaultProps: any = {};
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let { statisticType } = this.props;
        if (!statisticType) {
            statisticType = 'CHANNEL';
        }
        let fields = [
            {
                property: 'name',
            },
            {
                property: 'statisticType',
            },
            {
                property: 'subsiteId',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
                visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
            },
            {
                property: 'merchantIds',
                visible: (values: any) => get(values, 'statisticType') === 'MERCHANT',
            },
            {
                property: 'channels',
                controlConfig: {
                    isAdd: true,
                },
            },
            {
                property: 'target',
                controlConfig: {
                    subsitePreRequired: true,
                    subsitePreRequiredMsg: services.language.getText('qxxzmdhzj'),
                    hideNeedUrlLink: statisticType === 'MERCHANT',
                    needUrlLinkExplain: services.language.getText('schyxq'),
                    bussinessType: 'miniprogram-v2',
                    onChange: (value: LinkTypeEffectPathValue, name: string) => {
                        const store = builder.getStore();

                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-MiniprogramV2AddFormContainer',
                                name,
                                value
                            )
                        );
                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-MiniprogramV2AddFormContainer',
                                'url',
                                value.linkPath
                            )
                        );
                        store.dispatch(
                            actions.formStatusChangeAction(
                                'DataFormPlus-MiniprogramV2AddFormContainer',
                                'url',
                                true
                            )
                        );
                    },
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'url',
                controlConfig: {
                    disabled: true,
                },
                className: 'property-hide',
                // visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
            },
            {
                property: 'channel',
                visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
            },
            {
                property: 'channelType',
            },
        ];

        let formProps = {
            entity,
            componentId: 'MiniprogramV2AddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'miniprogram-v2-code-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            onValidate: (entity: any) => {
                const target = get(entity, 'target', {});
                if (
                    !subsiteLinkTypeSelectorValidate(target || {}, {
                        required: true,
                        requiredMsg: services.language.getText('qxzmbldy'),
                    })
                ) {
                    return false;
                }
                return true;
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    let statisticType = get(
        _dispatch,
        'components.DataFormPlus-MiniprogramV2AddFormContainer.fields.statisticType'
    );

    return defaults({
        statisticType,
    });
}
export const MiniprogramV2AddFormView = connect(mapDispatchToProps)(miniprogramV2AddForm);
