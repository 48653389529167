import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { map } from 'lodash';

const { getText } = services.language;

export const config: Config = {
    entities: {
        GuideCommissionEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/distribution_goods_settlement_records',
            paramsFilter: ['SHOPPING_GUIDE'],
            filters: {
                memberName: {
                    type: 'string',
                    displayName: '<<dgnc>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<dgsjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<jszt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<djs_1>>' },
                        { id: 'COMPLETED', name: '<<alreadyCheckout>>' },
                        { id: 'CANCELLED', name: '<<ycx>>' },
                    ],
                },
                actualSettlementDateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<jssj>>',
                },
                clearingStatus: {
                    type: 'string.options.select',
                    displayName: '<<dzzt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<wdz>>' },
                        { id: 'SUCCESS', name: '<<ydz_1>>' },
                        { id: 'FAIL', name: '<<dzyc>>' },
                    ],
                },
                clearingDateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<dzsj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<dgymc>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<sp>>',
                },
                goodsAfterFoldingPrice: {
                    type: 'string',
                    displayName: '<<cjje_1>>',
                },
                commissionAmount: {
                    type: 'string',
                    displayName: '<<yjsy>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<jszt>>',
                    options: [
                        { id: 'WAITING_ORDER_RECEIVE', name: '<<dqs>>' },
                        { id: 'ORDER_AFTER_SALE', name: '<<shz>>' },
                        { id: 'WAITING', name: '<<djs_1>>' },
                        { id: 'COMPLETED', name: '<<alreadyCheckout>>' },
                        { id: 'CANCELLED', name: '<<ycx>>' },
                    ],
                },
                clearingStatus: {
                    type: 'string.options.select',
                    displayName: '<<dzzt>>',
                    options: [
                        { id: 'WAITING', name: '<<wdz>>' },
                        { id: 'SUCCESS', name: '<<ydz_1>>' },
                        { id: 'PROCESSING', name: '<<clz>>' },
                        { id: 'FAIL', name: '<<dzyc>>' },
                        { id: 'NONE', name: '-' },
                    ],
                },
                orderCreateTime: {
                    type: 'string.date',
                    displayName: '<<xdsj>>',
                },
                orderReceivedTime: {
                    type: 'string.date',
                    displayName: '<<qssj>>',
                },
                actualSettlementTime: {
                    type: 'string.date',
                    displayName: '<<jssj>>',
                },
                clearingTime: {
                    type: 'string.date',
                    displayName: '<<dzsj>>',
                },
            },
        },
        GuideDistributionActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/distribution_activities',
            paramsFilter: ['SHOPPING_GUIDE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'UN_START', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                    controlConfig: {
                        placeholder: '<<activityNameRequired>>',
                        maxLength: 20,
                        style: {
                            width: 450,
                        },
                    },
                },
                nameStr: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<acitivityTime>>',
                    rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        range: {
                            start: new Date(),
                        },
                    },
                },
                selectedSubsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    defaultValue: [],
                    rules: [{ required: true, message: '<<selectSubsite>>' }],
                },
                goodsDistributionInfoList: {
                    type: 'array.DistributionProducts',
                    displayName: '<<activityProduct>>',
                    rules: [
                        { required: true, message: '<<components.Tools.productSelectRequired>>' },
                    ],
                    controlConfig: {
                        getParams(props: any) {
                            const { row } = props;
                            const subsiteIds = row.selectedSubsites
                                ? map(row.selectedSubsites, 'id')
                                : null;
                            return { subsiteIds };
                        },
                        memberIdentity: 'SHOPPING_GUIDE',
                    },
                },
            },
        },
        SettlementConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath:
                '/admin/distribution_commission_clearing_rules/member_identities/SHOPPING_GUIDE',
            properties: {
                settlementDelayDays: {
                    type: 'number.tip.groupBuying',
                    displayName: '<<yjjssj>>',
                    name: JSON.stringify({
                        fill: '<<tzdjsyj>>',
                        empty: '<<wszyjjssj>>',
                    }),
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator(
                                rule: any,
                                value: number,
                                callback: (message?: string) => void
                            ) {
                                if (!value) {
                                    callback();
                                } else {
                                    const v = value + '';

                                    if (!/^\+?[1-9]\d*$/.test(v) && v !== '0') {
                                        callback(getText('qsrdydy'));
                                    } else if (value > 9999) {
                                        callback(getText('zdzcsz999t'));
                                    } else {
                                        callback();
                                    }
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                        min: 0,
                        max: 9999,
                        placeholder: '<<inputPlease>>',
                        addonbefore: '<<qsh_1>>',
                        addonafter: '<<zdjsyj>>',
                        tip: '<<wzshdndspjsyj>>',
                    },
                },
                clearingDay: {
                    type: 'number.tip.groupBuying',
                    displayName: '<<yjdzsj>>',
                    name: JSON.stringify({
                        fill: '<<rkffsyjsdyj>>',
                        empty: '<<wszyjtxsj>>',
                    }),
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator(
                                rule: any,
                                value: number,
                                callback: (message?: string) => void
                            ) {
                                if (!value) {
                                    callback();
                                } else {
                                    const v = value + '';

                                    if (!/^\+?[1-9]\d*$/.test(v)) {
                                        callback(getText('inputThanZero'));
                                    } else if (value > 28) {
                                        callback(getText('zdzcsz28r'));
                                    } else {
                                        callback();
                                    }
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                        min: 1,
                        max: 28,
                        placeholder: '<<inputPlease>>',
                        addonbefore: '<<everyMonth>>',
                        addonafter: '<<clearingDaybefore>>',
                        tip: '<<rffsyzryjsdqbyj>>',
                    },
                },
            },
        },
    },
    components: {
        GuideDistributionView: {
            component: 'Viewport',
        },
        GuideDistributionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'GuideDistributionTabs' }],
        },
        GuideDistributionTabs: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<szfxgz>>',
                    content: {
                        component: 'SettlementConfigView',
                    },
                },
                {
                    title: '<<glfxhd>>',
                    content: {
                        component: 'GuideDistributionActivityPage',
                    },
                },
                {
                    title: '<<glyj>>',
                    content: {
                        component: 'GuideCommissionPage',
                    },
                },
            ],
        },

        SettlementConfigView: {
            component: 'Card',
            entity: 'SettlementConfig',
            loaderType: 'get',
            content: {
                component: 'SettlementConfigForm',
            },
        },
        SettlementConfigForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'settlementDelayDays' },
                {
                    property: 'clearingDay',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'));
            },
        },

        GuideDistributionActivityPage: {
            entity: 'GuideDistributionActivityEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GuideDistributionActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/guide-distribution/activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'GuideDistributionActivityTable' },
            ],
        },
        GuideDistributionActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        GuideDistributionActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'nameStr',
                    width: 180,
                },
                {
                    property: 'startTime',
                    width: 120,
                },
                {
                    property: 'endTime',
                    width: 120,
                },
                {
                    property: 'subsites',
                    width: 150,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'status',
                    width: 80,
                    displayConfig: {
                        statusConfig: {
                            UN_START: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 180,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/distribution_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/distribution_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/guide-distribution/activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/menu-list/market/guide-distribution/activity/info/{{row.id}}',
                    },
                ],
            },
        },
        GuideDistributionActivityAddPage: {
            component: 'FlexLayout',
            entity: 'GuideDistributionActivityEntity',
            direction: 'horizontal',
            items: [{ component: 'GuideDistributionActivityAddFormView' }],
        },
        GuideDistributionActivityAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'name' },
                { property: 'dateRange' },
                { property: 'selectedSubsites' },
                { property: 'goodsDistributionInfoList' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (
                    entity.goodsDistributionInfoList &&
                    entity.goodsDistributionInfoList.length > 0
                ) {
                    entity.goodsDistributionInfoList.forEach((item: any) => {
                        if (item.commissionInfos && item.commissionInfos.length > 0) {
                            item.commissionInfos.forEach((info: any) => {
                                if (!info.percentage) flag = false;
                            });
                        } else {
                            flag = false;
                        }
                    });
                } else {
                    flag = false;
                }
                if (!flag) {
                    message.error(getText('qwztxfybl'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success('活动新增成功', () => services.behaviorHandle({ route: 'goBack' }));
            },
        },
        GuideDistributionActivityEditPage: {
            component: 'FlexLayout',
            entity: 'GuideDistributionActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'GuideDistributionActivityEditFormView' }],
        },
        GuideDistributionActivityEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'name' },
                {
                    property: 'dateRange',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'selectedSubsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'goodsDistributionInfoList',
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (
                    entity.goodsDistributionInfoList &&
                    entity.goodsDistributionInfoList.length > 0
                ) {
                    entity.goodsDistributionInfoList.forEach((item: any) => {
                        if (item.commissionInfos && item.commissionInfos.length > 0) {
                            item.commissionInfos.forEach((info: any) => {
                                if (!info.percentage) flag = false;
                            });
                        } else {
                            flag = false;
                        }
                    });
                } else {
                    flag = false;
                }
                if (!flag) {
                    message.error(getText('qwztxfybl'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        GuideDistributionActivityInfoView: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'GuideDistributionActivityEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'Detail',
                    labelCol: 6,
                    controlCol: 18,
                    style: { width: 720 },
                    fields: [
                        { property: 'name' },
                        {
                            property: 'dateRange',
                        },
                        {
                            property: 'selectedSubsites',
                        },
                        {
                            property: 'goodsDistributionInfoList',
                        },
                    ],
                    footer: {
                        items: [
                            {
                                htmlType: 'button',
                                route: 'goBack',
                                type: 'primary',
                                text: '<<common.cancel>>',
                            },
                        ],
                    },
                },
            ],
        },

        GuideCommissionPage: {
            entity: 'GuideCommissionEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GuideCommissionFilter',
                },
                { component: 'GuideCommissionTable' },
            ],
        },
        GuideCommissionFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            className: 'mb24',
            fields: [
                {
                    property: 'memberName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'memberMobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'actualSettlementDateRange',
                    controlConfig: {
                        style: { width: 400 },
                    },
                },
                {
                    property: 'clearingStatus',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'clearingDateRange',
                    controlConfig: {
                        style: { width: 400 },
                    },
                },
            ],
        },
        GuideCommissionTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'orderNo',
                    width: 180,
                },
                {
                    property: 'memberName',
                    width: 150,
                },
                {
                    property: 'memberMobile',
                    width: 200,
                },
                {
                    property: 'goodsName',
                    width: 200,
                },
                {
                    property: 'goodsAfterFoldingPrice',
                    width: 150,
                },
                {
                    property: 'commissionAmount',
                    width: 150,
                },
                {
                    property: 'status',
                    width: 100,
                },
                {
                    property: 'clearingStatus',
                    width: 100,
                },
                {
                    property: 'orderCreateTime',
                    width: 200,
                },
                {
                    property: 'orderReceivedTime',
                    width: 200,
                },
                {
                    property: 'actualSettlementTime',
                    width: 200,
                },
                {
                    property: 'clearingTime',
                    width: 200,
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/guide-distribution',
                    component: 'GuideDistributionView',
                    breadcrumbName: '<<dgfx>>',
                    privilege: {
                        path: 'guideDistributionManager',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/activity/add',
                            component: 'GuideDistributionActivityAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/activity/edit/:id',
                            component: 'GuideDistributionActivityEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/activity/info/:id',
                            component: 'GuideDistributionActivityInfoView',
                            breadcrumbName: '<<ckhd>>',
                        },

                        { path: '/', component: 'GuideDistributionPage' },
                    ],
                },
            ],
        },
    ],
};
