import React, { PureComponent } from 'react';
import { Form, Radio } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
/**
 * 王府井首页会员卡组件-配置表单
 */
class indexMemberCard extends PureComponent<any> {
    render() {
        const { form, sourceRoot, uploadAction } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;

        return (
            <Form className="editor-member-card">
                <FormItem {...formItemLayout} label={services.language.getText('bgPic')}>
                    {getFieldDecorator('bgImage')(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                    <p className="upload-picture-tips">图片建议宽度为750px，高度为130px</p>
                </FormItem>
                <FormItem {...formItemLayout} label={'会员等级'}>
                    {getFieldDecorator('level', { initialValue: 'show' })(
                        <Radio.Group>
                            <Radio value={'show'}>{services.language.getText('display')}</Radio>
                            <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
            </Form>
        );
    }
}

export const IndexMemberCard = tools.ConfigFormDecorator(indexMemberCard);
