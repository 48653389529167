import React, { Component } from 'react';
import { Input, Select, Button, message } from 'antd';
import { clone, isEqual, map, concat, isEmpty, isArray } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

type RulesValue = {
    /**
     * 会员等级id
     */
    id?: string;
    /**
     * 消费金额
     */
    limitCount?: number;
    /**
     * 卡名称
     */
    name?: string;
    /**
     * 可抵用停车费
     */
    deductionAmount?: number;
};

export interface CreditShopMemberLimitProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 候选项集合
     */
    options: Array<any>;
    typeTitle?: string;
    defaultValue?: string;
}
export class CreditShopMemberLimit extends Component<CreditShopMemberLimitProps> {
    onMemberLevelIdChange(id: string, index: number) {
        let { name, value, onChange, options } = this.props;
        const selectedItem = options.find((i) => i.id === id);
        const cardName = selectedItem ? selectedItem.name : '';
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.id = id;
            item.name = cardName;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(id)) {
                message.warning('请选择会员等级');
                return;
            }
            let item: RulesValue = {};
            item.id = id;
            item.name = cardName;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onAmountChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        let limitCount = event.target.value;
        if (!isEmpty(limitCount) && limitCount < 0) {
            message.warning('请填写不小于0的金额');
            return;
        }
        if (isNaN(Number(limitCount))) {
            return;
        }
        if (limitCount && limitCount > 999999) {
            message.warning('金额最大值为999999');
            return;
        }
        if (/\s/.test(limitCount)) {
            return;
        }
        let amountArr = limitCount.split('.');
        if (amountArr.length > 1) {
            amountArr = amountArr.slice(0, 2);
            if (amountArr[1].length >= 2) {
                amountArr[1] = amountArr[1].substr(0, 2);
                limitCount = amountArr.join('.');
            }
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.limitCount = Number(limitCount);
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(limitCount)) {
                message.warning('请输入用户消费金额');
                return;
            }
            let item: RulesValue = {};
            item.limitCount = Number(limitCount);
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = [{}];
        }
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: CreditShopMemberLimitProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, typeTitle } = this.props;

        return (
            <div className="member-level-park-benefit">
                {typeTitle ? <div>{typeTitle}</div> : ''}
                {map(value, (rule, index) => this.renderRule(rule, index))}
                {value && value.length ? null : this.renderRule({}, 0)}
                <Button type="default" className="add-rule-button" onClick={() => this.onAddRule()}>
                    添加会员等级
                </Button>
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number) {
        const { options, defaultValue } = this.props;
        const selectOption =
            isArray(options) && options.filter((option) => Number(option.id) === Number(rule.id));
        const ruleLevelName = selectOption && selectOption.length > 0 && selectOption[0].name;

        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text">会员卡等级：</span>
                    <Select
                        className="rule-type-selection"
                        placeholder={services.language.getText('selectPlease')}
                        value={ruleLevelName || defaultValue}
                        onChange={(id: any) => this.onMemberLevelIdChange(id, index)}
                    >
                        {isArray(options) &&
                            map(options, ({ id, name }) => (
                                <Select.Option key={id}>{name}</Select.Option>
                            ))}
                    </Select>
                    <span className="item-text">最多兑换</span>
                    <div className="rule-count-input">
                        <Input
                            value={rule.limitCount}
                            onChange={(e) => this.onAmountChange(e, index)}
                        />
                    </div>
                    <span className="item-text">件</span>

                    <Button
                        type="default"
                        className="remove-rule-button"
                        onClick={() => this.onRemoveRule(index)}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                </div>
            </div>
        );
    }
}
