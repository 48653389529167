import React, { PureComponent } from 'react';
import './index.less';
import { services } from '@comall-backend-builder/core';
/**
 * 王府井可视化组件选择器
 */
export class WfjIndexComponentSelector extends PureComponent<{ onSelect: (value: any) => void }> {
    wfjComponents = [
        { id: 'wfjIndexSubsite', name: '门店信息' },
        { id: 'hotArea', name: '热区图片' },
        { id: 'indexMemberCard', name: '首页会员卡片1' },
        { id: 'homeMemberCard', name: '首页会员卡片2' },
        { id: 'cardNav', name: '卡片导航' },
        {
            id: 'imageAd',
            name: '图片广告',
        },
        {
            id: 'nav',
            name: '导航',
        },
        {
            id: 'showcase',
            name: '橱窗',
        },
        {
            id: 'title',
            name: '标题',
        },
        {
            id: 'text',
            name: '文本',
        },
        {
            id: 'divider',
            name: services.language.getText('fzx'),
        },
        {
            id: 'space',
            name: '辅助空白',
        },
        {
            id: 'noticeBar',
            name: '通知栏',
        },
        {
            id: 'virtualProducts',
            name: '虚拟商品',
        },
        {
            id: 'memberAssets',
            name: '我的资产',
        },
        {
            id: 'carousel',
            name: '轮播图',
        },
    ];

    marketingComponents = [{ id: 'coupon', name: '优惠券' }];

    othersComponents = [{ id: 'weixin', name: '关注公众号' }];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        const marketing = this.marketingComponents;
        const others = this.othersComponents;

        return (
            <div className="custom-component-selector">
                <div className="group">
                    <div className="group-content">
                        {this.wfjComponents.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">营销组件</div>
                    <div className="group-content">
                        {marketing.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">其他组件</div>
                    <div className="group-content">
                        {others.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
