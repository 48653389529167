import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, map, defaults, get, forEach, debounce } from 'lodash';
import { connect } from 'react-redux';
import {} from 'lodash';
import { FormComponentProps } from 'antd/lib/form';

export interface CGBManageAddFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}
class CGBManageAddForm extends PureComponent<CGBManageAddFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, wrappedComponentRef, onSubmit } = this.props;
        let formProps = {
            entity,
            componentId: 'CGBManageAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'baseInfo.status' },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                {
                    property: 'rulesInfo.rules',
                },
                {
                    property: 'products.goods',
                    className: 'form-item-products',
                },
                {
                    property: 'advertisement.activityPicture',
                    extra: services.language.getText('jhzhdtfydbxs'),
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('tghdxzcg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    const isEmpty = (value: any) => value === null || value === undefined;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        let goods: any = get(entityFields, 'products.goods', null);
        if (!goods || !goods.length) {
            AntMessage.warning(services.language.getText('qxzhdsp'));
            return false;
        } else {
            let hasValidate = true;
            forEach(goods, (i) => {
                if (isEmpty(i.groupPrice)) {
                    AntMessage.warning(i.name + services.language.getText('wpztzghj'));
                    hasValidate = false;
                    return false;
                }
                if (isEmpty(i.groupBuyPrice)) {
                    AntMessage.warning(i.name + services.language.getText('wpztymjj'));
                    hasValidate = false;
                    return false;
                }
                if (isEmpty(i.groupStock)) {
                    AntMessage.warning(i.name + services.language.getText('wpzsqtgkc'));
                    hasValidate = false;
                    return false;
                }
            });
            if (!hasValidate) {
                return false;
            }
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                let submit = true;
                const entityFields = mapValues(fields, (field, name) => {
                    if (name === 'rulesInfo') {
                        let rules = field.value.rules;
                        map(rules, (rule) => {
                            if (isEmpty(rule.ruleType) || isEmpty(rule.ruleCount)) {
                                submit = false;
                                AntMessage.warning(services.language.getText('qwsxggz'));
                                return;
                            }
                        });
                    }
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate && submit) {
                        entity.add(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('CGBManageAddFormContainer'));
            },
        }
    );
}
export const CGBManageAddFormView = connect(mapDispatchToProps)(CGBManageAddForm);
