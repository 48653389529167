import { message } from 'antd';
/**
 * 错误处理器
 */
export interface ErrorInfo {
    response: Response;
    status: string;
}
interface Response {
    body: BodyNode;
}
interface BodyNode {
    [x: string]: string;
}
interface Response {
    body: BodyNode;
}
const ERROR_HANDLE = (error: ErrorInfo) => {
    console.log('error:', error);
    let { response, status } = error;
    let msg = '请求已被终止。';
    if (status) {
        if (response && response.body) {
            msg = response.body.err_msg || response.body.message || response.body.error;
        }
        if (!msg) {
            msg = '请求失败。';
        }
    }
    message.error(msg);
};
/**
 * 处理接口调用失败的错误信息
 * @param error
 */
export const errorHandle = (error: ErrorInfo) => {
    let errorHandle = ERROR_HANDLE;
    errorHandle(error);
};
