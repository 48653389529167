import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { ForwardDataCenterModal } from '../../../services';
import { api } from '@comall-backend-builder/core/lib/services';
import { EntityButtonProps } from '../../../components';

/**
 * 认证方式
 */
export enum CertificationPattern {
    //邮箱
    EMAIL = 'EMAIL',
    //手机号
    MOBILE = 'MOBILE',
}

export const config: Config = {
    entities: {
        EnterpriseEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/enterprises',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<qymc_1>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrqymc>>',
                        maxLength: 15,
                    },
                },
                certificationPattern: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<rzfs>>',
                    rules: [{ required: true }],
                    options: [
                        { id: CertificationPattern.EMAIL, name: '<<yxhz>>' },
                        { id: CertificationPattern.MOBILE, name: '<<phoneNum>>' },
                    ],
                    defaultValue: [CertificationPattern.EMAIL],
                    extra: '<<rxzsjhwrzfs>>',
                },
                certificationMobileNum: {
                    type: 'string',
                    displayName: '<<drrs>>',
                },

                mailboxSuffixes: {
                    type: 'array.mailboxSuffix',
                    displayName: '<<yxhz>>',
                    rules: [{ required: true }],
                    extra: '<<yyqyyhjsyzm>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<tjsj_1>>',
                },
                employeeCountLinkObj: {
                    type: 'object.link',
                    displayName: '<<yrzrs>>',
                },
                certificationInterval: {
                    type: 'number.tip',
                    displayName: '<<sfyzsj>>',
                    controlConfig: {
                        addonafter: '<<day>>',
                        style: { width: 300 },
                    },
                    extra: '<<btxzswgqyyhzqyx>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<hzzt>>',
                },
            },
        },
        EnterpriseEmployeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/enterprises',
            paramsFilter: ['employees'],
            properties: {
                id: {
                    type: 'string',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                enterpriseName: {
                    type: 'string',
                    displayName: '<<qymc_1>>',
                },
                certificationPattern: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<rzfs>>',
                    options: [
                        { id: CertificationPattern.EMAIL, name: '<<email>>' },
                        { id: CertificationPattern.MOBILE, name: '<<phoneNum>>' },
                    ],
                },
                email: {
                    type: 'string',
                    displayName: '<<rzyx>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<sfzt>>',
                },
                lastCertificationTime: {
                    type: 'string',
                    displayName: '<<rzsj>>',
                },
                certificationMobile: {
                    type: 'string',
                    displayName: '<<rzsjh>>',
                },
            },
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                certificationMobile: {
                    type: 'string',
                    displayName: '<<rzsjh>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<rzyx>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<sfzt>>',
                    options: [
                        { id: 'true', name: '<<zc_1>>' },
                        { id: 'false', name: '<<sx_3>>' },
                    ],
                },
            },
        },
        EnterpriseRelationMobileEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/enterprises/relation_mobile',
            properties: {
                id: {
                    type: 'string',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                mobiles: {
                    type: 'string.text.paragraph',
                    displayName: '<<tj>>',
                    controlConfig: {
                        rows: 8,
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<qsrsjh>>',
                        },
                    ],
                    extra: '<<rtjdgsjhyyw>>',
                },
            },
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
            },
        },
        InternalPurchaseActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/internal_purchase_activities',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                enterprises: {
                    type: 'array.popover',
                    displayName: '<<hzqy>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'object.option.select',
                    displayName: '<<subsites>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'NOT_STARTED',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                urlZipUrl: {
                    type: 'string',
                    displayName: '<<hdewm>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            controlConfig: {
                                placeholder: '<<qsrhdmc>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            extra: '<<hdmcbkzf>>',
                        },
                        picture: {
                            displayName: '<<hdtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit250>>',
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                format: 'YYYY-MM-DD HH:mm',
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'string.options.select',
                            className: 'custom-array-options',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        enterpriseIds: {
                            type: 'array.options.autoComplete',
                            className: 'custom-array-options',
                            displayName: '<<hzqy>>',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                apiPath: '/loader/admin/enterprises',
                                paramsFilter: ['status'],
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
        },
        InternalPurchaseGoodsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/internal_purchase_goods',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
                brandName: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },

                goodsCode: {
                    type: 'string',
                    displayName: '<<goodCode>>',
                },
                price: {
                    type: 'number.price',
                    displayName: '<<ngj>>',
                    rules: [
                        {
                            required: true,
                            message: '<<qszngj>>',
                        },
                    ],
                },
                salesPrice: {
                    type: 'number.price',
                    displayName: '<<xsj>>',
                },
                tags: {
                    type: 'array.popover',
                    displayName: '<<bq>>',
                },
                memberLimit: {
                    type: 'number.integer',
                    displayName: '<<grxgs>>',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                actualStock: {
                    type: 'number.integer',
                    displayName: '<<sjkc>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                tagIds: {
                    type: 'array.options.autoComplete',
                    className: 'custom-array-options',
                    displayName: '<<bq>>',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/loader/admin/internal_purchase_goods',
                        paramsFilter: ['tags'],
                    },
                },
                discount: {
                    type: 'number.tip',
                    displayName: '<<szngjzk>>',
                    extra: '<<sswrqxsdhlw>>',
                    controlConfig: {
                        addonafter: '<<z_8>>',
                        style: { width: 150 },
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<qszngjzk>>',
                        },
                    ],
                },
                shelfStatus: {
                    type: 'string.options.select',
                    displayName: '<<sxj>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<alreadyShelf>>' },
                        { id: 'false', name: '<<alreadyOffShelf>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                keywords: {
                    type: 'string',
                    displayName: '<<keywordsSearch>>',
                    controlConfig: {
                        placeholder: '<<keywordsPlaceholder>>',
                        style: { width: 300 },
                    },
                },

                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    displayName: '<<merchantName>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
            },
        },
        InternalPurchaseGoodsImportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/internal_purchase_activities',
            paramsFilter: ['goodsImportResult'],
            properties: {
                id: {
                    type: 'string',
                },
                fileName: {
                    type: 'string',
                    displayName: '<<fileName>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<createTimeStr>>',
                },
                operateTime: {
                    type: 'string',
                    displayName: '<<clsj>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<processStatus>>',
                    defaultValue: 'UNHANDLE',
                    options: [
                        {
                            id: 'UNHANDLE',
                            name: '<<processing>>',
                        },
                        {
                            id: 'HANDLE',
                            name: '<<processing>>',
                        },
                        {
                            id: 'FAILURE',
                            name: '<<clsb>>',
                        },
                        {
                            id: 'PARTIAL_FAILURE',
                            name: '<<bfsb>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<processFinished>>',
                        },
                    ],
                },
            },
        },
        InternalPurchaseRelationMobileImportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/enterprises/relation_mobile',
            paramsFilter: ['relationMobileImportResult'],
            properties: {
                id: {
                    type: 'string',
                },
                fileName: {
                    type: 'string',
                    displayName: '<<fileName>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<createTimeStr>>',
                },
                operateTime: {
                    type: 'string',
                    displayName: '<<clsj>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<processStatus>>',
                    defaultValue: 'UNHANDLE',
                    options: [
                        {
                            id: 'UNHANDLE',
                            name: '<<processing>>',
                        },
                        {
                            id: 'HANDLE',
                            name: '<<processing>>',
                        },
                        {
                            id: 'FAILURE',
                            name: '<<clsb>>',
                        },
                        {
                            id: 'PARTIAL_FAILURE',
                            name: '<<bfsb>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<processFinished>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        InternalPurchaseView: {
            component: 'Viewport',
        },
        InternalPurchasePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'InternalPurchaseTabs' }],
        },
        InternalPurchaseTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<hdlb>>',
                    content: {
                        component: 'InternalPurchaseActivityManagePage',
                    },
                },
                {
                    title: '<<hzqy>>',
                    content: {
                        component: 'EnterpriseManagePage',
                    },
                },
            ],
        },
        InternalPurchaseActivityManagePage: {
            entity: 'InternalPurchaseActivityEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'InternalPurchaseActivityManageFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/internal-purchase/activities/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'InternalPurchaseActivityManageTable' },
            ],
        },
        InternalPurchaseActivityManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        InternalPurchaseActivityManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'enterprises',
                },
                {
                    property: 'subsites',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'true',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/internal_purchase_activities/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: 'false',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/internal_purchase_activities/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: 'COMPLETE_OPEN',
                                    config: {
                                        text: '<<common.close>>',
                                        disabled: true,
                                    },
                                },
                                {
                                    value: 'COMPLETE_CLOSE',
                                    config: {
                                        text: '<<common.opend>>',
                                        disabled: true,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/internal-purchase/activities/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<glsp>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/internal-purchase/activities/{{row.id}}/goods',
                    },
                    {
                        type: 'component',
                        component: 'qrcodeRuleDownloadButton',
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/internal_purchase_activities/{{row.id}}.xls',
                            text: '<<xzbb>>',
                        },
                    },
                ],
            },
        },
        ActivityAddPage: {
            component: 'Card',
            content: { component: 'ActivityAddForm' },
        },
        ActivityAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'InternalPurchaseActivityEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.picture',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.subsiteIds',
                },
                {
                    property: 'baseInfo.enterpriseIds',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: (fields: any, res: any) => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ActivityEditPage: {
            component: 'Card',
            content: { component: 'ActivityEditForm' },
        },
        ActivityEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'InternalPurchaseActivityEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.picture',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.subsiteIds',
                },
                {
                    property: 'baseInfo.enterpriseIds',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ActivityGoodsManagePage: {
            entity: 'InternalPurchaseGoodsEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'ActivityGoodsManageFilter' },
                {
                    component: 'InternalPurchaseGoodsManageButton',
                },
                { component: 'ActivityGoodsManageTable' },
            ],
        },
        ActivityGoodsManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'code',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'merchantName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        ActivityGoodsManageTable: { component: 'ActivityGoodsManageTableView' },
        EnterpriseManagePage: {
            entity: 'EnterpriseEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    style: {
                        marginTop: 5,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzhzqy>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/internal-purchase/enterprise/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'EnterpriseManageTable' },
            ],
        },
        EnterpriseManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: false,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'certificationPattern',
                },
                {
                    property: 'certificationMobileNum',
                },
                {
                    property: 'mailboxSuffixes',
                },
                // {
                //     property: 'createTime',
                // },
                {
                    property: 'employeeCountLinkObj',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: {
                            true: {
                                text: '<<enable>>',
                                status: 'success',
                            },
                            false: {
                                text: '<<disabled>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'true',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/enterprises/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<yjy>>',
                                        text: '<<disabled>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isDisabled>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: 'false',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/enterprises/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<enabled>>',
                                        text: '<<enable>>',
                                    },
                                    confirm: {
                                        text: '<<isEnable>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/internal-purchase/enterprise/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<glsjh>>',
                        statusKey: 'row.canManageRelationMobile',
                        path:
                            '/menu-list/market/internal-purchase/enterprise/{{row.id}}/relation-mobile',
                    },
                ],
            },
        },
        EnterpriseAddPage: {
            component: 'Card',
            content: { component: 'EnterpriseAddForm' },
        },
        EnterpriseAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'EnterpriseEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                { property: 'name' },
                {
                    property: 'certificationPattern',
                },
                {
                    property: 'mailboxSuffixes',
                    visible: (values: any) => {
                        const certificationPattern = get(values, 'certificationPattern');
                        return includes(certificationPattern, CertificationPattern.EMAIL);
                    },
                    rules: [
                        {
                            async validator(_: unknown, values: any, callback: () => void) {
                                if (!values?.length) {
                                    throw new Error(services.language.getText('yxhzbnwk'));
                                }
                                if (values.some(isEmpty)) {
                                    throw new Error(services.language.getText('yxhzbnwk'));
                                }
                                const reg = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
                                if (!values.every(reg.test.bind(reg))) {
                                    throw new Error(services.language.getText('yxgscw'));
                                }
                            },
                        },
                    ],
                },
                { property: 'certificationInterval' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                let certificationInterval = entity.certificationInterval;
                let numberReg = /^([1-9]\d*)$/;
                if (
                    (certificationInterval !== null &&
                        certificationInterval !== undefined &&
                        !numberReg.test(certificationInterval)) ||
                    certificationInterval === 0
                ) {
                    AntMessage.warning(services.language.getText('sfyzsjqsrzzs'));
                    flag = false;
                }

                if (!flag) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        EnterpriseEditPage: {
            component: 'Card',
            content: { component: 'EnterpriseEditForm' },
        },
        EnterpriseEditForm: {
            component: 'ModifyFormPlus',
            componentId: 'EnterpriseEditForm',
            direction: 'horizontal',
            entity: 'EnterpriseEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                { property: 'name' },
                {
                    property: 'certificationPattern',
                },
                {
                    property: 'mailboxSuffixes',
                    visible: (values: any) => {
                        const certificationPattern = get(values, 'certificationPattern');
                        return includes(certificationPattern, CertificationPattern.EMAIL);
                    },
                    rules: [
                        {
                            async validator(_: unknown, values: any, callback: () => void) {
                                if (!values?.length) {
                                    throw new Error(services.language.getText('yxhzbnwk'));
                                }
                                if (values.some(isEmpty)) {
                                    throw new Error(services.language.getText('yxhzbnwk'));
                                }
                                const reg = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
                                if (!values.every(reg.test.bind(reg))) {
                                    throw new Error(services.language.getText('yxgscw'));
                                }
                            },
                        },
                    ],
                },
                { property: 'certificationInterval' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                let certificationInterval = entity.certificationInterval;
                let numberReg = /^([1-9]\d*)$/;
                if (
                    (certificationInterval !== null &&
                        certificationInterval !== undefined &&
                        !numberReg.test(certificationInterval)) ||
                    certificationInterval === 0
                ) {
                    AntMessage.warning(services.language.getText('sfyzsjqsrzzs'));
                    flag = false;
                }

                if (!flag) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        EnterpriseRelationMobileManagePage: {
            entity: 'EnterpriseRelationMobileEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'EnterpriseRelationMobileManageFilter' },
                {
                    component: 'EnterpriseRelationMobileManageButton',
                },
                { component: 'EnterpriseRelationMobileManageTable' },
            ],
        },
        EnterpriseRelationMobileManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        EnterpriseRelationMobileManageTable: {
            component: 'RelationMobleManageTableView',
        },
        EnterpriseEmployeManagePage: {
            entity: 'EnterpriseEmployeEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'EnterpriseEmployeManageFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<exportSearchResult>>',
                                        onClick: async (
                                            _: any,
                                            entityButtonProps: EntityButtonProps
                                        ): Promise<void> => {
                                            try {
                                                const params: Record<string, any> = Object.assign(
                                                    {},
                                                    entityButtonProps.entity.params,
                                                    entityButtonProps.entity.filters
                                                );
                                                const id = params.id;
                                                delete params.id;
                                                const config = {
                                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                                    apiPath: `/admin/enterprises/${id}/export_employees`,
                                                };

                                                await api.post(params, config);
                                                ForwardDataCenterModal();
                                            } catch (err) {
                                                services.errorHandle(err as any);
                                            }
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'EnterpriseEmployeManageTable' },
            ],
        },
        EnterpriseEmployeManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'certificationMobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'email',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        EnterpriseEmployeManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },

            columns: [
                {
                    property: 'enterpriseName',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'certificationPattern',
                },
                {
                    property: 'email',
                },
                {
                    property: 'certificationMobile',
                },
                {
                    property: 'lastCertificationTime',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: {
                            true: {
                                text: '<<zc_1>>',
                                status: 'success',
                            },
                            false: {
                                text: '<<sx_3>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
        },

        InternalPurchaseGoodsImportPage: {
            entity: 'InternalPurchaseGoodsImportEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'InternalPurchaseGoodsImportTable' }],
        },
        InternalPurchaseGoodsImportTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: false,
            columns: [
                {
                    property: 'fileName',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'operateTime',
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/internal_purchase_goods_files/{{row.id}}/export',
                            text: '<<downloadData>>',
                        },
                    },
                ],
            },
        },
        InternalPurchaseRelationMobileImportPage: {
            entity: 'InternalPurchaseRelationMobileImportEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'InternalPurchaseRelationMobileImportTable' }],
        },
        InternalPurchaseRelationMobileImportTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: false,
            columns: [
                {
                    property: 'fileName',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'operateTime',
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/enterprises/relation_mobile/file/{{row.id}}/export',
                            text: '<<downloadData>>',
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/internal-purchase',
                    component: 'InternalPurchaseView',
                    breadcrumbName: '<<internalPurChase>>',
                    privilege: {
                        path: 'enterpriseInternalPurchase',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/enterprise/add',
                            component: 'EnterpriseAddPage',
                            breadcrumbName: '<<xzhzqy>>',
                        },
                        {
                            path: '/enterprise/edit/:id',
                            component: 'EnterpriseEditPage',
                            breadcrumbName: '<<bjhzqy>>',
                        },
                        {
                            path: '/enterprise/:id/employees',
                            component: 'EnterpriseEmployeManagePage',
                            breadcrumbName: '<<hzqyyrzrs>>',
                        },
                        {
                            path: '/enterprise/:id/mobile-import-result',
                            component: 'InternalPurchaseRelationMobileImportPage',
                            breadcrumbName: '<<importResult>>',
                        },
                        {
                            path: '/enterprise/:enterpriseId/relation-mobile',
                            component: 'EnterpriseRelationMobileManagePage',
                            breadcrumbName: '<<glsjh>>',
                        },
                        {
                            path: '/activities/add',
                            component: 'ActivityAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/activities/edit/:id',
                            component: 'ActivityEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/activities/:activityId/goods',
                            component: 'ActivityGoodsManagePage',
                            breadcrumbName: '<<glsp>>',
                        },

                        {
                            path: '/activities/:id/goods-import-result',
                            component: 'InternalPurchaseGoodsImportPage',
                            breadcrumbName: '<<importResult>>',
                        },

                        { path: '/', component: 'InternalPurchasePage' },
                    ],
                },
            ],
        },
    ],
};
