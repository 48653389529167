//@ts-nocheck
import React, { Component, createElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { defaults, without, indexOf, map, intersection } from 'lodash';
import { List as AntList, Checkbox as AntCheckbox } from 'antd';
import { ComponentsManager, services } from '@comall-backend-builder/core';
const privilege = services.privilege;
/**
 * 列表展示
 *  @param {Function | String} renderItem - 每个列表项渲染，传入函数或者动态组件名都可以
 *  @param {Boolean | Object} rowSelection - 列表项选择/全选配置
 *  @param {string | ReactNode | Object} header - 列表头配置，值为object时需配置{type:'componnet',component:componnetName,batchConfig:{}}
 */
export class List extends Component<any, any> {
    static propTypes = {
        renderItem: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        rowSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
    };

    state = {
        indeterminate: false,
        selectedRowKeys: [],
        checkAll: false,
    };

    componentWillMount() {
        this.props.onInit && this.props.onInit();
    }

    convertPagination() {
        const {
            result,
            page,
            perPage,
            onChange,
            totalNum,
            pagination: paginationConfig,
        } = this.props;
        let pagination;

        if (paginationConfig && result && result.length > 0) {
            pagination = {
                current: page,
                total: totalNum,
                pageSize: perPage,
                onShowSizeChange: onChange,
                onChange,
            };

            if (typeof paginationConfig === 'object') {
                defaults(pagination, paginationConfig);
            }
        } else {
            pagination = false;
        }

        return pagination;
    }

    renderItem = (data: any) => {
        const { renderItem } = this.props;
        if (typeof renderItem === 'function') {
            return renderItem(data, this.props);
        }
        return createElement(ComponentsManager.get(renderItem), {
            ...data,
            parentProps: this.props,
        });
    };

    componentWillReceiveProps(nextProps: any) {
        const result = nextProps.result;
        let newSelectedRowKeys = intersection(map(result, 'id'), this.state.selectedRowKeys);
        this.setState({
            selectedRowKeys: newSelectedRowKeys,
            indeterminate: !!newSelectedRowKeys.length && newSelectedRowKeys.length < result.length,
            checkAll: newSelectedRowKeys.length === result.length,
        });
    }

    renderHeader = () => {
        let { header, rowSelection, result, entity, params } = this.props;
        let headerNode = header;
        if (rowSelection && rowSelection.privilege) {
            const { path, level } = rowSelection.privilege;
            rowSelection = privilege.check(path, level);
        }
        if (header && header.privilege) {
            const { path, level } = header.privilege;
            if (!privilege.check(path, level)) {
                return null;
            }
        }
        if (header && header.type === 'component') {
            if (header.component === 'DataTableBatch' && !result.length) {
                headerNode = null;
            } else {
                headerNode = createElement(ComponentsManager.get(header.component), {
                    ...header.config,
                    parentProps: this.props,
                    entity,
                    params,
                    selectedRowKeys: this.state.selectedRowKeys,
                    batchConfig: header.batchConfig,
                });
            }
        }

        if (
            typeof header === 'undefined' &&
            (rowSelection !== true || (rowSelection === true && !result.length))
        ) {
            return null;
        }
        return (
            <Fragment>
                {rowSelection && !!result.length && (
                    <AntCheckbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        全选
                    </AntCheckbox>
                )}
                {header && <Fragment>{headerNode === null ? <div /> : headerNode}</Fragment>}
            </Fragment>
        );
    };

    onCheckAllChange = (e) => {
        let result = this.props.result;
        this.setState({
            selectedRowKeys: e.target.checked ? map(result, 'id') : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    onChange = (e: any, a: any) => {
        let selectedRowKeys = this.state.selectedRowKeys;
        let resultLength = this.props.result.length;
        if (e.target.checked) {
            //@ts-ignore
            selectedRowKeys.push(a);
        } else {
            //@ts-ignore
            selectedRowKeys = without(selectedRowKeys, a);
        }
        this.setState({
            selectedRowKeys,
            indeterminate: !!selectedRowKeys.length && selectedRowKeys.length < resultLength,
            checkAll: selectedRowKeys.length === resultLength,
        });
    };

    render() {
        let {
            bordered,
            footer,
            grid,
            itemLayout,
            loadMore,
            locale,
            size,
            className,
            rowSelection,
            split,
            result,
            requestStatus,
        } = this.props;
        const pagination = this.convertPagination();
        if (rowSelection && rowSelection.privilege) {
            const { path, level } = rowSelection.privilege;
            rowSelection = privilege.check(path, level) ? true : false;
        }

        const listProps = {
            bordered,
            footer,
            grid,
            header: this.renderHeader(),
            itemLayout,
            loadMore,
            locale,
            pagination,
            size,
            loading: requestStatus === 'pending',
            className,
            split,
            dataSource: result,
        };
        return (
            <AntList
                {...listProps}
                renderItem={(item) => (
                    <AntList.Item
                        key={item.id}
                        className={
                            rowSelection ? 'has-list-row-selection' : 'hasnot-list-row-selection'
                        }
                    >
                        {rowSelection && (
                            <AntCheckbox
                                checked={indexOf(this.state.selectedRowKeys, item.id) > -1}
                                style={{ marginRight: 8, alignSelf: 'flex-start' }}
                                onChange={(e) => {
                                    this.onChange(e, item.id);
                                }}
                            />
                        )}
                        {this.renderItem(item)}
                    </AntList.Item>
                )}
            />
        );
    }
}
