import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex, isEqual, isEmpty } from 'lodash';
import { DistributionProductSelectedDisplay, ProductSelector } from '../../components';
import { services } from '@comall-backend-builder/core';

/**
 * 分销活动选择商品
 */
export class DistributionSelectProduct extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
    getParams: (v: any) => void;
    disabled: any;
    memberIdentity: string;
}> {
    state = {
        value: [],
    };

    componentWillReceiveProps(nextProps: any) {
        const nextParams = this.getParams(nextProps);
        const currentParams = this.getParams(this.props);
        if (!isEqual(nextParams, currentParams) && nextParams && !isEmpty(nextProps.value)) {
            this.processDataAfterSubsiteChange(nextParams.subsiteIds, nextProps);
        }
    }

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        if (!subsiteIds) {
            this.onChange([]);
            return;
        }
        const { value } = props;
        const newData: any[] = [];
        forEach(value, (i) => {
            if (subsiteIds.indexOf(i.subsiteId + '') > -1 || subsiteIds.indexOf(i.subsiteId) > -1) {
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    processSelectedData = (data: any[]) => {
        const { memberIdentity } = this.props;
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        productStyleCode: goods.csku,
                        subsiteName: goods.subsiteName,
                        subsiteId: goods.subsiteId,
                        merchantName: goods.merchantName,
                        salesPrice: Number(goods.salesPrice.value),
                        stock: goods.stock,
                        commissionInfos: [
                            {
                                identityLevel: services.language.getText('fxy'),
                                identityLevelId: '1',
                                percentage: 0,
                                memberIdentity: memberIdentity,
                            },
                        ],
                        commissionValue: '0.00',
                    });
                });
            });
        }
        return items;
    };

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        onChange(goods);
    };

    onSelect = (values: any[]) => {
        const { value } = this.props;
        const data = this.processSelectedData(values);
        const newData: any[] = cloneDeep(value || []);
        data.forEach((i: any) => {
            const hasIndex = findIndex(value, (o: any) => o.id === i.id);
            if (hasIndex === -1) {
                newData.unshift(i);
            }
        });
        this.onChange(newData);
    };

    getParams = (props: any) => {
        const { getParams } = props;

        if (typeof getParams === 'function') {
            return getParams(props);
        }

        return {};
    };

    render() {
        const { value, disabled } = this.props;
        const hasValue = value && value.length > 0;
        const params = this.getParams(this.props);
        return (
            <div className="cgb-select-product">
                {!disabled && (
                    <ProductSelector
                        params={params}
                        buttonText={services.language.getText('xzsp_1')}
                        onChange={this.onSelect}
                    />
                )}
                {hasValue && (
                    <DistributionProductSelectedDisplay
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                        disabled={false}
                    ></DistributionProductSelectedDisplay>
                )}
            </div>
        );
    }
}
