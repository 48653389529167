import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { PayWithGiftRewardNotificatonCheckbox } from './pay-with-gift-reward-notificaton-checkbox';
import { services } from '@comall-backend-builder/core';

export class PayWithGiftRewardNotificatonCheckboxMode extends modes.ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return object && object.channel ? (
            <div>
                <p>{services.language.getText('dx')}</p>
                <p>{object.content}</p>
            </div>
        ) : (
            <p>{services.language.getText('wpz')}</p>
        );
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            services.language.getText('notData')
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any): JSX.Element {
        return <PayWithGiftRewardNotificatonCheckbox {...controlInfo} />;
    }
}
