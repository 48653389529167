import { PureComponent, createElement } from 'react';
import { actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';

import { get, mapValues, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface AuditGuideUserFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

class AuditGuideUserFormView extends PureComponent<AuditGuideUserFormProps> {
    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;

        let fields = [
            {
                property: 'baseInfo.mobile',
            },
            {
                property: 'baseInfo.loginName',
            },
            {
                property: 'baseInfo.name',
                controlConfig: {
                    disabled: true,
                    style: {
                        width: 400,
                    },
                },
            },
            {
                property: 'baseInfo.code',
                controlConfig: {
                    disabled: true,
                    style: {
                        width: 400,
                    },
                },
            },
            {
                property: 'authInfo.organizationId',
                controlConfig: {
                    disabled: true,
                    style: {
                        width: 400,
                    },
                },
            },
            {
                property: 'authInfo.privilegeInfos',
                controlConfig: {
                    disabled: true,
                    style: {
                        width: 400,
                    },
                },
            },
            {
                property: 'authInfo.roleInfos',
                controlConfig: {
                    disabled: true,
                    hideRemark: true,
                    style: {
                        width: 400,
                    },
                },
            },
            {
                property: 'authInfo.remark',
                controlConfig: {
                    disabled: true,
                    style: {
                        width: 400,
                    },
                },
            },

            // 下一期做
            // {
            //     property: 'authInfo.isCreateMerchant',
            // },
        ];

        const formProps = {
            entity,
            componentId: 'AuditGuideUserFormViewContainer',
            wrappedComponentRef,
            loaderType: 'get',
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data =
        get(_dispatch, 'components.["DataFormPlus-AuditGuideUserFormViewContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(
                    actions.unmountComponentAction('DataFormPlus-AuditGuideUserFormViewContainer')
                );
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });
            const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展

            if (newData) {
                try {
                    entity.modify(newData, params);
                } catch (error) {
                    console.error(error);
                }
            }
        },
    };
}
export const AuditGuideUserViewForm = connect(mapStateToProps)(AuditGuideUserFormView);
