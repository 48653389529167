import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, isArray, forEach } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface RedPackageRainEditFormProps extends FormComponentProps {
    editExecuteStatus: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class redPackageRainEditFormView extends PureComponent<RedPackageRainEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let editExecuteStatus = get(entity, 'fields.editExecuteStatus');
        let fields = [
            {
                property: 'pageShowId',
                className: 'property-hide',
            },
            {
                property: 'baseInfo.name',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
                rules: [
                    { required: true, message: services.language.getText('acitivityTimeRequired') },
                    {
                        async validator(_: any, value: any) {
                            const originStartDate = get(entity.fields, 'baseInfo.dateRange.start');
                            if (!value) {
                                return;
                            }
                            const currentStartDate = value.start;
                            const currentEndDate = value.end;
                            if (originStartDate !== currentStartDate) {
                                throw new Error(
                                    services.language.getText('ksrqbngg') + `${originStartDate}`
                                );
                            }
                            if (new Date(currentEndDate).getTime() < new Date().getTime()) {
                                throw new Error(services.language.getText('jssjbnzydqsj'));
                            }
                        },
                    },
                ],
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            { property: 'baseInfo.marketingSchedule' },
            {
                property: 'ruleInfo.activityPeriodRewardLimit',
                controlConfig: {
                    style: {
                        width: 150,
                    },
                    addonbefore: services.language.getText('mrmtzdkcs'),
                    addonafter: services.language.getText('frequency'),
                    tip: '',
                },
            },
            { property: 'pictureInfo.backgroundPicture' },
            { property: 'pictureInfo.description' },
            {
                property: 'prizeInfo.assetRewards',
                className: 'prize-info-asset-rewards',
                controlConfig: {
                    disabled: editExecuteStatus === 'COMPLETE',
                    eventType: 'RED_PACKAGE_RAIN',
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'RedPackageRainEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'red-package-rain-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: { text: services.language.getText('common.save') },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                const assetRewards = get(entityFields, 'prizeInfo.assetRewards', null);
                if (!assetRewards || !isArray(assetRewards) || assetRewards.length <= 0) {
                    AntMessage.warning(services.language.getText('qtjjp'));
                    return false;
                }
                if (assetRewards) {
                    //验证中奖率100%
                    let conditionValue = 0;
                    forEach(assetRewards, (item: any) => {
                        if (item.status === 'ENABLED') {
                            conditionValue += parseFloat(item.conditionValue);
                        }
                    });
                    if (Number(conditionValue.toFixed(2)) !== 100) {
                        AntMessage.warning(services.language.getText('jpzjglbzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    let editExecuteStatus = get(
        _dispatch,
        'components.RedPackageRainEditFormContainer.fields.editExecuteStatus'
    );

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('RedPackageRainEditFormContainer'));
            },
        }
    );
}
export const RedPackageRainEditFormView = connect(mapDispatchToProps)(redPackageRainEditFormView);
