import React, { Component } from 'react';
import { Checkbox, Button, Modal, Radio, Popover, Icon } from 'antd';
import { DndProvider as DragDropContext } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { debounce, cloneDeep } from 'lodash';
import { DragSortBox } from '../../../../components';
import './index.less';
import { services } from '@comall-backend-builder/core';

export enum Mode {
    /**
     * 商城商品开单
     */
    SHOP = 'SHOP',
    /**
     * 商品开单
     */
    BIG_CODE = 'BIG_CODE',
    /**
     * 订金开单
     */
    DEPOSIT = 'DEPOSIT',
}

interface Value {
    mode: Mode;
    status: boolean;
    /**
     * 是否展示订金凭证码
     */
    showQrCode?: boolean;
}
interface Props {
    value: Array<Value>;
    onChange: (value: Array<Value>) => void;
    row: any;
}

interface State {
    showModal: boolean;
    modelChangeValue?: boolean;
}

const defalutValue: Array<Value> = [
    {
        mode: Mode.SHOP,
        status: false,
    },
    {
        mode: Mode.BIG_CODE,
        status: false,
    },
    {
        mode: Mode.DEPOSIT,
        status: false,
        showQrCode: false,
    },
];

/**
 * 停车页面配置功能菜单
 */
export class ArrayDepositModeMenus extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange(defalutValue);
        }
    }

    onValueChange = (newValue: Array<Value>) => {
        const { onChange } = this.props;
        onChange(newValue);
    };

    onChangePosition = debounce((hoverItem: any, dragItem: any) => {
        const { value } = this.props;
        const newValue = update(value, {
            $splice: [
                [dragItem.index, 1],
                [hoverItem.index, 0, value[dragItem.index]],
            ],
        });
        this.onValueChange(newValue);
    }, 100);

    onItemCheck = (index: number) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        newValue[index].status = !newValue[index].status;
        this.onValueChange(newValue);
    };

    showModal = (item: Value) => {
        this.setState({ showModal: true, modelChangeValue: item.showQrCode });
    };
    closeModal = () => {
        this.setState({ showModal: false });
    };
    onModalConfirm = () => {
        const { value, onChange } = this.props;
        const { modelChangeValue } = this.state;
        const newValue = cloneDeep(value);
        const find = newValue.find((i) => i.mode === Mode.DEPOSIT);
        if (find) {
            find.showQrCode = modelChangeValue;
            onChange(newValue);
        }
        this.closeModal();
    };

    onModelChangeValue = (e: any) => {
        const changeValue: boolean = e.target.value;
        this.setState({
            modelChangeValue: changeValue,
        });
    };

    renderModal = () => {
        const { showModal, modelChangeValue } = this.state;
        const codeImage = require('./code.jpg');
        return (
            <Modal
                title={services.language.getText('djkd')}
                onCancel={this.closeModal}
                onOk={this.onModalConfirm}
                visible={showModal}
                zIndex={1}
            >
                <div className="deposit-mode-menus__modal">
                    <div className="deposit-mode-menus__modal-item">
                        <div className="deposit-mode-menus__modal-item-name">
                            <span className="red">*</span>
                            {services.language.getText('djpzm')}：
                        </div>
                        <div className="deposit-mode-menus__modal-item-info">
                            <Radio.Group
                                value={modelChangeValue || false}
                                onChange={this.onModelChangeValue}
                            >
                                <Radio value={true}>{services.language.getText('display')}</Radio>
                                <Radio value={false}>
                                    {services.language.getText('noDisplay')}
                                </Radio>
                            </Radio.Group>
                            <div className="deposit-mode-menus__modal-item-tip">
                                {services.language.getText('dgzdgzsdglddhwdcdmkz')}
                                <Popover
                                    placement="bottom"
                                    content={
                                        <img
                                            style={{ width: 260 }}
                                            alt={services.language.getText('preview')}
                                            src={codeImage}
                                        ></img>
                                    }
                                    trigger="hover"
                                >
                                    <Button style={{ padding: 0, height: '18px' }} type="link">
                                        {services.language.getText('viewExample')}
                                    </Button>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    renderItem = (item: Value, index: number) => {
        const { status } = item;
        const content = {
            [Mode.SHOP]: '商城商品开单，导购可以选择在管理门店/专柜商品里，已上架的商品开单。',
            [Mode.BIG_CODE]: 'ERP商家码开单，支持导购选择商品，自定义售价开单。',
            [Mode.DEPOSIT]:
                '订金商家码开单，支持导购选择支持订金的商家码，自定义售价销售订金单。（当前暂不支持使用订金单）',
        };
        const name = {
            [Mode.SHOP]: services.language.getText('scspkd'),
            [Mode.BIG_CODE]: services.language.getText('spkd'),
            [Mode.DEPOSIT]: services.language.getText('djkd'),
        };
        return (
            <div className="deposit-mode-menus__item">
                <div className="deposit-mode-menus__item__content">
                    <Checkbox checked={status} onChange={() => this.onItemCheck(index)} />
                    <span className="deposit-mode-menus__item__name">{name[item.mode]}</span>
                    <Popover
                        placement="right"
                        content={<div>{content[item.mode]}</div>}
                        trigger="hover"
                    >
                        <Icon
                            type="question-circle-o"
                            style={{ marginLeft: '6px', cursor: 'pointer' }}
                        />
                    </Popover>
                    {item.mode === Mode.DEPOSIT && (
                        <div className="deposit-mode-menus__item__setting">
                            <Button
                                onClick={this.showModal.bind(this, item)}
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}
                                type="link"
                            >
                                {services.language.getText('settings')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const modeImage = require('./mode.jpg');
        return (
            <DragDropContext backend={HTML5Backend}>
                <div className="deposit-mode-menus__desc">
                    <Popover
                        placement="right"
                        content={<img style={{ width: 260 }} alt="预览" src={modeImage}></img>}
                        trigger="hover"
                    >
                        <Button style={{ padding: 0 }} type="link">
                            查看示例
                        </Button>
                    </Popover>
                    <div className="deposit-mode-menus__desc__tip">拖拽可以改变排序</div>
                </div>
                <div className="deposit-mode-menus">
                    {value.map((item, index) => {
                        return (
                            <DragSortBox
                                type="CARPARK"
                                data={{ index }}
                                hover={this.onChangePosition}
                            >
                                {this.renderItem(item, index)}
                            </DragSortBox>
                        );
                    })}
                </div>
                {this.renderModal()}
            </DragDropContext>
        );
    }
}
