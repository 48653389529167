import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const TouchlessParkingAuthorizationLoader: Loader = {
    get(params, config) {
        if (params.authTime) {
            params.startTime = params.authTime.start;
            params.endTime = params.authTime.end;
            delete params.authTime;
        }
        return api.get(params, config);
    },
};
