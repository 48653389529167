import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import './index.less';
import { services } from '@comall-backend-builder/core';

/**
 * 弹窗广告预览效果
 * @param props
 */
const openScreenAdsPreview = (props: any) => {
    const { preview } = props;
    const imgUrl = preview ? get(preview, 'picture', []) : [];
    const videoUrl = preview ? get(preview, 'video', []) : [];
    const type = preview ? get(preview, 'contentType', '') : '';
    const countdown = preview ? get(preview, 'countdown', '3') : '3';
    const position = preview ? get(preview, 'position', 'RIGHT_TOP') : 'RIGHT_TOP';
    const screenImageUrl = imgUrl && imgUrl[0]?.picture ? imgUrl[0]?.picture[0]?.path : '';
    const styleSkip =
        position === 'RIGHT_TOP'
            ? {
                  top: '100px',
                  right: '30px',
              }
            : position === 'RIGHT_BOTTOM'
            ? {
                  bottom: '80px',
                  right: '30px',
              }
            : position === 'LEFT_TOP'
            ? {
                  top: '100px',
                  left: '30px',
              }
            : position === 'LEFT_BOTTOM'
            ? {
                  bottom: '80px',
                  left: '30px',
              }
            : {};
    return (
        <div className="open-screen-preview">
            <img
                className="open-screen-background-img"
                alt={services.language.getText('preview')}
                src="./images/avatar/open-screen-imgs.png"
            ></img>
            {(imgUrl && imgUrl.length) || (videoUrl && videoUrl.length) ? (
                <div className="open-screen-skip" style={styleSkip}>
                    {services.language.getText('skip')}
                    {countdown}
                </div>
            ) : null}
            {type === 'PICTURE' && imgUrl && imgUrl.length && screenImageUrl ? (
                <div className="open-screen-imgs">
                    <img className="open-screen-img-blur" alt="" src={screenImageUrl}></img>
                    <img className="open-screen-img" alt="" src={screenImageUrl}></img>
                </div>
            ) : null}
            {type === 'VIDEO' && videoUrl && videoUrl.length ? (
                <video controls className="open-screen-video">
                    <source src={videoUrl[0]?.path} />
                </video>
            ) : null}
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields['rules'];
        }
    }
    return { preview: preview };
};

export const OpenScreenAdsPreview = connect(mapStateToProps)(openScreenAdsPreview);
