/**
 * 注意:
 * 1. page-design逻辑中全局变量 subsites，场景是老C装修页面
 * 2. 新c逻辑直接赋值到 data.subSiteIds中，目前场景是
 *    渠道推广码、优惠券领券后跳转的页面、可视化弹窗组件、停车缴费（新）页面链接类型
 * 3. 目前二者功能不交叉
 * eg: ScratchCardLoader
 */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isNull, isUndefined, cloneDeep, isString, isArray } from 'lodash';
import { Checkbox } from 'antd';
// 借用可视化的选择器可以实现当前需求
import { newClientCustomSelectorConfig } from '../../../../applications/design/design';
import './index.less';
import {
    LinkTypeEffectPathConfig,
    getOpenLinkTypeEffectPathConfigList,
    getNewLinkTypeEffectPathConfigList,
    getStaffNewLinkTypeEffectPathConfigList,
    getMerchantNewLinkTypeEffectPathConfigList,
} from '../../../../components/link-type-effect-path/config';
import { LinkTypePlus } from '../../../../applications/design/components';
import { language } from '@comall-backend-builder/core/lib/services';

export interface SubsiteLinkTypeSelectorValue {
    linkType: string;
    linkParams: any;
    linkName: string;
    linkPath: string;
    needUrlLink: boolean;
}
export enum LinkTypeSelectorBussinessType {
    MiniprogramV2 = 'miniprogram-v2',
    MiniprogramV2Staff = 'miniprogram-v2-staff',
    MiniprogramV2Merchant = 'miniprogram-v2-merchant',
    NewPopUpAd = 'new-pop-up-ad',
    Coupon = 'Coupon',
    CouponPackage = 'Coupon-Package',
    MessageBatch = 'message-batch',
    OpenBox = 'open-box',
    EventMarket = 'event-market',
    OpenScreenAd = 'open-screen-ad',
}
export interface SubsiteLinkTypeSelectorProps {
    name: string;
    value?: SubsiteLinkTypeSelectorValue;
    disabled?: boolean;
    row: any;
    onChange?(value: SubsiteLinkTypeSelectorValue, name: string): void;
    hideNeedUrlLink?: boolean;
    disabledNeedUrlLink: boolean;
    needUrlLinkExplain?: string;
    bussinessType: LinkTypeSelectorBussinessType;
    /**
     * 是否需要门店/专柜前置选择
     * 1. 未选择门店/专柜提示报错
     * 2. 切换门店/专柜报清空链接目标
     */
    subsitePreRequired?: boolean;
    /**
     * 门店/专柜未前置选择报错信息
     */
    subsitePreRequiredMsg?: boolean;
    /**
     * 平台模式
     */
    isPlatform?: boolean;
    /**
     * 无论什么条件，都展示勾选短链
     */
    allShowNeedUrlLink: boolean;
}

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: null,
    linkName: '',
    linkPath: '',
    needUrlLink: false,
};

const selectorConfig = cloneDeep({ ...newClientCustomSelectorConfig }) as any;

/**
 * 兼容老数据迁移后select options展示
 * 兼容老Saas专题展示（newTopic 新saas专题页，topic 老saas专题页）
 * @param linkType
 * @returns
 */
const getLinkType = (linkType: string) => {
    const linkTransformMap = {
        topic: 'newTopic',
    };
    return (linkTransformMap as any)[linkType] || linkType;
};

/**
 * linkTypes 根据业务类型获取链接目标范围
 * 黑名单模式
 * @param bussinessType
 * @returns
 */
const getLinkTypesByBussinessType = (
    bussinessType?: LinkTypeSelectorBussinessType
): LinkTypeEffectPathConfig[] => {
    let nextLinkTypes = getNewLinkTypeEffectPathConfigList();
    if (!bussinessType) {
        return nextLinkTypes;
    }
    const disableLinkTypesByTypeMap: Record<string, string[]> = {
        [LinkTypeSelectorBussinessType.MiniprogramV2]: ['none'],
        [LinkTypeSelectorBussinessType.NewPopUpAd]: [],
    };
    const disableLinkTypes = disableLinkTypesByTypeMap[bussinessType] || [];
    const filtor = (linkType: LinkTypeEffectPathConfig) => {
        if (isArray(linkType.children)) {
            linkType.children = linkType.children.filter(filtor);
        }
        return !disableLinkTypes.includes(linkType.id);
    };
    nextLinkTypes = nextLinkTypes.filter(filtor);

    //特殊页面-渠道员工码
    if (bussinessType && bussinessType === LinkTypeSelectorBussinessType.MiniprogramV2Staff) {
        nextLinkTypes = getStaffNewLinkTypeEffectPathConfigList();
    }
    //特殊页面-渠道专柜码
    if (bussinessType && bussinessType === LinkTypeSelectorBussinessType.MiniprogramV2Merchant) {
        nextLinkTypes = getMerchantNewLinkTypeEffectPathConfigList();
    }
    //特殊页面-开屏广告页面
    if (bussinessType && bussinessType === LinkTypeSelectorBussinessType.OpenScreenAd) {
        nextLinkTypes = getOpenLinkTypeEffectPathConfigList();
    }
    return nextLinkTypes;
};

export const SubsiteLinkTypeSelector: React.FC<SubsiteLinkTypeSelectorProps> = (props) => {
    const {
        value,
        name,
        disabled,
        row,
        onChange,
        hideNeedUrlLink,
        disabledNeedUrlLink,
        needUrlLinkExplain = language.getText('linkTypeShort'),
        bussinessType,
        subsitePreRequired,
        subsitePreRequiredMsg = language.getText('selectPromotionalStore'),
        isPlatform,
        allShowNeedUrlLink,
    } = props;

    const getSubsitesFromProps = (
        row: any,
        bussinessType: LinkTypeSelectorBussinessType
    ): string[] => {
        const {
            NewPopUpAd,
            MiniprogramV2,
            Coupon,
            CouponPackage,
            OpenBox,
            EventMarket,
        } = LinkTypeSelectorBussinessType;
        if (bussinessType === NewPopUpAd) {
            return row.subsites ? row.subsites.map((it: any) => it.id) : [];
        } else if (bussinessType === MiniprogramV2) {
            const { statisticType } = row;
            if (statisticType === 'CHANNEL') {
                return row.subsiteId ? [row.subsiteId] : [];
            } else {
                return row?.merchantIds?.map((it: any) => it.subSiteId) || [];
            }
        } else if (bussinessType === Coupon) {
            const { productInfo = {} } = row;
            if (productInfo?.selectMode === 'MERCHANT') {
                return productInfo?.shop?.map((it: any) => it.subSiteId) || [];
            } else {
                return productInfo?.subsite?.map((it: any) => it.id) || [];
            }
        } else if (
            bussinessType === CouponPackage ||
            bussinessType === OpenBox ||
            bussinessType === EventMarket
        ) {
            const { baseInfo = {} } = row;
            return baseInfo?.subsiteIds?.map((it: any) => it.id) || [];
        } else {
            // 注意： 新增bussinessType 需要实现对应subsiteIds取值逻辑
            return [];
        }
    };

    const [subsites, setSubsites] = useState<string[]>([]);
    const [subsiteFirstChanged, setsubsiteFirstChanged] = useState(false);
    const current = value || DEFAULT_VALUE;
    current.linkType = getLinkType(current.linkType);
    const nextSubsites = getSubsitesFromProps(row, bussinessType);
    // 门店切换需要清空链接目标
    useEffect(() => {
        if (
            nextSubsites.join(',') !== subsites.join(',') &&
            subsiteFirstChanged &&
            (subsitePreRequired || isPlatform)
        ) {
            onChange &&
                onChange(
                    {
                        ...DEFAULT_VALUE,
                        linkType: current.linkType,
                        linkName: current.linkName,
                        needUrlLink: current.needUrlLink,
                    },
                    name
                );

            setSubsites(nextSubsites);
        }
        if (nextSubsites.length && !subsites.length && !subsiteFirstChanged) {
            setsubsiteFirstChanged(true);
            setSubsites(nextSubsites);
        }
    }, [
        row,
        name,
        setsubsiteFirstChanged,
        nextSubsites,
        onChange,
        subsites,
        subsiteFirstChanged,
        current,
        subsitePreRequired,
        isPlatform,
    ]);
    let linkTypes = getLinkTypesByBussinessType(bussinessType);

    function transformParams(linkParams: any, paramsHandler?: Function): any {
        if (!linkParams || !paramsHandler) {
            return linkParams;
        }

        return paramsHandler(linkParams);
    }

    function linkNameGen(name: string, linkParams: any): string {
        return [name, linkParams && linkParams.name].filter(Boolean).join('-');
    }

    function linkPathGen(basePath: string, params?: string | string[], paramsObj?: any): string {
        if (!params || !paramsObj) {
            return basePath;
        }

        const paramsStr = [params].flat(3).reduce((result, key) => {
            const value = paramsObj[key];
            if (isNull(value) || isUndefined(value) || (isString(value) && value.length === 0)) {
                return result;
            }
            return `${result}&${key}=${value}`;
        }, '');

        if (!paramsStr) {
            return basePath;
        }

        return /\?/.test(basePath)
            ? `${basePath}${paramsStr}`
            : `${basePath}?${paramsStr.slice(1)}`;
    }

    function tryInjectParentRow(id: string): void {
        const selector = selectorConfig[id];
        if (!selector) {
            return;
        }

        const contentConfig = (selector.contentConfig = selector.contentConfig || {});
        Object.assign(contentConfig, {
            parentRow: row,
        });
    }

    const findLinkTypeById = (
        id: string,
        linkTypes: LinkTypeEffectPathConfig[]
    ): LinkTypeEffectPathConfig | undefined => {
        let config;
        linkTypes.forEach((linkType) => {
            if (!linkType.children && linkType.id === id) {
                config = linkType;
            }
            if (linkType.children && linkType.children.length > 0) {
                linkType.children.forEach((it) => {
                    if (it.id === id) {
                        config = it;
                    }
                });
            }
        });
        return config;
    };

    function handleChange(value: SubsiteLinkTypeSelectorValue): void {
        if (disabled) {
            return;
        }
        const config = findLinkTypeById(value.linkType, linkTypes);
        if (!config) {
            onChange && onChange(DEFAULT_VALUE, name);
            return;
        }
        const paramsObj = transformParams(value.linkParams, config.paramsHandler);

        Object.assign(value, {
            linkName: linkNameGen(config.name, value.linkParams),
            linkPath: linkPathGen(config.basePath, config.params, paramsObj),
        });

        tryInjectParentRow(config.id);
        onChange && onChange(value, name);
    }

    function changeNeedUrlLink(): void {
        if (disabled) {
            return;
        }
        Object.assign(current, {
            needUrlLink: !current.needUrlLink,
        });
        onChange && onChange(current, name);
    }

    const showUrlLink =
        (!hideNeedUrlLink && row.statisticType !== 'MERCHANT') || allShowNeedUrlLink;
    const hideLinkType = (!subsites || subsites.length === 0) && subsitePreRequired;
    const msgClassName = 'subsite-link-type-selector__subsite-pre-msg';
    return (
        <div
            className={classNames({
                'subsite-link-type-selector': true,
                'subsite-link-type-selector--disabled': disabled,
            })}
        >
            {!hideLinkType && (
                <LinkTypePlus
                    selector={selectorConfig}
                    linkTypes={linkTypes}
                    linkTypeMode={'cascader'}
                    value={current}
                    onChange={handleChange}
                    subsiteIds={subsites}
                />
            )}
            {hideLinkType && <div className={msgClassName}> {subsitePreRequiredMsg}</div>}
            {!disabled && showUrlLink && (
                <Checkbox
                    onChange={changeNeedUrlLink}
                    checked={current.needUrlLink}
                    disabled={disabledNeedUrlLink}
                >
                    {needUrlLinkExplain}
                </Checkbox>
            )}
        </div>
    );
};
