import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';

const api = services.api;

/**
 * 激励列表页面导出数据
 */
export class NotePromotionEncourageListExportButton extends PureComponent<any, any> {
    onClick = () => {
        const { row } = this.props;
        api.download(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath:
                    '/AFFILIATE-MARKETING/admin/creation_activities/archives/' +
                    row.id +
                    '/export.xls',
                customHeaders: { 'content-disposition': 'attachment;filename="filename.xls"' },
            }
        ).catch(errorHandle);
    };
    render() {
        return (
            <AntButton
                type="link"
                style={{ padding: 0 }}
                className="span-link"
                onClick={this.onClick}
            >
                导出数据
            </AntButton>
        );
    }
}
