/*
 * @Author: zhuhuajuan
 * @Description: 门店会员卡等级
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

export let config: Config = {
    components: {
        StoreCardLevelView: {
            component: 'Viewport',
            entity: 'storeCardLevel',
        },
        StoreCardLevelPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoreCardLevelFilter',
                },
                { component: 'StoreCardLevelTable' },
            ],
        },
        StoreCardLevelTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoreCardLevelButton',
                },
                {
                    component: 'DataTable',
                    componentId: 'StoreCardLevelDataTable',
                    loadFirstPage: false,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'baseInfo.code',
                        },
                        {
                            property: 'baseInfo.level',
                        },
                        {
                            property: 'baseInfo.name',
                        },
                        {
                            property: 'baseInfo.iconPicture',
                            displayConfig: {
                                style: {
                                    width: 80,
                                },
                            },
                        },
                        {
                            property: 'baseInfo.cardCoverPicture',
                            displayConfig: {
                                style: {
                                    width: 100,
                                },
                            },
                        },
                        {
                            property: 'baseInfo.status',
                            displayConfig: {
                                statusConfig: {
                                    ENABLE: {
                                        text: '<<enable>>',
                                        status: 'processing',
                                    },
                                    DISABLE: {
                                        text: '<<disabled>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/store-card-level/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'baseInfo.status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                    handles: [
                                        {
                                            params: { status: 'DISABLE' },
                                            value: 'ENABLE',
                                            apiPath: '/admin/store_card_levels/:id/status',
                                            config: {
                                                content: '<<disabled>>',
                                                text: '<<disabled>>',
                                            },
                                            confirm: {
                                                text: '<<isDisabled>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLE' },
                                            value: 'DISABLE',
                                            apiPath: '/admin/store_card_levels/:id/status',
                                            config: { content: '<<enable>>', text: '<<enable>>' },
                                            confirm: {
                                                text: '<<isEnable>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
        StoreCardLevelEditPage: {
            component: 'Card',
            content: { component: 'StoreCardLevelEditForm' },
        },
        StoreCardLevelEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            entity: 'storeCardLevel',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                { property: 'baseInfo.code', modifiable: false },
                { property: 'baseInfo.level', modifiable: false },
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.iconPicture',
                    extra: '建议尺寸：136px * 62px，大小不超过1M，支持jpg、png、jpeg格式',
                },
                {
                    property: 'baseInfo.cardCoverPicture',
                    extra: '建议尺寸：600px * 380px，大小不超过1M，支持jpg、png、jpeg格式',
                },
                {
                    property: 'baseInfo.status',
                },
                {
                    property: 'rightInfo.rights',
                },
                {
                    property: 'rightInfo.description',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/store-card-level',
                    breadcrumbName: '门店会员卡等级',
                    component: 'StoreCardLevelView',
                    privilege: {
                        path: 'storeCardLevel',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'StoreCardLevelEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'StoreCardLevelPage' },
                    ],
                },
            ],
        },
    ],
};
