import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const BatchDeliveryLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/waiting_delivery_orders';
        const { id } = data;
        if (!id) {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start;
                data.endTime = data.dateRange.end;
                delete data.dateRange;
            }
            data.queryType = 'RELATE_LOGISTICS_PLATFORM';
        }
        return await api.get(data, config);
    },
};
