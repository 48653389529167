import React, { Component } from 'react';
import { Input, Button } from 'antd';
import { clone, isEqual, map, concat, isEmpty } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

export interface MailboxSuffixesProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<string> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<string> | null;
}
export class MailboxSuffixes extends Component<MailboxSuffixesProps> {
    onMailboxSuffixChange(mailboxSuffix: any, index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item = mailboxSuffix;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item = mailboxSuffix;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onAddMailboxSuffix() {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, '');
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveMailboxSuffix(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: MailboxSuffixesProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value } = this.props;
        if (!value || value.length === 0) {
            value = [''];
        }
        return (
            <div className="mailbox-suffixes">
                {map(value, (mailboxSuffix, index) =>
                    this.renderMailboxSuffix(mailboxSuffix, index)
                )}
                <Button
                    type="default"
                    className="add-button"
                    onClick={() => this.onAddMailboxSuffix()}
                >
                    {services.language.getText('tjhz')}
                </Button>
            </div>
        );
    }

    renderMailboxSuffix(mailboxSuffix: string, index: number) {
        let { value } = this.props;
        return (
            <div className="mailbox-suffix" key={index}>
                <div className="mailbox-suffix-input">
                    <Input
                        addonBefore="@"
                        value={mailboxSuffix}
                        placeholder={services.language.getText('qsryxhz')}
                        onChange={(e) => this.onMailboxSuffixChange(e.target.value, index)}
                    />
                </div>
                {!isEmpty(value) && (
                    <Button
                        type="default"
                        className="remove-button"
                        onClick={() => this.onRemoveMailboxSuffix(index)}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                )}
            </div>
        );
    }
}
