import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { ActivityRewardCheckin } from './activity-reward-checkin';

/**
 *  邀请有礼，被邀请人奖励
 */
export class ObjectActivityRewardCheckinMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ActivityRewardCheckin {...controlInfo} />;
    }
}
