import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        EscapeWordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
            apiPath: '/admin/escape_word',
            filters: {
                escapeWordKey: {
                    type: 'string',
                    displayName: '<<translationKey>>',
                    controlConfig: {
                        placeholder: '<<translationKeyRequired>>',
                        style: { width: 250 },
                    },
                },
            },
            properties: {
                escapeWordKey: {
                    displayName: '<<translationKey>>',
                    type: 'string',
                    rules: [{ required: true, message: '<<translationKeyRequired>>!' }],
                    controlConfig: {
                        style: {
                            width: 250,
                        },
                        placeholder: '<<translationKeyRequired>>',
                    },
                },

                escapeWordValue: {
                    type: 'string',
                    displayName: '<<escapeWordValue>>',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<escapeWordValueRequired>>',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 250,
                        },
                        placeholder: '<<escapeWordValueRequired>>',
                    },
                },
                id: { type: 'number.integer' },
            },
        },
    },
    components: {
        EscapeWordView: {
            component: 'Viewport',
            entity: 'EscapeWordEntity',
        },
        EscapeWordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EscapeWordFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        marginBottom: 24,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'SetEscapeWordModal',
                                    triggerComponent: {
                                        component: 'Button',
                                        icon: 'plus',
                                        type: 'primary',
                                        text: '<<addTranslationKey>>',
                                        style: { marginRight: '10px' },
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'EscapeWordTable' },
            ],
        },
        EscapeWordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                {
                    property: 'escapeWordKey',
                },
            ],
        },
        EscapeWordTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'EscapeWordDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'escapeWordKey',
                        },
                        {
                            property: 'escapeWordValue',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'SetEscapeWordModal',
                                config: {
                                    edit: true,
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<common.edit>>',
                                        style: { marginRight: '10px' },
                                    },
                                },
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/escapeWord',
                    component: 'EscapeWordView',
                    breadcrumbName: '<<escapeWord>>',
                    privilege: {
                        path: 'escapeWord',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'EscapeWordPage' }],
                },
            ],
        },
    ],
};
