import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const StoredValueCardOrderLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = `/admin/orders`;
        if (!isEmpty(data.dateRange)) {
            data.startOrderCreateDateTime = data.dateRange.start + ' 00:00:00';
            data.endOrderCreateDateTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.receiveDate)) {
            data.receiveStartTime = data.receiveDate.start + ' 00:00:00';
            data.receiveEndTime = data.receiveDate.end + ' 23:59:59';
            delete data.receiveDate;
        }
        data.status =
            data.status && data.status.length > 0
                ? data.status.map((status: any) => {
                      return status.id;
                  })
                : undefined;
        data.orderTypeCodes = ['eStoredValueCard', 'pStoredValueCard', 'eRechargestoredValueCard'];
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    subsiteName: item.subsite.name,
                    memberMobile: item.member.mobile,
                    receiveTime: item.orderReceive ? item.orderReceive.receiveTime : undefined,
                    cancelTime: item.orderCancel ? item.orderCancel.cancelTime : undefined,
                    orderItemsName:
                        item.orderItems &&
                        item.orderItems.map((order: any) => {
                            return {
                                name: order.goods.productName,
                            };
                        }),
                    paymentBillTradeNo: item.paymentBill.tradeNo,
                };
            });
            return res;
        });
    },
};
