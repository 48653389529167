import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const SlotMachinesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/slot_machine_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/slot_machine_activities/${id}`;
        } else {
            data.eventType = 'SLOT_MACHINE';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                res.isOpen = res.activityVo.status;
                res.pageShowId = res.pageShow.id;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    subsiteIds: res.activityVo.normalConditions[0].includeIds.map((i: any) => ({
                        ...i,
                        id: i.id + '',
                    })),
                    couponRuleCode: res.lottery.couponRuleCode[0],
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.pictureInfo = {
                    backgroundPicture: [
                        {
                            id: res.pageShow.backgroundPicture.id || '',
                            path: res.pageShow.backgroundPicture.url || '',
                        },
                    ],
                    soltMachinePicture: [
                        {
                            id: res.pageShow.soltMachinePicture.id || '',
                            path: res.pageShow.soltMachinePicture.url || '',
                        },
                    ],
                    singleButtonPicture: [
                        {
                            id: res.pageShow.singleButtonPicture.id || '',
                            path: res.pageShow.singleButtonPicture.url || '',
                        },
                    ],
                    moreButtonPicture: [
                        {
                            id: res.pageShow.moreButtonPicture.id || '',
                            path: res.pageShow.moreButtonPicture.url || '',
                        },
                    ],
                    ruleDesc: res.activityVo.description,
                };
                const assetRewards = [];
                if (
                    res.activityVo.rewardConfig.activityRewards &&
                    res.activityVo.rewardConfig.activityRewards.length > 0
                ) {
                    const activityRewardList = res.activityVo.rewardConfig.activityRewards;
                    // 是否是复制-暂无复制功能，提前修改代码
                    const isCopy = window.location.href.includes('/copy');
                    for (let i = 0; i < activityRewardList.length; i++) {
                        const activityReward = activityRewardList[i];
                        for (let j = 0; j < activityReward.couponRewards.length; j++) {
                            const coupon = activityReward.couponRewards[j];
                            const couponReward: any = coupon;
                            if (isCopy) {
                                couponReward.issuedNum = 0;
                                couponReward.issuedCount = 0;
                                couponReward.inventory = couponReward.quantity || 0;
                                delete couponReward.id;
                            }
                            couponReward.picture = {
                                id: coupon.picture.id || '',
                                path: coupon.picture.url || '',
                            };
                            couponReward.type = {
                                checked: 'CUSTOM',
                                assetRewards: [
                                    {
                                        rewardType: 'CUSTOM',
                                        rewardValue: [
                                            {
                                                id: coupon.couponDefinitionId,
                                                quantity: coupon.count,
                                            },
                                        ],
                                    },
                                ],
                            };
                            assetRewards.push(couponReward);
                        }
                        for (let j = 0; j < activityReward.assetRewards.length; j++) {
                            const reward = activityReward.assetRewards[j];
                            let assetReward: any = reward;
                            if (isCopy) {
                                assetReward.inventory = assetReward.quantity || 0;
                                assetReward.issuedCount = 0;
                                assetReward.issuedNum = 0;
                                delete assetReward.id;
                            }
                            assetReward.picture = {
                                id: reward.picture.id || '',
                                path: reward.picture.url || '',
                            };
                            assetReward.type = {
                                checked: reward.rewardType,
                            };
                            assetRewards.push(assetReward);
                        }
                    }
                    assetRewards.sort(function(a, b) {
                        if (a.sequence < b.sequence) {
                            return -1;
                        } else if (a.sequence === b.sequence) {
                            return 0;
                        } else {
                            return 1;
                        }
                    });
                }
                res.prizeInfo = {
                    assetRewards: assetRewards,
                    finishWithNoPrize: res.lottery.finishWithNoPrize ? 'true' : undefined,
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.canExport =
                                item.executeStatus === 'STARTED' ||
                                item.executeStatus === 'COMPLETE';
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/slot_machine_activities';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                eventType: 'SLOT_MACHINE',
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                description: params.pictureInfo.ruleDesc,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                userWhiteListScope: 'ALL',
            };
        }
        if (params.pictureInfo) {
            newParams.pageShow = {
                backgroundPicture: {
                    id: params.pictureInfo.backgroundPicture[0].id,
                    url: params.pictureInfo.backgroundPicture[0].path,
                },
                soltMachinePicture: {
                    id: params.pictureInfo.soltMachinePicture[0].id,
                    url: params.pictureInfo.soltMachinePicture[0].path,
                },
                singleButtonPicture: {
                    id: params.pictureInfo.singleButtonPicture[0].id,
                    url: params.pictureInfo.singleButtonPicture[0].path,
                },
                moreButtonPicture: {
                    id: params.pictureInfo.moreButtonPicture[0].id,
                    url: params.pictureInfo.moreButtonPicture[0].path,
                },
            };
        }
        if (
            params.prizeInfo &&
            params.prizeInfo.assetRewards &&
            params.prizeInfo.assetRewards.length > 0
        ) {
            const assetRewardsInfo = [];
            const couponRewardsInfo: any = [];
            for (let i = 0; i < params.prizeInfo.assetRewards.length; i++) {
                const assetReward = params.prizeInfo.assetRewards[i];
                var checked = assetReward.type.checked; //奖品项最后选中的类型
                let raward = {
                    rewardType: checked,
                    name: assetReward.name,
                    picture: assetReward.picture,
                    quantity: assetReward.quantity,
                    sequence: assetReward.sequence,
                };
                assetRewardsInfo.push(raward);
            }
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
                crowdScope: 'EACH',
            };
            newParams.activityVo.rewardConfig.activityRewards = [
                {
                    assetRewards: assetRewardsInfo.length > 0 ? assetRewardsInfo : null,
                    couponRewards: couponRewardsInfo.length > 0 ? couponRewardsInfo : null,
                },
            ];
        }
        let couponRuleCode = [];
        couponRuleCode.push(params.baseInfo.couponRuleCode);
        let finishWithNoPrize = false;
        const assetRewards = params.prizeInfo.assetRewards || [];
        const findNoneReward = assetRewards.filter((item: any) => item.type.checked === 'NONE');
        if (
            findNoneReward.length > 0 &&
            params.prizeInfo.finishWithNoPrize &&
            params.prizeInfo.finishWithNoPrize.includes('true')
        ) {
            finishWithNoPrize = true;
        }
        newParams.lottery = {
            couponRuleCode: couponRuleCode,
            finishWithNoPrize,
        };
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/slot_machine_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                id: params.id,
                eventType: 'SLOT_MACHINE',
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                description: params.pictureInfo.ruleDesc,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                couponRuleCode: params.baseInfo.couponRuleCode,
            };
        }
        if (params.pictureInfo) {
            newParams.pageShow = {
                id: params.id,
                backgroundPicture: {
                    id: params.pictureInfo.backgroundPicture[0].id,
                    url: params.pictureInfo.backgroundPicture[0].path,
                },
                soltMachinePicture: {
                    id: params.pictureInfo.soltMachinePicture[0].id,
                    url: params.pictureInfo.soltMachinePicture[0].path,
                },
                singleButtonPicture: {
                    id: params.pictureInfo.singleButtonPicture[0].id,
                    url: params.pictureInfo.singleButtonPicture[0].path,
                },
                moreButtonPicture: {
                    id: params.pictureInfo.moreButtonPicture[0].id,
                    url: params.pictureInfo.moreButtonPicture[0].path,
                },
            };
            if (params.pictureInfo.rulePicture && params.pictureInfo.rulePicture[0]) {
                newParams.pageShow.rulePicture = {
                    id: params.pictureInfo.rulePicture[0].id,
                    url: params.pictureInfo.rulePicture[0].path,
                };
            }
            if (params.pictureInfo.sharePicture && params.pictureInfo.sharePicture[0]) {
                newParams.lottery = {
                    shareType: params.baseInfo.shareType,
                    shareRewardLimit: params.baseInfo.shareRewardLimit,
                    sharePicture: {
                        id: params.pictureInfo.sharePicture[0].id,
                        url: params.pictureInfo.sharePicture[0].path,
                    },
                    shareContent: params.pictureInfo.shareContent,
                };
            }
        }
        if (
            params.prizeInfo &&
            params.prizeInfo.assetRewards &&
            params.prizeInfo.assetRewards.length > 0
        ) {
            const assetRewardsInfo = [];
            const couponRewardsInfo: any = [];
            for (let i = 0; i < params.prizeInfo.assetRewards.length; i++) {
                const assetReward = params.prizeInfo.assetRewards[i];
                var checked = assetReward.type.checked; //奖品项最后选中的类型
                let raward = {
                    id: assetReward.id,
                    rewardType: checked,
                    name: assetReward.name,
                    picture: assetReward.picture,
                    quantity: assetReward.quantity,
                    sequence: assetReward.sequence,
                };
                assetRewardsInfo.push(raward);
            }
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
                crowdScope: 'EACH',
            };
            newParams.activityVo.rewardConfig.activityRewards = [
                {
                    assetRewards: assetRewardsInfo.length > 0 ? assetRewardsInfo : null,
                    couponRewards: couponRewardsInfo.length > 0 ? couponRewardsInfo : null,
                },
            ];
        }
        newParams.lottery = {};
        let couponRuleCode = [];
        couponRuleCode.push(params.baseInfo.couponRuleCode);
        let finishWithNoPrize = false;
        const assetRewards = params.prizeInfo.assetRewards || [];
        const findNoneReward = assetRewards.filter((item: any) => item.type.checked === 'NONE');
        if (
            findNoneReward.length > 0 &&
            params.prizeInfo.finishWithNoPrize &&
            params.prizeInfo.finishWithNoPrize.includes('true')
        ) {
            finishWithNoPrize = true;
        }
        newParams.lottery = {
            couponRuleCode: couponRuleCode,
            finishWithNoPrize,
        };
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.put(newParams, config);
    },
};
