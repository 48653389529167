import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, isArray } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface SlotMachinesEditFormProps extends FormComponentProps {
    editExecuteStatus: string;
    isOpen: boolean;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class slotMachinesEditFormView extends PureComponent<SlotMachinesEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef, isOpen } = this.props;
        const isBegin = +new Date(get(entity, 'fields.baseInfo.dateRange.start')) <= +new Date();

        let fields = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    disabled: !(!isBegin && !isOpen),
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    disabled: !(!isBegin && !isOpen),
                    style: {
                        width: 450,
                    },
                    range: {
                        start: new Date(),
                    },
                },
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    disabled: !(!isBegin && !isOpen),
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.couponRuleCode',
                controlConfig: {
                    disabled: !(!isBegin && !isOpen),
                    style: {
                        width: 450,
                    },
                },
            },
            { property: 'baseInfo.marketingSchedule' },
            {
                property: 'pictureInfo.downloadSample',
                className: 'download-ample-hide-label',
            },
            {
                property: 'pictureInfo.backgroundPicture',
            },
            { property: 'pictureInfo.soltMachinePicture' },
            { property: 'pictureInfo.singleButtonPicture' },
            { property: 'pictureInfo.moreButtonPicture' },
            { property: 'pictureInfo.ruleDesc' },
            {
                property: 'prizeInfo.assetRewards',
                className: 'prize-info-asset-rewards',
                controlConfig: {
                    eventType: 'SLOT_MACHINE',
                    isOpen,
                    isBegin,
                    disabled: isBegin,
                },
            },
            {
                property: 'prizeInfo.finishWithNoPrize',
                className: 'prize-info-asset-finishWithNoPrize',
                visible: (values: any) => {
                    const assetRewards = get(values, 'prizeInfo.assetRewards') || [];
                    const findNoneReward = assetRewards.filter(
                        (item: any) => item.type.checked === 'NONE'
                    );
                    if (findNoneReward.length > 0) {
                        return true;
                    }
                    return false;
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'SlotMachinesEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'slot-machines-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: { text: services.language.getText('common.save') },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                const assetRewards = get(entityFields, 'prizeInfo.assetRewards', null);
                if (!assetRewards || !isArray(assetRewards) || assetRewards.length <= 0) {
                    AntMessage.warning(services.language.getText('qtjjp'));
                    return false;
                }
                // 校验时间：开始时间需要大于当前时间
                if (+new Date(get(entityFields, 'baseInfo.dateRange.start')) <= +new Date()) {
                    AntMessage.error(services.language.getText('hdkssjywydqsj'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    let editExecuteStatus = get(
        _dispatch,
        'components.SlotMachinesEditFormContainer.fields.editExecuteStatus'
    );
    let isOpen = get(_dispatch, 'components.SlotMachinesEditFormContainer.fields.isOpen');

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
            isOpen,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('SlotMachinesEditFormContainer'));
            },
        }
    );
}
export const SlotMachinesEditFormView = connect(mapDispatchToProps)(slotMachinesEditFormView);
