import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Row as AntRow,
    Col as AntCol,
    Card as AntCard,
    Modal,
    Input as AntInput,
    message as AntMessage,
    Select,
} from 'antd';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { find, trim } from 'lodash';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface couponOrderInfoState {
    data: any | undefined;
    pos: any | undefined;
    businessReply: any | undefined;
    previewPictureVisible: boolean;
    previewPictureImage: string;
    remark: string;
    chooseReplyId: string;
    replyData: any[];
}

class offlineOrderCommentsInfo extends PureComponent<
    {
        fields: any | undefined;
        entity: Entity;
        params: { id: string };
    },
    couponOrderInfoState
> {
    state: couponOrderInfoState = {
        data: undefined,
        pos: undefined,
        businessReply: undefined,
        previewPictureVisible: false,
        previewPictureImage: '',
        remark: '',
        chooseReplyId: '',
        replyData: [],
    };

    isSubmitReply = false;

    componentDidMount() {
        this.loadData();
    }

    loadQuickReplySettings = async (subsiteId: number) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
            apiPath: '/admin/reply_common_words_configs',
        };
        const result: any = await api.get(
            {
                subsiteIds: subsiteId,
            },
            config
        );
        if (result) {
            this.setState({
                replyData: result,
            });
        }
    };

    handleChange = (val: any) => {
        const { replyData } = this.state;
        const row = find(replyData, { id: val });
        let remark = '';
        if (row) {
            remark = row.content;
        }
        if (val === 0) {
            remark = '';
        }
        this.setState({
            chooseReplyId: val,
            remark: remark,
        });
    };

    loadData = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
                apiPath: '/admin/comments/' + this.props.params.id,
            }
        ).then((data: any) => {
            this.setState({ data });
            this.loadInfo(data.objectId);
            this.loadReply();
            if (data.commentSubsiteVo && data.commentSubsiteVo.id) {
                this.loadQuickReplySettings(data.commentSubsiteVo.id);
            }
        });
    };

    loadReply = () => {
        api.get(
            {
                objectId: this.props.params.id,
                scene: 'COMMENT',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
                apiPath: '/admin/comments/by_object_id',
            }
        ).then((businessReply: any) => {
            this.setState({ businessReply });
        });
    };

    loadInfo = (objectId: number) => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-POS-RECEIPT`,
                apiPath: '/admin/pos_receipts/' + objectId,
            }
        ).then((res) => {
            this.setState({ pos: res });
        });
    };

    handlePicturePreview = (url: string) => {
        if (url) {
            this.setState({
                previewPictureImage: url,
                previewPictureVisible: true,
            });
        }
    };

    handlePictureCancel = () => this.setState({ previewPictureVisible: false });

    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ remark: value });
    };

    submit = () => {
        const { pos, remark } = this.state;

        if (!trim(remark)) {
            AntMessage.error(language.getText('qsrhfnr'));
            return;
        }

        if (this.isSubmitReply) {
            return;
        }

        this.isSubmitReply = true;

        const id = this.props.params.id;
        api.post(
            {
                remark,
                objectId: id,
                scene: 'COMMENT',
                subsiteId: pos && pos.subsiteVo && pos.subsiteVo.id,
                content: remark,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
                apiPath: '/admin/comments',
            }
        )
            .then(() => {
                AntMessage.success(language.getText('hfcg'));
                this.loadReply();
            })
            .catch((error) => {
                errorHandle(error);
                this.isSubmitReply = false;
            });
    };

    render() {
        const {
            data,
            pos,
            previewPictureVisible,
            previewPictureImage,
            remark,
            businessReply,
            chooseReplyId,
            replyData,
        } = this.state;

        return data && pos ? (
            <div className="offline-order-comments-info">
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCard type="inner" title={services.language.getText('hyxx')}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <AntCol span={6}>
                                {language.getText('loginName')}：{data.commentUser.nickName}
                            </AntCol>
                            <AntCol span={6}>
                                {language.getText('phoneNum')}：{data.commentUser.mobile}
                            </AntCol>
                        </AntRow>
                    </AntCard>
                </AntRow>
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCol span={12} style={{ paddingRight: 10 }}>
                        <AntCard type="inner" title={language.getText('ddxx')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {language.getText('xpbh')}：{pos.posReceiptNo}
                                </div>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {services.language.getText('xdsj')}：{pos.createTime}
                                </div>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {services.language.getText('xfmd')}：
                                    {pos.subsiteVo && pos.subsiteVo.name}
                                </div>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {services.language.getText('xfje')}：{pos.orderAmount}
                                </div>
                            </AntRow>
                        </AntCard>
                    </AntCol>
                    <AntCol span={12} style={{ paddingLeft: 10 }}>
                        <AntCard type="inner" title={language.getText('ddspxx')}>
                            {pos.products &&
                                pos.products.map((product: any) => {
                                    return (
                                        <AntRow style={{ marginBottom: 10 }}>
                                            <AntCol key={product.productName}>
                                                {product.productName}
                                            </AntCol>
                                        </AntRow>
                                    );
                                })}
                        </AntCard>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCard type="inner" title={language.getText('pjxx')}>
                        <AntRow style={{ marginBottom: 10 }}>
                            <div>
                                {language.getText('pjsj')}：{data.createTime}
                            </div>
                        </AntRow>
                        {data.commentItems.map((commentItem: any) => {
                            return (
                                <AntRow style={{ marginBottom: 10 }}>
                                    <div>
                                        {commentItem.name}：{commentItem.starScore}星
                                    </div>
                                    {commentItem.tags && commentItem.tags.length > 0 && (
                                        <div style={{ display: 'flex', margin: '10px 40px' }}>
                                            {commentItem.tags.map((tag: any) => {
                                                return (
                                                    <div
                                                        style={{
                                                            background: '#f0f0f0',
                                                            height: '28px',
                                                            lineHeight: '28px',
                                                            padding: '0 10px',
                                                            borderRadius: '10px',
                                                            margin: '0 10px 10px 0',
                                                        }}
                                                    >
                                                        {tag}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </AntRow>
                            );
                        })}
                        {data.content && (
                            <AntRow style={{ marginBottom: 10 }}>
                                <div>
                                    {language.getText('pjnr')}：{data.content}
                                </div>
                            </AntRow>
                        )}
                        {data.pictures.map((picture: any) => {
                            return (
                                <img
                                    style={{
                                        width: '210px',
                                        height: '210px',
                                        margin: '0 10px 10px 0',
                                    }}
                                    src={picture.url}
                                    alt={language.getText('kdjckyt')}
                                    onClick={this.handlePicturePreview.bind(this, picture.url)}
                                />
                            );
                        })}
                    </AntCard>
                </AntRow>
                <AntRow style={{ marginBottom: 20 }}>
                    <AntCard type="inner" title={services.language.getText('hfxx')}>
                        {!businessReply ? (
                            <AntRow style={{ marginBottom: 10, display: 'flex' }}>
                                <div>{language.getText('hfnr')}：</div>
                                <div>
                                    <div style={{ marginBottom: 20 }}>
                                        <Select
                                            style={{ width: '500px' }}
                                            value={chooseReplyId ? chooseReplyId : undefined}
                                            placeholder={language.getText('kjhf')}
                                            onSelect={(transAction: any) =>
                                                this.handleChange(transAction)
                                            }
                                            allowClear
                                        >
                                            {replyData &&
                                                replyData.map((option) => (
                                                    <Select.Option key={option.id}>
                                                        {option.content}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </div>
                                    <div>
                                        <AntInput.TextArea
                                            placeholder={language.getText('qsrhfnr')}
                                            style={{ width: '500px' }}
                                            autoSize={{ minRows: 6, maxRows: 6 }}
                                            onChange={this.inputChange}
                                            value={remark}
                                            maxLength={500}
                                        />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <Button
                                            type="primary"
                                            onClick={this.submit}
                                            text={language.getText('hf')}
                                        />
                                    </div>
                                </div>
                            </AntRow>
                        ) : (
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntRow
                                    style={{
                                        marginBottom: 10,
                                        wordBreak: 'break-all',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    <AntCol span={6}>
                                        {language.getText('hfr')}：
                                        {businessReply.commentUser.nickName}
                                    </AntCol>
                                    <AntCol span={6}>
                                        {language.getText('hfsj')}：{businessReply.createTime}
                                    </AntCol>
                                </AntRow>
                                <AntRow
                                    style={{
                                        marginBottom: 10,
                                        wordBreak: 'break-all',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    <div>
                                        {language.getText('hfnr')}：{businessReply.content}
                                    </div>
                                </AntRow>
                            </AntRow>
                        )}
                    </AntCard>
                </AntRow>
                <Modal
                    visible={previewPictureVisible}
                    footer={null}
                    onCancel={this.handlePictureCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewPictureImage} />
                </Modal>
                <Button route="goBack" text={services.language.getText('back')} />
            </div>
        ) : null;
    }
}

function mapStateToProps(state: any, props: any) {
    const {
        entity: { fields },
        componentId,
    } = props;
    return {
        fields,
        componentId,
    };
}

export const OfflineOrderCommentsInfo = connect(mapStateToProps)(offlineOrderCommentsInfo);
