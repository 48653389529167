import builder, {
    actions,
    ComponentsManager,
    EntitiesManager,
    services,
} from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { Button as AntButton, Modal as AntModal } from 'antd';
import { find, get, map, remove } from 'lodash';
import React, { createElement, PureComponent } from 'react';
import { connect } from 'react-redux';

interface Subsite {
    id: string;
    name: string;
    address: string;
    status: number;
}
interface Props {
    id: string;
    value?: Subsite[];
    onChange?(value: Subsite[]): void;
    requestStatus: string;
}
interface State {
    modalVisible: boolean;
}

const componentId = 'WechatOfficialAccountSubsitesTable';
let Entity: any = {};
let entity: any = {};
class SubsiteSelectoraModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        Entity = EntitiesManager.get('WechatOfficialAccountSubsite');
        entity = new Entity({ id: props.id });
        this.state = {
            modalVisible: false,
        };
        this.selectedRows = props.value ? [...props.value] : [];
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.requestStatus === 'pending' && this.props.requestStatus === 'success') {
            setTimeout(() => {
                builder
                    .getStore()
                    .dispatch(
                        actions.tableRowSelectionChangeAction(
                            componentId,
                            map(this.props.value, 'id')
                        )
                    );
            }, 300);
        }
        if (this.props.value?.length && !this.selectedRows.length) {
            this.selectedRows = [...this.props.value];
        }
    }

    private selectedRows: Subsite[] = [];

    private onOk = () => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange([...this.selectedRows]);
        }
        this.hide();
    };

    private onCancel = () => {
        this.hide();
    };

    private show = () => {
        this.setState({ modalVisible: true });
    };

    private hide = () => {
        this.setState({ modalVisible: false });
    };

    private get flexlayoutConfig() {
        const { value } = this.props;
        return {
            direction: 'vertical',
            entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [{ property: 'name' }],
                },
                {
                    component: 'DataTable',
                    componentId,
                    scroll: {
                        y: 400,
                    },
                    loadFirstPage: true,
                    rowSelection: {
                        type: 'checkbox',
                        selectedRowKeys: map(value, 'id'),
                        rowKey: 'id',
                        onSelect: (record: Subsite, selected: boolean) => {
                            if (selected) {
                                if (!find(this.selectedRows, { id: record.id })) {
                                    this.selectedRows.push(record);
                                }
                            } else {
                                remove(this.selectedRows, { id: record.id });
                            }
                        },
                        onSelectAll: (selected: boolean, rows: Subsite[]) => {
                            if (selected) {
                                this.selectedRows = [...rows];
                            } else {
                                this.selectedRows = [];
                            }
                        },
                        onChange() {},
                    },
                    columns: [
                        { property: 'name' },
                        { property: 'address' },
                        { property: 'status' },
                    ],
                },
            ],
        };
    }
    render() {
        const { modalVisible } = this.state;

        return (
            <div>
                <AntButton type="link" onClick={this.show}>
                    选择门店
                </AntButton>
                <AntModal
                    visible={modalVisible}
                    title="选择门店"
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    width={900}
                >
                    {createElement(ComponentsManager.get('FlexLayout'), this.flexlayoutConfig)}
                </AntModal>
            </div>
        );
    }
}

export default connect(() => ({ requestStatus: get(entity, 'requestStatus', null) }))(
    SubsiteSelectoraModal
);
