import React, { Component } from 'react';
import { Select } from 'antd';
import { map } from 'lodash';
import { services } from '@comall-backend-builder/core';

import './index.less';

const Days = Array.from(new Array(31).keys()).map((i) => i + 1);

interface DayPeriodConditionValue {
    startDay?: number;
    endDay?: number;
}

interface DayPeriodConditionProps {
    onChange: (value: DayPeriodConditionValue) => void;
    value: DayPeriodConditionValue;
    disabled: boolean;
}

export class DayPeriodCondition extends Component<DayPeriodConditionProps, any> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defaultValue = {};
            onChange(defaultValue);
        }
    }

    onStartDayChange = (startDay: number) => {
        const { value, onChange } = this.props;
        onChange(Object.assign({}, value, { startDay }));
    };
    onEndDayChange = (endDay: number) => {
        const { value, onChange } = this.props;
        onChange(Object.assign({}, value, { endDay }));
    };
    render() {
        const { value, disabled } = this.props;
        const start = value ? value.startDay : undefined;
        const end = value ? value.endDay : undefined;
        return (
            <div className="day-period-condition">
                <span className="text">{services.language.getText('everyMonth')} </span>
                <Select
                    onChange={this.onStartDayChange}
                    placeholder={services.language.getText('selectPlease')}
                    className="select"
                    value={start}
                    disabled={disabled}
                >
                    {map(Days.slice(0, end ? end : 31), (d) => (
                        <Select.Option value={d}>{d}</Select.Option>
                    ))}
                </Select>
                <span className="text">{services.language.getText('dayAndTo')}</span>
                <Select
                    value={end}
                    onChange={this.onEndDayChange}
                    placeholder={services.language.getText('selectPlease')}
                    className="select"
                    disabled={disabled}
                >
                    {map(Days.slice(start ? start - 1 : 0, 31), (d) => (
                        <Select.Option value={d}>{d}</Select.Option>
                    ))}
                </Select>
                <span className="text">{services.language.getText('r_3')}</span>
            </div>
        );
    }
}
