import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { RichTextPlus } from '../../../components';

export class StringRichTextPlusMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        const controlInfo = Object.assign({}, displayInfo, {
            disabled: true,
            value: value,
        });
        return <RichTextPlus {...controlInfo} />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <RichTextPlus {...displayInfo} />;
    }
}
