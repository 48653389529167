import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const PaymentModeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
        config.apiPath = `/admin/payment_mode`;
        if (data.id) {
            config.apiPath = `/admin/payment_mode/${data.id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.baseInfo = {
                    name: res.name,
                    typeName: res.typeName,
                    instruction: res.instruction,
                    sequence: res.sequence,
                    procedureFee: res.procedureFee,
                };
                res.paymentAuthorizationProtocolConfig = handleBoolean(
                    res.paymentAuthorizationProtocolConfig
                );
                const protocolArticle = get(
                    res,
                    'paymentAuthorizationProtocolConfig.protocolArticle',
                    undefined
                );
                res.paymentAuthorizationProtocolConfig.protocolArticle = protocolArticle
                    ? [protocolArticle]
                    : [];
                res.paymentPasswordConfig = handleBoolean(res.paymentPasswordConfig);
                return res;
            }
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    status: item.enabled,
                    enabled: item.enabled.toString(),
                    enabledPaymentPassword:
                        typeof item.enabledPaymentPassword === 'boolean'
                            ? item.enabledPaymentPassword.toString()
                            : '',
                };
            });
            return res;
        });
    },
    put: async function(data: any, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
        config.apiPath = `/admin/payment_mode/${id}`;
        const newParams = dataParser(data);
        newParams.id = id;
        return services.api.put(newParams, config);
    },
};

function handleBoolean(object: any) {
    if (!object) {
        return object;
    }
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const element = object[key];
            if (typeof element === 'boolean') {
                object[key] = element.toString();
            }
        }
    }
    return object;
}

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {
        ...params.baseInfo,
        paymentAuthorizationProtocolConfig: {
            ...params.paymentAuthorizationProtocolConfig,
            protocolArticleId: get(
                params,
                'paymentAuthorizationProtocolConfig.protocolArticle[0].id',
                null
            ),
        },
    };
    if (params?.paymentPasswordConfig) {
        newParams.paymentPasswordConfig = {
            enabled: params.paymentPasswordConfig.enabled,
            allowModify: params.paymentPasswordConfig.allowModify,
            useMemberPassword:
                params.paymentPasswordConfig.allowModify === 'true'
                    ? params.paymentPasswordConfig.useMemberPassword
                    : false,
        };
    }

    return newParams;
}
