import { Config } from '@comall-backend-builder/core/lib/parser';
import { ForwardDataCenterModal } from '../../../../services';
import { api } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { EntityButtonProps } from '../../../../components';

export const config: Config = {
    entities: {
        TouchlessParkingAuthorization: {
            apiPath: '/loader/admin/touchless_parking/authorizations',
            apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<sqsjh>>',
                },
                authTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<sqsj_1>>',
                },
                authState: {
                    displayName: '<<sqzt>>',
                    type: 'string.options.select',
                    options: [
                        { id: 'AUTHORIZED', name: '<<sqz>>' },
                        { id: 'CANCELED', name: '<<qxsq>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            },
            properties: {
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                carNumberColorName: {
                    type: 'string',
                    displayName: '<<cpys>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<sqsjh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                authedTime: {
                    type: 'string',
                    displayName: '<<sqsj_1>>',
                },
                touchlessParkingSystemName: {
                    type: 'string',
                    displayName: '<<sqqd>>',
                },
                canceledTime: {
                    type: 'string',
                    displayName: '<<qxsqsj>>',
                },
                authState: {
                    type: 'string.options.select',
                    displayName: '<<sqzt>>',
                    options: [
                        { id: 'AUTHORIZED', name: '<<sqz>>' },
                        { id: 'CANCELED', name: '<<qxsq>>' },
                    ],
                },
            },
        },
        TouchlessParkingAuthorizationLog: {
            apiPath: '/admin/touchless_parking/authorizations/logs',
            apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
            properties: {
                authState: {
                    type: 'string',
                    displayName: '<<bgzt>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<zxsj>>',
                },
            },
        },
        TouchlessParkingAssetAuthorization: {
            apiPath: '/loader/admin/touchless_parking/asset_authorizations',
            apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
            filters: {
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                assetTypes: {
                    type: 'array.optionIds.select',
                    displayName: '<<sqyhx>>',
                    options: [
                        { id: 'CONSUMPTION_DEDUCTION', name: '<<hyqy>>' },
                        { id: 'COUPON', name: '<<tcyhq>>' },
                        { id: 'POINT', name: '<<hyjf>>' },
                        { id: 'BALANCE_COUPON', name: '<<yeqzf>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                modifyTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<zhxgsj>>',
                },
            },
            properties: {
                assetTypeNames: {
                    type: 'string',
                    displayName: '<<sqyhx>>',
                },
                authedTime: {
                    type: 'string',
                    displayName: '<<sqsj_1>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<zhxgsj>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
            },
        },
        TouchlessParkingAssetAuthorizationLog: {
            apiPath: '/admin/touchless_parking/asset_authorizations/logs',
            apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
            properties: {
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                assetsAfterChange: {
                    type: 'string',
                    displayName: '<<bgh>>',
                },
                assetsBeforeChange: {
                    type: 'string',
                    displayName: '<<bgq>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<zxsj>>',
                },
            },
        },
    },
    components: {
        TouchlessParkingAuthorizationViewport: {
            component: 'Viewport',
        },
        TouchlessParkingAuthorizationTabs: {
            component: 'TabsPlus',
            type: 'card',
            defaultActiveKey: 'authorization',
            cache: false,
            animated: false,
            basePath: '/touchless-parking-authorization-record',
            items: [
                {
                    title: '<<TouchlessParkingAuthRecord>>',
                    content: {
                        component: 'TouchlessParkingAuthorizationTab',
                    },
                    key: 'authorization',
                },
                {
                    title: '<<wgyhsq>>',
                    content: {
                        component: 'TouchlessParkingAssetAuthorizationTab',
                    },
                    key: 'asset-authorization',
                },
            ],
        },
        TouchlessParkingAuthorizationTab: {
            entity: 'TouchlessParkingAuthorization',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'TouchlessParkingAuthorizationFilterForm' },
                { component: 'TouchlessParkingAuthorizationButtonGroup' },
                { component: 'TouchlessParkingAuthorizationDataTable' },
            ],
        },
        TouchlessParkingAuthorizationFilterForm: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                { property: 'carNumber' },
                { property: 'memberMobile' },
                { property: 'authTime' },
                { property: 'authState' },
            ],
        },
        TouchlessParkingAuthorizationButtonGroup: {
            component: 'GridLayout',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<dccxsj>>',
                        style: {
                            width: 110,
                        },
                        className: 'mt24 mb24',
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters
                            );
                            if (params.authTime) {
                                params.startTime = params.authTime.start;
                                params.endTime = params.authTime.end;
                                delete params.authTime;
                            }
                            if (params.subsiteId) {
                                params.subsiteId = params.subsiteId.id;
                            }
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
                                apiPath: '/admin/touchless_parking/authorizations/export_excel',
                            };

                            api.post(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
            ],
        },
        TouchlessParkingAuthorizationDataTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            style: { marginTop: 24 },
            columns: [
                { property: 'carNumber' },
                { property: 'carNumberColorName' },
                { property: 'memberMobile' },
                { property: 'authedTime' },
                { property: 'touchlessParkingSystemName' },
                { property: 'authState' },
                { property: 'canceledTime' },
            ],
            actionColumn: {
                title: '<<sqrz>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path:
                            '/touchless-parking-authorization-record/{{row.memberId}}/{{row.carNumberColor}}/{{row.carNumber}}/log',
                    },
                ],
            },
        },
        TouchlessParkingAuthorizationLogTable: {
            entity: 'TouchlessParkingAuthorizationLog',
            component: 'DataTable',
            loadFirstPage: true,
            pagination: false,
            columns: [{ property: 'authState' }, { property: 'createTime' }],
        },
        TouchlessParkingAssetAuthorizationTab: {
            entity: 'TouchlessParkingAssetAuthorization',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'TouchlessParkingAssetAuthorizationFilterForm' },
                { component: 'TouchlessParkingAssetAuthorizationButtonGroup' },
                { component: 'TouchlessParkingAssetAuthorizationDataTable' },
            ],
        },
        TouchlessParkingAssetAuthorizationFilterForm: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                { property: 'carNumber' },
                { property: 'memberMobile' },
                { property: 'assetTypes' },
                { property: 'modifyTime' },
            ],
        },
        TouchlessParkingAssetAuthorizationButtonGroup: {
            component: 'GridLayout',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<dccxsj>>',
                        style: {
                            width: 110,
                        },
                        className: 'mt24 mb24',
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters
                            );
                            if (params.modifyTime) {
                                params.modifyStartTime = params.modifyTime.start;
                                params.modifyEndTime = params.modifyTime.end;
                                delete params.modifyTime;
                            }
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
                                apiPath:
                                    '/admin/touchless_parking/asset_authorizations/export_excel',
                            };

                            api.post(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
            ],
        },
        TouchlessParkingAssetAuthorizationDataTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            style: { marginTop: 24 },
            columns: [
                { property: 'memberMobile' },
                { property: 'carNumber' },
                { property: 'assetTypeNames' },
                { property: 'lastModifyTime' },
            ],
            actionColumn: {
                title: '<<sqrz>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path:
                            '/touchless-parking-authorization-record/{{row.memberId}}/{{row.carNumber}}/asset-log',
                    },
                ],
            },
        },
        TouchlessParkingAssetAuthorizationLogTable: {
            entity: 'TouchlessParkingAssetAuthorizationLog',
            component: 'DataTable',
            loadFirstPage: true,
            pagination: false,
            columns: [
                { property: 'carNumber' },
                { property: 'assetsBeforeChange' },
                { property: 'assetsAfterChange' },
                { property: 'createTime' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/touchless-parking-authorization-record',
                    component: 'TouchlessParkingAuthorizationViewport',
                    breadcrumbName: '<<TouchlessParkingAuthRecord>>',
                    childRoutes: [
                        {
                            path: '/:memberId/:carNumberColor/:carNumber/log',
                            component: 'TouchlessParkingAuthorizationLogTable',
                            breadcrumbName: '<<wgtcsqrz>>',
                        },
                        {
                            path: '/:memberId/:carNumber/asset-log',
                            component: 'TouchlessParkingAssetAuthorizationLogTable',
                            breadcrumbName: '<<wgtcyhsqrz>>',
                        },
                        {
                            path: '/',
                            component: 'TouchlessParkingAuthorizationTabs',
                        },
                    ],
                    privilege: {
                        path: 'TouchlessParkingAuthRecord',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
