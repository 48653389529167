import { Config } from '@comall-backend-builder/core/lib/parser';
import { forEach, isEmpty, map } from 'lodash';
import { RegistrationPriceType } from '../../../../components/registration-price';
import { ActivityTagType } from '../../../cms/mall-activity-tag';

const GENDER_OPTIONS = [
    {
        id: 'MAN',
        name: '男',
    },
    {
        id: 'WOMAN',
        name: '女',
    },
];
const REGISTRATION_STATUS_OPTIONS = [
    {
        id: 'STARTED',
        name: '<<inprogress>>',
    },
    {
        id: 'COMPLETE',
        name: '<<end>>',
    },
    {
        id: 'NOTSTARTED',
        name: '<<notStarted>>',
    },
    {
        id: 'NULL',
        name: '非报名',
    },
];

const EXECUTE_STATUS_OPTIONS = [
    {
        id: 'STARTED',
        name: '<<inprogress>>',
    },
    {
        id: 'COMPLETE',
        name: '<<end>>',
    },
    {
        id: 'NOTSTARTED',
        name: '<<notStarted>>',
    },
];

export const config: Config = {
    entities: {
        MallActivitySettingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/mallActivitySetting',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                registrationStatus: {
                    type: 'string.options.select',
                    displayName: '报名状态',
                    options: REGISTRATION_STATUS_OPTIONS,
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: EXECUTE_STATUS_OPTIONS,
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editRegistrationStatus: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        pictures: {
                            displayName: '<<hdtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '建议尺寸:750 * 345  大小:不超过1M  格式:JPG,PNG,JPEG',
                        },
                        dateRange: {
                            type: 'object.dateRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            controlConfig: {
                                format: 'YYYY-MM-DD',
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                            extra: '<<hddksjsrq>>',
                        },
                        dayTime: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<acitivityTime>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd > 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart < 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        } else {
                                            return false;
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            extra: '活动每天的开始结束时间',
                        },
                        address: {
                            type: 'string',
                            displayName: '活动地点',
                            controlConfig: {
                                placeholder: '请输入活动地点，不超过20个字',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        phone: {
                            type: 'string',
                            displayName: '咨询电话',
                            controlConfig: {
                                placeholder: '请输入咨询电话',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        info: {
                            type: 'string.richTextPlus',
                            displayName: '活动详情',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                    },
                },
                merchants: {
                    type: 'array.shop.selector',
                    displayName: '<<cymdjzj>>',
                    rules: [{ required: true, type: 'array' }],
                    controlConfig: {
                        uniqueSubsite: true,
                    },
                },
                tagIdList: {
                    displayName: '活动标签',
                    type: 'array.activityTag.selector',
                    controlConfig: {
                        getParams(props: any) {
                            const { row } = props;
                            const subsiteIds =
                                row.merchants && row.merchants.length
                                    ? map(row.merchants, 'subSiteId').join(',')
                                    : undefined;
                            return {
                                type: ActivityTagType.MALL_ACTIVITY,
                                subsiteIds,
                            };
                        },
                    },
                },
                registrationInfo: {
                    type: 'object.subForm',
                    displayName: '报名设置',
                    properties: {},
                },
                registrationType: {
                    type: 'string.options.radio',
                    displayName: '<<hdms_1>>',
                    defaultValue: 'NO',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'NO',
                            name: '非报名类',
                        },
                        {
                            id: 'YES',
                            name: '报名类',
                        },
                    ],
                    extra: '选择[报名类]后，用户需要先报名后参与活动',
                },
                timeLimit: {
                    type: 'number.tip',
                    displayName: '提前周期',
                    rules: [
                        {
                            type: 'number',
                            min: 1,
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (value && (!reg.test(value) || value === 0)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 100,
                        },
                        addonbefore: '至少提前',
                        addonafter: '天报名',
                        tip: '',
                    },
                },
                registrationDateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<bmsj>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        range: {
                            start: new Date(),
                        },
                    },
                },
                session: {
                    type: 'string.options.radio',
                    displayName: '按场次报名',
                    defaultValue: 'NO',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'NO',
                            name: '否',
                        },
                        {
                            id: 'YES',
                            name: '是',
                        },
                    ],
                },
                registrationTimes: {
                    type: 'array.registrationTimePicker',
                    displayName: '<<hdcc>>',
                    className: 'presale-range-timepicker',
                    rules: [
                        {
                            message: '活动场次的每个时间段不可重复',
                            validator: (rule: any, value: any) => {
                                const completeStartWithEnd = value
                                    .filter(
                                        (item: any) =>
                                            item.startDate && item.startTime && item.endTime
                                    )
                                    .map((item: any) => ({
                                        start: `${item.startDate} ${item.startTime}`,
                                        end: `${item.startDate} ${item.endTime}`,
                                    }))
                                    .sort((a: any, b: any) => (a.start > b.start ? 1 : -1));

                                return completeStartWithEnd.every((item: any, index: number) => {
                                    if (index === 0) {
                                        return true;
                                    }

                                    const prev = completeStartWithEnd[index - 1];

                                    return item.start >= prev.end;
                                });
                            },
                        },
                    ],
                },
                totalQuantity: {
                    type: 'number.tip',
                    displayName: '报名名额',
                    rules: [
                        { required: true },
                        {
                            type: 'number',
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (value && (!reg.test(value) || value === 0)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 100,
                        },
                        min: 1,
                        addonbefore: '活动总计',
                        addonafter: '个名额',
                        tip: '',
                    },
                },
                limitCountType: {
                    type: 'string.options.radio',
                    displayName: '报名限购',
                    defaultValue: 'YES',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'YES',
                            name: '限购',
                        },
                        {
                            id: 'NO',
                            name: '不限购',
                        },
                    ],
                },
                limitCount: {
                    type: 'number.tip',
                    displayName: '限报数量',
                    defaultValue: 1,
                    rules: [
                        { required: true },
                        {
                            type: 'number',
                            min: 1,
                            message: '请输入大于1的正整数',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (value && (!reg.test(value) || value === 0)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        min: 1,
                        max: 999999999,
                        style: {
                            width: 150,
                        },
                        addonbefore: '每用户',
                        addonafter: '个名额',
                        tip: '',
                    },
                },
                registrationPrice: {
                    type: 'object.registrationPrice',
                    displayName: '报名价格',
                    defaultValue: { type: RegistrationPriceType.NONE, price: '', integral: '' },
                    rules: [
                        { required: true },
                        {
                            message: '请输入报名价格',
                            validator: (rule: any, value: any) => {
                                if (value.type === RegistrationPriceType.PAY) {
                                    if (!value.price && !value.integral) {
                                        return false;
                                    }
                                }
                                return true;
                            },
                        },
                    ],
                },
                targetUserType: {
                    type: 'string.options.radio',
                    displayName: '参与人群',
                    defaultValue: 'ANY',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'ANY',
                            name: '全部用户',
                        },
                        {
                            id: 'SOME',
                            name: '指定用户',
                        },
                    ],
                },
                targetUserLevels: {
                    type: 'array.options.select',
                    displayName: '卡等级',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/mallActivitySetting/userCardLevels',
                        dependences: ['merchants'],
                    },
                    controlConfig: {
                        placeholder: '请选择用户等级',
                    },
                },
                targetUserBirthDay: {
                    type: 'object.monthDayRange',
                    displayName: '<<srfw>>',
                },
                targetUserSexuality: {
                    type: 'string.options.select',
                    displayName: '<<xb_1>>',
                    options: GENDER_OPTIONS,
                    controlConfig: {
                        placeholder: '请选择性别',
                    },
                },
                customInfo: {
                    type: 'array.customForm',
                    displayName: '自定义报名信息',
                    defaultValue: [],
                },
                info: {
                    type: 'string.text.paragraph',
                    displayName: '报名须知',
                    rules: [
                        {
                            whitespace: true,
                            max: 500,
                            message: '请输入报名须知，不超过500个字',
                        },
                    ],
                    controlConfig: {
                        placeholder: '请输入报名须知',
                        rows: 4,
                        maxLength: 500,
                        style: {
                            width: 720,
                        },
                    },
                },
                rewardType: {
                    type: 'string.options.radio',
                    displayName: '奖品发放',
                    defaultValue: 'OFF_LINE',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'OFF_LINE',
                            name: '线下发放奖品',
                        },
                        {
                            id: 'COUPON',
                            name: '优惠券',
                        },
                    ],
                    extra:
                        '选择[线下发送奖品]，报名成功后生成活动二维码，用户凭码参与活动；选择[优惠券]，报名成功后会给用户绑定优惠券。',
                },
                selectCoupon: {
                    displayName: '选择优惠券',
                    defaultValue: [],
                    type: 'array.couponSelectorWithPackage',
                    controlConfig: {
                        disablePrice: true,
                        getSubsiteIds(props: any) {
                            const { row } = props;
                            const subsiteIds =
                                row.merchants && row.merchants.length
                                    ? map(row.merchants, 'subSiteId').join(',')
                                    : null;
                            return subsiteIds;
                        },
                    },
                    rules: [
                        {
                            type: 'array',
                            validator: (rule: any, value: any) => {
                                return true;
                            },
                        },
                    ],
                },
                rewardName: {
                    type: 'string',
                    displayName: '<<jpmc>>',
                    controlConfig: {
                        placeholder: '<<qsrjqmcThan20>>',
                        maxLength: 20,
                        style: {
                            width: 450,
                        },
                    },
                },
                reviewType: {
                    type: 'string.options.radio',
                    displayName: '报名审核',
                    defaultValue: 'FALSE',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [
                        {
                            id: 'FALSE',
                            name: '提交报名成功即为审核通过',
                        },
                        {
                            id: 'TRUE',
                            name: '提交报名后需手工审核',
                        },
                    ],
                    extra: '如需手工审核，审核不通过的用户会自动退款',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                registrationStatus: {
                    type: 'string',
                    displayName: '报名状态',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '活动开始日期',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '活动结束日期',
                },
                blackUserListFileUrl: {
                    type: 'string.lotteryCodeImportExport',
                    displayName: '<<hmd>>',
                    controlConfig: {
                        importExportType: 'BLACK_LIST',
                    },
                },
            },
        },
    },
    components: {
        MallActivitySettingView: {
            component: 'Viewport',
            entity: 'MallActivitySettingEntity',
        },
        MallActivitySettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MallActivitySettingFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '创建活动',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/mall-activity-setting/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MallActivitySettingTable' },
            ],
        },
        MallActivitySettingFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'registrationStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        MallActivitySettingTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'registrationStatus',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOTSTARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'blackUserListFileUrl',
                    displayConfig: {
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/mall_activities/{{row.id}}/user_lists`,
                            importFileSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                                apiPath: '/admin/files/locations/62/upload',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 300,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/mall_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/mall_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/mall-activity-setting/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        text: '活动数据',
                        component: 'MallActivitySettingStatistic',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: '/admin/mall_activities/{{row.id}}/export.xls',
                        },
                    },
                ],
            },
        },
        MallActivitySettingAddPage: {
            component: 'FlexLayout',
            entity: 'MallActivitySettingEntity',
            direction: 'horizontal',
            items: [
                { component: 'MallActivitySettingLeftInfoView' },
                { component: 'MallActivitySettingAddFormView' },
            ],
        },
        MallActivitySettingEditPage: {
            component: 'FlexLayout',
            entity: 'MallActivitySettingEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'MallActivitySettingLeftInfoView' },
                { component: 'MallActivitySettingEditFormView' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mall-activity-setting',
                    component: 'MallActivitySettingView',
                    breadcrumbName: '<<mallActivitySetting>>',
                    privilege: {
                        path: 'mallActivitySetting',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MallActivitySettingAddPage',
                            breadcrumbName: '创建活动',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MallActivitySettingEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'MallActivitySettingPage' },
                    ],
                },
            ],
        },
    ],
};
