import { PureComponent } from 'react';
import { Button } from 'antd';
import React from 'react';
import { services } from '@comall-backend-builder/core';

/**
 * 三方发券统计
 */
export class CouponRulesChannlesInfoButton extends PureComponent<any, any> {
    render() {
        const { row, type } = this.props;
        const url = `#/menu-list/coupon/${type}-coupon-rules/channels/${type}/${row.id}`;
        return (
            row.couponChannelStocks &&
            row.couponChannelStocks.length > 0 && (
                <Button type="link" style={{ paddingLeft: 0 }} href={url}>
                    {services.language.getText('sffqtj')}
                </Button>
            )
        );
    }
}
