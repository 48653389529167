import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        invitationActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/invitations',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'NOT_STARTED',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'STARTED',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 160 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<kqzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                            extra: '<<hdjsbyqrhyqrdbhddjl>>',
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                        // subsiteIds: {
                        //     type: 'array.options.autoComplete',
                        //     className: 'custom-array-options',
                        //     displayName: '<<otherSelectSubsite>>',
                        //     options: [],
                        //     source: {
                        //         apiPath: '/admin/defaults/subsite',
                        //     },
                        //     rules: [
                        //         {
                        //             required: true,
                        //         },
                        //     ],
                        //     controlConfig: {
                        //         placeholder: '<<selectSubsite>>',
                        //     },
                        // },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                rewardLimit: {
                    type: 'string.options.radio',
                    displayName: '<<yqrjl>>',
                    defaultValue: 'NEW_MEMBER',
                    options: [
                        // { id: 'NO_LIMIT', name: '不限制，邀请来新用户、老用户都可获得邀请奖励' },
                        {
                            id: 'NEW_MEMBER',
                            name: '<<jyqldsxyhkhdyqjl>>',
                        },
                    ],
                    extra: '<<yqcgkhdyqlxjl>>',
                    rules: [{ required: true }],
                },
                rewardMethod: {
                    type: 'string.options.radio',
                    displayName: '<<hdms_1>>',
                    defaultValue: 'STEP',
                    options: [
                        // { id: 'FIXED', name: '每邀请成功1名用户获得固定（同样）奖励' },
                        { id: 'STEP', name: '<<lxyqcgdmyhhdbtjtjl>>' },
                    ],
                    rules: [{ required: true }],
                },
                inviterRewards: {
                    type: 'array.activityReward',
                    displayName: '<<jtsz>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/activityRewardItems',
                        paramsFilter: ['INVITE'],
                    },
                    controlConfig: {
                        maxCount: 3,
                        rewardType: 'multi',
                        stepValueTextConfig: {
                            start: '<<lxyqd>>',
                            end: '<<hdyxjl>>',
                            unit: '<<people>>',
                        },
                    },
                },
                invitedToReward: {
                    type: 'object.activityRewardInvited',
                    displayName: '<<byqrjl>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/activityRewardItems',
                        paramsFilter: ['INVITE'],
                    },
                },
                memberType: {
                    type: 'string.options.radio',
                    displayName: '<<xyhjl>>',
                    defaultValue: 'NEW',
                    options: [
                        { id: 'NEW', name: '<<yqldsxyhs>>' },
                        // { id: 'OLD', name: '邀请来的是老用户时，给老用户发放礼物' },
                    ],
                    rules: [{ required: true }],
                },
                rewardCondition: {
                    type: 'string.options.radio.plus',
                    displayName: '<<jltj>>',
                    defaultValue: 'REGISTERED',
                    controlConfig: {
                        setOptions: [
                            {
                                value: 'REGISTERED',
                                name: '<<byqrzhdqjzccwhy>>',
                                tip: {
                                    text: '<<yqrjkhdjl>>',
                                },
                            },
                            // { value: 'ORDERED', name: '被邀请人在活动期间下单成功' },
                            {
                                value: 'SIGNED',
                                name: '<<byqrzhdqjxdqqs>>',
                                tip: {
                                    text: '<<byqrhdjshqs>>',
                                },
                            },
                            // { value: 'NO_AFTER_SALES', name: '被邀请人签收在活动期间下的订单' },
                        ],
                    },
                    rules: [{ required: true }],
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    properties: {
                        // pictureSetting: {
                        //     type: 'object.invitationPictureSetting',
                        //     displayName: '图片设置',
                        // },
                        bottomPictureUrl: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/invitation-activity/bottom-picture-new.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        giftPictureUrl: {
                            displayName: '<<lwtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        buttonPictureUrl: {
                            displayName: '<<anbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/invitation-activity/button-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        markPictureUrl: {
                            displayName: '<<yqjltbbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/invitation-activity/mark-picture-new.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<activityRule>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    max: 1000,
                                    message: '<<qsrhdsm>>',
                                },
                            ],
                            controlConfig: {
                                rows: 4,
                                maxLength: 1000,
                            },
                        },
                        sharePosterPictureId: {
                            displayName: '<<fxhb>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/invitation-activity/share-poster-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        shareSmallPictureId: {
                            displayName: '<<fxxt>>',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/invitation-activity/share-small-picture.png',
                                },
                            ],
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: ENV.TENANT === 'hxgc' ? [] : [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            controlConfig: { placeholder: '<<klgwqhw>>' },
                            rules:
                                ENV.TENANT === 'hxgc'
                                    ? []
                                    : [
                                          {
                                              required: true,
                                              whitespace: true,
                                              message: '<<qsrfxwa>>',
                                          },
                                      ],
                        },
                    },
                },
            },
        },
    },
    components: {
        invitationActivityView: {
            component: 'Viewport',
            entity: 'invitationActivityEntity',
        },
        invitationActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'invitationActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzyqyl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/invitation-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'invitationActivityTable' },
            ],
        },
        invitationActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        invitationActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            // statusKey: 'baseInfo.status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLED' },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/invitations/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.baseInfo.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: 'ENABLED' },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/invitations/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/invitation-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/invitation-activity/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<reportData>>',
                        path: '/menu-list/market/invitation-activity/report/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/invitations/{{row.id}}/export',
                            text: '<<dc>>',
                        },
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/market/invitation-activity/logs/{{row.id}}',
                    },
                ],
            },
        },
        invitationActivityAddPage: {
            component: 'FlexLayout',
            entity: 'invitationActivityEntity',
            direction: 'horizontal',
            items: [
                { component: 'InvitationActivityPreview' },
                { component: 'InvitationActivityAddFormView' },
            ],
        },
        invitationActivityEditPage: {
            component: 'FlexLayout',
            entity: 'invitationActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'InvitationActivityPreview' },
                { component: 'InvitationActivityEditFormView' },
            ],
        },
        invitationActivityCopyPage: {
            component: 'FlexLayout',
            entity: 'invitationActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'InvitationActivityPreview' },
                { component: 'InvitationActivityCopyFormView' },
            ],
        },
        invitationActivityReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'InvitationActivityReportView',
                },
            ],
        },
        invitationActivityLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/invitations/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/invitation-activity',
                    component: 'invitationActivityView',
                    breadcrumbName: '<<yqyl>>',
                    privilege: {
                        path: 'invitationActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'invitationActivityAddPage',
                            breadcrumbName: '<<xzyqyl>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'invitationActivityEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'invitationActivityCopyPage',
                            breadcrumbName: '<<copy>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'invitationActivityReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'invitationActivityLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'invitationActivityPage' },
                    ],
                },
            ],
        },
    ],
};
