import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { BirthdayMessageTime } from './birthday-message-time';

export class BirthdayMessageTimeMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <BirthdayMessageTime {...controlInfo} />;
    }
}
