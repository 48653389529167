import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Switch as AntSwitch } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';

/**
 * 拣货助手 - 订单打印类型选择
 */
class orderLackSwitch extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange = (checked: boolean) => {
        const { entity, params } = this.props;
        entity.search({ ...params, lack: checked });
    };

    render() {
        return (
            <div className="order-lack-switch">
                <AntSwitch
                    style={{ marginRight: 10 }}
                    defaultChecked={false}
                    onChange={this.onChange}
                />
                {services.language.getText('zkqhpd')}
            </div>
        );
    }
}

export const OrderLackSwitch = connect((state, props: any) => {
    const { entity } = props;
    return {
        entity,
    };
})(orderLackSwitch);
