import React, { Component } from 'react';
import { VoiceReminder } from '../../../components';
import './index.less';
import { services } from '@comall-backend-builder/core';

/**
 * 语言消息提醒
 * 处理接口数据，提醒语音，点击消息跳转对应页面
 */

export class NotificationVoice extends Component<any, any> {
    state = {
        visible: true,
        loop: false,
        markRead: false,
        play: false,
        icon: 'sound',
        hasWaitPickOrder: false,
        muted: '',
    };

    componentWillReceiveProps(props: any) {
        if (!props.hasWaitPickOrder) {
            this.setState({
                play: false,
                message: '',
                markRead: true,
            });
        } else {
            this.setState({
                play: true,
                markRead: false,
            });
        }
    }

    shouldComponentUpdate(props: any) {
        if (typeof props.playTimer === 'undefined' || this.props.playTimer === props.playTimer) {
            return false;
        } else {
            return true;
        }
    }

    goTarget = () => {
        const { goto } = this.props;
        goto('waitingPick');
    };

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    timer: any;

    render() {
        const { play, visible, loop, markRead, icon, muted } = this.state;
        const { style } = this.props;
        return (
            <div className="notification-voice" style={style}>
                <VoiceReminder
                    src="./audio/waitpick.mp3"
                    play={play}
                    loop={loop}
                    visible={visible}
                    markRead={markRead}
                    icon={icon}
                    muted={muted}
                />
                {play && (
                    <span className="notification-message">
                        {services.language.getText('nydjhdjhd')}
                    </span>
                )}
            </div>
        );
    }
}
