import React, { PureComponent } from 'react';
import { Modal, message as AntMessage, Checkbox as AntCheckbox } from 'antd';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, forEach, find } from 'lodash';
import {
    DeliveryFreightRuleRegion,
    Region,
    RegionSelectStatus,
} from '../delivery-freight-rule-region';
import { FreightRule } from '../../delivery-freight-rule-set-up';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

interface DeliveryFreightRuleRegionsSelectorProps {
    pageType: string;
    row: any;
    freightRule: FreightRule;
    showDeliveryScopes: Region[];
    changeValues: Region[];
    onChangeshowDeliveryScopes: (regions: Region[]) => void;
    onChangeDeliveryScopes: (regions: Region[]) => void;
}

interface DeliveryFreightRuleRegionsSelectorStates {
    /**
     * 展示的省市内容
     */
    showRegions: Region[];
    /**
     * 在基础上改变的省市内容
     */
    changeRegions: Array<Region>;
    /**
     * 是否展示弹层
     */
    visible: boolean;
    /**
     * 全选按钮的选中状态
     */
    checkAllSelectStatus: RegionSelectStatus;
}

/**
 * 此组件最复杂的是关于城市的选择
 * 1.城市省市区街道都是一级一级获取的/admin/regions/${id}/children
 * 2.如果其他规则已选中的城市省市区街道是置灰的不能再次勾选，获取接口也是一级一级/admin/express_deliveries/regions
 */

export class DeliveryFreightRuleRegionsSelector extends PureComponent<
    DeliveryFreightRuleRegionsSelectorProps,
    DeliveryFreightRuleRegionsSelectorStates
> {
    state = {
        showRegions: [],
        changeRegions: [],
        visible: false,
        checkAllSelectStatus: RegionSelectStatus.NO,
    };

    /**
     * 获取所有城市
     */
    componentDidMount() {
        const { freightRule, onChangeshowDeliveryScopes, showDeliveryScopes } = this.props;
        if (!showDeliveryScopes || (showDeliveryScopes && showDeliveryScopes.length === 0)) {
            const deliveryScopesInfo = freightRule.deliveryScopesInfo;
            const parentId = 0;
            api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/regions/${parentId}/children`,
                }
            ).then((response: any) => {
                //1.获取一级城市数据
                response = response.map((region: any) => {
                    region.selectStatus = RegionSelectStatus.NO;
                    region.availableStatus = true;
                    region.readOnly = false;
                    region.children = [];
                    //2.如果是编辑页面，之前一级选中的状态（勾选，半勾选）循环赋值
                    let regionDeliveryScopesInfo: any = find(deliveryScopesInfo, {
                        id: region.id + '',
                    });
                    if (regionDeliveryScopesInfo) {
                        region.selectStatus = regionDeliveryScopesInfo.selectStatus;
                    }
                    return region;
                });
                this.setState(
                    {
                        showRegions: response,
                    },
                    () => {
                        onChangeshowDeliveryScopes(response);
                    }
                );
            });
        } else {
            this.setState({
                showRegions: showDeliveryScopes,
            });
        }
    }

    /**
     * 弹层确认事件
     */
    onOk = () => {
        const { onChangeshowDeliveryScopes, onChangeDeliveryScopes } = this.props;
        const { showRegions, changeRegions } = this.state;
        if (onChangeshowDeliveryScopes) {
            onChangeDeliveryScopes(changeRegions);
            onChangeshowDeliveryScopes(showRegions);
            this.toggleModal();
        }
    };

    /**
     * 控制弹层展示
     */
    toggleModal = () => {
        const { pageType, row, freightRule } = this.props;
        const { showRegions, visible } = this.state;
        //1.判断门店或专柜是否已选择
        if (
            (pageType === 'subsite' || pageType === 'creditEshop') &&
            row &&
            row.baseInfo &&
            !row.baseInfo.subsite
        ) {
            AntMessage.error(language.getText('selectBeforeMerchant'));
            return false;
        }
        if (
            pageType === 'merchant' &&
            row &&
            row.baseInfo &&
            (!row.baseInfo.merchants ||
                (row.baseInfo.merchants && row.baseInfo.merchants.length === 0))
        ) {
            AntMessage.error(language.getText('qxxzzj'));
            return false;
        }
        let subsiteId;
        let merchantId;
        if (row.baseInfo) {
            if (row.baseInfo.subsite) {
                subsiteId = row.baseInfo.subsite.id;
            }
            if (row.baseInfo.merchants) {
                subsiteId = row.baseInfo.merchants[0].subSiteId;
                merchantId = row.baseInfo.merchants[0].merchantId;
            }
        }
        if (!subsiteId) {
            return;
        }
        //2.判断弹层是新打开还是关闭
        if (visible) {
            this.setState({
                visible: !visible,
            });
            return;
        }
        //3.判断所选择的门店或专柜已配置好的规则，选择过的置灰
        const parentId = 0;
        api.get(
            {
                subsiteId: subsiteId,
                merchantId: merchantId,
                deliveryRuleId: freightRule.deliveryRuleId,
                group: freightRule.group,
                parentRegionId: parentId,
                deliveryRuleType: pageType === 'creditEshop' ? 'POINT' : undefined,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                apiPath: `/admin/express_deliveries/regions`,
            }
        ).then((response: any) => {
            showRegions.map((region: any) => {
                let row: any = find(response, { id: region.id + '' });
                if (row) {
                    region.availableStatus = row.availableStatus;
                    region.readOnly = row.readOnly;
                }
                return region;
            });
            this.setState({
                showRegions: showRegions,
                visible: !visible,
            });
        });
    };

    onChangeShowRegions = (region: Region) => {
        const { showRegions } = this.state;
        const newRegions = showRegions.map((r: any) => {
            if (r.id === region.id) {
                r = region;
            }
            return r;
        });
        this.setState({
            showRegions: cloneDeep(newRegions),
        });
    };

    onChangeRegions = (region: Region) => {
        const { changeRegions } = this.state;
        const idx = changeRegions.findIndex((item: any) => item.id === region.id);
        if (idx > -1) {
            const newRegions = changeRegions.map((r: any) => {
                if (r.id === region.id) {
                    r = region;
                }
                return r;
            });
            this.setState({
                changeRegions: newRegions,
            });
        } else {
            const newChangeRegions: Region[] = changeRegions;
            newChangeRegions.push(region);
            this.setState({
                changeRegions: changeRegions,
            });
        }
    };

    onLoadFirstRegions = () => {
        const { showRegions, checkAllSelectStatus } = this.state;
        const { row, freightRule } = this.props;
        return (
            showRegions && (
                <div className="regions-selector">
                    {showRegions.map((region: Region) => {
                        return (
                            <DeliveryFreightRuleRegion
                                key={region.id}
                                row={row}
                                freightRule={freightRule}
                                parentRegion={region}
                                onChangeRegions={this.onChangeRegions}
                                onChangeShowRegions={this.onChangeShowRegions}
                            />
                        );
                    })}
                    <div className="regions-selector-check-all">
                        <AntCheckbox
                            checked={checkAllSelectStatus === RegionSelectStatus.ALL ? true : false}
                            indeterminate={checkAllSelectStatus === RegionSelectStatus.PART}
                            onChange={this.onCheckAllChange}
                        >
                            {language.getText('qx_1')}
                        </AntCheckbox>
                    </div>
                </div>
            )
        );
    };

    onCheckAllChange = () => {
        const { checkAllSelectStatus, showRegions } = this.state;
        const changeCheckStatus =
            checkAllSelectStatus === RegionSelectStatus.NO
                ? RegionSelectStatus.ALL
                : RegionSelectStatus.NO;
        showRegions.map((parentRegion: any) => {
            if (parentRegion.readOnly || !parentRegion.availableStatus) {
                return parentRegion;
            }
            parentRegion.selectStatus = changeCheckStatus;
            parentRegion.children = parentRegion.children.map((i: any) => {
                if (i.readOnly || !i.availableStatus) {
                    return i;
                }
                i.selectStatus = changeCheckStatus;
                i.children = i.children.map((j: any) => {
                    if (j.readOnly || !j.availableStatus) {
                        return j;
                    }
                    j.selectStatus = changeCheckStatus;
                    j.children = j.children.map((k: any) => {
                        if (k.readOnly || !k.availableStatus) {
                            return k;
                        }
                        k.selectStatus = changeCheckStatus;
                        return k;
                    });
                    return j;
                });
                return i;
            });
            return parentRegion;
        });

        this.setState({
            showRegions: cloneDeep(showRegions),
            changeRegions: cloneDeep(showRegions),
            checkAllSelectStatus: changeCheckStatus,
        });
    };

    onLoadSelectFirstRegions = () => {
        const { showDeliveryScopes } = this.props;
        let showDeliveryScopesText = '';
        if (showDeliveryScopes && showDeliveryScopes.length > 0) {
            let checkedShowDeliveryScopes: Array<string> = [];
            forEach(showDeliveryScopes, (parentRegion) => {
                if (parentRegion.selectStatus === RegionSelectStatus.ALL) {
                    checkedShowDeliveryScopes.push(parentRegion.name + language.getText('qj_2'));
                }
                if (parentRegion.selectStatus === RegionSelectStatus.PART) {
                    checkedShowDeliveryScopes.push(parentRegion.name + language.getText('bfdq'));
                }
            });
            showDeliveryScopesText = checkedShowDeliveryScopes.join('，');
        }

        return showDeliveryScopesText ? (
            <div style={{ width: '250px', lineHeight: '24px' }}>
                <span>{showDeliveryScopesText}</span>
                <div style={{ textAlign: 'right' }}>
                    <span
                        style={{ marginLeft: '5px', color: '#1890ff', cursor: 'pointer' }}
                        onClick={this.toggleModal}
                    >
                        {language.getText('common.modify')}
                    </span>
                </div>
            </div>
        ) : (
            <div style={{ color: '#1890ff', cursor: 'pointer' }} onClick={this.toggleModal}>
                <span>{language.getText('qxtjkpzdqy')}</span>
            </div>
        );
    };

    render() {
        const modalContent = this.onLoadFirstRegions();
        const modalProps = {
            width: 735,
            title: language.getText('xzkpsqy'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                {this.onLoadSelectFirstRegions()}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
