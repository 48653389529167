import React, { useEffect, useState, Fragment } from 'react';
import { Button, Modal, Select, Form, message } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface SelectOption {
    id: string;
    name: string;
    status: 'ENABLE' | 'DISABLE';
}

export const CreditEshopSortSetting: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [currentSelectId, setCurrentSelectId] = useState('');
    const [selectOptionsData, setSelectOptionsData] = useState<SelectOption[]>([]);
    const [updateLoading, setUpdateLoading] = useState(false);

    async function loadSelectOptionsData(): Promise<void> {
        const selectOptionsData = (await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: '/admin/credit_eshop_configs',
            }
        )) as SelectOption[];

        setSelectOptionsData(selectOptionsData);
    }

    async function updateSelectOptionsData(): Promise<void> {
        try {
            setUpdateLoading(true);

            const newSelectOptionsData = selectOptionsData.map((option) => {
                return {
                    ...option,
                    status: option.id === currentSelectId ? 'ENABLE' : 'DISABLE',
                } as const;
            });

            await api.put(
                {
                    configs: newSelectOptionsData,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_eshop_configs',
                }
            );

            setSelectOptionsData(newSelectOptionsData);
            visibleChange();
            message.success(services.language.getText('settingSucess'));
        } finally {
            setUpdateLoading(false);
        }
    }

    function visibleChange(): void {
        setVisible((visible) => !visible);
    }

    function handleVisibleChange(): void {
        visibleChange();
    }

    function handleCurrentSelectIdChange(id: string): void {
        setCurrentSelectId(id);
    }

    function handleUpdateSelectOptionsData(): void {
        updateSelectOptionsData();
    }

    useEffect(() => {
        if (!visible || selectOptionsData.length) {
            return;
        }

        loadSelectOptionsData();
    }, [visible, selectOptionsData]);

    useEffect(() => {
        if (!visible || !selectOptionsData.length) {
            return;
        }

        const { id: currentSelectId } = selectOptionsData.find(
            (option) => option.status === 'ENABLE'
        ) as SelectOption;

        setCurrentSelectId(currentSelectId);
    }, [visible, selectOptionsData]);

    const formElement = (
        <Form layout="inline">
            <Form.Item label={language.getText('mrpxgz')}>
                <Select value={currentSelectId} onSelect={handleCurrentSelectIdChange}>
                    {selectOptionsData.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                            {option.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );

    return (
        <Fragment>
            <Button
                style={{
                    marginLeft: '20px',
                }}
                icon="setting"
                type="primary"
                onClick={handleVisibleChange}
            >
                {language.getText('settings')}
            </Button>
            <Modal
                title={language.getText('settings')}
                okText={language.getText('common.ok')}
                cancelText={language.getText('common.cancel')}
                visible={visible}
                confirmLoading={updateLoading}
                onOk={handleUpdateSelectOptionsData}
                onCancel={handleVisibleChange}
            >
                {formElement}
            </Modal>
        </Fragment>
    );
};
