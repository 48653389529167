import * as React from 'react';
import { Tag, Popover } from 'antd';
import { ArrayMode } from '@comall-backend-builder/types';
import { MailboxSuffixes } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class ArrayMailboxSuffixMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        return (
            <div>
                {value && value.length > 0 ? value[0] : ''}{' '}
                {value && (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {value.map((mailboxSuffix: any, index: number) => (
                                    <Tag key={index} style={{ marginBottom: 10 }}>
                                        {mailboxSuffix}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            {services.language.getText('inAll')}
                            {value.length}
                            {services.language.getText('ge')}
                        </span>
                    </Popover>
                )}
            </div>
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MailboxSuffixes {...controlInfo} />;
    }
}
