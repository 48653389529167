/**
 * 消息群发
 */
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const JQMessageNotifyConfig: Config = {
    entities: {
        MessageNotifyEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
            apiPath: '/loader/admin/business_remind',
            filters: {
                remindType: {
                    type: 'string.options.select',
                    displayName: '<<alarmEvents>>',
                    options: [
                        {
                            id: 'NOTICE_GOODS_COST_PRICE_CHANGE_LARGE',
                            name: '<<noticeGoodsCostPrice>>',
                        },
                        {
                            id: 'NOTICE_GOODS_STOCK_FEW',
                            name: '<<noticeGoodsStockFew>>',
                        },
                        {
                            id: 'NOTICE_GOODS_SUPPLIER_OFF_SHELF',
                            name: '<<noticeGoodsSupplierOffShelf>>',
                        },
                        {
                            id: 'NOTICE_ORDER_CREATE_FAIL',
                            name: '<<noticeOfderCreateFail>>',
                        },
                        {
                            id: 'NOTICE_ORDER_PAYMENT_FAIL',
                            name: '<<paymentFail>>',
                        },
                        {
                            id: 'NOTICE_ORDER_CUSTOM_FAIL',
                            name: '<<orderCustomFail>>',
                        },
                    ],
                },
                keyword: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sendTime>>',
                    controlConfig: {
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
            },
            properties: {
                remindType: {
                    type: 'string',
                    displayName: '<<alarmEvents>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<alarmTime>>',
                },
                message: {
                    type: 'string',
                    displayName: '<<alarmContent>>',
                },
            },
        },
    },
    components: {
        MessageNotifyView: {
            component: 'Viewport',
            entity: 'MessageNotifyEntity',
        },
        MessageNotifyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterFormPlus',
                    className: 'mb24',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 10,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'remindType',
                            controlConfig: {
                                style: { width: 200 },
                            },
                        },
                        {
                            property: 'keyword',
                            controlConfig: {
                                style: { width: 230 },
                            },
                        },
                        {
                            property: 'dateRange',
                            controlConfig: {
                                style: { width: 260 },
                            },
                        },
                    ],
                },
                { component: 'MessageNotifyTable' },
            ],
        },
        MessageNotifyTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'remindType',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'message',
                },
            ],
            actionColumn: {},
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/jq-message-notify',
                    component: 'MessageNotifyView',
                    breadcrumbName: '<<JQMessageNotify>>',
                    privilege: {
                        path: 'businessRemind',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MessageNotifyPage' }],
                },
            ],
        },
    ],
};
