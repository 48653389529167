import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
// import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
// import { EntityButtonProps } from '../../../../components';
// import { ForwardDataCenterModal } from '../../../../services';

export const config: Config = {
    entities: {
        MemberCarNumberConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
            apiPath: '/loader/admin/car_number',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                bindTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<bdsj>>',
                },
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
            },
            properties: {
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<bdsj>>',
                },
            },
        },
    },
    components: {
        MemberCarNumberView: {
            component: 'Viewport',
            entity: 'MemberCarNumberConfig',
        },
        MemberCarNumberPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MemberCarNumberFilter',
                },
                // {
                //     component: 'GridLayout',
                //     className: 'mt24 mb24',
                //     items: [
                //         {
                //             component: 'EntityButton',
                //             props: {
                //                 type: 'primary',
                //                 children: '导出数据',
                //                 style: {
                //                     width: 110,
                //                 },
                //                 onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                //                     const params = Object.assign(
                //                         {},
                //                         entityButtonProps.entity.params,
                //                         entityButtonProps.entity.filters
                //                     );

                //                     if (
                //                         entityButtonProps.entity.filters &&
                //                         (entityButtonProps.entity.filters as any).bindTime
                //                     ) {
                //                         Object.assign(params, {
                //                             startTime: (entityButtonProps.entity.filters as any)
                //                                 .bindTime.start,
                //                             endTime: (entityButtonProps.entity.filters as any)
                //                                 .bindTime.end,
                //                             bindTime: undefined,
                //                         });
                //                     }

                //                     const config = {
                //                         apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
                //                         apiPath: '/admin/car_numbers/export',
                //                     };

                //                     api.get(params, config)
                //                         .then(() => {
                //                             ForwardDataCenterModal();
                //                         })
                //                         .catch((error) => {
                //                             errorHandle(error);
                //                         });
                //                 },
                //             },
                //         },
                //     ],
                // },
                {
                    component: 'Text',
                    text: '<<jyxwsxzdhybdcph>>',
                    style: {
                        color: '#999',
                        marginBottom: 24,
                    },
                },
                {
                    component: 'MemberCarNumberTable',
                },
            ],
        },
        MemberCarNumberFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'carNumber',
                },
                {
                    property: 'bindTime',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        MemberCarNumberTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: true,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                    width: 240,
                },
                {
                    property: 'carNumber',
                },
                {
                    property: 'createTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 190,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
                            handles: [
                                {
                                    value: 'ENABLE',
                                    apiPath: '/admin/car_numbers/delete_carnumber/:id',
                                    loadType: 'put',
                                    config: { content: '<<jb_2>>', text: '<<jb_2>>' },
                                    confirm: {
                                        text: '<<sfqrjb>>{{row.carNumber}}?',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-car-number',
                    component: 'MemberCarNumberView',
                    breadcrumbName: '<<memberCarNumber>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'MemberCarNumberPage',
                        },
                    ],
                    privilege: {
                        path: 'memberCarNumber',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
