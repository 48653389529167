import React, { PureComponent } from 'react';
import { Radio, Checkbox, Input, Button, message as AntMessage } from 'antd';
import { GoogleMap, LoadScript, PolygonF } from '@react-google-maps/api';
import AMapLoader from '@amap/amap-jsapi-loader';
import { services } from '@comall-backend-builder/core';
import { forEach, isEqual, cloneDeep } from 'lodash';
import './index.less';

const enum MapSystem {
    /**
     * 高德
     */
    AMAP = 'AMAP',
    /**
     * 谷歌
     */
    GOOGLE = 'GOOGLE',
}

const { api } = services;
type Geofence = {
    /**
     * 圆形围栏中心点,格式：longitude,latitude
     */
    center?: string;
    /**
     * 围栏名称
     */
    name: string;
    /**
     * 多边形围栏坐标点,格式:lon1,lat1;lon2,lat2;lon3,lat3
     */
    points: string;
    /**
     * 圆形围栏半径,单位：米。范围0~5000
     */
    radius?: number;
};
type GeofenceValue = {
    geofences: Array<Geofence>;
    scope: string;
    isEdit?: boolean;
};
const DEFAULTSCOPE = 'CUSTOM';
const DEFAULTNAME = '默认区域';
interface GeofencesProps {
    onChange: (data: GeofenceValue) => void;
    /**
     * 当前值
     */
    value: GeofenceValue;
    row: any;
}

interface GeofencesStates {
    /**
     * 地图类型
     */
    system: MapSystem;
    /**
     * 地图key
     */
    mapKey: string;
    /**
     * 高德当前地图实例
     */
    map: any;
    /**
     * 高德地图api
     */
    AMap: any;
    /**
     * 高德多边形对象集合
     */
    polygons: any;
    /**
     * 高德门店位置标记
     */
    marker: any;
    /**
     * 当前的门店维度【lng，lat】
     */
    centerPosition: any;
    /**
     * 当前的门店名称
     */
    subsiteName: string;
}

export class Geofences extends PureComponent<GeofencesProps, GeofencesStates> {
    constructor(props: any) {
        super(props);

        this.state = {
            system: MapSystem.AMAP,
            mapKey: '',
            map: undefined,
            AMap: undefined,
            polygons: undefined,
            marker: undefined,
            centerPosition: undefined,
            subsiteName: '',
        };
    }
    /**
     * 是否切换分站，切换分站需重新画区域
     */
    isSubsiteChange = false;

    componentDidMount() {
        this.getConfig();
    }

    componentWillReceiveProps(nextProps: any) {
        const nextSubsiteId = this.getSubsiteId(nextProps);
        const currentSubsiteId = this.getSubsiteId(this.props);
        const isEdit = nextProps.value && nextProps.value.isEdit ? nextProps.value.isEdit : false;
        if (!isEqual(nextSubsiteId, currentSubsiteId) && nextSubsiteId) {
            if (!isEdit) {
                this.isSubsiteChange = true;
            }
            this.initMap(nextProps);
        }
    }

    getConfig = async () => {
        const result: any = await api.get({}, { apiPath: '/admin/amap/config' });
        if (result) {
            this.setState(
                {
                    system: result.system,
                    mapKey: result.key,
                },
                () => {
                    this.initMap(this.props);
                }
            );
        }
    };

    getSubsiteId = (props: any) => {
        const { row } = props;
        const subsiteId = row && row.info && row.info.subsite ? row.info.subsite.id : null;
        return subsiteId;
    };

    initMap = (props: GeofencesProps) => {
        const { map, AMap, mapKey, system } = this.state;
        if (map && AMap) {
            //改变门店后重新渲染新门店的地图，不需要重新走AMapLoader.load
            this.initSubsiteCenterPosition(AMap, map, props);
        } else {
            //高德
            if (system === MapSystem.AMAP) {
                AMapLoader.load({
                    key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ['AMap.Marker', 'AMap.Polygon', 'AMap.PolyEditor'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                })
                    .then((AMap: any) => {
                        let map = new AMap.Map('mapContainer', {
                            zoom: 13,
                        });
                        this.initSubsiteCenterPosition(AMap, map, props);
                        this.setState({ AMap, map });
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
            //谷歌
            if (system === MapSystem.GOOGLE) {
                this.initSubsiteGooleCenterPosition(props);
            }
        }
    };

    //谷歌
    initSubsiteGooleCenterPosition = (props: GeofencesProps) => {
        const subsiteId = this.getSubsiteId(props);
        if (subsiteId) {
            api.get({}, { apiPath: '/admin/subsites/mine/' + subsiteId }).then((result: any) => {
                const centerPosition: any = [result.longitude, result.latitude];
                this.setState({
                    centerPosition: centerPosition,
                    subsiteName: result.name,
                });
                if (!props.value || !props.value.isEdit) {
                    //添加页面选择完门店加载重置
                    const defalutPoints = this.getDefalutPoints(
                        Number(result.longitude),
                        Number(result.latitude)
                    );
                    let newValue: GeofenceValue = {
                        scope: DEFAULTSCOPE,
                        geofences: [
                            {
                                name: DEFAULTNAME,
                                points: defalutPoints,
                            },
                        ],
                    };
                    this.onChange(newValue);
                }
            });
        } else {
            if (!props.value || !props.value.isEdit) {
                //添加页面默认值北京
                this.setState({
                    centerPosition: [116.397228, 39.908815],
                    subsiteName: '',
                });
            }
        }
    };

    //谷歌，根据经纬度获取以它为中心半径为5公里内的矩形的四个点经纬度
    getDefalutPoints = (lng: number, lat: number) => {
        //方法一：不精准
        // const num = 0.014607; //5公里半径维度
        // const path1 = `${lng - num},${lat + num}`;
        // const path2 = `${lng + num},${lat + num}`;
        // const path3 = `${lng + num},${lat - num}`;
        // const path4 = `${lng - num},${lat - num}`;
        // return `${path1};${path2};${path3};${path4}`;

        //方法二
        //数字 111 代表的是地球表面上每度纬度大约对应的公里数。这是一个常用的近似值，用于简化地球表面的计算，尤其是当需要快速估算或不需要非常高精度的场合。
        const radiusKm = 5;
        const latI = radiusKm / 111; //维度增量
        const lngI = radiusKm / (111 * Math.cos((lat * Math.PI) / 180));
        const zs = `${lng + lngI},${lat + latI}`;
        const ys = `${lng - lngI},${lat + latI}`;
        const zx = `${lng - lngI},${lat - latI}`;
        const yx = `${lng + lngI},${lat - latI}`;
        const points = `${zs};${ys};${zx};${yx}`;
        return points;
    };

    //高德
    initSubsiteCenterPosition = (AMap: any, map: any, props: GeofencesProps) => {
        const subsiteId = this.getSubsiteId(props);
        if (subsiteId) {
            api.get({}, { apiPath: '/admin/subsites/mine/' + subsiteId }).then((result: any) => {
                let centerPosition = new AMap.LngLat(result.longitude, result.latitude); // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                this.setState(
                    {
                        centerPosition: centerPosition,
                        subsiteName: result.name,
                    },
                    () => {
                        this.initGeofrences(AMap, map, props);
                    }
                );
            });
        }
    };

    //高德
    initGeofrences = (AMap: any, map: any, props: GeofencesProps) => {
        const { value } = props;
        const { centerPosition, subsiteName } = this.state;
        //移除所有覆盖物
        const { polygons, marker } = this.state;
        if (polygons) {
            map.remove(polygons);
        }
        if (marker) {
            map.remove(marker);
        }

        // 创建一个 Marker 实例：
        let newMarker = new AMap.Marker({
            position: centerPosition,
            title: subsiteName,
            zIndex: 101,
            bubble: true,
        });
        // 将创建的点标记添加到已有的地图实例：
        map.add(newMarker);

        let newPolygons = [];
        let callbackFlag = false;
        let path;
        if (value && value.geofences && !this.isSubsiteChange) {
            value.geofences.forEach((geofence) => {
                if (geofence.points) {
                    let points = geofence.points.split(';');
                    path = points.map((pointStr) => {
                        let point = pointStr.split(',');
                        return new AMap.LngLat(point[0], point[1]);
                    });
                    let polygon = new AMap.Polygon({
                        path: path,
                        strokeColor: '#1791fc',
                        strokeWeight: 6,
                        strokeOpacity: 0.2,
                        fillOpacity: 0.4,
                        fillColor: '#1791fc',
                        zIndex: 100,
                        bubble: true,
                    });
                    newPolygons.push(polygon);
                }
            });
        } else {
            //系统默认会以发货地址（门店地址）为中心给出一个公3里范围的矩形区域
            path = [
                centerPosition.offset(-1500, 1500),
                centerPosition.offset(1500, 1500),
                centerPosition.offset(1500, -1500),
                centerPosition.offset(-1500, -1500),
            ];

            let polygon = new AMap.Polygon({
                path: path,
                strokeColor: '#1791fc',
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: '#1791fc',
                zIndex: 100,
                bubble: true,
            });
            newPolygons.push(polygon);
            callbackFlag = true;
            this.isSubsiteChange = false;
        }

        map.add(newPolygons);
        // 缩放地图到合适的视野级别
        map.setFitView(newPolygons);

        //开启多边形编辑
        newPolygons.forEach((newPolygon, index) => {
            let newPolyEditor = new AMap.PolyEditor(map, newPolygon);
            newPolyEditor.open();

            newPolyEditor.on('addnode', () => {
                this.onPolygonChange(index);
            });

            newPolyEditor.on('adjust', () => {
                this.onPolygonChange(index);
            });

            newPolyEditor.on('removenode', () => {
                this.onPolygonChange(index);
            });
        });

        this.setState({
            map,
            polygons: newPolygons,
            marker: newMarker,
        });
        if (callbackFlag) {
            //回传默认值
            let points = this.getPoints(path);
            let newValue: GeofenceValue = {
                scope: DEFAULTSCOPE,
                geofences: [
                    {
                        name: DEFAULTNAME,
                        points,
                    },
                ],
            };
            this.onChange(newValue);
        }
    };

    onChange = (data: GeofenceValue) => {
        const { onChange } = this.props;
        onChange(cloneDeep(data));
    };

    //高德
    onPolygonChange = (index: number) => {
        let { value } = this.props;
        const { polygons } = this.state;
        let geofences;
        let geofence;
        if (value) {
            geofences = value.geofences;
            geofence = geofences[index];

            let points;
            if (polygons && polygons[index]) {
                points = this.getPoints(polygons[index].getPath());
            }
            geofence = { ...geofence, points };

            geofences.splice(index, 1, geofence);

            value = { ...value, geofences: geofences };
            this.onChange(value);
        }
    };

    onChangeScope = () => {};

    onNameChange = (event: any, index: number) => {
        let { value } = this.props;
        let geofences;
        let geofence;
        if (value) {
            geofences = value.geofences;
            geofence = geofences[index];
            geofence = { ...geofence, name: event.target.value };

            geofences.splice(index, 1, geofence);

            value = { ...value, geofences: geofences };
            this.onChange(value);
        }
    };

    getPoints = (path: any) => {
        return path
            .map((point: any) => {
                return point.lng + ',' + point.lat;
            })
            .join(';');
    };

    addPolygon = () => {
        const { AMap, map, centerPosition, system } = this.state;
        const { value } = this.props;
        if (value && value.geofences && value.geofences.length >= 10) {
            AntMessage.warning('仅支持最多配置10个自定义范围');
            return;
        }
        if (system === MapSystem.AMAP && AMap && map && centerPosition) {
            const path = [
                centerPosition.offset(-1500, 1500),
                centerPosition.offset(1500, 1500),
                centerPosition.offset(1500, -1500),
                centerPosition.offset(-1500, -1500),
            ];
            const points = this.getPoints(path);
            let geofences = value.geofences;
            geofences.push({
                name: DEFAULTNAME,
                points,
            });
            value.geofences = geofences;
            this.onChange(value);
            this.initGeofrences(AMap, map, this.props);
        }
        if (system === MapSystem.GOOGLE) {
            const lng = Number(centerPosition[0]);
            const lat = Number(centerPosition[1]);
            const defalutPoints = this.getDefalutPoints(lng, lat);
            let geofences = value.geofences;
            geofences.push({
                name: DEFAULTNAME,
                points: defalutPoints,
            });
            value.geofences = geofences;
            this.onChange(value);
        }
    };

    deletePolygon = (index: number) => {
        let { value } = this.props;
        const { AMap, map, system } = this.state;
        if (system === MapSystem.AMAP) {
            if (value && map) {
                const newGeofences = value.geofences.slice();
                newGeofences.splice(index, 1);
                value.geofences = newGeofences;
                this.onChange(value);
                setTimeout(() => {
                    map.clearMap();
                    this.initGeofrences(AMap, map, this.props);
                }, 1000);
            }
        }
        if (system === MapSystem.GOOGLE) {
            const newGeofences = value.geofences.slice();
            newGeofences.splice(index, 1);
            value.geofences = newGeofences;
            this.onChange(value);
        }
    };

    //谷歌
    isEditIng = false;
    onGoogleChange = (e: any, index: number) => {
        if (this.isEditIng) {
            return;
        }
        this.isEditIng = true;
        const paths = e.getPaths().getArray();
        const pathsString: Array<string> = [];
        if (paths && paths.length > 0) {
            forEach(paths, (path) => {
                forEach(path.getArray(), (latLng) => {
                    const lng = latLng.lng();
                    const lat = latLng.lat();
                    pathsString.push(`${lng.toFixed(6)},${lat.toFixed(6)}`);
                });
            });
        }
        const { value } = this.props;
        if (value && value.geofences && value.geofences.length > 0) {
            const newValue = cloneDeep(value);
            newValue.geofences[index].points = pathsString.join(';');
            this.onChange(newValue);
        }
        this.isEditIng = false;
    };

    renderGoogle = () => {
        const { mapKey, centerPosition } = this.state;
        if (!mapKey || !centerPosition || centerPosition.length !== 2) {
            return null;
        }
        const { value } = this.props;
        const lng = Number(centerPosition[0]);
        const lat = Number(centerPosition[1]);
        const paths: any = [];
        if (value && value.geofences) {
            forEach(value.geofences, (geofence, index) => {
                const path: Array<any> = [];
                const points = geofence.points.split(';');
                forEach(points, (pointStr) => {
                    let point = pointStr.split(',');
                    path.push({
                        lng: Number(point[0]),
                        lat: Number(point[1]),
                    });
                });
                paths[index] = path;
            });
        }
        const center = { lng, lat };
        return (
            <div className="map-container">
                <LoadScript googleMapsApiKey={mapKey}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '500px' }}
                        center={center}
                        zoom={13}
                    >
                        {paths &&
                            paths.length > 0 &&
                            paths.map((path: any, index: number) => {
                                return (
                                    <PolygonF
                                        path={path}
                                        editable={true}
                                        draggable={true}
                                        onEdit={(e) => {
                                            this.onGoogleChange(e, index);
                                        }}
                                    />
                                );
                            })}
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    };

    renderAMap = () => {
        return <div className="map-container" id="mapContainer"></div>;
    };

    render() {
        const { system } = this.state;
        const { value } = this.props;
        const geofences = value && value.geofences ? value.geofences : [];
        const scope = value && value.scope ? value.scope : DEFAULTSCOPE;
        return (
            <div className="geofences">
                <Radio.Group onChange={this.onChangeScope} value={scope}>
                    <Radio value={'CUSTOM'}>自定义配送范围</Radio>
                </Radio.Group>
                <div className="scope-tip">
                    可以自定义半径5公里内的区域，收货地址在配送范围外的买家，不可以选择下单
                </div>
                <div className="geofences-wrap">
                    {system === MapSystem.AMAP && this.renderAMap()}
                    {system === MapSystem.GOOGLE && this.renderGoogle()}
                    {geofences && geofences.length > 0 && (
                        <div className="setting-wrap">
                            <div className="setting-geofences-wrap">
                                {geofences.map((geofence, index) => {
                                    return (
                                        <Checkbox key={index} checked>
                                            <Input
                                                onChange={(e) => {
                                                    this.onNameChange(e, index);
                                                }}
                                                style={{ width: 145 }}
                                                value={geofence.name}
                                                maxLength={10}
                                            />
                                            {index !== 0 && (
                                                <span
                                                    className="setting-delete"
                                                    onClick={this.deletePolygon.bind(this, index)}
                                                >
                                                    {services.language.getText('common.delete')}
                                                </span>
                                            )}
                                        </Checkbox>
                                    );
                                })}
                            </div>
                            <Button type="default" className="add-btn" onClick={this.addPolygon}>
                                添加配送区域
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
