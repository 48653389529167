import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { getUrlParam } from '../../applications/cae/services/utils';
import { language } from '@comall-backend-builder/core/lib/services';

function getDefaultValue() {
    const subsiteId = getUrlParam(window.location.hash, 'subsiteId');
    return subsiteId
        ? {
              id: subsiteId,
          }
        : {};
}

export const config: Config = {
    entities: {
        MerchantUserEntity: {
            apiPath: '/loader/admin/merchant_users',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<belongSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                merchantCode: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<belongMerchant>>',
                },
                merchantCode: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                merchants: {
                    type: 'array.shop.selector',
                    displayName: '<<selectSubsites>>',
                    controlConfig: {
                        type: 'radio',
                        fields: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                        ],
                    },
                    rules: [
                        {
                            message: '<<pleaseSelectMerchant>>',
                            validator: (rule: any, value: any) => {
                                if (value) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<belongSubsite>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    rules: [
                        {
                            required: true,
                            message: '<<pleaseRightMobile>>',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<pleaseClerkMobile>>',
                        style: { width: 300 },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        placeholder: '<<pleaseClerkName>>',
                        maxLength: 10,
                        style: { width: 300 },
                    },
                },
                avatar: {
                    displayName: '<<clerkAvater>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: '<<avatarExtra>>',
                },
                wechatCardPicture: {
                    displayName: '<<enterpriseQrcode>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: '<<avatarExtra>>',
                },
                status: {
                    type: 'string.options.radio',
                    displayName: '<<status>>',
                    defaultValue: true,
                    options: [
                        { id: 'ENABLED', name: '<<enable>>' },
                        { id: 'DISABLED', name: '<<disabled>>' },
                    ],
                },
            },
        },
    },
    components: {
        MerchantUserView: {
            component: 'Viewport',
            entity: 'MerchantUserEntity',
        },
        MerchantUserPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MerchantUserFilter',
                },
                { component: 'MerchantUserTable' },
            ],
        },
        MerchantUserFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                    defaultValue: getDefaultValue(),
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'merchantName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'merchantCode',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        MerchantUserTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<addClerk>>',
                    route: '/merchant-user/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(language.getText('total'), { total });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'merchantCode',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    ENABLED: {
                                        text: '<<enable>>',
                                        status: 'success',
                                    },
                                    DISABLED: {
                                        text: '<<disabled>>',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: 'ENABLED',
                                            apiPath: '/admin/merchant_users/:id/status',
                                            config: {
                                                content: '<<disabled>>',
                                                text: '<<disabled>>',
                                            },
                                            confirm: {
                                                text: '<<isDisabled>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: 'DISABLED',
                                            apiPath: '/admin/merchant_users/:id/status',
                                            config: { content: '<<enable>>', text: '<<enable>>' },
                                            confirm: {
                                                text: '<<isEnable>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/merchant-user/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MerchantUserAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MerchantUserEntity',
            items: [
                {
                    component: 'MerchantUserAddForm',
                },
            ],
        },
        MerchantUserAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'name',
                },
                { property: 'mobile' },
                { property: 'avatar' },
                { property: 'wechatCardPicture' },

                {
                    property: 'merchants',
                },
                {
                    property: 'status',
                    displayName: '<<enabledStatus>>',
                    type: 'boolean.switch',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantUserEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MerchantUserEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'MerchantUserEditForm',
                },
            ],
        },
        MerchantUserEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'name',
                },
                { property: 'mobile' },
                { property: 'avatar' },
                { property: 'wechatCardPicture' },
                {
                    property: 'merchants',
                },
                {
                    property: 'status',
                    displayName: '<<enabledStatus>>',
                    type: 'boolean.switch',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-user',
                    component: 'MerchantUserView',
                    breadcrumbName: '<<merchantUser>>',
                    privilege: {
                        path: 'merchantUser',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'MerchantUserEditPage',
                            breadcrumbName: '<<editUser>>',
                        },
                        {
                            path: '/add',
                            component: 'MerchantUserAddPage',
                            breadcrumbName: '<<addUser>>',
                        },
                        { path: '/', component: 'MerchantUserPage' },
                    ],
                },
            ],
        },
    ],
};
