import React, { Component } from 'react';
import { default as Radio, RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';
import { find } from 'lodash';
import './index.less';

import { PrintTempalteType } from '../../../../config/settings/setting/print-setting';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export interface RadioGroupValue {
    type: string;
    fontSize?: string;
}

export interface ObjectImageRadioPrintProps extends Pick<AntRadioGroupProps, 'value' | 'disabled'> {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: RadioGroupValue;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: RadioGroupValue, name: string) => void;
}

const options = [
    {
        id: PrintTempalteType.TEMP1,
        name: 'h_template_one',
        image: '../../../images/editor/print-template-one.png',
    },
    {
        id: PrintTempalteType.TEMP2,
        name: 'h_template_two',
        image: '../../../images/editor/print-template-two.png',
        fontSizes: ['12px', '16px', '18px'],
    },
    {
        id: PrintTempalteType.TEMP3,
        name: 'h_template_three',
        image: '../../../images/editor/print-template-three.png',
        fontSizes: ['12px', '16px', '18px'],
    },
];

const tips = ['templateIntro1', 'templateIntro2'];

export class ObjectImageRadioPrint extends Component<ObjectImageRadioPrintProps> {
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            onChange(
                {
                    type: options[0].id,
                    fontSize: options[0].fontSizes ? options[0].fontSizes[0] : undefined,
                },
                name
            );
        }
    }
    onChange = (e: any) => {
        const { name, onChange } = this.props;
        const parent = find(options, { id: e.target.value });
        if (parent) {
            onChange(
                {
                    type: e.target.value,
                    fontSize: parent.fontSizes ? parent.fontSizes[0] : undefined,
                },
                name
            );
        }
    };
    onChildrenChange = (e: any) => {
        const {
            name,
            onChange,
            value: { type },
        } = this.props;
        if (onChange) {
            onChange(
                {
                    type,
                    fontSize: e.target.value,
                },
                name
            );
        }
    };

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const parantValue = value.type;
        const parent: any = find(options, { id: parantValue });
        return (
            <div>
                <div>
                    <Radio.Group value={value.type} onChange={this.onChange}>
                        {options.map((option) => {
                            return <Radio value={option.id}>{getText(option.name)}</Radio>;
                        })}
                    </Radio.Group>
                </div>
                {parent && parent.fontSizes && (
                    <div>
                        <span>{getText('xpzh')}：</span>
                        <Radio.Group value={value.fontSize} onChange={this.onChildrenChange}>
                            {parent.fontSizes.map((size: any) => {
                                return <Radio value={size}>{size}</Radio>;
                            })}
                        </Radio.Group>
                    </div>
                )}
                {parent && parent.image && (
                    <div>
                        <img className="image-radio-preview" src={parent.image} alt="" />
                    </div>
                )}
                {tips &&
                    tips.map((tip) => {
                        return <div className="image-radio-tip">{getText(tip)}</div>;
                    })}
            </div>
        );
    }
}
