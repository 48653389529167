import React, { PureComponent, Fragment } from 'react';
import { map } from 'lodash';
import { Select, Cascader } from 'antd';
import { services } from '@comall-backend-builder/core';

export class LinkTypeSelector extends PureComponent<any> {
    changeCascader = (value: any) => {
        const { onChange } = this.props;
        onChange(value[value.length - 1]);
    };

    /**
     * 根据子节点拿到树全路径，所有叶子节点id必须唯一
     * @param nodes
     * @param leafValue
     */
    treeValues = (nodes: any, leafValue: any) => {
        const result: any = [];
        function treePath(roots: any) {
            let found = false;
            roots.forEach((node: any) => {
                if (node.id === leafValue) {
                    result.push(node.id);
                    found = true;
                    return;
                }
                if (node.children && node.children.length > 0) {
                    result.push(node.id);
                    if (treePath(node.children)) {
                        found = true;
                    } else {
                        result.pop();
                    }
                }
            });
            return found;
        }
        treePath(nodes);
        return result;
    };

    renderCascader = () => {
        const { value, linkTypes } = this.props;
        let selectValue = value ? value.linkType : 'none';
        const values = this.treeValues(linkTypes, selectValue);
        return (
            <Cascader
                placeholder={services.language.getText('selectPlease')}
                className="link-type"
                fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                options={linkTypes}
                value={values}
                onChange={this.changeCascader}
            ></Cascader>
        );
    };

    render() {
        const { value, linkTypes, onChange, linkTypeMode = 'select' } = this.props;
        let selectValue = value ? value.linkType : 'none';
        return (
            <Fragment>
                {linkTypeMode === 'select' && (
                    <Select
                        placeholder={services.language.getText('selectPlease')}
                        className="link-type"
                        value={selectValue}
                        onChange={onChange}
                    >
                        {map(linkTypes, function(type) {
                            return (
                                <Select.Option key={type.id} value={type.id}>
                                    {type.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                )}
                {linkTypeMode === 'cascader' && this.renderCascader()}
            </Fragment>
        );
    }
}
