import React, { FC, ReactElement } from 'react';
import { Radio, Tooltip } from 'antd';
import classNames from 'classnames';
import { RadioChangeEvent } from 'antd/lib/radio';
import './index.less';

export interface ButtonRadioOption {
    label: string;
    icon?: string;
    fontSize?: string;
    activeIcon?: string;
    renderInner?(checked: boolean): ReactElement;
    value: string | number;
    valueText?: string | number;
    disabled?: boolean;
}

interface ButtonRadioProps {
    value?: string | number;
    onChange?(e: RadioChangeEvent): void;
    /**
     * 当mode为inline时是否在左侧显示选中的值
     */
    showValue?: boolean;
    options: ButtonRadioOption[];
    defaultValue?: string;
    size?: 'small' | 'middle' | 'large';
    mode?: 'inline' | 'horizontal';
    className?: string;
}

const ButtonRadio: FC<ButtonRadioProps> = (props) => {
    const {
        options,
        defaultValue,
        value,
        onChange,
        mode = 'horizontal',
        showValue = false,
        className,
    } = props;
    const groupStyle = mode === 'horizontal' ? { flex: 1 } : {};
    const checkedOption = options.find((option) => option.value === value);
    const checkedOptionsLabel = checkedOption?.valueText ?? checkedOption?.label ?? '未选择';

    const radioGroupClassName = classNames('button-radio', `button-radio--${mode}`);
    return (
        <div className="button-radio-wrapper">
            {mode === 'inline' && (
                <div className="button-radio__value">{showValue ? checkedOptionsLabel : ''}</div>
            )}
            <div style={groupStyle}>
                <Radio.Group
                    onChange={onChange}
                    defaultValue={defaultValue}
                    value={value}
                    className={radioGroupClassName}
                >
                    {options.map((option) => {
                        const tooltipVisible = option.icon ? undefined : false;
                        const checked = option.value === value;
                        return (
                            <Tooltip
                                key={option.value}
                                title={option.label}
                                placement="bottom"
                                overlayClassName="button-radio"
                                trigger="hover"
                                visible={tooltipVisible}
                            >
                                <Radio.Button
                                    value={option.value}
                                    className={className}
                                    disabled={option.disabled}
                                >
                                    <ButtonRadioOptionInner {...option} checked={checked} />
                                </Radio.Button>
                            </Tooltip>
                        );
                    })}
                </Radio.Group>
            </div>
        </div>
    );
};

const ButtonRadioOptionInner: FC<ButtonRadioOption & { checked: boolean }> = ({
    checked,
    label,
    renderInner,
}) => {
    if (typeof renderInner === 'function') {
        return renderInner(checked);
    }
    return <span className="button-radio__option__label">{label}</span>;
};

export default ButtonRadio;
