import React, { Component } from 'react';
// import { DateRangePicker } from '@comall-backend-builder/components-basis';
import { DateTimeRangePickerPlus } from '../date-time-range-plus';

export class DateTimeRangeTip extends Component<any, any> {
    render() {
        const { tip } = this.props;

        return (
            <div>
                <DateTimeRangePickerPlus {...this.props} />
                {tip && (
                    <div {...this.props} style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                        {tip}
                    </div>
                )}
            </div>
        );
    }
}
