import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';
import { ConsumeType } from '../../config/promotion/coupon/coupon-consume-history';

const api = services.api;
export const couponConsumeHistoryLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/consume_order_items';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (data.consumeMethod === ConsumeType.ALL) {
            data = {
                ...data,
                consumeMethod: undefined,
            };
        }
        if (!data.mobile) {
            delete data.mobile;
        }
        return await api.get(data, config);
    },
};
