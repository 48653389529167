import React, { PureComponent } from 'react';
import { Button, Upload, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
const { api } = services;

export default class CardLevelUploadButton extends PureComponent<any, any> {
    render() {
        const { value, onChange, style: imgStyle } = this.props;

        const fileList: any = [];
        const uploadProps = {
            accept: 'image/jpg, image/png, image/gif, image/jpeg',
            maxCount: 1,
            multiple: false,
            fileName: 'files',
            uploadType: 'multi',
            mode: 'picture-card',
            beforeUpload: (file: any) => {
                const maxSize = 2 * 1024 * 1024;
                if (file.size > maxSize) {
                    AntMessage.warning('图片超出2M限制');
                    return false;
                }
                AntMessage.config({ duration: 20 });
                AntMessage.info('上传图片中，请稍后～');
                api.upload(
                    { files: file },
                    {
                        apiRoot: `${ENV.API_ROOT}/admin/images/locations/90/upload`,
                        apiPath: '',
                        fileName: 'files',
                        progressCallBack: () => {},
                    }
                )
                    .then((res) => {
                        onChange(res.path);
                    })
                    .catch((err) => {
                        if (err) {
                            AntMessage.error(
                                err.message || services.language.getText('uoloadFail')
                            );
                        }
                    })
                    .finally(() => {
                        AntMessage.config({ duration: 3 });
                        AntMessage.destroy();
                    });
                return false;
            },
            fileList,
        };
        const style = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
        return (
            <div style={style}>
                {value ? <img src={value} style={imgStyle} alt="" /> : null}
                <Upload {...uploadProps}>
                    <Button type="link">
                        {value ? '修改' : services.language.getText('upload')}
                    </Button>
                </Upload>
            </div>
        );
    }
}
