/**
 * @author Chanphy
 * @description 拼团活动
 */
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

const { getText } = services.language;

const groupBuyingLimitIllustrates: { [str: string]: any } = {
    ONLY_NEW: '<<xkzwzptcgzfgdddyh>>',
    NOT_LIMITED: '',
};

export const config: Config = {
    entities: {
        GroupBuyingEntity: {
            apiPath: '/loader/admin/group-buying',
            apiRoot: `${ENV.AUTH_API_ROOT}/GROUP-BUYING`,
            properties: {
                id: {
                    type: 'string',
                },
                baseSettings: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<ptmc>>',
                            controlConfig: {
                                placeholder: '<<qsrptmc>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                            rules: [{ required: true }],
                        },

                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<activityMerchant>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseSettings?.subsites?.length &&
                                        row.baseSettings?.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                        // subsites: {
                        //     type: 'array.options.autoComplete',
                        //     displayName: '<<activityMerchant>>',
                        //     options: [],
                        //     source: {
                        //         apiPath: '/admin/defaults/subsite',
                        //     },
                        //     rules: [{ required: true }],
                        //     controlConfig: {
                        //         style: {
                        //             width: 450,
                        //         },
                        //     },
                        // },
                    },
                },

                groupBuyingSettings: {
                    type: 'object.subForm',
                    displayName: '<<ptsz>>',
                    properties: {
                        groupBuyingType: {
                            type: 'string.options.radio',
                            displayName: '<<ptlx>>',
                            defaultValue: 'GENERAL',
                            options: [
                                { id: 'GENERAL', name: '<<ptt>>' },
                                // { id: 'LADDER', name: '阶梯团' },
                            ],
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },

                        groupBuyingLimit: {
                            type: 'string.radioIllustrate',
                            displayName: '<<ctxz>>',
                            defaultValue: 'NOT_LIMITED',
                            name: JSON.stringify(groupBuyingLimitIllustrates),
                            options: [
                                { id: 'NOT_LIMITED', name: '<<bxz>>' },
                                { id: 'ONLY_NEW', name: '<<xxk>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                illustrate: groupBuyingLimitIllustrates,
                                style: { display: 'flex', paddingTop: 9 },
                            },
                        },

                        quantity: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<ptrs>>',
                            name: JSON.stringify({
                                fill: '<<ptmValue>>',
                                empty: '<<wszptrs>>',
                            }),
                            rules: [{ required: true }],
                            controlConfig: {
                                style: { width: 150 },
                                min: 1,
                                addonbefore: '<<ptm>>',
                                addonafter: '<<people>>',
                                tip: '',
                            },
                        },

                        groupBuyingTime: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<ctsj>>',
                            name: JSON.stringify({
                                fill: '<<groupBuyingTimeFill>>',
                                empty: '<<wszktsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 2376) {
                                                callback(getText('zdzcsz'));
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 150 },
                                min: 1,
                                max: 2376,
                                placeholder: '<<mrkth>>',
                                addonbefore: '<<kth>>',
                                addonafter: '<<xsnmzptrs>>',
                                tip: '<<ktcghksjsctsj>>',
                            },
                        },

                        hasAutoGroupBuyingTime: {
                            type: 'array.groupBuyingCheckbook',
                            displayName: '<<zdct>>',
                            defaultValue: [],
                            options: [{ id: '1', name: '' }],
                        },
                        autoGroupBuyingTime: {
                            type: 'number.tip.groupBuying',
                            displayName: ' ',
                            name: JSON.stringify({
                                fill: '<<ktcghzdct>>',
                                empty: '<<wszzdctsj>>',
                            }),
                            controlConfig: {
                                style: { width: 150 },
                                min: 1,
                                max: 2376,
                                placeholder: '',
                                addonbefore: '<<ktcgh>>',
                                addonafter: '<<xszdct>>',
                                tip: '<<zdctExtra>>',
                            },
                        },
                        hasAutoGroupBuyingQuantity: {
                            type: 'array.groupBuyingCheckbook',
                            displayName: ' ',
                            defaultValue: [],
                            options: [{ id: '1', name: '' }],
                        },
                        autoGroupBuyingQuantity: {
                            type: 'number.tip.groupBuying',
                            displayName: ' ',
                            name: JSON.stringify({
                                fill: '<<ktcghzdct>>',
                                empty: '<<wszzdctrs>>',
                            }),
                            controlConfig: {
                                style: { width: 150 },
                                min: 1,
                                max: 2376,
                                placeholder: '',
                                addonbefore: '<<ktcgh>>',
                                addonafter: '<<rzdct>>',
                                tip: '<<ktcghptrsm>>',
                            },
                        },
                    },
                },

                goodsSettings: {
                    type: 'object.subForm',
                    displayName: '<<ptsp_1>>',
                    properties: {
                        goods: {
                            type: 'array.groupBuyingGoods',
                            displayName: '<<ptsp_1>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        products: {
                            type: 'array.groupBuyingProducts',
                            displayName: '<<ptsp_1>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        saleLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<gmxz>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'NO', name: '<<bxz>>' },
                                { id: 'YES', name: '<<xz>>' },
                            ],
                        },
                        saleLimitNum: {
                            type: 'number.tip',
                            displayName: '<<limitCount>>',
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<jzctxzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<mgyhgmsl>>',
                                addonafter: '<<jian>>',
                                tip: '',
                            },
                        },
                    },
                },

                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                groupProductType: {
                    type: 'string.options.select',
                    displayName: '<<ptsp_1>>',
                    options: [
                        { id: 'GENERAL_PRODUCT', name: '<<ptsp>>' },
                        { id: 'COUPON', name: '<<yhq>>' },
                        { id: 'ASSEMBLE_PRODUCT', name: '<<ptzhsp>>' },
                        { id: 'ASSEMBLE_COUPON', name: '<<xnzhsp>>' },
                        { id: 'VIRTUAL_GOODS', name: '<<xnsp>>' },
                    ],
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                groupSubsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                groupBuyingStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'BEFORE_START',
                    options: [
                        { id: 'BEFORE_START', name: '<<notStarted>>' },
                        { id: 'GONGING', name: '<<inprogress>>' },
                        { id: 'END', name: '<<end>>' },
                    ],
                },

                groupBuyingAdvancedSettings: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    properties: {
                        togetherFlag: {
                            type: 'array.groupBuyingCheckbook',
                            displayName: '<<cypt>>',
                            name: '<<zspxqyzswpcdt>>',
                            defaultValue: ['1'],
                            options: [{ id: '1', name: '<<zspxqyzswpcdt>>' }],
                        },
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                groupBuyingStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'BEFORE_START', name: '<<notStarted>>' },
                        { id: 'GONGING', name: '<<inprogress>>' },
                        { id: 'END', name: '<<end>>' },
                    ],
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>' },
                },
                dateRange: {
                    type: 'object.dateTimeRange.tip',
                    displayName: '<<acitivityTime>>',
                },
            },
        },

        GroupBuyingGoodsSelector: {
            apiPath: '/loader/admin/group-buying/goods-selector',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },

                goodsMvo: {
                    type: 'object',
                    properties: {
                        productName: {
                            type: 'string.tip',
                            displayName: '<<components.Products.name>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<goodName>>',
                        },
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchantName>>',
                        },
                        stock: {
                            type: 'number',
                            displayName: '<<sjkc>>',
                        },
                        spuCode: {
                            type: 'string',
                            displayName: '<<spbh>>',
                        },
                        csku: {
                            type: 'string',
                            displayName: '<<hpbh>>',
                        },
                    },
                },
                barcode: {
                    type: 'string',
                    displayName: '<<spewm>>',
                },
                brand: {
                    type: 'string',
                    displayName: '<<brand>>',
                },
                brandName: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },

                productName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                productCode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<goodCode>>',
                },

                batchNo: {
                    type: 'string',
                    displayName: '<<couponBaCode>>',
                },
                couponTypeDes: {
                    type: 'string',
                    displayName: '<<couponType>>',
                },
                sourceDesc: {
                    type: 'string',
                    displayName: '<<setCouponType>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<couponStartTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<couponEndTime>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
                keywords: {
                    type: 'string',
                    displayName: '<<keywordsSearch>>',
                    controlConfig: {
                        placeholder: '<<keywordsPlaceholder>>',
                        style: { width: 300 },
                    },
                },

                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    displayName: '<<merchantName>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
            },
        },
    },
    components: {
        GroupBuyingFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'groupBuyingStatus',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'subsiteName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: '100%' },
                    },
                },
            ],
        },

        GroupBuyingTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'groupProductType',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'groupSubsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'groupBuyingStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'ENABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/GROUP-BUYING`,
                                    apiPath: '/admin/group_buying_rules/:id/close',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<bqykdthdjwfzct>>',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: 'DISABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/GROUP-BUYING`,
                                    apiPath: '/admin/group_buying_rules/:id/open',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/group-buying/edit/{{row.id}}',
                    },
                    {
                        text: '<<copy>>',
                        type: 'link',
                        path: '/menu-list/market/group-buying/copy/{{row.id}}',
                    },
                    {
                        text: '<<reportData>>',
                        type: 'link',
                        path: '/menu-list/market/group-buying/activity-data/{{row.id}}',
                    },
                    {
                        text: '<<common.view>>',
                        type: 'link',
                        path: '/menu-list/market/group-buying/detail/{{row.id}}',
                    },
                ],
            },
        },

        GroupBuyingView: {
            component: 'Viewport',
        },

        GroupBuying: {
            entity: 'GroupBuyingEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        {
                            component: 'GroupBuyingFilter',
                        },
                        {
                            component: 'Button',
                            text: '<<components.GroupBuying.add>>',
                            type: 'primary',
                            icon: 'plus',
                            route: '/menu-list/market/group-buying/add',
                            style: {
                                flex: 'none',
                                width: '150px',
                            },
                            className: 'mt24 mb24',
                        },
                    ],
                },
                {
                    component: 'GroupBuyingTable',
                },
            ],
        },

        GroupBuyingAddPage: {
            component: 'GroupBuyingAddView',
            entity: 'GroupBuyingEntity',
        },

        GroupBuyingData: {
            component: 'GroupBuyingAddView',
            entity: 'GroupBuyingEntity',
            loaderType: 'get',
            modifiable: false,
        },

        GroupBuyingEditPage: {
            component: 'GroupBuyingAddView',
            entity: 'GroupBuyingEntity',
            loaderType: 'get',
        },
        GroupBuyingCopyPage: {
            component: 'GroupBuyingAddView',
            entity: 'GroupBuyingEntity',
            loaderType: 'get',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/group-buying',
                    breadcrumbName: '<<pthd>>',
                    component: 'GroupBuyingView',
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'GroupBuyingAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'GroupBuyingEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'GroupBuyingCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/detail/:id',
                            component: 'GroupBuyingData',
                            breadcrumbName: '<<hdxq>>',
                        },
                        {
                            path: '/activity-data/:id',
                            component: 'GroupBuyingActivityData',
                            breadcrumbName: '<<viewData>>',
                        },
                        { path: '/', component: 'GroupBuying' },
                    ],
                },
            ],
        },
    ],
};
