import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectProductCategory, ProductCategorySelectedDisplay } from '../../../components';

/**
 * 选择商品组件-选择完以商品款式的维度展示（一期支持商品维度，后期会随着需求改）
 */
export class ProductCategorySelector extends modes.ArrayMode {
    getDisplayComponent(value: any, displayInfo: any) {
        return (
            value && (
                <ProductCategorySelectedDisplay
                    data={value.values}
                    modifiable={false}
                    onChange={() => {}}
                    type={value.type}
                    productDisplaySelectValueType={displayInfo.productDisplaySelectValueType}
                ></ProductCategorySelectedDisplay>
            )
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectProductCategory {...displayInfo} />;
    }
}
