import ReactDOM from 'react-dom';
import { builder } from '@comall-backend-builder/core';
import * as defaultComponents from '@comall-backend-builder/components-basis';
import { forIn } from 'lodash';

import './loaders';
import * as myConfigs from './config';
import Types from './types';

import './init';

import './styles/index.less';

import './applications';
import './cybershop-route';

Types.forEach((type, name) => {
    builder.registerType(name, type);
});
forIn(myConfigs, (config) => {
    builder.registerConfig(config);
});

ReactDOM.render(builder.bootstrap(defaultComponents.AntdEntry), document.getElementById('app'));
