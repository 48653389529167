import React, { Component } from 'react';
import {
    Radio,
    Divider,
    Input,
    Select,
    Button,
    Table,
    message,
    Modal,
    Checkbox,
    Row,
    Col,
    Spin,
    Tabs,
} from 'antd';
import { services, Loader } from '@comall-backend-builder/core';
import { cloneDeep, pullAt, debounce, assign, forEach } from 'lodash';
import { errorHandle } from '../../../../applications/cae/services/error-handle';
import { DeliveryMode, LogisticsMethod, DefaultFormData, DeliveryFormData } from '../types';
import { AutomaticDeliveryForm } from '../automatic-delivery-form';
import { formatDate } from '../../services';
import './index.less';

const { Option } = Select;
const { confirm } = Modal;
// 发货方式 ALL :整单发货  Aplit:拆单发货
enum DeliverMethod {
    All = 1,
    Split = 2,
}

const columns = [
    {
        title: '货品ID',
        dataIndex: 'goodsId',
        key: 'goodsId',
    },
    {
        title: '商品名称',
        dataIndex: 'productName',
        key: 'productName',
    },
    {
        title: '商品编码',
        dataIndex: 'productCode',
        key: 'productCode',
    },
    {
        title: '款式',
        dataIndex: 'styleName',
        key: 'styleName',
    },
    {
        title: '重量',
        dataIndex: 'weight',
        key: 'weight',
    },
    {
        title: '发货数量（重量）',
        dataIndex: 'showTableQuantity',
        key: 'showTableQuantity',
    },
    {
        title: '实际总金额',
        dataIndex: 'price',
        key: 'price',
    },
];

const printerIcon = './images/editor/printer-icon.png';

export class ManageOrderDeliverForm extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            // 配送方式 EXPRESS=快递|CITYWIDE=同城配|PICK_UP=自提|NONE=其他
            deliveryMode: DeliveryMode.NONE,
            // 发货方式 1：整单发货  2：拆包发货
            deliverMethod: DeliverMethod.All,
            // 物流生成方式 AUTOMATIC：自动叫单  MANUAL：手动填单
            logisticsMethod: LogisticsMethod.AUTOMATIC,
            // 发货方式按钮是否禁用
            disableDeliverMethod: false,
            // 已拆包裹
            sendPackages: [],
            selectedRowKeys: [],
            // 当前订单
            order: {},
            // 可拆包裹
            canPackage: [],
            // 门店配送公司配置
            deliveryLogisticsConfig: {},
            // 主单是否支持一件叫单
            supportDeliveryAutomatic: false,
            // 主单是否支持手动填单
            supportDeliveryManual: false,
            // 编辑的表单数据
            formData: cloneDeep(DefaultFormData),
            showSelect: true,
            // 可选物流公司列表
            expressCompanyList: [],
            // 可合单发货订单
            canMergeOrder: [],
            // 选中的合单发货订单（索引值）
            selectedCanMergeOrder: [],
            canPackageLoading: true,
            isSubmitting: false,
            disableSubmit: false,
            isLoadingMergeOrder: false,
            //不能合并发货的订单
            noValidCanMergeOrderList: [],
        };
        this.debounceSubmit = debounce(this.onSubmit, 300);
    }
    debounceSubmit: () => void;

    // 切换发货方式
    onDeliverMethodChange = (e: any) => {
        this.setState({
            deliverMethod: e.target.value,
        });
    };
    orderId: string | undefined;
    componentDidMount() {
        const { params, location } = this.props;

        if (!params.id) return alert('订单为空');
        this.orderId = params.id;
        const search = location.search;
        let { deliverType } = Object.fromEntries(new URLSearchParams(search).entries()) || {};
        let deliveryMode = DeliveryMode.NONE;
        //1001：快递 1002：自提 1004：同城配 0：其他
        if (deliverType === '1001') {
            deliveryMode = DeliveryMode.EXPRESS;
        }
        if (deliverType === '1002') {
            deliveryMode = DeliveryMode.PICK_UP;
        }
        if (deliverType === '1004') {
            deliveryMode = DeliveryMode.CITYWIDE;
        }
        this.setState({ deliveryMode }, () => {
            this.loadCanPackages();
        });
    }
    // 可拆包裹
    loadCanPackages = () => {
        const { params } = this.props;
        this.setState({
            canPackageLoading: true,
        });
        Loader.load('get', {
            params: {},
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
            apiPath: `/admin/waiting_delivery_orders?query_type=SELF&order_id=${params.id}`,
        })
            .then((res: any) => {
                if (res && res.result.length) {
                    const order = res.result[0];
                    this.setState(
                        {
                            order: order,
                            canPackage: this.formatTableData(order.items),
                        },
                        () => {
                            this.getDeliveryLogisticsConfig();
                            this.loadPackages();
                        }
                    );
                }
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({
                    canPackageLoading: false,
                });
            });
    };

    formatTableData = (list: any) => {
        return list.map((i: any) => {
            if (i.productType === 'WEIGHT') {
                const quantity =
                    i.displayUnit === 'KG'
                        ? Number((Number(i.quantity) / 1000).toFixed(3)) + 'kg'
                        : i.quantity + 'g';
                return { ...i, showTableQuantity: quantity, weight: '' };
            } else {
                return { ...i, showTableQuantity: i.quantity };
            }
        });
    };

    // 已拆包裹
    loadPackages = () => {
        const { params } = this.props;
        const { deliveryMode } = this.state;
        Loader.load('get', {
            params: {},
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_packages/by_order_id?order_id=${params.id}`,
        })
            .then((res: any) => {
                this.setState(
                    {
                        sendPackages: res,
                        disableDeliverMethod: !!res.length || deliveryMode === DeliveryMode.PICK_UP,
                        deliverMethod: res.length ? DeliverMethod.Split : DeliverMethod.All,
                        showSelect: !res.length,
                    },
                    () => {
                        const { deliverMethod } = this.state;
                        if (deliverMethod === DeliverMethod.All) {
                            this.getCanMergeOrder();
                        }
                    }
                );
            })
            .catch(services.errorHandle);
    };
    // 获取门店配送公司设置
    getDeliveryLogisticsConfig = () => {
        const { order } = this.state;
        Loader.load('get', {
            params: {
                subsiteId: order.subsite?.id,
                merchantId: order.merchant?.id,
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_logistics_configs/by_merchant_id`,
        })
            .then((res: any) => {
                const configInfo = this.getConfigInfo(res);
                const orderType =
                    order.orderType === 'SHOPPING_LIST' ? 'SHOPPING_LIST' : 'MINI_PROGRAM_ORDER';
                const isDefaultAutomatic = (res.defaultAutomaticBiz || []).includes(orderType);
                let logisticsMethod = undefined;
                if (configInfo.supportDeliveryAutomatic && configInfo.supportDeliveryManual) {
                    logisticsMethod = isDefaultAutomatic
                        ? LogisticsMethod.AUTOMATIC
                        : LogisticsMethod.MANUAL;
                } else {
                    if (configInfo.supportDeliveryAutomatic) {
                        logisticsMethod = LogisticsMethod.AUTOMATIC;
                    }
                    if (configInfo.supportDeliveryManual) {
                        logisticsMethod = LogisticsMethod.MANUAL;
                    }
                }
                this.setState(
                    {
                        deliveryLogisticsConfig: res,
                        supportDeliveryAutomatic: configInfo.supportDeliveryAutomatic,
                        supportDeliveryManual: configInfo.supportDeliveryManual,
                        logisticsMethod,
                    },
                    this.loadExpressCompany
                );
            })
            .catch(services.errorHandle);
    };
    // 可合单发货的订单
    getCanMergeOrder = () => {
        this.setState({ isLoadingMergeOrder: true });
        Loader.load('get', {
            params: {},
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
            apiPath: `/admin/waiting_delivery_orders?query_type=SAME_DELIVERY&order_id=${this.orderId}`,
        })
            .then((res: any) => {
                this.setState(
                    {
                        canMergeOrder: res.result,
                    },
                    () => {
                        this.isValidOrderMerge();
                    }
                );
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({ isLoadingMergeOrder: false });
            });
    };

    // 判断专柜是否支持一键叫单等信息
    getConfigInfo = (res: any) => {
        if (!res) {
            return {
                supportDeliveryAutomatic: false,
                supportDeliveryManual: false,
                companyList: [],
            };
        }
        const { deliveryMode } = this.state;
        if (deliveryMode === DeliveryMode.EXPRESS) {
            //快递
            const expressCompanies = [];
            if (res.expressCompanies && res.expressCompanies.length > 0) {
                const automaticList = res.expressCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.AUTOMATIC)
                );
                const manualList = res.expressCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                );
                if (automaticList && automaticList.length > 0) {
                    expressCompanies.push({
                        logisticsMethod: LogisticsMethod.AUTOMATIC,
                        companys: automaticList,
                    });
                }
                if (manualList && manualList.length > 0) {
                    expressCompanies.push({
                        logisticsMethod: LogisticsMethod.MANUAL,
                        companys: manualList,
                    });
                }
                return {
                    supportDeliveryAutomatic: Boolean(automaticList.length),
                    supportDeliveryManual: Boolean(manualList.length),
                    companyList: expressCompanies,
                };
            }
        }
        if (deliveryMode === DeliveryMode.CITYWIDE) {
            //同城配
            const cityWideCompanies = [];
            if (res.cityWideCompanies && res.cityWideCompanies.length > 0) {
                const automaticList = res.cityWideCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.AUTOMATIC)
                );
                const manualList = res.cityWideCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                );
                if (automaticList && automaticList.length > 0) {
                    cityWideCompanies.push({
                        logisticsMethod: LogisticsMethod.AUTOMATIC,
                        companys: automaticList,
                    });
                }
                if (manualList && manualList.length > 0) {
                    cityWideCompanies.push({
                        logisticsMethod: LogisticsMethod.MANUAL,
                        companys: manualList,
                    });
                }
            }
            return {
                supportDeliveryAutomatic: cityWideCompanies.find(
                    (c) => c.logisticsMethod === LogisticsMethod.AUTOMATIC
                )
                    ? true
                    : false,
                supportDeliveryManual: cityWideCompanies.find(
                    (c) => c.logisticsMethod === LogisticsMethod.MANUAL
                )
                    ? true
                    : false,
                companyList: cityWideCompanies,
            };
        }
        return {
            supportDeliveryAutomatic: false,
            supportDeliveryManual: false,
            companyList: [],
        };
    };

    isValidOrderMerge = () => {
        const { formData, logisticsMethod, order, canMergeOrder, deliveryMode } = this.state;
        if (!formData || !formData.companyId || !canMergeOrder || canMergeOrder.length === 0) {
            return;
        }
        const orders: Array<any> = [
            {
                orderId: order.id,
                merchantId: order.merchant.id,
            },
        ];
        forEach(canMergeOrder, (order) => {
            orders.push({
                orderId: order.id,
                merchantId: order.merchant.id,
            });
        });
        Loader.load('post', {
            params: {
                subsiteId: order.subsite?.id,
                orders: orders,
                companyId: formData.companyId,
                companyAccount: formData.companyAccount,
                deliveryMode: deliveryMode,
                logisticsBillGenerationMethod: logisticsMethod,
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/order_merge_delivery_infos`,
        })
            .then((res: any) => {
                const noValidCanMergeOrderList = res.filter(
                    (order: any) => order.mergeDelivery === false
                );
                this.setState({
                    noValidCanMergeOrderList: noValidCanMergeOrderList,
                });
            })
            .catch(services.errorHandle);
    };

    /**
     * 获取快递公司的服务类型
     */
    getServiceTypes = (companyCode: string) => {
        Loader.load('get', {
            params: {
                companyCode,
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/logistics_company_services`,
        })
            .then((res: any) => {
                this.setState({
                    formData: assign(this.state.formData, {
                        serviceTypes: res,
                        serviceType: res && res.length ? res[0].code : '',
                    }),
                });
            })
            .catch(services.errorHandle);
    };

    // 选中/取消发货商品
    onChangeSelectedGoods = (selectedRowKeys: any) => {
        this.setState({ selectedRowKeys });
    };

    /**
     * 物流公司变更(重置表单数据)
     * @param value
     */
    onChangeCompany = (value: string) => {
        if (value === this.state.formData.companyId) {
            return;
        }
        const defaultFormData = cloneDeep(DefaultFormData);
        this.setState(
            {
                formData: defaultFormData,
            },
            () => {
                const { expressCompanyList, logisticsMethod } = this.state;

                const selectedCompany: any = expressCompanyList.find(
                    (i: any) => i.companyId === value
                );
                const companyCode = selectedCompany['companyCode'];
                const deliveryLogisticsCompanyId = selectedCompany['id'];
                const companyAccount = selectedCompany['account'];
                this.setState(
                    {
                        formData: assign(this.state.formData, {
                            companyId: value,
                            companyAccount,
                            deliveryLogisticsCompanyId,
                        }),
                    },
                    () => {
                        //查看判断订单是否能合单发货
                        this.isValidOrderMerge();
                    }
                );
                // 一件叫单：重新计算保价，重新获取物流公司的服务类型
                if (logisticsMethod === LogisticsMethod.AUTOMATIC) {
                    this.computeInsured();
                    this.getServiceTypes(companyCode);
                }
            }
        );
    };

    /**
     * 加载物流公司列表
     */
    loadExpressCompany = debounce((name?: string) => {
        const { logisticsMethod, deliveryLogisticsConfig } = this.state;
        const configInfo = this.getConfigInfo(deliveryLogisticsConfig);
        const automaticLogisticsMethodCompanys = configInfo.companyList.find(
            (c: any) => c.logisticsMethod === LogisticsMethod.AUTOMATIC
        );
        const manualLogisticsMethodCompanys = configInfo.companyList.find(
            (c: any) => c.logisticsMethod === LogisticsMethod.MANUAL
        );
        const automaticCompanys = automaticLogisticsMethodCompanys
            ? automaticLogisticsMethodCompanys.companys
            : [];
        const manualCompanys = manualLogisticsMethodCompanys
            ? manualLogisticsMethodCompanys.companys
            : [];
        // 如果不存在一件叫单配送公司，则切换为手动填单，并且不展示该页签
        if (logisticsMethod === LogisticsMethod.AUTOMATIC && automaticCompanys.length === 0) {
            this.setState(
                {
                    formData: cloneDeep(DefaultFormData),
                    supportDeliveryAutomatic: false,
                    logisticsMethod: LogisticsMethod.MANUAL,
                },
                this.loadExpressCompany
            );
        } else {
            let companys =
                logisticsMethod === LogisticsMethod.AUTOMATIC ? automaticCompanys : manualCompanys;
            if (name) {
                companys = companys.filter((c: any) => c.companyName.includes(name));
            }
            this.setState(
                {
                    expressCompanyList: companys,
                },
                () => {
                    // 默认选中第一家
                    if (companys && companys.length) {
                        this.onChangeCompany(companys[0]['companyId']);
                    }
                }
            );
        }
    }, 200);
    // 重新计算保价
    computeInsured = () => {
        const {
            logisticsMethod,
            deliveryLogisticsConfig,
            canMergeOrder,
            selectedCanMergeOrder,
            order,
        } = this.state;
        let insured = false;
        if (
            logisticsMethod === LogisticsMethod.AUTOMATIC &&
            deliveryLogisticsConfig &&
            deliveryLogisticsConfig.insured
        ) {
            // 都保价
            if (deliveryLogisticsConfig.insured.type === 'ALL') {
                insured = true;
            }
            // 达到金额保价
            if (deliveryLogisticsConfig.insured.type === 'LOWER_LIMIT') {
                let orderAmount = Number(order.payableAmount);

                if (selectedCanMergeOrder.length) {
                    const selected = pullAt(cloneDeep(canMergeOrder), selectedCanMergeOrder);
                    selected.forEach((i: any) => {
                        orderAmount += Number(i.payableAmount);
                    });
                }
                if (orderAmount > Number(deliveryLogisticsConfig.insured.amount)) {
                    insured = true;
                }
            }
            this.setState({ formData: assign(this.state.formData, { insured: insured }) });
        }
    };
    // 输入快递单号
    onChangeExpressNumber = (e: any) => {
        this.setState({
            formData: assign(this.state.formData, {
                expressNumber: e.target.value,
            }),
        });
    };

    // 选中合单发货订单
    onMergeOrderChange = (selectedCanMergeOrder: any) => {
        this.setState({ selectedCanMergeOrder }, this.computeInsured);
    };

    addPackage = () => {
        const { showSelect, canPackage } = this.state;
        if (showSelect) {
            return message.warning('请先将已选商品发货');
        }
        if (!canPackage.length) {
            return message.warning('已无可打包货品');
        }
        this.setState({ showSelect: true });
    };
    onCancel = () => {
        confirm({
            title: '提示',
            content: '确定取消发货并关闭此页面？',
            onOk() {
                window.close();
            },
        });
    };

    onSubmit = () => {
        const {
            deliverMethod,
            logisticsMethod,
            selectedRowKeys,
            canPackage,
            deliveryMode,
            selectedCanMergeOrder,
            canMergeOrder,
            formData,
            deliveryLogisticsConfig,
            order,
        } = this.state;
        const {
            companyId,
            companyAccount,
            deliveryLogisticsCompanyId,
            pickupTimeRange,
            serviceType,
            selectedPickupDay,
            selectedPickupTimeRange,
            pickup,
            remark,
            expressNumber,
            insured,
        } = formData;
        const subsiteId = order.subsite?.id;
        const params: any = {
            subsiteId,
            companyId,
            companyAccount,
            deliveryMode,
            logisticsBillGenerationMethod: logisticsMethod,
            logisticsConfigId: deliveryLogisticsConfig?.id,
            remark,
            deliveryLogisticsCompanyId,
        };

        if (!companyId && deliveryMode !== DeliveryMode.PICK_UP) {
            return message.warning(services.language.getText('qtxkddh'));
        }
        // 手动填单校验
        if (logisticsMethod === LogisticsMethod.MANUAL) {
            if (!expressNumber && deliveryMode !== DeliveryMode.PICK_UP) {
                return message.warning(services.language.getText('qtxkddh'));
            } else {
                if (expressNumber && expressNumber.length > 64) {
                    return message.warning('快递单号支持最大输入64位，请重新填写');
                }
                params.expressNumber = expressNumber;
            }
        }
        if (logisticsMethod === LogisticsMethod.AUTOMATIC) {
            params.insured = insured;
            params.serviceType = serviceType ? serviceType : null;
            if (pickup && (!selectedPickupDay || !selectedPickupTimeRange)) {
                return message.warning(services.language.getText('qxzyysj'));
            }
            if (pickup && selectedPickupDay && selectedPickupTimeRange) {
                const selectedPickupDate = pickupTimeRange[selectedPickupDay].date;

                params.pickupStartTime = formatDate(
                    selectedPickupDate + ' ' + selectedPickupTimeRange.split('-')[0],
                    'yyyy-MM-dd hh:mm:ss'
                );
                params.pickupEndTime = formatDate(
                    selectedPickupDate + ' ' + selectedPickupTimeRange.split('-')[1],
                    'yyyy-MM-dd hh:mm:ss'
                );
            }
        }

        if (deliveryMode === DeliveryMode.PICK_UP) {
            params.companyId = '0';
            params.companyAccount = '';
        }

        //组装发货的订单项
        let packageItems = [];
        if (deliverMethod === DeliverMethod.All) {
            packageItems = canPackage.map((i: any) => ({
                orderId: order.id,
                orderItemId: i.id,
            }));

            if (selectedCanMergeOrder.length) {
                const selected = pullAt(cloneDeep(canMergeOrder), selectedCanMergeOrder);

                selected.forEach((i: any) => {
                    let mergePackageItems: any = [];
                    mergePackageItems = i.items.map((j: any) => ({
                        orderId: i.id,
                        orderItemId: j.id,
                    }));
                    packageItems.push(...mergePackageItems);
                });
            }
        } else if (deliverMethod === DeliverMethod.Split) {
            // 拆包裹
            if (!selectedRowKeys.length) {
                return message.warning(services.language.getText('qxzdbhp'));
            }
            const allCanPackage = cloneDeep(canPackage);
            const selected = pullAt(allCanPackage, selectedRowKeys);

            packageItems = selected.map((i: any) => ({
                orderId: order.id,
                orderItemId: i.id,
            }));
        }
        params.packages = [
            {
                deliveryOrderId: order.id,
                packageItems: packageItems,
            },
        ];

        this.logisticsDeliver(params);
    };
    //发货请求
    logisticsDeliver = (params: any) => {
        this.setState({ isSubmitting: true });
        Loader.load('post', {
            params: params,
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_packages`,
        })
            .then((res: any) => {
                this.onOrderChangedDispatch();
                this.showResultModal(res);
            })
            .catch(errorHandle)
            .finally(() => {
                this.setState({
                    isSubmitting: false,
                });
            });
    };
    // 根据返回结果弹出结果窗口
    showResultModal = (res: any) => {
        const { deliverMethod, logisticsMethod, formData } = this.state;
        // 手动填单发货成功提示
        const confirmProps: any = {
            title: '发货成功',
            content: '确定关闭此页面？',
            okText: services.language.getText('common.ok'),
            onOk() {
                window.close();
            },
            onCancel: () => {
                if (res.success) {
                    if (deliverMethod === DeliverMethod.Split) {
                        this.setState({
                            selectedRowKeys: [],
                            formData: cloneDeep(DefaultFormData),
                        });

                        this.loadCanPackages();
                        this.loadPackages();
                    }
                    if (deliverMethod === DeliverMethod.All) {
                        this.setState({
                            disableSubmit: true,
                        });
                    }
                }
            },
        };
        if (res.success) {
            if (logisticsMethod === LogisticsMethod.AUTOMATIC) {
                // 自动叫单  指定预约取件成功提示
                if (formData.pickup) {
                    confirmProps.title = '叫单成功';
                    confirmProps.content = (
                        <div>
                            <div style={{ margin: '10px 0' }}>
                                {services.language.getText('yyqjwxzjdymd')}
                            </div>
                            <div>确定关闭此页面？</div>
                        </div>
                    );
                } else {
                    // 自动叫单  未指定预约取件成功提示
                    confirmProps.title = '打印面单';
                    confirmProps.content = (
                        <div>
                            <div>{services.language.getText('qtgddrjdymd')}</div>
                            <img
                                style={{
                                    display: 'block',
                                    width: '100px',
                                    height: '100px',
                                    margin: '10px auto',
                                }}
                                alt="微信打单软件"
                                src={printerIcon}
                            ></img>
                            <div style={{ margin: '0 auto 10px', textAlign: 'center' }}>
                                微信打单软件
                            </div>
                            <div>确定关闭此页面？</div>
                        </div>
                    );
                }
            }
        } else {
            confirmProps.title = '发货失败';
            if (logisticsMethod === LogisticsMethod.AUTOMATIC) {
                confirmProps.title = '叫单失败';
            }
            const reason = res && res.failedOrders ? res.failedOrders[0].reason : '';
            confirmProps.content = (
                <div>
                    <div style={{ margin: '10px 0' }}>{reason}</div>
                    <div>确定关闭此页面？</div>
                </div>
            );
        }
        confirm(confirmProps);
    };

    // 改变物流生成方式
    changeLogisticsMethod = (logisticsMethod: string) => {
        this.setState(
            { logisticsMethod, selectedCanMergeOrder: [], formData: cloneDeep(DefaultFormData) },
            this.loadExpressCompany
        );
    };

    // 改变表单数据
    changeFormData = (formData: DeliveryFormData) => {
        this.setState({
            formData,
        });
    };

    onOrderChangedDispatch = () => {
        localStorage.setItem(`order-refresh-${window.location.host}`, String(Math.random()));
    };

    render() {
        const {
            sendPackages,
            deliverMethod,
            disableDeliverMethod,
            showSelect,
            canMergeOrder,
            isSubmitting,
            disableSubmit,
            isLoadingMergeOrder,
            supportDeliveryAutomatic,
            supportDeliveryManual,
            logisticsMethod,
            formData,
            expressCompanyList,
            deliveryMode,
        } = this.state;
        return (
            <div className="manage-order-delivery-form">
                <div className="manage-order-delivery-form__type">
                    <span className="manage-order-delivery-form__deliver-type">发货方式：</span>
                    <Radio.Group
                        disabled={disableDeliverMethod}
                        onChange={this.onDeliverMethodChange}
                        value={deliverMethod}
                    >
                        <Radio value={DeliverMethod.All}>全部发货</Radio>
                        <Radio value={DeliverMethod.Split}>拆包发货</Radio>
                    </Radio.Group>
                </div>
                {/* 已拆包裹 */}
                {deliverMethod === DeliverMethod.Split && sendPackages.map(this.renderPackages)}
                {/* 全部发货时的包裹展示 */}
                {deliverMethod === DeliverMethod.All && (
                    <div>
                        {this.renderAllSelectGoods()}
                        {isLoadingMergeOrder && (
                            <div className="manage-order-delivery-form__merge-order-loading">
                                <Spin />
                            </div>
                        )}
                        {!!canMergeOrder.length && (
                            <div className="manage-order-delivery-form__merge-order">
                                <Divider orientation="left">合单发货</Divider>
                                <div className="manage-order-delivery-form__text-tip">
                                    订单的配送方式，收货人信息完全一致的订单可合单发货；使用自动叫单发货时，配送公司的月结账户，发货地址（快递取件地址）完全一致订单才可合单发货
                                </div>
                                {this.renderCanMergeOrder()}
                            </div>
                        )}
                    </div>
                )}
                {deliverMethod === DeliverMethod.Split && showSelect && (
                    <div>
                        <Divider orientation="left">请选择发货商品</Divider>
                        {this.renderSelectGoods()}
                    </div>
                )}

                {(deliverMethod === DeliverMethod.All ||
                    (deliverMethod === DeliverMethod.Split && showSelect)) && (
                    <div className="manage-order-delivery-form__confirm-delivery">
                        <Divider orientation="left">确认发货</Divider>
                        {supportDeliveryAutomatic && supportDeliveryManual ? (
                            <Tabs
                                activeKey={logisticsMethod}
                                onTabClick={this.changeLogisticsMethod}
                                animated={false}
                            >
                                <Tabs.TabPane tab="自动叫单" key={LogisticsMethod.AUTOMATIC}>
                                    <AutomaticDeliveryForm
                                        formData={formData}
                                        deliveryMode={deliveryMode}
                                        expressCompanyList={expressCompanyList}
                                        onChangeCompany={this.onChangeCompany}
                                        changeFormData={this.changeFormData}
                                        loadExpressCompany={this.loadExpressCompany}
                                    ></AutomaticDeliveryForm>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="手动填单" key={LogisticsMethod.MANUAL}>
                                    {this.renderManualLogisticsMethod()}
                                </Tabs.TabPane>
                            </Tabs>
                        ) : null}
                        {supportDeliveryAutomatic && !supportDeliveryManual ? (
                            <AutomaticDeliveryForm
                                formData={formData}
                                deliveryMode={deliveryMode}
                                expressCompanyList={expressCompanyList}
                                onChangeCompany={this.onChangeCompany}
                                changeFormData={this.changeFormData}
                                loadExpressCompany={this.loadExpressCompany}
                            ></AutomaticDeliveryForm>
                        ) : null}
                        {!supportDeliveryAutomatic && supportDeliveryManual
                            ? this.renderManualLogisticsMethod()
                            : null}
                        {!supportDeliveryAutomatic && !supportDeliveryManual ? (
                            deliveryMode === DeliveryMode.PICK_UP ? (
                                this.renderManualLogisticsMethod()
                            ) : (
                                <div style={{ color: '#f5222d' }}>暂无可发货的配送公司</div>
                            )
                        ) : null}

                        <div className="manage-order-delivery-form__button">
                            <Button
                                loading={isSubmitting}
                                disabled={disableSubmit}
                                onClick={this.debounceSubmit}
                                type="primary"
                            >
                                {logisticsMethod === LogisticsMethod.AUTOMATIC
                                    ? '自动叫单'
                                    : '确定'}
                            </Button>
                            <Button onClick={this.onCancel} style={{ marginLeft: '20px' }}>
                                取消
                            </Button>
                        </div>
                    </div>
                )}
                {deliverMethod === DeliverMethod.Split && (
                    <>
                        <Divider dashed />
                        <Button onClick={this.addPackage}>新增包裹</Button>
                    </>
                )}
            </div>
        );
    }
    // 渲染拆单发货的已拆包裹
    renderPackages = (packageItem: any, index: number) => {
        const logistics =
            packageItem.logistics && packageItem.logistics.length ? packageItem.logistics[0] : null;
        return (
            <div key={index} className="manage-order-delivery-form__packages">
                <div className="manage-order-delivery-form__info">
                    <span>
                        {logistics?.companyName}： {logistics?.expressNumber}
                    </span>
                    <span>包裹{index + 1}</span>
                </div>
                <Table
                    pagination={false}
                    size="small"
                    rowKey={'id'}
                    dataSource={this.formatTableData(packageItem.items)}
                    columns={columns}
                />
            </div>
        );
    };
    // 渲染拆单发货的可选发货商品
    renderSelectGoods = () => {
        const { selectedRowKeys, canPackage } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onChangeSelectedGoods,
        };
        return (
            <Table
                style={{ width: '800px' }}
                rowSelection={rowSelection}
                columns={columns}
                pagination={false}
                dataSource={canPackage}
            />
        );
    };
    // 渲染全部发货时的发货商品
    renderAllSelectGoods = () => {
        const { order, canPackage, canPackageLoading } = this.state;
        return (
            <div style={{ width: '800px', marginTop: '15px' }}>
                <div className="manage-order-delivery-form__merchant">
                    <span>货品明细</span>
                    <span>{order.merchant?.name}</span>
                </div>
                <Table
                    style={{ width: '800px' }}
                    size="small"
                    loading={canPackageLoading}
                    rowKey={'id'}
                    columns={columns}
                    pagination={false}
                    dataSource={canPackage}
                />
            </div>
        );
    };

    // 渲染可合单发货订单
    renderCanMergeOrder = () => {
        const { canMergeOrder, noValidCanMergeOrderList, selectedCanMergeOrder } = this.state;

        return (
            <div>
                <Checkbox.Group
                    style={{ width: '800px' }}
                    onChange={this.onMergeOrderChange}
                    value={selectedCanMergeOrder}
                >
                    <Row>
                        {canMergeOrder.map((i: any, index: number) => {
                            const noValidCanMergeOrder = noValidCanMergeOrderList.find(
                                (order: any) => order.orderId === i.id + ''
                            );
                            return (
                                <Col key={index} span={24}>
                                    <div className="manage-order-delivery-form__order-number">
                                        <Checkbox disabled={noValidCanMergeOrder} value={index}>
                                            {i.orderNo}
                                        </Checkbox>
                                        {noValidCanMergeOrder && (
                                            <span className="manage-order-delivery-form__order-tip">
                                                {noValidCanMergeOrder.canNotMergeDeliveryReason}
                                            </span>
                                        )}
                                    </div>
                                    <div className="manage-order-delivery-form__merchant">
                                        <span>货品明细</span>
                                        <span>{i.merchant?.name}</span>
                                    </div>
                                    <Table
                                        pagination={false}
                                        size="small"
                                        rowKey={'id'}
                                        dataSource={this.formatTableData(i.items)}
                                        columns={columns}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </Checkbox.Group>
            </div>
        );
    };

    // 渲染自动填单发货方式
    renderManualLogisticsMethod = () => {
        const { deliveryMode, expressCompanyList, formData } = this.state;
        return (
            <div>
                <div className="manage-order-delivery-form__form">
                    <span className="manage-order-delivery-form__form-label">
                        {services.language.getText('deliveryType')}：
                    </span>
                    <div className="manage-order-delivery-form__form-input">
                        <Select style={{ width: 300 }} disabled value={deliveryMode}>
                            <Option value={DeliveryMode.EXPRESS}>快递</Option>
                            <Option value={DeliveryMode.CITYWIDE}>同城配</Option>
                            <Option value={DeliveryMode.PICK_UP}>自提</Option>
                            <Option value={DeliveryMode.NONE}>其他</Option>
                        </Select>
                    </div>
                </div>
                {deliveryMode === DeliveryMode.PICK_UP && (
                    <div className="manage-order-delivery-form__form">
                        <span className="manage-order-delivery-form__form-label">请注意：</span>
                        <div className="manage-order-delivery-form__form-input">
                            <span className="manage-order-delivery-form__form-input-warn">
                                请确认该订单商品已打包完毕
                            </span>
                        </div>
                    </div>
                )}
                {deliveryMode !== DeliveryMode.PICK_UP && (
                    <>
                        <div className="manage-order-delivery-form__form">
                            <span className="manage-order-delivery-form__form-label">
                                {services.language.getText('wlgs')}：
                            </span>
                            <div className="manage-order-delivery-form__form-input">
                                <Select
                                    autoClearSearchValue
                                    showSearch
                                    filterOption={false}
                                    placeholder={services.language.getText('selectPlease')}
                                    value={formData.companyId}
                                    onChange={this.onChangeCompany}
                                    onSearch={this.loadExpressCompany}
                                    onFocus={this.loadExpressCompany}
                                    style={{ width: 300 }}
                                >
                                    {expressCompanyList.map((i: any) => (
                                        <Option key={i.companyId} value={i.companyId}>
                                            {i.companyName}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="manage-order-delivery-form__form">
                            <span className="manage-order-delivery-form__form-label">
                                快递单号：
                            </span>
                            <div className="manage-order-delivery-form__form-input">
                                <Input
                                    onChange={this.onChangeExpressNumber}
                                    value={formData.expressNumber}
                                ></Input>
                            </div>
                        </div>
                        {formData.expressNumber && formData.expressNumber.length > 64 && (
                            <div className="error">快递单号支持最大输入64位，请重新填写</div>
                        )}
                    </>
                )}
            </div>
        );
    };
}
