import React from 'react';
import { useToggle } from 'ahooks';
import { clamp, isString } from 'lodash';
import { cloneElement, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import './zoom.less';

export interface ImageZoomProps {
    /**
     * 预览图样式
     */
    previewImgClassName?: string;
    /**
     * 预览高清图，不传时取 img 的 src
     */
    preview?: string;
    /**
     * 镜头尺寸，支持数字也支持百分比
     *
     * @default '20%'
     */
    lensSize?: string | number;
    /**
     * 放大倍数
     *
     * @default 5
     */
    zoom?: number;
    /**
     * 禁用
     */
    disabled?: boolean;
    /**
     * 隱藏鏡頭
     */
    hideLens?: boolean;
    /**
     * img
     */
    children?: React.ReactElement;
}

export const ImageZoom: React.FC<ImageZoomProps> = ({
    previewImgClassName,
    preview,
    lensSize = '20%',
    zoom = 3,
    disabled,
    hideLens,
    children,
}) => {
    const [active, { toggle: toggleActive }] = useToggle();

    // 镜头
    const lensRef = useRef<HTMLDivElement>(null);
    // 视口
    const viewportRef = useRef<HTMLDivElement>(null);
    // 预览图
    const previewImgRef = useRef<HTMLImageElement>(null);

    const observe: React.PointerEventHandler<HTMLImageElement> = (e) => {
        const { clientX: x, clientY: y } = e;
        const {
            width,
            height,
            top,
            right,
            bottom,
            left,
        } = (e.target as HTMLImageElement).getBoundingClientRect();

        const lensSizeValue = isString(lensSize)
            ? (Math.min(width, height) * parseInt(lensSize, 10)) / 100
            : lensSize;

        const lensTop = clamp(y - lensSizeValue / 2, top, bottom - lensSizeValue);
        const lensLeft = clamp(x - lensSizeValue / 2, left, right - lensSizeValue);
        // const zoom = width / lensSizeValue;
        if (lensRef.current) {
            Object.assign(lensRef.current.style, {
                width: `${lensSizeValue}px`,
                height: `${lensSizeValue}px`,
                top: `${lensTop}px`,
                left: `${lensLeft}px`,
            });
        }

        if (viewportRef.current) {
            Object.assign(viewportRef.current.style, {
                width: `${lensSizeValue * zoom}px`,
                height: `${lensSizeValue * zoom}px`,
                top: `${top - 1}px`,
                left: `${right + 5}px`,
            });
        }

        if (previewImgRef.current) {
            Object.assign(previewImgRef.current.style, {
                width: `${width}px`,
                height: `${height}px`,
                transformOrigin: 'top left',
                transform: `translate3d(-${(lensLeft - left) * zoom}px, -${(lensTop - top) *
                    zoom}px, 10px) scale(${zoom})`,
            });
        }
    };

    if (!children) return null;

    const previewSrc = preview ?? children.props.src;
    if (disabled || !isString(previewSrc)) return children;
    return (
        <>
            {cloneElement(children, {
                onPointerOver: toggleActive,
                onPointerOut: toggleActive,
                onPointerMove: observe,
            })}
            {active &&
                createPortal(
                    <div id="image-zoom" className="pointer-events-none">
                        {!hideLens && <div ref={lensRef} className="lens"></div>}
                        <div ref={viewportRef} className="viewport">
                            <img
                                ref={previewImgRef}
                                className={classNames('block object-fill', previewImgClassName)}
                                src={previewSrc}
                                alt=""
                            />
                        </div>
                    </div>,
                    document.body
                )}
        </>
    );
};
