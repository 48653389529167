import React, { PureComponent } from 'react';
import { pick, isEmpty } from 'lodash';
import { Tag, Button } from 'antd';
import { SelectCategory } from '../../design/components/custom-select-category/select-category';

export class ShowCategory extends PureComponent<any> {
    removeCategory = () => {
        const { onChange } = this.props;
        onChange([]);
    };

    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };

    changeTarget = (selected: any) => {
        const { onChange } = this.props;
        pick(selected, ['id', 'name']);

        this.hideModal();
        onChange([selected]);
    };

    renderCategory = (category: any) => {
        return (
            <div>
                <Tag closable onClose={this.removeCategory} className="category-i">
                    {category.name}
                </Tag>
                <div className="category-tip">展示排序与分类排序一致</div>
            </div>
        );
    };

    render() {
        const { visibleModal } = this.state;
        const { value, modalConfig, contentConfig } = this.props;
        const hasValue = !isEmpty(value);
        const selectProps = {
            value: hasValue ? value[0] : undefined,
            onChange: this.changeTarget,
        };
        return (
            <div className="category-show">
                {hasValue && this.renderCategory(value[0])}
                {!hasValue && (
                    <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                        选择分类
                    </Button>
                )}

                <SelectCategory
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    {...selectProps}
                    onlyVirtualCategory
                />
            </div>
        );
    }
}
