import { BaseConfig } from '../interfaces';

export const config: BaseConfig = {
    entities: {
        OrganizationManageEntity: {
            apiPath: '/loader/cae/organizations',
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<zzjgmc>>',
                },
                parentId: {
                    type: 'string',
                    displayName: '<<fjzz>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<zzjgmc>>',
                    rules: [{ required: true, message: '<<qsrzzjgmc>>' }],
                    controlConfig: {
                        placeholder: '<<qsrzzjgmc>>',
                        maxLength: 40,
                    },
                },
                nameStr: {
                    type: 'string.popover',
                    displayName: '<<zzjgmc>>',
                },
                organizationType: {
                    type: 'string',
                    displayName: '<<lx_1>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<businessStatus>>',
                },
                sequence: {
                    type: 'number',
                    displayName: '<<sequence>>',
                    defaultValue: '0',
                    controlConfig: {
                        min: 0,
                        max: 999999999,
                        precision: 0,
                        style: {
                            width: 100,
                        },
                    },
                },
            },
        },
    },
    components: {
        OrganizationManageView: {
            component: 'Viewport',
            entity: 'OrganizationManageEntity',
        },
        OrganizationManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'OrganizationManageFilter' },
                { component: 'OrganizationManageViewContainer' },
            ],
        },
        OrganizationManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                display: 'none',
            },
            fields: [],
        },

        OrganizationMangeAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OrginazationCreateForm',
                },
            ],
        },
        OrginazationCreateForm: {
            component: 'OrganizationForm',
            entity: 'organizationManage',
            content: {
                component: 'CreateForm',
                fields: [
                    { property: 'parentId' },
                    { property: 'name' },
                    { property: 'code' },
                    { property: 'remark' },
                    { property: 'extendAttributes' },
                ],
            },
        },
        OrganizationMangeEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OrginazationModifyForm',
                },
            ],
        },
        OrginazationModifyForm: {
            component: 'OrganizationForm',
            entity: 'organizationManage',
            loaderType: 'get',
            content: {
                component: 'ModifyForm',
                fields: [
                    { property: 'name', modifiable: false },
                    { property: 'code', modifiable: false },
                    { property: 'remark' },
                    { property: 'extendAttributes' },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/organization-manage',
                    breadcrumbName: '<<organizationManage>>',
                    category: '<<organizationManage>>',
                    component: 'OrganizationManageView',
                    privilege: {
                        path: 'organization_manage',
                        level: 'full',
                    },
                    childRoutes: [
                        // { path: '/organization-add', component: 'OrganizationMangeAddPage' },
                        // {
                        //     path: '/organization-edit/:id',
                        //     component: 'OrganizationMangeEditPage',
                        // },
                        // { path: '/organization-info/:id' },
                        { path: '/', component: 'OrganizationManagePage' },
                    ],
                },
            ],
        },
    ],
};
