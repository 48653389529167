import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { set } from 'lodash';
import {
    MallActivityTicketPrice,
    NewMallActivityRow,
    NewMallActivityMemberCardLevel,
} from '../types';
import { TableProps, TableSize } from 'antd/lib/table';

import { NumberInputTip } from '../..';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityTicketPricesTableProps {
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange?: (value: MallActivityTicketPrice[]) => void;
    /**
     * 当前值
     */
    value: MallActivityTicketPrice[];
    disabled?: boolean;
    row?: NewMallActivityRow;
    cardLevels: NewMallActivityMemberCardLevel[];
    size?: TableSize;
}

/**
 * 新商场活动票档价格表格
 */
class NewMallActivityTicketPricesTable extends PureComponent<
    NewMallActivityTicketPricesTableProps
> {
    changeValue = (value: MallActivityTicketPrice[]) => {
        const { onChange, disabled } = this.props;
        if (!disabled && onChange) {
            onChange(value);
        }
    };

    changeTicketPrice = (
        field: keyof MallActivityTicketPrice,
        fieldValue: MallActivityTicketPrice[keyof MallActivityTicketPrice],
        index: number
    ) => {
        const { value = [] } = this.props;
        const newValue = [...value];
        const price = newValue[index];
        if (!price) {
            return;
        }
        set(price, field, fieldValue);
        this.changeValue(newValue);
    };

    getCardLevelName = (id: number) => {
        const levelId = Number(id);
        if (levelId === -1) {
            return language.getText('all');
        }
        const { cardLevels } = this.props;
        return cardLevels?.find((item) => Number(item.id) === levelId)?.name || '';
    };

    renderPrice = (value: number, record: MallActivityTicketPrice, index: number) => {
        const { disabled } = this.props;
        const { pointPrice, salePrice } = record;
        const inputStyle = {
            maxWidth: '120px',
        };
        return (
            <div className="new-mall-activity__flex">
                {/* {this.getCardLevelName(record.memberCardLevelId)} */}
                <NumberInputTip
                    value={pointPrice}
                    min={0}
                    max={999999}
                    style={inputStyle}
                    precision={2}
                    addonafter={language.getText('point')}
                    disabled={disabled}
                    onChange={(num: number) => this.changeTicketPrice('pointPrice', num, index)}
                ></NumberInputTip>
                <span style={{ margin: '0 5px' }}>+</span>
                <NumberInputTip
                    value={salePrice}
                    style={inputStyle}
                    min={0}
                    max={999999}
                    precision={2}
                    addonafter={language.getText('yuan')}
                    disabled={disabled}
                    onChange={(num: number) => this.changeTicketPrice('salePrice', num, index)}
                ></NumberInputTip>
            </div>
        );
    };
    getTableConfig = () => {
        const { size = 'default' } = this.props;
        let config: TableProps<MallActivityTicketPrice> = {
            rowKey: 'memberCardLevelId',
            size,
            columns: [
                {
                    title: language.getText('cardLevel'),
                    dataIndex: 'memberCardLevelId',
                    width: 150,
                    render: this.getCardLevelName,
                },
                {
                    title: language.getText('smjg'),
                    dataIndex: 'pointPrice',
                    width: 360,
                    render: this.renderPrice,
                },
            ],
        };
        config.columns?.forEach((item) => {
            item.align = 'center';
            if (!item.key) {
                item.key = item.dataIndex;
            }
        });
        return config;
    };

    render() {
        const { value } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: value };
        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { NewMallActivityTicketPricesTable };
