import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import get from 'lodash/get';
import { TipsModalContentType } from '../../../components';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export const config: Config = {
    entities: {
        OrderSettingConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/order-setting',
            properties: {
                orderRule: {
                    type: 'object.subForm',
                    displayName: '<<ddsz>>',
                    properties: {
                        expireMins: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<dzfddqxsjsz>>',
                            name: JSON.stringify({
                                fill: '<<h_pxwzfdd_fill>>',
                                empty: '<<wszdzfddqxsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 80 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<pxwzfdd>>',
                                addonafter: '<<zdqxdd>>',
                            },
                        },
                        offlinePaymentOrderAutoCloseMins: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<dzfddqxsjsz_xxzf>>',
                            name: JSON.stringify({
                                fill: '<<h_pxwzfdd_fill>>',
                                empty: '<<wszdzfddqxsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 100 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<pxwzfdd>>',
                                addonafter: '<<zdqxdd>>',
                            },
                        },
                        timeLimitExpireMins: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<mshddzfddqxsjsz>>',
                            name: JSON.stringify({
                                fill: '<<h_pxwzfdd_fill>>',
                                empty: '<<wszmshddzfddqxsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 80 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<pxwzfdd>>',
                                addonafter: '<<zdqxdd>>',
                            },
                        },
                        lateAutomaticReceiptNumber: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<fhhzdqrshsjsz>>',
                            name: JSON.stringify({
                                fill: '<<xtzdqrshAfter>>',
                                empty: '<<wszzdqrshsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 80 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<fhh>>',
                                addonafter: '<<xtzdqrsh>>',
                            },
                        },
                        frontOpenDelayDays: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<yqshanzssjsz>>',
                            name: JSON.stringify({
                                fill: '<<h_jhpqshy_fill>>',
                                empty: '<<wszyqshanzssj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 80 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<jhpqshy>>',
                                addonafter: '<<h_ts_kfyqshan>>',
                            },
                        },
                        memberEachTimeDelayDays: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<hysqyqshsjsz>>',
                            name: JSON.stringify({
                                fill: '<<h_yqzdshq_fill>>',
                                empty: '<<wszhysqyqshsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 80 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<yqzdshq>>',
                                addonafter: '<<xtzdqrsh>>',
                            },
                        },
                        selfMentionOrderAutoDelivery: {
                            type: 'string.options.radio',
                            displayName: '<<ztddgshzdfh>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        uploadWechat: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<ddfhdjwxfhfw>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<djwxfhfw>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<bdjwxfhfw>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<qjssz>>',
                                    },
                                    contentType: TipsModalContentType.UploadWechat,
                                    showIcon: false,
                                    text: '<<ckjszq>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                            rules: [{ required: true }],
                        },
                        beforeReceivedCanRefund: {
                            type: 'string.options.radio',
                            displayName: '<<ddqsqksqtk>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<common.opend>>' },
                                { id: 'false', name: '<<common.close>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<h_ddqsqksqtk_tip>>',
                        },
                        quickBillingOrderAllowAfterSales: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<dkxdddzqtzssqtk>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<qtzbtdddjdzsdydan>>',
                                    },
                                    contentType: TipsModalContentType.OrderAllowAfterSales,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                            rules: [{ required: true }],
                        },
                        crossborderOrderAllowAfterSales: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<kjgddzqtzssqtk>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<qtzbtdddjdzsdydan>>',
                                    },
                                    contentType: TipsModalContentType.OrderAllowAfterSales,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
                deliveryRule: {
                    type: 'object.subForm',
                    displayName: '<<deliverySettings>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        deliveryType: {
                            type: 'string.options.radio.plus',
                            displayName: '<<szmdyzjdyfgx>>',
                            defaultValue: 'DELIVERY_WITH_SUBSITE',
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'DELIVERY_WITH_SUBSITE',
                                        name: '<<zjsymdyfgz>>',
                                    },
                                    {
                                        value: 'DELIVERY_INDEPENDENT',
                                        name: '<<zjdlpzyfgz>>',
                                        tip: {
                                            text: '<<h_zjdlpzyfgz_tip>>',
                                            hrefText: {
                                                href: '/merchant-delivery',
                                                text: '<<szyfgz>>',
                                            },
                                        },
                                    },
                                    {
                                        value: 'DELIVERY_PART_INDEPENDENT',
                                        name: '<<bfzjcymdyfgzjs>>',
                                        tip: {
                                            text: '<<rwszdydyfgzzyhwfxd>>',
                                            hrefText: {
                                                href: '/merchant-delivery',
                                                text: '<<szyfgz>>',
                                            },
                                        },
                                    },
                                ],
                            },
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<szxpzyfgzdzj>>',
                            rules: [{ required: true, message: '<<qzsxzygzj>>' }],
                            controlConfig: {
                                fieldParams: {
                                    isContainsSelfMerchant: false,
                                },
                                fields: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'merchantTagId',
                                    },
                                ],
                            },
                        },
                    },
                },
                afterSaleRule: {
                    type: 'object.subForm',
                    displayName: '<<afterSaleSetting>>',
                    properties: {
                        afterSaleDays: {
                            type: 'number.tip.groupBuying',
                            displayName: '<<yxyhsqshsjsz>>',
                            name: JSON.stringify({
                                fill: '<<h_yxyhsqshsjsz_fill>>',
                                empty: '<<wszyxyhsqshsj>>',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v)) {
                                                callback(getText('inputThanZero'));
                                            } else if (value > 9999) {
                                                callback(`${getText('szzdzcsz')}9999`);
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 80 },
                                min: 1,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<ddqs>>',
                                addonafter: '<<tnyxyhzqdsqsh>>',
                            },
                        },
                        examineServiceApplyShowPointsTips: {
                            type: 'string.options.radio',
                            displayName: '<<yxshshdszszsjfts>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<h_yxshshdszszsjfts_tip>>',
                        },
                        examineCloseOrderShowPointsTips: {
                            type: 'string.options.radio',
                            displayName: '<<yxshqxgbdszszsjfts>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<h_yxshqxgbdszszsjfts_tip>>',
                        },
                        closeOrderManualAudit: {
                            type: 'array.optionIds.checkbox',
                            displayName: '取消/关闭订单需要人工审核得场景',
                            defaultValue: [],
                            options: [
                                {
                                    name: '运营在后台关闭订单',
                                    id: 'Backend',
                                },
                                {
                                    name: '客户在用户端申请退款',
                                    id: 'NewClient',
                                },
                            ],
                            extra:
                                '勾选后后台关闭订单或用户申请退款时，需要人工审核取消关闭单，通过审核则取消或关闭订单；未勾选，则系统自动审核通过关闭取消单，系统审核成功则自动给用户退款。',
                        },
                    },
                },
                deliverOrdersRule: {
                    type: 'object.subForm',
                    displayName: '<<jhsz>>',
                    properties: {
                        pickingListSubsiteVos: {
                            type: 'array.subsites.selector',
                            displayName: '<<xzscjhddmd>>',
                            defaultValue: [],
                            controlConfig: {
                                canBeNull: true,
                            },
                            extra: '<<h_jhsz_tip>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        OrderSettingView: {
            component: 'Viewport',
        },
        GOrderSettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'OrderSettingForm' }],
        },
        OrderSettingForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'OrderSettingConfig',
            loaderType: 'get',
            labelCol: 9,
            controlCol: 14,
            style: { width: 1000 },
            fields: [
                { property: 'orderRule.expireMins' },
                { property: 'orderRule.offlinePaymentOrderAutoCloseMins' },
                { property: 'orderRule.timeLimitExpireMins' },
                { property: 'orderRule.lateAutomaticReceiptNumber' },
                { property: 'orderRule.frontOpenDelayDays' },
                { property: 'orderRule.memberEachTimeDelayDays' },
                { property: 'orderRule.selfMentionOrderAutoDelivery' },
                { property: 'orderRule.uploadWechat' },
                { property: 'orderRule.beforeReceivedCanRefund' },
                { property: 'orderRule.quickBillingOrderAllowAfterSales' },
                { property: 'orderRule.crossborderOrderAllowAfterSales' },
                { property: 'deliveryRule.deliveryType' },
                {
                    property: 'deliveryRule.merchants',
                    visible: (values: any) =>
                        get(values, 'deliveryRule.deliveryType') === 'DELIVERY_PART_INDEPENDENT',
                },
                { property: 'afterSaleRule.afterSaleDays' },
                { property: 'afterSaleRule.examineServiceApplyShowPointsTips' },
                { property: 'afterSaleRule.examineCloseOrderShowPointsTips' },
                { property: 'afterSaleRule.closeOrderManualAudit' },
                { property: 'deliverOrdersRule.pickingListSubsiteVos' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/order-setting',
                    component: 'OrderSettingView',
                    breadcrumbName: '<<szdd>>',
                    privilege: {
                        path: 'orderSetting',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'GOrderSettingPage' }],
                },
            ],
        },
    ],
};
