import React, { useCallback, useMemo } from 'react';
import { TreeNode } from '@comall-backend-builder/core/lib/type';
import { Tree as AntdTree, Checkbox as AntdCheckBox } from 'antd';
const { TreeNode: AntdTreeNode } = AntdTree;

export interface PrivilegeTreeProps {
    name: string;
    onChange: (value: string[]) => void;
    value: string[];
    options: TreeNode[];
}

function renderTreeNodes(treeNodes: TreeNode[]) {
    return treeNodes.map(({ id, name, children }) => (
        <AntdTreeNode key={id} title={name}>
            {children ? renderTreeNodes(children) : undefined}
        </AntdTreeNode>
    ));
}

function getKeys(nodes: TreeNode[]) {
    let keys: string[] = [];
    for (let { id, children } of nodes) {
        keys.push(id);
        if (children?.length) {
            keys = keys.concat(getKeys(children));
        }
    }
    return keys;
}

export const PrivilegeTree: React.FunctionComponent<PrivilegeTreeProps> = ({
    name,
    onChange,
    value,
    options,
}) => {
    const checkHandle = useCallback(
        (checkedKeys) => {
            onChange(checkedKeys);
        },
        [onChange]
    );

    const allKeys = useMemo(() => {
        return getKeys(options);
    }, [options]);

    const checkAll = value.sort().join(',') === allKeys.sort().join(',');

    const checkAllHandle = useCallback(() => {
        if (checkAll) {
            onChange([]);
        } else {
            onChange(allKeys);
        }
    }, [allKeys, checkAll, onChange]);

    return (
        <div className="privilege-tree">
            <div className="privilege-tree-title">
                <AntdCheckBox
                    className="privilege-tree-check-all"
                    checked={checkAll && value.length > 0}
                    indeterminate={!checkAll && value.length > 0}
                    onClick={checkAllHandle}
                ></AntdCheckBox>
                {name}
            </div>
            <AntdTree
                checkable
                selectable={false}
                autoExpandParent={false}
                checkedKeys={value}
                onCheck={checkHandle}
            >
                {renderTreeNodes(options)}
            </AntdTree>
        </div>
    );
};
