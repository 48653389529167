import React, { PureComponent } from 'react';
import { Button, message } from 'antd';
import get from 'lodash/get';

import { SelectPromotion } from './select-promotion-activity';
import { services } from '@comall-backend-builder/core';
const language = services.language;

export class PromotionActivitySelector extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        const subsiteId = get(this.props.value, 'linkParams.subsiteId');
        if (subsiteId === '' || subsiteId === undefined) return message.error('请选择门店');

        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;
        this.hideModal();
        onChange(value);
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
        } = this.props;

        const { visibleModal } = this.state;
        const hasSingleTarget = linkParams && linkParams.name;
        const subsiteId = get(this.props.value, 'linkParams.subsiteId');
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };

        return (
            <span className="link-name">
                {hasSingleTarget ? (
                    <span className="link-name-label">{linkParams.name}</span>
                ) : (
                    '未选择'
                )}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                {subsiteId !== undefined ? (
                    <SelectPromotion
                        contentConfig={contentConfig}
                        modalConfig={modalConfig}
                        showModal={visibleModal}
                        onCancel={this.hideModal}
                        {...selectProps}
                    />
                ) : null}
            </span>
        );
    }
}
