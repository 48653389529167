import React, { Component } from 'react';
import { InputNumber, Radio } from 'antd';
import get from 'lodash/get';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
export enum ServicePriceType {
    NONE = 'NONE',
    PAY = 'PAY',
    POINT = 'POINT',
}
type ServicePriceValue = {
    /**
     * 价格类型
     */
    type?: string;
    /**
     * 价格
     */
    price?: number;
    /**
     * 积分
     */
    point?: number;
};

export interface ServicePriceProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: ServicePriceValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: ServicePriceValue;
    /**
     * 当前值
     */
    disabled?: boolean;
}
export class ServicePrice extends Component<ServicePriceProps> {
    onValueChange = (type: any, e: any) => {
        let { onChange, value, name } = this.props;

        if (type === 'price') {
            onChange({ ...value, price: e, point: undefined }, name);
        } else if (type === 'type') {
            const val = e.target.value;
            onChange({ ...value, type: val, price: undefined, point: undefined }, name);
        } else if (type === 'point') {
            onChange({ ...value, point: e, price: undefined }, name);
        }
    };
    render() {
        const { value, disabled } = this.props;
        const type = get(value, 'type');
        const price = get(value, 'price');
        const point = get(value, 'point');
        return (
            <div className="registration-price">
                <Radio.Group
                    disabled={disabled}
                    value={type || ServicePriceType.NONE}
                    onChange={(e) => this.onValueChange('type', e)}
                >
                    <Radio
                        className="block-radio"
                        style={{ marginTop: '8px' }}
                        value={ServicePriceType.NONE}
                    >
                        <span>{language.getText('mfyy')}</span>
                    </Radio>
                    <Radio className="block-radio" value={ServicePriceType.PAY}>
                        <span>{language.getText('xzf')}&nbsp;</span>
                        <div className="input-container">
                            <InputNumber
                                disabled={disabled || type !== ServicePriceType.PAY}
                                className="input-item"
                                onChange={(e) => this.onValueChange('price', e)}
                                min={0}
                                max={999999}
                                step={1}
                                precision={2}
                                value={price}
                            />{' '}
                            {language.getText('yuan')}
                        </div>
                    </Radio>
                    <Radio className="block-radio" value={ServicePriceType.POINT}>
                        <span>{language.getText('xzf')}&nbsp;</span>
                        <div className="input-container">
                            <InputNumber
                                onError={() => {}}
                                disabled={disabled || type !== ServicePriceType.POINT}
                                className="input-item"
                                onChange={(e) => this.onValueChange('point', e)}
                                min={0}
                                max={999999}
                                step={1}
                                precision={2}
                                value={point}
                            />{' '}
                            {language.getText('point')}
                        </div>
                    </Radio>
                </Radio.Group>
            </div>
        );
    }
}
