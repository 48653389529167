import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { OpenBoxDrawLimitRule } from './draw-limit';

export class OpenBoxDrawLimitRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any): JSX.Element {
        return <OpenBoxDrawLimitRule {...controlInfo} />;
    }
}
