import React, { PureComponent } from 'react';
import { Modal, InputNumber, Select, message as AntMessage } from 'antd';
import { cloneDeep } from 'lodash';
import './index.less';
const Option = Select.Option;

interface CreditShopExchangeLimitCountProps {
    visible: boolean;
    onOk: (value: any) => void;
    onCancel: () => void;
    memberLevelRewardInfo: any;
    data: any;
}

export enum LIMIT_COUNT_TYPE {
    /**
     * 每日
     */
    EACH_DAY = 'EACH_DAY',
    /**
     * 每周
     */
    EACH_WEEK = 'EACH_WEEK',
    /**
     * 每月
     */
    EACH_MONTH = 'EACH_MONTH',
    /**
     * 每年
     */
    EACH_YEAR = 'EACH_YEAR',
    /**
     * 活动期间
     */
    EACH_PERIOD = 'EACH_PERIOD',
}

interface CreditShopExchangeLimitCountState {
    newData: any;
}

export class CreditShopExchangeLimitCount extends PureComponent<
    CreditShopExchangeLimitCountProps,
    CreditShopExchangeLimitCountState
> {
    constructor(props: any) {
        super(props);
        this.state = { newData: [] };
    }
    componentDidMount() {
        const { data } = this.props;
        if (data) {
            this.setState({
                newData: cloneDeep(data),
            });
        }
    }
    onCancel = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };
    onChangeLimit = (type: string, value: any, index: number) => {
        const { newData } = this.state;
        const newCouponData = newData.map((item: any, iindex: number) => {
            if (iindex === index) {
                item[type] = value;
            }
            return item;
        });
        this.setState({ newData: newCouponData });
    };
    onOk = () => {
        const { onOk } = this.props;
        const { newData } = this.state;
        const first = newData[0];
        const activity = newData[1];
        if (first.limitCount && activity.limitCount && first.limitCount > activity.limitCount) {
            AntMessage.warning('周期限购数量需≤活动期间每用户限购数量');
            return false;
        }
        onOk && onOk(newData);
    };

    render() {
        const { visible, memberLevelRewardInfo } = this.props;
        const { newData } = this.state;
        if (
            !memberLevelRewardInfo ||
            !memberLevelRewardInfo.cycleLimits ||
            memberLevelRewardInfo.cycleLimits.length === 0 ||
            !newData
        ) {
            return null;
        }
        if (newData.length !== 2) {
            return null;
        }
        const first = newData[0];
        const activity = newData[1];
        return (
            <Modal
                visible={visible}
                width={600}
                title="设置限购规则"
                onCancel={this.onCancel}
                onOk={this.onOk}
            >
                <div className="credit-cycle-limit-wrap">
                    <div className="title">按周期限购：</div>
                    <div className="credit-cycle-limit">
                        <span className="ant-input-group-wrapper">
                            <span className="ant-input-wrapper ant-input-group">
                                <span className="ant-input-group-addon">每个用户</span>
                                <Select
                                    value={first.ruleType}
                                    onChange={(value: any) => {
                                        this.onChangeLimit('ruleType', value, 0);
                                    }}
                                >
                                    <Option value={LIMIT_COUNT_TYPE.EACH_DAY}>每日</Option>
                                    <Option value={LIMIT_COUNT_TYPE.EACH_WEEK}>每周</Option>
                                    <Option value={LIMIT_COUNT_TYPE.EACH_MONTH}>每月</Option>
                                    <Option value={LIMIT_COUNT_TYPE.EACH_YEAR}>每年</Option>
                                </Select>
                                <span className="ant-input-group-addon middle">限购</span>
                                <InputNumber
                                    precision={0}
                                    type="number"
                                    min={1}
                                    max={999999}
                                    value={first.limitCount}
                                    onChange={(value) => {
                                        this.onChangeLimit('limitCount', value, 0);
                                    }}
                                />
                                <span className="ant-input-group-addon">件</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="credit-cycle-limit-wrap" style={{ marginTop: '28px' }}>
                    <div className="title">按活动限购：</div>
                    <div className="credit-cycle-limit">
                        <span className="ant-input-group-wrapper">
                            <span className="ant-input-wrapper ant-input-group">
                                <span className="ant-input-group-addon">每个活动限购</span>
                                <InputNumber
                                    precision={0}
                                    type="number"
                                    min={1}
                                    max={999999}
                                    value={activity.limitCount}
                                    onChange={(value) => {
                                        this.onChangeLimit('limitCount', value, 1);
                                    }}
                                    style={{ width: '121px' }}
                                />
                                <span className="ant-input-group-addon">件</span>
                            </span>
                        </span>
                    </div>
                </div>
            </Modal>
        );
    }
}
