import React, { PureComponent } from 'react';
import { Card, Table } from 'antd';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import PayAuthorizationButton from '../../member-payment-authorization-detail/PayAuthorizationButton';

import './index.less';
import { services } from '@comall-backend-builder/core';
const prefix = 'member-payment-authorization';

interface Props {
    memberId: string;
}

interface EventItem {
    lastModifiedTime: string;
    payModeName: string;
    sequence: number;
    status: string;
}
interface State {
    loading: boolean;
    stateData: EventItem[];
    logData: EventItem[];
}

/**
 * 会员个人信息-支付授权
 */
export default class MemberPayAuthorizationDetail extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            stateData: [],
            logData: [],
            loading: false,
        };
    }
    componentDidMount() {
        this.loadData();
    }
    private loadData = () => {
        const { memberId } = this.props;
        this.setState({ loading: true });
        //获取详细信息
        api.get(
            { memberId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/payment_member_authorization`,
            }
        )
            .then((res: any) => {
                this.setState({
                    stateData: res?.authorizationList || [],
                    logData: res?.logList || [],
                });
            })
            .catch(errorHandle)
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { stateData, logData, loading } = this.state;
        const { memberId } = this.props;
        const inClassName = {
            box: `${prefix}__table`,
        };
        const _that = this;
        const stateColumns = [
            {
                title: language.getText('sqzffsmc'),
                dataIndex: 'payModeName',
                key: 'payModeName',
            },
            {
                title: language.getText('yhsqzt'),
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: language.getText('zhycczsj'),
                dataIndex: 'lastModifiedTime',
                key: 'lastModifiedTime',
                render(text: string) {
                    return <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>;
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                dataIndex: 'operation',
                key: 'operation',
                render(text: string, record: any) {
                    return (
                        <PayAuthorizationButton
                            key={record.paymentModeCode}
                            memberId={memberId}
                            row={record}
                            OnRefresh={_that.loadData}
                        />
                    );
                },
            },
        ];

        const logColumns = [
            {
                title: language.getText('sqzffsmc'),
                dataIndex: 'payModeName',
                key: 'payModeName',
            },
            {
                title: language.getText('common.tableAction'),
                dataIndex: 'operation',
                key: 'operation',
            },
            {
                title: language.getText('czsj'),
                dataIndex: 'createTime',
                key: 'createTime',
                render(text: string) {
                    return <div>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''}</div>;
                },
            },
            {
                title: language.getText('lastModifiedUser'),
                dataIndex: 'operationUserName',
                key: 'operationUserName',
            },
            {
                title: language.getText('remark'),
                dataIndex: 'remark',
                key: 'remark',
            },
        ];

        return (
            <div className={inClassName.box}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    dataSource={stateData}
                    columns={stateColumns}
                    pagination={false}
                    loading={loading}
                    rowKey={(record: EventItem, index: number) => `${record.payModeName}-${index}`}
                />
                <div style={{ marginTop: 20 }}>
                    <Card
                        title={language.getText('zfsqrz')}
                        bordered={false}
                        bodyStyle={{ paddingLeft: 0 }}
                    >
                        <Table
                            rowClassName={(_, index: number) => {
                                if (index % 2) {
                                    return `${prefix}__table__bg`;
                                }
                                return '';
                            }}
                            dataSource={logData}
                            columns={logColumns}
                            pagination={false}
                            loading={loading}
                            rowKey={(record: EventItem, index: number) =>
                                `${record.payModeName}-${index}`
                            }
                        />
                    </Card>
                </div>
            </div>
        );
    }
}
