import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Cascader, Icon, Input, message, message as AntMessage, Modal, Tooltip } from 'antd';
import { Textbox } from '@comall-backend-builder/components-basis';
import { api, localStorage } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, debounce, find, forEach, isNil, last } from 'lodash';
import { SceneType, wechantTemplatesTargetOptions } from '../../common';
import { MessageParameterSelector } from '../message-parameter-selector';
import { ColorPickerPlus } from '../../../../components';
import { TopicSelector } from '../../../../applications/design/design/components/custom-select-topic';
import { CustomSelectLiveInfo } from '../../../../applications/design/design/components/custom-select-live-info';
import { CustomSelectWheelDraw } from '../../../../applications/design/design/components/custorm-select-wheel-draw';
import { CustomSelectInvite } from '../../../../applications/design/design/components/custom-select-invite';
import { CustomSelectRedPackageRain } from '../../../../applications/design/design/components/custom-select-red-package-rain';
import { CustomSelectBlindBox } from '../../../../applications/design/design/components/custom-select-blind-box';
import { CustomSelectScratchCard } from '../../../../applications/design/design/components/custom-select-scratch-card';
import { CustomSelectLotteryCode } from '../../../../applications/design/design/components/custom-select-lottery-code';
import { CustomSelectMallActivity } from '../../../../applications/design/design/components/custom-select-mall-activity';
import { CustomSelectCardCollection } from '../../../../applications/design/design/components/custom-select-card-collection';
import { WechatTemplatesChooseModal } from '../wechat-templates-choose-modal';
import { searchStringify, searchWebParse } from '../../../../services/utils';
import './index.less';
import { MsgChannelType } from '../../messages-subscription-find-list-item';

enum FieldType {
    SHORT_THING = 'short_thing',
    THING = 'thing',
    PHONE_NUMBER = 'phone_number',
    CAR_NUMBER = 'car_number',
    NUMBER = 'number',
    LETTER = 'letter',
    SYMBOL = 'symbol',
    CHARACTER_STRING = 'character_string',
    TIME = 'time',
    DATE = 'date',
    AMOUNT = 'amount',
    NAME = 'name',
    PHRASE = 'phrase',
    KEYWORD = 'keyword',
    CONST = 'const',
}

export interface Props {
    msgChannelType: MsgChannelType;
    editId?: number;
    refreshList: () => void;
    handleVisible: () => void;
    visible: boolean;
}

interface TemplateParamsVo {
    name: string;
    value: string;
    color: string;
    defaultValue: string;
    editable: boolean;
    fieldType: string;
    info: string;
}

interface TemplateVo {
    id?: number;
    name: string;
    scene: SceneType;
    sceneRemark: string;
    alias: string;
    code: string;
    miniTargetUrl: string;
    top?: string;
    other?: Array<TemplateParamsVo>;
    bottom?: string;
    topDefaultValue?: string;
    detailContentColor?: string;
    bottomContentColor?: string;
    bottomDefaultValue?: string;
    templateId?: string;
    msgChannelType?: MsgChannelType;
}

export interface States {
    data: TemplateVo | undefined;
    pathParams: any | undefined; //特殊页面传参；比如专题页选择后的id，name
    subsiteWechantName: string;
    logoUrl: string;
    appId: string;
}

/**
 * 编辑
 */
export class MessagesSubscriptionFindListItemEditModal extends PureComponent<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: undefined,
            pathParams: undefined,
            subsiteWechantName: '',
            logoUrl: '',
            appId: '',
        };
    }

    componentDidMount() {
        this.loadWechatOfficialAccount();
        this.loadTemplateInfo();
    }

    loadWechatOfficialAccount = async () => {
        let store: any = localStorage.get('wechantTemplatesStore');
        if (store && store.id) {
            //公众号名称
            const result: any = await api.get(
                {
                    subsiteId: store.id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                }
            );
            if (result) {
                this.setState({
                    subsiteWechantName: result.appName ? result.appName : '',
                    appId: result.appId ? result.appId : undefined,
                });
            }
        }
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/front_config`,
            }
        ).then((response: any) => {
            if (response) {
                this.setState({
                    logoUrl: response.authorizeLogoFileUrl,
                });
            }
        });
    };

    loadTemplateInfo = () => {
        const { editId } = this.props;
        if (!editId) {
            //新增手动模板
            this.setState({
                data: {
                    name: '',
                    scene: SceneType.MANUAL_ADD,
                    sceneRemark: '',
                    alias: '',
                    code: '',
                    miniTargetUrl: '',
                    msgChannelType: MsgChannelType.WECHAT_OFFICIAL_ACCOUNT,
                },
            });
            return;
        }
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: '/admin/template_definitions/' + editId,
            }
        ).then((data: any) => {
            if (data && data.params) {
                const top = find(data.params, { name: 'top' });
                const bottom = find(data.params, { name: 'bottom' });
                if (top) {
                    data.top = top.value;
                    // data.topContentColor = top.color;
                    data.topDefaultValue = top.defaultValue;
                }
                if (bottom) {
                    data.bottom = bottom.value;
                    data.bottomContentColor = bottom.color;
                    data.bottomDefaultValue = bottom.defaultValue;
                }
                const other: any = [];
                forEach(data.params, (param: any) => {
                    if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                        other.push(param);
                    }
                });
                data.other = other;
                if (other.length > 0) {
                    data.detailContentColor = other[0].color;
                }
            }
            data.miniTargetUrl = data.targetUrl || '';
            if (data.miniTargetUrl && this.getTargetUrlParamsComponent(data.miniTargetUrl)) {
                const urlVo = data.miniTargetUrl.split('?');
                const pageParams: string = urlVo && urlVo.length >= 1 ? urlVo[1] : '';
                const scene = urlVo && urlVo.length > 1 ? searchWebParse(pageParams) : null;
                if (scene) {
                    if (
                        data.miniTargetUrl.includes('subpackages/topic') ||
                        data.miniTargetUrl.includes('wheel-draw/pages') ||
                        data.miniTargetUrl.includes('invite-reward/pages') ||
                        data.miniTargetUrl.includes('packet-rain/index') ||
                        data.miniTargetUrl.includes('blind-box/pages/detail') ||
                        data.miniTargetUrl.includes('scratch-card/index') ||
                        data.miniTargetUrl.includes('lottery-code/index') ||
                        data.miniTargetUrl.includes('mall-activity/pages/activity-detail') ||
                        data.miniTargetUrl.includes('card-collection/index')
                    ) {
                        const id = scene.id || '';
                        const name = scene.name || '';
                        if (id && name) {
                            this.setState({
                                pathParams: {
                                    id: id,
                                    name,
                                },
                            });
                            data.miniTargetUrl = data.miniTargetUrl
                                .replace('&id=' + id, '')
                                .replace('&name=' + name, '');
                        }
                    } else if (data.miniTargetUrl.includes('pages/live-player-plugin')) {
                        const roomId = scene.room_id || '';
                        const name = scene.name || '';
                        if (roomId && name) {
                            this.setState({
                                pathParams: {
                                    room_id: roomId,
                                    name,
                                },
                            });
                            data.miniTargetUrl = data.miniTargetUrl
                                .replace('&room_id=' + roomId, '')
                                .replace('&name=' + name, '');
                        }
                    }
                }
            }
            this.setState({
                data: data,
            });
        });
    };

    validate = () => {
        const { data } = this.state;
        if (!data) {
            return false;
        }
        const { top, bottom, miniTargetUrl, scene, other, sceneRemark } = data;
        // if (!top) {
        //     message.warning('请填写模板头部信息');
        //     return false;
        // }
        // if (!bottom) {
        //     message.warning('请填写模板尾部信息');
        //     return false;
        // }

        if (!this.validateTargetUrl(miniTargetUrl, scene)) {
            return false;
        }

        if (scene === SceneType.MANUAL_ADD && !sceneRemark) {
            message.warning(services.language.getText('qtxcjsm'));
            return false;
        }

        let hasValidate = true;
        let otherLength = 0;
        forEach(other || [], (o) => {
            otherLength += o.value ? o.value.length : 0;
            if (o.editable) {
                if (!o.value) {
                    AntMessage.warning(`${services.language.getText('selectFillin')}${o.name}`);
                    hasValidate = false;
                    return false;
                }
                const fieldType = o.fieldType.toLowerCase();
                const info = this.getOtherValidInfo(fieldType);
                if (info && !info.valid.test(o.value)) {
                    AntMessage.warning(
                        `${o.name}${services.language.getText('gscw')}${info.errorTip}】`
                    );
                    hasValidate = false;
                    return false;
                }
            }
        });
        if (!hasValidate) {
            return false;
        }

        if ((top?.length || 0) + (bottom?.length || 0) + otherLength > 200) {
            message.warning(services.language.getText('mbnryc200'));
            return false;
        }
        return true;
    };

    validateTargetUrl = (miniTargetUrl: string, scene: SceneType) => {
        const { pathParams } = this.state;
        const targets: any[] = wechantTemplatesTargetOptions[scene] || [];

        if (targets.length && (!miniTargetUrl || miniTargetUrl === '0')) {
            message.warning(services.language.getText('qxzljlx'));
            return false;
        }
        if (miniTargetUrl && this.getTargetUrlParamsComponent(miniTargetUrl) && !pathParams) {
            message.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        return true;
    };

    handleChange = (key: string, val: string) => {
        const targetUrl = val && val.length > 0 ? last(val) : '';
        let { data } = this.state;
        if (data) {
            // @ts-ignore
            data[key] = targetUrl;
        }
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
        if (key === 'miniTargetUrl') {
            this.setState({
                pathParams: undefined,
            });
        }
    };

    isSubmit = false;
    handleSubmit = debounce(() => {
        const hasValidate = this.validate();
        const { data, pathParams, appId } = this.state;
        const { refreshList, handleVisible, editId, msgChannelType } = this.props;
        if (!data) {
            return;
        }
        if (hasValidate) {
            let targetUrl = data.miniTargetUrl || '';
            if (targetUrl && this.getTargetUrlParamsComponent(targetUrl)) {
                targetUrl += `${targetUrl.includes('?') ? '&' : '?'}${searchStringify(pathParams)}`;
            }
            const detailContent: any = [];
            if (data.other && data.other.length > 0) {
                forEach(data.other, (o: any) => {
                    if (o.editable) {
                        detailContent.push({
                            name: o.name,
                            value: o.value,
                            field_type: o.fieldType, //后端要求蛇形
                            editable: o.editable,
                        });
                    }
                });
            }
            const params = {
                msgChannelType, //公众号模板消息
                topContent: data.top,
                // topContentColor: data.topContentColor,
                bottomContent: data.bottom,
                bottomContentColor: data.bottomContentColor,
                detailContentColor: data.detailContentColor,
                targetUrl: targetUrl,
                scene: data.scene,
                remark: data.scene === SceneType.MANUAL_ADD ? data.sceneRemark : undefined,
            };
            if (this.isSubmit) {
                return;
            }
            this.isSubmit = true;
            if (editId) {
                api.put(
                    {
                        detailContent: JSON.stringify(detailContent),
                        ...params,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: '/admin/template_definitions/' + data.id,
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('editSuccess'));
                        handleVisible();
                        refreshList();
                    })
                    .catch((error) => {
                        services.errorHandle(error);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSubmit = false;
                        }, 1000);
                    });
            } else {
                let store: any = localStorage.get('wechantTemplatesStore');
                api.post(
                    {
                        detailContents: detailContent,
                        title: data.name,
                        appId: appId,
                        templateId: data.templateId,
                        subsiteId: store.id,
                        ...params,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: '/admin/template_definitions/manual',
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('addSuccess'));
                        handleVisible();
                        refreshList();
                    })
                    .catch((error) => {
                        services.errorHandle(error);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSubmit = false;
                        }, 1000);
                    });
            }
        }
    }, 500);

    onHandleTextBoxChange(type: string, valueString: any) {
        let { data } = this.state;
        if (!data) {
            return {};
        }
        // @ts-ignore
        data[type] = valueString;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
        return {};
    }

    onHandleOtherChange(name: string, valueString: any) {
        let { data } = this.state;
        if (data && data.other) {
            const current = find(data.other, {
                name: name,
            });
            if (current) {
                current.value = valueString;
                this.setState(
                    {
                        data: data,
                    },
                    () => {
                        this.forceUpdate();
                    }
                );
            }
        }
        return {};
    }

    isSubscriptionMessage = () => {
        const { msgChannelType } = this.props;
        return (
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG
        );
    };

    renderLeftWrap = () => {
        const { data, subsiteWechantName, logoUrl } = this.state;
        const topImage = require('../images/icon-miniprogram-top.png');
        const goImage = require('../images/icon-miniprogram-right.png');
        const miniImage = require('../images/icon-miniprogram.png');
        return (
            <div className="wechat-templates-find-list-item-edit-view__left-wrap">
                <div className="wechat-templates-find-list-item-edit-view__info">
                    <div className="wechat-templates-find-list-item-edit-view__info__top">
                        <img src={topImage} width="364px" alt="" />
                        <div className="wechat-templates-find-list-item-edit-view__info__top__name">
                            {subsiteWechantName}
                        </div>
                    </div>
                    <div className="wechat-templates-find-list-item-edit-view__info__data-info">
                        <div className="wechat-templates-find-list-item-edit-view__info__content">
                            <div className="wechat-templates-find-list-item-edit-view__info__header">
                                <div>{data && data.name ? data.name : '模板标题'}</div>
                                <div className="wechat-templates-find-list-item-edit-view__info__dian-wrap">
                                    <div className="wechat-templates-find-list-item-edit-view__info__dian"></div>
                                    <div className="wechat-templates-find-list-item-edit-view__info__dian"></div>
                                    <div className="wechat-templates-find-list-item-edit-view__info__dian"></div>
                                </div>
                            </div>
                            {data && (
                                <div>
                                    {!!data.top && (
                                        <div className="wechat-templates-find-list-item-edit-view__info__center">
                                            <span
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                    // color:
                                                    //     data.top && data.topContentColor
                                                    //         ? data.topContentColor
                                                    //         : '#747474',
                                                }}
                                            >
                                                {data.top}
                                            </span>
                                        </div>
                                    )}
                                    <div className="wechat-templates-find-list-item-edit-view__info__other">
                                        {data.other &&
                                            data.other.map((o: any) => {
                                                return (
                                                    <div
                                                        key={o.name}
                                                        className="wechat-templates-find-list-item-edit-view__info__other-item"
                                                    >
                                                        <span>{o.name}：</span>
                                                        <span
                                                            style={{
                                                                color:
                                                                    data && data.detailContentColor
                                                                        ? data.detailContentColor
                                                                        : '#000',
                                                                flex: 1,
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            {o.value}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    {!!data.bottom && (
                                        <div
                                            className="wechat-templates-find-list-item-edit-view__info__buttom"
                                            style={{
                                                whiteSpace: 'pre-line',
                                                color: data.bottomContentColor || '#D0021B',
                                            }}
                                        >
                                            {data.bottom}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {data && this.isSubscriptionMessage() ? (
                            <div className="wechat-templates-find-list-item-edit-view__info__footer">
                                <div>
                                    <span>{services.language.getText('jrxcxck')}</span>
                                </div>
                                <div>
                                    <img
                                        src={goImage}
                                        alt=""
                                        style={{ marginLeft: '4px', width: '16px' }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="wechat-templates-find-list-item-edit-view__info__footer">
                                <div>
                                    <img
                                        src={logoUrl}
                                        alt=""
                                        className="wechat-templates-find-list-item-edit-view__info__logo"
                                    />
                                    <span>{services.language.getText('xcxmc')}</span>
                                </div>
                                <div>
                                    <img
                                        src={miniImage}
                                        alt=""
                                        style={{ marginRight: '4px', width: '16px' }}
                                    />
                                    <span>{services.language.getText('xcx')}</span>
                                    <img
                                        src={goImage}
                                        alt=""
                                        style={{ marginLeft: '4px', width: '16px' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderMiniTargetUrlParams = () => {
        const { data } = this.state;
        if (data && data.miniTargetUrl && this.getTargetUrlParamsComponent(data.miniTargetUrl)) {
            return (
                <div className="wechat-templates-find-list-item-edit-view__flex">
                    <div
                        style={{ lineHeight: '40px' }}
                        className="wechat-templates-find-list-item-edit-view__title"
                    >
                        {services.language.getText('ljmb')}：
                    </div>
                    <div className="wechat-templates-find-list-item-edit-view__value">
                        {this.getTargetUrlParamsComponent(data.miniTargetUrl)}
                    </div>
                </div>
            );
        }
        return null;
    };

    getTargetUrlParamsComponent = (miniTargetUrl: string) => {
        const { pathParams } = this.state;
        let store: any = localStorage.get('wechantTemplatesStore');
        // 专题活动
        if (miniTargetUrl.includes('subpackages/topic')) {
            return (
                <TopicSelector
                    newTopic
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 直播间
        if (miniTargetUrl.includes('pages/live-player-plugin')) {
            return (
                <CustomSelectLiveInfo
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 大转盘抽奖
        if (miniTargetUrl.includes('wheel-draw/pages')) {
            return (
                <CustomSelectWheelDraw
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 邀请有礼
        if (miniTargetUrl.includes('invite-reward/pages')) {
            return (
                <CustomSelectInvite
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 红包雨
        if (miniTargetUrl.includes('packet-rain/index')) {
            return (
                <CustomSelectRedPackageRain
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 盲盒活动列表
        if (miniTargetUrl.includes('blind-box/pages/detail')) {
            return (
                <CustomSelectBlindBox
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 积分刮刮卡
        if (miniTargetUrl.includes('scratch-card/index')) {
            return (
                <CustomSelectScratchCard
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 积分抽奖码
        if (miniTargetUrl.includes('lottery-code/index')) {
            return (
                <CustomSelectLotteryCode
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 商场活动
        if (miniTargetUrl.includes('mall-activity/pages/activity-detail')) {
            return (
                <CustomSelectMallActivity
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 集卡活动
        if (miniTargetUrl.includes('card-collection/index')) {
            return (
                <CustomSelectCardCollection
                    subsiteIds={[store.id]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        return null;
    };

    onChangePathParams = (value: any) => {
        const { data } = this.state;
        if (data && data.miniTargetUrl) {
            const miniTargetUrl = data.miniTargetUrl;
            if (
                miniTargetUrl.includes('subpackages/topic') ||
                miniTargetUrl.includes('wheel-draw/pages') ||
                miniTargetUrl.includes('invite-reward/pages') ||
                miniTargetUrl.includes('packet-rain/index') ||
                miniTargetUrl.includes('blind-box/pages/detail') ||
                miniTargetUrl.includes('scratch-card/index') ||
                miniTargetUrl.includes('lottery-code/index') ||
                miniTargetUrl.includes('mall-activity/pages/activity-detail') ||
                miniTargetUrl.includes('card-collection/index')
            ) {
                this.setState({
                    pathParams: {
                        id: value.id,
                        name: value.name,
                    },
                });
            } else if (miniTargetUrl.includes('pages/live-player-plugin')) {
                this.setState({
                    pathParams: {
                        room_id: value.roomid,
                        name: value.name,
                    },
                });
            }
        }
    };

    getOtherValidInfo = (fieldType: string) => {
        let maxLength = 100;
        let valid = new RegExp('/^[\u4e00-\u9fa5\\d\\w\\s\\S]{1,20}$/');
        let placeholder = '';
        let tip = '';
        let errorTip = '';
        if (fieldType.includes(FieldType.SHORT_THING)) {
            maxLength = 5;
            valid = new RegExp(/^[\u4e00-\u9fa5\d\w\s\S]{1,5}$/);
            placeholder = services.language.getText('gynzf');
            tip = services.language.getText('gynzfTip');
            errorTip = services.language.getText('gynzf');
        } else if (fieldType.includes(FieldType.LETTER)) {
            maxLength = 32;
            valid = new RegExp(/^[a-zA-Z]{1,32}$/);
            placeholder = services.language.getText('wynywzf');
            tip = services.language.getText('wynywzfTip');
            errorTip = services.language.getText('wyncywzm');
        } else if (fieldType.includes(FieldType.SYMBOL)) {
            maxLength = 5;
            valid = new RegExp(/^[^\u4e00-\u9fa5A-Za-z0-9]{1,5}$/);
            placeholder = services.language.getText('wwynzf');
            tip = services.language.getText('wwynzfzktxfh');
            errorTip = services.language.getText('wwynzfzktxfh');
        } else if (fieldType.includes(FieldType.CHARACTER_STRING)) {
            maxLength = 32;
            valid = new RegExp(/^[\u0021-\u007E]{1,32}$/);
            placeholder = services.language.getText('wynszzmhzf');
            tip = services.language.getText('wynszzmhzf');
            errorTip = services.language.getText('wynszzmhzf');
        } else if (fieldType.includes(FieldType.TIME)) {
            //支持：
            // 或 2019年10月1日
            // 或 2019-10-11
            // 或 2019年10月1日 15:01
            // 或2019-10-1 15:01
            // 或 15:01
            // 或 09:00~15:00
            valid = new RegExp(
                /^(?:(\d{4})[年](\d{1,2})[月](\d{1,2})日|(\d{4})[./-](\d{1,2})[./-](\d{1,2})|(\d{4})[年](\d{1,2})[月](\d{1,2})日\s+(\d{2}):(\d{2})|(\d{4})[./-](\d{1,2})[./-](\d{1,2})\s+(\d{2}):(\d{2})|\d{2}:\d{2}|(?:\d{2}:\d{2}~\d{2}:\d{2}))$/
            );
            placeholder = services.language.getText('qsrsj');
            tip = services.language.getText('xszsjgszcsjd');
            errorTip = services.language.getText('zktxsjgs');
        } else if (fieldType.includes(FieldType.DATE)) {
            //支持： 2019年10月1日～2019年-10月-2日 或者2019年10月1日 15:01～2019年10月1日 15:01 或 2019-10-1～2019-10-2 或 2019-10-1 15:01~2019-10-1 15:01
            valid = new RegExp(
                /^(?:(\d{4})[年](\d{1,2})[月](\d{1,2})日~(\d{4})[年](\d{1,2})[月](\d{1,2})日|(\d{4})[年](\d{1,2})[月](\d{1,2})日\s+(\d{2}):(\d{2})~(\d{4})[年](\d{1,2})[月](\d{1,2})日\s+(\d{2}):(\d{2})|(\d{4})[./-](\d{1,2})[./-](\d{1,2})~(\d{4})[./-](\d{1,2})[./-](\d{1,2})|(\d{4})[./-](\d{1,2})[./-](\d{1,2})\s+(\d{2}):(\d{2})~(\d{4})[./-](\d{1,2})[./-](\d{1,2})\s+(\d{2}):(\d{2}))$/
            );
            placeholder = services.language.getText('qsrrq');
            tip = services.language.getText('nyrgszctsjd');
            errorTip = services.language.getText('zktxsjgs');
        } else if (fieldType.includes(FieldType.AMOUNT)) {
            maxLength = 10;
            valid = new RegExp(/^(?:[A-Za-z]{1,3})?\d{1,10}(?:\.\d{1,2})?元?$/);
            placeholder = services.language.getText('swynszkdxs');
            tip = services.language.getText('jwkd');
            errorTip = services.language.getText('swynszkdxsd');
        } else if (fieldType.includes(FieldType.PHONE_NUMBER)) {
            maxLength = 17;
            valid = new RegExp(/^(?:[\d+()\-\s]){1,17}$/);
            placeholder = services.language.getText('wynszfh');
            tip = services.language.getText('wynszfhdh');
            errorTip = services.language.getText('wynszfh');
        } else if (fieldType.includes(FieldType.CAR_NUMBER)) {
            maxLength = 8;
            //^[A-Za-z0-9\u4e00-\u9fa5][A-Za-z0-9]{0,6}[A-Za-z0-9\u4e00-\u9fa5]$/
            valid = new RegExp(
                /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Za-z0-9]{4,5}[A-Za-z0-9挂学警港澳]{1}$/
            );
            placeholder = services.language.getText('jkwcph8wyn');
            tip = services.language.getText('jkwcphdywyzh');
            errorTip = services.language.getText('jkwcph8wyn');
        } else if (fieldType.includes(FieldType.NUMBER)) {
            maxLength = 32;
            valid = new RegExp(/^(?:\d{1,32}(?:\.\d{1,32})?)$/);
            placeholder = services.language.getText('yncszkdxs');
            tip = services.language.getText('wynzktxsz');
            errorTip = services.language.getText('yncszkdxs');
        } else if (fieldType.includes(FieldType.NAME)) {
            maxLength = 20;
            valid = new RegExp(
                /^([\u4e00-\u9fa5]{1,10}|[A-Za-z]{1,20}|[\u4e00-\u9fa5][A-Za-z]{1,10})$/
            );
            placeholder = services.language.getText('gyncszhgynczm');
            tip = services.language.getText('gynchzhgynczm');
            errorTip = services.language.getText('gyncszhgynczm');
        } else if (fieldType.includes(FieldType.PHRASE)) {
            maxLength = 5;
            valid = new RegExp(/^[\u4e00-\u9fa5]{1,5}$/);
            placeholder = services.language.getText('gynhz');
            tip = services.language.getText('gynhz');
            errorTip = services.language.getText('gynhz');
        } else if (
            fieldType.includes(FieldType.THING) ||
            fieldType.includes(FieldType.KEYWORD) ||
            fieldType.includes(FieldType.CONST)
        ) {
            maxLength = 20;
            valid = new RegExp(/^[\u4e00-\u9fa5\d\w\s\S]{1,20}$/);
            placeholder = services.language.getText('gynzf20');
            tip = services.language.getText('gynzf20Tip');
            errorTip = services.language.getText('gynzf20');
        }
        return {
            maxLength,
            valid,
            placeholder,
            tip,
            errorTip,
        };
    };

    renderOther = (o: any) => {
        const { data } = this.state;
        if (!data) {
            return null;
        }
        if (data.code && data.code.includes('OPENTM')) {
            return (
                <Textbox
                    prefixIcon=""
                    suffixIcon=""
                    type="textarea"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    name="textBox"
                    placeholder={services.language.getText('inputPlease')}
                    value={o.value}
                    onChange={(value: any) => this.onHandleOtherChange(o.name, value)}
                />
            );
        }
        const fieldType = o.fieldType ? o.fieldType.toLowerCase() : '';
        const info = this.getOtherValidInfo(fieldType);
        return (
            <div className="wechat-templates-find-list-item-edit-view__other-wrap">
                <Input
                    placeholder={info.placeholder}
                    value={o.value}
                    onChange={(value: any) => this.onHandleOtherChange(o.name, value.target.value)}
                    maxLength={info.maxLength}
                />
                <Tooltip
                    placement="top"
                    title={info.tip}
                    className="wechat-templates-find-list-item-edit-view__other-wrap__icon"
                >
                    <Icon type="question-circle" theme="outlined" />
                </Tooltip>
            </div>
        );
    };

    onChangeTemplate = (template: any) => {
        const { data } = this.state;
        if (!data) {
            return null;
        }
        data.name = template.title;
        data.templateId = template.templateId;
        if (template.contents) {
            const top = find(template.contents, { name: 'top' });
            const bottom = find(template.contents, { name: 'bottom' });
            if (top) {
                data.top = top.value;
                data.topDefaultValue = top.defaultValue;
            }
            if (bottom) {
                data.bottom = bottom.value;
                data.bottomContentColor = bottom.color;
                data.bottomDefaultValue = bottom.defaultValue;
            }
            const other: any = [];
            forEach(template.contents, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    param.fieldType = param.fieldType.toLowerCase();
                    other.push(param);
                }
            });
            data.other = other;
            if (other.length > 0) {
                data.detailContentColor = other[0].color;
            }
        }
        this.setState({
            data: cloneDeep(data),
        });
    };

    renderRightWrap = () => {
        const { data, appId } = this.state;
        if (!data) {
            return null;
        }
        const targets: any[] = wechantTemplatesTargetOptions[data.scene as SceneType];
        const others: any[] = data.other?.filter((item: any) => item.editable) || [];
        return (
            <div className="wechat-templates-find-list-item-edit-view__right-wrap">
                <div className="wechat-templates-find-list-item-edit-view__right-title">
                    {services.language.getText('nrpz')}
                </div>
                {(data.scene === SceneType.MANUAL_ADD || (others && others.length > 0)) && (
                    <div className="wechat-templates-find-list-item-edit-view__parameter">
                        <MessageParameterSelector />
                        <div>{services.language.getText('qfzkydblmc')}</div>
                    </div>
                )}
                {data.scene === SceneType.MANUAL_ADD && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('templateId')}：
                        </div>
                        {data.id ? (
                            <div className="wechat-templates-find-list-item-edit-view__value">
                                {data.templateId}
                            </div>
                        ) : (
                            <div className="wechat-templates-find-list-item-edit-view__value">
                                <WechatTemplatesChooseModal
                                    appId={appId}
                                    defalutTemplateId={data.templateId}
                                    onChange={this.onChangeTemplate}
                                />
                            </div>
                        )}
                    </div>
                )}
                {data.scene === SceneType.MANUAL_ADD && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('cjsm')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <Textbox
                                prefixIcon=""
                                suffixIcon=""
                                type="textarea"
                                autoSize={{ minRows: 2, maxRows: 3 }}
                                name="textBox"
                                placeholder={services.language.getText('zd40gzf')}
                                value={data.sceneRemark}
                                onChange={(value: any) =>
                                    this.onHandleTextBoxChange('sceneRemark', value)
                                }
                                maxLength={40}
                            />
                        </div>
                    </div>
                )}
                {data.scene !== SceneType.MANUAL_ADD && data.sceneRemark && (
                    <div className="wechat-templates-find-list-item-edit-view__flex wechat-templates-find-list-item-edit-view__flex-tip">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('cjsm')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            {data.sceneRemark}
                        </div>
                    </div>
                )}
                {data.name && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('mbbt')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            {data.name}
                            {!!data.alias && (
                                <div className="wechat-templates-find-list-item-edit-view__tip">
                                    {data.alias}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!isNil(data.top) && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('mbtb')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <div className="wechat-templates-find-list-item-edit-view__value-flex">
                                <Textbox
                                    prefixIcon=""
                                    suffixIcon=""
                                    type="textarea"
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                    name="textBox"
                                    placeholder={services.language.getText('inputPlease')}
                                    value={data.top}
                                    onChange={(value: any) =>
                                        this.onHandleTextBoxChange('top', value)
                                    }
                                    // style={{ color: data.topContentColor || '#000000' }}
                                />
                                {/*<div className="wechat-templates-find-list-item-edit-view__color">*/}
                                {/*    <ColorPickerPlus {...topContentColorProps} />*/}
                                {/*</div>*/}
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__tip">
                                {services.language.getText('example_2')}
                                {data.topDefaultValue}
                            </div>
                        </div>
                    </div>
                )}
                {others &&
                    others.map((o: any, index: number) => {
                        return (
                            <div
                                className="wechat-templates-find-list-item-edit-view__flex"
                                key={index}
                            >
                                <div className="wechat-templates-find-list-item-edit-view__other-title">
                                    {o.name}：
                                </div>
                                <div className="wechat-templates-find-list-item-edit-view__value">
                                    {this.renderOther(o)}
                                    {o.info && (
                                        <div className="wechat-templates-find-list-item-edit-view__tip">
                                            {services.language.getText('example_2')}
                                            {o.info}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                {data.detailContentColor && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('xqys')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <ColorPickerPlus
                                {...({
                                    originalColor: '#000',
                                    value: data && data.detailContentColor,
                                    onChange: (value: string) => {
                                        this.handleChange('detailContentColor', value);
                                    },
                                } as any)}
                            />
                        </div>
                    </div>
                )}
                {!isNil(data.bottom) && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {services.language.getText('remark')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <Textbox
                                prefixIcon=""
                                suffixIcon=""
                                type="textarea"
                                autoSize={{ minRows: 2, maxRows: 3 }}
                                name="textBox"
                                placeholder={services.language.getText('inputPlease')}
                                value={data.bottom}
                                onChange={(value: any) =>
                                    this.onHandleTextBoxChange('bottom', value)
                                }
                            />
                            <div className="wechat-templates-find-list-item-edit-view__tip">
                                {services.language.getText('example_2')}
                                {data.bottomDefaultValue}
                            </div>
                            {data.bottomContentColor && (
                                <div style={{ marginTop: '10px' }}>
                                    <ColorPickerPlus
                                        {...({
                                            originalColor: '#D0021B',
                                            value: data && data.bottomContentColor,
                                            onChange: (value: string) => {
                                                this.handleChange('bottomContentColor', value);
                                            },
                                        } as any)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!!targets.length && (
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__other-title">
                            {services.language.getText('ljlx')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <Cascader
                                className="trans-action-selection"
                                style={{ width: '230px' }}
                                value={this.getCascaderMiniTargetUrl(data.miniTargetUrl)}
                                options={targets}
                                placeholder={services.language.getText('selectPlease')}
                                onChange={(transAction: any) =>
                                    this.handleChange('miniTargetUrl', transAction)
                                }
                            />
                            {/* <Select
                                className="trans-action-selection"
                                style={{ width: '130px' }}
                                value={data.miniTargetUrl}
                                placeholder={services.language.getText('selectPlease')}
                                onChange={(transAction: any) =>
                                    this.handleChange('miniTargetUrl', transAction)
                                }
                                allowClear
                            >
                                {targets.map((option) => (
                                    <Select.Option key={option.path}>{option.name}</Select.Option>
                                ))}
                            </Select> */}
                        </div>
                    </div>
                )}
                {this.renderMiniTargetUrlParams()}
            </div>
        );
    };

    getCascaderMiniTargetUrl = (miniTargetUrl: string) => {
        const { data } = this.state;
        const list = [
            SceneType.SUBSITE_BUSINESS_STATUS_CHANGE,
            SceneType.BUSINESS_EXPIRE_MANUAL_REMIND,
            SceneType.APPOINTMENT_SUCCESS_REMIND,
            SceneType.MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE,
            SceneType.POS_RECEIPT_ORDER_COMMENT,
            SceneType.MANUAL_ADD,
            SceneType.ACTIVITY_NOTICE,
        ];
        if (data && list.includes(data.scene)) {
            const marketing = [
                'flash-sale/index',
                'wheel-draw/pages',
                'invite-reward/pages',
                'sign-in-with-gift/index',
                'card-collection/index',
                'packet-rain/index',
                'group-buying/pages/list',
                'group-buying/pages/virtual-list',
                'blind-box/pages/list',
                'blind-box/pages/detail',
                'mode=FULL_PAYMENT',
                'mode=RESERVE&',
            ];
            const credit = [
                'credit-shop/index',
                'scratch-card/index',
                'lottery-code/index',
                'self-crediting/pages',
                'business-circle-point-plugin/index',
            ];
            const memberMenu = [
                'mall-activity/pages/activity-detail',
                'mall-activity/pages/activity-calendar',
                'mall-activity/index?type=list',
            ];
            const service = [
                'parking-fee/index',
                'parking-fee/pages/parking-fee-records',
                'coupons-center/index',
                'coupons-center/pages/my-balance-coupons',
                'subpackages/electronic-bill/pages/list/index',
            ];
            if (marketing.some((i) => miniTargetUrl.includes(i))) {
                return ['marketing', miniTargetUrl];
            }
            if (credit.some((i) => miniTargetUrl.includes(i))) {
                return ['credit', miniTargetUrl];
            }
            if (memberMenu.some((i) => miniTargetUrl.includes(i))) {
                return ['member-menu', miniTargetUrl];
            }
            if (service.some((i) => miniTargetUrl.includes(i))) {
                return ['service', miniTargetUrl];
            }
        }
        return [miniTargetUrl];
    };
    renderModal = () => {
        const { visible, handleVisible, editId } = this.props;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                title={
                    editId
                        ? services.language.getText('bjmbxx')
                        : services.language.getText('xzmbxx')
                }
                width="1000px"
                onCancel={handleVisible}
                onOk={this.handleSubmit}
            >
                <div className="wechat-templates-find-list-item-edit-view__wrap">
                    {this.renderLeftWrap()}
                    {this.renderRightWrap()}
                </div>
            </Modal>
        );
    };

    range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    render() {
        return (
            <div className="wechat-templates-find-list-item-edit-view">{this.renderModal()}</div>
        );
    }
}
