import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from 'antd';
import './index.less';

const {
    api,
    language: { getText },
} = services;

export interface NormalInvoiceConfigsHeaderStates {
    invoiceTypeConfig: any | null;
    taxRatesData: any | null;
}

enum invoiceContents {
    PRODUCT_DETAIL = 'PRODUCT_DETAIL',
    PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
}

enum invoiceTypes {
    NORMAL = 'NORMAL',
    SPECIAL = 'SPECIAL',
}

export const invoiceContentsText: any = {
    [invoiceContents.PRODUCT_DETAIL]: 'productDetail',
    [invoiceContents.PRODUCT_CATEGORY]: 'productT',
};

export const invoiceTypesText: any = {
    [invoiceTypes.NORMAL]: 'zzsdzptfp',
    [invoiceTypes.SPECIAL]: 'zzszyfp',
};

export class NormalInvoiceConfigsHeader extends Component<{}, NormalInvoiceConfigsHeaderStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            invoiceTypeConfig: null,
            taxRatesData: null,
        };
    }
    componentDidMount() {
        //获取发票类型配置
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: `/admin/invoice_system_config`,
            }
        ).then((response: any) => {
            this.setState({
                invoiceTypeConfig: response,
            });
        });
        //获取税率
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/category_tax_rates/statistics`,
            }
        ).then((response: any) => {
            this.setState({
                taxRatesData: response,
            });
        });
    }

    goInvoiceTypeConfig = () => {
        const { invoiceTypeConfig } = this.state;
        if (invoiceTypeConfig.id) {
            window.location.href = '/#/invoice-config/normal-invoice-type';
        }
    };

    goInvoiceTaxrateConfig = () => {
        window.location.href = '/#/invoice-config/normal-invoice-taxrate';
    };

    renderInvoiceTypes = () => {
        const { invoiceTypeConfig } = this.state;
        return (
            invoiceTypeConfig && (
                <div>
                    <div className="title">{getText('fplxsz')}</div>
                    <div className="content">
                        <div className="content-value">
                            <span>{getText('zckpnr')}：</span>
                            {invoiceTypeConfig.contents &&
                                invoiceTypeConfig.contents.map((name: any, index: number) => {
                                    return (
                                        name && (
                                            <span className="content-value-right" key={index}>
                                                {getText(invoiceContentsText[name])}
                                            </span>
                                        )
                                    );
                                })}
                        </div>
                        <div className="content-value">
                            <span>{getText('mrzdkfp')}：</span>
                            <span>
                                {invoiceTypeConfig.authInvoicing ? getText('yes') : getText('no')}
                            </span>
                        </div>
                        <Button type="primary" onClick={this.goInvoiceTypeConfig.bind(this)}>
                            {getText('djjxpz')}
                        </Button>
                    </div>
                </div>
            )
        );
    };

    renderInvoicetaxRate = () => {
        const { taxRatesData } = this.state;
        const tip = getText('h_slsyyxj_tip');
        return (
            taxRatesData && (
                <div>
                    <div className="title">{getText('slpz')}</div>
                    <div className="content">
                        <div className="content-value">
                            <span>{getText('gy')}</span>
                            <span className="content-value-red">
                                {taxRatesData.totalCount}
                                {getText('ge')}
                            </span>
                            <span>{getText('standCategoryConfig')}</span>
                            <span className="content-value-red">
                                {taxRatesData.hasTaxCodeCount}
                                {getText('ge')}
                            </span>
                            <span>，{getText('wpz')}</span>
                            <span className="content-value-red">
                                {taxRatesData.noTaxCodeCount}
                                {getText('ge')}
                            </span>
                        </div>
                        <div className="content-value content-value-tip">{tip}</div>
                        <Button type="primary" onClick={this.goInvoiceTaxrateConfig.bind(this)}>
                            {getText('djjxpz')}
                        </Button>
                    </div>
                </div>
            )
        );
    };
    render() {
        return (
            <div className="normal-invoice-configs-header">
                {this.renderInvoiceTypes()}
                {this.renderInvoicetaxRate()}
                <div className="title title-bottom">{getText('ztmdpz')}</div>
            </div>
        );
    }
}
