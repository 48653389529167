import React from 'react';
import { Row, Col, Input } from 'antd';
import { get } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';

import { MoreConfig } from './index';
import './index.less';

interface MoreConfigFormProps {
    value: MoreConfig;
    selector: any;
    uploadAction: string;
    sourceRoot: { image?: string };
    linkTypes: Array<any>;
    onChange: (more: MoreConfig) => void;
}

const UploadPicture = tools.UploadPicture;
const LinkType = tools.LinkType;
const ColorPicker = tools.ColorPicker;

const MoreConfigForm: React.FC<MoreConfigFormProps> = (props) => {
    const { selector, uploadAction, linkTypes, sourceRoot, value, onChange } = props;

    function handleChangeImage(image: string): void {
        onChange &&
            onChange({
                ...value,
                image,
            });
    }

    function handleChangeText(text: string): void {
        onChange &&
            onChange({
                ...value,
                text,
            });
    }

    function handleChangeColor(color: string): void {
        onChange &&
            onChange({
                ...value,
                color,
            });
    }

    function handleChangeLinkTypes(val: { linkParams: string; linkType: string }): void {
        const { linkParams, linkType } = val;

        onChange &&
            onChange({
                ...value,
                linkParams,
                linkType,
            });
    }

    return (
        <Row className="entry-item">
            <Col className="entry-item-image" span={5}>
                <UploadPicture
                    sourceRoot={sourceRoot}
                    value={get(value, 'image', '')}
                    uploadAction={uploadAction}
                    onChange={handleChangeImage}
                />
            </Col>
            <Col span={18}>
                <label className="field-name">文字：</label>
                <Input
                    value={get(value, 'text', '查看更多')}
                    className="link-input"
                    onChange={(e) => handleChangeText(e.target.value)}
                />
            </Col>
            <Col span={18} offset={5}>
                <ColorPicker onChange={handleChangeColor} value={get(value, 'color', '#999999')} />
            </Col>
            <Col span={18} offset={5}>
                <LinkType
                    linkTypes={linkTypes}
                    linkTypeMode={'cascader'}
                    selector={selector}
                    value={{
                        linkParams: get(value, 'linkParams', ''),
                        linkType: get(value, 'linkType', ''),
                    }}
                    onChange={handleChangeLinkTypes}
                />
            </Col>
        </Row>
    );
};

export default MoreConfigForm;
