import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Select } from 'antd';
import { OptionProps } from 'antd/lib/select';
import React, { Component } from 'react';

interface Subsite {
    id: string;
    name: string;
    address?: string;
    status: number;
}

interface Props {
    entity: Entity;
    value?: Subsite;
    onChange(value?: Subsite): void;
    options: Subsite[];
    onSubsiteChange?(value?: Subsite, entity?: Entity): void;
}
export default class ObjectSubsiteSelector extends Component<Props> {
    private onChange = (id: string) => {
        const { options, onChange, value, onSubsiteChange, entity } = this.props;
        if (value?.id !== id) {
            const subsite = options.find((subsite) => subsite.id === id);
            onChange(subsite);
            onSubsiteChange?.(subsite, entity);
        }
    };
    private filterOption = (
        input: string,
        option: React.ReactElement<OptionProps, string | React.JSXElementConstructor<any>>
    ) => {
        return (option.props.children! as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    private get value() {
        const { value } = this.props;
        return value?.id;
    }
    render() {
        const { options } = this.props;
        return (
            <Select
                value={this.value}
                showSearch
                filterOption={this.filterOption}
                onChange={this.onChange}
            >
                {options.map(({ id, name }) => (
                    <Select.Option value={id}>{name}</Select.Option>
                ))}
            </Select>
        );
    }
}
