import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        FrontInfoEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/front_info',
            properties: {
                homeLogo: {
                    displayName: '<<sc_3>>LOGO',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    rules: [{ required: true }],
                    extra: '<<h_sc_3_tip>>',
                },
                authorizeLogo: {
                    displayName: '<<sqdl>>LOGO',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    rules: [{ required: true }],
                    extra: '<<h_sqdl_tip>>',
                },
                authorizeImg: {
                    displayName: '<<sqdltp>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    rules: [{ required: true }],
                    extra: '<<h_sqdltp_tip>>',
                },
                agreementBackImg: {
                    displayName: '<<ysxyybjt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: '<<h_ysxyybjt_tip>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<scmc>>',
                    rules: [{ required: true, message: '<<h_scmc_msg>>' }],
                    controlConfig: {
                        style: { width: 220 },
                        placeholder: '<<h_scmc_tip>>',
                    },
                },
            },
        },
    },
    components: {
        FrontInfoView: {
            component: 'Viewport',
            entity: 'FrontInfoEntity',
        },
        FrontInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'FrontInfoRuleForm' }],
        },
        FrontInfoRuleForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'FrontInfoEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 760 },
            fields: [
                { property: 'homeLogo' },
                { property: 'authorizeLogo' },
                { property: 'authorizeImg' },
                { property: 'agreementBackImg' },
                { property: 'name' },
            ],
            submit: {
                text: '<<common.submit>>',
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/frontinfo',
                    breadcrumbName: '<<scmcj>>logo',
                    component: 'FrontInfoView',
                    privilege: {
                        path: 'frontinfo',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'FrontInfoPage' }],
                },
            ],
        },
    ],
};
