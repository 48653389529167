import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { LiveRoomProduct } from '../../../../config/promotion/market/live-room-product';
import './index.less';

export class MerchantMode extends ObjectMode {
    getDisplayComponent(value: any, { row }: { row: LiveRoomProduct }): JSX.Element {
        return (
            <div className="live-room-list-item-merchant">
                <div>{row.merchantName}</div>
                <div>{row.subsiteName}</div>
            </div>
        );
    }
}
