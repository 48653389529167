import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, forEach } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

export interface BlindBoxEditFormProps extends FormComponentProps {
    editExecuteStatus: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class blindBoxEditFormView extends PureComponent<BlindBoxEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef, editExecuteStatus } = this.props;
        const isDisabled = editExecuteStatus === 'STARTED' || editExecuteStatus === 'COMPLETE';

        let fields = [
            { property: 'activityInfo.name' },
            {
                property: 'activityInfo.dateRange',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                    disabled: isDisabled,
                },
            },
            {
                property: 'activityInfo.subsites',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            { property: 'activityInfo.marketingSchedule' },
            {
                property: 'ruleInfo.identity',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'ruleInfo.activityRewardLimitType',
                controlConfig: {
                    disabled: isDisabled,
                },
            },
            {
                property: 'ruleInfo.activityRewardLimit',
                controlConfig: {
                    max: 99999999,
                    style: { width: '150px' },
                    placeholder: services.language.getText('inputPlease'),
                    addonbefore: services.language.getText('mgyhgmsl'),
                    addonafter: services.language.getText('jian'),
                    tip: '',
                    disabled: isDisabled,
                },
                visible: (values: any) => get(values, 'ruleInfo.activityRewardLimitType') === 'YES',
            },
            {
                property: 'ruleInfo.price',
                controlConfig: {
                    max: 99999999,
                    style: { width: 200 },
                    addonafter: services.language.getText('yuan'),
                    tip: '',
                    disabled: isDisabled,
                },
            },
            {
                property: 'productInfo.goods',
                controlConfig: {
                    selectProductTypes: ['NORMAL'],
                    selectShelfStatus: 'ALL',
                    showBlindBoxSaleCount: true,
                    showBlindBoxWinningRate: true,
                    disabled: isDisabled,
                },
            },
            { property: 'pictureInfo.showPicture' },
            { property: 'pictureInfo.backgroundPicture' },
            { property: 'pictureInfo.introductionPicture' },
            { property: 'pictureInfo.coverPicture' },
            { property: 'pictureInfo.packagePicture' },
            { property: 'pictureInfo.rulePicture' },
            { property: 'pictureInfo.sharePicture' },
            { property: 'pictureInfo.shareContent' },
        ];

        let formProps = {
            entity,
            componentId: 'BlindBoxEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'blind-box-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 650 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                const activityRewardLimitType = entityFields.ruleInfo.activityRewardLimitType;
                const activityRewardLimit = entityFields.ruleInfo.activityRewardLimit;
                if (activityRewardLimitType === 'YES' && !activityRewardLimit) {
                    AntMessage.error(services.language.getText('qsryhxgsl'));
                    return false;
                }
                const goods = entityFields.productInfo.goods;
                let hasValidate = true;
                forEach(goods, (i) => {
                    if (!i.quantity) {
                        AntMessage.warning(services.language.getText('qsrmhkmkc'));
                        hasValidate = false;
                        return false;
                    }
                    if (i.quantity !== null && i.quantity <= 0) {
                        AntMessage.warning(services.language.getText('mhkmkcqsrdy'));
                        hasValidate = false;
                        return false;
                    }
                });
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity } = props;
    let form: any;
    let editExecuteStatus = get(entity, 'fields.editExecuteStatus');

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('BlindBoxEditFormContainer'));
            },
        }
    );
}
export const BlindBoxEditFormView = connect(mapDispatchToProps)(blindBoxEditFormView);
