import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
/**
 * hex转rgba格式
 * @param {string} hex 色值
 * @param {number} opacity 透明度
 * @returns rgba颜色
 */
function hexToRgba(hex: string, opacity = 1) {
    const red = parseInt(hex.slice(1, 3), 16);
    const green = parseInt(hex.slice(3, 5), 16);
    const blue = parseInt(hex.slice(5, 7), 16);
    const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    return rgba;
}

/**
 * 弹窗广告预览效果
 * @param props
 */
const popThemeStylePreview = (props: any) => {
    const { preview = {} } = props;
    const primarySyles: any = {
        color: preview?.themeStyle?.color?.secondary || '#ab8e66',
    };
    const heartStyles: any = {
        backgroundColor: preview?.themeStyle?.color?.primary || '#007046',
    };
    let decrementStyles: any = {
        backgroundColor: preview?.themeStyle?.color?.primary
            ? hexToRgba(preview?.themeStyle?.color?.primary, 0.1)
            : hexToRgba('#007046', 0.1),
        color: preview?.themeStyle?.color?.primary || '#007046',
    };
    let decrementsStyles: any = {
        borderColor: preview?.themeStyle?.color?.secondary || '#ab8e66',
        color: preview?.themeStyle?.color?.secondary || '#ab8e66',
    };
    let divider = 'theme-style-preview-divider';
    let backgroundImage: any = {
        backgroundImage:
            preview?.themeStyle?.backgroundImage && preview?.themeStyle?.backgroundImage.length
                ? `url(${preview?.themeStyle?.backgroundImage[0].path ||
                      preview?.themeStyle?.backgroundImage})`
                : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    const bottonStyles: any = {
        background: preview?.themeStyle?.button?.from || '#007046',
    };

    const addCartStyle: any = {
        borderColor: preview?.themeStyle?.button?.from || '#007046',
        color: preview?.themeStyle?.button?.from || '#007046',
    };

    if (preview?.themeStyle?.button?.colorType === 'gradient') {
        if (preview?.themeStyle?.button?.direction === 'vertical') {
            bottonStyles.background = `linear-gradient(0deg, ${hexToRgba(
                preview?.themeStyle?.button?.to || '#ab8e66'
            )} 0%, ${hexToRgba(preview?.themeStyle?.button?.from || '#ab8e66')} 100%)`;
        } else {
            bottonStyles.background = `linear-gradient(270deg, ${hexToRgba(
                preview?.themeStyle?.button?.to || '#ab8e66'
            )} 0%, ${hexToRgba(preview?.themeStyle?.button?.from || '#ab8e66')} 100%)`;
        }
    }
    if (preview?.themeStyle?.styleMode === '1') {
        divider = 'theme-style-preview-divider-line';
        addCartStyle.color = '#000';
        addCartStyle.borderColor = '#000';
        bottonStyles.color = '#000';
        bottonStyles.borderColor = '#000';
    } else {
        divider = 'theme-style-preview-divider';
        addCartStyle.color = preview?.themeStyle?.button?.from || '#007046';
        addCartStyle.borderColor = preview?.themeStyle?.button?.from || '#007046';
        bottonStyles.borderColor = preview?.themeStyle?.button?.from || '#007046';
        decrementStyles = {
            backgroundColor: hexToRgba('#FF5646', 0.1),
            color: '#FF5646',
        };
        backgroundImage = {
            backgroundColor: '#fff',
        };
    }
    const headStyle = {
        backgroundColor:
            preview?.themeStyle?.styleMode === '1'
                ? preview?.navigation?.style?.backgroundColor
                : '#fff',
    };
    return (
        <div className={'theme-style-preview'}>
            <div className="theme-style-preview-box">
                <div className="theme-style-preview-item theme-style-preview-item-white">
                    <img alt="" src="./images/avatar/theme-head.png" />
                </div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-product-lipstick.png" />
                </div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-product-title.png" />
                    <div className="theme-style-preview-unit" style={primarySyles}>
                        {language.getText('monetaryUnit')}
                        <span>2980</span>.00
                    </div>
                    <div className="theme-style-preview-heart" style={heartStyles}></div>
                </div>
                <div className={divider}></div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-product-discount.png" />
                    <div className="theme-style-preview-decrement" style={decrementStyles}>
                        {language.getText('decrement')}
                    </div>
                    <div className="theme-style-preview-folding" style={decrementStyles}>
                        {language.getText('folding')}
                    </div>
                    <div className="theme-style-preview-restrictions" style={decrementStyles}>
                        {language.getText('limit')}
                    </div>
                </div>
                <div className={divider}></div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-product-store.png" />
                    <div className="theme-style-preview-service" style={heartStyles}></div>
                    <div
                        className="theme-style-preview-service theme-style-preview-service-quality"
                        style={heartStyles}
                    ></div>
                </div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-product-foot.png" />
                    <div className="theme-style-preview-addcart" style={addCartStyle}>
                        {language.getText('addCart')}
                    </div>
                    <div className="theme-style-preview-cart" style={bottonStyles}>
                        {language.getText('buy')}
                    </div>
                </div>
            </div>
            <div className="theme-style-preview-box" style={backgroundImage}>
                <div className="theme-style-preview-item" style={headStyle}>
                    <img alt="" src="./images/avatar/theme-head.png" />
                    <div className="theme-style-preview-cart-title">购物车</div>
                </div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-cart-title.png" />
                </div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-cart-list1.png" />
                    <div
                        className="theme-style-preview-unit theme-style-preview-list1-unit1"
                        style={primarySyles}
                    >
                        {language.getText('monetaryUnit')}
                        <span>2998</span>.90
                    </div>
                    <div
                        className="theme-style-preview-unit theme-style-preview-list1-unit2"
                        style={primarySyles}
                    >
                        {language.getText('monetaryUnit')}
                        <span>2998</span>.90
                    </div>
                    <div
                        className="theme-style-preview-check theme-style-preview-check0"
                        style={heartStyles}
                    ></div>
                </div>
                <div className={divider}></div>
                <div className="theme-style-preview-item">
                    <img alt="" src="./images/avatar/theme-cart-list2.png" />
                    <div
                        className="theme-style-preview-unit theme-style-preview-list2-unit"
                        style={primarySyles}
                    >
                        {language.getText('monetaryUnit')}
                        <span>2998</span>.90
                    </div>
                    <div className="theme-style-preview-support" style={decrementsStyles}>
                        {language.getText('selfOperated')}
                    </div>
                    <div className="theme-style-preview-check" style={heartStyles}></div>
                    <div
                        className="theme-style-preview-check theme-style-preview-check1"
                        style={heartStyles}
                    ></div>
                    <div
                        className="theme-style-preview-check theme-style-preview-check2"
                        style={heartStyles}
                    ></div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const ThemeStylePreview = connect(mapStateToProps)(popThemeStylePreview);
