import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, get, isNil } from 'lodash';
import {
    SupApportionType,
    limitType,
    activityStatus,
} from '../config/promotion/promotion-activity/timelimit-activity';

enum actionType {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}
const api = services.api;
let status: any = null;
export const timeLimitActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/timelimit';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        if (data.id) config.apiPath = `/admin/timelimit/${data.id}`;

        let res: any = await api.get(data, config);
        if (!data.id) {
            res.result = res.result.map((listItem: any) => {
                if (isNil(listItem.frontRuleStatus)) {
                    listItem.frontRuleStatus = activityStatus.COMPLETE;
                }

                return {
                    ...listItem,
                    subsites: listItem.subsiteVos,
                    supApportionType:
                        listItem.supApportionType === 0
                            ? SupApportionType.NONE
                            : listItem.supApportionType === 3
                            ? SupApportionType.RPICE_DIFFERENCE
                            : null,
                    name: listItem.name,
                    perOrderLimitNum: listItem.timeLimitModeVo.perOrderLimitNum,
                    perPersonLimitNum: listItem.timeLimitModeVo.perPersonLimitNum,
                };
            });
        } else {
            const {
                startTime,
                endTime,
                supApportionRatio,
                subsiteVos,
                timeLimitModeVo: { perOrderLimitNum, perPersonLimitNum },
                timeLimitGoods,
                timeLimitModeVo,
            } = res;
            let supApportionType: any = null;
            let _limitType: any = null;
            if (res.supApportionType === 0) supApportionType = SupApportionType.NONE;
            if (res.supApportionType === 3) supApportionType = SupApportionType.RPICE_DIFFERENCE;
            if (get(timeLimitModeVo, 'id') === 2) _limitType = limitType.MORE;
            if (get(timeLimitModeVo, 'id') === 1) _limitType = limitType.PER;
            status = res.status;
            if (res.marketingSchedule) {
                const { startTime, endTime } = res.marketingSchedule || {};
                res.marketingSchedule = {
                    ...res.marketingSchedule,
                    time:
                        startTime && endTime
                            ? `${startTime}-${endTime}`
                            : services.language.getText('longTerm'),
                };
            }
            res.baseInfo = {
                name: res.name,
                dateRange: {
                    start: startTime,
                    end: endTime,
                },
            };
            res.ruleConfig = {
                supApportionType,
                supApportionRatio,
                subsites: subsiteVos,
                marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                goods: timeLimitGoods.map((item: any) => {
                    return {
                        ...item,
                        stockId: item.id,
                        id: item.goodsId,
                        productName: item.productName,
                        productCode: item.productCode,
                        goodsCode: item.goodsCode,
                        barCode: item.barcode,
                        salePrice: item.limitPrice,
                        groupLeftStock: item.balance,
                        saleStock: item.balance,
                        profit: item.limitPrice - item.costPrice,
                    };
                }),
            };
            res.limitConfig = {
                limitType: _limitType,
                perOrderLimitNum,
                perPersonLimitNum,
            };
        }
        return res;
    },
    post: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/timelimit';
        const result = await api.post(
            transformData(data, { status: 2 }, actionType.CREATE),
            config
        );
        return result;
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/timelimit/${data.id}`;
        const result = await api.put(transformData(data, { status }, actionType.EDIT), config);
        return result;
    },
};

function transformData(data: any, defaultData: any, type: actionType) {
    const newParams: any = { ...defaultData };
    if (data.baseInfo) {
        newParams.name = get(data.baseInfo, 'name');
        newParams.startTime = get(data.baseInfo, 'dateRange.start');
        newParams.endTime = get(data.baseInfo, 'dateRange.end');
        newParams.marketingScheduleId =
            (data.ruleConfig?.marketingSchedule?.length &&
                data.ruleConfig.marketingSchedule[0].id) ||
            null;
    }

    if (data.ruleConfig) {
        const supApportionType = get(data.ruleConfig, 'supApportionType');
        const subsites = get(data.ruleConfig, 'subsites');
        const goods = get(data.ruleConfig, 'goods');

        if (supApportionType === SupApportionType.NONE) {
            newParams.supApportionType = 0;
        }
        if (supApportionType === SupApportionType.RPICE_DIFFERENCE) {
            newParams.supApportionType = 3;
            newParams.supApportionRatio = get(data.ruleConfig, 'supApportionRatio');
        }
        if (subsites && subsites.length) {
            newParams.subsiteIds = subsites.map((item: any) => item.id);
        }
        if (goods && goods.length) {
            newParams.goodsDtos = goods.map((item: any) => {
                const newItem: any = {
                    goodsId: item.id,
                    limitPrice: item.salePrice,
                    pointPrice: 0,
                    status: 1,
                    subSiteId: item.subsiteId,
                    sellStock: item.saleStock,
                };
                if (type === actionType.EDIT) {
                    newItem.id = item.stockId;
                }
                return newItem;
            });
        }
    }
    if (data.limitConfig) {
        const limitMode: any = get(data.limitConfig, 'limitType');
        newParams.limitMode =
            limitMode === limitType.MORE ? 2 : limitMode === limitType.PER ? 1 : null;
        newParams.perOrderLimitNum = get(data.limitConfig, 'perOrderLimitNum');
        newParams.perPersonLimitNum = get(data.limitConfig, 'perPersonLimitNum');
    }

    return newParams;
}

// function ellipsis(str: any, maxLength: number = 9) {
//     if (isString(str)) {
//         let value = str.trim();
//         const strLength = value.length;
//         if (strLength <= maxLength) {
//             return value;
//         } else {
//             return str.slice(0, 9) + '...';
//         }
//     }
//     return str;
// }
