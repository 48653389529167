import React from 'react';
import classNames from 'classnames';
import { map, find } from 'lodash';
import { modes } from '@comall-backend-builder/types';
import { ArrayOptionIdsCheckboxTip } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayOptionIdsCheckboxTipMode extends modes.ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, options } = displayInfo;

        if (array && array.length) {
            return (
                <div
                    className={classNames('type-array mode-array-timepicker-mode', className)}
                    style={style}
                >
                    {map(array, (item, index) => {
                        let typeOption = find(options, { id: item }) || {};
                        return <span key={index}> {typeOption.name} </span>;
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ArrayOptionIdsCheckboxTip {...displayInfo} />;
    }
}
