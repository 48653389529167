import { reduce } from 'lodash';
import { ArrayFormat } from '@comall-backend-builder/types';

export class ArrayCarparkImageAdsFormat extends ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: number, value: any) {
        return {
            [key]: reduce<any, any>(
                value,
                (result, reward) => {
                    result.push({
                        ...reward,
                    });
                    return result;
                },
                []
            ),
        };
    }

    /**
     * 对数据进行校验
     */
    public validate(_rule: any, value: any, callback: any) {
        callback();
    }
}
