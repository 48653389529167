import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { ActivitySignInReward } from '../../../components/activity-signin-reward';

export class ArrayActivitySignInRewardMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ActivitySignInReward {...controlInfo} />;
    }
}
