import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { DateTimeRangeTip } from '../../../components';

export class ObjectDateTimeRangeTipMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <DateTimeRangeTip {...displayInfo} />;
    }
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (object) {
            return (
                <div
                    className={classNames('type-object mode-object-date-time-range-tip', className)}
                    style={style}
                >
                    <div>
                        <span>
                            {object.start}&nbsp;~&nbsp;{object.end}
                        </span>
                    </div>
                </div>
            );
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }
}
