import React, { PureComponent } from 'react';
import { Form, Radio, Checkbox } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

export class DesignMallActivityListView extends PureComponent<any> {
    render() {
        const {
            form: { getFieldDecorator },
        } = this.props;
        const formItemLayout = FormItemLayout;

        return (
            <Form className="editor-image-ad">
                <FormItem {...formItemLayout} label="展示样式">
                    {getFieldDecorator(
                        'showStyle',
                        {}
                    )(
                        <RadioGroup className="fill-style">
                            <Radio value={'list'}>列表</Radio>
                        </RadioGroup>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label="显示内容">
                    {getFieldDecorator(
                        'show',
                        {}
                    )(
                        <Checkbox.Group className="fill-style">
                            <Checkbox value="tag">活动标签</Checkbox>
                            <Checkbox value="share">分享按钮</Checkbox>
                        </Checkbox.Group>
                    )}
                </FormItem>
            </Form>
        );
    }
}

export const DesignMallActivityList = tools.ConfigFormDecorator(DesignMallActivityListView);
