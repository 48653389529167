import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { ActivityRewardBaseValue } from '../../../components/avtivity-reward-base';

enum Status {
    /**
     * 启用
     */
    ENABLE = 'ENABLED',
    /**
     * 禁用
     */
    DISABLE = 'DISABLED',
}

const validater = (entity: any) => {
    const { rewardConfig } = entity;
    if (!rewardConfig.normalConditionsIds?.length) {
        message.warn(services.language.getText('qxzcytxzl'));
        return false;
    }
    const rewards: ActivityRewardBaseValue | undefined = rewardConfig.rewards;
    if (!rewards) {
        message.warn(services.language.getText('qwzszwswchzsnr'));
        return false;
    }
    const { assetRewards, couponRewards } = rewards;
    if (!couponRewards?.length && !assetRewards?.length) {
        message.warn(services.language.getText('qwzszwswchzsnr'));
        return false;
    }
    if (couponRewards) {
        if (!couponRewards.length) {
            message.warn(services.language.getText('qxzyhq'));
            return false;
        }
        if (couponRewards.some((coupon) => !coupon.quantity)) {
            message.warn(services.language.getText('qszyhqffsl'));
            return false;
        }
    }
    if (assetRewards.some((rewards) => !rewards.rewardValue)) {
        message.warn(services.language.getText('qwzszwswchzsnr'));
        return false;
    }
    return true;
};

const formConfig = {
    direction: 'horizontal',
    labelCol: 6,
    controlCol: 18,
    style: { width: 720 },
    fields: [
        { property: 'baseInfo.name' },
        { property: 'baseInfo.dateRange' },
        { property: 'baseInfo.subsiteIds' },
        { property: 'baseInfo.marketingSchedule' },
        { property: 'rewardConfig.normalConditionsIds' },
        { property: 'rewardConfig.rewards' },
    ],
    submit: true,
    footer: {
        items: [
            {
                htmlType: 'button',
                route: 'goBack',
                text: '<<common.cancel>>',
                type: 'default',
                style: { marginLeft: 10 },
            },
        ],
    },
    onValidate: validater,
    onSubmitSuccess: () => {
        message.success(services.language.getText('common.saveSuccess'), () =>
            services.behaviorHandle({ route: 'goBack' })
        );
    },
};

export const config: Config = {
    entities: {
        ImproveInformationEntity: {
            apiPath: '/loader/admin/improve_information',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        allowClear: true,
                        style: { width: 170 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<activityMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 220 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<activityStatus>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                        name: {
                            displayName: '<<activityName>>',
                            type: 'string',
                            defaultValue: [],
                            rules: [
                                { required: true },
                                {
                                    max: 20,
                                    message: '<<hdmcbncg>>',
                                },
                                {
                                    message: '<<activityNameNotEmpty>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<applicableSubsite>>',
                            rules: [{ required: true }],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            rules: [{ required: true }],
                            displayName: '<<acitivityTime>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                rewardConfig: {
                    type: 'object.subForm',
                    displayName: '<<jlgz>>',
                    properties: {
                        normalConditionsIds: {
                            displayName: '<<cytxzl>>',
                            type: 'array.checkboxPlus',
                            rules: [{ required: true }],
                            defaultValue: [],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/improve_information/participate_fields',
                                },
                                convertFunction: (item: any) => item,
                                addDefaultSelectAll: false,
                            },
                            extra: '<<hdgjldyhhqbhzczs>>',
                        },
                        rewards: {
                            displayName: '<<rewards>>',
                            type: 'object.activityReward.single',
                            options: [],
                            controlConfig: {
                                optionsConfig: {
                                    apiPath: '/admin/activities/reward_items',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    params: {
                                        event_type: 'COMMON',
                                    },
                                },
                                getSubsiteIds(row: any) {
                                    return row && row.baseInfo?.subsiteIds?.length
                                        ? row.baseInfo.subsiteIds
                                              .map((subsite: any) => subsite.id)
                                              .join(',')
                                        : '';
                                },
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
            },
        },
    },
    components: {
        ImproveInformationView: {
            component: 'Viewport',
            entity: 'ImproveInformationEntity',
        },
        ImproveInformationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ImproveInformationFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/improve-information/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'ImproveInformationTable',
                },
            ],
        },
        ImproveInformationFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteId',
                },
            ],
        },
        ImproveInformationTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'subsites',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            scroll: { x: true },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'status',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: Status.DISABLE,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/improve_information/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: Status.ENABLE,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/improve_information/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/improve-information/edit/{{row.id}}',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/market/improve-information/logs/{{row.id}}',
                    },
                ],
            },
        },
        ImproveInformationAddPage: {
            component: 'Card',
            entity: 'ImproveInformationEntity',
            content: { component: 'ImproveInformationAddFormView' },
        },
        ImproveInformationAddFormView: {
            component: 'CreateFormPlus',
            ...formConfig,
        },
        ImproveInformationEditPage: {
            component: 'Card',
            entity: 'ImproveInformationEntity',
            loaderType: 'get',
            content: { component: 'ImproveInformationEditFormView' },
        },
        ImproveInformationEditFormView: {
            component: 'ModifyFormPlus',
            ...formConfig,
        },
        ImproveInformationLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: `/admin/improve_information/{id}/operation_logs`,
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/improve-information',
                    component: 'ImproveInformationView',
                    breadcrumbName: '<<wsxxyl>>',
                    privilege: {
                        path: 'improveInformation',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ImproveInformationAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ImproveInformationEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'ImproveInformationLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'ImproveInformationPage' },
                    ],
                },
            ],
        },
    ],
};
