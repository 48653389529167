import React from 'react';
import { Button } from 'antd';
import './index.less';
import { OrderFlowSelector, PaymentResult } from './order-flow-selector';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface OrderFlowRuleProps {
    value?: PaymentResult[];
    onChange: (value: PaymentResult[]) => void;
    isViewPage: boolean;
    orderInfo?: any;
}

export class OrderFlowRule extends React.Component<OrderFlowRuleProps> {
    state = { activeIndex: 0 };
    onChange = (items: PaymentResult[]) => {
        const { value = [], onChange } = this.props;
        const selectValues = items
            .filter((item) => !value.some((i) => i.outTradeNo === item.outTradeNo))
            .map((item) => ({ ...item, manualReview: true }));
        onChange([...value, ...selectValues]);
    };
    onDeleteRule = (index: number) => {
        const { value = [], onChange } = this.props;
        const newValue = value.filter((_, i) => i !== index);
        onChange && onChange(newValue);
    };

    render() {
        const { value = [], isViewPage, orderInfo } = this.props;
        const subsiteId = orderInfo && orderInfo.subsite ? orderInfo.subsite.id : '';
        const merchantId =
            orderInfo && orderInfo.subsite && orderInfo.subsite.merchant
                ? orderInfo.subsite.merchant.id
                : '';
        const params = {
            paymentMode: 'CHINA_MERCHANTS_BANK_TRANS_FER_PAY',
            businessType: 'STORED_VALUE_CARD_SALES_ORDER',
            subsiteId,
            merchantId,
        };
        return (
            <div className="order-flow-rule">
                {value.map((item, index) => {
                    return (
                        <div className="order-flow-rule-item" key={index}>
                            <div className="order-flow-rule-item-flex">
                                <span>{getText('jylsh')}：</span>
                                <span>{item.outTradeNo}</span>
                            </div>
                            <div className="order-flow-rule-item-flex">
                                <span>{services.language.getText('moneyTotal')}：</span>
                                <span>{item.paidAmount}</span>
                            </div>
                            <div className="order-flow-rule-item-flex">
                                <span>{getText('ly')}：</span>
                                <span>{getText(item.manualReview ? 'rgfh' : 'xtfh')}</span>
                            </div>
                            {item.manualReview && !isViewPage && (
                                <Button
                                    className="order-flow-rule-delete"
                                    onClick={() => {
                                        this.onDeleteRule(index);
                                    }}
                                >
                                    {services.language.getText('common.delete')}
                                </Button>
                            )}
                        </div>
                    );
                })}
                {Boolean(orderInfo) && (
                    <div className="mt10">
                        <OrderFlowSelector
                            params={params}
                            type="checkbox"
                            onChange={this.onChange}
                        />
                    </div>
                )}
            </div>
        );
    }
}
