import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PictureLinkType } from '../../../components';

export class PictureLinkTypeMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PictureLinkType {...controlInfo} />;
    }
}
