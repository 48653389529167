import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, last } from 'lodash';
import { InvoiceModeEnum } from '../../types/mode/string/invoice-mode/invoice-mode';
import { TaxRateType } from '../../types/mode/number/number-tax/tax-rate-number';

const api = services.api;
export const ParkingInvoiceConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoice_configs';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/invoice_configs/${id}`;
        } else {
            data.type = 'SERVICE';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    subsiteId: res.subsite.id + '',
                    invoiceMode: res.invoiceMode,
                    taxCode: res.taxCode,
                    taxRate: res.taxRate,
                    taxPreferentialPolicies: res.taxRateType !== 'STANDARD' ? 'true' : 'false',
                    taxPreferentialPoliciesType:
                        res.taxRateType === 'STANDARD' ? 'SIMPLE_TAX' : res.taxRateType,
                };
                res.sellerInfo = {
                    sellerName: res.seller.name,
                    sellerTaxNo: res.seller.taxNo,
                    sellerAddress: res.seller.address,
                    sellerPhone: res.seller.phone,
                    sellerBank: res.seller.bank,
                    sellerBankAccount: res.seller.bankAccount,
                    sellerPayee: res.seller.payee,
                    sellerReviewer: res.seller.reviewer,
                    sellerDrawer: res.seller.drawer,
                    remark: res.remark,
                    regionAndAddress: {
                        regionIds:
                            res.carparkParentRegionIds && res.carparkParentRegionIds.length
                                ? res.carparkParentRegionIds.slice(1)
                                : [],
                        address: res.carparkDetailAddress || '',
                    },
                    realPropertyCertificate: res.realPropertyCertificate,
                    crossCityFlag: res.crossCityFlag + '' || 'false',
                };
                res.invoiceSystemInfo = {
                    invoiceSystem: res.invoiceThirdSystem,
                };
                res.otherInfo = {
                    isEffectiveDaysLimit: res.effectiveDaysLimit ? 'true' : 'false',
                    effectiveDaysLimit: res.effectiveDaysLimit,
                    isChangeCountLimit: res.changeCountLimit ? 'true' : 'false',
                    changeCountLimit: res.changeCountLimit,
                    amountTopLimit: res.amountTopLimit,
                };
                // res.specialInfo = {
                //     extNo: res.extNo,
                //     clientNo: res.clientNo,
                // };
            } else {
                res.result = res.result.map((item: any) => {
                    let subsiteName = '';
                    if (item.subsites && item.subsites.length > 0) {
                        subsiteName = item.subsites[0].name;
                    }
                    item.subsiteName = subsiteName;
                    item.name = item.invoiceSeller && item.invoiceSeller.name;
                    item.taxNo = item.invoiceSeller && item.invoiceSeller.taxNo;
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoice_configs';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = `/admin/invoice_configs/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.type = 'SERVICE';
    newParams.contents = ['PRODUCT_CATEGORY'];
    if (params.baseInfo && params.otherInfo) {
        newParams.amountTopLimit =
            params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                ? params.otherInfo.amountTopLimit
                : undefined;
        newParams.changeCountLimit =
            params.otherInfo.isChangeCountLimit === 'true'
                ? params.otherInfo.changeCountLimit
                : null;
        newParams.effectiveDaysLimit =
            params.otherInfo.isEffectiveDaysLimit === 'true'
                ? params.otherInfo.effectiveDaysLimit
                : null;
        newParams.invoiceTypes = ['NORMAL'];
        newParams.invoiceMode = params.baseInfo.invoiceMode;
        newParams.seller = {
            address: params.sellerInfo.sellerAddress,
            bank: params.sellerInfo.sellerBank,
            bankAccount: params.sellerInfo.sellerBankAccount,
            drawer: params.sellerInfo.sellerDrawer,
            name: params.sellerInfo.sellerName,
            payee:
                params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                    ? params.sellerInfo.sellerPayee
                    : undefined,
            phone: params.sellerInfo.sellerPhone,
            reviewer:
                params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                    ? params.sellerInfo.sellerReviewer
                    : undefined,
            taxNo: params.sellerInfo.sellerTaxNo,
        };
        newParams.remark =
            params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                ? params.sellerInfo.remark
                : undefined;
        let invoiceThirdSystemParams: any = {};
        const invoiceThirdSystem = params.invoiceSystemInfo.invoiceSystem;
        if (invoiceThirdSystem.params && invoiceThirdSystem.params.length > 0) {
            forEach(invoiceThirdSystem.params, (p) => {
                invoiceThirdSystemParams[p.field] = p.value;
            });
        }
        newParams.invoiceThirdSystem = {
            id: invoiceThirdSystem.id,
            params: JSON.stringify(invoiceThirdSystemParams),
        };
        newParams.subsiteId = params.baseInfo.subsiteId;
        newParams.taxCode = params.baseInfo.taxCode;
        if (params.baseInfo.taxPreferentialPolicies === 'true') {
            newParams.taxRateType = params.baseInfo.taxPreferentialPoliciesType;
        } else {
            newParams.taxRateType = 'STANDARD';
        }
        // 简易征收才会像后端传税率，其他优惠政策类型传null
        if (
            params.baseInfo.taxPreferentialPoliciesType === TaxRateType.SIMPLETAX ||
            params.baseInfo.taxPreferentialPolicies === 'false'
        ) {
            newParams.taxRate = params.baseInfo.taxRate;
        } else {
            newParams.taxRate = null;
        }

        if (params.baseInfo.invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
            newParams.carparkRegionId =
                params.sellerInfo.regionAndAddress && params.sellerInfo.regionAndAddress.regionIds
                    ? last(params.sellerInfo.regionAndAddress.regionIds)
                    : undefined;
            newParams.carparkDetailAddress = params.sellerInfo.regionAndAddress
                ? params.sellerInfo.regionAndAddress.address
                : '';
            newParams.realPropertyCertificate = params.sellerInfo.realPropertyCertificate;
            newParams.crossCityFlag = params.sellerInfo.crossCityFlag;
        }
    }
    return newParams;
}
