import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { forEach } from 'lodash';

const api = services.api;
export const PhotoCreditReviewReasonsLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/reasons/9';
        return await api.get(data, config).then((res: any) => {
            const result: any[] = [];
            result.push({
                id: '其他',
                name: '其他',
            });
            if (res && res.length > 0) {
                forEach(res, (item) => {
                    item.id = item.name;
                    result.push(item);
                });
            }
            return result;
        });
    },
};
