import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import { services } from '@comall-backend-builder/core';
/**
 * hex转rgba格式
 * @param {string} hex 色值
 * @param {number} opacity 透明度
 * @returns rgba颜色
 */
function hexToRgba(hex: string, opacity = 1) {
    const red = parseInt(hex.slice(1, 3), 16);
    const green = parseInt(hex.slice(3, 5), 16);
    const blue = parseInt(hex.slice(5, 7), 16);
    const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    return rgba;
}
/**
 * 全民分销预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {}, themeStyle = {} } = props;
    const bottonStyles: any = {
        background: themeStyle?.button?.from || '#007046',
    };
    if (themeStyle?.button?.colorType === 'gradient') {
        if (themeStyle?.button?.gradient.direction === 'vertical') {
            bottonStyles.background = `linear-gradient(0deg, ${hexToRgba(
                themeStyle?.button?.gradient?.to || '#ab8e66'
            )} 0%, ${hexToRgba(themeStyle?.button?.gradient?.from || '#ab8e66')} 100%)`;
        } else {
            bottonStyles.background = `linear-gradient(270deg, ${hexToRgba(
                themeStyle?.button?.gradient?.to || '#ab8e66'
            )} 0%, ${hexToRgba(themeStyle?.button?.gradient?.from || '#ab8e66')} 100%)`;
        }
    }

    const backgroundImage: any = {
        backgroundImage: preview?.recruitDistributor?.backgroundImage
            ? `url(${preview?.recruitDistributor?.backgroundImage})`
            : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    return (
        <div className={'recruit-distributor-preview'}>
            <div className="recruit-distributor-preview-item recruit-distributor-preview-item-white">
                <img alt="" src="./images/avatar/theme-head.png" />
            </div>
            <div className="recruit-distributor-preview-box" style={backgroundImage}>
                <div className="recruit-distributor-preview-foot">
                    {preview?.recruitDistributor?.show === 'show' && (
                        <div className="recruit-distributor-preview-agreement">
                            <div className="recruit-distributor-preview-agreement-agree">
                                {services.language.getText('wyydbty')}
                            </div>
                            <div className="recruit-distributor-preview-agreement-name">
                                《
                                {preview.recruitDistributor.title ||
                                    services.language.getText('zlsxymc')}
                                》
                            </div>
                        </div>
                    )}

                    <div className="recruit-distributor-preview-addcart" style={bottonStyles}>
                        {services.language.getText('sqcwfxy')}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    let themeStyle = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
            //获取主题设置
            if (_state.entities[comp.entityId]) {
                themeStyle = _state.entities[comp.entityId]?.fields?.themeStyle || {};
            }
        }
    }

    return { preview: preview, themeStyle: themeStyle };
};

export const recruitDistributorPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
