import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Popover as AntPopover,
    Icon as AntIcon,
    Modal as AntModal,
    Tooltip as AntTooltip,
} from 'antd';
import { filter } from 'lodash';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';

import { IconProps } from 'antd/lib/icon';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { Carousel } from '@comall-backend-builder/components-basis';
import { CarouselAPI, CarouselProps } from '@comall-backend-builder/components-basis/lib/carousel';

import { CustomStyleComponentProps } from '@comall-backend-builder/components-basis';
import './index.less';

/**
 * 可预览的最多图片数量，默认为4张
 */
const MAX_COUNT = 4;

/**
 * 气泡框位置
 */
enum PopoverPlacementAPI {
    topLeft = 'topLeft',
    top = 'top',
    topRight = 'topRight',
    leftTop = 'leftTop',
    left = 'left',
    leftBottom = 'leftBottom',
    rightTop = 'rightTop',
    right = 'right',
    rightBottom = 'rightBottom',
    bottomLeft = 'bottomLeft',
    bottom = 'bottom',
    bottomRight = 'bottomRight',
}

/**
 * 气泡触发事件
 */
enum PopoverTriggerAPI {
    click = 'click',
    hover = 'hover',
}

/**
 * 气泡卡片props 单张图片预览使用popover
 */
interface ImagePreviewPlusPopover {
    /**
     * 卡片标题
     */
    title?: string;

    /**
     * 触发弹出气泡卡片的行为
     */
    trigger?: 'click' | 'hover';

    /**
     * 气泡卡片位置
     */
    placement?: TooltipPlacement;
}

/**
 * 多张预览时，轮播图props
 */
type ImagePreviewPlusCarousel = Pick<
    CarouselProps,
    'dots' | 'easing' | 'effect' | 'vertical' | 'autoplay' | 'showArrows' | 'items' | 'activePanel'
>;

/**
 * 实际组件暴露的 Props
 */
interface ImagePreviewPlusProps
    extends CustomStyleComponentProps,
        ImagePreviewPlusPopover,
        ImagePreviewPlusCarousel {
    /**
     * 是否预览，默认 false 不可预览
     */
    visible?: boolean;

    /**
     * 预览模式，使用 modal 或者 popover
     * 规则为：多张预览使用 modal；单张预览使用 popover
     */
    type: 'modal' | 'popover';

    /**
     * 预览尺寸
     * 多张图片时，为 modal 尺寸，具体图片尺寸需要 size - modal的padding
     * 单张图片时，为实际预览图片的尺寸
     */
    size?: number;

    /**
     * 可预览的最多图片数量，默认为4张
     */
    maxCount?: number;

    /**
     * 多张图片预览时，打开预览的icon配置，默认使用省略号的 icon
     */
    icon?: IconProps;

    /**
     * 预览中的图片配置
     * 单张图片取 src 来展示图片
     * 多张图片取 style className 来设置图片
     */
    imageConfig: CustomStyleComponentProps & {
        /**
         * 自定义样式
         */
        style: {
            width: string | number;
            [key: string]: string | number;
        };

        /**
         * 图片src
         */
        src?: string;

        /**
         * 图片alt
         */
        alt?: string;
    };
}

/**
 * 组件内部state
 */
interface ImagePreviewPlusState {
    /**
     * 当前 modal 预览是否可见，默认 false
     */
    modalPreviewVisible: boolean;

    /**
     * 当前需要显示的面板索引，默认为 0；
     */
    activePanel: number;
}

export class ImagePreviewPlus extends PureComponent<ImagePreviewPlusProps, ImagePreviewPlusState> {
    static defaultProps: Pick<ImagePreviewPlusProps, 'visible' | 'maxCount' | 'icon'> = {
        // 默认不可预览
        visible: false,
        // 默认可展示的图片数量
        maxCount: MAX_COUNT,
        // 查看图片时的icon配置
        icon: {
            type: 'ellipsis',
        },
    };

    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,

        // 预览：配置类型，仅有可预览／不可预览
        visible: PropTypes.bool,

        // 预览：尺寸
        size: PropTypes.number,

        // 预览：可展示的图片数量
        maxCount: PropTypes.number,

        // 预览：modal 配置
        autoplay: PropTypes.bool,
        showArrows: PropTypes.bool,
        dots: PropTypes.bool,
        easing: PropTypes.oneOf([CarouselAPI.linear, CarouselAPI.swing]),
        effect: PropTypes.oneOf([CarouselAPI.scrollx, CarouselAPI.fade]),
        vertical: PropTypes.bool,

        // 预览：popover 配置
        title: PropTypes.string,
        trigger: PropTypes.oneOf([PopoverTriggerAPI.click, PopoverTriggerAPI.hover]),
        placement: PropTypes.oneOf([
            PopoverPlacementAPI.topLeft,
            PopoverPlacementAPI.top,
            PopoverPlacementAPI.topRight,
            PopoverPlacementAPI.leftTop,
            PopoverPlacementAPI.left,
            PopoverPlacementAPI.leftBottom,
            PopoverPlacementAPI.rightTop,
            PopoverPlacementAPI.right,
            PopoverPlacementAPI.rightBottom,
            PopoverPlacementAPI.bottomLeft,
            PopoverPlacementAPI.bottom,
            PopoverPlacementAPI.bottomRight,
        ]),

        // 预览：多条预览数据，用于modal预览
        items: PropTypes.array,

        // 图片配置
        imageConfig: PropTypes.shape({
            className: PropTypes.string,
            style: PropTypes.object,
            src: PropTypes.string,
            alt: PropTypes.string,
        }),
    };
    constructor(props: any) {
        super(props);

        this.state = { modalPreviewVisible: false, activePanel: 0 };

        this.renderImagePreviewPlus = this.renderImagePreviewPlus.bind(this);
        this.renderModalPreview = this.renderModalPreview.bind(this);
        this.renderPopoverPreview = this.renderPopoverPreview.bind(this);

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    render() {
        const { style, className } = this.props;
        return (
            <div className={classNames('image-preview-plus-container', className)} style={style}>
                {this.renderImagePreviewPlus()}
            </div>
        );
    }

    /**
     * 配置preview时，显示图片预览
     * 多张图片，则使用modal
     * 单张图片，则使用popover
     */
    renderImagePreviewPlus() {
        const { imageConfig, items, visible, type, maxCount, icon } = this.props;

        if (type === 'modal') {
            const {
                style: { width },
            } = imageConfig;

            // 图片内容
            let imageItems = filter(items, function(_item, index) {
                return index < (maxCount || MAX_COUNT);
            }).map((item, index) => {
                const { imageurl, text } = item;
                return (
                    <div className="modal-preview-container" key={index} style={{ width }}>
                        <img {...imageConfig} src={imageurl} alt={text} />
                    </div>
                );
            });

            const iconProps = {
                ...icon,
                onClick: this.handleOpenModal,
            };

            // modal层内容
            let modalPreview = (
                <Fragment>
                    <AntTooltip title={services.language.getText('common.preview')}>
                        <AntIcon className="image-preview-icon" {...iconProps} />
                    </AntTooltip>
                    {this.renderModalPreview()}
                </Fragment>
            );

            return (
                <Fragment>
                    <div className="image-list">{imageItems}</div>
                    {visible ? modalPreview : null}
                </Fragment>
            );
        } else if (type === 'popover') {
            return (
                <Fragment>
                    <div className="popover-preview-container">
                        <img {...imageConfig} alt={imageConfig.alt} />
                    </div>
                    {visible ? this.renderPopoverPreview() : null}
                </Fragment>
            );
        } else {
            return undefined;
        }
    }

    /**
     * 打开 modal
     */
    handleOpenModal() {
        this.setState({
            modalPreviewVisible: true,
        });
    }

    /**
     * 关闭 modal
     */
    handleCloseModal() {
        this.setState({
            modalPreviewVisible: false,
        });
    }

    /**
     * 图片预览：modal
     */
    renderModalPreview() {
        const { modalPreviewVisible, activePanel } = this.state;
        const { size, autoplay, showArrows, dots, easing, effect, vertical, items } = this.props;

        const modalProps = {
            visible: modalPreviewVisible,
            footer: null,
            onCancel: this.handleCloseModal,
            width: size,
        };

        const carouselProps = {
            className: 'modal-carousel',
            autoplay,
            showArrows,
            dots,
            easing,
            effect,
            vertical,
            items,
            activePanel,
        };

        return (
            <AntModal {...modalProps}>
                <Carousel {...carouselProps} />
            </AntModal>
        );
    }

    /**
     * 图片预览：popover
     */
    renderPopoverPreview() {
        const {
            size,
            title,
            trigger,
            placement,
            imageConfig: { className, style, src, alt },
        } = this.props;

        const popoverImageProps = { src, alt, style: { width: size } };

        const content = (
            <div className="popover-image">
                <img {...popoverImageProps} alt={alt} />
            </div>
        );

        const popoverProps = {
            title,
            trigger,
            placement,
            content,
        };

        const imageProps = {
            className,
            style,
            src,
            alt,
        };

        return (
            <div className="popover-preview">
                <AntPopover {...popoverProps}>
                    <img {...imageProps} alt={alt} />
                </AntPopover>
            </div>
        );
    }
}
