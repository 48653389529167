import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { cloneDeep } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
export const CommentActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/comment_reward_activities';

        let goldValueAlias = '金币';
        const { id } = data;
        if (data.executeStatus === 'ALL') {
            delete data.executeStatus;
        }
        if (id) {
            config.apiPath = `/admin/comment_reward_activities/${id}`;
            const resData: any[] = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/activities/reward_items?event_type=SIGN_IN',
                }
            );
            if (resData && resData.length) {
                goldValueAlias = resData.find((item: any) => item.code === 'GOLD')?.name || '金币';
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res) {
                    res.baseInfo = {
                        name: res.name,
                        dateRange: {
                            start: res.startTime,
                            end: res.endTime,
                        },
                    };
                    res.ruleInfo = {
                        subsiteId: res.subsites ? res.subsites.id + '' : '',
                        activityProductType: res.activityProductType,
                        channel: res.channel,
                        crowdScope: res.unifiedReward ? 'CYCLE' : 'LADDER',
                        memberLevelRule: [],
                    };
                    if (res.rewards.length) {
                        res.ruleInfo.memberLevelRule = res.rewards.map((innerItem: any) => {
                            const lastResult: any = {};
                            if (innerItem.assetRewards.length) {
                                innerItem.assetRewards.forEach((assetItem: any) => {
                                    switch (assetItem.rewardType) {
                                        case 'POINT':
                                            lastResult.pointChecked = true;
                                            lastResult.pointName = assetItem.name;
                                            lastResult.point = Number(assetItem.rewardValue);
                                            break;
                                        case 'GOLD':
                                            lastResult.goldChecked = true;
                                            lastResult.goldName = goldValueAlias;
                                            lastResult.gold = Number(assetItem.rewardValue);
                                            break;
                                        case 'HI':
                                            lastResult.goldValueChecked = true;
                                            lastResult.goldValueName = assetItem.name;
                                            lastResult.goldValue = Number(assetItem.rewardValue);
                                            break;
                                        default:
                                            break;
                                    }
                                });
                            }
                            if (innerItem.couponRewards.length) {
                                lastResult.couponsChecked = true;
                            }
                            lastResult.couponsValue = innerItem.couponRewards.map(
                                (couponItem: any) => {
                                    couponItem.id =
                                        couponItem?.couponDefinitionId?.toString() || '';
                                    couponItem.couponValue = {
                                        type: couponItem.couponValueType,
                                        value: couponItem.couponValue,
                                    };
                                    return couponItem;
                                }
                            );

                            lastResult.levelId = innerItem.rewardConditionValue;
                            lastResult.levelName = innerItem.rewardConditionName;
                            return lastResult;
                        });
                    }
                    if (res.products && res.products.length > 0) {
                        res.ruleInfo.products = {
                            type: 'APPOINT_PRODUCT',
                            values: res.products.map((item: any) => {
                                return {
                                    ...item,
                                };
                            }),
                        };
                    } else if (res.brands && res.brands.length > 0) {
                        res.ruleInfo.products = {
                            type: 'APPOINT_BRAND',
                            values: res.brands,
                        };
                    }
                }
            } else {
                if (res && res.result) {
                    res.result.forEach((item: any) => {
                        item.canImport = item.activityProductType !== 'ALL' && !item.status;
                    });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/comment_reward_activities';

        let newParams = dataParser(params);

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/comment_reward_activities/${params.id}`;
        let newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
    }
    if (params.ruleInfo) {
        newParams.subsiteId = params.ruleInfo.subsiteId;
        newParams.channel = params.ruleInfo.channel;
        const products = params.ruleInfo.products;
        if (products && products.type) {
            newParams.rangeType = products.type;
        }
        if (products && products.values) {
            newParams.productIds = products.values.map((value: any) => value.id);
        }
        newParams.activityProductType = params.ruleInfo.activityProductType;
        const memberLevelRule =
            params.ruleInfo.memberLevelRule && cloneDeep(params.ruleInfo.memberLevelRule);
        if (memberLevelRule) {
            //线下订单-设置为同一规则
            const rewardRule =
                newParams.channel === 'OFFLINE' ? 'CYCLE' : params.ruleInfo.crowdScope;
            newParams.rewardConfig = {
                rewardRule,
                activityRewards: [],
            };
            newParams.rewardConfig.activityRewards = memberLevelRule.map((item: any) => {
                const assetRewards: any = [];
                let couponRewards: any = [];

                if (item.pointChecked) {
                    assetRewards.push({
                        name: item.pointName,
                        rewardType: 'POINT',
                        rewardValue: item.point,
                    });
                }
                if (item.goldChecked) {
                    assetRewards.push({
                        name: item.goldName,
                        rewardType: 'GOLD',
                        rewardValue: item.gold,
                    });
                }
                if (item.experienceChecked) {
                    assetRewards.push({
                        name: item.experienceName,
                        rewardType: 'EXPERIENCE',
                        rewardValue: item.experience,
                    });
                }
                if (item.goldValueChecked) {
                    assetRewards.push({
                        name: item.goldValueName,
                        rewardType: 'GOLD',
                        rewardValue: item.goldValue,
                    });
                }
                if (item.couponsChecked) {
                    if (item.couponsValue.length) {
                        couponRewards = [...item.couponsValue];
                    }
                }
                const reslutParams: any = {
                    conditionValue: item.levelId || 0,
                    conditionName: item.levelName || language.getText('qbdj'),
                };
                if (assetRewards.length) {
                    reslutParams.assetRewards = assetRewards;
                }
                if (couponRewards.length) {
                    reslutParams.couponRewards = couponRewards;
                }
                return reslutParams;
            });
        }
    }

    return newParams;
}
