import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;

export const CGBConfigLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COMMUNITY-GROUP-BUYING`;
        config.apiPath = '/admin/config';
        return await api.get(data, config).then((result: any) => {
            return {
                id: result.id,
                baseConfig: {
                    name: result.name,
                    subsites: result.subsites,
                },
                checkoutConfig: {
                    settleDays: result.settleDays,
                    info: result.info,
                },
            };
        });
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COMMUNITY-GROUP-BUYING`;
        config.apiPath = '/admin/config';
        const subsites = new Array<{ id: string }>();
        forEach(data.baseConfig.subsites, (subsite) => {
            subsites.push({
                id: subsite.id,
            });
        });
        const params = {
            id: data.id,
            info: data.checkoutConfig.info,
            name: data.baseConfig.name,
            settle_days: data.checkoutConfig.settleDays,
            subsites: subsites,
        };

        return await api.put(params, config);
    },
};
