import * as React from 'react';
import { ImageUpload } from '@comall-backend-builder/components-basis';

interface ImageObject {
    id: string | number;
    url?: string;
    path?: string;
}

export const MultipleImageUpload = (props: {
    value: ImageObject[] | undefined;
    onChange: (value: Required<ImageObject>[], name: string) => void;
    maxCount: number;
    maxSize?: number;
}) => {
    const { value, onChange, maxCount, maxSize } = props;
    const imageControlInfo = {
        name: 'picture',
        value: value as any,
        accept: 'image/*',
        maxCount,
        multiple: true,
        fileName: 'fileName',
        uploadType: 'multi',
        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
        mode: 'picture-card',
        maxSize: maxSize || 2048,
        preview: {
            size: 500,
        },
    };
    const onImageChange = (
        value: {
            id: string;
            path: string;
        }[],
        name: any
    ) => {
        const outputValue = value ? value.map((i) => ({ ...i, url: i.path })) : [];
        onChange(outputValue, name);
        return {};
    };
    return <ImageUpload {...imageControlInfo} onChange={onImageChange} />;
};
