import { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage, Modal } from 'antd';
import React from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../applications/cae/services/error-handle';
import { services } from '@comall-backend-builder/core';

export interface CouponServiceAppliesApplyButtonProps {
    row: any;
    entity: Entity;
    params: any;
}

/**
 * 售后单审核
 */
export class CouponServiceAppliesApplyButton extends PureComponent<
    CouponServiceAppliesApplyButtonProps,
    {}
> {
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { row, entity, params } = this.props;
        if (auditStatus === 'SUCCESS') {
            Modal.confirm({
                title: services.language.getText('qrshtgm'),
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/service_applies/${row.id}/approve`,
                        }
                    )
                        .then(() => {
                            AntMessage.success(services.language.getText('shtgcg'));
                            entity.search(params);
                        })
                        .catch((err) => {
                            if (err.response.body.err_code === 300111010) {
                                let msg = err.response.body.err_msg;
                                Modal.error({
                                    title: services.language.getText('tip'),
                                    content: msg,
                                    okText: services.language.getText('common.ok'),
                                    onOk: () => {},
                                });
                            } else {
                                errorHandle(err);
                            }
                        });
                },
            });
        }
        if (auditStatus === 'FAIL') {
            Modal.confirm({
                title: services.language.getText('qrjjshtgm'),
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/service_applies/${row.id}/reject`,
                        }
                    ).then(() => {
                        AntMessage.success(services.language.getText('jjshtgcg'));
                        entity.search(params);
                    });
                },
            });
        }
    }

    render() {
        const { row } = this.props;
        const status = row.status;
        return status === 'APPLYING' ? (
            <span style={{ marginRight: 10 }}>
                <AntButton
                    type="link"
                    className="span-link action-divide"
                    style={{ padding: 0, marginRight: 10 }}
                    onClick={this.submit.bind(this, 'SUCCESS')}
                >
                    {services.language.getText('approved')}
                </AntButton>
                <AntButton
                    type="link"
                    className="span-link"
                    style={{ padding: 0 }}
                    onClick={this.submit.bind(this, 'FAIL')}
                >
                    {services.language.getText('shbtg')}
                </AntButton>
            </span>
        ) : (
            <span style={{ display: 'inline-block', marginRight: 10 }}>
                <AntButton
                    disabled
                    type="link"
                    className="span-link action-divide"
                    style={{ padding: 0, marginRight: 10 }}
                >
                    {services.language.getText('approved')}
                </AntButton>
                <AntButton disabled type="link" style={{ padding: 0 }} className="span-link">
                    {services.language.getText('shbtg')}
                </AntButton>
            </span>
        );
    }
}
