import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { Member } from './member';

export class SelectMemberMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <Member {...displayInfo} />;
    }
}
