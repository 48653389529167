import { PureComponent, createElement } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
export class CgbManageListStatusButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        let closeText = language.getText('isAreadyClose') + row.baseInfo.name;
        if (row.executeStatus === 'STARTED') {
            closeText = services.language.getText('tqgbsqtghd');
        }
        let buttonProps = {
            ...this.props,
            statusKey: 'baseInfo.status',
            renderComponent: 'Button',
            type: 'link',
            style: { display: 'inline-flex' },
            handles: [
                {
                    params: { status: false },
                    value: true,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COMMUNITY-GROUP-BUYING`,
                    apiPath: '/admin/activities/:id/status',
                    config: {
                        content: language.getText('common.close'),
                        text: language.getText('common.close'),
                        style: { color: '#f5222d' },
                    },
                    confirm: {
                        text: closeText,
                    },
                },
                {
                    params: { status: true },
                    value: false,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COMMUNITY-GROUP-BUYING`,
                    apiPath: '/admin/activities/:id/status',
                    config: {
                        content: language.getText('common.opend'),
                        text: language.getText('common.opend'),
                    },
                    confirm: {
                        text: language.getText('isAreadyOpend') + '{{row.baseInfo.name}}?',
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('ChangeStatusButton'), buttonProps);
    }
}
