// 订单状态
export const orderStatusOptions = [
    { id: 'ALL', name: '<<all>>' },
    { id: 'CREATE', name: '待付款' },
    { id: 'PAYMENT', name: '待使用' },
    { id: 'RECEIVE', name: '已完成' },
    { id: 'CANCEL', name: '<<yqx>>' },
    { id: 'CLOSE', name: '<<beforeClose>>' },
];

// 订单类型
export const orderTypeOptions = [
    { id: 'ALL', name: '<<all>>' },
    { id: 'SALE_COUPON', name: '<<smyhq>>' },
    { id: 'MALL_ACTIVITY', name: '<<mallActivity>>' },
    { id: 'MALL_ACTIVITY_NEW', name: '新商场活动' },
    { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
    { id: 'GROUP_BUYING_COUPON', name: '拼团购买' },
];
// 订单类型-余额券
export const balanceOrderTypeOptions = [
    { id: 'ALL', name: '<<all>>' },
    { id: 'SALE_COUPON', name: '<<smyhq>>' },
    { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
    { id: 'GROUP_BUYING_COUPON', name: '拼团购买' },
];

// 退款单状态
export const refundStatusOptions = [
    { id: 'ALL', name: '<<all>>' },
    { id: 'APPLYING', name: '未处理' },
    { id: 'CANCEL', name: '<<yqx>>' },
    { id: 'APPLY_SUCC', name: '<<approved>>' },
    { id: 'REFUND_SUCC', name: '<<tkcg>>' },
    { id: 'REFUND_FAIL', name: '<<tksb>>' },
    { id: 'REFUND_PART_SUCC', name: '<<tkbfcg>>' },
];

// 核销渠道
export const channelOptions = [
    { id: 'OFFLINE', name: '线下核销' },
    { id: 'ONLINE', name: '线上核销' },
];
// 余额券充值状态
export const blanceRechargeOptions = [
    { id: 'NONE', name: '待充值' },
    { id: 'SUCCESS', name: '成功' },
    { id: 'FAIL', name: '失败' },
];
