import React, { Component } from 'react';
import { Table, InputNumber, Button, message, Popconfirm } from 'antd';
import { cloneDeep, remove, find, forEach } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export enum GroupBuyingProductType {
    GENERAL_PRODUCT = '普通商品',
    COUPON = '优惠券',
    VIRTUAL_GOODS = '虚拟商品',
    // ASSEMBLE_PRODUCT = '普通组合商品',
    // ASSEMBLE_COUPON = '虚拟组合商品',
}

interface GroupBuyingGoodsSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    groupBuyingProductType?: keyof typeof GroupBuyingProductType;
}

interface GroupBuyingGoodsSelectedDisplayState {
    generalPrice: undefined | number;
}

/**
 * 商品展示和内容编辑
 */
export class GroupBuyingGoodsSelectedDisplay extends Component<
    GroupBuyingGoodsSelectedDisplayProps,
    GroupBuyingGoodsSelectedDisplayState
> {
    state = {
        generalPrice: undefined,
    };

    selectedRows: any[] = [];

    getTableConfig = () => {
        let { modifiable, groupBuyingProductType, data = [] } = this.props;
        !groupBuyingProductType && (groupBuyingProductType = data[0]?.groupBuyingProductType);

        let columns: any[] = [];
        switch (groupBuyingProductType) {
            case 'GENERAL_PRODUCT':
                columns = [
                    {
                        title: services.language.getText('components.Products.name'),
                        dataIndex: 'productName',
                        key: 'productName',
                        width: 150,
                        render: (i: string) => (
                            <span title={i} className="goods-field-name">
                                {i}
                            </span>
                        ),
                    },
                    // {
                    //     title: services.language.getText('goodName'),
                    //     dataIndex: 'name',
                    //     key: 'name',
                    //     render: (i: string) => (
                    //         <span title={i} className="goods-field-name">
                    //             {i}
                    //         </span>
                    //     ),
                    // },
                    {
                        title: services.language.getText('subsiteName'),
                        dataIndex: 'subsiteName',
                        key: 'subsiteName',
                        width: 150,
                        render: (i: string) => (
                            <span title={i} className="goods-field-name">
                                {i}
                            </span>
                        ),
                    },
                    // {
                    //     title: services.language.getText('merchantName'),
                    //     dataIndex: 'merchantName',
                    //     key: 'merchantName',
                    //     width: 150,
                    //     render: (i: string) => (
                    //         <span title={i} className="goods-field-name">
                    //             {i}
                    //         </span>
                    //     ),
                    // },
                    {
                        title: services.language.getText('productCode'),
                        dataIndex: 'productCode',
                        key: 'productCode',
                        width: 150,
                        render: (i: string) => (
                            <span title={i} className="goods-field-name">
                                {i}
                            </span>
                        ),
                    },
                    // {
                    //     title: services.language.getText('goodCode'),
                    //     dataIndex: 'code',
                    //     key: 'code',
                    //     width: 150,
                    //     render: (i: string) => (
                    //         <span title={i} className="goods-field-name">
                    //             {i}
                    //         </span>
                    //     ),
                    // },
                    {
                        title: services.language.getText('xsj'),
                        dataIndex: 'salesPrice',
                        key: 'salesPrice',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: services.language.getText('sjkc'),
                        dataIndex: 'goodsStock',
                        key: 'goodsStock',
                        align: 'center',
                    },
                    {
                        title: '普通拼团价',
                        dataIndex: 'generalPrice',
                        key: 'generalPrice',
                        align: 'center',
                        render: (v: number, r: any) => {
                            return modifiable ? (
                                <InputNumber
                                    value={v}
                                    min={0.01}
                                    placeholder="拼团价"
                                    onChange={(value: any) =>
                                        this.changeValue('generalPrice', value, r.id)
                                    }
                                />
                            ) : (
                                v
                            );
                        },
                    },
                ];
                break;

            case 'COUPON':
                columns = [
                    {
                        title: services.language.getText('couponName'),
                        dataIndex: 'name',
                        key: 'name',
                        width: 150,
                        render: (i: string) => (
                            <span title={i} className="goods-field-name">
                                {i}
                            </span>
                        ),
                    },
                    {
                        title: services.language.getText('couponBaCode'),
                        dataIndex: 'batchNo',
                        key: 'batchNo',
                    },
                    {
                        title: services.language.getText('couponType'),
                        dataIndex: 'couponType',
                        key: 'couponType',
                        render: (type: string) => (
                            <span className="goods-field-name">
                                {type === 'CASH' && '代金券'}
                                {type === 'GIFT' && '礼品券'}
                                {type === 'PARKING' && '停车券'}
                            </span>
                        ),
                    },
                    {
                        title: services.language.getText('setCouponType'),
                        dataIndex: 'sourceDesc',
                        key: 'sourceDesc',
                    },
                    {
                        title: services.language.getText('couponStartTime'),
                        dataIndex: 'startTime',
                        key: 'startTime',
                    },
                    {
                        title: services.language.getText('couponEndTime'),
                        dataIndex: 'endTime',
                        key: 'endTime',
                    },
                    {
                        title: '普通拼团价',
                        dataIndex: 'generalPrice',
                        key: 'generalPrice',
                        align: 'center',
                        render: (v: number, r: any) => {
                            return modifiable ? (
                                <InputNumber
                                    value={v}
                                    min={0.01}
                                    placeholder="拼团价"
                                    onChange={(value: any) =>
                                        this.changeValue('generalPrice', value, r.id)
                                    }
                                />
                            ) : (
                                v
                            );
                        },
                    },
                ];
                break;
            default:
                break;
        }

        let config: any = {
            rowKey: 'id',
            columns,
            scroll: {
                // x: groupBuyingProductType === 'COUPON' ? 1000 : 900,
                x: 1000,
                y: 500,
            },
        };
        if (modifiable) {
            if (groupBuyingProductType !== 'COUPON') {
                config.rowSelection = {
                    fixed: true,
                    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                        this.selectedRows = selectedRows;
                    },
                };
            }

            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => (
                    <Popconfirm
                        onConfirm={this.onRemove(row.id)}
                        title={services.language.getText('qrscztsjm')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button type="link">{services.language.getText('common.delete')}</Button>
                    </Popconfirm>
                ),
            });
        }
        return config;
    };

    renderFooter = () => {
        const { generalPrice } = this.state;

        return (
            <div className="product-selected-footer">
                <div className="batch-remove"></div>
                <div className="setting-item">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                    <InputNumber
                        className="item-number"
                        style={{ width: 200 }}
                        placeholder={services.language.getText('plxgptptj')}
                        value={generalPrice}
                        min={0.01}
                        onChange={(e) => this.changeBatchInputValue('generalPrice', e)}
                    ></InputNumber>
                    <Popconfirm
                        onConfirm={this.batchChangeValue('generalPrice')}
                        title={services.language.getText('qrplxgxzdptptjm')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('confirmEdit')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    batchChangeValueCancel = (field: string) => {
        return () => {
            // @ts-ignore：
            this.setState({ [field]: undefined });
        };
    };

    batchChangeValue = (field: string) => {
        return () => {
            // @ts-ignore：
            const fieldValue = this.state[field];
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.id === j.id);
                item && (i[field] = fieldValue);
            });
            onChange(result);
        };
    };

    changeBatchInputValue = (field: 'generalPrice', value: any) => {
        // @ts-ignore：
        this.setState({ [field]: value });
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    render() {
        const { data, modifiable, groupBuyingProductType } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };

        return (
            <div>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && groupBuyingProductType !== 'COUPON' && this.renderFooter()}
            </div>
        );
    }
}
