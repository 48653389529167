import React, { Fragment, PureComponent } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { UploadFile } from './upload-file';
import { GoodsSelectorModal } from '../../../components';
import './index.less';

let internalPurchaseActivityId: any;
export { internalPurchaseActivityId };
export class InternalPurchaseGoodsManageButton extends PureComponent<any> {
    state = { uploadModalVisible: false, goodsModalVisible: false, subsiteIds: '' };
    componentDidMount() {
        internalPurchaseActivityId = this.props.params.activityId;
        services.api
            .get(
                {},
                {
                    apiPath: `/admin/internal_purchase_activities/${internalPurchaseActivityId}`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                }
            )
            .then((res: any) => {
                const ids = res?.subsites
                    ?.map((item: any) => {
                        return item.id;
                    })
                    .join(',');
                this.setState({ subsiteIds: ids });
            });
    }
    componentWillUnmount() {
        internalPurchaseActivityId = -1;
    }
    handleUpload = () => {
        this.setState({ uploadModalVisible: true });
    };
    onClose = () => {
        this.setState({ uploadModalVisible: false });
    };

    handleAddGoods = () => {
        this.setState({ goodsModalVisible: true });
    };
    onCloseGoodsModal = () => {
        const { entity } = this.props;
        this.setState({ goodsModalVisible: false });
        entity.search();
    };
    render() {
        let activityId = this.props.params.activityId;
        const { uploadModalVisible, subsiteIds } = this.state;
        const uploadFileProps = {
            activityId: activityId,
            visible: uploadModalVisible,
            onClose: this.onClose,
            ...this.props,
        };
        const params = {
            subsiteIds: subsiteIds,
            productTypes: ['NORMAL,WEIGHT'],
            shelfStatus: 'SHELF',
        };

        let importResultRoute =
            '/menu-list/market/internal-purchase/activities/' + activityId + '/goods-import-result';
        return (
            <Fragment>
                <div className="batch-button">
                    {!subsiteIds && (
                        <Button
                            text={services.language.getText('addProduct')}
                            icon="plus"
                            type="primary"
                            disabled
                        />
                    )}
                    {subsiteIds && (
                        <GoodsSelectorModal
                            modalTitle={services.language.getText('select')}
                            buttonText={services.language.getText('addProduct')}
                            icon="plus"
                            type="primary"
                            params={params}
                            onChange={this.handleAddGoods}
                            onCancel={this.onCloseGoodsModal}
                            hasSecondModal={true}
                        />
                    )}
                    <Button
                        type="default"
                        style={{
                            marginLeft: 15,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                        }}
                        onClick={this.handleUpload}
                    >
                        {services.language.getText('importProduct')}
                    </Button>
                    <Button
                        type="default"
                        style={{
                            marginLeft: 15,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                        }}
                        route={importResultRoute}
                    >
                        {services.language.getText('importResult')}
                    </Button>
                </div>
                <UploadFile {...uploadFileProps} />
            </Fragment>
        );
    }
}
