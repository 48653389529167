import React, { PureComponent, Fragment } from 'react';
import { matchPath } from 'react-router';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { services } from '@comall-backend-builder/core';
import { RoutesManager } from '@comall-backend-builder/core/lib/parser';
import './index.less';
/**
 * 功能同组件Breadcrumb,
 * 增加功能：如果在route中配置category，则会在菜单链接最前面加上一层菜单
 */
export class BreadcrumbNav extends PureComponent<any, any> {
    convertRoutes: any = (pathname: string, originRoutes: any) => {
        let routes = [];
        for (let route of originRoutes) {
            let match = matchPath(pathname, route);
            if (match) {
                let { breadcrumbName, childRoutes } = route;
                if (breadcrumbName) {
                    //如果有标识菜单分类，则增加一层路由
                    if (route.category) {
                        routes.push({
                            breadcrumbName: route.category,
                            isCategory: true,
                        });
                    }
                    routes.push({ ...route, params: match.params });
                }
                if (childRoutes) {
                    if (match.url !== '/') {
                        pathname = pathname.substring(match.url.length);
                    }
                    routes = routes.concat(this.convertRoutes(pathname, childRoutes));
                }
                break;
            }
        }
        return routes;
    };

    itemRender = (route: any, params: any, routes: any, paths: any) => {
        const isLastItem = routes.indexOf(route) === routes.length - 1;
        let href = '#/' + paths.join('/');
        if (route.isCategory || isLastItem) {
            return <span>{route.breadcrumbName}</span>;
        }
        return <a href={href}>{route.breadcrumbName}</a>;
    };

    render() {
        let pathname = this.props.location.pathname;
        let routes = this.convertRoutes(pathname, RoutesManager.getRoutes());
        let title = undefined;

        if (routes && routes.length) {
            let { title: breadcrumbTitle, breadcrumbName, params } = routes[routes.length - 1];
            title = services.interpolate(breadcrumbTitle || breadcrumbName, params);
        } else {
            return <Fragment />;
        }
        return (
            <div className="breadcrumb-layout">
                <AntBreadcrumb
                    className="breadcrumb-nav"
                    itemRender={this.itemRender}
                    routes={routes}
                />
                <h1 className="breadcrumb-page-title">{title}</h1>
            </div>
        );
    }
}
