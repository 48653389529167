import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';

/**
 * 下载二维码
 */
export class qrcodeRuleDownloadButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        const url = row.urlZipUrl;
        return url ? (
            <AntButton type="link" style={{ padding: 0, marginRight: 10 }} className="span-link">
                <a href={url}>{services.language.getText('xzewm')}</a>
            </AntButton>
        ) : null;
    }
}
