import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { map } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        ProductSearchKeywordsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/product_search_keywords',
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<keywords>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'true', name: '<<enable>>' },
                        { id: 'false', name: '<<disabled>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                keyword: {
                    type: 'string',
                    displayName: '<<keywords>>',
                    rules: [{ required: true, whitespace: true, message: '<<inputKeywords>>' }],
                    controlConfig: {
                        placeholder: '<<keywordsRequired>>',
                        maxLength: 20,
                        style: {
                            width: 450,
                        },
                    },
                },
                keywordStr: {
                    type: 'string.popover',
                    displayName: '<<keywords>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<enable>>' },
                        { id: 'false', name: '<<disabled>>' },
                    ],
                },
                selectedSubsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    defaultValue: [],
                    rules: [{ required: true, message: '<<selectSubsite>>' }],
                },
                goods: {
                    type: 'array.KeywordsProducts',
                    displayName: '<<components.Tools.selectProduct>>',
                    rules: [
                        { required: true, message: '<<components.Tools.productSelectRequired>>' },
                    ],
                    controlConfig: {
                        getParams(props: any) {
                            const { row } = props;
                            const subsiteIds = row.selectedSubsites
                                ? map(row.selectedSubsites, 'id')
                                : null;
                            return {
                                subsiteIds,
                                productTypes: ['NORMAL,COMBINE'],
                            };
                        },
                        memberIdentity: 'DISTRIBUTOR',
                    },
                },
            },
        },
    },
    components: {
        ProductSearchKeywordsView: {
            component: 'Viewport',
            entity: 'ProductSearchKeywordsEntity',
        },
        ProductSearchKeywordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ProductSearchKeywordsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addKeywords>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/product-search-keywords/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ProductSearchKeywordsTable' },
            ],
        },
        ProductSearchKeywordsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'enabled',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        ProductSearchKeywordsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1000,
            },
            columns: [
                {
                    property: 'keywordStr',
                    width: 180,
                },
                {
                    property: 'subsites',
                    width: 150,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'enabled',
                    width: 80,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 160,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_search_keywords/:id/disabled',
                                    config: {
                                        content: '{{row.keyword}}<<alreadyEnabled>>',
                                        text: '<<disabled>>',
                                    },
                                    confirm: {
                                        text: '<<isEnabledKeywords>>{{row.keyword}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_search_keywords/:id/enabled',
                                    config: {
                                        content: '{{row.keyword}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<keywords>>{{row.keyword}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/product-search-keywords/edit/{{row.id}}',
                    },
                ],
            },
        },
        ProductSearchKeywordsAddPage: {
            component: 'FlexLayout',
            entity: 'ProductSearchKeywordsEntity',
            direction: 'horizontal',
            items: [{ component: 'ProductSearchKeywordsAddFormView' }],
        },
        ProductSearchKeywordsAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'keyword' },
                { property: 'selectedSubsites' },
                { property: 'goods' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.goods && entity.goods.length > 0) {
                    entity.goods.forEach((item: any) => {
                        if (!item.sequence || Number(item.sequence) === 0) {
                            flag = false;
                        }
                    });
                } else {
                    flag = false;
                }
                if (!flag) {
                    message.error(language.getText('fillinAllSortRule'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(language.getText('keywordsSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ProductSearchKeywordsEditPage: {
            component: 'FlexLayout',
            entity: 'ProductSearchKeywordsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ProductSearchKeywordsEditFormView' }],
        },
        ProductSearchKeywordsEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'keyword' },
                {
                    property: 'selectedSubsites',
                },
                {
                    property: 'goods',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.goods && entity.goods.length > 0) {
                    entity.goods.forEach((item: any) => {
                        if (!item.sequence || Number(item.sequence) === 0) {
                            flag = false;
                        }
                    });
                } else {
                    flag = false;
                }
                if (!flag) {
                    message.error(language.getText('fillinAllSortRule'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/product-search-keywords',
                    component: 'ProductSearchKeywordsView',
                    breadcrumbName: '<<productSearch>>',
                    privilege: {
                        path: 'productSearch',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ProductSearchKeywordsAddPage',
                            breadcrumbName: '<<addKeywords>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ProductSearchKeywordsEditPage',
                            breadcrumbName: '<<editKeywords>>',
                        },
                        { path: '/', component: 'ProductSearchKeywordsPage' },
                    ],
                },
            ],
        },
    ],
};
