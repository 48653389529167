import React, { Component } from 'react';
import { Checkbox, Button, Modal, Row, Col, Radio, message, Select, Input } from 'antd';
import { DndProvider as DragDropContext } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { DragSortBox } from '../drag-sort-box';
import { debounce, cloneDeep, set, get, isEqual } from 'lodash';
import { LinkTypePlus } from '../../applications/design/components';
import { newClientCustomSelectorConfig } from '../../applications/design/design';
import {
    getThirdLinkTypeEffectPathConfigList,
    getNewLinkTypeEffectPathConfigList,
} from '../link-type-effect-path/config';
import { NavigationLink } from '../navigation-link';
import { CarparkCheckoutType } from '../index';
import { services } from '@comall-backend-builder/core';
import { isSimplicityTheme } from '../../containers/theme-style';
import {
    Value,
    ModuleType,
    moduleNames,
    moduleTips,
    Menu,
    InvoiceChannelType,
    CarparkOrderInvoiceConfig,
    defaultValue,
    moduleDefaultIcon,
    moduleDefaultIconSimplicity,
    FindVehicleType,
    FindVehicleConfig,
    BookParkingSpaceConfig,
    BookParkingSpaceType,
    VisibilitySetting,
} from './common';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;
const { Option } = Select;

interface Props {
    value: Value;
    onChange: (value: Value) => void;
    getSubsite?: (row: any) => { id: string; name: string };
    row: any;
}

interface State {
    showModal: boolean;
    modalType: ModuleType;
    localValue: any;
    dependencesValue?: {
        id: string;
        name: string;
    };
    cardLevelOptions: { id: string; name: string }[];
    showNeedSubsite: boolean;
    showCustomMenuModal: boolean;
    /**功能菜单集合 */
    customMenuValue: any;
    /**设置自定义菜单的当前索引 */
    currentCustomIndex: any;
}

/**
 * 停车页面配置功能菜单
 */
export class CarparkMenusConfig extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        const { value, onChange } = this.props;
        this.state = {
            showModal: false,
            modalType: ModuleType.MyVehicle,
            localValue: value || defaultValue,
            dependencesValue: undefined,
            cardLevelOptions: [],
            showNeedSubsite: true,
            showCustomMenuModal: false,
            customMenuValue: value,
            currentCustomIndex: 0,
        };
        this.isEdit = !!get(props, 'params.id');
        if (!this.isEdit) {
            onChange(defaultValue);
        }
    }

    componentDidMount(): void {
        const dependencesValue = this.getDependencesValue(this.props);
        if (dependencesValue) {
            this.setState(
                {
                    dependencesValue,
                    showNeedSubsite: false,
                    cardLevelOptions: [],
                },
                () => {
                    this.loadOptions(dependencesValue.id);
                }
            );
        }
    }
    isEdit = false;

    getDependencesValue = (props: Props) => {
        const { row, getSubsite } = props;
        if (getSubsite && row) {
            return getSubsite(row);
        }
        return undefined;
    };

    loadOptions = (subsiteIds: string) => {
        const params = {
            subsiteIds,
            page: 1,
            perPage: 100,
            status: 'ENABLE',
        };
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/store_card_levels/legal',
        };
        api.get(params, config).then((res: any) => {
            const result: Array<{
                id: string;
                name: string;
            }> = [];
            res.result.forEach((level: any) => {
                result.push({
                    id: level.cardLevelId.toString(),
                    name: level.name,
                });
            });
            this.setState({
                cardLevelOptions: result,
            });
        });
    };

    changeSubsiteClearValue = () => {
        const { customMenuValue } = this.state;
        const currentValue = cloneDeep(customMenuValue);
        const moduleValue = cloneDeep(
            currentValue?.find((item: any) => item.moduleType === ModuleType.BookParkingSpace)
        );
        if (!moduleValue) {
            return;
        }
        set(moduleValue, 'config.visibleMemberCardLevel', []);
        const newValue = currentValue.map((i: any) =>
            i.moduleType === ModuleType.BookParkingSpace ? moduleValue : i
        );
        this.onValueChange(newValue);
    };

    handlePictureByThemeStyle = async (value: Value) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/theme_style',
        };
        const res: any = await api.get({}, config);
        const isSimplicity = isSimplicityTheme(JSON.parse(res.config));
        const newValue = cloneDeep(value)?.map((item) => ({
            ...item,
            picture:
                item.picture ||
                (isSimplicity
                    ? moduleDefaultIconSimplicity[item.moduleType]
                    : moduleDefaultIcon[item.moduleType]),
        }));
        this.onValueChange(newValue);
    };

    componentWillReceiveProps(nextProps: Readonly<Props>) {
        const { dependencesValue } = this.state;
        const nextDependencesValue = this.getDependencesValue(nextProps);
        if (!this.isEdit && !this.props.value && nextProps.value) {
            this.handlePictureByThemeStyle(nextProps.value);
        }
        const nextIsInternal = this.isInternal(nextProps);
        const isInternalChange = nextIsInternal !== this.isInternal(this.props);
        if (isInternalChange) {
            this.updateWhencCheckoutTypeChange(nextProps, nextIsInternal);
        }
        if (nextDependencesValue) {
            if (!isEqual(dependencesValue, nextDependencesValue)) {
                this.changeSubsiteClearValue();
                this.setState(
                    {
                        dependencesValue: nextDependencesValue,
                        cardLevelOptions: [],
                        showNeedSubsite: false,
                    },
                    () => {
                        this.loadOptions(nextDependencesValue.id);
                    }
                );
            }
        } else if (dependencesValue) {
            this.changeSubsiteClearValue();
        }
    }

    updateWhencCheckoutTypeChange = (props: any, nextIsInternal: boolean) => {
        const { value = defaultValue } = props;
        const carparkOrderIndex = value.findIndex(
            (item: any) => item.moduleType === ModuleType.CarparkOrder
        );
        const queryPaymentIndex = value.findIndex(
            (item: any) => item.moduleType === ModuleType.QueryPayment
        );
        const newValue = cloneDeep(value);
        if (nextIsInternal) {
            set(newValue, `[${carparkOrderIndex}].visible`, true);
            set(newValue, `[${queryPaymentIndex}].visible`, false);
        } else {
            set(newValue, `[${carparkOrderIndex}].visible`, false);
        }
        this.onValueChange(newValue);
    };

    onValueChange = (newValue: Value) => {
        this.setState({
            customMenuValue: newValue,
        });
        const { onChange } = this.props;
        onChange(newValue);
    };

    onItemCheck = (index: number) => {
        const menuList = this.getMenuList();
        const newValue = cloneDeep(menuList);
        newValue[index].visible = !newValue[index].visible;
        this.onValueChange(newValue);
    };

    onChangePosition = debounce((hoverItem: any, dragItem: any) => {
        const { value } = this.props;
        const newValue = update(value, {
            $splice: [
                [dragItem.index, 1],
                [hoverItem.index, 0, value[dragItem.index]],
            ],
        });
        this.onValueChange(newValue);
    }, 100);

    getModuleValue = (moduleType: ModuleType) => {
        const { localValue } = this.state;
        return localValue?.find((item: any) => item.moduleType === moduleType);
    };
    getCustomModuleValue = () => {
        const { localValue, currentCustomIndex } = this.state;
        return localValue?.find((item: any, index: any) => index === currentCustomIndex);
    };
    onLocalValueChange = (path: string, value: any, moduleType: ModuleType) => {
        const { localValue } = this.state;
        const moduleValue = cloneDeep(this.getModuleValue(moduleType));
        if (!moduleValue) {
            return;
        }
        set(moduleValue, path, value);
        this.setState({
            localValue: localValue.map((i: any) => (i.moduleType === moduleType ? moduleValue : i)),
        });
    };
    onPictrueChange = (picture: any, moduleType: ModuleType) => {
        if (moduleType === ModuleType.Custom) {
            //自定义菜单
            this.onCustomLocalValueChange('picture', picture?.length ? picture[0].path : undefined);
        } else {
            this.onLocalValueChange(
                'picture',
                picture?.length ? picture[0].path : undefined,
                moduleType
            );
        }

        return {};
    };
    renderCommonModalContent = (moduleType: ModuleType) => {
        return (
            <ModalRow label={language.getText('tb_1')}>
                {this.renderImageUpload(moduleType)}
            </ModalRow>
        );
    };
    onFindVehicleTypeChange = (e: any) => {
        const type = e.target.value;
        this.onLocalValueChange('config.type', type, ModuleType.FindVehicle);
    };
    renderFindVehicleModalContent = () => {
        const moduleValue = this.getModuleValue(ModuleType.FindVehicle) as Menu<FindVehicleConfig>;
        const isInternal = moduleValue?.config?.type === FindVehicleType.INTERNAL;
        return (
            <div>
                <ModalRow label={language.getText('tb_1')}>
                    {this.renderImageUpload(ModuleType.FindVehicle)}
                </ModalRow>
                <ModalRow label={language.getText('xcfs')}>
                    <Radio.Group
                        value={moduleValue?.config?.type || FindVehicleType.EXTERNAL}
                        onChange={this.onFindVehicleTypeChange}
                    >
                        <Radio value={FindVehicleType.INTERNAL}>{language.getText('xcxzcx')}</Radio>
                        <Radio value={FindVehicleType.EXTERNAL}>
                            {language.getText('tzdsfym')}
                        </Radio>
                    </Radio.Group>
                </ModalRow>
                <ModalRow hidden={isInternal} label={language.getText('tzsz')}>
                    {this.renderLinkType(ModuleType.FindVehicle)}
                </ModalRow>
            </div>
        );
    };
    onInvoiceChannelChange = (e: any) => {
        const channel = e.target.value;
        this.onLocalValueChange('config.channel', channel, ModuleType.CarparkOrderInvoice);
    };
    renderCarparkOrderInvoiceModalContent = () => {
        const moduleValue = this.getModuleValue(ModuleType.CarparkOrderInvoice) as Menu<
            CarparkOrderInvoiceConfig
        >;
        const isInternal = moduleValue?.config?.channel === InvoiceChannelType.INTERNAL_INVOICE;
        return (
            <div>
                <ModalRow label={language.getText('tb_1')}>
                    {this.renderImageUpload(ModuleType.CarparkOrderInvoice)}
                </ModalRow>
                <ModalRow label={language.getText('kpfs')}>
                    <Radio.Group
                        value={moduleValue?.config?.channel}
                        onChange={this.onInvoiceChannelChange}
                    >
                        <Radio value={InvoiceChannelType.INTERNAL_INVOICE}>
                            {language.getText('xcxkfp')}
                        </Radio>
                        <Radio value={InvoiceChannelType.EXTERNAL_INVOICE}>
                            {language.getText('tzdsfkfp')}
                        </Radio>
                    </Radio.Group>
                    <div hidden={!isInternal}>
                        <span style={{ color: '#ccc' }}>
                            {language.getText('qqrmdpzfpxxdjrkmfy')}
                        </span>
                        <NavigationLink
                            href="#/invoice-config"
                            text={language.getText('cksz')}
                            privilege={{ path: 'invoiceConfig', level: 'full' }}
                            disabledType="tips"
                        />
                    </div>
                </ModalRow>
                <ModalRow hidden={isInternal} label={language.getText('tzsz')}>
                    {this.renderLinkType(ModuleType.CarparkOrderInvoice)}
                </ModalRow>
            </div>
        );
    };
    onChangeBookParkingSpaceVisbilitySetting = (e: any) => {
        const visibilitySetting = e.target.value;
        this.onLocalValueChange(
            'config.visibilitySetting',
            visibilitySetting,
            ModuleType.BookParkingSpace
        );
    };
    onChangeCustomMenuVisbilitySetting = (e: any) => {
        const visibilitySetting = e.target.value;
        this.onCustomLocalValueChange('config.visibilitySetting', visibilitySetting);
    };
    onChangeBookParkingSpaceCardLevelChange = (cardLevelId: string[]) => {
        this.onLocalValueChange(
            'config.visibleMemberCardLevel',
            cardLevelId,
            ModuleType.BookParkingSpace
        );
    };
    onChangeCustomMenuLevelChange = (cardLevelId: string[]) => {
        this.onCustomLocalValueChange('config.visibleMemberCardLevel', cardLevelId);
    };
    private onHandleSelect = () => {
        const { showNeedSubsite } = this.state;
        if (showNeedSubsite) {
            message.warning(language.getText('selectBeforeMerchant'));
            return false;
        }
    };
    renderCarparkSubscribeModalContent = () => {
        const { showNeedSubsite, cardLevelOptions } = this.state;
        const moduleValue = this.getModuleValue(ModuleType.BookParkingSpace) as Menu<
            BookParkingSpaceConfig
        >;
        const isInternal = moduleValue?.config?.type === BookParkingSpaceType.INTERNAL;
        const showVisibleMemberCardLevel =
            moduleValue?.config?.visibilitySetting === VisibilitySetting.VISIBLE_MEMBER_CARD_LEVEL;
        return (
            <div>
                <ModalRow label={language.getText('tb_1')}>
                    {this.renderImageUpload(ModuleType.BookParkingSpace)}
                </ModalRow>
                <ModalRow label={language.getText('yzsz')}>
                    <Radio.Group
                        value={moduleValue?.config?.visibilitySetting}
                        onChange={this.onChangeBookParkingSpaceVisbilitySetting}
                    >
                        <Radio value={VisibilitySetting.UNRESTRICTED}>
                            {language.getText('l_syrjkj_1')}
                        </Radio>
                        <Radio value={VisibilitySetting.VISIBLE_MEMBER_CARD_LEVEL}>
                            {language.getText('designatedLevelVisible')}
                        </Radio>
                    </Radio.Group>
                </ModalRow>
                <ModalRow hidden={!showVisibleMemberCardLevel} label={language.getText('xzhykdj')}>
                    <div onClick={this.onHandleSelect}>
                        <Select
                            allowClear
                            mode="multiple"
                            value={moduleValue?.config?.visibleMemberCardLevel || []}
                            style={{ width: 200 }}
                            onChange={this.onChangeBookParkingSpaceCardLevelChange}
                            disabled={showNeedSubsite}
                        >
                            {cardLevelOptions.map((item) => {
                                return <Option value={item.id}>{item.name}</Option>;
                            })}
                        </Select>
                    </div>
                </ModalRow>
                <ModalRow label={language.getText('jumpMethod')}>
                    <Radio.Group value={moduleValue?.config?.type}>
                        <Radio disabled value={FindVehicleType.INTERNAL}>
                            {language.getText('xcxzcx')}
                        </Radio>
                        <Radio value={FindVehicleType.EXTERNAL}>
                            {language.getText('tzdsfym')}
                        </Radio>
                    </Radio.Group>
                </ModalRow>
                <ModalRow hidden={isInternal} label={language.getText('tzsz')}>
                    {this.renderLinkType(ModuleType.BookParkingSpace)}
                </ModalRow>
            </div>
        );
    };

    /**
     * 自定义菜单 修改菜单名称
     */
    changeMenuName = (value: string) => {
        this.onCustomLocalValueChange('name', value);
        return {};
    };

    /**
     * 修改自定义菜单属性
     */
    onCustomLocalValueChange = (path: string, value: any) => {
        const { localValue, currentCustomIndex } = this.state;
        const moduleValue = cloneDeep(localValue[currentCustomIndex]);
        if (!moduleValue) {
            return;
        }
        set(moduleValue, path, value);
        this.setState({
            localValue: localValue.map((i: any, j: any) =>
                j === currentCustomIndex ? moduleValue : i
            ),
        });
    };

    /**
     * 添加自定义菜单弹窗
     */
    renderCustomMenuModalContent = () => {
        const { localValue, currentCustomIndex, showNeedSubsite, cardLevelOptions } = this.state;
        const item = localValue[currentCustomIndex];
        const showVisibleMemberCardLevel =
            item?.config?.visibilitySetting === VisibilitySetting.VISIBLE_MEMBER_CARD_LEVEL;
        return (
            <div>
                <ModalRow label={language.getText('cdmc')}>
                    <Input
                        value={item?.name}
                        onChange={(e) => this.changeMenuName(e.target.value)}
                    ></Input>
                </ModalRow>
                <ModalRow label={language.getText('cdtb')}>
                    {this.renderImageUpload(ModuleType.Custom)}
                </ModalRow>
                <ModalRow label={language.getText('yzsz')}>
                    <Radio.Group
                        value={item?.config?.visibilitySetting}
                        onChange={this.onChangeCustomMenuVisbilitySetting}
                    >
                        <Radio value={VisibilitySetting.UNRESTRICTED}>
                            {language.getText('l_syrjkj_1')}
                        </Radio>
                        <Radio value={VisibilitySetting.VISIBLE_MEMBER_CARD_LEVEL}>
                            {language.getText('designatedLevelVisible')}
                        </Radio>
                    </Radio.Group>
                </ModalRow>
                <ModalRow hidden={!showVisibleMemberCardLevel} label={language.getText('xzhykdj')}>
                    <div onClick={this.onHandleSelect}>
                        <Select
                            allowClear
                            mode="multiple"
                            value={item?.config?.visibleMemberCardLevel || []}
                            style={{ width: 200 }}
                            onChange={this.onChangeCustomMenuLevelChange}
                            disabled={showNeedSubsite}
                        >
                            {cardLevelOptions.map((item) => {
                                return <Option value={item.id}>{item.name}</Option>;
                            })}
                        </Select>
                    </div>
                </ModalRow>
                <ModalRow label={language.getText('tzsz')}>
                    {this.renderLinkType(ModuleType.Custom)}
                </ModalRow>
            </div>
        );
    };

    renderImageUpload = (moduleType: ModuleType) => {
        const { localValue, currentCustomIndex } = this.state;
        let imageControlInfo = {
            name: 'picture',
            value:
                moduleType === ModuleType.Custom
                    ? localValue[currentCustomIndex]?.picture
                    : this.getModuleValue(moduleType)?.picture,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: 1 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                services.language.getText('pictures') +
                '{{file.name}}' +
                services.language.getText('pictureUploadLimit1'),
            limitTips: {
                style: {
                    display: 'none',
                },
            },
        };
        const onChange = (value: any, name: any) => this.onPictrueChange(value, moduleType);

        return (
            <div className="carpark-menus-config__modal__img">
                <ImageUpload {...imageControlInfo} onChange={onChange} />
                <span className="carpark-menus-config__modal__img__tips">
                    {language.getText('picLimitHeight50')}
                </span>
            </div>
        );
    };
    getLinkValue = (moduleType: ModuleType) => {
        let moduleValue;
        if (moduleType === ModuleType.Custom) {
            //自定义菜单
            moduleValue = this.getCustomModuleValue();
        } else {
            moduleValue = this.getModuleValue(moduleType);
        }
        const content = moduleValue?.config?.content;
        return content
            ? JSON.parse(content)
            : {
                  linkType: '',
                  linkParams: null,
              };
    };
    onLinkValueChange = (linkValue: any, moduleType: ModuleType) => {
        if (moduleType === ModuleType.Custom) {
            this.onCustomLocalValueChange(
                'config.content',
                linkValue ? JSON.stringify(linkValue) : null
            );
        } else {
            this.onLocalValueChange(
                'config.content',
                linkValue ? JSON.stringify(linkValue) : null,
                moduleType
            );
        }
    };
    thirdLinkTypeEffectPathConfigList = getThirdLinkTypeEffectPathConfigList();
    allLinkTypeConfigList = getNewLinkTypeEffectPathConfigList();
    renderLinkType = (moduleType: ModuleType) => {
        const linkValue = this.getLinkValue(moduleType);
        let linkTypes;
        const onChange = (linkValue: any) => this.onLinkValueChange(linkValue, moduleType);
        if (moduleType === ModuleType.Custom) {
            linkTypes = this.allLinkTypeConfigList;
        } else {
            linkTypes = this.thirdLinkTypeEffectPathConfigList;
        }
        return (
            <LinkTypePlus
                selector={newClientCustomSelectorConfig as any}
                linkTypes={linkTypes}
                linkTypeMode="cascader"
                value={linkValue}
                onChange={onChange}
            />
        );
    };

    modalContent = {
        [ModuleType.MyVehicle]: this.renderCommonModalContent,
        [ModuleType.QueryPayment]: this.renderCommonModalContent,
        [ModuleType.CarparkOrder]: this.renderCommonModalContent,
        [ModuleType.FindVehicle]: this.renderFindVehicleModalContent,
        [ModuleType.CarparkOrderInvoice]: this.renderCarparkOrderInvoiceModalContent,
        [ModuleType.BookParkingSpace]: this.renderCarparkSubscribeModalContent,
        [ModuleType.Custom]: this.renderCustomMenuModalContent,
    };
    renderModalContent = () => {
        const { modalType } = this.state;
        return this.modalContent[modalType](modalType);
    };
    showModal = (modalType: ModuleType, index: any) => {
        const menuList = this.getMenuList();
        this.setState({
            showModal: true,
            modalType,
            currentCustomIndex: index,
            localValue: cloneDeep(menuList),
        });
    };
    closeModal = () => {
        this.setState({ showModal: false });
    };
    getMenuList = () => {
        const { customMenuValue } = this.state;
        const menuList: any[] = customMenuValue
            ? customMenuValue
            : this.props.value
            ? this.props.value
            : defaultValue;
        return menuList;
    };
    onModalConfirm = () => {
        const { modalType, localValue } = this.state;
        const moduleValue = this.getModuleValue(modalType);
        if (!moduleValue?.picture) {
            message.warn(language.getText('qsctb'));
            return;
        }
        this.onValueChange(localValue);
        this.closeModal();
    };

    renderModal = () => {
        const { showModal, modalType } = this.state;
        let title: string = '';
        if (modalType === ModuleType.Custom) {
            title = language.getText('customizeMenus');
        } else {
            title = language.getText(moduleNames[modalType]);
        }
        return (
            <Modal
                title={title}
                onCancel={this.closeModal}
                onOk={this.onModalConfirm}
                visible={showModal}
            >
                {this.renderModalContent()}
            </Modal>
        );
    };

    showCustomMenuModal = () => {
        const menuList = this.getMenuList();
        this.setState({ showCustomMenuModal: true, localValue: cloneDeep(menuList) });
    };
    closeCustomMenuModal = () => {
        this.setState({ showCustomMenuModal: false });
    };

    /**
     * 添加自定义菜单
     */
    addCustomMenu = () => {
        const menuList = this.getMenuList();
        const newItem = {
            moduleType: ModuleType.Custom,
            visible: false,
            description: language.getText('customizeMenus'),
            config: {
                type: BookParkingSpaceType.EXTERNAL,
                visibilitySetting: VisibilitySetting.UNRESTRICTED,
            },
        };

        this.setState({
            customMenuValue: [...menuList, newItem],
        });
    };
    /**
     * 删除自定义的菜单项
     */
    deleteMenu = (item: any) => {
        const menuList = this.getMenuList();
        this.setState({
            customMenuValue: menuList.filter((c: any) => {
                return c !== item;
            }),
        });
    };

    /**
     * 设置自定义的菜单项
     */
    addMenu = () => {
        const menuList = this.getMenuList();
        this.setState({ showModal: true, localValue: cloneDeep(menuList) });
    };
    /**
     * 复选框是否可以点击
     */
    getItemCheckable = (moduleType: ModuleType) => {
        /*  if (moduleType === ModuleType.MyVehicle) {
            return false;
        }
        if (moduleType === ModuleType.CarparkOrder && this.isInternal(this.props)) {
            return false;
        } */
        return true;
    };

    renderItem = (item: Menu<any>, index: number) => {
        const { moduleType, visible } = item;
        const name =
            moduleType === ModuleType.Custom
                ? item?.name
                    ? item?.name
                    : language.getText('noNamed')
                : language.getText(moduleNames[moduleType]);
        const tips = language.getText(moduleTips[moduleType]);
        const disabled = !this.getItemCheckable(moduleType);

        return (
            <div className="carpark-menus-config__item">
                <div className="carpark-menus-config__item__content">
                    <Checkbox
                        disabled={disabled}
                        checked={visible}
                        onChange={() => this.onItemCheck(index)}
                    />
                    <span className="carpark-menus-config__item__name">{name}</span>
                    <div className="carpark-menus-config__item__setting">
                        <Button onClick={() => this.showModal(moduleType, index)} type="link">
                            {language.getText('settings')}
                        </Button>
                        {item.moduleType === ModuleType.Custom && (
                            <Button onClick={() => this.deleteMenu(item)} type="link">
                                {language.getText('common.delete')}
                            </Button>
                        )}
                    </div>
                </div>
                {Boolean(tips) && <div className="carpark-menus-config__item__tips">{tips}</div>}
            </div>
        );
    };

    private isInternal = (props: Props) => {
        const { row } = props;
        return (
            get(row, 'carparkConfig.checkoutType.type', CarparkCheckoutType.INTERNAL) ===
            CarparkCheckoutType.INTERNAL
        );
    };

    private get hiddenItemType() {
        if (this.isInternal(this.props)) {
            return [ModuleType.QueryPayment];
        }
        return [ModuleType.CarparkOrder];
    }

    render() {
        const menuList = this.getMenuList();
        return (
            <DragDropContext backend={HTML5Backend}>
                <div className="carpark-menus-config__desc">{language.getText('tzcdxkjxpx')}</div>
                <div className="carpark-menus-config">
                    {menuList.length > 0 &&
                        menuList.map((item: any, index: number) => {
                            if (this.hiddenItemType.includes(item.moduleType)) {
                                return null;
                            }
                            return (
                                <DragSortBox
                                    type="CARPARK"
                                    data={{ index }}
                                    hover={this.onChangePosition}
                                >
                                    {this.renderItem(item, index)}
                                </DragSortBox>
                            );
                        })}
                </div>
                <div className="carpark-menus-config__custom-menu" onClick={this.addCustomMenu}>
                    {language.getText('addCustomMenu')}
                </div>
                {this.renderModal()}
            </DragDropContext>
        );
    }
}

class ModalRow extends Component<{ label: string; hidden?: boolean }> {
    render() {
        const { label, hidden, children } = this.props;
        return (
            <Row hidden={hidden} className="carpark-menus-config__modal__row">
                <Col span={6}>{label}：</Col>
                <Col span={18}>{children}</Col>
            </Row>
        );
    }
}
