import React, { Component } from 'react';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { ArrayCouponRuleStackSelector } from './array-coupon-rule-stack-selector';
import { services } from '@comall-backend-builder/core';

const enum StackEnum {
    OTHER = 'OTHER',
    SELF = 'SELF',
}
export class ArrayCouponRuleStack extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value || value.length === 0) {
            onChange([StackEnum.OTHER]);
        }
    }

    onChange(value: any) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, name, row } = this.props;
        const options = [
            {
                id: StackEnum.OTHER,
                name: services.language.getText('khqtqdjsy'),
            },
            {
                id: StackEnum.SELF,
                name: services.language.getText('khzskdjsy'),
            },
        ];
        return (
            <div>
                <CheckboxGroup
                    name={name}
                    onChange={this.onChange}
                    options={options}
                    value={value}
                />
                <div style={{ display: 'flex' }}>
                    <ArrayCouponRuleStackSelector row={row} />
                    <div>{services.language.getText('qtqdjjsskytygxdjdyhqyqsy')}</div>
                </div>
            </div>
        );
    }
}
