import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';

export const config: Config = {
    entities: {
        PointSettingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/pointSetting',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        exchangeRule: {
                            type: 'object.PointExchangeRule',
                            displayName: '<<dkgz>>',
                            rules: [{ required: true }],
                            extra: '<<deductionRuleExtra>>',
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<dxsz>>',
                    properties: {
                        deductionType: {
                            type: 'string.options.radio',
                            displayName: '<<dkje>>',
                            defaultValue: 'PRODUCT',
                            options: [
                                { id: 'PRODUCT', name: '<<spje>>' },
                                { id: 'PROFIT', name: '<<splr>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<yhqhdzxjjqspjylmzdspcbjbf>>',
                        },
                        productRange: {
                            type: 'string.options.radio',
                            displayName: '<<spfw>>',
                            defaultValue: 'ALL_PRODUCT',
                            options: [
                                { id: 'ALL_PRODUCT', name: '<<allProduct>>' },
                                { id: 'PART_PRODUCT', name: '<<bfsp>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        minUseCount: {
                            type: 'number.tip',
                            displayName: '<<zssy>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    step: 1,
                                    precision: 0,
                                    message: '<<l_priceErrorMessage>>',
                                },
                            ],
                        },
                        maxUseCount: {
                            type: 'number.tip',
                            displayName: '<<zdsy>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    step: 1,
                                    precision: 0,
                                    message: '<<l_priceErrorMessage>>',
                                },
                            ],
                        },
                        largestPercentage: {
                            type: 'number.tip',
                            displayName: '<<dkddxmjebl>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    max: 100,
                                    step: 1,
                                    precision: 0,
                                    message: '<<l_frontDayMessage>>',
                                },
                            ],
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<gzsm>>',
                            rules: [{ required: true, whitespace: true, max: 800 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 800,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        PointSettingView: {
            component: 'Viewport',
            entity: 'PointSettingEntity',
        },
        PointSettingPage: {
            component: 'Card',
            entity: 'PointSettingEntity',
            content: { component: 'PointSettingEditForm' },
        },
        PointSettingEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'PointSettingEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.exchangeRule',
                },
                {
                    property: 'ruleInfo.deductionType',
                },
                {
                    property: 'ruleInfo.productRange',
                },
                {
                    property: 'ruleInfo.minUseCount',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '<<point>>',
                    },
                },
                {
                    property: 'ruleInfo.maxUseCount',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '<<point>>',
                    },
                },
                {
                    property: 'ruleInfo.largestPercentage',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '%',
                    },
                },
                {
                    property: 'ruleInfo.remark',
                    controlConfig: {
                        style: {
                            width: 550,
                        },
                    },
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const reg = /^\+?[0-9]\d*$/;

                let exchangeRatio = get(entity, 'baseInfo.exchangeRule.exchangeRatio');
                if (exchangeRatio) {
                    if (!reg.test(exchangeRatio)) {
                        AntMessage.warning('<<dkdwqtx>>');
                        return false;
                    }
                }
                let exchangeAmount = get(entity, 'baseInfo.exchangeRule.exchangeAmount');
                if (exchangeAmount) {
                    const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;

                    if (!reg.test(String(exchangeAmount))) {
                        AntMessage.warning('<<dkjeqtx>>');
                        return;
                    }
                }

                let minUseCount = get(entity, 'ruleInfo.minUseCount');
                if (minUseCount) {
                    if (!reg.test(minUseCount)) {
                        AntMessage.warning('<<zssyqtx>>');
                        return false;
                    }
                }
                if (minUseCount < exchangeRatio) {
                    AntMessage.warning('<<zssybnxyzxdkdw>>');
                    return false;
                }
                let maxUseCount = get(entity, 'ruleInfo.maxUseCount');
                if (maxUseCount) {
                    if (!reg.test(maxUseCount)) {
                        AntMessage.warning('<<zdsyqtx>>');
                        return false;
                    }
                }
                let largestPercentage = get(entity, 'ruleInfo.largestPercentage');
                if (largestPercentage) {
                    if (!reg.test(largestPercentage)) {
                        AntMessage.warning('<<dkddxmjeblqtx>>');
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () => {
                    window.location.reload();
                });
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/point-setting',
                    component: 'PointSettingView',
                    breadcrumbName: '<<pointRuleSet>>',
                    privilege: {
                        path: 'pointRuleSet',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'PointSettingPage' }],
                },
            ],
        },
    ],
};
