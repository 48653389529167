import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import SecurityPolicyCondition from './security-policy-condition';

export class SecurityPolicyConditionMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SecurityPolicyCondition {...displayInfo} />;
    }
}
