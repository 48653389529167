import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

interface ServiceReservedStockValue {
    totalQuantity?: number;
    changeStock?: number;
    availableStock?: number;
    defalutTotalQuantity?: number;
    defalutAvailableStock?: number;
}

interface ServiceReservedStockProps {
    row: any;
    /**
     * 选中值
     */
    value: ServiceReservedStockValue;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: ServiceReservedStockValue) => void;
}

export class ServiceReservedStock extends Component<ServiceReservedStockProps, {}> {
    changeValue = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        if (inputValue && newValue.defalutAvailableStock + inputValue >= 0) {
            newValue.changeStock = inputValue;
            newValue.totalQuantity = newValue.defalutTotalQuantity + inputValue;
            newValue.availableStock = newValue.defalutAvailableStock + inputValue;
            onChange(newValue);
        } else {
            newValue.changeStock = '';
            newValue.totalQuantity = newValue.defalutTotalQuantity;
            newValue.availableStock = newValue.defalutAvailableStock;
            onChange(newValue);
        }
    };
    changeTotalQuantityValue = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        newValue.totalQuantity = inputValue;
        onChange(newValue);
    };
    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const isAddCopy =
            window.location.href.includes('/add') || window.location.href.includes('/copy');
        if (isAddCopy) {
            return (
                <div>
                    <InputNumber
                        placeholder={language.getText('qsrkcsl')}
                        style={{ width: '150px' }}
                        value={value.totalQuantity}
                        min={1}
                        max={100000000}
                        step={1}
                        precision={0}
                        onChange={(value: any) => this.changeTotalQuantityValue(value)}
                    ></InputNumber>
                </div>
            );
        }
        const tip = language.getText('rsjkczsr');
        return (
            <div>
                <div>
                    <InputNumber
                        style={{ width: '150px' }}
                        disabled={true}
                        value={value.totalQuantity}
                    ></InputNumber>
                </div>
                <div
                    style={{
                        margin: '30px 0 0px -103px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >
                    <span>{language.getText('zjjskc')}：</span>
                    <InputNumber
                        placeholder={language.getText('stockRequired')}
                        style={{ width: '150px' }}
                        value={value.changeStock}
                        max={999999999}
                        precision={0}
                        onChange={(value: any) => this.changeValue(value)}
                    ></InputNumber>
                    <div style={{ margin: '0px 0 0 103px', color: '#ccc', lineHeight: '24px' }}>
                        {tip}
                    </div>
                </div>
                <div
                    style={{
                        margin: '25px 0 0px -71px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >
                    <span>{language.getText('sykc')}：</span>
                    <InputNumber
                        style={{ width: '150px' }}
                        disabled={true}
                        value={value.availableStock}
                    ></InputNumber>
                </div>
            </div>
        );
    }
}
