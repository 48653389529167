import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex, map, isEqual, isEmpty } from 'lodash';
import { ProductSelector } from '../../../components';
import { SalesPlansProductSelectedDisplay } from '../sales-plans-product-selected-display';
/*
 *数据选择类型
 */
enum RowSelectionType {
    /**
     * 复选
     */
    checkbox = 'checkbox',
    /**
     * 单选
     */
    radio = 'radio',
}
/**
 * 销售选择商品
 */
export class SalesPlansProductSelectProduct extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
    row: any;
}> {
    state = {
        value: [],
    };
    getSubsiteIds = (props: any) => {
        const { row } = props;
        let subsites = row.subsites;
        if (row.ruleInfo && row.ruleInfo.subsites) {
            subsites = row.ruleInfo.subsites;
        }
        const subsiteIds = subsites ? map(subsites, 'id') : null;
        return subsiteIds;
    };
    componentWillReceiveProps(nextProps: any) {
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (
            !isEqual(nextSubsiteIds, currentSubsiteIds) &&
            nextSubsiteIds &&
            !isEmpty(nextProps.value)
        ) {
            this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }

        const nextRowSelectionType = this.getRowSelectionType(nextProps);
        const currentRowSelectionType = this.getRowSelectionType(this.props);
        if (
            !nextProps.row.id &&
            currentRowSelectionType === RowSelectionType.checkbox &&
            nextRowSelectionType === RowSelectionType.radio &&
            !isEmpty(nextProps.value)
        ) {
            this.onChange([]);
        }
    }

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        forEach(value, (i) => {
            if (subsiteIds.indexOf(i.subsiteId + '') > -1 || subsiteIds.indexOf(i.subsiteId) > -1) {
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteId: goods.subsiteId + '',
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        salesPrice: (+goods.salesPrice.value).toFixed(2),
                        goodsStock: goods.stock,
                        stock: null,
                    });
                });
            });
        }
        return items;
    };

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        forEach(goods, (i) => {
            i.profit = (i.groupBuyPrice - i.groupPrice).toFixed(2);
        });
        onChange(goods);
    };

    onSelect = (values: any[]) => {
        const data = this.processSelectedData(values);
        const rowSelectionType = this.getRowSelectionType(this.props);
        let newData: any[] = [];
        if (rowSelectionType === RowSelectionType.radio) {
            newData = data;
        } else {
            const { value } = this.props;
            newData = cloneDeep(value || []);
            data.forEach((i: any) => {
                const hasIndex = findIndex(value, (o: any) => o.id === i.id);
                if (hasIndex === -1) {
                    newData.unshift(i);
                }
            });
        }
        this.onChange(newData);
    };

    getRowSelectionType = (props: any) => {
        const { row } = props;
        const extraActivityMode = row.extraActivityMode;
        if (extraActivityMode && extraActivityMode.length > 0) {
            return RowSelectionType.radio;
        } else {
            return RowSelectionType.checkbox;
        }
    };

    render() {
        const { value } = this.props;
        const subsiteIds = this.getSubsiteIds(this.props);
        const hasValue = value && value.length > 0;
        const rowSelectionType = this.getRowSelectionType(this.props);
        return (
            <div className="cgb-select-product">
                <ProductSelector
                    onChange={this.onSelect}
                    rowSelectionType={rowSelectionType}
                    params={{ subsiteIds: subsiteIds }}
                />
                {hasValue && (
                    <SalesPlansProductSelectedDisplay
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                    ></SalesPlansProductSelectedDisplay>
                )}
            </div>
        );
    }
}
