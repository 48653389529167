import { services } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { get } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { WechatOfficialAccount } from '.';

interface Props {
    entity: Entity;
    subsiteIdPath: string;
    placeholder?: string;
    onChange(wechatOfficialAccount?: WechatOfficialAccount): void;
    value?: WechatOfficialAccount;
    row: any;
}
export default function AutoCompleteAppidBySubsiteControl(props: Props) {
    const { subsiteIdPath, onChange, row, placeholder = '' } = props;
    const subsiteId = get(row, subsiteIdPath);
    const [appName, setAppName] = useState('');
    const [style, setStyle] = useState<CSSProperties>();
    useEffect(() => {
        if (subsiteId) {
            services.api
                .get(
                    { subsiteId },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                    }
                )
                .then((res: any) => {
                    if (res?.appName) {
                        setAppName(res.appName);
                        setStyle({});
                    } else {
                        setAppName('暂未关联公众号');
                        setStyle({ color: '#f5222d' });
                    }
                    if (res) {
                        onChange({ name: res.appName, appId: res.appId });
                    } else {
                        onChange();
                    }
                });
        } else {
            setAppName('');
            setStyle({ color: '#999' });
            onChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subsiteId]);

    return <div style={style}>{appName || placeholder}</div>;
}
