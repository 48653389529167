import React, { Component, createElement } from 'react';
import { Checkbox, Button, message, Col, Row, Icon, Modal, Popover } from 'antd';
import { services } from '@comall-backend-builder/core';
import { copyToClipboard } from '../../services/utils';
import { ComponentsManager } from '@comall-backend-builder/core';
import { PaymentRemittanceRecordVO } from '../payment-remittance-order-list-item';

import './index.less';

const CheckboxGroup = Checkbox.Group;
const { api } = services;
interface PaymentRemittancePendingRecordState {
    checkedList: string[];
    indeterminate: boolean;
    checkAll: boolean;
    remittanceVisible: boolean;
    pendingRemittanceInfo: PendingRemittanceInfo;
}
interface PendingRemittanceInfo {
    overseasRemittanceRecordIds: string[];
    paidEndDate: string;
    paidStartDate: string;
    totalAmount: number;
    totalQuantity: number;
}

/**
 * 汇款订单明细-待汇款
 */
export class PaymentRemittancePendingRecord extends Component<
    any,
    PaymentRemittancePendingRecordState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            checkedList: [],
            indeterminate: false,
            checkAll: false,
            remittanceVisible: false,
            pendingRemittanceInfo: {
                overseasRemittanceRecordIds: [],
                paidEndDate: '',
                paidStartDate: '',
                totalAmount: 0,
                totalQuantity: 0,
            },
        };
    }
    componentDidMount(): void {
        this.loadData();
    }

    loadData = () => {
        const { entity, params } = this.props;
        entity.filtersChange({
            status: params.remittanceStatus || 'PENDING',
        });
        entity.search();
    };

    /**
     * 全选/取消勾选
     */
    onCheckAllChange = (e: any) => {
        const { entity } = this.props;
        const checkedList = entity.result.map((comment: { id: string }) => {
            return comment.id;
        });
        this.setState({
            checkedList: e.target.checked ? checkedList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };
    /**
     * 勾选/取消
     * @param e
     */
    onCheckboxChange = (checkedList: any) => {
        const { entity } = this.props;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < entity.result.length,
            checkAll: checkedList.length === entity.result.length,
        });
    };

    /**
     * 页数改变清空选中评论
     */
    changeCommentsPage = () => {
        this.setState({
            checkedList: [],
        });
    };
    batchReviewComment = () => {
        const { entity } = this.props;
        const { checkedList } = this.state;
        if (!checkedList || !checkedList.length) {
            message.info(services.language.getText('qxzplczddd'));
            return;
        }
        let totalAmount = 0;
        let paidStartDate = '';
        let paidEndDate = '';
        checkedList.forEach((entityItem) => {
            const res = entity.result.find((e: PaymentRemittanceRecordVO) => e.id === entityItem);
            totalAmount += Number(res.remittanceAmount);
            if (!paidEndDate && !paidStartDate) {
                paidStartDate = res.paidTime;
                paidEndDate = res.paidTime;
            } else if (res.paidTime) {
                const paidTime = new Date(res.paidTime).getTime();
                const startTime = new Date(paidStartDate).getTime();
                const endTime = new Date(paidEndDate).getTime();
                //获得最早的开始时间
                if (startTime > paidTime) {
                    paidStartDate = res.paidTime;
                }
                //获得最晚的结束时间
                if (endTime < paidTime) {
                    paidEndDate = res.paidTime;
                }
            }
        });

        this.setState({
            remittanceVisible: true,
            pendingRemittanceInfo: {
                overseasRemittanceRecordIds: checkedList,
                paidStartDate: paidStartDate.split(' ')[0],
                paidEndDate: paidEndDate.split(' ')[0],
                totalAmount: totalAmount,
                totalQuantity: checkedList.length,
            },
        });
    };

    getFilterConfig = () => {
        const { entity } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        this.setState(
                            {
                                checkedList: [],
                                indeterminate: false,
                                checkAll: false,
                            },
                            () => {
                                this.loadData();
                            }
                        );
                    },
                    fields: [
                        { property: 'subsiteIds' },
                        { property: 'payTime' },
                        { property: 'remittanceTime' },
                        { property: 'orderNo' },
                        { property: 'outOrderNo' },
                    ],
                },
            ],
        };
    };

    getExportFilterConfig = () => {
        return {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: services.language.getText('exportSearchResult'),
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/overseas_remittance_records/export',
                        requestMethod: 'post',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        };
    };

    onCopyOrderNo = (orderNo: any) => {
        copyToClipboard(orderNo);
    };
    private onOk = () => {
        const { pendingRemittanceInfo } = this.state;
        this.setState({
            remittanceVisible: false,
        });
        api.post(
            {
                overseasRemittanceRecordIds: pendingRemittanceInfo.overseasRemittanceRecordIds,
                paidStartDate: pendingRemittanceInfo.paidStartDate,
                paidEndDate: pendingRemittanceInfo.paidEndDate,
            },
            {
                apiPath: '/admin/overseas_remittance',
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            }
        ).then(
            () => {
                this.setState({
                    remittanceVisible: false,
                    pendingRemittanceInfo: {
                        overseasRemittanceRecordIds: [],
                        paidEndDate: '',
                        paidStartDate: '',
                        totalAmount: 0,
                        totalQuantity: 0,
                    },
                });
                message.success(services.language.getText('applicationSubmitted'));
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    private onClose = () => {
        this.setState({
            remittanceVisible: false,
        });
    };
    renderItem = (item: PaymentRemittanceRecordVO) => {
        const {
            orderNo,
            outOrderNo,
            paidTime,
            remittanceStatus,
            remittanceAmount,
            subsite,
            applyRemittanceTime,
            remark,
        } = item;

        const getRemittanceStateLabel: any = {
            PENDING: services.language.getText('dcl'),
            PROCESSING: services.language.getText('clz'),
            SUCCESS: services.language.getText('success'),
            FAIL: services.language.getText('sb_2'),
        };
        return (
            <div style={{ overflow: 'hidden', width: '100%' }}>
                <Col>
                    <Row
                        gutter={[24, 24]}
                        style={{ backgroundColor: '#eee' }}
                        type="flex"
                        align="middle"
                    >
                        <Col span={8}>
                            <Checkbox value={item.id} className="comment-item__checkbox" />
                            {services.language.getText('orderNumber')}:
                            <Button type="link" onClick={this.onCopyOrderNo.bind(this, orderNo)}>
                                {orderNo}
                                <Icon type="copy" />
                            </Button>
                        </Col>
                        <Col span={8}>
                            {services.language.getText('wbddh')}:
                            {outOrderNo ? (
                                <Button
                                    type="link"
                                    onClick={this.onCopyOrderNo.bind(this, orderNo)}
                                >
                                    {outOrderNo}
                                    <Icon type="copy" />
                                </Button>
                            ) : null}
                        </Col>
                        <Col span={4}>
                            {services.language.getText('subsites')}: {subsite?.subsiteName}
                        </Col>
                        <Col span={4}>
                            {services.language.getText('hkzt')}:{' '}
                            {getRemittanceStateLabel[remittanceStatus]}
                            {remark && remittanceStatus === 'FAIL' ? (
                                <Popover content={remark}>
                                    &nbsp;&nbsp;
                                    <Icon type="question-circle" />
                                </Popover>
                            ) : null}
                        </Col>
                    </Row>
                    <Row
                        gutter={[24, 24]}
                        type="flex"
                        align="middle"
                        style={{ backgroundColor: '#fff' }}
                    >
                        <Col span={8}>
                            {services.language.getText('zfsj')}: {paidTime}
                        </Col>
                        <Col span={8}>
                            {services.language.getText('hkje')}: {remittanceAmount}
                        </Col>
                        <Col span={8}>
                            {services.language.getText('remittanceTime')}: {applyRemittanceTime}
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    };
    render() {
        const { entity } = this.props;
        const { remittanceVisible, pendingRemittanceInfo } = this.state;
        // const config = this.getFilterConfig();
        // const filterContent = createElement(ComponentsManager.get(config.component), config);
        // const configExport = this.getExportFilterConfig();
        // const ExportfilterContent = createElement(
        //     ComponentsManager.get(configExport.component),
        //     configExport
        // );
        const dataListProps = {
            entity: entity,
            component: 'DataList',
            renderItem: this.renderItem,
            loadFirstPage: false,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: any) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            onPageChange: this.changeCommentsPage,
        };
        const dataListContent = createElement(
            ComponentsManager.get(dataListProps.component),
            dataListProps
        );
        return (
            <div className="remittance-pending-comments">
                {/* {filterContent} */}
                <div className="comment-actions">
                    <Checkbox
                        className="all-delivery-checkbox"
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        {services.language.getText('qx_1')}
                    </Checkbox>
                    &nbsp;&nbsp;
                    <Button
                        className="batch-delivery-button"
                        type="primary"
                        icon="plus"
                        onClick={() => {
                            this.batchReviewComment();
                        }}
                    >
                        {services.language.getText('sqhk')}
                    </Button>
                </div>
                <div className="comment-list">
                    <CheckboxGroup value={this.state.checkedList} onChange={this.onCheckboxChange}>
                        {dataListContent}
                    </CheckboxGroup>
                </div>
                <Modal
                    visible={remittanceVisible}
                    title={services.language.getText('remittance')}
                    closable={false}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.onOk}>
                            {services.language.getText('common.ok')}
                        </Button>,
                        <Button key="back" onClick={this.onClose}>
                            {services.language.getText('common.cancel')}
                        </Button>,
                    ]}
                >
                    <p>
                        {services.language.getText('determineOn')}
                        {pendingRemittanceInfo.paidStartDate && pendingRemittanceInfo.paidEndDate
                            ? `${pendingRemittanceInfo.paidStartDate} ～ ${pendingRemittanceInfo.paidEndDate}`
                            : '-'}
                        ，{services.language.getText('remittance')}
                        {pendingRemittanceInfo.totalQuantity}
                        {services.language.getText('remittanceIndividualOrders')}
                    </p>
                    <p>
                        {services.language.getText('totalAmountRemitted')}：
                        {pendingRemittanceInfo.totalAmount
                            ? pendingRemittanceInfo.totalAmount.toFixed(2)
                            : 0}
                        {services.language.getText('yuan')}
                    </p>

                    <div className={'comment-list__tips'}>
                        {services.language.getText('hktjcghjddyxcl')}
                    </div>
                    <div className={'comment-list__tips'} style={{ color: 'red' }}>
                        {services.language.getText('sqtjbkch')}
                    </div>
                </Modal>
            </div>
        );
    }
}
