import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { remove, cloneDeep, map, find, set, debounce } from 'lodash';
import { Button, Modal, Input, Popover, Table, Select, InputNumber, message } from 'antd';
import './index.less';

const { api } = services;
const { Search } = Input;

interface BigCodeItem {
    code: string;
    codeName?: string;
    id: string;
    name?: string;
    /**
     * 是否支持开票
     */
    status: string;
    /**
     * 税收分类编码
     */
    taxCode?: string;
    /**
     * 税率
     */
    taxRate?: number;
    /**
     * 税收分类免税类型
     */
    taxRateType?: TaxRateType;
}

enum TaxRateType {
    // 标准税率
    Standard = 'STANDARD',
    // 免税
    DutyFree = 'DUTY_FREE',
    // 不征税
    NoTax = 'NO_TAX',
    // 零税率
    ZeroRate = 'ZERO_RATE',
    // 出口退税
    ExportTaxRebate = 'EXPORT_TAX_REBATE',
}

const taxRateOptions = [
    { id: TaxRateType.Standard, name: '标准税率' },
    { id: TaxRateType.DutyFree, name: '免税' },
    { id: TaxRateType.NoTax, name: '不征税' },
    { id: TaxRateType.ZeroRate, name: '零税率' },
    { id: TaxRateType.ExportTaxRebate, name: '出口退税' },
];

interface MerchantBigCodesModalState {
    visible: boolean;
    inputCode: string;
    popoverVisible: boolean;
    datasource: BigCodeItem[];
    selectedRows: BigCodeItem[];
    addCodeValue: string;
}
/**
 * @deprecated
 * 组件不再使用，组件内部调用的MAGIC-SHOP服务接口都已经废弃
 */
export class MerchantBigCodesModal extends PureComponent<any, MerchantBigCodesModalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            inputCode: '',
            popoverVisible: false,
            datasource: [],
            selectedRows: [],
            addCodeValue: '',
        };
    }

    private loadData = async () => {
        const { merchantId } = this.props;
        const { inputCode } = this.state;
        try {
            const res: BigCodeItem[] = await api.get(
                {
                    merchantId,
                    merchantBigCode: inputCode,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                    apiPath: '/admin/merchant_category_code_tax_rates',
                }
            );
            this.setState({
                datasource: map(res, (i: BigCodeItem) => {
                    i.status = i.status ? 'true' : 'false';
                    return i;
                }),
                selectedRows: [],
            });
        } catch (error) {
            throw error;
        }
    };

    private toggleModal = () => {
        const { visible } = this.state;
        if (!visible) {
            this.loadData();
        }
        this.setState({
            visible: !visible,
        });
    };

    private onOk = async () => {
        const { datasource } = this.state;
        const { merchantId } = this.props;
        if (datasource.some((item) => !this.verifyItem(item))) {
            return;
        }
        const codeData = map(datasource, (i: BigCodeItem) => {
            return {
                id: i.id,
                merchantBigCode: i.code,
                name: i.codeName || '',
            };
        });
        const taxRatesData: any[] = map(datasource, (i: BigCodeItem) => {
            return {
                id: i.id,
                status: i.status === 'true',
                name: i.status === 'true' ? i.name : '',
                taxCode: i.status === 'true' ? i.taxCode : '',
                taxRateType: i.taxRateType,
                taxRate:
                    i.status === 'true' && i.taxRateType === TaxRateType.Standard ? i.taxRate : '',
            };
        });
        try {
            await Promise.all([
                api.put(codeData, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                    apiPath: `/admin/merchant_big_code/${merchantId}`,
                }),
                api.post(taxRatesData, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                    apiPath: '/admin/merchant_category_code_tax_rates/batch',
                }),
            ]);
            this.setState({
                visible: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    private verifyItem = (item: BigCodeItem) => {
        if (item.status === 'true') {
            if (!item.name) {
                message.warning('请输入开票名称');
                return false;
            }
            if (!item.taxCode) {
                message.warning('请输入税收分类编码');
                return false;
            }
            if (!item.taxRateType) {
                message.warning('请选择商品税率类型');
                return false;
            }
            if (item.taxRateType === TaxRateType.Standard && !item.taxRate) {
                message.warning('请输入税率');
                return false;
            }
        }
        return true;
    };

    private onChangCode = (e: any) => {
        this.setState({
            inputCode: e.target.value,
        });
        return e.target.value;
    };

    private onReset = () => {
        this.setState(
            {
                inputCode: '',
            },
            () => {
                this.loadData();
            }
        );
    };

    private onAddCodeChange = (e: any) => {
        const { value } = e.target;
        this.setState({
            addCodeValue: value,
        });
        return value;
    };

    private renderHandle = () => {
        const { popoverVisible, addCodeValue } = this.state;
        const content = (
            <div>
                <Search
                    placeholder="请输入商家码"
                    enterButton="添加"
                    value={addCodeValue}
                    maxLength={100}
                    onChange={this.onAddCodeChange}
                    onSearch={debounce(this.onAddCode, 300)}
                />
            </div>
        );
        return (
            <div className="merchant-big-codes-modal-handle">
                <Popover
                    placement="right"
                    content={content}
                    arrowPointAtCenter
                    trigger="click"
                    visible={popoverVisible}
                    onVisibleChange={this.handleVisibleChange}
                    overlayStyle={{ zIndex: 1000 }}
                >
                    <Button type="primary">添加</Button>
                </Popover>
            </div>
        );
    };

    private handleVisibleChange = (visible: boolean) => {
        this.setState({
            popoverVisible: visible,
        });
    };

    private onAddCode = async () => {
        const { addCodeValue } = this.state;
        const { merchantId } = this.props;
        try {
            await api.post(
                {
                    merchantId,
                    merchantBigCode: addCodeValue,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                    apiPath: '/admin/merchant_big_code',
                }
            );
            this.loadData();
            this.setState({
                popoverVisible: false,
                addCodeValue: '',
            });
        } catch (error) {
            throw error;
        }
    };

    private onTableRowSelect = (record: BigCodeItem, selected: boolean) => {
        const { selectedRows } = this.state;
        const currentRows = cloneDeep(selectedRows);
        if (selected) {
            if (find(currentRows, { id: record.id })) {
                return;
            }
            currentRows.push(record);
        } else {
            remove(currentRows, (i) => {
                return i.id === record.id;
            });
        }
        this.setState({
            selectedRows: currentRows,
        });
    };

    private onTableRowSelectAll = (selected: boolean, rows: BigCodeItem[]) => {
        if (selected) {
            this.setState({
                selectedRows: rows,
            });
        } else {
            this.setState({
                selectedRows: [],
            });
        }
    };

    private onBatchDelete = () => {
        const { selectedRows } = this.state;
        this.onDelete(map(selectedRows, 'id'));
    };

    private onDelete = async (ids: string[]) => {
        try {
            const promiseList: Promise<any>[] = [];
            ids.forEach((id: string) => {
                promiseList.push(
                    api.delete(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                            apiPath: `/admin/merchant_category_code_tax_rates/${id}`,
                        }
                    )
                );
                promiseList.push(
                    api.delete(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                            apiPath: `/admin/merchant_big_code/${id}`,
                        }
                    )
                );
            });
            await Promise.all(promiseList);
            this.loadData();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    onChange = (value: string | number | undefined, rowId: string, fields: keyof BigCodeItem) => {
        const { datasource } = this.state;
        const newValues = cloneDeep(datasource);
        const row = newValues.find((item) => item.id === rowId);
        if (!row) return;
        set(row, fields, value);
        if (fields === 'taxRateType' && value !== TaxRateType.Standard) {
            set(row, 'taxRate', undefined);
        }
        if (fields === 'status' && value === 'true' && !row.taxRateType) {
            set(row, 'taxRateType', TaxRateType.Standard);
        }
        this.setState({ datasource: newValues });
    };

    private getTableProps = () => {
        const { datasource, selectedRows } = this.state;
        return {
            dataSource: datasource,
            pagination: false as const,
            scroll: {
                y: 400,
                x: true,
            },
            rowKey: 'id',
            rowSelection: {
                type: 'checkbox' as const,
                selectedRowKeys: map(selectedRows, 'id') as string[],
                onChange: () => {},
                onSelect: (record: BigCodeItem, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: BigCodeItem[]) => {
                    this.onTableRowSelectAll(selected, rows);
                },
            },
            columns: [
                {
                    title: services.language.getText('bigCode'),
                    dataIndex: 'code',
                    key: 'code',
                    width: '150px',
                    align: 'center' as const,
                },
                {
                    title: '商家码名称',
                    dataIndex: 'codeName',
                    key: 'codeName',
                    width: '200px',
                    align: 'center' as const,
                    render: (value: string, record: BigCodeItem) => {
                        return (
                            <Input
                                style={{ width: 150 }}
                                value={value}
                                maxLength={100}
                                onChange={(e) =>
                                    this.onChange(e.target.value, record.id, 'codeName')
                                }
                            />
                        );
                    },
                },
                {
                    title: '是否支持开票',
                    dataIndex: 'status',
                    key: 'status',
                    width: '100px',
                    align: 'center' as const,
                    render: (value: string, record: BigCodeItem) => {
                        return (
                            <Select
                                style={{ width: 90 }}
                                value={value}
                                onSelect={(newValue: string) =>
                                    this.onChange(newValue, record.id, 'status')
                                }
                            >
                                <Select.Option value="true">是</Select.Option>
                                <Select.Option value="false">否</Select.Option>
                            </Select>
                        );
                    },
                },
                {
                    title: '开票名称',
                    dataIndex: 'name',
                    key: 'name',
                    width: '200px',
                    align: 'center' as const,
                    render: (value: string, record: BigCodeItem) => {
                        const disabled = record.status === 'false';
                        return (
                            <Input
                                maxLength={100}
                                style={{ width: 150 }}
                                value={disabled ? undefined : value}
                                disabled={disabled}
                                onChange={(e) => this.onChange(e.target.value, record.id, 'name')}
                            />
                        );
                    },
                },
                {
                    title: '税收分类编码',
                    dataIndex: 'taxCode',
                    key: 'taxCode',
                    width: '260px',
                    align: 'center' as const,
                    render: (value: string, record: BigCodeItem) => {
                        const disabled = record.status === 'false';
                        return (
                            <Input
                                style={{ width: 220 }}
                                value={disabled ? undefined : value}
                                maxLength={19}
                                disabled={disabled}
                                onChange={(e) =>
                                    this.onChange(e.target.value, record.id, 'taxCode')
                                }
                            />
                        );
                    },
                },
                {
                    title: '商品税率类型',
                    dataIndex: 'taxRateType',
                    key: 'taxRateType',
                    width: '130px',
                    align: 'center' as const,
                    render: (value: string, record: BigCodeItem) => {
                        const disabled = record.status === 'false';
                        return (
                            <Select
                                style={{ width: 100 }}
                                value={disabled ? undefined : value}
                                disabled={disabled}
                                onSelect={(newValue: string) =>
                                    this.onChange(newValue, record.id, 'taxRateType')
                                }
                            >
                                {taxRateOptions.map((option) => (
                                    <Select.Option key={option.id}>{option.name}</Select.Option>
                                ))}
                            </Select>
                        );
                    },
                },
                {
                    title: '税率',
                    dataIndex: 'taxRate',
                    key: 'taxRate',
                    width: '120px',
                    align: 'center' as const,
                    render: (value: number, record: BigCodeItem) => {
                        const disabled =
                            record.status === 'false' ||
                            record.taxRateType !== TaxRateType.Standard;
                        return (
                            <div style={{ width: 100, display: 'flex', alignItems: 'center' }}>
                                <InputNumber
                                    value={disabled ? undefined : value}
                                    min={1}
                                    max={99}
                                    precision={0}
                                    disabled={disabled}
                                    onChange={(num) => this.onChange(num, record.id, 'taxRate')}
                                />
                                <span style={{ marginLeft: 5 }}>%</span>
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center' as const,
                    width: '100px',
                    render: (id: string, record: any) => {
                        return (
                            <Button type="link" onClick={this.onDelete.bind(this, [id])}>
                                {services.language.getText('common.delete')}
                            </Button>
                        );
                    },
                },
            ],
        };
    };

    render() {
        const { visible, inputCode } = this.state;
        const modalProps = {
            width: 900,
            title: services.language.getText('bigCode'),
            visible: visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const tableProps = this.getTableProps();
        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="link" onClick={this.toggleModal}>
                    查看/添加
                </Button>
                <Modal {...modalProps}>
                    <div className="merchant-big-codes-modal-header">
                        <span>商家码：</span>
                        <Input
                            onChange={this.onChangCode}
                            value={inputCode}
                            style={{ width: 120, marginRight: 10 }}
                        />
                        <Button type="primary" style={{ marginRight: 10 }} onClick={this.loadData}>
                            查询
                        </Button>
                        <Button onClick={this.onReset}>重置</Button>
                    </div>
                    {this.renderHandle()}
                    <div style={{ marginTop: 10 }}>
                        <Table {...tableProps}></Table>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Button onClick={this.onBatchDelete}>批量删除</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}
