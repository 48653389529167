import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import {
    Form,
    Button,
    Input,
    Select,
    Icon,
    Dropdown,
    Menu,
    Popover,
    message,
    Tooltip,
    InputNumber,
    Table,
    Pagination,
    Modal,
} from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { language, privilege } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, find, debounce } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import {
    MerchantSelfType,
    MerchantSelfStatus,
    MerchantSelfStatusValue,
} from '../../config/merchant/merchant-manager/merchant-self';
import { MerchantSelfPageImportButton, PageNewPreviewCodeButton } from '../../components';
import { ForwardDataCenterModal } from '../../services';
import './index.less';

const { api, navigation } = services;

enum ModeType {
    Preview = 'preview',
    List = 'list',
}

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            modeType: ModeType;
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
            onSubsiteChange: (id: string) => void;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        onChangeSubsite = (id: any) => {
            const { onSubsiteChange } = this.props as any;
            onSubsiteChange(id);
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            const defaultValue = subsites && subsites.length > 0 ? subsites[0].id : undefined;
            return (
                <Form layout="inline" ref="searchFrom">
                    <div>
                        <Form.Item label={language.getText('subsites')}>
                            {getFieldDecorator('subsiteIds', {
                                initialValue: defaultValue,
                            })(
                                <Select
                                    className="find-filter-width"
                                    showSearch
                                    placeholder={services.language.getText('selectPlease')}
                                    optionFilterProp="name"
                                    filterOption={(input, option: any) =>
                                        (option.props.children || '')
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={this.onChangeSubsite}
                                >
                                    {subsites &&
                                        subsites.map((subsite: any) => {
                                            return (
                                                <Select.Option key={subsite.id} value={subsite.id}>
                                                    {subsite.name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            )}
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label={language.getText('merchantName')}>
                            {getFieldDecorator('name')(
                                <Input
                                    className="find-filter-width"
                                    placeholder={language.getText('merchantNameRequired')}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={language.getText('merchantCode')}>
                            {getFieldDecorator('code')(
                                <Input
                                    className="find-filter-width"
                                    placeholder={language.getText('merchantCodeRequired')}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={language.getText('merchantType')}>
                            {getFieldDecorator('type')(
                                <Select
                                    className="find-filter-width"
                                    allowClear
                                    showSearch
                                    placeholder={services.language.getText('selectPlease')}
                                    filterOption={(input, option: any) =>
                                        (option.props.children || '')
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {merchantSelfTypeOptions.map((item: any) => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>
                                                {language.getText(item.name)}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.handleSearch}>
                                {language.getText('common.search')}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="reset" onClick={this.handleReset}>
                                {language.getText('common.reset')}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            );
        }
    }
);

export class MerchantSelfPageView extends PureComponent {
    state = {
        modeType: ModeType.Preview,
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        subsites: [],
        merchantFloors: [],
        currenttFloor: '',
        showEditSortId: '',
        editSortValue: undefined,
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            const defaultSubsite =
                subsites && (subsites as any[]).length > 0 ? (subsites as any[])[0] : undefined;
            this.setState({
                subsites,
            });
            this.getMerchantFloors(defaultSubsite?.id);
            this.onSearch({
                subsiteIds: defaultSubsite?.id,
            });
        });
    }

    /**
     * 获取门店楼层
     */
    private getMerchantFloors = (subsiteId: string) => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: `/WEB-API/admin/subsites/getFloors/${subsiteId}`,
            }
        ).then((res: any) => {
            const floors = (res || []).map((i: { id: string; name: string }) => ({
                id: i.id,
                name: i.name,
            }));
            this.setState({
                merchantFloors: floors,
                currenttFloor: '',
            });
        });
    };

    /**
     * 搜索
     */
    private onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination, currenttFloor, modeType } = this.state;
        try {
            let res: any = await api.get(
                {
                    ...searchParams,
                    ...pagination,
                    subsiteFloorId:
                        modeType === ModeType.Preview && currenttFloor ? currenttFloor : undefined,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                    apiPath: '/admin/merchants/page',
                }
            );
            if (res && res.result) {
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                    showEditSortId: '',
                });
            }
        } catch (error) {
            throw error;
        }
    };

    /**
     * 批量导出专柜信息
     */
    private onExportMerchant = () => {
        const { searchParams, currenttFloor, modeType } = this.state;
        api.get(
            {
                ...searchParams,
                subsiteFloorId:
                    modeType === ModeType.Preview && currenttFloor ? currenttFloor : undefined,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: '/admin/merchants/export',
            }
        )
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch((err) => {
                message.error(err.response.body.err_msg);
                throw err;
            });
    };

    /**
     * 筛选
     */
    private onSearchFind = (searchParams: any = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    /**
     * 切换门店
     */
    private onSubsiteChange = (id: string) => {
        const { searchParams } = this.state;
        this.getMerchantFloors(id);
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch({
                    ...searchParams,
                    subsiteIds: id,
                });
            }
        );
    };

    /**
     * 修改楼层
     */
    private onChangeFloor = (id: string) => {
        const { searchParams } = this.state;
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
                currenttFloor: id,
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    /**
     * 修改列表类型
     */
    private onChangeModeType = () => {
        const { modeType, searchParams } = this.state;
        this.setState(
            {
                modeType: modeType === ModeType.List ? ModeType.Preview : ModeType.List,
            },
            () => {
                this.onSearchFind(searchParams);
            }
        );
    };

    private pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };

    /**
     * 快速入口跳转
     */
    private onChangeQuickEntry = (item: any) => {
        const { key } = item;
        if (privilege.check(key, 'full')) {
            window.open(this.getOpenUrl(key));
        } else {
            if (key === 'merchantBigCodeManager') {
                message.warning(language.getText('nwqxszsjm'));
            } else {
                message.warning(services.language.getText('noPerationPermission'));
            }
        }
    };

    private getOpenUrl = (key: string) => {
        const { searchParams } = this.state;
        const subsiteId = (searchParams as any)?.subsiteIds;
        const search = subsiteId ? `?subsiteId=${subsiteId}` : '';
        if (key === 'merchantUser') {
            return `#/merchant-user${search}`;
        } else if (key === 'merchantProductInfoMananger') {
            return '#/merchantProductInfoMananger';
        } else if (key === 'merchantBigCodeManager') {
            return '#/merchant-big-codes';
        } else if (key === 'merchantGoodsManager') {
            return '#/merchantGoodsManager';
        } else if (key === 'market') {
            return '#/menu-list/market';
        } else if (key === 'merchantDeliveryFreightRule') {
            return `#/merchant-delivery${search}`;
        } else if (key === 'merchantBuyLimitRule') {
            return `#/merchant-buy-limit${search}`;
        }
    };

    private renderHandler = () => {
        const { modeType } = this.state;
        if (modeType === ModeType.List) {
            return (
                <div className="merchant-self-page-handle">
                    <div>
                        <Button
                            type="primary"
                            style={{
                                marginRight: 10,
                            }}
                            onClick={this.gotoAddPage}
                        >
                            <Icon type="plus" />
                            {language.getText('xzzj')}
                        </Button>
                    </div>
                    {this.renderHandlerRight()}
                </div>
            );
        }
        return (
            <div className="merchant-self-page-handle">
                {this.renderFloors()}
                {this.renderHandlerRight()}
            </div>
        );
    };

    private renderHandlerRight = () => {
        return (
            <div className="merchant-self-page-handle-right">
                {this.renderChangeModeIcon()}
                {this.renderBatchUpload()}
                {this.renderQuickEntry()}
            </div>
        );
    };

    private renderBatchUpload = () => {
        const { modeType } = this.state;
        const infoUploadProps: any = {
            triggerText: language.getText('pldrzjxx'),
            controlDownloadTemplate: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: `/admin/merchants/download_import_template.xlsx?type=BATCH_MERCHANT_INFO_TEMPLATE`,
            },
            controlUpload: {
                apiPath: '/admin/files/locations/700/upload',
            },
            showForwardDataCenterModal: true,
        };
        const picUploadProps: any = {
            triggerText: language.getText('pldrzjtp'),
            uploadText: language.getText('xzysb'),
            acceptList: ['.zip'],
            hideDownloadTemplate: true,
            controlUpload: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: '/admin/merchants/batch_upload_pic',
                fileName: 'multipartFile',
            },
            showForwardDataCenterModal: true,
            tips: [
                {
                    text: language.getText('drgz') + ':',
                },
                {
                    text: language.getText('picUploadTip.one'),
                },
                {
                    text: language.getText('picUploadTip.two'),
                },
                {
                    text: language.getText('picUploadTip.three'),
                },
                {
                    text: language.getText('picUploadTip.four'),
                },
                {
                    text: language.getText('picUploadTip.five'),
                },
                {
                    text: services.language.getText('zjfwtbServices1'),
                },
                {
                    text: services.language.getText('zjfwtbServices2'),
                },
            ],
        };
        /* const codeUploadProps: any = {
            triggerText: '批量导入商家码',
            controlDownloadTemplate: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: `/admin/merchants/download_import_template.xlsx?type=BATCH_MERCHANT_BIGCODE_TEMPLATE`,
            },
            controlUpload: {
                apiPath: '/admin/files/locations/600/upload',
            },
            showForwardDataCenterModal: true,
        }; */
        const menu = (
            <Menu>
                <Menu.Item>
                    <MerchantSelfPageImportButton {...infoUploadProps} />
                </Menu.Item>
                <Menu.Item>
                    <MerchantSelfPageImportButton {...picUploadProps} />
                </Menu.Item>
                {/* <Menu.Item>
                    <MerchantSelfPageImportButton {...codeUploadProps} />
                </Menu.Item> */}
                <Menu.Item>
                    <Button
                        type="link"
                        style={{ textAlign: 'center', color: 'rgb(0 0 0 / 65%)' }}
                        onClick={this.onExportMerchant}
                    >
                        {language.getText('pldczjxx')}
                    </Button>
                </Menu.Item>
            </Menu>
        );
        const size = modeType === ModeType.Preview ? 'small' : 'default';
        const iconStyle =
            modeType === ModeType.Preview
                ? {
                      fontSize: 10,
                  }
                : {
                      color: '#1890ff',
                  };
        const buttonStyle =
            modeType === ModeType.Preview
                ? undefined
                : {
                      borderColor: '#1890ff',
                      color: '#1890ff',
                  };
        return (
            <Dropdown overlay={menu}>
                <Button
                    size={size}
                    style={{
                        ...buttonStyle,
                        marginRight: 10,
                    }}
                >
                    {language.getText('batchAction')} <Icon type="caret-down" style={iconStyle} />
                </Button>
            </Dropdown>
        );
    };

    private renderChangeModeIcon = () => {
        const { modeType } = this.state;
        const iconClass = `merchant-self-page-handle-icon merchant-self-page-handle-icon-${modeType}`;
        return (
            <Tooltip
                arrowPointAtCenter
                title={`${language.getText('merchant')}${
                    modeType === ModeType.Preview
                        ? language.getText('lbst')
                        : language.getText('dtms')
                }`}
            >
                <div className={iconClass} onClick={this.onChangeModeType}></div>
            </Tooltip>
        );
    };

    private renderQuickEntry = () => {
        const { modeType } = this.state;
        const menu = (
            <Menu onClick={this.onChangeQuickEntry}>
                <Menu.Item key="merchantUser">{language.getText('merchantUser')}</Menu.Item>
                <Menu.SubMenu title={language.getText('merchantProduct')}>
                    <Menu.Item key="merchantProductInfoMananger">
                        {language.getText('productinfo')}
                    </Menu.Item>
                    <Menu.Item key="merchantGoodsManager">
                        {language.getText('merchantGoodsManager')}
                    </Menu.Item>
                    <Menu.Item key="merchantBigCodeManager">
                        {language.getText('merchantBigCodeManager')}
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu title={language.getText('promotion')}>
                    <Menu.Item key="market">{language.getText('marketActivity')}</Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu title={language.getText('delivery')}>
                    <Menu.Item key="merchantDeliveryFreightRule">
                        {language.getText('merchantDelivery')}
                    </Menu.Item>
                    <Menu.Item key="merchantBuyLimitRule">
                        {language.getText('merchantBuyLimitRule')}
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        );
        const size = modeType === ModeType.Preview ? 'small' : 'default';
        const buttonStyle =
            modeType === ModeType.Preview
                ? undefined
                : {
                      borderColor: '#1890ff',
                  };
        const iconStyle =
            modeType === ModeType.Preview
                ? {
                      color: '#1890ff',
                      fontSize: 10,
                  }
                : {
                      color: '#1890ff',
                  };
        return (
            <Dropdown overlay={menu}>
                <Button
                    size={size}
                    style={{
                        ...buttonStyle,
                        color: '#1890ff',
                        marginRight: 10,
                    }}
                >
                    {language.getText('ksrk')} <Icon type="caret-down" style={iconStyle} />
                </Button>
            </Dropdown>
        );
    };

    private renderFloors = () => {
        const { merchantFloors, currenttFloor } = this.state;
        const allClassNames = classnames('merchant-self-page-handle-floors-item', {
            'merchant-self-page-handle-floors-item-active': currenttFloor === '',
        });
        return (
            <div className="merchant-self-page-handle-floors">
                <div className={allClassNames} onClick={this.onChangeFloor.bind(this, '')}>
                    {language.getText('all')}
                </div>
                <div className="merchant-self-page-handle-scroll">
                    {merchantFloors.map((item: any, index: number) => {
                        const classNames = classnames('merchant-self-page-handle-floors-item', {
                            'merchant-self-page-handle-floors-item-active':
                                currenttFloor === item.id,
                        });
                        return (
                            <div
                                className={classNames}
                                key={`${index}-${item.id}`}
                                onClick={this.onChangeFloor.bind(this, item.id)}
                            >
                                {item.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    private getTableColumns = (): ColumnProps<any>[] => {
        return [
            {
                title: services.language.getText('merchantCode'),
                key: 'code',
                dataIndex: 'code',
            },
            {
                title: services.language.getText('merchantName'),
                key: 'name',
                dataIndex: 'name',
                render: (name) => {
                    return (
                        <Tooltip arrowPointAtCenter title={name}>
                            <div className="ant-table-cell-ellipsis" style={{ width: 200 }}>
                                {name}
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                title: services.language.getText('sequence'),
                key: 'sequence',
                dataIndex: 'sequence',
            },
            {
                title: services.language.getText('merchantType'),
                dataIndex: 'type',
                key: 'type',
                render: (type) => <span>{this.getMerchantSelfType(type)}</span>,
            },
            {
                title: services.language.getText('merchantStatus'),
                dataIndex: 'status',
                key: 'status',
                render: (status) => {
                    let text = services.language.getText('kd_1');
                    if (status === MerchantSelfStatus.CLOSE) {
                        text = services.language.getText('bd');
                    } else if (status === MerchantSelfStatus.PAUSE) {
                        text = services.language.getText('zt_1');
                    } else if (status === MerchantSelfStatus.PREPARE) {
                        text = services.language.getText('subsiteStatusDisable');
                    }
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('createTimeStr'),
                key: 'createTime',
                dataIndex: 'createTime',
            },
            {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                dataIndex: 'id',
                key: 'id',
                fixed: 'right',
                width: 300,
                render: (val: any, record: any) => {
                    const customGetName = () => {
                        return `${record.subsiteName}-${record.name}-${record.code}`;
                    };
                    return (
                        <div>
                            <Button
                                size="small"
                                type="link"
                                onClick={this.godoEditPage.bind(this, val)}
                            >
                                {services.language.getText('common.edit')}
                            </Button>
                            <PageNewPreviewCodeButton
                                codeTypes="all"
                                codeModalType="item"
                                codePageType="storePage"
                                buttonTitle={language.getText('generalization')}
                                row={record}
                                customGetName={customGetName}
                                customParams={{
                                    source: 'MERCHANT_MANAGE',
                                    merchantIds: [record.id],
                                    statisticType: 'MERCHANT',
                                }}
                            />
                            {this.renderItemHandle(record.status, record.id, true)}
                            <Button
                                size="small"
                                type="link"
                                onClick={this.godoLogsPage.bind(this, val)}
                            >
                                {services.language.getText('common.log')}
                            </Button>
                        </div>
                    );
                },
            },
        ];
    };

    private renderMerchantList = () => {
        const { modeType, datasource, pagination, total, current } = this.state;
        const paginationCongid = {
            pageSize: pagination.perPage,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '15', '20'],
            total,
            current,
            showTotal(total: number) {
                return services.interpolate(services.language.getText('total'), {
                    total,
                });
            },
            onChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
        };
        if (modeType === ModeType.List) {
            const columns = this.getTableColumns();
            return (
                <Table
                    scroll={{ x: true }}
                    rowKey={(record: any, i: number) => `${record.id}-${i}`}
                    pagination={paginationCongid}
                    columns={columns}
                    dataSource={datasource}
                />
            );
        }
        return (
            <div>
                <div className="merchant-self-page-content-list">
                    {this.renderAddItem()}
                    {datasource.map(this.renderMerchantItem)}
                </div>
                <div className="merchant-self-page-content-pagination">
                    <Pagination {...paginationCongid} />
                </div>
            </div>
        );
    };

    private renderAddItem = () => {
        return (
            <div className="merchant-self-page-content-list-add" onClick={this.gotoAddPage}>
                <Icon type="plus" />
                <div style={{ marginTop: 10 }}>{language.getText('merchant')}</div>
            </div>
        );
    };

    private renderMerchantItem = (item: any, index: number) => {
        const { currenttFloor } = this.state;
        const propagatePic = require('./icon/merchant-propagate.png');
        const logoPic = require('./icon/merchant-logo.png');
        const statusBg = require('./icon/merchant-status-bg.png');
        const propagateStyle = {
            backgroundImage: `url(${item.propagatePic || propagatePic})`,
        };
        const statusBgStyle = {
            backgroundImage: `url(${statusBg})`,
        };
        const logoUrl = item.logoPic || logoPic;
        const showMask =
            item.status === MerchantSelfStatus.CLOSE ||
            item.status === MerchantSelfStatus.PAUSE ||
            item.status === MerchantSelfStatus.PREPARE;
        const itemKey = `${currenttFloor}-${item.id}-${index}`;
        const customGetName = () => {
            return `${item.subsiteName}-${item.name}-${item.code}`;
        };
        return (
            <div
                className="merchant-self-page-content-list-item"
                key={itemKey}
                onClick={this.godoEditPage.bind(this, item.id)}
            >
                <div
                    className="merchant-self-page-content-list-item__propagate"
                    style={propagateStyle}
                >
                    {showMask ? (
                        <div className="merchant-self-page-content-list-item__mask">
                            <div
                                className="merchant-self-page-content-list-item__mask__status"
                                style={statusBgStyle}
                            >
                                {this.getStatusText(item.status)}
                            </div>
                        </div>
                    ) : null}
                </div>
                <img className="merchant-self-page-content-list-item__logo" src={logoUrl} alt="" />
                <div className="merchant-self-page-content-list-item__info">
                    <div className="merchant-self-page-content-list-item__info__name">
                        <Tooltip arrowPointAtCenter title={item.name}>
                            <div className="merchant-self-page-content-list-item__info__text">
                                {item.name}
                            </div>
                        </Tooltip>
                        <div className="merchant-self-page-content-list-item__info__type">
                            {language.getText(this.getMerchantSelfType(item.type))}
                        </div>
                    </div>
                    <div className="merchant-self-page-content-list-item__info__row">
                        <div className="merchant-self-page-content-list-item__info__label">
                            {currenttFloor
                                ? language.getText('wz_2')
                                : language.getText('merchant')}
                            {language.getText('sequence')}
                        </div>
                        <div
                            className="merchant-self-page-content-list-item__info__value"
                            onClick={this.onStopPropagation}
                        >
                            {currenttFloor ? item.merchantFloorSequence : item.sequence}{' '}
                            {this.renderEditSort(item, index)}
                        </div>
                    </div>
                    <div className="merchant-self-page-content-list-item__info__row">
                        <div className="merchant-self-page-content-list-item__info__label">
                            {language.getText('merchantCode')}
                        </div>
                        <div className="merchant-self-page-content-list-item__info__value">
                            {item.code}
                        </div>
                    </div>
                    <div
                        className="merchant-self-page-content-list-item__info__row"
                        style={{ marginTop: 0 }}
                    >
                        <div className="merchant-self-page-content-list-item__info__label">
                            {language.getText('servicePhone')}
                        </div>
                        <div className="merchant-self-page-content-list-item__info__value">
                            {item.customerPhone}
                        </div>
                    </div>
                    <div className="merchant-self-page-content-list-item__info__tag">
                        {(item.serviceTags || []).map((item: string, index: number) => {
                            return (
                                <div
                                    className="merchant-self-page-content-list-item__info__tag__item"
                                    key={`${itemKey}-tag-${index}`}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    className="merchant-self-page-content-list-item__footer"
                    onClick={this.onStopPropagation}
                >
                    <div className="merchant-self-page-content-list-item__footer__decorate">
                        <Button
                            size="small"
                            type="link"
                            onClick={this.gotoDecorate.bind(this, item)}
                        >
                            {language.getText('decorate')}
                        </Button>
                        <div className="merchant-self-page-content-list-item__footer__line"></div>
                        <PageNewPreviewCodeButton
                            codeTypes="all"
                            codeModalType="item"
                            codePageType="storePage"
                            buttonTitle={language.getText('generalization')}
                            row={item}
                            customGetName={customGetName}
                            customParams={{
                                source: 'MERCHANT_MANAGE',
                                merchantIds: [item.id],
                                statisticType: 'MERCHANT',
                            }}
                        />
                    </div>
                    <div className="merchant-self-page-content-list-item__footer__handle">
                        {this.renderItemHandle(item.status, item.id)}
                    </div>
                </div>
            </div>
        );
    };

    private onStopPropagation = (e: any) => {
        e.stopPropagation();
    };

    private gotoDecorate = (item: any) => {
        const merchantName = item.name;
        if (privilege.check('merchantPageNew', 'full')) {
            window.open(`#/merchant-page?merchantName=${merchantName}`);
        } else {
            message.warning(services.language.getText('noPerationPermission'));
        }
    };

    private gotoAddPage = () => {
        const { searchParams, subsites } = this.state;
        const subsiteId = (searchParams as any)?.subsiteIds;
        const subsite: any = find(subsites, { id: subsiteId });
        navigation.goto(`/merchant-self/add?subsiteId=${subsiteId}&subsiteName=${subsite?.name}`);
    };

    private godoEditPage = (id: string) => {
        navigation.goto(`/merchant-self/edit/${id}`);
    };
    private godoLogsPage = (id: string) => {
        navigation.goto(`/merchant-self/logs/${id}`);
    };

    private renderItemHandle = (status: MerchantSelfStatus, id: string, isLink = false) => {
        // 开店按钮
        const OpenBtn = (
            <Button
                size="small"
                type={isLink ? 'link' : 'primary'}
                onClick={debounce(
                    this.onHandleStatus.bind(this, id, MerchantSelfStatusValue.OPEN),
                    300
                )}
            >
                {language.getText('kd_1')}
            </Button>
        );
        const CloseBtn = (
            <Button
                size="small"
                type={isLink ? 'link' : 'default'}
                onClick={debounce(
                    this.onShowModal.bind(this, id, MerchantSelfStatusValue.CLOSE),
                    300
                )}
            >
                {language.getText('bd')}
            </Button>
        );
        const PauseBtn = (
            <Button
                size="small"
                type={isLink ? 'link' : 'default'}
                onClick={debounce(
                    this.onShowModal.bind(this, id, MerchantSelfStatusValue.PAUSE),
                    300
                )}
            >
                {language.getText('zt_1')}
            </Button>
        );
        if (status === MerchantSelfStatus.PREPARE) {
            return (
                <>
                    {OpenBtn}
                    {CloseBtn}
                </>
            );
        } else if (status === MerchantSelfStatus.OPEN) {
            return (
                <>
                    {PauseBtn}
                    {CloseBtn}
                </>
            );
        } else if (status === MerchantSelfStatus.PAUSE) {
            return (
                <>
                    {OpenBtn}
                    {CloseBtn}
                </>
            );
        } else if (status === MerchantSelfStatus.CLOSE) {
            return (
                <>
                    {OpenBtn}
                    {PauseBtn}
                </>
            );
        }
    };

    private onShowModal = (id: string, type: MerchantSelfStatusValue) => {
        const content =
            type === MerchantSelfStatusValue.CLOSE
                ? language.getText('merchantSelfTitleType1')
                : language.getText('merchantSelfTitleType2');
        Modal.confirm({
            content,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: () => {
                this.onHandleStatus(id, type);
            },
        });
    };

    private onHandleStatus = (id: string, type: MerchantSelfStatusValue) => {
        const { searchParams } = this.state;
        api.put(
            {
                id: id,
                status: type,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: '/admin/merchants/status',
            }
        )
            .then(() => {
                this.onSearchFind(searchParams);
            })
            .catch((err) => {
                message.error(err.response.body.err_msg);
                throw err;
            });
    };

    private renderEditSort = (item: any, index: number) => {
        const { editSortValue, showEditSortId, currenttFloor } = this.state;
        const showEditSortKey = `${item.id}-${index}`;
        const content = (
            <div className="merchant-self-page-content-list-item__info__sort">
                {currenttFloor ? (
                    <div className="merchant-self-page-content-list-item__info__sort__text">
                        {language.getText('merchantPosition')}:{' '}
                        {[item.subsiteFloorName, item.merchantFloorAddress]
                            .filter((i) => Boolean(i))
                            .join('-')}
                    </div>
                ) : null}
                <div>
                    <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        value={editSortValue}
                        onChange={this.onChangeEditSort}
                    />
                </div>
                <div className="merchant-self-page-content-list-item__info__sort__item">
                    <Button size="small" onClick={this.onSortVisibleChange.bind(this, '')}>
                        {language.getText('common.cancel')}
                    </Button>
                    <Button
                        size="small"
                        type="primary"
                        style={{ marginLeft: 5 }}
                        onClick={this.onEditSortSubmit.bind(this, item)}
                    >
                        {language.getText('common.ok')}
                    </Button>
                </div>
            </div>
        );
        const iconStyle = {
            color: '#BABABA',
            fontsize: 12,
            cursor: 'pointer',
        };
        const visible = showEditSortId === showEditSortKey;
        return (
            <Popover
                placement="bottomLeft"
                content={content}
                arrowPointAtCenter
                trigger="click"
                visible={visible}
            >
                <Icon
                    type="edit"
                    style={iconStyle}
                    onClick={this.onSortVisibleChange.bind(this, showEditSortKey)}
                />
            </Popover>
        );
    };

    private onChangeEditSort = (value: any) => {
        this.setState({
            editSortValue: value,
        });
    };

    private onSortVisibleChange = (showEditSortId: string) => {
        this.setState({
            showEditSortId,
            editSortValue: undefined,
        });
    };

    private onEditSortSubmit = async (item: any) => {
        const { searchParams, editSortValue, currenttFloor } = this.state;
        try {
            await api.put(
                {
                    id: item.id,
                    sequence: editSortValue,
                    floorId: currenttFloor ? item.merchantFloorId : undefined,
                    sequenceType: currenttFloor ? 'MERCHANT_FLOOR_SEQUENCE' : 'MERCHANT_SEQUENCE',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                    apiPath: '/admin/merchants/sequence',
                }
            );
            this.setState({
                showEditSortId: '',
                editSortValue: undefined,
            });
            this.onSearchFind(searchParams);
        } catch (error) {
            throw error;
        }
    };

    private getStatusText = (status: MerchantSelfStatus) => {
        if (status === MerchantSelfStatus.CLOSE) {
            return language.getText('closeStoreIng');
        } else if (status === MerchantSelfStatus.PAUSE) {
            return language.getText('zt_1');
        } else if (status === MerchantSelfStatus.PREPARE) {
            return language.getText('subsiteStatusDisable');
        }
    };

    private getMerchantSelfType = (type: MerchantSelfType) => {
        const newTypes = cloneDeep(merchantSelfTypeOptions);
        newTypes.push({ id: MerchantSelfType.SELF, name: language.getText('selfOperated') });
        const name = find(newTypes, { id: type })?.name;
        return (name && language.getText(name)) || '';
    };

    render() {
        const { modeType, subsites } = this.state;
        return (
            <div className="merchant-self-page">
                <div className="merchant-self-page-filter">
                    <CollectionCreateForm
                        modeType={modeType}
                        onSearch={this.onSearchFind}
                        subsites={subsites}
                        onSubsiteChange={this.onSubsiteChange}
                    />
                </div>
                <div className="merchant-self-page-content">
                    {this.renderHandler()}
                    {this.renderMerchantList()}
                </div>
            </div>
        );
    }
}

const merchantSelfTypeOptions = [
    { id: MerchantSelfType.JOINT, name: 'joint' },
    { id: MerchantSelfType.MERCHANT_SELF, name: 'merchantSelf' },
    { id: MerchantSelfType.LEASE, name: 'lease' },
    { id: MerchantSelfType.LEAGUE, name: 'league' },
    { id: MerchantSelfType.OTHER, name: 'other' },
];
