import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        ExternalCouponSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/external_coupon_rules',
            filters: {
                code: {
                    type: 'string',
                    displayName: '<<qbm>>',
                    controlConfig: { placeholder: '<<qsrqbm>>' },
                },
                name: {
                    type: 'string',
                    displayName: '<<qlx>>',
                    controlConfig: { placeholder: '<<qsrqlx>>' },
                },
            },
            properties: {
                code: {
                    type: 'string',
                    displayName: '<<qbm>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<qlx>>',
                },
            },
        },
        BalanceCouponRulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/balance_coupon_rules',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                    controlConfig: { placeholder: '<<qsryhqmc>>' },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<couponTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.productInfo?.subsite?.length &&
                                        row.productInfo.subsite
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                        externalCoupon: {
                            type: 'object.external.coupon.choose',
                            displayName: '<<couponInfo2>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                showChoose: true,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<couponStartTime>>',
                            rules: [{ required: true, message: '<<qxzqsxsj>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        dynamicEffectivePeriod: {
                            type: 'number.tip',
                            displayName: '<<syyxq>>',
                            rules: [
                                {
                                    message: '<<dynamicEffectivePeriodRule>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value > 366 || value === 0)
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<btxzwsxjssj>>',
                                style: {
                                    width: 200,
                                },
                                addonbefore: '<<bindCoupon>>',
                                addonafter: '<<effectDay>>',
                                tip: '',
                            },
                            extra: '<<yxqazrtjs>>',
                        },
                        stock: {
                            type: 'object.couponRuleStock',
                            displayName: '<<stock>>',
                            rules: [{ required: true, message: '<<stockRequired>>' }],
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<mdsz>>',
                    properties: {
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true }],
                            defaultValue: [],
                            controlConfig: {
                                type: 'radio',
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<qgz>>',
                    properties: {
                        couponValue: {
                            type: 'number.price',
                            displayName: '<<mz_1>>',
                            displayConfig: { addonafter: '<<yuan>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<mzbkwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                        if (value && Number(value) >= 0.01 && reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<yuan>>',
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                    },
                                ],
                            },
                        },
                    },
                },
                useRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<sygz>>',
                    properties: {
                        usedChannelLimit: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<usedChannelLimit>>',
                            defaultValue: ['2'],
                            options: [
                                { id: '1', name: '<<xsscsy>>' },
                                { id: '2', name: '<<xxddhx>>' },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<yeqtp>>',
                    properties: {
                        couponPicture: {
                            displayName: '<<tppz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pzhzszwdyhqb>>',
                        },
                    },
                },
                instructions: {
                    type: 'object.subForm',
                    displayName: '<<instructions>>',
                    properties: {
                        info: {
                            type: 'string.text.paragraph',
                            displayName: '<<instructions>>',
                            rules: [{ required: true, whitespace: true, max: 2000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 2000,
                            },
                        },
                    },
                },
                info: {
                    type: 'object.couponInfo',
                    displayName: '<<couponInfo2>>',
                },
                externalCode: {
                    type: 'string',
                    displayName: '<<qbm>>',
                },
                value: {
                    type: 'string',
                    displayName: '<<me>>',
                },
                dynamicEffectivePeriod: {
                    type: 'string',
                    displayName: '<<syyxq>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<executeStatus>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
            },
        },
    },
    components: {
        BalanceCouponRulesView: {
            component: 'Viewport',
            entity: 'BalanceCouponRulesEntity',
        },
        BalanceCouponRulesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'BalanceCouponRulesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addCoupon>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/balance-coupon-rules/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'BalanceCouponRulesTable' },
            ],
        },
        BalanceCouponRulesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        BalanceCouponRulesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'info',
                    width: 360,
                },
                {
                    property: 'externalCode',
                },
                {
                    property: 'value',
                },
                {
                    property: 'dynamicEffectivePeriod',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 180,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/balance_coupon_rules/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/balance_coupon_rules/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/balance-coupon-rules/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/coupon/balance-coupon-rules/copy/copydata/{{row.id}}',
                    },
                ],
            },
        },
        BalanceCouponRulesAddPage: {
            component: 'Card',
            content: { component: 'BalanceCouponRulesAddForm' },
        },
        BalanceCouponRulesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'BalanceCouponRulesEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'productInfo.subsite' },
                { property: 'baseInfo.externalCoupon' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'ruleInfo.couponValue' },
                {
                    property: 'useRuleInfo.usedChannelLimit',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'pictureInfo.couponPicture' },
                { property: 'instructions.info' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const externalCoupon = entity.baseInfo.externalCoupon;
                if (!externalCoupon || !externalCoupon.code) {
                    AntMessage.warn(services.language.getText('qxzsfyeq'));
                    return false;
                }
                if (!externalCoupon.name) {
                    AntMessage.warn(services.language.getText('qtxqmc'));
                    return false;
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                if (isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BalanceCouponRulesEditPage: {
            component: 'Card',
            content: { component: 'BalanceCouponRulesEditForm' },
        },
        BalanceCouponRulesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'BalanceCouponRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'productInfo.subsite',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.externalCoupon',
                    controlConfig: {
                        showChoose: false,
                    },
                },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'ruleInfo.couponValue' },
                {
                    property: 'useRuleInfo.usedChannelLimit',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'pictureInfo.couponPicture' },
                { property: 'instructions.info' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const externalCoupon = entity.baseInfo.externalCoupon;
                if (!externalCoupon || !externalCoupon.code) {
                    AntMessage.warn(services.language.getText('qxzsfyeq'));
                    return false;
                }
                if (!externalCoupon.name) {
                    AntMessage.warn(services.language.getText('qtxqmc'));
                    return false;
                }
                // // 库存
                // const stock = entity.baseInfo.stock.maxCount;
                // // 需要填写库存
                // if (!stock) {
                //     AntMessage.warning(services.language.getText('kcsbkwk'));
                //     return false;
                // }
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                if (isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BalanceCouponRulesCopyPage: {
            component: 'Card',
            content: { component: 'BalanceCouponRulesCopyForm' },
        },
        BalanceCouponRulesCopyForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'BalanceCouponRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'productInfo.subsite' },
                {
                    property: 'baseInfo.externalCoupon',
                },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'ruleInfo.couponValue' },
                {
                    property: 'useRuleInfo.usedChannelLimit',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'pictureInfo.couponPicture' },
                { property: 'instructions.info' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const externalCoupon = entity.baseInfo.externalCoupon;
                if (!externalCoupon || !externalCoupon.code) {
                    AntMessage.warn(services.language.getText('qxzsfyeq'));
                    return false;
                }
                if (!externalCoupon.name) {
                    AntMessage.warn(services.language.getText('qtxqmc'));
                    return false;
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                if (isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/balance-coupon-rules',
                    component: 'BalanceCouponRulesView',
                    breadcrumbName: '<<balance>>',
                    privilege: {
                        path: 'balanceCouponRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'BalanceCouponRulesAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'BalanceCouponRulesEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:type/:id',
                            component: 'BalanceCouponRulesCopyPage',
                            breadcrumbName: '<<copy>>',
                        },
                        { path: '/', component: 'BalanceCouponRulesPage' },
                    ],
                },
            ],
        },
    ],
};
