import React, { Component } from 'react';
import { Checkbox, Input, Button, Modal } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';

const { TextArea } = Input;

export interface NotificatonContentValue {
    channel: boolean;
    content: string;
    agreement: boolean;
}
export interface ObjectNotificatonContentCheckboxTipState {
    hasMessageChannel: boolean;
    checkBoxDisabled: boolean;
    tip: string;
    isModalOpen: boolean;
}
const defaultValue: NotificatonContentValue = {
    channel: false,
    content: 'notificatonContentDefaultValue',
    agreement: false,
};

export class ObjectNotificatonContentCheckboxTip extends Component<
    any,
    ObjectNotificatonContentCheckboxTipState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasMessageChannel: false,
            checkBoxDisabled: true,
            tip: '',
            isModalOpen: false,
        };
    }
    componentDidMount() {
        this.getNotificatonConfig();
    }
    componentWillReceiveProps(nextProps: any) {
        const { hasMessageChannel } = this.state;
        if (hasMessageChannel && nextProps && nextProps.row && nextProps.row.baseInfo) {
            const currentSubsite = get(this.props, 'row.baseInfo.subsiteIds', []);
            const nextSubsite = get(nextProps, 'row.baseInfo.subsiteIds', []);
            const currentSubsiteIds = currentSubsite.map((i: any) => i.id);
            const nextSubsiteIds = nextSubsite.map((i: any) => i.id);
            if (!this.isEqualSubsiteIds(currentSubsiteIds, nextSubsiteIds)) {
                this.loadDisabled(nextProps);
            }
        }
    }

    isEqualSubsiteIds = (current: string[], next: string[]) => {
        let flag = true;
        if (current.length !== next.length) {
            flag = false;
        } else {
            current.forEach((id) => {
                if (!next.includes(id)) {
                    flag = false;
                }
            });
        }
        return flag;
    };

    loadDisabled = (props: any) => {
        const { hasMessageChannel } = this.state;
        const subsites = get(props, 'row.baseInfo.subsiteIds', []);
        if (hasMessageChannel) {
            if (subsites.length === 1) {
                this.setState({
                    checkBoxDisabled: false,
                    tip: '',
                });
            } else {
                this.setState(
                    {
                        checkBoxDisabled: true,
                        tip: '仅支持单门店设置推送短信消息',
                    },
                    () => {
                        this.setDisabledAndClearValue();
                    }
                );
            }
        } else {
            this.setState(
                {
                    checkBoxDisabled: true,
                    tip: '您还未开营销类短信服务，请联系客户经理开通后可使用此功能',
                },
                () => {
                    this.setDisabledAndClearValue();
                }
            );
        }
    };

    getNotificatonConfig = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/message_channel/hasMessageChannel`,
            }
        ).then((response: any) => {
            this.setState(
                {
                    hasMessageChannel: response,
                },
                () => {
                    this.loadDisabled(this.props);
                }
            );
        });
    };

    onChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentDefaultValue = {
            ...defaultValue,
            content: language.getText(defaultValue.content),
        };
        const currentValue = value || currentDefaultValue;
        onChange(
            {
                ...currentValue,
                channel: event.target.checked,
            },
            name
        );
    };

    onInputChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentDefaultValue = {
            ...defaultValue,
            content: language.getText(defaultValue.content),
        };
        const currentValue = value || currentDefaultValue;
        onChange(
            {
                ...currentValue,
                content: event.target.value,
            },
            name
        );
    };

    onAgreementChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentDefaultValue = {
            ...defaultValue,
            content: language.getText(defaultValue.content),
        };
        const currentValue = value || currentDefaultValue;
        onChange(
            {
                ...currentValue,
                agreement: event.target.checked,
            },
            name
        );
    };

    onClickLink = () => {
        this.setState({
            isModalOpen: true,
        });
    };

    setDisabledAndClearValue = () => {
        const { name, onChange } = this.props;
        onChange(undefined, name);
    };

    handleOk = () => {
        const { name, onChange, value } = this.props;
        const currentDefaultValue = {
            ...defaultValue,
            content: language.getText(defaultValue.content),
        };
        const currentValue = value || currentDefaultValue;
        onChange(
            {
                ...currentValue,
                agreement: true,
            },
            name
        );
        this.setState({
            isModalOpen: false,
        });
    };

    onCancel = () => {
        this.setState({
            isModalOpen: false,
        });
    };

    render() {
        const { value, showAgreementIn } = this.props;
        const { checkBoxDisabled, tip, isModalOpen } = this.state;
        const checked = value && value.channel;
        const contentText = value && value.content ? value.content : '';
        const textLength = contentText.length;
        const agreementChecked = value && value.agreement;
        return (
            <div>
                <Checkbox onChange={this.onChange} disabled={checkBoxDisabled} checked={checked}>
                    {language.getText('dx')}
                </Checkbox>
                {checked ? (
                    <div>
                        <TextArea
                            onChange={this.onInputChange}
                            value={contentText}
                            rows={4}
                            placeholder={services.language.getText('inputPlease')}
                            maxLength={500}
                            style={{ width: 400 }}
                        ></TextArea>
                        <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                            {language.getText('dxzfs')}{' '}
                            <span style={{ color: '#1890ff' }}>{textLength}</span>{' '}
                            {language.getText('bbhqmblzdzfsl')}
                        </div>
                        <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                            {language.getText('jblzdzddqfsyh')}
                        </div>
                        {(!showAgreementIn || showAgreementIn !== 'false') && (
                            <Checkbox onChange={this.onAgreementChange} checked={agreementChecked}>
                                {language.getText('ty')}
                                <Button
                                    type="link"
                                    onClick={this.onClickLink}
                                    style={{ padding: 0 }}
                                >
                                    {language.getText('dxfwxy')}
                                </Button>
                            </Checkbox>
                        )}
                    </div>
                ) : null}
                {tip && <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>{tip}</div>}
                <Modal
                    title={language.getText('messageAgreeMent')}
                    visible={isModalOpen}
                    width={800}
                    onCancel={this.onCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('wzdl')}
                        </Button>,
                    ]}
                >
                    <div
                        style={{ fontSize: 14, color: '#000', height: '60vh', overflowY: 'scroll' }}
                    >
                        <p style={{ fontWeight: 'bold' }}>一、接受条款</p>
                        <p>{services.language.getText('rgnbtyjsqbdtkhtj')}</p>
                        <p>
                            1.2  科码先锋有权随时修改本协议条款和条件，并只需公示于网站或通知予您，而无需征得您的事先同意。修改后的条款应于公示或通知时即时生效。在修改本协议后，您继续使用短信功能应被视作您已接受了修改后的条款。除非得到科码先锋的书面授权，否则任何人将不得修改本协议。
                        </p>
                        <p style={{ fontWeight: 'bold' }}>二、 定义</p>
                        <p>
                            2.1  
                            甲方：指在北京科码先锋互联网技术股份有限公司的平台、产品、系统等注册成功，并在科码先锋平台上或使用科码先锋产品开设店铺、销售商品、提供服务的公司、个人、个体工商户等。
                        </p>
                        <p>2.2   乙方：北京科码先锋互联网技术股份有限公司</p>
                        <p>
                            2.3  
                            短信功能：指由科码先锋向甲方提供的、在北京科码先锋互联网技术股份有限公司系统后台可开通短信通知的功能。
                        </p>
                        <p>2.4   用户：仅指“短信接受方”。</p>
                        <p style={{ fontWeight: 'bold' }}>三、甲方的义务</p>
                        <p>{services.language.getText('zswjfwy')}</p>
                        <p>
                            3.2
                            甲方保证使用乙方短信业务仅向其内部员工或注册客户（以下统称“用户”）发送短信，甲方只有在获得用户同意接收的确认后，方可向用户提供短信业务服务，用户同意后又明确拒绝接收短信息的，应当立即停止向其发送。甲方保证，向其用户提供行业短信业务服务前，已明确告知用户服务内容的业务形式、使用方法、取消方式等并能够证明已获得用户同意（书面、互联网上确认等）。当产生用户投诉时，甲方应提供用户同意接收短信的证明文件。
                        </p>
                        <p>{services.language.getText('zqrwyyfjyhzgxdcp')}</p>
                        <p>{services.language.getText('jfxzcsmbbzsydxgnfsxxs')}</p>
                        <p>
                            (a)
                            涉嫌欺诈、涉嫌提供虚假信息、涉嫌销售或试图销售假冒伪劣及盗窃的物品以及其它法律法规限制或禁止销售及推广的物品。
                        </p>
                        <p>
                            (b)
                            涉嫌侵犯或教唆他人侵犯第三方的版权，专利，商业秘密或其它合法权利，或涉嫌不正当竞争。 
                                  
                        </p>
                        <p>
                            (c)
                            涉嫌违反相关法律，法规，规章或决定(包括但不限于国家出口限制，消费者保护，不正当竞争和歧视或虚假广告)。
                        </p>
                        <p>(d)涉嫌含有侮辱、诽谤，恐吓或非法骚扰等内容。</p>
                        <p>(e)涉嫌含有淫秽，或包含任何色情内容。</p>
                        <p>(f)涉嫌含有种族，性别，地区，民族，生理残疾等歧视性或倾向性内容。</p>
                        <p>{services.language.getText('eygr')}</p>
                        <p>{services.language.getText('tgsxfsdnr')}</p>
                        <p>
                            3.5
                            甲方应自行对您通过短信功能发布的内容所引起的一切法律纠纷承担全部责任。
                        </p>
                        <p>
                            3.6 
                            甲方使用短信功能应当符合本协议及其他使用规范的要求，并保证不违反中华人民共和国法律法规以及对您具有法律约束力的其他任何文件，也不构成对任何第三人任何形式的侵权，保证不会因向其提供短信功能而构成违法、违约或者对任何第三人的侵权，保证使用短信功能不具有任何恶意，如利用短信功能发布不发信息等。
                        </p>
                        <p style={{ fontWeight: 'bold' }}>四、诚信经营和第三方投诉处理</p>
                        <p>{services.language.getText('yftyjfyqbjtznjljdftzyfddxgn')}</p>
                        <p>{services.language.getText('sxlydxgnssqzhqtwfxwd')}</p>
                        <p>
                            (b)
                            未经有甲方面同意，擅自许可他人使用短信功能或替他人利用短信功能发送短信或擅自将短信功能转让或部分转让他人的（包括但不限于擅自许可他人使用其帐号和密码，或擅自将其帐号和密码转让或泄露给他人的）；
                        </p>
                        <p>{services.language.getText('xghzjyfhyfqtsjdrhxx')}</p>
                        <p>(d) 违反其他约定或网站规则的。</p>
                        <p>
                            4.2 
                            如因上述造成乙方损失的（包括但不限于依法必须支付给第三方的赔偿款、诉讼费、律师费、公证费等），均由甲方承担。
                        </p>
                        <p style={{ fontWeight: 'bold' }}>五、乙方的责任限制和免责</p>
                        <p>
                            5.1 
                             甲方在此明确声明对甲方短信功能不作任何明示或暗示的保证，包括但不限于无漏发、准确性之类的保证、声明或承诺。
                        </p>
                        <p>
                            5.2 
                             甲方对甲方短信功能所涉的技术和信息的有效性，准确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。
                        </p>
                        <p>
                            5.3 
                             甲方对乙方的任何通知，不论是口头还是书面的，均不表示甲方做出在本协议陈述以外的任何担保责任。
                        </p>
                        <p>5.4   对下列情形，甲方不承担任何责任：</p>
                        <p>(a)并非由于甲方的故意或过失而导致甲方短信功能未能提供的；</p>
                        <p>(b)由于乙方的故意或过失导致您及/或任何第三方遭受损失的；</p>
                        <p>{services.language.getText('yfwfbxy')}</p>
                        <p>(d)因不可抗力导致甲方不能履行其义务的。 </p>
                        <p style={{ fontWeight: 'bold' }}>六、其他</p>
                        <p>
                            6.1 
                             本协议取代双方就有关本协议所载任何事项于先前以口头及书面达成的共识。
                        </p>
                        <p>6.2   本协议将受中国法律约束。</p>
                        <p>
                            6.3 
                             如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。
                        </p>
                        <p>{services.language.getText('fdtkddy')}</p>
                        <p>
                            6.5 
                             乙方于甲方过失或违约时放弃本协议规定的权利的，不得视为其对乙方的其他或以后同类之过失或违约行为弃权。
                        </p>
                    </div>
                </Modal>
            </div>
        );
    }
}
