import React, { PureComponent } from 'react';
import { cloneDeep, forEach, find, findIndex } from 'lodash';

import { MemberSelectedDisplay } from './member-selected-display';
import { MemberSelector } from './member-selector';

export interface Member {
    address: string;
    id: string;
    name: string;
    status: number;
    hasLeaders: boolean;
    /**
     * 被选中的分站
     */
    select?: boolean;
    /**
     * 将要被删除的分站
     */
    delete?: boolean;
}

export enum SendMemberMode {
    ALL = 'ALL',
    LEVEL = 'LEVEL',
    MOBILE = 'MOBILE',
}
export interface SendMemberFile {
    fileId: number;
    fileName: string;
    fileUrl: string;
    messageBatchMemberList: MessageBatchMember[];
}
export interface MessageBatchMember {
    memberId: number;
    mobile: string;
}

export interface SelectMemberValue {
    mode: SendMemberMode;
    list: Member[];
    file: SendMemberFile | null;
}

export class CGBConfigSelectMember extends PureComponent<any, any> {
    /**
     * 选择分站
     * @param selectValues
     */
    onChange = (newValue: SelectMemberValue) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(newValue);
        }
    };

    /**
     * 改变已选择分站选中状态
     * @param selectValues
     */
    onChangeSelect = (newList: Member[]) => {
        const { onChange, value } = this.props;

        forEach(value.list, (member: any) => {
            const selectSubsite = find(newList, (selectSubsite: any) => {
                return selectSubsite.id === member.id;
            });

            if (selectSubsite) {
                member.select = true;
            } else {
                member.select = false;
            }
        });
        if (onChange) {
            onChange(value);
        }
    };

    /**
     * 添加分站弹窗确认后回调
     */
    onSelect = (newValue: SelectMemberValue) => {
        const { value } = this.props;
        let newList: Member[] = [];
        if (value) {
            newList = cloneDeep(value.list);
        }
        if (newValue && newValue.list) {
            newValue.list.forEach((selectSubsite: any) => {
                const hasIndex = findIndex(
                    value.list,
                    (member: any) => member.id === selectSubsite.id
                );
                if (hasIndex === -1) {
                    newList.unshift(selectSubsite);
                }
            });
        }
        if (newValue && newValue.list && newValue.list.length) {
            newValue.list = newList;
        } else {
            newValue.list = [];
        }

        this.onChange(newValue);
    };

    render() {
        const { value, disabled } = this.props;
        const hasValue = value && value.list && value.list.length > 0;
        return (
            <div>
                <MemberSelector disabled={disabled} onChange={this.onSelect} value={value} />
                {hasValue && (
                    <MemberSelectedDisplay
                        value={value}
                        onChangeSelect={this.onChangeSelect}
                        onRemove={this.onChange}
                        disabled={disabled}
                    ></MemberSelectedDisplay>
                )}
            </div>
        );
    }
}
