import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { Popover } from 'antd';

import './index.less';
import { services } from '@comall-backend-builder/core';
/**
 *  优惠券详情
 */
export class CouponInfoMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getDisplayComponent(value: any) {
        if (!value) return <div></div>;
        const { url, name, startTime, endTime } = value;
        return (
            <div className="coupon-info">
                {!!url && (
                    <div className="coupon-info__img">
                        <img src={url} alt="" />
                    </div>
                )}
                <div className="coupon-info__detail">
                    {name && name.length > 30 ? (
                        <Popover
                            style={{ zIndex: 1030 }}
                            placement="top"
                            content={<div style={{ maxWidth: 300 }}>{name}</div>}
                        >
                            <div>{name.slice(0, 29)}...</div>
                        </Popover>
                    ) : (
                        <div>{name}</div>
                    )}
                    <div className="coupon-info__time">
                        <div>
                            <span>{startTime}</span>
                            <span style={{ fontSize: 12, marginLeft: 2 }}>
                                {services.language.getText('to')}
                            </span>
                            <br />
                            <span>{endTime}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
