import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import isEmpty from 'lodash/isEmpty';

const api = services.api;

export const RestaurantReservationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`;
        config.apiPath = '/admin/restaurant_reservation_orders';
        if (!isEmpty(data.reservationDateRange)) {
            data.reservationStartDate = data.reservationDateRange.start + ' 00:00:00';
            data.reservationEndDate = data.reservationDateRange.end + ' 23:59:59';
            delete data.reservationDateRange;
        }
        if (!isEmpty(data.createDateRange)) {
            data.createStartDate = data.createDateRange.start + ' 00:00:00';
            data.createEndDate = data.createDateRange.end + ' 23:59:59';
            delete data.createDateRange;
        }
        if (data.status) {
            data.field = data.status === 'Create' ? 'createTime' : 'lastModifiedTime';
            data.order = data.status === 'Create' ? 'ASCEND' : 'DESCEND';
        } else {
            data.field = 'createTime';
            data.order = 'DESCEND';
        }

        return await api.get(data, config);
    },
};
