import React, { PureComponent } from 'react';
import Print from '../order-picking-assistant/print';
import './index.less';

interface BingCodeItem {
    id: string;
    name: string;
    subsiteName: string;
    merchantName: string;
    url: string;
}

interface Props {
    bigCodes: BingCodeItem[];
    setWrappedInstance: (ref: any) => void;
}

export class BigCodesPrint extends PureComponent<Props> {
    constructor(props: any) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }
    setRef = (ref: any) => {
        const { setWrappedInstance } = this.props;
        if (setWrappedInstance) {
            setWrappedInstance(ref);
        }
    };

    renderbingCodeItem = (item: BingCodeItem) => {
        return (
            <div
                style={{
                    flex: 1,
                    width: '33%',
                    maxWidth: '33%',
                    border: 'none',
                    padding: 0,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '18px',
                        lineHeight: '18px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {item.id}
                </div>
                <div
                    style={{
                        width: '100%',
                        height: '36px',
                        lineHeight: '18px',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    }}
                >
                    {item.name}
                </div>
                <div
                    style={{
                        height: '18px',
                        lineHeight: '18px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {item.subsiteName}-{item.merchantName}
                </div>
                <div style={{ width: '100%', overflow: 'hidden' }}>
                    <img style={{ width: '100%' }} alt="" src={item.url} />
                </div>
            </div>
        );
    };
    splitArrayIntoChunks = (array: BingCodeItem[], chunkSize: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            result.push(chunk);
        }
        return result;
    };
    render() {
        const { bigCodes } = this.props;
        if (!bigCodes || bigCodes.length === 0) {
            return null;
        }
        const list = this.splitArrayIntoChunks(bigCodes, 3);
        return (
            <Print ref={this.setRef} insertHead={false}>
                <div style={{ display: 'none' }}>
                    {list.map((data: BingCodeItem[], index: number) => {
                        return (
                            <div
                                key={`tr-${index}`}
                                style={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {data.map(this.renderbingCodeItem)}
                            </div>
                        );
                    })}
                </div>
            </Print>
        );
    }
}
