import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';

export const ScratchCardLoader = {
    get: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/scratch_card_activities';
        if (data.executeStatus === 'ALL') {
            delete data.executeStatus;
        }
        data.eventType = 'SCRATCH_CARD';
        if (!isEmpty(subsites)) {
            data.subSiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }

        return services.api.get(data, config);
    },
};
