import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, isArray, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

enum ActivityMode {
    /**
     * 无需报名，无需抽签(无需条件)
     */
    NOT_CONDITION = 'NOT_CONDITION',
    /**
     * 需要报名，需要抽签（需要条件）
     */
    NEED_CONDITION = 'NEED_CONDITION',
    /**
     * 既需要报名，又需要抽签
     */
    NEED_RESERVE_DRAW = 'NEED_RESERVE_DRAW',
    /**
     * 仅需要报名
     */
    NEED_RESERVE = 'NEED_RESERVE',
    /**
     * 仅需要抽签
     */
    NEED_DRAW = 'NEED_DRAW',
}

enum LimitRules {
    /**
     * 不限购
     */
    LIMIT_NO = 'LIMIT_NO',
    /**
     * 用户限购
     */
    LIMIT_USER = 'LIMIT_USER',
    /**
     * 会员等级限购
     */
    LIMIT_USER_LEVEL = 'LIMIT_USER_LEVEL',
}

export interface PreSaleListAddFormProps extends FormComponentProps {
    activityMode: ActivityMode;
    limitRulesMode: LimitRules;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class preSaleListAddForm extends PureComponent<PreSaleListAddFormProps> {
    static defaultProps: any = {
        activityMode: ActivityMode.NOT_CONDITION,
        limitRulesMode: LimitRules.LIMIT_NO,
    };
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let { activityMode, limitRulesMode } = this.props;
        if (!activityMode) {
            activityMode = ActivityMode.NOT_CONDITION;
        }
        if (!limitRulesMode) {
            limitRulesMode = LimitRules.LIMIT_NO;
        }
        let fields = [
            { property: 'baseInfo.name' },
            { property: 'baseInfo.dateRange' },

            { property: 'subsites' },
            { property: 'activityInfo.presaleMode' },
            {
                property: 'extraActivityMode',
                extra: '两项均勾选后，则用户需要先报名参加活动，中签后才能购买商品',
            },

            { property: 'goods' },
            {
                property: 'registeredDays',
                className:
                    activityMode === ActivityMode.NEED_RESERVE ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
                extra:
                    '如填写30天，活动日期为1日-30日，若用户是25号参加的活动，则从25号当天开始计算，用户的注册时间必须为上月26日24:00及之前注册（若上月为30天）的才可报名成功',
            },
            {
                property: 'monthlyConsumedTimes',
                className:
                    activityMode === ActivityMode.NEED_RESERVE ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
                extra: '如填写3次，则表示用户每月至少消费>=3次才可报名成功',
            },
            {
                property: 'minPointThreshold',
                className:
                    activityMode === ActivityMode.NEED_RESERVE ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
                extra: '报名时校验用户是否符合该积分，如不符合则不允许报名',
            },
            {
                property: 'certification',
                className:
                    activityMode === ActivityMode.NEED_RESERVE ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
                extra: '勾选实名认证后，则用户需要进行姓名和身份证的认证',
            },
            {
                property: 'ballotDate',
                className:
                    activityMode === ActivityMode.NEED_DRAW ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
            },
            {
                property: 'ballotedPeopleCount',
                className:
                    activityMode === ActivityMode.NEED_DRAW ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
            },
            {
                property: 'perballotedCount',
                className:
                    activityMode === ActivityMode.NEED_DRAW ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
            },
            {
                property: 'activityTimes',
                className:
                    activityMode === ActivityMode.NEED_DRAW ||
                    activityMode === ActivityMode.NEED_RESERVE_DRAW
                        ? ''
                        : 'property-hide',
            },
            {
                property: 'rulesInfo.limitRules',
            },
            {
                property: 'limitUser',
                className: limitRulesMode === LimitRules.LIMIT_USER ? '' : 'property-hide',
            },
            {
                property: 'limitUserLevel',
                className: limitRulesMode === LimitRules.LIMIT_USER_LEVEL ? '' : 'property-hide',
            },
            {
                property: 'checkoutRules.orderRules',
                extra: '勾选后，在结算页时可同时使用',
            },
            {
                property: 'checkoutRules.rules',
            },
        ];
        if (activityMode !== ActivityMode.NOT_CONDITION) {
            fields.push({
                property: 'advertisement.activityPicture',
                extra: '将会在活动投放页顶部显示，建议尺寸：750px * 560px',
            });
            fields.push({
                property: 'advertisement.rulePicture',
                extra: '将会在活动投放页规则说明处显示，建议尺寸：宽度为750px 高度不限制',
            });
        }

        let formProps = {
            entity,
            componentId: 'PreSaleListAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'presale-list-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    let activityMode: ActivityMode = ActivityMode.NOT_CONDITION;

    let limitRulesMode = get(
        _dispatch,
        'components.PreSaleListAddFormContainer.fields.rulesInfo.limitRules',
        LimitRules.LIMIT_NO
    );

    // 活动模式
    let extraMode = get(
        _dispatch,
        'components.PreSaleListAddFormContainer.fields.extraActivityMode'
    );
    if (isArray(extraMode)) {
        if (extraMode.length === 2) {
            activityMode = ActivityMode.NEED_RESERVE_DRAW;
        } else if (extraMode.length === 1) {
            if (extraMode[0] === 'SIGN_UP') {
                activityMode = ActivityMode.NEED_RESERVE;
            } else {
                activityMode = ActivityMode.NEED_DRAW;
            }
        } else {
            activityMode = ActivityMode.NOT_CONDITION;
        }
    } else {
        activityMode = ActivityMode.NOT_CONDITION;
    }

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        // 活动图
        let activityPicture: any = get(entityFields, 'advertisement.activityPicture', null);
        //中签人数
        let ballotedPeopleCount = get(entityFields, 'ballotedPeopleCount.number', null);
        //每用户中签次数
        let perballotedCount = get(entityFields, 'perballotedCount', null);

        if (activityMode === ActivityMode.NEED_CONDITION) {
            AntMessage.warning('请至少选择一个活动模式条件');
            return false;
        }
        if (
            activityMode === ActivityMode.NEED_DRAW ||
            activityMode === ActivityMode.NEED_RESERVE_DRAW
        ) {
            if (!ballotedPeopleCount || Number(ballotedPeopleCount) <= 0) {
                AntMessage.warning('请填写中签人数');
                return false;
            }
            if (!perballotedCount || perballotedCount <= 0) {
                AntMessage.warning(services.language.getText('qtxmyhzqcs'));
                return false;
            }
        }
        if (activityMode !== ActivityMode.NOT_CONDITION) {
            if (!activityPicture || !isArray(activityPicture) || activityPicture.length < 1) {
                AntMessage.warning('请上传活动图');
                return false;
            }
        }

        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            activityMode,
            limitRulesMode,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('PreSaleListAddFormContainer'));
            },
        }
    );
}
export const PreSaleListAddFormView = connect(mapDispatchToProps)(preSaleListAddForm);
