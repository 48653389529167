import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, find, isNil } from 'lodash';
import { OrderMergeTabSequencesItemType } from '../types/mode/array/order-merge-tab-sequences/order-merge-tab-sequences';

const api = services.api;
export const CommonSettingLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/system_configs/common_setting';
        const res: any = await api.get(data, config);
        if (res) {
            const orderMergeTabSequences = find(res.pageConfigs, { code: 'ORDER_MERGE_PAGE' });
            orderMergeTabSequences.value = JSON.parse(orderMergeTabSequences.value).map(
                (item: any) => ({
                    ...item,
                    status: isNil(item.status) ? true : item.status,
                })
            );
            const item = orderMergeTabSequences.value.find(
                (i: any) => i.orderType === OrderMergeTabSequencesItemType.DepositOrder
            );
            if (!item) {
                orderMergeTabSequences.value.push({
                    name: '订金订单',
                    orderType: OrderMergeTabSequencesItemType.DepositOrder,
                    status: false,
                });
            }
            const storedValueCardOrder = orderMergeTabSequences.value.find(
                (i: any) => i.orderType === OrderMergeTabSequencesItemType.StoredValueCard
            );
            if (!storedValueCardOrder) {
                orderMergeTabSequences.value.push({
                    name: '储值卡订单',
                    orderType: OrderMergeTabSequencesItemType.StoredValueCard,
                    status: false,
                });
            }
            const invoiceOrderMergTabSequences = find(res.pageConfigs, {
                code: 'INVOICE_ORDER_MERGE_PAGE',
            });
            invoiceOrderMergTabSequences.value = JSON.parse(invoiceOrderMergTabSequences.value);
            res.cart = {
                checkoutInMultiSubsite: res.checkoutInMultiSubsite + '',
                showMerchantTypeTag: !isNil(res.showMerchantTypeTag)
                    ? res.showMerchantTypeTag + ''
                    : 'true',
            };
            res.login = {
                privacyAgreementStyle: res?.privacyAgreementStyle,
                headerContent: res?.privacyAgreement?.headerContent,
                agreements: res?.privacyAgreement?.agreements,
                mobileLogin: res.mobileLogin ? ['1', '2'] : ['1'],
                loginTip: res.loginTip ? res.loginTip : '手机号登陆/注册',
                logoutMode: res.logoutMode,
                logoutModeTip: res.logoutModeTip,
                logoutAgreement: res.logoutAgreement,
            };
            res.productDetails = {
                showAppraise: !isNil(res.showAppraise) ? res.showAppraise + '' : 'true',
            };
            res.order = {
                orderMergeTabSequences: orderMergeTabSequences,
                invoiceOrderMergTabSequences: invoiceOrderMergTabSequences,
            };
            const balanceCouponPage = find(res.pageConfigs, {
                code: 'BALANCE_COUPON_QRCODE',
            });
            // const balanceCouponPageValue = Object.assign(
            //     {
            //         balanceCouponQrCode: [],
            //         balanceCouponQrCodeName: '',
            //         balanceCouponShowMode: 'SINGLE_COUNT',
            //     },
            //     JSON.parse(balanceCouponPage.value)
            // );
            res.assets = {
                memberCardExperienceAlias: res.memberCardExperienceAlias,
                balanceCouponPage,
                // ...balanceCouponPageValue,
            };
            res.market = {
                showEstimatePrice: Boolean(res.showEstimatePrice).toString(),
                activityRewardLimit: res.activityRewardLimit,
            };
            const miniProgramCodeSystemPictureResult: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/mini_program_code_system_picture',
                }
            );
            res.codeInfo = {
                miniProgramCodeSystemPicture:
                    miniProgramCodeSystemPictureResult &&
                    miniProgramCodeSystemPictureResult.logoFileId
                        ? [
                              {
                                  id: miniProgramCodeSystemPictureResult.logoFileId,
                                  path: miniProgramCodeSystemPictureResult.logoFileUrl,
                              },
                          ]
                        : undefined,
            };
            res.parking = {
                allowCarNumberMultiUserBind: !isNil(res.allowCarNumberMultiUserBind)
                    ? res.allowCarNumberMultiUserBind + ''
                    : 'true',
            };
            res.noteComment = {
                showNoteComment: Boolean(res.showNoteComment).toString(),
            };
            /* res.credit = {};
           if (res.creditEshopBlackGroundPicId && res.creditEshopBlackGroundPicUrl) {
                res.credit.bgPicture = [
                    {
                        id: res.creditEshopBlackGroundPicId,
                        path: res.creditEshopBlackGroundPicUrl,
                    },
                ];
            } */
            const couponStackedLimitCountResult: any = await api.get(
                {
                    code: 'COUPON_STACKED_LIMIT_COUNT',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/system_configs/page_config`,
                }
            );
            res.market.couponStackedLimitCount = couponStackedLimitCountResult
                ? couponStackedLimitCountResult.value
                : undefined;
            res.userPositioning = { defaultLocationStore: res.defaultLocationStore };
        }
        return res;
    },
    put: async function(params, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/system_configs/common_setting';
        const memberCardExperienceAlias = (params.assets.memberCardExperienceAlias || '').trim();
        const loginTip = (params.login.loginTip || '').trim();
        const orderMergeTabSequences = cloneDeep(params.order.orderMergeTabSequences);
        const invoiceOrderMergTabSequences = cloneDeep(params.order.invoiceOrderMergTabSequences);
        orderMergeTabSequences.value = JSON.stringify(orderMergeTabSequences.value);
        invoiceOrderMergTabSequences.value = JSON.stringify(invoiceOrderMergTabSequences.value);
        const balanceCouponPage = {
            ...params.assets.balanceCouponPage,
            // value: JSON.stringify({
            //     balanceCouponQrCode: params.assets.balanceCouponQrCode,
            //     balanceCouponQrCodeName: params.assets.balanceCouponQrCodeName,
            //     balanceCouponShowMode: params.assets.balanceCouponShowMode,
            // }),
        };
        /* const bgPicture =
            params.credit.bgPicture && params.credit.bgPicture.length
                ? params.credit.bgPicture[0]
                : {}; */
        const newParams = {
            defaultLocationStore: params.userPositioning.defaultLocationStore,
            privacyAgreementStyle: params.login.privacyAgreementStyle,
            privacyAgreement: {
                headerContent: params.login.headerContent,
                agreements: params.login.agreements,
            },
            checkoutInMultiSubsite: params.cart.checkoutInMultiSubsite,
            showMerchantTypeTag:
                params.cart.showMerchantTypeTag && params.cart.showMerchantTypeTag === 'true'
                    ? true
                    : false,
            showAppraise:
                params.productDetails.showAppraise && params.productDetails.showAppraise === 'true'
                    ? true
                    : false,
            mobileLogin: params.login.mobileLogin
                ? /2/g.test(params.login.mobileLogin.join(''))
                : false,
            memberCardExperienceAlias:
                memberCardExperienceAlias === '' ? null : memberCardExperienceAlias,
            loginTip: loginTip === '' ? null : loginTip,
            pageConfigs: [orderMergeTabSequences, invoiceOrderMergTabSequences, balanceCouponPage],
            showEstimatePrice: params.market.showEstimatePrice === 'true',
            activityRewardLimit: params.market.activityRewardLimit,
            allowCarNumberMultiUserBind: params.parking.allowCarNumberMultiUserBind === 'true',
            showNoteComment: params.noteComment.showNoteComment === 'true',
            logoutMode: params.login.logoutMode,
            logoutModeTip:
                params.login.logoutMode !== 'NOT_SHOW' ? params.login.logoutModeTip : undefined,
            logoutAgreement:
                params.login.logoutMode === 'ONLINE' ? params.login.logoutAgreement : undefined,
            // creditEshopBlackGroundPicId: bgPicture.id,
            // creditEshopBlackGroundPicUrl: bgPicture.path,
        };
        const commonSettingResult = await api.put(newParams, config);
        const logoFileId = params.codeInfo.miniProgramCodeSystemPicture?.[0]?.id;
        const miniProgramCodeSystemPictureResult = await api.put(
            {
                logoFileId: logoFileId || null,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/mini_program_code_system_picture',
            }
        );
        const couponStackedLimitCountResult = await api.put(
            {
                code: 'COUPON_STACKED_LIMIT_COUNT',
                name: '优惠券可叠加使用张数限制',
                value: params.market.couponStackedLimitCount
                    ? params.market.couponStackedLimitCount
                    : undefined,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/system_configs/page_config`,
            }
        );
        if (
            commonSettingResult &&
            miniProgramCodeSystemPictureResult &&
            couponStackedLimitCountResult
        ) {
            return true;
        }
        return true;
    },
};
