import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { RewardSetting } from '../../../containers';
import { RewardSettingDisplay } from '../../../components';

export class RewardSettingMode extends ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <RewardSettingDisplay
                data={value}
                onChange={() => {}}
                executeStatus=""
            ></RewardSettingDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <RewardSetting {...controlInfo} />;
    }
}
