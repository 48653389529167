import React, { PureComponent } from 'react';
import { map, remove, find } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import {
    Button,
    DatePicker,
    Form,
    Icon,
    Input,
    message as AntMessage,
    Modal as AntModal,
    Popover,
    Select,
    Table,
    Tabs as AntTabs,
    Dropdown,
    Menu,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import './index.less';

const { api } = services;
const AntTabPane = AntTabs.TabPane;
const activeKeyList: Array<any> = [
    {
        value: 'ALL',
        name: 'all',
    },
    {
        value: 'SHELF',
        name: 'alreadyShelf',
    },
    {
        value: 'NONE',
        name: 'notListed',
    },
    {
        value: 'OFF_SHELF',
        name: 'alreadyOffShelf',
    },
];
const shelfStatus: any = {
    NONE: 'notListed',
    OFF_SHELF: 'alreadyOffShelf',
    SHELF: 'alreadyShelf',
};
const auditStatus: any = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    AUDITING: 'auditing',
    WAITING: 'waiting',
};

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
            couponsType: [];
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form, subsites, couponsType } = this.props as any;
            const { getFieldDecorator, getFieldValue } = form;
            const defaultValue = subsites && subsites.length === 1 ? subsites[0].id : undefined;

            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('saleCouponName')}>
                        {getFieldDecorator('name')(
                            <Input
                                className="find-filter-width"
                                placeholder={language.getText('selectSaleCouponName')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('couponRuleName')}>
                        {getFieldDecorator('couponName')(
                            <Input
                                className="find-filter-width"
                                placeholder={language.getText('selectCouponRuleName')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('couponType')}>
                        {getFieldDecorator('couponType')(
                            <Select
                                className="find-filter-width"
                                allowClear
                                showSearch
                                placeholder={language.getText('selectPlease')}
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="">{language.getText('all')}</Select.Option>
                                {couponsType &&
                                    couponsType.map((item: any) => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('saleSubsites')}>
                        {getFieldDecorator('subsiteIds', {
                            initialValue: defaultValue,
                        })(
                            <Select
                                className="find-filter-width"
                                allowClear
                                showSearch
                                placeholder={language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="">{language.getText('all')}</Select.Option>
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('salesChannels')}>
                        {getFieldDecorator('salesChannel')(
                            <Select
                                className="find-filter-width"
                                allowClear
                                showSearch
                                placeholder={language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="">
                                    {language.getText('allChannel')}
                                </Select.Option>
                                <Select.Option value="OWN">
                                    {language.getText('selfOwnChannel')}
                                </Select.Option>
                                <Select.Option value="DOUYIN">
                                    {language.getText('douyin')}
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    {getFieldValue('salesChannel') === 'DOUYIN' && (
                        <Form.Item label="">
                            {getFieldDecorator('auditStatus')(
                                <Select
                                    className="find-filter-width"
                                    allowClear
                                    showSearch
                                    placeholder={language.getText('selectPlease')}
                                    optionFilterProp="name"
                                    filterOption={(input, option: any) =>
                                        (option.props.children || '')
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="">
                                        {language.getText('allAuditStatus')}
                                    </Select.Option>
                                    <Select.Option value="APPROVED">
                                        {language.getText('approved')}
                                    </Select.Option>
                                    <Select.Option value="REJECTED">
                                        {language.getText('rejected')}
                                    </Select.Option>
                                    <Select.Option value="AUDITING">
                                        {language.getText('auditing')}
                                    </Select.Option>
                                    <Select.Option value="WAITING">
                                        {language.getText('waiting')}
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    )}
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class SalesElectronicCouponFind extends PureComponent {
    state = {
        datasource: [],
        dataDySource: [],
        copyDataDySource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        selectedRows: new Array<any>(),
        selectedDyRows: new Array<any>(),
        activeKey: 'ALL',
        auditStatusSelect: 'ALL',
        subsites: [],
        couponsType: [
            { id: 'CASH', name: language.getText('cash') },
            { id: 'GIFT', name: language.getText('gift') },
            { id: 'FREIGHT', name: language.getText('freight') },
            { id: 'PARKING', name: language.getText('parking') },
            { id: 'LOTTERY', name: language.getText('lottery') },
            { id: 'PACKAGE', name: language.getText('package') },
            { id: 'BALANCE', name: language.getText('balance') },
            { id: 'EFUTURE_CASH', name: language.getText('efutureCash') },
            { id: 'EFUTURE_BALANCE', name: language.getText('efutureBalance') },
            { id: 'BFPLAT_COUPON', name: language.getText('bfplat') },
        ],
        chooseVisible: false, //批量操作悬浮弹层展示
        chooseValue: '', //弹层类型（批量定时上架，批量定时下架）
        modalVisible: false, //弹层展示
        modalDyVisible: false, //抖音弹层展示
        startDate: '', //弹层时间
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            this.setState({
                subsites,
            });
        });
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination, activeKey } = this.state;
        try {
            let res: any = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                    shelfStatus: activeKey !== 'ALL' ? activeKey : undefined,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/virtual_products',
                }
            );
            if (res && res.result) {
                res.result = res.result.map((good: any, index: number) => {
                    return {
                        ...good,
                        id: good.goodsId,
                    };
                });
                this.setState({ datasource: res.result, total: res.totalNum, current: res.page });
            }
        } catch (error) {
            throw error;
        }
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            const rows = [...selectedRows];
            rows.push(record);
            this.setState({ selectedRows: rows });
        } else {
            const rows = [...selectedRows];
            remove(rows, (i) => {
                return i.id === record.id;
            });
            this.setState({ selectedRows: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        const { selectedRows, datasource } = this.state;
        const selectedRowIds = map(selectedRows, 'id');
        if (selected) {
            const addRows = rows.filter((item: any) => {
                return !selectedRowIds.includes(item.id);
            });
            this.setState({ selectedRows: [...selectedRows, ...addRows] });
        } else {
            const deleteIds: any = map(datasource, 'id');
            const deleteRows = selectedRows.filter((item: any) => {
                return !deleteIds.includes(item.id);
            });
            this.setState({ selectedRows: [...deleteRows] });
        }
    };
    onTableDyRowSelect = (record: any, selected: boolean) => {
        const { selectedDyRows } = this.state;
        if (selected) {
            const rows = [...selectedDyRows];
            rows.push(record);
            this.setState({ selectedDyRows: rows });
        } else {
            const rows = [...selectedDyRows];
            remove(rows, (i) => {
                return i.id === record.id;
            });
            this.setState({ selectedDyRows: rows });
        }
    };
    onTableDyRowSelectAll = (selected: boolean, rows: any[]) => {
        const { selectedDyRows, dataDySource } = this.state;
        const selectedRowIds = map(selectedDyRows, 'id');
        if (selected) {
            const addRows = rows.filter((item: any) => {
                return !selectedRowIds.includes(item.id);
            });
            this.setState({ selectedDyRows: [...selectedDyRows, ...addRows] });
        } else {
            const deleteIds: any = map(dataDySource, 'id');
            const deleteRows = selectedDyRows.filter((item: any) => {
                return !deleteIds.includes(item.id);
            });
            this.setState({ selectedDyRows: [...deleteRows] });
        }
    };

    onChange = async (activeKey: any) => {
        this.setState(
            {
                chooseVisible: false,
                modalVisible: false,
                startDate: '',
                status: 'open',
                activeKey,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                const { searchParams } = this.state;
                this.onSearch(searchParams);
            }
        );
    };

    onChangeDyShelfStatus = (name: string) => {
        const { selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(language.getText('productSelectRequired'));
            return;
        }
        const dataDySourceList: any[] = [];
        selectedRows.forEach((s) => {
            const item = s.salesChannelInfos.find((c: any) => c.salesChannel === 'DOUYIN');
            if (item) {
                dataDySourceList.push(s);
            }
        });

        if (!dataDySourceList || dataDySourceList.length === 0) {
            AntMessage.error(language.getText('selectDouyinProduct'));
            return;
        }
        this.setState({
            dataDySource: dataDySourceList,
            copyDataDySource: dataDySourceList,
            chooseValue: name,
            modalDyVisible: true,
        });
    };
    // 上下架操作
    onChangeShelfStatus = (shelfStatus: string, salesChannel?: string) => {
        const { selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(language.getText('productSelectRequired'));
            return;
        }
        const goodsIds = map(selectedRows, 'id');
        const apiPath =
            shelfStatus === 'OFF_SHELF'
                ? '/admin/virtual_products/batch_off_shelf'
                : '/admin/virtual_products/batch_on_shelf';
        api.put(
            {
                goodsIds: goodsIds,
                salesChannel: salesChannel ? salesChannel : 'OWN',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath,
            }
        )
            .then(() => {
                AntMessage.success(
                    shelfStatus === 'OFF_SHELF'
                        ? language.getText('batchShelvesSuccess')
                        : language.getText('batchListingSuccess'),
                    1.5
                );
                const { searchParams } = this.state;
                this.onSearch(searchParams);
                this.setState({
                    selectedRows: [],
                });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    hardleChoose = () => {
        const { chooseVisible } = this.state;
        this.setState({
            chooseVisible: !chooseVisible,
        });
    };

    renderButtons = () => {
        const { activeKey, chooseVisible } = this.state;
        const menu = (
            <Menu>
                {(activeKey === 'ALL' || activeKey === 'NONE' || activeKey === 'OFF_SHELF') && (
                    <Menu.Item>
                        <div onClick={this.onChangeShelfStatusTime.bind(this, 'SHELF')}>
                            {language.getText('batchTimingUpShelf')}
                        </div>
                    </Menu.Item>
                )}
                {(activeKey === 'ALL' || activeKey === 'SHELF') && (
                    <Menu.Item>
                        <div onClick={this.onChangeShelfStatusTime.bind(this, 'OFF_SHELF')}>
                            {language.getText('batchTimingDownShelf')}
                        </div>
                    </Menu.Item>
                )}
            </Menu>
        );
        const menuUp = (
            <Menu>
                <Menu.Item>
                    <div onClick={this.onChangeShelfStatus.bind(this, 'SHELF', 'OWN')}>
                        {language.getText('selfChannelUpShelf')}
                    </div>
                </Menu.Item>

                <Menu.Item>
                    <div onClick={this.onChangeDyShelfStatus.bind(this, 'SHELF')}>
                        {language.getText('douyinUpShelf')}
                    </div>
                </Menu.Item>
            </Menu>
        );
        const menuDown = (
            <Menu>
                <Menu.Item>
                    <div onClick={this.onChangeShelfStatus.bind(this, 'OFF_SHELF', 'OWN')}>
                        {language.getText('selfChannelDownShelf')}
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div onClick={this.onChangeDyShelfStatus.bind(this, 'OFF_SHELF')}>
                        {language.getText('downDownShelf')}
                    </div>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="find-buttons">
                {/* <Button
                type="default"
                onClick={this.onChangeShelfStatus.bind(this, 'SHELF')}
                className="find-button"
            >
                上架
            </Button> */}
                {/* 
                    <Button
                        type="default"
                        onClick={this.onChangeShelfStatus.bind(this, 'OFF_SHELF')}
                        className="find-button"
                    >
                        下架
                    </Button> */}
                {(activeKey === 'ALL' || activeKey === 'NONE' || activeKey === 'OFF_SHELF') && (
                    <Dropdown overlay={menuUp} placement="bottomCenter" trigger={['click']}>
                        <Button type="default" className="find-button">
                            {language.getText('channelUpShelf')}
                            {chooseVisible ? <Icon type="up" /> : <Icon type="down" />}
                        </Button>
                    </Dropdown>
                )}
                {(activeKey === 'ALL' || activeKey === 'SHELF') && (
                    <Dropdown overlay={menuDown} placement="bottomCenter" trigger={['click']}>
                        <Button type="default" className="find-button">
                            {language.getText('channelDownShelf')}
                            {chooseVisible ? <Icon type="up" /> : <Icon type="down" />}
                        </Button>
                    </Dropdown>
                )}
                <span className="find-choose-wrap">
                    <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                        <Button type="default" className="find-button">
                            {language.getText('batchAction')}
                            {chooseVisible ? <Icon type="up" /> : <Icon type="down" />}
                        </Button>
                    </Dropdown>
                </span>
            </div>
        );
    };

    //抖音批量上架
    onChangeShelfStatusTime = (name: string) => {
        const { selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(language.getText('productSelectRequired'));
            return;
        }
        this.setState({
            chooseValue: name,
            modalVisible: true,
            startDate: '',
            status: 'open',
        });
    };

    renderButtonsModal = () => {
        const { chooseValue, modalVisible, startDate } = this.state;
        let title = '';
        if (chooseValue === 'SHELF') {
            title = language.getText('timingUpShelf');
        } else if (chooseValue === 'OFF_SHELF') {
            title = language.getText('timingDownShelf');
        }
        return (
            <AntModal
                visible={modalVisible}
                okText={services.language.getText('common.ok')}
                title={title}
                onOk={this.submitShelfStatusTime}
                onCancel={this.closeModal}
                cancelButtonProps={{
                    onClick: this.closeModal,
                }}
            >
                <div className="modal-time-wrap">
                    <div>
                        {title}
                        {language.getText('time')}
                    </div>
                    <DatePicker
                        style={{ marginLeft: '20px' }}
                        disabledDate={this.disabledDate}
                        disabledTime={this.disabledTime}
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime
                        value={startDate ? moment(startDate) : undefined}
                        onChange={(value) => this.handleDateChange(value)}
                        placeholder={language.getText('selectPlease')}
                    />
                </div>
            </AntModal>
        );
    };

    selectChange = (e: any) => {
        this.setState({ auditStatusSelect: e });
    };
    handleDySearch = (e: any) => {
        const { copyDataDySource = [], auditStatusSelect } = this.state;

        if (auditStatusSelect === 'ALL') {
            this.setState({ dataDySource: copyDataDySource });
            return;
        }
        const dataList: any = [];
        copyDataDySource.forEach((d: any) => {
            const item = d.salesChannelInfos.find((s: any) => s.salesChannel === 'DOUYIN');
            if (item.auditStatus === auditStatusSelect) {
                dataList.push(d);
            }
        });
        this.setState({ dataDySource: dataList });
    };
    renderDyModal = () => {
        const {
            modalDyVisible,
            selectedDyRows,
            dataDySource,
            chooseValue,
            auditStatusSelect,
        } = this.state;
        const columns = [
            {
                title: language.getText('productInformation'),
                dataIndex: 'pictureUrl',
                key: 'pictureUrl',
                width: 300,
                render: (val: any, record: any) => {
                    const popoverContent = (
                        <div style={{ maxWidth: '300px' }}>{record.productName}</div>
                    );
                    return (
                        <div className="sales-electronic-coupon-find-info">
                            <img alt="" style={{ width: '60px', height: '60px' }} src={val} />
                            <div className="sales-electronic-coupon-find-info-right">
                                {record.productName && record.productName.length > 15 ? (
                                    <Popover content={popoverContent}>
                                        <div className="sales-electronic-coupon-find-info-right-name">
                                            {record.productName}
                                        </div>
                                    </Popover>
                                ) : (
                                    <div className="sales-electronic-coupon-find-info-right-name">
                                        {record.productName}
                                    </div>
                                )}
                                <div className="sales-electronic-coupon-find-info-right-name">
                                    {record.productCode}
                                </div>
                                {/* <div className="sales-electronic-coupon-find-info-right-price">
                                    {services.language.getText('monetaryUnit')}{record.salesPrice}
                                </div> */}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: language.getText('salesChannels'),
                dataIndex: 'salesChannel',
                key: 'salesChannel',
                render: (v: any, r: any) => {
                    return <span>{language.getText('douyin')}</span>;
                },
            },
            {
                title: language.getText('channelProductId'),
                dataIndex: 'outProductId',
                key: 'outProductId',
                render: (v: any, r: any) => {
                    const item = r.salesChannelInfos.find((s: any) => s.salesChannel === 'DOUYIN');
                    return <span>{item.outProductId}</span>;
                },
            },
            {
                title: services.language.getText('auditStatus'),
                dataIndex: 'auditStatus',
                key: 'auditStatus',
                render: (v: any, r: any) => {
                    const item = r.salesChannelInfos.find((s: any) => s.salesChannel === 'DOUYIN');
                    return <span>{language.getText(auditStatus[item.auditStatus])}</span>;
                },
            },
            {
                title: language.getText('upAndDownShelfStatus'),
                dataIndex: 'shelfStatus',
                key: 'shelfStatus',
                render: (v: any, r: any) => {
                    const item = r.salesChannelInfos.find((s: any) => s.salesChannel === 'DOUYIN');
                    return <span>{language.getText(shelfStatus[item.shelfStatus])}</span>;
                },
            },
        ];
        return (
            <AntModal
                visible={modalDyVisible}
                okText={services.language.getText('common.ok')}
                cancelText={language.getText('common.cancel')}
                title={language.getText('douyinUpShelf')}
                onOk={this.submitDyShelfStatus}
                onCancel={this.closeDyModal}
                cancelButtonProps={{
                    onClick: this.closeDyModal,
                }}
                width={1000}
            >
                <div className="modal-dy-wrap">
                    <div className="modal-time-wrap">
                        <div>{language.getText('auditStatus')}：</div>
                        <Select
                            style={{ width: '200px', marginRight: '20px' }}
                            defaultValue={auditStatusSelect}
                            onChange={this.selectChange}
                        >
                            <Select.Option value="ALL">{language.getText('all')}</Select.Option>
                            <Select.Option value="APPROVED">
                                {language.getText('approved')}
                            </Select.Option>
                            <Select.Option value="REJECTED">
                                {language.getText('rejected')}
                            </Select.Option>
                            <Select.Option value="AUDITING">
                                {language.getText('auditing')}
                            </Select.Option>
                            <Select.Option value="WAITING">
                                {language.getText('waiting')}
                            </Select.Option>
                        </Select>
                        <Button type="primary" onClick={this.handleDySearch}>
                            {language.getText('common.search')}
                        </Button>
                    </div>
                    <br />
                    <div className="sales-electronic-coupon-find">
                        <Table
                            style={{ width: '100%' }}
                            rowSelection={{
                                fixed: true,
                                type: 'checkbox',
                                selectedRowKeys: map(selectedDyRows, 'id'),
                                onSelect: (record: any, selected: boolean, selectedRow: any) => {
                                    this.onTableDyRowSelect(record, selected);
                                },
                                onSelectAll: (selected: boolean, rows: any[]) => {
                                    this.onTableDyRowSelectAll(selected, rows);
                                },
                                getCheckboxProps: (record: any) => {
                                    const item = record.salesChannelInfos.find(
                                        (s: any) => s.salesChannel === 'DOUYIN'
                                    );
                                    let disabled = true;
                                    if (chooseValue === 'SHELF') {
                                        if (
                                            item.shelfStatus !== 'SHELF' &&
                                            item.auditStatus === 'APPROVED'
                                        ) {
                                            disabled = false;
                                        }
                                    } else if (chooseValue === 'OFF_SHELF') {
                                        if (
                                            item.shelfStatus === 'SHELF' &&
                                            item.auditStatus === 'APPROVED'
                                        ) {
                                            disabled = false;
                                        }
                                    }
                                    return {
                                        disabled: disabled,
                                    };
                                },
                            }}
                            rowKey={(record: any) => record.id}
                            dataSource={dataDySource}
                            columns={columns}
                            pagination={{
                                pageSize: 10,
                            }}
                        />
                    </div>
                </div>
            </AntModal>
        );
    };

    disabledDate = (current: any) => {
        return (
            current &&
            current <
                moment()
                    .subtract(1, 'days')
                    .endOf('day')
        );
    };

    range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    disabledTime = (current: any) => {
        const hours = moment().hours();
        const minutes = moment().minutes();
        const currentHour = moment(current).hour();
        if (current && moment(current).date() === moment().date()) {
            return {
                disabledHours: () => this.range(0, 24).splice(0, hours),
                disabledMinutes: () => {
                    if (currentHour === moment().hour()) {
                        return this.range(0, 60).splice(0, minutes);
                    }
                    return [];
                },
                disabledSeconds: () => [],
            };
        }
        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
        };
    };

    handleDateChange = (data: any) => {
        const thisValue = data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '';
        this.setState({
            startDate: thisValue,
        });
    };

    closeModal = () => {
        const { modalVisible } = this.state;
        this.setState({
            startDate: '',
            modalVisible: !modalVisible,
        });
    };
    closeDyModal = () => {
        const { modalDyVisible } = this.state;
        this.setState({
            modalDyVisible: !modalDyVisible,
        });
    };

    submitShelfStatusTime = () => {
        const { chooseValue, startDate, selectedRows } = this.state;
        if (!selectedRows || selectedRows.length === 0) {
            AntMessage.error(language.getText('productSelectRequired'));
            return;
        }
        if (startDate && moment().isAfter(moment(startDate))) {
            AntMessage.error(language.getText('currentTimePassTiming'));
            return;
        }

        const params: any = {
            timingTime: startDate,
            salesChannel: 'OWN',
        };
        params.goodsIds = map(selectedRows, 'id');
        const apiPath =
            chooseValue === 'OFF_SHELF'
                ? '/admin/virtual_products/batch_timing_off_shelf'
                : '/admin/virtual_products/batch_timing_on_shelf';
        api.put(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
            apiPath,
        })
            .then(() => {
                AntMessage.success(
                    `${language.getText('batchTiming')}${
                        chooseValue === 'SHELF'
                            ? language.getText('upShelf')
                            : language.getText('offShelf')
                    }${language.getText('timeSetSuccess')}`
                );
                this.setState({
                    chooseVisible: false,
                    modalVisible: false,
                });
                const { searchParams } = this.state;
                this.onSearch(searchParams);
                this.setState({
                    selectedRows: [],
                });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    submitDyShelfStatus = () => {
        const { chooseValue, selectedDyRows } = this.state;
        if (selectedDyRows.length) {
            this.setState({ selectedRows: selectedDyRows }, () => {
                this.onChangeShelfStatus(chooseValue, 'DOUYIN');
            });
        }
        this.setState({
            modalDyVisible: false,
            selectedDyRows: [],
        });
    };

    getTableColumns = (): ColumnProps<any>[] => {
        const { couponsType } = this.state;
        return [
            {
                title: language.getText('couponInfo'),
                dataIndex: 'pictureUrl',
                key: 'pictureUrl',
                width: 300,
                render: (val: any, record: any) => {
                    const popoverContent = (
                        <div style={{ maxWidth: '300px' }}>{record.productName}</div>
                    );
                    return (
                        <div className="sales-electronic-coupon-find-info">
                            <img alt="" style={{ width: '100px', height: '100px' }} src={val} />
                            <div className="sales-electronic-coupon-find-info-right">
                                {record.productName && record.productName.length > 15 ? (
                                    <Popover content={popoverContent}>
                                        <div className="sales-electronic-coupon-find-info-right-name">
                                            {record.productName}
                                        </div>
                                    </Popover>
                                ) : (
                                    <div className="sales-electronic-coupon-find-info-right-name">
                                        {record.productName}
                                    </div>
                                )}
                                <div className="sales-electronic-coupon-find-info-right-name">
                                    {record.productCode}
                                </div>
                                <div className="sales-electronic-coupon-find-info-right-price">
                                    {services.language.getText('monetaryUnit')}
                                    {record.salesPrice}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: language.getText('saleSubsites'),
                key: 'subsiteName',
                dataIndex: 'subsiteName',
            },
            {
                title: language.getText('couponContent'),
                dataIndex: 'couponType',
                key: 'couponType',
                render: (val, record) => (
                    <span>
                        {find(couponsType, (item: any) => {
                            return item.id === val;
                        })?.name || ''}
                        {record.discountContent ? `（${record.discountContent}）` : ''}
                    </span>
                ),
            },
            {
                title: language.getText('salesChannels'),
                key: 'salesChannelInfos',
                dataIndex: 'salesChannelInfos',
                render: (val, record) => {
                    const columns = [
                        {
                            title: language.getText('salesChannels'),
                            dataIndex: 'salesChannel',
                            key: 'salesChannel',
                            render: (v: any) => {
                                return (
                                    <span>
                                        {v === 'OWN'
                                            ? language.getText('selfOwnChannel')
                                            : language.getText('douyin')}
                                    </span>
                                );
                            },
                        },
                        {
                            title: language.getText('channelProductId'),
                            dataIndex: 'outProductId',
                            key: 'outProductId',
                            render: (v: any) => {
                                return <span>{v || '-'}</span>;
                            },
                        },
                        {
                            title: language.getText('auditStatus'),
                            dataIndex: 'auditStatus',
                            key: 'auditStatus',
                            render: (v: any) => {
                                return <span>{language.getText(auditStatus[v])}</span>;
                            },
                        },
                        {
                            title: language.getText('upAndDownShelfStatus'),
                            dataIndex: 'shelfStatus',
                            key: 'shelfStatus',
                            render: (v: any) => {
                                return <span>{language.getText(shelfStatus[v])}</span>;
                            },
                        },
                        {
                            title: language.getText('auditFailResult'),
                            dataIndex: 'auditRemark',
                            key: 'auditRemark',
                            render: (v: any) => {
                                return <span>{v || '-'}</span>;
                            },
                        },
                        {
                            title: language.getText('auditLog'),
                            dataIndex: 'goodsId',
                            key: 'goodsId',
                            render: (v: any, r: any) => {
                                const url = '#/sales-electronic-coupon/logs/' + record.goodsId;
                                if (r.salesChannel === 'DOUYIN') {
                                    return <a href={url}>{language.getText('common.log')}</a>;
                                }
                            },
                        },
                    ];
                    const popoverContent = (
                        <div style={{ maxWidth: '800px' }}>
                            <h4>{language.getText('salesChannels')}</h4>
                            <Table dataSource={val} columns={columns} />
                        </div>
                    );
                    return (
                        <div>
                            {val &&
                                val.map((v: any, index: number) => {
                                    return (
                                        <p>
                                            {v.salesChannel === 'OWN'
                                                ? language.getText('selfOwnChannel') + '-'
                                                : language.getText('douyin') + '-'}
                                            {language.getText(shelfStatus[v.shelfStatus]) || '-'}
                                            {v.salesChannel === 'OWN'
                                                ? ''
                                                : `(${language.getText(
                                                      auditStatus[v.auditStatus]
                                                  )})`}

                                            {index === 0 && (
                                                <Popover content={popoverContent}>
                                                    <span
                                                        style={{
                                                            marginLeft: '20px',
                                                            color: '#1890ff',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {language.getText('common.view')}
                                                    </span>
                                                </Popover>
                                            )}
                                        </p>
                                    );
                                })}
                        </div>
                    );
                },
            },
            /* {
                title: '优惠内容',
                dataIndex: 'discountContent',
                key: 'discountContent',
                render: (val) => {
                    return <span>{val || '-'}</span>;
                },
            },
            {
                title: '状态',
                dataIndex: 'shelfStatus',
                key: 'shelfStatus',
                render: (val) => {
                    let statusName = '';
                    if (val === 'NONE') {
                        statusName = '未上架';
                    } else if (val === 'OFF_SHELF') {
                        statusName = '已下架';
                    } else if (val === 'SHELF') {
                        statusName = '已上架';
                    }
                    return <span>{statusName}</span>;
                },
            }, */
            {
                title: language.getText('saleStock'),
                key: 'stock',
                dataIndex: 'stock',
            },
            {
                title: language.getText('timingUpShelfTime'),
                key: 'timingShelfTime',
                dataIndex: 'timingShelfTime',
                render: (val) => {
                    return <span>{val || '-'}</span>;
                },
            },
            {
                title: language.getText('timingDownShelfTime'),
                key: 'timingOffShelfTime',
                dataIndex: 'timingOffShelfTime',
                render: (val) => {
                    return <span>{val || '-'}</span>;
                },
            },
            {
                title: language.getText('common.tableAction'),
                className: 'action-column',
                dataIndex: 'id',
                key: 'id',
                fixed: 'right',
                width: 120,
                render: (val: any, record: any) => {
                    return (
                        <div>
                            <a href={`#/sales-electronic-coupon/edit/${record.productId}/${val}`}>
                                {language.getText('common.edit')}
                            </a>
                            <a
                                href={`#/sales-electronic-coupon/copy/${record.productId}/${val}`}
                                style={{ marginLeft: '8px' }}
                            >
                                {language.getText('copy')}
                            </a>
                        </div>
                    );
                },
            },
        ];
    };

    render() {
        const {
            datasource,
            pagination,
            total,
            current,
            selectedRows,
            activeKey,
            subsites,
            couponsType,
        } = this.state;
        const columns = this.getTableColumns();
        return (
            <div className="sales-electronic-coupon-find">
                <div>
                    <CollectionCreateForm
                        onSearch={this.onSearchFind}
                        subsites={subsites}
                        couponsType={couponsType}
                    />
                </div>
                <div className="sales-electronic-coupon-add">
                    <Button type="primary" href="#/sales-electronic-coupon/add">
                        {language.getText('createSaleRule')}
                    </Button>
                </div>
                <AntTabs
                    className="find-tabs"
                    activeKey={activeKey}
                    animated={false}
                    onChange={this.onChange}
                >
                    {activeKeyList &&
                        activeKeyList.map((activeKeyVo) => {
                            return (
                                <AntTabPane
                                    tab={language.getText(activeKeyVo.name)}
                                    key={activeKeyVo.value}
                                >
                                    <div></div>
                                </AntTabPane>
                            );
                        })}
                </AntTabs>
                {this.renderButtons()}
                {this.renderButtonsModal()}
                {this.renderDyModal()}
                <Table
                    scroll={{ x: true }}
                    rowSelection={{
                        fixed: true,
                        type: 'checkbox',
                        selectedRowKeys: map(selectedRows, 'id'),
                        onSelect: (record: any, selected: boolean, selectedRow: any) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                    }}
                    rowKey={(record: any) => record.id}
                    pagination={{
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(language.getText('total'), { total });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={columns}
                    dataSource={datasource}
                />
            </div>
        );
    }
}
