import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from 'antd';
import { PushAndSiteMailSceneType } from '../../../../../../containers/wechat/common';
import {
    MessageNotificationMethodValueItem,
    MsgChannelType,
    LandingPage,
} from '../../message-notification-method';
import { MessageNotificationWeappEditModal } from '../message-notification-weapp-edit-modal/message-notification-weapp-edit-modal';
import { MessageNotificationPushAndSitemailEditModal } from '../message-notification-push-and-sitemail-edit-modal/message-notification-push-and-sitemail-edit-modal';
import { MessageNotificationEmailEditModal } from '../message-notification-email-edit-modal/message-notification-email-edit-modal';
import './message-notification-item-edit.less';

interface Props {
    template: MessageNotificationMethodValueItem;
    scene: PushAndSiteMailSceneType;
    landingPage: LandingPage;
    onChange: (template: MessageNotificationMethodValueItem) => void;
}
interface State {
    editModalVisible: boolean;
}

export class MessageNotificationItemEdit extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            editModalVisible: false,
        };
    }
    onEdit = () => {
        this.setState({
            editModalVisible: true,
        });
    };

    closeModal = () => {
        this.setState({
            editModalVisible: false,
        });
    };

    renderModal = () => {
        const { editModalVisible } = this.state;
        const { template, scene, landingPage, onChange } = this.props;
        const { msgChannelType } = template;
        // 如果是微信消息订阅或者微信公众号模板消息
        const messageAndWechat =
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT ||
            msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG;
        //站内信和push
        const siteMailAndMessage =
            msgChannelType === MsgChannelType.SITEMAIL ||
            msgChannelType === MsgChannelType.APP_PUSH;
        // 邮件
        const emailMessage = msgChannelType === MsgChannelType.EMAIL;
        if (editModalVisible) {
            if (messageAndWechat) {
                return (
                    <MessageNotificationWeappEditModal
                        scene={scene}
                        template={template}
                        visible={editModalVisible}
                        landingPage={landingPage}
                        closeModal={this.closeModal}
                        onChange={onChange}
                    />
                );
            }
            if (siteMailAndMessage) {
                return (
                    <MessageNotificationPushAndSitemailEditModal
                        scene={scene}
                        template={template}
                        visible={editModalVisible}
                        landingPage={landingPage}
                        closeModal={this.closeModal}
                        onChange={onChange}
                    />
                );
            }
            if (emailMessage) {
                return (
                    <MessageNotificationEmailEditModal
                        scene={scene}
                        template={template}
                        visible={editModalVisible}
                        landingPage={landingPage}
                        closeModal={this.closeModal}
                        onChange={onChange}
                    />
                );
            }
        }
        return null;
    };
    render() {
        const { template } = this.props;
        const editDisabled = template.msgStatus === 'DISABLE';
        return (
            <div className={prefix}>
                <div className={classes.buttonWrap}>
                    <Button
                        disabled={editDisabled}
                        className={classes.button}
                        type="primary"
                        onClick={this.onEdit}
                    >
                        {services.language.getText('common.edit')}
                    </Button>
                </div>
                {this.renderModal()}
            </div>
        );
    }
}

const prefix = 'message-notification-item-edit';
const classes = {
    buttonWrap: `${prefix}__button-wrap`,
    button: `${prefix}__button`,
};
