import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import AffiliateMarketingMemberLevel from './affiliate-marketing-member-level';

export class AffiliateMarketingMemberLevelMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <AffiliateMarketingMemberLevel {...controlInfo} />;
    }
}
