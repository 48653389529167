import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        PlatformPageManageView: {
            component: 'Viewport',
            entity: 'PlatformPageManageEntity',
        },
        PlatformPageManageDesign: {
            component: 'Card',
            content: {
                component: 'PageDesign',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/platform_home_pages/:id',
                type: 'platform',
            },
        },
        PlatformPageManage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'PlatformPageManageFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<components.PageManage.createPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/platform-page-manage/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'PlatformPageManageTable',
                },
            ],
        },
        PlatformPageManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: '160px' },
                    },
                },
            ],
            submit: { text: '<<common.search>>' },
        },
        PlatformPageManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'enabled',
                },
                {
                    property: 'pageName',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<components.PageManage.setting>>',
                        path: '/platform-page-manage/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<components.PageManage.decorate>>',
                        path: '/platform-page-manage/design/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'PagePreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'delete',
                        text: '<<components.PageManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        PlatformPageManageAdd: {
            component: 'Card',
            title: '<<components.PageManage.createPage>>',
            entities: [
                { name: 'PlatformPageManageEntity', entityName: 'PlatformPageManageEntity' },
            ],
            content: {
                component: 'PlatformPageManageAddForm',
            },
        },
        PlatformPageManageAddForm: {
            component: 'CreateForm',
            entity: 'PlatformPageManageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'enabled',
                },
                // {
                //     property: 'template',
                //     controlConfig: {
                //         pageType: 'home',
                //     },
                // },
            ],
            labelCol: 8,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/platform-page-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
        PlatformPageManageEdit: {
            component: 'Card',
            title: '<<components.PageManage.setPage>>',
            content: {
                component: 'PlatformPageManageEditForm',
            },
        },
        PlatformPageManageEditForm: {
            component: 'ModifyForm',
            entity: 'PlatformPageManageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'enabled',
                },
                // {
                //     property: 'template',
                //     controlConfig: {
                //         pageType: 'home',
                //     },
                // },
            ],
            loaderType: 'get',
            labelCol: 8,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/platform-page-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/platform-page-manage',
                    component: 'PlatformPageManageView',
                    breadcrumbName: '管理平台首页',
                    privilege: {
                        path: 'platformHomePage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'PlatformPageManageDesign',
                        },
                        {
                            path: '/add',
                            component: 'PlatformPageManageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'PlatformPageManageEdit',
                        },
                        {
                            path: '/',
                            component: 'PlatformPageManage',
                        },
                    ],
                },
            ],
        },
    ],
};
