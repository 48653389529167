import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const StoredValueCardProductLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/stored_value_card_products`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/stored_value_card_products/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    code: res.code,
                    name: res.name,
                    editType: res.type,
                    pictures: res.pictures
                        ? res.pictures.map((picture: any) => {
                              return {
                                  id: picture.id,
                                  path: picture.url,
                              };
                          })
                        : undefined,
                    cardListPicture: res.cardListPicture
                        ? [
                              {
                                  id: res.cardListPicture.id || '',
                                  path: res.cardListPicture.url || '',
                              },
                          ]
                        : undefined,
                };
                res.settingInfo = {
                    amountDefinitions: res.amountDefinitions.map((i: any) => {
                        return {
                            ...i,
                            isOld: true,
                        };
                    }),
                };
                res.useRulesInfo = {
                    description: res.description,
                    details: res.details,
                };
                res.seniorSetting = {
                    shareTitle: res.shareInfo.shareTitle,
                    sharePicture: res.shareInfo.sharePicture
                        ? [
                              {
                                  id: res.shareInfo.sharePicture.id || '',
                                  path: res.shareInfo.sharePicture.url || '',
                              },
                          ]
                        : undefined,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/stored_value_card_products';
        const newParams: any = dataParser(params);
        newParams.type = params.baseInfo.addType;
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/stored_value_card_products/${params.id}`;
        const newParams: any = dataParser(params);
        newParams.type = params.baseInfo.editType;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.code = params.baseInfo.code;
    newParams.name = params.baseInfo.name;
    newParams.pictures = params.baseInfo.pictures.map((picture: any) => {
        return {
            id: picture.id,
            url: picture.path,
        };
    });
    newParams.cardListPicture =
        params.baseInfo.cardListPicture && params.baseInfo.cardListPicture.length > 0
            ? {
                  id: params.baseInfo.cardListPicture[0].id,
              }
            : undefined;
    newParams.amountDefinitions = params.settingInfo.amountDefinitions;
    newParams.description = params.useRulesInfo.description;
    newParams.details = params.useRulesInfo.details;
    newParams.shareInfo = {
        shareTitle: params.seniorSetting.shareTitle,
        sharePicture:
            params.seniorSetting.sharePicture && params.seniorSetting.sharePicture.length > 0
                ? {
                      id: params.seniorSetting.sharePicture[0].id,
                  }
                : undefined,
    };
    return newParams;
}
