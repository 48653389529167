import React, { PureComponent } from 'react';
import { Modal, InputNumber, Table, message as AntMessage } from 'antd';
import { cloneDeep, map, some, forEach, isNumber } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { plus, times } from 'number-precision';
import { SalesPriceAndCouponPackagePricesValue } from './sales-price-and-coupon-package-prices';
import { services } from '@comall-backend-builder/core';

interface CouponPackagePricesDisplayProps {
    visible: boolean;
    value: SalesPriceAndCouponPackagePricesValue;
    onOk: (value: any) => void;
    onCancel: () => void;
}
interface CouponPackagePricesDisplayState {
    sourceData: SalesPriceAndCouponPackagePricesValue;
}

export class CouponPackagePricesDisplay extends PureComponent<
    CouponPackagePricesDisplayProps,
    CouponPackagePricesDisplayState
> {
    constructor(props: CouponPackagePricesDisplayProps) {
        super(props);
        this.state = { sourceData: cloneDeep(props.value) };
    }
    componentWillReceiveProps(props: CouponPackagePricesDisplayProps) {
        this.setState({
            sourceData: cloneDeep(props.value),
        });
    }

    onOk = () => {
        const { sourceData } = this.state;
        const { onOk } = this.props;
        if (some(sourceData.couponPackagePrices, (item: any) => !isNumber(item.apportionPrice))) {
            AntMessage.warning('优惠券礼包售卖时请填写分摊价');
        } else {
            const salesPrice = sourceData.salesPrice || 0;
            let totalPrice = 0;
            forEach(sourceData.couponPackagePrices, (item: any) => {
                totalPrice = plus(totalPrice, times(item.apportionPrice, item.quantity));
            });
            if (salesPrice !== totalPrice) {
                AntMessage.warning('分摊价总和和礼包单卖价不等');
            } else {
                if (onOk) {
                    onOk(sourceData);
                }
            }
        }
    };

    renderTable = () => {
        const { sourceData } = this.state;
        const data =
            sourceData && sourceData.couponPackagePrices.length > 0
                ? sourceData.couponPackagePrices
                : [];
        const limitDecimalsF = (value: any) => {
            return value;
        };
        const limitDecimalsP = (value: any) => {
            let reg = /^(-)*(\d+)\.(\d\d).*$/;
            return value.replace(/\s?|(,*)/g, '').replace(reg, '$1$2.$3');
        };

        const columns: ColumnProps<any>[] = [
            {
                title: services.language.getText('couponName'),
                dataIndex: 'couponName',
                key: 'couponName',
                width: 200,
            },
            {
                title: services.language.getText('couponType'),
                dataIndex: 'couponTypeName',
                key: 'couponTypeName',
                width: 200,
            },
            {
                title: services.language.getText('ffsl'),
                dataIndex: 'quantity',
                key: 'quantity',
                width: 200,
            },
            {
                title: services.language.getText('ftj'),
                dataIndex: 'apportionPrice',
                key: 'apportionPrice',
                width: 300,
                render: (price: number, record: any) => {
                    const disabled =
                        record.couponTypeName === services.language.getText('bfplat') &&
                        record.principal > 0
                            ? true
                            : false;
                    return (
                        <div>
                            <InputNumber
                                style={{ width: 100 }}
                                step={0.01}
                                value={price}
                                min={0}
                                formatter={limitDecimalsF}
                                parser={limitDecimalsP}
                                onChange={(value) => this.onChangePrice(value, record)}
                                disabled={disabled}
                            ></InputNumber>
                        </div>
                    );
                },
            },
        ];

        return <Table scroll={{ x: true }} columns={columns} dataSource={data} rowKey={'id'} />;
    };

    onChangePrice = (value: any, record: any) => {
        const { sourceData } = this.state;
        const newCouponPackagePrices = map(sourceData.couponPackagePrices, (item) => {
            if (record.definitionId === item.definitionId) {
                item.apportionPrice = value;
            }
            return item;
        });
        this.setState({
            sourceData: {
                salesPrice: sourceData.salesPrice,
                couponPackagePrices: newCouponPackagePrices,
            },
        });
    };

    render() {
        const { visible, onCancel } = this.props;
        const { sourceData } = this.state;
        return (
            <Modal
                visible={visible}
                width={900}
                title="设置分摊价格"
                onCancel={onCancel}
                onOk={this.onOk}
            >
                <div
                    style={{
                        width: '100%',
                        height: '50px',
                        lineHeight: '50px',
                    }}
                >
                    售价： {services.language.getText('monetaryUnit')}
                    {sourceData ? sourceData.salesPrice : ''}
                    {services.language.getText('yuan')}
                </div>
                {this.renderTable()}
            </Modal>
        );
    }
}
