import React, { PureComponent } from 'react';
import { Switch, Button, Select, Input, InputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';
import './index.less';

const {
    navigation,
    language: { getText },
} = services;
const { Option } = Select;
const InputGroup = Input.Group;

const ChannelStatusEnum = {
    WEEK: 'WEEK',
    HALF_MONTH: 'HALF_MONTH',
    MONTH: 'MONTH',
    TWO_MONTHS: 'TWO_MONTHS',
    THREE_MONTHS: 'THREE_MONTHS',
    HALF_YEAR: 'HALF_YEAR',
    YEAR: 'YEAR',
    UNLIMITED: 'UNLIMITED',
};
const channelStatusOptions = [
    {
        label: 'h_zj7d',
        value: ChannelStatusEnum.WEEK,
    },
    {
        label: 'h_zj15d',
        value: ChannelStatusEnum.HALF_MONTH,
    },
    {
        label: 'h_zj30d',
        value: ChannelStatusEnum.MONTH,
    },
    {
        label: 'h_zj60d',
        value: ChannelStatusEnum.TWO_MONTHS,
    },
    {
        label: 'h_zj90d',
        value: ChannelStatusEnum.THREE_MONTHS,
    },
    {
        label: 'h_zj180d',
        value: ChannelStatusEnum.HALF_YEAR,
    },
    {
        label: 'h_zj365d',
        value: ChannelStatusEnum.YEAR,
    },
    {
        label: 'bx',
        value: ChannelStatusEnum.UNLIMITED,
    },
];
interface SecurityPolicyConditionValue {
    status: boolean;
    virtualNumber: number;
    virtualNumberStatus: boolean;
    channelTimeRange: string;
    channelExpression: string;
    accountNumber?: number;
    channelStatus: boolean;
}
interface SecurityPolicyConditionProps {
    value?: SecurityPolicyConditionValue;
    onChange: (value: SecurityPolicyConditionValue) => void;
}
const defaultValue = {
    status: false,
    virtualNumber: 0,
    virtualNumberStatus: false,
    channelTimeRange: ChannelStatusEnum.MONTH,
    channelExpression: 'MORE_THAN_OR_EQUAL',
    accountNumber: undefined,
    channelStatus: false,
};

export default class SecurityPolicyCondition extends PureComponent<
    SecurityPolicyConditionProps,
    any
> {
    gotoEdit = () => {
        navigation.goto('/virtual-number-setting/virtual-number-edit');
    };
    handleSwitchChange = (checked: boolean) => {
        const { onChange, value = defaultValue } = this.props;
        onChange({
            ...value,
            status: checked,
        });
    };
    virtualNumberSwitchChange = (checked: boolean) => {
        const { onChange, value = defaultValue } = this.props;
        onChange({
            ...value,
            virtualNumberStatus: checked,
        });
    };
    channelSwitchChange = (checked: boolean) => {
        const { onChange, value = defaultValue } = this.props;
        onChange({
            ...value,
            channelStatus: checked,
        });
    };
    channelDayChange = (day: string) => {
        const { onChange, value = defaultValue } = this.props;
        onChange({
            ...value,
            channelTimeRange: day,
        });
    };
    channelSystemChange = (system: string) => {
        const { onChange, value = defaultValue } = this.props;
        onChange({
            ...value,
            channelExpression: system,
        });
    };
    channelCountChange = (count?: number) => {
        const { onChange, value = defaultValue } = this.props;
        onChange({
            ...value,
            accountNumber: count,
        });
    };
    render() {
        const { value } = this.props;
        return (
            <div className="security-policy-condition">
                <div>
                    <Switch
                        checkedChildren={getText('kai')}
                        unCheckedChildren={getText('guan')}
                        onChange={this.handleSwitchChange}
                        checked={value?.status}
                    />
                </div>
                <div className="security-policy-condition__number">
                    <div>
                        {getText('pbxnhd')}（{value?.virtualNumber}）
                    </div>
                    <Button style={{ marginLeft: 14 }} type="link" onClick={this.gotoEdit}>
                        {getText('common.edit')}
                    </Button>
                    <div className="security-policy-condition__number__switch">
                        <Switch
                            onChange={this.virtualNumberSwitchChange}
                            checked={value?.virtualNumberStatus}
                        />
                    </div>
                </div>
                <div className="security-policy-condition__channel">
                    <div style={{ width: 100 }}>{getText('qdbs')}：</div>
                    <div className="security-policy-condition__channel__main">
                        <div className="security-policy-condition__channel__main__row">
                            <Select
                                value={value?.channelTimeRange}
                                defaultValue={ChannelStatusEnum.MONTH}
                                style={{ width: 120 }}
                                onChange={this.channelDayChange}
                                size="small"
                            >
                                {channelStatusOptions.map((item) => (
                                    <Option value={item.value}>{getText(item.label)}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="security-policy-condition__channel__main__row">
                            <div
                                style={{
                                    width: '200px',
                                    height: '20px',
                                    lineHeight: '20px',
                                    flexShrink: 0,
                                }}
                            >
                                {getText('yhzdlqdwysfbs')}
                            </div>
                            <InputGroup compact style={{ marginRight: 10 }}>
                                <Select
                                    value={value?.channelExpression}
                                    defaultValue="MORE_THAN_OR_EQUAL"
                                    style={{ width: 90 }}
                                    onChange={this.channelSystemChange}
                                    size="small"
                                >
                                    <Option value="MORE_THAN">{getText('dy_1')}</Option>
                                    <Option value="MORE_THAN_OR_EQUAL">{getText('dydy')}</Option>
                                </Select>
                                <InputNumber
                                    value={value?.accountNumber}
                                    size="small"
                                    min={1}
                                    precision={0}
                                    style={{ width: 100 }}
                                    onChange={this.channelCountChange}
                                />
                            </InputGroup>
                            <div
                                style={{
                                    width: '100px',
                                    height: '20px',
                                    lineHeight: '20px',
                                    flexShrink: 0,
                                }}
                            >
                                {getText('hyzhsl')}
                            </div>
                        </div>
                    </div>
                    <div className="security-policy-condition__channel__switch">
                        <Switch
                            onChange={this.channelSwitchChange}
                            checked={value?.channelStatus}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
