import React, { PureComponent, Fragment } from 'react';
import { Tabs } from 'antd';
import { CGBRecruitLeaderDesign } from '../cgb-recruit-leader-design';
import { CgbRecruitLeaderCode } from '../cgb-recruit-leader-code';
import { CGBLeaderSetup } from '../../applications/design/components/cgb-leader-setup';
import './index.less';
import { services } from '@comall-backend-builder/core';
const { TabPane } = Tabs;

export class CGBRecruitLeader extends PureComponent<any, any> {
    render() {
        return (
            <Fragment>
                <Tabs onChange={() => {}} type="card" className="cgb-recruit-leader-container">
                    <TabPane
                        tab={services.language.getText('zmtz')}
                        key="1"
                        className="cgb-recruit-leader-wrap"
                    >
                        <CGBRecruitLeaderDesign />
                        <CgbRecruitLeaderCode />
                    </TabPane>
                    <TabPane
                        tab={services.language.getText('sqsz')}
                        key="2"
                        className="cgb-recruit-leader-wrap"
                    >
                        <CGBLeaderSetup />
                    </TabPane>
                </Tabs>
            </Fragment>
        );
    }
}
