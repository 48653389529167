import React, { PureComponent } from 'react';
import './index.less';
import { services } from '@comall-backend-builder/core';

/**
 * 可视化组件选择器
 */
export class CustomComponentSelector extends PureComponent<any> {
    baseComponents = [
        { id: 'wfjIndexSubsite', name: '门店信息' },
        {
            id: 'searchBar',
            name: '搜索框',
        },
        {
            id: 'categoryNavigate',
            name: '分组导航',
        },
        {
            id: 'imageAd',
            name: '图片广告',
        },
        {
            id: 'nav',
            name: '导航',
        },
        {
            id: 'showcase',
            name: '橱窗',
        },
        {
            id: 'products',
            name: '商品',
        },
        {
            id: 'title',
            name: '标题',
        },
        {
            id: 'text',
            name: '文本',
        },
        {
            id: 'divider',
            name: services.language.getText('fzx'),
        },
        {
            id: 'space',
            name: '辅助空白',
        },
        {
            id: 'noticeBar',
            name: '通知栏',
        },
        {
            id: 'contentNoteIndex',
            name: '笔记',
        },
        {
            id: 'virtualProducts',
            name: '虚拟商品',
        },
    ];
    marketingComponents = [
        { id: 'flashProducts', name: '秒杀' },
        { id: 'coupon', name: '优惠券' },
        { id: 'presale', name: '预售' },
    ];
    othersComponents = [{ id: 'weixin', name: '关注公众号' }];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        if (ENV.TENANT === 'jml') {
            this.baseComponents.push(
                {
                    id: 'homeMemberCard',
                    name: '会员卡',
                },
                {
                    id: 'livePreviewCard',
                    name: '直播',
                }
            );
            this.marketingComponents.push({
                id: 'flashProductsPlus',
                name: '秒杀2',
            });
        }
        const base = this.baseComponents;
        const marketing = this.marketingComponents;
        const others = this.othersComponents;

        return (
            <div className="custom-component-selector">
                <div className="group">
                    <div className="group-title">基础组件</div>
                    <div className="group-content">
                        {base.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                        <br />
                        <div
                            className="item-waterfall"
                            onClick={this.onSelect('waterfallProducts')}
                        >
                            商品瀑布流
                        </div>
                        <div className="item-tip">
                            瀑布流组件仅可添加在置底位置且只能添加1个，避免前端展示错误
                        </div>
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">营销组件</div>
                    <div className="group-content">
                        {marketing.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">其他组件</div>
                    <div className="group-content">
                        {others.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
