import React from 'react';

interface Props {
    value: {
        accountName: string;
        accountNumber: string;
        accountType: 'DOMESTIC' | 'FOREIGN';
        allocationRatio: number;
        sequence: number;
    }[];
}

const accountTypeLabels = {
    DOMESTIC: '境内账户',
    FOREIGN: '境外账户',
};

export default function AllocationAccountRatioDisplay(props: Props) {
    return (
        <div>
            {props.value.map(({ accountName, allocationRatio, accountType }) => (
                <div>{`${accountName} ${allocationRatio}% ${accountTypeLabels[accountType]}`}</div>
            ))}
        </div>
    );
}
