import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { CarparkCheckoutTypeConfig } from '../../../components';

export class ObjectCarparkCheckoutTypeMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CarparkCheckoutTypeConfig {...controlInfo}></CarparkCheckoutTypeConfig>;
    }
}
