import { services } from '@comall-backend-builder/core';
import { NEW_CLIENT_DESIGN_PAGE_TYPES } from '../../constants/page-design';

export const TemplateTabs = (component: any) => {
    const templateTabItems = [
        {
            title: '<<all>>',
            content: {
                component: component,
            },
            params: {},
        },
    ];
    for (const key in NEW_CLIENT_DESIGN_PAGE_TYPES) {
        templateTabItems.push({
            //@ts-ignore
            title: NEW_CLIENT_DESIGN_PAGE_TYPES[key],
            content: {
                component: component,
            },
            params: { type: key },
        });
    }
    return {
        component: 'Tabs',
        cache: false,
        animated: false,
        items: templateTabItems,
    };
};
export const TemplateTabPane = {
    component: 'DataList',
    loadFirstPage: true,
    pagination: {
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal(total: number) {
            return services.interpolate(services.language.getText('total'), {
                total,
            });
        },
    },
    grid: { gutter: 16, column: 5 },
    renderItem: 'PageTemplateListItem',
};
