import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Button as AntButton, Form, Select, message, Spin, Input, Radio } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import classNames from 'classnames';
import { api, errorHandle, navigation } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, isNil, remove } from 'lodash';
import moment from 'moment';
import { List, MetricDayEnum, ConditionType, ListItem } from './list';
import { language } from '@comall-backend-builder/core/lib/services';
import './rule.less';
import { services } from '@comall-backend-builder/core';

const RadioGroup = Radio.Group;

export interface CustomCrowdRuleProps {
    id: string | number;
    row: any;
    entity: Entity;
    params: any;
    form: FormComponentProps['form'];
}
interface CustomCrowdRuleState {
    loading: boolean;
    setList: ListItem[];
    status: boolean;
    subsites: any[];
    baseOptions: Array<any>;
    flowOptions: Array<any>;
    consumptionOptions: Array<any>;
    behaviorOptions: Array<any>;
    disabled: boolean;
    estimateLoading: boolean;
    levelOptions: any[];
}

interface CrowdRule {
    conditionType: ConditionType;
    conditions: CrowdRuleCondition[];
}

interface CrowdRuleCondition {
    conditionName: string;
    [key: string]: any;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 11 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    },
};
const tailFormAddLayout = {
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 10,
        },
        sm: {
            span: 18,
        },
    },
};

class CustomCrowdRule extends PureComponent<CustomCrowdRuleProps, CustomCrowdRuleState> {
    state: CustomCrowdRuleState = {
        loading: false,
        status: false,
        setList: [],
        subsites: [],
        baseOptions: [
            {
                id: ConditionType.MemberSex,
                name: language.getText('xb_1'),
                selected: false,
            },
            {
                id: ConditionType.MemberBirthday,
                name: language.getText('birthday'),
                selected: false,
            },
            {
                id: ConditionType.MemberLevel,
                name: language.getText('dj_2'),
                selected: false,
            },
            {
                id: ConditionType.BindLicencePlate,
                name: language.getText('ybdcp'),
                selected: false,
            },
            {
                id: ConditionType.MemberTag,
                name: language.getText('bq'),
                selected: false,
            },
        ],
        consumptionOptions: [
            {
                id: ConditionType.DealDay,
                name: language.getText('zjxfsj'),
                selected: false,
            },
            {
                id: ConditionType.DealAmount,
                name: language.getText('xfje'),
                selected: false,
            },
            {
                id: ConditionType.DealCount,
                name: language.getText('xfcs'),
                selected: false,
            },
        ],
        behaviorOptions: [
            {
                id: ConditionType.VisitDay,
                name: language.getText('ddts'),
                selected: false,
            },
        ],
        flowOptions: [
            {
                id: ConditionType.RegisterChannel,
                name: language.getText('zcqd'),
                selected: false,
            },
            {
                id: ConditionType.RegisterSubsite,
                name: language.getText('zcmd'),
                selected: false,
            },
            {
                id: ConditionType.RegisterTime,
                name: language.getText('zcsj'),
                selected: false,
            },
        ],
        disabled: false,
        estimateLoading: false,
        levelOptions: [], //等级
    };
    componentDidMount() {
        this.getSubsites();
    }
    getSubsites() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites: any) => {
            this.setState({
                subsites,
            });
        });
        this.getInitData();
    }

    getLevels = (subsiteId: string) => {
        if (!subsiteId) {
            return;
        }
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/level_plans/subsite/${subsiteId}`,
            }
        ).then((res: any) => {
            let levels = res.map((i: any) => {
                i.value = i.id.toString();
                i.name = i.levelName;
                return i;
            });
            this.setState({ levelOptions: levels });
        });
    };

    getInitData() {
        const {
            params: { id },
            form,
        } = this.props;
        if (!id) {
            const initFormValue = {
                crowdName: '',
                subsiteId: undefined,
                creationMode: 'RULE_MODE',
                crowdRule: [],
                peopleNumber: undefined,
            };
            form.setFieldsValue(initFormValue);
            return;
        }
        this.setState({ disabled: true, loading: true });
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: ` /admin/crowds/${id}`,
            }
        )
            .then((data: any) => {
                const {
                    baseOptions,
                    flowOptions,
                    consumptionOptions,
                    behaviorOptions,
                } = this.state;
                //标签选中状态
                const baseList = cloneDeep(baseOptions);

                //流量行为
                const flowList = cloneDeep(flowOptions);
                // 消费行为
                const consumptionList = cloneDeep(consumptionOptions);
                // 互动行为
                const behaviorList = cloneDeep(behaviorOptions);
                const list: ListItem[] = [];
                const formValue = {
                    crowdName: data?.crowdName || '',
                    subsiteId: data?.subsiteId || '',
                    creationMode: data?.creationMode || '',
                    crowdRule: [],
                    peopleNumber: data?.peopleNumber || 0,
                };
                if (data?.creationMode === 'RULE_MODE') {
                    this.getLevels(data?.subsiteId);
                }
                if (!isNil(data.crowdRule)) {
                    const rules = JSON.parse(data.crowdRule);
                    rules.forEach((rule: CrowdRule) => {
                        const row = [
                            ...baseList,
                            ...flowList,
                            ...consumptionList,
                            ...behaviorList,
                        ].find((b: any) => b.id === rule.conditionType);
                        if (row) {
                            row.selected = true;
                        }
                        let item: ListItem = {
                            id: rule.conditionType,
                        };
                        if (
                            rule.conditionType === ConditionType.MemberSex || //性别
                            rule.conditionType === ConditionType.BindLicencePlate || //绑定车牌
                            rule.conditionType === ConditionType.RegisterChannel || //注册渠道
                            rule.conditionType === ConditionType.RegisterSubsite //注册门店
                        ) {
                            item.value = rule.conditions[0].conditionName;
                            item.selectMode = '';
                        }
                        //生日
                        if (rule.conditionType === ConditionType.MemberBirthday) {
                            item.selectMode = rule.conditions[0].conditionName;
                            //指定生日日期
                            if (rule.conditions[0].conditionName === 'APPOINT_BIRTHDAY_DATE') {
                                item.value = {
                                    from: rule?.conditions[0].min || '',
                                    to: rule?.conditions[0].max || '',
                                };
                            }
                            //生日距当前天数
                            if (rule.conditions[0].conditionName === 'BIRTHDAY_LAST_DAY') {
                                item.value = isNil(rule.conditions[0].lastDay)
                                    ? undefined
                                    : rule.conditions[0].lastDay;
                            }
                            //指定出生日期
                            if (rule.conditions[0].conditionName === 'APPOINT_BIRTH_DATE') {
                                item.value =
                                    rule && rule.conditions?.length && rule.conditions[0].min
                                        ? {
                                              start: rule.conditions[0]?.min || '',
                                              end: rule.conditions[0]?.max || '',
                                          }
                                        : undefined;
                            }
                        }
                        //等级
                        if (rule.conditionType === ConditionType.MemberLevel) {
                            item.value =
                                rule?.conditions.map((item: any) => {
                                    return item.conditionName;
                                }) || [];
                        }
                        //标签
                        if (rule.conditionType === ConditionType.MemberTag) {
                            item.value = rule.conditions[0]?.value || [];
                        }
                        //注册时间
                        if (rule.conditionType === ConditionType.RegisterTime) {
                            item.selectMode = rule.conditions[0].conditionName;
                            //注册距当前天数
                            if (rule.conditions[0].conditionName === 'REGISTER_LAST_DAY') {
                                item.value = rule.conditions[0]?.lastDay || undefined;
                            }
                            //指定注册时间
                            if (rule.conditions[0].conditionName === 'APPOINT_REGISTER_DATE') {
                                item.value =
                                    rule && rule.conditions?.length && rule.conditions[0].min
                                        ? {
                                              start: rule.conditions[0]?.min || '',
                                              end: rule.conditions[0]?.max || '',
                                          }
                                        : undefined;
                            }
                        }
                        // 最近消费时间 消费金额 到店天数
                        if (
                            rule.conditionType === ConditionType.DealDay ||
                            rule.conditionType === ConditionType.DealAmount ||
                            rule.conditionType === ConditionType.DealCount ||
                            rule.conditionType === ConditionType.VisitDay
                        ) {
                            item.selectMode = '';
                            item.value = {
                                lastDay: rule.conditions[0]?.conditionName,
                                min: rule.conditions[0]?.min,
                                max: rule.conditions[0]?.max,
                            };
                        }

                        list.push(item);
                    });
                }
                this.setState({
                    setList: list,
                    baseOptions: baseList,
                    flowOptions: flowList,
                    consumptionOptions: consumptionList,
                    behaviorOptions: behaviorList,
                    loading: false,
                });
                form.setFieldsValue(formValue);
            })
            .catch((error) => {
                this.setState({ loading: false });
                errorHandle(error);
            });
    }
    handleSubmit = async (e: any) => {
        e.preventDefault();
        const values = await this.validator();
        if (!values) {
            return;
        }
        this.setState({ loading: true });
        const {
            params: { id },
        } = this.props;
        const params = this.dataParser(values);
        const method = id ? 'put' : 'post';
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        const apiPath = id ? `/admin/crowds/${id}` : '/admin/crowds';
        api[method](params, { apiRoot, apiPath })
            .then(() => {
                this.setState({ loading: false });
                message.success(language.getText('common.saveSuccess'));
                navigation.goBack();
            })
            .catch((error) => {
                this.setState({ loading: false });
                errorHandle(error);
            });
    };
    validator = () => {
        return new Promise<any>((resovle) => {
            const { form } = this.props;
            form.validateFields((err, values) => {
                if (err || !this.validateRule(values)) {
                    resovle(null);
                }
                resovle(values);
            });
        });
    };
    validateRule = (values: any): boolean => {
        const { setList } = this.state;
        const { creationMode } = values;
        let isError = '';
        if (creationMode && creationMode === 'RULE_MODE') {
            if (!setList || (setList && setList.length === 0)) {
                message.error(services.language.getText('qzsxzygrqtz'));
                return false;
            }
            setList.forEach((item: ListItem) => {
                //生日
                if (item.id === ConditionType.MemberBirthday) {
                    if (
                        !item.selectMode ||
                        (item.selectMode === 'BIRTHDAY_LAST_DAY' && isNil(item.value)) || //生日距当前天数
                        (item.selectMode === 'APPOINT_BIRTH_DATE' &&
                            (!item.value || !item?.value?.start || !item?.value?.end)) //指定出生日期
                    ) {
                        isError = language.getText('jxxwbtqwsxx');
                        return;
                    }

                    if (item.selectMode === 'APPOINT_BIRTHDAY_DATE') {
                        if (!item.value || !item?.value?.from || !item?.value?.to) {
                            //指定生日日期
                            isError = language.getText('jxxwbtqwsxx');
                            return;
                        }
                        const from = moment(item.value.from, 'MM-DD');
                        const to = moment(item.value.to, 'MM-DD');
                        if (from.isAfter(to)) {
                            isError = language.getText('qsrqbnwyjzrq');
                            return;
                        }
                    }
                }
                //等级
                if (
                    item.id === ConditionType.MemberLevel &&
                    (!item.value || (item?.value && item?.value?.length === 0))
                ) {
                    isError = language.getText('jxxwbtqwsxx');
                    return;
                }
                //标签
                if (
                    item.id === ConditionType.MemberTag &&
                    (!item.value || (item?.value && item?.value?.length === 0))
                ) {
                    isError = language.getText('jxxwbtqwsxx');
                    return;
                }
                //注册时间
                if (
                    item.id === ConditionType.RegisterTime &&
                    (!item.selectMode ||
                    (item.selectMode === 'REGISTER_LAST_DAY' && isNil(item.value)) || //注册距当前天数
                        (item.selectMode === 'APPOINT_REGISTER_DATE' &&
                            (!item.value || !item?.value?.start || !item?.value?.end))) //指定注册时间
                ) {
                    isError = language.getText('jxxwbtqwsxx');
                    return;
                }
                if (
                    !item.value &&
                    (item.id === ConditionType.MemberSex || //性别
                    item.id === ConditionType.BindLicencePlate || //绑定车牌
                    item.id === ConditionType.RegisterChannel || //注册渠道
                        item.id === ConditionType.RegisterSubsite) //注册门店
                ) {
                    isError = language.getText('jxxwbtqwsxx');
                    return;
                }
                if (
                    (item.id === ConditionType.DealDay ||
                        item.id === ConditionType.DealAmount ||
                        item.id === ConditionType.DealCount ||
                        item.id === ConditionType.VisitDay) &&
                    !item.value?.lastDay
                ) {
                    isError = language.getText('jxxwbtqwsxx');
                    return;
                }
                if (
                    item.id === ConditionType.DealAmount ||
                    item.id === ConditionType.DealCount ||
                    item.id === ConditionType.VisitDay
                ) {
                    if (isNil(item.value.min) || isNil(item.value.max)) {
                        isError = language.getText('jxxwbtqwsxx');
                        return;
                    } else {
                        if (Number(item.value.min) > Number(item.value.max)) {
                            isError = language.getText('szdzxzbndyzdz');
                            return;
                        }
                    }
                }
            });
        }
        if (isError) {
            message.error(isError);
            return false;
        }
        return true;
    };
    onEstimate = async (e: any) => {
        e.preventDefault();
        const values = await this.validator();
        if (!values) {
            return;
        }
        const { subsiteId } = values;
        const { form } = this.props;
        const crowdRule = this.getCrowdRuleJson();
        this.setState({ estimateLoading: true });
        api.post(
            {
                crowdRule,
                subsiteId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/crowd_members/estimate',
            }
        )
            .then((res) => {
                if (!isNil(res)) {
                    form.setFieldsValue({ peopleNumber: res });
                }
                this.setState({ estimateLoading: false });
            })
            .catch((error) => {
                this.setState({ estimateLoading: false });
                errorHandle(error);
            });
    };

    getCrowdRuleJson = () => {
        const { setList } = this.state;
        const crowdRule: CrowdRule[] = [];
        setList.forEach((item: ListItem) => {
            const rule: CrowdRule = {
                conditionType: item.id,
                conditions: [],
            };
            switch (item.id) {
                case ConditionType.MemberSex:
                case ConditionType.BindLicencePlate:
                case ConditionType.RegisterChannel:
                case ConditionType.RegisterSubsite:
                    rule.conditions.push({
                        conditionName: item.value,
                    });
                    break;
                case ConditionType.MemberBirthday:
                    let birthdayCondition: CrowdRuleCondition = {
                        conditionName: item.selectMode,
                    };
                    //指定生日日期
                    if (item.selectMode === 'APPOINT_BIRTHDAY_DATE') {
                        birthdayCondition.min = item?.value?.from || '';
                        birthdayCondition.max = item?.value?.to || '';
                    }
                    //生日距当前天数
                    if (item.selectMode === 'BIRTHDAY_LAST_DAY') {
                        birthdayCondition.lastDay = item.value;
                    }
                    //指定出生日期
                    if (item.selectMode === 'APPOINT_BIRTH_DATE') {
                        birthdayCondition.min = item?.value?.start || '';
                        birthdayCondition.max = item?.value?.end || '';
                    }
                    rule.conditions.push(birthdayCondition);
                    break;
                case ConditionType.MemberLevel:
                    rule.conditions = item?.value.map((level: any) => {
                        return {
                            conditionName: level,
                        };
                    });
                    break;
                case ConditionType.MemberTag:
                    rule.conditions.push({
                        conditionName: item.selectMode,
                        value: item.value,
                    });
                    break;
                case ConditionType.RegisterTime:
                    let registerTimeCondition: CrowdRuleCondition = {
                        conditionName: item.selectMode,
                    };
                    //注册距当前天数
                    if (item.selectMode === 'REGISTER_LAST_DAY') {
                        registerTimeCondition.lastDay = item.value;
                    }
                    //指定注册时间
                    if (item.selectMode === 'APPOINT_REGISTER_DATE') {
                        registerTimeCondition.min = item?.value?.start || '';
                        registerTimeCondition.max = item?.value?.end || '';
                    }
                    rule.conditions.push(registerTimeCondition);
                    break;
                case ConditionType.DealDay:
                case ConditionType.DealAmount:
                case ConditionType.DealCount:
                case ConditionType.VisitDay:
                    rule.conditions.push({
                        conditionName: item.value.lastDay,
                        min: item.value?.min,
                        max: item.value?.max,
                    });
                    break;
                default:
                    break;
            }
            crowdRule.push(rule);
        });
        return JSON.stringify(crowdRule);
    };
    getTagIds = () => {
        const { setList } = this.state;
        const tagItem = setList.find((i) => i.id === ConditionType.MemberTag);
        if (!tagItem?.value?.length) {
            return undefined;
        }
        return tagItem.value.map((tag: any) => tag.id);
    };
    /**
     * 处理修改和新增接口数据格式
     * @param params
     */
    dataParser = (params: any) => {
        const newParams: any = {
            crowdName: params.crowdName,
            subsiteId: params.subsiteId,
            creationMode: params.creationMode,
            peopleNumber: params?.peopleNumber || 0,
        };
        //导入指定用户
        if (params.creationMode === 'IMPORT_MEMBER_MODE') {
            newParams.crowdRule = null;
        }
        //规则创建
        if (params.creationMode === 'RULE_MODE') {
            newParams.crowdRule = this.getCrowdRuleJson();
            const tagIds = this.getTagIds();
            if (tagIds && tagIds.length) {
                newParams.tagIds = tagIds;
            }
        }

        return newParams;
    };
    changeValue = (value: any, item: ListItem, valueType: string) => {
        const type = item.id;
        switch (type) {
            //性别
            case ConditionType.MemberSex:
                item.value = value;
                break;
            //生日
            case ConditionType.MemberBirthday:
                if (valueType === 'value') {
                    item.value = value;
                } else {
                    item.selectMode = value;
                    switch (value) {
                        case 'APPOINT_BIRTHDAY_DATE':
                            item.value = {
                                from: '',
                                to: '',
                            };
                            break;
                        case 'BIRTHDAY_LAST_DAY':
                            item.value = undefined;
                            break;
                        case 'APPOINT_BIRTH_DATE':
                            item.value = undefined;
                            break;
                        default:
                            break;
                    }
                }
                break;
            //等级
            case ConditionType.MemberLevel:
                item.value = value;
                break;
            // 已绑定车牌
            case ConditionType.BindLicencePlate:
                item.value = value;
                break;
            //标签
            case ConditionType.MemberTag:
                item.value = value;
                break;
            //注册渠道
            case ConditionType.RegisterChannel:
                item.value = value;
                break;
            //注册门店
            case ConditionType.RegisterSubsite:
                item.value = value;
                break;
            //注册时间
            case ConditionType.RegisterTime:
                if (valueType === 'value') {
                    item.value = value;
                } else {
                    item.selectMode = value;
                    item.value = undefined;
                }
                break;
            case ConditionType.DealDay:
                item.value = value;
                break;
            case ConditionType.DealAmount:
                item.value = value;
                break;
            case ConditionType.DealCount:
                item.value = value;
                break;
            case ConditionType.VisitDay:
                item.value = value;
                break;
            default:
                break;
        }
        const { setList } = this.state;
        const newList: any = cloneDeep(setList);
        const index = newList.findIndex((l: any) => l.id === item.id);
        if (index !== -1) {
            newList[index] = item;
        }
        this.setState({
            setList: [...newList],
        });
    };
    onDelete = (item: ListItem) => {
        const {
            setList,
            baseOptions,
            flowOptions,
            consumptionOptions,
            behaviorOptions,
        } = this.state;
        const newList: any = cloneDeep(setList);
        remove(newList, (i: any) => {
            return i.id === item.id;
        });
        //标签选中状态
        const baseList = cloneDeep(baseOptions);
        const baseRow = baseList.find((b: any) => b.id === item.id);
        if (baseRow) {
            baseRow.selected = false;
        }
        //流量行为
        const flowList = cloneDeep(flowOptions);
        const flowRow = flowList.find((b: any) => b.id === item.id);
        if (flowRow) {
            flowRow.selected = false;
        }
        // 消费行为
        const consumptionList = cloneDeep(consumptionOptions);
        const consumptionRow = consumptionList.find((b: any) => b.id === item.id);
        if (consumptionRow) {
            consumptionRow.selected = false;
        }
        //互动行为
        const behaviorList = cloneDeep(behaviorOptions);
        const behaviorRow = behaviorList.find((b: any) => b.id === item.id);
        if (behaviorRow) {
            behaviorRow.selected = false;
        }
        this.setState({
            setList: newList,
            baseOptions: baseList,
            flowOptions: flowList,
            consumptionOptions: consumptionList,
            behaviorOptions: behaviorList,
        });
    };
    getOptions = (type: string) => {
        const { baseOptions, flowOptions, consumptionOptions, behaviorOptions } = this.state;
        switch (type) {
            case 'base':
                return baseOptions;
            case 'flow':
                return flowOptions;
            case 'consumption':
                return consumptionOptions;
            case 'behavior':
                return behaviorOptions;
            default:
                return [];
        }
    };
    onConditionClick = (item: any, type: string) => {
        const { baseOptions, flowOptions, consumptionOptions, behaviorOptions } = this.state;
        const options = this.getOptions(type);
        const row = options.find((o) => o.id === item.id);
        if (row?.selected) {
            return;
        }
        if (row) {
            row.selected = !row.selected;
        }
        const { setList } = this.state;
        let defalutValue = undefined;
        if (item.id === ConditionType.DealDay) {
            defalutValue = {
                lastDay: MetricDayEnum.Last7,
            };
        } else if (item.id === ConditionType.DealAmount) {
            defalutValue = {
                lastDay: MetricDayEnum.Last7,
                min: '',
                max: '',
            };
        } else if (item.id === ConditionType.DealCount) {
            defalutValue = {
                lastDay: MetricDayEnum.Last7,
                min: '',
                max: '',
            };
        } else if (item.id === ConditionType.VisitDay) {
            defalutValue = {
                lastDay: MetricDayEnum.Last7,
                min: '',
                max: '',
            };
        }
        this.setState({
            baseOptions: type === 'base' ? options : baseOptions,
            flowOptions: type === 'flow' ? options : flowOptions,
            consumptionOptions: type === 'consumption' ? options : consumptionOptions,
            behaviorOptions: type === 'behavior' ? options : behaviorOptions,
            setList: [
                ...setList,
                {
                    selectMode: '',
                    id: item.id,
                    value: defalutValue,
                },
            ],
        });
    };
    onSubSiteIdChange = (value: any) => {
        this.getLevels(value);
        const initLevels = {
            id: ConditionType.MemberLevel,
            selectMode: '',
            value: [],
        };
        this.changeValue([], initLevels, 'value');
    };
    render() {
        const {
            form: { getFieldDecorator, getFieldValue },
        } = this.props;
        const {
            setList,
            subsites,
            loading,
            baseOptions,
            flowOptions,
            disabled,
            estimateLoading,
            levelOptions,
            behaviorOptions,
            consumptionOptions,
        } = this.state;
        let subSiteId = getFieldValue('subsiteId');
        let peopleNumber = getFieldValue('peopleNumber');

        return (
            <Spin spinning={loading}>
                <Form
                    {...formItemLayout}
                    onSubmit={this.handleSubmit}
                    className="custom-crowd-form"
                    style={{ width: '100%' }}
                >
                    <div className="rule-main">
                        <div style={{ width: '50%' }}>
                            <Form.Item label={language.getText('peopleName')}>
                                {getFieldDecorator('crowdName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText('qsrrqmc'),
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder={language.getText('jqsrzd30gzf')}
                                        maxLength={30}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label={language.getText('subsites')}>
                                {getFieldDecorator('subsiteId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText('selectSubsite'),
                                        },
                                    ],
                                })(
                                    <Select
                                        disabled={disabled}
                                        className="find-filter-width"
                                        placeholder={services.language.getText('selectPlease')}
                                        onChange={(value) => this.onSubSiteIdChange(value)}
                                    >
                                        {subsites &&
                                            subsites.map((subsite: any) => {
                                                return (
                                                    <Select.Option
                                                        key={subsite.id}
                                                        value={subsite.id}
                                                    >
                                                        {subsite.name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label={language.getText('createType')}>
                                {getFieldDecorator('creationMode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText('qxzcjfs'),
                                        },
                                    ],
                                })(
                                    <RadioGroup disabled={disabled} className="fill-style">
                                        <Radio value={'RULE_MODE'}>
                                            {language.getText('gzcj')}
                                        </Radio>
                                        <Radio value={'IMPORT_MEMBER_MODE'}>
                                            {language.getText('drzdyh')}
                                        </Radio>
                                    </RadioGroup>
                                )}
                            </Form.Item>
                            {getFieldValue('creationMode') === 'RULE_MODE' ? (
                                <div className="characteristic-main">
                                    <p>{language.getText('xzrqtz')}：</p>
                                    <div className="box">
                                        <div className="item-main">
                                            <div className="title">
                                                {language.getText('otherBaseInfo')}：
                                            </div>
                                            <div className="options-main">
                                                {baseOptions.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            onClick={(event) =>
                                                                this.onConditionClick(item, 'base')
                                                            }
                                                            className={classNames('btn', {
                                                                'selected-btn': item.selected,
                                                            })}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="item-main">
                                            <div className="title">
                                                {language.getText('xfxw')}：
                                            </div>
                                            <div className="options-main">
                                                {consumptionOptions.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            onClick={(event) =>
                                                                this.onConditionClick(
                                                                    item,
                                                                    'consumption'
                                                                )
                                                            }
                                                            className={classNames('btn', {
                                                                'selected-btn': item.selected,
                                                            })}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="item-main">
                                            <div className="title">
                                                {language.getText('hdxw')}：
                                            </div>
                                            <div className="options-main">
                                                {behaviorOptions.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            onClick={(event) =>
                                                                this.onConditionClick(
                                                                    item,
                                                                    'behavior'
                                                                )
                                                            }
                                                            className={classNames('btn', {
                                                                'selected-btn': item.selected,
                                                            })}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="items-main">
                                            <div style={{ marginBottom: 10 }}>
                                                {language.getText('llxw')}：
                                            </div>
                                            <div className="item-main">
                                                <div className="title">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {language.getText('register')}：
                                                </div>
                                                <div className="options-main">
                                                    {flowOptions.map((item, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                onClick={(event) =>
                                                                    this.onConditionClick(
                                                                        item,
                                                                        'flow'
                                                                    )
                                                                }
                                                                className={classNames('btn', {
                                                                    'selected-btn': item.selected,
                                                                })}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {getFieldValue('creationMode') === 'RULE_MODE' ? (
                            <div style={{ width: '50%' }}>
                                <Form.Item {...tailFormAddLayout} label="">
                                    {getFieldDecorator('crowdRule')(
                                        <List
                                            setList={setList}
                                            subsiteId={subSiteId}
                                            onChange={(value, item, valueType) =>
                                                this.changeValue(value, item, valueType)
                                            }
                                            levelOptions={levelOptions}
                                            onDelete={this.onDelete}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item {...tailFormAddLayout} label="">
                                    {getFieldDecorator('peopleNumber')(
                                        <div style={{ marginLeft: 30 }}>
                                            {language.getText('ygrs')}：
                                            {isNil(peopleNumber) ? '--' : `${peopleNumber}人`}
                                            <AntButton
                                                loading={estimateLoading}
                                                style={{ marginLeft: 30 }}
                                                type="primary"
                                                onClick={(e) => this.onEstimate(e)}
                                            >
                                                {language.getText('gs')}
                                            </AntButton>
                                        </div>
                                    )}
                                </Form.Item>
                            </div>
                        ) : null}
                    </div>
                    <Form.Item {...tailFormItemLayout} className="public-label pdt20">
                        <AntButton type="primary" htmlType="submit" style={{ marginRight: 10 }}>
                            {language.getText('submit')}
                        </AntButton>
                        <AntButton onClick={navigation.goBack}>
                            {language.getText('common.cancel')}
                        </AntButton>
                    </Form.Item>
                </Form>
            </Spin>
        );
    }
}
function mapStateToProps(state: any, props: CustomCrowdRuleProps) {
    const { entity, row, params, id } = props;
    return {
        id,
        entity,
        row,
        params,
    };
}
export const CustomCrowdRuleForm = connect(mapStateToProps)(Form.create()(CustomCrowdRule));
