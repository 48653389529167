import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
const couponSources: any = {
    ONLINE: '线上制券',
    OFFLINE: '线下制券',
};
const couponModes: any = {
    GENERATE_COUPON_CODE: '仅展示制券方式->线上券',
    PREPARE_COUPON_CODE: '仅展示导入三方券码的券',
};
const couponCenterDefaultSource: any = {
    ONLINE: '线上券页签',
    OFFLINE: '三方券页签',
};

export const MyMemberCouponsSettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data.id) {
            config.apiPath = `/admin/member_coupon_settings/${data.id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.subsiteIds =
                    res?.subsites?.map((subsite: any) => {
                        subsite.select = true;
                        return subsite;
                    }) || [];
                res.couponCenterDefaultSource = res?.couponCenterDefaultSource || '';
                return res;
            }
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    couponSourcesStr: item.couponSources?.length
                        ? item.couponSources
                              .map((source: string) => {
                                  return couponSources[source];
                              })
                              .join('、')
                        : '',
                    couponModesStr: item.couponModes?.length
                        ? item.couponModes
                              .map((mode: string) => {
                                  return couponModes[mode];
                              })
                              .join('、')
                        : '',
                    couponCenterDefaultSourceStr: item?.couponCenterDefaultSource
                        ? couponCenterDefaultSource[item.couponCenterDefaultSource]
                        : '',
                };
            });
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        const newParams = dataParser(params);
        return api.post(newParams, config);
    },
    put: async function(data: any, config: ApiRequestConfig) {
        const { id } = data;
        config.apiPath = `/admin/member_coupon_settings/${id}`;
        const newParams = dataParser(data);
        newParams.id = id;
        return services.api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const couponSources = params?.couponSources || [];
    const arr = ['ONLINE', 'OFFLINE'];

    couponSources.sort((a: any, b: any) => {
        return arr.indexOf(a) - arr.indexOf(b);
    });
    let newParams: any = {
        ...params,
        couponSources,
        subsiteIds: params?.subsiteIds.map((subsite: any) => {
            return subsite.id;
        }),
        couponModes: params?.couponModes || [],
        couponCenterDefaultSource: params?.couponCenterDefaultSource || null,
    };

    return newParams;
}

export const MemberBalanceSettingsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        return await api.get(data, config).then((res: any) => {
            const result = Object.assign(
                {
                    balanceCouponQrCode: [],
                    balanceCouponQrCodeName: '',
                    balanceCouponShowMode: 'SINGLE_COUNT',
                },
                JSON.parse(res.value)
            );
            return result;
        });
    },
    put: async function(data: any, config: ApiRequestConfig) {
        const params = {
            code: 'BALANCE_COUPON_QRCODE',
            id: 3,
            name: '余额券线下支付二维码',
            value: JSON.stringify({
                balanceCouponQrCode: data.balanceCouponQrCode,
                balanceCouponQrCodeName: data.balanceCouponQrCodeName,
                balanceCouponShowMode: data.balanceCouponShowMode,
            }),
        };
        return services.api.put(params, config);
    },
};
