import React, { PureComponent } from 'react';
import { cloneDeep, get, isEqual, isUndefined, remove } from 'lodash';
import { Button, InputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface SettingInfo {
    /**
     * 面值
     */
    amount: string;
    /**
     * 售卖价
     */
    price: string;
    /**
     * 是否是已经添加过的数据
     */
    isOld?: boolean;
}
interface Props {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: Array<SettingInfo> | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<SettingInfo> | null) => void;
}

export class StoredValueCardAmountDefinitions extends PureComponent<Props, any> {
    componentDidMount() {
        let { value, onChange } = this.props;
        if (!value || value.length === 0) {
            if (onChange) {
                onChange([{ amount: '', price: '' }]);
            }
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const currentAddType = get(this.props, 'row.baseInfo.addType');
        const nextAddtype = get(nextProps, 'row.baseInfo.addType');
        if (!isUndefined(currentAddType) && !isEqual(currentAddType, nextAddtype)) {
            this.props.onChange([{ amount: '', price: '' }]);
        }
    }

    changeInput = (index: number, inputValue: number, name: any) => {
        const { value, onChange } = this.props;
        if (!value) {
            return;
        }
        const row: any = value[index];
        if (row) {
            row[name] = inputValue;
            if (name === 'amount') {
                if (this.isCardType()) {
                    row['price'] = inputValue;
                } else {
                    row['price'] = '';
                }
            }
            onChange(cloneDeep(value));
        }
    };

    onAdd = () => {
        const { value, onChange } = this.props;
        if (!value) {
            return;
        }
        value.push({
            amount: '',
            price: '',
        });
        onChange(cloneDeep(value));
    };

    onDelete = (index: number) => {
        const { value, onChange } = this.props;
        if (!value) {
            return;
        }
        const result = cloneDeep(value);
        remove(result, (item: any, itemIndex: number) => itemIndex === index);
        onChange(result);
    };

    isCardType = () => {
        const { row } = this.props;
        const cardTypeName = 'E_RECHARGE_STORED_VALUE_CARD';
        const is =
            row &&
            row.baseInfo &&
            (row.baseInfo.addType === cardTypeName || row.baseInfo.editType === cardTypeName)
                ? true
                : false;
        return is;
    };

    render() {
        const { value, disabled } = this.props;
        return (
            <div>
                {value &&
                    value.length > 0 &&
                    value.map((setting: SettingInfo, index: number) => {
                        const inputDisabled = disabled && setting.isOld;
                        return (
                            <div>
                                {getText('mz_1')} &nbsp;
                                <InputNumber
                                    disabled={inputDisabled}
                                    placeholder={services.language.getText('inputPlease')}
                                    value={setting.amount ? Number(setting.amount) : undefined}
                                    min={0.01}
                                    max={999999999}
                                    onChange={(value: any) => {
                                        this.changeInput(index, value, 'amount');
                                    }}
                                ></InputNumber>
                                &nbsp;&nbsp;{getText('h_ysmj')}&nbsp;
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={setting.price ? Number(setting.price) : undefined}
                                    min={0.01}
                                    max={setting.amount ? Number(setting.amount) : 999999999}
                                    onChange={(value: any) => {
                                        this.changeInput(index, value, 'price');
                                    }}
                                    disabled={this.isCardType()}
                                ></InputNumber>
                                &nbsp;&nbsp;{getText('yuan')}
                                {!setting.isOld && (
                                    <span
                                        style={{ marginLeft: '20px', color: '#1990FF' }}
                                        onClick={() => {
                                            this.onDelete(index);
                                        }}
                                    >
                                        {getText('common.delete')}
                                    </span>
                                )}
                            </div>
                        );
                    })}
                <Button type="default" onClick={this.onAdd}>
                    {getText('tj')}
                </Button>
            </div>
        );
    }
}
