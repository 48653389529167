import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { debounce, cloneDeep } from 'lodash';
import { Select, Icon } from 'antd';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const { Option } = Select;

export class SelectMallActivityTopics extends PureComponent<any> {
    refreshList: any;
    constructor(props: any) {
        super(props);
        this.refreshList = debounce(this.getList, 500);
    }
    state = {
        inputValue: '',
        list: [],
        loading: false,
    };
    handleChange = (val: any, options: any) => {
        const values = options.map((i: any) => ({ name: i.props.children, id: i.key }));
        const { onChange } = this.props;
        this.setState({
            inputValue: '',
        });
        onChange && onChange(values);
    };

    onSearch = (v: any) => {
        this.setState(
            {
                inputValue: v,
            },
            () => {
                this.refreshList();
            }
        );
    };

    onFocus = async () => {
        this.getList();
    };
    onBlur = () => {
        this.setState({
            inputValue: '',
        });
    };
    getList = async () => {
        this.setState({ loading: true });

        try {
            let res: any = await services.api.get(
                { page: 1, perPage: 100, topicName: this.state.inputValue, status: 'ENABLE' },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/SHOW`,
                    apiPath: '/admin/topics',
                }
            );
            this.setState({
                list: res.result.map((i: any) => ({ name: i.name, id: i.id })),
                loading: false,
            });
        } catch (error) {
            throw error;
        }
    };
    removeTopic = (id: any) => {
        const { value, onChange } = this.props;

        onChange && onChange(cloneDeep(value).filter((i: any) => Number(i.id) !== Number(id)));
    };
    render() {
        const { value, style, disabled } = this.props;
        const selectedIds = value ? value.map((i: any) => i.id + '') : [];
        const { list, loading } = this.state;
        return (
            <div>
                <div className="select-mall-activity-topics">
                    <Select
                        disabled={disabled}
                        style={style}
                        loading={loading}
                        onSearch={this.onSearch}
                        mode="multiple"
                        value={selectedIds}
                        onFocus={this.onFocus}
                        filterOption={false}
                        onBlur={this.onBlur}
                        onChange={this.handleChange}
                        placeholder={language.getText('qsrhtmc')}
                    >
                        {list.map((i: any, index: number) => (
                            <Option key={i.id}>{i.name}</Option>
                        ))}
                    </Select>
                </div>
                <div className="ant-form-extra">
                    <span>
                        {language.getText('mzdht')}？
                        <a href="#/content-topic" target="_blank">
                            {language.getText('cjxht')}
                        </a>
                    </span>
                </div>
                <div
                    className="mall-activity-topic-selected"
                    style={{ width: style.width ? style.width : '450px' }}
                >
                    {!!(value && value.length) &&
                        value.map((i: any) => {
                            return (
                                <div className="topics">
                                    <span>{i.name}</span>
                                    <span
                                        className="close"
                                        onClick={() => {
                                            this.removeTopic(i.id);
                                        }}
                                    >
                                        <Icon type="close" />
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}
