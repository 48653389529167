import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import './index.less';
import { services } from '@comall-backend-builder/core';

class dataPanelTitle extends PureComponent<{
    entity: any;
    fields: any;
    title: string;
}> {
    state = {
        value: [],
    };
    updateTime: any;
    componentDidMount() {
        const now = new Date();
        let month: any = now.getMonth() + 1;
        let day: any = now.getDate();
        let hours: any = now.getHours();
        hours = hours > 9 ? hours : '0' + hours;
        month = month > 9 ? month : '0' + month;
        day = day > 9 ? day : '0' + day;
        this.updateTime = now.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':00:00';
    }

    render() {
        const { title } = this.props;
        return (
            <div className="data-panel-title">
                <span className="title">{title}</span>
                <span className="sub-title">{services.language.getText('sjmgzdgx')}</span>
                <span className="time">{this.updateTime}</span>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId, title } = props;
    return {
        entity,
        componentId,
        fields: entity.fields ? entity.fields : null,
        title,
    };
}

export const DataPanelTitle = connect(mapStateToProps, null)(dataPanelTitle);
