/** 消息群发模块 loader */
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';
import { SendMemberMode } from '../../types/mode/object/member-selector/config-select-member';
const { api } = services;

/** 接口上送数据处理 */
const processToVO = (data: any) => {
    const params: any = {};
    params.subject = data.subject;
    params.content = data.content;
    if (data.pic && data.pic.length) {
        params.imageUrl = data.pic[0].id;
    }
    params.type = data.type;
    if (data.tags) {
        params.sendMemberMode = data.tags.mode;
        if (data.tags.mode === SendMemberMode.LEVEL) {
            params.tags = data.tags.list.map((i: any) => ({
                subsiteId: i.subsiteId,
                cardLevelId: i.cardLevelId,
            }));
        }
        if (data.tags.mode === SendMemberMode.MOBILE) {
            params.fileId = data.tags.file.fileId;
            params.fileName = data.tags.file.fileName;
            params.fileUrl = data.tags.file.fileUrl;
            params.messageBatchMemberList = data.tags.file.messageBatchMemberList;
        }
    }

    const link = {
        linkType: get(data, 'linkType.linkType', null),
        linkParams: get(data, 'linkType.linkParams', null),
    };
    if (data.linkTypeNew) {
        link.linkType = get(data, 'linkTypeNew.linkType');
        link.linkParams = get(data, 'linkTypeNew.linkParams');
    }
    params.targetType = link.linkType;
    params.targetUrl = link.linkParams ? JSON.stringify(link.linkParams) : null;
    params.sendTimeEnum = data.sendTimeEnum;
    params.regularSendTime = data.regularSendTime;
    params.offlineMessageSaveTime = data.offlineMessageSaveTime;
    return params;
};

/** 数据库返回数据处理 */
function processToVM(data: any) {
    if (data.imageId && data.imageUrl) {
        data.pic = [{ id: data.imageId, path: data.imageUrl }];
    }
    data.linkType = data.linkTypeNew = {
        linkType: data.targetType,
        linkParams: data.targetUrl ? JSON.parse(data.targetUrl) : data.targetUrl,
    };
    if (data.sendMemberMode === SendMemberMode.LEVEL) {
        data.tags = {
            mode: data.sendMemberMode,
            list: data.tags.map((i: any) => ({ ...i, select: true })),
        };
    }
    if (data.sendMemberMode === SendMemberMode.MOBILE) {
        data.tags = {
            mode: data.sendMemberMode,
            file: {
                fileId: data.fileId,
                fileName: data.fileName,
                fileUrl: data.fileUrl,
                messageBatchMemberList: data.messageBatchMemberList,
            },
        };
    }

    data.offlineMessageSaveTime = data.offlineMessageSaveTime + '';
    return data;
}

/** 内容弹窗数据处理 */
export const MessagesBatchLoader = {
    /** 列表、详情查询接口 */
    get: function(data: any, config: ApiRequestConfig) {
        if (data.dateRange) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return api.get(data, config).then((res: any) => {
            if (data.id) {
                return processToVM(res);
            } else {
                return res;
            }
        });
    },

    /** 修改接口 */
    put: function(data: any, config: ApiRequestConfig) {
        config.apiPath = `/admin/messages_batch/${data.id}`;
        return api.put(processToVO(data), config);
    },

    /** 新增接口 */
    post: function(data: any, config: ApiRequestConfig) {
        return api.post(processToVO(data), config);
    },

    /** 新增接口 */
    delete: function(data: any, config: ApiRequestConfig) {
        return api.delete(data, config);
    },
};
