import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';

const DISPLAY_STYLE = {
    TITLE: {
        fontSize: 15,
        color: '#333',
        margin: 0,
    },
    SUBTITLE: {
        fontSize: 12,
        color: '#999',
        margin: 0,
    },
};
export class liveRoomDateMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const [title, subtitle] = value;
        return (
            <div>
                <p style={DISPLAY_STYLE.TITLE}>{title}</p>
                <p style={DISPLAY_STYLE.SUBTITLE}>{subtitle}</p>
            </div>
        );
    }
}
