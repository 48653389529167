import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { LotteryCodeImportButton, LotteryCodeExportButton } from '../../../containers';

import './index.less';
import { isFunction } from 'lodash';
import { services } from '@comall-backend-builder/core';
/**
 * 抽奖码活动列表用户白名单导入及报表下载
 */
export class LotteryCodeImportExportMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const executeStatus = displayInfo.row.executeStatus;
        const lotteryStatus = displayInfo.row.lotteryStatus;
        const registrationStatus = displayInfo.row.registrationStatus;
        const userWhiteListScope =
            displayInfo.userWhiteListScope || displayInfo.row.userWhiteListScope;

        if (displayInfo.name === 'whiteUserListFileUrl') {
            if (userWhiteListScope === 'PART') {
                const { disabled: displayInfoDisabled } = displayInfo;
                const disabled =
                    displayInfoDisabled === undefined
                        ? executeStatus !== 'NOT_STARTED'
                        : isFunction(displayInfoDisabled)
                        ? displayInfoDisabled(displayInfo)
                        : displayInfoDisabled;
                return (
                    <div>
                        <LotteryCodeImportButton
                            {...displayInfo}
                            disabled={disabled}
                            importExportType="WHITE_LIST"
                        ></LotteryCodeImportButton>
                        <LotteryCodeExportButton
                            {...displayInfo}
                            importExportType="WHITE_LIST"
                        ></LotteryCodeExportButton>
                    </div>
                );
            }
            if (userWhiteListScope === 'ALL') {
                return <span>全部用户</span>;
            }
        }
        if (displayInfo.name === 'blackUserListFileUrl') {
            return (
                <div>
                    <LotteryCodeImportButton
                        {...displayInfo}
                        disabled={
                            lotteryStatus === services.language.getText('ykj') ||
                            registrationStatus === '已结束'
                        }
                        importExportType="BLACK_LIST"
                    ></LotteryCodeImportButton>
                    <LotteryCodeExportButton
                        {...displayInfo}
                        importExportType="BLACK_LIST"
                    ></LotteryCodeExportButton>
                </div>
            );
        }

        return <span></span>;
    }
}
