import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        CouponExchangeRecord: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponExchangeRecord',
            filters: {
                recordNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                subsites: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },

                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                recordNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<number>>',
                },
                orderStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<ddzt>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                totalPoint: {
                    type: 'string',
                    displayName: '<<jfze>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
            },
        },
    },
    components: {
        CouponExchangeRecordView: {
            component: 'Viewport',
            entity: 'CouponExchangeRecord',
        },
        CouponExchangeRecordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponExchangeRecordFilter',
                },
                { component: 'CouponExchangeRecordTable' },
            ],
        },
        CouponExchangeRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsites',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'recordNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        CouponExchangeRecordTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'recordNo',
                        },
                        {
                            property: 'couponName',
                        },
                        {
                            property: 'quantity',
                        },
                        {
                            property: 'createTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                            },
                        },
                        {
                            property: 'totalPoint',
                        },
                        {
                            property: 'mobile',
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-exchange-record',
                    component: 'CouponExchangeRecordView',
                    breadcrumbName: '线上券订单',
                    privilege: {
                        path: 'couponExchangeRecord',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponExchangeRecordPage' }],
                },
            ],
        },
    ],
};
