import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { map } from 'lodash';
import './index.less';
interface pickupConfig {
    labelTitle: string;
    addonbefore: string;
    addonafter: string;
    tip: string;
    type: string;
    rules: any;
}

interface PickupInfo {
    pickupTime?: number;
    pickupLimitTime?: number;
    eachPresale?: number;
    eachOrder?: number;
}
interface NumberInputTitleTipProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: PickupInfo | null, name: string) => void;
    /**
     * 当前值
     */
    value?: PickupInfo;
    /**
     * 候选项集合
     */
    title: string;
    configInfo: Array<pickupConfig>;
    row: any;
}

export class NumberInputTitleTip extends Component<NumberInputTitleTipProps, any> {
    onPickupTimeChange(time: number | undefined, type: string) {
        let { name, value, onChange } = this.props;

        if (!value) {
            value = {};
        }
        if (type === 'pickupTime') {
            value.pickupTime = time;
        } else if (type === 'pickupLimitTime') {
            value.pickupLimitTime = time;
        }
        switch (type) {
            case 'pickupTime':
                value.pickupTime = time;
                break;
            case 'pickupLimitTime':
                value.pickupLimitTime = time;
                break;
            case 'EACH_PRESALE':
                value.eachPresale = time;
                break;
            case 'EACH_ORDER':
                value.eachOrder = time;
                break;
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, title, configInfo, row } = this.props;

        return (
            <div className="number-input-title-tip-template">
                {title ? <div className="title">{title}</div> : ''}
                {map(
                    configInfo,
                    ({ labelTitle, addonbefore, addonafter, tip, rules, type }, index) => {
                        let inputValue;
                        let maxCount = row.perballotedCount ? row.perballotedCount : 0;
                        let eachOrderMaxCount =
                            row.limitUser && row.limitUser.eachPresale
                                ? row.limitUser.eachPresale
                                : 0;
                        if (value) {
                            switch (type) {
                                case 'pickupTime':
                                    inputValue = value.pickupTime;
                                    break;
                                case 'pickupLimitTime':
                                    inputValue = value.pickupLimitTime;
                                    break;
                                case 'EACH_PRESALE':
                                    rules[0].min = maxCount;
                                    inputValue = value.eachPresale;
                                    break;
                                case 'EACH_ORDER':
                                    if (!value.eachPresale) {
                                        inputValue = value.eachOrder;
                                        rules = [];
                                    } else {
                                        rules[0].max = eachOrderMaxCount;
                                        inputValue = value.eachOrder;
                                    }
                                    break;
                            }
                        }

                        return (
                            <div className="input-title-line" key={index}>
                                {labelTitle ? (
                                    <span className="input-title">{labelTitle}: </span>
                                ) : (
                                    ''
                                )}
                                <div>
                                    <span className="ant-input-group-wrapper">
                                        <span className="ant-input-wrapper ant-input-group">
                                            {addonbefore && (
                                                <span className="ant-input-group-addon">
                                                    {addonbefore}
                                                </span>
                                            )}
                                            {rules && rules[0] ? (
                                                <InputNumber
                                                    {...rules[0]}
                                                    value={
                                                        inputValue && inputValue > 0
                                                            ? inputValue
                                                            : ''
                                                    }
                                                    onChange={(value) =>
                                                        this.onPickupTimeChange(value, type)
                                                    }
                                                />
                                            ) : (
                                                <InputNumber
                                                    {...rules}
                                                    value={inputValue}
                                                    onChange={(value) =>
                                                        this.onPickupTimeChange(value, type)
                                                    }
                                                />
                                            )}

                                            {addonafter && (
                                                <span className="ant-input-group-addon">
                                                    {addonafter}
                                                </span>
                                            )}
                                        </span>
                                    </span>
                                    {tip && (
                                        <div
                                            style={{
                                                fontSize: 14,
                                                color: '#ccc',
                                                lineHeight: '22px',
                                            }}
                                        >
                                            {tip}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
}
