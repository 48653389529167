import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { LogoutAgreement } from './logout-agreement';

export class LogoutAgreementMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <LogoutAgreement {...controlInfo} />;
    }
}
