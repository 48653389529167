import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { CouponSaleCategoriesStatus } from '../../config/promotion/coupon/coupon-sale-sort';
const api = services.api;
export const CouponSaleCategoriesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/sale_rules_categories';
        const { id } = data;
        if (!id) {
            if (!data.subsiteIds) {
                delete data.subsiteIds;
            }
            if (data.status === CouponSaleCategoriesStatus.ALL) {
                delete data.status;
            }
        } else {
            config.apiPath = `/admin/sale_rules_categories/${id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    let status = '';
                    if (item.status) {
                        status = CouponSaleCategoriesStatus.ENABLE;
                    } else {
                        status = CouponSaleCategoriesStatus.DISABLE;
                    }
                    return { ...item, status };
                });
            }
            return res;
        });
    },
};
