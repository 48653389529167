import React, { PureComponent, createElement } from 'react';
import { ComponentsManager, builder, actions, services } from '@comall-backend-builder/core';
import { Button, message } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { remove, find, map } from 'lodash';
import { SelectTree, TreeData } from '../../select-tree';
import { HandleOrganizationModal } from '../handle-organization-modal';
import { ChangeParentModal } from '../change-parent-modal';
import {
    handleOrganizationTreeData,
    getSelectedTreeNodeParents,
    OrganizationType,
} from '../common';
import './index.less';
interface OrganizationManageProps {
    entity: any;
    visible: boolean;
    avatar: string;
    onCancel: () => void;
    onOk: (avatar: string) => void;
}

interface OrganizationManageState {
    //组织机构树
    treeData: TreeData[];
    //选中的组织机构树节点
    selectedTreeNode: TreeData | undefined;
    // 添加/编辑的弹框是否显示
    handleModalVisible: boolean;
    //弹框类型
    modalType: 'add' | 'edit';
    //选中节点的父级树
    selectedTreeNodeParents: TreeData[];
    // 正在操作的组织结构
    currentHandleOrganization: TreeData | undefined;
    // 表格中选中的组织
    selectedRows: TreeData[];
    // 变更组织弹窗是否显示
    changeParentModalVisible: boolean;
}

export class OrganizationManageViewContainer extends PureComponent<
    OrganizationManageProps,
    OrganizationManageState
> {
    dispatch: any = null;
    constructor(props: any) {
        super(props);
        this.state = {
            treeData: [],
            selectedTreeNodeParents: [],
            selectedTreeNode: undefined,
            handleModalVisible: false,
            modalType: 'add',
            currentHandleOrganization: undefined,
            selectedRows: [],
            changeParentModalVisible: false,
        };
        this.dispatch = builder.getStore().dispatch;
    }

    componentDidMount() {
        this.loadTreeData(true);
    }
    /**
     * 加载组织机构树形数据
     */
    loadTreeData = (resetSelected?: boolean) => {
        api.get(
            {},
            {
                apiPath: `/organizations`,
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            }
        ).then((res: any) => {
            if (res && res.result) {
                // 是否重置左侧树
                let selectedTreeNode = this.state.selectedTreeNode;
                if (resetSelected) {
                    selectedTreeNode = res.result.length ? res.result[0] : undefined;
                }

                const treeData = handleOrganizationTreeData(res.result);
                this.setState(
                    { treeData, selectedTreeNode, currentHandleOrganization: selectedTreeNode },
                    this.loadSelectedTreeNodeChildren
                );
            }
        });
    };
    /**
     * 加载当前选中树的子级树
     */
    loadSelectedTreeNodeChildren = () => {
        const { entity } = this.props;
        const { selectedTreeNode } = this.state;
        if (selectedTreeNode) {
            delete entity.filters.name;
            entity.filters.parentId = selectedTreeNode.id;
            entity.pageChange(Object.assign({}, entity.filters, entity.paging, { page: 1 }));
            entity.search();
        }
    };

    /**
     * 选中树
     * @param selectedTreeNode
     */
    onTreeSelect = (selectedTreeNode: TreeData) => {
        this.setState(
            { selectedTreeNode, currentHandleOrganization: selectedTreeNode, selectedRows: [] },
            this.loadSelectedTreeNodeChildren
        );
    };

    // 添加下级组织
    addOrganization = () => {
        const { currentHandleOrganization } = this.state;

        if (!currentHandleOrganization || !currentHandleOrganization.id) {
            message.error(services.language.getText('qxzygsjzzjg'));
            return;
        }
        const organizationTypeObj = find(currentHandleOrganization.extendAttributes, {
            attributeCode: 'organization_type',
        });
        const organizationType = organizationTypeObj ? organizationTypeObj.attributeValue : '';
        if (
            organizationType === OrganizationType.SUBSITE_CUSTOM ||
            organizationType === OrganizationType.MERCHANT_CUSTOM2
        ) {
            // 总部下只允许添加一级自定义组织机构
            // 门店下只允许添加两级自定义组织机构
            message.error(services.language.getText('yddktjzzjgdzdcj'));
            return;
        }
        // 专柜下不可自定义组织机构
        if (organizationType === OrganizationType.MERCHANT) {
            message.error(services.language.getText('zjxbktjzdyzzjg'));
            return;
        }
        this.setState(
            {
                modalType: 'add',
            },
            this.showHandleModal
        );
    };
    /**
     * 打开添加下级组织机构弹窗
     */
    showHandleModal = () => {
        const { currentHandleOrganization, treeData } = this.state;
        const selectedTreeNodeParents = getSelectedTreeNodeParents(
            currentHandleOrganization,
            treeData
        );

        this.setState({ handleModalVisible: true, selectedTreeNodeParents });
    };
    /**
     * 添加组织机构后刷新树
     */
    onHandleModalOK = () => {
        this.setState({ handleModalVisible: false, selectedRows: [] }, this.loadTreeData);
    };
    /**
     * 关闭弹窗(将正在操作的组织重置为左侧选中树节点)
     */
    onHandleModaCancel = () => {
        const { selectedTreeNode } = this.state;
        this.setState({ handleModalVisible: false, currentHandleOrganization: selectedTreeNode });
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            selectedRows.push(record);
        } else {
            remove(selectedRows, (i: any) => {
                return i.id === record.id;
            });
        }
        this.setState({ selectedRows });
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        let { selectedRows } = this.state;
        if (selected) {
            selectedRows = rows;
        } else {
            selectedRows = [];
        }
        this.setState({ selectedRows });
    };
    /**
     * 变更组织
     */
    changeParentOrganization = () => {
        const { selectedRows } = this.state;
        if (!selectedRows || !selectedRows.length) {
            message.error(services.language.getText('qxxzygzzjg'));
            return;
        }
        // 所选组织是否类型一致
        let sameType = true;
        // 循环中前一个组织的类型
        let preOrganizationType: any;
        // 所选组织是否属于同一个父级
        let sameParent = true;
        // 循环中前一个组织的父级id
        let preParentId: any;
        selectedRows.forEach((item: any, index: number) => {
            const organizationTypeObj = find(item.extendAttributes, {
                attributeCode: 'organization_type',
            });
            const organizationType = organizationTypeObj ? organizationTypeObj.attributeValue : '';
            if (index !== 0 && organizationType !== preOrganizationType) {
                sameType = false;
            }
            if (index !== 0 && item.parentId !== preParentId) {
                sameParent = false;
            }
            preOrganizationType = organizationType;
            preParentId = item.parentId;
        });
        if (!sameType) {
            message.error(services.language.getText('nxzdzzjglxbt'));
            return;
        }
        if (!sameParent) {
            message.error(services.language.getText('btjzzjg'));
            return;
        }
        this.setState({
            changeParentModalVisible: true,
        });
    };
    /**
     * 变更组织后刷新树
     */
    onChangeModalOK = () => {
        this.setState({ selectedRows: [], changeParentModalVisible: false }, this.loadTreeData);
    };
    /**
     * 关闭变更组织弹窗
     */
    onChangeModalCancel = () => {
        this.setState({ changeParentModalVisible: false });
    };

    render() {
        const {
            treeData,
            handleModalVisible,
            selectedTreeNode,
            selectedTreeNodeParents,
            currentHandleOrganization,
            modalType,
            changeParentModalVisible,
            selectedRows,
        } = this.state;
        const modalProps = {
            type: modalType,
            currentHandleOrganization: currentHandleOrganization,
            selectedTreeNodeParents: selectedTreeNodeParents,
            visible: handleModalVisible,
            onOk: this.onHandleModalOK,
            onCancel: this.onHandleModaCancel,
        };

        return (
            <div className="organization-manage-view">
                {this.renderFilters()}
                <div className="data-view">
                    <div className="left-tree-view">
                        <div className="buttons">
                            <Button
                                className="button"
                                type="default"
                                onClick={this.addOrganization}
                            >
                                {services.language.getText('tjxjzzjg')}
                            </Button>
                        </div>
                        <div className="tree-wrap">
                            {treeData && treeData.length ? (
                                <SelectTree
                                    onSelect={this.onTreeSelect}
                                    result={treeData}
                                    selectedTreeNode={selectedTreeNode}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="right-table-view">
                        <div className="buttons">
                            <Button type="primary" onClick={this.changeParentOrganization}>
                                {services.language.getText('bgzz')}
                            </Button>
                        </div>
                        {this.renderTable()}
                    </div>
                </div>
                {handleModalVisible && <HandleOrganizationModal {...this.props} {...modalProps} />}
                {changeParentModalVisible && (
                    <ChangeParentModal
                        {...this.props}
                        visible={changeParentModalVisible}
                        selectedRows={selectedRows}
                        onOk={this.onChangeModalOK}
                        onCancel={this.onChangeModalCancel}
                    />
                )}
            </div>
        );
    }

    renderFilters = () => {
        const { entity } = this.props;
        const componentId = 'OrganizationManageTable';
        let filterFormPlusProps = {
            entity,

            component: 'FilterForm',
            componentId: 'OrganizationManageFilterFormPlus',
            direction: 'inline',
            submit: {
                text: services.language.getText('common.search'),
            },
            reset: {
                text: services.language.getText('common.reset'),
                style: {
                    marginLeft: 16,
                },
            },
            onSubmit: (e: any, value: any) => {
                delete entity.filters.parentId;
                this.setState({
                    selectedTreeNode: undefined,
                    selectedRows: [],
                });
                this.dispatch(actions.componentSubmitAction(componentId, 'paging'));
                entity.pageChange(Object.assign({}, entity.filters, entity.paging, { page: 1 }));
                entity.search();
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('FilterForm'), filterFormPlusProps);
    };

    renderTable = () => {
        const { entity } = this.props;
        const { selectedRows } = this.state;
        const _this = this;
        const componentId = 'OrganizationManageTable';

        let tableProps = {
            entity,
            componentId,
            rowKey: 'id',
            loadFirstPage: false,

            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            rowSelection: {
                selectedRowKeys: map(selectedRows, 'id'),
                onChange: () => {},
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
            },
            columns: [
                {
                    property: 'nameStr',
                    width: 180,
                },
                { property: 'organizationType', width: 180 },
                {
                    property: 'status',
                    width: 100,
                },
                // { property: 'sequence' },
            ],

            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'OrganizationManageButton',
                        config: {
                            props: {
                                title: services.language.getText('tjxjzz'),
                                showKey: 'canAddOrganization',
                                onClick(row: any) {
                                    _this.setState(
                                        { currentHandleOrganization: row },
                                        _this.addOrganization
                                    );
                                },
                            },
                        },
                    },
                    {
                        type: 'component',
                        component: 'OrganizationManageButton',
                        config: {
                            props: {
                                title: services.language.getText('common.edit'),
                                showKey: 'canEdit',
                                onClick(row: any) {
                                    entity &&
                                        entity.setFields({
                                            ...row,
                                        });
                                    _this.setState(
                                        { currentHandleOrganization: row, modalType: 'edit' },
                                        _this.showHandleModal
                                    );
                                },
                            },
                        },
                    },
                    {
                        type: 'delete',
                        text: services.language.getText('common.delete'),
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            id: 'row.id',
                            tooltip: null,
                            title: services.language.getText('confirmDelete'),
                            okText: services.language.getText('common.ok'),
                            cancelText: services.language.getText('common.cancel'),
                        },
                    },
                ],
            },
            onDeleteItem: (itemId: string) => {
                this.dispatch(actions.componentSubmitAction(componentId, 'delete'));
                entity.delete({ id: itemId });
            },
            onSubmitSuccess: (_result: any, submitType: any) => {
                if (submitType === 'delete') {
                    message.success(services.language.getText('sccg'));
                    this.setState({ selectedRows: [] }, this.loadTreeData);
                }
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
}

/**
 * 按钮组件
 */
export class OrganizationManageButton extends PureComponent<any, any> {
    onClick = () => {
        const { row, props } = this.props;
        const { onClick } = props;
        onClick && onClick(row);
    };
    render() {
        const { row, props } = this.props;
        const { title, showKey, route } = props;
        const visibleValue = showKey ? row[showKey] : true;
        return visibleValue ? (
            <Button type="link" style={{ padding: 0, marginRight: 10 }} className="span-link">
                <a href={route} onClick={this.onClick}>
                    {title}
                </a>
            </Button>
        ) : null;
    }
}
