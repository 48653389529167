import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        RemittanceManagerEntity: {
            apiPath: '',
            filters: {},
            properties: {},
        },
    },
    components: {
        RemittanceManagerView: {
            component: 'Viewport',
            entity: 'RemittanceManagerEntity',
        },
        RemittanceManagerPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            // className: 'table-content-viewport',
            items: [{ component: 'RemittanceManagerItem' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/remittance-manager',
                    component: 'RemittanceManagerView',
                    breadcrumbName: '<<remittanceManager>>',
                    privilege: {
                        path: 'paymentRemittanceManager',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'RemittanceManagerPage' }],
                },
            ],
        },
    ],
};
