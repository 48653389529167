import React, { PureComponent } from 'react';
import { Card, InputNumber, Button, DatePicker } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
    NewMallActivityRoundItem,
    NewMallActivityMemberCardLevel,
    NewMallActivityRow,
} from '../types';
import { TimeRangePickerPlus } from '../..';
import { disabledDate, formatTime } from '../common';
import { NewMallActivityTickets } from '../new-mall-activity-tickets/new-mall-activity-tickets';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityRoundProps {
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: NewMallActivityRoundItem) => void;
    /**
     * 当前值
     */
    value: NewMallActivityRoundItem;
    // disabled?: boolean;
    onCopy: (value: NewMallActivityRoundItem) => void;
    onRemove: (value: NewMallActivityRoundItem) => void;
    cardLevels: NewMallActivityMemberCardLevel[];
    showTickets: boolean;
    isAdd: boolean;
    row: NewMallActivityRow;
}

/**
 * 新商场活动单个场次
 */
class NewMallActivityRound extends PureComponent<NewMallActivityRoundProps> {
    /**
     * 编辑页面时，判断场次开始时间是否早于当前时间
     */
    private get isStartTimePassed() {
        const { value, isAdd } = this.props;
        if (isAdd) {
            return false;
        }
        const { roundStartDate, startTime } = value;
        if (!roundStartDate || !startTime) {
            return false;
        }
        return moment() > moment(`${roundStartDate} ${startTime}`);
    }
    /**
     * 场次日期时间是否可编辑
     */
    private get dateTimeModifiable() {
        const { value, isAdd } = this.props;
        // 新增复制页面可以编辑场次
        if (isAdd) {
            return true;
        }
        const { tickets, id } = value;
        // 没有id说明是用户新添加的场次，可以编辑
        if (!id) {
            return true;
        }
        // 场次的票档是否还没购买数量
        const unsold = tickets.every((i) => !i.soldQuantity);
        // 既没到时间，票也没人购买时，可以编辑场次
        if (!this.isStartTimePassed && unsold) {
            return true;
        }
        // 总结：编辑页面，场次的历史数据中，如果场次已经过了开始时间，或者票已经有购买记录，则不可以编辑场次日期时间
        return false;
    }

    /**
     * 变更场次内容
     * @param obj 变更的内容对象
     */
    changeRound = (obj: Partial<NewMallActivityRoundItem>) => {
        const { value, onChange } = this.props;
        const round = Object.assign(cloneDeep(value), obj);
        onChange(round);
    };

    /**
     * 开启/禁用场次
     */
    toggleRoundStatus = () => {
        const { value } = this.props;
        this.changeRound({ enable: !value.enable });
    };

    renderDate = () => {
        const {
            value: { roundStartDate },
            isAdd,
        } = this.props;
        return (
            <DatePicker
                style={{ width: 125, marginRight: 10 }}
                className="mrp-10"
                placeholder={language.getText('qxzrq')}
                showToday={false}
                allowClear={false}
                disabledDate={(current) => disabledDate(current, this.props.row, isAdd)}
                value={roundStartDate ? moment(roundStartDate) : undefined}
                disabled={!this.dateTimeModifiable}
                onChange={(date) =>
                    this.changeRound({
                        roundStartDate: date?.format('YYYY-MM-DD'),
                        roundEndDate: date?.format('YYYY-MM-DD'),
                    })
                }
            />
        );
    };

    onTimeChange = (time: { start?: string; end?: string }) => {
        this.changeRound({ startTime: formatTime(time.start), endTime: formatTime(time.end) });
    };

    renderTime = () => {
        const {
            value: { startTime: start, endTime: end },
        } = this.props;
        return (
            <TimeRangePickerPlus
                placeholder={[language.getText('selectPlease'), language.getText('selectPlease')]}
                name="start"
                value={{
                    start,
                    end,
                }}
                format="HH:mm"
                style={{ width: 80 }}
                allowClear={true}
                disabled={!this.dateTimeModifiable}
                onChange={this.onTimeChange}
                allowSingle
            />
        );
    };

    renderMaxPurchaseTotal = () => {
        const {
            value: { maxPurchaseTotal },
        } = this.props;
        return (
            <div className="new-mall-activity__round__max">
                {language.getText('mccmyhxg')}&nbsp;&nbsp;
                <InputNumber
                    value={maxPurchaseTotal}
                    min={1}
                    max={999999}
                    precision={0}
                    onChange={(num) => this.changeRound({ maxPurchaseTotal: num })}
                ></InputNumber>
            </div>
        );
    };

    renderActionColum = () => {
        const { onCopy, value, onRemove } = this.props;
        const buttonStyle = {
            padding: '0 5px',
        };
        const delButton = (
            <Button style={buttonStyle} type={'link'} onClick={() => onRemove(value)}>
                {language.getText('sccc')}
            </Button>
        );
        const statusButton = (
            <Button style={buttonStyle} type={'link'} onClick={this.toggleRoundStatus}>
                {value.enable ? language.getText('jycc_1') : language.getText('kqcc')}
            </Button>
        );
        return (
            <div className="new-mall-activity__round__action">
                {value.id ? statusButton : delButton}
                <Button style={buttonStyle} type={'link'} onClick={() => onCopy(value)}>
                    {language.getText('fzcc')}
                </Button>
            </div>
        );
    };

    render() {
        const {
            value: { tickets },
            cardLevels,
            showTickets,
            isAdd,
        } = this.props;
        return (
            <div className="new-mall-activity__round">
                <LabelRow label={language.getText('ccsj')}>
                    <div className="new-mall-activity__round__info">
                        {this.renderDate()}
                        {this.renderTime()}
                        {this.renderMaxPurchaseTotal()}
                        {this.renderActionColum()}
                    </div>
                </LabelRow>
                {showTickets && (
                    <LabelRow label={language.getText('pdxx')}>
                        <Card
                            size="small"
                            bordered={false}
                            style={{ width: 890, marginTop: 10, borderRadius: 8 }}
                        >
                            <NewMallActivityTickets
                                value={tickets}
                                cardLevels={cardLevels}
                                onChange={(value) => this.changeRound({ tickets: value })}
                                isAdd={isAdd}
                                size="small"
                                disabled={this.isStartTimePassed}
                            />
                        </Card>
                    </LabelRow>
                )}
            </div>
        );
    }
}

const LabelRow: React.FC<{ label: string }> = (props) => {
    return (
        <div className="new-mall-activity__round__row">
            <div className="new-mall-activity__round__label">{props.label}：</div>
            {props.children}
        </div>
    );
};

export { NewMallActivityRound };
