import React, { PureComponent } from 'react';
import { Cascader } from 'antd';
import { services } from '@comall-backend-builder/core';
import { TipsModal } from '../../../../components';
import { TipsModalContentType } from '../../../../components/tips-modal';

const { api } = services;

interface businessTypeItem {
    value: string;
    label: string;
    children?: businessTypeItem[];
}
export interface ArticleTypeSelectStates {
    businessType: any;
}
export class ArticleTypeSelect extends PureComponent<
    {
        disabled: boolean | undefined;
        value: any;
        onChange: (value: Array<string> | undefined) => void;
        style: any;
        showtip: boolean;
    },
    ArticleTypeSelectStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            businessType: [],
        };
    }

    componentDidMount() {
        this.loadbBusinessType();
    }

    loadbBusinessType = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/management_article/business_type',
        };
        api.get({}, config).then((res: any) => {
            res = res.map((item: any) => {
                return this.getBusinessTypeItem(item);
            });
            this.setState({
                businessType: res,
            });
        });
    };
    getBusinessTypeItem = (item: any): businessTypeItem => {
        if (item.parent) {
            return {
                value: item.id.toString(),
                label: item.name,
                children: item.businessTypeChildrenInfo.map((item: any) => {
                    return this.getBusinessTypeItem(item);
                }),
            };
        } else {
            return {
                value: item.id.toString(),
                label: item.name,
            };
        }
    };

    onChange = (value: string[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (value && value.length === 0) {
                onChange(undefined);
            } else {
                onChange(value);
            }
        }
    };

    getTipsModalContentTypeById = (id: string) => {
        switch (id) {
            case '10024':
                return TipsModalContentType.HelpCenterArticle;
            case '20311':
                return TipsModalContentType.UserPrivacyAgreement;
            case '20006001':
                return TipsModalContentType.WeighingGoodsService;
            case '20007001':
                return TipsModalContentType.ProductDetailPagePriceDescription;
            case '2022071':
                return TipsModalContentType.CheckoutPageInstructions;
            case '2022072':
                return TipsModalContentType.CardBindingPageInstructions;
            case '2022076':
                return TipsModalContentType.RealNameAuthenticationServiceAgreement;
            case '2022081':
                return TipsModalContentType.CheckoutPageUsageInstructions;
            case '2022082':
                return TipsModalContentType.OfflineBalanceVoucherPaymentCodeInstructions;
            case '202407001':
                return TipsModalContentType.InvoiceIssuanceRules;
            case '202407002':
                return TipsModalContentType.InvoiceTaxNumberExplanation;
            case '202407003':
                return TipsModalContentType.InvoiceNotice;
            case '202408001':
                return TipsModalContentType.AuthorizationAgreement;
            case '202409001':
                return TipsModalContentType.CrossBorderDataExplanation;
            case '999':
                return TipsModalContentType.ServiceReservationRentalAgreement;
            case '2580011':
                return TipsModalContentType.DepositPurchaseAuthorizationAgreement;
            case '800':
                return TipsModalContentType.PersonalizedContentRecommendation;
            case '20321':
                return TipsModalContentType.UserAgreement;
            case '20331':
                return TipsModalContentType.TermsOfServiceAndPrivacyPolicy;
            case '20341':
                return TipsModalContentType.MemberCodeDisclaimer;
            case '525':
                return TipsModalContentType.AccountCancellationNotice;
            case '2022075':
                return TipsModalContentType.CardPurchasePageInstructions;
            default:
                break;
        }
    };
    filter = (inputValue: string, path: any[]) => {
        return path.some(
            (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        );
    };

    render() {
        const { businessType } = this.state;
        const { value, style, showtip } = this.props;
        const showSearch = {
            filter: this.filter,
        };
        const text = services.language.getText('viewExample');
        const contentType =
            showtip && value && value.length
                ? this.getTipsModalContentTypeById(value[value.length - 1])
                : undefined;
        return (
            <div>
                <Cascader
                    value={value}
                    options={businessType}
                    onChange={this.onChange}
                    style={style}
                    showSearch={showSearch}
                />
                {showtip && contentType && (
                    <div style={{ display: 'flex', color: '#ccc', fontSize: 14 }}>
                        <div>{services.language.getText('articleTip')}</div>
                        <div style={{ marginLeft: 5 }}>
                            <TipsModal
                                textColor="#1890ff"
                                title=""
                                text={text}
                                contentType={contentType}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
