import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        CouponExchangeRefundRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponExchangeRefundRecord',
            filters: {
                recordNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                subsites: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                recordNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                refundTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                refundStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<tkdzt>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                refundType: {
                    type: 'string.statusBadge',
                    displayName: '<<tkfs>>',
                },
                refundPoint: {
                    type: 'number',
                    displayName: '<<tkjf>>',
                },
            },
        },
    },
    components: {
        CouponExchangeRefundRecordView: {
            component: 'Viewport',
            entity: 'CouponExchangeRefundRecordEntity',
        },
        CouponExchangeRefundRecordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponExchangeRefundRecordFilter',
                },
                { component: 'CouponExchangeRefundRecordTable' },
            ],
        },
        CouponExchangeRefundRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsites',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'recordNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        CouponExchangeRefundRecordTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'recordNo',
                        },
                        {
                            property: 'refundStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    REFUND_SUCCESS: {
                                        text: '退款成功',
                                        status: 'success',
                                    },
                                    REFUND_FAIL: {
                                        text: '退款失败',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                        {
                            property: 'refundTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        { property: 'refundPoint' },
                        {
                            property: 'refundType',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    REVERT: {
                                        text: '<<revert>>',
                                        status: 'processing',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-exchange-refund-record',
                    component: 'CouponExchangeRefundRecordView',
                    breadcrumbName: '线上券退款单',
                    privilege: {
                        path: 'couponExchangeRefundRecord',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponExchangeRefundRecordPage' }],
                },
            ],
        },
    ],
};
