import { builder } from '@comall-backend-builder/core';
import { WechatOfficialAccountsLoader } from './wechat-official-accounts-loader';
import { WechatTemplatesLoader } from './wechant-templates-loader';
import { WechatOfficialAccountFanLoader } from './wechat-official-account-fan-loader';
import {
    WechatOfficialAccountConfigTopicOptionsLoader,
    WechatOfficialAccountConfigSubsiteOptionsLoader,
    WechatOfficialAccountConfigWidgetLoader,
} from './wechat-official-account-config-widget';
import { WechatOfficialAccountQrcodeRulesLoader } from './wechat-official-account-qrcode-rules-loader';
import { WechatOfficialAccountsGiftLoader } from './wechat-official-accounts-gift-loader';

builder.registerLoader('/admin/wechat_official_accounts', WechatOfficialAccountsLoader);
builder.registerLoader('/admin/wechat_templates', WechatTemplatesLoader);
builder.registerLoader('/admin/wechat_official_accounts_fan', WechatOfficialAccountFanLoader);
builder.registerLoader(
    '/admin/wechat_official_accounts/widget',
    WechatOfficialAccountConfigWidgetLoader
);
builder.registerLoader(
    '/admin/wechat_official_accounts/subsites',
    WechatOfficialAccountConfigSubsiteOptionsLoader
);
builder.registerLoader(
    '/admin/wechat_official_accounts/topics',
    WechatOfficialAccountConfigTopicOptionsLoader
);
builder.registerLoader(
    '/admin/wechat_official_account_qrcode_rules',
    WechatOfficialAccountQrcodeRulesLoader
);
builder.registerLoader('/admin/wechat_official_accounts_gift', WechatOfficialAccountsGiftLoader);
