import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CGBConfigSelectMember } from './config-select-member';

export class MemberSelectorMode extends modes.ObjectMode {
    getDisplayComponent(value: any, displayInfo: any) {
        return <CGBConfigSelectMember value={value} disabled={true} {...displayInfo} />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CGBConfigSelectMember {...displayInfo} />;
    }
}
