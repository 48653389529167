import React, { PureComponent } from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { CardLevelRightsDisplay, CardLevelRightAdd } from '../../components';

/**
 * 会员卡权益
 */
export class CardLevelRights extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
    row: any;
    editSequence?: boolean;
}> {
    state = {
        value: [],
    };

    formRef = React.createRef<any>();

    editIndex: number = 0;

    onChange = (rights: any[]) => {
        const { onChange } = this.props;
        onChange(rights);
    };

    handleEdit = () => {};

    onAdd = (item: any) => {
        const { value } = this.props;
        const newData: any[] = cloneDeep(value || []);
        const hasIndex = findIndex(value, (o: any) => o.name === item.name);
        if (hasIndex === -1) {
            newData.push(item);
        }
        this.onChange(newData);
    };

    handleEditStart = (data: any, index: number) => {
        if (!this.formRef.current) {
            return;
        }

        this.editIndex = index;
        this.formRef.current.open(data);
    };

    handleEditFinish = (data: any) => {
        const { value } = this.props;

        const newData: any[] = value.map((item, index) => {
            if (this.editIndex === index) {
                return data;
            }
            return item;
        });

        this.onChange(newData);
    };

    render() {
        const { value, editSequence } = this.props;
        const hasValue = value && value.length > 0;
        return (
            <div>
                <CardLevelRightAdd
                    ref={this.formRef}
                    onAdd={this.onAdd}
                    onEdit={this.handleEditFinish}
                    data={value}
                />
                {hasValue && (
                    <CardLevelRightsDisplay
                        modifiable={true}
                        editSequence={editSequence}
                        data={value}
                        onChange={this.onChange}
                        onEdit={this.handleEditStart}
                    ></CardLevelRightsDisplay>
                )}
            </div>
        );
    }
}
