import React, { useEffect, useState, Fragment } from 'react';
import { Button, Modal, Form, message } from 'antd';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';

interface LogoFileData {
    logoFileUrl: string;
}
interface ImageData {
    id: string;
    path: string;
}
export const MarketMiniProgramLogoSetting: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [imageList, setImageList] = useState<ImageData[]>([]);
    const [updateLoading, setUpdateLoading] = useState(false);

    async function initLogoFileId(): Promise<void> {
        const logoFileData = (await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/mini_program_code_system_picture',
            }
        )) as LogoFileData;
        const imageList = logoFileData
            ? [
                  {
                      id: '',
                      path: logoFileData.logoFileUrl,
                  },
              ]
            : [];
        setImageList(imageList);
    }

    async function updateLogoFileId(): Promise<void> {
        try {
            setUpdateLoading(true);
            const logoFileId = imageList?.[0]?.id;
            await api
                .put(
                    {
                        logoFileId: logoFileId || null,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: '/admin/mini_program_code_system_picture',
                    }
                )
                .then(() => {
                    visibleChange();
                    message.success(services.language.getText('settingSucess'));
                })
                .catch((error) => {
                    errorHandle(error);
                });
        } finally {
            setUpdateLoading(false);
        }
    }

    function visibleChange(): void {
        if (!visible) {
            setImageList([]);
        }
        setVisible((visible) => !visible);
    }

    function handleVisibleChange(): void {
        visibleChange();
    }
    function onPicChange(picture: ImageData[]) {
        setImageList(picture);
    }

    function handleUpdateSelectOptionsData(): void {
        updateLogoFileId();
    }

    useEffect(() => {
        if (!visible) {
            return;
        }

        initLogoFileId();
    }, [visible]);

    let imageControlInfo = {
        name: 'picture',
        value: imageList.map((it) => it.path),
        accept: 'image/jpg, image/png',
        maxCount: 1,
        multiple: false,
        fileName: 'files',
        uploadType: 'single',
        uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/109/upload`,
        mode: 'picture-card',
        maxSize: 1 * 1024,
        preview: {
            size: 500,
        },
        uploadLimitSizeErrorMessage:
            services.language.getText('pictures') +
            '{{file.name}}' +
            services.language.getText('pictureUploadLimit1'),
        limitTips: {
            limitUnit: '张。建议图片100*100方图；大小不超过1M；jpg格式。',
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: { span: 8 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    const formElement = (
        <Form layout="horizontal" {...formItemLayout}>
            <Form.Item label="图片配置">
                <ImageUpload
                    {...imageControlInfo}
                    onChange={(value: any, name: string) => {
                        onPicChange(value);
                        return {};
                    }}
                />
            </Form.Item>
        </Form>
    );

    return (
        <Fragment>
            <Button
                style={{
                    marginLeft: '20px',
                }}
                icon="setting"
                type="primary"
                onClick={handleVisibleChange}
            >
                {language.getText('pzjhmLogo')}
            </Button>
            <Modal
                title={language.getText('pzjhmLogo')}
                visible={visible}
                confirmLoading={updateLoading}
                onOk={handleUpdateSelectOptionsData}
                onCancel={handleVisibleChange}
            >
                {formElement}
            </Modal>
        </Fragment>
    );
};
