import React, { Component, createElement, Fragment } from 'react';
import { Tabs as AntTabs, Button, message } from 'antd';
import { map, snakeCase } from 'lodash';
import {
    navigation,
    api,
    localStorage,
    behaviorHandle,
    privilege,
} from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { StoreSelector, NotificationVoice, CodeScanner } from '../../../containers';

const AntTabPane = AntTabs.TabPane;

const TAB_STATUS = {
    waitingPick: 'djh',
    picking: 'jhz',
    finishPick: 'yjh',
    cancelled: 'qxjh',
    all: 'all',
    waitingDelivery: 'dfh_1',
};

enum TAB_STATUS_ENUM {
    waitingPick = 'waitingPick',
    picking = 'picking',
    finishPick = 'finishPick',
    cancelled = 'cancelled',
    all = 'all',
    waitingDelivery = 'waitingDelivery',
}

const TRANSFORM_STATUS = {
    cancelled: 'cancelledPickQuantity',
    picking: 'pickingQuantity',
    finishPick: 'finishPickQuantity',
    waitingPick: 'waitingPickQuantity',
    all: 'allQuantity',
    waitingDelivery: 'waitingDeliverQuantity',
};

//是否有门店数据
const hasStoreInfo = () => !!localStorage.get('cyStore');

const getStoreId = () => {
    let storeInfo: any = localStorage.get('cyStore');
    if (storeInfo && storeInfo.id) {
        return storeInfo.id;
    } else {
        return '';
    }
};

const playHandle = (total: any) => {
    let data = total || {};
    if (data.waitingPickQuantity > 0) {
        return true;
    } else {
        return false;
    }
};

/**
 * 动态创建订单调度台各个订单状态的tab
 */
export class OrderPickingTabs extends Component<any, any> {
    constructor(props: any) {
        super(props);
        //初始选中tab
        const { params } = props;
        let activeKey = null;
        if (params && params.status) {
            activeKey = params.status;
        } else {
            activeKey = 'waitingPick';
        }
        this.state = {
            activeKey,
            hasWaitPickOrder: false,
            openWaitPickOrder: false,
        };
    }

    fetchOrderStatus() {
        let fetch = () => {
            if (!hasStoreInfo()) {
                return;
            }
            api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
                    apiPath: `/admin/picking_lists/${getStoreId()}/verify_picking_subsite`,
                }
            ).then((openWaitPickOrder: any) => {
                this.setState({
                    openWaitPickOrder,
                });
                if (openWaitPickOrder) {
                    const orderStatusPromise = api.get(
                        { subsiteId: hasStoreInfo() ? getStoreId() : '' },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
                            apiPath: '/admin/picking_lists/tabs',
                        }
                    );
                    const waitingDeliveryPromise = api.get(
                        { subsiteId: hasStoreInfo() ? getStoreId() : '' },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/orders/status/waiting_deliver_orders/quantity',
                        }
                    );
                    Promise.all([orderStatusPromise, waitingDeliveryPromise]).then((res) => {
                        this.setState({
                            playTimer: new Date().getTime(),
                            hasWaitPickOrder: playHandle(res[0]),
                            totalOrderStatus: Object.assign(
                                {},
                                {
                                    waitingPickQuantity: 0,
                                    pickingQuantity: 0,
                                    finishPickQuantity: 0,
                                    cancelledPickQuantity: 0,
                                    allQuantity: 0,
                                    waitingDeliverQuantity: res[1] ? res[1] : 0,
                                },
                                res[0]
                            ),
                        });
                    });
                } else {
                    this.setState({
                        waitingPickQuantity: 0,
                        pickingQuantity: 0,
                        finishPickQuantity: 0,
                        cancelledPickQuantity: 0,
                        allQuantity: 0,
                        waitingDeliverQuantity: 0,
                    });
                }
            });
        };
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(fetch, 60 * 1000);
        fetch();
    }

    componentDidMount() {
        //发送请求，获取到订单状态
        if (typeof this.props.params.status === 'undefined') {
            const history = navigation.getHistory();
            history.replace({ pathname: '/order-picking-assistant/' + this.state.activeKey });
        }
        this.fetchOrderStatus();

        window.addEventListener('storage', this.refreshOrder);
    }

    timer: any;

    componentWillUnmount() {
        clearInterval(this.timer);
        window.removeEventListener('storage', this.refreshOrder);
    }

    createPanes = () => {
        const props = this.props;
        const { items, entities } = props;
        const { totalOrderStatus, activeKey } = this.state;
        const fetchOrderStatus = this.fetchOrderStatus.bind(this);
        return map(items, function(componentName, status) {
            let tab = services.language.getText(TAB_STATUS[status as TAB_STATUS_ENUM]);
            if (
                totalOrderStatus &&
                !!totalOrderStatus[TRANSFORM_STATUS[status as TAB_STATUS_ENUM]]
            ) {
                tab =
                    tab +
                    '（' +
                    totalOrderStatus[TRANSFORM_STATUS[status as TAB_STATUS_ENUM]] +
                    '）';
            }
            let dynamic = ComponentsManager.get(componentName);

            let component = createElement(dynamic, {
                params: {
                    pickingListStatus: snakeCase(status).toUpperCase(),
                    subsiteId: getStoreId(),
                },
                isActive: activeKey === status,
                refresh: fetchOrderStatus,
                entities,
            });

            return (
                <AntTabPane tab={tab} key={status}>
                    {component}
                </AntTabPane>
            );
        });
    };

    onChange = (activeKey: any, keyword?: string) => {
        this.setState({ activeKey });
        const history = navigation.getHistory();
        if (keyword) {
            history.replace({
                pathname: '/order-picking-assistant/' + activeKey + `?keyword=${keyword}`,
            });
        } else {
            history.replace({
                pathname: '/order-picking-assistant/' + activeKey,
            });
        }

        this.fetchOrderStatus();
    };

    refreshOrder = (event: any) => {
        if (`order-refresh-${window.location.host}` === event.key) {
            this.fetchOrderStatus();
        }
    };

    onNavigationToSetting = () => {
        if (privilege.check('orderSetting', 'full')) {
            behaviorHandle({
                route: '/order-setting',
            });
        } else {
            message.warning(services.language.getText('ndqmyglmqx'));
        }
    };

    render() {
        if (!this.state) {
            return null;
        }
        let panes = this.createPanes();
        const activeKey = this.state.activeKey;
        const { playTimer, hasWaitPickOrder, openWaitPickOrder } = this.state;
        const { entity, entities } = this.props;

        return (
            <Fragment>
                <NotificationVoice
                    style={{
                        position: 'absolute',
                        top: 15,
                        left: 300,
                        zIndex: 5,
                    }}
                    hasWaitPickOrder={hasWaitPickOrder}
                    playTimer={playTimer}
                    goto={this.onChange}
                />
                <CodeScanner onSearch={this.onChange} activeKey={activeKey} entities={entities} />
                <StoreSelector
                    refresh={this.fetchOrderStatus.bind(this)}
                    entity={entity}
                    entities={entities}
                />
                {openWaitPickOrder ? (
                    <div style={{ color: '#f00' }}>{services.language.getText('dqmdykqjh')}</div>
                ) : (
                    <div style={{ color: '#ccc' }}>
                        {services.language.getText('dqmdygbjhgn')}
                        <Button type="link" onClick={this.onNavigationToSetting}>
                            {services.language.getText('settings')}&gt;
                            {services.language.getText('settingMore')}&gt;
                            {services.language.getText('orderSetting')}&gt;
                            {services.language.getText('jhsz')}
                        </Button>
                        {services.language.getText('ztjmd')}
                    </div>
                )}
                <AntTabs activeKey={activeKey} animated={false} onChange={this.onChange}>
                    {panes}
                </AntTabs>
            </Fragment>
        );
    }
}
