import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { forEach, get, defaults, isEqual, map, set } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

let Entity: any = {};
let entity: any = {};
const componentId = 'CardLevelRightAddForm';

export interface CardLevelRightAddProps extends FormComponentProps {
    onAdd: (cardLevelRight: any) => void;
    data: any;
    onSubmit: (e: any, fields: any) => void;
    onInit: (fields?: any) => void;
    getForm: () => any;
    onEdit?(data: any): void;
}

export interface CardLevelRightAddState {
    visible: boolean;
    fields: any;
}

/**
 * 添加权益
 */
class cardLevelRightAdd extends PureComponent<CardLevelRightAddProps, CardLevelRightAddState> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('cardLevelRight');
        entity = new Entity({});
    }

    state: CardLevelRightAddState = {
        visible: false,
        fields: undefined,
    };

    open = (fields: any) => {
        this.props.onInit(fields);
        this.setState({
            fields,
            visible: true,
        });
    };

    onSubmit = (event: any, fields: any) => {
        event.stopPropagation();
        const { onAdd, onEdit, getForm } = this.props;
        const form = getForm();
        form.validateFields(['name', 'iconPicture', 'context'], { force: true }).then(
            (entityFields: any) => {
                if (entityFields) {
                    const hasValidate = this.validate(entityFields);
                    if (hasValidate) {
                        let iconPicture: any = get(entityFields, 'iconPicture', null);
                        entityFields.iconId = iconPicture && iconPicture[0].id;
                        entityFields.iconUrl = iconPicture && iconPicture[0].path;

                        if (
                            entityFields.context &&
                            !entityFields.context.replace(/<\/?[\w\s/-="'&?.:]+\/?>/g, '')
                        ) {
                            entityFields.context = null;
                        }

                        if (this.state.fields) {
                            onEdit &&
                                onEdit({
                                    ...this.state.fields,
                                    ...entityFields,
                                });
                        } else {
                            onAdd && onAdd(entityFields);
                        }

                        this.toggleModal();
                    }
                }
            }
        );

        return;
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = (entityFields: any) => {
        const { data } = this.props;
        let name: any = get(entityFields, 'name', '');
        if (name) {
            let hasValidate = true;
            forEach(data, (item) => {
                if (!this.state.fields && isEqual(item.name, name)) {
                    AntMessage.warning(services.language.getText('qymcbnzf'));
                    hasValidate = false;
                    return false;
                }
            });
            if (!hasValidate) {
                return false;
            }
        }
        return true;
    };

    renderModal = () => {
        const { wrappedComponentRef } = this.props;
        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: componentId,
            onSubmit: this.onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 14,
            style: { width: 620 },
            fields: [
                { property: 'name', extra: '不能超过6个字' },
                { property: 'iconPicture', extra: '建议尺寸：75px * 75px，大小不超过1M' },
                { property: 'context' },
            ],
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: '确定',
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.toggleModal,
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    };

    toggleModal = () => {
        const { visible } = this.state;
        this.setState(
            {
                visible: !visible,
                fields: undefined,
            },
            () => {
                const { visible } = this.state;
                if (!visible) {
                    this.props.onInit();
                }
            }
        );
    };

    render() {
        const modalContent = this.renderModal();
        const modalProps = {
            width: 800,
            title: '新增权益项',
            visible: this.state.visible,
            footer: null,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                <Button type={'link'} onClick={this.toggleModal}>
                    新增权益项
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onInit, onSubmitStart } = props;
    let form: any;

    const handleInit = (fields?: any) => {
        let {
            id: entityId,
            metainfo: { properties },
            fields: fieldsFromProps,
        } = entity;
        const loaded = !!fieldsFromProps;

        if (!fields) {
            let fields: { [key: string]: any } = {};
            map(fieldsFromProps, (field) => {
                let { property } = field;

                // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
                let entityProperty = get(properties, property.replace('.', '.properties.'));
                if (entityProperty) {
                    set(fields, property, entityProperty.defaultValue || undefined);
                } else {
                    throw new Error(`Property ${property} not found in Entity ${entityId}`);
                }
            });

            _dispatch(
                actions.formInitAction(componentId, {
                    type: 'CreateForm',
                    entityId: entity.id,
                    fields,
                })
            );
        } else {
            setTimeout(() => {
                _dispatch(
                    actions.formInitAction(componentId, {
                        type: 'CreateForm',
                        entityId: entity.id,
                        fields: {
                            ...fields,
                        },
                        loaded,
                    })
                );
            });
        }
    };

    return defaults(
        {
            onSubmit,
            onInit,
            onSubmitStart,
        },
        {
            onInit: handleInit,
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}

export const CardLevelRightAdd = connect(null, mapDispatchToProps, null, {
    forwardRef: true,
})(cardLevelRightAdd);
