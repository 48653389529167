import React, { PureComponent, createElement, Fragment } from 'react';

import {
    ComponentsManager,
    services,
    Loader,
    builder,
    actions,
} from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal } from 'antd';
import { mapValues } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        fields: entity.fields,
        ...props,
    };
}

const ModalEditEscapeWordForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('ModifyForm'), {
        ...props,
        componentId: `ModalEditEscapeWordForm-${props.row.id}`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [
            {
                property: 'escapeWordKey',
                controlConfig: {
                    disabled: true,
                    style: {
                        width: 250,
                    },
                },
            },
            {
                property: 'escapeWordValue',
            },
            { property: 'id', className: 'hidden' },
        ],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            const id = entityFields['id'];
            const params = {
                escapeWordKey: entityFields['escapeWordKey'],
                escapeWordValue: entityFields['escapeWordValue'],
            };
            Loader.load('put', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/escape_word/${id}`,
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(language.getText('editSuccess'), 1.5);
                })
                .catch(services.errorHandle);
        },
    });
});

const ModalCreateEscapeWordForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('CreateForm'), {
        ...props,
        componentId: `ModalCreateEscapeWordForm`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [
            {
                property: 'escapeWordKey',
            },
            {
                property: 'escapeWordValue',
            },
        ],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });

            const params = {
                escapeWordKey: entityFields['escapeWordKey'],
                escapeWordValue: entityFields['escapeWordValue'],
            };
            Loader.load('post', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/escape_word',
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(language.getText('common.addSuccess'), 1.5);

                    let { fields: entityFields } = entity;
                    const loaded = !!entityFields;
                    builder.getStore().dispatch(
                        actions.formInitAction('ModalCreateEscapeWordForm', {
                            type: 'EditForm',
                            entityId: entity.id,
                            fields: { escapeWordKey: '', escapeWordValue: '' },
                            loaded,
                        })
                    );
                })
                .catch(services.errorHandle);
        },
    });
});

export class SetEscapeWordModal extends PureComponent<any> {
    state = { visible: false };
    openModal = () => {
        this.setState({ visible: true });
        const { edit } = this.props;
        if (edit) {
            const { entity, row } = this.props;
            entity &&
                entity.setFields({
                    ...row,
                });
        }
    };

    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
    };
    render() {
        const { edit, triggerComponent } = this.props;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };
        const title = !!edit
            ? language.getText('editTranslationKey')
            : language.getText('addTranslationKey');
        return (
            <Fragment>
                {createElement(ComponentsManager.get('Button'), buttonProps)}
                <Modal
                    visible={this.state.visible}
                    title={title}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="580px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        const { entity, row, edit } = this.props;
                        let { fields: entityFields } = entity;
                        const loaded = !!entityFields;
                        if (!!edit) {
                            builder.getStore().dispatch(
                                actions.formInitAction(`ModalEditEscapeWordForm-${row.id}`, {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: { ...row },
                                    loaded,
                                })
                            );
                        } else {
                            builder.getStore().dispatch(
                                actions.formInitAction('ModalCreateEscapeWordForm', {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: { escapeWordKey: '', escapeWordValue: '' },
                                    loaded,
                                })
                            );
                        }

                        this.setState({ visible: false });
                    }}
                >
                    {edit ? (
                        <div>
                            <ModalEditEscapeWordForm
                                {...this.props}
                                wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                                onClose={() => {
                                    this.setState({ visible: false });
                                }}
                            />
                        </div>
                    ) : (
                        <ModalCreateEscapeWordForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    )}
                </Modal>
            </Fragment>
        );
    }
}
