import { services } from '@comall-backend-builder/core';
import { refreshToken } from './refresh-token';
const api = services.api;
const statusCode = services.statusCode;
/**
 * 错误结构
 */
type ErrorResponse = {
    status: number;
    statusCode?: number;
};
services.swizzle(api, {
    errorHandle(error: ErrorResponse) {
        // @ts-ignore
        error.statusCode = statusCode[error.status];
        if (error.status === statusCode.UNAUTHORIZED) {
            refreshToken.logout();
        }
        return Promise.reject(error);
    },
});
