import React, { Component } from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { AuthScopeEnum } from '../wechat-official-accounts-auth-list-page';
import './index.less';

interface Props {
    row: any;
    entity: any;
}
export class WechatOfficialAccountsEmpowerButton extends Component<Props, {}> {
    //跳往授权页面
    setEmpower = () => {
        const { row } = this.props;
        api.post(
            {
                appId: row.appId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: `/admin/wechat_official_accounts/auth_page`,
            }
        )
            .then((result: any) => {
                //火狐浏览器window.open出现拦截弹层，采用setTimeout解决
                setTimeout(() => {
                    window.open(result.authPageUrl);
                }, 100);
            })
            .catch((error) => {
                services.errorHandle(error);
            });
    };

    //授权权限页面
    goEmpower = () => {
        const { row } = this.props;
        window.location.href = `/#/wechat-official-accounts/auth?appId=${row.appId}&name=${row.name}`;
    };

    //日志页面
    goLog = () => {
        const { row } = this.props;
        window.location.href = `/#/wechat-official-accounts/logs/${row.id}`;
    };
    render() {
        const { row } = this.props;
        if (!row) {
            return null;
        }
        const menu =
            row.authScope === AuthScopeEnum.NONE ? (
                <Menu>
                    <Menu.Item>
                        <Button type="link" onClick={this.setEmpower}>
                            {language.getText('sq_2')}
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={this.goLog}>
                            {language.getText('common.log')}
                        </Button>
                    </Menu.Item>
                </Menu>
            ) : (
                <Menu>
                    <Menu.Item>
                        <Button className="first-button" type="link" onClick={this.setEmpower}>
                            {language.getText('cxsq')}
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={this.goEmpower}>
                            {language.getText('cksq')}
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={this.goLog}>
                            {language.getText('common.log')}
                        </Button>
                    </Menu.Item>
                </Menu>
            );
        return (
            <Dropdown overlay={menu}>
                <span className="dropdown-link" onClick={(e) => e.preventDefault()}>
                    {language.getText('common.more')} <Icon type="down" />
                </span>
            </Dropdown>
        );
    }
}
