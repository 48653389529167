import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

const letter = [
    'ALL',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'Other',
];
const FILTER_OPTIONS = () => {
    return letter.map((item) => {
        return {
            id: item,
            name: item,
        };
    });
};

export const config: Config = {
    entities: {
        FilterWordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/filter_word',
            filters: {
                filterWord: {
                    type: 'string',
                    displayName: '<<mgz>>',
                    controlConfig: {
                        placeholder: '<<qsrmgz>>',
                        style: { width: 250 },
                    },
                },
                filterWordPhonetic: {
                    type: 'string.options.select',
                    displayName: '<<azmsx>>',
                    options: FILTER_OPTIONS(),
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
            },
            properties: {
                filterWord: {
                    displayName: '<<mgz>>',
                    type: 'string.text.paragraph',
                    rules: [{ required: true, message: '<<mgzbnwk>>' }],
                    controlConfig: {
                        placeholder: '<<h_qsrmgz_msg>>',
                        maxLength: 120,
                        style: {
                            width: 220,
                        },
                    },
                },
                batchName: {
                    displayName: '<<mgz>>',
                    type: 'string.text.paragraph',
                    controlConfig: {
                        placeholder: '<<h_mgcydhgkr>>',
                        rows: 4,
                        style: {
                            width: 220,
                        },
                    },
                },
                id: {
                    type: 'number',
                },
            },
        },
    },
    components: {
        FilterWordView: {
            component: 'Viewport',
            entity: 'FilterWordEntity',
        },
        FilterWordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterWordFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        marginBottom: 24,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'SetFilterWordModal',
                                    triggerComponent: {
                                        component: 'Button',
                                        icon: 'plus',
                                        type: 'primary',
                                        text: '<<xzmgz>>',
                                        style: { marginRight: '10px' },
                                    },
                                },
                                {
                                    component: 'SetFilterWordModal',
                                    batchAdd: true,
                                    triggerComponent: {
                                        component: 'Button',
                                        icon: 'plus',
                                        type: 'primary',
                                        text: '<<pltjmgz>>',
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'FilterWordTable' },
            ],
        },
        FilterWordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                {
                    property: 'filterWord',
                },
                {
                    property: 'filterWordPhonetic',
                },
            ],
        },
        FilterWordTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ParameterDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'filterWord',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'SetFilterWordModal',
                                config: {
                                    edit: true,
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<common.edit>>',
                                        style: { marginRight: '10px' },
                                    },
                                },
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/filterWord',
                    component: 'FilterWordView',
                    breadcrumbName: '<<filterWord>>',
                    privilege: {
                        path: 'filterWord',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'FilterWordPage' }],
                },
            ],
        },
    ],
};
