import React, { Component } from 'react';
import { get } from 'lodash';
import './index.less';

export class HeaderLogo extends Component<any, any> {
    render() {
        let { fields } = this.props;
        // const defaultLogo = 'logo-dark.png';
        const picDomain = get(fields, 'picDomain');
        const frameLogoFileUrl = get(fields, 'frameLogoFileUrl');
        const logoPath = picDomain + frameLogoFileUrl;

        return fields && picDomain && frameLogoFileUrl ? (
            <div className="logo-view">
                <a href="/#/">{frameLogoFileUrl && <img alt="logo" src={logoPath} />}</a>
            </div>
        ) : null;
        //  (
        //     <div className="logo-view">
        //         <a href="/#/">
        //             <img alt="logo" src={defaultLogo} />
        //         </a>
        //     </div>
        // );
    }
}
