import * as OpenCC from 'opencc-js';
import { zhCN } from './zh-CN';
import { translater } from './utils';

/**
 * 简体中文转港澳繁体转换器
 */
const hkConverter = OpenCC.Converter({
    from: 'cn',
    to: 'hk',
});

/**
 * 将人民币单位转为港币单位
 */
function replaceMonetaryUnit(text: string) {
    return text
        .replace('元', '港币')
        .replace('人民币', '港币')
        .replace('￥', 'HK$');
}

const handler = (text: string) => {
    return hkConverter(replaceMonetaryUnit(text));
};

const zhHK = translater(zhCN, handler);

export { zhHK };
