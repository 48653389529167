import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const CouponConsumeLoader: Loader = {
    async get(data, config) {
        if (data.consumeTime) {
            data = {
                ...data,
                startTime: data.consumeTime.start,
                endTime: data.consumeTime.end,
                consumeTime: undefined,
            };
        }

        const apiPath = data.id
            ? '/admin/coupon_consume_records/:id'
            : '/admin/coupon_consume_records';

        return api.get(data, {
            ...config,
            apiPath,
        });
    },
};
