import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const DrawLotsReplenishLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`;
        config.apiPath = '/admin/draw_lots_replenish';
        const { id } = data;
        if (!id) {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            res.result.map((i: any) => (i.activeTime = i.startTime + '-' + i.endTime));
            return res;
        });
    },
};

export const TicketMessageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`;
        config.apiPath = '/admin/draw_lots_replenish/record';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/draw_lots_replenish/record/${id}`;
        }
        return await api.get(data, config);
    },
};

export const TicketMessageDownloadLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`;
        config.apiPath = '/admin/draw_lots_replenish/import_record';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result.map(
                (i: any) =>
                    (i.importResult =
                        '全部（' + i.allQuantity + '），失败（' + i.failQuantity + '）')
            );
            return res;
        });
    },
};
