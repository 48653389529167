import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { ComponentsManager, Entity } from '@comall-backend-builder/core/lib/parser';
import { api, language, navigation } from '@comall-backend-builder/core/lib/services';
import { Modal, Input } from 'antd';
import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';

export interface contentComplainDetailProps {
    fields: any;
    entity: Entity;
    componentId: string;
}
interface contentComplainState {
    visible: boolean;
    auditRemark: string;
}
class contentComplainDetail extends Component<contentComplainDetailProps, contentComplainState> {
    constructor(props: contentComplainDetailProps) {
        super(props);
        this.state = {
            visible: false,
            auditRemark: '',
        };
    }
    renderDetail() {
        const { entity, componentId } = this.props;
        const detailProps = {
            entity,
            componentId,
            labelCol: 5,
            fields: [
                {
                    property: 'contentType',
                },
                {
                    property: 'reason',
                },
                {
                    property: 'complainDetail',
                },
                {
                    property: 'complainContentAuthorMobile',
                },
                {
                    property: 'complainMemberMobile',
                },
                {
                    property: 'complainTime',
                },
                {
                    property: 'auditTime',
                },
                {
                    property: 'auditRemark',
                },
            ],
        };
        return createElement(ComponentsManager.get('Detail'), detailProps);
    }
    audit = () => {
        this.setState({ visible: true });
    };
    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ auditRemark: value });
    };
    auditSuccess = () => {
        const { auditRemark } = this.state;
        const { fields } = this.props;
        api.put(
            {
                auditRemark,
                auditStatus: 'SUCCESS',
                id: fields.id,
            },
            {
                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                apiPath: '/admin/complain/records/:id/audit_status',
            }
        ).then(() => {
            navigation.goBack();
        });
    };
    close = () => {
        this.setState({ visible: false, auditRemark: '' });
    };
    auditFail = () => {
        const { auditRemark } = this.state;
        const { fields } = this.props;
        api.put(
            {
                auditRemark,
                auditStatus: 'FAIL',
                id: fields.id,
            },
            {
                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                apiPath: '/admin/complain/records/:id/audit_status',
            }
        ).then(() => {
            navigation.goBack();
        });
    };
    render() {
        const { visible, auditRemark } = this.state;
        const { fields } = this.props;
        return (
            <div>
                {this.renderDetail()}
                <div style={{ textAlign: 'center' }}>
                    {fields && fields.auditStatus === 'WAIT' && (
                        <Button type="primary" onClick={this.audit} style={{ marginRight: 24 }}>
                            {services.language.getText('audity')}
                        </Button>
                    )}
                    <Button route="goBack">{language.getText('common.cancel')}</Button>
                </div>
                <Modal
                    visible={visible}
                    okText={language.getText('jbcg')}
                    cancelText={language.getText('jbsb')}
                    title={language.getText('shbz')}
                    onOk={this.auditSuccess}
                    onCancel={this.close}
                    cancelButtonProps={{
                        onClick: this.auditFail,
                    }}
                >
                    <div>
                        <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            onChange={this.inputChange}
                            value={auditRemark}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        fields: entity.fields || {},
    };
}
export const ContentComplainDetail = connect(mapStateToProps)(contentComplainDetail);
