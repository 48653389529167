import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { NumberInputTip } from '../../../components';
import UserConditionModal from '../maotai-presale-prize-quality-limit/userConditionModal';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface ValueItem {
    conditionType?: string;
    checked?: string;
    num?: number | undefined;
}

export interface MaotaiDrawPrizeQualityLimitProps {
    name: string;
    value: any[];
    onChange: (value: ValueItem[]) => void;
    drawType: string;
}
export class MaotaiDrawQualityLimit extends PureComponent<MaotaiDrawPrizeQualityLimitProps> {
    static defaultProps = {
        value: [],
    };

    state = { visible: false, conditionRules: [], editParentRuleIndex: null };

    addRow = () => {
        const { onChange, value } = this.props;

        onChange(
            value && value.length
                ? [...value, { ruleCommands: [[]], quantity: '' }]
                : [{ ruleCommands: [[]], quantity: '' }]
        );
    };

    onOpen(conditionRules: any[], editParentRuleIndex: number) {
        this.setState({ conditionRules, editParentRuleIndex }, () => {
            this.setState({
                visible: true,
            });
        });
    }
    numChange = (val: number, index: number) => {
        const { value, onChange } = this.props;
        const data = value.map((i: any, itemIndex: number) => {
            if (itemIndex === index) {
                return { ...i, quantity: val };
            }
            return i;
        });
        onChange(data);
    };

    onOkClick = (val: any) => {
        const { value, onChange } = this.props;

        const data = value.map((i: any, itemIndex: number) => {
            if (this.state.editParentRuleIndex === itemIndex) {
                return { ...i, ruleCommands: val };
            }
            return i;
        });

        onChange(data);
        this.setState({ visible: false });
    };

    onCloseClick = () => {
        this.setState({ visible: false });
    };
    delete = (index: number) => {
        const { value, onChange } = this.props;

        const data = value.filter((_i: any, itemIndex: number) => index !== itemIndex);

        onChange(data);
    };

    render() {
        const { value, drawType } = this.props;
        const { conditionRules, visible } = this.state;
        const _this = this;
        const showQuality = drawType === 'CUSTOM';
        const columns = [
            {
                key: 'sequence',
                title: services.language.getText('xh_1'),
                dataIndex: 'sequence',
                render(_value: any, _record: any, index: number) {
                    return <span>{index + 1}</span>;
                },
            },
            {
                key: 'ruleCommands',
                title: services.language.getText('xzrytj'),
                dataIndex: 'ruleCommands',

                render(value: any, _record: any, index: number) {
                    return (
                        <Button
                            onClick={() => {
                                _this.onOpen(value || [], index);
                            }}
                            type="link"
                        >
                            {services.language.getText('xzrytj')}
                        </Button>
                    );
                },
            },
        ];
        if (showQuality) {
            columns.push({
                key: 'quantity',
                title: services.language.getText('kzdsl'),
                dataIndex: 'quantity',
                render(value: any, _record: any, index: number) {
                    return (
                        <NumberInputTip
                            min={0}
                            value={value}
                            precision={0}
                            max={100}
                            onChange={(val: number) => {
                                _this.numChange(val, index);
                            }}
                            style={{ width: 120 }}
                            addonafter="%"
                        ></NumberInputTip>
                    );
                },
            });
        }

        columns.push({
            key: 'action',
            title: services.language.getText('common.tableAction'),
            dataIndex: 'action',
            render(_value, _record, index) {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            _this.delete(index);
                        }}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                );
            },
        });

        return (
            <div className="maotai-presale-prize-quality-limit">
                <div>
                    <span>{services.language.getText('hmdrybcyjs')}</span>
                    {value.length < 10 && (
                        <Button type="link" onClick={this.addRow}>
                            {services.language.getText('xzcjcs')}
                        </Button>
                    )}
                </div>
                {!!(value && value.length) && (
                    <div style={{ width: 800 }}>
                        <Table dataSource={value} columns={columns}></Table>
                    </div>
                )}
                <UserConditionModal
                    onOk={this.onOkClick}
                    onClose={this.onCloseClick}
                    visible={visible}
                    conditionRules={conditionRules}
                    {...this.props}
                ></UserConditionModal>
            </div>
        );
    }
}
