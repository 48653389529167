import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import { formatDate } from '../order/services';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
const prefix = 'invitation-activity-preview';
const pagePrefix = 'invite-reward-detail';
const innerClassNames = {
    top: `${prefix}__tpp`,
    title: `${prefix}__title`,
    box: `${prefix}__box`,
    ruler: `${prefix}__ruler`,
    rulerText: `${prefix}__ruler__text`,

    middle: `${pagePrefix}__middle`,
    middleTitle: `${pagePrefix}__middle__title`,

    middleInner: `${pagePrefix}__middle__inner`,
    middleInnerTitle: `${pagePrefix}__middle__inner__title`,
    middleInnerNext: `${pagePrefix}__middle__inner__next`,
    middleInnerNextRed: `${pagePrefix}__middle__inner__next__red`,
    middleInnerTime: `${pagePrefix}__middle__inner__time`,

    shareBtn: `${pagePrefix}__share-btn`,
    bottom: `${pagePrefix}__bottom`,
    bottomTitle: `${pagePrefix}__bottom__title`,
    listWrap: `${pagePrefix}__list-wrap `,
    list: `${pagePrefix}__list`,
    rowup: `${pagePrefix}__rowup `,

    listItem: `${pagePrefix}__list__item`,
    listItemLeft: `${pagePrefix}__list__item__left`,
    listItemLeftAvatar: `${pagePrefix}__list__item__left__avatar`,
    listItemLeftStatus: `${pagePrefix}__list__item__left__status`,
    listItemLeftName: `${pagePrefix}__list__item__left__name`,
    listItemRight: `${pagePrefix}__list__item__right`,
};

const enum CouponType {
    Cash = 'CASH',
    Gift = 'GIFT',
    Parking = 'PARKING',
    Package = 'PACKAGE',
    Freight = 'FREIGHT',
    Lottery = 'LOTTERY',
    Balance = 'BALANCE',
    EfutureCash = 'EFUTURE_CASH',
    EfutureBalance = 'EFUTURE_BALANCE',
    BfplatCoupon = 'BFPLAT_COUPON',
}

const CouponTypeValue: any = {
    Cash: 'cashCoupon',
    Gift: 'gift',
    Parking: 'parking',
    Package: 'zhqb',
    Freight: 'freight',
    Lottery: 'lottery',
    Balance: 'yhq',
    EfutureCash: 'mzq',
    EfutureBalance: 'yeq',
    BfplatCoupon: 'bfplat',
};

/**
 * 邀请有礼预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.bottomPictureUrl &&
        preview.pictureInfo.bottomPictureUrl.length > 0
            ? preview.pictureInfo.bottomPictureUrl[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const name = preview?.baseInfo.name || '';
    return (
        <div className={prefix}>
            <div className={innerClassNames.top}>
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className={innerClassNames.title}>{name}</div>
            </div>
            {backgroundImage && (
                <div className={innerClassNames.box} style={backgroundImage}>
                    {renderRuler(preview)}
                    {renderStepSec(preview)}
                    {renderList(preview)}
                </div>
            )}
        </div>
    );
};
const renderRuler = (preview: any) => {
    const description =
        preview && preview.pictureInfo && preview.pictureInfo.description
            ? preview.pictureInfo.description
            : '';
    if (!description) {
        return null;
    }
    return <div className={innerClassNames.ruler}>{language.getText('rule')}</div>;
};

const renderStepSec = (preview: any) => {
    let start = '';
    let end = '';
    if (preview && preview.baseInfo && preview.baseInfo.dateRange) {
        const startString = new Date(preview.baseInfo.dateRange.start);
        const endString = new Date(preview.baseInfo.dateRange.end);
        start = formatDate(startString, 'yyyy.MM.dd hh:mm');
        end = formatDate(endString, 'yyyy.MM.dd hh:mm');
    }
    // let tip = '';
    // let stepValue = '';
    // if (preview && preview.inviterRewards && preview.inviterRewards.length > 0) {
    //     const stepReward = tranformRewardsToValue(preview);
    //     tip = stepReward[0].join('+');
    //     stepValue = preview.inviterRewards[0].stepValue;
    // }
    return (
        <div className={innerClassNames.middle}>
            <div className={innerClassNames.middleTitle}>{language.getText('nhwcgyqhy')}</div>
            <div className={innerClassNames.middleInner}>
                <div className={innerClassNames.middleInnerTitle}>
                    {language.getText('fhdyqjldnln')}
                </div>
                {renderStepRewards(preview)}
                {renderStepRewardsb(preview)}
                {/*<div className={innerClassNames.middleInnerNext}>*/}
                {/*    邀请*/}
                {/*    <span className={innerClassNames.middleInnerNextRed}>{stepValue}</span>*/}
                {/*    名好友可获得{tip}*/}
                {/*</div>*/}
                {renderShareBtn(preview)}
                {start && end && (
                    <div className={innerClassNames.middleInnerTime}>
                        {language.getText('acitivityTime')}：{start}-{end}
                    </div>
                )}
            </div>
        </div>
    );
};

const renderShareBtn = (preview: any) => {
    const shareBtnUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.buttonPictureUrl &&
        preview.pictureInfo.buttonPictureUrl.length > 0
            ? preview.pictureInfo.buttonPictureUrl[0].path
            : '';
    const btnStyle = {
        backgroundImage: `url(${shareBtnUrl})`,
    };
    return (
        <div className={innerClassNames.shareBtn} style={btnStyle}>
            {language.getText('ljyq')}
        </div>
    );
};

const renderStepRewardsb = (preview: any) => {
    if (!preview || !preview.inviterRewards || preview.inviterRewards.length === 0) {
        return null;
    }
    if (!getChecked(preview)) {
        return null;
    }
    const len = preview.inviterRewards.length || 0;
    const stepClassNames = {
        stepsb: `${pagePrefix}__stepsb`,
        stepsbItem: `${pagePrefix}__stepsb-item`,
        itemb: `${pagePrefix}__stepsb__itemb ${pagePrefix}__stepsb__itemb-${len}`,
        itembTip: `${pagePrefix}__stepsb__itemb__tip `,
        itembValue: `${pagePrefix}__stepsb__itemb__value `,
    };
    const stepReward = tranformRewardsToValue(preview);
    return (
        <div className={stepClassNames.stepsb}>
            {preview.inviterRewards.map((it: any, i: number) => {
                const inviteTip =
                    i === 0 && it.stepValue === 1
                        ? language.getText('scyq')
                        : services.interpolate(language.getText('inviterMuchPeople'), {
                              stepValue: it.stepValue ? it.stepValue : '',
                          });
                const inviteValueText = stepReward[i].join('+');
                return (
                    <div key={i} className={stepClassNames.stepsbItem}>
                        <div className={stepClassNames.itemb}>
                            <div className={stepClassNames.itembTip}>{inviteTip}</div>
                            <div className={stepClassNames.itembValue}>
                                {i === 0
                                    ? `${language.getText('song')}${inviteValueText}`
                                    : `${language.getText('againSong')}${inviteValueText}`}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const tranformRewardsToValue = (preview: any) => {
    if (!preview || !preview.inviterRewards || preview.inviterRewards.length === 0) {
        return null;
    }
    const inviterRewards = preview.inviterRewards;
    const values = inviterRewards.map((it: any) => {
        const value: string[] = [];
        if (it.pointChecked && it.point) {
            value.push(`${Number(it.point).toFixed(0)}` + services.language.getText('point'));
        }
        if (it.experienceChecked && it.experience) {
            value.push(
                `${Number(it.experience).toFixed(0)}` +
                    services.language.getText('experienceManage')
            );
        }
        if (it.goldChecked && it.gold) {
            value.push(`${Number(it.gold).toFixed(0)}${it.goldTxtName}`);
        }
        if (it.couponsChecked && it.couponsValue && it.couponsValue.length > 0) {
            if (it.couponRewardNameChecked && it.couponRewardName) {
                value.push(it.couponRewardName);
            } else if (it.couponsValue[0]?.couponType === CouponType.Package) {
                value.push(services.language.getText('gzhqb'));
            } else {
                const allCouponMount: any = {
                    Cash: 0,
                    Gift: 0,
                    Parking: 0,
                    Freight: 0,
                    Lottery: 0,
                    Balance: 0,
                    EfutureCash: 0,
                    EfutureBalance: 0,
                    BfplatCoupon: 0,
                };
                it.couponsValue.forEach((couponsItem: any) => {
                    const type = couponsItem.type || couponsItem.couponType;
                    switch (type) {
                        case CouponType.Cash:
                            allCouponMount.Cash += couponsItem.quantity || 1;
                            break;
                        case CouponType.Gift:
                            allCouponMount.Gift += couponsItem.quantity || 1;
                            break;
                        case CouponType.Parking:
                            allCouponMount.Parking += couponsItem.quantity || 1;
                            break;
                        case CouponType.Freight:
                            allCouponMount.Freight += couponsItem.quantity || 1;
                            break;
                        case CouponType.Lottery:
                            allCouponMount.Lottery += couponsItem.quantity || 1;
                            break;
                        case CouponType.Balance:
                            allCouponMount.Balance += couponsItem.quantity || 1;
                            break;
                        case CouponType.EfutureCash:
                            allCouponMount.EfutureCash += couponsItem.quantity || 1;
                            break;
                        case CouponType.EfutureBalance:
                            allCouponMount.EfutureBalance += couponsItem.quantity || 1;
                            break;
                        case CouponType.BfplatCoupon:
                            allCouponMount.BfplatCoupon += couponsItem.quantity;
                            break;
                        default:
                            break;
                    }
                });
                Object.keys(allCouponMount).forEach((key) => {
                    if (allCouponMount[key] > 0) {
                        value.push(
                            `${allCouponMount[key]}${language.getText('zhang')}${language.getText(
                                CouponTypeValue[key]
                            )}`
                        );
                    }
                });
            }
        }
        if (it.customChecked && it.custom) {
            value.push(it.custom);
        }
        return value;
    });
    return values;
};

const getChecked = (preview: any) => {
    if (!preview || !preview.inviterRewards || preview.inviterRewards.length === 0) {
        return null;
    }
    const inviterRewards = preview.inviterRewards;
    let checked = false;
    inviterRewards.map((it: any) => {
        if (it.pointChecked && it.point) {
            checked = true;
        }
        if (it.experienceChecked && it.experience) {
            checked = true;
        }
        if (it.goldChecked && it.gold) {
            checked = true;
        }
        if (it.customChecked && it.custom) {
            checked = true;
        }
        if (it.customChecked) {
            checked = true;
        }
        if (it.couponsChecked && it.couponsValue && it.couponsValue.length > 0) {
            checked = true;
        }
        return it;
    });
    return checked;
};
const renderStepRewards = (preview: any) => {
    if (!preview || !preview.inviterRewards || preview.inviterRewards.length === 0) {
        return null;
    }
    if (!getChecked(preview)) {
        return null;
    }
    const len = preview.inviterRewards.length || 0;
    const stepClassNames = {
        steps: `${pagePrefix}__steps`,
        stepsItem: `${pagePrefix}__steps-item`,
        item: `${pagePrefix}__steps__item ${pagePrefix}__steps__item-${len}`,
        itemImg: `${pagePrefix}__steps__item__img`,
        gap: `${pagePrefix}__steps__gap ${pagePrefix}__steps__gap-${len}`,
    };
    return (
        <div className={stepClassNames.steps}>
            {preview.inviterRewards.map((it: any, i: number) => {
                const isLast = i === preview.inviterRewards.length - 1;
                const ladderGiftPicture =
                    it && it.ladderGiftPicture && it.ladderGiftPicture.length > 0
                        ? it.ladderGiftPicture[0].path
                        : '';
                return (
                    <div key={i} className={stepClassNames.stepsItem}>
                        <div className={stepClassNames.item}>
                            <img
                                alt=""
                                src={ladderGiftPicture}
                                className={stepClassNames.itemImg}
                            ></img>
                        </div>
                        {!isLast && <div className={stepClassNames.gap}></div>}
                    </div>
                );
            })}
        </div>
    );
};

const renderList = (preview: any) => {
    const markPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.markPictureUrl &&
        preview.pictureInfo.markPictureUrl.length > 0
            ? preview.pictureInfo.markPictureUrl[0].path
            : '';
    const bgStyle = {
        backgroundImage: `url(${markPictureUrl})`,
    };
    const list = [
        {
            name: services.language.getText('xtx'),
            invitationTime: '2021-11-20',
            status: services.language.getText('yyq'),
        },
        {
            name: services.language.getText('mxk'),
            invitationTime: '2021-11-20',
            status: services.language.getText('success'),
        },
        {
            name: services.language.getText('xnm'),
            invitationTime: '2021-11-20',
            status: services.language.getText('yyq'),
        },
    ];
    return (
        <div className={innerClassNames.bottom}>
            <div className={innerClassNames.bottomTitle}>{services.language.getText('yqhyjl')}</div>
            <div className={innerClassNames.listWrap}>
                <div>
                    {list.map((it, i) => {
                        const avatarUrl =
                            'https://b-puzhehei-cdn.co-mall.net/global/default-avatar.png';
                        return (
                            <div className={innerClassNames.listItem} key={i}>
                                <div className={innerClassNames.listItemLeft}>
                                    <img
                                        alt=""
                                        src={avatarUrl}
                                        className={innerClassNames.listItemLeftAvatar}
                                    />
                                    <span className={innerClassNames.listItemLeftName}>
                                        {it.name}
                                    </span>
                                    <span
                                        className={innerClassNames.listItemLeftStatus}
                                        style={bgStyle}
                                    >
                                        <span style={{ zoom: '0.8' }}>
                                            {services.language.getText('yyq')}
                                        </span>
                                    </span>
                                </div>
                                <div className={innerClassNames.listItemRight}>
                                    {it.invitationTime}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const InvitationActivityPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
