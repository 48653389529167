import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';

import { LinkTypeEffectPath } from '../../../components/link-type-effect-path';

export class LinkTypeEffectPathMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className } = displayInfo;
        return <div className={className}>{object}</div>;
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <LinkTypeEffectPath {...controlInfo}></LinkTypeEffectPath>;
    }
}
