import React, { PureComponent } from 'react';
import { SelectAutoComplete } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { get, cloneDeep } from 'lodash';
import { message as AntMessage } from 'antd';

const api = services.api;
let config: any = {};

/**
 * 支持添加编辑配置联动和自动匹配的下拉多选
 */
export class AutoCompleteSelect extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        const { row, needDependences, dependences, source } = props;
        if (source) {
            // 保存当前下拉框的source配置
            config = cloneDeep(source);
        }

        this.state = {
            //特殊详情页面
            pageType: props.pageType,
            // 当前的属性名
            name: props.name,
            // 依赖项的属性名
            dependences: props.dependences,
            // 依赖项的限制选择数量
            dependencesLimitLength: props.dependencesLimitLength,
            // 依赖项的值
            dependencesValue: row && get(row, dependences),
            // 依赖项的字段名 该字段有则代表 依赖项是否数组
            dependencesProperties: props.dependencesProperties,
            // 依赖变动时重新请求数据入参名称，如果未设置，默认入参名称为依赖项的属性名
            paramsName: props.paramsName ? props.paramsName : props.dependences,
            //是否展示需要依赖的提示语
            showNeedDependencesMessage: needDependences ? needDependences : false,
            //下拉框options值
            options: [],
        };
    }

    // 当props发生变化时，更新待选项数据
    componentWillReceiveProps(nextProps: any) {
        const { name, onChange, row } = nextProps;
        const { dependencesValue, dependences, dependencesLimitLength } = this.state;
        const nextDependencesValue = row && get(row, dependences);
        let showNeedDependencesMessage = false;
        // 如果props变更后，依赖项有值
        if (nextDependencesValue) {
            if (dependencesValue !== nextDependencesValue) {
                if (
                    dependencesLimitLength &&
                    nextDependencesValue.length !== dependencesLimitLength
                ) {
                    this.clearValue(nextProps);
                    return;
                }
                //清空已选择的值
                if (dependencesValue) {
                    if (onChange) {
                        onChange(null, name);
                    }
                }
                //重新请求options
                this.setState(
                    {
                        options: [],
                        dependencesValue: nextDependencesValue,
                        showNeedDependencesMessage: showNeedDependencesMessage,
                    },
                    () => {
                        this.loadOptions(nextDependencesValue);
                    }
                );
            }
        } else if (dependencesValue) {
            this.clearValue(nextProps);
        }
    }

    clearValue = (nextProps: any) => {
        let showNeedDependencesMessage = false;
        const { needDependences } = this.props;
        const { name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        if (dependencesValue) {
            //清空已选择的值
            if (onChange) {
                onChange(null, name);
            }
        }
        if (needDependences) {
            showNeedDependencesMessage = true;
        }
        // 清楚保存的依赖项的值
        this.setState({
            dependencesValue: null,
            options: [],
            showNeedDependencesMessage: showNeedDependencesMessage,
        });
    };

    loadOptions = (dependencesValue: any) => {
        let params: any = {};
        config.apiPath = this.props?.source?.apiPath.replace(/{id}/, dependencesValue);
        api.get(params, config).then((response: any) => {
            this.setState({
                options: response,
            });
        });
    };

    handleClick = () => {
        const { needDependencesMessage } = this.props;
        const { showNeedDependencesMessage } = this.state;
        if (showNeedDependencesMessage) {
            AntMessage.warning(needDependencesMessage);
            return false;
        }
    };

    render() {
        const props: any = this.props;
        let { options, showNeedDependencesMessage } = this.state;
        if (props.disabled) {
            showNeedDependencesMessage = true;
        }
        return (
            <div onClick={this.handleClick}>
                <SelectAutoComplete
                    {...props}
                    disabled={showNeedDependencesMessage}
                    options={options}
                />
            </div>
        );
    }
}
