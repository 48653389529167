import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';

import { get, mapValues, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface RoleAddFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
}

class RoleAddFormView extends PureComponent<RoleAddFormProps> {
    componentDidMount() {
        const { params } = this.props;
        const store = builder.getStore();
        store.dispatch(
            actions.formChangeAction(
                'DataFormPlus-RoleAddFormContainer',
                'baseInfo.parentName',
                params.parentName
            )
        );
    }

    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, data } = this.props;
        let fields = [
            {
                property: 'baseInfo.parentName',
                controlConfig: {
                    value: get(data, 'baseInfo.parentName'),
                },
            },
            {
                property: 'baseInfo.name',
            },
            // {
            //     property: 'baseInfo.sequence',
            // },
            {
                property: 'baseInfo.remark',
            },
            {
                property: 'authInfo.privilegeSetIds',
                className: 'auth-label-hidden',
            },
        ];

        const formProps = {
            entity,
            componentId: 'RoleAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data = get(_dispatch, 'components.["DataFormPlus-RoleAddFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(actions.unmountComponentAction('DataFormPlus-RoleAddFormContainer'));
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });
            const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展

            if (newData) {
                entity.add(newData, params);
            }
        },
    };
}
export const NewRoleManageAddForm = connect(mapStateToProps)(RoleAddFormView);
