import { api } from '@comall-backend-builder/core/lib/services';
import { ObjectFormat } from '@comall-backend-builder/types';
import { compose } from 'redux';

export enum WechatOfficialAccountFunctionType {
    MessageManager = '1',
    MemberManager = '2',
    AccountService = '3',
    Notification = '7',
    ResourceManager = '11',
    MenuManager = '15',
}
interface WechatOfficialAccountFunction {
    id: WechatOfficialAccountFunctionType;
    name: string;
    authStatus: boolean;
    description: string;
}
interface WechatOfficialAccountRecord {
    appId: string;
    authScope: string;
    componentAppId: string;
    functions: WechatOfficialAccountFunction[];
}
export class AutoCompleteAppIdBySubsiteObjectFormat extends ObjectFormat {}
type ValidateCallback = (msg?: string) => void;
type WechatOfficialAccountChecker = (
    records: WechatOfficialAccountRecord[],
    callback: ValidateCallback
) => any;
type WechatOfficialAccountCheckerMiddleware = (
    next: WechatOfficialAccountChecker
) => WechatOfficialAccountChecker;

const checkWechatOfficialAccountRecordsExist: WechatOfficialAccountCheckerMiddleware = (next) => (
    records,
    callback
) => {
    if (!records?.length) {
        callback('权限集未授权，请先授权，才可使用此项业务功能');
    } else {
        next(records, callback);
    }
};
export function createWechatOfficialAccountRecordsFunctionsAuth(
    checkTypes: WechatOfficialAccountFunctionType[]
) {
    const checkRecordsFunctionAuthed: WechatOfficialAccountCheckerMiddleware = (next) => (
        records,
        callback
    ) => {
        const unAuthedFunctions = records[0].functions
            .filter(({ id }) => checkTypes.includes(id))
            .filter(({ authStatus }) => !authStatus);
        if (unAuthedFunctions.length) {
            const message =
                unAuthedFunctions.map(({ name }) => `【${name}】`).join(',') +
                '权限集未授权，请先授权，才可使用此项业务功能';
            callback(message);
        } else {
            next(records, callback);
        }
    };
    return checkRecordsFunctionAuthed;
}
const checkPass: WechatOfficialAccountChecker = (_records, callback) => {
    callback();
};

export function createChecker(...checkMiddlewares: WechatOfficialAccountCheckerMiddleware[]) {
    return compose<WechatOfficialAccountChecker>(
        checkWechatOfficialAccountRecordsExist,
        ...checkMiddlewares
    )(checkPass);
}

export function createValidator(checker: WechatOfficialAccountChecker) {
    return (_rule: any, value: any, callback: ValidateCallback) => {
        if (!value) {
            callback();
            return;
        }
        api.get<WechatOfficialAccountRecord[]>(
            { appIds: value.appId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_accounts/auth_record',
            }
        )
            .then((records) => {
                checker(records, callback);
                callback();
            })
            .catch((error) => {
                let { response, status } = error;
                let msg = '公众号权限集请求已被终止。';
                if (status) {
                    if (response && response.body) {
                        msg = response.body.err_msg || response.body.message || response.body.error;
                    }
                    if (!msg) {
                        msg = '公众号权限集请求失败。';
                    }
                }
                callback(msg);
            });
    };
}
