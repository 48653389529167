import { services } from '@comall-backend-builder/core/';

export const GoodsLoader = {
    get: async function(data: any, config: any) {
        if (data.merchantId) {
            data.merchantIds = data.merchantId;
        }
        if (data.subsiteId) {
            data.subsiteIds = data.subsiteId;
        }
        delete data.merchantId;
        delete data.subsiteId;
        return await services.api.get(data, config);
    },
};
