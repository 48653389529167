import * as React from 'react';

import { modes } from '@comall-backend-builder/types';
import { UploadFile } from '@comall-backend-builder/components-basis';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

function Upload(props: any) {
    let _uploadProps = {
        ...props,
    };
    if (!props.value) {
        _uploadProps.value = props.getDefaultValue ? props.getDefaultValue() : undefined;
        props.onChange(_uploadProps.value);
    }
    const levelName = props?.row?.contentInfo?.levelName || '';
    return (
        <div className="wechat-card-background">
            <UploadFile {..._uploadProps} />
            <div className="wechat-card-background__level">
                <div className="wechat-card-background__level__name">
                    {levelName || props.getDefaultLevel()}
                </div>
                <div className="wechat-card-background__level__default">
                    {language.getText('mrdj')}
                </div>
            </div>
        </div>
    );
}

export class ImageObjectArrayForWechatCardBackgroundMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        let _controlInfo = Object.assign({}, controlInfo, {
            accept: controlInfo.accept ? controlInfo.accept : 'image/*',
            uploadType: 'multi',
            maxSize: controlInfo.maxSize ? controlInfo.maxSize : 2048,
        });

        return <Upload {..._controlInfo} />;
    }
}
