import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const MiniProgramChargesLoader: Loader = {
    async get(data, config: ApiRequestConfig) {
        if (data.dateRange) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!data.dateRange) {
            delete data.dateRange;
        }
        if (data.subsites && data.subsites.length) {
            data.subsiteIds = data.subsites.map((item: any) => item.id).join(',');
            delete data.subsites;
        }
        if (!data.projectName) {
            delete data.projectName;
        }

        return api.get(data, {
            ...config,
            apiPath: '/admin/coupon_dashboard/mini_program_charges',
        });
    },
};
