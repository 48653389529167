import React, { Component } from 'react';
import { map, parseInt, forEach, cloneDeep, findIndex } from 'lodash';
import { Divider, Row as AntRow, Col as AntCol, message as AntMessage, Spin } from 'antd';
import { Countdown } from '../../../components/order-picking-assistant/countdown';
import { MessageModal } from '@comall-backend-builder/components-basis';

import {
    GetOrderInfo,
    PickingLists,
    ORDER_STATUS_ENUM,
    GetPrintOrders,
    PickingListsOrders,
    FinishPicking,
    toTwo,
    ProductType,
    ReceiptInfo,
    GetReceiptInfo,
    FinishPickOrders,
    PickingListsOrderItems,
    GetPickingPriceDifference,
} from '../../../services/order-print';
import {
    api,
    errorHandle,
    language,
    loading,
    privilege,
} from '@comall-backend-builder/core/lib/services';
import { PickOrdersTemplate, PickReceiptTemplate } from '../../../components';
import { ORDER_STATUS } from '../../../services/constant';
import { GoodsList } from './goods-list';
import { ScanGoods } from './scan-goods';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface PickingListInfoStates {
    pickingLists: PickingLists;
    pickTime: number;
    countdownStatus: string;
    totalPickTime: number;
    title: string;
    color: string;
    orderPrintData: PickingLists[];
    pickingListOrders: PickingListsOrders[];
    receiptData: ReceiptInfo[];
}

export class PickingListInfo extends Component<any, PickingListInfoStates> {
    constructor(props: any) {
        super(props);
        this.state = {} as PickingListInfoStates;
        this.setPickOrderRef = this.setPickOrderRef.bind(this);
        this.setPickReceiptRef = this.setPickReceiptRef.bind(this);
    }
    PickOrderRef = null;
    PickReceiptRef = null;
    setPickOrderRef(ref: any) {
        this.PickOrderRef = ref;
    }

    setPickReceiptRef(ref: any) {
        this.PickReceiptRef = ref;
    }

    componentDidMount() {
        const { params } = this.props;
        loading.open();
        GetOrderInfo(params.id)
            .then((res) => {
                this.handleData(res);
            })
            .catch(errorHandle)
            .finally(loading.close);
    }

    /**
     * 刷新页面信息
     */
    refresh() {
        const {
            pickingLists: { id },
        } = this.state;
        GetOrderInfo(id)
            .then((res) => {
                this.handleData(res);
            })
            .catch(errorHandle);
    }

    /**
     * 倒计时时间变更
     * @param second 倒计时秒数
     */
    onTimeChange = (second: number) => {
        if (second < 300 && second > 0) {
            this.setState({
                color: 'red',
            });
        } else if (second <= 0) {
            this.setState({
                color: 'red',
                title: services.language.getText('jhcs'),
                countdownStatus: 'timeout',
            });
        }
    };

    /**
     * 拣货信息输入
     * @param field 表更的属性key
     * @param value 变更的属性value
     * @param id 变更的商品项id
     * @param orderId 变更的商品项所属订单ID
     */
    onfieldChange = (field: string, value: number | string, id: string, orderId: string) => {
        const { pickingListOrders } = this.state;
        const orderIndex = findIndex(pickingListOrders, (order) => order.orderId === orderId);
        let orderItemIndex = -1;
        if (orderIndex !== -1) {
            orderItemIndex = findIndex(
                pickingListOrders[orderIndex].orderItems,
                (item) => item.id === id
            );
        }
        if (orderItemIndex !== -1) {
            if (field === 'barCode') {
                this.handleBarCode(value as string, orderIndex, orderItemIndex);
            } else if (field === 'actualPickQuantity') {
                this.handleActualPickQuantity(value as number, orderIndex, orderItemIndex);
            } else if (field === 'isPicking') {
                this.handleIsPickingStatus(Boolean(value), orderIndex, orderItemIndex);
            }
        }
    };

    /**
     * 处理条形码
     * @param barCode 条形码
     * @param orderIndex 所属订单在订单列表中的下标
     * @param orderItemIndex 所属订单项在订单项列表中的下标
     */
    handleBarCode = (barCode: string, orderIndex: number, orderItemIndex: number) => {
        const { pickingListOrders } = this.state;
        const orders = cloneDeep(pickingListOrders);
        const item = orders[orderIndex].orderItems[orderItemIndex];
        item.barCode = barCode;
        if (barCode && barCode.length === 13) {
            const actualPickQuantity = parseInt(barCode.slice(-6, -1));
            item.actualPickQuantity = actualPickQuantity;
            if (actualPickQuantity < item.needPickQuantity) {
                item.lackQuantity = item.needPickQuantity - actualPickQuantity;
                const priceDifference =
                    ((item.needPickQuantity - actualPickQuantity) / item.needPickQuantity) *
                    Number(item.afterFoldingPrice);
                item.priceDifference = Number(toTwo(priceDifference.toString()));
            } else {
                item.lackQuantity = 0;
                item.priceDifference = 0;
            }
            orders[orderIndex].orderItems[orderItemIndex] = item;
        }
        this.setState({
            pickingListOrders: orders,
        });
    };

    /**
     * 处理实际重量
     * @param actualPickQuantity 实际重量
     * @param orderIndex 所属订单在订单列表中的下标
     * @param orderItemIndex 所属订单项在订单项列表中的下标
     */
    handleActualPickQuantity = async (
        actualPickQuantity: number,
        orderIndex: number,
        orderItemIndex: number
    ) => {
        const { pickingListOrders } = this.state;
        const orders = cloneDeep(pickingListOrders);
        const order = orders[orderIndex];
        const item = order.orderItems[orderItemIndex];
        orders[orderIndex].orderItems[orderItemIndex] = await this.handleQuantityAndPriceDifference(
            item,
            order,
            actualPickQuantity
        );
        this.setState({
            pickingListOrders: orders,
        });
    };

    handleQuantityAndPriceDifference = async (
        item: PickingListsOrderItems,
        order: PickingListsOrders,
        actualPickQuantity: number
    ) => {
        item.actualPickQuantity = actualPickQuantity;
        if (actualPickQuantity < item.needPickQuantity) {
            item.lackQuantity = item.needPickQuantity - actualPickQuantity;
            const { params } = this.props;

            const priceDifference = await GetPickingPriceDifference({
                id: params.id,
                orderId: order.orderId,
                orderItemId: item.orderItemId,
                actualPickQuantity,
            });
            // ((item.needPickQuantity - actualPickQuantity) / item.needPickQuantity) *
            // Number(item.afterFoldingPrice);
            item.priceDifference = Number(toTwo(priceDifference.toString()));
        } else {
            item.lackQuantity = 0;
            item.priceDifference = 0;
        }
        return item;
    };

    onScanModalConfirm = (pickingListOrders: PickingListsOrders[]) => {
        this.setState({ pickingListOrders });
    };

    handleIsPickingStatus = (isPicking: boolean, orderIndex: number, orderItemIndex: number) => {
        const { pickingListOrders } = this.state;
        const orders = cloneDeep(pickingListOrders);
        orders[orderIndex].orderItems[orderItemIndex].isPicking = isPicking;
        this.setState(
            {
                pickingListOrders: orders,
            },
            () => {
                this.handleActualPickQuantity(0, orderIndex, orderItemIndex);
            }
        );
    };

    /**
     * 开始拣货并打印拣货单
     * @param order 订单
     */
    changeStatusAndPrintPickingList = (order: PickingLists) => {
        const _this = this;
        //打印商家联
        function unAssemblePrint() {
            GetPrintOrders([order.id]).then((data) => {
                _this.setState({ orderPrintData: data }, () => {
                    const ref: any = _this.PickOrderRef;
                    ref && ref.onPrint();
                    // AntModal.info({
                    //     content: <PickintReceiptTemplate order={data[0]} />,
                    //     width: 1400,
                    // });
                });
            });
        }

        unAssemblePrint();
        this.PickingOrder([Number(order.id)]).then(() => {
            this.refresh();
        });
    };

    /**
     * 开始拣货
     * @param ids 订单id
     * @returns
     */
    PickingOrder(ids: number[]) {
        return api.put(
            { ids },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
                apiPath: `/admin/picking_lists/batch_picking`,
            }
        );
    }

    /**
     * 拣货完成并打印小票
     * @param notFinishedPicking 是否有未拣货的订单商品
     * @returns
     */
    submitStockOutQuantityAndChangeStatus = (notFinishedPicking: boolean) => {
        if (notFinishedPicking) return;
        const { pickingListOrders, pickingLists } = this.state;
        const orders = pickingListOrders.map((o) => {
            return {
                id: o.id,
                orderId: o.orderId,
                orderItems: o.orderItems.map((i) => {
                    return {
                        actualPickQuantity: i.actualPickQuantity,
                        barCode: i.barCode,
                        goodsId: i.goods.goodsId,
                        id: i.id,
                        orderItemId: i.orderItemId,
                    };
                }),
            };
        });
        FinishPicking(pickingLists.id, orders as FinishPickOrders[])
            .then(() => {
                AntMessage.success(services.language.getText('jhwccg'));
                this.printReceipt();
                this.refresh();
            })
            .catch(() => {
                AntMessage.warning(services.language.getText('jhwcsb'));
            });
    };

    //打印小票
    printReceipt() {
        const { pickingLists } = this.state;
        const ids = pickingLists.orders.map((order) => order.orderId);
        GetReceiptInfo(ids).then((res) => {
            //调试验证打印内容
            // AntModal.info({ content: <ReceiptTemplate data={data} />, width: 800 });
            this.setState({ receiptData: res }, () => {
                const ref: any = this.PickReceiptRef;
                ref && ref.onPrint();
            });
        });
    }

    /**
     * 处理接口返回信息
     * @param res 详情接口返回值
     */
    handleData(res: PickingLists) {
        this.handleCountdown(res);
        const newOrders = res.orders.map((order) => {
            order.orderItems.map((item) => {
                item.isPicking = !(
                    res.status === ORDER_STATUS_ENUM.WAITING_PICK ||
                    res.status === ORDER_STATUS_ENUM.PICKING
                );
                return item;
            });
            return order;
        });
        this.setState({
            pickingLists: res,
            pickingListOrders: newOrders,
        });
    }

    /**
     * 处理拣货单倒计时
     * @param order 拣货单详情
     */
    handleCountdown = (order: PickingLists) => {
        const {
            countdown: { pickTime, totalPickTime: total, countdownType },
            status,
        } = order;
        let time = countdownType === 'TIMEOUT' ? -Number(pickTime) : Number(pickTime);
        const totalPickTime = Number(total);
        let color = 'green',
            title = '',
            countdownStatus = 'disabled';
        if (status === ORDER_STATUS_ENUM.PICKING) {
            if (time <= 300) color = 'red';
            title =
                time > 0 ? services.language.getText('jhsy') : services.language.getText('jhcs');
            countdownStatus = time > 0 ? 'countdown' : 'timeout';
        } else if (status === ORDER_STATUS_ENUM.FINISH_PICK) {
            if (time >= 0) title = services.language.getText('wc');
            if (time < 0) {
                title = services.language.getText('cswc');
                color = 'red';
            }
        } else if (status === ORDER_STATUS_ENUM.CANCELLED) {
            time = 0;
        }
        this.setState({
            pickTime: time,
            totalPickTime,
            color,
            title,
            countdownStatus,
        });
    };

    getLackOrders = () => {
        const { pickingListOrders } = this.state;
        const lackOrders: {
            notFinishedPicking: boolean;
            notFinishedGoods: string[];
            hasLackOrders: boolean;
            lackOrders: string[];
        } = {
            notFinishedPicking: false,
            notFinishedGoods: [],
            hasLackOrders: false,
            lackOrders: [],
        };
        forEach(pickingListOrders, (order) => {
            forEach(order.orderItems, (item) => {
                if (item.goods.productType === ProductType.WEIGHT) {
                    if (
                        item.actualPickQuantity === undefined ||
                        typeof item.actualPickQuantity === 'string'
                    ) {
                        lackOrders.notFinishedPicking = true;
                        lackOrders.notFinishedGoods.push(item.goods.name);
                    }
                    if (item.lackQuantity && item.lackQuantity > 0) {
                        lackOrders.hasLackOrders = true;
                        lackOrders.lackOrders.push(item.goods.name);
                    }
                } else {
                    if (item.isPicking) {
                        if (item.lackQuantity && item.lackQuantity > 0) {
                            lackOrders.hasLackOrders = true;
                            lackOrders.lackOrders.push(item.goods.name);
                        }
                    } else {
                        lackOrders.notFinishedPicking = true;
                        lackOrders.notFinishedGoods.push(item.goods.name);
                    }
                }
            });
        });
        return lackOrders;
    };

    // 渲染关联订单
    renderRelatedOrder() {
        const {
            pickingLists: { orders },
        } = this.state;
        const hasPrivilege = privilege.check('picking.assistant.view_order_detail', 'full');
        return map(orders, (order) => {
            const { orderId, id } = order;
            return (
                <div className="related-order" key={id}>
                    <a
                        href={
                            hasPrivilege ? `#/order-picking-assistant/info/${orderId}` : undefined
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {id}
                    </a>
                </div>
            );
        });
    }
    render() {
        const { pickingLists, pickingListOrders } = this.state;
        const lackOrders = this.getLackOrders();
        return !!pickingLists ? (
            <div className="picking-list-info-page">
                <div className="picking-list-info">
                    <div className="picking-list-countdown">
                        <Countdown
                            onTimeChange={this.onTimeChange}
                            time={this.state.pickTime}
                            title={this.state.title}
                            status={this.state.countdownStatus}
                            color={this.state.color}
                            total={this.state.totalPickTime}
                            needChange={true}
                        />
                    </div>
                    <div className="picking-list-detail-info">
                        <AntRow>
                            <AntCol span={8} className="picking-list-info-item">
                                <span className="picking-list-label">
                                    {services.language.getText('jhdh')}:
                                </span>
                                <span>{pickingLists.pickingListNo}</span>
                            </AntCol>
                            <AntCol span={8} className="picking-list-info-item">
                                <span className="picking-list-label">
                                    {services.language.getText('belongSubsite')}:
                                </span>
                                <span>{pickingLists.subsite.name}</span>
                            </AntCol>
                            {!!pickingLists.subsite.merchant &&
                                pickingLists.subsite.merchant.id !== '0' && (
                                    <AntCol span={8} className="picking-list-info-item">
                                        <span className="picking-list-label">
                                            {services.language.getText('belongMerchant')}:
                                        </span>
                                        <span>{pickingLists.subsite.merchant.name}</span>
                                    </AntCol>
                                )}
                        </AntRow>
                        <AntRow>
                            <AntCol span={8} className="picking-list-info-item">
                                <span className="picking-list-label">
                                    {services.language.getText('jhdcjsj')}:
                                </span>
                                <span>{pickingLists.createTime}</span>
                            </AntCol>
                            <AntCol span={8} className="picking-list-info-item">
                                <span className="picking-list-label">
                                    {services.language.getText('jhy')}:
                                </span>
                                <span>
                                    {(pickingLists.user && pickingLists.user.name) ||
                                        services.language.getText('wfp')}
                                </span>
                            </AntCol>
                        </AntRow>
                    </div>
                    <div className="picking-list-button">
                        <div className="picking-list-status">
                            <span
                                className={`picking-list-status-text${
                                    pickingLists.status === ORDER_STATUS_ENUM.CANCELLED
                                        ? '-red'
                                        : ''
                                }`}
                            >
                                {ORDER_STATUS[pickingLists.status]}
                            </span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <ScanGoods
                                handleQuantityAndPriceDifference={
                                    this.handleQuantityAndPriceDifference
                                }
                                pickingListOrders={pickingListOrders}
                                onSubmit={this.onScanModalConfirm}
                                status={pickingLists.status}
                            />
                            {pickingLists.status === 'PICKING' ? (
                                <MessageModal
                                    iconType="success"
                                    mode={lackOrders.notFinishedPicking ? 'info' : 'confirm'}
                                    maskClosable={true}
                                    okText={
                                        lackOrders.notFinishedPicking
                                            ? services.language.getText('common.close')
                                            : services.language.getText('common.ok')
                                    }
                                    cancelText={language.getText('common.cancel')}
                                    triggerComponent={{
                                        component: 'Button',
                                        text: services.language.getText('jhwcbdyxp'),
                                        type: 'primary',
                                        style: {
                                            marginRight: 20,
                                        },
                                    }}
                                    title={
                                        lackOrders.notFinishedPicking
                                            ? services.language.getText('jhdhyspwwcjh')
                                            : services.interpolate(
                                                  services.language.getText('sfqrjhd'),
                                                  { pickingListNo: pickingLists.pickingListNo }
                                              )
                                    }
                                    content={
                                        lackOrders.notFinishedPicking ? (
                                            <div>
                                                <div>{services.language.getText('wjsp')}：</div>
                                                {lackOrders.notFinishedGoods.map((goods, index) => {
                                                    return <div key={index + ''}>{goods}</div>;
                                                })}
                                            </div>
                                        ) : (
                                            <div>
                                                <div>
                                                    {services.language.getText('qhsp')}：
                                                    {lackOrders.hasLackOrders
                                                        ? ''
                                                        : services.language.getText(
                                                              'indoorMapProviderNone'
                                                          )}
                                                </div>
                                                {lackOrders.lackOrders.map((order, index) => {
                                                    return <div key={index + ''}>{order}</div>;
                                                })}
                                            </div>
                                        )
                                    }
                                    onConfirm={() => {
                                        if (!lackOrders.notFinishedPicking) {
                                            this.submitStockOutQuantityAndChangeStatus(
                                                lackOrders.notFinishedPicking
                                            );
                                        }
                                    }}
                                    onCancel={() => {}}
                                />
                            ) : null}
                            {pickingLists.status === 'WAITING_PICK' ? (
                                <MessageModal
                                    mode="confirm"
                                    maskClosable={true}
                                    okText={language.getText('common.ok')}
                                    cancelText={language.getText('common.cancel')}
                                    triggerComponent={{
                                        component: 'Button',
                                        text: language.getText('ksjhbdyjhd'),
                                        type: 'primary',
                                        style: {
                                            marginRight: 20,
                                        },
                                    }}
                                    title={`${language.getText('sfksjh')}${
                                        pickingLists.pickingListNo
                                    }`}
                                    onConfirm={() => {
                                        this.changeStatusAndPrintPickingList(pickingLists);
                                    }}
                                    onCancel={() => {}}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <Divider />
                {pickingListOrders &&
                    pickingListOrders.map((order, index) => {
                        return (
                            <GoodsList
                                status={pickingLists.status}
                                pickingListOrder={order}
                                onfieldChange={this.onfieldChange}
                            />
                        );
                    })}

                <PickOrdersTemplate
                    setWrappedInstance={this.setPickOrderRef}
                    data={this.state.orderPrintData}
                />
                <PickReceiptTemplate
                    setWrappedInstance={this.setPickReceiptRef}
                    data={this.state.receiptData}
                />
            </div>
        ) : (
            <div className="picking-list-info-loading">
                <Spin />
            </div>
        );
    }
}
