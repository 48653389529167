import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';

const api = services.api;

/**
 * 导出数据
 */
export class ExportDataButton extends PureComponent<any, any> {
    onClick = () => {
        const { params } = this.props;
        let { apiRoot, apiPath } = params;
        // apiPath = apiPath.replace('{{row.id}}', row.id);
        // apiPath = apiPath.replace('{{row.activityId}}', row.activityId);
        // apiPath = apiPath.replace('{{row.couponId}}', row.couponId);
        services.interpolate(apiPath, this.props);
        api.download(
            {},
            {
                apiRoot: apiRoot,
                apiPath: services.interpolate(apiPath, this.props),
                customHeaders: { 'content-disposition': 'attachment;filename="filename.xls"' },
            }
        ).catch(errorHandle);
    };
    render() {
        const { row, params } = this.props;

        if (row.canExport === undefined) {
            row.canExport = true;
        }

        return row.canExport ? (
            <AntButton
                type="link"
                style={{ padding: 0 }}
                className="span-link"
                onClick={this.onClick}
            >
                {params.text ? params.text : services.language.getText('dcbb')}
            </AntButton>
        ) : (
            ''
        );
    }
}
