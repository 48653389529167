import React, { Component } from 'react';
import { InputNumber, Checkbox, Input, message as AntMessage } from 'antd';
import { clone, map } from 'lodash';

import './index.less';
import { ActivityRewardItem } from '../activity-reward-item';
import { Coupon } from '../../containers/select-coupon';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';

type ActivityRewardInvitedValue = {
    /**
     * 是否给新注册用户发放奖励
     */
    registerRewardChecked?: boolean;
    /**
     * 积分
     */
    pointChecked?: boolean;
    point?: number;
    /**
     * HI币
     */
    goldChecked?: boolean;
    gold?: number;
    /**
     * 经验值
     */
    experienceChecked?: boolean;
    experience?: number;
    /**
     * 优惠券
     */
    couponsChecked?: boolean;
    couponsValue?: any;
    /**
     * 自定义
     */
    customChecked?: boolean;
    custom?: string;
    /**
     * 奖励背景图
     */
    inviterRewardPicture?: any;
};

/**
 * 后端接口返回的目前支持的选项
 */
enum RewardItems {
    register = 'REGESTER',
    point = 'POINT',
    gold = 'GOLD',
    experience = 'EXPERIENCE',
    coupons = 'COUPON',
    picture = 'PICTURE',
    custom = 'CUSTOM',
    pictureBg = 'PICTUREBG',
}

export interface ActivityRewardPropsInvited {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: ActivityRewardInvitedValue | null;

    /**
     * 候选项集合
     */
    options: Array<any>;

    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: ActivityRewardInvitedValue | null, name: string) => void;
}
export class ActivityRewardInvited extends Component<ActivityRewardPropsInvited> {
    static defaultProps = {
        disabled: false,
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: ActivityRewardInvitedValue = { couponsValue: [] };
            value = item;
            if (onChange) {
                onChange(value, name);
            }
        } else {
        }
    }

    onPicChange = (picValue: any) => {
        let { name, value, onChange } = this.props;
        let item: ActivityRewardInvitedValue;
        if (value !== null) {
            item = clone(value);
        } else {
            item = { couponsValue: [] };
        }
        item.inviterRewardPicture = picValue;
        value = item;
        if (onChange) {
            onChange(value, name);
        }
    };
    onInputValueChange(event: any, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        let item: ActivityRewardInvitedValue;
        var reg = /^\+?[0-9]\d*$/;
        if (
            type !== RewardItems.custom &&
            inputValue !== null &&
            inputValue !== '' &&
            !reg.test(inputValue)
        ) {
            AntMessage.warning(services.language.getText('inputNumber'));
            inputValue = null;
        }
        if (value !== null) {
            item = clone(value);
        } else {
            item = { couponsValue: [] };
        }
        if (type === RewardItems.point) {
            item.point = inputValue;
        }
        if (type === RewardItems.gold) {
            item.gold = inputValue;
        }
        if (type === RewardItems.experience) {
            item.experience = inputValue;
        }
        if (type === RewardItems.custom) {
            inputValue = event.target.value;
            item.custom = inputValue;
        }
        value = item;
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, type: string) {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        let item: ActivityRewardInvitedValue;
        if (value !== null) {
            item = clone(value);
        } else {
            item = { couponsValue: [] };
        }
        if (type === RewardItems.point) {
            item.pointChecked = checkboxValue;
            if (!checkboxValue) {
                item.point = undefined;
            }
        }
        if (type === RewardItems.gold) {
            item.goldChecked = checkboxValue;
            if (!checkboxValue) {
                item.gold = undefined;
            }
        }
        if (type === RewardItems.experience) {
            item.experienceChecked = checkboxValue;
            if (!checkboxValue) {
                item.experience = undefined;
            }
        }
        if (type === RewardItems.coupons) {
            item.couponsChecked = checkboxValue;
        }
        if (type === RewardItems.custom) {
            item.customChecked = checkboxValue;
            if (!checkboxValue) {
                item.custom = undefined;
            }
        }
        if (type === RewardItems.register) {
            item.registerRewardChecked = checkboxValue;
        }
        value = item;
        if (onChange) {
            onChange(value, name);
        }
    }

    onChangeCoupon = (products: Coupon[]) => {
        let { name, value, onChange } = this.props;
        let item: ActivityRewardInvitedValue;
        if (value !== null) {
            item = clone(value);
        } else {
            item = { couponsValue: [] };
        }
        item.couponsValue = products;
        value = item;
        if (onChange) {
            onChange(value, name);
        }
    };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };

    renderReward(reward: ActivityRewardInvitedValue, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const { options } = this.props;
        let _imageBgControlInfo = Object.assign(
            {},
            {
                name: 'inviterRewardPicture',
                fileName: 'fileName',
                value: reward.inviterRewardPicture,
                accept: 'image/*',
                uploadType: 'single',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                multiple: false,
                maxCount: 1,
                limitTips: {
                    limitText: services.language.getText('bscmrsyxtzdddct'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                },
            }
        );
        let point: any;
        let gold: any;
        let experience: any;
        let coupons: any;
        let custom: any;
        let pictureBg: any;
        map(options, (option, index) => {
            if (option.code === RewardItems.point) {
                point = option;
            } else if (option.code === RewardItems.gold) {
                gold = option;
            } else if (option.code === RewardItems.experience) {
                experience = option;
            } else if (option.code === RewardItems.coupons) {
                coupons = option;
            } else if (option.code === RewardItems.custom) {
                custom = option;
            } else if (option.code === RewardItems.pictureBg) {
                pictureBg = option;
            }
        });
        return (
            <div className="activity-reward-invited">
                <div className="reward-content-list">
                    <div className="reward-content-item">
                        <Checkbox
                            onChange={(e) => this.onCheckboxChange(e, RewardItems.register)}
                            checked={reward.registerRewardChecked}
                            value="register"
                        >
                            {services.language.getText('jxyhffjl')}
                        </Checkbox>
                    </div>
                    {reward.registerRewardChecked && point && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, RewardItems.point)}
                                checked={reward.pointChecked}
                                value="point"
                            >
                                {point.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.point}
                                onChange={(e) => this.onInputValueChange(e, RewardItems.point)}
                                disabled={!reward.pointChecked}
                            />
                            <span className="reward-item-text">{point.name}</span>
                        </div>
                    )}
                    {reward.registerRewardChecked && gold && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, RewardItems.gold)}
                                checked={reward.goldChecked}
                                value="point"
                            >
                                {gold.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.gold}
                                onChange={(e) => this.onInputValueChange(e, RewardItems.gold)}
                                disabled={!reward.goldChecked}
                            />
                            <span className="reward-item-text">{gold.name}</span>
                        </div>
                    )}
                    {reward.registerRewardChecked && experience && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, RewardItems.experience)}
                                checked={reward.experienceChecked}
                                value="experience"
                            >
                                {experience.name}
                            </Checkbox>
                            <span className="reward-item-text">
                                {services.language.getText('song')}
                            </span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.experience}
                                onChange={(e) => this.onInputValueChange(e, RewardItems.experience)}
                                disabled={!reward.experienceChecked}
                            />
                            <span className="reward-item-text">
                                {services.language.getText('value')}
                            </span>
                        </div>
                    )}
                </div>
                {reward.registerRewardChecked && coupons && (
                    <div className="reward-content-list">
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, RewardItems.coupons)}
                                checked={reward.couponsChecked}
                                value="coupons"
                            >
                                {coupons.name}
                            </Checkbox>
                        </div>
                        <div className="reward-coupons">
                            <ActivityRewardItem
                                index={0}
                                value={reward.couponsValue}
                                subsiteIds={subsiteIds}
                                onChangeCoupon={this.onChangeCoupon}
                                type="checkbox"
                                packageSelectType="radio"
                                canChangeQuantity
                            />
                        </div>
                    </div>
                )}
                {reward.registerRewardChecked && custom && (
                    <div className="reward-content-list">
                        <div className="reward-content-custom-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, RewardItems.custom)}
                                checked={reward.customChecked}
                                value="custom"
                            >
                                {custom.name}
                            </Checkbox>
                            <Input
                                className="reward-content-item-text"
                                placeholder={services.language.getText('trdwzjzszyqylhdyz')}
                                type="text"
                                maxLength={20}
                                value={reward.custom}
                                onChange={(e) => this.onInputValueChange(e, RewardItems.custom)}
                                disabled={!reward.customChecked}
                            />
                        </div>
                    </div>
                )}

                {reward.registerRewardChecked && pictureBg && (
                    <div className="reward-pic">
                        <span className="reward-picbg-text">
                            {services.language.getText('byqr') + pictureBg.name}{' '}
                        </span>
                        <ImageUpload
                            {..._imageBgControlInfo}
                            onChange={(value: any) => {
                                this.onPicChange(value);
                                return {};
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
    render() {
        let { value, disabled } = this.props;
        return (
            <div className="activity-reward">
                {this.renderReward(value || { couponsValue: [] }, disabled)}
            </div>
        );
    }
}
