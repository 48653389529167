import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const FilterWordLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/filter_word';
        const { id } = data;
        if (!id) {
            if (data.filterWordPhonetic === 'ALL') {
                delete data.filterWordPhonetic;
            } else if (data.filterWordPhonetic === 'Other') {
                data.filterWordPhonetic = '-1';
            }
        }

        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
};
