import React, { PureComponent, Fragment } from 'react';
import { Input, Form, Button as AntButton, Spin, message } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { api, localStorage } from '@comall-backend-builder/core/lib/services';
import { SessionsLoader } from '../../../loaders/sessions-loader';
import { CheckPasswordComponent } from '../../../components/check-password-component';
import { checkPassword } from '../../../services/utils';
import { errorHandle } from '../../../services/error-handle';
import '../index.less';
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 10,
            offset: 6,
        },
    },
};

interface PasswordProps {
    click: (type: string) => void;
    form: FormComponentProps['form'];
}

class password extends PureComponent<PasswordProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            confirmDirty: false,
            spinning: false,
            passwordDirty: false,
            passStatus: null,
            newPasswordHelp: '',
        };
    }
    // 新密码与确认密码校验
    compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('两次密码输入不一致!');
        } else {
            callback();
        }
    };
    validateToNextPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && value === form.getFieldValue('currentPassword')) {
            callback('新密码不能与之前的密码相同!');
        }
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };
    validateToNewPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && this.state.passwordDirty) {
            form.validateFields(['newPassword'], { force: true });
        }
        callback();
    };
    handleBlur = (e: any, type: string) => {
        const { value } = e.target;
        if (type === 'passwordDirty') {
            api.get(
                {
                    password: value,
                },
                {
                    apiPath: `/open_api/password_strength/verify`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                }
            ).then((res: any) => {
                this.setState({
                    newPasswordHelp: res.ok ? '' : res.msg,
                });
            });
        }
        this.setState({ [type]: this.state[type] || !!value });
    };
    handlePasswordChange = (e: any) => {
        const { value } = e.target;
        if (!value) {
            this.setState({ passStatus: null });
        } else {
            const passStatus = checkPassword(value);
            this.setState({ passStatus });
        }
    };
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                api.get(
                    {
                        password: values.newPassword,
                    },
                    {
                        apiPath: `/open_api/password_strength/verify`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    }
                ).then((res: any) => {
                    if (res.ok) {
                        this.setState({ spinning: true });
                        api.put(
                            { ...values },
                            {
                                apiPath: `/users/mine/password`,
                                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                            }
                        )
                            .then(() => {
                                message.success('密码修改成功', () => this.goBack());
                                SessionsLoader.get().then((res) => {
                                    localStorage.set('USER_INFO', res);
                                });
                            })
                            .catch(errorHandle)
                            .finally(() => {
                                this.setState({ spinning: false });
                            });
                    } else {
                        message.warning(res.msg);
                    }
                });
            }
        });
    };
    goBack = () => {
        this.props.click('default');
    };
    render() {
        const {
            form: { getFieldDecorator },
        } = this.props;
        const { spinning, passStatus, newPasswordHelp } = this.state;
        return (
            <Fragment>
                <div className="title">修改密码</div>
                <Spin spinning={spinning}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item label="当前密码">
                            {getFieldDecorator('currentPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入当前密码!',
                                    },
                                    {
                                        validator: this.validateToNewPassword,
                                    },
                                ],
                            })(<Input.Password placeholder="请输入当前密码" maxLength={40} />)}
                        </Form.Item>
                        <Form.Item
                            label="新密码"
                            validateStatus={newPasswordHelp ? 'error' : undefined}
                            help={newPasswordHelp}
                            extra="密码长度8~40位，数字、字母、字符至少包含两种"
                        >
                            {getFieldDecorator('newPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入新密码!',
                                    },
                                    {
                                        validator: this.validateToNextPassword,
                                    },
                                ],
                            })(
                                <div style={{ position: 'relative' }}>
                                    <Input.Password
                                        maxLength={40}
                                        placeholder="请输入新密码"
                                        onChange={this.handlePasswordChange}
                                        onBlur={(e) => this.handleBlur(e, 'passwordDirty')}
                                    />
                                    {passStatus && <CheckPasswordComponent type={passStatus} />}
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item label="确认新密码">
                            {getFieldDecorator('confirmPassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请确认新密码!',
                                    },
                                    {
                                        validator: this.compareToFirstPassword,
                                    },
                                ],
                            })(
                                <Input.Password
                                    maxLength={40}
                                    placeholder="请再次输入新密码"
                                    onBlur={(e) => this.handleBlur(e, 'confirmDirty')}
                                />
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <AntButton type="primary" htmlType="submit">
                                保存修改
                            </AntButton>
                            <AntButton style={{ marginLeft: 10 }} onClick={this.goBack}>
                                取消
                            </AntButton>
                        </Form.Item>
                    </Form>
                </Spin>
            </Fragment>
        );
    }
}

export default Form.create<PasswordProps>()(password);
