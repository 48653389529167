import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const RedPackageRainLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/red_package_rain_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/red_package_rain_activities/${id}`;
        } else {
            data.eventType = 'RED_PACKAGE_RAIN';
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.pageShowId = res.pageShow.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    description: res.activityVo.description,
                    subsiteIds: res.activityVo.normalConditions[0].includeIds,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.ruleInfo = {
                    activityPeriodRewardLimit:
                        res.activityVo.exchangeConditions &&
                        res.activityVo.exchangeConditions.length
                            ? res.activityVo.exchangeConditions[0].exchangeLimit
                            : null,
                };
                let picturenPageShow = res.pageShow;
                if (res.lottery && res.lottery.pageConfig) {
                    const pageConfigJson = JSON.parse(res.lottery.pageConfig);
                    picturenPageShow = pageConfigJson;
                }
                res.pictureInfo = {
                    backgroundPicture: [
                        {
                            id: picturenPageShow.backgroundPicture.id || '',
                            path: picturenPageShow.backgroundPicture.url || '',
                        },
                    ],
                    description: res.activityVo.description,
                };
                const assetRewards = [];
                if (
                    res.activityVo.rewardConfig.activityRewards &&
                    res.activityVo.rewardConfig.activityRewards.length > 0
                ) {
                    const activityRewardList = res.activityVo.rewardConfig.activityRewards;
                    // 是否是复制
                    const isCopy = window.location.href.includes('/copy');
                    for (let i = 0; i < activityRewardList.length; i++) {
                        const activityReward = activityRewardList[i];
                        for (let j = 0; j < activityReward.couponRewards.length; j++) {
                            const coupon = activityReward.couponRewards[j];
                            const couponReward: any = coupon;
                            if (isCopy) {
                                couponReward.issuedNum = 0;
                                couponReward.issuedCount = 0;
                                couponReward.inventory = couponReward.quantity || 0;
                                delete couponReward.id;
                            }
                            couponReward.conditionValue = activityReward.conditionValue;
                            couponReward.quantityType = coupon.quantity ? 'YES' : 'NO';
                            couponReward.picture = {
                                id: coupon.picture.id || '',
                                path: coupon.picture.url || '',
                            };
                            couponReward.type = {
                                checked: 'COUPON',
                                assetRewards: [
                                    {
                                        rewardType: 'COUPON',
                                        rewardValue: [
                                            {
                                                ...coupon,
                                                name: couponReward.couponName,
                                                id: coupon.couponDefinitionId,
                                                quantity: coupon.count,
                                            },
                                        ],
                                    },
                                ],
                            };
                            assetRewards.push(couponReward);
                        }
                        for (let j = 0; j < activityReward.assetRewards.length; j++) {
                            const reward = activityReward.assetRewards[j];
                            let assetReward: any = reward;
                            if (isCopy) {
                                assetReward.inventory = assetReward.quantity || 0;
                                assetReward.issuedCount = 0;
                                assetReward.issuedNum = 0;
                                delete assetReward.id;
                            }
                            assetReward.conditionValue = activityReward.conditionValue;
                            assetReward.quantityType = reward.quantity ? 'YES' : 'NO';
                            assetReward.picture = {
                                id: reward.picture.id || '',
                                path: reward.picture.url || '',
                            };
                            assetReward.type = {
                                checked: reward.rewardType,
                                assetRewards: [
                                    {
                                        rewardType: reward.rewardType,
                                        rewardValue: reward.rewardValue,
                                    },
                                ],
                            };
                            assetRewards.push(assetReward);
                        }
                    }
                    assetRewards.sort(function(a, b) {
                        if (a.sequence < b.sequence) {
                            return -1;
                        } else if (a.sequence === b.sequence) {
                            return 0;
                        } else {
                            return 1;
                        }
                    });
                }
                res.prizeInfo = {
                    assetRewards: assetRewards,
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.canExport =
                                item.executeStatus === 'STARTED' ||
                                item.executeStatus === 'COMPLETE';
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/red_package_rain_activities';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                eventType: 'RED_PACKAGE_RAIN',
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                exchangeConditions: [
                    {
                        consumeValue: 0,
                        exchangeLimit: params.ruleInfo.activityPeriodRewardLimit,
                        exchangeMode: 'GIVE',
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'DAY',
                        scopeId: -1,
                    },
                ],
            };
        }
        if (params.ruleInfo) {
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
            };
        }
        if (params.pictureInfo) {
            const pageConfig: any = {
                backgroundPicture: {
                    id: params.pictureInfo.backgroundPicture[0].id,
                    url: params.pictureInfo.backgroundPicture[0].path,
                },
            };
            newParams.lottery = {
                pageConfig: JSON.stringify(pageConfig),
            };
            newParams.pageShow = {
                backgroundPicture: {
                    id: params.pictureInfo.backgroundPicture[0].id,
                    url: params.pictureInfo.backgroundPicture[0].path,
                },
                showRewardRecord: false,
            };
            newParams.activityVo.description = params.pictureInfo.description;
        }
        if (
            params.prizeInfo &&
            params.prizeInfo.assetRewards &&
            params.prizeInfo.assetRewards.length > 0
        ) {
            const assetRewardsInfo = [];
            for (let i = 0; i < params.prizeInfo.assetRewards.length; i++) {
                const assetReward = params.prizeInfo.assetRewards[i];
                var checked = assetReward.type.checked; //奖品项最后选中的类型
                var assetRewardsList = assetReward.type.assetRewards;
                if (checked === 'COUPON') {
                    for (let j = 0; j < assetRewardsList.length; j++) {
                        const assetRewardsItem = assetRewardsList[j];
                        if (assetRewardsItem.rewardType === checked) {
                            //奖品项最终的输入
                            let couponDefinitionId;
                            let count;
                            if (
                                assetRewardsItem.rewardValue &&
                                assetRewardsItem.rewardValue.length > 0
                            ) {
                                couponDefinitionId = assetRewardsItem.rewardValue[0].id;
                                count = assetRewardsItem.rewardValue[0].quantity;
                            }
                            let raward = {
                                conditionValueMax: 0,
                                conditionValue: assetReward.conditionValue || null,
                                couponRewards: [
                                    {
                                        count: count ? count : 1,
                                        couponDefinitionId: couponDefinitionId,
                                        name: assetReward.name,
                                        picture: assetReward.picture,
                                        quantity: assetReward.quantity,
                                        sequence: assetReward.sequence,
                                        tip: assetReward.tip,
                                    },
                                ],
                            };
                            assetRewardsInfo.push(raward);
                        }
                    }
                } else {
                    if (checked === 'NONE') {
                        let raward = {
                            conditionValueMax: 0,
                            conditionValue: assetReward.conditionValue || null,
                            assetRewards: [
                                {
                                    rewardType: checked,
                                    name: assetReward.name,
                                    picture: assetReward.picture,
                                    quantity: assetReward.quantity,
                                    sequence: assetReward.sequence,
                                    tip: assetReward.tip,
                                },
                            ],
                        };
                        assetRewardsInfo.push(raward);
                    } else {
                        if (assetRewardsList && assetRewardsList.length > 0) {
                            for (let j = 0; j < assetRewardsList.length; j++) {
                                const assetRewardsItem = assetRewardsList[j];
                                let raward = {
                                    conditionValueMax: 0,
                                    conditionValue: assetReward.conditionValue || null,
                                    assetRewards: [
                                        {
                                            rewardType: checked,
                                            name: assetReward.name,
                                            picture: assetReward.picture,
                                            quantity: assetReward.quantity,
                                            rewardValue: assetRewardsItem.rewardValue,
                                            sequence: assetReward.sequence,
                                            tip: assetReward.tip,
                                        },
                                    ],
                                };
                                assetRewardsInfo.push(raward);
                            }
                        }
                    }
                }
            }
            newParams.activityVo.rewardConfig.activityRewards = assetRewardsInfo;
        }
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/red_package_rain_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                id: params.id,
                eventType: 'RED_PACKAGE_RAIN',
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                exchangeConditions: [
                    {
                        consumeValue: 0,
                        exchangeLimit: params.ruleInfo.activityPeriodRewardLimit,
                        exchangeMode: 'GIVE',
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'DAY',
                        scopeId: -1,
                    },
                ],
            };
        }
        if (params.ruleInfo) {
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
            };
        }
        if (params.pictureInfo) {
            const pageConfig: any = {
                backgroundPicture: {
                    id: params.pictureInfo.backgroundPicture[0].id,
                    url: params.pictureInfo.backgroundPicture[0].path,
                },
            };
            newParams.lottery = {
                pageConfig: JSON.stringify(pageConfig),
            };
            newParams.pageShow = {
                id: params.pageShowId,
                backgroundPicture: {
                    id: params.pictureInfo.backgroundPicture[0].id,
                    url: params.pictureInfo.backgroundPicture[0].path,
                },
                showRewardRecord: false,
            };
            newParams.activityVo.description = params.pictureInfo.description;
        }
        if (
            params.prizeInfo &&
            params.prizeInfo.assetRewards &&
            params.prizeInfo.assetRewards.length > 0
        ) {
            const assetRewardsInfo = [];
            for (let i = 0; i < params.prizeInfo.assetRewards.length; i++) {
                const assetReward = params.prizeInfo.assetRewards[i];
                var checked = assetReward.type.checked; //奖品项最后选中的类型
                var assetRewardsList = assetReward.type.assetRewards;
                if (checked === 'COUPON') {
                    for (let j = 0; j < assetRewardsList.length; j++) {
                        const assetRewardsItem = assetRewardsList[j];
                        if (assetRewardsItem.rewardType === checked) {
                            //奖品项最终的输入
                            let couponDefinitionId;
                            let count;
                            if (
                                assetRewardsItem.rewardValue &&
                                assetRewardsItem.rewardValue.length > 0
                            ) {
                                couponDefinitionId = assetRewardsItem.rewardValue[0].id;
                                count = assetRewardsItem.rewardValue[0].quantity;
                            }
                            let raward = {
                                conditionValueMax: 0,
                                conditionValue: assetReward.conditionValue || null,
                                couponRewards: [
                                    {
                                        count: count ? count : 1,
                                        couponDefinitionId: couponDefinitionId,
                                        name: assetReward.name,
                                        picture: assetReward.picture,
                                        quantity: assetReward.quantity,
                                        sequence: assetReward.sequence,
                                        tip: assetReward.tip,
                                        id: assetReward.id,
                                    },
                                ],
                            };
                            assetRewardsInfo.push(raward);
                        }
                    }
                } else {
                    if (checked === 'NONE') {
                        let raward = {
                            conditionValueMax: 0,
                            conditionValue: assetReward.conditionValue || null,
                            assetRewards: [
                                {
                                    rewardType: checked,
                                    name: assetReward.name,
                                    picture: assetReward.picture,
                                    quantity: assetReward.quantity,
                                    sequence: assetReward.sequence,
                                    tip: assetReward.tip,
                                    id: assetReward.id,
                                },
                            ],
                        };
                        assetRewardsInfo.push(raward);
                    } else {
                        if (assetRewardsList && assetRewardsList.length > 0) {
                            for (let j = 0; j < assetRewardsList.length; j++) {
                                const assetRewardsItem = assetRewardsList[j];
                                let raward = {
                                    conditionValueMax: 0,
                                    conditionValue: assetReward.conditionValue || null,
                                    assetRewards: [
                                        {
                                            rewardType: checked,
                                            name: assetReward.name,
                                            picture: assetReward.picture,
                                            quantity: assetReward.quantity,
                                            rewardValue: assetRewardsItem.rewardValue,
                                            sequence: assetReward.sequence,
                                            tip: assetReward.tip,
                                            id: assetReward.id,
                                        },
                                    ],
                                };
                                assetRewardsInfo.push(raward);
                            }
                        }
                    }
                }
            }
            newParams.activityVo.rewardConfig.activityRewards = assetRewardsInfo;
            newParams.marketingScheduleId =
                (params.baseInfo?.marketingSchedule?.length &&
                    params.baseInfo.marketingSchedule[0].id) ||
                null;
        }
        return await api.put(newParams, config);
    },
};
