import { services } from '@comall-backend-builder/core/';
import { forEach, isEmpty } from 'lodash';
import { subsites } from '../containers/page-design';

export const NewMerchantPageLoader = {
    get: async function(data: any, config: any) {
        config.apiPath = '/admin/pages/MERCHANT_PAGE';
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.result) {
                forEach(res.result, (i) => {
                    i.canDelete = !i.enabled;
                });
            }
            return res;
        });
    }
};
