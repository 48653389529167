import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, isUndefined, get, defaults, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { ReceiveLimitEnum } from '../../config/product/sales-electronic-coupon';
import { language } from '@comall-backend-builder/core/lib/services';

export interface SalesElectronicCouponCopyFormProps extends FormComponentProps {
    entity: Entity;
    fields: any;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    type: any;
    unmountComponent: () => void;
}
class SalesElectronicCouponCopyForm extends PureComponent<SalesElectronicCouponCopyFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, wrappedComponentRef, onSubmit } = this.props;
        let formProps = {
            entity,
            componentId: 'SalesElectronicCouponCopyFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 1000 },
            fields: [
                { property: 'baseInfo.salesChannels' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.coupon' },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.salesPriceAndCouponPackagePrices' },
                { property: 'baseInfo.marketPrice' },
                { property: 'baseInfo.stock' },
                { property: 'baseInfo.pictures' },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'settingInfo.afterSales',
                    controlConfig: {
                        seat: 'row.baseInfo.coupon',
                        isEditForm: true,
                    },
                },
                {
                    property: 'settingInfo.saleStatus',
                },
                {
                    property: 'settingInfo.saleTime',
                    visible: (values: any) => get(values, 'settingInfo.saleStatus') === 'NO',
                },
                { property: 'settingInfo.receiveLimit' },
                {
                    property: 'settingInfo.couponExpiredAutoShelfOff',
                },
                {
                    property: 'settingInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'settingInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'settingInfo.limitQuantity',
                },
                {
                    property: 'settingInfo.virtualCategoryIds',
                },
                {
                    property: 'settingInfo.limitStock',
                },
                {
                    property: 'settingInfo.initSalesVolume',
                },
                {
                    property: 'settingInfo.mobileDetails',
                },
            ],
            submit: { text: language.getText('common.save') },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const prices = get(entity, 'baseInfo.salesPriceAndCouponPackagePrices', {
                    salesPrice: undefined,
                    couponPackagePrices: [],
                });
                const coupon = get(entity, 'baseInfo.coupon[0]', {});
                if (!prices.salesPrice) {
                    AntMessage.warning(language.getText('inputPrice'));
                    return false;
                }
                if (coupon.type === 'PACKAGE' && prices.couponPackagePrices.length > 0) {
                    const isOk = prices.couponPackagePrices.some((item: any) => {
                        return isUndefined(item.apportionPrice);
                    });
                    if (isOk) {
                        AntMessage.warning(language.getText('selectSharePrice'));
                        return false;
                    }
                }
                const receiveLimit = entity.settingInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.settingInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        entity,
        fields: entity.fields || {},
    };
}
function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                entity.add(entityFields, params);
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('SalesElectronicCouponCopyFormContainer')
                    );
            },
        }
    );
}
export const SalesElectronicCouponCopyFormView = connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesElectronicCouponCopyForm);
