import React, { Component } from 'react';
import { InputNumber, Button, Popconfirm } from 'antd';
import { get, findIndex, toString, remove, isEqual } from 'lodash';
import { language, privilege } from '@comall-backend-builder/core/lib/services';

import {
    CouponSelector,
    CouponType,
    EventCouponValueTypeEnum,
} from '../event-market-pay-coupon/select-coupon';
import { services } from '@comall-backend-builder/core';

interface Coupon {
    id: string;
    couponName: number;
    couponType: string;
    startTime: string;
    endTime: string;
    batchNo: string;
    couponTypeDes: string;
    cooperationPatternDesc: string;
    count?: number;
    stock: number;
    apportionPrice?: number;
    leftStock: number;
    couponDefinitionId?: number;
    rewardPeriod: RewardPeriod;
    issuedNum: number;
}

interface RewardPeriod {
    memberRewardLimit?: number;
    periodQuantity: number | undefined;
}

interface OtherStockSet {
    /**
     * label 文案
     */
    addonBefore: string;
    /**
     * 单位文案
     */
    addonAfter: string;
}

interface ActivityRewardProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: Array<Coupon> | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<Coupon> | null, name: string) => void;
    /**
     * 优惠券每人每次发放次数是否可修改 true 表示输入框不可输入
     */
    inputDisabled: boolean;
    /**
     * 是否页面有且只存在一条优惠券数据  默认false
     */
    isOnlyOne: boolean;
    /**
     * 设置活动期间最多发放数量
     */
    sendCoupon: OtherStockSet;
    /**
     * 设置剩余数量
     */
    residueCoupon: OtherStockSet;
    /**
     * 是否是新增页面（复制页面）
     */
    isAdd: boolean;
}

export class BargainCouponSelect extends Component<ActivityRewardProps, any> {
    static defaultProps = {
        disabled: false,
        inputDisabled: false,
        isOnlyOne: false,
    };
    CouponSelector: any;
    constructor(props: any) {
        super(props);
        this.state = {
            goNextpage: null,
        };
    }

    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            onChange([], name);
        }
    }
    componentWillReceiveProps(nextProps: any) {
        let { name, onChange } = nextProps;
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (currentSubsiteIds && nextSubsiteIds && !isEqual(nextSubsiteIds, currentSubsiteIds)) {
            onChange([], name);
        }
    }

    getSubsiteIds = (props: any) => {
        const subsiteId = get(props, 'row.baseInfo.subsites');
        return subsiteId;
    };

    onChooseCoupon = () => {
        this.CouponSelector.toggleModal();
    };
    showModel = (ref: any) => {
        this.CouponSelector = ref;
    };
    setDisplayType = (row: Coupon[]) => {
        let displayType = 'selectCoupon';
        if (row.length && row[0]?.couponType === 'PACKAGE') {
            displayType = 'selectCouponPackage';
        }
        return displayType;
    };
    modalToCoupon = (item: any) => {
        return {
            ...item,
            id: item.id,
            couponDefinitionId: item.id,
            couponName: item.name || item.couponName,
            couponType: item.couponType || item.type,
            startTime: item.startTime,
            endTime: item.endTime,
            startDate: item.startDate,
            endDate: item.endDate,
            batchNo: item.code || item.batchNo,
            couponTypeDes: item.typeName || item.couponTypeDes,
            cooperationPatternDesc:
                (item.cooperationPattern && item.cooperationPattern.description) ||
                item.cooperationPatternDesc,
            consumeChannelDescription: item.consumeChannelDescription,
            count: item.count || 1,
            stock: item.stock,
            leftStock: item.leftStock,
            couponValue: item.couponValue,
            consumeRule: item.consumeRule,
        };
    };
    changeCoupons = (coupons: Array<any>, type: string) => {
        let { name, value, onChange, isOnlyOne } = this.props;
        let newCoupons: any = value ? value : [];
        if (this.setDisplayType(newCoupons) !== type) {
            newCoupons = [];
        }

        if (type === 'selectCoupon' && !isOnlyOne) {
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => toString(coupon.id) === toString(selectCuopon.id)
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
        } else {
            newCoupons = [...coupons];
        }
        newCoupons = newCoupons.map(this.modalToCoupon);
        if (onChange) {
            onChange(newCoupons, name);
        }
    };
    changeModal = () => {
        const { value } = this.props;
        let subsiteIds = get(this.props, 'row.baseInfo.subsites');
        if (subsiteIds && Array.isArray(subsiteIds) && subsiteIds.length) {
            subsiteIds = subsiteIds.map((item: any) => item.id).join(',');
        }
        const params = {
            subsiteIds: subsiteIds,
        };
        return (
            <CouponSelector
                onRef={this.showModel}
                selectValues={value || []}
                rewardIndex={0}
                onChange={this.changeCoupons}
                params={params}
            />
        );
    };

    onChangeCoupon = (products: Coupon[]) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(products, name);
        }
    };

    CheckCoupon = (item: Coupon) => {
        const { id } = item;
        let hasPrivilege = true;
        let route = '';
        const baseUrl = '#/menu-list/coupon';
        switch (item.couponType) {
            case CouponType.PACKAGE:
                route = `${baseUrl}/coupon-package-rules/edit/${id}`;
                hasPrivilege = privilege.check('couponPackageRule', 'full');
                break;
            case CouponType.CASH:
                route = `${baseUrl}/cash-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('cashCouponRule', 'full');
                break;
            case CouponType.GIFT:
                route = `${baseUrl}/gift-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('giftCouponRule', 'full');
                break;
            case CouponType.FREIGHT:
                route = `${baseUrl}/freight-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('freightCouponRule', 'full');
                break;
            case CouponType.PARKING:
                route = `${baseUrl}/parking-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('parkingCouponRule', 'full');
                break;
            case CouponType.LOTTERY:
                route = `${baseUrl}/lottery-coupon/edit/${id}`;
                hasPrivilege = privilege.check('lotteryCouponRule', 'full');
                break;
            case CouponType.BALANCE:
                route = `${baseUrl}/balance-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('balanceCouponRule', 'full');
                break;

            default:
                break;
        }
        if (hasPrivilege && route) {
            window.open(`${route}`, '_blank');
        } else {
            this.setState({
                goNextpage: {
                    id,
                    hasPrivilege,
                },
            });
        }
    };
    renderValue = (coupon: any) => {
        const { couponValue, couponType } = coupon;
        const couponRuleType = couponType;

        if (couponRuleType === CouponType.GIFT) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponRuleType === CouponType.LOTTERY) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                </div>
            );
        } else if (couponRuleType === CouponType.PACKAGE) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('gifts')}
                    </span>
                </div>
            );
        } else if (
            couponRuleType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.No
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('qm_2')}
                    </span>
                </div>
            );
        }
        if (!couponValue || !couponValue.value) {
            return null;
        } else if (
            couponType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                    <span className="event-coupon__symbol">小时</span>
                </div>
            );
        }
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__symbol">
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className="event-coupon__price__num">{couponValue.value}</span>
            </div>
        );
    };
    renderCoupons = (coupon: any) => {
        const innerClassName = `${coupon.couponType}-coupon-popup__list__item`;
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };
        return (
            <div className={innerClassName}>
                <div className="event-coupon" style={style}>
                    <div className="event-coupon__left">{this.renderValue(coupon)}</div>
                    <div className="event-coupon__right">
                        <div className="event-coupon__right__title" title={coupon.couponName}>
                            {coupon.couponName}
                        </div>
                        <div className="event-coupon__right__time">
                            {coupon.startDate}-{coupon.endDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    changeStock = (couponIndex: number, valueNum: number) => {
        const { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];
        if (rowItem) {
            rowItem.count = valueNum;
            if (onChange) {
                onChange(value, name);
            }
        }
    };
    /**
     *
     * @param valueNum 当前输入的最多发放数量
     * @returns
     */
    changePeriodQuantity = (couponIndex: number, valueNum: number) => {
        const { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];
        if (rowItem) {
            rowItem.rewardPeriod = {
                periodQuantity: valueNum,
            };
            if (onChange) {
                onChange(value, name);
            }
        }
    };
    deleteCouponItem = (couponIndex: number) => {
        const { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];
        if (rowItem) {
            remove(value, function(rowItemCoupon: Coupon) {
                return rowItemCoupon.id === rowItem.id;
            });
            if (onChange) {
                onChange(value, name);
            }
        }
    };
    showCouponItem = () => {
        const { value, inputDisabled = false, sendCoupon, residueCoupon, isAdd } = this.props;
        if (value) {
            const { goNextpage } = this.state;
            return value.map((item: Coupon, couponIndex: number) => {
                const unitTxt =
                    item.couponType === CouponType.PACKAGE
                        ? services.language.getText('ge')
                        : services.language.getText('zhang');
                let residueNum: number = 0;
                if (sendCoupon) {
                    if (item.rewardPeriod) {
                        item.rewardPeriod.periodQuantity = item.rewardPeriod?.periodQuantity
                            ? item.rewardPeriod?.periodQuantity
                            : item.leftStock;
                    } else {
                        item.rewardPeriod = {
                            periodQuantity: item.leftStock,
                        };
                    }
                    item.issuedNum = item.issuedNum ? item.issuedNum : 0;
                    if (item.rewardPeriod.periodQuantity) {
                        residueNum = item.rewardPeriod.periodQuantity - item.issuedNum;
                    }
                }

                return (
                    <div
                        key={item.id}
                        style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
                    >
                        <div>{this.renderCoupons(item)}</div>
                        <div style={{ minWidth: '135px', padding: '0 10px' }}>
                            {services.language.getText('stock')}：{item.leftStock}
                        </div>
                        <div style={{ width: '240px', flexShrink: 0 }}>
                            <div>
                                {services.language.getText('mrmcff')}：
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    disabled={inputDisabled}
                                    value={item.count}
                                    min={0}
                                    max={999999999}
                                    onChange={(value: any) => this.changeStock(couponIndex, value)}
                                ></InputNumber>
                                &nbsp;&nbsp;{unitTxt}
                            </div>
                            {sendCoupon && (
                                <div style={{ position: 'relative' }}>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-10px',
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                    {sendCoupon.addonBefore}：
                                    <InputNumber
                                        placeholder={services.language.getText('inputPlease')}
                                        value={item.rewardPeriod?.periodQuantity}
                                        min={0}
                                        max={999999999}
                                        onChange={(value: any) =>
                                            this.changePeriodQuantity(couponIndex, value)
                                        }
                                    ></InputNumber>
                                    &nbsp;&nbsp;{sendCoupon.addonAfter}
                                </div>
                            )}
                            {residueCoupon && !isAdd && (
                                <div>
                                    {residueCoupon.addonBefore}：
                                    <InputNumber
                                        placeholder={services.language.getText('inputPlease')}
                                        disabled={inputDisabled}
                                        value={residueNum}
                                        min={0}
                                        max={999999999}
                                        onChange={(value: any) =>
                                            this.changeStock(couponIndex, value)
                                        }
                                    ></InputNumber>
                                    &nbsp;&nbsp;{residueCoupon.addonAfter}
                                </div>
                            )}
                        </div>
                        <Popconfirm
                            title={`${services.language.getText('qrsc')}${item.couponName}?`}
                            onConfirm={() => this.deleteCouponItem(couponIndex)}
                            okText={services.language.getText('common.confirm')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="link">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>

                        <Button type="link" onClick={() => this.CheckCoupon(item)}>
                            {services.language.getText('ckqgz')}
                        </Button>
                        {goNextpage && !goNextpage.hasPrivilege && goNextpage.id === item.id && (
                            <div style={{ color: 'red', flexShrink: 0 }}>
                                {services.language.getText('zwckqgzqx')}
                            </div>
                        )}
                    </div>
                );
            });
        }
    };

    render() {
        return (
            <div className="comment-activity-reward">
                <Button type={'link'} onClick={this.onChooseCoupon} style={{ padding: 0 }}>
                    {services.language.getText('xzyhq')}
                </Button>
                <div className="comment-activity-reward__wrap">
                    {this.showCouponItem()}
                    {this.changeModal()}
                </div>
            </div>
        );
    }
}
