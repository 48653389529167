import React, { PureComponent, Fragment } from 'react';
import { Tree as AntdTree, Button, Modal } from 'antd';
import { uniq } from 'lodash';
import { categorie } from '../index';
import '../index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const { TreeNode: AntdTreeNode } = AntdTree;
interface Props {
    categoriesData: categorie[];
    filterCategoryIds: string[];
    onCategoryChange: (value: string[]) => void;
}
export default class SelectionPoolFilterClassify extends PureComponent<Props, unknown> {
    state = {
        visibleModal: false,
        selectTreeKeys: [],
        needKeys: [],
    };
    private checkHandle = (checkedKeys: any, info: any) => {
        const { needKeys } = this.state;
        let newNeedKeys: any = [];
        let keys: any = [];
        if (info.checked) {
            if (info.node.props.dataRef.children?.length) {
                info.node.props.dataRef.children.forEach((children: categorie) => {
                    if (children?.children?.length) {
                        children.children.forEach((c: categorie) => {
                            keys.push(c.categoryId);
                        });
                    } else {
                        keys.push(children.categoryId);
                    }
                });
            } else {
                keys.push(info.node.props.dataRef.categoryId);
            }
            newNeedKeys = uniq(needKeys.concat(keys));
        } else {
            newNeedKeys = this.getTreeKeys(checkedKeys, 'need');
        }
        this.setState({
            needKeys: newNeedKeys,
            selectTreeKeys: checkedKeys,
        });
    };
    private showModal = () => {
        const { filterCategoryIds } = this.props;
        let keys = this.getTreeKeys(filterCategoryIds);
        this.setState({
            visibleModal: true,
            selectTreeKeys: keys,
            needKeys: filterCategoryIds,
        });
    };
    private getTreeKeys = (filterCategoryIds: any[], type?: string) => {
        const { categoriesData } = this.props;
        const selectTreeKeys: any[] = [];
        let tLevel = 0;
        let fLevel = 0;
        if (filterCategoryIds?.length) {
            categoriesData.forEach((g: categorie) => {
                g?.children?.forEach((f: categorie) => {
                    let index = filterCategoryIds.findIndex(
                        (item: any) => String(item) === String(f.categoryId)
                    );
                    if (index > -1) {
                        tLevel++;
                        if (type) {
                            if (!f?.children?.length) {
                                selectTreeKeys.push(f.categoryId);
                            }
                        } else {
                            if (tLevel === g?.children.length) {
                                selectTreeKeys.push(g.categoryId, f.categoryId);
                            } else {
                                selectTreeKeys.push(f.categoryId);
                            }
                        }
                    }
                    f?.children?.forEach((a: categorie) => {
                        let index = filterCategoryIds.findIndex(
                            (item: any) => String(item) === String(a.categoryId)
                        );
                        if (index > -1) {
                            fLevel++;
                            if (type) {
                                selectTreeKeys.push(a.categoryId);
                            } else {
                                if (fLevel === f?.children.length) {
                                    selectTreeKeys.push(f.categoryId, a.categoryId);
                                } else {
                                    selectTreeKeys.push(a.categoryId);
                                }
                            }
                        }
                    });
                });
            });
            let newKeys = uniq(selectTreeKeys);
            return newKeys;
        }
        return [];
    };
    hideModal = () => {
        this.setState({ visibleModal: false, selectTreeKeys: [], needKeys: [] });
    };
    private onOk = () => {
        const { needKeys } = this.state;
        const { onCategoryChange } = this.props;
        if (onCategoryChange) {
            onCategoryChange(needKeys);
            this.hideModal();
        }
    };
    private renderTreeNodes = (treeNodes: categorie[]) => {
        return treeNodes.map((tree: any) => (
            <AntdTreeNode key={tree.categoryId} title={tree.categoryName} dataRef={tree}>
                {tree?.children ? this.renderTreeNodes(tree.children) : undefined}
            </AntdTreeNode>
        ));
    };
    private renderCategories = () => {
        const { categoriesData } = this.props;
        const { selectTreeKeys } = this.state;
        return (
            <AntdTree
                selectable={false}
                checkable
                autoExpandParent={true}
                checkedKeys={selectTreeKeys}
                onCheck={this.checkHandle}
            >
                {this.renderTreeNodes(categoriesData)}
            </AntdTree>
        );
    };
    render() {
        const { visibleModal } = this.state;
        const { filterCategoryIds } = this.props;
        return (
            <Fragment>
                <div className={wrap}>
                    <Button style={{ paddingRight: 5 }} type={'link'} onClick={this.showModal}>
                        {language.getText('pctdspfl')}
                    </Button>
                    {filterCategoryIds?.length > 0 && <span>({filterCategoryIds.length})</span>}
                </div>
                <Modal
                    title={services.language.getText('qxzfl')}
                    width={350}
                    visible={visibleModal}
                    className={clsNames.modal}
                    onCancel={this.hideModal}
                    onOk={this.onOk}
                >
                    <div className={clsNames.modalContent}>{this.renderCategories()}</div>
                </Modal>
            </Fragment>
        );
    }
}

const wrap = `selection-pool-page-filter__classify`;
const clsNames = {
    modal: `${wrap}__modal`,
    modalContent: `${wrap}__modal__content`,
};
