import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, forEach, map, find, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

import './index.less';

const api = services.api;
const TableComponentId = 'SubsiteSelectorTable';
let Entity: any = {};
let entity: any = {};
enum AllSubsiteCheckboxState {
    SELECTED = 'SELECTED',
    PART_SELECTED = 'PART_SELECTED',
    UNSELECTED = 'UNSELECTED',
}

/**
 * 选择分站
 */
class activitySelector extends PureComponent<{
    selectValues: any[];
    onChange: (products: any[]) => void;
    requestStatus: string;
    params?: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('NewMallActivityEntity');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = {
        visible: false,
        allSubsites: [],
        allSubsiteCheckboxState: AllSubsiteCheckboxState.UNSELECTED,
    };

    selectedRows = new Array<any>();

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            }
        ).then((response: any) => {
            this.setState({
                allSubsites: response.result,
            });
        });
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        let tableRowSelectionChangeAction = false;
        //在选中列表中删除数据后更新弹窗选中状态
        if (
            (!nextProps.selectValues || nextProps.selectValues.length === 0) &&
            this.props.selectValues &&
            this.props.selectValues.length > 0
        ) {
            tableRowSelectionChangeAction = true;
            this.selectedRows = [];
            this.changeAllSubsiteCheckboxState();
        }
        if (nextProps.selectValues) {
            tableRowSelectionChangeAction = true;
            this.selectedRows = nextProps.selectValues;
            this.changeAllSubsiteCheckboxState();
        }
        if (
            (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') ||
            tableRowSelectionChangeAction
        ) {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
            }
        }
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            if (find(this.selectedRows, { id: record.id })) {
                return;
            }
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
        this.changeAllSubsiteCheckboxState();
    };

    onTableRowSelectAll = (selected: boolean, rows: any[], unSelectedRows: any) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
        this.changeAllSubsiteCheckboxState();
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });

            onChange(this.selectedRows);
            this.toggleModal();
        }
    };
    changeAllSubsiteCheckboxState = () => {
        const { allSubsites } = this.state;
        if (
            this.selectedRows &&
            allSubsites &&
            this.selectedRows.length > 0 &&
            this.selectedRows.length === allSubsites.length
        ) {
            this.setState({
                allSubsiteCheckboxState: AllSubsiteCheckboxState.SELECTED,
            });
        } else if (this.selectedRows && this.selectedRows.length === 0) {
            this.setState({
                allSubsiteCheckboxState: AllSubsiteCheckboxState.UNSELECTED,
            });
        } else {
            this.setState({
                allSubsiteCheckboxState: AllSubsiteCheckboxState.PART_SELECTED,
            });
        }
    };
    selectAllSubsite = (selected: boolean, subsite: any[]) => {
        const { allSubsiteCheckboxState, allSubsites } = this.state;
        switch (allSubsiteCheckboxState) {
            case AllSubsiteCheckboxState.PART_SELECTED:
                this.selectedRows = this.selectedRows.concat(allSubsites);
                this.setState({
                    allSubsiteCheckboxState: AllSubsiteCheckboxState.SELECTED,
                });
                break;
            case AllSubsiteCheckboxState.UNSELECTED:
                this.selectedRows = this.selectedRows.concat(allSubsites);
                this.setState({
                    allSubsiteCheckboxState: AllSubsiteCheckboxState.SELECTED,
                });
                break;
            default:
                this.selectedRows = [];
                this.setState({
                    allSubsiteCheckboxState: AllSubsiteCheckboxState.UNSELECTED,
                });
                break;
        }
        this.dispatch(
            actions.tableRowSelectionChangeAction(
                TableComponentId,
                map(this.selectedRows, (value) => {
                    return value.id + '';
                })
            )
        );
    };

    getConfig = () => {
        const { params, selectValues } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    // onSubmit: (event: any) => {
                    //     event.stopPropagation();
                    //     entity.search(params);
                    // },
                    fields: [
                        {
                            property: 'name',
                            controlConfig: {
                                style: { width: 300 },
                            },
                        },
                        {
                            property: 'status',
                            controlConfig: {
                                style: { width: 150 },
                            },
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.id + '';
                        }),
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any[], unSelectedRows: any[]) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '未开始',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '进行中',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '已结束',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: '选择活动',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="primary" onClick={this.toggleModal}>
                    选择活动
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const ActivitySelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(activitySelector);
