import { message as AntMessage } from 'antd';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ContentPlateTypes, CONTENT_PLATE_TYPES_OPTIONS } from './content-plate';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

enum ContentTopicStatus {
    /**
     * 启用
     */
    ENABLE = 'ENABLE',
    /**
     * 禁用
     */
    DISABLE = 'DISABLE',
}

const CONTENT_TOPIC_STATUS_OPTIONS = [
    {
        id: ContentTopicStatus.ENABLE,
        name: '<<enable>>',
    },
    {
        id: ContentTopicStatus.DISABLE,
        name: '<<disabled>>',
    },
];

const CONTENT_TOPIC_STATUS_CONFIG = {
    [ContentTopicStatus.ENABLE]: {
        text: '<<enable>>',
        status: 'success',
    },
    [ContentTopicStatus.DISABLE]: {
        text: '<<disabled>>',
        status: 'error',
    },
};

export const config: Config = {
    entities: {
        contentTopicEntity: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/topics',
            filters: {
                plateId: {
                    type: 'string.options.select',
                    displayName: '<<ssbk_1>>',
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/loader/admin/plates/available',
                    },
                },
                topicName: {
                    type: 'string',
                    displayName: '<<htmc>>',
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<htzt>>',
                    controlConfig: {
                        style: {
                            width: 100,
                        },
                    },
                    options: CONTENT_TOPIC_STATUS_OPTIONS,
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<htmc>>',
                    controlConfig: {
                        maxLength: 20,
                    },
                    defaultValue: '',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                        },
                    ],
                },
                topicName: {
                    type: 'string.popover',
                    displayName: '<<htmc>>',
                },
                id: {
                    type: 'string',
                    displayName: '<<topicId>>',
                },
                plateType: {
                    type: 'string.options.radio',
                    displayName: '<<bklx>>',
                    defaultValue: ContentPlateTypes.GENERAL,
                    options: CONTENT_PLATE_TYPES_OPTIONS,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                plateIds: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<ssbk_1>>',
                    defaultValue: [],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/admin/plates/available',
                    },
                },
                plateNames: {
                    type: 'string',
                    displayName: '<<ssbk_1>>',
                },
                generalPlateIds: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<ptbk>>',
                    defaultValue: [],
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: `/admin/plates/available?type=${ContentPlateTypes.GENERAL}`,
                    },
                },
                publicityPlateIds: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<xcbk>>',
                    defaultValue: [],
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: `/admin/plates/available?type=${ContentPlateTypes.PUBLICITY}`,
                    },
                },
                resource: {
                    type: 'imageObjectArray',
                    displayName: '<<http>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'files',
                        uploadType: 'single',
                        uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/302/upload`,
                        mode: 'picture-card',
                        maxSize: 2 * 1024,
                        preview: {
                            size: 500,
                        },
                        uploadLimitSizeErrorMessage:
                            '<<pictures>>{{file.name}}<<pictureUploadLimit>>',
                    },
                    defaultValue: [],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    extra: '<<topicResourceExtra>>',
                },
                sequence: {
                    type: 'number',
                    displayName: '<<sequence>>',
                    controlConfig: {
                        min: 1,
                    },
                    defaultValue: '1',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<enabledStatus>>',
                    controlConfig: {
                        style: {
                            width: '200px',
                        },
                    },
                    defaultValue: ContentTopicStatus.ENABLE,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: CONTENT_TOPIC_STATUS_OPTIONS,
                },
                topicValidity: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<htyxq>>',
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                        range: {
                            start: new Date(),
                        },
                    },
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
            },
        },
    },
    components: {
        contentTopicView: {
            component: 'Viewport',
            entity: 'contentTopicEntity',
        },
        contentTopicPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'contentTopicFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzht>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/content-topic/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'contentTopicTable',
                },
            ],
        },
        contentTopicFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'plateId',
                },
                {
                    property: 'topicName',
                },
                {
                    property: 'status',
                },
            ],
        },
        contentTopicTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'topicName',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'id',
                },
                {
                    property: 'plateNames',
                    displayConfig: {
                        className: 'custom-array-options-text',
                    },
                },
                {
                    property: 'resource',
                    displayConfig: {
                        style: {
                            width: 80,
                            height: 80,
                        },
                    },
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: CONTENT_TOPIC_STATUS_CONFIG,
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 140,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/content-topic/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: {
                                display: 'inline-flex',
                            },
                            handles: [
                                {
                                    apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                                    apiPath: '/admin/topics/:id/status',
                                    params: {
                                        status: ContentTopicStatus.DISABLE,
                                    },
                                    value: ContentTopicStatus.ENABLE,
                                    config: {
                                        content: '<<disabled>>',
                                        text: '<<disabled>>',
                                        style: {
                                            color: '#f5222d',
                                        },
                                    },
                                    confirm: {
                                        text: '<<isDisabled>>{{row.name}}?',
                                        content: '<<jyhqtymzsjsdyx>>',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                                    apiPath: '/admin/topics/:id/status',
                                    params: {
                                        status: ContentTopicStatus.ENABLE,
                                    },
                                    value: ContentTopicStatus.DISABLE,
                                    config: {
                                        content: '<<enable>>',
                                        text: '<<enable>>',
                                    },
                                    confirm: {
                                        text: '<<isEnable>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },

        contentTopicAddPage: {
            component: 'Card',
            content: {
                component: 'contentTopicAddForm',
            },
        },
        contentTopicAddForm: {
            component: 'CreateFormPlus',
            entity: 'contentTopicEntity',
            direction: 'vertical',
            style: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'resource',
                },
                {
                    property: 'plateType',
                },
                {
                    property: 'generalPlateIds',
                    visible: (values: any) =>
                        get(values, 'plateType') === ContentPlateTypes.GENERAL,
                },
                {
                    property: 'publicityPlateIds',
                    visible: (values: any) =>
                        get(values, 'plateType') === ContentPlateTypes.PUBLICITY,
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                },
                {
                    property: 'topicValidity',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: {
                            marginLeft: 10,
                        },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (
                    get(entity, 'plateType') === ContentPlateTypes.GENERAL &&
                    !get(entity, 'generalPlateIds.length')
                ) {
                    AntMessage.warning(language.getText('qgxptbk'));
                    return false;
                } else if (
                    get(entity, 'plateType') === ContentPlateTypes.PUBLICITY &&
                    !get(entity, 'publicityPlateIds.length')
                ) {
                    AntMessage.warning(language.getText('qgxxcbk'));
                    return false;
                }
                // 校验活动有效期时间：话题结束时间需要大于当前时间，开始时间需要小于结束时间
                if (
                    +new Date(get(entity, 'topicValidity.start')) >=
                        +new Date(get(entity, 'topicValidity.end')) ||
                    +new Date(get(entity, 'topicValidity.end')) <= +new Date()
                ) {
                    AntMessage.error(language.getText('qsrzqdhtyxq'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({
                        route: 'goBack',
                    })
                );
            },
        },
        contentTopicEditPage: {
            component: 'Card',
            content: {
                component: 'contentTopicEditForm',
            },
        },
        contentTopicEditForm: {
            component: 'ModifyFormPlus',
            entity: 'contentTopicEntity',
            direction: 'vertical',
            loaderType: 'get',
            style: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'resource',
                },
                {
                    property: 'plateType',
                },
                {
                    property: 'generalPlateIds',
                    visible: (values: any) =>
                        get(values, 'plateType') === ContentPlateTypes.GENERAL,
                },
                {
                    property: 'publicityPlateIds',
                    visible: (values: any) =>
                        get(values, 'plateType') === ContentPlateTypes.PUBLICITY,
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                },
                {
                    property: 'topicValidity',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: {
                            marginLeft: 10,
                        },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (
                    get(entity, 'plateType') === ContentPlateTypes.GENERAL &&
                    !get(entity, 'generalPlateIds.length')
                ) {
                    AntMessage.warning(language.getText('qgxptbk'));
                    return false;
                } else if (
                    get(entity, 'plateType') === ContentPlateTypes.PUBLICITY &&
                    !get(entity, 'publicityPlateIds.length')
                ) {
                    AntMessage.warning(language.getText('qgxxcbk'));
                    return false;
                }
                // 校验活动有效期时间：话题结束时间需要大于当前时间，开始时间需要小于结束时间
                if (
                    +new Date(get(entity, 'topicValidity.start')) >=
                        +new Date(get(entity, 'topicValidity.end')) ||
                    +new Date(get(entity, 'topicValidity.end')) <= +new Date()
                ) {
                    AntMessage.error(language.getText('qsrzqdhtyxq'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({
                        route: 'goBack',
                    })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/content-topic',
                    breadcrumbName: '<<contentTopic>>',
                    component: 'contentTopicView',
                    privilege: {
                        path: 'showTopic',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'contentTopicAddPage',
                            breadcrumbName: '<<xzht>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'contentTopicEditPage',
                            breadcrumbName: '<<bjht>>',
                        },
                        {
                            path: '/',
                            component: 'contentTopicPage',
                        },
                    ],
                },
            ],
        },
    ],
};
