import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach } from 'lodash';

export const config: Config = {
    entities: {
        BargainActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/bargain_activity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'string.options.select',
                            className: 'custom-array-options',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        productOrGoods: {
                            type: 'object.bargain.productsOrGoodsSelector',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    async validator(_: any, value: any) {
                                        const products = value.products;
                                        const goods = value.goods;
                                        if (products.length > 0 || goods.length > 0) {
                                            if (products.length > 0) {
                                                forEach(products, (i) => {
                                                    if (!i.generalPrice) {
                                                        throw new Error(
                                                            services.language.getText('qsrdj')
                                                        );
                                                    }
                                                    if (
                                                        i.stock === undefined ||
                                                        i.stock === null ||
                                                        i.stock === ''
                                                    ) {
                                                        throw new Error(
                                                            services.language.getText('qsrkjkc')
                                                        );
                                                    }
                                                    if (i.stock <= 0) {
                                                        throw new Error(
                                                            services.language.getText('kjkcbnw')
                                                        );
                                                    }
                                                });
                                            }
                                            if (goods.length > 0) {
                                                forEach(goods, (i) => {
                                                    if (!i.generalPrice) {
                                                        throw new Error(
                                                            services.language.getText('qsrdj')
                                                        );
                                                    }
                                                    if (
                                                        i.stock === undefined ||
                                                        i.stock === null ||
                                                        i.stock === ''
                                                    ) {
                                                        throw new Error(
                                                            services.language.getText('qsrkjkc')
                                                        );
                                                    }
                                                    if (i.stock <= 0) {
                                                        throw new Error(
                                                            services.language.getText('kjkcbnw')
                                                        );
                                                    }
                                                });
                                            }
                                        }
                                    },
                                },
                            ],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                subsiteField: 'baseInfo.subsites',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    return {
                                        subsiteIds: row.baseInfo?.subsites,
                                    };
                                },
                            },
                        },
                    },
                },
                initiatorInfo: {
                    type: 'object.subForm',
                    displayName: '<<fqzgzsz>>',
                    properties: {
                        effectiveHours: {
                            type: 'number.tip',
                            displayName: '<<kjyxq>>',
                            rules: [{ required: true, message: '<<qsrkjyxq>>' }],
                            controlConfig: {
                                min: 1,
                                max: 48,
                                precision: 0,
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<hour>>',
                                tip: '',
                            },
                            extra: '<<yxbkjkcghxddsz>>',
                        },
                        initBargainAmount: {
                            type: 'number.tip',
                            displayName: '<<csyhje>>',
                            controlConfig: {
                                min: 0.01,
                                max: 999999999,
                                precision: 2,
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<yuan>>',
                                tip: '',
                            },
                            extra: '<<fqkjh>>',
                        },
                        bargainCountEachMember: {
                            type: 'number.tip',
                            displayName: '<<mrfqkjcs>>',
                            rules: [{ required: true, message: '<<qsrmrfqkjcs>>' }],
                            controlConfig: {
                                min: 1,
                                max: 99,
                                precision: 0,
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<ciandr>>',
                                tip: '',
                            },
                            extra: '<<mgyhfqkjjwyc>>',
                        },
                    },
                },
                helpInfo: {
                    type: 'object.subForm',
                    displayName: '<<bkzgzsz>>',
                    properties: {
                        rewardConfigId: {
                            type: 'string',
                        },
                        bargainAmountCalculateMethod: {
                            type: 'string.options.radio',
                            displayName: '<<mrbkje>>',
                            options: [
                                { id: 'RANDOM', name: '<<sjje>>' },
                                { id: 'AVERAGE', name: '<<gdje>>' },
                            ],
                            defaultValue: 'RANDOM',
                            rules: [{ required: true }],
                            extra: '<<szmwyhdbkje>>',
                        },
                        helpMemberCount: {
                            type: 'number.tip',
                            displayName: '<<bkrs>>',
                            rules: [{ required: true, message: '<<qsrbkrs>>' }],
                            controlConfig: {
                                min: 1,
                                max: 9999,
                                precision: 0,
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '人',
                                tip: '',
                            },
                            extra: '<<dbkddcrss>>',
                        },
                        helpBargainCountEachMember: {
                            type: 'number.tip',
                            displayName: '<<bkcs>>',
                            rules: [{ required: true, message: '<<qsrbkcs>>' }],
                            controlConfig: {
                                min: 1,
                                max: 9999,
                                precision: 0,
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<frequency>>',
                                tip: '',
                            },
                            extra: '<<tyrkybjgfqzkj>>',
                        },
                        coupons: {
                            type: 'array.bargain.couponsSelector',
                            displayName: '<<hybkjl>>',
                            extra: '<<xbkdhyzsjl>>',
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherSetting>>',
                    properties: {
                        shareDescription: {
                            type: 'string',
                            displayName: '<<fxhdspwa>>',
                            defaultValue: '<<klbwkydb>>',
                            controlConfig: {
                                placeholder: '<<klbwkydb>>',
                                style: {
                                    width: 450,
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 30,
                                    whitespace: true,
                                    message: '<<qsrfxwa30Rule>>',
                                },
                            ],
                            extra: '<<qgkjspsszddjz>>',
                        },
                        backgroundPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<hdybjt>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/bargain-bg-new.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<backgroundPicExtra>>',
                                    img: './images/avatar/bargain-background-new.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        bargainButtonPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<kjant>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/bargain-button.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<bargainButtonPictureExtra>>',
                                    img: './images/avatar/bargain-button-new.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        bargainRewardPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<bkjltst>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/red-reward-bg-new.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<bargainRewardPictureExtra>>',
                                    img: './images/avatar/bargain-reward.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        openBargainRewardPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<ckbkjlt>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/open-red-reward-bg-new.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<bargainRewardPictureExtra>>',
                                    img: './images/avatar/open-bargain-reward.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            defaultValue: '<<descriptionValue>>',
                            rules: [
                                { whitespace: true, max: 1000, message: '<<notMoreThan2000>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<description2Extra>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                product: {
                    type: 'object.list.item.productInfo',
                    displayName: '<<activityProduct>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<activityMerchant>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                bargainCount: {
                    type: 'string',
                    displayName: '<<fqrs_1>>',
                },
                helpMemberCount: {
                    type: 'string',
                    displayName: '<<bkrs>>',
                },
                bargainSuccessCount: {
                    type: 'string',
                    displayName: '<<kjcgs>>',
                },
                finishCount: {
                    type: 'string',
                    displayName: '<<cjdds>>',
                },
                remainQuantity: {
                    type: 'string',
                    displayName: '<<sykc>>',
                },
            },
        },
        BargainActivityConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/bargain_activity_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                helpBargainCountEachMember: {
                    type: 'string',
                    displayName: '<<bksxcs>>',
                },
            },
        },
    },
    components: {
        BargainActivityView: {
            component: 'Viewport',
            entity: 'BargainActivityEntity',
        },
        BargainActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'BargainActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzkjhd>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    style: {
                                        marginRight: '20px',
                                    },
                                    route: '/menu-list/market/bargain-activity/add',
                                },
                                {
                                    component: 'Button',
                                    text: '<<kjtysz>>',
                                    type: 'primary',
                                    route: '/menu-list/market/bargain-activity/setting',
                                },
                            ],
                        },
                    ],
                },
                { component: 'BargainActivityTable' },
            ],
        },
        BargainActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        BargainActivityTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: 2000,
                    },
                    columns: [
                        {
                            property: 'product',
                            width: 300,
                        },
                        {
                            property: 'name',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    width: '180px',
                                },
                            },
                        },
                        {
                            property: 'startTime',
                            width: 200,
                        },
                        {
                            property: 'endTime',
                            width: 200,
                        },
                        {
                            property: 'subsiteName',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                            },
                        },
                        {
                            property: 'bargainCount',
                            width: 100,
                        },
                        {
                            property: 'helpMemberCount',
                            width: 100,
                        },
                        {
                            property: 'bargainSuccessCount',
                            width: 100,
                        },
                        {
                            property: 'finishCount',
                            width: 100,
                        },
                        {
                            property: 'remainQuantity',
                            width: 100,
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 270,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                            apiPath: '/admin/bargain_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                            apiPath: '/admin/bargain_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/menu-list/market/bargain-activity/edit/{{row.id}}',
                            },
                            {
                                text: '<<copy>>',
                                type: 'link',
                                path: '/menu-list/market/bargain-activity/copy/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'PageNewPreviewCodeButton',
                                config: {
                                    codeTypes: 'all',
                                    codeModalType: 'item',
                                    codePageType: 'bargainPage',
                                },
                            },
                            {
                                text: '<<common.log>>',
                                type: 'link',
                                path: '/menu-list/market/bargain-activity/logs/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        BargainActivityAddPage: {
            component: 'Card',
            content: { component: 'BargainActivityAddForm' },
        },
        BargainActivityAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'BargainActivityEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.productOrGoods' },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'initiatorInfo.effectiveHours' },
                { property: 'initiatorInfo.initBargainAmount' },
                { property: 'initiatorInfo.bargainCountEachMember' },
                { property: 'helpInfo.bargainAmountCalculateMethod' },
                { property: 'helpInfo.helpMemberCount' },
                { property: 'helpInfo.helpBargainCountEachMember' },
                { property: 'helpInfo.coupons' },
                { property: 'pictureInfo.shareDescription' },
                { property: 'pictureInfo.backgroundPicture' },
                { property: 'pictureInfo.bargainButtonPicture' },
                { property: 'pictureInfo.bargainRewardPicture' },
                { property: 'pictureInfo.openBargainRewardPicture' },
                { property: 'pictureInfo.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const products = entity.baseInfo.productOrGoods.products;
                const goods = entity.baseInfo.productOrGoods.goods;
                if (products.length === 0 && goods.length === 0) {
                    AntMessage.warning(
                        services.language.getText('components.Tools.productSelectRequiredl')
                    );
                    return false;
                }
                const initBargainAmount = entity.initiatorInfo.initBargainAmount;
                const helpMemberCount = entity.helpInfo.helpMemberCount;
                if (products.length > 0) {
                    const product = products[0];
                    const salesPrice = product.salesPrice;
                    const generalPrice = product.generalPrice;
                    if (initBargainAmount && initBargainAmount > salesPrice - generalPrice) {
                        AntMessage.warning(services.language.getText('csjebdgyxsjydjdce'));
                        return false;
                    }
                    if (salesPrice && generalPrice) {
                        let price = salesPrice - generalPrice;
                        if (initBargainAmount) {
                            price = price - initBargainAmount;
                        }
                        if (Number(price.toFixed(2)) / helpMemberCount < 0.01) {
                            AntMessage.warning(services.language.getText('mrzsbk'));
                            return false;
                        }
                    }
                }
                if (goods.length > 0) {
                    const good = goods[0];
                    const salesPrice = good.salesPrice;
                    const generalPrice = good.generalPrice;
                    if (initBargainAmount && initBargainAmount > salesPrice - generalPrice) {
                        AntMessage.warning(services.language.getText('csjebdgyxsjydjdce'));
                        return false;
                    }
                    if (salesPrice && generalPrice) {
                        let price = salesPrice - generalPrice;
                        if (initBargainAmount) {
                            price = price - initBargainAmount;
                        }
                        if (Number(price.toFixed(2)) / helpMemberCount < 0.01) {
                            AntMessage.warning(services.language.getText('mrzsbk'));
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BargainActivityEditPage: {
            component: 'FlexLayout',
            entity: 'BargainActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'BargainActivityEditForm' }],
        },
        BargainActivityCopyPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'BargainActivityCopyForm' }],
        },
        BargainActivityCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            entity: 'BargainActivityEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.productOrGoods' },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'initiatorInfo.effectiveHours' },
                { property: 'initiatorInfo.initBargainAmount' },
                { property: 'initiatorInfo.bargainCountEachMember' },
                { property: 'helpInfo.bargainAmountCalculateMethod' },
                { property: 'helpInfo.helpMemberCount' },
                { property: 'helpInfo.helpBargainCountEachMember' },
                { property: 'helpInfo.coupons' },
                { property: 'pictureInfo.shareDescription' },
                { property: 'pictureInfo.backgroundPicture' },
                { property: 'pictureInfo.bargainButtonPicture' },
                { property: 'pictureInfo.bargainRewardPicture' },
                { property: 'pictureInfo.openBargainRewardPicture' },
                { property: 'pictureInfo.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const products = entity.baseInfo.productOrGoods.products;
                const goods = entity.baseInfo.productOrGoods.goods;
                if (products.length === 0 && goods.length === 0) {
                    AntMessage.warning(
                        services.language.getText('components.Tools.productSelectRequiredl')
                    );
                    return false;
                }
                const initBargainAmount = entity.initiatorInfo.initBargainAmount;
                const helpMemberCount = entity.helpInfo.helpMemberCount;
                if (products.length > 0) {
                    const product = products[0];
                    const salesPrice = product.salesPrice;
                    const generalPrice = product.generalPrice;
                    if (initBargainAmount && initBargainAmount > salesPrice - generalPrice) {
                        AntMessage.warning(services.language.getText('csjebdgyxsjydjdce'));
                        return false;
                    }
                    if (salesPrice && generalPrice) {
                        let price = salesPrice - generalPrice;
                        if (initBargainAmount) {
                            price = price - initBargainAmount;
                        }
                        if (Number(price.toFixed(2)) / helpMemberCount < 0.01) {
                            AntMessage.warning(services.language.getText('mrzsbk'));
                            return false;
                        }
                    }
                }
                if (goods.length > 0) {
                    const good = goods[0];
                    const salesPrice = good.salesPrice;
                    const generalPrice = good.generalPrice;
                    if (initBargainAmount && initBargainAmount > salesPrice - generalPrice) {
                        AntMessage.warning(services.language.getText('csjebdgyxsjydjdce'));
                        return false;
                    }
                    if (salesPrice && generalPrice) {
                        let price = salesPrice - generalPrice;
                        if (initBargainAmount) {
                            price = price - initBargainAmount;
                        }
                        if (Number(price.toFixed(2)) / helpMemberCount < 0.01) {
                            AntMessage.warning(services.language.getText('mrzsbk'));
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BargainActivityLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/bargain_activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
        BargainActivityConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'BargainActivityConfigsEntity',
            items: [
                {
                    component: 'BargainActivityConfigsFilter',
                },
                { component: 'BargainActivityConfigsTable' },
            ],
        },
        BargainActivityConfigsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                marginBottom: 16,
            },
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: '16px',
                },
            },
            fields: [
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        BargainActivityConfigsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'helpBargainCountEachMember',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'BargainActivitySettingEditButton',
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/bargain-activity',
                    component: 'BargainActivityView',
                    breadcrumbName: '<<kj>>',
                    privilege: {
                        path: 'bargain',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'BargainActivityAddPage',
                            breadcrumbName: '<<xzkjhd>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'BargainActivityEditPage',
                            breadcrumbName: '<<bjkjhd>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'BargainActivityCopyPage',
                            breadcrumbName: '<<fzkjhd>>',
                        },
                        {
                            path: '/setting',
                            component: 'BargainActivityConfigsPage',
                            breadcrumbName: '<<kjtysz>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'BargainActivityLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'BargainActivityPage',
                        },
                    ],
                },
            ],
        },
    ],
};
