import { Modal as AntModal, Input, message as AntMessage } from 'antd';
import React, { Component } from 'react';
import { WechatLinkOptions } from './wechat-link-message';
import { LinkTypePlus } from '../../../../applications/design/components';
import {
    LinkTypeEffectPathConfig,
    getNewLinkTypeEffectPathConfigList,
} from '../../../../components/link-type-effect-path/config';
import { newClientCustomSelectorConfig } from '../../../../applications/design/design';
import { checkLinkTypeParamsRequired } from '../../../../types/format';
import { linkNameGen, linkPathGen, transformParams } from '../../../miniprogram-v2';
import { find } from 'lodash';
import { WechatTextTargetVo } from '../wechant-content';
import './index.less';
import { decodeURLToPath, encodePathToURL } from '../../utils';
import WechatOfficialReleatedSubsiteSelector from '../wechat-official-related-subsite-selector';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface Props {
    onConfirm(value: WechatLinkOptions): void;
    value?: Partial<WechatLinkOptions>;
    targetList: Array<WechatTextTargetVo>;
    visible: boolean;
    onCancel(): void;
    appId: string;
}
interface States {
    link: string;
    text: string;
    target?: any;
    linkTypes: LinkTypeEffectPathConfig[];
}
export class WechatLinkModalForm extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            link: '',
            text: '',
            target: {
                linkType: '',
                linkParams: {},
                linkName: '',
                linkPath: '',
            },
            linkTypes: getNewLinkTypeEffectPathConfigList(),
        };
    }

    componentDidMount() {
        const { value, targetList } = this.props;
        if (value && value.url) {
            const defalutTarget: any = find(targetList, {
                href: value.url,
            });
            this.setState({
                text: value.text || '',
                link: decodeURLToPath(value.url) || '',
                target: defalutTarget
                    ? defalutTarget.target
                    : {
                          linkType: '',
                          linkParams: {},
                          linkName: '',
                          linkPath: '',
                      },
            });
        }
    }

    findLinkTypeById = (
        id: string,
        linkTypes: LinkTypeEffectPathConfig[]
    ): LinkTypeEffectPathConfig | undefined => {
        let config;
        linkTypes.forEach((linkType) => {
            if (!linkType.children && linkType.id === id) {
                config = linkType;
            }
            if (linkType.children && linkType.children.length > 0) {
                linkType.children.forEach((it) => {
                    if (it.id === id) {
                        config = it;
                    }
                });
            }
        });
        return config;
    };

    changeTargetValue = (val: any) => {
        const { linkTypes } = this.state;
        const config = this.findLinkTypeById(val.linkType, linkTypes);
        if (config) {
            const valObj = { ...val };
            const paramsObj = transformParams(val.linkParams, config.paramsHandler);
            Object.assign(valObj, {
                linkName: linkNameGen(config.name, valObj.linkParams),
                linkPath: linkPathGen(config.basePath, config.params, paramsObj),
            });
            this.setState({
                target: valObj,
                link: valObj.linkPath,
            });
        }
    };
    onTextChange = (e: any) => {
        this.setState({
            text: e.target.value,
        });
    };

    onOK = () => {
        const { text, target, link } = this.state;
        const { onConfirm } = this.props;
        if (!target.subsiteId) {
            AntMessage.warning(services.language.getText('selectSubsite'));
            return false;
        }
        if (!text) {
            AntMessage.warning(services.language.getText('qsrljwz'));
            return false;
        }
        if (!target || !target.linkType) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        if (target && !checkLinkTypeParamsRequired(target.linkType, target.linkParams)) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        onConfirm({
            url: encodePathToURL(link, target.subsiteId),
            text,
            target,
        });
    };

    private onSubsiteChange = (subsite: { id: number; name: string }) => {
        const { target } = this.state;
        this.setState({
            target: {
                ...target,
                subsiteId: subsite.id,
                subsiteName: subsite.name,
            },
        });
    };

    render() {
        const { visible, onCancel, appId } = this.props;
        const { text, target, linkTypes: allLinkTypes } = this.state;
        const linkTypes = allLinkTypes.filter(
            (item: any) => item.id !== 'none' && item.id !== 'miniprogram' && item.id !== 'urlLink'
        );
        const linkTypeSelector = newClientCustomSelectorConfig;
        const subsite =
            (target.subsiteId &&
                target.subsiteName && { id: target.subsiteId, name: target.subsiteName }) ||
            undefined;
        const subsiteIds = target.subsiteId ? [String(target.subsiteId)] : undefined;
        return (
            <AntModal
                visible={visible}
                okText={language.getText('common.ok')}
                title={services.language.getText('xcxlj')}
                onOk={this.onOK}
                onCancel={onCancel}
            >
                <div className="wechat-link-modal-form">
                    <div className="item" style={{ marginBottom: 20 }}>
                        {services.language.getText('otherSelectSubsite')}：
                        <WechatOfficialReleatedSubsiteSelector
                            appId={appId}
                            subsite={subsite}
                            onChange={this.onSubsiteChange}
                        />
                    </div>
                    <div className="item">
                        {services.language.getText('ljwz')}：
                        <Input
                            className="item-input"
                            required
                            placeholder={services.language.getText('gzfn')}
                            value={text}
                            onChange={this.onTextChange}
                            maxLength={64}
                        />
                    </div>
                    <div className="item">
                        <div className="item-link">
                            <LinkTypePlus
                                selector={linkTypeSelector as any}
                                linkTypes={linkTypes}
                                linkTypeMode="cascader"
                                value={target ? target : undefined}
                                onChange={(val) => this.changeTargetValue(val)}
                                subsiteIds={subsiteIds}
                            />
                        </div>
                    </div>
                </div>
            </AntModal>
        );
    }
}
