import { forEach } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { getDesignPageApiPath } from '../configs/polyfill';
export const TopicPageManageLoader = {
    get: async function(data: any, config: ApiRequestConfig) {
        if (data && data.type === 'all') {
            delete data.type;
        }
        // 分享有礼会读取列表，新版可视化编辑器需要兼容，但是单独查询不替换
        if (!data.id) {
            config.apiPath = getDesignPageApiPath('topic_pages');
        }
        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.result) {
                forEach(res.result, (i) => {
                    i.canDelete = !i.enabled;
                });
            }
            return res;
        });
    },
    post: function(data: any, config: any) {
        data.type = 'topic';
        return services.api.post(data, config);
    },
};
