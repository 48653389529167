import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const NormalInvoiceTypeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = `/admin/invoice_system_config`;
        return await api.get(data, config).then((res: any) => {
            res.baseInfo = {
                id: res.id,
                authInvoicing: res.authInvoicing + '',
                contents: res.contents,
            };
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const id = params.baseInfo.id;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = `/admin/invoice_system_config/${id}`;
        let newParams: any = {};
        newParams.authInvoicing = params.baseInfo.authInvoicing;
        newParams.contents = params.baseInfo.contents;
        newParams.invoiceTypes = ['NORMAL'];
        return await api.put(newParams, config);
    },
};
