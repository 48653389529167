import React, { Component, Fragment } from 'react';
import { remove, find, get } from 'lodash';
import { Modal, Button, Icon, Popover, Input, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';
import { DESIGN_PAGE_LINK_TYPES_LIST } from '../../applications/design/constants/page-design';
import { customSelectorConfig } from '../../applications/design/design';
import './index.less';

const { LinkType, ColorPicker } = tools;

function getUUID() {
    return services.uuid().slice(0, 6);
}

const HocLinkType = (props: any) => {
    const newProps = { ...props };
    newProps.value = props.value || { linkType: 'none' };
    return (
        <LinkType
            selector={customSelectorConfig}
            linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
            linkTypeMode={'cascader'}
            {...newProps}
        />
    );
};

/**
 * 热区项类型
 */
export interface HotArea {
    id: string;
    x: number;
    y: number;
    w: number;
    h: number;
    zIndex: number;
    text?: string;
    textColor?: string;
    link?: any;
}

interface DrawHotareaState {
    showModal: boolean;
    items: Array<HotArea>;
}

interface DrawHotareaProps {
    img: string;
    items?: any;
    onChange?: (value: any) => void;
}

/**
 * 绘制热区
 */
export class DrawHotarea extends Component<DrawHotareaProps, DrawHotareaState> {
    state = { showModal: false, items: [] };
    dragTargetId: string = '';
    dragging = false;
    scaling = false;
    preX = 0;
    preY = 0;
    maxX = 0;
    maxY = 0;

    componentDidMount() {
        const { items } = this.props;
        if (items) {
            this.setState({ items: items });
        }
    }

    toggleModal = () => {
        const { img } = this.props;
        if (!img) {
            message.info('请先上传图片，才能进行绘制热区');
            return;
        }
        this.setState({ showModal: !this.state.showModal });
    };

    onImgLoad = (e: any) => {
        const img = e.nativeEvent.target;
        this.maxX = img.width;
        this.maxY = img.height;
    };

    startDraw = () => {
        const items: any[] = this.state.items.slice(0);
        const zIndex = items.length + 10;
        items.push({
            id: getUUID(),
            x: 100 + zIndex,
            y: 100 + zIndex,
            w: 100,
            h: 100,
            zIndex: zIndex,
        });
        this.setState({ items: items });
    };

    onSave = () => {
        const { onChange } = this.props;
        const result = {
            relativeWidth: this.maxX,
            relativeHeight: this.maxY,
            items: this.state.items,
        };
        if (onChange) {
            onChange(result);
        }
        this.toggleModal();
    };

    onRemove = (item: HotArea) => () => {
        const items = this.state.items.slice(0);
        remove(items, (i: HotArea) => i.id === item.id);
        this.setState({ items });
    };

    onMouseDown = (e: any) => {
        const target = get(e, 'nativeEvent.target');
        const targetClassName = target.className + '';
        const isScale = !!targetClassName && targetClassName.indexOf('scale') > -1;
        const dragTarget = target.closest('.hot-area-item');
        const isDrag = !!dragTarget && !isScale;
        if (isScale) {
            this.scaling = true;
            this.dragging = false;
            this.dragTargetId = dragTarget.dataset.id;
        }
        if (isDrag) {
            this.dragging = true;
            this.scaling = false;
            this.dragTargetId = dragTarget.dataset.id;
        }
        this.preX = 0;
        this.preY = 0;
    };
    onMouseUp = () => {
        this.onResetMouse();
    };

    onMouseMove = (e: any) => {
        e.preventDefault();
        if (!this.dragging && !this.scaling) {
            return;
        }
        const item: any = find(this.state.items, { id: this.dragTargetId });
        if (!item) {
            return;
        }
        if (this.preX && this.preY) {
            const dx = e.clientX - this.preX;
            const dy = e.clientY - this.preY;
            if (this.scaling) {
                const w = item.w + dx;
                const h = item.h + dy;
                if (w > this.maxX || h > this.maxY) {
                    this.onResetMouse();
                    return;
                }
                item.w = w;
                item.h = h;
            } else {
                const posX = item.x + dx;
                const posY = item.y + dy;
                if (posX < 0 || posY < 0) {
                    this.onResetMouse();
                    return;
                }
                if (posX + item.w > this.maxX || posY + item.h > this.maxY) {
                    this.onResetMouse();
                    return;
                }
                item.x = posX;
                item.y = posY;
            }
            this.forceUpdate();
        }
        this.preX = e.clientX;
        this.preY = e.clientY;
    };
    onResetMouse = () => {
        this.preX = 0;
        this.preY = 0;
        this.scaling = false;
        this.dragging = false;
    };

    changeLinkType = (data: HotArea) => (value: any) => {
        data.link = value;
        this.forceUpdate();
    };

    changeItemValue = (data: any, field: string) => (value: any) => {
        data[field] = value;
        this.forceUpdate();
    };

    renderHotareaSetting = (data: HotArea) => {
        const linkTypeProps = {
            value: data.link,
            onChange: this.changeItemValue(data, 'link'),
        };
        return (
            <div className="hot-area-setting">
                <div className="link-item-field">
                    <label className="field-name">热区名称：</label>
                    <div className="link-type">
                        <Input
                            width={200}
                            value={data.text}
                            onChange={(e) => this.changeItemValue(data, 'text')(e.target.value)}
                        ></Input>
                    </div>
                </div>
                <div className="link-item-field">
                    <label className="link-item-field">名称颜色：</label>
                    <ColorPicker
                        value={data.textColor || '#fff'}
                        onChange={this.changeItemValue(data, 'textColor')}
                    />
                </div>
                <HocLinkType {...linkTypeProps}></HocLinkType>
            </div>
        );
    };

    renderHotarea = (data: HotArea) => {
        return (
            <div
                className={'hot-area-item hot-area-' + data.id}
                data-id={data.id}
                key={data.id}
                style={{
                    width: data.w,
                    height: data.h,
                    top: data.y,
                    left: data.x,
                    zIndex: data.zIndex,
                }}
            >
                <Popover
                    title="热区设置"
                    trigger={'contextMenu'}
                    content={this.renderHotareaSetting(data)}
                    overlayStyle={{ backgroundColor: '#fff', zIndex: 1000 }}
                >
                    <div className="item-tips">
                        <Fragment>
                            <p className="item-tips-setting" style={{ color: data.textColor }}>
                                {data.text ? data.text : '右击设置'}
                            </p>
                        </Fragment>
                    </div>
                </Popover>

                <Icon
                    title="点击删除热区"
                    className="remove"
                    onClick={this.onRemove(data)}
                    type="close"
                />
                <div className="scale" title="按住鼠标拖动，可调整大小"></div>
            </div>
        );
    };

    renderFooter = () => {
        return [
            <div className="hot-are-footer-tips">右击热区，可以设置热区内容</div>,
            <Button key="back" type="primary" onClick={this.startDraw}>
                添加热区
            </Button>,
            <Button key="submit" onClick={this.onSave}>
                保存
            </Button>,
        ];
    };

    render() {
        const { showModal, items } = this.state;
        const { img } = this.props;

        const modalProps = {
            title: '热区编辑器',
            visible: showModal,
            width: 600,
            footer: this.renderFooter(),
            onCancel: this.toggleModal,
        };
        return (
            <Fragment>
                <Button type="primary" onClick={this.toggleModal}>
                    绘制热区
                </Button>
                <Modal {...modalProps}>
                    <div
                        className="hot-area-wrap"
                        onMouseUp={this.onMouseUp}
                        onMouseDown={(e) => this.onMouseDown(e)}
                        onMouseMove={(e) => this.onMouseMove(e)}
                        onMouseLeave={this.onResetMouse}
                    >
                        <img
                            draggable={false}
                            className="hot-area-target-img"
                            alt="热区图片"
                            src={img}
                            onLoad={this.onImgLoad}
                        ></img>
                        {items.map((i) => this.renderHotarea(i))}
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
