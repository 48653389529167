import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        WfjLotteryCodeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/wfjLotteryCode',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.select',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        drawTime: {
                            type: 'string.dateTime',
                            displayName: '<<kjsj>>',
                            rules: [{ required: true, message: '<<qxzkjsj>>' }],
                            controlConfig: {
                                placeholder: '<<kjsj>>',
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                            extra: '<<kjsjjywyhdyjssj>>',
                        },
                        subsiteIds: {
                            type: 'array.options.autoComplete',
                            className: 'custom-array-options',
                            displayName: '<<sxmd_1>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qxzhdznxmdsx>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfxwa50Rule>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxwa>>',
                                maxLength: 50,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        shareAssistContent: {
                            type: 'string',
                            displayName: '<<fxzlwa>>',
                            rules: [
                                {
                                    whitespace: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxzlwa>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    max: 20000,
                                    message: '<<l_notMoreThan2000_2>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<hdbz>>',
                            rules: [
                                {
                                    whitespace: true,
                                    max: 500,
                                    message: '<<remarkMessage>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrhdbz>>',
                                rows: 4,
                                maxLength: 500,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                    },
                },
                numberInfo: {
                    type: 'object.subForm',
                    displayName: '<<cjmsl>>',
                    collapsed: true,
                    properties: {
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<cszscjmsl>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<cszscjmslbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        shareRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<fxhdcjmsx>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<fxhdcjmsxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        shareAssistLimit: {
                            type: 'number.tip',
                            displayName: '<<fxbzlhdcjmsx>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<fxbzlhdcjmsxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                max: 999999,
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        assistLimit: {
                            type: 'number.tip',
                            displayName: '<<whyzlhdcjmsx>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<whyzlhdcjmsxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                max: 999999,
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        pointRule: {
                            type: 'string.options.radio',
                            displayName: '<<jfdhcjm>>',
                            defaultValue: 'NO',
                            options: [
                                {
                                    id: 'NO',
                                    name: '<<bzc>>',
                                },
                                {
                                    id: 'YES',
                                    name: '<<zc_2>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        point: {
                            type: 'number.tip',
                            displayName: '<<mccjxh>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<inputThanZero>>',
                                },
                            ],
                            controlConfig: {
                                style: { width: '120px' },
                                placeholder: '0',
                                addonafter: '积分',
                                tip: '',
                            },
                        },
                        pointExchangeLimit: {
                            type: 'number.tip',
                            displayName: '<<zddh>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<inputThanZero>>',
                                },
                            ],
                            controlConfig: {
                                style: { width: '120px' },
                                placeholder: '0',
                                addonafter: '<<frequency>>',
                                tip: '',
                            },
                        },
                        // refundPoint: {
                        //     type: 'string.options.radio',
                        //     displayName: '未中奖退还积分',
                        //     defaultValue: 'false',
                        //     options: [
                        //         {
                        //             id: 'false',
                        //             name: '不退还',
                        //         },
                        //         {
                        //             id: 'true',
                        //             name: '退还',
                        //         },
                        //     ],
                        // },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<jpmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrjpmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrjpmc>>',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        type: {
                            type: 'object.rewardRules',
                            displayName: '<<jplx>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'LOTTERY_CODE' },
                                baseConfig: {
                                    isConditionsInput: false,
                                    isItmesRadio: true,
                                    isLadder: false,
                                },
                            },
                        },
                        picture: {
                            displayName: '<<jptp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/lottery-code/lottery-code.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pictureLimitExtra>>',
                        },
                        quantity: {
                            type: 'number.integer',
                            displayName: '<<jpsl>>',
                            displayConfig: { addonafter: '<<frequency>>' },
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    message: '<<quantityErrorMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrsl_1>>',
                                style: {
                                    width: 120,
                                },
                            },
                            extra: '<<jpsljwkjsl>>',
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherSet>>',
                    collapsed: true,
                    properties: {
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                drawTime: {
                    type: 'string.date',
                    displayName: '<<kjsj>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                lotteryStatus: {
                    type: 'string',
                    displayName: '<<kjzt>>',
                },
            },
        },
    },
    components: {
        WfjLotteryCodeView: {
            component: 'Viewport',
            entity: 'WfjLotteryCodeEntity',
        },
        WfjLotteryCodePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'WfjLotteryCodeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/wfj-lottery-code/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'WfjLotteryCodeTable' },
            ],
        },
        WfjLotteryCodeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        WfjLotteryCodeTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subSites',
                    width: 200,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                    width: 200,
                },
                {
                    property: 'endTime',
                    width: 200,
                },
                {
                    property: 'drawTime',
                    width: 200,
                },
                {
                    property: 'executeStatus',
                    width: 100,
                    displayConfig: {
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'lotteryStatus',
                    width: 100,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/lottery_code_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/lottery_code_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/wfj-lottery-code/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/wfj-lottery-code/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        text: '<<dcbb>>',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/lottery_code_activities/{{row.id}}/export_jobs',
                        },
                    },
                ],
            },
        },
        WfjLotteryCodeAddPage: {
            component: 'FlexLayout',
            entity: 'WfjLotteryCodeEntity',
            direction: 'horizontal',
            items: [
                { component: 'WfjLotteryCodePreview' },
                { component: 'WfjLotteryCodeAddFormView' },
            ],
        },
        WfjLotteryCodeEditPage: {
            component: 'FlexLayout',
            entity: 'WfjLotteryCodeEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'WfjLotteryCodePreview' },
                { component: 'WfjLotteryCodeEditFormView' },
            ],
        },
        WfjLotteryCodeCopyPage: {
            component: 'FlexLayout',
            entity: 'WfjLotteryCodeEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'WfjLotteryCodePreview' },
                { component: 'WfjLotteryCodeCopyFormView' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wfj-lottery-code',
                    component: 'WfjLotteryCodeView',
                    breadcrumbName: '积分抽奖码',
                    privilege: {
                        path: 'wfjLotteryCode',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'WfjLotteryCodeAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'WfjLotteryCodeEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'WfjLotteryCodeCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        { path: '/', component: 'WfjLotteryCodePage' },
                    ],
                },
            ],
        },
    ],
};
