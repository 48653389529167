import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface WfjLotteryCodeAddFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    pointRule: string;
}

class wfjLotteryCodeAddFormView extends PureComponent<WfjLotteryCodeAddFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let { pointRule } = this.props;
        if (!pointRule) {
            pointRule = 'NO';
        }

        let fields = [
            { property: 'baseInfo.name' },
            { property: 'baseInfo.dateRange' },
            { property: 'baseInfo.drawTime' },
            { property: 'baseInfo.subsiteIds' },
            { property: 'baseInfo.shareContent' },
            { property: 'baseInfo.shareAssistContent' },
            { property: 'baseInfo.description' },
            { property: 'baseInfo.remark' },
            { property: 'numberInfo.activityRewardLimit' },
            { property: 'numberInfo.shareRewardLimit' },
            { property: 'numberInfo.shareAssistLimit' },
            { property: 'numberInfo.assistLimit' },
            { property: 'numberInfo.pointRule' },
            {
                property: 'numberInfo.point',
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.pointExchangeLimit',
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            { property: 'prizeInfo.name' },
            { property: 'prizeInfo.type' },
            { property: 'prizeInfo.picture' },
            { property: 'prizeInfo.quantity' },
            { property: 'otherInfo.marketingSchedule' },
        ];

        let formProps = {
            entity,
            componentId: 'WfjLotteryCodeAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'wfj-lottery-code-add-form-container',
            direction: 'horizontal',
            labelCol: 8,
            controlCol: 16,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    let pointRule = get(
        _dispatch,
        'components.WfjLotteryCodeAddFormContainer.fields.numberInfo.pointRule'
    );

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        // const activityRewardLimit = get(entityFields, 'baseInfo.activityRewardLimit', null);
        // const shareRewardLimit = get(entityFields, 'baseInfo.shareRewardLimit', null);
        // if (activityRewardLimit === 0 && shareRewardLimit === 0) {
        //     AntMessage.warning(
        //         '初始赠送抽奖码数量与分享获得抽奖码上限不能同时设置为0，请检查后重新提交'
        //     );
        //     return false;
        // }
        if (pointRule === 'YES') {
            const point = get(entityFields, 'numberInfo.point', null);
            const pointExchangeLimit = get(entityFields, 'numberInfo.pointExchangeLimit', null);
            const reg = /^([1-9]\d*)$/;
            if (!point || point === '0' || !reg.test(point)) {
                AntMessage.warning('请正确填写每次抽奖消耗积分');
                return false;
            }
            if (
                !pointExchangeLimit ||
                pointExchangeLimit === '0' ||
                !reg.test(pointExchangeLimit)
            ) {
                AntMessage.warning('请正确填写最多兑换次数');
                return false;
            }
        }

        if (
            get(entityFields, 'prizeInfo.type[0].checked') === 'COUPON' &&
            !get(entityFields, 'prizeInfo.type[0].assetRewards[0].rewardValue.length')
        ) {
            AntMessage.warning('请选择优惠券奖品');
            return false;
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            pointRule,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            },
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('WfjLotteryCodeAddFormContainer'));
            },
        }
    );
}
export const WfjLotteryCodeAddFormView = connect(mapDispatchToProps)(wfjLotteryCodeAddFormView);
