import React, { PureComponent, createElement } from 'react';
import { Modal as AntModal } from 'antd';
import { ComponentsManager } from '@comall-backend-builder/core';
/*
 *  DELETE(0,"删除"),
 *  ENABLE(1,"启用"),
 *  DISABLE(2,"禁用"),
 *  INVALID(3,"失效");
 */

export class TimeLimitActivityListStatusBotton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;

        let buttonActionProps = {
            ...this.props,
            renderComponent: 'Button',
            statusKey: 'status',
            type: 'link',
            style: {
                display: 'inline-flex',
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/timelimit/:id/status',
            handles: [
                {
                    params: {
                        status: 'DISABLED',
                    },
                    value: 1,
                    config: {
                        content: '{{row.name}}已被关闭',
                        text: '关闭',
                        style: {
                            color: '#f5222d',
                        },
                    },
                    confirm: {
                        text: '是否关闭{{row.name}}?',
                    },
                },
                {
                    params: {
                        status: 'ENABLED',
                    },
                    value: 2,
                    config: {
                        onConfirmSuccess: (result: any) => {
                            AntModal.info({
                                content: (
                                    <div>
                                        <span>活动已开启，货品数据结果可前往</span>
                                        <a href="/#/report-download-center" target="_blank">
                                            数据-导入/导出记录
                                        </a>
                                        <span>中查看</span>
                                    </div>
                                ),
                                onOk: () => {
                                    AntModal.destroyAll();
                                },
                            });
                        },
                        text: '开启',
                    },
                    confirm: {
                        text: '是否开启{{row.name}}?',
                    },
                },
            ],
        };
        return row.status === 3
            ? createElement(ComponentsManager.get('Button'), {
                  text: '关闭',
                  type: 'link',
                  disabled: true,
                  style: {
                      marginRight: 10,
                  },
              })
            : createElement(ComponentsManager.get('ChangeStatusButton'), buttonActionProps);
    }
}
