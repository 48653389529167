import React from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { cloneDeep, get } from 'lodash';
import './index.less';
import { ConditionsItem } from '../rule';
import ConditionItem, { ConditionType } from '../../maotai-presale-conditions/conditonItem';
import { MaotaiPresaleConditionsDefaultValue } from '../../maotai-presale-conditions';
import { services } from '@comall-backend-builder/core';

interface UserConditionModalProps {
    visible: boolean;
    onOk: (val: any) => void;
    onClose: () => void;
    conditionRules: ConditionsItem[][];
}

const MaotaiPresaleConditionsTypeList = [
    { conditionType: ConditionType.REALNAME, endText: '' },
    { conditionType: ConditionType.AGE, endText: '' },
    { conditionType: ConditionType.LEVEL, endText: '' },
    { conditionType: ConditionType.REGISTRATION_TIME, endText: '' },
    { conditionType: ConditionType.TOTAL_POINT, endText: '' },
    { conditionType: ConditionType.SUPERMARKET_POINT, endText: '' },
    { conditionType: ConditionType.STORE_POINT, endText: '' },
    { conditionType: ConditionType.CONSUME_AMOUNT, endText: '' },
    { conditionType: ConditionType.TRANSACTION_QUANTITY, endText: '' },
    { conditionType: ConditionType.UNIT_PRICE, endText: '' },
    { conditionType: ConditionType.REGION, endText: '' },
];

export default class UserConditionModal extends React.Component<UserConditionModalProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            conditionRules: props.conditionRules || [],
            conditionModalVisible: false,
            willAddConditions: MaotaiPresaleConditionsDefaultValue,
        };
    }

    componentWillReceiveProps(props: UserConditionModalProps) {
        this.setState({
            conditionRules: props.conditionRules,
        });
    }
    handleOk = () => {
        const { onOk } = this.props;
        onOk(this.state.conditionRules);
    };
    handleCancel = () => {
        const { onClose } = this.props;
        onClose();
    };
    onAddRule = (index: number) => {
        const { conditionRules } = this.state;
        let list: any = cloneDeep(conditionRules);
        list.splice(index + 1, 0, [] as never);
        this.setState({
            conditionRules: list,
        });
    };
    onDeleteRule = (index: number) => {
        const { conditionRules } = this.state;

        this.setState({
            conditionRules: conditionRules.filter(
                (_: any, itemIndex: number) => itemIndex !== index
            ),
        });
    };

    editRuleIndex: number = 0;
    onAddConditionToRule = (index: number) => {
        this.editRuleIndex = index;
        this.setState({ willAddConditions: MaotaiPresaleConditionsDefaultValue }, () => {
            this.setState({
                conditionModalVisible: true,
            });
        });
    };
    handleConditionOk = () => {
        const { conditionRules, willAddConditions } = this.state;

        let ruleList: any = cloneDeep(conditionRules);
        let list = willAddConditions.filter((item: any) => item.checked);

        if (ruleList[this.editRuleIndex] && ruleList[this.editRuleIndex].length) {
            if (ruleList[this.editRuleIndex].find((i: any) => i.conditionType === 'REALNAME')) {
                list = list.filter((item: any) => item.conditionType !== 'REALNAME');
            }
            ruleList[this.editRuleIndex] = [...ruleList[this.editRuleIndex], ...list];
        } else {
            ruleList[this.editRuleIndex] = [...list];
        }

        this.setState({
            conditionRules: ruleList,
        });
        this.handleConditionCancel();
    };
    handleConditionCancel = () => {
        this.setState({ willAddConditions: MaotaiPresaleConditionsDefaultValue }, () => {
            this.setState({
                conditionModalVisible: false,
            });
        });
    };
    renderConditions = () => {
        const { willAddConditions } = this.state;
        return MaotaiPresaleConditionsTypeList.map((i, index) => {
            const checked: any = !!get(
                willAddConditions.find((item: any) => item.conditionType === i.conditionType),
                'checked'
            );
            return (
                <div key={i.conditionType} className="maotai-presale-conditions-row">
                    <Checkbox
                        checked={checked}
                        onChange={this.onCheckChange}
                        value={i.conditionType}
                    ></Checkbox>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <ConditionItem
                        {...this.props}
                        endText={i.endText}
                        conditionType={i.conditionType}
                        value={willAddConditions}
                        onChange={this.conditionModalChange}
                        index={index}
                    ></ConditionItem>
                </div>
            );
        });
    };
    onCheckChange = (e: any) => {
        const { checked, value: conditionType } = e.target;
        const { willAddConditions } = this.state;
        const item = willAddConditions.find((i: any) => i.conditionType === conditionType);
        if (!item) {
            this.setState({
                willAddConditions: [...willAddConditions, { conditionType, checked }],
            });
        } else {
            const list = willAddConditions.map((item: any) => {
                if (item.conditionType === conditionType) {
                    return { ...item, checked };
                }
                return item;
            });
            this.setState({
                willAddConditions: list,
            });
        }
    };
    conditionModalChange = (val: any) => {
        this.setState({
            willAddConditions: val,
        });
    };

    ruleModalChange = (val: any, index: number) => {
        const { conditionRules } = this.state;

        let ruleList: any = cloneDeep(conditionRules);
        ruleList[index] = val;
        this.setState({
            conditionRules: ruleList,
        });
    };

    onDeleteCondition = (conditionIndex: number, ruleIndex: number) => {
        const { conditionRules } = this.state;

        let ruleList: any = cloneDeep(conditionRules);
        ruleList[ruleIndex] = ruleList[ruleIndex].filter(
            (_: any, index: number) => conditionIndex !== index
        );
        this.setState({
            conditionRules: ruleList,
        });
    };

    render() {
        const { conditionRules, conditionModalVisible } = this.state;

        return (
            <div>
                <Modal
                    title="选择人员条件"
                    visible={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    closable={false}
                    width={900}
                >
                    <div className="user-condition-modal-tips">
                        若配置多个规则，则用户需要满足多个规则才能进入该条件的人员名单中
                    </div>
                    <div className="user-condition-modal-rules">
                        {conditionRules.map((i: ConditionsItem[], index: number) => {
                            return (
                                <div key={index} className="user-condition-modal-rules-item">
                                    <span className="user-condition-modal-rules-index">
                                        规则{index + 1}：
                                    </span>
                                    <div className="user-condition-modal-rules-content">
                                        <div className="user-condition-modal-rules-conditions">
                                            {i.map((condition: any, conditionIndex: number) => {
                                                return (
                                                    <div
                                                        key={condition.conditionType}
                                                        className="user-condition-modal-rules-conditions-item"
                                                    >
                                                        <span style={{ marginRight: 10 }}>
                                                            {conditionIndex !== 0 && '或  '}
                                                        </span>
                                                        <ConditionItem
                                                            {...this.props}
                                                            index={conditionIndex}
                                                            endText=""
                                                            value={i}
                                                            conditionType={condition.conditionType}
                                                            onChange={(val) => {
                                                                this.ruleModalChange(val, index);
                                                            }}
                                                        ></ConditionItem>
                                                        <Button
                                                            type="link"
                                                            onClick={() =>
                                                                this.onDeleteCondition(
                                                                    conditionIndex,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            {services.language.getText(
                                                                'common.delete'
                                                            )}
                                                        </Button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="user-condition-modal-rules-buttons">
                                            <Button
                                                className="user-condition-modal-rules-button"
                                                onClick={() => this.onAddRule(index)}
                                            >
                                                添加规则
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                onClick={() => this.onAddConditionToRule(index)}
                                                className="user-condition-modal-rules-button"
                                            >
                                                添加条件
                                            </Button>
                                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                            {conditionRules.length > 1 && (
                                                <Button
                                                    type="link"
                                                    onClick={() => this.onDeleteRule(index)}
                                                >
                                                    删除规则
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal>
                <Modal
                    title="添加条件"
                    visible={conditionModalVisible}
                    onOk={this.handleConditionOk}
                    onCancel={this.handleConditionCancel}
                    closable={false}
                    width={900}
                >
                    <div>{this.renderConditions()}</div>
                </Modal>
            </div>
        );
    }
}
