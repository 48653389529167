import React, { PureComponent } from 'react';
import { Popover as AntPopover, Button as AntButton, Spin } from 'antd';
import { services } from '@comall-backend-builder/core';
import './index.less';

function base64ToBlob(code: string) {
    let parts = code.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

function downloadFile(fileName: string, content: string) {
    let aLink = document.createElement('a');
    let blob = base64ToBlob(content);
    let evt = document.createEvent('HTMLEvents');
    //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    evt.initEvent('click', true, true);
    aLink.download = fileName;
    aLink.href = URL.createObjectURL(blob);
    aLink.click();
}

export class MiniprogramPreviewButton extends PureComponent<any, any> {
    state = {
        url: '',
        loading: true,
    };

    downLoad = (urlBase64: string, name: string) => {
        downloadFile(`${name}-${services.language.getText('xcxm')}.jpg`, urlBase64);
    };
    onVisibleChange = async (visible: boolean) => {
        if (visible) {
            const {
                params: { applicationVersion },
            } = this.props;
            const { id } = this.props.row;
            this.setState({ loading: true });

            const params = {
                page:
                    applicationVersion === 'V2'
                        ? 'subpackages/miniprogram-code/index'
                        : 'sub-packages/miniprogram-code/pages/enter/index',
                // page: 'pages/home/index',
                type: 'MINI_PROGRAME_CODE',
                scene: `mid=${id}`,
            };
            const apiRoot = `${ENV.API_ROOT}`;
            let res: any = await services.api.get(params, {
                apiRoot,
                apiPath: `/wechat/jhcode`,
            });
            this.setState({ url: 'data:image/jpg;base64,' + res.image, loading: false });
        }
    };
    renderContent = () => {
        let { url, loading } = this.state;
        const { name } = this.props.row;
        return loading ? (
            <div className="mini-preview-content">
                <Spin />
            </div>
        ) : (
            <div className="mini-preview-content">
                <img className="qr-code" src={url} alt={services.language.getText('wxCode')} />
                <p className="info">
                    <AntButton
                        type="link"
                        style={{ padding: 0 }}
                        className="span-link"
                        onClick={() => {
                            this.downLoad(url, name);
                        }}
                    >
                        {services.language.getText('djxz')}
                    </AntButton>
                    {services.language.getText('xcxm')}
                </p>
            </div>
        );
    };
    render() {
        const {
            params: { applicationVersion },
        } = this.props;
        return (
            <AntPopover
                trigger="click"
                onVisibleChange={this.onVisibleChange}
                content={this.renderContent()}
            >
                <AntButton
                    type="link"
                    className="table-action-column-item span-link"
                    style={{ padding: 0 }}
                >
                    {applicationVersion === 'V2'
                        ? services.language.getText('xcxm')
                        : services.language.getText('ckewm')}
                </AntButton>
            </AntPopover>
        );
    }
}
