import React, { PureComponent, FC } from 'react';
import { isFunction } from 'lodash';
import { Input, Popover, Table, InputNumber, Button, Icon } from 'antd';
import {
    MallActivityTicket,
    MallActivityTicketPrice,
    NewMallActivityMemberCardLevel,
    MallActivityTicketPromotionPrice,
    ChangeTicket,
    ShowTicketFiledChangeModal,
} from '../types';
import { TableProps, ColumnProps, TableSize } from 'antd/lib/table';
import { isEqualId } from '../common';
import { NewMallActivityTicketPricesTable } from '../new-mall-activity-ticket-price/new-mall-activity-ticket-prices-table';
import { services } from '@comall-backend-builder/core';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityTicketsTableProps {
    /**
     * 内容改变回调
     */
    changeTicket?: ChangeTicket;
    showModal?: ShowTicketFiledChangeModal;
    /**
     * 当前值
     */
    value: MallActivityTicket[];
    disabled?: boolean;
    cardLevels: NewMallActivityMemberCardLevel[];
    actionColum?: ColumnProps<MallActivityTicket>;
    onSelectedRowKeysChange?: (selectedRowKeys: string[]) => void;
    size?: TableSize;
    showSalesQuantity?: boolean;
}

interface NewMallActivityTicketsTableState {
    showAll: boolean;
}

/**
 * 新商场活动票档表格
 */
class NewMallActivityTicketsTable extends PureComponent<
    NewMallActivityTicketsTableProps,
    NewMallActivityTicketsTableState
> {
    constructor(props: NewMallActivityTicketsTableProps) {
        super(props);
        const { value = [] } = this.props;
        this.state = {
            showAll: value.length <= 3,
        };
    }
    changeTicket: ChangeTicket = (field, fieldValue, index) => {
        const { changeTicket } = this.props;
        if (!isFunction(changeTicket)) {
            return;
        }
        changeTicket(field, fieldValue, index);
    };

    private get disabled() {
        const { disabled } = this.props;
        return disabled;
    }

    renderName = (name: string, record: MallActivityTicket, index: number) => {
        return (
            <Input
                style={{ width: 125 }}
                placeholder={services.language.getText('inputPlease')}
                value={name}
                maxLength={20}
                onChange={(e) => this.changeTicket('name', e.target.value, index)}
            />
        );
    };

    getPriceText = (pointPrice: number | undefined, salePrice: number | undefined) => {
        return Number(pointPrice) || Number(salePrice)
            ? `${Number(pointPrice) || 0}${language.getText('point')}+${Number(salePrice) ||
                  0}${language.getText('yuan')}`
            : language.getText('mf_1');
    };

    getLevelPriceText = (price: MallActivityTicketPrice) => {
        const { pointPrice, salePrice, memberCardLevelId: levelId } = price;
        const { cardLevels } = this.props;
        const levelName =
            Number(levelId) === -1
                ? ''
                : `${cardLevels.find((i) => isEqualId(i.id, levelId))?.name}：`;
        return `${levelName}${this.getPriceText(pointPrice, salePrice)}`;
    };

    renderTicketPrices = (
        ticketPrices: MallActivityTicketPrice[] = [],
        record: MallActivityTicket,
        index: number
    ) => {
        const { cardLevels, showModal } = this.props;
        const onClick = () => showModal?.('ticketPrices', record);
        if (!ticketPrices?.length) {
            return (
                <Button type="link" onClick={onClick}>
                    {language.getText('szjg')}
                </Button>
            );
        }
        const prices = ticketPrices
            .slice(0, 1)
            .map((price) => <div>{this.getLevelPriceText(price)}</div>);
        const button = showModal ? (
            <Button type="link" onClick={onClick}>
                {language.getText('common.modify')}
            </Button>
        ) : null;
        if (ticketPrices.length <= 1) {
            return (
                <div className="new-mall-activity__flex">
                    <div>{prices}</div>
                    {button}
                </div>
            );
        }
        const ticketsTable = (
            <div className="new-mall-activity__tickets-table__price-popover">
                <NewMallActivityTicketPricesTable
                    disabled
                    value={ticketPrices}
                    cardLevels={cardLevels}
                    size="small"
                />
            </div>
        );
        return (
            <div className="new-mall-activity__flex">
                <div>
                    {prices}
                    <Popover content={ticketsTable}>
                        <Button type="link">
                            {services.interpolate(services.language.getText('jdsgdjjg'), {
                                num: ticketPrices.length,
                            })}
                        </Button>
                    </Popover>
                </div>
                {button}
            </div>
        );
    };
    renderTicketPromotionPrice = (
        ticketPromotionPrice: MallActivityTicketPromotionPrice | undefined,
        record: MallActivityTicket,
        index: number
    ) => {
        const { showModal } = this.props;
        const showSettingModal = () => showModal?.('ticketPromotionPrice', record);
        const onClear = () => this.changeTicket('ticketPromotionPrice', undefined, index);
        if (!ticketPromotionPrice) {
            return (
                <Button type="link" onClick={showSettingModal}>
                    {language.getText('szjg')}
                </Button>
            );
        }
        const button = showModal ? (
            <div className="new-mall-activity__batch-modal__flex-column">
                <Button type="link" onClick={showSettingModal}>
                    {language.getText('common.modify')}
                </Button>
                <Button type="link" onClick={onClear}>
                    {language.getText('common.clear')}
                </Button>
            </div>
        ) : null;
        const { salePrice, pointPrice, startTime, endTime } = ticketPromotionPrice;
        const priceText = this.getPriceText(pointPrice, salePrice);
        const content = (
            <div style={{ textAlign: 'right' }}>
                <div>{priceText}</div>
                <div>{startTime}</div>
                <div>~{endTime}</div>
            </div>
        );
        return (
            <div className="new-mall-activity__flex">
                {content}
                {button}
            </div>
        );
    };

    renderTotalStock = (value: number, record: MallActivityTicket, index: number) => {
        const min = record.soldQuantity || 0;
        return (
            <InputNumber
                value={value}
                min={min}
                max={999999}
                precision={0}
                onChange={(num) => this.changeTicket('totalStock', num, index)}
            ></InputNumber>
        );
    };

    renderRequiredTitle = (title: string, tips?: string) => {
        return (
            <div>
                <span style={{ color: 'red' }}>*</span>
                <span>{title}&nbsp;</span>
                {Boolean(tips) ? (
                    <Popover content={tips}>
                        <Icon type="question-circle-o" />
                    </Popover>
                ) : null}
            </div>
        );
    };

    getTableConfig = () => {
        const {
            actionColum,
            onSelectedRowKeysChange,
            size = 'default',
            showSalesQuantity,
        } = this.props;
        const canSelected = isFunction(onSelectedRowKeysChange);
        let config: TableProps<MallActivityTicket> = {
            rowKey: 'rowKey',
            columns: [
                {
                    title: <TableColumnTitle title={language.getText('mpmc')} />,
                    dataIndex: 'name',
                    width: 160,
                    render: this.renderName,
                },
                {
                    title: <TableColumnTitle title={language.getText('sj_1')} required />,
                    dataIndex: 'ticketPrices',
                    width: 240,
                    render: this.renderTicketPrices,
                },
                {
                    title: (
                        <TableColumnTitle
                            title={language.getText('hdj')}
                            tips={language.getText('jszhdjhxtjzxhdj')}
                        />
                    ),
                    dataIndex: 'ticketPromotionPrice',
                    width: 260,
                    render: this.renderTicketPromotionPrice,
                },
                {
                    title: (
                        <TableColumnTitle
                            title={language.getText('number')}
                            required
                            tips={showSalesQuantity ? language.getText('slbkdyybm') : undefined}
                        />
                    ),
                    dataIndex: 'totalStock',
                    width: 100,
                    render: this.renderTotalStock,
                },
            ],
            scroll: {
                x: 400,
                y: 600,
            },
            style: {
                maxWidth: 900,
            },
            size,
            rowSelection: canSelected
                ? {
                      onChange: (selectedRowKeys) => {
                          onSelectedRowKeysChange?.(selectedRowKeys as string[]);
                      },
                      columnWidth: 40,
                      //   fixed: true,
                      // getCheckboxProps: (record) => {
                      //     if (record.id) {
                      //         return { disabled: true };
                      //     }
                      //     return {};
                      // },
                  }
                : undefined,
        };
        if (showSalesQuantity) {
            config.columns?.push({
                title: <TableColumnTitle title={language.getText('yssl')} />,
                dataIndex: 'soldQuantity',
                width: 100,
                render: (value?: number) => value || 0,
            });
        }
        if (actionColum) {
            config.columns?.push(actionColum);
        }
        config.columns?.forEach((item) => {
            item.align = 'center';
            if (!item.key) {
                item.key = item.dataIndex;
            }
        });
        return config;
    };

    toggleShowAll = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll });
    };

    renderFooter = () => {
        const { value = [] } = this.props;
        if (value.length < 4) {
            return null;
        }
        const { showAll } = this.state;
        const rotate = showAll ? 90 : 270;
        return (
            <div onClick={this.toggleShowAll} className="new-mall-activity__tickets-table__footer">
                <Icon
                    type="double-left"
                    rotate={rotate}
                    style={{ fontSize: 24, color: '#1890ff', cursor: 'pointer' }}
                />
            </div>
        );
    };

    render() {
        const { value = [] } = this.props;
        const { showAll } = this.state;
        const dataSource = showAll ? value : value.slice(0, 3);
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource };
        if (value.length < 4) {
            return <Table {...tableProps} pagination={false}></Table>;
        }
        return <Table footer={this.renderFooter} {...tableProps} pagination={false}></Table>;
    }
}

interface TableColumnTitleProps {
    title: string;
    required?: boolean;
    tips?: string;
}

const TableColumnTitle: FC<TableColumnTitleProps> = ({ title, required, tips }) => {
    return (
        <div>
            {required ? <span style={{ color: 'red' }}>*</span> : null}
            <span>{title}&nbsp;</span>
            {Boolean(tips) ? (
                <Popover content={tips}>
                    <Icon type="question-circle-o" />
                </Popover>
            ) : null}
        </div>
    );
};

export { NewMallActivityTicketsTable };
