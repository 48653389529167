import React, { PureComponent } from 'react';
import { cloneDeep, findIndex, map } from 'lodash';
import { StoredValueCardProductSelector } from './stored-value-card-product-selector';
import { StoreValueCardProductDisplay } from './stored-value-card-product-display';

interface StoredValueCardStyle {
    /**
     * 面值
     */
    amount: number;
    /**
     * 活动价
     */
    price: number;
    /**
     * 售卖价
     */
    salesPrice: number;
    /**
     * 售卖的储值卡款式id
     */
    styleId: number;
    /**
     * 上否上下架
     */
    status: boolean;
}
export interface StoredValueCardProduct {
    effectiveEndTime: string;
    effectiveStartTime: string;
    /**
     * 使用说明
     */
    info: string;
    /**
     * 名称
     */
    name: string;
    /**
     * 储值卡商品id
     */
    id: number;
    productId: number;
    /**
     * 规则
     */
    styles: Array<StoredValueCardStyle>;
    /**
     * 购买提示
     */
    tip: string;
    /**
     * 卡类别类型
     */
    type: string;
    /**
     * 是否隐藏展示
     */
    isHide: boolean;
    /**
     * 总限量
     */
    totalAmountLimit: number;
    /**
     * 个人限量
     */
    memberAmountLimit: number;
    /**
     * 排序
     */
    sequence: number;
}

interface Props {
    value: StoredValueCardProduct[];
    onChange: (v: StoredValueCardProduct[]) => void;
    row: any;
}

export class StoredValueCardSetting extends PureComponent<Props> {
    getSubsiteIds = (props: any) => {
        const { row } = props;
        let subsites = row.subsiteIds;
        if (row.baseInfo && row.baseInfo.subsites) {
            subsites = row.baseInfo.subsiteIds;
        }
        const subsiteIds = subsites ? map(subsites, 'id') : null;
        return subsiteIds;
    };
    componentWillReceiveProps(nextProps: any) {
        const { onChange } = nextProps;
        let nextSubsiteIds: any = this.getSubsiteIds(nextProps);
        let currentSubsiteIds: any = this.getSubsiteIds(this.props);
        if (nextSubsiteIds && currentSubsiteIds && nextSubsiteIds !== currentSubsiteIds) {
            onChange([]);
        }
    }

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        onChange(cloneDeep(goods));
    };

    onSelect = (values: any[]) => {
        const { value } = this.props;
        const newData = cloneDeep(value || []);
        values.forEach((i: any) => {
            const hasIndex = findIndex(value, (o: any) => o.id === i.id);
            if (hasIndex === -1) {
                i.info = i.details;
                i.productId = i.id;
                i.styles = i.amountDefinitions.map((amountDefinition: any) => {
                    return {
                        ...amountDefinition,
                        salesPrice: amountDefinition.price,
                        status: true,
                    };
                });
                i.isHide = false;
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    render() {
        const { value } = this.props;
        return (
            <div className="select-product-goods">
                <StoredValueCardProductSelector
                    onChange={this.onSelect}
                    selectValues={cloneDeep(value)}
                />
                {value && value.length > 0 && (
                    <StoreValueCardProductDisplay data={value} onChange={this.onChange} />
                )}
            </div>
        );
    }
}
