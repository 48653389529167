import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { customSelectorConfig } from '../../applications/design/design';

enum HotWordStatus {
    /**
     * 启用
     */
    ENABLED = 'ENABLED',
    /**
     * 禁用
     */
    DISABLED = 'DISABLED',
}

/** linkParams 不能为空 */
const noLinkParams = ['topicContent', 'product'];

export const config: Config = {
    entities: {
        HotWordsSearchEntity: {
            apiPath: '/loader/admin/hot_word',
            filters: {
                word: {
                    type: 'string',
                    displayName: '<<keywords>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'ENABLED', name: '<<enabled>>' },
                        { id: 'DISABLED', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                word: {
                    type: 'string',
                    displayName: '<<keywords>>',
                    rules: [{ required: true, whitespace: true, message: '<<inputKeywords>>' }],
                    controlConfig: {
                        placeholder: '<<keywordsRequired>>',
                        maxLength: 20,
                        style: {
                            width: 450,
                        },
                    },
                },
                subsite: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                pageName: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
                plate: {
                    type: 'string',
                    displayName: '<<plate>>',
                },
                sequence: {
                    type: 'number',
                    displayName: '<<sequence>>',
                    controlConfig: {
                        placeholder: '<<sortTip2>>',
                        style: {
                            width: 200,
                        },
                    },
                    rules: [{ required: true }],
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<selectPlate>>',
                    defaultValue: 'SHOW',
                    options: [
                        { id: 'SHOW', name: '<<contPlate>>' },
                        { id: 'SHOP', name: '<<commePlate>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                    rules: [{ required: true }],
                },
                selectedSubsites: {
                    type: 'array.options.autoComplete',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                    rules: [{ required: true }],
                },
                selectMerchantGuide: {
                    type: 'string.options.select',
                    displayName: '<<selectPage>>',
                    options: [],
                    source: {
                        apiPath: '/admin/merchants_guide/list',
                    },
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                    rules: [{ required: true }],
                },
                config: {
                    type: 'object.linkType',
                    displayName: '<<linkConfig>>',
                    defaultValue: { linkType: 'none', linkParams: {} },
                },
            },
        },
    },
    components: {
        HotWordsSearchView: {
            component: 'Viewport',
            entity: 'HotWordsSearchEntity',
        },
        HotWordsSearchPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'HotWordsSearchFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addKeywords>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/hot-words-search/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'HotWordsSearchTable' },
            ],
        },
        HotWordsSearchFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'word',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        HotWordsSearchTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                page: 1,
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'word',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '400px',
                        },
                    },
                },
                {
                    property: 'subsite',
                    width: 200,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'pageName',
                },
                {
                    property: 'plate',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        statusConfig: {
                            ENABLED: {
                                text: '<<enabled>>',
                                status: 'success',
                            },
                            DISABLED: {
                                text: '<<beforeClose>>',
                                status: 'error',
                            },
                            DELETED: {
                                text: '<<afterDelete>>',
                                status: 'error',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            handles: [
                                {
                                    value: HotWordStatus.ENABLED,
                                    apiPath: '/admin/hot_word/:id/disable',
                                    config: {
                                        content: '{{row.word}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseKeywords>>{{row.word}}?',
                                    },
                                },
                                {
                                    value: HotWordStatus.DISABLED,
                                    apiPath: '/admin/hot_word/:id/enable',
                                    config: {
                                        content: '{{row.word}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<keywords>>{{row.word}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/hot-words-search/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        HotWordsSearchAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'HotWordsSearchEntity',
            items: [{ component: 'HotWordsSearchAddFormView' }],
        },
        HotWordsSearchAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'word' },
                { property: 'type' },
                { property: 'selectedSubsites' },
                { property: 'selectMerchantGuide' },
                { property: 'sequence' },
                {
                    property: 'config',
                    className: 'link-type-property',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const linkType = get(entity, 'config.linkType');
                const linkParams = get(entity, 'config.linkParams');
                const configKeys = Object.keys(customSelectorConfig) || [];
                let flag = true;

                map([...configKeys, ...noLinkParams], (item) => {
                    if (linkType === item && isEmpty(linkParams)) {
                        message.error(services.language.getText('selectLinkTarget'));
                        flag = false;
                    }
                });

                return flag;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('keywordsSuccess'), () => {
                    services.behaviorHandle({ route: 'goBack' });
                });
            },
        },
        HotWordsSearchEditPage: {
            component: 'FlexLayout',
            entity: 'HotWordsSearchEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'HotWordsSearchEditFormView' }],
        },
        HotWordsSearchEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'word' },
                { property: 'type' },
                { property: 'selectedSubsites' },
                { property: 'selectMerchantGuide' },
                { property: 'sequence' },
                {
                    property: 'config',
                    className: 'link-type-property',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const linkType = get(entity, 'config.linkType');
                const linkParams = get(entity, 'config.linkParams');
                const configKeys = Object.keys(customSelectorConfig) || [];
                let flag = true;

                map([...configKeys, ...noLinkParams], (item) => {
                    if (linkType === item && isEmpty(linkParams)) {
                        message.error(services.language.getText('selectLinkTarget'));
                        flag = false;
                    }
                });

                return flag;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () => {
                    services.behaviorHandle({ route: 'goBack' });
                });
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/hot-words-search',
                    component: 'HotWordsSearchView',
                    breadcrumbName: '<<components.SearchBar.keywords>>',
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'HotWordsSearchAddPage',
                            breadcrumbName: '<<addKeywords>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'HotWordsSearchEditPage',
                            breadcrumbName: '<<editKeywords>>',
                        },
                        { path: '/', component: 'HotWordsSearchPage' },
                    ],
                    privilege: {
                        path: 'hotWord',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
