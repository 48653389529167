import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        CouponOrderEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponOrder',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                subsites: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                orderStatus: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'CREATE', name: '<<dfk>>' },
                        { id: 'PAYMENT', name: '<<dsy>>' },
                        { id: 'RECEIVE', name: '<<ywc>>' },
                        { id: 'CANCEL', name: '<<beforeClose>>' },
                    ],
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<yhqptdd>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponBaCode>>',
                },
                mergePaymentNo: {
                    type: 'string',
                    displayName: '<<ztlsh>>',
                },
                tradeNo: {
                    type: 'string',
                    displayName: '<<jylsh>>',
                },
                paymentType: {
                    type: 'string.options.select',
                    displayName: '<<zffs>>',
                    options: [
                        { id: 'pay', name: '<<ptzf>>' },
                        { id: 'mergePayment', name: '<<hbzf>>' },
                    ],
                },
                orderOrigin: {
                    type: 'string.options.select',
                    displayName: '<<orderChannel>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                        { id: 'DOUYIN', name: '<<douyinMiniP>>' },
                    ],
                },
                payTimeRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<fkcgsj>>',
                    startField: 'paidStartTime',
                    endField: 'paidEndTime',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                goodsName: {
                    type: 'string.popover',
                    displayName: '<<goodName>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<number>>',
                },
                couponName: {
                    type: 'array.stringLineFeed',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'array.couponNoPopover',
                    displayName: '<<couponBaCode>>',
                },
                orderStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<ddzt>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<xjzje>>',
                },
                pointAmount: {
                    type: 'string',
                    displayName: '<<jfze>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'MALL_ACTIVITY', name: '<<mallActivity>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<yhqptdd>>' },
                    ],
                },

                mergePaymentNo: {
                    type: 'string',
                    displayName: '<<ztlsh>>',
                },
                tradeNo: {
                    type: 'string',
                    displayName: '<<jylsh>>',
                },
                upstreamOrderId: {
                    type: 'string',
                    displayName: '<<upstreamOrderId>>',
                },
                paymentType: {
                    type: 'string.options.select',
                    displayName: '<<zffs>>',
                    options: [
                        { id: 'pay', name: '<<ptzf>>' },
                        { id: 'mergePayment', name: '<<hbzf>>' },
                    ],
                },
                orderOrigin: {
                    type: 'string.options.select',
                    displayName: '<<orderChannel>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                        { id: 'DOUYIN', name: '<<douyinMiniP>>' },
                    ],
                },
                paidTime: {
                    type: 'string',
                    displayName: '<<fkcgsj>>',
                },
            },
        },
    },
    components: {
        CouponOrderView: {
            component: 'Viewport',
            entity: 'CouponOrderEntity',
        },
        CouponOrderPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponOrderFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24',
                    style: {
                        marginBottom: 12,
                    },
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                type: 'coupon-order',
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/orders/download_data',
                                requestMethod: 'get',
                            },
                        },
                    ],
                },
                { component: 'CouponOrderTables' },
            ],
        },
        CouponOrderFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsites',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'goodsName',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderType',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'tradeNo',
                },
                {
                    property: 'orderOrigin',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'paymentType',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'mergePaymentNo',
                },
                {
                    property: 'payTimeRange',
                },
            ],
        },
        CouponOrderTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'CouponOrderTable',
                    },
                    params: {},
                },
                {
                    title: '<<dfk>>',
                    content: {
                        component: 'CouponOrderTable',
                    },
                    params: { orderStatus: 'CREATE' },
                },
                {
                    title: '<<dsy>>',
                    content: {
                        component: 'CouponOrderTable',
                    },
                    params: { orderStatus: 'PAYMENT' },
                },
                {
                    title: '<<ywc>>',
                    content: {
                        component: 'CouponOrderTable',
                    },
                    params: { orderStatus: 'RECEIVE' },
                },
                {
                    title: '<<yqxygb>>',
                    content: {
                        component: 'CouponOrderTable',
                    },
                    params: { orderStatus: 'CANCEL' },
                },
            ],
        },
        CouponOrderTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'goodsName',
                            width: 180,
                        },
                        {
                            property: 'quantity',
                            displayConfig: {
                                showField: 'count',
                            },
                        },
                        {
                            property: 'couponName',
                            displayConfig: {
                                showField: 'couponName',
                            },
                        },
                        {
                            property: 'couponNo',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    width: '180px',
                                },
                                propertyName: 'couponNos',
                            },
                        },
                        {
                            property: 'orderStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    CREATE: {
                                        text: '<<dfk>>',
                                        status: 'warning',
                                    },
                                    PAYMENT: {
                                        text: '<<dsy>>',
                                        status: 'processing',
                                    },
                                    RECEIVE: {
                                        text: '<<ywc>>',
                                        status: 'success',
                                    },
                                    CANCEL: {
                                        text: '<<yqx>>',
                                        status: 'error',
                                    },
                                    CLOSE: {
                                        text: '<<beforeClose>>',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                        {
                            property: 'orderCreateTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'paidTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'orderAmount',
                        },
                        {
                            property: 'pointAmount',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'orderType',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                            },
                        },
                        {
                            property: 'tradeNo',
                        },
                        {
                            property: 'orderOrigin',
                        },
                        {
                            property: 'paymentType',
                        },
                        {
                            property: 'mergePaymentNo',
                        },
                        {
                            property: 'upstreamOrderId',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 160,
                        items: [
                            {
                                type: 'link',
                                text: '<<details>>',
                                path:
                                    '/coupon-order/info/{{row.id}}/{{row.orderType}}/displayCouponsInfo',
                            },
                            {
                                type: 'link',
                                text: '<<sh_2>>',
                                statusKey: 'row.canRefund',
                                path: '/coupon-order/apply/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        CouponOrderInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponOrderEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'CouponOrderInfo',
                },
            ],
        },
        CouponOrderApplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponOrderApplyForm',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-order',
                    component: 'CouponOrderView',
                    breadcrumbName: '<<saleCouponOrder>>',
                    privilege: {
                        path: 'saleCouponOrder',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id/:orderType',
                            component: 'CouponOrderInfoPage',
                            breadcrumbName: '<<yhqddxq>>',
                        },
                        {
                            path: '/apply/:id',
                            component: 'CouponOrderApplyPage',
                            breadcrumbName: '<<sh_2>>',
                        },
                        { path: '/', component: 'CouponOrderPage' },
                    ],
                },
            ],
        },
    ],
};
