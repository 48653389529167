import { Config } from '@comall-backend-builder/core/lib/parser';
import { ButtonProps } from 'antd/lib/button';
import { EntityButtonProps } from '../../../../components';

/**
 * 数据类型
 */
export enum FileType {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 线下核销优惠券
     */
    CONSUME = 'CONSUME',
    /**
     * 线下退优惠券
     */
    UNCONSUME = 'UNCONSUME',
}

/**
 * 数据处理结果
 */
export enum FileStatus {
    /**
     * 正在处理
     */
    PROCESSING = 'PROCESSING',
    /**
     * 处理完成
     */
    PROCESS_FINISHED = 'PROCESS_FINISHED',
    /**
     * 处理失败（系统异常）
     */
    PROCESS_FAILED = 'PROCESS_FAILED',
}

const FILE_TYPE_OPTIONS = [
    {
        id: FileType.ALL,
        name: '<<all>>',
    },
    {
        id: FileType.CONSUME,
        name: '线下核销优惠券',
    },
    {
        id: FileType.UNCONSUME,
        name: '线下退优惠券',
    },
];

const FILE_STATUS_OPTIONS = [
    {
        id: FileStatus.PROCESSING,
        name: '正在处理',
    },
    {
        id: FileStatus.PROCESS_FINISHED,
        name: '处理完成',
    },
    {
        id: FileStatus.PROCESS_FAILED,
        name: '处理失败（系统异常）',
    },
];

export const config: Config = {
    entities: {
        couponDownloadEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/couponDownloadLoader',
            filters: {
                dataType: {
                    type: 'string.options.select',
                    displayName: '数据类型',
                    defaultValue: undefined,
                    controlConfig: {
                        style: {
                            width: 160,
                        },
                        placeholder: '<<selectPlease>>',
                    },
                    options: FILE_TYPE_OPTIONS,
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                fileName: {
                    type: 'string',
                    displayName: '<<fileName>>',
                },
                creatorUserName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dataType: {
                    type: 'string.options.select',
                    displayName: '数据类型',
                    options: FILE_TYPE_OPTIONS,
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                processEndTime: {
                    type: 'string',
                    displayName: '处理结束时间',
                },
                processStatus: {
                    type: 'string.options.select',
                    displayName: '<<processStatus>>',
                    options: FILE_STATUS_OPTIONS,
                },
            },
        },
    },
    components: {
        couponDownloadView: {
            component: 'Viewport',
        },
        couponDownloadPage: {
            component: 'FlexLayout',
            entity: 'couponDownloadEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'couponDownloadFilter',
                },
                {
                    component: 'couponDownloadTable',
                },
            ],
        },
        couponDownloadFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                paddingBottom: 16,
            },
            fields: [
                {
                    property: 'dataType',
                },
            ],
        },
        couponDownloadTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'fileName',
                },
                {
                    property: 'creatorUserName',
                },
                {
                    property: 'dataType',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'processEndTime',
                },
                {
                    property: 'processStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                items: [
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.processStatus !==
                                    FileStatus.PROCESS_FINISHED,
                                children: '下载数据',
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                return {
                                    ...buttonProps,
                                    download: entityButtonProps.row.fileName,
                                    href: entityButtonProps.row.downloadUrl,
                                };
                            },
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-download',
                    component: 'couponDownloadView',
                    breadcrumbName: '下载优惠券数据',
                    privilege: {
                        path: 'couponDownload',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'couponDownloadPage',
                        },
                    ],
                },
            ],
        },
    ],
};
