import { PureComponent, createElement } from 'react';
import builder, { actions, services } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import { defaults, get } from 'lodash';
import { subsiteLinkTypeSelectorValidate } from '../../types/format';
import { language } from '@comall-backend-builder/core/lib/services';

export interface NewPopUpAdsAddFormProps extends FormComponentProps {
    entity: Entity;
    scope: string;
    getForm: () => any;
    onSubmit: (e: any, fields: any) => void;
}

class NewPopUpAdsAddForm extends PureComponent<NewPopUpAdsAddFormProps> {
    static defaultProps: any = {};
    render() {
        const { entity, onSubmit, wrappedComponentRef, scope } = this.props;
        let fields = [
            { property: 'name' },
            { property: 'showTime' },
            {
                property: 'scope',
                controlConfig: {
                    onChange: (value: any, name: string) => {
                        const store = builder.getStore();

                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-NewPopUpAdsAddFormContainer',
                                name,
                                value
                            )
                        );
                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-NewPopUpAdsAddFormContainer',
                                'subsites',
                                []
                            )
                        );
                        store.dispatch(
                            actions.formStatusChangeAction(
                                'DataFormPlus-NewPopUpAdsAddFormContainer',
                                'subsites',
                                true
                            )
                        );
                    },
                },
            },
            {
                property: 'subsites',
                visible: (values: any) => get(values, 'scope') === 'SUBSITE',
            },
            {
                property: 'channels',
                controlConfig: {
                    isAdd: true,
                },
            },
            // { property: 'type' },
            { property: 'homeType', visible: (values: any) => get(values, 'scope') === 'SUBSITE' },
            {
                property: 'platformType',
                visible: (values: any) => get(values, 'scope') === 'PLATFORM',
            },
            { property: 'crowd' },
            { property: 'config' },
            {
                property: 'second',
                visible: (values: any) => get(values, 'config') === 'dayThreeTime',
            },
            { property: 'pictureType' },
            {
                property: 'pic',
                visible: (values: any) => get(values, 'pictureType') === 'SINGLE_IMAGE_FIXED',
            },
            {
                property: 'target',
                controlConfig: {
                    subsitePreRequired: scope === 'SUBSITE',
                    isPlatform: scope !== 'SUBSITE',
                    subsitePreRequiredMsg: services.language.getText('selectBeforeMerchant'),
                    bussinessType: 'new-pop-up-ad',
                    hideNeedUrlLink: true,
                },
                visible: (values: any) => get(values, 'pictureType') === 'SINGLE_IMAGE_FIXED',
            },
            {
                property: 'interval',
                visible: (values: any) => get(values, 'pictureType') === 'MULTIPLE_IMAGE_CAROUSEL',
            },
            {
                property: 'operator',
                controlConfig: {
                    subsitePreRequired: scope === 'SUBSITE',
                    isPlatform: scope !== 'SUBSITE',
                    subsitePreRequiredMsg: services.language.getText('selectBeforeMerchant'),
                    bussinessType: 'new-pop-up-ad',
                    hideNeedUrlLink: true,
                },
                visible: (values: any) => get(values, 'pictureType') === 'MULTIPLE_IMAGE_CAROUSEL',
            },
        ];

        let formProps = {
            entity,
            componentId: 'NewPopUpAdsAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const scope = get(entityFields, 'scope', null);
                const subsites = get(entityFields, 'subsites', null);
                const pictureType = get(entityFields, 'pictureType', 'SINGLE_IMAGE_FIXED');
                const operator = get(entityFields, 'operator', []);
                if (scope === 'SUBSITE' && (!subsites || !subsites.length)) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                const target = get(entityFields, 'target', {});
                if (!subsiteLinkTypeSelectorValidate(target || {})) {
                    return false;
                }
                if (pictureType === 'MULTIPLE_IMAGE_CAROUSEL') {
                    if (!operator || operator.length === 0) {
                        AntMessage.warning(language.getText('qtjggtp'));
                        return false;
                    }
                    const isFull = operator.every((item: any) => {
                        return item.pic && item.pic.path;
                    });
                    if (!isFull) {
                        AntMessage.warning(language.getText('qscggtp'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(language.getText('xzcg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}
function mapDispatchToProps(_dispatch: any, props: any) {
    let scope = get(_dispatch, 'components.DataFormPlus-NewPopUpAdsAddFormContainer.fields.scope');

    return defaults({
        scope,
    });
}
export const NewPopUpAdsAddFormView = connect(mapDispatchToProps)(NewPopUpAdsAddForm);
