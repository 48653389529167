import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { subsiteLinkTypeSelectorValidate } from '../../../types/format';
import { LinkTypeSelectorBussinessType } from '../../../types/mode/object/subsite-link-type-selector/subsite-link-type-selector';
import { ReceiveLimitEnum } from './cash-coupon-rules';

export const config: Config = {
    entities: {
        CouponPackageRulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_package_rules',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<giftName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<ffzt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                info: {
                    type: 'object.couponInfo',
                    displayName: '<<lbxx>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<ffzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<giftName>>',
                            rules: [{ required: true, whitespace: true }],
                            controlConfig: {
                                placeholder: '<<giftNameRequired>>',
                                style: {
                                    width: 450,
                                },
                                maxLength: 30,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<lbsj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.options.autoComplete',
                            className: 'custom-array-options',
                            displayName: '<<lbmd>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                    },
                },
                contentInfo: {
                    type: 'object.subForm',
                    displayName: '<<lbnr>>',
                    properties: {
                        content: {
                            type: 'array.couponsSelectorWithQuantity',
                            displayName: '<<lbnr>>',
                            rules: [{ type: 'array' }],
                            controlConfig: {
                                style: {
                                    width: 950,
                                },
                            },
                        },
                    },
                },
                useRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<useRuleInfo>>',
                    properties: {
                        receivePeriod: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<receivePeriod>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<receivePeriodExtra>>',
                        },
                        receiveLimit: {
                            type: 'string.options.radio.plus',
                            displayName: '<<receiveLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: ReceiveLimitEnum.NO_LIMIT,
                                        name: '<<noLimit>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.MEMBER_LIMIT,
                                        name: '<<memberLimit>>',
                                        tip: {
                                            text: '<<memberLimitText>>',
                                        },
                                    },
                                    {
                                        value: ReceiveLimitEnum.CROWD_LIMIT,
                                        name: '<<crowdLimit>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.TIME_LIMIT,
                                        name: '<<timeLimit>>',
                                        tip: {
                                            text: '<<timeLimitTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        receiveLimitCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'CouponPackageRuleMemberLevel',
                            },
                        },
                        memberLevels: {
                            type: 'array.options.autoComplete.properties.coupon',
                            displayName: '<<memberLevels>>',
                            options: [],
                            controlConfig: {
                                pageType: 'CouponPackageRuleMemberLevel',
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                            extra: '<<memberLevelsTip>>',
                        },
                        registerMemberSource: {
                            type: 'string.options.radio.plus',
                            displayName: '<<registerMemberSource>>',
                            defaultValue: 'ONLINE',
                            rules: [{ required: true }],
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'ONLINE',
                                        name: '<<registOnline>>',
                                        tip: {
                                            text: '<<registOnlineTip>>',
                                        },
                                    },
                                    {
                                        value: 'OFFLINE',
                                        name: '<<registOffline>>',
                                        tip: {
                                            text: '<<registTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        useLimitationsMode: {
                            type: 'string.options.radio',
                            displayName: '<<useLimitationsMode>>',
                            defaultValue: 'DATE',
                            options: [
                                {
                                    id: 'DATE',
                                    name: '<<useLimitationsModeDate>>',
                                },
                                {
                                    id: 'DAY',
                                    name: '<<useLimitationsModeDay>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        useLimitationsDate: {
                            type: 'object.dateRangePlus',
                            displayName: '<<useLimitationsDate>>',
                            rules: [{ required: true, message: '<<selectUseLimitationsDate>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<useLimitationsDateExtra>>',
                        },
                        useLimitationsDay: {
                            type: 'number.tip',
                            displayName: '<<registDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    required: true,
                                    message: '<<registDayMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<day>>',
                                min: 1,
                                max: 999,
                                tip: '',
                            },
                            extra: '<<registDayExtra>>',
                        },
                        receiveQuantityLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<receiveQuantityLimitType>>',
                            defaultValue: 'NO_LIMIT',
                            options: [
                                {
                                    id: 'NO_LIMIT',
                                    name: '<<limitNumberNoLimit>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_LIMIT',
                                    name: '<<everyMemberLimit>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        target: {
                            type: 'object.subsiteLinkTypeSelector',
                            displayName: '<<target>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        memberLimitQuantity: {
                            type: 'number',
                            displayName: '<<limitQuantity>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            displayConfig: { addonafter: '<<zhang>>' },
                        },
                    },
                },
                grantInfo: {
                    type: 'object.subForm',
                    displayName: '<<ffsz>>',
                    properties: {
                        condition: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<sxtj>>',
                            defaultValue: ['ONCE_ONLY'],
                            options: [
                                {
                                    id: 'ONCE_ONLY',
                                    name: '<<ryyzyhqkcbzs>>',
                                },
                            ],
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<tpsz>>',
                    properties: {
                        packImg: {
                            displayName: '<<lbtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: false }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                    },
                },
                seniorSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        /* advanceTime: {
                            type: 'string.options.selectPlus',
                           displayName: '<<yhqgq>>',
                            options: [
                                { id: '1', name: '1' },
                                { id: '2', name: '2' },
                                { id: '3', name: '3' },
                                { id: '4', name: '4' },
                                { id: '5', name: '5' },
                                { id: '6', name: '6' },
                                { id: '7', name: '7' },
                                { id: '8', name: '8' },
                                { id: '9', name: '9' },
                                { id: '10', name: '10' },
                                { id: '11', name: '11' },
                                { id: '12', name: '12' },
                                { id: '13', name: '13' },
                                { id: '14', name: '14' },
                            ],
                            controlConfig: {
                                addonbefore: '<<yhqgqq>>',
                                 addonafter: '<<ttx>>',
                                allowClear: true,
                            },
                            extra:
                                '<<jyszdtsxyyhqdyxq>>
                        }, */
                        fourIdenticalCertification: {
                            type: 'string.options.radio',
                            displayName: `<<zfbsmlqsfxy>>`,
                            defaultValue: 'DISABLE',
                            options: [
                                { id: 'ENABLE', name: '<<common.opend>>' },
                                { id: 'DISABLE', name: '<<common.close>>' },
                            ],
                            // rules: [{ required: true }],
                            extra: '<<yhsyzfb>>',
                        },
                        guideProvide: {
                            type: 'string.options.radio',
                            displayName: '<<guideProvide>>',
                            defaultValue: 'DISABLE',
                            options: [
                                { id: 'ENABLE', name: '<<yes>>' },
                                { id: 'DISABLE', name: '<<no>>' },
                            ],
                        },
                        shop: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<merchantAndSubsiteSelect>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                    },
                },
            },
        },
    },
    components: {
        CouponPackageRulesView: {
            component: 'Viewport',
            entity: 'CouponPackageRulesEntity',
        },
        CouponPackageRulesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponPackageRulesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzyhqlb>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/coupon-package-rules/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'CouponPackageRulesTable' },
            ],
        },
        CouponPackageRulesFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteIds',
                },
            ],
        },
        CouponPackageRulesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'info',
                    width: 360,
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/coupon_package_rules/:id/disabled',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/coupon_package_rules/:id/enabled',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },

                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/coupon-package-rules/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<viewData>>',
                        path: '/menu-list/coupon/coupon-package-rules/report/{{row.id}}',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/coupon/coupon-package-rules/logs/{{row.id}}',
                    },
                ],
            },
        },
        CouponPackageRulesAddPage: {
            component: 'Card',
            content: { component: 'CouponPackageRulesAddForm' },
        },
        CouponPackageRulesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'CouponPackageRulesEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                    extra: '<<btxapzdyhqdqlbnrpzdyhqzzsxsjhzwjssj>>',
                },
                { property: 'baseInfo.subsiteIds' },
                { property: 'contentInfo.content' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                { property: 'useRuleInfo.receiveLimit' },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                {
                    property: 'useRuleInfo.target',
                    controlConfig: {
                        // applicationVersion: 'V2',
                        hideNeedUrlLink: true,
                        subsitePreRequired: true,
                        subsitePreRequiredMsg: '<<selectBeforeMerchant>>',
                        bussinessType: LinkTypeSelectorBussinessType.CouponPackage,
                    },
                },
                { property: 'grantInfo.condition' },
                {
                    property: 'pictureInfo.packImg',
                    extra: '<<lqcjpz>>',
                },
                {
                    property: 'seniorSetting.guideProvide',
                },
                {
                    property: 'seniorSetting.fourIdenticalCertification',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.selectMode',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.shop',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'MERCHANT' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        fields: [
                            { property: 'subSiteName' },
                            { property: 'merchantTagId' },
                            { property: 'merchantName' },
                        ],
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                {
                    property: 'seniorSetting.subsite',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'SUB_SITE' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (!entity.contentInfo.content || !entity.contentInfo.content.length) {
                    AntMessage.error(services.language.getText('qxzlbnr'));
                    return false;
                }
                // if (entity.contentInfo.content.length > 10) {
                //     AntMessage.error('优惠券礼包最多可组合10个批次券');
                //     return false;
                // }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                if (
                    (entity.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        entity.useRuleInfo.receiveQuantityLimitType ===
                            'EVERY_MEMBER_DAILY_LIMIT') &&
                    !entity.useRuleInfo.memberLimitQuantity
                ) {
                    AntMessage.error(services.language.getText('qsrlqsl'));
                    return false;
                }
                //导购发券
                if (entity.seniorSetting && entity.seniorSetting.guideProvide) {
                    const guideProvide = entity.seniorSetting.guideProvide;
                    if (guideProvide && guideProvide === 'ENABLE') {
                        const guideMerchants = get(entity, 'seniorSetting.shop');
                        const guideSubsiteIds = get(entity, 'seniorSetting.subsite');
                        const isguideMerchantsEmpty = !guideMerchants || !guideMerchants.length;
                        const isguideSubsiteIdsEmpty = !guideSubsiteIds || !guideSubsiteIds.length;
                        if (isguideMerchantsEmpty && isguideSubsiteIdsEmpty) {
                            AntMessage.warn(services.language.getText('dgfqgjmdxzhzgjzjxzbndwk'));
                            return false;
                        }
                        return true;
                    }
                }
                const target = get(entity, 'useRuleInfo.target', {});
                if (!subsiteLinkTypeSelectorValidate(target || {})) {
                    return false;
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CouponPackageRulesEditPage: {
            component: 'Card',
            content: { component: 'CouponPackageRulesEditForm' },
        },
        CouponPackageRulesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'CouponPackageRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                    extra: '<<btxapzdyhqdqlbnrpzdyhqzzsxsjhzwjssj>>',
                },
                {
                    property: 'baseInfo.subsiteIds',
                },
                {
                    property: 'contentInfo.content',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 950,
                        },
                    },
                },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        disabled: true,
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: ReceiveLimitEnum.NO_LIMIT,
                                name: '<<noLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.MEMBER_LIMIT,
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: ReceiveLimitEnum.CROWD_LIMIT,
                                name: '<<crowdLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.TIME_LIMIT,
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                {
                    property: 'useRuleInfo.target',
                    controlConfig: {
                        // applicationVersion: 'V2',
                        hideNeedUrlLink: true,
                        subsitePreRequired: true,
                        subsitePreRequiredMsg: '<<selectBeforeMerchant>>',
                        bussinessType: LinkTypeSelectorBussinessType.CouponPackage,
                    },
                },
                {
                    property: 'grantInfo.condition',
                    controlConfig: { disabled: true },
                },
                {
                    property: 'pictureInfo.packImg',
                    extra: '<<lqcjpz>>',
                },
                {
                    property: 'seniorSetting.guideProvide',
                },
                {
                    property: 'seniorSetting.fourIdenticalCertification',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.selectMode',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.shop',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'MERCHANT' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        fields: [
                            { property: 'subSiteName' },
                            { property: 'merchantTagId' },
                            { property: 'merchantName' },
                        ],
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                {
                    property: 'seniorSetting.subsite',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'SUB_SITE' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
            ],
            submit: true,
            onValidate: (entity: any) => {
                if (!entity.contentInfo.content || !entity.contentInfo.content.length) {
                    AntMessage.error(services.language.getText('qxzlbnr'));
                    return false;
                }
                // if (entity.contentInfo.content.length > 10) {
                //     AntMessage.error('优惠券礼包最多可组合10个批次券');
                //     return false;
                // }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                if (
                    (entity.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        entity.useRuleInfo.receiveQuantityLimitType ===
                            'EVERY_MEMBER_DAILY_LIMIT') &&
                    !entity.useRuleInfo.memberLimitQuantity
                ) {
                    AntMessage.error(services.language.getText('qsrlqsl'));
                    return false;
                }
                //导购发券
                if (entity.seniorSetting && entity.seniorSetting.guideProvide) {
                    const guideProvide = entity.seniorSetting.guideProvide;
                    if (guideProvide && guideProvide === 'ENABLE') {
                        const guideMerchants = get(entity, 'seniorSetting.shop');
                        const guideSubsiteIds = get(entity, 'seniorSetting.subsite');
                        const isguideMerchantsEmpty = !guideMerchants || !guideMerchants.length;
                        const isguideSubsiteIdsEmpty = !guideSubsiteIds || !guideSubsiteIds.length;
                        if (isguideMerchantsEmpty && isguideSubsiteIdsEmpty) {
                            AntMessage.warn(services.language.getText('dgfqgjmdxzhzgjzjxzbndwk'));
                            return false;
                        }
                        return true;
                    }
                }
                const target = get(entity, 'useRuleInfo.target', {});
                if (!subsiteLinkTypeSelectorValidate(target || {})) {
                    return false;
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }

                return true;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSetSubmitConfirmProps: () => {
                return {
                    title: services.language.getText('wxts'),
                    content: services.language.getText('yscdwsyyhqjaxghdgzzx'),
                    okText: services.language.getText('rxxg'),
                    cancelText: services.language.getText('common.cancel'),
                };
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CouponPackageRulesReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponPackageRulesReportView',
                },
            ],
        },
        CouponPackageRulesLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                    apiPath: '/admin/operation_logs/PACKAGE/{id}',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/coupon-package-rules',
                    component: 'CouponPackageRulesView',
                    breadcrumbName: '<<package>>',
                    privilege: {
                        path: 'couponPackageRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CouponPackageRulesAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CouponPackageRulesEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'CouponPackageRulesReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'CouponPackageRulesLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'CouponPackageRulesPage' },
                    ],
                },
            ],
        },
    ],
};
