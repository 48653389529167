import React, { Component } from 'react';
import { Table } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { getUrlParam } from '../../../applications/cae/services/utils';
import './index.less';

export enum AuthScopeEnum {
    /**
     * 已授权
     */
    ALL = 'ALL',
    /**
     * 未授权
     */
    NONE = 'NONE',
    /**
     * 部分授权
     */
    PART = 'PART',
}

interface Function {
    id: string;
    name: string;
    /**
     * 授权状态, true=已授权，false=未授权
     */
    authStatus: boolean;
    /**
     * 说明
     */
    description: string;
}

export interface AuthRecordInfo {
    appId: number;
    componentAppId: number;
    /**
     * 授权范围
     */
    authScope: AuthScopeEnum;
    /**
     *
     */
    functions: Array<Function>;
}

interface States {
    info?: AuthRecordInfo;
}

export class WechatOfficialAccountsAuthListPage extends Component<any, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: undefined,
        };
    }

    componentDidMount() {
        const appId = getUrlParam(window.location.href, 'appId');
        if (appId) {
            api.get(
                {
                    appIds: appId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: '/admin/wechat_official_accounts/auth_record',
                }
            ).then((result: any) => {
                this.setState({
                    info: result && result.length > 0 ? result[0] : undefined,
                });
            });
        }
    }

    //跳往授权页面
    setEmpower = () => {
        const appId = getUrlParam(window.location.href, 'appId');
        if (!appId) {
            return;
        }
        api.post(
            {
                appId: appId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: `/admin/wechat_official_accounts/auth_page`,
            }
        )
            .then((result: any) => {
                //火狐浏览器window.open出现拦截弹层，采用setTimeout解决
                setTimeout(() => {
                    window.open(result.authPageUrl);
                }, 100);
            })
            .catch((error) => {
                services.errorHandle(error);
            });
    };

    renderWechatInfo = () => {
        const { info } = this.state;
        const name = getUrlParam(window.location.href, 'name');
        if (!info || !name) {
            return null;
        }
        return (
            <div className="wechat-info">
                <div>公众号：{name}</div>
                <div className="wechat-info__status">
                    授权状态：
                    {info.authScope === AuthScopeEnum.PART
                        ? services.language.getText('bfsq')
                        : '已授权'}
                </div>
                <Button type="link" onClick={this.setEmpower}>
                    重新授权
                </Button>
            </div>
        );
    };

    renderTip = () => {
        const tipsImage = require('./icon-tip.png');
        const { info } = this.state;
        if (!info || info.authScope !== AuthScopeEnum.PART) {
            return null;
        }
        return (
            <div className="tip">
                <img alt="" className="tip__image" src={tipsImage} />
                用户管理权限部分授权，将会导致公众号相关的部分功能不可使用，请重新授权全部权限
            </div>
        );
    };

    renderList = () => {
        const { info } = this.state;
        if (!info) {
            return null;
        }
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '授权集名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '授权状态',
                dataIndex: 'authStatus',
                key: 'authStatus',
                render: (authStatus: any, row: any) => {
                    //未授权：展示红色的颜色
                    if (authStatus) {
                        return <span>已授权</span>;
                    }
                    return <span style={{ color: 'red' }}>未授权</span>;
                },
            },
            {
                title: '权限使用说明',
                dataIndex: 'description',
                key: 'description',
            },
        ];

        return (
            <Table
                scroll={{ x: true }}
                rowKey={(record: any, i: number) => `${record.id}-${i}`}
                // pagination={paginationCongid}
                columns={columns}
                dataSource={info.functions}
            />
        );
    };

    renderFooter = () => {
        return (
            <div className="footer">
                <Button route="goBack">{services.language.getText('back')}</Button>
            </div>
        );
    };

    render() {
        return (
            <div className="wechat-official-accounts-auth-list-page">
                {this.renderWechatInfo()}
                {this.renderTip()}
                {this.renderList()}
                {this.renderFooter()}
            </div>
        );
    }
}
