import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { CardCollectionItemConfig } from '../../../components';

export class ArrayCardCollectItemConfigMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CardCollectionItemConfig {...controlInfo} />;
    }
}
