import React, { PureComponent, createElement } from 'react';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';
import moment from 'moment';

const TableComponentId = 'CouponRuleStackSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择专题页
 */
export class ArrayCouponRuleStackSelector extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('NewCouponSelector');
        entity = new Entity();
        this.state = {
            visible: false,
        };
    }

    dispatch: any = {};

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    getConfig = () => {
        const params = this.getParams();
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'left', marginBottom: '20px' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                        { property: 'multiExecuteStatus' },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    loadFirstPage: true,
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'name',
                            width: 300,
                            displayConfig: {
                                showLength: 18,
                            },
                        },
                        { property: 'code' },
                        { property: 'amountValue', width: 100 },
                        { property: 'startTime' },
                        { property: 'endTime' },
                        { property: 'executeStatus' },
                    ],
                },
            ],
        };
    };

    getParams = () => {
        const { row } = this.props;
        let params: any = {
            minEndTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            executeStatus: 'ALL',
            excludeIds: row.id,
            stackable: true,
        };
        return params;
    };

    toggleModal = () => {
        const { visible } = this.state;
        const params = this.getParams();
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.paging.page = 1;
                entity.search({
                    ...entity.paging,
                    ...params,
                });
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 1200,
            title: services.language.getText('kdjdyhqgz'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            footer: (
                <div>
                    <Button type="primary" onClick={this.toggleModal}>
                        {services.language.getText('common.ok')}
                    </Button>
                </div>
            ),
            onCancel: this.toggleModal,
        };

        return (
            <div className="topic-selector" style={{ display: 'inline-block' }}>
                <Button type="link" onClick={this.toggleModal} style={{ paddingLeft: 0 }}>
                    {services.language.getText('ckqtkdjq')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
