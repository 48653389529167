import { PureComponent, createElement } from 'react';
import { services, actions, builder, Loader } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import moment from 'moment';
import { get, reduce, clone, mapValues, cloneDeep, isNil } from 'lodash';
import { getField } from '@comall-backend-builder/components-basis/lib/form';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { RegistrationPriceType } from '../../components/registration-price';
// import './index.less';

export interface MessageBatchEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
    onReloadOptions: (fieldName: any, fields: any) => void;
    formComponent?: string;
}
enum SessionType {
    /**
     * 否
     */
    NO = 'NO',
    /**
     * 是
     */
    YES = 'YES',
}

class mallActivityAddFormView extends PureComponent<MessageBatchEditFormProps> {
    state = { haveShow: false };
    componentDidMount() {
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        const apiPath = `/admin/mall_activities/have_show`;
        services.api.get({}, { apiRoot, apiPath }).then((res: any) => {
            if (res) {
                this.setState({ haveShow: true });
            }
        });
    }

    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const {
            entity,
            onSubmit,
            wrappedComponentRef,
            data,
            onReloadOptions,
            formComponent = 'CreateFormPlus',
        } = this.props;
        const { haveShow } = this.state;
        const dateRange = get(data, 'baseInfo.dateRange');
        const dayTime = get(data, 'baseInfo.dayTime[0]');
        const needRegistration = get(data, 'registrationInfo.registrationType') === 'YES';
        let fields = [
            {
                property: 'baseInfo.name',
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    format: 'YYYY-MM-DD',
                    style: {
                        width: 450,
                    },
                    range: {
                        start: new Date(),
                    },
                    onChange(val: any) {
                        if (val) {
                            const store = builder.getStore();
                            const diffDays = moment(val.end).diff(moment(val.start), 'days') + 1;

                            const registrationTimes: any = [];
                            for (let i = 0; i < diffDays; i++) {
                                const item: any = {
                                    startDate: moment(val.start, 'YYYY-MM-DD')
                                        .subtract(0 - i, 'days')
                                        .format('YYYY-MM-DD'),
                                };
                                if (dayTime) {
                                    item.startTime = dayTime.start;
                                    item.endTime = dayTime.end;
                                }
                                registrationTimes.push(item);
                            }
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-MallActivityAddFormContainer',
                                    'registrationInfo.registrationTimes',
                                    registrationTimes
                                )
                            );
                        }
                    },
                },
            },
            {
                property: 'baseInfo.dayTime',
                controlConfig: {
                    onChange(val: any) {
                        if (val && val[0] && dateRange) {
                            const store = builder.getStore();
                            const diffDays =
                                moment(dateRange.end).diff(moment(dateRange.start), 'days') + 1;

                            const registrationTimes: any = [];
                            for (let i = 0; i < diffDays; i++) {
                                const item: any = {
                                    startDate: moment(dateRange.start, 'YYYY-MM-DD')
                                        .subtract(0 - i, 'days')
                                        .format('YYYY-MM-DD'),
                                };
                                item.startTime = val[0].start;
                                item.endTime = val[0].end;
                                registrationTimes.push(item);
                            }
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-MallActivityAddFormContainer',
                                    'registrationInfo.registrationTimes',
                                    registrationTimes
                                )
                            );
                        }
                    },
                },
            },
            {
                property: 'baseInfo.merchants',
            },
            {
                property: 'baseInfo.addressName',
            },
            {
                property: 'baseInfo.address',
            },
            {
                property: 'baseInfo.phone',
            },
            {
                property: 'baseInfo.merchantUserPhone',
            },
            {
                property: 'baseInfo.tagIdList',
                modifiable: (v: any) => get(v, 'baseInfo.merchants.length'),
            },
            {
                property: 'baseInfo.canShare',
            },
            {
                property: 'baseInfo.hide',
            },
            {
                property: 'mediaInfo.pictures',
            },
            {
                property: 'mediaInfo.poster',
            },
            {
                property: 'mediaInfo.info',
            },
            {
                property: 'registrationInfo.registrationType',
            },
            {
                property: 'registrationInfo.session',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.registrationStartTime',
                visible: () => needRegistration,
                controlConfig: {
                    mode: 'BEGIN',
                },
            },
            {
                property: 'registrationInfo.registrationEndTime',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.registrationTimes',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.session') === 'YES',
            },
            {
                property: 'registrationInfo.totalQuantity',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.session') === 'NO',
            },
            {
                property: 'registrationInfo.limitCountType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.limitCount',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.limitCountType') === 'YES',
            },
            {
                property: 'registrationInfo.limitSession',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.session') === 'YES',
            },
            {
                property: 'registrationInfo.limitSessionCount',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'registrationInfo.session') === 'YES' &&
                    get(values, 'registrationInfo.limitSession') === 'YES',
            },
            {
                property: 'registrationInfo.priceType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.payList',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'registrationInfo.priceType') === RegistrationPriceType.PAY,
            },
            {
                property: 'registrationInfo.targetUserType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.targetUserLevels',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.targetUserType') === 'SOME',
                modifiable: (v: any) => get(v, 'baseInfo.merchants.length'),
            },
            {
                property: 'registrationInfo.targetUserBirthDay',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.targetUserType') === 'SOME',
            },
            {
                property: 'registrationInfo.targetUserSexuality',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.targetUserType') === 'SOME',
            },
            {
                property: 'registrationInfo.customInfo',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.info',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.reviewType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.cancelableRule',
                visible: () => needRegistration,
            },

            {
                property: 'registrationInfo.excludeRule',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.excludeType',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.excludeRule') === 'YES',
            },
            {
                property: 'registrationInfo.excludeTypeActivity',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'registrationInfo.excludeRule') === 'YES' &&
                    get(values, 'registrationInfo.excludeType') === 'SOME',
            },
            {
                property: 'contentInfo.creator',
                visible: () => haveShow,
            },
            {
                property: 'contentInfo.activityTopics',
                visible: () => haveShow,
            },
            {
                property: 'rewardInfo.reward',
                visible: () => needRegistration,
            },
            {
                property: 'rewardInfo.rewardDistributionTime',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'rewardInfo.reward') &&
                    get(values, 'rewardInfo.reward').length,
            },
            {
                property: 'seniorSetting.enableNotice',
            },
            {
                property: 'seniorSetting.subscriptMessage',
                visible: (values: any) =>
                    get(values, 'seniorSetting.enableNotice', []).includes('true'),
            },
            {
                property: 'seniorSetting.marking',
            },
        ];

        const formProps = {
            entity,
            componentId: 'MallActivityAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: {
                minWidth: '750px',
            },
            fields: fields,
            submit: true,
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                if (!get(entity, 'baseInfo.pictures[0].id')) {
                    AntMessage.error('请上传活动图片');
                    return false;
                }

                if (!get(entity, 'baseInfo.poster[0].id')) {
                    AntMessage.error(services.language.getText('qscfxhb'));
                    return false;
                }

                if (get(entity, 'baseInfo.phone') && get(entity, 'baseInfo.phone').trim()) {
                    let merchantUsers = await Loader.load('get', {
                        params: { mobile: get(entity, 'baseInfo.merchantUserPhone').trim() },
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: '/admin/merchant_users/by_mobile',
                    });
                    if (!merchantUsers) {
                        AntMessage.error('该店员不存在');
                        return false;
                    } else if (!merchantUsers.wechatCardPicture) {
                        AntMessage.error('导购员没有上传名片');
                        return false;
                    }
                }
                const startTime = get(entity, 'registrationInfo.registrationStartTime.timeStr');
                const endTime = get(entity, 'registrationInfo.registrationEndTime.timeStr');
                if (
                    get(entity, 'registrationInfo.registrationStartTime.timeType') === 'DAY_TIME' &&
                    startTime &&
                    get(entity, 'registrationInfo.registrationEndTime.timeType') === 'DAY_TIME' &&
                    endTime
                ) {
                    // const startTime = +newDate()
                    if (+new Date(endTime) < +new Date(startTime)) {
                        AntMessage.error('报名开始时间不能大于报名结束时间');
                        return false;
                    }
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get(formComponent), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data =
        get(_dispatch, 'components.["DataFormPlus-MallActivityAddFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(
                    actions.unmountComponentAction('DataFormPlus-MallActivityAddFormContainer')
                );
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });
            const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展
            const needRegistration = get(newData, 'registrationInfo.registrationType') === 'YES';

            if (needRegistration && newData.registrationInfo.session === SessionType.YES) {
                // 过滤掉什么也没填的项
                newData.registrationInfo.registrationTimes = newData.registrationInfo.registrationTimes.filter(
                    (item: any) => item.startDate || item.startTime || item.endTime || item.quantity
                );
                // 统计完整项的数量
                const completeCount = newData.registrationInfo.registrationTimes.reduce(
                    (count: number, item: any) =>
                        item.startDate && item.startTime && item.endTime && item.quantity
                            ? count + 1
                            : count,
                    0
                );

                if (
                    completeCount === 0 ||
                    completeCount !== newData.registrationInfo.registrationTimes.length
                ) {
                    AntMessage.error('报名场次日期、时间、名额不能为空');
                    return;
                }
            }

            const enableNotice = get(newData, 'seniorSetting.enableNotice', []).includes('true');
            const subscriptMessage = get(newData, 'seniorSetting.subscriptMessage');
            if (enableNotice && (!subscriptMessage || !subscriptMessage.num)) {
                AntMessage.error('活动参与提醒时间不能为空');
                return;
            }

            if (get(newData, 'contentInfo.creator') && get(newData, 'contentInfo.creator').trim()) {
                let userInfo = await Loader.load('get', {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                    apiPath: `/admin/members/mobile/${get(newData, 'contentInfo.creator')}`,
                }).catch(services.errorHandle);
                if (!userInfo) {
                    AntMessage.error('活动发起人手机号没有注册');
                    return;
                }
                newData.contentInfo.creatorId = userInfo.id;
            }
            const marking = get(entity, 'seniorSetting.marking', undefined);
            if (!isNil(marking) && marking?.check && !marking.label) {
                AntMessage.error('请选择要打标的标签');
                return false;
            }

            if (newData) {
                entity.add(newData, params);
            }
        },
        // 重新加载属性候选值
        onReloadOptions: (fieldName: any, fields: any) => {
            //? 因为在这个场景下reload时fields可能已经不在dataTable上了
            //? 会导致修改被依赖字段未触发获取依赖方获取数据
            //? 所以这里改成从entity上取需要的参数
            const isSubform = fieldName.split('.').length > 1;
            const fieldNamePath = isSubform ? fieldName.replace(/\./g, '.properties.') : fieldName;
            const field = get(entity, `metainfo.properties.${fieldNamePath}`);

            if (!field) return;
            const sourceDefination = field.source;
            let dependences = sourceDefination.dependences;
            let params = reduce(
                dependences,
                (values, dependence) => {
                    values[dependence] = getField(dependence, fields).value;
                    return values;
                },
                props.params ? clone(props.params) : {}
            );
            entity.loadPropertyOptions(fieldName, sourceDefination, params);
        },
    };
}
export const MallActivityAddFormView = connect(mapStateToProps)(mallActivityAddFormView);
