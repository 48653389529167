import React, { Component } from 'react';
import { map } from 'lodash';
import { ImagePreviewPlus } from '../image-preview-plus';
import { language } from '@comall-backend-builder/core/lib/services';

export interface ComplainDetailProps {
    value: ComplainDetailValue;
}

interface ComplainDetailValue {
    content: any;
    type: string;
}

export class ComplainDetail extends Component<ComplainDetailProps> {
    render() {
        const { value } = this.props;
        const preview = {
            visible: true,
            maxCount: 3,
            autoplay: true,
            size: 550,
        };
        let previewProps: any;
        let images: any = [];
        if (value.type === 'NOTE') {
            if (value.content.type === 'PICTURE') {
                images = value.content.pictures.map((pic: any) => pic.url);
            } else if (value.content.type === 'VIDEO') {
                images = [value.content.video.coverUrl];
            }
            // 原数据集array需要格式化，在preview中显示
            let formatArray: any = [];
            map(images, (item, key) => {
                formatArray.push({
                    id: key,
                    imageurl: item,
                    text: item,
                    component: 'Image',
                });
            });

            // 预览组件配置信息
            previewProps = {
                ...preview,
                type: 'modal',
                imageConfig: {
                    style: {
                        width: 200,
                    },
                },
                items: formatArray,
            };
        }
        return (
            <div>
                {value.type !== 'NOTE' && <div>{value.content}</div>}
                {value.type === 'NOTE' && (
                    <div>
                        {value.content.type === 'PICTURE' && (
                            <div>{previewProps && <ImagePreviewPlus {...previewProps} />}</div>
                        )}
                        {value.content.type === 'VIDEO' && (
                            <div>
                                <div>
                                    <video width={300} controls>
                                        <source src={value.content.video.url} type="video/mp4" />
                                    </video>
                                </div>
                                <div>
                                    {language.getText('spfm')}：
                                    {previewProps && <ImagePreviewPlus {...previewProps} />}
                                </div>
                            </div>
                        )}
                        <div>
                            {language.getText('bjbt')}：{value.content.title}
                        </div>
                        <div>
                            {language.getText('bjnr')}：{value.content.content}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
