import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { Row, Col, Modal, Input, Button as AntButton, message } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { NewMallActivityTicketType } from '../../../components';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import { orderStatusMap } from '../../../loaders/magic-mall-activity-registration-loader';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface State {
    visible: boolean;
    reason?: string;
    fields: any;
}
const couponStatusMap: any = {
    PREBINDING: 'jybd',
    ENABLED: 'dsy',
    USED: 'ysy_1',
    INVALID: 'ysx',
    EXPIRED: 'ygq',
    FREEZE: 'ydj',
};
class NewMallActivityTicketPurchaseRecordDetailView extends PureComponent<any, State> {
    state = { visible: false, reason: '', fields: {} };
    componentDidMount(): void {
        this.loadData();
    }
    loadData = async () => {
        const {
            params: { recordId },
        } = this.props;
        try {
            let fields: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${recordId}`,
                }
            );
            let orderStatusStr = fields.status;
            if (orderStatusMap[fields.status]) {
                orderStatusStr = orderStatusMap[fields.status];
            }
            const {
                roundStartDate,
                roundEndDate,
                roundStartTime,
                roundEndTime,
            } = fields.ticketInfo;
            let participateDate =
                fields.mallActivityInfo?.ticketType === NewMallActivityTicketType.Activity
                    ? `${roundStartDate}${language.getText('to')}${roundEndDate}`
                    : `${roundStartDate} ${roundStartTime}${
                          roundEndTime ? `-${roundEndTime}` : ''
                      }`;
            fields.orderStatusStr = orderStatusStr;
            fields.participateDate = participateDate;
            this.setState({ fields });
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
    };
    openModal = () => {
        this.setState({ visible: true });
    };
    closeModal = () => {
        this.setState({ visible: false, reason: '' });
    };
    handleOk = (auditStatus: string) => {
        const { reason } = this.state;
        let values: any = { auditStatus };
        if (auditStatus === 'REJECTED') {
            values.auditMark = reason;
        }
        if (auditStatus === 'REJECTED' && !reason.trim()) {
            message.error(language.getText('qtxshbtgdyy'));
            return;
        }
        this.onRegistrationRecordAudit(values);
    };
    onRegistrationRecordAudit = async (value: any) => {
        const {
            params: { recordId },
        } = this.props;
        try {
            await api.put(
                { ...value },
                {
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${recordId}/audit`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                }
            );
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
        message.success(language.getText('actionSuccess'));
        this.loadData();
        this.closeModal();
    };
    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ reason: value });
    };

    /**
     * 处理后端返回的时间对象 返回的数据格式是hh:mm:ss
     */
    treatmentTime = (time: any) => {
        const modifyTime = [
            time.hour.toString().padStart(2, '0'),
            time.minute.toString().padStart(2, '0'),
            time.second.toString().padStart(2, '0'),
        ].join(':');
        return modifyTime;
    };
    renderReview = () => {
        const { fields } = this.state;
        let result = '';
        const auditStatus = get(fields, 'auditInfo.auditStatus');
        if (auditStatus === 'APPROVED') {
            result = language.getText('approved');
        }
        if (auditStatus === 'REJECTED') {
            result = services.language.getText('shbtg');
        }

        return get(fields, 'status') === 'WAIT_AUDIT' ? (
            <div className="item-content">
                <Row>
                    <Col span={8}>
                        <span>
                            {language.getText('shr_1')}：{language.getText('empty')}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            {language.getText('shsj')}：{language.getText('empty')}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            {language.getText('shjg')}：{language.getText('wsh')}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <span>
                            {language.getText('reason')}：{language.getText('empty')}
                        </span>
                    </Col>
                </Row>
            </div>
        ) : (
            <div className="item-content">
                <Row>
                    <Col span={8}>
                        <span>
                            {language.getText('shr_1')}：{get(fields, 'auditInfo.auditor') || ''}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            {language.getText('shsj')}：{get(fields, 'auditInfo.auditTime') || ''}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            {language.getText('shjg')}：{result}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <span style={{ wordBreak: 'break-all', wordWrap: 'break-word' }}>
                            {language.getText('reason')}：{get(fields, 'auditInfo.auditMark') || ''}
                        </span>
                    </Col>
                </Row>
            </div>
        );
    };
    renderPopup = () => {
        const { fields } = this.state;
        const { visible, reason } = this.state;
        let isWaitAudit = get(fields, 'status') !== 'WAIT_AUDIT';
        if (isWaitAudit) {
            return null;
        }
        return (
            <Modal
                onCancel={this.closeModal}
                visible={visible}
                title={services.language.getText('shbtg')}
                className="new-activity-ticket-purchase-records-detail__modal"
                width={500}
                footer={
                    <>
                        <AntButton type="primary" onClick={() => this.handleOk('REJECTED')}>
                            {services.language.getText('shbtg')}
                        </AntButton>
                        <AntButton type="default" onClick={this.closeModal}>
                            {language.getText('common.cancel')}
                        </AntButton>
                    </>
                }
            >
                <div className="new-activity-ticket-purchase-records-detail__modal-content">
                    <div className="title">{language.getText('reason')}：</div>
                    <Input.TextArea
                        maxLength={500}
                        value={reason}
                        onChange={this.inputChange}
                        placeholder={language.getText('jtxshbtgdyy')}
                        autoSize={{ minRows: 5, maxRows: 8 }}
                    />
                </div>
            </Modal>
        );
    };
    renderFooter = () => {
        const { fields } = this.state;
        let isWaitAudit = get(fields, 'status') === 'WAIT_AUDIT';
        return (
            <div className="footer">
                {isWaitAudit && (
                    <div>
                        <AntButton
                            key="submit"
                            type="primary"
                            onClick={() => this.handleOk('APPROVED')}
                        >
                            {services.language.getText('bcbtgsh')}
                        </AntButton>
                        <AntButton
                            style={{ marginLeft: 10 }}
                            type="primary"
                            onClick={this.openModal}
                        >
                            {services.language.getText('shbtg')}
                        </AntButton>
                    </div>
                )}
                <Button style={{ marginLeft: 10 }} route="goBack">
                    {services.language.getText('back')}
                </Button>
            </div>
        );
    };
    render() {
        const { fields } = this.state as any;
        const orderPoint = get(fields, 'orderInfo.totalPointAmount');
        const orderPrice = get(fields, 'orderInfo.totalSalesAmount');
        let orderPriceStr = '';
        const showPoints = orderPoint && parseFloat(orderPoint) !== 0;
        const showPrice = orderPrice && parseFloat(orderPrice) !== 0;
        if (!showPoints && !showPrice) {
            orderPriceStr = '0';
        } else if (showPoints && showPrice) {
            orderPriceStr = `${orderPoint}${language.getText(
                'point'
            )}${orderPrice}${language.getText('yuan')}`;
        } else if (!showPoints && showPrice) {
            orderPriceStr = `${orderPrice}${language.getText('yuan')}`;
        } else if (showPoints && !showPrice) {
            orderPriceStr = `${orderPoint}${language.getText('point')}`;
        }
        let extension = get(fields, 'joinMemberInfo.extension');
        if (extension && typeof extension === 'string') {
            fields.joinMemberInfo.extension = JSON.parse(fields.joinMemberInfo.extension);
        }
        let entranceCouponInfos = get(fields, 'ticketInfo.entranceCouponInfos') || [];
        const { startTime, endTime, startDate, endDate } = fields?.mallActivityInfo || {};
        //改签信息
        let changeTicketRecords = get(fields, 'changeTicketRecords') || [];

        return (
            <div className="new-activity-ticket-purchase-records-detail">
                <div className="page-title">{language.getText('ddxx')}</div>
                <div className="part-container">
                    <div className="title">{language.getText('hdxx')}</div>
                    <div>
                        {language.getText('activityName')}：{' '}
                        <span>{get(fields, 'mallActivityInfo.name') || ''}</span>
                    </div>
                    {startTime ? (
                        <div>{`${language.getText(
                            'acitivityTime'
                        )}： ${startDate}${language.getText(
                            'to'
                        )}${endDate} ${startTime}-${endTime}`}</div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="part-container">
                    <div className="title">{language.getText('cyzxx')}</div>
                    <div className="item-content">
                        <Row>
                            <Col span={8}>
                                <span>
                                    {language.getText('cyrxm')}：
                                    {get(fields, 'joinMemberInfo.name') ||
                                        language.getText('empty')}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('cyrsjh')}：
                                    {get(fields, 'joinMemberInfo.mobile') ||
                                        language.getText('empty')}
                                </span>
                            </Col>
                            {fields.joinMemberInfo &&
                                fields.joinMemberInfo.extension &&
                                fields.joinMemberInfo.extension.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <Col span={8} key={index}>
                                                <div>
                                                    {item.title}：
                                                    {typeof item.value === 'string'
                                                        ? item.value
                                                        : item.value.join('、')}
                                                </div>
                                            </Col>
                                        </>
                                    );
                                })}
                        </Row>
                    </div>
                </div>
                <div className="part-container">
                    <div className="title">{language.getText('pdxx')}</div>
                    <div className="item-content">
                        <table
                            className="ticket_info_table"
                            cellSpacing={0}
                            cellPadding={5}
                            style={{
                                borderCollapse: 'collapse',
                                textAlign: 'center',
                                width: '100%',
                                margin: '20px 0',
                            }}
                        >
                            <tbody>
                                <tr className="table-title-tr">
                                    <th>{language.getText('cysj')}</th>
                                    <th>{language.getText('mpmc')}</th>
                                    <th>{language.getText('number')}</th>
                                    <th>{language.getText('zj_6')}</th>
                                    <th>{language.getText('qmxx')}</th>
                                </tr>
                                <tr className="table-tr">
                                    <td style={{ width: '28%' }}>
                                        {get(fields, 'participateDate') || '-'}
                                    </td>
                                    <td style={{ width: '20%' }}>
                                        {get(fields, 'ticketInfo.name') || '-'}
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        {get(fields, 'ticketInfo.purchasedQuantity') || '-'}
                                    </td>
                                    <td style={{ width: '17%' }}>{orderPriceStr}</td>
                                    <td style={{ width: '25%' }}>
                                        {entranceCouponInfos.map((item: any, index: number) => (
                                            <p key={item.code}>
                                                {language.getText('qm_1')}
                                                {index + 1}：{item.code}(
                                                {language.getText(couponStatusMap[item.status])})
                                            </p>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="part-container">
                    <div className="title">{language.getText('mjxx')}</div>
                    <div className="item-content">
                        <Row>
                            <Col span={8}>
                                <span>
                                    {language.getText('xdrsjh')}：
                                    {get(fields, 'purchaseMemberInfo.mobile') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('xdrhykdj')}：
                                    {get(fields, 'purchaseMemberInfo.memberCardLevelName') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('xdrbmcgdcs')}：
                                    {get(fields, 'purchaseMemberInfo.successTicketPurchaseCount')}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('wcyhddcs')}：
                                    {get(fields, 'purchaseMemberInfo.unJoinCount')}
                                </span>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="part-container">
                    <div className="title">{language.getText('ddxx')}</div>
                    <div className="item-content">
                        <Row>
                            <Col span={8}>
                                <span>
                                    {language.getText('orderNumber')}：
                                    {get(fields, 'orderInfo.orderNo') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('gmsl')}：
                                    {get(fields, 'orderInfo.totalQuantity')}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('ddzt')}：
                                    {get(fields, 'orderStatusStr') || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {language.getText('orderTotalAmount')}：{orderPriceStr || ''}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span>
                                    {services.language.getText('xdsj')}：
                                    {get(fields, 'orderInfo.createTime') || ''}
                                </span>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="part-container">
                    <div className="title">{language.getText('shxx')}</div>
                    {!get(fields, 'orderInfo.audit', false) ? (
                        <span>{language.getText('bmjwshtg')}</span>
                    ) : (
                        this.renderReview()
                    )}
                </div>
                <div className="part-container">
                    <div className="title">{language.getText('gqxx')}</div>
                    <div className="item-content">
                        <table
                            className="ticket_info_table"
                            cellSpacing={0}
                            cellPadding={5}
                            style={{
                                borderCollapse: 'collapse',
                                textAlign: 'center',
                                width: '100%',
                                margin: '20px 0',
                            }}
                        >
                            <tbody>
                                <tr className="table-title-tr">
                                    <th>{language.getText('lastModifiedUser')}</th>
                                    <th>{language.getText('czsj')}</th>
                                    <th>{services.language.getText('gqqmpmc')}</th>
                                    <th>{language.getText('gqqccxx')}</th>
                                    <th>{language.getText('gqhmpmc')}</th>
                                    <th>{language.getText('gqhccxx')}</th>
                                </tr>
                                <>
                                    {changeTicketRecords.length > 0 &&
                                        changeTicketRecords.map((item: any, index: number) => {
                                            const beforeTicket = item.beforeTicket;
                                            const beforeTicketInfo = `${
                                                beforeTicket.roundStartDate
                                            } ${beforeTicket.roundStartTime}${
                                                beforeTicket.roundEndTime
                                                    ? `-${beforeTicket.roundEndTime}`
                                                    : ''
                                            }`;
                                            const afterTicket = item.afterTicket;
                                            const afterTicketInfo = `${
                                                afterTicket.roundStartDate
                                            } ${afterTicket.roundStartTime}${
                                                afterTicket.roundEndTime
                                                    ? `-${afterTicket.roundEndTime}`
                                                    : ''
                                            }`;
                                            return (
                                                <tr className="table-tr" key={index}>
                                                    <td style={{ width: '12%' }}>
                                                        {item.operator || '-'}
                                                    </td>
                                                    <td style={{ width: '20%' }}>
                                                        {item.operatorTime}
                                                    </td>
                                                    <td style={{ width: '11%' }}>
                                                        {beforeTicket.name}
                                                    </td>
                                                    <td style={{ width: '23%' }}>
                                                        {beforeTicketInfo}
                                                    </td>
                                                    <td style={{ width: '11%' }}>
                                                        {afterTicket.name}
                                                    </td>
                                                    <td style={{ width: '23%' }}>
                                                        {afterTicketInfo}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </>
                                <>
                                    {changeTicketRecords.length === 0 && (
                                        <tr className="table-tr">
                                            <td style={{ width: '12%' }}>{'-'}</td>
                                            <td style={{ width: '20%' }}>{'-'}</td>
                                            <td style={{ width: '11%' }}>{'-'}</td>
                                            <td style={{ width: '23%' }}>{'-'}</td>
                                            <td style={{ width: '11%' }}>{'-'}</td>
                                            <td style={{ width: '23%' }}>{'-'}</td>
                                        </tr>
                                    )}
                                </>
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.renderFooter()}
                {this.renderPopup()}
            </div>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields || {},
    };
}
export const NewMallActivityTicketPurchaseRecordDetail = connect(
    mapStateToProps,
    null
)(NewMallActivityTicketPurchaseRecordDetailView);
