import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MerchantStyleSelector } from './merchant-style-selector';

export class ArrayMerchantStyleSelector extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any) {
        return <MerchantStyleSelector {...displayInfo} />;
    }
}
