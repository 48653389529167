import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const StoreCardLevelBenefitLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/store_card_levels?page=1&per_page=200&status=ENABLE';
        const { subsiteId, loadDependences = '' } = data;
        if (subsiteId) {
            config.apiPath = `/admin/store_card_levels?subsite_id=${subsiteId}&page=1&per_page=100&status=ENABLE`;
        }
        // 解决编辑时加载多门店数据，当没有subsiteId时
        if (loadDependences) {
            return [];
        } else {
            return await api.get(data, config).then((res: any) => {
                return res.result;
            });
        }
    },
};
