import React from 'react';
import { Button, Tree as AntdTree, Modal, Input, Checkbox, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, get, unionWith } from 'lodash';
import classnames from 'classnames';
import './index.less';

const { TreeNode: AntdTreeNode } = AntdTree;
const { Search } = Input;

interface Organization {
    id: string;
    name: string;
    originalChildren?: Organization[];
    remark: string;
    parentId: string;
    originalId: string;
    originalParentId: string;
    parentName?: string;
    type: OrganizationType;
    originalCode?: string;
}

enum OrganizationType {
    SUBSITE = 'SUBSITE',
    MERCHANT = 'MERCHANT',
}

type OrganizationHash = Record<string, Organization>;

interface OrganizationStoreSelectorProps {
    onChange: (val: Array<Organization>) => void;
    value: Array<Organization>;
    disabled: boolean;
    type: 'edit' | 'show';
}
interface OrganizationStoreSelectorState {
    list: Organization[];
    subsiteOptions: Organization[];
    merchantOptions: Organization[];
    selectedMerchant: Organization[];
    selectedSubsite: Organization[];
    visible: boolean;
    loading: boolean;
    subsiteHash: OrganizationHash;
    merchantHash: OrganizationHash;
    tab: OrganizationType;
    subsiteKeywords: string;
    merchantKeywords: string;
}

const tabs = [
    { name: 'subsites', type: OrganizationType.SUBSITE },
    { name: 'merchant', type: OrganizationType.MERCHANT },
];
export class OrganizationStoreSelector extends React.Component<
    OrganizationStoreSelectorProps,
    OrganizationStoreSelectorState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: [],
            visible: false,
            subsiteOptions: [],
            merchantOptions: [],
            loading: false,
            selectedSubsite: [],
            selectedMerchant: [],
            subsiteHash: {},
            merchantHash: {},
            tab: OrganizationType.SUBSITE,
            subsiteKeywords: '',
            merchantKeywords: '',
        };
    }

    static defaultProps = {
        value: [],
    };

    async componentDidMount() {
        this.loadDataSubsite();
        this.loadDataMerchant();
    }

    getOrganizations = async (type: OrganizationType, name?: string) => {
        const result: Organization[] = await services.api.get(
            { name, dataType: 'ORGANIZATION_GRANTED', type },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/user_organizations`,
            }
        );
        return result;
    };

    loadDataSubsite = async (organizationName?: string) => {
        this.setState({ loading: true });

        try {
            const subsiteOptions = await this.getOrganizations(
                OrganizationType.SUBSITE,
                organizationName
            );
            if (!organizationName) {
                const subsiteHash = {};
                this.transformTree(subsiteOptions, subsiteHash);
                this.setState({
                    subsiteHash,
                    subsiteOptions,
                    loading: false,
                });
                return;
            }
            this.setState({
                subsiteOptions,
                loading: false,
            });
        } catch (error) {
            errorHandle(error as any);
            this.setState({ loading: false });
        }
    };
    loadDataMerchant = async (organizationName?: string) => {
        this.setState({ loading: true });

        try {
            const merchantOptions = await this.getOrganizations(
                OrganizationType.MERCHANT,
                organizationName
            );
            if (!organizationName) {
                const merchantHash = {};
                this.transformTree(cloneDeep(merchantOptions), merchantHash);
                this.setState({
                    merchantHash,
                    merchantOptions,
                    loading: false,
                });
                return;
            }
            this.setState({
                merchantOptions,
                loading: false,
            });
        } catch (error) {
            errorHandle(error as any);
            this.setState({ loading: false });
        }
    };

    transformTree = (data: Organization[], itemHash: OrganizationHash = {}) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            itemHash[node.id] = node;
            if (node.originalChildren) {
                this.transformTree(node.originalChildren, itemHash);
            }
        }
    };

    getMerchantSubsite = (merchant: Organization) => {
        const { merchantHash } = this.state;
        let subsite;
        for (const key in merchantHash) {
            if (Object.prototype.hasOwnProperty.call(merchantHash, key)) {
                const element = merchantHash[key];
                if (
                    element.originalId === merchant.originalParentId &&
                    element.type === OrganizationType.SUBSITE
                ) {
                    subsite = element;
                    break;
                }
            }
        }
        return subsite || merchantHash[merchant.id];
    };

    /**
     * 已选择的扁平化数据 =》弹窗右侧展示的数据
     */
    transformDataToPreview = (data: Organization[]) => {
        data = data || [];
        const selectedMerchant = data.filter((i) => i.type === OrganizationType.MERCHANT);
        const selectedSubsite = data.filter((i) => i.type === OrganizationType.SUBSITE);
        // .sort((a, b) => a.sequence - b.sequence);
        const selectedMerchantTree: Organization[] = [];
        const parentHash: OrganizationHash = {};
        const indexHash: Record<string, number> = {};
        selectedMerchant.forEach((item) => {
            const subsiteId = item.originalParentId;
            if (!parentHash[subsiteId]) {
                const subsite = this.getMerchantSubsite(item);
                // 新的门店
                parentHash[subsiteId] = subsite;
                indexHash[subsiteId] = selectedMerchantTree.length;
                selectedMerchantTree.push({ ...subsite, originalChildren: [item] });
            } else {
                (selectedMerchantTree[indexHash[subsiteId]].originalChildren || []).push(item);
            }
        });

        this.setState({
            selectedSubsite,
            // selectedMerchant: selectedMerchantTree.sort(
            //     (a, b) => a.sequence - b.sequence
            // ),
            selectedMerchant: selectedMerchantTree,
        });
    };

    onOpen = async () => {
        // 转换preview
        const { value } = this.props;

        const { merchantHash, subsiteHash } = this.state;
        const ids = value ? value.map((i) => i.id) : [];
        const hashList = { ...subsiteHash, ...merchantHash };
        let list: Organization[] = [];

        if (Object.keys(hashList).length) {
            list = ids.map((i) => hashList[i]).filter((i) => i);
        }

        this.setState({ list: list, subsiteKeywords: '', merchantKeywords: '' }, () => {
            this.tabClick(OrganizationType.SUBSITE);
            this.transformDataToPreview(list);
        });
        this.setState({ visible: true });
    };
    onCheckChange = (e: any) => {
        const { list } = this.state;
        const { checked, value } = e.target;
        const data = cloneDeep(list);
        const { id } = value;
        let newData = [...data];
        if (checked) {
            newData.push(value);
        } else {
            newData = newData.filter((i) => i.id !== id);
        }
        this.setState(
            {
                list: newData,
            },
            () => {
                this.transformDataToPreview(newData);
            }
        );
    };

    renderSubsiteTreeNodes = (treeNodes: Organization[]) => {
        const { list } = this.state;
        return treeNodes.map((item) => {
            const checked = !!list.find((i) => i.id === item.id);

            const title = (
                <div className="organization-store-selector-tree-item">
                    <Checkbox
                        checked={checked}
                        value={item}
                        onChange={this.onCheckChange}
                    ></Checkbox>
                    <span className="organization-store-selector-tree-item-name">
                        &nbsp;&nbsp;{item.name}
                    </span>
                </div>
            );
            return (
                <AntdTreeNode key={item.id} title={title}>
                    {item.originalChildren
                        ? this.renderSubsiteTreeNodes(item.originalChildren)
                        : undefined}
                </AntdTreeNode>
            );
        });
    };
    renderMerchantTreeNodes = (treeNodes: Organization[], isChildren?: boolean) => {
        const { list, selectedMerchant } = this.state;
        return treeNodes.map((item) => {
            const checked = !!list.find((i) => i.id === item.id);
            const options: Organization[] = selectedMerchant.reduce((a, b) => {
                return [...a, ...(b.originalChildren || [])];
            }, [] as Organization[]);
            const innerSelectList = options.filter((i) => i.originalParentId === item.originalId);
            const merchantIndeterminate =
                !!innerSelectList.length &&
                innerSelectList.length !== (item.originalChildren || []).length;
            const isAllMerchantSelected =
                !!innerSelectList.length &&
                innerSelectList.length === (item.originalChildren || []).length;

            const title = (
                <div className="organization-store-selector-tree-item">
                    {item.type === OrganizationType.SUBSITE && (
                        <Checkbox
                            checked={isAllMerchantSelected}
                            value={item}
                            indeterminate={merchantIndeterminate}
                            onChange={(e) => this.onSubsiteCheckChange(e, item)}
                        ></Checkbox>
                    )}
                    {item.type === OrganizationType.MERCHANT && (
                        <Checkbox
                            checked={checked}
                            value={item}
                            onChange={this.onCheckChange}
                        ></Checkbox>
                    )}
                    <span className="organization-store-selector-tree-item-name">
                        &nbsp;&nbsp; {item.name}&nbsp;&nbsp;
                        {isChildren ? `${item.originalCode}` : ''}
                    </span>
                </div>
            );
            return (
                <AntdTreeNode key={item.id} title={title}>
                    {item.originalChildren
                        ? this.renderMerchantTreeNodes(item.originalChildren, true)
                        : undefined}
                </AntdTreeNode>
            );
        });
    };
    onSubsiteCheckChange = (e: any, item: Organization) => {
        const { list } = this.state;
        let newData: Organization[] = [];
        const selected = cloneDeep(list);

        if (e.target.checked) {
            newData = unionWith(item.originalChildren, selected, (a, b) => a.id === b.id);
        } else {
            const ids = (item.originalChildren || []).map((i) => i.id);
            newData = selected.filter((i) => !ids.includes(i.id));
        }

        this.setState(
            {
                list: newData,
            },
            () => {
                this.transformDataToPreview(newData);
            }
        );
    };

    renderTree = () => {
        const { disabled } = this.props;
        const {
            subsiteOptions,
            merchantOptions,
            tab,
            selectedSubsite,
            selectedMerchant,
        } = this.state;
        const allMerchantLength = merchantOptions.reduce(
            (a, b) => a + (b.originalChildren || []).length,
            0
        );
        const selectedMerchantLength = selectedMerchant.reduce(
            (a, b) => a + (b.originalChildren || []).length,
            0
        );
        const merchantIndeterminate =
            !!selectedMerchantLength && selectedMerchantLength !== allMerchantLength;
        const isAllMerchantSelected =
            !!selectedMerchantLength && selectedMerchantLength === allMerchantLength;

        const subsiteIndeterminate =
            !!selectedSubsite.length && selectedSubsite.length !== subsiteOptions.length;
        const isAllSubsiteSelected =
            !!selectedSubsite.length && selectedSubsite.length === subsiteOptions.length;

        return (
            <div>
                <div className="organization-store-selector__all">
                    {tab === OrganizationType.SUBSITE && (
                        <Checkbox
                            onChange={this.selectALLSubsite}
                            checked={isAllSubsiteSelected}
                            indeterminate={subsiteIndeterminate}
                        >
                            {services.language.getText('allSubsites')}
                        </Checkbox>
                    )}
                    {tab === OrganizationType.MERCHANT && (
                        <Checkbox
                            onChange={this.selectALLMerchant}
                            checked={isAllMerchantSelected}
                            indeterminate={merchantIndeterminate}
                        >
                            {services.language.getText('qbzj')}
                        </Checkbox>
                    )}
                </div>

                <AntdTree selectable={false} autoExpandParent={true} disabled={disabled}>
                    {tab === OrganizationType.SUBSITE &&
                        this.renderSubsiteTreeNodes(subsiteOptions)}
                    {tab === OrganizationType.MERCHANT &&
                        this.renderMerchantTreeNodes(merchantOptions)}
                </AntdTree>
            </div>
        );
    };

    selectALLSubsite = (e: any) => {
        const { subsiteOptions, list } = this.state;
        const selected = cloneDeep(list);

        let newData: Organization[] = [];
        if (e.target.checked) {
            newData = unionWith(subsiteOptions, selected, (a, b) => a.id === b.id);
        } else {
            const ids = subsiteOptions.map((i) => i.id);
            newData = selected.filter((i) => !ids.includes(i.id));
        }

        this.setState(
            {
                list: newData,
            },
            () => {
                this.transformDataToPreview(newData);
            }
        );
    };

    selectALLMerchant = (e: any) => {
        const { merchantOptions, list } = this.state;
        const selected = cloneDeep(list);
        const options: Organization[] = merchantOptions.reduce((a, b) => {
            return [...a, ...(b.originalChildren || [])];
        }, [] as Organization[]);
        let newData: Organization[] = [];
        if (e.target.checked) {
            newData = unionWith(options, selected, (a, b) => a.id === b.id);
        } else {
            const ids = options.map((i) => i.id);
            newData = selected.filter((i) => !ids.includes(i.id));
        }

        this.setState(
            {
                list: newData,
            },
            () => {
                this.transformDataToPreview(newData);
            }
        );
    };

    onSearchSubsiteChange = (name: any) => {
        this.loadDataSubsite(name);
    };
    onSearchMerchantChange = (name: any) => {
        this.loadDataMerchant(name);
    };
    onCancel = () => {
        this.setState({
            visible: false,
        });
        this.clear();
    };
    clear = () => {
        this.setState({ list: [], selectedMerchant: [], selectedSubsite: [] });
    };
    onDelete = (id: string) => {
        const { list } = this.state;
        this.setState(
            {
                list: list.filter((i) => i.id !== id),
            },
            () => {
                this.transformDataToPreview(this.state.list);
            }
        );
    };
    onkeywordsChange = (type: 'subsite' | 'merchant', value: string) => {
        if (type === 'subsite') {
            this.setState({ subsiteKeywords: value });
        } else {
            this.setState({ merchantKeywords: value });
        }
    };
    renderPreview = () => {
        const { list, selectedSubsite, selectedMerchant } = this.state;
        const selectedMerchantLength = selectedMerchant.reduce(
            (a, b) => a + (b.originalChildren || []).length,
            0
        );
        return (
            <div className="organization-store-selector-preview">
                <div className="organization-store-selector-preview-list">
                    <div className="organization-store-selector-preview-list-title">
                        {services.language.getText('selectedSubsiteMerchant')}({list.length})
                    </div>
                    <Button type="link" onClick={this.clear}>
                        {services.language.getText('common.clear')}
                    </Button>
                </div>
                <div className="organization-store-selector-preview-list">
                    <div className="organization-store-selector-preview-list-title">
                        {services.language.getText('subsites')}({selectedSubsite.length})
                    </div>
                </div>
                {selectedSubsite.map((i) => (
                    <div key={i.id} className="organization-store-selector-preview-list">
                        <div className="organization-store-selector-tree-item-name">{i.name}</div>
                        <Button onClick={() => this.onDelete(i.id)} type="link">
                            {services.language.getText('common.delete')}
                        </Button>
                    </div>
                ))}

                <div className="organization-store-selector-preview-list">
                    <div className="organization-store-selector-preview-list-title">
                        {services.language.getText('merchant')}({selectedMerchantLength})
                    </div>
                </div>

                {selectedMerchant.map((i) => (
                    <div key={i.id}>
                        <div className="organization-store-selector-preview-list-title--merchant">
                            {i.name}({(i.originalChildren || []).length})
                        </div>
                        {(i.originalChildren || []).map((j) => (
                            <div key={j.id} className="organization-store-selector-preview-list">
                                <div className="organization-store-selector-tree-item-name">
                                    {j.name} {j.originalCode}
                                </div>
                                <Button onClick={() => this.onDelete(j.id)} type="link">
                                    {services.language.getText('common.delete')}
                                </Button>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };
    renderDisplay = () => {
        const { value, type, disabled } = this.props;
        const { merchantHash, subsiteHash } = this.state;
        const ids = value ? value.map((i) => i.id) : [];
        const hashList = { ...subsiteHash, ...merchantHash };
        let list: Organization[] = [];

        if (Object.keys(hashList).length) {
            list = ids.map((i) => hashList[i]).filter((i) => i);
            list.forEach((item) => {
                delete item.originalChildren;
            });
        }

        const columns: any = [
            {
                title: services.language.getText('lx_1'),
                dataIndex: 'type',
                width: 60,
                key: 'type',
                render: (type: OrganizationType) => {
                    return (
                        <span>
                            {type === OrganizationType.SUBSITE &&
                                services.language.getText('subsites')}
                            {type === OrganizationType.MERCHANT &&
                                services.language.getText('merchant')}
                        </span>
                    );
                },
            },
            {
                title: services.language.getText('subsiteName'),
                dataIndex: 'name',
                width: 260,
                key: 'name',
                render: (text: string, record: Organization) => {
                    return (
                        <span>
                            {record.type === OrganizationType.SUBSITE && text}
                            {record.type === OrganizationType.MERCHANT &&
                                get(this.getMerchantSubsite(record), 'name')}
                        </span>
                    );
                },
            },
            {
                title: services.language.getText('merchantName'),
                dataIndex: 'name',
                width: 180,
                key: 'name',
                render: (text: string, record: Organization) => {
                    return <span>{record.type === OrganizationType.MERCHANT && text}</span>;
                },
            },
            {
                title: services.language.getText('merchantCode'),
                dataIndex: 'originalCode',
                width: 180,
                key: 'originalCode',
                render: (text: string, record: Organization) => {
                    return <span>{record.type === OrganizationType.MERCHANT && text}</span>;
                },
            },
        ];

        if (type === 'edit') {
            const actionColumn = {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 90,
                render: (text: string, record: Organization) => {
                    return (
                        <Button
                            disabled={disabled}
                            type="link"
                            onClick={() => this.deleteSelected(record.id)}
                            style={{ padding: 0 }}
                        >
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            };
            columns.push(actionColumn);
        }
        return (
            !!(value && value.length) && (
                <Table
                    className="organization-store-selector-table"
                    pagination={false}
                    rowKey={'id'}
                    bordered={false}
                    dataSource={list}
                    columns={columns}
                    scroll={{ y: 390 }}
                />
            )
        );
    };
    deleteSelected = (id: string) => {
        const { value, onChange } = this.props;
        onChange(value.filter((i) => i.id !== id));
    };
    onOk = () => {
        const { onChange } = this.props;
        const { list } = this.state;
        const data = cloneDeep(list);
        data.forEach((i) => {
            delete i.originalChildren;
        });
        onChange(data);
        this.onCancel();
    };
    tabClick = (type: OrganizationType) => {
        this.setState({ tab: type });
        if (type === OrganizationType.SUBSITE) {
            this.loadDataSubsite();
        }
        if (type === OrganizationType.MERCHANT) {
            this.loadDataMerchant();
        }
    };
    render() {
        const { disabled, type } = this.props;
        const { visible, loading, tab, subsiteKeywords, merchantKeywords } = this.state;
        return (
            <div className="organization-store-selector">
                {!!(type === 'edit' && !disabled) && (
                    <>
                        <Button type="link" onClick={this.onOpen} disabled={disabled}>
                            {services.language.getText('selectedSubsiteMerchant_2')}
                        </Button>
                    </>
                )}
                <span style={{ color: '#CCCCCC' }}>
                    {services.language.getText('zjkzzhdksyjsdywgnsjqxfw')}
                </span>
                <Modal
                    title={services.language.getText('glmdzg')}
                    width={800}
                    visible={visible}
                    className="organization-store-selector-modal"
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                >
                    <div className="organization-store-selector-container">
                        <div className="organization-store-selector-container-tab">
                            {tabs.map((i) => (
                                <div
                                    className={classnames(
                                        'organization-store-selector-container-tab__item',
                                        {
                                            'organization-store-selector-container-tab__item--selected':
                                                i.type === tab,
                                        }
                                    )}
                                    onClick={() => this.tabClick(i.type)}
                                >
                                    {services.language.getText(i.name)}
                                </div>
                            ))}
                        </div>
                        <div className="organization-store-selector-container-content">
                            <div className="organization-store-selector-container-left">
                                {tab === OrganizationType.SUBSITE && (
                                    <Search
                                        placeholder={services.language.getText(
                                            'subsiteNameRequired'
                                        )}
                                        className="organization-store-selector-search"
                                        onSearch={this.onSearchSubsiteChange}
                                        onInput={(e: any) =>
                                            this.onkeywordsChange('subsite', e.target.value)
                                        }
                                        value={subsiteKeywords}
                                    />
                                )}
                                {tab === OrganizationType.MERCHANT && (
                                    <Search
                                        placeholder={services.language.getText(
                                            'merchantNameRequired'
                                        )}
                                        className="organization-store-selector-search"
                                        onSearch={this.onSearchMerchantChange}
                                        onInput={(e: any) =>
                                            this.onkeywordsChange('merchant', e.target.value)
                                        }
                                        value={merchantKeywords}
                                    />
                                )}
                                {loading ? (
                                    <span className="organization-store-selector-loading">
                                        {services.language.getText('jzz')}...
                                    </span>
                                ) : (
                                    this.renderTree()
                                )}
                            </div>
                            <div className="organization-store-selector-container-right">
                                {this.renderPreview()}
                            </div>
                        </div>
                    </div>
                </Modal>
                {this.renderDisplay()}
            </div>
        );
    }
}
