import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Icon } from 'antd';
import { Bar, Line } from '@ant-design/charts';

import './index.less';
import { formatNumber } from '../../services/utils';
import { language } from '@comall-backend-builder/core/lib/services';

class dataPanelsMembersCardView extends PureComponent<{
    entity: any;
    fields: any;
}> {
    state = {
        value: [],
    };

    // /**
    //  * 格式化数字显示
    //  */
    // formatNumber = (amount: number, tips: string) => {
    //     if (amount > 10000000) return (amount / 10000000).toFixed(2) + '千万' + tips;
    //     if (amount > 10000) return (amount / 10000).toFixed(2) + '万' + tips;
    //     if (amount > 1000)
    //         return Math.floor(amount / 1000) + ',' + Math.floor(amount % 1000) + tips;
    //     if (amount >= 0) return amount + tips;
    //     return '-' + tips;
    // };

    /**
     * 渲染新增客户折线图
     */
    renderNewCustomLineChart = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            seriesField: 'seriesFieldValue',
            yAxis: {
                label: {
                    formatter: function formatter(v: any) {
                        return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
                            return ''.concat(s, ',');
                        });
                    },
                },
            },
            color: ['#ff794b', '#bb2f2a', '#FAA219'],
            meta: {
                date: {
                    type: 'cat',
                    ticks: [2005, 2010, 2015],
                },
            },
        };

        return (
            <div className="half">
                <Line {...config} />
            </div>
        );
    };

    /**
     * 渲染累计客户折线图
     */
    renderTotalCustomLineChart = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            seriesField: 'seriesFieldValue',
            yAxis: {
                label: {
                    formatter: function formatter(v: any) {
                        return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
                            return ''.concat(s, ',');
                        });
                    },
                },
            },
            colorField: 'seriesFieldValue',
            color: ['#7ca5fb', '#7868fd', '#FAA219'],
            meta: {
                date: {
                    type: 'cat',
                    ticks: [2005, 2010, 2015],
                },
            },
        };

        return (
            <div className="half">
                <Line {...config} />
            </div>
        );
    };

    /**
     * 渲染门店新增客户数条形图
     */
    renderNewCustomBarhart = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'yfieldValue',
            yField: 'xfieldValue',
            barWidthRatio: 0.6,
            meta: {
                xfieldValue: { alias: language.getText('subsites') },
                yfieldValue: { alias: language.getText('xzkhs') },
            },
            scrollbar: { type: 'vertical' as any },
            color: '#bbe2ff',
        };
        return (
            <div className="half">
                <div className="column-chart-title">{language.getText('mdxzkhs')}</div>
                <Bar {...config} />
            </div>
        );
    };

    /**
     * 渲染门店累计客户数条形图
     */
    renderTotalCustomBarhart = (data: any) => {
        const config = {
            data: data ? data : [],
            xField: 'yfieldValue',
            yField: 'xfieldValue',
            barWidthRatio: 0.6,
            meta: {
                xfieldValue: { alias: language.getText('subsites') },
                yfieldValue: { alias: language.getText('ljkhs') },
            },
            scrollbar: { type: 'vertical' as any },
            color: '#bbe2ff',
        };
        return (
            <div className="half">
                <div className="column-chart-title">{language.getText('mdljkhs')}</div>
                <Bar {...config} />
            </div>
        );
    };

    /**
     * 渲染支付概览子项
     */
    renderOverviewItem = (title: string, data: string, yesterday: number, lastWeek: number) => {
        return (
            <div className="col">
                <div className="title">{title}</div>
                <div className="data">{data}</div>
                <div className="contrast">
                    <span className="text">{language.getText('dbzr')}：</span>
                    <span className="percent">{!!yesterday ? Math.abs(yesterday) + '%' : '-'}</span>
                    {!!yesterday && (
                        <Icon
                            type={yesterday < 0 ? 'arrow-down' : 'arrow-up'}
                            className={yesterday < 0 ? 'icon-green' : 'icon-red'}
                        />
                    )}
                </div>
                <div className="contrast">
                    <span className="text">{language.getText('jsztq')}：</span>
                    <span className="percent">{!!lastWeek ? Math.abs(lastWeek) + '%' : '-'}</span>
                    {!!lastWeek && (
                        <Icon
                            type={lastWeek < 0 ? 'arrow-down' : 'arrow-up'}
                            className={lastWeek < 0 ? 'icon-green' : 'icon-red'}
                        />
                    )}
                </div>
            </div>
        );
    };

    /**
     * 渲染支付概览
     */
    renderOverview = (data: any[]) => {
        const newMemberCount = find(data, (item) => item.overviewType === 'NEW_MEMBER_COUNT');
        const memberTotalCount = find(data, (item) => item.overviewType === 'MEMBER_TOTAL_COUNT');

        return (
            <div className="data-panel-reprot">
                {newMemberCount &&
                    this.renderOverviewItem(
                        language.getText('xzkh'),
                        formatNumber(newMemberCount.overviewValue, language.getText('people')),
                        newMemberCount.compareWithYesterday,
                        newMemberCount.compareWithLastWeek
                    )}
                {memberTotalCount &&
                    this.renderOverviewItem(
                        language.getText('ljkhs'),
                        formatNumber(memberTotalCount.overviewValue, language.getText('people')),
                        memberTotalCount.compareWithYesterday,
                        memberTotalCount.compareWithLastWeek
                    )}
                <div className="col"></div>
                <div className="col"></div>
            </div>
        );
    };

    render() {
        const { fields } = this.props;
        return (
            <div className="data-panel-members-view">
                {this.renderOverview(fields.overviewStatistics)}
                <div className="chart chart-double">
                    {this.renderNewCustomLineChart(fields.newMember)}
                    {this.renderTotalCustomLineChart(fields.totalMember)}
                </div>
                <div className="chart chart-double">
                    {this.renderNewCustomBarhart(fields.subsiteNewMember)}
                    {this.renderTotalCustomBarhart(fields.subsiteTotalMember)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;

    return {
        entity,
        componentId,
        fields: entity.result ? entity.result : entity.fields ? entity.fields : [],
    };
}

export const DataPanelsMembersCardView = connect(mapStateToProps, null)(dataPanelsMembersCardView);
