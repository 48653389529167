import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, forEach, isNull } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
export const CheckinActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/checkin/activities';
        const { id } = data;
        let goldValueAlias = '金币';
        if (id) {
            config.apiPath = `/admin/checkin/activities/${id}`;
            const resData: any[] = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/activities/reward_items?event_type=SIGN_IN',
                }
            );
            if (resData && resData.length) {
                goldValueAlias = resData.find((item: any) => item.code === 'GOLD')?.name || '金币';
            }
        } else {
            if (data.timeStatus === 'ALL') {
                delete data.timeStatus;
            }
            if (data.timeStatus) {
                data.status = data.timeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
            const subsiteName = data.subsiteName;
            if (subsiteName) {
                data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime.slice(0, 10),
                        end: res.endTime.slice(0, 10),
                    },
                    subsiteIds: res.subsites,
                    recordShare: res.recordShare + '',
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };

                if (res.daysRewardRule) {
                    const pointValue = res.daysRewardRule.pointValue;
                    const experienceValue = res.daysRewardRule.experienceValue;
                    const coupons = res.daysRewardRule.coupons;
                    const gold = res.daysRewardRule.goldValue;

                    res.daysRewardRule = [
                        {
                            id: res.daysRewardRule.id,
                            point: pointValue,
                            experience: experienceValue,
                            pointChecked: pointValue > 0 ? true : false,
                            experienceChecked: experienceValue > 0 ? true : false,
                            goldChecked: gold > 0 ? true : false,
                            gold: gold,
                            couponsValue: coupons,
                            couponsChecked: coupons.length > 0 ? true : false,
                        },
                    ];
                }
                // 处理累计签到数据
                const totalRewardRule: any = [];
                if (res.ladderRewardRules) {
                    forEach(res.ladderRewardRules, (item) => {
                        totalRewardRule.push({
                            id: item.id,
                            conditionType: item.conditionType,
                            stepValue: item.conditionValue,
                            point: item.pointValue,
                            pointChecked: item.pointValue > 0 ? true : false,
                            experience: item.experienceValue,
                            experienceChecked: item.experienceValue > 0 ? true : false,
                            goldValueChecked: item.goldValue > 0 ? true : false,
                            goldValue: item.goldValue,
                            goldValueTxtName: goldValueAlias,
                            couponsValue: item.coupons,
                            couponsChecked: item.coupons.length > 0 ? true : false,
                        });
                    });
                    if (totalRewardRule.length === 0) {
                        totalRewardRule.push({ couponsValue: [] });
                    }
                }
                const specialDayRewardRules: any = [];
                if (res.specialDayRewardRules) {
                    forEach(res.specialDayRewardRules, (item) => {
                        specialDayRewardRules.push({
                            id: item.id,
                            stepValue: item.specialDayTime,
                            point: item.pointValue,
                            pointChecked: item.pointValue > 0 ? true : false,
                            experience: item.experienceValue,
                            experienceChecked: item.experienceValue > 0 ? true : false,
                            couponsValue: item.coupons,
                            couponsChecked: item.coupons.length > 0 ? true : false,
                        });
                    });
                    if (specialDayRewardRules.length === 0) {
                        specialDayRewardRules.push({ couponsValue: [] });
                    }
                }
                res.specialDayRewardRules = specialDayRewardRules;
                res.totalRewardRule = {
                    values: totalRewardRule,
                    continuousCheckin:
                        res.ladderRewardConfig && res.ladderRewardRules.length > 0
                            ? res.ladderRewardConfig.continuousCheckin
                            : null,
                };
                res.ladderPolling =
                    res.ladderRewardConfig && res.ladderRewardConfig.ladderPolling
                        ? [res.ladderRewardConfig.ladderPolling.toString()]
                        : [];

                res.pictureInfo = {
                    backgroundPicture: [
                        {
                            id: res.backgroundPictureId || '',
                            path: res.backgroundPictureUrl || '',
                        },
                    ],
                    popupPicture: [
                        {
                            id: res.popupPictureId || '',
                            path: res.popupPictureUrl || '',
                        },
                    ],
                    sharePicture: [
                        {
                            id: res.sharePictureId || '',
                            path: res.sharePictureUrl || '',
                        },
                    ],
                    shareDescription: res.shareDescription,
                    description: res.description,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        subsiteName: item.subsites,
                    };
                });
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/checkin/activities`;
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/checkin/activities/${params.id}`;
        }
        const newParams: any = dataParser(params);
        newParams.id = params.id;
        newParams.status = params.status;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    //todo 二期实现，暂时写死true
    newParams.homePagePopup = true;
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start.slice(0, 10) + ' 00:00:00';
        newParams.endTime = params.baseInfo.dateRange.end.slice(0, 10) + ' 23:59:59';
        const subsiteIds = params.baseInfo.subsiteIds.map((subsite: any) => {
            return subsite.id;
        });
        newParams.subsiteIds = subsiteIds;
        newParams.recordShare = params.baseInfo.recordShare;
        newParams.marketingScheduleId =
            (params.baseInfo.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
    }
    if (params.daysRewardRule && params.daysRewardRule.length > 0) {
        let daysRewardRule = params.daysRewardRule[0];
        if (
            daysRewardRule.pointChecked ||
            daysRewardRule.couponsChecked ||
            daysRewardRule.experienceChecked ||
            daysRewardRule.goldChecked
        ) {
            const idArray = new Array<{
                id: number;
                quantity: number;
            }>();
            if (
                daysRewardRule.couponsChecked &&
                daysRewardRule.couponsValue &&
                daysRewardRule.couponsValue.length > 0
            ) {
                forEach(daysRewardRule.couponsValue, (coupon) => {
                    idArray.push({
                        id: coupon.id,
                        quantity: coupon.quantity ? coupon.quantity : 1,
                    });
                });
            }
            newParams.daysRewardRule = {
                id: daysRewardRule.id ? daysRewardRule.id : '',
                pointValue: daysRewardRule.pointChecked ? daysRewardRule.point : null,
                experienceValue: daysRewardRule.experienceChecked
                    ? daysRewardRule.experience
                    : null,
                goldValue: daysRewardRule.goldChecked ? daysRewardRule.gold : null,
                coupons: idArray,
            };
        } else {
            newParams.daysRewardRule = null;
        }
    }

    // 提交时处理累计签到阶梯数据
    const totalRewardArray: any = [];
    if (
        params.totalRewardRule &&
        !isNull(params.totalRewardRule.continuousCheckin) &&
        params.totalRewardRule.values.length > 0
    ) {
        forEach(params.totalRewardRule.values, (reward) => {
            if (reward.stepValue > 0) {
                let point = null;
                if (reward.pointChecked && reward.pointChecked === true) {
                    point = reward.point;
                }
                let experience = null;
                if (reward.experienceChecked && reward.experienceChecked === true) {
                    experience = reward.experience;
                }
                let goldValue = null;
                if (reward.goldValueChecked && reward.goldValueChecked === true) {
                    goldValue = reward.goldValue;
                }
                const idArray = new Array<{
                    id: number;
                    quantity: number;
                }>();
                if (
                    reward.couponsChecked &&
                    reward.couponsValue &&
                    reward.couponsValue.length > 0
                ) {
                    forEach(reward.couponsValue, (coupon) => {
                        idArray.push({
                            id: coupon.id,
                            quantity: coupon.quantity ? coupon.quantity : 1,
                        });
                    });
                }
                totalRewardArray.push({
                    id: reward.id,
                    conditionType: params.totalRewardRule.continuousCheckin
                        ? 'CONTINUOUS_CHECKIN_DAYS'
                        : 'TOTAL_CHECKIN_DAYS',
                    conditionValue: reward.stepValue,
                    pointValue: point,
                    experienceValue: experience,
                    coupons: idArray,
                    prizePictureId: null,
                    prizePictureUrl: null,
                    goldValue,
                });
            }
        });
    }
    newParams.ladderRewardRules = totalRewardArray;
    newParams.ladderRewardConfig =
        totalRewardArray.length > 0 && !isNull(params.totalRewardRule.continuousCheckin)
            ? {
                  continuousCheckin: params.totalRewardRule.continuousCheckin,
                  ladderPolling:
                      !params.totalRewardRule.continuousCheckin && params.ladderPolling[0]
                          ? true
                          : false,
              }
            : null;

    // 提交时处理特殊日期阶梯数据
    const specialDayRewardArray: any = [];
    if (params.specialDayRewardRules && params.specialDayRewardRules.length > 0) {
        forEach(params.specialDayRewardRules, (reward) => {
            if (typeof reward.stepValue == 'string') {
                let point = null;
                if (reward.pointChecked && reward.pointChecked === true) {
                    point = reward.point;
                }
                let experience = null;
                if (reward.experienceChecked && reward.experienceChecked === true) {
                    experience = reward.experience;
                }
                const idArray = new Array<{
                    id: number;
                    quantity: number;
                }>();
                if (
                    reward.couponsChecked &&
                    reward.couponsValue &&
                    reward.couponsValue.length > 0
                ) {
                    forEach(reward.couponsValue, (coupon) => {
                        idArray.push({
                            id: coupon.id,
                            quantity: coupon.quantity ? coupon.quantity : 1,
                        });
                    });
                }
                specialDayRewardArray.push({
                    id: reward.id,
                    specialDayTime: reward.stepValue,
                    pointValue: point,
                    experienceValue: experience,
                    coupons: idArray,
                    prizePictureId: null,
                    prizePictureUrl: null,
                });
            }
        });
    }
    newParams.specialDayRewardRules = specialDayRewardArray;

    if (params.pictureInfo) {
        if (params.pictureInfo.backgroundPicture && params.pictureInfo.backgroundPicture[0]) {
            newParams.backgroundPictureId = params.pictureInfo.backgroundPicture[0].id;
            newParams.backgroundPictureUrl = params.pictureInfo.backgroundPicture[0].path;
        }
        if (params.pictureInfo.popupPicture && params.pictureInfo.popupPicture[0]) {
            newParams.popupPictureId = params.pictureInfo.popupPicture[0].id;
            newParams.popupPictureUrl = params.pictureInfo.popupPicture[0].path;
        }
        if (params.pictureInfo.sharePicture && params.pictureInfo.sharePicture[0]) {
            newParams.sharePictureId = params.pictureInfo.sharePicture[0].id;
            newParams.sharePictureUrl = params.pictureInfo.sharePicture[0].path;
        }

        newParams.description = params.pictureInfo.description;
        newParams.shareDescription = params.pictureInfo.shareDescription;
    }
    return newParams;
}
