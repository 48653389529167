import { language } from '@comall-backend-builder/core/lib/services';
import React, { PureComponent } from 'react';

interface MessageStyle {
    innerClass: any;
    top: any;
    bottom: any;
    goImage?: any;
    miniImage?: any;
}
interface MessagesSubscriptioPushAndSiteMailProps {
    /**
     * 样式
     */
    messageStyle: MessageStyle;
    /**
     * 模板数据列表
     */
    messageList: any;

    /** 名称*/
    name: string;
    landingPageName?: string;
}

/**
 * 站内信和Apppush模板
 */
export class MessagesSubscriptioPushAndSiteMail extends PureComponent<
    MessagesSubscriptioPushAndSiteMailProps
> {
    render() {
        const {
            messageStyle: { innerClass, top, bottom },
            messageList,
            name,
            landingPageName,
        } = this.props;

        // 图片
        const siteMailMessageImage = messageList[2].value && JSON.parse(messageList[2].value).url;

        return (
            <div className={innerClass.content}>
                <div className={innerClass.mailItems}>
                    <div className={innerClass.contentTitle}>{name}</div>
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {top && top.value}
                    </div>
                    {messageList.map((o: any, index: number) => {
                        return (
                            <>
                                {index === 0 && (
                                    <div key={o.name} className={innerClass.otherItem}>
                                        <span className={innerClass.otherItemTitle}>{o.name}</span>
                                        <span
                                            className={innerClass.otherItemValue}
                                            style={{
                                                color: o && o.color ? o.color : '#000',
                                            }}
                                        >
                                            {o.value}
                                        </span>
                                    </div>
                                )}
                                {index === 1 && (
                                    <div key={o.name} className={innerClass.otherItem}>
                                        <span className={innerClass.otherItemTitle}>{o.name}</span>
                                        <span
                                            className={innerClass.otherPicItemValue}
                                            style={{
                                                color: o && o.color ? o.color : '#000',
                                            }}
                                        >
                                            {messageList.length > 1 && messageList[2].value && (
                                                <img
                                                    className="img"
                                                    alt={language.getText('xxtp')}
                                                    src={siteMailMessageImage}
                                                />
                                            )}
                                            <span className="value">{o.value}</span>
                                        </span>
                                    </div>
                                )}
                            </>
                        );
                    })}
                    <div
                        style={{
                            color: bottom && bottom.color ? bottom.color : '#D0021B',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {bottom && bottom.value}
                    </div>
                    {landingPageName ? (
                        <div className={innerClass.otherItem} style={{ marginTop: 20 }}>
                            <span className={innerClass.otherItemTitle}>
                                {language.getText('tzldy')}
                            </span>
                            <span className={innerClass.otherItemValue}>{landingPageName}</span>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
