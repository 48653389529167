import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        serviceReservedRefundOrderEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/serviceReservedRefundOrder',
            filters: {
                saleRuleName: {
                    type: 'string.text.trim',
                    displayName: '<<fwmc>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                subsites: {
                    displayName: '<<subsiteName>>',
                    type: 'string.options.autoComplete',
                    className: 'custom-array-options',
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: {
                            width: 250,
                        },
                    },
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                mobile: {
                    type: 'string.text.trim',
                    displayName: '<<yydh_1>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsryyzdh>>',
                        style: {
                            width: 220,
                        },
                        maxLength: 11,
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                    controlConfig: {
                        placeholder: ['<<ksrq>>', '<<jsrq>>'],
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },

                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                activityName: {
                    type: 'string.popover',
                    displayName: '<<fwmc>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<yyddh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<yydh_1>>',
                },
                interfaceStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<tkdzt>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                refundMoney: {
                    type: 'string',
                    displayName: '<<tkje>>',
                },
                refundItemMode: {
                    type: 'string.statusBadge',
                    displayName: '<<tkfs>>',
                },
                refundCreateReason: {
                    type: 'string',
                    displayName: '<<scyy>>',
                },
            },
        },
    },
    components: {
        serviceReservedRefundOrderView: {
            component: 'Viewport',
        },

        serviceReservedRefundOrderPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'serviceReservedRefundOrderEntity',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'serviceReservedRefundOrderContent',
                },
            ],
        },
        serviceReservedRefundOrderContent: {
            component: 'FlexLayout',
            entity: 'serviceReservedRefundOrderEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'serviceReservedRefundOrderFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/service_reserved_activity_refunds/export',
                                type: 'service_reserved_activity_refunds',
                                requestMethod: 'get',
                                target: '_blank',
                            },
                        },
                    ],
                },
                {
                    component: 'serviceReservedRefundOrderTable',
                },
            ],
        },
        serviceReservedRefundOrderFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'saleRuleName',
                },
                {
                    property: 'subsites',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        serviceReservedRefundOrderTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: true,
            },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'activityName',
                    width: 180,
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'interfaceStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            SUBMIT_SUCC: {
                                text: '<<tjcg_1>>',
                                status: 'success',
                            },
                            APPLYING: {
                                text: '<<wcl>>',
                                status: 'error',
                            },
                            SUBMIT_FAIL: {
                                text: '<<tjsb_1>>',
                                status: 'error',
                            },
                            REFUND_SUCC: {
                                text: '<<tkcg>>',
                                status: 'success',
                            },
                            REFUND_FAIL: {
                                text: '<<tksb>>',
                                status: 'error',
                            },
                        },
                    },
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'refundMoney',
                },
                {
                    property: 'refundItemMode',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            REVERT: {
                                text: '<<revert>>',
                                status: 'success',
                            },
                            TRANSFERS: {
                                text: '<<zz_4>>',
                                status: 'success',
                            },
                            SYSTEM: {
                                text: '<<xtnth>>',
                                status: 'success',
                            },
                            STORES: {
                                text: '<<mdth>>',
                                status: 'success',
                            },
                        },
                    },
                },
                {
                    property: 'refundCreateReason',
                    displayConfig: {
                        className: 'ant-table-cell-multi-ellipsis',
                        style: {
                            maxWidth: '180px',
                            '-webkit-line-clamp': '3',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 100,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'canRefund',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/service_reserved_activity_refunds/:id',
                                    config: {
                                        content: '<<zctk>>',
                                        text: '<<zctk>>',
                                    },
                                    confirm: {
                                        text: '<<qdyzxfqtk>>',
                                    },
                                },
                                {
                                    value: false,
                                    config: {
                                        content: '<<zctk>>',
                                        text: '<<zctk>>',
                                        disabled: true,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/service-reserved-refund-order',
                    component: 'serviceReservedRefundOrderView',
                    breadcrumbName: '<<serviceReservedRefundOrder>>',
                    privilege: {
                        path: 'serviceReservedRefundOrder',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'serviceReservedRefundOrderPage',
                        },
                    ],
                },
            ],
        },
    ],
};
