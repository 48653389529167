import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

const COMPLAIN_AUDIT_STATUS = [
    {
        id: 'WAIT',
        name: '<<wsh>>',
    },
    {
        id: 'SUCCESS',
        name: '<<jbcg>>',
    },
    {
        id: 'FAIL',
        name: '<<jbsb>>',
    },
];
const COMPLAIN_CONTENT_TYPE = [
    {
        id: 'NOTE',
        name: '<<bjjb>>',
    },
    {
        id: 'COMMENT',
        name: '<<pljb>>',
    },
    {
        id: 'MEMBER',
        name: '<<yhjb>>',
    },
];
export const config: Config = {
    entities: {
        contentComplain: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/complain/records',
            properties: {
                complainContent: {
                    type: 'string',
                    displayName: '<<jbnr>>',
                },
                complainContentAuthorMobile: {
                    type: 'string',
                    displayName: '<<bjbr>>',
                },
                complainMemberMobile: {
                    type: 'string',
                    displayName: '<<jbr>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<jblx>>',
                    options: COMPLAIN_CONTENT_TYPE,
                },
                reason: {
                    type: 'string',
                    displayName: '<<jbyy>>',
                },
                complainTime: {
                    type: 'string',
                    displayName: '<<jbsj>>',
                },
                auditStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                auditTime: {
                    type: 'string',
                    displayName: '<<shsj>>',
                },
                auditRemark: {
                    type: 'string',
                    displayName: '<<shbz>>',
                },
                complainDetail: {
                    type: 'object.content.complainDetail',
                    displayName: '<<jbnr>>',
                },
            },
            filters: {
                complainContentAuthorMobile: {
                    type: 'string',
                    displayName: '<<bjbr>>',
                },
                complainMemberMobile: {
                    type: 'string',
                    displayName: '<<jbr>>',
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<jbzt>>',
                    options: COMPLAIN_AUDIT_STATUS,
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<jblx>>',
                    options: COMPLAIN_CONTENT_TYPE,
                },
                complainTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<jbsj>>',
                },
            },
        },
    },
    components: {
        ContentComplainView: {
            component: 'Viewport',
            entity: 'contentComplain',
        },
        ContentComplainListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ContentComplainFilter',
                },
                {
                    component: 'ContentComplainTable',
                },
            ],
        },
        ContentComplainFilter: {
            component: 'FilterForm',
            fieldRow: {},
            fieldCol: {
                span: 8,
            },
            labelCol: 5,
            fields: [
                {
                    property: 'complainContentAuthorMobile',
                },
                {
                    property: 'complainMemberMobile',
                },
                {
                    property: 'auditStatus',
                },
                {
                    property: 'contentType',
                },
                {
                    property: 'complainTime',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
            ],
            footer: {
                style: {
                    textAlign: 'right',
                },
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 8,
                },
            },
            reset: true,
        },
        ContentComplainTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), { total });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'complainContent',
                },
                {
                    property: 'complainContentAuthorMobile',
                },
                {
                    property: 'complainMemberMobile',
                },
                {
                    property: 'contentType',
                },
                {
                    property: 'reason',
                },
                {
                    property: 'complainTime',
                },
                {
                    property: 'auditStatus',
                    displayConfig: {
                        statusConfig: {
                            WAIT: {
                                text: '<<wsh>>',
                                status: 'default',
                            },
                            SUCCESS: {
                                text: '<<jbcg>>',
                                status: 'success',
                            },
                            FAIL: {
                                text: '<<jbsb>>',
                                status: 'error',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/content-complain/detail/{{row.id}}',
                    },
                    {
                        type: 'component',
                        statusKey: 'row.showBlacklist',
                        component: 'BlacklistButton',
                    },
                ],
            },
        },
        ContentComplainDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ContentComplainDetailWrap',
                },
            ],
        },
        ContentComplainDetailWrap: {
            component: 'ContentComplainDetail',
            entity: 'contentComplain',
            loaderType: 'get',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/content-complain',
                    breadcrumbName: '<<complain>>',
                    component: 'ContentComplainView',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'ContentComplainDetailPage',
                        },
                        {
                            path: '/',
                            component: 'ContentComplainListPage',
                        },
                    ],
                    privilege: {
                        path: 'showComplain',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
