import React, { PureComponent, ReactNode, CSSProperties } from 'react';
import { Icon, Popover } from 'antd';
import './index.less';

interface QuestionPoprverProps {
    /**
     * 气泡内容
     */
    content: string | ReactNode;
    /**
     * 图标类型
     */
    type?: string;
    /**
     * 图标样式
     */
    iconStyle?: any;
    /**
     * 气泡最大宽度
     */
    maxWidth?: number;
    placement?: 'right' | 'left' | 'top' | 'bottom';
    trigger?: 'hover' | 'focus' | 'click';
    text?: string;
    textColor?: string;
    style?: CSSProperties;
}

/**
 * 气泡提示图标
 */
export class QuestionPoprver extends PureComponent<QuestionPoprverProps, {}> {
    render() {
        const {
            content,
            type,
            iconStyle,
            maxWidth,
            placement,
            trigger,
            text,
            textColor,
            style,
        } = this.props;
        return (
            <Popover
                placement={placement ? placement : 'right'}
                trigger={trigger}
                content={<div style={{ maxWidth: maxWidth ? maxWidth : 400 }}>{content}</div>}
            >
                <div className="question-poprver" style={style}>
                    <Icon
                        className="question-poprver-icon"
                        style={iconStyle}
                        type={type ? type : 'question-circle'}
                    />
                    {!!text && (
                        <span className="question-poprver-text" style={{ color: textColor }}>
                            {text}
                        </span>
                    )}
                </div>
            </Popover>
        );
    }
}
