import React from 'react';
import { get } from 'lodash';
import { TypesManager } from '@comall-backend-builder/core';
import { Checkbox } from 'antd';

export const CheckboxWithValueGroup = (props) => {
    const { properties = [], name, defaultValue, disabled: globalDisabled, onChange } = props;

    function getValue() {
        return get(props, 'value', defaultValue || {});
    }

    function getPropertieValue(key) {
        return get(getValue(), key, {
            checked: false,
            value: undefined,
        });
    }

    function handleChange(propertieValue, propertieKey) {
        onChange &&
            onChange(
                {
                    ...getValue(),
                    [propertieKey]: propertieValue,
                },
                name
            );
    }

    function renderPropertie(propertie) {
        const {
            type,
            key,
            displayName,
            disabled,
            checkboxDisabled,
            valueDisabled,
            controlConfig,
        } = propertie;
        const propertieValue = getPropertieValue(key);

        function onCheckedChange(e) {
            handleChange(
                {
                    ...propertieValue,
                    checked: e.target.checked,
                },
                key
            );
        }

        function onValueChange(value) {
            handleChange(
                {
                    ...propertieValue,
                    value,
                },
                key
            );
        }

        return (
            <div style={{ display: 'flex' }} key={key}>
                {displayName && <label style={{ marginRight: '8px' }}>{displayName}:</label>}
                <Checkbox
                    style={{ marginRight: '8px' }}
                    checked={propertieValue.checked}
                    disabled={globalDisabled || disabled || checkboxDisabled}
                    onChange={onCheckedChange}
                />
                {TypesManager.get(type).getControlComponent({
                    ...props,
                    ...controlConfig,
                    disabled: globalDisabled || disabled || valueDisabled,
                    value: propertieValue.value,
                    onChange: onValueChange,
                })}
            </div>
        );
    }

    return <div>{properties.map(renderPropertie)}</div>;
};
