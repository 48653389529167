import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';
import {
    MemberLevelAccessTypes,
    MemberLevelRelationTypes,
} from '../../../components/member-level-up-rule';
import { language } from '@comall-backend-builder/core/lib/services';
export let config: Config = {
    entities: {
        memberLevelsManage: {
            apiPath: '/loader/admin/memberLevels',
            properties: {
                id: { type: 'string' },
                deduct: {
                    displayName: '<<dqkc>>',
                    type: 'number.integer',
                },
                expRange: {
                    displayName: '<<jyzsxx>>',
                    type: 'string.valueRange',
                },
                level: {
                    type: 'number.integer',
                    displayName: '<<czdj>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ENABLED', name: '<<enable>>' },
                        { id: 'DISABLED', name: '<<disabled>>' },
                    ],
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string.text.trim',
                            displayName: '<<djmc>>',
                            controlConfig: {
                                style: {
                                    width: '360px',
                                },
                            },
                            rules: [
                                { required: true, message: '<<djmcbnwk>>' },
                                { max: 9, message: '<<djmcbncg>>' },
                                {
                                    validator: (_rule: any, value: any, callback: any) => {
                                        if (value && !value.trim()) {
                                            return callback(language.getText('djmcbnwk'));
                                        }
                                        callback();
                                    },
                                },
                            ],
                        },
                        iconPicture: {
                            displayName: '<<djtb>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                className: 'sigle-image-field',
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                limitTips: {
                                    limitText: '<<jIconPictureExtra>>',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                            rules: [{ required: true, message: '<<levelIconNotEmpty>>' }],
                        },
                    },
                },
                ruleConfig: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        levelProcess: {
                            displayName: '<<sfsjj>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: 'true',
                            options: [
                                { id: 'false', name: '<<no>>' },
                                { id: 'true', name: '<<yes>>' },
                            ],
                        },
                        validDays: {
                            displayName: '<<dynamicEffectivePeriodDay>>',
                            type: 'number.integer',
                            controlConfig: {
                                placeholder: '* <<wgdyxqcxbbtx>>',
                                style: {
                                    width: '360px',
                                },
                                min: 0,
                                max: 999999,
                            },
                        },
                        deduct: {
                            displayName: '<<dqkcjyz>>',
                            type: 'number.integer',
                            controlConfig: {
                                placeholder: '* <<wjyzcxbbtx>>',
                                style: {
                                    width: '360px',
                                },
                                min: 0,
                                max: 999999,
                            },
                        },
                        memberLevelUpRule: {
                            displayName: '<<hdtj>>',
                            type: 'object.memberLevelUpRule',
                            rules: [
                                { required: true },
                                {
                                    validator: (_rule: any, value: any, callback: any) => {
                                        const type = get(value, 'accessConditionType');
                                        const isFloat = (num: any) => /\D/g.test(num);
                                        if (!type) return callback(language.getText('qxzhdtj'));
                                        if (type === MemberLevelAccessTypes.ExperienceValue) {
                                            if (
                                                isFloat(value.expLower) ||
                                                isFloat(value.expUpper)
                                            ) {
                                                return callback(language.getText('qsrhfzs'));
                                            }
                                            if (value.expLower >= value.expUpper) {
                                                return callback(language.getText('jyzsxygdyxx'));
                                            }
                                        }
                                        if (type === MemberLevelAccessTypes.GrowthRules) {
                                            if (
                                                isFloat(value.totalNote) ||
                                                isFloat(value.videoNote) ||
                                                isFloat(value.topicNote) ||
                                                isFloat(value.totalLiked) ||
                                                isFloat(value.totalCollect)
                                            ) {
                                                return callback(language.getText('qsrhfzs'));
                                            }
                                            if (!value.relation) {
                                                return callback(language.getText('qxzgx'));
                                            }
                                            if (
                                                value.totalNote < value.videoNote ||
                                                value.totalNote < value.topicNote
                                            ) {
                                                return callback(
                                                    language.getText('bjzsbkxysphzhtbjs')
                                                );
                                            }
                                        }
                                        callback();
                                    },
                                },
                            ],
                            defaultValue: {
                                accessConditionType: MemberLevelAccessTypes.ExperienceValue,
                                level: 0,
                                expLower: 0,
                                expUpper: 0,
                                totalNote: 0,
                                videoNote: 0,
                                topicNote: 0,
                                totalLiked: 0,
                                relation: MemberLevelRelationTypes.Perhaps,
                                totalCollect: 0,
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<xqms>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        memberLevelsView: {
            component: 'Viewport',
            entity: 'memberLevelsManage',
        },
        memberLevelsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'memberLevelsTable' }],
        },
        memberLevelsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'memberLevelsManage',
            items: [
                {
                    component: 'Button',
                    text: '<<xzdj_1>>',
                    route: '/member-level-manage/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mb24',
                },
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'level',
                        },
                        {
                            property: 'baseInfo.name',
                        },
                        {
                            property: 'baseInfo.iconPicture',
                            displayConfig: {
                                style: {
                                    width: 80,
                                },
                            },
                        },
                        {
                            property: 'expRange',
                        },
                        {
                            property: 'ruleConfig.validDays',
                        },
                        {
                            property: 'deduct',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    ENABLED: {
                                        text: '<<enable>>',
                                        status: 'processing',
                                    },
                                    DISABLED: {
                                        text: '<<disabled>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/member-level-manage/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'MemberLevelListStatusBotton',
                            },
                        ],
                    },
                },
            ],
        },
        memberLevelsAddPage: {
            component: 'Card',
            content: { component: 'memberLevelsAddForm' },
        },
        memberLevelsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.iconPicture',
                    extra: '<<jIconPictureExtra>>',
                },
                {
                    property: 'ruleConfig.levelProcess',
                },
                {
                    property: 'ruleConfig.validDays',
                },
                {
                    property: 'ruleConfig.deduct',
                    extra: '<<dgjyzdyhydqjyzs>>',
                },
                {
                    property: 'ruleConfig.memberLevelUpRule',
                    visible: (values: any) => {
                        return get(values, 'ruleConfig.levelProcess') === 'true';
                    },
                },
                {
                    property: 'ruleConfig.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        memberLevelsEditPage: {
            component: 'Card',
            content: { component: 'memberLevelsEditForm' },
        },
        memberLevelsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'memberLevelsManage',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.iconPicture',
                    extra: '<<jIconPictureExtra>>',
                },
                {
                    property: 'ruleConfig.levelProcess',
                },
                {
                    property: 'ruleConfig.validDays',
                },
                {
                    property: 'ruleConfig.deduct',
                    extra: '<<dgjyzdyhydqjyzs>>',
                },
                {
                    property: 'ruleConfig.memberLevelUpRule',
                    visible: (values: any) => {
                        return get(values, 'ruleConfig.levelProcess') === 'true';
                    },
                },
                {
                    property: 'ruleConfig.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-level-manage',
                    breadcrumbName: '<<memberLevel>>',
                    component: 'memberLevelsView',
                    privilege: {
                        path: 'memberLevel',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/',
                            component: 'memberLevelsAddPage',
                            breadcrumbName: '<<xzhydj>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'memberLevelsEditPage',
                            breadcrumbName: '<<bjhydj>>',
                        },
                        { path: '/', component: 'memberLevelsPage' },
                    ],
                },
            ],
        },
    ],
};
