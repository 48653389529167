import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { ServicePriceType } from '../components/service-price';

const api = services.api;
export const ServiceReservedLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/service_reserved_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/service_reserved_activities/${id}`;
        } else {
            // if (data.executeStatus === 'ALL') {
            //     delete data.executeStatus;
            // }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    subsiteId: res.subSite.id,
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    totalQuantity: res.totalQuantity,
                    pictures: [{ id: res.pictures[0].id, path: res.pictures[0].url }],
                    phone: res.phone,
                    address: res.address,
                    timeRange: {
                        start: res.dayStartTime,
                        end: res.dayEndTime,
                    },
                    info: res.info,
                    sequence: res.sequence,
                };
                let servicePriceType = ServicePriceType.NONE;
                if (res.salePrice) {
                    servicePriceType = ServicePriceType.PAY;
                } else if (res.point) {
                    servicePriceType = ServicePriceType.POINT;
                }
                res.rulesInfo = {
                    limit: res.limit,
                    timeLimit: res.timeLimit,
                    servicePrice: {
                        type: servicePriceType,
                        price:
                            servicePriceType === ServicePriceType.PAY ? res.salePrice : undefined,
                        point: servicePriceType === ServicePriceType.POINT ? res.point : undefined,
                    },
                };
                res.serviceInfo = {
                    serviceReservedStock: {
                        totalQuantity: res.totalQuantity,
                        changeStock: undefined,
                        availableStock: res.availableStock,
                        defalutTotalQuantity: res.totalQuantity,
                        defalutAvailableStock: res.availableStock,
                    },
                    serviceFacilities: res.servicePlaces,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        id: item.id,
                        sequence: item.sequence,
                        subsiteName: item.subSite.name,
                        name: item.name,
                        startTime: item.startTime,
                        endTime: item.endTime,
                        stock: item.stock,
                        availableStock: item.availableStock,
                        status: item.status ? 'enabled' : 'disabled',
                        executeStatus: item.executeStatus,
                        reservationCount: item.reservationCount,
                        inProgressCount: item.inProgressCount,
                    };
                });
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/service_reserved_activities';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        if (params.id) {
            config.apiPath = `/admin/service_reserved_activities/${params.id}`;
        }
        const newParams: any = dataParser(params);
        newParams.id = params.id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        if (params.baseInfo.dateRange.start.length <= 10) {
            newParams.startTime = params.baseInfo.dateRange.start + ' 00:00:00';
            newParams.endTime = params.baseInfo.dateRange.end + ' 23:59:59';
        } else {
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
        }

        newParams.subSite = {
            id: params.baseInfo.subsiteId,
        };
        newParams.sequence = params.baseInfo.sequence;
        newParams.pictures = params.baseInfo.pictures.map((picture: any) => {
            return {
                id: picture.id,
                url: picture.path,
            };
        });
        // newParams.phone = params.baseInfo.phone;
        // newParams.address = params.baseInfo.address;
        newParams.dayEndTime = params.baseInfo.timeRange.end;
        newParams.dayStartTime = params.baseInfo.timeRange.start;
        newParams.info = params.baseInfo.info;
    }
    if (params.rulesInfo) {
        newParams.limit = params.rulesInfo.limit;
        newParams.timeLimit = params.rulesInfo.timeLimit;
        if (params.rulesInfo.servicePrice.type === ServicePriceType.PAY) {
            newParams.salePrice = params.rulesInfo.servicePrice.price;
        }
        if (params.rulesInfo.servicePrice.type === ServicePriceType.POINT) {
            newParams.point = params.rulesInfo.servicePrice.point;
        }
    }
    if (params.serviceInfo) {
        if (params.id) {
            newParams.changeStock = params.serviceInfo.serviceReservedStock.changeStock;
            newParams.totalQuantity = params.serviceInfo.serviceReservedStock.defalutTotalQuantity;
        } else {
            newParams.totalQuantity = params.serviceInfo.totalQuantity;
        }
        newParams.servicePlaces = params.serviceInfo.serviceFacilities;
    }
    return newParams;
}
