import React, { PureComponent } from 'react';
import { Table, Button, message, Popconfirm, InputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, remove, find, isNil } from 'lodash';
import {
    ProductSelectedDisplayFilter,
    ProductSelectedDisplayFilterStates,
} from './product-selected-display-filter';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface ProductSelectedDisplayForPresaleProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    showPresalePrice?: boolean;
    showQuantityLimit?: boolean;
    showBlindBoxSaleCount?: boolean;
    showBlindBoxWinningRate?: boolean;
    showMaotaiPreSaleInput?: boolean;
    pageType?: string;
    showFilter?: boolean;
}

interface ProductSelectedDisplayForPresaleStates {
    filterParams: ProductSelectedDisplayFilterStates;
}

/**
 * 预售活动选择商品展示和内容编辑
 */
class ProductSelectedDisplayForPresale extends PureComponent<
    ProductSelectedDisplayForPresaleProps,
    ProductSelectedDisplayForPresaleStates
> {
    state: ProductSelectedDisplayForPresaleStates = {
        filterParams: {},
    };
    selectedRows: any[] = [];

    getTableConfig = () => {
        const {
            modifiable,
            showPresalePrice,
            showQuantityLimit,
            showBlindBoxSaleCount,
            showBlindBoxWinningRate,
            showMaotaiPreSaleInput,
            pageType,
        } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 300,
                    render: (i: string) => (
                        <span title={i} className="presale-goods-field-name">
                            {i}
                        </span>
                    ),
                },
            ],
            scroll: {
                x: 1550,
                y: 500,
            },
        };

        if (showMaotaiPreSaleInput) {
            config.columns.push({
                title: services.language.getText('mcmgspsgsl'),
                dataIndex: 'maotaiQuantity',
                key: 'maotaiQuantity',
                width: 150,
                render: (i: number, row: any) => {
                    return (
                        <InputNumber
                            value={i}
                            min={0}
                            max={999999}
                            step={1}
                            precision={0}
                            onChange={(value: any) =>
                                this.changeValue('maotaiQuantity', value, row.id)
                            }
                        ></InputNumber>
                    );
                },
            });
            if (!pageType || pageType !== 'normal') {
                config.columns.push({
                    title: services.language.getText('mcmgspbmddsl'),
                    dataIndex: 'maotaiWhiteListQuantity',
                    key: 'maotaiWhiteListQuantity',
                    width: 180,
                    render: (i: number, row: any) => {
                        return (
                            <InputNumber
                                value={i}
                                min={0}
                                max={
                                    isNil(row.maotaiQuantity) ? 999999 : Number(row.maotaiQuantity)
                                }
                                step={1}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeValue('maotaiWhiteListQuantity', value, row.id)
                                }
                            ></InputNumber>
                        );
                    },
                });
            }
        }

        config.columns.push(
            {
                title: services.language.getText('goodName'),
                dataIndex: 'name',
                key: 'name',
                width: 300,
                render: (i: string) => (
                    <span title={i} className="presale-goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('productCode'),
                dataIndex: 'productCode',
                key: 'productCode',
                width: 150,
            },
            {
                title: services.language.getText('goodCode'),
                dataIndex: 'goodsCode',
                key: 'goodsCode',
                width: 150,
            },
            {
                title: services.language.getText('bigCode'),
                dataIndex: 'merchantBigCode',
                key: 'merchantBigCode',
                width: 150,
            },
            {
                title: services.language.getText('subsiteName'),
                dataIndex: 'subsiteName',
                key: 'subsiteName',
                width: 150,
            },
            {
                title: services.language.getText('merchantName'),
                dataIndex: 'merchantName',
                key: 'merchantName',
                width: 150,
            },
            {
                title: services.language.getText('xsj'),
                dataIndex: 'salesPrice',
                key: 'salesPrice',
                width: 120,
                align: 'center' as const,
            }
        );

        if (showPresalePrice) {
            config.columns.push({
                title: services.language.getText('ysj'),
                dataIndex: 'presalePrice',
                key: 'presalePrice',
                width: 150,
                render: (i: number, row: any) => {
                    return (
                        showPresalePrice && (
                            <InputNumber
                                value={i}
                                min={0}
                                max={999999}
                                step={1}
                                precision={2}
                                onChange={(value: any) =>
                                    this.changeValue('presalePrice', value, row.id)
                                }
                            ></InputNumber>
                        )
                    );
                },
            });
        }
        config.columns.push(
            {
                title: services.language.getText('jfj'),
                dataIndex: 'goodsDeductPoint',
                key: 'goodsDeductPoint',
                width: 120,
                align: 'center' as const,
            },
            {
                title: services.language.getText('sjkc'),
                dataIndex: 'goodsStock',
                key: 'goodsStock',
                width: 150,
                align: 'center' as const,
            }
        );
        if (showQuantityLimit) {
            config.columns.push({
                title: services.language.getText('limitUser'),
                dataIndex: 'quantityLimit',
                key: 'quantityLimit',
                align: 'center',
                width: 150,
                render: (i: number, row: any) => {
                    return (
                        showQuantityLimit && (
                            <span>
                                <InputNumber
                                    value={i}
                                    min={0}
                                    max={999999}
                                    step={1}
                                    precision={0}
                                    onChange={(value: any) =>
                                        this.changeValue('quantityLimit', value, row.id)
                                    }
                                ></InputNumber>{' '}
                                {services.language.getText('jian')}
                            </span>
                        )
                    );
                },
            });
        }
        if (showBlindBoxWinningRate) {
            config.columns.push({
                title: services.language.getText('zjl'),
                dataIndex: 'winningRate',
                key: 'winningRate',
                width: 120,
                align: 'center' as const,
                render: (i: number) => {
                    return <span>{i}%</span>;
                },
            });
        }
        if (showBlindBoxSaleCount) {
            config.columns.push({
                title: services.language.getText('mhkmkc'),
                dataIndex: 'quantity',
                key: 'quantity',
                align: 'center',
                fixed: 'right' as const,
                width: 150,
                render: (i: number, row: any) => {
                    return (
                        <span>
                            <InputNumber
                                disabled={!modifiable}
                                value={i}
                                min={0}
                                max={999999}
                                step={1}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeValue('quantity', value, row.id)
                                }
                            ></InputNumber>{' '}
                            {services.language.getText('jian')}
                        </span>
                    );
                },
            });
        }
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Popconfirm
                            onConfirm={this.onRemove(row.id)}
                            title={services.language.getText('qrscm')}
                            okText={services.language.getText('common.ok')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type={'link'}>
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>
                    );
                },
            });
            config.rowSelection = {
                fixed: true,
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    renderFooter = () => {
        return (
            <div className="presale-product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    onFilter = (filterParams: ProductSelectedDisplayFilterStates) => {
        this.setState({ filterParams });
    };

    filterFn = () => {
        const { filterParams } = this.state;
        if (!filterParams) {
            return () => true;
        }
        return (item: any) => {
            let result = true;
            for (const key in filterParams) {
                const filterValue = filterParams[key as keyof typeof filterParams]?.trim();
                const itemValue = item[key];
                if (!filterValue || itemValue?.includes(filterValue)) {
                    continue;
                }
                result = false;
                break;
            }
            return result;
        };
    };

    getDataSource = () => {
        const { data } = this.props;
        if (!data?.length) {
            return data;
        }
        return data.filter(this.filterFn());
    };

    render() {
        const { modifiable, data, showFilter } = this.props;
        let tableConfig = this.getTableConfig();
        const dataSource = this.getDataSource();
        const tableProps = { ...tableConfig, dataSource };
        const hiddenFilter = !showFilter || !data?.length;
        return (
            <div className={'product-select-display-for-presale ' + (modifiable ? 'modify' : '')}>
                <ProductSelectedDisplayFilter hidden={hiddenFilter} onFilter={this.onFilter} />
                <Table
                    {...tableProps}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    }}
                ></Table>
                {modifiable && this.renderFooter()}
            </div>
        );
    }
}

export { ProductSelectedDisplayForPresale };
