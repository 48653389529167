import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        FollowEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/interactivePromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'FOLLOW',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        rewardRule: {
                            type: 'string.options.radio',
                            displayName: '<<jllx>>',
                            defaultValue: 'CYCLE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'CYCLE', name: '<<mmjl>>' },
                                { id: 'LADDER', name: '<<jtjl>>' },
                            ],
                        },
                        cycleRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<cycleRewardRule>>',
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'FOLLOW' },
                                rewardStartText: '<<jmgz>>',
                                rewardUnit: '<<people>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: false,
                                },
                            },
                        },
                        ladderRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<jtsz>>',
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'FOLLOW' },
                                rewardStartText: '<<jmgz>>',
                                rewardUnit: '<<people>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: true,
                                    maxCount: 10,
                                },
                            },
                        },
                        rewardCalculation: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<jljs>>',
                            defaultValue: ['FIRST'],
                            options: [
                                {
                                    id: 'FIRST',
                                    name: '<<jzyscgztrzsjl>>',
                                },
                            ],
                            extra: '<<zzygztrjzsjl>>',
                        },
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<jlsx_1>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdsjnhd>>',
                                addonafter: '<<bzhdjl_1>>',
                                tip: '',
                            },
                            extra: '<<activityRewardLimitExtra>>',
                        },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        BeFollowEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/interactivePromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'BE_FOLLOW',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        rewardRule: {
                            type: 'string.options.radio',
                            displayName: '<<jllx>>',
                            defaultValue: 'CYCLE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'CYCLE', name: '<<mmjl>>' },
                                { id: 'LADDER', name: '<<jtjl>>' },
                            ],
                        },
                        cycleRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<cycleRewardRule>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'BE_FOLLOW' },
                                rewardStartText: '<<mb_1>>',
                                rewardEndText: '<<gzhdyxjl>>',
                                rewardUnit: '<<people>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: false,
                                },
                            },
                        },
                        ladderRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<jtsz>>',
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'BE_FOLLOW' },
                                rewardStartText: '<<mb_1>>',
                                rewardEndText: '<<gzhdyxjl>>',
                                rewardUnit: '<<people>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: true,
                                    maxCount: 10,
                                },
                            },
                        },
                        rewardCalculation: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<jljs>>',
                            options: [{ id: '', name: '' }],
                        },
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<jlsx_1>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdsjnhd>>',
                                addonafter: '<<bzhdjl_1>>',
                                tip: '',
                            },
                            extra: '<<dszmztjhdjl>>',
                        },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        MutualFollowEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/interactivePromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'MUTUAL_FOLLOW',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        rewardRule: {
                            type: 'string.options.radio',
                            displayName: '<<jllx>>',
                            defaultValue: 'CYCLE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'CYCLE', name: '<<mmjl>>' },
                                { id: 'LADDER', name: '<<jtjl>>' },
                            ],
                        },
                        cycleRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<cycleRewardRule>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'MUTUAL_FOLLOW' },
                                rewardStartText: '<<mxhgz>>',
                                rewardUnit: '<<d_3>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: false,
                                },
                            },
                        },
                        ladderRewardRule: {
                            type: 'object.rewardRules',
                            displayName: '<<jtsz>>',
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'MUTUAL_FOLLOW' },
                                rewardStartText: '<<mxhgz>>',
                                rewardUnit: '<<people>>',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: true,
                                    maxCount: 10,
                                },
                            },
                        },
                        rewardCalculation: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<jljs>>',
                            defaultValue: ['FIRST'],
                            options: [
                                {
                                    id: 'FIRST',
                                    name: '<<zyscxhgzzsjl>>',
                                },
                            ],
                            extra: '<<rewardCalculationExtra3>>',
                        },
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<jlsx_1>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdsjnhd>>',
                                addonafter: '<<bzhdjl_1>>',
                                tip: '',
                            },
                            extra: '<<dszmztjhdjl>>',
                        },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        InteractivePromotionEncourageView: {
            component: 'Viewport',
        },
        InteractivePromotionEncouragePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'InteractivePromotionEncourageTables' }],
        },
        InteractivePromotionEncourageTables: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<gztr>>',
                    content: {
                        component: 'FollowPage',
                    },
                },
                {
                    title: '<<btrgz>>',
                    content: {
                        component: 'BeFollowPage',
                    },
                },
                {
                    title: '<<jlhy>>',
                    content: {
                        component: 'MutualFollowPage',
                    },
                },
            ],
        },
        FollowPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'FollowEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        { component: 'FollowPageFilter', params: { eventType: 'FOLLOW' } },
                        {
                            component: 'GridLayout',
                            className: 'mt24 mb24',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzhdjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/interactive-promotion-encourage/add-FOLLOW',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'InteractivePromotionEncourageTable',
                    params: { eventType: 'FOLLOW' },
                },
            ],
        },
        BeFollowPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'BeFollowEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        { component: 'BeFollowPageFilter', params: { eventType: 'BE_FOLLOW' } },
                        {
                            component: 'GridLayout',
                            className: 'mt24 mb24',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzhdjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/interactive-promotion-encourage/add-BE_FOLLOW',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'InteractivePromotionEncourageTable',
                    params: { eventType: 'BE_FOLLOW' },
                },
            ],
        },
        MutualFollowPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MutualFollowEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        {
                            component: 'MutualFollowPageFilter',
                            params: { eventType: 'MUTUAL_FOLLOW' },
                        },
                        {
                            component: 'GridLayout',
                            className: 'mt24 mb24',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzhdjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/interactive-promotion-encourage/add-MUTUAL_FOLLOW',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'InteractivePromotionEncourageTable',
                    params: { eventType: 'MUTUAL_FOLLOW' },
                },
            ],
        },
        FollowPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        BeFollowPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        MutualFollowPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        InteractivePromotionEncourageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/creation_activities/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/interactive_activities/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path:
                                    '/interactive-promotion-encourage/edit-{{row.eventType}}/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        FollowAddPage: {
            component: 'FlexLayout',
            entity: 'FollowEntity',
            direction: 'horizontal',
            items: [{ component: 'InteractivePromotionEncourageAddFormView' }],
        },
        FollowEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'FollowEntity',
            direction: 'horizontal',
            items: [{ component: 'InteractivePromotionEncourageEditFormView' }],
        },
        BeFollowAddPage: {
            component: 'FlexLayout',
            entity: 'BeFollowEntity',
            direction: 'horizontal',
            items: [{ component: 'InteractivePromotionEncourageAddFormView' }],
        },
        BeFollowEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'BeFollowEntity',
            direction: 'horizontal',
            items: [{ component: 'InteractivePromotionEncourageEditFormView' }],
        },
        MutualFollowAddPage: {
            component: 'FlexLayout',
            entity: 'MutualFollowEntity',
            direction: 'horizontal',
            items: [{ component: 'InteractivePromotionEncourageAddFormView' }],
        },
        MutualFollowEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'MutualFollowEntity',
            direction: 'horizontal',
            items: [{ component: 'InteractivePromotionEncourageEditFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/interactive-promotion-encourage',
                    component: 'InteractivePromotionEncourageView',
                    breadcrumbName: '<<interactivePromotionEncourage>>',
                    privilege: {
                        path: 'interactivePromotionEncourage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-FOLLOW',
                            component: 'FollowAddPage',
                            breadcrumbName: '<<xzhdjl>>',
                        },
                        {
                            path: '/edit-FOLLOW/:id',
                            component: 'FollowEditPage',
                            breadcrumbName: '<<bjhdjl>>',
                        },
                        {
                            path: '/add-BE_FOLLOW',
                            component: 'BeFollowAddPage',
                            breadcrumbName: '<<xzhdjl>>',
                        },
                        {
                            path: '/edit-BE_FOLLOW/:id',
                            component: 'BeFollowEditPage',
                            breadcrumbName: '<<bjhdjl>>',
                        },
                        {
                            path: '/add-MUTUAL_FOLLOW',
                            component: 'MutualFollowAddPage',
                            breadcrumbName: '<<xzhdjl>>',
                        },
                        {
                            path: '/edit-MUTUAL_FOLLOW/:id',
                            component: 'MutualFollowEditPage',
                            breadcrumbName: '<<bjhdjl>>',
                        },
                        { path: '/', component: 'InteractivePromotionEncouragePage' },
                    ],
                },
            ],
        },
    ],
};
