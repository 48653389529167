import React, { Component } from 'react';
import {
    Row as AntRow,
    InputNumber,
    Button,
    message,
    Checkbox,
    Popconfirm,
    Input,
    message as AntMessage,
} from 'antd';
import { clone, map, concat, isEmpty } from 'lodash';
import { DatePicker, ImageUpload } from '@comall-backend-builder/components-basis';
import moment from 'moment';

import './index.less';
import { ActivityRewardItem } from '../activity-reward-item';
import { Coupon } from '../../containers/select-coupon';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

type ActivitySignInRewardValue = {
    /**
     * 顶部输入值
     */
    stepValue?: any;
    /**
     * 积分
     */
    pointChecked?: boolean;
    point?: number;
    /**
     * HI币
     */
    goldChecked?: boolean;
    gold?: number;
    /**
     * 经验值
     */
    experienceChecked?: boolean;
    experience?: number;
    /**
     * 优惠券
     */
    couponsChecked?: boolean;
    couponsValue: Array<Coupon>;
    /**
     * 自定义
     */
    customChecked?: boolean;
    custom?: string;
    /**
     * 礼物图
     */
    ladderGiftPicture?: any;
    id?: number;
};

/**
 * 奖励的类型，multi: 阶梯奖励, single: 单个奖励
 */
enum RewardType {
    multi = 'multi',
    single = 'single',
}

enum RewardItems {
    stepValue = 'STEPVALUE',
    point = 'POINT',
    gold = 'GOLD',
    experience = 'EXPERIENCE',
    coupons = 'COUPON',
    picture = 'PICTURE',
    custom = 'CUSTOM',
}

export interface ActivitySignInRewardProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: Array<ActivitySignInRewardValue> | null;

    /**
     * 奖励的类型，multi: 阶梯奖励, single: 单个奖励
     */
    rewardType: 'multi' | 'single';

    /**
     * 奖励最大数量
     */
    maxCount: number;

    /**
     * 候选项集合
     */
    options: Array<any>;

    /**
     * 阶梯值文本配置
     */
    stepValueTextConfig?: {
        /**
         * 起始文本
         */
        start: string;
        /**
         * 结束文本
         */
        end: string;
        /**
         * 阶梯值单位
         */
        unit: string;
        /**
         * 输入类型
         */
        inputComponentType: 'InputNumber' | 'DatePicker';
    };
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<ActivitySignInRewardValue> | null, name: string) => void;
}
export class ActivitySignInReward extends Component<ActivitySignInRewardProps> {
    static defaultProps = {
        maxCount: 3,
        rewardType: 'multi',
        disabled: false,
        stepValueTextConfig: {
            inputComponentType: 'InputNumber',
        },
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: ActivitySignInRewardValue = { couponsValue: [] };
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAddReward() {
        let { name, value, onChange, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning(
                    language.getText('mostSupport') + maxCount + language.getText('notAdd')
                );
                return;
            }
            value = concat([], value);
            value.splice(value.length, 0, { couponsValue: [] });
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: ActivitySignInRewardValue = { couponsValue: [] };
                value = concat([], item);
            }
        } else {
            let item: ActivitySignInRewardValue = { couponsValue: [] };
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        const { inputComponentType = 'InputNumber' } = this.props.stepValueTextConfig || {
            inputComponentType: 'InputNumber',
        };
        let inputValue = event;
        var reg = /^\+?[0-9]\d*$/;
        if (
            inputComponentType === 'InputNumber' &&
            type !== RewardItems.custom &&
            inputValue !== null &&
            inputValue !== '' &&
            !reg.test(inputValue)
        ) {
            AntMessage.warning(services.language.getText('inputNumber'));
            inputValue = null;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            //TODO 后续迭代需要支持Hi贝
            if (type === RewardItems.custom) {
                inputValue = event.target.value;
                item.custom = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivitySignInRewardValue = { couponsValue: [] };
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.custom) {
                inputValue = event.target.value;
                item.custom = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.custom) {
                item.customChecked = checkboxValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivitySignInRewardValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.custom) {
                item.customChecked = checkboxValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value, rewardType, maxCount, disabled } = this.props;

        return (
            <div className="activity-reward">
                {map(value, (reward, index) => this.renderReward(reward, index, disabled))}
                {value && value.length
                    ? null
                    : this.renderReward({ couponsValue: [] }, 0, disabled)}
                {rewardType === RewardType.multi && value && value.length < maxCount && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {language.getText('addRule')}
                    </Button>
                )}
            </div>
        );
    }

    onChangeCoupon = (products: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.couponsValue = products;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivitySignInRewardValue = { couponsValue: [] };
            item.couponsValue = products;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    onPicChange = (ladderGiftPicture: any, index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.ladderGiftPicture = ladderGiftPicture;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivitySignInRewardValue = { couponsValue: [] };
            item.ladderGiftPicture = ladderGiftPicture;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    renderStepValue(reward: ActivitySignInRewardValue, index: number, isDisabled: boolean) {
        const { inputComponentType = 'InputNumber' } = this.props.stepValueTextConfig || {
            inputComponentType: 'InputNumber',
        };

        switch (inputComponentType) {
            case 'InputNumber': {
                return (
                    <InputNumber
                        type="number"
                        min={1}
                        value={reward.stepValue}
                        onChange={(e) => this.onInputValueChange(e, index, RewardItems.stepValue)}
                    />
                );
            }
            case 'DatePicker': {
                return (
                    <DatePicker
                        disabled={isDisabled}
                        name={index + ''}
                        value={reward.stepValue}
                        onChange={
                            ((e: any) =>
                                this.onInputValueChange(e, index, RewardItems.stepValue)) as any
                        }
                    />
                );
            }
        }
    }

    renderReward(reward: ActivitySignInRewardValue, index: number, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const { options, rewardType, stepValueTextConfig } = this.props;
        const hideDelete =
            isDisabled &&
            reward.id &&
            reward.stepValue &&
            moment().isSameOrAfter(reward.stepValue, 'day');

        let _imageControlInfo = Object.assign(
            {},
            {
                name: 'ladderGiftPicture',
                fileName: 'fileName',
                value: reward.ladderGiftPicture,
                accept: 'image/*',
                uploadType: 'single',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                multiple: false,
                maxCount: 1,
                limitTips: {
                    limitText: language.getText('rewardPicLimit'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                },
            }
        );
        let stepValue: any;
        let point: any;
        let experience: any;
        let coupons: any;
        let custom: any;
        let picture: any;
        map(options, (option, index) => {
            if (option.code === RewardItems.stepValue) {
                stepValue = option;
            } else if (option.code === RewardItems.point) {
                point = option;
            } else if (option.code === RewardItems.experience) {
                experience = option;
            } else if (option.code === RewardItems.coupons) {
                coupons = option;
            } else if (option.code === RewardItems.custom) {
                custom = option;
            } else if (option.code === RewardItems.picture) {
                picture = option;
            }
        });

        return (
            <div className="reward" key={index}>
                {stepValue && (
                    <AntRow>
                        <div className="reward-content">
                            <span className="reward-item-text">
                                {stepValueTextConfig ? stepValueTextConfig.start : ''}
                            </span>
                            <div className="reward-count-inputDate">
                                {this.renderStepValue(reward, index, !!reward.id && isDisabled)}
                            </div>
                            <span className="reward-item-text">
                                {stepValueTextConfig ? stepValueTextConfig.unit : ''}
                                {stepValueTextConfig ? stepValueTextConfig.end : ''}
                            </span>
                            {rewardType === RewardType.multi && !isEmpty(reward) && !hideDelete && (
                                <Popconfirm
                                    onConfirm={this.onRemoveReward.bind(this, index)}
                                    title={services.language.getText('sfscggz')}
                                    okText={services.language.getText('common.ok')}
                                    cancelText={services.language.getText('common.cancel')}
                                >
                                    <Button type="default" className="reward-remove-reward-button">
                                        {language.getText('scgz')}
                                    </Button>
                                </Popconfirm>
                            )}
                        </div>
                    </AntRow>
                )}

                <div className="reward-content-list">
                    {point && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) => this.onCheckboxChange(e, index, RewardItems.point)}
                                checked={reward.pointChecked}
                                value="point"
                            >
                                {point.name}
                            </Checkbox>
                            <span className="reward-item-text">{language.getText('song')}</span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.point}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.point)
                                }
                            />
                            <span className="reward-item-text">{point.name}</span>
                        </div>
                    )}
                    {experience && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.experience)
                                }
                                checked={reward.experienceChecked}
                                value="experience"
                            >
                                {experience.name}
                            </Checkbox>
                            <span className="reward-item-text">{language.getText('song')}</span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.experience}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.experience)
                                }
                            />
                            <span className="reward-item-text">{language.getText('value')}</span>
                        </div>
                    )}

                    {coupons && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.coupons)
                                }
                                checked={reward.couponsChecked}
                                value="coupons"
                            >
                                {coupons.name}
                            </Checkbox>
                        </div>
                    )}
                </div>
                {coupons && (
                    <div className="reward-coupons">
                        <ActivityRewardItem
                            index={index}
                            value={reward.couponsValue}
                            subsiteIds={subsiteIds}
                            onChangeCoupon={this.onChangeCoupon}
                            type="checkbox"
                            packageSelectType="radio"
                            canChangeQuantity
                            hideIssuedNum
                        />
                    </div>
                )}
                {custom && (
                    <div className="reward-content-list">
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.custom)
                                }
                                checked={reward.customChecked}
                                value="custom"
                            >
                                {custom.name}
                            </Checkbox>
                            <Input
                                className="reward-content-item-text"
                                placeholder={language.getText('trdwzjzszyqylhdyz')}
                                type="text"
                                maxLength={20}
                                value={reward.custom}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.custom)
                                }
                            />
                        </div>
                    </div>
                )}
                {picture && (
                    <div className="reward-pic">
                        <span className="reward-pic-text">{picture.name}</span>
                        <ImageUpload
                            {..._imageControlInfo}
                            onChange={(value: any, name: string) => {
                                this.onPicChange(value, index);
                                return {};
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };
}
