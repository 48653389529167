import { services } from '@comall-backend-builder/core/';
import { isEmpty, forEach } from 'lodash';

export const NewPageTemplateOptionsLoader = {
    get: async function(data: any, config: any) {
        return services.api.get(data, config).then((res: any) => {
            if (res && !isEmpty(res.result)) {
                forEach(res.result, (item) => {
                    item.preview = item.preview ? [item.preview] : []
                })
            }
            return res;
        });
    }
};
