import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import get from 'lodash/get';

/**
 *  字符串描述
 */
export class StringDescMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const content = get(displayInfo, 'value');
        const style = get(displayInfo, 'style');
        if (content) {
            return (
                <div>
                    <div style={style}>{content}</div>
                </div>
            );
        }
        return <div></div>;
    }

    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        const content = get(displayInfo, 'value');
        const style = get(displayInfo, 'style');
        if (content) {
            return (
                <div>
                    <div style={style}>{content}</div>
                </div>
            );
        }
        return <div></div>;
    }
}
