import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { ExportFilterType } from '../../../containers/export-data-button-by-filter';
import { EntityButtonProps } from '../../../components';
import get from 'lodash/get';
import { checkLinkTypeParamsRequired } from '../../../types/format';

export enum StatisticType {
    /**
     * 按门店统计
     * */
    CHANNEL = 'CHANNEL',
    /**
     * 按专柜统计（批量）
     */
    MERCHANT = 'MERCHANT',
}

export const STATISTIC_TYPE_OPTIONS = [
    {
        id: StatisticType.CHANNEL,
        name: '<<amdtj>>',
    },
    {
        id: StatisticType.MERCHANT,
        name: '<<azjtj>>',
    },
];
export const config: Config = {
    entities: {
        miniprogramV2Entity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/miniprogramCodeV2',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tgjkmc>>',
                    controlConfig: { placeholder: '<<qsrtgjkmc>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<tgmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
                urlLink: {
                    type: 'string',
                    displayName: '<<ljdz>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                    },
                },
                channelType: {
                    type: 'string',
                    displayName: '<<qdlx>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<tgjk>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<tgjkmc>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<qtxtgjkmc>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                nameStr: {
                    type: 'string.popover',
                    displayName: '<<tgjkmc>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<tgmd>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<tgzj>>',
                },

                pageName: {
                    type: 'string',
                    displayName: '<<tgym>>',
                },
                statisticType: {
                    type: 'string.options.radio',
                    displayName: '<<statisticType>>',
                    defaultValue: StatisticType.CHANNEL,
                    options: STATISTIC_TYPE_OPTIONS,
                    rules: [{ required: true }],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                urlLink: {
                    type: 'string',
                    displayName: '<<dlj>>',
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<xztgmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                merchantIds: {
                    type: 'array.shop.selector',
                    displayName: '<<selectSubsites>>',
                    rules: [{ required: true, type: 'array' }],
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                    },
                    // extra: '创建二维码成功后，二维码名称为“专柜名称+活动名称”',
                },
                target: {
                    type: 'object.subsiteLinkTypeSelector',
                    displayName: '<<mbldy>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                url: {
                    type: 'string',
                    displayName: '<<ymlj>>',
                    rules: [{ whitespace: true }],
                    controlConfig: {
                        placeholder: '',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                    extra: '<<rxfzqfhlbfzzqlj>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<tgqd>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<qtxtgqd>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                channelType: {
                    type: 'string',
                    displayName: '<<qdlx>>',
                    controlConfig: {
                        placeholder: '<<onlineAndOffline>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
        },
        miniprogramV2StaffEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/miniprogramCodeV2Staff',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<tgjk>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<tgjkmc>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<qtxtgjkmc>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                statisticType: {
                    type: 'string.options.radio',
                    displayName: '<<statisticType>>',
                    defaultValue: StatisticType.CHANNEL,
                    options: STATISTIC_TYPE_OPTIONS,
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<xztgmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                merchantIds: {
                    type: 'array.shop.selector',
                    displayName: '<<selectSubsites>>',
                    rules: [{ type: 'array' }],
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                    },
                    // extra: '创建二维码成功后，二维码名称为“专柜名称+活动名称”',
                },
                target: {
                    type: 'object.subsiteLinkTypeSelector',
                    displayName: '<<mbldy>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                url: {
                    type: 'string',
                    displayName: '<<ymlj>>',
                    rules: [{ whitespace: true }],
                    controlConfig: {
                        placeholder: '',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                    extra: '<<rxfzqfhlbfzzqlj>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<tgqd>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<qtxtgqd>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                channelType: {
                    type: 'string',
                    displayName: '<<qdlx>>',
                    controlConfig: {
                        placeholder: '<<onlineAndOffline>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
            },
        },
        miniprogramV2MerchantEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/miniprogramCodeV2Merchant',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<tgjk>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<tgjkmc>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<qtxtgjkmc>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                statisticType: {
                    type: 'string.options.radio',
                    displayName: '<<statisticType>>',
                    defaultValue: StatisticType.CHANNEL,
                    options: STATISTIC_TYPE_OPTIONS,
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<xztgmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                merchantIds: {
                    type: 'array.shop.selector',
                    displayName: '<<selectSubsites>>',
                    rules: [{ type: 'array' }],
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                    },
                    // extra: '创建二维码成功后，二维码名称为“专柜名称+活动名称”',
                },
                target: {
                    type: 'object.subsiteLinkTypeSelector',
                    displayName: '<<mbldy>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                url: {
                    type: 'string',
                    displayName: '<<ymlj>>',
                    rules: [{ whitespace: true }],
                    controlConfig: {
                        placeholder: '',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                    extra: '<<rxfzqfhlbfzzqlj>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<tgqd>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<qtxtgqd>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
                channelType: {
                    type: 'string',
                    displayName: '<<qdlx>>',
                    controlConfig: {
                        placeholder: '<<onlineAndOffline>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 100,
                    },
                },
            },
        },
        miniProgramCodeDefaultLinksEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/miniProgramCodeDefaultLinks',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<tgmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<tgmd>>',
                },
                pageName: {
                    type: 'string',
                    displayName: '<<tgym>>',
                },
                lastModifiedTime: {
                    type: 'string',
                    displayName: '<<scxgsj>>',
                },
            },
        },
    },
    components: {
        miniprogramV2View: {
            component: 'Viewport',
            // entity: 'miniprogramV2Entity',
        },
        miniprogramV2Pages: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'miniprogramV2Tabs' }],
        },
        miniprogramV2Tabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<qdm>>',
                    content: {
                        component: 'miniprogramV2Page',
                    },
                    params: {
                        showTab: 'MINI_PROGRAM_CODE',
                    },
                },
                {
                    title: '<<ygm>>',
                    content: {
                        component: 'MiniprogramV2StaffPage',
                    },
                    params: {
                        showTab: 'GUIDE_CODE',
                    },
                },
                {
                    title: '<<zjm>>',
                    content: {
                        component: 'MiniprogramV2MerchantsCodePage',
                    },
                    params: {
                        showTab: 'MERCHANT_CODE',
                    },
                },
                {
                    title: '<<ymm>>',
                    content: {
                        component: 'miniprogramV2PagesCodePage',
                    },
                    params: {
                        showTab: 'PAGE_CODE',
                    },
                },
            ],
        },
        miniprogramV2PagesCodePage: {
            entity: 'miniprogramV2Entity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'miniprogramV2PagesCodeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [],
                        },
                    ],
                },
                { component: 'miniprogramV2PagesCodeTable' },
            ],
        },
        miniprogramV2PagesCodeFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'urlLink',
                },
                {
                    property: 'channelType',
                },
            ],
        },
        miniprogramV2PagesCodeTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'id',
                },
                {
                    property: 'nameStr',
                    width: 180,
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'pageName',
                },
                {
                    property: 'statisticType',
                },
                {
                    property: 'channel',
                },
                {
                    property: 'channelType',
                },
                {
                    property: 'channels',
                },
                {
                    property: 'createTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                fixed: 'right',
                width: 200,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/miniprogram-v2/edit-page/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'MiniprogramV2PreviewButton',
                        params: {
                            applicationVersion: 'V2',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButtonByFilter',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/mini_program_codes/{{row.id}}/export',
                            text: '<<dcsj_1>>',
                            filters: [ExportFilterType.TIME],
                            isAsync: true,
                        },
                    },
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                style: {
                                    padding: 0,
                                    marginRight: '10px',
                                },
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.statisticType === StatisticType.MERCHANT,
                                children: '<<fzlj>>',
                                onClick(_: any, entityButtonProps: EntityButtonProps) {
                                    let input = document.createElement('input');
                                    input.value = `/subpackages/miniprogram-code/index?mid=${entityButtonProps.row.id}`;
                                    document.body.appendChild(input);
                                    input.select();
                                    if (document.execCommand('Copy')) {
                                        AntMessage.success(services.language.getText('ljdzyfz_1'));
                                    } else {
                                        AntMessage.warning(services.language.getText('ljdzfzsb'));
                                    }
                                    document.body.removeChild(input);
                                },
                            },
                        },
                    },
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                style: {
                                    padding: 0,
                                    marginRight: '10px',
                                },
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.statisticType ===
                                        StatisticType.MERCHANT || !entityButtonProps.row.urlLink,
                                children: '<<fzdl>>',
                                onClick(_: any, entityButtonProps: EntityButtonProps) {
                                    let input = document.createElement('input');

                                    const { urlLink, alipayUrlLink } = entityButtonProps.row;
                                    let value = alipayUrlLink
                                        ? services.language.getText('wx') +
                                          `：${urlLink}${services.language.getText(
                                              'zfb'
                                          )}：${alipayUrlLink}`
                                        : `${services.language.getText('wx')}：${urlLink}`;
                                    input.value = value;
                                    document.body.appendChild(input);
                                    input.select();
                                    if (document.execCommand('Copy')) {
                                        AntMessage.success(services.language.getText('dldzyfz'));
                                    } else {
                                        AntMessage.warning(services.language.getText('dldzfzsb'));
                                    }
                                    document.body.removeChild(input);
                                },
                            },
                        },
                    },
                ],
            },
        },
        miniprogramV2Page: {
            entity: 'miniprogramV2Entity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'miniprogramV2Filter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<cjqdm>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    // span: 4,
                                    route: '/menu-list/market/miniprogram-v2/add',
                                },
                                {
                                    component: 'MiniProgramParamSetting',
                                    privilege: {
                                        path: 'miniProgramCodeParam',
                                        level: 'full',
                                    },
                                },
                                // {
                                //     component: 'MarketMiniProgramLogoSetting',
                                // },
                            ],
                        },
                    ],
                },
                { component: 'miniprogramV2Table' },
            ],
        },
        miniprogramV2Filter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'urlLink',
                },
                {
                    property: 'channelType',
                },
            ],
        },
        miniprogramV2Table: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'id',
                },
                {
                    property: 'nameStr',
                    width: 180,
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'pageName',
                },
                {
                    property: 'statisticType',
                },
                {
                    property: 'channel',
                },
                {
                    property: 'channelType',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'channels',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                width: 250,
                className: 'column-left action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/miniprogram-v2/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'MiniprogramV2PreviewButton',
                        params: {
                            applicationVersion: 'V2',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButtonByFilter',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/mini_program_codes/{{row.id}}/export',
                            text: '<<dcsj_1>>',
                            filters: [ExportFilterType.TIME],
                            isAsync: true,
                        },
                    },
                    {
                        type: 'component',
                        component: 'MiniprogramCopyOrDownloadButton',
                    },
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.statisticType ===
                                        StatisticType.MERCHANT || !entityButtonProps.row.urlLink,
                                children: '<<fzdl>>',
                                style: {
                                    paddingLeft: 0,
                                },
                                onClick(_: any, entityButtonProps: EntityButtonProps) {
                                    let input = document.createElement('input');

                                    const { urlLink, alipayUrlLink } = entityButtonProps.row;
                                    let value = alipayUrlLink
                                        ? services.language.getText('wx') +
                                          `：${urlLink}${services.language.getText(
                                              'zfb'
                                          )}：${alipayUrlLink}`
                                        : `${services.language.getText('wx')}：${urlLink}`;
                                    input.value = value;
                                    document.body.appendChild(input);
                                    input.select();
                                    if (document.execCommand('Copy')) {
                                        AntMessage.success(services.language.getText('dldzyfz'));
                                    } else {
                                        AntMessage.warning(services.language.getText('dldzfzsb'));
                                    }
                                    document.body.removeChild(input);
                                },
                            },
                        },
                    },
                ],
            },
        },
        miniprogramV2AddPage: {
            component: 'Card',
            entity: 'miniprogramV2Entity',
            content: {
                component: 'MiniprogramV2AddFormView',
                hideNeedUrlLink: false,
                applicationVersion: 'V2',
            },
        },
        miniprogramV2EditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MiniprogramV2EditFormView' }],
        },
        MiniprogramV2EditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'miniprogramV2Entity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'statisticType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
                },
                {
                    property: 'merchantIds',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        oldDataDeleteable: false,
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'MERCHANT',
                },
                {
                    property: 'channels',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'target',
                    controlConfig: {
                        needUrlLinkExplain: '<<schyxq>>',
                        disabled: true,
                        bussinessType: 'miniprogram-v2',
                    },
                },
                {
                    property: 'url',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => false,
                },
                {
                    property: 'channel',
                    controlConfig: {
                        disabled: true,
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
                },
                {
                    property: 'channelType',
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        miniprogramV2StaffEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'miniprogramV2StaffEditFormView' }],
        },
        miniprogramV2StaffEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'miniprogramV2StaffEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'statisticType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
                },
                {
                    property: 'merchantIds',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        oldDataDeleteable: false,
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'MERCHANT',
                },
                {
                    property: 'target',
                    controlConfig: {
                        needUrlLinkExplain: '<<schyxq>>',
                        bussinessType: 'miniprogram-v2-staff',
                        allShowNeedUrlLink: true,
                        disabledNeedUrlLink: true,
                    },
                },
                {
                    property: 'url',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => false,
                },
                {
                    property: 'channel',
                    controlConfig: {
                        disabled: true,
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
                },
                {
                    property: 'channelType',
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                //链接类型校验
                const target = get(entityFields, 'target', null);
                if (!target || !target.linkType) {
                    AntMessage.warning(services.language.getText('qxzljlx'));
                    return false;
                }
                if (target && !checkLinkTypeParamsRequired(target.linkType, target.linkParams)) {
                    AntMessage.warning(services.language.getText('selectLinkTarget'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        miniprogramV2MerchantEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'miniprogramV2MerchantEditFormView' }],
        },
        miniprogramV2MerchantEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'miniprogramV2MerchantEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'statisticType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
                },
                {
                    property: 'merchantIds',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        oldDataDeleteable: false,
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'MERCHANT',
                },
                {
                    property: 'target',
                    controlConfig: {
                        needUrlLinkExplain: '<<schyxq>>',
                        bussinessType: 'miniprogram-v2-merchant',
                        allShowNeedUrlLink: true,
                        disabledNeedUrlLink: true,
                    },
                },
                {
                    property: 'url',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => false,
                },
                {
                    property: 'channel',
                    controlConfig: {
                        disabled: true,
                    },
                    visible: (values: any) => get(values, 'statisticType') === 'CHANNEL',
                },
                {
                    property: 'channelType',
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                //链接类型校验
                const target = get(entityFields, 'target', null);
                if (!target || !target.linkType) {
                    AntMessage.warning(services.language.getText('qxzljlx'));
                    return false;
                }
                if (target && !checkLinkTypeParamsRequired(target.linkType, target.linkParams)) {
                    AntMessage.warning(services.language.getText('selectLinkTarget'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        miniProgramCodeDefaultLinksPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'miniProgramCodeDefaultLinksEntity',
            items: [
                {
                    component: 'miniProgramCodeDefaultLinks',
                },
            ],
        },
        miniProgramCodeDefaultLinks: {
            component: 'FlexLayout',
            entity: 'miniProgramCodeDefaultLinksEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'miniProgramCodeDefaultLinksFilter',
                    style: {
                        marginBottom: 16,
                    },
                },
                {
                    component: 'miniProgramCodeDefaultLinksTable',
                },
            ],
        },
        miniProgramCodeDefaultLinksFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        miniProgramCodeDefaultLinksTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'pageName',
                },
                {
                    property: 'lastModifiedTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 120,
                items: [
                    {
                        type: 'component',
                        component: 'MiniProgramCodeDefaultLinkEditButton',
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/miniprogram-v2',
                    component: 'miniprogramV2View',
                    breadcrumbName: '<<qdtgm>>',
                    privilege: {
                        path: 'miniProgramCodeV2',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'miniprogramV2AddPage',
                            breadcrumbName: '<<cjqdm>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'miniprogramV2EditPage',
                            breadcrumbName: '<<bjqdm>>',
                        },
                        {
                            path: '/edit-page/:id',
                            component: 'miniprogramV2EditPage',
                            breadcrumbName: '<<bjymm>>',
                        },
                        {
                            path: '/edit-staff/:id',
                            component: 'miniprogramV2StaffEditPage',
                            breadcrumbName: '<<bjygm>>',
                        },
                        {
                            path: '/edit-merchant/:id',
                            component: 'miniprogramV2MerchantEditPage',
                            breadcrumbName: '<<bjzjm>>',
                        },
                        {
                            path: '/edit-staff-link/:source',
                            component: 'miniProgramCodeDefaultLinksPage',
                            breadcrumbName: '<<xgcsmbylj>>',
                        },
                        { path: '/', component: 'miniprogramV2Pages' },
                    ],
                },
            ],
        },
    ],
};
