import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        VirtualNumberWhiteListView: {
            component: 'Viewport',
        },
        VirtualNumberWhiteListPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'VirtualNumberWhiteListForm' },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/virtual-number-white-list',
                    component: 'VirtualNumberWhiteListView',
                    breadcrumbName: '<<virtualNumberWhiteList>>',
                    privilege: {
                        path: 'virtualNumberWhiteList',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'VirtualNumberWhiteListPage' }],
                },
            ],
        },
    ],
};
