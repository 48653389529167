import React, { useEffect, useState, Fragment } from 'react';
import { Button, Form, Icon, Modal, Radio, Spin, Table } from 'antd';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { PhotoCreditReviewPreviewPicture } from '../photo-credit-review-preview-picture';

import './index.less';

const api = services.api;

const NO_DATA = null;

export const PhotoCreditReviewDisplayButton: React.FC = (props: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [data, setData] = useState<any>(NO_DATA);
    const imgUrl = get(props, 'row.imgUrl');
    const id = get(props, 'row.id');
    const status = get(props, 'row.status');
    const columns = [
        {
            title: services.language.getText('subsiteName'),
            dataIndex: 'subsiteName',
        },
        {
            title: services.language.getText('merchantCode'),
            dataIndex: 'code',
        },
        {
            title: services.language.getText('merchantName'),
            dataIndex: 'merchantName',
        },
        {
            title: services.language.getText('merchantType'),
            dataIndex: 'merchantType',
        },
        {
            title: services.language.getText('merchantStatus'),
            dataIndex: 'merchantStatus',
        },
    ];

    useEffect(() => {
        async function loadData() {
            const params = {
                id,
            };
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                apiPath: '/admin/photo_crediting/:id',
            };
            const result = await api.get(params, config);
            setData(result);
        }

        if (visible && data === NO_DATA && status !== 'PROCESSING') {
            loadData();
        }
    }, [data, visible, id, status]);

    if (status === 'PROCESSING') {
        return null;
    }

    function handleVisible(): void {
        setVisible((value) => !value);
    }

    function handleDownload(): void {
        window.open(imgUrl, '_self');
    }

    return (
        <span className="photo-credit-review-display-button">
            <Button type="link" onClick={handleVisible}>
                {services.language.getText('ck')}
            </Button>
            <Modal
                destroyOnClose
                visible={visible}
                cancelText={services.language.getText('common.cancel')}
                title={services.language.getText('ckshxx')}
                width="1100px"
                okButtonProps={{
                    hidden: true,
                }}
                onCancel={handleVisible}
            >
                <div className="photo-credit-review-display-button__modal">
                    <div className="photo-credit-review-display-button__ticket">
                        <PhotoCreditReviewPreviewPicture imgUrl={imgUrl} />
                        <Button
                            className="photo-credit-review-display-button__ticket-download"
                            onClick={handleDownload}
                        >
                            {services.language.getText('xzxpt')}
                        </Button>
                    </div>
                    <Spin
                        spinning={data === NO_DATA}
                        tip={services.language.getText('nljzz')}
                        indicator={
                            <Icon
                                type="loading"
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        }
                    >
                        <Form
                            layout="horizontal"
                            labelCol={{
                                span: 4,
                            }}
                            style={{
                                width: '800px',
                            }}
                        >
                            {data !== NO_DATA && (
                                <Fragment>
                                    {data.status === 'SUCCESS' ||
                                    data.status === 'APPROVED' ||
                                    data.status === 'EXCEPTION' ? (
                                        <Fragment>
                                            <div
                                                style={{
                                                    marginLeft: '60px',
                                                    marginBottom: '30px',
                                                }}
                                            >
                                                <Radio.Group>
                                                    <Radio>
                                                        {services.language.getText('pass')}
                                                    </Radio>
                                                </Radio.Group>
                                            </div>
                                            <Form.Item
                                                label={services.language.getText('subsiteName')}
                                            >
                                                <Table
                                                    style={{
                                                        width: '600px',
                                                        display: 'inline-block',
                                                    }}
                                                    columns={columns}
                                                    dataSource={[data]}
                                                    pagination={false}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label={services.language.getText('orderNumber')}
                                            >
                                                {data.orderNo}
                                            </Form.Item>
                                            <Form.Item label={services.language.getText('spsl')}>
                                                {data.goodsNum}
                                            </Form.Item>
                                            <Form.Item label={services.language.getText('xfsj')}>
                                                {data.consumptionTime}
                                            </Form.Item>
                                            <Form.Item label={services.language.getText('xfje')}>
                                                {data.amount}
                                            </Form.Item>
                                            <Form.Item
                                                label={services.language.getText(
                                                    'lastModifiedUser'
                                                )}
                                            >
                                                {data.operatorName}
                                            </Form.Item>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div
                                                style={{
                                                    marginLeft: '20px',
                                                    marginBottom: '30px',
                                                }}
                                            >
                                                <Radio.Group>
                                                    <Radio>
                                                        {services.language.getText('btg')}
                                                    </Radio>
                                                </Radio.Group>
                                            </div>
                                            <Form.Item label={services.language.getText('shbtgyy')}>
                                                {data.reason}
                                            </Form.Item>
                                            <Form.Item
                                                label={services.language.getText(
                                                    'lastModifiedUser'
                                                )}
                                            >
                                                {data.operatorName}
                                            </Form.Item>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </span>
    );
};
