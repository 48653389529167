import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const MemberBenefitLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const type = 'CARPARK_BENEFITS';
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/member_benefits?type=${type}`;
        const { id } = data;

        if (id) {
            config.apiPath = `/admin/member_benefits/${id}?type=${type}`;
        }
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        function processResult(item: any) {
            item.subsiteId = item.subsiteId + '';
            if (item.consumeDeductionBenefits && item.pointDeductionBenefits) {
                const enableConsumeDeductions = item.consumeDeductionBenefits.enable;
                const {
                    deductionTimePeriod,
                    canUseCoupons,
                    stackedUse,
                    maxUsedQuantity,
                } = item.carCouponDeductionBenefit;
                item.benefitInfo = {
                    enableConsumeDeductions: enableConsumeDeductions.toString(),
                    deductionType: enableConsumeDeductions
                        ? item.consumeDeductionBenefits.deductionType
                        : 'SPECIFY_VALUE',
                    consumeDeductionBenefits: enableConsumeDeductions
                        ? {
                              limitMemberLevel: item.consumeDeductionBenefits.limitMemberLevel,
                              consumeDeductions: item.consumeDeductionBenefits.consumeDeductions,
                          }
                        : {
                              limitMemberLevel: false,
                              consumeDeductions: [{ deductionRules: [{}] }],
                          },
                    enableDiscountLimit: (!!item.discountLimit).toString(),
                    discountLimit: item.discountLimit || { deductionType: 'SPECIFY_VALUE' },
                    enablePointDeduction: item.pointDeductionBenefits.enable.toString(),
                    pointDeduction: item.pointDeductionBenefits
                        ? {
                              point: item.pointDeductionBenefits.point,
                              deductionAmount: item.pointDeductionBenefits.deductionAmount,
                          }
                        : {},
                    canUseCoupons: canUseCoupons.toString(),
                    stackedUse: {
                        state: stackedUse,
                        count: stackedUse ? maxUsedQuantity : undefined,
                    },
                    deductionTimePeriod:
                        canUseCoupons && deductionTimePeriod
                            ? {
                                  start: deductionTimePeriod.startTime,
                                  end: deductionTimePeriod.endTime,
                              }
                            : undefined,
                };
            }
            if (item.carLicenseBenefit) {
                item.bindBenefitInfo = {
                    maxBindQuantity: item.carLicenseBenefit.maxBindQuantity,
                    enableLimitUnbind: item.carLicenseBenefit.limitUnbind.toString(),
                    unbindPeriod: item.carLicenseBenefit.unbindPeriod
                        ? item.carLicenseBenefit.unbindPeriod
                        : 'MONTH',
                    unbindBenefits: item.carLicenseBenefit.unbindBenefits,
                };
            }
            if (item.carBalanceCouponBenefits) {
                item.benefitInfo.enableBalanceCouponBenefits = item.carBalanceCouponBenefits.enable.toString();
                item.benefitInfo.balanceCoupons = (
                    item.carBalanceCouponBenefits.balanceCoupons || []
                ).map((item: any) => ({
                    code: item.deductionCode,
                    name: item.deductionType,
                }));
            }
            return item;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res = processResult(res);
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/member_benefits`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/member_benefits`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.subsiteId = params.subsiteId;
    newParams.type = 'CARPARK_BENEFITS';
    const benefitInfo = params.benefitInfo;
    const bindBenefitInfo = params.bindBenefitInfo;
    if (benefitInfo) {
        const stackedUse = benefitInfo.stackedUse;
        const consumeDeductionBenefits =
            benefitInfo.enableConsumeDeductions === 'true'
                ? {
                      enable: true,
                      ...benefitInfo.consumeDeductionBenefits,
                      deductionType: benefitInfo.deductionType,
                  }
                : {
                      enable: false,
                  };
        newParams.consumeDeductionBenefits = consumeDeductionBenefits;

        if (benefitInfo.enableDiscountLimit === 'true') {
            newParams.discountLimit = benefitInfo.discountLimit;
        }

        const canUseCoupons = benefitInfo.canUseCoupons === 'true';
        newParams.carCouponDeductionBenefit = {
            deductionTimePeriod:
                canUseCoupons && !!benefitInfo.deductionTimePeriod
                    ? {
                          startTime: benefitInfo.deductionTimePeriod.start,
                          endTime: benefitInfo.deductionTimePeriod.end,
                      }
                    : null,
            canUseCoupons,
            stackedUse: stackedUse ? stackedUse.state : true,
            maxUsedQuantity: stackedUse ? stackedUse.count : undefined,
        };

        newParams.pointDeductionBenefits = {
            enable: params.benefitInfo.enablePointDeduction,
            point:
                params.benefitInfo.enablePointDeduction === 'true' &&
                params.benefitInfo.pointDeduction
                    ? params.benefitInfo.pointDeduction.point
                    : null,
            deductionAmount:
                params.benefitInfo.enablePointDeduction === 'true' &&
                params.benefitInfo.pointDeduction
                    ? params.benefitInfo.pointDeduction.deductionAmount
                    : null,
        };
        if (params.benefitInfo && params.benefitInfo.enableBalanceCouponBenefits === 'true') {
            newParams.carBalanceCouponBenefits = {
                balanceCoupons: (params.benefitInfo.balanceCoupons || []).map((item: any) => ({
                    deductionCode: item.code,
                    deductionType: item.name,
                })),
            };
        }
    }
    if (bindBenefitInfo) {
        newParams.carLicenseBenefit = {
            limitUnbind: bindBenefitInfo.enableLimitUnbind === 'true' ? true : false,
            maxBindQuantity: bindBenefitInfo.maxBindQuantity,
            unbindPeriod:
                bindBenefitInfo.enableLimitUnbind === 'true' ? bindBenefitInfo.unbindPeriod : null,
            unbindBenefits:
                bindBenefitInfo.enableLimitUnbind === 'true' ? bindBenefitInfo.unbindBenefits : [],
        };
    }
    return newParams;
}
