import React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { OrganizationStoreSelector } from '../../../containers/organization/organization-store-selector';
export class OrganizationStoreSelectorMode extends ArrayMode {
    public getDisplayComponent(value: any[], displayInfo: any): JSX.Element {
        return <OrganizationStoreSelector {...displayInfo} value={value} type="show" />;
    }
    public getControlComponent(controlInfo: any) {
        return <OrganizationStoreSelector {...controlInfo} type="edit" />;
    }
}
