import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PaymentRewards } from './payment-rewards';

/**
 *  支付有礼，奖品设置
 */
export class PaymentRewardsMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PaymentRewards {...controlInfo} />;
    }
}
