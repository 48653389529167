import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ProductAuditRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/product_audit_rules';
        
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/product_audit_rules/${id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    item.merchants.map((merchant: any) => {
                        merchant.name = merchant.merchantName;
                        return merchant;
                    });
                    item.subsiteName = item.subsiteVo ? item.subsiteVo.name : '';
                    return item;
                });
            } else {
                const merchantIds = res.merchants && res.merchants.length ? res.merchants.map((i: any) => {
                    i.id = `${res?.subsiteVo?.id},${i.merchantId}`;
                    return i;
                }) : [];
                res = {
                    ...res,
                    subsiteInfo: {
                        subsiteId: res?.subsiteVo?.id.toString() || '',
                    },
                    merchantsInfo: {
                        merchantIds,
                    },
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/product_audit_rules';
        let newParams: any = {
            auditType: params.auditType
        };
        if (params.merchantsInfo) {
            newParams.merchantIds = params.merchantsInfo.merchantIds.map((item: { merchantId: string }) => item.merchantId);
        }
        if (params.subsiteInfo) {
            newParams.subsiteId = params.subsiteInfo.subsiteId;
        }

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/product_audit_rules/${id}`;
        let newParams: any = {
            id: id,
            auditType: params.auditType
        };
        if (params.merchantsInfo) {
            newParams.merchantIds = params.merchantsInfo.merchantIds.map((item: { merchantId: string }) => item.merchantId);
        }
        if (params.subsiteInfo) {
            newParams.subsiteId = params.subsiteInfo.subsiteId;
        }


        return await api.put(newParams, config);
    },
};
