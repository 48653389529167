import React, { createElement, PureComponent } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import {
    message as AntMessage,
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Table,
    Tabs as AntTabs,
    Popover,
} from 'antd';
import PropTypes from 'prop-types';
import { ColumnProps } from 'antd/lib/table';
import { PageNewPreviewCodeButton } from '../../../components';
import { isEmpty } from 'lodash';
import moment from 'moment';
import './index.less';
import { privilege } from '@comall-backend-builder/core/lib/services';

const { api } = services;
const AntTabPane = AntTabs.TabPane;
/* const activeKeyList: Array<any> = [
    {
        value: 'ALL',
        name: services.language.getText('all'),
    },
    {
        value: 'NOTSTARTED',
        name: services.language.getText('notStarted'),
    },
    {
        value: 'STARTED',
        name: services.language.getText('inprogress'),
    },
    {
        value: 'COMPLETE',
        name: services.language.getText('end'),
    },
]; */

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            onReset: any;
            subsites: [];
        };
        handleReset = () => {
            const { form, onReset } = this.props as any;
            form.resetFields();
            onReset();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    if (!isEmpty(values.dateRange)) {
                        const dateFormat = 'YYYY-MM-DD';
                        const dateRange =
                            values.dateRange && values.dateRange.length
                                ? [
                                      moment(values.dateRange[0] || undefined).format(dateFormat),
                                      moment(values.dateRange[1] || undefined).format(dateFormat),
                                  ]
                                : undefined;
                        if (dateRange && dateRange.length === 2) {
                            values.startTime = dateRange[0] + ' 00:00:00';
                            values.endTime = dateRange[1] + ' 23:59:59';
                            delete values.dateRange;
                        }
                    } else {
                        delete values.dateRange;
                    }
                    if (values.exchangeType && values.exchangeType === 'ALL') {
                        delete values.exchangeType;
                    }
                    onSearch(values);
                }
            });
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            const defaultValue = subsites && subsites.length === 1 ? subsites[0].id : undefined;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={services.language.getText('spmc')}>
                        {getFieldDecorator('goodsName')(
                            <Input
                                className="find-filter-width"
                                placeholder={services.language.getText('productNameRequired')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('couponRuleName')}>
                        {getFieldDecorator('couponName')(
                            <Input
                                className="find-filter-width"
                                placeholder={services.language.getText('selectCouponRuleName')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('subsiteName')}>
                        {getFieldDecorator('subsiteId', {
                            initialValue: defaultValue,
                        })(
                            <Select
                                className="find-filter-width"
                                allowClear
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="">
                                    {services.language.getText('all')}
                                </Select.Option>
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('dhlx_1')}>
                        {getFieldDecorator('exchangeType')(
                            <Select
                                style={{ width: '150px' }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                <Select.Option value="ALL">
                                    {services.language.getText('all')}
                                </Select.Option>
                                <Select.Option value="POINT_PRODUCT">
                                    {services.language.getText('lp')}
                                </Select.Option>
                                <Select.Option value="COUPON">
                                    {services.language.getText('yhq')}
                                </Select.Option>
                                <Select.Option value="COUPON_PACKAGE">
                                    {services.language.getText('package')}
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('acitivityTime')}>
                        {getFieldDecorator('dateRange')(
                            <DatePicker.RangePicker allowClear={true} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {services.language.getText('cx')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {services.language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class CreditShopFind extends PureComponent {
    formData = React.createRef();
    state = {
        datasource: [],
        searchParams: {},
        sortedInfo: {
            column: {},
            columnKey: '',
            field: '',
            order: '',
        },
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        activeKey: 'ALL',
        subsites: [],
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            this.setState({
                subsites,
            });
        });
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination, activeKey, sortedInfo } = this.state;
        let sorters = {};
        if (sortedInfo?.field && sortedInfo?.order) {
            sorters = {
                sequence: sortedInfo?.field || undefined,
                order: sortedInfo?.order === 'ascend' ? 'asc' : 'desc',
            };
        }
        try {
            let res: any = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                    ...sorters,
                    status: activeKey !== 'ALL' ? activeKey : undefined,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_products',
                }
            );
            if (res && res.result) {
                res.result = res.result.map((good: any, index: number) => {
                    return {
                        ...good,
                    };
                });
                this.setState({ datasource: res.result, total: res.totalNum, current: res.page });
            }
        } catch (error) {
            throw error;
        }
    };
    pageChange = (pageVal: object = {}) => {
        this.setState({ pagination: { ...pageVal } });
    };

    onChange = async (activeKey: any) => {
        this.setState(
            {
                activeKey,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                const { searchParams } = this.state;
                this.onSearch(searchParams);
            }
        );
    };
    handleTableChange = async (pagination: any, filters: any, sorter: any) => {
        const { props } = this.formData as any;
        let nValues: any = {};
        if (props && props.form) {
            props.form.validateFields((err: any, values: any) => {
                if (!err) {
                    if (!isEmpty(values.dateRange)) {
                        const dateFormat = 'YYYY-MM-DD';
                        const dateRange =
                            values.dateRange && values.dateRange.length
                                ? [
                                      moment(values.dateRange[0] || undefined).format(dateFormat),
                                      moment(values.dateRange[1] || undefined).format(dateFormat),
                                  ]
                                : undefined;
                        if (dateRange && dateRange.length === 2) {
                            values.startTime = dateRange[0] + ' 00:00:00';
                            values.endTime = dateRange[1] + ' 23:59:59';
                            delete values.dateRange;
                        }
                    } else {
                        delete values.dateRange;
                    }
                    if (values.exchangeType && values.exchangeType === 'ALL') {
                        delete values.exchangeType;
                    }
                    nValues = values;
                }
            });
        }
        this.setState({ sortedInfo: sorter }, () => {
            this.onSearch(nValues);
        });
    };
    onReset = async () => {
        this.setState({ sortedInfo: {} });
    };

    range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    getTableColumns = (): ColumnProps<any>[] => {
        const { sortedInfo } = this.state;
        let sortOrder: any =
            sortedInfo &&
            sortedInfo.columnKey &&
            sortedInfo.columnKey === 'sequence' &&
            sortedInfo.order
                ? sortedInfo.order
                : false;
        return [
            {
                title: services.language.getText('productInformation'),
                dataIndex: 'name',
                key: 'name',
                width: 300,
                render: (name: any, record: any) => {
                    const popoverContent = <div style={{ maxWidth: '300px' }}>{name}</div>;
                    return (
                        <div className="credit-shop-find-info">
                            <img
                                alt=""
                                style={{ width: '100px', height: '100px' }}
                                src={record.imgUrl}
                            />
                            <div className="credit-shop-find-info-right">
                                {name && name.length > 20 ? (
                                    <Popover content={popoverContent}>
                                        <div className="credit-shop-find-info-right-name">
                                            {name}
                                        </div>
                                    </Popover>
                                ) : (
                                    <div className="credit-shop-find-info-right-name">{name}</div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('dhmd'),
                key: 'subsites',
                dataIndex: 'subsites',
                render: (val: any) => {
                    const subsiteName = val[0].name;
                    return <span>{subsiteName}</span>;
                },
            },
            {
                title: services.language.getText('dhlx_1'),
                dataIndex: 'exchangeType',
                key: 'exchangeType',
            },
            {
                title: services.language.getText('l_ydh_1'),
                dataIndex: 'convertNum',
                key: 'convertNum',
                render: (convertNum: any, record: any) => {
                    return (
                        <span>
                            {convertNum}/{record.stock}
                        </span>
                    );
                },
            },
            {
                title: services.language.getText('sequence'),
                key: 'sequence',
                dataIndex: 'sequence',
                sorter: true,
                sortOrder,
            },
            {
                title: services.language.getText('dhsxsj_1'),
                key: 'startTime',
                dataIndex: 'startTime',
                render: (val) => {
                    return <span>{val}</span>;
                },
            },
            {
                title: services.language.getText('dhsxsj'),
                key: 'endTime',
                dataIndex: 'endTime',
                render: (val) => {
                    return <span>{val}</span>;
                },
            },
            {
                title: services.language.getText('status'),
                dataIndex: 'executeStatus',
                key: 'executeStatus',
                render: (val) => {
                    let statusName = '';
                    if (val === 'NOTSTARTED') {
                        statusName = services.language.getText('notStarted');
                    } else if (val === 'STARTED') {
                        statusName = services.language.getText('inprogress');
                    } else if (val === 'COMPLETE') {
                        statusName = services.language.getText('end');
                    }
                    return <span>{statusName}</span>;
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                dataIndex: 'id',
                key: 'id',
                fixed: 'right',
                width: 220,
                render: (val: any, record: any) => {
                    record.saleRuleId = record.id;
                    record.subsiteId =
                        record.subsites && record.subsites.length > 0 ? record.subsites[0].id : '';
                    return (
                        <div>
                            {this.renderButton(record)}
                            <a
                                href={`#/credit-shop/edit/${record.id}`}
                                style={{ marginLeft: '8px' }}
                            >
                                {services.language.getText('common.edit')}
                            </a>
                            <a
                                href={`#/credit-shop/copy/${record.id}`}
                                style={{ marginLeft: '8px' }}
                            >
                                {services.language.getText('copy')}
                            </a>
                            <a
                                href={`#/credit-shop/report/${record.id}`}
                                style={{ marginLeft: '8px' }}
                            >
                                {services.language.getText('data')}
                            </a>
                            <PageNewPreviewCodeButton
                                codeTypes="all"
                                codeModalType="item"
                                codePageType="creditShopDetailPage"
                                buttonTitle={services.language.getText('generalization')}
                                row={record}
                            />
                            <a
                                href={`#/credit-shop/logs/${record.id}`}
                                style={{ marginLeft: '8px' }}
                            >
                                {services.language.getText('common.log')}
                            </a>
                        </div>
                    );
                },
            },
        ];
    };

    renderButton = (record: any) => {
        let buttonProps = {
            row: record,
            entity: {
                params: {
                    id: record.id,
                },
            },
            statusKey: 'status',
            renderComponent: 'Button',
            type: 'link',
            style: { display: 'inline-flex' },
            handles: [
                {
                    params: { status: false },
                    value: true,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_products/:id/status',
                    config: {
                        content: services.language.getText('gb'),
                        text: services.language.getText('gb'),
                        style: { color: '#f5222d' },
                        onConfirmSearch: () => {
                            const { activeKey, searchParams } = this.state;
                            this.setState(
                                {
                                    activeKey,
                                    pagination: {
                                        page: 1,
                                        perPage: 10,
                                    },
                                },
                                () => {
                                    this.onSearch(searchParams);
                                }
                            );
                        },
                    },
                    confirm: {
                        text: `${services.language.getText('isCloseActivity')}{{row.name}}?`,
                        content: services.language.getText('jyhycsdddbsyx'),
                        className: 'confirm-content',
                    },
                },
                {
                    params: { status: true },
                    value: false,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_products/:id/status',
                    config: {
                        content: services.language.getText('kq'),
                        text: services.language.getText('kq'),
                        onConfirmSearch: () => {
                            const { activeKey, searchParams } = this.state;
                            this.setState(
                                {
                                    activeKey,
                                    pagination: {
                                        page: 1,
                                        perPage: 10,
                                    },
                                },
                                () => {
                                    this.onSearch(searchParams);
                                }
                            );
                        },
                    },
                    confirm: {
                        text: `${services.language.getText('isAreadyOpend')}{{row.name}}?`,
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('ChangeStatusButton'), buttonProps);
    };
    onCheckCredit = () => {
        if (privilege.check('creditShopPage', 'full')) {
            window.open('#/credit-shop-page');
        } else {
            AntMessage.warning(services.language.getText('qlxglypz'));
        }
    };
    render() {
        const { datasource, pagination, total, current, activeKey, subsites } = this.state;
        const columns = this.getTableColumns();

        return (
            <div className="credit-shop-find">
                <div>
                    <CollectionCreateForm
                        wrappedComponentRef={(form: any) => (this.formData = form)}
                        onSearch={this.onSearchFind}
                        subsites={subsites}
                        onReset={this.onReset}
                    />
                </div>
                <div className="credit-shop-add">
                    <Button type="primary" href="#/credit-shop/add" icon="plus">
                        {services.language.getText('xjdhgz')}
                    </Button>
                    {/* <CreditEshopSortSetting />*/}
                    <Button
                        style={{ marginLeft: 10 }}
                        target="_blank"
                        type="primary"
                        onClick={this.onCheckCredit}
                    >
                        {services.language.getText('zxjfsc')}
                    </Button>
                    <PageNewPreviewCodeButton
                        codeTypes="all"
                        codeModalType="list"
                        codePageType="creditEshopPage"
                        buttonTitle={services.language.getText('tgjfsclb')}
                    />
                </div>
                <AntTabs
                    className="find-tabs"
                    activeKey={activeKey}
                    animated={false}
                    onChange={this.onChange}
                >
                    {[
                        {
                            value: 'ALL',
                            name: services.language.getText('all'),
                        },
                        {
                            value: 'NOTSTARTED',
                            name: services.language.getText('notStarted'),
                        },
                        {
                            value: 'STARTED',
                            name: services.language.getText('inprogress'),
                        },
                        {
                            value: 'COMPLETE',
                            name: services.language.getText('end'),
                        },
                    ].map((activeKeyVo) => {
                        return (
                            <AntTabPane tab={activeKeyVo.name} key={activeKeyVo.value}>
                                <div></div>
                            </AntTabPane>
                        );
                    })}
                </AntTabs>
                <Table
                    scroll={{ x: true }}
                    pagination={{
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={datasource}
                />
            </div>
        );
    }
}
