import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import { SelectBlindBox } from './select-blind-box';

const language = services.language;

/**
 * 自定义可视化，盲盒活动选择器
 */
export class CustomSelectBlindBox extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        subsiteIds?: string[];
        onChange: (value: any) => void;
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;

        this.hideModal();
        onChange({
            id: value[0].id,
            name: value[0].name,
        });
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
            subsiteIds,
        } = this.props;

        const { visibleModal } = this.state;
        const hasSingleTarget = linkParams && linkParams.name;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        return (
            <span className="link-name">
                {hasSingleTarget ? (
                    <span className="link-name-label">{linkParams.name}</span>
                ) : (
                    language.getText('notSelect')
                )}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <SelectBlindBox
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    subsiteIds={subsiteIds}
                    {...selectProps}
                />
            </span>
        );
    }
}
