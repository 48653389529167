import React, { PureComponent, createElement } from 'react';
import { Modal } from 'antd';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

/**
 * 导出数据
 */
export class MessageBatchButton extends PureComponent<any, any> {
    onStop = () => {
        const { entity, params, row } = this.props;

        Modal.confirm({
            title: services.language.getText('qrtzdqsjm'),
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
            onOk: () => {
                api.put(
                    {},
                    {
                        apiPath: `/admin/messages_batch/${row.id}/stop`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('actionSuccess'));

                        entity.search(params);
                    })
                    .catch(errorHandle);
            },
        });
    };
    render() {
        const { row, routeRoot = 'messageBatch' } = this.props;
        const { sendStatus } = row;
        const editButtonProps = {
            type: 'link',
            text: services.language.getText('common.edit'),
            route: `/${routeRoot}/edit/${row.id}`,
        };
        const viewButtonProps = {
            type: 'link',
            text: services.language.getText('common.view'),
            route: `/${routeRoot}/info/${row.id}`,
        };
        const stopButtonProps = {
            type: 'link',
            text: services.language.getText('stop'),
            style: { marginLeft: 10 },
            onClick: this.onStop,
        };
        return (
            <>
                {sendStatus === 'WAIT_SEND' &&
                    createElement(ComponentsManager.get('Button'), editButtonProps)}
                {(sendStatus === 'SENDED' || sendStatus === 'CLOSE') &&
                    createElement(ComponentsManager.get('Button'), viewButtonProps)}
                {(sendStatus === 'WAIT_SEND' || sendStatus === 'SENDING') &&
                    createElement(ComponentsManager.get('Button'), stopButtonProps)}
            </>
        );
    }
}
