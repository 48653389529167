import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

const ActivityProductTypeVal: any = {
    ALL: '0',
    INCLUDE_PRODUCT: '1',
    EXCLUDE_PRODUCT: '2',
};

export const goldDeductionLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data.id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
            config.apiPath = '/admin/payment_mode_rule/' + data.id;
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                };
                res.ruleInfo = {
                    subsiteId: String(res.subsites[0].id),
                    merchants:
                        res.merchants && res.merchants.length
                            ? res.merchants.map((item: any) => ({
                                  ...item,
                                  merchantId: item.id,
                              }))
                            : [],
                    products: {
                        type: 'APPOINT_PRODUCT',
                        values:
                            res.products && res.products.length
                                ? res.products.map((item: any) => ({
                                      ...item,
                                      name: item.productName,
                                      id: item.productId,
                                      code: item.productCode,
                                  }))
                                : [],
                    },
                    ...parseRuleInfoConfig(res.params),
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.subsite =
                            item.subsites && item.subsites.length
                                ? item.subsites.map((item: any) => item.name).join(',')
                                : '';
                        item.status = item.statusSwitch;
                        item.canImport = item.uploadSwitch;
                        return item;
                    });
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
        config.apiPath = `/admin/payment_mode_rule/${params.id}`;
        const { baseInfo, ruleInfo } = params;
        let newParams = {
            name: baseInfo.name,
            startTime: baseInfo.dateRange.start,
            endTime: baseInfo.dateRange.end,
            subsiteIds: [ruleInfo.subsiteId],
            merchantIds:
                ruleInfo.merchants && ruleInfo.merchants.length
                    ? ruleInfo.merchants.map((i: any) => i.merchantId)
                    : [],
            goodsIds:
                ruleInfo.products && ruleInfo.products.values && ruleInfo.products.values.length
                    ? ruleInfo.products.values.map((i: any) => i.id)
                    : [],
            params: tranformParams(params),
        };
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        const { baseInfo, ruleInfo } = params;
        let newParams = {
            name: baseInfo.name,
            startTime: baseInfo.dateRange.start,
            endTime: baseInfo.dateRange.end,
            subsiteIds: [ruleInfo.subsiteId],
            merchantIds:
                ruleInfo.merchants && ruleInfo.merchants.length
                    ? ruleInfo.merchants.map((i: any) => i.merchantId)
                    : [],
            goodsIds:
                ruleInfo.products && ruleInfo.products.values && ruleInfo.products.values.length
                    ? ruleInfo.products.values.map((i: any) => i.id)
                    : [],
            params: tranformParams(params),
        };
        return await api.post(newParams, config);
    },
};

function tranformParams({ baseInfo, ruleInfo }: any) {
    let List: any = [];
    const {
        activityProductType,
        deductionItems,
        goldExchangeLimit,
        deductionProportion,
    } = ruleInfo;
    List = [
        {
            paramName: 'goodsAstrict',
            paramValue: ActivityProductTypeVal[activityProductType],
        },
        {
            paramName: 'pointDeductionType',
            paramValue: deductionItems,
        },
        {
            paramName: 'maxUseCount',
            paramValue: goldExchangeLimit,
        },
        {
            paramName: 'deductionRatio',
            paramValue: deductionProportion,
        },
    ];
    return List;
}
function parseRuleInfoConfig(values: any) {
    const config: any = {
        goodsAstrict: 'activityProductType',
        pointDeductionType: 'deductionItems',
        maxUseCount: 'goldExchangeLimit',
        deductionRatio: 'deductionProportion',
    };
    const ActivityProductTypeStingVal: any = {
        '0': 'ALL',
        '1': 'INCLUDE_PRODUCT',
        '2': 'EXCLUDE_PRODUCT',
    };
    const result: any = {};
    for (let item of values) {
        if (
            ['pointDeductionType', 'maxUseCount', 'deductionRatio'].includes(String(item.paramName))
        ) {
            result[config[item.paramName]] = item.paramValue;
        }
        if (String(item.paramName) === 'goodsAstrict') {
            result[config[item.paramName]] = item.paramValue
                ? ActivityProductTypeStingVal[item.paramValue]
                : 'ALL';
        }
    }
    return result;
}
