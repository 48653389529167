import React, { PureComponent } from 'react';
import { RichTextPlus } from '../../../components';

interface MessageStyle {
    innerClass: any;
    top: any;
    bottom: any;
}
interface MessagesSubscriptioEmailProps {
    /**
     * 样式
     */
    messageStyle: MessageStyle;
    /**
     * 模板数据列表
     */
    messageList: any;
}
/**
 * 邮件模板
 */
export class MessagesSubscriptioEmail extends PureComponent<MessagesSubscriptioEmailProps> {
    render() {
        const {
            messageStyle: { innerClass, top, bottom },
            messageList,
        } = this.props;
        const richText: any = {
            toolbarId: 'descriptionRichText',
            style: {
                height: 300,
            },
            uploadConfig: {
                apiPath: `/admin/images/upload`,
                fileName: 'fileName',
            },
        };
        return (
            <div className={innerClass.content}>
                <div className={innerClass.emailItems}>
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {top && top.value}
                    </div>
                    {messageList.map((o: any) => {
                        const item = o.fieldType === 'string' || o.fieldType === 'mediumtext';
                        return (
                            <>
                                {item && (
                                    <div
                                        key={o.name}
                                        className={innerClass.otherItem}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <span className={innerClass.otherItemTitle}>{o.name}</span>
                                        {o.fieldType === 'string' && (
                                            <span
                                                className={innerClass.otherItemValue}
                                                style={{
                                                    color: o && o.color ? o.color : '#000',
                                                }}
                                            >
                                                {o.value}
                                            </span>
                                        )}
                                        {o.fieldType === 'mediumtext' && (
                                            <div className={innerClass.richText}>
                                                <RichTextPlus
                                                    {...richText}
                                                    value={o.value}
                                                ></RichTextPlus>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        );
                    })}
                    <div
                        style={{
                            color: bottom && bottom.color ? bottom.color : '#D0021B',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {bottom && bottom.value}
                    </div>
                </div>
            </div>
        );
    }
}
