import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, forEach, map, uniq, find, isEqual, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';
import { Shop, Property } from '../../containers/select-shop';
import { language } from '@comall-backend-builder/core/lib/services';

const TableComponentId = 'ShopSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择专柜
 */
class shopSelector extends PureComponent<{
    selectValues: Shop[];
    onChange: (products: Shop[]) => void;
    params?: any;
    type?: 'radio';
    /**
     * 只能选择唯一分站
     */
    uniqueSubsite?: boolean;
    columns?: Property[];
    fields?: Property[];
    requestStatus: string;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('ShopSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<Shop>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentDidMount() {
        this.mapSelectValuesToSelectedRows(this.props.selectValues);
    }

    componentWillReceiveProps(nextProps: any) {
        let tableRowSelectionChangeAction = false;
        //在选中列表中删除数据后更新弹窗选中状态
        if (!isEqual(nextProps.selectValues, this.props.selectValues)) {
            tableRowSelectionChangeAction = true;
            this.mapSelectValuesToSelectedRows(nextProps.selectValues);
        }
        if (
            (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') ||
            tableRowSelectionChangeAction
        ) {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
            }
        }
    }

    mapSelectValuesToSelectedRows = (selectValues?: Shop[]) => {
        this.selectedRows = (selectValues || []).map((shop: Shop) => ({
            ...shop,
            id: String(shop.id),
        }));
    };

    onTableRowSelect = (record: Shop, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { id: record.id })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: Shop[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        // selectedRows被清空后 则取弹层打开时的数据
        this.mapSelectValuesToSelectedRows(this.props.selectValues);
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange, uniqueSubsite } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });
            if (uniqueSubsite) {
                const selectedSubsiteIds = uniq(
                    this.selectedRows.map((row) => {
                        return row.subSiteId;
                    })
                );
                if (selectedSubsiteIds.length > 1) {
                    message.warn(language.getText('onlySelectCommonMerchant'));
                    return;
                }
            }
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };
    initEntityFilters = () => {
        entity.filtersChange({
            subSiteName: undefined,
            merchantType: undefined,
            merchantTagId: undefined,
            merchantName: undefined,
            sorter: undefined,
        });
        entity.pageChange({ page: 1 });
        const store = builder.getStore();
        store.dispatch(actions.filtersChangeAction(entity, {}));
        store.dispatch(
            actions.tableSortChangeAction(TableComponentId, { field: undefined, order: undefined })
        );
    };

    getConfig = () => {
        const { params, selectValues, type, fields, columns } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    // style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    onReset: () => {
                        this.initEntityFilters();
                    },
                    fields:
                        fields && fields.length
                            ? [...fields]
                            : [
                                  {
                                      property: 'subSiteName',
                                  },
                                  {
                                      property: 'merchantName',
                                  },
                              ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: Shop, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: Shop[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns:
                        columns && columns.length
                            ? [...columns]
                            : [
                                  {
                                      property: 'subSiteName',
                                  },
                                  {
                                      property: 'merchantName',
                                  },
                                  {
                                      property: 'code',
                                      sorter: true,
                                  },
                                  {
                                      property: 'merchantType',
                                  },
                                  {
                                      property: 'merchantStatus',
                                  },
                              ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;
        //判断filters 当前是否有排序字段 没有则更新页面显示（解决同一组件多场景使用下！.filters.order页面排序却高亮的问题）
        if (entity && (!entity.filters || (entity.filters && !entity.filters.order))) {
            const store = builder.getStore();
            store.dispatch(
                actions.tableSortChangeAction(TableComponentId, {
                    field: undefined,
                    order: undefined,
                })
            );
        }

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.filtersChange({
                    subSiteName: undefined,
                    merchantName: undefined,
                });
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: language.getText('selectSubsiteOrMerchant'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="primary" onClick={this.toggleModal}>
                    {language.getText('specifyMerchantOrSubsite')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const ShopSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(shopSelector);
