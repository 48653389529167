import { BaseConfig } from '../../../cae/interfaces';
import { services } from '@comall-backend-builder/core';

enum ContentTopicStatus {
    /**
     * 启用
     */
    ENABLE = 'ENABLED',
    /**
     * 禁用
     */
    DISABLE = 'DISABLED',
}

export let config: BaseConfig = {
    entities: {
        NewPopUpAds: {
            apiPath: '/loader/admin/new_pop_up_ads',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                    rules: [{ required: true }],
                },
                scope: {
                    type: 'string.options.radio',
                    displayName: '<<applicableScope>>',
                    defaultValue: 'SUBSITE',
                    rules: [{ required: true }],
                    options: [
                        { id: 'SUBSITE', name: '<<subsites>>' },
                        { id: 'PLATFORM', name: '<<platform>>' },
                    ],
                },
                subsites: {
                    type: 'array.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    defaultValue: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<displayTitle>>',
                    rules: [
                        { required: true },
                        {
                            max: 16,
                            message: '<<advTitleRequired>>',
                        },
                    ],
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<dispalyTime>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 380 },
                        allowClear: false,
                    },
                },
                startTime: {
                    type: 'string.dateTime',
                    displayName: '<<displayStartTime>>',
                },
                endTime: {
                    type: 'string.dateTime',
                    displayName: '<<displayEndTime>>',
                },
                popUpAdsStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    defaultValue: 'START',
                    options: [
                        { id: 'START', name: '<<inprogress>>' },
                        { id: 'NOT_START', name: '<<notStarted>>' },
                        { id: 'END', name: '<<end>>' },
                    ],
                },
                status: {
                    type: 'string.options.radio',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ENABLED',
                    options: [
                        { id: 'ENABLED', name: '<<enable>>' },
                        { id: 'DISABLED', name: '<<disabled>>' },
                    ],
                },
                pic: {
                    type: 'string.image.picker',
                    displayName: '<<popupAdv>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        maxCount: 1,
                        limitTips: {
                            limitText: '<<picLimitTips>>',
                            limitUnit: ' ',
                            className: 'image-picker-tips-ext',
                        },
                    },
                },
                types: {
                    type: 'string',
                    displayName: '<<displayPosition>>',
                },
                homeType: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<displayPosition>>',
                    defaultValue: ['HOME'],
                    options: [
                        { id: 'HOME', name: '<<storePage>>' },
                        { id: 'MEMBER_CENTER', name: '<<memberCenterPage>>' },
                        { id: 'CHECK_OUT', name: '<<checkoutPage>>' },
                    ],
                    rules: [{ required: true }],
                    extra: '',
                },
                platformType: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<displayPosition>>',
                    defaultValue: ['HOME'],
                    options: [{ id: 'HOME', name: '<<platformPage>>' }],
                    rules: [{ required: true }],
                    extra: '',
                },
                config: {
                    type: 'string.options.radio',
                    displayName: '<<displayFrequency>>',
                    defaultValue: 'dayThreeTime',
                    options: [
                        { id: 'everyTime', name: '<<eachTime>>' },
                        { id: 'dayThreeTime', name: '<<customFrequency>>' },
                    ],
                },
                second: {
                    type: 'number.tip',
                    defaultValue: 3,
                    displayName: '<<customFrequency>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        addonbefore: '',
                        addonafter: '<<frequency>>/<<day>>',
                        style: { width: '80px' },
                        rules: [
                            {
                                type: 'number',
                            },
                        ],
                        min: 1,
                        max: 99,
                        placeholder: '<<inputPlease>>',
                    },
                },
                crowd: {
                    type: 'string.options.select',
                    displayName: '<<targetAudience>>',
                    options: [{ id: 'all', name: '<<all>>' }],
                    rules: [{ required: true }],
                },
                target: {
                    type: 'object.subsiteLinkTypeSelector',
                    displayName: '<<linkConfig>>',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                pictureType: {
                    type: 'string.options.radio',
                    displayName: '<<picNumber>>',
                    defaultValue: 'SINGLE_IMAGE_FIXED',
                    options: [
                        { id: 'SINGLE_IMAGE_FIXED', name: '<<singleFixed>>' },
                        { id: 'MULTIPLE_IMAGE_CAROUSEL', name: '<<multScroll>>' },
                    ],
                },
                interval: {
                    type: 'string.options.radio',
                    displayName: '<<carouselInterval>>',
                    defaultValue: '3',
                    options: [
                        { id: '3', name: '3S' },
                        { id: '5', name: '5S' },
                    ],
                },
                operator: {
                    type: 'array.arrayImageAndOperatorMode',
                    displayName: '<<advPic>>',
                    controlConfig: {
                        maxCount: 20,
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        maxSize: 2 * 1024,
                        uploadLimitSizeErrorMessage:
                            '<<pictures>>{{file.name}}<<pictureUploadLimit>>',
                    },
                    extra: '<<picturesExtra>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                    rules: [{ required: true, message: '<<qzsgxygsyqd>>' }],
                },
            },
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<dispalyTime>>',
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
            },
        },
    },
    components: {
        NewPopUpAdsView: {
            component: 'Viewport',
            entity: 'NewPopUpAds',
        },
        NewPopUpAdsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'showTime',
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '<<addAdv>>',
                    route: '/new-pop-up-ads/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'NewPopUpAdsTable' },
            ],
        },

        NewPopUpAdsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), { total });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'name',
                },
                { property: 'channels' },
                {
                    property: 'types',
                },

                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'popUpAdsStatus',
                    displayConfig: {
                        statusConfig: {
                            START: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            NOT_START: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            END: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'status',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: ContentTopicStatus.DISABLE,
                                    apiPath: '/admin/pop_up_ads/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: ContentTopicStatus.ENABLE,
                                    apiPath: '/admin/pop_up_ads/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/new-pop-up-ads/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/new-pop-up-ads/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/new-pop-up-ads/log/{{row.id}}/POP_UP_ADS',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            id: 'row.id',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },

        NewPopUpAdsAddPage: {
            entity: 'NewPopUpAds',
            component: 'FlexLayout',
            items: [
                { component: 'PopUpAdsPreview' },
                {
                    component: 'NewPopUpAdsAddFormView',
                },
            ],
        },

        NewPopUpAdsEditPage: {
            component: 'FlexLayout',
            entity: 'NewPopUpAds',
            loaderType: 'get',
            items: [
                { component: 'PopUpAdsPreview' },
                {
                    component: 'NewPopUpAdsEditFormView',
                },
            ],
        },
        NewPopUpAdsCopyPage: {
            component: 'FlexLayout',
            entity: 'NewPopUpAds',
            loaderType: 'get',
            items: [
                { component: 'PopUpAdsPreview' },
                {
                    component: 'NewPopUpAdsCopyFormView',
                },
            ],
        },
        NewPopUpAdsLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/new-pop-up-ads',
                    breadcrumbName: '<<managePopup>>',
                    component: 'NewPopUpAdsView',
                    privilege: {
                        path: 'newClientPopUpAds',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'NewPopUpAdsEditPage',
                            breadcrumbName: '<<editAdv>>',
                        },
                        {
                            path: '/add',
                            component: 'NewPopUpAdsAddPage',
                            breadcrumbName: '<<addAdv>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'NewPopUpAdsCopyPage',
                            breadcrumbName: '<<copyAdv>>',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'NewPopUpAdsLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'NewPopUpAdsPage' },
                    ],
                },
            ],
        },
    ],
};
