/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { find, isEqual } from 'lodash';
import { default as Radio } from 'antd/lib/radio';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 是否可转赠单选框
 */
export class StringOptionsRadioHasLinkMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringRadioHasLink {...displayInfo} />;
    }
}

class StringRadioHasLink extends Component<any, any> {
    onChange = (e: any) => {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(e.target.value, name);
        }
    };

    componentWillReceiveProps(nextProps: any) {
        const { name, value, setOptions, onChange, defaultValueIndex } = nextProps;

        if (isEqual(this.props.setOptions, setOptions)) {
            return;
        }

        if (value) {
            if (!find(setOptions, { id: value })) {
                onChange(undefined, name);
            }
        } else if (defaultValueIndex !== undefined) {
            onChange(setOptions[defaultValueIndex].id, name);
        }
    }

    render() {
        const { value, setOptions, JumpPath, JumpText } = this.props;
        return (
            <div>
                <Radio.Group onChange={this.onChange} value={value}>
                    {setOptions &&
                        setOptions.map((option: any) => {
                            return (
                                <Radio key={option.value} value={option.value}>
                                    {option.name}
                                </Radio>
                            );
                        })}
                </Radio.Group>
                {value === 'true' && (
                    <div>
                        <a href={JumpPath} target="_blank">
                            {language.getText(JumpText)}
                        </a>
                    </div>
                )}
            </div>
        );
    }
}
