import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty } from 'lodash';
import { Coupon } from '../containers';

const api = services.api;
export const InvitationActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/invitations';
        let goldValueAlias = '金币';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/invitations/${id}`;
            const resData: any[] = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/activities/reward_items?event_type=SIGN_IN',
                }
            );
            if (resData && resData.length) {
                goldValueAlias = resData.find((item: any) => item.code === 'GOLD')?.name || '金币';
            }
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (data.executeStatus) {
                data.status = data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsiteIds: res.subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };

                const rewardsArray: any = [];
                if (res.inviterRewards && res.inviterRewards.length > 0) {
                    forEach(res.inviterRewards, (reward) => {
                        if (reward.point && reward.point > 0) {
                            reward.pointChecked = true;
                        }
                        if (reward.experience && reward.experience > 0) {
                            reward.experienceChecked = true;
                        }
                        if (reward.coupons && reward.coupons.length > 0) {
                            reward.couponsValue = reward.coupons;
                            reward.couponsChecked = true;
                        }
                        if (reward.gold && reward.gold > 0) {
                            reward.goldChecked = true;
                            reward.goldTxtName = goldValueAlias;
                        }
                        if (reward.custom && reward.custom.length > 0) {
                            reward.customChecked = true;
                        }
                        if (reward.couponRewardName) {
                            reward.couponRewardNameChecked = true;
                        }
                        let picArray: any = [];
                        if (
                            reward.ladderGiftPicture &&
                            reward.ladderGiftPicture.id &&
                            reward.ladderGiftPicture.url
                        ) {
                            picArray = [
                                {
                                    id: reward.ladderGiftPicture.id || '',
                                    path: reward.ladderGiftPicture.url || '',
                                },
                            ];
                        }
                        reward.ladderGiftPicture = picArray;

                        let picBgArray: any = [];
                        if (
                            reward.inviterRewardPicture &&
                            reward.inviterRewardPicture.id &&
                            reward.inviterRewardPicture.url
                        ) {
                            picBgArray = [
                                {
                                    id: reward.inviterRewardPicture.id || '',
                                    path: reward.inviterRewardPicture.url || '',
                                },
                            ];
                        }
                        reward.inviterRewardPicture = picBgArray;
                        rewardsArray.push(reward);
                    });
                }
                if (res.invitedToReward) {
                    if (res.invitedToReward.point && res.invitedToReward.point > 0) {
                        res.invitedToReward.pointChecked = true;
                        res.invitedToReward.registerRewardChecked = true;
                    }
                    if (res.invitedToReward.experience && res.invitedToReward.experience > 0) {
                        res.invitedToReward.experienceChecked = true;
                        res.invitedToReward.registerRewardChecked = true;
                    }
                    if (res.invitedToReward.coupons && res.invitedToReward.coupons.length > 0) {
                        res.invitedToReward.couponsValue = res.invitedToReward.coupons;
                        res.invitedToReward.couponsChecked = true;
                        res.invitedToReward.registerRewardChecked = true;
                    }
                    if (res.invitedToReward.gold && res.invitedToReward.gold > 0) {
                        res.invitedToReward.goldChecked = true;
                        res.invitedToReward.registerRewardChecked = true;
                    }
                    if (res.invitedToReward.custom && res.invitedToReward.custom.length > 0) {
                        res.invitedToReward.customChecked = true;
                        res.invitedToReward.registerRewardChecked = true;
                    }
                    if (
                        res.invitedToReward.inviterRewardPicture &&
                        res.invitedToReward.inviterRewardPicture.id &&
                        res.invitedToReward.inviterRewardPicture.url
                    ) {
                        res.invitedToReward.inviterRewardPicture = [
                            {
                                id: res.invitedToReward.inviterRewardPicture.id || '',
                                path: res.invitedToReward.inviterRewardPicture.url || '',
                            },
                        ];
                    }
                } else {
                    res.invitedToReward = null;
                }

                res.inviterRewards = rewardsArray;
                if (res.pageConfig) {
                    const pageConfigJson = JSON.parse(res.pageConfig);
                    res.pictureInfo = {
                        bottomPictureUrl: pageConfigJson.bottomPicture
                            ? [
                                  {
                                      id: pageConfigJson.bottomPicture.id || '',
                                      path: pageConfigJson.bottomPicture.url || '',
                                  },
                              ]
                            : undefined,
                        buttonPictureUrl: pageConfigJson.buttonPicture
                            ? [
                                  {
                                      id: pageConfigJson.buttonPicture.id || '',
                                      path: pageConfigJson.buttonPicture.url || '',
                                  },
                              ]
                            : undefined,
                        markPictureUrl: pageConfigJson.markPicture
                            ? [
                                  {
                                      id: pageConfigJson.markPicture.id || '',
                                      path: pageConfigJson.markPicture.url || '',
                                  },
                              ]
                            : undefined,
                        description: res.description,
                        sharePosterPictureId: pageConfigJson.sharePosterPicture
                            ? [
                                  {
                                      id: pageConfigJson.sharePosterPicture.id || '',
                                      path: pageConfigJson.sharePosterPicture.url || '',
                                  },
                              ]
                            : undefined,
                        shareSmallPictureId: pageConfigJson.shareSmallPicture
                            ? [
                                  {
                                      id: pageConfigJson.shareSmallPicture.id || '',
                                      path: pageConfigJson.shareSmallPicture.url || '',
                                  },
                              ]
                            : undefined,
                        shareContent: res.shareContent,
                    };
                } else {
                    res.pictureInfo = {
                        bottomPictureUrl: [
                            {
                                id: res.bottomPicture.id || '',
                                path: res.bottomPicture.url || '',
                            },
                        ],
                        // giftPictureUrl: [
                        //     {
                        //         id: res.giftPicture.id || '',
                        //         path: res.giftPicture.url || '',
                        //     },
                        // ],
                        buttonPictureUrl: [
                            {
                                id: res.buttonPicture.id || '',
                                path: res.buttonPicture.url || '',
                            },
                        ],
                        markPictureUrl: [
                            {
                                id: res.markPicture.id || '',
                                path: res.markPicture.url || '',
                            },
                        ],
                        description: res.description,
                        sharePosterPictureId: [
                            {
                                id: res.sharePosterPicture.id || '',
                                path: res.sharePosterPicture.url || '',
                            },
                        ],
                        shareSmallPictureId: [
                            {
                                id: (res.shareSmallPicture && res.shareSmallPicture.id) || '',
                                path: (res.shareSmallPicture && res.shareSmallPicture.url) || '',
                            },
                        ],
                        shareContent: res.shareContent,
                    };
                }
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/invitations`;

        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
            const subsiteIds = params.baseInfo.subsiteIds.map((subsite: any) => {
                return subsite.id;
            });
            newParams.subsiteIds = subsiteIds;
        }
        newParams.rewardLimit = params.rewardLimit;
        newParams.rewardMethod = params.rewardMethod;
        newParams.description = params.pictureInfo.description;
        newParams.rewardCondition = params.rewardCondition;
        newParams.memberType = params.memberType;

        const rewardsArray: any = [];
        if (params.inviterRewards && params.inviterRewards.length > 0) {
            forEach(params.inviterRewards, (reward) => {
                let point = null;
                if (reward.pointChecked && reward.pointChecked === true) {
                    point = reward.point;
                }
                let experience = null;
                if (reward.experienceChecked && reward.experienceChecked === true) {
                    experience = reward.experience;
                }
                let couponRewardName = null;
                if (reward.couponRewardNameChecked && reward.couponRewardNameChecked === true) {
                    couponRewardName = reward.couponRewardName;
                }
                const idArray = new Array<{
                    couponDefinitionId: number;
                    quantity: number;
                }>();
                if (reward.couponsValue && reward.couponsValue.length > 0) {
                    forEach(reward.couponsValue, (coupon) => {
                        idArray.push({
                            couponDefinitionId: coupon.id,
                            quantity: coupon.quantity ? coupon.quantity : 1,
                        });
                    });
                }
                let inviterRewardPictureId = 0;
                if (reward.inviterRewardPicture && reward.inviterRewardPicture[0]) {
                    inviterRewardPictureId = reward.inviterRewardPicture[0].id;
                }
                let ladderGiftPictureId = 0;
                if (reward.ladderGiftPicture && reward.ladderGiftPicture[0]) {
                    ladderGiftPictureId = reward.ladderGiftPicture[0].id;
                }
                let gold = null;
                if (reward.goldChecked && reward.goldChecked === true) {
                    gold = reward.gold;
                }
                let custom = null;
                if (reward.customChecked && reward.customChecked === true) {
                    custom = reward.custom;
                }
                rewardsArray.push({
                    stepValue: reward.stepValue > 0 ? reward.stepValue : null,
                    point: point,
                    experience: experience,
                    coupons: idArray,
                    ladderGiftPictureId: ladderGiftPictureId,
                    inviterRewardPictureId: inviterRewardPictureId,
                    gold: gold,
                    custom: custom,
                    couponRewardName: couponRewardName,
                });
            });
        }
        if (params.invitedToReward && params.invitedToReward.registerRewardChecked) {
            newParams.invitedToReward = {
                coupons:
                    (params.invitedToReward.couponsChecked &&
                        params.invitedToReward.couponsValue?.map((coupon: Coupon) => ({
                            couponDefinitionId: coupon.id,
                            quantity: coupon.quantity || 1,
                        }))) ||
                    null,
                point:
                    (params.invitedToReward.pointChecked && params.invitedToReward.point) || null,
                experience:
                    (params.invitedToReward.experienceChecked &&
                        params.invitedToReward.experience) ||
                    null,
                custom:
                    (params.invitedToReward.customChecked && params.invitedToReward.custom) || null,
                gold: (params.invitedToReward.goldChecked && params.invitedToReward.gold) || null,
                inviterRewardPictureId:
                    params.invitedToReward?.inviterRewardPicture &&
                    params.invitedToReward?.inviterRewardPicture[0]?.id,
            };
        } else {
            newParams.invitedToReward = {
                coupons: null,
                point: null,
                experience: null,
                custom: null,
                gold: null,
            };
        }
        newParams.inviterRewards = rewardsArray;

        if (params.pictureInfo) {
            const pageConfig: any = {
                bottomPicture:
                    params.pictureInfo.bottomPictureUrl && params.pictureInfo.bottomPictureUrl[0]
                        ? {
                              id: params.pictureInfo.bottomPictureUrl[0].id,
                              url: params.pictureInfo.bottomPictureUrl[0].path,
                          }
                        : undefined,
                buttonPicture:
                    params.pictureInfo.buttonPictureUrl && params.pictureInfo.buttonPictureUrl[0]
                        ? {
                              id: params.pictureInfo.buttonPictureUrl[0].id,
                              url: params.pictureInfo.buttonPictureUrl[0].path,
                          }
                        : undefined,
                markPicture:
                    params.pictureInfo.markPictureUrl && params.pictureInfo.markPictureUrl[0]
                        ? {
                              id: params.pictureInfo.markPictureUrl[0].id,
                              url: params.pictureInfo.markPictureUrl[0].path,
                          }
                        : undefined,
                sharePosterPicture:
                    params.pictureInfo.sharePosterPictureId &&
                    params.pictureInfo.sharePosterPictureId[0]
                        ? {
                              id: params.pictureInfo.sharePosterPictureId[0].id,
                              url: params.pictureInfo.sharePosterPictureId[0].path,
                          }
                        : undefined,
                shareSmallPicture:
                    params.pictureInfo.shareSmallPictureId &&
                    params.pictureInfo.shareSmallPictureId[0]
                        ? {
                              id: params.pictureInfo.shareSmallPictureId[0].id,
                              url: params.pictureInfo.shareSmallPictureId[0].path,
                          }
                        : undefined,
            };
            newParams.pageConfig = JSON.stringify(pageConfig);
            newParams.shareContent = params.pictureInfo.shareContent;
        }

        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/invitations/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.id = params.id;
            newParams.name = params.baseInfo.name;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
            const subsiteIds = params.baseInfo.subsiteIds.map((subsite: any) => {
                return subsite.id;
            });
            newParams.subsiteIds = subsiteIds;
        }
        newParams.rewardLimit = params.rewardLimit;
        newParams.rewardMethod = params.rewardMethod;
        newParams.description = params.pictureInfo.description;
        newParams.rewardCondition = params.rewardCondition;
        newParams.memberType = params.memberType;
        const rewardsArray: any = [];
        if (params.inviterRewards && params.inviterRewards.length > 0) {
            forEach(params.inviterRewards, (reward) => {
                let point = null;
                if (reward.pointChecked && reward.pointChecked === true) {
                    point = reward.point;
                }
                let experience = null;
                if (reward.experienceChecked && reward.experienceChecked === true) {
                    experience = reward.experience;
                }
                let couponRewardName = null;
                if (reward.couponRewardNameChecked && reward.couponRewardNameChecked === true) {
                    couponRewardName = reward.couponRewardName;
                }
                const idArray = new Array<{
                    couponDefinitionId: number;
                    quantity: number;
                }>();
                if (reward.couponsValue && reward.couponsValue.length > 0) {
                    forEach(reward.couponsValue, (coupon) => {
                        idArray.push({
                            couponDefinitionId: coupon.id,
                            quantity: coupon.quantity ? coupon.quantity : 1,
                        });
                    });
                }
                let inviterRewardPictureId = 0;
                if (reward.inviterRewardPicture && reward.inviterRewardPicture[0]) {
                    inviterRewardPictureId = reward.inviterRewardPicture[0].id;
                }
                let ladderGiftPictureId = 0;
                if (reward.ladderGiftPicture && reward.ladderGiftPicture[0]) {
                    ladderGiftPictureId = reward.ladderGiftPicture[0].id;
                }
                let gold = null;
                if (reward.goldChecked && reward.goldChecked === true) {
                    gold = reward.gold;
                }
                let custom = null;
                if (reward.customChecked && reward.customChecked === true) {
                    custom = reward.custom;
                }
                rewardsArray.push({
                    stepValue: reward.stepValue > 0 ? reward.stepValue : null,
                    point: point,
                    experience: experience,
                    coupons: idArray,
                    ladderGiftPictureId: ladderGiftPictureId,
                    inviterRewardPictureId: inviterRewardPictureId,
                    gold: gold,
                    custom: custom,
                    couponRewardName: couponRewardName,
                });
            });
        }
        if (params.invitedToReward && params.invitedToReward.registerRewardChecked) {
            newParams.invitedToReward = {
                coupons:
                    (params.invitedToReward.couponsChecked &&
                        params.invitedToReward.couponsValue?.map((coupon: Coupon) => ({
                            couponDefinitionId: coupon.id,
                            quantity: coupon.quantity || 1,
                        }))) ||
                    null,
                point:
                    (params.invitedToReward.pointChecked && params.invitedToReward.point) || null,
                experience:
                    (params.invitedToReward.experienceChecked &&
                        params.invitedToReward.experience) ||
                    null,
                custom:
                    (params.invitedToReward.customChecked && params.invitedToReward.custom) || null,
                gold: (params.invitedToReward.goldChecked && params.invitedToReward.gold) || null,
                inviterRewardPictureId:
                    params.invitedToReward?.inviterRewardPicture &&
                    params.invitedToReward?.inviterRewardPicture[0]?.id,
            };
        } else {
            newParams.invitedToReward = {
                coupons: null,
                point: null,
                experience: null,
                custom: null,
                gold: null,
            };
        }
        newParams.inviterRewards = rewardsArray;

        if (params.pictureInfo) {
            const pageConfig: any = {
                bottomPicture:
                    params.pictureInfo.bottomPictureUrl && params.pictureInfo.bottomPictureUrl[0]
                        ? {
                              id: params.pictureInfo.bottomPictureUrl[0].id,
                              url: params.pictureInfo.bottomPictureUrl[0].path,
                          }
                        : undefined,
                buttonPicture:
                    params.pictureInfo.buttonPictureUrl && params.pictureInfo.buttonPictureUrl[0]
                        ? {
                              id: params.pictureInfo.buttonPictureUrl[0].id,
                              url: params.pictureInfo.buttonPictureUrl[0].path,
                          }
                        : undefined,
                markPicture:
                    params.pictureInfo.markPictureUrl && params.pictureInfo.markPictureUrl[0]
                        ? {
                              id: params.pictureInfo.markPictureUrl[0].id,
                              url: params.pictureInfo.markPictureUrl[0].path,
                          }
                        : undefined,
                sharePosterPicture:
                    params.pictureInfo.sharePosterPictureId &&
                    params.pictureInfo.sharePosterPictureId[0]
                        ? {
                              id: params.pictureInfo.sharePosterPictureId[0].id,
                              url: params.pictureInfo.sharePosterPictureId[0].path,
                          }
                        : undefined,
                shareSmallPicture:
                    params.pictureInfo.shareSmallPictureId &&
                    params.pictureInfo.shareSmallPictureId[0]
                        ? {
                              id: params.pictureInfo.shareSmallPictureId[0].id,
                              url: params.pictureInfo.shareSmallPictureId[0].path,
                          }
                        : undefined,
            };
            newParams.pageConfig = JSON.stringify(pageConfig);
            newParams.shareContent = params.pictureInfo.shareContent;
            newParams.marketingScheduleId =
                (params.baseInfo?.marketingSchedule?.length &&
                    params.baseInfo.marketingSchedule[0].id) ||
                null;
        }
        return await api.put(newParams, config);
    },
};
