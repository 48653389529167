import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const MemberInfoLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id, tripartiteRegistered } = data;
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.tripartiteDateRange)) {
            data.tripartiteStartTime = data.tripartiteDateRange.start + ' 00:00:00';
            data.tripartiteEndTime = data.tripartiteDateRange.end + ' 23:59:59';
            delete data.tripartiteDateRange;
        }
        if (!isEmpty(data.firstUseTime)) {
            data.firstUseTimeStart = data.firstUseTime.start + ' 00:00:00';
            data.firstUseTimeEnd = data.firstUseTime.end + ' 23:59:59';
            delete data.firstUseTime;
        }
        if (!isEmpty(data.lastUseTime)) {
            data.lastUseTimeStart = data.lastUseTime.start + ' 00:00:00';
            data.lastUseTimeEnd = data.lastUseTime.end + ' 23:59:59';
            delete data.lastUseTime;
        }
        if (!data.tagName || !data.tagName.trim()) {
            delete data.tagName;
        }
        if (!id) {
            const subsiteName = data.subsiteName;
            if (subsiteName) {
                data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
            }
        }
        if (tripartiteRegistered === 'all') {
            delete data.tripartiteRegistered;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    avatarUrl: res.avatarUrl,
                    name: res.name,
                    birthday: res.birthday,
                    gender: res.gender,
                    region: res.region,
                    address: res.address,
                    postCode: res.postCode,
                };

                if (res.tags && res.tags.length) {
                    const tagMap: any = {};
                    res.tags.forEach((i: any) => {
                        if (!tagMap[i.type.code]) {
                            tagMap[i.type.code] = {
                                typeName: i.type.name,
                                list: [i.name],
                            };
                        } else {
                            tagMap[i.type.code]['list'].push(i.name);
                        }
                    });
                    res.tagInfo = {
                        tags: tagMap,
                    };
                }

                res.accountInfo = {
                    loginName: res.loginName,
                    mobile: res.mobile,
                    levelName: res.levelName,
                    pointAccountBalance: res.pointAccountBalance,
                    expAccountBalance: res.expAccountBalance,
                    couponCount: res.couponCount,
                };
                res.otherInfo = {
                    registrationSource: res.registrationSource,
                    regIp: res.regIp,
                    scanSource: res.scanSource,
                    utmTypeName: res.utmTypeName,
                    regSubsite: res.regSubsite,
                    status: res.status,
                    email: res.email,
                    regTime: res.regTime,
                    memberLoginTime: res.memberLoginTime,
                    memberLoginIp: res.memberLoginIp,
                    mobileValidated: res.mobileValidated,
                    tripartiteRegSubsiteName: res.tripartiteRegSubsiteName,
                    tripartiteRegTime: res.tripartiteRegTime,
                    memberFirstLoginTime: res.memberFirstLoginTime,
                    cid: res.cid,
                    sourceDepartment: res.sourceDepartment,
                    sourceMerchant: res.sourceMerchant,
                    sourceMerchantWorker: res.sourceMerchantWorker,
                    plateNo: res.plateNo,
                    receivingAddress: res.receivingAddress,
                };
            } else {
                res.result.forEach((i: any, index: any) => {
                    if (i.tags) {
                        i.tagName = i.tags.map((i: any) => i.name).join('、');
                    }
                });
            }

            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        return await api.put(params, config);
    },
};

export const MemberCouponLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/coupons';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (data.couponStatus === 'ALL') {
            delete data.couponStatus;
        }
        if (data.type === 'ALL') {
            delete data.type;
        }
        if (data.sourceType === 'ALL') {
            delete data.sourceType;
        }
        data.memberId = data.id;
        delete data.id;
        return await api.get(data, config).then((res: any) => {
            res.result.forEach((item: any) => {
                item.validTime = `${item.startTime}~${item.endTime}`;
            });
            return res;
        });
    },
};

export const MemberPointLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.subsite)) {
            data.subsiteId = data.subsite.id;
            delete data.subsite;
        }
        if (data.id) {
            data.memberId = data.id;
            delete data.id;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/member_account_trans_logs';
        return await api.get(data, config).then((res: any) => {
            res.result.forEach((item: any) => {
                item.transAmount =
                    item.transType === 'IN' ? '+' + item.transAmount : '-' + item.transAmount;
            });
            return res;
        });
    },
};

export const MemberGoldDetailLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const id = data.id;
        delete data.id;
        if (data.dateRange) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = `/admin/gold_accounts/${id}`;
        const res: any = await api.get(data, config);
        res.result.forEach((item: any) => {
            item.transAmount = `${item.transType === 'IN' ? '+' : '-'}${item.transAmount}`;
        });
        return res;
    },
};

export const TransActionSelectorLoader: Loader = {
    get: async function(data, config) {
        const res: any = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                apiPath: `/admin/gold_accounts/trans_action_type`,
            }
        );
        res.forEach((item: any) => {
            item.id = item.transAction;
        });
        return res;
    },
};
