import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { message, Modal, Upload, Icon, Modal as AntModal } from 'antd';
import './index.less';

const { getText } = services.language;

const api = services.api;
const { Dragger } = Upload;

export interface NormalInvoiceTaxrateProductEditFormStates {
    fileList: any[];
    showModal: boolean;
    statusMsg: string;
}

export class NormalInvoiceTaxrateProductEditForm extends Component<
    {},
    NormalInvoiceTaxrateProductEditFormStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            fileList: [],
            showModal: false,
            statusMsg: services.language.getText('uploadFileClickButton'),
        };
    }

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport();
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    downloadTemplate = () => {
        services.api.download(
            {
                type: 'PRODUCT_TAX_RATE_TEMPLATE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/products/export.xlsx',
            }
        );
    };

    onImport = () => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        //开始走上传文件的接口
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('files', fileList[0]);
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                    apiPath: '/admin/files/locations/90/upload',
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then(() => {
                let contentBefore = React.createElement(
                    'span',
                    null,
                    services.language.getText('fileUploading')
                );
                let contentAfter = React.createElement('span', null, getText('toView'));
                let link = React.createElement(
                    'a',
                    {
                        onClick: () => {
                            services.behaviorHandle({
                                route: '/report-download-center',
                            });
                            AntModal.destroyAll();
                        },
                    },
                    getText('dataExportAndImport')
                );
                let content = React.createElement('div', {}, [contentBefore, link, contentAfter]);
                AntModal.info({
                    content: content,
                });

                this.toggleModal();
                //上传成功之后页面置空
                this.setState({
                    fileList: [],
                });
            })
            .catch((err) => {
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                    this.setState({
                        statusMsg: services.language.getText('importFail'),
                    });
                }
            });
    };

    renderImportModal = () => {
        const { showModal, statusMsg, fileList } = this.state;
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: () => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        return (
            <Modal
                onCancel={this.toggleModal}
                visible={showModal}
                title={services.language.getText('batchImport')}
                footer={[
                    <span className="giving-coupon-schedules-status-msg">{statusMsg}</span>,
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        {getText('import')}
                    </Button>,
                    <Button key="back" onClick={this.toggleModal}>
                        {services.language.getText('back')}
                    </Button>,
                ]}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                    <p className="ant-upload-hint">
                        {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                    </p>
                </Dragger>
                <Button
                    style={{ marginTop: '10px' }}
                    onClick={this.downloadTemplate}
                    className="import-button-template tag-modal-import-button-template"
                    type="link"
                >
                    {services.language.getText('clickDownloadTemplate')}
                </Button>
                <div className="giving-coupon-schedules-tips">
                    <p className="giving-coupon-schedules-tips-text">
                        {services.language.getText('importRules')}
                    </p>
                    <p className="giving-coupon-schedules-tips-text">{getText('zwjztxxydrdxx')}</p>
                    <p className="giving-coupon-schedules-tips-text-red">
                        {services.language.getText('merchantBigCodeNote')}
                    </p>
                    <p className="giving-coupon-schedules-tips-text">
                        {getText('h_dqjzcxlxs')}
                        {getText('h_jysbbfz')}
                    </p>
                    <p className="giving-coupon-schedules-tips-text">
                        {getText('merchantBigCodeIntro3')}
                    </p>
                </div>
            </Modal>
        );
    };

    onExport = (eventType: string) => {
        let type = '';
        if (eventType === 'notConfiguredTaxrate') {
            type = 'NO_TAX_RATE';
        }
        if (eventType === 'notSupportedProduct') {
            type = 'NOT_SUPPORT_INVOICE';
        }
        api.get(
            {
                type,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/products/export.xlsx',
            }
        )
            .then(() => {
                let contentBefore = React.createElement('span', null, getText('h_wjydcshkqw'));
                let contentAfter = React.createElement('span', null, getText('toView'));
                let link = React.createElement(
                    'a',
                    {
                        onClick: () => {
                            services.behaviorHandle({
                                route: '/report-download-center',
                            });
                            AntModal.destroyAll();
                        },
                    },
                    getText('dataExportAndImport')
                );
                let content = React.createElement('div', {}, [contentBefore, link, contentAfter]);
                AntModal.info({
                    content: content,
                });
            })
            .catch(errorHandle);
    };

    render() {
        return (
            <div className="parking-invoice-taxrate-product-form">
                <div className="tip">{getText('spbsyspssbzfldpzssbms')}</div>
                <div>
                    <Button
                        className="parking-button"
                        type="primary"
                        onClick={this.onExport.bind(this, 'notConfiguredTaxrate')}
                    >
                        {getText('pldcwpzslsp')}
                    </Button>
                    <Button
                        className="parking-button"
                        type="primary"
                        onClick={this.onExport.bind(this, 'notSupportedProduct')}
                    >
                        {getText('pldcbzckpsp')}
                    </Button>
                    <Button className="parking-button" type="primary" onClick={this.toggleModal}>
                        {getText('pldrspsl')}
                    </Button>
                </div>
                {this.renderImportModal()}
            </div>
        );
    }
}
