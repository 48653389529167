import React from 'react';
import { connect } from 'react-redux';
import './index.less';

const prefix = 'pay-with-gift-config-preview';
/**
 * 支付有礼预览效果
 * @param props
 */
const payWithGiftConfigPreview = (props: any) => {
    const { preview = {} } = props;
    const background =
        preview &&
        preview.baseInfo &&
        preview.baseInfo.backgroundPicture &&
        preview.baseInfo.backgroundPicture.length > 0
            ? preview.baseInfo.backgroundPicture[0].path
            : require('../pay-with-gifts-preview/images/icon-background.png');
    const content = require('../pay-with-gifts-preview/images/icon-content.png');
    const subsiteName = preview && preview.subsiteName ? preview.subsiteName : '';
    return (
        <div className={prefix}>
            <img alt="" className="background" src={background} />
            <div className="content">
                <div className="subsite">
                    <img
                        alt=""
                        src="https://b-puzhehei-cdn.co-mall.net/global/location.svg"
                        className="subsite__icon"
                    />
                    <div className="subsite__text">
                        <span className="subsite__name">{subsiteName}</span>
                    </div>
                </div>
                <img alt="" className="content-image" src={content} />
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const PayWithGiftConfigPreview = connect(mapStateToProps)(payWithGiftConfigPreview);
