import React, { PureComponent } from 'react';
import { cloneDeep, map } from 'lodash';
import { CouponSelector, CouponSelectedDisplay } from '../../components';

export interface Coupon {
    id: string;
    // type: string;
    name: number;
    startTime: string;
    endTime: string;
    quantity?: number;
    /**
     * 被选中的优惠券
     */
    select?: boolean;
}

export class SelectCoupon extends PureComponent<
    any,
    {
        value: Array<Coupon>;
    }
> {
    state = { value: new Array<Coupon>() };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds = row && row.subsiteId ? map(row.subsiteId, 'id') : null;
        return subsiteIds;
    };
    /**
     * 选择优惠券
     * @param selectValues
     */
    onChange = (selectValues: Coupon[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: Coupon[]) => {
        const { value } = this.props;
        let newData: Coupon[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            newData = selectValues;
        }
        this.onChange(newData);
    };

    render() {
        const { value, disabled, getParams } = this.props;
        const hasValue = value && value.length > 0;
        let subsiteIds = this.getSubsiteIds(this.props);
        let params = { subsiteIds };
        if (getParams && typeof getParams === 'function') {
            params = getParams(this.props);
        }
        return (
            <div>
                {!disabled && (
                    <CouponSelector
                        onChange={this.onSelect}
                        selectValues={value}
                        type="radio"
                        params={params}
                    />
                )}
                {hasValue && (
                    <CouponSelectedDisplay
                        data={value}
                        onRemove={this.onChange}
                        disabled={disabled}
                    ></CouponSelectedDisplay>
                )}
            </div>
        );
    }
}
