import React, { Component } from 'react';
import { InputNumber, Radio, Select } from 'antd';
import { cloneDeep, get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export enum RegistrationPriceType {
    NONE = 'NONE',
    PAY = 'PAY',
}
export interface MallActivityCelableValue {
    cancelable: boolean;
    cancelableRule: CancelableRule;
}

export interface CancelableRule {
    duration: number;
    unit: CancelableRuleUnit;
}

export enum CancelableRuleUnit {
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
}

export interface MallActivityCelableProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: MallActivityCelableValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: MallActivityCelableValue;
    /**
     * 当前值
     */
    disabled?: boolean;
    row: any;
}
export class MallActivityCelable extends Component<MallActivityCelableProps> {
    onValueChange = (type: any, e: any) => {
        let { onChange, value, name } = this.props;

        if (type === 'cancelable') {
            onChange({ ...value, cancelable: e.target.value }, name);
        } else if (type === 'duration') {
            const newValue = cloneDeep(value);
            newValue.cancelableRule.duration = e;
            onChange(newValue, name);
        } else if (type === 'unit') {
            const newValue = cloneDeep(value);
            newValue.cancelableRule.unit = e;
            onChange(newValue, name);
        }
    };
    render() {
        const { value, disabled, row } = this.props;
        const session = get(row, 'registrationInfo.session');
        return (
            <div className="registration-price">
                <Radio.Group
                    disabled={disabled}
                    value={value ? value.cancelable : ''}
                    onChange={(e) => this.onValueChange('cancelable', e)}
                >
                    <Radio className="block-radio" style={{ marginTop: '8px' }} value={false}>
                        {/* <span>不可取消</span> */}
                        <span>{language.getText('byxyhqxdd')}</span>
                    </Radio>
                    <Radio className="block-radio" value={true}>
                        {/* <span>可取消&nbsp;</span> */}
                        <span>{language.getText('yxyhz')}&nbsp;</span>
                        {/* <div className="input-container" style={{ paddingLeft: '30px' }}> */}
                        <div className="input-container">
                            {session === 'NO' ? language.getText('hd') : language.getText('jvenue')}
                            {services.language.getText('ksq')}&nbsp;
                            <InputNumber
                                disabled={disabled}
                                className="input-item"
                                style={{ width: '150px' }}
                                onChange={(e) => this.onValueChange('duration', e)}
                                min={0}
                                max={999999}
                                step={1}
                                precision={0}
                                value={value?.cancelableRule?.duration}
                            />
                            <Select
                                value={value?.cancelableRule?.unit}
                                style={{ width: '100px' }}
                                onChange={(e: any) => this.onValueChange('unit', e)}
                            >
                                <Select.Option value={CancelableRuleUnit.MINUTE}>
                                    {services.language.getText('minutes')}
                                </Select.Option>
                                <Select.Option value={CancelableRuleUnit.HOUR}>
                                    {language.getText('hour')}
                                </Select.Option>
                            </Select>
                        </div>
                        <span>&nbsp;&nbsp;{language.getText('qxdd')}</span>
                    </Radio>
                </Radio.Group>
            </div>
        );
    }
}
