import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        DataPanelsBiPagesView: {
            component: 'Viewport',
            className: 'bi-pages',
        },
        DataPanelsBiPagesPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'BiIframePage',
                    params: {
                        apiPath: '/BI-DATART-ADAPTOR/auth_pass_on',
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/data-panels-bi-pages',
                    component: 'DataPanelsBiPagesView',
                    breadcrumbName: 'datart',
                    privilege: {
                        path: 'biData',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'DataPanelsBiPagesPage' }],
                },
            ],
        },
    ],
};
