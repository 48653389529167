import { PureComponent } from 'react';
import {
    message as AntMessage,
    Form,
    Button,
    Popconfirm,
    Modal,
    Input,
    InputNumber,
    Radio,
    DatePicker,
} from 'antd';
import { Textbox } from '@comall-backend-builder/components-basis';
import { FormComponentProps } from 'antd/es/form';
import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import { services } from '@comall-backend-builder/core';
import { forEach } from 'lodash';
import './index.less';

export interface RestaurantReservationListItemButtonsProps {
    row: any;
    entity: Entity;
    params: any;
    form: FormComponentProps['form'];
}

export enum SpaceType {
    HALL = 'HALL',
    BOX = 'BOX',
}

export interface RestaurantReservationListItemButtonsStates {
    visible: boolean;
    changeType: ChangeType;
    auditRemark: string;
    location: string;
    reservationTime: string;
    spaceType: SpaceType;
    totalCount: number;
    sendMsg: boolean;
    spaceTypes: Array<SpaceType>;
}

enum RestaurantReservationStatus {
    /**
     * 待确认
     */
    CREATE = 'CREATE',
    /**
     * 已预订
     */
    AUDITED = 'AUDITED',
    /**
     * 已取消
     */
    CANCELED = 'CANCELED',
}

enum ChangeType {
    EDIT = 'EDIT',
    AUDIT = 'AUDIT',
}

/**
 * 售后单审核
 */
class restaurantReservationListItemButtons extends PureComponent<
    RestaurantReservationListItemButtonsProps,
    RestaurantReservationListItemButtonsStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            changeType: ChangeType.EDIT,
            auditRemark: '',
            location: '',
            reservationTime: '',
            spaceType: SpaceType.HALL,
            totalCount: 0,
            sendMsg: true,
            spaceTypes: [],
        };
    }
    onCancelOrder = () => {
        const { row, entity, params } = this.props;
        const { auditRemark } = this.state;
        api.put(
            {
                auditRemark: auditRemark,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: `/admin/restaurant_reservation_orders/${row.id}/canceled`,
            }
        )
            .then(() => {
                AntMessage.success(language.getText('qxcg'));
                entity.search(params);
                this.setState({
                    visible: false,
                });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    onCancel = () => {
        this.setState({
            visible: false,
            auditRemark: '',
        });
    };

    onChange = (changeType: ChangeType) => {
        const { row } = this.props;
        api.get(
            {
                id: row.merchantId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: `/admin/merchants/${row.merchantId}`,
            }
        ).then((res: any) => {
            //取专柜配置的和该条单子选中的类型并集
            const spaceTypes: SpaceType[] = [];
            if (res.restaurantReservation && res.restaurantReservation.spaceTypes.length > 0) {
                forEach(res.restaurantReservation.spaceTypes, (spaceType) => {
                    spaceTypes.push(spaceType);
                });
            }
            if (!spaceTypes.includes(row.spaceType)) {
                spaceTypes.push(row.spaceType);
            }
            this.setState({
                changeType,
                visible: true,
                spaceTypes: spaceTypes,
                location: row.location,
                reservationTime: row.reservationTime,
                spaceType: row.spaceType,
                totalCount: row.totalCount,
                auditRemark: row.auditRemark,
                sendMsg: changeType === ChangeType.EDIT ? false : true, //是否发短信，编辑弹层默认不选中，审核弹层默认选中
            });
        });
    };

    onTimeChange = (val: any) => {
        const timeStr = moment(val || undefined).format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            reservationTime: timeStr,
        });
    };
    handleSubmit = () => {
        const { row, entity, params } = this.props;
        const {
            changeType,
            auditRemark,
            sendMsg,
            location,
            reservationTime,
            spaceType,
            totalCount,
        } = this.state;
        if (!totalCount) {
            AntMessage.warning(services.language.getText('qtxycrs'));
            return;
        }
        const changeParams = {
            auditRemark: auditRemark,
            info: {
                location,
                reservationTime,
                spaceType,
                totalCount,
            },
            sendMsg,
        };
        let apiPath = '';
        if (changeType === ChangeType.EDIT) {
            apiPath = `/admin/restaurant_reservation_orders/${row.id}`;
        } else if (changeType === ChangeType.AUDIT) {
            apiPath = `/admin/restaurant_reservation_orders/${row.id}/audited`;
        }
        //修改
        api.put(
            {
                ...changeParams,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: apiPath,
            }
        ).then(
            () => {
                AntMessage.success(
                    `${
                        changeType === ChangeType.EDIT
                            ? language.getText('common.modify')
                            : language.getText('audity')
                    }${language.getText('success')}`
                );
                entity.search(params);
                this.setState({
                    visible: false,
                });
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };

    disabledDate(current: moment.Moment | null) {
        return !!(current && current < moment().subtract(1, 'days'));
    }

    renderFooter = () => {
        const { changeType } = this.state;
        if (changeType === ChangeType.EDIT) {
            return (
                <div>
                    <Button onClick={this.onCancel}>{language.getText('common.cancel')}</Button>
                    <Button type="primary" onClick={this.handleSubmit}>
                        {language.getText('common.confirm')}
                    </Button>
                </div>
            );
        }
        return (
            <div>
                <Button onClick={this.onCancelOrder}>{services.language.getText('shbtg')}</Button>
                <Button type="primary" onClick={this.handleSubmit}>
                    {language.getText('approved')}
                </Button>
            </div>
        );
    };

    renderModal = () => {
        const {
            visible,
            spaceTypes,
            changeType,
            location,
            auditRemark,
            totalCount,
            spaceType,
            sendMsg,
            reservationTime,
        } = this.state;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                title={
                    changeType === ChangeType.EDIT
                        ? language.getText('common.modify')
                        : language.getText('sp_1')
                }
                width="570px"
                onCancel={this.onCancel}
                footer={this.renderFooter()}
            >
                <div className="restaurant-reservation-modal">
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ycrs')}：
                        </div>
                        <div className="item-content">
                            <InputNumber
                                style={{ width: 100 }}
                                placeholder={services.language.getText('inputPlease')}
                                min={1}
                                max={100}
                                step={1}
                                precision={0}
                                value={totalCount}
                                onChange={(value: any) => {
                                    this.onChangeValue('totalCount', value);
                                }}
                            ></InputNumber>
                            &nbsp;&nbsp;人
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ycsj')}：
                        </div>
                        <div className="item-content">
                            <DatePicker
                                style={{ width: '150px' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                value={reservationTime ? moment(reservationTime) : undefined}
                                onChange={this.onTimeChange}
                                placeholder={services.language.getText('selectPlease')}
                                disabledDate={this.disabledDate}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ydwz')}：
                        </div>
                        <div className="item-content">
                            <Radio.Group
                                value={spaceType}
                                onChange={(value: any) => {
                                    this.onChangeValue('spaceType', value.target.value);
                                }}
                            >
                                {spaceTypes.map((s: SpaceType) => {
                                    const spaceTypes = {
                                        [SpaceType.HALL]: language.getText('hall'),
                                        [SpaceType.BOX]: language.getText('privateRoom'),
                                    };
                                    return <Radio value={s}>{spaceTypes[s]}</Radio>;
                                })}
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('wzmc')}：</div>
                        <div className="item-content">
                            <Input
                                style={{ width: 200 }}
                                placeholder={language.getText('qsrjtwz')}
                                value={location}
                                onInput={(data: any) =>
                                    this.onChangeValue('location', data.target.value)
                                }
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{services.language.getText('spxx')}：</div>
                        <div className="item-content">
                            <Textbox
                                prefixIcon=""
                                suffixIcon=""
                                type="textarea"
                                autoSize={{ minRows: 5, maxRows: 5 }}
                                style={{
                                    width: '400px',
                                }}
                                name="textBox"
                                placeholder={language.getText('qsrqrdxx')}
                                value={auditRemark}
                                onChange={(value: any) => this.onChangeValue('auditRemark', value)}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('dxtz')}：</div>
                        <div className="item-content">
                            <Radio.Group
                                value={sendMsg}
                                onChange={(value: any) => {
                                    this.onChangeValue('sendMsg', value.target.value);
                                }}
                            >
                                <Radio value={true}>{language.getText('fs_1')}</Radio>
                                <Radio value={false}>{language.getText('bfs')}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    onChangeValue(name: string, inputValue: any) {
        this.setState({
            [name]: inputValue,
        } as Pick<RestaurantReservationListItemButtonsStates, 'auditRemark' | 'location' | 'reservationTime' | 'spaceType' | 'totalCount' | 'sendMsg'>);
        return {};
    }

    goLogs = () => {
        const { row } = this.props;
        window.location.href = `/#/restaurant-reservation/logs/${row.id}`;
    };

    render() {
        const { row } = this.props;
        return (
            <div>
                {row.status === RestaurantReservationStatus.AUDITED && (
                    <Button
                        style={{ padding: '0 5px' }}
                        type="link"
                        onClick={this.onChange.bind(this, ChangeType.EDIT)}
                    >
                        {language.getText('common.modify')}
                    </Button>
                )}
                {row.status === RestaurantReservationStatus.CREATE && (
                    <Button
                        style={{ padding: '0 5px' }}
                        type="link"
                        onClick={this.onChange.bind(this, ChangeType.AUDIT)}
                    >
                        {language.getText('sp_1')}
                    </Button>
                )}
                {(row.status === RestaurantReservationStatus.CREATE ||
                    row.status === RestaurantReservationStatus.AUDITED) && (
                    <Popconfirm
                        onConfirm={() => {
                            this.setState(
                                {
                                    auditRemark: '',
                                },
                                () => {
                                    this.onCancelOrder();
                                }
                            );
                        }}
                        title={language.getText('qrqxyd')}
                        okText={language.getText('common.confirm')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button style={{ padding: '0 5px' }} type="link">
                            {language.getText('common.cancel')}
                        </Button>
                    </Popconfirm>
                )}
                <Button style={{ padding: '0 5px' }} type="link" onClick={this.goLogs}>
                    {language.getText('common.log')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}
function mapStateToProps(state: any, props: RestaurantReservationListItemButtonsProps) {
    const { entity, row, params } = props;
    return {
        entity,
        row,
        params,
    };
}
export const RestaurantReservationListItemButtons = connect(mapStateToProps)(
    Form.create()(restaurantReservationListItemButtons)
);
