import React, { Component } from 'react';
import { InputNumber, Select, Button, message } from 'antd';
import { find, clone, isEqual, map, concat, isEmpty } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

type RulesValue = {
    /**
     * 限购类型
     */
    ruleType?: string;
    /**
     * 限购件数
     */
    ruleCount?: number;
};

export interface UserRulesProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 候选项集合
     */
    options: Array<any>;
    typeTitle?: string;
    defaultValue?: string;
}
export class UserRules extends Component<UserRulesProps> {
    onRuleTypeChange(ruleType: string, index: number) {
        let { name, value, onChange } = this.props;

        if (value !== null && !isEmpty(value)) {
            let userRule = find(value, { ruleType });
            if (userRule) {
                message.warning(services.language.getText('userRuleLimit'));
                return;
            }
            let item = clone(value[index]);
            item.ruleType = ruleType;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(ruleType)) {
                message.warning(services.language.getText('qxzyhxglx'));
                return;
            }
            let item: RulesValue = {};
            item.ruleType = ruleType;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRuleCountChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        let ruleCount = event;
        if (!isEmpty(ruleCount) && ruleCount < 1) {
            ruleCount = 1;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.ruleCount = ruleCount;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(ruleCount)) {
                message.warning(services.language.getText('qsrzdgmjs'));
                return;
            }
            let item: RulesValue = {};
            item.ruleCount = ruleCount;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: UserRulesProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, typeTitle } = this.props;

        return (
            <div className="user-rules">
                {typeTitle ? <div>{typeTitle}</div> : ''}
                {map(value, (rule, index) => this.renderRule(rule, index))}
                {value && value.length ? null : this.renderRule({}, 0)}
                <Button type="default" className="add-rule-button" onClick={() => this.onAddRule()}>
                    {services.language.getText('tjyhxg')}
                </Button>
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number) {
        const { options, defaultValue } = this.props;
        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text"> {services.language.getText('yhxg')}：</span>
                    <Select
                        className="rule-type-selection"
                        placeholder={services.language.getText('selectPlease')}
                        value={rule.ruleType || defaultValue}
                        onChange={(ruleType: any) => this.onRuleTypeChange(ruleType, index)}
                    >
                        {map(options, ({ id, name }) => (
                            <Select.Option key={id}>{name}</Select.Option>
                        ))}
                    </Select>
                    <span className="item-text">{services.language.getText('zdgm')}</span>
                    <div className="rule-count-input">
                        <InputNumber
                            type="number"
                            min={1}
                            value={rule.ruleCount}
                            onChange={(e) => this.onRuleCountChange(e, index)}
                        />
                    </div>
                    <span className="item-text">{services.language.getText('jian')}</span>
                    {!isEmpty(rule) && (
                        <Button
                            type="default"
                            className="remove-rule-button"
                            onClick={() => this.onRemoveRule(index)}
                        >
                            {services.language.getText('common.delete')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}
