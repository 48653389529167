import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { CarparkModuleInfoDisplay } from './carpark-module-info';

export class CarparkModuleInfoMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CarparkModuleInfoDisplay {...controlInfo} />;
    }
}
