import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach, isEmpty } from 'lodash';

const { getText } = services.language;

export const config: Config = {
    entities: {
        StoredValueCardActivityEntity: {
            apiPath: '/loader/admin/stored_value_card_activity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<effectiveTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                hideTime: true,
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<activityMerchant>>',
                            rules: [{ required: true }],
                        },
                    },
                },
                useRulesInfo: {
                    type: 'object.subForm',
                    displayName: '<<storedValueCardProduct>>',
                    properties: {
                        products: {
                            type: 'array.storedValueCardSetting',
                            displayName: '<<settings>>',
                            rules: [{ required: true, message: '<<qxzklb>>' }],
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        StoredValueCardActivityView: {
            component: 'Viewport',
            entity: 'StoredValueCardActivityEntity',
        },
        StoredValueCardActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoredValueCardActivityFilter',
                    span: 24,
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    style: {
                        marginTop: 18,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/stored-value-card-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'StoredValueCardActivityTable' },
            ],
        },
        StoredValueCardActivityFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'dateRange',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        StoredValueCardActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/stored_value_card_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        ext: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/stored_value_card_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        ext: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/stored-value-card-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/stored-value-card-activity/copy/{{row.id}}',
                    },
                ],
            },
        },
        StoredValueCardActivityAddPage: {
            component: 'Card',
            content: { component: 'StoredValueCardActivityAddForm' },
        },
        StoredValueCardActivityAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardActivityEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'useRulesInfo.products' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const products = entity.useRulesInfo.products;
                if (!products || products.length === 0) {
                    AntMessage.warning(getText('qxzklb'));
                    return false;
                }
                const dateRange = entity.baseInfo.dateRange;
                const { start, end } = dateRange;
                let errorTip = '';
                forEach(products, (i) => {
                    //必须有一个上架的
                    const shelfStyles = i.styles.filter((i: any) => i.status === true) || [];
                    if (shelfStyles.length === 0) {
                        errorTip = getText('klbxbxyygsjd');
                        return false;
                    }
                    //如果是上架的，活动价必须填
                    const noPriceStyles = i.styles.filter(
                        (i: any) => i.status === true && !i.price
                    );
                    if (noPriceStyles.length > 0) {
                        errorTip = getText('sjdmz');
                        return false;
                    }
                    //选择卡有效期
                    if (!isEmpty(i.effectiveStartTime) && !isEmpty(i.effectiveEndTime)) {
                        //设置的卡有效期 开始和结束时间 必须大于活动开始时间
                        if (
                            new Date(i.effectiveStartTime).getTime() < new Date(start).getTime() ||
                            new Date(i.effectiveEndTime).getTime() < new Date(start).getTime()
                        ) {
                            errorTip = getText('kyxqdkshjssj');
                            return false;
                        }
                        if (new Date(i.effectiveEndTime).getTime() < new Date(end).getTime()) {
                            errorTip = getText('kyxqdjssj');
                            return false;
                        }
                    }
                });
                if (errorTip) {
                    AntMessage.warning(errorTip);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardActivityEditPage: {
            component: 'Card',
            content: { component: 'StoredValueCardActivityEditForm' },
        },
        StoredValueCardActivityEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'StoredValueCardActivityEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.subsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'useRulesInfo.products' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const products = entity.useRulesInfo.products;
                if (!products || products.length === 0) {
                    AntMessage.warning(getText('qxzklb'));
                    return false;
                }
                const dateRange = entity.baseInfo.dateRange;
                const { start, end } = dateRange;
                let errorTip = '';
                forEach(products, (i) => {
                    //必须有一个上架的
                    const shelfStyles = i.styles.filter((i: any) => i.status === true) || [];
                    if (shelfStyles.length === 0) {
                        errorTip = getText('klbxbxyygsjd');
                        return false;
                    }
                    //如果是上架的，活动价必须填
                    const noPriceStyles = i.styles.filter(
                        (i: any) => i.status === true && !i.price
                    );
                    if (noPriceStyles.length > 0) {
                        errorTip = getText('sjdmz');
                        return false;
                    }
                    //选择卡有效期
                    if (!isEmpty(i.effectiveStartTime) && !isEmpty(i.effectiveEndTime)) {
                        //设置的卡有效期 开始和结束时间 必须大于活动开始时间
                        if (
                            new Date(i.effectiveStartTime).getTime() < new Date(start).getTime() ||
                            new Date(i.effectiveEndTime).getTime() < new Date(start).getTime()
                        ) {
                            errorTip = getText('kyxqdkshjssj');
                            return false;
                        }
                        if (new Date(i.effectiveEndTime).getTime() < new Date(end).getTime()) {
                            errorTip = getText('kyxqdjssj');
                            return false;
                        }
                    }
                });
                if (errorTip) {
                    AntMessage.warning(errorTip);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardActivityCopyPage: {
            component: 'Card',
            content: { component: 'StoredValueCardActivityCopyForm' },
        },
        StoredValueCardActivityCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'StoredValueCardActivityEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'useRulesInfo.products' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const products = entity.useRulesInfo.products;
                if (!products || products.length === 0) {
                    AntMessage.warning(getText('qxzklb'));
                    return false;
                }
                const dateRange = entity.baseInfo.dateRange;
                const { start, end } = dateRange;
                let errorTip = '';
                forEach(products, (i) => {
                    //必须有一个上架的
                    const shelfStyles = i.styles.filter((i: any) => i.status === true) || [];
                    if (shelfStyles.length === 0) {
                        errorTip = getText('klbxbxyygsjd');
                        return false;
                    }
                    //如果是上架的，活动价必须填
                    const noPriceStyles = i.styles.filter(
                        (i: any) => i.status === true && !i.price
                    );
                    if (noPriceStyles.length > 0) {
                        errorTip = getText('sjdmz');
                        return false;
                    }
                    //选择卡有效期
                    if (!isEmpty(i.effectiveStartTime) && !isEmpty(i.effectiveEndTime)) {
                        //设置的卡有效期 开始和结束时间 必须大于活动开始时间
                        if (
                            new Date(i.effectiveStartTime).getTime() < new Date(start).getTime() ||
                            new Date(i.effectiveEndTime).getTime() < new Date(start).getTime()
                        ) {
                            errorTip = getText('kyxqdkshjssj');
                            return false;
                        }
                        if (new Date(i.effectiveEndTime).getTime() < new Date(end).getTime()) {
                            errorTip = getText('kyxqdjssj');
                            return false;
                        }
                    }
                });
                if (errorTip) {
                    AntMessage.warning(errorTip);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/stored-value-card-activity',
                    component: 'StoredValueCardActivityView',
                    breadcrumbName: '<<storedValueCardActivity>>',
                    privilege: {
                        path: 'storedValueCardActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'StoredValueCardActivityAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'StoredValueCardActivityEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'StoredValueCardActivityCopyPage',
                            breadcrumbName: '<<copy>>',
                        },
                        { path: '/', component: 'StoredValueCardActivityPage' },
                    ],
                },
            ],
        },
    ],
};
