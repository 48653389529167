import React, { PureComponent } from 'react';
import { Checkbox } from 'antd';
import { NumberInputTip } from '../../../components';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface ValueItem {
    conditionType?: string;
    checked?: string;
    day?: number | undefined;
}

export interface MaotaiPresalePrizeConditionProps {
    name: string;
    value: ValueItem[];
    onChange: (value: ValueItem[]) => void;
}
export class MaotaiPresalePrizeConditions extends PureComponent<MaotaiPresalePrizeConditionProps> {
    static defaultProps = {
        value: [],
    };
    onCheckChange = (e: any) => {
        const { checked, value: conditionType } = e.target;
        const { onChange, value } = this.props;
        const item = value.find((i) => i.conditionType === conditionType);
        if (!item) {
            onChange([...value, { conditionType, checked }]);
        } else {
            const list = value.map((item) => {
                if (item.conditionType === conditionType) {
                    return { ...item, checked };
                }
                return item;
            });
            onChange(list);
        }
    };

    dayChange = (val: number, conditionType: string) => {
        const { onChange, value } = this.props;
        const item = value.find((i) => i.conditionType === conditionType);
        if (!item) {
            onChange([...value, { conditionType, day: val }]);
        } else {
            const list = value.map((item) => {
                if (item.conditionType === conditionType) {
                    return { ...item, day: val };
                }
                return item;
            });
            onChange(list);
        }
    };

    render() {
        const { value } = this.props;
        const prizeChecked = !!value.find((item) => item.conditionType === 'PRIZE')?.checked;
        const prizeDay = value.find((item) => item.conditionType === 'PRIZE')?.day;
        const consumeChecked = !!value.find((item) => item.conditionType === 'CONSUME')?.checked;
        const consumeDay = value.find((item) => item.conditionType === 'CONSUME')?.day;
        return (
            <div className="maotai-presale-prize-conditions">
                <div className="maotai-presale-prize-conditions-row">
                    <div className="maotai-presale-prize-conditions-checkbox">
                        <Checkbox
                            checked={prizeChecked}
                            value="PRIZE"
                            onChange={this.onCheckChange}
                        ></Checkbox>
                    </div>
                    <NumberInputTip
                        min={0}
                        onChange={(e: any) => {
                            this.dayChange(e, 'PRIZE');
                        }}
                        value={prizeDay}
                        max={999999999}
                        precision={0}
                        style={{ width: 120 }}
                        addonafter={services.language.getText('tnbkzcsg')}
                        addonbefore={services.language.getText('jlscsgzs')}
                        tip={services.language.getText('rzqbhzccysg')}
                    ></NumberInputTip>
                </div>
                <div className="maotai-presale-prize-conditions-row">
                    <div className="maotai-presale-prize-conditions-checkbox">
                        <Checkbox
                            checked={consumeChecked}
                            value="CONSUME"
                            onChange={this.onCheckChange}
                        ></Checkbox>
                    </div>
                    <NumberInputTip
                        min={0}
                        value={consumeDay}
                        max={999999999}
                        onChange={(e: any) => {
                            this.dayChange(e, 'CONSUME');
                        }}
                        precision={0}
                        style={{ width: 120 }}
                        addonafter={services.language.getText('tnbkzcsg')}
                        addonbefore={services.language.getText('jlschxzs')}
                        tip={services.language.getText('rzqbkzccysg')}
                    ></NumberInputTip>
                </div>
            </div>
        );
    }
}
