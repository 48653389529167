/**
 * 消息群发
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import get from 'lodash/get';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { SendMemberMode } from '../../types/mode/object/member-selector/config-select-member';

export const config: Config = {
    components: {
        MessageBatchViewV2: {
            component: 'Viewport',
            entity: 'MessageBatchEntity',
        },
        MessageBatchPageV2: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'dateRange',
                        },
                        {
                            property: 'subject',
                        },
                        {
                            property: 'sendStatusEnum',
                            controlConfig: {
                                style: { width: 180 },
                            },
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '<<xjqf>>',
                    route: '/message-batch-v2/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'MessageBatchTableV2' },
            ],
        },

        MessageBatchTableV2: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subject',
                },
                {
                    property: 'content',
                },
                {
                    property: 'type',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'creatorStr',
                },
                {
                    property: 'sendTime',
                },
                {
                    property: 'sendStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 140,
                items: [
                    {
                        type: 'component',
                        component: 'MessageBatchButton',
                        config: {
                            routeRoot: 'message-batch-v2',
                        },
                    },
                ],
            },
        },

        MessageBatchAddPageV2: {
            entity: 'MessageBatchHandleEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'MessageBatchViewV2',
                },
                {
                    component: 'MessageBatchAddFormV2',
                },
            ],
        },
        MessageBatchEditPageV2: {
            entity: 'MessageBatchHandleEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'MessageBatchViewV2',
                },
                {
                    component: 'MessageBatchEditFormV2',
                },
            ],
        },
        MessageBatchAddFormV2: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'subject' },
                { property: 'content' },
                { property: 'pic' },
                { property: 'type' },
                { property: 'tags' },
                { property: 'linkTypeNew' },
                { property: 'sendTimeEnum' },
                {
                    property: 'regularSendTime',
                    visible: (values: any) => get(values, 'sendTimeEnum') === 'REGULAR_SEND',
                },
                { property: 'offlineMessageSaveTime' },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const tags = get(entity, 'tags');
                if (tags.mode === SendMemberMode.LEVEL && (!tags.list || !tags.list.length)) {
                    AntMessage.warning(services.language.getText('qtjhykdj'));
                    return false;
                }
                if (tags.mode === SendMemberMode.MOBILE && (!tags.file || !tags.file.fileName)) {
                    AntMessage.warning(services.language.getText('qdrzdhy'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        MessageBatchEditFormV2: {
            entity: 'MessageBatchHandleEntity',
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'subject' },
                { property: 'content' },
                { property: 'pic' },
                { property: 'type' },
                { property: 'tags' },
                { property: 'linkTypeNew' },
                { property: 'sendTimeEnum' },
                {
                    property: 'regularSendTime',
                    visible: (values: any) => get(values, 'sendTimeEnum') === 'REGULAR_SEND',
                },
                { property: 'offlineMessageSaveTime' },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const tags = get(entity, 'tags');
                if (tags.mode === SendMemberMode.LEVEL && (!tags.list || !tags.list.length)) {
                    AntMessage.warning(services.language.getText('qtjhykdj'));
                    return false;
                }
                if (tags.mode === SendMemberMode.MOBILE && (!tags.file || !tags.file.fileName)) {
                    AntMessage.warning(services.language.getText('qdrzdhy'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('editSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/message-batch-v2',
                    component: 'MessageBatchViewV2',
                    breadcrumbName: '<<messageBatch>>',
                    // privilege: {
                    //     path: 'message-batch-v2',
                    //     level: 'full',
                    // },
                    childRoutes: [
                        { path: '/add', component: 'MessageBatchAddPageV2' },
                        { path: '/edit/:id', component: 'MessageBatchEditPageV2' },
                        {
                            path: '/info/:id',
                            component: 'MessageBatchInfoPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'MessageBatchPageV2' },
                    ],
                },
            ],
        },
    ],
};
