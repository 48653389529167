import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import { SelectMultiSubsite } from './select-multi-subsite';
// import './index.less';
const { language } = services;
class CustomSelectMultiSubsite extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
        type?: string;
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;

        this.hideModal();
        // let ids = '';
        // let names = '';
        // value &&
        //     value.map((subsite: any) => {
        //         ids = ids + subsite.id + ',';
        //         names = names + subsite.name + ',';
        //     });
        // onChange({
        //     id: ids.substring(0, ids.length - 1),
        //     name: names.substring(0, ids.length - 1),
        // });
        onChange(value);
    };
    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
        } = this.props;
        const { visibleModal } = this.state;
        const hasSingleTarget = linkParams && linkParams.length > 0;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };

        return (
            <span className="link-name">
                {hasSingleTarget ? (
                    <span className="link-name-label">
                        {linkParams.map((subsite: any, index: number) => {
                            if (index < linkParams.length - 1) {
                                return subsite.name + '，';
                            } else {
                                return subsite.name;
                            }
                        })}
                    </span>
                ) : (
                    '未选择'
                )}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <SelectMultiSubsite
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    {...selectProps}
                />
            </span>
        );
    }
}

export { CustomSelectMultiSubsite };
