import React, { Component } from 'react';
import { Input, InputNumber, Select } from 'antd';
import { ORDER_STATUS_ENUM, PickingListsOrderItems } from '../../../services/order-print';
import { isEqual } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface PickingGoodsProps {
    /**
     * 订单列表数据
     */
    orderItem: PickingListsOrderItems;
    /**
     * 订单状态
     */
    status: ORDER_STATUS_ENUM;
    /**
     * 修改订单内容函数
     */
    onfieldChange: (field: string, value: string | number, id: string) => void;
}

interface PickingGoodsStates {
    /**
     * 条形码
     */
    barCode: string;
    /**
     * 实际重量
     */
    weight: number | undefined;
}

const diplayUnitText = {
    KG: 'kg',
    G: 'g',
    PIECE: '',
};

/**
 * 格式化重量
 * @param quantity 重量
 * @param unit 重量单位
 * @returns
 */
function formartQuantity(quantity: number | string | undefined, unit: 'KG' | 'G' | 'PIECE') {
    if (quantity === undefined || quantity === '') {
        return '';
    }
    const displayUnit = diplayUnitText[unit];
    if (quantity === 0 || quantity === '0') {
        return '0' + displayUnit;
    }
    if (unit === 'KG') {
        const quantities = (Number(quantity) / 1000).toString().split('.');
        if (quantities.length < 2) {
            return quantities[0] + '' + displayUnit;
        } else {
            return quantities[0] + '.' + quantities[1].slice(0, 3) + displayUnit;
        }
    } else {
        return quantity + '' + displayUnit;
    }
}

/**
 * 拣货单订单项商品
 */
export class PickingGoods extends Component<PickingGoodsProps, PickingGoodsStates> {
    constructor(props: PickingGoodsProps) {
        super(props);
        this.state = {
            barCode: '',
            weight: undefined,
        };
    }

    componentDidMount() {
        const { orderItem } = this.props;
        if (orderItem) {
            this.setState({
                barCode: orderItem.barCode,
                weight: orderItem.actualPickQuantity
                    ? orderItem.goods.displayUnit === 'KG'
                        ? orderItem.actualPickQuantity / 1000
                        : orderItem.actualPickQuantity
                    : undefined,
            });
        }
    }

    componentWillReceiveProps(props: PickingGoodsProps) {
        const { orderItem: nextOrder } = props;
        const { orderItem: prevOrder } = this.props;
        if (nextOrder.barCode !== prevOrder.barCode) {
            this.setState({
                barCode: nextOrder.barCode,
            });
        }
        if (nextOrder.actualPickQuantity !== prevOrder.actualPickQuantity) {
            this.setState({
                weight:
                    nextOrder.actualPickQuantity || nextOrder.actualPickQuantity === 0
                        ? nextOrder.goods.displayUnit === 'KG'
                            ? nextOrder.actualPickQuantity / 1000
                            : nextOrder.actualPickQuantity
                        : undefined,
            });
        }
    }

    shouldComponentUpdate(props: PickingGoodsProps, state: PickingGoodsStates) {
        const { orderItem: nextOrder, status: nextstatus } = props;
        const { orderItem: prevOrder, status: prevStatus } = this.props;
        return (
            !isEqual(prevOrder, nextOrder) ||
            !isEqual(state, this.state) ||
            !isEqual(nextstatus, prevStatus)
        );
    }

    onBarCodeInput = (e: any) => {
        this.setState({
            barCode: e.target.value,
        });
    };

    onWeightInput = (value: any) => {
        this.setState({
            weight: value,
        });
    };

    onBarCodeChange = (e: any) => {
        const { onfieldChange, orderItem } = this.props;
        if (onfieldChange) {
            onfieldChange('barCode', e.target.value, orderItem.id);
        }
    };

    onWeightChange = (e: any) => {
        const { onfieldChange, orderItem } = this.props;
        let value = e.target.value;
        if (value.trim().length === 0) {
            value = undefined;
        }
        if (value && orderItem.goods.displayUnit === 'KG') {
            value = value * 1000;
        } else if (value) {
            value = Number(value);
        }
        if (onfieldChange) {
            onfieldChange('actualPickQuantity', value, orderItem.id);
        }
    };

    handlePickingChange = (value: number) => {
        const { onfieldChange, orderItem } = this.props;
        if (onfieldChange) {
            onfieldChange('isPicking', value, orderItem.id);
            this.setState({
                weight: 0,
            });
        }
    };

    render() {
        const { orderItem, status } = this.props;
        const { barCode, weight } = this.state;
        const goods = orderItem && orderItem.goods;
        const isWeigth = goods.productType === 'WEIGHT';
        const isNormal = ['NORMAL', 'POINTS', 'GIFT'].includes(goods.productType);
        const isStatusPicking = status === ORDER_STATUS_ENUM.PICKING;
        return (
            orderItem && (
                <div className="picking-goods">
                    <img className="img" src={goods.imageUrl} alt="" />
                    <div className="content">
                        <div className="attribute">
                            <span className="title">{services.language.getText('spmc')}：</span>
                            <span className="value">{goods.name}</span>
                        </div>
                        <div className="attribute">
                            <span className="title">
                                {services.language.getText('productCode')}：
                            </span>
                            <span className="value">{goods.productCode}</span>
                        </div>
                        <div className="attribute">
                            <span className="title">
                                {services.language.getText('goodBarCode')}：
                            </span>
                            <span className="value">{goods.barCode}</span>
                        </div>
                        <div className="attribute">
                            <span className="title">
                                {services.language.getText('passagewayNo')}：
                            </span>
                            <span className="value">
                                {goods.pickRoutes && goods.pickRoutes.passageway}
                            </span>
                        </div>
                    </div>
                    <div className="content">
                        <div className="attribute">
                            <span className="title">
                                {services.language.getText('xd')}
                                {goods.displayUnit === 'PIECE'
                                    ? services.language.getText('number')
                                    : services.language.getText('zl_3')}
                                ：
                            </span>
                            <span className="value">
                                {formartQuantity(orderItem.needPickQuantity, goods.displayUnit)}
                                {goods.displayUnit === 'PIECE'
                                    ? services.language.getText('jian')
                                    : ''}
                            </span>
                        </div>
                        {isNormal && (
                            <div className="attribute">
                                <span className="title">
                                    {services.language.getText('specifications')}：
                                </span>
                                <span className="value">{goods.styleName}</span>
                            </div>
                        )}
                    </div>
                    <div className="content">
                        {isWeigth && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('txm')}：</span>
                                {isStatusPicking ? (
                                    <span className="input">
                                        <Input
                                            className="barcode"
                                            maxLength={13}
                                            type="number"
                                            value={barCode}
                                            onInput={this.onBarCodeInput}
                                            onBlur={this.onBarCodeChange}
                                        />
                                    </span>
                                ) : (
                                    <span className="value">{orderItem.barCode}</span>
                                )}
                            </div>
                        )}
                        {isWeigth && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('jhzl')}：</span>
                                {isStatusPicking ? (
                                    <span className="input">
                                        <InputNumber
                                            value={weight}
                                            min={0}
                                            precision={goods.displayUnit === 'KG' ? 3 : 0}
                                            className="input-number"
                                            onChange={this.onWeightInput}
                                            onBlur={this.onWeightChange}
                                        />
                                        <span>{diplayUnitText[goods.displayUnit]}</span>
                                    </span>
                                ) : (
                                    <span className="value">
                                        {formartQuantity(
                                            orderItem.actualPickQuantity,
                                            goods.displayUnit
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                        {isWeigth && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('czl')}：</span>
                                <span className="value">
                                    {formartQuantity(orderItem.lackQuantity, goods.displayUnit)}
                                </span>
                            </div>
                        )}
                        {isWeigth && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('ytcj')}：</span>
                                <span className="value">
                                    {!!orderItem.priceDifference &&
                                        '-' + orderItem.priceDifference.toString()}
                                </span>
                            </div>
                        )}
                        {isNormal && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('sfjh')}：</span>
                                {isStatusPicking ? (
                                    <span className="input">
                                        <Select
                                            size="small"
                                            value={orderItem.isPicking ? 1 : 0}
                                            onChange={this.handlePickingChange}
                                        >
                                            <Select.Option value={0}>
                                                {services.language.getText('wj_1')}：
                                            </Select.Option>
                                            <Select.Option value={1}>
                                                {services.language.getText('yj')}
                                            </Select.Option>
                                        </Select>
                                    </span>
                                ) : (
                                    <span className="value">
                                        {status === ORDER_STATUS_ENUM.WAITING_PICK
                                            ? services.language.getText('wj_1')
                                            : services.language.getText('yj')}
                                    </span>
                                )}
                            </div>
                        )}
                        {isNormal && orderItem.isPicking && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('yjsl')}：</span>
                                {isStatusPicking ? (
                                    <span className="input">
                                        <InputNumber
                                            value={weight}
                                            min={0}
                                            max={orderItem.needPickQuantity}
                                            precision={0}
                                            className="input-number"
                                            onChange={this.onWeightInput}
                                            onBlur={this.onWeightChange}
                                        />
                                        {goods.displayUnit === 'PIECE'
                                            ? services.language.getText('jian')
                                            : ''}
                                    </span>
                                ) : (
                                    <span className="value">
                                        {orderItem.actualPickQuantity}
                                        {goods.displayUnit === 'PIECE'
                                            ? services.language.getText('jian')
                                            : ''}
                                    </span>
                                )}
                            </div>
                        )}
                        {isNormal && orderItem.isPicking && (
                            <div className="attribute">
                                <span className="title">{services.language.getText('ytcj')}：</span>
                                <span className="value">
                                    {!!orderItem.priceDifference &&
                                        '-' + orderItem.priceDifference.toString()}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            )
        );
    }
}
