import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { Tag } from 'antd';
import { get } from 'lodash';
const systemTags = ['administrator', 'tenant_administrator', 'predefined'];
export class RoleSystemTagMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getAvailableDisplayComponent(value: string, displayConfig: any): JSX.Element {
        const { row } = displayConfig;
        const { extendAttributes } = row;
        const roleTypeData = extendAttributes.find((i: any) => i.attributeCode === 'role_type');
        const roleType = get(roleTypeData, 'attributeValue');
        const isSystem = systemTags.includes(roleType);

        return (
            <span>
                {row.name}&nbsp; {isSystem && <Tag color="geekblue">系</Tag>}
            </span>
        );
    }
}
