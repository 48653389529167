import React, { PureComponent } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage, message } from 'antd';
import { mapValues, defaults, get, reduce, clone, isUndefined, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { ModifyForm } from './modify-form';
import { getField } from '@comall-backend-builder/components-basis/lib/form';
import './index.less';
import { SessionType } from '..';

/**
 * 活动模式
 */
enum RegistrationType {
    /**
     * 非报名类
     */
    NO = 'NO',
    /**
     * 报名类
     */
    YES = 'YES',
}
/**
 * 限购
 */
enum LimitCountType {
    /**
     * 非限购
     */
    NO = 'NO',
    /**
     * 限购
     */
    YES = 'YES',
}

/**
 * 参与人群
 */
enum TargetUserType {
    /**
     * 全部
     */
    ANY = 'ANY',
    /**
     * 指定
     */
    SOME = 'SOME',
}

/**
 * 奖品发放
 */
enum RewardType {
    /**
     * 非报名类
     */
    OFF_LINE = 'OFF_LINE',
    /**
     * 报名类
     */
    COUPON = 'COUPON',
}

export interface MallActivitySettingEditFormProps extends FormComponentProps {
    registrationType: RegistrationType;
    rewardType: RewardType;
    targetUserType: TargetUserType;
    editRegistrationStatus: string;
    limitCountType: LimitCountType;
    totalQuantity: number;
    limitCount: number;
    session: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    onReloadOptions: (fieldName: any, fields: any) => void;
}

class mallActivitySettingEditFormView extends PureComponent<MallActivitySettingEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const {
            entity,
            onSubmit,
            wrappedComponentRef,
            editRegistrationStatus,
            onReloadOptions,
        } = this.props;

        let {
            registrationType,
            rewardType,
            targetUserType,
            limitCountType,
            totalQuantity,
            limitCount,
            session,
        } = this.props;

        let editDisabled = false;
        if (editRegistrationStatus === 'STARTED' || editRegistrationStatus === 'COMPLETE') {
            editDisabled = true;
        }

        let fields: any = [
            {
                property: 'editRegistrationStatus',
                className: 'property-hide',
            },
            { property: 'baseInfo.name' },
            { property: 'baseInfo.pictures' },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    format: 'YYYY-MM-DD',
                    style: {
                        width: 450,
                    },
                    disabled: editDisabled,
                },
            },
            {
                property: 'baseInfo.dayTime',
                controlConfig: {
                    format: 'HH:mm:ss',
                    style: {
                        width: 450,
                    },
                    disabled: editDisabled,
                },
            },
            { property: 'baseInfo.address' },
            { property: 'baseInfo.phone' },
            { property: 'baseInfo.info' },
            {
                property: 'merchants',
                className: 'property-left',
                controlConfig: {
                    disabled: editDisabled,
                },
            },
            { property: 'tagIdList', className: 'property-left' },
            { property: 'registrationInfo' },
            {
                property: 'registrationType',
                className: 'property-left',
                controlConfig: {
                    disabled: editDisabled,
                },
            },
        ];

        // 如果是报名类
        if (registrationType === RegistrationType.YES) {
            fields = fields.concat([
                {
                    property: 'timeLimit',
                    className: 'property-left',
                    controlConfig: {
                        style: {
                            width: 100,
                        },
                        addonbefore: '至少提前',
                        addonafter: '天报名',
                        tip: '',
                        disabled: editDisabled,
                    },
                },
                {
                    property: 'registrationDateRange',
                    className: 'property-left',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        disabled: editDisabled,
                    },
                },

                {
                    property: 'session',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
            ]);

            if (session === SessionType.YES) {
                fields = fields.concat([
                    {
                        property: 'registrationTimes',
                        className: 'property-left',
                        controlConfig: {
                            disabled: editDisabled,
                        },
                    },
                ]);
            } else {
                fields = fields.concat([
                    {
                        property: 'totalQuantity',
                        className: 'property-left',
                        controlConfig: {
                            style: {
                                width: 100,
                            },
                            min: 1,
                            addonbefore: '活动总计',
                            addonafter: '个名额',
                            tip: '',
                            disabled: editDisabled,
                        },
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'limitCountType',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
            ]);

            // 如果是限购数量
            if (limitCountType === LimitCountType.YES) {
                fields = fields.concat([
                    {
                        property: 'limitCount',
                        className: 'property-left',
                        controlConfig: {
                            min: 1,
                            max:
                                totalQuantity && totalQuantity < limitCount
                                    ? totalQuantity
                                    : 999999999,
                            style: {
                                width: 150,
                            },
                            addonbefore: '每用户',
                            addonafter: '个名额',
                            tip: '',
                        },
                    },
                ]);
            }
            fields = fields.concat([
                {
                    property: 'registrationPrice',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
                {
                    property: 'targetUserType',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
            ]);
            // 如果是指定参与人群
            if (targetUserType === TargetUserType.SOME) {
                fields = fields.concat([
                    {
                        property: 'targetUserLevels',
                        className: 'property-left',
                        controlConfig: {
                            placeholder: '请选择用户等级',
                            disabled: editDisabled,
                        },
                    },
                    {
                        property: 'targetUserBirthDay',
                        className: 'property-left',
                        controlConfig: {
                            disabled: editDisabled,
                        },
                    },
                    {
                        property: 'targetUserSexuality',
                        className: 'property-left',
                        controlConfig: {
                            placeholder: '请选择性别',
                            disabled: editDisabled,
                        },
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'customInfo',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
                {
                    property: 'info',
                    className: 'property-left',
                },
                {
                    property: 'rewardType',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
            ]);

            // 如果发放奖品为优惠券
            if (rewardType === RewardType.COUPON) {
                fields = fields.concat([
                    {
                        property: 'selectCoupon',
                        className: 'property-left',
                        controlConfig: {
                            disabled: editDisabled,
                            disablePrice: true,
                        },
                    },
                ]);
            }

            fields = fields.concat([
                {
                    property: 'rewardName',
                    className: 'property-left',
                },
                {
                    property: 'reviewType',
                    className: 'property-left',
                    controlConfig: {
                        disabled: editDisabled,
                    },
                },
            ]);
        }

        let formProps = {
            entity,
            componentId: 'MallActivitySettingEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'mall-activity-setting-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: { text: '保存' },
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        // return createElement(ComponentsManager.get('ModifyForm'), formProps);
        return <ModifyForm {...formProps}></ModifyForm>;
    }
}

function mapStateToProps(state: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;

    let editRegistrationStatus = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.editRegistrationStatus'
    );
    const properties = entity.metainfo.properties;
    // 活动类型
    const registrationTypeDefaultValue = get(properties, 'registrationType.defaultValue');
    let registrationType: RegistrationType = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.registrationType',
        registrationTypeDefaultValue
    );

    // 奖励类型
    const rewardTypeDefaultValue = get(properties, 'rewardType.defaultValue');
    let rewardType: RewardType = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.rewardType'
    );

    // 参与人群
    const targetUserTypeDefaultValue = get(properties, 'targetUserType.defaultValue');
    let targetUserType: TargetUserType = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.targetUserType'
    );

    // 报名价格
    const registrationPriceDefaultValue = get(properties, 'registrationPrice.defaultValue');
    let registrationPrice: any = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.registrationPrice'
    );

    // 按场次报名
    const sessionDefaultValue = get(properties, 'session.defaultValue');
    let session: any = get(state, 'components.MallActivitySettingEditFormContainer.fields.session');

    // 报名限购
    const limitCountTypeDefaultValue = get(properties, 'limitCountType.defaultValue');
    let limitCountType: any = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.limitCountType'
    );

    // 限报数量
    const limitCountDefaultValue = get(properties, 'limitCount.defaultValue');
    let limitCount: any = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.limitCount'
    );
    // 报名审核
    const reviewTypeDefaultValue = get(properties, 'reviewType.defaultValue');
    let reviewType: any = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.reviewType'
    );

    // 报名总数数量
    const totalQuantityDefaultValue = get(properties, 'totalQuantity.defaultValue');
    let totalQuantity: any = get(
        state,
        'components.MallActivitySettingEditFormContainer.fields.totalQuantity'
    );

    // 为子级项设置默认值
    if (registrationType === RegistrationType.YES) {
        if (isUndefined(rewardType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'rewardType',
                        rewardTypeDefaultValue
                    )
                );
        }
        if (isUndefined(targetUserType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'targetUserType',
                        targetUserTypeDefaultValue
                    )
                );
        }
        if (isUndefined(limitCountType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'limitCountType',
                        limitCountTypeDefaultValue
                    )
                );
        }
        if (isUndefined(limitCount)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'limitCount',
                        limitCountDefaultValue
                    )
                );
        }
        if (isUndefined(reviewType)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'reviewType',
                        reviewTypeDefaultValue
                    )
                );
        }

        if (isUndefined(session)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'session',
                        sessionDefaultValue
                    )
                );
        }

        if (isUndefined(totalQuantity)) {
            builder
                .getStore()
                .dispatch(
                    actions.formChangeAction(
                        'MallActivitySettingEditFormContainer',
                        'totalQuantity',
                        totalQuantityDefaultValue
                    )
                );
        }
    }
    if (isUndefined(registrationPrice)) {
        builder
            .getStore()
            .dispatch(
                actions.formChangeAction(
                    'MallActivitySettingEditFormContainer',
                    'registrationPrice',
                    registrationPriceDefaultValue
                )
            );
    }

    let form: any;
    return defaults(
        {
            onSubmit,
            onFieldChange,
            registrationType,
            rewardType,
            targetUserType,
            editRegistrationStatus,
            registrationPrice,
            limitCountType,
            limitCount,
            reviewType,
            totalQuantity,
            session,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });

                if (entityFields.session === SessionType.YES) {
                    // 过滤掉什么也没填的项
                    entityFields.registrationTimes = entityFields.registrationTimes.filter(
                        (item: any) =>
                            item.startDate || item.startTime || item.endTime || item.quantity
                    );
                    // 统计完整项的数量
                    const completeCount = entityFields.registrationTimes.reduce(
                        (count: number, item: any) =>
                            item.startDate && item.startTime && item.endTime && item.quantity
                                ? count + 1
                                : count,
                        0
                    );

                    if (
                        completeCount === 0 ||
                        completeCount !== entityFields.registrationTimes.length
                    ) {
                        message.error('报名场次日期、时间、名额不能为空');
                        return;
                    }
                }

                if (entityFields) {
                    entity.modify(entityFields, params);
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('MallActivitySettingEditFormContainer')
                    );
            },
            // 重新加载属性候选值
            onReloadOptions: (fieldName: any, fields: any) => {
                //? 因为在这个场景下reload时fields可能已经不在dataTable上了
                //? 会导致修改被依赖字段未触发获取依赖方获取数据
                //? 所以这里改成从entity上取需要的参数
                const field = get(entity, `metainfo.properties.${fieldName}`);
                if (!field) return;
                const sourceDefination = field.source;
                let dependences = sourceDefination.dependences;
                let params = reduce(
                    dependences,
                    (values, dependence) => {
                        values[dependence] = getField(dependence, fields).value;
                        return values;
                    },
                    props.params ? clone(props.params) : {}
                );
                entity.loadPropertyOptions(fieldName, sourceDefination, params);
            },
        }
    );
}
export const MallActivitySettingEditFormView = connect(mapStateToProps)(
    mallActivitySettingEditFormView
);
