import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty } from 'lodash';

const api = services.api;
export const DeliveryPackageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_packages';
        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    const ordersMerchant: Array<string> = [];
                    forEach(item.orders, (order) => {
                        const isHave = ordersMerchant.find((i: string) => i === order.merchantName);
                        if (!isHave) {
                            ordersMerchant.push(order.merchantName);
                        }
                    });
                    return {
                        ...item,
                        id: item.deliveryLogisticsBillId,
                        status:
                            item.logisticsBillGenerationMethod === 'AUTOMATIC'
                                ? item.status
                                : 'disabled',
                        ordersMerchant: [
                            {
                                name: ordersMerchant[0],
                                contents: ordersMerchant,
                            },
                        ],
                    };
                });
                return res;
            }
        });
    },
};
