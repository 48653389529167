import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { OrganizationType } from '../../cae/containers/organization/common';

const api = services.api;

export const GuideUserAuditLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/shopping_guide_application_records';

        const { id } = data;
        if (!id) {
            if (data.status === 'WAITING') {
                data.audited = false;
                delete data.status;
            } else if (data.status) {
                data.auditStatus = data.status;
                delete data.status;
            }
            return await api.get(data, config).then((res: any) => {
                res.result.forEach((item: any) => {
                    item.roleNames = item.roleNames.map((i: any) => ({ id: '', name: i }));
                    item.merchant = item.merchantNames.map((i: any) => ({ id: '', name: i }));
                    item.organizationName = item.userOrganizationInfo.name;
                    if (item.audited === false) {
                        item.status = 'WAITING';
                    } else if (item.audited === true) {
                        item.status = item.auditStatus;
                    }
                    item.canEdit = !item.audited;
                });
                return res;
            });
        } else {
            config.apiPath = `/admin/shopping_guide_application_records/${id}`;
            return await api.get(data, config).then((res: any) => {
                res.baseInfo = {
                    mobile: res.mobile,
                    loginName: res.loginName,
                    name: res.name,
                    code: res.code,
                };
                const organization = res.userOrganizationInfo;
                const isMerchant = organization.type === 'MERCHANT';
                const isSubsite = organization.type === 'SUBSITE';
                res.authInfo = {
                    organizationId: {
                        organizationId: organization.id,
                        organizationName: organization.name,
                        organizationType: isSubsite
                            ? OrganizationType.SUBSITE
                            : isMerchant
                            ? OrganizationType.MERCHANT
                            : undefined,
                        originId: isSubsite
                            ? organization.subsiteId
                            : isMerchant
                            ? organization.merchantId
                            : undefined,
                        subsiteId: organization.subsiteId,
                    },
                    privilegeInfos: res.privilegeInfos.map((i: any) => ({
                        ...i,
                        id: i.organizationId,
                        name: i.organizationName,
                    })),
                    roleInfos: res.roleInfos.map((i: any) => ({
                        ...i,
                        id: i.roleId,
                        name: i.roleName,
                    })),
                    remark: res.remark,
                };

                return res;
            });
        }
    },
};

// 门店下拉筛选

export const OrganizationSubsiteLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = `/organizations`;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`;

        const { result = [] } = await api.get<any>(data, config);
        let options: any = [];
        handleTreeData(result, 'subsite', options);
        return options;
    },
};

// 专柜下拉筛选
export const OrganizationMerchantLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = `/organizations`;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`;

        const { result = [] } = await api.get<any>(data, config);
        let options: any = [];
        handleTreeData(result, 'merchant', options);
        return options;
    },
};

const handleTreeData = (treeData: any[], type: string, options: any[]) => {
    treeData.forEach((item) => {
        const node = item.extendAttributes.find(
            (i: any) => i.attributeCode === 'organization_type'
        );
        if (node.attributeValue === type) {
            options.push({ id: item.id, name: item.name });
        }

        if (item.children && item.children.length) {
            handleTreeData(item.children, type, options);
        }
    });
    return treeData;
};
