import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';

export const config: Config = {
    entities: {
        PointRulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/pointValid',
            properties: {
                rule: {
                    type: 'object.PointValidRule',
                    displayName: '<<jfyxqgz>>',
                },
                notification: {
                    type: 'number.tip',
                    displayName: '<<jfgq>>',
                    defaultValue: 1,
                    rules: [
                        { required: true },
                        {
                            type: 'number',
                            min: 1,
                            message: '<<l_priceErrorMessage>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        PointRulesView: {
            component: 'Viewport',
            entity: 'PointRulesEntity',
        },
        PointRulesPage: {
            component: 'Card',
            entity: 'PointRulesEntity',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'Text',
                        text: '<<l_gbyxqgzh>>',
                        style: {
                            marginBottom: 12,
                            color: 'red',
                        },
                    },
                    { component: 'PointRulesEditForm' },
                ],
            },
        },
        PointRulesEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'PointRulesEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'rule',
                },
                {
                    property: 'notification',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '<<tqcdyh>>',
                    },
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let rule = get(entity, 'rule');
                if (rule && rule.clearType === 'FIXED_CLEAR') {
                    if (
                        !rule?.fixedRule?.day ||
                        !rule?.fixedRule?.month ||
                        !rule?.fixedRule?.yearDelay
                    ) {
                        AntMessage.warning(services.language.getText('qtxnyr'));
                        return false;
                    }
                }
                if (rule && rule.clearType === 'GRADUALLY_CLEAR') {
                    if (!rule?.graduallyRule?.dateValue) {
                        AntMessage.warning(services.language.getText('qtxjfyxq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () => {
                    window.location.reload();
                });
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/point-rules',
                    component: 'PointRulesView',
                    breadcrumbName: '<<pointClearRule>>',
                    privilege: {
                        path: 'pointClearRule',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'PointRulesPage' }],
                },
            ],
        },
    ],
};
