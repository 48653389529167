import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PointExchangeRule } from './point-exchange-rule';
import './index.less';
/**
 *  积分抵扣规则
 */
export class PointExchangeRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PointExchangeRule {...controlInfo} />;
    }
}
