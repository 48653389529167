// import React, { PureComponent } from 'react';
// import { some, get } from 'lodash';
import React, { PureComponent } from 'react';
import { Table, Button, message as AntMessage, InputNumber } from 'antd';
import { cloneDeep, remove, set, get, some, findIndex, find } from 'lodash';
import { Coupon } from '../../containers/select-coupon';
import { PaginationConfig } from 'antd/lib/table/interface';
import { CouponPackageApportionPrice } from '../coupon-package-apportion-price';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface VirtualProductSelectorDisplayProps {
    data: Array<Coupon>;
    onChange: (value: any) => void;
    disabled?: boolean;
    xScrollWidth?: number;
}
interface VirtualProductSelectorDisplayState {
    selectedRows: Array<Coupon>;
    visible: boolean;
    settingGoods: object;
}

/**
 * 优惠券展示和内容编辑
 */
export class VirtualProductSelectorDisplay extends PureComponent<
    VirtualProductSelectorDisplayProps,
    VirtualProductSelectorDisplayState
> {
    constructor(props: VirtualProductSelectorDisplayProps) {
        super(props);
        this.state = { selectedRows: [], visible: false, settingGoods: {} };
    }
    toggleModal = (good: any) => {
        const { visible } = this.state;

        this.setState({ visible: !visible, settingGoods: good });
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item: any = find(result, (i) => id === i.id);
        if (item) {
            if (field === 'limitNumber') {
                const max = item.maxLimitNumber ? item.maxLimitNumber : item.goodsStock;
                if (value > max) {
                    value = max;
                }
                if (value < 0) {
                    value = 0;
                }
            }
            item[field] = value;
            onChange(result);
        }
    };
    getTableConfig = () => {
        const { disabled, xScrollWidth, data } = this.props;
        const isPackage = some(
            data,
            (item) => get(item, 'virtualProductCouponDefinitionType') === 'COUPON_PACKAGE'
        );

        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="virtual-goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 120,
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 80,
                },
                {
                    title: services.language.getText('sjkc'),
                    dataIndex: 'goodsStock',
                    key: 'goodsStock',
                    width: 80,
                    align: 'center' as const,
                },
                {
                    title: '普通兑换价',
                    dataIndex: 'price',
                    key: 'price',
                    width: 300,
                    align: 'center',
                    render: (price: any, record: any) => {
                        return !disabled ? (
                            <div>
                                <InputNumber
                                    value={price.points}
                                    min={1}
                                    step={0.01}
                                    precision={2}
                                    placeholder={services.language.getText('inputPlease')}
                                    onChange={(value: any) =>
                                        this.changeValue(
                                            'price',
                                            {
                                                ...price,
                                                points:
                                                    value === undefined
                                                        ? undefined
                                                        : (+value || 1).toFixed(2),
                                            },
                                            record.id
                                        )
                                    }
                                />
                                <span>{services.language.getText('point')} + </span>
                                <InputNumber
                                    value={price.salesPrice}
                                    min={0}
                                    step={0.01}
                                    placeholder={services.language.getText('inputPlease')}
                                    onChange={(value: any) =>
                                        this.changeValue(
                                            'price',
                                            {
                                                ...price,
                                                salesPrice:
                                                    value === undefined
                                                        ? undefined
                                                        : (+value || 0).toFixed(2),
                                            },
                                            record.id
                                        )
                                    }
                                />
                                <span> {services.language.getText('yuan')}</span>
                            </div>
                        ) : (
                            `${price.points}积分${
                                price.salesPrice
                                    ? ` + ${price.salesPrice}${services.language.getText('yuan')}`
                                    : ''
                            }`
                        );
                    },
                },

                {
                    title: '可兑换数量',
                    dataIndex: 'limitNumber',
                    key: 'limitNumber',
                    width: 180,
                    align: 'center',
                    render: (i: any, record: any) => {
                        return !disabled ? (
                            <InputNumber
                                value={i}
                                min={0}
                                placeholder={services.language.getText('inputPlease')}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeValue('limitNumber', value, record.id)
                                }
                            />
                        ) : (
                            i
                        );
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
            scroll: {
                y: 500,
                x: 1000,
            },
        };

        if (xScrollWidth && xScrollWidth > 0) {
            tableConfig.style = {
                width: xScrollWidth,
            };
            tableConfig.scroll = {
                x: true,
            };
        }

        if (isPackage) {
            tableConfig.columns.push({
                title: services.language.getText('ftj'),
                dataIndex: 'apportionPrices',
                key: 'apportionPrices',
                width: 180,
                render: (i: any, record: any) => {
                    const flag = record.apportionPrices.some(
                        (item: any) => !!item.price.apportionPoints
                    );
                    return (
                        <div>
                            <label>
                                <Button
                                    type="link"
                                    onClick={this.toggleModal.bind(this, record)}
                                    style={{ padding: '0' }}
                                >
                                    {flag
                                        ? services.language.getText('common.modify')
                                        : services.language.getText('settings')}
                                </Button>
                            </label>
                        </div>
                    );
                },
            });
        }

        tableConfig.columns.push({
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: 100,
            render: (i: any, row: any) => {
                return (
                    <Button
                        type={'link'}
                        onClick={this.onRemove(row)}
                        style={{ padding: '0' }}
                        disabled={disabled}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                );
            },
        });

        return tableConfig;
    };

    onRemove = (row: Coupon) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onChange(result);
        };
    };

    handleOk = (value: any) => {
        const { onChange, data } = this.props;

        const notExist = value.some(
            (item: any) => !item.price.apportionPoints && item.price.apportionPoints !== 0
        );
        if (notExist) {
            AntMessage.error(services.language.getText('yhqlbsmsqtxftj'));
        } else {
            const { settingGoods } = this.state;
            let newData = cloneDeep(data);
            const index = findIndex(newData, settingGoods);
            set(newData, `[${index}].apportionPrices`, value);
            onChange && onChange(newData);
            this.setState({ visible: false });
        }
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };

    render() {
        const { data } = this.props;
        const { visible, settingGoods } = this.state;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: cloneDeep(data),
            pagination,
        };
        const isPackage = some(
            data,
            (item) => get(item, 'virtualProductCouponDefinitionType') === 'COUPON_PACKAGE'
        );

        return (
            <div>
                <Table {...tableProps}></Table>
                {isPackage && (
                    <CouponPackageApportionPrice
                        data={[settingGoods]}
                        visible={visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        title="设置分摊价"
                        field="apportionPrices"
                        type="price"
                    />
                )}
            </div>
        );
    }
}
