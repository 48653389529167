import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';

import './index.less';
import 'antd/dist/antd.css';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    fileId: number;
    fileUrl: string;
}

/**
 * 导入数据按钮
 */
export class LotteryCodeImportButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            fileId: 0,
            fileUrl: '',
        },
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = (resource: any) => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
            importExportType,
        } = this.props;
        const outApiRoot = get(this.props, 'apiRoot', get(this.props, 'params.apiRoot'));
        const outApiPath = get(this.props, 'apiPath', get(this.props, 'params.apiPath'));
        const apiRoot = outApiRoot ? outApiRoot : `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        const importMethod = get(this.props, 'importMethod', 'post') as 'post' | 'put';
        let apiPath = outApiPath ? outApiPath : `/admin/lottery_code_activities/${id}/user_lists`;
        apiPath = apiPath.replace('{{row.id}}', id);

        api[importMethod](
            {
                id: String(id),
                fileId: resource.fileId,
                fileUrl: resource.fileUrl,
                type: importExportType,
            },
            { apiRoot, apiPath }
        )
            .then(
                (res) => {
                    message.success('导入成功');
                    this.toggleModal();
                    window.location.reload();
                },
                (err) => {
                    message.error(err.response.body.err_msg);
                    this.setState({
                        statusMsg: services.language.getText('importFailPrivity'),
                    });
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.fileId) {
            this.onImport(resource);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const { disabled, importExportType } = this.props;
        let importExportTypeText = '';
        if (importExportType === 'WHITE_LIST') {
            importExportTypeText = '导入白名单';
        } else if (importExportType === 'BLACK_LIST') {
            importExportTypeText = '导入黑名单';
        }
        const importExampleImg = './images/editor/import_example.png';
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            fileId: 0,
                            fileUrl: '',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                const outApiRoot = get(
                    this.props,
                    'importFileSource.apiRoot',
                    get(this.props, 'params.importFileSource.apiRoot')
                );
                const outApiPath = get(
                    this.props,
                    'importFileSource.apiPath',
                    get(this.props, 'params.importFileSource.apiPath')
                );
                const apiRoot = outApiRoot ? outApiRoot : `${ENV.AUTH_API_ROOT}/FILE`;
                const apiPath = outApiPath ? outApiPath : '/admin/files/locations/40/upload';
                api.upload(
                    { files: file },
                    { apiRoot, apiPath, fileName: 'files', progressCallBack: () => {} }
                )
                    .then((res) => {
                        const { path, id } = res;
                        this.setState({
                            resource: {
                                fileId: id,
                                fileUrl: path,
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
        return (
            <div className="giving-coupon-schedules-data-statistics">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {importExportTypeText}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={services.language.getText('batchImport')}
                    footer={[
                        <span className="giving-coupon-schedules-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            导入
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div className="giving-coupon-schedules-tips">
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('importRules')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            1.在文件中填写需要导入的信息（样式如图所示），然后上传该文件
                        </p>
                        <p className="giving-coupon-schedules-tips-text-red">
                            注：表头名称必须填写为“手机号码”（不含符号、空格等其他字符）,否则会导致执行错误
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            2.当前仅支持xlxs、xls、csv格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office
                            2010及以上版本，否则可能出现乱码
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            3.文件命名将作为导入数据文件名展示
                        </p>
                        <p className="giving-coupon-schedules-tips-text-template">
                            {services.language.getText('wjysmb')}：
                        </p>
                        <img
                            className="giving-coupon-schedules-img"
                            src={importExampleImg}
                            alt=""
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
