import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';

import { ArrayMode } from '@comall-backend-builder/types';
import { TimepickerRangeTips } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class ArrayTimepickerRangeTipsMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (array && array.length) {
            return (
                <div
                    className={classNames('type-array mode-array-timepicker-mode', className)}
                    style={style}
                >
                    {map(array, ({ startTime, endTime }, index) => {
                        return (
                            <div key={index}>
                                <span>{startTime}</span> - <span>{endTime}</span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            services.language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <TimepickerRangeTips {...controlInfo} />;
    }
}
