import React, { useCallback, useEffect } from 'react';
import { Radio } from 'antd';
import { get, find, cloneDeep, forEach, map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { MemberLevelTable } from './member-level-table';
import {
    BirthdayMemberLevel,
    BirthdayMemberLevelCouponRuleProps,
} from './birthday-member-level-types';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

const defaultAllRule = [{ levelId: 0, levelName: 'qbdj', coupons: [], experience: '' }];

function getDefaultAllRule() {
    return defaultAllRule.map((i: any) => ({ ...i, levelName: language.getText(i.levelName) }));
}

/**
 * 会员生日营销，给不同会员等级设置优惠券奖励规则
 * @param props
 */
export const BirthdayMemberLevelCouponRule = (props: BirthdayMemberLevelCouponRuleProps) => {
    const subsiteId = get(props, 'row.baseInfo.subsiteId');
    const changeValue = useCallback(
        (value) => {
            const currentValue = props.value ? props.value : {};
            const newValue = Object.assign({ type: 'ALL' }, { ...currentValue }, { ...value });
            props.onChange(newValue);
        },
        [props]
    );

    useEffect(() => {
        if (!subsiteId || subsiteId.length === 0 || props.value?.type === 'ALL' || !props.value) {
            return;
        }

        let params = {};
        let config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/card_levels?status=ENABLE',
        };
        if (subsiteId.length === 1) {
            params = {
                // subsiteId: map(subsiteId, 'id'),
                // page: 1,
                // perPage: 100,
                // status: 'ENABLE',
            };
            config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/level_plans/${map(subsiteId, 'id')}/level_items`,
            };
        }
        api.get(params, config).then((res: any) => {
            let memberLevelsData: any[] = [];
            if (subsiteId.length === 1) {
                const result: Array<any> = [];
                forEach(res, (level: any) => {
                    result.push({
                        cardLevelId: Number(level.levelId),
                        name: level.levelName,
                    });
                });
                memberLevelsData = result;
            } else {
                const result: Array<any> = [];
                forEach(res, (level: any) => {
                    result.push({
                        cardLevelId: level.id,
                        name: level.name,
                    });
                });
                memberLevelsData = result;
            }
            if (!memberLevelsData || memberLevelsData.length === 0) {
                changeValue({ memberLevel: [] });
            } else {
                const memberLevel = get(props.row, 'rewardInfo.receiveRule.memberLevel', null);
                const initData = memberLevelsData.map((level: any) => {
                    let item = {
                        levelId: level.cardLevelId,
                        levelName: level.name,
                        experience: level.experience,
                        coupons: [],
                    };
                    if (memberLevel) {
                        const withCoupons = find(memberLevel, { levelId: item.levelId });
                        if (withCoupons) {
                            item.experience = withCoupons.experience;
                            item.coupons = withCoupons.coupons;
                        }
                    }
                    return item;
                });
                changeValue({ memberLevel: initData });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subsiteId, props.value?.type]);

    const onChangeRule = useCallback(
        (e) => {
            const type = e.target.value;
            let memberLevel: Array<BirthdayMemberLevel> = [];
            if (type === 'ALL') {
                memberLevel = [
                    {
                        levelId: 0,
                        levelName: language.getText('qbdj'),
                        coupons: [],
                        experience: '',
                    },
                ];
            }
            changeValue({ type, memberLevel });
        },
        [changeValue]
    );

    const onChangeCoupons = useCallback(
        (memberLevel: Array<BirthdayMemberLevel>) => {
            changeValue({ memberLevel });
        },
        [changeValue]
    );

    const ruleType = props.value ? props.value.type : 'ALL';
    const rules = props.value ? props.value.memberLevel : cloneDeep(getDefaultAllRule());

    return (
        <div className="birthday-member-level-coupon-rule">
            <Radio.Group className="rule-type" onChange={onChangeRule} value={ruleType}>
                <Radio value={'ALL'} className="rule-type-item">
                    {language.getText('sydjtygz')}
                </Radio>
                <Radio value={'LEVEL'} className="rule-type-item">
                    {language.getText('btdjbtgz')}
                </Radio>
            </Radio.Group>
            {ruleType === 'LEVEL' && !subsiteId && (
                <div className="rule-tips">{language.getText('selectBeforeMerchant')}</div>
            )}
            <MemberLevelTable
                filterCouponParams={{
                    subsiteIds:
                        props.row.baseInfo && props.row.baseInfo.subsiteId
                            ? map(props.row.baseInfo.subsiteId, 'id')
                            : null,
                }}
                onChange={onChangeCoupons}
                data={rules}
            />
        </div>
    );
};
