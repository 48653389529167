import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const SubsitesMerchantsSorterLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data.field && data.order) {
            data.order = data.order === 'ascend' ? 'asc' : 'desc';
            data.sequence = data.field;
        }
        if (data.field) {
            delete data.field;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/subsites/mine/merchants';
        return await api.get(data, config);
    },
};

export const DeliveryShopMerchantsSorterLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data.field && data.order) {
            data.order = data.order === 'ascend' ? 'asc' : 'desc';
            data.sequence = data.field;
            delete data.field;
        }
        if (data.field) {
            delete data.field;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_config/merchants';
        return await api.get(data, config);
    },
};
