import React, { PureComponent } from 'react';
import { Radio, message } from 'antd';
import { isEqual, filter, isFunction } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { CrowdsSelector } from './crowds-selector';

const { api } = services;
const RadioGroup = Radio.Group;

export enum GivingCouponCrowdEnum {
    Member = 'Member',
    Crowd = 'Crowd',
}
export interface CrowdItem {
    id: string;
    crowdName: string;
    creationMode: string;
    peopleNumber: number;
}
interface Value {
    mode: GivingCouponCrowdEnum;
    crowds?: CrowdItem[];
}

export class GivingCouponCrowds extends PureComponent<any, any> {
    supportMemberMarking = true;
    async componentDidMount() {
        const response = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                apiPath: `/roles/super/privilege_set/memberv2Crowds/verify`,
            }
        );
        this.supportMemberMarking = Boolean(response);
    }

    componentWillReceiveProps(nextProps: any) {
        const { getSubsiteIds, value } = this.props;
        const { getSubsiteIds: getNextSubsiteIds, value: nextValue } = nextProps;

        const subsiteIds = getSubsiteIds(this.props);
        const nextSubsiteIds = getNextSubsiteIds(nextProps);
        if (!isEqual(subsiteIds, nextSubsiteIds)) {
            if (value && value.mode === GivingCouponCrowdEnum.Crowd) {
                nextProps.onChange({
                    ...nextValue,
                    crowds: [],
                });
                this.verifySubsiteIds(nextSubsiteIds);
            }
        }
    }

    setDefaultValue = () => {
        const { hasDefaultValue, getDefaultValue, onChange } = this.props;
        const defaultValue = isFunction(getDefaultValue) ? getDefaultValue() : '';
        if (hasDefaultValue && defaultValue && defaultValue.subsiteId && defaultValue.crowdId) {
            api.get(
                {
                    page: 1,
                    perPage: 400,
                    subsiteId: defaultValue.subsiteId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/crowds',
                }
            ).then((res: any) => {
                const crowds = filter(res.result, { id: defaultValue.crowdId }) || [];
                onChange({
                    mode: GivingCouponCrowdEnum.Crowd,
                    crowds,
                });
            });
        }
    };
    private onModeChange = (e: any) => {
        const { onChange, getSubsiteIds } = this.props;
        const value: GivingCouponCrowdEnum = e.target.value;
        const newValue: Value = {
            mode: value,
            crowds: [],
        };
        if (value === GivingCouponCrowdEnum.Crowd) {
            const subsiteIds = getSubsiteIds(this.props);
            this.verifySubsiteIds(subsiteIds);
        }
        onChange(newValue);
    };

    verifySubsiteIds = (subsiteIds: any[]) => {
        if (!subsiteIds || subsiteIds.length === 0) {
            message.warn(services.language.getText('selectBeforeMerchant'));
        } else if (subsiteIds.length > 1) {
            message.warn(services.language.getText('zkddmdxzrq'));
        }
    };

    onChangeCrowds = (crowds: CrowdItem[]) => {
        const { value, onChange } = this.props;
        const newValue: Value = {
            mode: value.mode,
            crowds: crowds,
        };
        onChange(newValue);
    };

    render() {
        const { value, getSubsiteIds } = this.props;
        const modeValue = value && value.mode ? value.mode : GivingCouponCrowdEnum.Member;
        const crowdsValue = value && value.crowds ? value.crowds : [];
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const disabled = !this.supportMemberMarking;
        const subsiteIds = getSubsiteIds(this.props);
        const subsiteId = subsiteIds && subsiteIds.length === 1 ? subsiteIds[0] : undefined;
        return (
            <div>
                <RadioGroup onChange={this.onModeChange} value={modeValue}>
                    <Radio style={radioStyle} value={GivingCouponCrowdEnum.Member}>
                        导入指定用户
                    </Radio>
                    <Radio
                        style={radioStyle}
                        value={GivingCouponCrowdEnum.Crowd}
                        disabled={disabled}
                    >
                        选择人群
                    </Radio>
                    {disabled ? (
                        <div className="ant-form-extra">
                            {services.language.getText('contactManager')}
                        </div>
                    ) : null}
                </RadioGroup>
                {modeValue === GivingCouponCrowdEnum.Crowd ? (
                    <CrowdsSelector
                        disabled={!subsiteId || disabled}
                        subsiteId={subsiteId}
                        value={crowdsValue}
                        onChange={this.onChangeCrowds}
                    />
                ) : null}
            </div>
        );
    }
}
