import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get, trim } from 'lodash';
const api = services.api;
export const ShowStyleLabelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        config.apiPath = '/admin/style_labels';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/style_labels/${id}`;
        } else {
            if (data.status === 'ALL') {
                delete data.status;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res = {
                    baseInfo: {
                        name: res.name || '',
                        dateRange: {
                            start: res.startTime || '',
                            end: res.endTime || '',
                        },
                        subsites: res.subsites || [],
                        pictures: [
                            {
                                id: res.pictureId || '',
                                path: res.pictureUrl || '',
                            },
                        ],
                        sequence: res.sequence || undefined,
                    },
                    associationInfo: {
                        merchants: res.merchants || [],
                    },
                    id: res.id,
                };
            }
            return res;
        });
    },
    post: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        config.apiPath = '/admin/style_labels';
        const body = dataParser(data);

        return await api.post(body, config);
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        config.apiPath = `/admin/style_labels/${data.id}`;
        const body = dataParser(data);

        return await api.put(body, config);
    },
};

function dataParser(data: any) {
    let newParams: any = {};
    const dateRange = get(data, 'baseInfo.dateRange', {
        start: '',
        end: '',
    }) || {
        start: '',
        end: '',
    };
    const subsites = get(data, 'baseInfo.subsites', []) || [];
    const pictures = get(data, 'baseInfo.pictures', []) || [];
    const merchants = get(data, 'associationInfo.merchants', []) || [];
    if (data.baseInfo) {
        newParams.name = trim(get(data, 'baseInfo.name', ''));
        newParams.startTime = dateRange.start;
        newParams.endTime = dateRange.end;
        newParams.subsiteIds = subsites.map((i: any) => i.id);
        const picture = pictures && pictures.length ? pictures[0] : undefined;
        newParams.pictureId = picture ? picture.id : '';
        newParams.pictureUrl = picture ? picture.path : '';
        newParams.sequence = get(data, 'baseInfo.sequence', '');
    }
    if (data.associationInfo) {
        newParams.merchantIds = merchants.map((i: any) => i.id);
    }
    return newParams;
}
