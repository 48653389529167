import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Form, Select, Button, DatePicker, Table, Tooltip, Icon } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const { RangePicker } = DatePicker;

interface Props {
    memberId: string;
}
interface State {
    subsites: any[];
    total: number;
    pagination: {
        page: number;
        perPage: number;
    };
    searchParams: any;
    datasource: any[];
    loading: boolean;
    totalPoint: string;
}
const initDateRange = [moment().subtract(1, 'years'), moment()];

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            const defaultValue = subsites && subsites.length > 0 ? subsites[0].id : undefined;
            return (
                <Form layout="inline">
                    <Form.Item label={language.getText('bdmd')}>
                        {getFieldDecorator('subsiteId', {
                            initialValue: defaultValue,
                        })(
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('bgsj')}>
                        {getFieldDecorator('dateRange', {
                            initialValue: initDateRange,
                        })(
                            <RangePicker
                                allowClear={false}
                                placeholder={[
                                    services.language.getText('startTime'),
                                    services.language.getText('endTime'),
                                ]}
                                style={{ width: '300px' }}
                                className="find-filter-width"
                            />
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginLeft: 'auto' }}>
                        <Button type="primary" shape="round" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" shape="round" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export default class MemberInfoPointPage extends PureComponent<Props, State> {
    state: State = {
        subsites: [],
        total: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        searchParams: {},
        datasource: [],
        loading: true,
        totalPoint: '-',
    };

    componentDidMount() {
        this.getSubsites();
    }

    private getSubsites = async () => {
        const subsites: any[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        const defaultSubsite =
            subsites && (subsites as any[]).length > 0 ? (subsites as any[])[0] : undefined;
        this.setState(
            {
                subsites,
                searchParams: {
                    subsiteId: defaultSubsite.id,
                    dateRange: initDateRange,
                },
            },
            () => {
                this.getTotalPoint();
                this.onSearchData();
            }
        );
    };

    private getTotalPoint = async () => {
        const { memberId } = this.props;
        const { subsites, searchParams } = this.state;
        const subsiteId = searchParams.subsiteId;
        const subsiteCode = subsites.find((i) => i.id === subsiteId)?.code;
        const memberParams = {
            subsiteId,
            subsiteCode,
        };
        const accountInfo: any = await api.get(memberParams, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: `/admin/members/${memberId}/accounts`,
        });
        if (accountInfo && accountInfo.accounts) {
            const pointInfo = accountInfo.accounts.find((i: any) => i.accountType === 'POINT');
            const totalPoint = pointInfo ? pointInfo.balance : '-';
            this.setState({
                totalPoint,
            });
        }
    };

    private onChangeParams = (searchParams: any) => {
        this.setState(
            {
                searchParams,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.getTotalPoint();
                this.onSearchData();
            }
        );
    };

    private onSearchData = async () => {
        const { memberId } = this.props;
        const { searchParams, pagination } = this.state;
        this.setState({
            loading: true,
        });
        const { subsiteId } = searchParams || {};
        const params: any = {
            ...pagination,
            memberId: memberId,
            subsiteId,
        };
        if (searchParams && searchParams?.dateRange) {
            let date = searchParams?.dateRange.map((i: any) => i.format('YYYY-MM-DD'));
            params.startTime = date[0] + ' 00:00:00';
            params.endTime = date[1] + ' 23:59:59';
        }
        const res: any = await api.get(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/member_account_trans_logs',
        });
        this.setState({
            datasource: res.result,
            total: res.totalNum,
            loading: false,
        });
    };

    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearchData();
        });
    };

    private renderFilter = () => {
        const classes = {
            filter: `${prefix}__filter`,
        };
        const { subsites } = this.state;
        return (
            <div className={classes.filter}>
                <CollectionCreateForm onSearch={this.onChangeParams} subsites={subsites} />
            </div>
        );
    };

    private renderTable = () => {
        const { pagination, total, datasource, loading, totalPoint } = this.state;
        return (
            <div className={`${prefix}__table`}>
                <div className={`${prefix}__table__total`}>
                    {language.getText('kyjf')}
                    <Tooltip title={language.getText('yhzhzcztxdqkyjf')}>
                        <Icon type="question-circle-o" />
                    </Tooltip>
                    <span className={`${prefix}__table__total__value`}>{totalPoint}</span>
                </div>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    loading={loading}
                    rowKey={(record: any) => record.id}
                    pagination={{
                        current: pagination.page,
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={[
                        {
                            title: services.language.getText('bgsj'),
                            dataIndex: 'transTime',
                        },
                        {
                            title: language.getText('bdmd'),
                            dataIndex: 'subsiteName',
                        },
                        {
                            title: language.getText('categoryName'),
                            dataIndex: 'transActionRemark',
                        },
                        {
                            title: language.getText('jfbd'),
                            dataIndex: 'transAmount',
                            render: (val, row) => (
                                <span>
                                    {row.transType === 'IN' ? '+' : '-'}
                                    {val}
                                </span>
                            ),
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    };

    render() {
        return (
            <div className={prefix}>
                {this.renderFilter()}
                {this.renderTable()}
            </div>
        );
    }
}

const prefix = 'member-info-point-page';
