import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const HibeiDoublePromotionsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/shopping_reward_double_promotions';
        const { id } = data;
        if (data.enable === 'ALL') {
            delete data.enable;
        }

        if (id) {
            config.apiPath = `/admin/shopping_reward_double_promotions/${id}`;
        } else {
            data.rewardType = 'HI';
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result ? res.result.map((r: any) => {
                    return {
                        ...r,
                        subsite: r.subsite.name,
                    };
                }) : [];
            } else {
                const reward = res.rewardInfos && res.rewardInfos.length > 0 && res.rewardInfos[0]
                res.baseInfo = {
                    name: res.name,
                };
                res.ruleInfo = {
                    subsites: res.subsite && res.subsite.id,
                    ruleType: 'MEMBER_LEVEL',
                    memberPointDoubleRule: {
                        memberLevelId: reward && Number(reward.rewardCondition),
                        multiple: reward && reward.rewardValue,
                    },
                };
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = `/admin/shopping_reward_double_promotions/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/shopping_reward_double_promotions';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
    }
    if (params.ruleInfo) {
        newParams.subsiteId = params.ruleInfo.subsites;
        newParams.channels = [];
        const memberPointDoubleRule = params.ruleInfo.memberPointDoubleRule;
        if (memberPointDoubleRule) {
            newParams.unifiedReward = false;
            newParams.rewardInfos = [{
                rewardConditionValue: memberPointDoubleRule.memberLevelId,
                rewardValue: memberPointDoubleRule.multiple,
                rewardType: 'HI'
            }]
        }
    }

    return newParams;
}
