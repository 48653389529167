import { Config } from '@comall-backend-builder/core/lib/parser';
import { map, get, forEach, isEmpty } from 'lodash';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { floatComputed } from '../../../types/mode/object/credit-shop-exchange/credit-shop-exchange-apportion';
import { language } from '@comall-backend-builder/core/lib/services';
import { TipsModalContentType } from '../../../components';

export enum ExchangeLimitEnum {
    NO_RESTRICTIONS = 'NO_RESTRICTIONS',
    MEMBER_CARD_LEVEL = 'MEMBER_CARD_LEVEL',
    CROWD = 'CROWD',
}
export enum HideSettingEnum {
    NO_RESTRICTIONS = 'NO_RESTRICTIONS',
    RESTRICTIONS = 'RESTRICTIONS',
    RESTRICTIONS_HIDE = 'RESTRICTIONS_HIDE',
}
/**
 * 隐藏设置枚举
 */
export enum HideSettingTypeEnum {
    /**
     * 无限制
     */
    NO_RESTRICTIONS = 'NO_RESTRICTIONS',
    /**
     * 指定会员卡可见
     */
    MEMBER_CARD_LEVEL = 'MEMBER_CARD_LEVEL',
    /**
     * 指定人群可见
     */
    CROWD = 'CROWD',
    /**
     * 指定会员卡不可见
     */
    MEMBER_CARD_LEVEL_LIMIT = 'MEMBER_CARD_LEVEL_LIMIT',
    /**
     * 指定人群不可见
     */
    CROWD_LIMIT = 'CROWD_LIMIT',
}

const isVirtualCoupon = (values: any) => get(values, 'productType') === 'VIRTUAL_COUPON';
const isNormalProduct = (value: any) => get(value, 'productType') === 'POINTS';
const isDeliveryModeAssign = (value: any) =>
    isNormalProduct(value) && get(value, 'showTag.deliveryModeType') === 'POINT';

const onValidate = (modify = false) => {
    return (entityFields: any) => {
        if (!entityFields) return false;
        const {
            coupon,
            gift,
            weight,
            stock,
            exchangeInfo: { exchange, exchangeLimit, memberLevels, limitCrowds },
            showTag: {
                notice,
                assignDeliveryModes,
                hideSetting,
                showSettingType,
                showSettingMemberLevels,
                showSettingCrowds,
                hideSettingType,
                hideSettingMemberLevels,
                hideSettingCrowds,
            },
            baseInfo: { dateRange },
        } = entityFields;
        if (!modify && !stock.maxCount) {
            AntMessage.warning(services.language.getText('qtxkdhdzdkc'));
            return false;
        }
        if (
            stock.maxSaleNumberEveryDay &&
            stock.maxCount &&
            stock.maxSaleNumberEveryDay > stock.maxCount
        ) {
            AntMessage.warning(
                services.language.getText('mrxdslx') + '<' + services.language.getText('zdkdhsl')
            );
            return false;
        }
        const isCoupon = isVirtualCoupon(entityFields);
        if (isCoupon && !coupon?.length) {
            AntMessage.warning(services.language.getText('qxzyhqhyhqlb'));
            return false;
        }
        if (!isCoupon) {
            if (!gift) {
                AntMessage.warning(services.language.getText('qxzlp'));
                return false;
            }
            if (!weight && weight !== 0) {
                AntMessage.warning(services.language.getText('qszzl'));
                return false;
            }
            if (!assignDeliveryModes?.length) {
                AntMessage.warning(services.language.getText('qwgspxzyzpsfs'));
                return false;
            }
        }
        // 校验兑换限制
        if (!exchangeLimit) {
            AntMessage.warning(services.language.getText('qxzdhxz'));
            return false;
        }
        if (
            exchangeLimit === ExchangeLimitEnum.MEMBER_CARD_LEVEL &&
            (!memberLevels || memberLevels.length === 0)
        ) {
            AntMessage.warning(services.language.getText('qxzxzdhhykdj'));
            return false;
        }
        if (
            exchangeLimit === ExchangeLimitEnum.CROWD &&
            (!limitCrowds || limitCrowds.length === 0)
        ) {
            AntMessage.warning(services.language.getText('qxzxzdhrq'));
            return false;
        }

        //普通兑换价
        const commonExchange = exchange.commonExchange[0];
        if (!commonExchange.points) {
            AntMessage.warning(services.language.getText('qtxdhdjfjg'));
            return false;
        }
        //校验分摊价
        if (isCoupon && coupon[0].type === 'PACKAGE') {
            const apportionInfo = commonExchange.apportionInfos[0];
            if (apportionInfo.apportionPoints === null || apportionInfo.apportionPoints === '') {
                AntMessage.warning(services.language.getText('zcdhj_1'));
                return false;
            }
            let memberLevelerrMsg = '';
            const cardLevelExchange = exchange.cardLevelExchange;
            if (cardLevelExchange.length > 0) {
                for (let i = 0; i < cardLevelExchange.length; i++) {
                    const cardLevelExchangeInfo = cardLevelExchange[i];
                    const apportionInfos = cardLevelExchangeInfo.apportionInfos;
                    if (
                        cardLevelExchangeInfo.points &&
                        (apportionInfos[0].apportionPoints === null ||
                            apportionInfos[0].apportionPoints === '')
                    ) {
                        memberLevelerrMsg = services.interpolate(
                            language.getText('selectFtPrice'),
                            {
                                cardLevelName: cardLevelExchangeInfo.cardLevelName,
                            }
                        );
                        break;
                    }
                    if (cardLevelExchangeInfo.points) {
                        let hasApportionPrice = true,
                            totalApportionPoints = 0,
                            totalApportionPrice = 0;
                        forEach(apportionInfos, (item) => {
                            if (item.apportionPoints === null || item.apportionPoints === '') {
                                hasApportionPrice = false;
                            } else {
                                totalApportionPoints +=
                                    (+item.apportionPoints || 0) * item.quantity;
                            }
                            if (
                                cardLevelExchangeInfo.salesPrice &&
                                (item.apportionPrice === null || item.apportionPrice === '')
                            ) {
                                hasApportionPrice = false;
                            } else {
                                totalApportionPrice = floatComputed.add(
                                    totalApportionPrice,
                                    floatComputed.multiply(
                                        +item.apportionPrice || 0,
                                        item.quantity,
                                        2
                                    ),
                                    2
                                );
                            }
                        });
                        if (hasApportionPrice) {
                            if (
                                totalApportionPoints !== (+cardLevelExchangeInfo.points || 0) ||
                                totalApportionPrice !== (+cardLevelExchangeInfo.salesPrice || 0)
                            ) {
                                memberLevelerrMsg =
                                    '（' +
                                    cardLevelExchangeInfo.cardLevelName +
                                    '）' +
                                    services.language.getText('ftjzhbdysmjg');
                                break;
                            }
                        }
                    }
                }
                if (memberLevelerrMsg) {
                    AntMessage.warning(memberLevelerrMsg);
                    return false;
                }
            }
        }
        if (notice.type === 'MANUAL_TIME') {
            if (!notice.time) {
                AntMessage.warning(services.language.getText('qtxhdksygsj'));
                return false;
            }
            const noticeTime = new Date(notice.time).getTime();
            const startTime = new Date(dateRange.start).getTime();
            if (noticeTime > startTime) {
                if (!notice.time) {
                    AntMessage.warning(services.language.getText('hdksygsjyzydhkssj'));
                    return false;
                }
            }
        }

        // 隐藏校验
        if (hideSetting === HideSettingEnum.RESTRICTIONS) {
            if (
                showSettingType === HideSettingTypeEnum.MEMBER_CARD_LEVEL &&
                showSettingMemberLevels.length === 0
            ) {
                AntMessage.warning(services.language.getText('qxzzdkjhykdj'));
                return false;
            }
            if (showSettingType === HideSettingTypeEnum.CROWD && showSettingCrowds.length === 0) {
                AntMessage.warning(services.language.getText('qxzzdkjrq'));
                return false;
            }
        }
        if (hideSetting === HideSettingEnum.RESTRICTIONS_HIDE) {
            if (
                hideSettingType === HideSettingTypeEnum.MEMBER_CARD_LEVEL &&
                hideSettingMemberLevels.length === 0
            ) {
                AntMessage.warning(services.language.getText('qxzzdkjhykdj'));
                return false;
            }
            if (hideSettingType === HideSettingTypeEnum.CROWD && hideSettingCrowds.length === 0) {
                AntMessage.warning(services.language.getText('qxzzdkjrq'));
                return false;
            }
        }
        return true;
    };
};

const baseFormConfig = {
    direction: 'horizontal',
    labelCol: 4,
    controlCol: 20,
    submit: true,
    items: {},
    footer: {
        items: [
            {
                htmlType: 'button',
                route: '/credit-shop',
                text: '<<common.cancel>>',
                type: 'default',
                style: { marginLeft: 10 },
            },
        ],
    },
    onSubmitSuccess: () => {
        AntMessage.success(services.language.getText('common.saveSuccess'), () =>
            services.behaviorHandle({ route: '/credit-shop' })
        );
    },
};

const getFormFields = (type: 'add' | 'edit' | 'copy') => {
    const editDisabledConfig = {
        disabled: type === 'edit',
    };
    const isAdd = type === 'add';
    return [
        { property: 'baseInfo.dateRange' },
        {
            property: 'subsiteId',
            controlConfig: editDisabledConfig,
        },
        {
            property: 'productType',
            controlConfig: editDisabledConfig,
        },
        {
            property: 'coupon',
            controlConfig: editDisabledConfig,
            visible: isVirtualCoupon,
        },
        { property: 'gift', controlConfig: editDisabledConfig, visible: isNormalProduct },
        { property: 'name' },
        { property: 'weight', visible: isNormalProduct },
        { property: 'images', visible: isVirtualCoupon },
        {
            property: 'images',
            visible: isNormalProduct,
            controlConfig: {
                maxCount: 10,
                fileName: 'files',
                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                mode: 'picture-card',
                maxSize: 2 * 1024,
                uploadLimitSizeErrorMessage: '<<h_lptp_msg>>',
                autoFillDependencies: 'row.gift.images',
            },
        },
        { property: 'stock' },
        {
            property: 'exchangeInfo.exchangeLimit',
        },
        {
            property: 'exchangeInfo.memberLevels',
            visible: (value: any) =>
                get(value, 'exchangeInfo.exchangeLimit') === ExchangeLimitEnum.MEMBER_CARD_LEVEL,
        },
        {
            property: 'exchangeInfo.limitCrowds',
            visible: (value: any) =>
                get(value, 'exchangeInfo.exchangeLimit') === ExchangeLimitEnum.CROWD,
        },
        {
            property: 'exchangeInfo.exchange',
            controlConfig: {
                isEditForm: !isAdd,
            },
        },
        { property: 'showTag.deliveryModeType', visible: isNormalProduct },
        { property: 'showTag.deliveryTips', visible: isDeliveryModeAssign },
        { property: 'showTag.assignDeliveryModes', visible: isNormalProduct },
        {
            property: 'showTag.afterSales',
            controlConfig: isAdd
                ? {}
                : {
                      seat: 'row.coupon',
                      isEditForm: true,
                  },
            visible: isVirtualCoupon,
        },
        { property: 'showTag.notice' },
        { property: 'showTag.canShare' },
        // { property: 'showTag.targetHideUserLevels' },
        { property: 'showTag.hideSetting' },
        {
            property: 'showTag.showSettingType',
            visible: (value: any) =>
                get(value, 'showTag.hideSetting') === HideSettingEnum.RESTRICTIONS,
        },
        {
            property: 'showTag.showSettingMemberLevels',
            visible: (value: any) => {
                return (
                    get(value, 'showTag.hideSetting') === HideSettingEnum.RESTRICTIONS &&
                    get(value, 'showTag.showSettingType') === HideSettingTypeEnum.MEMBER_CARD_LEVEL
                );
            },
        },
        {
            property: 'showTag.showSettingCrowds',
            visible: (value: any) => {
                return (
                    get(value, 'showTag.hideSetting') === HideSettingEnum.RESTRICTIONS &&
                    get(value, 'showTag.showSettingType') === HideSettingTypeEnum.CROWD
                );
            },
        },
        {
            property: 'showTag.hideSettingType',
            visible: (value: any) =>
                get(value, 'showTag.hideSetting') === HideSettingEnum.RESTRICTIONS_HIDE,
        },
        {
            property: 'showTag.hideSettingMemberLevels',
            visible: (value: any) => {
                return (
                    get(value, 'showTag.hideSetting') === HideSettingEnum.RESTRICTIONS_HIDE &&
                    get(value, 'showTag.hideSettingType') ===
                        HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT
                );
            },
        },
        {
            property: 'showTag.hideSettingCrowds',
            visible: (value: any) => {
                return (
                    get(value, 'showTag.hideSetting') === HideSettingEnum.RESTRICTIONS_HIDE &&
                    get(value, 'showTag.hideSettingType') === HideSettingTypeEnum.CROWD_LIMIT
                );
            },
        },
        { property: 'showTag.tagIdList' },
        { property: 'showTag.sequence' },
        { property: 'showTag.info', visible: isVirtualCoupon },
        {
            property: 'showTag.giftInfo',
            visible: isNormalProduct,
        },
        { property: 'pictureInfo.description', visible: isVirtualCoupon },
        { property: 'pictureInfo.giftDescription', visible: isNormalProduct },
    ];
};

export const config: Config = {
    entities: {
        creditEshopEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/creditEshop',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteId: {
                    type: 'array.subsites.selector',
                    displayName: '<<dhmd>>',
                    defaultValue: [],
                    controlConfig: {
                        type: 'radio',
                    },
                    rules: [{ required: true }],
                },
                productType: {
                    type: 'string.options.radio',
                    displayName: '<<dhsplx>>',
                    defaultValue: 'VIRTUAL_COUPON',
                    rules: [{ required: true }],
                    options: [
                        { id: 'VIRTUAL_COUPON', name: '<<yhq>>' },
                        { id: 'POINTS', name: '<<presents>>' },
                    ],
                },
                coupon: {
                    type: 'object.salesElectronicCouponSelect',
                    displayName: '<<dhdx>>',
                    controlConfig: {
                        type: 'radio',
                        getSubsiteIds(props: any) {
                            const { row } = props;
                            const subsiteIds =
                                row && row.subsiteId ? map(row.subsiteId, 'id') : null;
                            return subsiteIds;
                        },
                    },
                },
                gift: {
                    type: 'object.giftProductSelect',
                    displayName: '<<presents>>',
                    controlConfig: {
                        type: 'radio',
                        getSubsiteIds(props: any) {
                            const { row } = props;
                            const subsiteIds =
                                row && row.subsiteId ? map(row.subsiteId, 'id') : null;
                            return subsiteIds;
                        },
                        clearValueOnSubsiteChange: true,
                    },
                },
                name: {
                    type: 'string.salesElectronicCouponName',
                    displayName: '<<dhmc_1>>',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<qsrdhmc>>',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        maxLength: 60,
                        seat: 'row.name',
                    },
                },
                stock: {
                    type: 'object.creditShopStock',
                    displayName: '<<dhzdkc>>',
                    rules: [{ required: true, message: '<<qsrdhzdkc>>' }],
                    controlConfig: {
                        allowFloat: true,
                        rules: [
                            {
                                type: 'number',
                                precision: 0,
                            },
                        ],
                        seat: 'row.stock',
                    },
                },
                images: {
                    displayName: '<<dhtp>>',
                    type: 'array.imgaesUploadSortable',
                    className: 'sigle-image-field',
                    rules: [{ required: true, message: '<<qscdhtp>>' }],
                    defaultValue: [],
                    controlConfig: {
                        maxCount: 10,
                        fileName: 'files',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                        mode: 'picture-card',
                        maxSize: 2 * 1024,
                        uploadLimitSizeErrorMessage: '<<h_lptp_msg>>',
                    },
                    extra: '<<productPicExtra>>',
                },
                weight: {
                    type: 'number.integerInputForWeight',
                    displayName: '<<zl_3>>',
                    controlConfig: {
                        addonafter: 'g',
                        rules: [
                            {
                                type: 'number',
                                min: 0,
                                max: 9999999,
                                precision: 4,
                                step: 0.0001,
                            },
                        ],
                        allowFloat: true,
                        disabled: true,
                        width: 100,
                        autoFillDependencies: 'row.gift.weight',
                    },
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<dhyxsj>>',
                            rules: [{ required: true, message: '<<effectiveTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                exchangeInfo: {
                    type: 'object.subForm',
                    displayName: '<<dhtj>>',
                    properties: {
                        exchangeLimit: {
                            type: 'string.options.radio',
                            displayName: '<<dhxz>>',
                            defaultValue: ExchangeLimitEnum.NO_RESTRICTIONS,
                            options: [
                                {
                                    id: ExchangeLimitEnum.NO_RESTRICTIONS,
                                    name: '<<syrkdh_1>>',
                                },
                                {
                                    id: ExchangeLimitEnum.MEMBER_CARD_LEVEL,
                                    name: '<<ahykdjdh>>',
                                },
                                { id: ExchangeLimitEnum.CROWD, name: '<<ahyrqdh>>' },
                            ],
                        },
                        exchange: {
                            type: 'object.creditShopExchange',
                            displayName: '<<dhj>>',
                            rules: [{ required: true }],
                        },
                        memberLevels: {
                            type: 'array.options.autoComplete.storeCardLevels',
                            displayName: '<<memberLevels>>',
                            rules: [{ required: true }],
                            options: [],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                            extra: '<<jsydjkdh>>',
                        },
                        limitCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'CreditShop',
                            },
                            extra: '<<jyxhyrqkdh>>',
                        },
                    },
                },
                showTag: {
                    type: 'object.subForm',
                    displayName: '<<otherSetting>>',
                    properties: {
                        deliveryModeType: {
                            type: 'string.options.radio',
                            displayName: '<<yfgz>>',
                            className: 'no-margin-field',
                            defaultValue: 'UNIFIED',
                            rules: [{ required: true }],
                            options: [
                                { id: 'POINT', name: '<<jfscyfgz>>' },
                                { id: 'UNIFIED', name: '<<symdyfgz>>' },
                            ],
                        },
                        deliveryTips: {
                            type: 'string.tips',
                            colon: false,
                            className: 'no-margin-field',
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<qwpz>>',
                                        },
                                        {
                                            type: 'link',
                                            text: '<<yfgz>>',
                                            onClick: () => {
                                                window.open('#/credit-shop/delivery');
                                            },
                                        },
                                    ],
                                ],
                            },
                        },
                        assignDeliveryModes: {
                            type: 'array.creditEshopAssignDeliveryModes',
                            displayName: '<<deliveryType>>',
                            extra: '<<l_yfgzymd_1>>',
                        },
                        afterSales: {
                            type: 'object.salesElectronicCouponAfterSales',
                            displayName: '<<afterService>>',
                            defaultValue: {
                                overdueAutoRefund: true,
                                anytimeRefund: true,
                                packageRefund: false,
                            },
                            controlConfig: {
                                seat: 'row.coupon',
                            },
                        },
                        notice: {
                            type: 'object.creditShopNotice',
                            displayName: '<<l_hdwks_1>>',
                            defaultValue: { type: 'NOT_REMAIN' },
                        },
                        canShare: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<fxsz>>',
                            defaultValue: ['true'],
                            options: [{ id: 'false', name: '<<byxjgspfxjhy>>' }],
                        },
                        hideSetting: {
                            type: 'string.options.radio',
                            displayName: '<<yzsz>>',
                            defaultValue: HideSettingEnum.NO_RESTRICTIONS,
                            options: [
                                {
                                    id: HideSettingEnum.NO_RESTRICTIONS,
                                    name: '<<l_syrjkj_1>>',
                                },
                                { id: HideSettingEnum.RESTRICTIONS, name: '<<zdrqkj>>' },
                                { id: HideSettingEnum.RESTRICTIONS_HIDE, name: '<<zdrqbkj>>' },
                            ],
                        },
                        showSettingType: {
                            type: 'string.options.radio',
                            displayName: '<<xzhysf>>',
                            defaultValue: HideSettingTypeEnum.MEMBER_CARD_LEVEL,
                            rules: [{ required: true }],
                            options: [
                                { id: HideSettingTypeEnum.MEMBER_CARD_LEVEL, name: '<<ahykdj>>' },
                                { id: HideSettingTypeEnum.CROWD, name: '<<ahyrq>>' },
                            ],
                        },
                        showSettingMemberLevels: {
                            type: 'array.options.autoComplete.storeCardLevels',
                            displayName: '<<xzhykdj>>',
                            rules: [{ required: true, message: '<<qxzhydj>>' }],
                            options: [],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                        },
                        showSettingCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true, message: '<<qxzhyrq>>' }],
                            controlConfig: {
                                pageType: 'CreditShop',
                            },
                        },
                        hideSettingType: {
                            type: 'string.options.radio',
                            displayName: '<<xzhysf>>',
                            defaultValue: HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT,
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT,
                                    name: '<<ahykdj>>',
                                },
                                { id: HideSettingTypeEnum.CROWD_LIMIT, name: '<<ahyrq>>' },
                            ],
                        },
                        hideSettingMemberLevels: {
                            type: 'array.options.autoComplete.storeCardLevels',
                            displayName: '<<xzhykdj>>',
                            rules: [{ required: true, message: '<<qxzhydj>>' }],
                            options: [],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                        },
                        hideSettingCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true, message: '<<qxzhyrq>>' }],
                            controlConfig: {
                                pageType: 'CreditShop',
                            },
                        },
                        tagIdList: {
                            displayName: '<<jfscbq>>',
                            type: 'array.tagIds.select',
                            defaultValue: [],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        sequence: {
                            displayName: '<<sppx>>',
                            type: 'number.integerInputTitleTip',
                            controlConfig: {
                                tip: '<<l_pxzxtzzxcjdykqzs_1>>',
                                rules: {
                                    type: 'number',
                                    placeholder: '<<sortTip>>',
                                    max: 99999,
                                    min: 0,
                                },
                                width: '300px',
                            },
                        },
                        info: {
                            type: 'string.richTextPlus',
                            displayName: '<<dhsm>>',
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 20000,
                                    message: '<<l_notMoreThan2000_1>>',
                                },
                            ],
                        },
                        giftInfo: {
                            type: 'string.richTextPlus',
                            displayName: '<<dhsm>>',
                            controlConfig: {
                                autoFillDependencies: 'row.gift.receiveExplain',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 20000,
                                    message: '<<l_notMoreThan2000_1>>',
                                },
                            ],
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<twxq>>',
                    properties: {
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<twxq>>',
                            controlConfig: {
                                fillSalesElectronicCouponInstructions: true,
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 20000,
                                    message: '<<productInfoRequired>>',
                                },
                            ],
                        },
                        giftDescription: {
                            type: 'string.richTextPlus',
                            displayName: '<<twxq>>',
                            controlConfig: {
                                autoFillDependencies: 'row.gift.details',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 20000,
                                    message: '<<productInfoRequired>>',
                                },
                            ],
                        },
                    },
                },
            },
        },
        creditEshopReportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/creditEshopReport',
            filters: {
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dhsj>>',
                },
                mobile: {
                    type: 'string.phone',
                    displayName: '<<yhsjh>>',
                    rules: [
                        {
                            pattern: /^[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]{11,20}$/,
                            message: '<<qsrzqgsdsjh>>',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsryhsjh>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                mobile: {
                    type: 'object.link',
                    displayName: '<<yhsjh>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                price: {
                    type: 'string',
                    displayName: '<<dhj>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<dhsl_1>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<dhmd>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<dhsj>>',
                },
                orderNo: {
                    type: 'object.link',
                    displayName: '<<orderNumber>>',
                },
                serviceApplyNos: {
                    type: 'object.link',
                    displayName: '<<afterSalesOrderNo>>',
                },
                refundStatus: {
                    type: 'object.link',
                    displayName: '<<tkzt>>',
                },
            },
        },
        creditEshopPickUpDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/credit_eshop_pick_up_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztdxx>>',
                    properties: {
                        name: {
                            type: 'string.options.select',
                            displayName: '<<deliveryType>>',
                            rules: [{ required: true }],
                            defaultValue: 'ALL',
                            options: [{ id: 'ALL', name: '<<mdzt>>' }],
                            controlConfig: {
                                disabled: true,
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        subsite: {
                            type: 'string.options.select',
                            displayName: '<<subsites>>',
                            rules: [{ required: true }],
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        address: {
                            type: 'string.tip',
                            displayName: '<<ztdz>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxztdxxdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qtxztdxxdz>>',
                                minLength: 5,
                                maxLength: 50,
                            },
                        },
                        pickUpTime: {
                            type: 'string',
                            displayName: '<<ztsjsm>>',
                            controlConfig: {
                                placeholder: '<<qtxztsjsm>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        reminder: {
                            type: 'string.modalTips',
                            displayName: '<<wxts>>',
                            controlConfig: {
                                placeholder: '<<jytxrqnsdztdxhddzte>>',
                                style: {
                                    width: 450,
                                },
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.DeliveryReminder,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                            },
                            extra: '<<reminderExtra>>',
                        },
                    },
                },
                chooseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztgz>>',
                    properties: {
                        reservedTimeHour: {
                            type: 'number.tip',
                            displayName: '<<bhsj>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<sjzstq>>',
                                addonafter: '<<xsbh>>',
                                tip: '',
                            },
                        },
                        topLimitDay: {
                            type: 'number.tip',
                            displayName: '<<topLimitDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<zc_2>>',
                                addonafter: '<<tnth>>',
                                tip: '',
                            },
                        },
                        isDeliveryTimePeriods: {
                            type: 'string.options.radio',
                            displayName: '<<mjkxztsj>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'YES', name: '<<enable>>' },
                                { id: 'NO', name: '<<common.close>>' },
                            ],
                            extra: '<<mjzjsykyxzpssj>>',
                        },
                        deliveryTimePeriods: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<kztsjd>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    message: '<<mgsjdbkzf>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd >= 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart <= 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                                tip: '<<qasxtj>>',
                                deleteButtontext: '<<common.delete>>',
                                addButtonText: '<<tjsjd>>',
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<wasthcl>>',
                    properties: {
                        orderHandleMode: {
                            type: 'string.options.radio',
                            displayName: '<<gqwthddsfgb>>',
                            defaultValue: 'NOT_HANDLE',
                            options: [
                                { id: 'PART_HANDLE', name: '<<yes>>' },
                                { id: 'NOT_HANDLE', name: '<<no>>' },
                            ],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        goods: {
                            type: 'object.productCategory.selector',
                            displayName: '<<szsp>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL,WEIGHT,COMBINE'],
                                verifyName: 'baseInfo.subsite',
                                verifyTip: '<<selectSubsite>>',
                                handleSelectMode: (row: any) => {
                                    return 'SUB_SITE';
                                },
                                productDisplaySelectValueType: 'goods',
                            },
                        },
                        pickUpLimitDays: {
                            type: 'number.tip',
                            displayName: '<<gqtssz>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<qsrdydy>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<gq>>',
                                addonafter: '<<thwth>>',
                                tip: '<<glyhzwthsj>>',
                            },
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<ztdz>>',
                },
                pickUpTime: {
                    type: 'string',
                    displayName: '<<ztsj>>',
                },
                reminder: {
                    type: 'string',
                    displayName: '<<wxts>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<enabled>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                status: {
                    type: 'string',
                    displayName: '<<status>>',
                },
            },
        },
        creditEshopExpressDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/credit_eshop_express_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string.tip',
                            displayName: '<<gzmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxgzmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<hbdqm99yby>>',
                                maxLength: 20,
                            },
                        },
                        subsite: {
                            type: 'object.option.select',
                            displayName: '<<sxmd_1>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qxzsxmd>>',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzsxmd>>',
                                },
                            ],
                        },
                    },
                },
                freightInfo: {
                    type: 'object.subForm',
                    displayName: '<<yfgz>>',
                    properties: {
                        isWeighTharging: {
                            type: 'string.options.radio',
                            displayName: '<<jffs>>',
                            defaultValue: 'YES',
                            options: [
                                { id: 'YES', name: '<<zlcyjf>>' },
                                { id: 'NO', name: '<<zlbcyjf>>' },
                            ],
                        },
                        isWeighThargingFreightRule: {
                            type: 'object.delivery.rules',
                            displayName: '<<psgz>>',
                            defaultValue: {
                                deliveryScopes: [],
                                deliveryScopesInfo: [],
                                showDeliveryScopes: [],
                                freightRanges: [
                                    {
                                        startAmount: null,
                                        endAmount: null,
                                        firstAmount: null,
                                        freightLadders: [
                                            {
                                                startValue: null,
                                                ladderValue: null,
                                                ladderAmount: null,
                                                type: 'WEIGHT',
                                            },
                                        ],
                                    },
                                ],
                            },
                            controlConfig: {
                                pageType: 'creditEshop',
                                isWeighTharging: true,
                            },
                        },
                        noWeighThargingFreightRule: {
                            type: 'object.delivery.rules',
                            displayName: '<<psgz>>',
                            defaultValue: {
                                deliveryScopes: [],
                                deliveryScopesInfo: [],
                                showDeliveryScopes: [],
                                freightRanges: [
                                    {
                                        startAmount: null,
                                        endAmount: null,
                                        firstAmount: null,
                                        freightLadders: [
                                            {
                                                startValue: null,
                                                ladderValue: null,
                                                ladderAmount: null,
                                                type: 'WEIGHT',
                                            },
                                        ],
                                    },
                                ],
                            },
                            controlConfig: {
                                pageType: 'creditEshop',
                                isWeighTharging: false,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        creditEshopView: {
            component: 'Viewport',
            entity: 'creditEshopEntity',
        },
        creditEshopPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CreditShopFind',
                },
            ],
        },
        creditEshopAddPage: {
            component: 'Card',
            entity: 'creditEshopEntity',
            content: { component: 'CreditShopAddFormView' },
        },
        CreditShopAddFormView: {
            component: 'CreateFormPlus',
            ...baseFormConfig,
            fields: getFormFields('add'),
            onValidate: onValidate(),
        },
        creditEshopEditPage: {
            component: 'Card',
            content: { component: 'CreditShopEditFormView' },
        },
        CreditShopEditFormView: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            entity: 'creditEshopEntity',
            ...baseFormConfig,
            fields: getFormFields('edit'),
            onValidate: onValidate(true),
        },
        CreditEshopCopyPage: {
            component: 'FlexLayout',
            entity: 'creditEshopEntity',
            direction: 'vertical',
            loaderType: 'get',
            items: [{ component: 'CreditShopCopyForm' }],
        },

        CreditShopCopyForm: {
            component: 'CopyFormPlus',
            ...baseFormConfig,
            fields: getFormFields('copy'),
            onValidate: onValidate(),
        },

        creditEshopReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'creditEshopReportEntity',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<hdsj>>',
                            content: {
                                component: 'creditEshopReportActiveData',
                            },
                        },
                    ],
                },
            ],
        },
        creditEshopReportActiveData: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CreditEshopReportView',
                },
                {
                    component: 'creditEshopReportActiveDataFilter',
                },
                {
                    component: 'creditEshopReportActiveDataTable',
                },
            ],
        },
        creditEshopReportActiveDataFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'dateRange',
                },
                {
                    property: 'mobile',
                },
            ],
        },
        creditEshopReportActiveDataTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                    displayConfig: {
                        target: '_blank',
                    },
                },
                {
                    property: 'name',
                },
                {
                    property: 'price',
                },
                {
                    property: 'quantity',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'orderCreateTime',
                },
                {
                    property: 'orderNo',
                    displayConfig: {
                        target: '_blank',
                    },
                },
                {
                    property: 'serviceApplyNos',
                    displayConfig: {
                        target: '_blank',
                    },
                },
                {
                    property: 'refundStatus',
                    displayConfig: {
                        target: '_blank',
                    },
                },
            ],
        },
        //积分商城列表可视化

        CreditEshopPageView: {
            component: 'Viewport',
            entity: 'ElectronicBillEntity',
        },
        CreditEshopPageDesignPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            entity: 'CreditEshopPageEntity',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'FilterForm',
                            direction: 'inline',
                            submit: {
                                text: '<<common.search>>',
                                style: {
                                    marginRight: 16,
                                },
                            },
                            reset: {
                                text: '<<common.reset>>',
                            },
                            fields: [
                                {
                                    property: 'groupSubsiteName',
                                    controlConfig: {
                                        allowClear: true,
                                        showSearch: true,
                                        optionFilterProp: 'children',
                                        style: { width: '160px' },
                                    },
                                },
                                {
                                    property: 'name',
                                },
                            ],
                        },
                        {
                            component: 'Button',
                            text: '<<components.PageManage.createPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/credit-shop-page/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'CreditEshopPageDesignPageTable',
                },
            ],
        },
        CreditEshopPageDesignPageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: 1300 },
            columns: [
                {
                    property: 'groupSubsiteName',
                },
                // {
                //     property: 'type',
                // },
                {
                    property: 'pageName',
                    width: 180,
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
                {
                    property: 'enabled',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: false,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'enabled',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<kq>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: true,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'disable',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<gb>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<components.TopicManage.setting>>',
                        path: '/credit-shop-page/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        config: {
                            url: `/design-editor/?type=CREDIT_ESHOP_PAGE&id={{row.id}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            codeTypes: 'all,h5',
                            codeModalType: 'item',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/credit-shop-page/log/{{row.id}}/DESIGN_PAGE',
                    },
                    {
                        type: 'delete',
                        text: '<<components.TopicManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<qx>>',
                        },
                    },
                ],
            },
        },
        CreditEshopPageAdd: {
            component: 'Card',
            title: '<<components.TopicManage.createPage>>',
            entities: [{ name: 'PageTemplateEntity', entityName: 'PageTemplateEntity' }],
            content: {
                component: 'CreditEshopPageAddForm',
            },
        },
        CreditEshopPageAddForm: {
            component: 'CreateForm',
            entity: 'CreditEshopPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                // {
                //     property: 'enabled',
                // },
                // {
                //     property: 'template',
                //     controlConfig: {
                //         pageType: 'creditEshopPage',
                //         applicableChannels: 'NEW_CLIENT',
                //     },
                // },
            ],
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/credit-shop-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        CreditEshopPageEdit: {
            component: 'Card',
            title: '<<components.TopicManage.setPage>>',
            content: {
                component: 'CreditEshopPageEditForm',
            },
        },
        CreditEshopPageEditForm: {
            component: 'ModifyForm',
            entity: 'CreditEshopPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                // {
                //     property: 'enabled',
                // },
                // {
                //     property: 'template',
                //     controlConfig: {
                //         pageType: 'creditEshopPage',
                //         applicableChannels: 'NEW_CLIENT',
                //     },
                // },
            ],
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/credit-shop-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        CreditEshopDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 24px',
            },
            items: [{ component: 'CreditEshopDeliveryPageTables' }],
        },
        CreditEshopDeliveryPageTables: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<zt>>',
                    content: {
                        component: 'CreditEshopPickUpDeliveryPage',
                    },
                },
                {
                    title: '<<kd_2>>',
                    content: {
                        component: 'CreditEshopExpressDeliveryPage',
                    },
                },
            ],
        },
        CreditEshopPickUpDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'creditEshopPickUpDeliveryEntity',
            items: [
                {
                    component: 'CreditEshopPickUpDeliveryFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdztpz_1>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/credit-shop/add-pick-up',
                                },
                            ],
                        },
                    ],
                },
                { component: 'CreditEshopPickUpDeliveryTable' },
            ],
        },
        CreditEshopPickUpDeliveryFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        CreditEshopPickUpDeliveryTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'address',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'pickUpTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'reminder',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    width: 100,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                    apiPath: '/admin/pick_up_deliveries/:id/disabled',
                                    config: {
                                        content: '<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                    apiPath: '/admin/pick_up_deliveries/:id/enabled',
                                    config: {
                                        content: '<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/credit-shop/edit-pick-up/{{row.id}}',
                    },
                ],
            },
        },
        CreditEshopExpressDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'creditEshopExpressDeliveryEntity',
            items: [
                { component: 'CreditEshopExpressDeliveryPageFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzyfgz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/credit-shop/add-express',
                                },
                            ],
                        },
                    ],
                },
                { component: 'CreditEshopExpressDeliveryPageTable' },
            ],
        },
        CreditEshopExpressDeliveryPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        CreditEshopExpressDeliveryPageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataList',
                    pageType: 'credit-shop',
                    renderItem: 'DeliveryExpressItem',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                },
            ],
        },

        CreditEshopPickUpDeliveryAddPage: {
            component: 'Card',
            content: { component: 'CreditEshopPickUpDeliveryAddForm' },
        },
        CreditEshopPickUpDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'creditEshopPickUpDeliveryEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsite' },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.pickUpTime' },
                { property: 'baseInfo.reminder' },
                { property: 'chooseInfo.reservedTimeHour' },
                { property: 'chooseInfo.topLimitDay' },
                { property: 'chooseInfo.isDeliveryTimePeriods' },
                {
                    property: 'chooseInfo.deliveryTimePeriods',
                    visible: (values: any) =>
                        get(values, 'chooseInfo.isDeliveryTimePeriods') === 'YES',
                },
                { property: 'ruleInfo.orderHandleMode' },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.goods',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.pickUpLimitDays',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.chooseInfo.isDeliveryTimePeriods === 'YES') {
                    if (
                        entity.chooseInfo.deliveryTimePeriods === undefined ||
                        entity.chooseInfo.deliveryTimePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzztsj'));
                        return false;
                    }
                    if (entity.chooseInfo.deliveryTimePeriods.length > 0) {
                        entity.chooseInfo.deliveryTimePeriods.forEach((item: any) => {
                            if (!item.start || !item.end) {
                                flag = false;
                            }
                        });
                    }
                    if (!entity.chooseInfo.topLimitDay) {
                        AntMessage.error(services.language.getText('qtxyysx'));
                        return false;
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('qpzwzdztsj'));
                    return false;
                }
                const orderHandleMode = entity.ruleInfo.orderHandleMode;
                if (orderHandleMode === 'PART_HANDLE') {
                    if (
                        entity.ruleInfo.activityProductType !== 'ALL' &&
                        (!entity.ruleInfo.goods || entity.ruleInfo.goods.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    if (!entity.ruleInfo.pickUpLimitDays && entity.ruleInfo.pickUpLimitDays !== 0) {
                        AntMessage.error(services.language.getText('qpzgqts'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CreditEshopPickUpDeliveryEditPage: {
            component: 'Card',
            content: { component: 'CreditEshopPickUpDeliveryEditForm' },
        },
        CreditEshopPickUpDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'creditEshopPickUpDeliveryEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.subsite',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 300,
                        },
                    },
                },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.pickUpTime' },
                { property: 'baseInfo.reminder' },
                { property: 'chooseInfo.reservedTimeHour' },
                { property: 'chooseInfo.topLimitDay' },
                { property: 'chooseInfo.isDeliveryTimePeriods' },
                {
                    property: 'chooseInfo.deliveryTimePeriods',
                    visible: (values: any) =>
                        get(values, 'chooseInfo.isDeliveryTimePeriods') === 'YES',
                },
                { property: 'ruleInfo.orderHandleMode' },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.goods',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.pickUpLimitDays',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.chooseInfo.isDeliveryTimePeriods === 'YES') {
                    if (
                        entity.chooseInfo.deliveryTimePeriods === undefined ||
                        entity.chooseInfo.deliveryTimePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzztsj'));
                        return false;
                    }
                    if (entity.chooseInfo.deliveryTimePeriods.length > 0) {
                        entity.chooseInfo.deliveryTimePeriods.forEach((item: any) => {
                            if (!item.start || !item.end) {
                                flag = false;
                            }
                        });
                    }
                    if (!entity.chooseInfo.topLimitDay) {
                        AntMessage.error(services.language.getText('qtxyysx'));
                        return false;
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('qpzwzdztsj'));
                    return false;
                }
                const orderHandleMode = entity.ruleInfo.orderHandleMode;
                if (orderHandleMode === 'PART_HANDLE') {
                    if (
                        entity.ruleInfo.activityProductType !== 'ALL' &&
                        (!entity.ruleInfo.goods || entity.ruleInfo.goods.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    if (!entity.ruleInfo.pickUpLimitDays && entity.ruleInfo.pickUpLimitDays !== 0) {
                        AntMessage.error(services.language.getText('qpzgqts'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CreditEshopExpressDeliveryAddPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'CreditEshopExpressDeliveryAddForm' },
        },
        CreditEshopExpressDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'creditEshopExpressDeliveryEntity',
            labelCol: 2,
            controlCol: 22,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsite' },
                { property: 'freightInfo.isWeighTharging' },
                {
                    property: 'freightInfo.isWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'YES',
                },
                {
                    property: 'freightInfo.noWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'NO',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const isWeighTharging = get(entity, 'freightInfo.isWeighTharging', null);
                if (isWeighTharging === 'YES') {
                    const isWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.isWeighThargingFreightRule',
                        null
                    );
                    //2.校验地区是否已选择
                    if (
                        !isWeighThargingFreightRule.deliveryScopes ||
                        isWeighThargingFreightRule.deliveryScopes.length === 0
                    ) {
                        AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                        return false;
                    }
                    //3.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(isWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null ||
                            freightRange.freightLadders[0].startValue === null ||
                            freightRange.freightLadders[0].ladderAmount === null ||
                            freightRange.freightLadders[0].ladderValue === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                if (isWeighTharging === 'NO') {
                    const noWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.noWeighThargingFreightRule',
                        null
                    );
                    //2.校验地区是否已选择
                    if (
                        !noWeighThargingFreightRule.deliveryScopes ||
                        noWeighThargingFreightRule.deliveryScopes.length === 0
                    ) {
                        AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                        return false;
                    }
                    //3.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(noWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CreditEshopExpressDeliveryEditPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'CreditEshopExpressDeliveryEditForm' },
        },
        CreditEshopExpressDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'creditEshopExpressDeliveryEntity',
            loaderType: 'get',
            labelCol: 2,
            controlCol: 22,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.subsite',
                    modifiable: false,
                },
                { property: 'freightInfo.isWeighTharging' },
                {
                    property: 'freightInfo.isWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'YES',
                },
                {
                    property: 'freightInfo.noWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'NO',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const isWeighTharging = get(entity, 'freightInfo.isWeighTharging', null);
                if (isWeighTharging === 'YES') {
                    const isWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.isWeighThargingFreightRule',
                        null
                    );
                    //1.校验地区是否已选择
                    if (
                        isWeighThargingFreightRule.showDeliveryScopes &&
                        isWeighThargingFreightRule.showDeliveryScopes.length > 0
                    ) {
                        let length = 0;
                        forEach(isWeighThargingFreightRule.showDeliveryScopes, (parentRegion) => {
                            if (parentRegion.selectStatus === 'ALL') {
                                length += 1;
                            }
                            if (parentRegion.selectStatus === 'PART') {
                                length += 1;
                            }
                        });
                        if (length === 0) {
                            AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                            return false;
                        }
                    }
                    //2.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(isWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null ||
                            freightRange.freightLadders[0].startValue === null ||
                            freightRange.freightLadders[0].ladderAmount === null ||
                            freightRange.freightLadders[0].ladderValue === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                if (isWeighTharging === 'NO') {
                    const noWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.noWeighThargingFreightRule',
                        null
                    );
                    //1.校验地区是否已选择
                    if (
                        noWeighThargingFreightRule.showDeliveryScopes &&
                        noWeighThargingFreightRule.showDeliveryScopes.length > 0
                    ) {
                        let length = 0;
                        forEach(noWeighThargingFreightRule.showDeliveryScopes, (parentRegion) => {
                            if (parentRegion.selectStatus === 'ALL') {
                                length += 1;
                            }
                            if (parentRegion.selectStatus === 'PART') {
                                length += 1;
                            }
                        });
                        if (length === 0) {
                            AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                            return false;
                        }
                    }
                    //2.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(noWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CreditEshopExpressDeliveryLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_products/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
        CreditEshopLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/credit-shop-page',
                    component: 'CreditEshopPageView',
                    breadcrumbName: '<<zxjfsc>>',
                    privilege: {
                        path: 'creditShopPage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CreditEshopPageAdd',
                            breadcrumbName: '<<zxjfsc>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CreditEshopPageEdit',
                            breadcrumbName: '<<zxjfsc>>',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'CreditEshopLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'CreditEshopPageDesignPage' },
                    ],
                },
                {
                    path: '/credit-shop',
                    component: 'creditEshopView',
                    breadcrumbName: '<<creditEShop>>',
                    privilege: {
                        path: 'creditEShop',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'creditEshopAddPage',
                            breadcrumbName: '<<xjdhgz>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'creditEshopEditPage',
                            breadcrumbName: '<<bjdhgz>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'CreditEshopCopyPage',
                            breadcrumbName: '<<fzdhgz>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'creditEshopReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/delivery',
                            component: 'CreditEshopDeliveryPage',
                        },
                        {
                            path: '/add-pick-up',
                            component: 'CreditEshopPickUpDeliveryAddPage',
                            breadcrumbName: '<<xzztd>>',
                        },
                        {
                            path: '/edit-pick-up/:id',
                            component: 'CreditEshopPickUpDeliveryEditPage',
                            breadcrumbName: '<<bjztd>>',
                        },
                        {
                            path: '/add-express',
                            component: 'CreditEshopExpressDeliveryAddPage',
                            breadcrumbName: '<<xzyfgz>>',
                        },
                        {
                            path: '/edit-express/:id',
                            component: 'CreditEshopExpressDeliveryEditPage',
                            breadcrumbName: '<<bjyfgz>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'CreditEshopExpressDeliveryLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'creditEshopPage' },
                    ],
                },
            ],
        },
    ],
};
