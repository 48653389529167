import React, { Component } from 'react';
import { InputNumber, Checkbox, Button, Popconfirm } from 'antd';
import { clone, map, concat, isEmpty } from 'lodash';
import classNames from 'classnames';

import './index.less';
import { services } from '@comall-backend-builder/core';

type ActivityRewardValue = {
    count1?: number;
    count2?: number;
    type?: string;
    checked?: boolean;
};

enum RewardItems {
    count1 = 'COUNT1',
    count2 = 'COUNT2',
}

export interface CardCollectionRuleProps {
    name: string;
    value: Array<ActivityRewardValue>;
    disabled: boolean;
    fixed?: boolean;
    title?: string;
    hideCount1?: boolean;
    prefix?: string;
    suffix?: string;
    stage?: number;
    max?: number;
    type?: string;
    addManually?: boolean;
    defaultChecked?: boolean;
    onChange: (value: Array<ActivityRewardValue> | null, name: string) => void;
}
export class CardCollectionRuleConfig extends Component<CardCollectionRuleProps> {
    static defaultProps = {
        max: 999,
        disabled: false,
    };
    componentDidMount() {
        let { name, value, onChange, stage, type, defaultChecked } = this.props;
        if (!value) {
            let item: ActivityRewardValue = { type, checked: defaultChecked };
            let times = stage || 1;
            let arr = [];
            for (let index = 0; index < times; index++) {
                arr.push(item);
            }
            value = concat([], arr);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.count1) {
                item.count1 = inputValue;
            } else if (type === RewardItems.count2) {
                item.count2 = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = {};
            if (type === RewardItems.count1) {
                item.count1 = inputValue;
            } else if (type === RewardItems.count2) {
                item.count2 = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheck = (e: any) => {
        let { name, value, onChange } = this.props;
        if (!value) return;
        if (onChange) {
            onChange(
                value.map((i) => {
                    return { ...i, checked: e.target.checked };
                }),
                name
            );
        }
    };
    onAddReward = () => {
        let { name, value, onChange, type } = this.props;
        const checked = value && value.some((i) => i.checked);
        if (value !== null && !isEmpty(value)) {
            let item: ActivityRewardValue = { type, checked };
            value = concat([], value);
            value.splice(value.length, 0, item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };
    onRemoveReward = (index: number) => {
        let { name, value, onChange, type, defaultChecked } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: ActivityRewardValue = { type, checked: defaultChecked };
                value = concat([], item);
            }
        } else {
            let item: ActivityRewardValue = { type, checked: defaultChecked };
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    render() {
        let { value, disabled, addManually } = this.props;
        const checked = value && value.some((i) => i.checked);
        return (
            <div className="card-collection-rule-config-container">
                <Checkbox disabled={disabled} onChange={this.onCheck} checked={checked}></Checkbox>
                <div className="card-collection-rule-config">
                    {map(value, (reward, index) =>
                        this.renderReward(reward, index, disabled || !checked)
                    )}
                    {value && value.length ? null : this.renderReward({}, 0, disabled || !checked)}
                    {addManually && checked && (
                        <Button
                            disabled={disabled}
                            type="default"
                            className="reward-add-reward-button"
                            onClick={() => this.onAddReward()}
                        >
                            {services.language.getText('xzjl')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    renderReward(reward: ActivityRewardValue, index: number, isDisabled: boolean) {
        const { title, hideCount1, prefix, suffix, max, value } = this.props;
        return (
            <div className="reward" key={index}>
                <div className={classNames('reward-item', { flex: hideCount1 })}>
                    {index === 0 && <div className="reward-item-text">{title}</div>}
                    <div className={classNames('reward-item-input', { flex: hideCount1 })}>
                        {!hideCount1 && (
                            <>
                                <span className="reward-item-text">{prefix}</span>
                                <InputNumber
                                    type="number"
                                    min={1}
                                    disabled={isDisabled}
                                    precision={0}
                                    max={max || 999}
                                    value={reward.count1}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.count1)
                                    }
                                />
                                <span className="reward-item-text">{suffix}</span>
                            </>
                        )}
                        <span className="reward-item-text">{services.language.getText('get')}</span>
                        <InputNumber
                            type="number"
                            min={1}
                            disabled={isDisabled}
                            precision={0}
                            max={999}
                            value={reward.count2}
                            onChange={(e) => this.onInputValueChange(e, index, RewardItems.count2)}
                        />
                        <span className="reward-item-text">
                            {services.language.getText('cckjh')}
                        </span>
                        {value && value.length > 1 && !isDisabled && (
                            <Popconfirm
                                onConfirm={this.onRemoveReward.bind(this, index)}
                                title={services.language.getText('sfscgjl')}
                                okText={services.language.getText('common.ok')}
                                cancelText={services.language.getText('common.cancel')}
                            >
                                <Button type="link" className="delete-icon">
                                    {services.language.getText('common.delete')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
