import React, { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage } from 'antd';
import { Loader } from '@comall-backend-builder/core';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { ForwardDataCenterModal } from '../../services';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 导出数据(带filter筛选参数)
 */
export class ExportDataByFilterParamsButton extends PureComponent<any, any> {
    onClick = () => {
        const {
            params: { apiRoot, apiPath, requestMethod, type, noFormatTime },
            entity: { filters: filtersOrigin, metainfo },
            entity,
        } = this.props;
        const filters = cloneDeep(filtersOrigin);
        // 处理开始截止时间组件入参方法，默认入参为startTime和endTime
        function handleDateRange(filter: any, name: string) {
            const { startField = 'startTime', endField = 'endTime' } = filter;
            if (filters && !isEmpty(filters[name])) {
                if (noFormatTime) {
                    filters[startField] = filters[name].start;
                    filters[endField] = filters[name].end;
                } else {
                    filters[startField] = filters[name].start + ' 00:00:00';
                    filters[endField] = filters[name].end + ' 23:59:59';
                }
                delete filters[name];
            }
        }
        // 找出filters中所有type为object.dateRangePlus的组件，依次处理入参
        const filtersConfig = get(metainfo, 'filters', {});
        for (const key in filtersConfig) {
            if (Object.prototype.hasOwnProperty.call(filtersConfig, key)) {
                const filter = filtersConfig[key];
                if (filter.type === 'object.dateRangePlus') {
                    handleDateRange(filter, key);
                }
            }
        }

        if (filters && isObject(filters.subsiteName)) {
            filters.subsiteName = filters.subsiteName.name;
        }

        let params = filters || {};

        // 虚拟订单的参数处理
        if (type === 'coupon-order') {
            const {
                entity: { paging },
            } = this.props;
            const orderType = get(filters, 'orderType');
            const subsites = get(filters, 'subsites');
            if (orderType === 'ALL') {
                delete filters.orderType;
            }

            if (!isEmpty(subsites)) {
                filters.subsiteId = filters.subsites;
                delete filters.subsites;
            }

            if (paging) {
                delete paging.totalPage;
                delete paging.totalNum;
                params = filters ? Object.assign({}, filters, paging) : Object.assign({}, paging);
            }
        }

        if (type === 'service-reserved-activity-orders') {
            const subSiteId = get(params, 'subSiteId');
            if (!isEmpty(subSiteId)) {
                params.subSiteId = subSiteId.id;
            }
            params.orderType = 'SERVICE_RESERVED_ACTIVITY';
            const orderStatusArray = ['', 'CREATE', 'PAYMENT', 'RECEIVE', 'CLOSE', 'CANCEL'];
            const hashArray = window.location.hash.split('#');
            const index = hashArray[hashArray.length - 1];
            params.orderStatus = parseInt(index) ? orderStatusArray[parseInt(index)] : '';
        }

        if (type === 'service_reserved_activity_refunds') {
            const subsites = get(params, 'subsites');
            if (!isEmpty(subsites)) {
                params.subsiteId = subsites.id;
                delete params.subsites;
            }
        }
        if (type === 'offline-order-comments') {
            params.scene = 'POS_RECEIPT_ORDER';
            const commentLevel = get(filters, 'commentLevel');
            const hasBusinessReply = get(filters, 'hasBusinessReply');
            const hasContent = get(filters, 'hasContent');
            const subsite = get(filters, 'subsite');
            if (commentLevel === 'ALL') {
                delete filters.commentLevel;
            }
            if (hasBusinessReply === 'ALL') {
                delete filters.hasBusinessReply;
            }
            if (hasContent === 'ALL') {
                delete filters.hasContent;
            }
            if (!isEmpty(subsite)) {
                filters.subsiteId = filters.subsite.id;
                delete filters.subsite;
            }
        }
        // 意见反馈
        if (type === 'feedback') {
            const subsiteIds = get(filters, 'subsiteIds');
            if (subsiteIds) {
                filters.subsiteIds = subsiteIds.id;
            }
            const type = get(filters, 'type');
            if (type) {
                filters.type = type.name;
            }
            const typeInfo = get(filters, 'typeInfo');
            if (typeInfo) {
                filters.typeInfo = typeInfo.name;
            }
            const replyStatus = get(filters, 'replyStatus');
            if (replyStatus === 'ALL') {
                delete filters.replyStatus;
            }
            const feedbackStatus = get(filters, 'feedbackStatus');
            if (feedbackStatus === 'ALL') {
                delete filters.feedbackStatus;
            }
            const dateRange = get(filters, 'dateRange');
            if (!isEmpty(dateRange)) {
                filters.feedbackStartTime = dateRange.start;
                filters.feedbackEndTime = dateRange.end;
                delete filters.dateRange;
            }
            const replyDateRange = get(filters, 'replyDateRange');
            if (!isEmpty(replyDateRange)) {
                filters.replyStartTime = replyDateRange.start;
                filters.replyEndTime = replyDateRange.end;
                delete filters.replyDateRange;
            }
        }

        if (type === 'authorize-member-management') {
            const subsite = get(filters, 'subsiteId');
            const merchant = get(filters, 'merchantId');
            if (!isEmpty(subsite)) {
                filters.subsiteId = subsite.id;
            }
            if (!isEmpty(merchant)) {
                filters.merchantId = merchant.id;
            }
        }
        if (type === 'offline-order-comments-customization') {
            const subsite = get(filters, 'subsite');
            if (!isEmpty(subsite)) {
                filters.subsiteId = filters.subsite.id;
                delete filters.subsite;
            }
        }
        //汇款明细
        if (type === 'remittance-order-manager') {
            const remittanceStatus = get(this.props.params, 'remittanceStatus');
            params.remittanceStatus = remittanceStatus;
        }

        Loader.load(requestMethod, {
            params,
            apiRoot,
            apiPath: apiPath.replace(/:id/g, entity?.params?.id),
        })
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch((err: any) => {
                console.log('err');
                AntMessage.error(err.response.body.err_msg);
                throw err;
            });
    };

    render() {
        const { params } = this.props;
        const { style } = params;
        return (
            <div>
                <AntButton type="primary" onClick={this.onClick} style={style}>
                    {params.text ? params.text : language.getText('exportRecord')}
                </AntButton>
                {!!params.tips && <span style={{ marginLeft: '20px' }}>{params.tips}</span>}
            </div>
        );
    }
}
