import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectCouponWithPackage } from '../../../containers';
import { CouponSelectorWithPackageDisplay } from '../../../components';

export class CouponSelectorWithPackageMode extends modes.ArrayMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayInfo: any) {
        return value && <CouponSelectorWithPackageDisplay disablePrice data={value} disabled />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectCouponWithPackage {...displayInfo} />;
    }
}
