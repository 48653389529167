import React, { Component } from 'react';
import { Radio } from 'antd';
import { ColorPickerPlus } from '../../../../components';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export class ThemeStyleButtonSelect extends Component<any, any> {
    componentDidMount() {
        const { params, onChange } = this.props;
        if (!params || !params.id) {
            const value = {
                from: '#007046',
                to: '#ab8e66',
                colorType: 'pure',
                direction: 'vertical',
            };
            onChange(value);
        }
    }
    onChange = (color: string, type: 'from' | 'to' | 'colorType' | 'direction') => {
        const { value, onChange } = this.props;
        const newValue = Object.assign({}, value);
        newValue[type] = color;
        onChange(newValue);
    };
    render() {
        const {
            value = {
                from: '#007046',
                to: '#ab8e66',
                colorType: 'pure',
                direction: 'vertical',
            },
            params,
        } = this.props;
        return (
            <div className="theme-style-selector">
                <div className="row">
                    <div className="title">{language.getText('displayEffect')}</div>
                    <div className="content">
                        <Radio.Group
                            className="fill-style"
                            value={value.colorType}
                            onChange={(e: any) => {
                                this.onChange(e.target.value, 'colorType');
                            }}
                        >
                            <Radio value={'pure'}>{language.getText('solidColor')}</Radio>
                            <Radio value={'gradient'}>{language.getText('gradientColor')}</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="row">
                    {value.colorType === 'pure' ? (
                        <div className="title">{language.getText('color')}</div>
                    ) : (
                        <div className="title">{language.getText('foregroundColor')}</div>
                    )}
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'from');
                                },
                                value: value.from,
                                params,
                            } as any)}
                        />
                    </div>
                    <div className="color-txt">{value.from}</div>
                </div>
                <div className="row" hidden={value.colorType === 'pure'}>
                    <div className="title">{language.getText('backgroundColor')}</div>
                    <div className="content">
                        <ColorPickerPlus
                            {...({
                                onChange: (v: string) => {
                                    this.onChange(v, 'to');
                                },
                                value: value.to,
                                params,
                            } as any)}
                        />
                    </div>
                    <div className="color-txt">{value.to}</div>
                </div>
                <div className="row" hidden={value.colorType === 'pure'}>
                    <div className="title">{language.getText('gradientType')}</div>
                    <div className="content">
                        <Radio.Group
                            className="fill-style"
                            value={value.direction}
                            onChange={(e: any) => {
                                this.onChange(e.target.value, 'direction');
                            }}
                        >
                            <Radio value={'vertical'}>{language.getText('portrait')}</Radio>
                            <Radio value={'horizontal'}>{language.getText('transverse')}</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        );
    }
}
