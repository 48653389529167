import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        MallActivityAfterSaleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/mallActivityAfterSale',
            filters: {
                saleRuleName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputOrderNumber>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '手机号' },
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                saleRuleName: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<tksl>>',
                },

                priceAmount: {
                    type: 'number',
                    displayName: '<<tkxjje>>',
                },
                pointAmount: {
                    type: 'number',
                    displayName: '<<tkjf>>',
                },
                statusName: {
                    type: 'string',
                    displayName: '<<auditStatus>>',
                },
                entryTypeName: {
                    type: 'string',
                    displayName: '<<shrk>>',
                },
                applyUser: {
                    type: 'string',
                    displayName: '<<sqr>>',
                },
                auditUser: {
                    type: 'string',
                    displayName: '<<shr_1>>',
                },
                refundStatusName: {
                    type: 'string',
                    displayName: '<<tkzt>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<sqsj>>',
                },
            },
        },
    },
    components: {
        MallActivityAfterSaleView: {
            component: 'Viewport',
            entity: 'MallActivityAfterSaleEntity',
            className: 'table-content-viewport',
        },
        MallActivityAfterSalePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityAfterSaleFilter',
                },
                { component: 'MallActivityAfterSaleTables' },
            ],
        },
        MallActivityAfterSaleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'saleRuleName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        MallActivityAfterSaleTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'MallActivityAfterSaleTable',
                    },
                    params: { status: '' },
                },
                {
                    title: '<<waiting>>',
                    content: {
                        component: 'MallActivityAfterSaleTable',
                    },
                    params: { status: 'APPLYING' },
                },
                {
                    title: '审核未通过',
                    content: {
                        component: 'MallActivityAfterSaleTable',
                    },
                    params: { status: 'APPLY_FAIL' },
                },
                {
                    title: '<<approved>>',
                    content: {
                        component: 'MallActivityAfterSaleTable',
                    },
                    params: { status: 'APPLY_SUCC' },
                },
            ],
        },
        MallActivityAfterSaleTable: {
            component: 'FlexLayout',
            direction: 'vertical',

            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    scroll: {
                        x: 1300,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            width: 160,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'saleRuleName',
                            width: 180,
                        },
                        {
                            property: 'orderNo',
                            width: 200,
                        },
                        {
                            property: 'serviceApplyNo',
                            width: 205,
                        },
                        {
                            property: 'quantity',
                            width: 80,
                        },

                        {
                            property: 'priceAmount',
                            width: 120,
                        },
                        {
                            property: 'pointAmount',
                            width: 80,
                        },
                        {
                            property: 'statusName',
                            width: 120,
                        },
                        {
                            property: 'entryTypeName',
                            width: 120,
                        },
                        {
                            property: 'applyUser',
                            width: 120,
                        },
                        {
                            property: 'auditUser',
                            width: 120,
                        },
                        {
                            property: 'refundStatusName',
                            width: 120,
                        },
                        {
                            property: 'createTime',
                            width: 120,
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 250,
                        items: [
                            {
                                type: 'component',
                                component: 'MallActivityAppliesApplyButton',
                            },
                            {
                                type: 'link',
                                text: '<<details>>',
                                path: '/mall-activity-after-sale/info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MallActivityAfterSaleInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityAfterSaleInfo',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mall-activity-after-sale',
                    component: 'MallActivityAfterSaleView',
                    breadcrumbName: '报名售后单',
                    privilege: {
                        path: 'mallActivityServiceApply',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'MallActivityAfterSaleInfoPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'MallActivityAfterSalePage' },
                    ],
                },
            ],
        },
    ],
};
