import React, { Component } from 'react';
import { Select } from 'antd';
import { IntegerInputTitleTip } from '../../../../components';

import './discount-limit.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

enum RestrictionType {
    SPECIFY_VALUE = 'SPECIFY_VALUE',
    HOUR = 'HOUR',
}

interface DiscountLimitValue {
    limitValue?: number;
    deductionType: RestrictionType;
}

interface DiscountLimitProps {
    onChange: (value: DiscountLimitValue) => void;
    value: DiscountLimitValue;
    disabled: boolean;
    row: any;
}

export class DiscountLimit extends Component<DiscountLimitProps, any> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defaultValue = {
                deductionType: RestrictionType.SPECIFY_VALUE,
            };
            onChange(defaultValue);
        }
    }

    onSelectType = (type: any) => {
        const { value, onChange } = this.props;
        const newValue = Object.assign(value, { deductionType: type });
        onChange && onChange(newValue);
    };

    onInput = (num: number | null | undefined) => {
        const { value, onChange } = this.props;
        const newValue = Object.assign(value, { limitValue: num });
        onChange && onChange(newValue);
    };

    render() {
        const { value } = this.props;
        if (!value) return null;
        const { limitValue, deductionType } = value;
        const isAmount = deductionType === RestrictionType.SPECIFY_VALUE;
        return (
            <div className="discount-limit">
                <div className="discount-limit-content">
                    <IntegerInputTitleTip
                        value={limitValue}
                        onChange={this.onInput}
                        allowFloat={isAmount}
                        width={160}
                        rules={{
                            precision: isAmount ? 2 : 0,
                            max: isAmount ? 9999999 : 24,
                            min: isAmount ? 0.01 : 1,
                        }}
                    />
                    <Select
                        className="discount-limit-type"
                        onSelect={this.onSelectType}
                        value={deductionType}
                    >
                        <Select.Option value={RestrictionType.HOUR}>
                            {language.getText('xstcsz')}
                        </Select.Option>
                        <Select.Option value={RestrictionType.SPECIFY_VALUE}>
                            {language.getText('ytcje')}
                        </Select.Option>
                    </Select>
                </div>
                <div className="discount-limit-tips">
                    {services.language.getText('ccbfbzzxrhyh')}
                </div>
            </div>
        );
    }
}
