import React, { Component } from 'react';
import { InputNumber, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, find, forEach, get, isEqual, map } from 'lodash';
import { CreditShopExchangeApportion } from './credit-shop-exchange-apportion';
import { CreditShopExchangeLimitCount, LIMIT_COUNT_TYPE } from './credit-shop-exchange-limit-count';
import { ExchangeLimitEnum } from '../../../../config/member/point-manage/credit-eshop';
import './index.less';

const { api } = services;

export interface MemberLevelRewardInfos {
    cardLevelId: number;
    cardLevelName: string;
    points: string;
    salesPrice: string;
    apportionInfos: Array<ApportionInfo>;
    cycleLimits: Array<limitCountInfo>;
}
interface limitCountInfo {
    ruleType: LIMIT_COUNT_TYPE;
    limitCount: string;
}
interface ApportionInfo {
    apportionPoints: string;
    apportionPrice: string;
    id: number;
    name: string;
    type: string;
    quantity: number;
}
interface MemberCardLevelPointRewardValue {
    commonExchange: MemberLevelRewardInfos[];
    cardLevelExchange: MemberLevelRewardInfos[];
}
interface CreditShopExchangeProps {
    onChange: (value: any) => void;
    value: MemberCardLevelPointRewardValue | undefined;
    row: { baseInfo: { subsiteId: string } };
    subsiteField: string;
    isEditForm: boolean;
}
interface CreditShopExchangeStates {
    isCouponPackage: boolean;
    loading: boolean;
    memberLevelRewardInfo?: MemberLevelRewardInfos;
    apportionModalVisible: boolean;
    cycleLimitVisible: boolean;
    // 当兑换限制未会员卡时，显示的会员卡等级
    isMemberLimt: boolean;
    showCardLevels: string[];
}

const defalutCycleLimits: Array<limitCountInfo> = [
    {
        ruleType: LIMIT_COUNT_TYPE.EACH_DAY,
        limitCount: '',
    },
    {
        ruleType: LIMIT_COUNT_TYPE.EACH_PERIOD,
        limitCount: '',
    },
];

const defaultCommonExchangeValue: MemberLevelRewardInfos = {
    cardLevelId: -1,
    cardLevelName: '',
    points: '',
    salesPrice: '',
    apportionInfos: [],
    cycleLimits: defalutCycleLimits,
};

const defaultValue = {
    commonExchange: [defaultCommonExchangeValue],
    cardLevelExchange: [],
};

export default class CreditShopExchange extends Component<
    CreditShopExchangeProps,
    CreditShopExchangeStates
> {
    constructor(props: CreditShopExchangeProps) {
        super(props);
        this.state = {
            isCouponPackage: false,
            loading: false,
            apportionModalVisible: false,
            cycleLimitVisible: false,
            isMemberLimt: false,
            showCardLevels: [],
        };
    }

    componentDidMount() {
        setTimeout(() => {
            const creditNextCoupon = get(this.props, 'row.coupon', []);
            const creditNextCouponItem =
                creditNextCoupon && creditNextCoupon.length > 0 ? creditNextCoupon[0] : undefined;
            if (creditNextCouponItem) {
                const isCouponPackage = creditNextCouponItem.type === 'PACKAGE';
                this.setState({
                    isCouponPackage,
                });
            }
            const exchangeLimit = get(this.props, 'row.exchangeInfo.exchangeLimit');
            if (exchangeLimit === ExchangeLimitEnum.MEMBER_CARD_LEVEL) {
                const showMemberLevels = get(this.props, 'row.exchangeInfo.memberLevels') || [];
                this.setState({
                    isMemberLimt: true,
                    showCardLevels: showMemberLevels.map((item: any) => item.id.toString()),
                });
            } else if (exchangeLimit === ExchangeLimitEnum.CROWD) {
                this.setState({
                    isMemberLimt: true,
                    showCardLevels: [],
                });
            } else {
                this.setState({
                    isMemberLimt: false,
                    showCardLevels: [],
                });
            }
            const { value, onChange } = this.props;
            if (!value) {
                onChange(defaultValue);
            }
        }, 2000);
    }

    componentWillReceiveProps(nextProps: CreditShopExchangeProps) {
        const { value } = nextProps;
        if (!value) {
            return;
        }
        const productType = get(this.props, 'row.productType');
        const nextProductType = get(nextProps, 'row.productType');
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        const subsiteChanged = !isEqual(nextSubsiteIds, currentSubsiteIds);
        // 当切换商品类型为积分实物商品时，或当商品类型为积分商品变更门店时，刷新会员卡等级
        // 切换商品类型就需要刷新会员卡信息数据 否则可能导致分摊价展示不正确
        if (nextSubsiteIds && (nextProductType !== productType || subsiteChanged)) {
            this.onChangeMemberLevel(nextProps);
            return;
        }
        const creditCurrentCoupon = get(this.props, 'row.coupon');
        const creditNextCoupon = get(nextProps, 'row.coupon', []);
        const [creditNextCouponItem] = creditNextCoupon || [];
        if (
            nextSubsiteIds &&
            creditNextCouponItem &&
            (subsiteChanged || !isEqual(creditCurrentCoupon, creditNextCoupon))
        ) {
            this.onChangeMemberLevel(nextProps);
        }
        const exchangeLimit = get(this.props, 'row.exchangeInfo.exchangeLimit');
        const nextExchangeLimit = get(nextProps, 'row.exchangeInfo.exchangeLimit');
        const memberLevels = get(this.props, 'row.exchangeInfo.memberLevels');
        const nextMemberLevels = get(nextProps, 'row.exchangeInfo.memberLevels');
        if (nextSubsiteIds && exchangeLimit !== nextExchangeLimit) {
            this.onChangeMemberLevel(nextProps);
            if (nextExchangeLimit === ExchangeLimitEnum.MEMBER_CARD_LEVEL) {
                this.setState({
                    isMemberLimt: true,
                    showCardLevels: (memberLevels || []).map((item: any) => item.id.toString()),
                });
            } else if (nextExchangeLimit === ExchangeLimitEnum.CROWD) {
                this.setState({
                    isMemberLimt: true,
                    showCardLevels: [],
                });
            } else {
                this.setState({
                    isMemberLimt: false,
                    showCardLevels: [],
                });
            }
        }
        if (
            exchangeLimit === ExchangeLimitEnum.MEMBER_CARD_LEVEL &&
            !isEqual(memberLevels, nextMemberLevels)
        ) {
            this.setState({
                isMemberLimt: true,
                showCardLevels: (nextMemberLevels || []).map((item: any) => item.id.toString()),
            });
        }
    }

    onChangeMemberLevel = async (props: any) => {
        const { onChange, isEditForm } = props;
        const isEditUnInited = isEditForm && !this.getSubsiteIds(this.props)?.length;
        if (isEditUnInited) {
            return;
        }
        if (get(props, 'row.exchangeInfo.exchangeLimit') === ExchangeLimitEnum.CROWD) {
            onChange(defaultValue);
            setTimeout(() => {
                this.onChangeApportionInfos();
            });
            return;
        }
        const subsiteId = this.getSubsiteIds(props);
        if (!subsiteId || subsiteId.length === 0) {
            onChange(defaultValue);
            return;
        }
        const params = {
            subsiteIds: subsiteId.join(','),
            page: 1,
            perPage: 100,
            status: 'ENABLE',
        };
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/store_card_levels/legal',
        };
        const res: any = await api.get(params, config);
        if (!res && !res.result && res.length === 0) {
            onChange(defaultValue);
            return;
        }
        const newValue: any = defaultValue;
        const rewardInfos: Array<MemberLevelRewardInfos> = [];
        forEach(res.result, (levelInfo) => {
            rewardInfos.push({
                cardLevelId: levelInfo.cardLevelId,
                cardLevelName: levelInfo.name,
                points: '',
                salesPrice: '',
                apportionInfos: [],
                cycleLimits: defalutCycleLimits,
            });
        });
        newValue.cardLevelExchange = rewardInfos;
        onChange && onChange(cloneDeep(newValue));
        this.onChangeApportionInfos();
    };

    onChangeApportionInfos = async () => {
        const { value, onChange } = this.props;
        const creditNextCoupon = get(this.props, 'row.coupon', []);
        const productType = get(this.props, 'row.productType', 'VIRTUAL_COUPON');
        const creditNextCouponItem =
            creditNextCoupon && creditNextCoupon.length > 0 ? creditNextCoupon[0] : undefined;
        if (!value || !creditNextCouponItem) {
            return;
        }
        const isCouponPackage =
            creditNextCouponItem.type === 'PACKAGE' && productType === 'VIRTUAL_COUPON';
        this.setState({
            isCouponPackage,
        });
        if (isCouponPackage) {
            const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
            const apiPath = `/admin/coupon_package_rules/${creditNextCouponItem.id}`;
            const data: any = await api.get({}, { apiRoot, apiPath });
            if (data) {
                const apportionInfos: Array<ApportionInfo> = [];
                data.ruleItems.forEach((rule: any) => {
                    apportionInfos.push({
                        apportionPoints: '',
                        apportionPrice: '',
                        id: rule.couponRule.id,
                        name: rule.couponRule.name,
                        type: rule.couponRule.typeName,
                        quantity: rule.quantity,
                    });
                });
                if (value.commonExchange) {
                    value.commonExchange[0].apportionInfos = apportionInfos;
                }
                if (value.cardLevelExchange) {
                    value.cardLevelExchange.forEach((item) => {
                        item.apportionInfos = apportionInfos;
                    });
                }
                onChange(value);
            }
        } else {
            if (value.commonExchange) {
                value.commonExchange[0].apportionInfos = [];
            }
            if (value.cardLevelExchange) {
                value.cardLevelExchange.forEach((item) => {
                    item.apportionInfos = [];
                });
            }
            onChange(value);
        }
        if (value) {
        }
    };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds = row && row.subsiteId ? map(row.subsiteId, 'id') : null;
        return subsiteIds;
    };

    onChangePrice = (inputType: string, inputValue: any, cardLevelId: number) => {
        const { value, onChange } = this.props;
        if (!value) {
            return;
        }
        if (inputValue && inputValue < 0) {
            inputValue = '';
        }
        if (cardLevelId === -1) {
            const commonExchange: any = value.commonExchange[0];
            commonExchange[inputType] = inputValue;
            onChange(value);
        } else {
            let row: any = find(value.cardLevelExchange, { cardLevelId: cardLevelId });
            if (row) {
                row[inputType] = inputValue;
            }
            onChange(value);
        }
    };

    renderItem = (infos: Array<MemberLevelRewardInfos>, type: string) => {
        const { isCouponPackage } = this.state;
        return (
            <td style={{ textAlign: 'center' }}>
                {infos.map((item, index) => {
                    const style = {
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 0',
                        borderTop:
                            type === 'cardLevelExchange' && index !== 0
                                ? '1px solid #eee'
                                : undefined,
                    };
                    let isSetting = false;
                    if (item.cycleLimits && item.cycleLimits.length > 0) {
                        forEach(item.cycleLimits, (item: any) => {
                            if (item.limitCount) {
                                isSetting = true;
                            }
                        });
                    }
                    return (
                        <div style={style} key={index}>
                            <div style={{ width: '15%' }}>
                                {item.cardLevelId > 0
                                    ? `${item.cardLevelName}`
                                    : services.language.getText('ty_2')}
                            </div>
                            <div style={{ width: '40%' }}>
                                <InputNumber
                                    value={
                                        item.points !== null && item.points !== ''
                                            ? Number(item.points)
                                            : undefined
                                    }
                                    max={100000000}
                                    step={0.01}
                                    precision={2}
                                    placeholder={services.language.getText('inputPlease')}
                                    onChange={(value: any) =>
                                        this.onChangePrice('points', value, item.cardLevelId)
                                    }
                                />
                                <span>{services.language.getText('point')} + </span>
                                <InputNumber
                                    value={
                                        item.salesPrice !== null && item.salesPrice !== ''
                                            ? Number(item.salesPrice)
                                            : undefined
                                    }
                                    max={100000000}
                                    step={0.01}
                                    precision={2}
                                    placeholder={services.language.getText('inputPlease')}
                                    onChange={(value: any) =>
                                        this.onChangePrice('salesPrice', value, item.cardLevelId)
                                    }
                                />
                                <span> {services.language.getText('yuan')}</span>
                            </div>
                            {isCouponPackage && (
                                <div style={{ width: '15%' }}>
                                    {item.apportionInfos && item.apportionInfos.length > 0 && (
                                        <span
                                            style={{ color: '#1890ff', cursor: 'pointer' }}
                                            onClick={this.handleApportionModal.bind(this, item)}
                                        >
                                            {services.language.getText('setSharePrice')}
                                        </span>
                                    )}
                                </div>
                            )}
                            <div
                                style={{
                                    width: '30%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {item.cycleLimits && item.cycleLimits.length > 0 && (
                                    <div style={{ marginRight: '10px', textAlign: 'right' }}>
                                        {item.cycleLimits.map((limit, index) => {
                                            const LIMIT_COUNT_TYPE_TEXT: Record<any, any> = {
                                                [LIMIT_COUNT_TYPE.EACH_DAY]: '每日',
                                                [LIMIT_COUNT_TYPE.EACH_WEEK]: '每周',
                                                [LIMIT_COUNT_TYPE.EACH_MONTH]: '每月',
                                                [LIMIT_COUNT_TYPE.EACH_YEAR]: '每年',
                                            };
                                            return (
                                                <div key={index}>
                                                    {index === 0 && limit.limitCount && (
                                                        <span>
                                                            {LIMIT_COUNT_TYPE_TEXT[limit.ruleType]}
                                                            {services.language.getText('limit')}
                                                            {limit.limitCount}
                                                            {services.language.getText('jian')}
                                                        </span>
                                                    )}
                                                    {index === 1 && limit.limitCount && (
                                                        <span>
                                                            {' '}
                                                            {services.language.getText('hdxg')}
                                                            {limit.limitCount}件
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <div
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={this.handleLimitCountModal.bind(this, item)}
                                >
                                    <span>
                                        {isSetting
                                            ? services.language.getText('settings')
                                            : type === 'cardLevelExchange'
                                            ? services.language.getText('szdjxg')
                                            : services.language.getText('szptxg')}
                                    </span>
                                </div>
                                {this.rendeRemptyBtn(item)}
                            </div>
                        </div>
                    );
                })}
            </td>
        );
    };

    handleApportionModal = (item: MemberLevelRewardInfos) => {
        this.setState(
            {
                memberLevelRewardInfo: undefined,
            },
            () => {
                if (!item.points) {
                    AntMessage.warning(services.language.getText('qxszsmjgzszftj'));
                    return;
                }
                this.setState({
                    memberLevelRewardInfo: cloneDeep(item),
                    apportionModalVisible: true,
                });
            }
        );
    };

    handleLimitCountModal = (item: MemberLevelRewardInfos) => {
        const { value } = this.props;
        let errorText = '';
        let data =
            item.cardLevelId === -1 ? value?.cardLevelExchange || [] : value?.commonExchange || [];
        if (data) {
            let status = 0;
            data.forEach((c: any) => {
                let cycleLimits =
                    c.cycleLimits && c.cycleLimits.filter((limit: any) => limit.limitCount);
                if (cycleLimits && cycleLimits.length) {
                    status++;
                }
            });
            if (status) {
                if (item.cardLevelId === -1) {
                    errorText = services.language.getText('l_qxqkdjxg');
                } else {
                    errorText = services.language.getText('qxqkptxg');
                }
            }
        }
        if (errorText) {
            AntMessage.warning(errorText);
            return;
        }

        this.setState(
            {
                memberLevelRewardInfo: undefined,
            },
            () => {
                if (!item.cycleLimits) {
                    item.cycleLimits = [
                        {
                            ruleType: LIMIT_COUNT_TYPE.EACH_DAY,
                            limitCount: '',
                        },
                        {
                            ruleType: LIMIT_COUNT_TYPE.EACH_PERIOD,
                            limitCount: '',
                        },
                    ];
                }
                this.setState({
                    memberLevelRewardInfo: cloneDeep(item),
                    cycleLimitVisible: true,
                });
            }
        );
    };
    rendeRemptyBtn = (item: MemberLevelRewardInfos) => {
        if (item.cycleLimits && item.cycleLimits.length) {
            let cycleLimits = item.cycleLimits.filter((limit: any) => limit.limitCount);
            if (cycleLimits.length) {
                return (
                    <div onClick={this.onClickEmpty.bind(this, item)}>
                        <span style={{ color: 'red', marginLeft: 10, cursor: 'pointer' }}>
                            {services.language.getText('qk')}
                        </span>
                    </div>
                );
            }
            return null;
        }
        return null;
    };
    onClickEmpty = (item: MemberLevelRewardInfos) => {
        const { onChange, value } = this.props as any;
        let data = item.cardLevelId === -1 ? value.commonExchange : value.cardLevelExchange;
        let cycleLimits = [
            {
                ruleType: LIMIT_COUNT_TYPE.EACH_DAY,
                limitCount: '',
            },
            {
                ruleType: LIMIT_COUNT_TYPE.EACH_PERIOD,
                limitCount: '',
            },
        ];
        const row = find(data, {
            cardLevelId: item.cardLevelId,
        });
        if (row) {
            row.cycleLimits = cycleLimits;
        }
        onChange && onChange(value);
    };
    handleCancel = () => {
        this.setState({
            memberLevelRewardInfo: undefined,
            apportionModalVisible: false,
            cycleLimitVisible: false,
        });
    };

    handleOk = (data: any) => {
        const { onChange, value } = this.props;
        const { memberLevelRewardInfo } = this.state;
        if (!value || !memberLevelRewardInfo) {
            return null;
        }
        if (memberLevelRewardInfo.cardLevelId === -1) {
            const row = find(value.commonExchange, {
                cardLevelId: memberLevelRewardInfo.cardLevelId,
            });
            if (row) {
                row.apportionInfos = data;
            }
        } else {
            const row = find(value.cardLevelExchange, {
                cardLevelId: memberLevelRewardInfo.cardLevelId,
            });
            if (row) {
                row.apportionInfos = data;
            }
        }
        onChange && onChange(value);
        this.setState({ apportionModalVisible: false });
    };

    handleCycleLimitsOk = (data: any) => {
        const { onChange, value } = this.props;
        const { memberLevelRewardInfo } = this.state;
        if (!value || !memberLevelRewardInfo) {
            return null;
        }
        if (memberLevelRewardInfo.cardLevelId === -1) {
            const row = find(value.commonExchange, {
                cardLevelId: memberLevelRewardInfo.cardLevelId,
            });
            if (row) {
                row.cycleLimits = data;
            }
        } else {
            const row = find(value.cardLevelExchange, {
                cardLevelId: memberLevelRewardInfo.cardLevelId,
            });
            if (row) {
                row.cycleLimits = data;
            }
        }
        onChange && onChange(value);
        this.setState({ cycleLimitVisible: false });
    };

    render() {
        const { value } = this.props;
        const {
            apportionModalVisible,
            memberLevelRewardInfo,
            cycleLimitVisible,
            isCouponPackage,
            isMemberLimt,
            showCardLevels,
        } = this.state;
        if (!value) {
            return null;
        }
        const subsiteId = this.getSubsiteIds(this.props);
        if (!subsiteId || subsiteId.length === 0) {
            return (
                <span style={{ color: 'red' }}>{services.language.getText('selectSubsite')}</span>
            );
        }
        const isCoupon = get(this.props, 'row.productType') === 'VIRTUAL_COUPON';
        const creditCurrentCoupon = get(this.props, 'row.coupon');
        if (isCoupon && (!creditCurrentCoupon || creditCurrentCoupon.length === 0)) {
            return <span style={{ color: 'red' }}>{services.language.getText('qxzyhq')}</span>;
        }
        const { commonExchange, cardLevelExchange } = value;
        const showCardLevelExchange = isMemberLimt
            ? cardLevelExchange.filter((item) => {
                  return showCardLevels.includes(item.cardLevelId.toString());
              })
            : cardLevelExchange;
        return (
            <div className="member-card-level-point-reward">
                <table
                    cellSpacing={0}
                    cellPadding={5}
                    style={{
                        borderCollapse: 'collapse',
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    <tbody style={{ border: '1px solid #eee' }}>
                        <tr style={{ backgroundColor: '#fafafa' }}>
                            <th style={{ width: '10%', borderRight: '1px solid #eee' }}>
                                {services.language.getText('jglx')}
                            </th>
                            <th style={{ display: 'flex' }}>
                                <div style={{ width: '15%' }}>
                                    {services.language.getText('dj_2')}
                                </div>
                                <div style={{ width: '40%' }}>
                                    {services.language.getText('price')}
                                </div>
                                {isCouponPackage && (
                                    <div style={{ width: '15%' }}>
                                        {services.language.getText('ftj')}
                                    </div>
                                )}
                                <div style={{ width: '30%' }}>
                                    {services.language.getText('limit')}
                                </div>
                            </th>
                        </tr>
                        <tr style={{ textAlign: 'left', borderBottom: '1px solid #eee' }}>
                            <td
                                style={{
                                    textAlign: 'center',
                                    borderRight: '1px solid #eee',
                                    width: '11%',
                                }}
                            >
                                <span style={{ color: 'red' }}>*&nbsp;</span>
                                {services.language.getText('mrdhj')}
                            </td>
                            {this.renderItem(commonExchange, 'commonExchange')}
                        </tr>
                        {showCardLevelExchange && showCardLevelExchange.length > 0 && (
                            <tr style={{ textAlign: 'left', borderBottom: '1px solid #eee' }}>
                                <td
                                    style={{
                                        textAlign: 'center',
                                        borderRight: '1px solid #eee',
                                        width: '10%',
                                    }}
                                >
                                    {services.language.getText('hyj')}
                                </td>
                                {this.renderItem(showCardLevelExchange, 'cardLevelExchange')}
                            </tr>
                        )}
                        {memberLevelRewardInfo && (
                            <CreditShopExchangeApportion
                                memberLevelRewardInfo={memberLevelRewardInfo}
                                data={memberLevelRewardInfo.apportionInfos}
                                visible={apportionModalVisible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                            />
                        )}
                        {memberLevelRewardInfo && (
                            <CreditShopExchangeLimitCount
                                memberLevelRewardInfo={memberLevelRewardInfo}
                                data={memberLevelRewardInfo.cycleLimits}
                                visible={cycleLimitVisible}
                                onOk={this.handleCycleLimitsOk}
                                onCancel={this.handleCancel}
                            />
                        )}
                    </tbody>
                </table>
                <div className="bottom-tip">
                    <div>
                        <span>{services.language.getText('l_rbpzhykdjjg')}</span>
                    </div>
                    <div>
                        <span>{services.language.getText('l_zxljdjxg')}</span>
                    </div>
                </div>
            </div>
        );
    }
}
