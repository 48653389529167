import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { AffiliateMarketingPeriodLimit } from './affiliate-marketing-period-limit';

export class AffiliateMarketingPeriodLimitMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <AffiliateMarketingPeriodLimit {...controlInfo} />;
    }
}
