import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MerchantBigCodesModal } from './big-codes-modal';

export class MerchantBigCodesMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any) {
        return <MerchantBigCodesModal {...displayInfo} />;
    }
}
