import { BaseConfig } from '../interfaces';

export const config: BaseConfig = {
    entities: {
        NewRoleManageEntity: {
            apiPath: '/loader/cae/roles',
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<jsmc>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<jszt_1>>',
                    options: [
                        {
                            id: 'ACTIVE',
                            name: '<<enable>>',
                        },
                        {
                            id: 'INACTIVE',
                            name: '<<disabled>>',
                        },
                    ],
                },
            },
            properties: {
                name: {
                    type: 'string.role.system',
                    displayName: '<<jsmc>>',
                },
                remark: {
                    type: 'string',
                    displayName: '<<jsms>>',
                },
                userNumber: {
                    type: 'string',
                    displayName: '<<zhsysl>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<jszt_1>>',
                    defaultValue: 'ACTIVE',
                    options: [
                        {
                            id: 'ACTIVE',
                            name: '<<enable>>',
                        },
                        {
                            id: 'INACTIVE',
                            name: '<<disabled>>',
                        },
                    ],
                },
                sequence: {
                    type: 'string',
                    displayName: '<<sequence>>',
                    rules: [{ required: true }],
                },
                extendAttributes: {
                    type: 'array.extendAttributes',
                    displayName: '<<tzsx>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        parentName: {
                            type: 'string.stringDesc',
                            displayName: '<<sjjs>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<jsmc>>',
                            rules: [
                                { required: true, message: '<<qtxjsmc>>' },
                                {
                                    message: '<<roleErrorMessage>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && value.trim().length > 40) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 500,
                                },
                            },
                        },
                        sequence: {
                            type: 'number',
                            displayName: '<<sequence>>',
                            defaultValue: '0',
                            controlConfig: {
                                min: 0,
                                max: 999999999,
                                precision: 0,
                                style: {
                                    width: 100,
                                },
                            },
                        },
                        remark: {
                            displayName: '<<jsms>>',
                            type: 'string.text.paragraph',
                            rules: [
                                {
                                    message: '<<moreThan400>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && value.trim().length > 400) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrjsms>>',
                                rows: 4,
                                style: {
                                    width: 500,
                                },
                            },
                        },
                    },
                },
                authInfo: {
                    type: 'object.subForm',
                    displayName: '<<jsqx>>',
                    properties: {
                        privilegeSetIds: {
                            displayName: '<<authority>>',
                            type: 'array.role.privilege',
                        },
                    },
                },
            },
        },
    },
    components: {
        NewRoleManageView: {
            component: 'Viewport',
            entity: 'NewRoleManageEntity',
        },
        NewRoleManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'NewRoleManageViewContainer' }],
        },
        NewRoleManageAddPage: {
            component: 'Card',
            content: {
                component: 'NewRoleManageAddForm',
            },
        },

        NewRoleManageEditPage: {
            component: 'Card',
            entity: 'NewRoleManageEntity',
            loaderType: 'get',
            content: {
                component: 'NewRoleManageEditForm',
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/new-role-manage',
                    breadcrumbName: '<<newRoleManage>>',
                    category: '<<newRoleManage>>',
                    component: 'NewRoleManageView',
                    privilege: {
                        path: 'role_manage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/:parentId/:parentName',
                            breadcrumbName: '<<xzjs_1>>',
                            component: 'NewRoleManageAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<bjjs>>',
                            component: 'NewRoleManageEditPage',
                        },
                        { path: '/', component: 'NewRoleManagePage' },
                    ],
                },
            ],
        },
    ],
};
