import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { TipsModalContentType } from '../../components';

export let config: Config = {
    entities: {
        WechatOfficialAccountsEntity: {
            apiPath: '/loader/admin/wechat_official_accounts',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<gzhmc>>',
                    controlConfig: {
                        placeholder: '<<qsrgzhmc>>',
                        style: { width: 170 },
                    },
                },
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<glmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },
            },
            properties: {
                appId: {
                    type: 'string',
                    displayName: 'AppId',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<inputPlease>>AppId',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        placeholder: '<<inputPlease>>AppId',
                        maxLength: 50,
                    },
                },
                appSecret: {
                    type: 'string',
                    displayName: 'AppSecret',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<inputPlease>>AppSecret',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        placeholder: '<<inputPlease>>AppSecret',
                        maxLength: 50,
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<gzhmc>>',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<qsrgzhmc>>',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        placeholder: '<<qsrgzhmc>>',
                        maxLength: 50,
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<glmd>>',
                    rules: [{ required: true, type: 'array' }],
                    defaultValue: [],
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                authScope: {
                    type: 'string.options.select',
                    displayName: '<<jksqzt>>',
                    options: [
                        { id: 'ALL', name: '<<ysq>>' },
                        { id: 'NONE', name: '<<wsq_1>>' },
                        { id: 'PART', name: '<<bfsq>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<kqz>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
            },
        },
        WechatOfficialAccountConfigEntity: {
            apiPath: '/loader/admin/wechat_official_accounts/widget',
            properties: {
                id: {
                    type: 'string',
                },
                widgetId: {
                    type: 'string',
                },
                qrCodeUrl: {
                    type: 'string',
                },
                subsites: {
                    displayName: '<<qymd>>',
                    type: 'array.wechatOfficialSubsites',
                },
                headTitle: {
                    displayName: '<<floatingActionButton>><<ymdbbt>>',
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<lqsrbcg>>',
                        maxLength: 10,
                        style: {
                            width: 400,
                        },
                    },
                    rules: [
                        {
                            required: true,
                            max: 10,
                            whitespace: true,
                            message: '<<btbnwk>>',
                        },
                    ],
                },
                applyPages: {
                    displayName: '<<xfczswz>>',
                    type: 'array.optionIds.checkbox.tip',
                    options: [
                        { id: 'SUBSITE_PAGE', name: '<<storePage>>' },
                        { id: 'MEMBER_PAGE', name: '<<memberCenterPage>>' },
                        { id: 'PAID_PAGE', name: '<<payResultPage>>' },
                        { id: 'TOPIC_PAGE', name: '<<topicActivity>>' },
                    ],
                    controlConfig: {
                        tip: ['<<lxfczswz1>>', '<<lxfczswz2>>'],
                    },
                },
                topicPages: {
                    displayName: '<<ztym>>',
                    type: 'array.wechatOfficialTopics',
                    rules: [
                        {
                            required: true,
                            message: '<<qxzxfczsdztym>>',
                        },
                    ],
                },
                backgroundPictureUrl: {
                    displayName: '<<xfcbjt>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    defaultValue: [
                        {
                            id: '0',
                            path:
                                'https://b-puzhehei-cdn.co-mall.net/global/wechat-official-account/wechat-official-account-popup-bg.png',
                        },
                    ],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
                title: {
                    displayName: '<<bt_1>>',
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<lqsrbcg15>>',
                        maxLength: 15,
                    },
                },
                description: {
                    displayName: '<<ms>>',
                    type: 'string.richTextPlus',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                },
            },
        },
        WechatOfficialAccountSubsite: {
            apiPath: '/loader/admin/wechat_official_accounts/subsites',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<subsiteAddress>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<businessStatus>>',
                    options: [
                        { id: '0', name: '<<sc_1>>' },
                        { id: '1', name: '<<zc_1>>' },
                        { id: '2', name: '<<disabled>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
            },
        },
        WechatOfficialAccountTopic: {
            apiPath: '/loader/admin/wechat_official_accounts/topics',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsites: {
                    displayName: '<<subsites>>',
                    type: 'array.popover',
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<enable>>' },
                        { id: 'false', name: '<<disabled>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
            },
        },
        WechatOfficialAccountGiftEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/wechat_official_accounts_gift',
            properties: {
                appId: {
                    type: 'string',
                },
                subsites: {
                    displayName: '<<qymd>>',
                    type: 'array.wechatOfficialSubsites',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        status: {
                            type: 'string.options.radio',
                            displayName: '<<gzgzhyl>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<kq>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<gb>>',
                                },
                            ],
                            extra: '<<followGiftStatus_tip>>',
                        },
                        name: {
                            displayName: '<<activityName>>',
                            type: 'string',
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                            rules: [{ required: true }],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                rewardConfig: {
                    type: 'object.subForm',
                    displayName: '<<jlgz>>',
                    properties: {
                        rewards: {
                            displayName: '<<gzhzs>>',
                            type: 'object.activityReward.single',
                            options: [],
                            controlConfig: {
                                optionsConfig: {
                                    apiPath: '/admin/activities/reward_items',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    params: {
                                        event_type: 'COMMON',
                                    },
                                },
                                tipsModalConfig: {
                                    type: 'extra',
                                    position: 'top',
                                    extraConfig: {
                                        before: '<<followGiftReward_tip>>',
                                    },
                                    contentType: TipsModalContentType.RewardsPopup,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                                getSubsiteIds(row: any) {
                                    return row && row.subsites
                                        ? row.subsites.map((subsite: any) => subsite.id).join(',')
                                        : '';
                                },
                                restrictPackage: true,
                            },
                            rules: [{ required: true }],
                        },
                        followAgainHasGift: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<zsgz>>',
                            className: 'no-margin-field',
                            defaultValue: ['NotReclaimable'],
                            options: [
                                {
                                    name: '<<checkRule>>',
                                    id: 'NotReclaimable',
                                },
                            ],
                            controlConfig: {
                                checkboxType: 'Multiple',
                                tip: ['<<zsgzText1>>', '<<zsgzText2>>', '<<zsgzText3>>'],
                            },
                        },
                        registerReward: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '',
                            className: 'no-name-register-reward',
                            defaultValue: ['RegisterReward'],
                            options: [
                                {
                                    name: '<<registerReward>>',
                                    id: 'RegisterReward',
                                },
                            ],
                            controlConfig: {
                                tip: ['<<registerRewardTip>>'],
                            },
                        },
                        // rule: {
                        //     type: 'string.tips',
                        //     displayName: ' ',
                        //     colon: false,
                        //     controlConfig: {
                        //         tips: [
                        //             [
                        //                 {
                        //                     text: '<<zsgzText1>>',
                        //                 },
                        //             ],
                        //             [
                        //                 {
                        //                     text: '<<zsgzText2>>',
                        //                 },
                        //             ],
                        //             [
                        //                 {
                        //                     text: '<<zsgzText3>>',
                        //                 },
                        //             ],
                        //         ],
                        //     },
                        // },
                        executionResult: {
                            type: 'string.pictureExampleButton',
                            displayName: '<<gzhhf>>',
                            className: 'no-margin-field',
                            controlConfig: {
                                extraText: '<<pzjl>>',
                                buttonName: '<<viewExample>>',
                                type: 'autoReply',
                            },
                        },
                        autoReply: {
                            type: 'object.WechatOfficialAutoReplyView',
                            displayName: ' ',
                            colon: false,
                        },
                    },
                },
            },
        },
    },
    components: {
        WechatOfficialAccountsView: {
            component: 'Viewport',
            entity: 'WechatOfficialAccountsEntity',
        },
        WechatOfficialAccountsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'subsite',
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '<<xzgzh>>',
                    route: '/wechat-official-accounts/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 125,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'WechatOfficialAccountsTable' },
            ],
        },

        WechatOfficialAccountsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    // return `<<inAll>> ${total} <<tjl>>`;
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'appId',
                },
                {
                    property: 'subsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'authScope',
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath: '/WEB-API/admin/wechat_official_accounts/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath: '/WEB-API/admin/wechat_official_accounts/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/wechat-official-accounts/edit/{{row.id}}',
                    },
                    {
                        text: '<<gzyd>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/wechat-official-accounts/config/{{row.id}}',
                    },
                    {
                        text: '<<gzyl>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/wechat-official-accounts/follow-gift/{{row.appId}}',
                    },
                ],
            },
        },
        WechatOfficialAccountsAddPage: {
            entity: 'WechatOfficialAccountsEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'WechatOfficialAccountsAddForm',
                },
            ],
        },
        WechatOfficialAccountsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'appId' },
                { property: 'appSecret' },
                { property: 'name' },
                { property: 'subsites' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: () => {
                return true;
            },
        },
        WechatOfficialAccountsEditPage: {
            component: 'FlexLayout',
            entity: 'WechatOfficialAccountsEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'WechatOfficialAccountsEditForm',
                },
            ],
        },
        WechatOfficialAccountsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'appId' },
                { property: 'appSecret' },
                { property: 'name' },
                { property: 'subsites' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            onValidate: () => {
                return true;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        WechatOfficialAccountsConfigPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'WechatOfficialAccountConfigEntity',
            items: [
                { component: 'WechatOfficialAccountConfigPreview' },
                { component: 'WechatOfficialAccountConfigFormView' },
            ],
            style: {
                paddingTop: 48,
            },
        },
        WechatOfficialAccountsAuthPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'WechatOfficialAccountsAuthListPage',
                },
            ],
        },
        WechatOfficialAccountsFollowGiftPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'WechatOfficialAccountsFollowGiftForm' }],
        },
        WechatOfficialAccountsFollowGiftForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'WechatOfficialAccountGiftEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: true,
            fields: [
                {
                    property: 'baseInfo.status',
                },
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'rewardConfig.rewards',
                },
                {
                    property: 'rewardConfig.followAgainHasGift',
                },
                {
                    property: 'rewardConfig.registerReward',
                },
                // {
                //     property: 'rewardConfig.rule',
                // },
                {
                    property: 'rewardConfig.executionResult',
                },
                {
                    property: 'rewardConfig.autoReply',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const { rewardConfig } = entity;
                const rewards = rewardConfig.rewards;
                if (!rewards) {
                    AntMessage.warn(services.language.getText('qwztxgzhzsnr'));
                    return false;
                }
                const { assetRewards, couponRewards } = rewards;
                if (!couponRewards?.length && !assetRewards?.length) {
                    AntMessage.warn(services.language.getText('qwztxgzhzsnr'));
                    return false;
                }
                if (couponRewards) {
                    if (!couponRewards.length) {
                        AntMessage.warn(services.language.getText('qxzyhq'));
                        return false;
                    }
                    if (couponRewards.some((coupon: any) => !coupon.quantity)) {
                        AntMessage.warn(services.language.getText('qszyhqffsl'));
                        return false;
                    }
                }
                if (assetRewards.some((rewards: any) => !rewards.rewardValue)) {
                    AntMessage.warn(services.language.getText('qwztxgzhzsnr'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({
                        route: 'goBack',
                    })
                );
            },
        },

        WechatOfficialAccountsLog: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/wechat_official_accounts/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-official-accounts',
                    breadcrumbName: '<<wechatOfficialAccountManger>>',
                    component: 'WechatOfficialAccountsView',
                    privilege: {
                        path: 'wechatOfficialAccountManger',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'WechatOfficialAccountsAddPage',
                            breadcrumbName: '<<xzgzh>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'WechatOfficialAccountsEditPage',
                            breadcrumbName: '<<bjgzh>>',
                        },
                        {
                            path: '/config/:id',
                            component: 'WechatOfficialAccountsConfigPage',
                            breadcrumbName: '<<gzgzhpz>>',
                        },
                        {
                            path: '/auth',
                            component: 'WechatOfficialAccountsAuthPage',
                            breadcrumbName: '<<cksqqx>>',
                        },
                        {
                            path: '/follow-gift/:appId',
                            component: 'WechatOfficialAccountsFollowGiftPage',
                            breadcrumbName: '<<gzyl>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'WechatOfficialAccountsLog',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'WechatOfficialAccountsPage' },
                    ],
                },
            ],
        },
    ],
};

if (ENV.TENANT !== 'shangguan' && config.components) {
    config.components.WechatOfficialAccountsTable.actionColumn.items.push({
        type: 'component',
        component: 'WechatOfficialAccountsEmpowerButton',
    });
}
