import React from 'react';
import {
    actions,
    builder,
    ComponentsManager,
    Loader,
    services,
} from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Component, createElement } from 'react';
import { ExportFilterType } from '../export-data-button-by-filter';
import { map, uniqueId } from 'lodash';
import {
    message as AntdMessage,
    Button,
    Col,
    Popover as AntdPopover,
    Row,
    DatePicker,
    message,
} from 'antd';
import { ForwardDataCenterModal } from '../../services';
import { ErrorInfo, errorHandle } from '../../applications/cae/services/error-handle';

interface Props {
    entity: Entity;
}
interface State {
    selectedRowKeys: string[];
    times: string[];
    exportDatePickerVisible: boolean;
    loadings: Record<string, boolean>;
}
export class WechatOfficialParametricQrcodeDistributionChannelTable extends Component<
    Props,
    State
> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        this.state = {
            selectedRowKeys: [],
            times: [],
            exportDatePickerVisible: false,
            loadings: {},
        };
    }

    dispatch: any = {};

    private tableId = uniqueId('WechatOfficialParametricQrcodeDistributionChannelTable-');
    private onCreate() {
        services.behaviorHandle({
            route: '/wechat-official-parametric-qrcode/add-distribution-channel',
        });
    }

    private createClickHandle = (key: string, handle: Function) => {
        return async (...args: any[]) => {
            try {
                this.setState({ loadings: { [key]: true } });
                await this.checkSubsitePermission();
                handle.call(this, ...args);
            } catch (error) {
                message.warn((error as Error).message);
            } finally {
                this.setState({ loadings: {} });
            }
        };
    };

    private checkSubsitePermission() {
        return Loader.load('get', {
            apiPath: '/loader/source/admin/v1/subsites/mine',
            params: {},
        }).then((subsites?: any[]) => {
            if (subsites?.length) {
                return Promise.resolve();
            }
            return Promise.reject(
                new Error(
                    services.language.getText('wzdrhmdqx') +
                        ',' +
                        services.language.getText('wfjxccz')
                )
            );
        });
    }

    private onBatchExportVisibleChange = (visible: boolean) => {
        this.setState({ exportDatePickerVisible: visible });
    };
    private onBatchDownloadQrcode = () => {
        const { selectedRowKeys } = this.state;
        if (!selectedRowKeys.length) {
            AntdMessage.warn(services.language.getText('qxxzygewm'));
            return;
        }
        return services.api
            .post(
                {
                    ids: selectedRowKeys.toString(),
                    dimensionTypes: ['CHANNEL'],
                },
                {
                    apiPath: '/admin/wechat_official_account_qrcode_rules/export_qrcode',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                }
            )
            .then(() => {
                ForwardDataCenterModal();
            });
    };

    private batchExportData = () => {
        const { times, selectedRowKeys } = this.state;
        const [startTime, endTime] = times;
        return services.api.post(
            {
                startTime,
                endTime,
                ids: selectedRowKeys.toString(),
                dimensionTypes: ['CHANNEL'],
            },
            {
                apiPath: '/admin/wechat_official_account_qrcode_rules/export',
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
            }
        );
    };

    private onDatePickerChange = (date: unknown, dateString: string[]) => {
        this.setState({ times: dateString });
    };

    private onTimePickerConfirm = async () => {
        try {
            await this.batchExportData();
            this.setState({
                exportDatePickerVisible: false,
            });
            ForwardDataCenterModal();
        } catch (error) {
            errorHandle(error as ErrorInfo);
        }
    };

    private onTableChange = (newSelectedRowKeys: string[]) => {
        const { selectedRowKeys } = this.state;
        setTimeout(() => {
            this.dispatch(
                actions.tableRowSelectionChangeAction(
                    this.tableId,
                    map(selectedRowKeys, (value) => {
                        return value;
                    })
                )
            );
        }, 300);
    };

    private onTableRowSelectAll = (
        selected: boolean,
        selectedRows: any[],
        unSelectedRows: any[]
    ) => {
        const { selectedRowKeys } = this.state;
        if (selected) {
            const addRows = selectedRows.filter((item: any) => {
                return !selectedRowKeys.includes(item.id);
            });
            const addRowsRowIds = map(addRows, 'id');
            this.setState({ selectedRowKeys: [...selectedRowKeys, ...addRowsRowIds] });
        } else {
            const deleteIds: any = map(unSelectedRows, 'id');
            const deleteRows = selectedRowKeys.filter((item: any) => {
                return !deleteIds.includes(item);
            });
            this.setState({ selectedRowKeys: [...deleteRows] });
        }
    };

    private onTableSelect = (record: any, selected: boolean) => {
        const { selectedRowKeys } = this.state;
        if (selected) {
            this.setState({
                selectedRowKeys: [...selectedRowKeys, record.id],
            });
        } else {
            this.setState({
                selectedRowKeys: selectedRowKeys.filter((key) => key !== record.id),
            });
        }
    };
    private get timePopoverContent() {
        return (
            <div>
                <Row gutter={[1, 24]}>
                    <Col> {services.language.getText('qxzsjsjd')}:</Col>
                    <Col>
                        <DatePicker.RangePicker
                            showTime
                            size="small"
                            onChange={this.onDatePickerChange}
                            format="YYYY-MM-DD HH:mm:ss"
                        />
                    </Col>
                </Row>
                <Row gutter={[2, 24]} justify="end" type="flex">
                    <Col>
                        <Button type="primary" onClick={this.onTimePickerConfirm}>
                            {services.language.getText('dc')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
    render() {
        const { entity } = this.props;
        const { selectedRowKeys, exportDatePickerVisible, loadings } = this.state;
        return (
            <div>
                <Row style={{ marginBottom: 16, display: 'flex' }}>
                    <Col style={{ marginRight: '20px' }}>
                        <Button
                            type="primary"
                            onClick={this.createClickHandle('create', this.onCreate)}
                            loading={loadings['create']}
                        >
                            {services.language.getText('xzgzhewm')}
                        </Button>
                    </Col>
                    <Col style={{ marginRight: '20px' }}>
                        <Button
                            type="default"
                            onClick={this.createClickHandle('download', this.onBatchDownloadQrcode)}
                            loading={loadings['download']}
                        >
                            {services.language.getText('plxzewm')}
                        </Button>
                    </Col>
                    <Col style={{ marginRight: '20px' }}>
                        <AntdPopover
                            placement="bottomRight"
                            visible={exportDatePickerVisible}
                            content={this.timePopoverContent}
                            onVisibleChange={this.createClickHandle(
                                'export',
                                this.onBatchExportVisibleChange
                            )}
                            trigger="click"
                        >
                            <Button loading={loadings['export']} type="default">
                                {services.language.getText('pldcsj')}
                            </Button>
                        </AntdPopover>
                    </Col>
                </Row>
                {createElement(ComponentsManager.get('DataTable'), {
                    entity,
                    componentId: this.tableId,
                    loadFirstPage: true,
                    size: 'middle',
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: 2100,
                        y: 400,
                    },
                    rowKey: 'id',
                    rowSelection: {
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: this.onTableChange,
                        onSelect: this.onTableSelect,
                        onSelectAll: (
                            selected: boolean,
                            selectedRows: any[],
                            unSelectedRows: any[]
                        ) => {
                            this.onTableRowSelectAll(selected, selectedRows, unSelectedRows);
                        },
                    },
                    columns: [
                        { property: 'wechatOfficialAccountName' },
                        {
                            property: 'name',
                            displayConfig: {
                                className: 'ant-table-cell-multi-ellipsis',
                                style: {
                                    '-webkit-line-clamp': '2',
                                },
                            },
                        },
                        { property: 'permanentDesc' },
                        { property: 'totalScanCount', width: 100 },
                        { property: 'totalScanUserCount', width: 100 },
                        { property: 'totalFollowCount', width: 120 },
                        { property: 'openMiniProgramPercentage' },
                        { property: 'replyEffectTime' },
                        { property: 'replyStartTime' },
                        { property: 'replyEndTime' },
                        { property: 'replyStatus' },
                        { property: 'createTime' },
                    ],
                    actionColumn: {
                        title: services.language.getText('common.tableAction'),
                        className: 'action-column',
                        width: 210,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    type: 'link',
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    style: { display: 'inline-flex' },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                                    handles: [
                                        {
                                            value: false,
                                            apiPath:
                                                '/admin/wechat_official_account_qrcode_rules/:id/enabled',
                                            config: {
                                                content:
                                                    '{{row.name}}' +
                                                    services.language.getText('areadyOpend'),
                                                text: services.language.getText('common.opend'),
                                            },
                                            confirm: {
                                                text:
                                                    services.language.getText('isAreadyOpend') +
                                                    '{{row.name}}?',
                                            },
                                        },
                                        {
                                            value: true,
                                            apiPath:
                                                '/admin/wechat_official_account_qrcode_rules/:id/disabled',
                                            config: {
                                                content:
                                                    '{{row.name}}' +
                                                    services.language.getText('areadyClosed'),
                                                text: services.language.getText('common.close'),
                                            },
                                            confirm: {
                                                text:
                                                    services.language.getText('isAreadyClose') +
                                                    '{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'component',
                                component: 'ExportDataButton',
                                params: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                                    apiPath:
                                        '/admin/wechat_official_account_qrcode_rules/{{row.id}}/export_qrcode',
                                    text: services.language.getText('downLoad'),
                                },
                            },
                            {
                                type: 'link',
                                text: services.language.getText('common.edit'),
                                config: {
                                    style: { marginLeft: 12 },
                                },
                                path:
                                    '/wechat-official-parametric-qrcode/edit-distribution-channel/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ExportDataButtonByFilter',
                                params: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                                    apiPath: '/admin/wechat_official_account_qrcode_rules/export',
                                    text: services.language.getText('reportData'),
                                    filters: [ExportFilterType.TIME],
                                    isAsync: true,
                                    getExtraParams(row: any) {
                                        return {
                                            ids: row.id,
                                        };
                                    },
                                },
                            },
                        ],
                    },
                })}
            </div>
        );
    }
}
