import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const { api } = services;

export const themeStyleLoader = {
    get: function(data: any, config: ApiRequestConfig) {
        config.apiPath = '/admin/theme_style';
        return api.get(data, config).then((res: any) => {
            if (res.id && res.config) {
                const config = (res.config && JSON.parse(res.config)) || {};
                const topic = config?.navigation?.hideTitlePages.includes('subpackages/topic/index')
                    ? '1'
                    : '0';
                const merchant = config?.navigation?.hideTitlePages.includes(
                    'subpackages/merchant/index'
                )
                    ? '1'
                    : '0';
                res = {
                    id: res.id,
                    themeStyle: {
                        color: {
                            primary: config?.color?.primary || '#007046',
                            secondary: config?.color?.secondary || '#ab8e66',
                        },
                        styleMode: String(config?.styleMode) || '0',
                        button: {
                            colorType: config?.button?.colorType || 'pure',
                            from: config?.button?.gradient?.from || '#007046',
                            to: config?.button?.gradient?.to || '#ab8e66',
                            direction: config?.button?.gradient?.direction || 'vertical',
                        },
                        backgroundImage: config?.pageBackground?.backgroundImage || '',
                    },
                    navigation: {
                        style: {
                            backgroundColor:
                                config?.navigation?.style?.backgroundColor || '#ffffff',
                        },
                    },
                    advancedSetting: {
                        hideTitlePages: {
                            topic: topic,
                            merchant: merchant,
                        },
                    },
                };
            } else {
                res = {
                    id: '',
                    themeStyle: {
                        color: {
                            primary: '#007046',
                            secondary: '#ab8e66',
                        },
                        styleMode: '0',
                        button: {
                            colorType: 'pure',
                            from: '#007046',
                            to: '#ab8e66',
                            direction: 'vertical',
                        },
                        backgroundImage: '',
                    },
                    navigation: {
                        style: {
                            backgroundColor: '#ffffff',
                        },
                    },
                    advancedSetting: {
                        hideTitlePages: {
                            topic: '1',
                            merchant: '1',
                        },
                    },
                };
            }

            return res;
        });
    },
    put: function(data: any, config: ApiRequestConfig) {
        config.apiPath = '/admin/theme_style';
        let hideTitlePages = [];
        if (data?.advancedSetting?.hideTitlePages?.topic === '1') {
            hideTitlePages.push('subpackages/topic/index');
        }
        if (data?.advancedSetting?.hideTitlePages?.merchant === '1') {
            hideTitlePages.push('subpackages/merchant/index');
        }
        const datalist: any = {
            config: JSON.stringify({
                color: {
                    primary: data?.themeStyle?.color?.primary || '#007046',
                    secondary: data?.themeStyle?.color?.secondary || '#ab8e66',
                },
                styleMode: Number(data?.themeStyle?.styleMode) || 0,
                button: {
                    colorType: data?.themeStyle?.button?.colorType || 'pure',
                    gradient: {
                        from: data?.themeStyle?.button?.from || '#007046',
                        to: data?.themeStyle?.button?.to || '#ab8e66',
                        direction: data?.themeStyle?.button?.direction || 'horizontal',
                    },
                },
                pageBackground: {
                    backgroundImage:
                        data?.themeStyle?.styleMode === '1'
                            ? data?.themeStyle?.backgroundImage
                            : '',
                },
                navigation: {
                    style: {
                        backgroundColor: data?.navigation?.style?.backgroundColor || '#ffffff',
                    },
                    hideTitlePages: hideTitlePages,
                },
            }),
        };
        if (data.id) {
            datalist.id = data.id;
        }

        return api.post(datalist, config);
    },
};
