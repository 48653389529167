import * as React from 'react';
import classNames from 'classnames';

import { ObjectMode } from '@comall-backend-builder/types';
import { PointDeduction } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export class ObjectPointDeductionMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (object) {
            return (
                <div
                    className={classNames('type-object mode-object-point-deduction', className)}
                    style={style}
                >
                    <div>
                        <span>
                            {language.getText('zxdkdw')} {object.point} {language.getText('point')}
                        </span>
                        <span>
                            {object.point} {language.getText('point')} = {object.deductionAmount}{' '}
                            {language.getText('ytcf')}
                        </span>
                    </div>
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PointDeduction {...controlInfo} />;
    }
}
