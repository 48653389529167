import { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

/**
 * 打印设置配置表单
 */
class PrintConfigForm extends PureComponent<any, any> {
    render() {
        const { entity } = this.props;
        return createElement(ComponentsManager.get('printSettingEditForm'), {
            ...this.props,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'));
                entity.get();
            },
        });
    }
}

const mapStateToProps = (_state: any, props: any) => ({
    fields: props.entity.fields,
});

export const PrintConfigFormView = connect(mapStateToProps)(PrintConfigForm);
