//@ts-nocheck
import React, { Component } from 'react';
import { Table } from 'antd';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface goodsInfoProps {
    data: Array<any>;
}

export class GoodsInfo extends Component<goodsInfoProps, any> {
    state = {
        indeterminate: false,
        selectedRowKeys: [],
        checkAll: false,
    };
    getTableConfig = () => {
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                },
                {
                    title: getText('mz_1'),
                    dataIndex: 'marketPrice',
                    key: 'marketPrice',
                    width: 120,
                },
                {
                    title: getText('number'),
                    dataIndex: 'quantity',
                    key: 'quantity',
                    width: 120,
                },
                {
                    title: getText('dj_1'),
                    dataIndex: 'originalPrice',
                    key: 'originalPrice',
                    width: 120,
                },
                {
                    title: getText('zxj'),
                    dataIndex: 'price',
                    key: 'price',
                    width: 120,
                },
                {
                    title: getText('zje'),
                    key: 'totalAmount',
                    dataIndex: 'totalAmount',
                    width: 120,
                },
                {
                    title: getText('cjje_1'),
                    key: 'dealPrice',
                    dataIndex: 'dealPrice',
                    width: 120,
                },
            ],
        };
        return config;
    };

    render() {
        let { data } = this.props;
        const goods = !data || data?.length === 0 ? [] : data;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: goods };
        return <Table {...tableProps} pagination={false}></Table>;
    }
}
