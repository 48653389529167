import React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { MaotaiPresalePrizeConditions } from '../../../containers';

export class MaotaiPresalePrizeConditionsMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MaotaiPresalePrizeConditions {...controlInfo} />;
    }
}
