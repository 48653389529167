import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    components: {
        PayResultPageView: {
            component: 'Viewport',
            entity: 'PayResultPage',
        },
        PayResultPageDesign: {
            component: 'Card',
            content: {
                component: 'DesignMemberCenter',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/pages/:type/:id',
                type: 'PAY_RESULT_PAGE',
            },
        },
        PayResultPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'FilterForm',
                            direction: 'inline',
                            submit: {
                                text: '<<common.search>>',
                                style: {
                                    marginRight: 16,
                                },
                            },
                            reset: {
                                text: '<<common.reset>>',
                            },
                            fields: [
                                {
                                    property: 'subsiteIds',
                                },
                            ],
                        },
                    ],
                },

                {
                    component: 'PayResultPageTable',
                    style: {
                        marginTop: 17,
                    },
                },
            ],
        },
        PayResultPageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: false,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'enabled',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: true,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'disable',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        config: {
                            url: `/design-editor/?type=PAY_RESULT_PAGE&id={{row.id}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/pay-result-page/log/{{row.id}}/DESIGN_PAGE',
                    },
                ],
            },
        },
        PayResultLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/pay-result-page',
                    component: 'PayResultPageView',
                    breadcrumbName: '<<payResultPage>>',
                    privilege: {
                        path: 'payResultPage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'PayResultPageDesign',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'PayResultLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'PayResultPage',
                        },
                    ],
                },
            ],
        },
    ],
};
