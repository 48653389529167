import React, { PureComponent } from 'react';
import { Form, Radio, Slider } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import RadioGroup from 'antd/lib/radio/group';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
/**
 * 王府井首页门店信息组件-配置表单
 */
class wfjIndexSubsite extends PureComponent<any> {
    render() {
        const { form, sourceRoot, uploadAction } = this.props;

        const { getFieldDecorator, getFieldValue } = form;
        const formItemLayout = tools.FormItemLayout;

        return (
            <Form className="editor-member-card">
                <FormItem
                    {...formItemLayout}
                    label={'展示样式'}
                    style={{ display: ENV.TENANT === 'wfj' ? 'block' : 'none' }}
                >
                    {getFieldDecorator('mode')(
                        <RadioGroup className="fill-style">
                            <Radio value="1">样式一</Radio>
                            <Radio value="2">样式二</Radio>
                            <Radio value="3">样式三</Radio>
                        </RadioGroup>
                    )}
                </FormItem>
                {getFieldValue('mode') === '1' && (
                    <FormItem {...formItemLayout} label={'图片'}>
                        {getFieldDecorator('subsiteImage')(
                            <UploadPicture
                                className="image-uploader"
                                sourceRoot={sourceRoot}
                                uploadAction={uploadAction}
                            />
                        )}
                        <p className="upload-picture-tips">图片建议宽度为120px，高度为230px</p>
                    </FormItem>
                )}
                {getFieldValue('mode') === '2' && (
                    <div>
                        <FormItem {...formItemLayout} label={'文字大小'}>
                            {getFieldDecorator('fontSize')(
                                <Slider defaultValue={28} min={20} max={36} step={2} />
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label={'营业时间'}>
                            {getFieldDecorator('showBusinessHours', {
                                initialValue: 'hide',
                            })(
                                <RadioGroup className="">
                                    <Radio value="show">
                                        {services.language.getText('display')}
                                    </Radio>
                                    <Radio value="hide">
                                        {services.language.getText('hidden')}
                                    </Radio>
                                </RadioGroup>
                            )}
                        </FormItem>
                    </div>
                )}
                {getFieldValue('mode') === '3' && (
                    <FormItem {...formItemLayout} label={'文字大小'}>
                        {getFieldDecorator('fontSize')(
                            <Slider defaultValue={28} min={20} max={36} step={2} />
                        )}
                    </FormItem>
                )}
            </Form>
        );
    }
}

export const WfjIndexSubsite = tools.ConfigFormDecorator(wfjIndexSubsite);
