import { Config } from '@comall-backend-builder/core/lib/parser';
import get from 'lodash/get';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        OfflineOrderCommentsEntity: {
            apiPath: '/loader/admin/offline-order-comments',
            filters: {
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },
                commentLevel: {
                    type: 'string.options.select',
                    displayName: '<<myd>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'PRAISE', name: '<<hp_1>>' },
                        { id: 'AVERAGE', name: '<<average>>' },
                        { id: 'POOR', name: '<<jcp>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: '200px',
                        },
                    },
                },
                receiptNo: {
                    type: 'string',
                    displayName: '<<xpbh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                hasBusinessReply: {
                    type: 'string.options.select',
                    displayName: '<<sfhf>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: '200px',
                        },
                    },
                },
                hasContent: {
                    type: 'string.options.select',
                    displayName: '<<pjnr>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'true', name: '<<have>>' },
                        { id: 'false', name: '<<indoorMapProviderNone>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: '200px',
                        },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xzrq_1>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                commentTime: {
                    type: 'string',
                    displayName: '<<pjsj>>',
                },
                commentItemScore: {
                    type: 'string',
                    displayName: '<<totalScore>>',
                },
                commentItemContent: {
                    type: 'string.tip',
                    displayName: '<<pjnr>>',
                },
                receiptNo: {
                    type: 'string',
                    displayName: '<<xpbh>>',
                },
                businessReplyTime: {
                    type: 'string',
                    displayName: '<<hfsj>>',
                },
                businessReplyContent: {
                    type: 'string.popover',
                    displayName: '<<hfnr>>',
                },
            },
        },
        ReplyCommonWordsConfigsEntity: {
            apiPath: '/loader/admin/reply-common-words-configs',
            filters: {
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },
                content: {
                    type: 'string',
                    displayName: '<<hfnr>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
            },
            properties: {
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    defaultValue: [],
                    rules: [{ required: true, message: '<<selectSubsite>>' }],
                },
                content: {
                    type: 'string.text.paragraph',
                    displayName: '<<hfnr>>',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            max: 500,
                            message: '<<jqsrhfnrzfyn>>',
                        },
                    ],
                    controlConfig: {
                        rows: 4,
                        style: { width: '300px', height: '400px' },
                        placeholder: '<<jqsrhsnrzcz>>',
                    },
                },
                sequence: {
                    displayName: '<<sequence>>',
                    type: 'number.integer',
                    rules: [{ required: true, message: '<<sequenceRequired>>' }],
                    controlConfig: {
                        placeholder: '<<jqsrszzydpxykq>>',
                        max: 999999,
                        min: 0,
                        style: { width: '300px' },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                listSubsites: {
                    type: 'array.popover',
                    displayName: '<<subsites>>',
                },
            },
        },
    },
    components: {
        OfflineOrderCommentsView: {
            component: 'Viewport',
        },
        ReplyCommonWordsConfigsView: {
            component: 'Viewport',
        },
        OfflineOrderCommentsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'OfflineOrderCommentsEntity',
            items: [
                {
                    component: 'OfflineOrderCommentsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        flexDirection: 'row',
                    },
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
                                apiPath: '/admin/comments/export',
                                type: 'offline-order-comments',
                                requestMethod: 'get',
                                target: '_blank',
                            },
                        },
                        {
                            component: 'Button',
                            text: '<<kjhfsz>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/offline-order-comments/reply-common-words-configs',
                            style: {
                                marginLeft: '10px',
                                width: '140px',
                                flex: 'none',
                            },
                        },
                    ],
                },
                { component: 'OfflineOrderCommentsTable' },
            ],
        },
        OfflineOrderCommentsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsite',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'commentLevel',
                },
                {
                    property: 'receiptNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'hasBusinessReply',
                },
                {
                    property: 'hasContent',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        OfflineOrderCommentsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'OfflineOrderCommentsDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'nickName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'commentTime',
                        },
                        {
                            property: 'commentItemScore',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'commentItemContent',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'receiptNo',
                        },
                        {
                            property: 'businessReplyTime',
                        },
                        {
                            property: 'businessReplyContent',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 120,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'OfflineOrderCommentsReply',
                            },
                            {
                                type: 'link',
                                text: '<<common.view>>',
                                path: '/offline-order-comments/info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        OfflineOrderCommentsInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'OfflineOrderCommentsEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'OfflineOrderCommentsInfo',
                },
            ],
        },
        ReplyCommonWordsConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsite',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'content',
                },
            ],
        },
        ReplyCommonWordsConfigsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ReplyCommonWordsConfigsTable',
                    loadFirstPage: true,
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'listSubsites',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                            },
                        },
                        {
                            property: 'content',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '400px',
                                },
                            },
                        },
                        {
                            property: 'sequence',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 120,
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path:
                                    '/offline-order-comments/reply-common-words-configs/edit/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        ReplyCommonWordsConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ReplyCommonWordsConfigsEntity',
            items: [
                {
                    component: 'ReplyCommonWordsConfigsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<cjhfy>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/offline-order-comments/reply-common-words-configs/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ReplyCommonWordsConfigsTable' },
            ],
        },
        ReplyCommonWordsConfigsAddPage: {
            entity: 'ReplyCommonWordsConfigsEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'ReplyCommonWordsConfigsAddForm',
                },
            ],
        },
        ReplyCommonWordsConfigsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [{ property: 'subsites' }, { property: 'content' }, { property: 'sequence' }],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const subsites = get(entityFields, 'subsites', null);
                if (!subsites || !subsites.length) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                return true;
            },
        },
        ReplyCommonWordsConfigsEditPage: {
            component: 'FlexLayout',
            entity: 'ReplyCommonWordsConfigsEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'ReplyCommonWordsConfigsEditForm',
                },
            ],
        },
        ReplyCommonWordsConfigsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [{ property: 'subsites' }, { property: 'content' }, { property: 'sequence' }],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            onValidate: (entityFields: any) => {
                const subsites = get(entityFields, 'subsites', null);
                if (!subsites || !subsites.length) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                return true;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/offline-order-comments',
                    component: 'OfflineOrderCommentsView',
                    breadcrumbName: '<<offlineOrderComments>>',
                    privilege: {
                        path: 'posReceiptOrderComment',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'OfflineOrderCommentsInfoPage',
                            breadcrumbName: '<<details>>',
                        },
                        {
                            path: '/reply-common-words-configs',
                            component: 'ReplyCommonWordsConfigsView',
                            breadcrumbName: '<<kjhfsz>>',
                            childRoutes: [
                                {
                                    path: '/add',
                                    component: 'ReplyCommonWordsConfigsAddPage',
                                    breadcrumbName: '<<cjhf>>',
                                },
                                {
                                    path: '/edit/:id',
                                    component: 'ReplyCommonWordsConfigsEditPage',
                                    breadcrumbName: '<<bjhf>>',
                                },
                                { path: '/', component: 'ReplyCommonWordsConfigsPage' },
                            ],
                        },
                        { path: '/', component: 'OfflineOrderCommentsPage' },
                    ],
                },
            ],
        },
    ],
};
