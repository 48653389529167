import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { SalesPriceAndCouponPackagePrices } from './sales-price-and-coupon-package-prices';

export class SalesPriceAndCouponPackagePricesMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SalesPriceAndCouponPackagePrices {...controlInfo}/>
    }
}
