import React, { Component } from 'react';
import { Popover, Radio, Icon } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { findIndex } from 'lodash';

export class StringRadioPopoverMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringRadioPopover {...displayInfo} />;
    }
}

class StringRadioPopover extends Component<any, any> {
    render() {
        const {
            value,
            className,
            style,
            disabled,
            options,
            onChange,
            popoverConfigs,
            tipConfigs,
        } = this.props;

        const radioGroupProps = {
            value,
            className,
            style,
            disabled,
            onChange,
        };
        const checkedIndex = findIndex(options, { id: value });
        const tipConfig = tipConfigs[checkedIndex];
        return (
            <div>
                <Radio.Group {...radioGroupProps}>
                    {options.map((option: any, index: number) => {
                        const popoverConfig = popoverConfigs[index];

                        return (
                            <Radio
                                key={option.id}
                                value={option.id}
                                disabled={option.disabled ? option.disabled : false}
                            >
                                {option.name}
                                {popoverConfig && (
                                    <Popover
                                        autoAdjustOverflow
                                        placement={
                                            popoverConfig.placement
                                                ? popoverConfig.placement
                                                : 'bottom'
                                        }
                                        content={
                                            <div style={{ maxWidth: 400 }}>
                                                <img
                                                    src={popoverConfig.imageUrl}
                                                    width="400px"
                                                    alt=""
                                                />
                                            </div>
                                        }
                                    >
                                        {popoverConfig.showIcon && (
                                            <Icon
                                                className="tips-modal-icon"
                                                style={{ marginLeft: '5px' }}
                                                type={
                                                    popoverConfig.iconType
                                                        ? popoverConfig.iconType
                                                        : 'question-circle'
                                                }
                                            />
                                        )}
                                        {!!popoverConfig.text && (
                                            <span className="tips-modal-text">
                                                {popoverConfig.text}
                                            </span>
                                        )}
                                    </Popover>
                                )}
                            </Radio>
                        );
                    })}
                </Radio.Group>
                {tipConfig && <div className="ant-form-extra">{tipConfig}</div>}
            </div>
        );
    }
}
