import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { TipsModalContentType } from '../../../../components';
import get from 'lodash/get';
import { message as AntMessage } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

export enum CarparkCannels {
    wechat = 'WECHAT_MINI_PROGRAM',
    alipay = 'ALIPAY_MINI_PROGRAM',
}

const formValidate = (entity: any) => {
    const channelsSet = new Set(get(entity, 'channels', []));
    if (channelsSet.has(CarparkCannels.alipay)) {
        if (!entity.name) {
            AntMessage.error(services.language.getText('qsrccmc'));
            return false;
        }
        if (!entity.address) {
            AntMessage.error(language.getText('qsrccjtdz'));
            return false;
        }
        if (!entity.mobile) {
            AntMessage.error(language.getText('qsrcckfdh'));
            return false;
        }
        if (!entity.mchntId) {
            AntMessage.error(language.getText('qsrccskf'));
            return false;
        }
        if (!entity.amapPoiId) {
            AntMessage.error(language.getText('qsrcc'));
            return false;
        }
    }

    return true;
};

export const config: Config = {
    entities: {
        carparkConfig: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
            apiPath: '/loader/admin/carparks',
            paramsFilter: ['OLD_FRONT_CLIENT'],
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                subsite: {
                    type: 'object.option.select',
                    displayName: '<<sxmd>>',
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
                        apiPath: '/admin/carparks/subsites',
                    },
                    rules: [{ required: true }],
                },
                carparkExplain: {
                    type: 'string.text.paragraph',
                    displayName: '<<jfsm>>',
                },
                carousels: {
                    type: 'array.carparkImageAds',
                    displayName: '<<tpgg>>',
                },
                showRecord: {
                    type: 'boolean.switch',
                    displayName: '<<carparkOrder>>',
                    rules: [{ required: true }],
                    defaultValue: true,
                    controlConfig: {
                        checkedChildren: '<<show>>',
                        unCheckedChildren: '<<hidden>>',
                    },
                },
                carparkSystemName: {
                    type: 'string',
                    displayName: '<<ccxt>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                channels: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<jfqd_1>>',
                    defaultValue: [CarparkCannels.wechat],
                    options: [
                        {
                            name: '<<wx>>',
                            id: CarparkCannels.wechat,
                            disabled: true,
                        },
                        {
                            name: '<<zfb>>',
                            id: CarparkCannels.alipay,
                        },
                    ] as any,
                    rules: [{ required: true }],
                    extra: '<<jfqdzgtcpzsyywx>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<ccmc>>',
                    extra: '<<jlygddtsdmcyz>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                address: {
                    type: 'string',
                    displayName: '<<ccjtdz>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<cckfdh>>',
                    rules: [
                        {
                            whitespace: true,
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                amapPoiId: {
                    type: 'string.modalTips',
                    displayName: '<<amapPoiId>>',
                    controlConfig: {
                        tipsModalConfig: {
                            contentType: TipsModalContentType.CarparkAmapPoiId,
                            showIcon: true,
                            iconStyle: {
                                color: '#1890ff',
                            },
                            text: '<<rhcx>>',
                            textColor: '#1890ff',
                            title: '<<amapPoiIdTitle>>',
                        },
                        style: {
                            width: 300,
                        },
                    },
                    extra: '<<zysjqjstxjxg>>',
                },
                mchntId: {
                    type: 'string.questionTips',
                    displayName: '<<ccskf>>',
                    controlConfig: {
                        questionTipsConfig: {
                            content: '<<mchntIdContent>>',
                            iconStyle: {
                                color: '#1890ff',
                            },
                            placement: 'top',
                        },
                        style: {
                            width: 300,
                        },
                    },
                },
                parkingId: {
                    type: 'string',
                    displayName: '<<parkingID>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            },
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<jfqd_1>>',
                    options: [
                        {
                            name: '<<wx>>',
                            id: CarparkCannels.wechat,
                        },
                        {
                            name: '<<zfb>>',
                            id: CarparkCannels.alipay,
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            },
        },
    },
    components: {
        SubsiteCarparkView: {
            component: 'Viewport',
            entity: 'carparkConfig',
        },
        SubsiteCarparkPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SubsiteCarparkHeader',
                },
                {
                    component: 'SubsiteCarparkTable',
                },
            ],
        },
        SubsiteCarparkHeader: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'SubsiteCarparkFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<common.add>>',
                            icon: 'plus',
                            type: 'primary',
                            route: '/subsite-carpark/create',
                            span: 4,
                            style: {
                                marginRight: 24,
                            },
                            className: 'mt24 mb24',
                        },
                    ],
                },
            ],
        },
        SubsiteCarparkFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'channel',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        SubsiteCarparkTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: true,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    width: 240,
                },
                {
                    property: 'carparkExplain',
                },
                {
                    property: 'carparkSystemName',
                },
                {
                    property: 'channels',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 130,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
                            handles: [
                                {
                                    params: { status: 'DISABLE' },
                                    value: 'ENABLE',
                                    apiPath: '/admin/carparks/:id/status/disable',
                                    loadType: 'post',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.subsiteName}}?',
                                    },
                                },
                                {
                                    params: { status: 'ENABLE' },
                                    value: 'DISABLE',
                                    apiPath: '/admin/carparks/:id/status/enable',
                                    loadType: 'post',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.subsiteName}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/subsite-carpark/edit/{{row.id}}',
                    },
                ],
            },
        },
        SubsiteCarparkCreatePage: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'carparkConfig',
            labelCol: 4,
            controlCol: 13,
            fields: [
                {
                    property: 'subsite',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'carousels',
                    width: 200,
                    rules: [{ required: false }],
                },
                {
                    property: 'carparkExplain',
                    label: '<<tcjfsm>>',
                    controlConfig: {
                        placeholder: '<<qsrtccsm>>',
                    },
                },
                {
                    property: 'showRecord',
                },
                {
                    property: 'channels',
                },
                {
                    property: 'name',
                },
                {
                    property: 'address',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'mchntId',
                    visible: (e: any) => new Set(get(e, 'channels', [])).has(CarparkCannels.alipay),
                },
                {
                    property: 'amapPoiId',
                    visible: (e: any) => new Set(get(e, 'channels', [])).has(CarparkCannels.alipay),
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: formValidate,
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteCarparkEditPage: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'carparkConfig',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 13,
            fields: [
                {
                    property: 'subsite',
                    modifiable: false,
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'carousels',
                    width: 200,
                    rules: [{ required: false }],
                },
                {
                    property: 'carparkExplain',
                    label: '<<tcjfsm>>',
                    controlConfig: {
                        placeholder: '<<qsrtccsm>>',
                    },
                },
                {
                    property: 'showRecord',
                },
                {
                    property: 'carparkSystemName',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'channels',
                },
                {
                    property: 'name',
                },
                {
                    property: 'address',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'mchntId',
                    visible: (e: any) => new Set(get(e, 'channels', [])).has(CarparkCannels.alipay),
                },
                {
                    property: 'amapPoiId',
                    visible: (e: any) => new Set(get(e, 'channels', [])).has(CarparkCannels.alipay),
                },
                {
                    property: 'parkingId',
                    visible: (e: any) => new Set(get(e, 'channels', [])).has(CarparkCannels.alipay),
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: formValidate,
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subsite-carpark',
                    component: 'SubsiteCarparkView',
                    breadcrumbName: '<<tcympz>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'SubsiteCarparkCreatePage',
                        },
                        {
                            path: '/edit/:id',
                            component: 'SubsiteCarparkEditPage',
                        },
                        {
                            path: '/',
                            component: 'SubsiteCarparkPage',
                        },
                    ],
                    privilege: {
                        path: 'subsiteCarpark',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
