import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const orderSettingLoader: Loader = {
    get: async function() {
        return await new Promise((resolve, reject) => {
            api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/system_configs/platform',
                }
            ).then((systemConfig: any) => {
                //获取专柜
                api.get(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                        apiPath: '/admin/delivery_config',
                    }
                ).then((deliveryConfig: any) => {
                    const closeOrderManualAudit = [
                        systemConfig.closeOrderManualAudit,
                        systemConfig.memberCloseOrderManualAudit,
                    ];
                    resolve({
                        orderRule: {
                            expireMins: systemConfig.expireMins,
                            offlinePaymentOrderAutoCloseMins:
                                systemConfig?.offlinePaymentOrderAutoCloseMins,
                            timeLimitExpireMins: systemConfig.timeLimitExpireMins,
                            lateAutomaticReceiptNumber: systemConfig.lateAutomaticReceiptNumber,
                            frontOpenDelayDays: systemConfig.frontOpenDelayDays,
                            memberEachTimeDelayDays: systemConfig.memberEachTimeDelayDays,
                            selfMentionOrderAutoDelivery: systemConfig.selfMentionOrderAutoDelivery
                                ? 'true'
                                : 'false',
                            uploadWechat: deliveryConfig.uploadWechat ? 'true' : 'false',
                            beforeReceivedCanRefund: systemConfig.beforeReceivedCanRefund
                                ? 'true'
                                : 'false',
                            quickBillingOrderAllowAfterSales: systemConfig.quickBillingOrderAllowAfterSales
                                ? 'true'
                                : 'false',
                            crossborderOrderAllowAfterSales: systemConfig.crossborderOrderAllowAfterSales
                                ? 'true'
                                : 'false',
                        },
                        deliveryRule: {
                            id: deliveryConfig.id,
                            deliveryType: deliveryConfig.deliveryType,
                            merchants: deliveryConfig.merchants ? deliveryConfig.merchants : [],
                        },
                        afterSaleRule: {
                            afterSaleDays: systemConfig.afterSaleDays,
                            examineCloseOrderShowPointsTips: String(
                                systemConfig.examineCloseOrderShowPointsTips
                            ),
                            examineServiceApplyShowPointsTips: String(
                                systemConfig.examineServiceApplyShowPointsTips
                            ),
                            closeOrderManualAudit: ['Backend', 'NewClient'].filter((_, idx) =>
                                Boolean(closeOrderManualAudit[idx])
                            ),
                        },
                        deliverOrdersRule: {
                            pickingListSubsiteVos: systemConfig?.pickingListSubsiteVos || [],
                        },
                    });
                });
            });
        });
    },
    put: async function(params) {
        const newParams = {
            ...params.orderRule,
            ...params.afterSaleRule,
            closeOrderManualAudit: params.afterSaleRule.closeOrderManualAudit.includes('Backend'),
            memberCloseOrderManualAudit: params.afterSaleRule.closeOrderManualAudit.includes(
                'NewClient'
            ),
            pickingListSubsiteIds: (params.deliverOrdersRule?.pickingListSubsiteVos || [])
                .map((i: any) => i.id)
                .join(','),
        };
        const platformConfigResult = await api.put(newParams, {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/system_configs/platform',
        });

        const deliveryNewParams = {
            deliveryType: params.deliveryRule.deliveryType,
            id: params.deliveryRule.id,
            merchantIds:
                params.deliveryRule.deliveryType === 'DELIVERY_PART_INDEPENDENT'
                    ? params.deliveryRule.merchants.map((merchants: any) => merchants.merchantId)
                    : null,
            uploadWechat: params.orderRule.uploadWechat,
        };
        const deliveryConfigResult = await api.put(deliveryNewParams, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/admin/delivery_config/' + params.deliveryRule.id,
        });
        if (platformConfigResult && deliveryConfigResult) {
            return true;
        }
        return true;
    },
};
