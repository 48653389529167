import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import './index.less';
const api = services.api;
export function formatNumber(number: number | string) {
    if (typeof number !== 'number') {
        return number;
    }
    const numberStr = number + ''; //数字转换为字符
    const x = numberStr.split('.'); //按照小数点分隔
    let x1 = x[0]; //整数部分
    const x2 = x.length > 1 ? '.' + x[1] : ''; //小数部分
    const rgx = /(\d+)(\d{3})/; //正则式定义
    while (rgx.test(x1)) {
        //正则式匹配
        x1 = x1.replace(rgx, '$1,$2'); //正则式替换
    }
    return x1 + x2;
}

interface Props {
    requestStatus?: any;
    filters?: any;
}
interface CountState {
    attentionCount: number | string;
    netIncreaseAttention: number | string;
    newAttention: number | string;
    subtractAttention: number | string;
}

class WechatPublicTotalView extends PureComponent<any> {
    state = {
        attentionCount: '-',
        netIncreaseAttention: '-',
        newAttention: '-',
        subtractAttention: '-',
    };
    getData = async (data: any) => {
        let params: any = {};
        if (get(data, 'wechatPublicId')) {
            params.wechatPublicId = data.wechatPublicId.id;
        }

        if (get(data, 'dateRange')) {
            params.startTime = data.dateRange.start;
            params.endTime = data.dateRange.end;
        }

        if (params.wechatPublicId === '0') {
            delete params.wechatPublicId;
        }

        const res: any = await api.get<CountState>(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/wechat_public_follows/count',
        });
        this.setState({
            attentionCount: get(res, 'attentionCount') || '-',
            netIncreaseAttention: get(res, 'netIncreaseAttention') || '-',
            newAttention: get(res, 'newAttention') || '-',
            subtractAttention: get(res, 'subtractAttention') || '-',
        });
    };

    componentWillReceiveProps(nextProps: Props) {
        const { requestStatus, filters } = nextProps;
        if (requestStatus === 'pending') {
            this.getData(filters);
        }
    }
    render() {
        const {
            attentionCount,
            netIncreaseAttention,
            newAttention,
            subtractAttention,
        } = this.state;
        return (
            <>
                <div className="wechat-public-total">
                    <div className="title">
                        {services.language.getText('gzhgzrs')}{' '}
                        <span>{services.language.getText('gxzrsj')}</span>
                    </div>
                    <div className="items">
                        <div className="item">
                            <span className="item-title">
                                {services.language.getText('xzgzrs')}
                            </span>
                            <span className="count">{formatNumber(newAttention)}</span>
                        </div>
                        <div className="item">
                            <span className="item-title">
                                {services.language.getText('qxgzrs')}
                            </span>
                            <span className="count">{formatNumber(subtractAttention)}</span>
                        </div>
                        <div className="item">
                            <span className="item-title">{services.language.getText('jzrs')}</span>
                            <span className="count">{formatNumber(netIncreaseAttention)}</span>
                        </div>
                        <div className="item">
                            <span className="item-title">{services.language.getText('zgzrs')}</span>
                            <span className="count">{formatNumber(attentionCount)}</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        filters: entity.filters,
        requestStatus: entity.requestStatus,
    };
}
export const WechatPublicTotal = connect(mapStateToProps)(WechatPublicTotalView);
