import React, { PureComponent } from 'react';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';
import { services } from '@comall-backend-builder/core';
const UploadPicture = tools.UploadPicture;
class customCGBRecruitLeaderAdv extends PureComponent<any, any> {
    render() {
        const { form, sourceRoot, uploadAction } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className="cgb-recruit-leader-adv">
                <div className="title">{services.language.getText('bgPic')}：</div>
                <div className="image-wrap">
                    {getFieldDecorator('title.image')(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                </div>

                <div className="notice">
                    <p className="notice-text">目前仅支持上传一张图片</p>
                    <p className="notice-text">尺寸：宽750*高不限</p>
                    <p className="notice-text">格式：jpg、jpeg</p>
                    <p className="notice-text">大小：小于1M</p>
                </div>
            </div>
        );
    }
}

export const CustomCGBRecruitLeaderAdv = tools.ConfigFormDecorator(customCGBRecruitLeaderAdv);
