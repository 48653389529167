import React, { PureComponent, Fragment, createElement } from 'react';
import { api, language } from '@comall-backend-builder/core/lib/services';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Tabs, Button } from 'antd';
let Entity: any = {};
let entity: any = {};
let MultipleEntity: any = {};
let multipleEntity: any = {};

export class MemberBenefitTabs extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;

        Entity = EntitiesManager.get('HiBeiBenefitBaseRuleEntity');
        entity = new Entity({});
        MultipleEntity = EntitiesManager.get('HiBeiBenefitMultipleRuleEntity');
        multipleEntity = new MultipleEntity({});
        this.state = { selectType: 'baseRule', goldAlias: language.getText('jb') };
    }
    dispatch: any = {};
    async componentDidMount() {
        const res: any = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/gold_exchange_rules',
            }
        );
        const { goldAlias } = res;
        this.setState({
            goldAlias,
        });
    }
    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }
    renderFilters = () => {
        const { componentId } = this.props;

        let filterFormPlusProps = {
            entity,
            component: 'FilterFormPlus',
            componentId,
            direction: 'inline',
            submit: {
                text: services.language.getText('common.search'),
            },
            reset: {
                text: services.language.getText('common.reset'),
                style: {
                    marginLeft: 16,
                },
            },
            onSubmit: (e: any, value: any) => {
                entity.pageChange(Object.assign({}, entity.filters, entity.paging, { page: 1 }));
                entity.search();
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('FilterForm'), filterFormPlusProps);
    };
    renderTable = () => {
        const { componentId } = this.props;

        let tableProps = {
            entity,
            component: 'DataTable',
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'activityProductType',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: language.getText('subsites'),
                    },
                },
                {
                    property: 'merchants',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: language.getText('merchant'),
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            STARTED: {
                                text: language.getText('inprogress'),
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: language.getText('end'),
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 320,
                // style: { width: 320 },
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    params: { status: false },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/promotion/:id/status',
                                    config: {
                                        content: '{{row.name}}' + language.getText('beforeClose'),
                                        text: language.getText('gb'),
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: language.getText('isAreadyClose') + '{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    params: { status: true },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/promotion/:id/status',
                                    config: {
                                        content: '{{row.name}}' + language.getText('beforeOpen'),
                                        text: language.getText('kq'),
                                    },
                                    confirm: {
                                        text: language.getText('isAreadyOpend') + '{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: language.getText('common.edit'),
                        statusKey: 'row.canEdit',
                        path: '/hibei-benefit/base-rule/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'NewImportButton',
                        statusKey: 'row.canImport',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                            apiPath: '/admin/files/locations/801/upload',
                            text: language.getText('importProduct'),
                            templateSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                apiPath: '/admin/promotion/export.xlsx',
                            },
                            specialTips: [language.getText('rxtmddsphfgzqdrdsp')],
                        },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
    renderMultipleFilters = () => {
        const { componentId } = this.props;
        let filterFormPlusProps = {
            entity: multipleEntity,
            component: 'FilterFormPlus',
            componentId,
            direction: 'inline',
            submit: {
                text: services.language.getText('common.search'),
            },
            reset: {
                text: services.language.getText('common.reset'),
                style: {
                    marginLeft: 16,
                },
            },
            onSubmit: (e: any, value: any) => {
                entity.pageChange(
                    Object.assign({}, multipleEntity.filters, multipleEntity.paging, { page: 1 })
                );
                entity.search();
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'enable',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('FilterFormPlus'), filterFormPlusProps);
    };
    renderMultipleTable = () => {
        const { componentId } = this.props;
        let tableProps = {
            entity: multipleEntity,
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subsite',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: language.getText('subsites'),
                    },
                    width: 150,
                },
                {
                    property: 'enable',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            STARTED: {
                                text: language.getText('inprogress'),
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: language.getText('end'),
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 320,
                // style: { width: 200 },
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enable',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/shopping_reward_double_promotions/:id/disable',
                                    config: {
                                        content: '{{row.name}}' + language.getText('beforeClose'),
                                        text: language.getText('gb'),
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: language.getText('isAreadyClose') + '{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/shopping_reward_double_promotions/:id/enable',
                                    config: {
                                        content: '{{row.name}}' + language.getText('beforeOpen'),
                                        text: language.getText('kq'),
                                    },
                                    confirm: {
                                        text: language.getText('isAreadyOpend') + '{{row.name}}?',
                                    },
                                },
                                {
                                    value: 'COMPLETE_OPEN',
                                    config: {
                                        text: language.getText('gb'),
                                        disabled: true,
                                    },
                                },
                                {
                                    value: 'COMPLETE_CLOSE',
                                    config: {
                                        text: language.getText('kq'),
                                        disabled: true,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: language.getText('common.edit'),
                        statusKey: 'row.canEdit',
                        path: '/hibei-benefit/multiple-rule/edit/{{row.id}}',
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
    onSelectKey = (type: string) => {
        this.setState({
            selectType: type,
        });
    };
    render() {
        const { selectType, goldAlias } = this.state;
        return (
            <div className="hibei-benefit-wrap">
                <Tabs activeKey={selectType} onTabClick={this.onSelectKey} animated={false}>
                    <Tabs.TabPane tab={language.getText('jcgz')} key="baseRule">
                        <Fragment>
                            <div>{this.renderFilters()}</div>
                            <Button
                                type="primary"
                                href="#/hibei-benefit/base-rule/add"
                                icon="plus"
                                className="mb24 mt24"
                            >
                                {language.getText('addActivity')}
                            </Button>
                            <div>{this.renderTable()}</div>
                        </Fragment>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={`${goldAlias}${language.getText('jbgz')}`}
                        key="multipleRule"
                    >
                        <Fragment>
                            <div>{this.renderMultipleFilters()}</div>
                            <Button
                                type="primary"
                                href="#/hibei-benefit/multiple-rule/add"
                                icon="plus"
                                className="mb24 mt24"
                            >
                                {language.getText('addActivity')}
                            </Button>
                            <div>{this.renderMultipleTable()}</div>
                        </Fragment>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}
