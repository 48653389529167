import React, { PureComponent, Fragment } from 'react';
import { Checkbox, Button, Modal } from 'antd';
import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const CheckboxGroup = Checkbox.Group;
interface Props {
    supplierIds: string[];
    onSupplierChange: (value: string[]) => void;
}
const plainOptions: Array<{ value: string; label: string }> = [
    {
        value: '1',
        label: '候鸟',
    },
    // {
    //     value: '2',
    //     label: '欧派',
    // },
    {
        value: '3',
        label: '行云',
    },
];
const selectedSuppliers = ['1', '3'];

const supplierVo: Record<string, string> = {
    '1': '候鸟',
    '2': '欧派',
    '3': '行云',
};
export default class SelectionPoolFilterSupplier extends PureComponent<Props, unknown> {
    state = {
        visibleModal: false,
        checkedList: [],
        indeterminate: true,
        checkAll: false,
    };

    private showModal = () => {
        const { supplierIds } = this.props;
        this.setState({
            visibleModal: true,
            checkedList: supplierIds,
            indeterminate: !!supplierIds.length && supplierIds.length < plainOptions.length,
            checkAll: supplierIds.length === plainOptions.length,
        });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    private onOk = () => {
        const { checkedList } = this.state;
        const { onSupplierChange } = this.props;
        if (onSupplierChange) {
            onSupplierChange(checkedList);
            this.hideModal();
        }
    };
    onCheckAllChange = (e: any) => {
        this.setState({
            checkedList: e.target.checked ? selectedSuppliers : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };
    onChange = (checkedList: any) => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
    };
    getSupplierNames = () => {
        const { supplierIds } = this.props;
        let supplierNames: any = '';
        if (supplierIds && supplierIds.length && supplierIds.length === plainOptions.length) {
            supplierNames = language.getText('all');
        } else {
            supplierIds.forEach((item: string, index: number) => {
                if (supplierVo[item]) {
                    if (supplierIds.length - 1 === index) {
                        supplierNames += `${supplierVo[item]}`;
                    } else {
                        supplierNames += `${supplierVo[item]},`;
                    }
                }
            });
        }
        return supplierNames;
    };

    private renderSuppliers = () => {
        const { indeterminate, checkAll, checkedList } = this.state;
        return (
            <div>
                <div>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={checkAll}
                    >
                        {language.getText('all')}
                    </Checkbox>
                </div>
                <div style={{ marginTop: 10, marginLeft: 25 }} className={clsNames.suppliers}>
                    <CheckboxGroup
                        options={plainOptions}
                        value={checkedList}
                        onChange={this.onChange}
                    />
                </div>
            </div>
        );
    };
    render() {
        const { visibleModal } = this.state;
        const { supplierIds } = this.props;
        let supplierNames = this.getSupplierNames();
        if (supplierNames?.length > 6) {
            supplierNames = supplierNames.substring(0, 5) + '...';
        }
        return (
            <Fragment>
                <div className={wrap}>
                    <Button style={{ paddingRight: 5 }} type={'link'} onClick={this.showModal}>
                        {language.getText('xzgyl')}
                    </Button>
                    {supplierIds?.length > 0 && <span>({supplierNames})</span>}
                </div>
                <Modal
                    title={language.getText('qxzgyl')}
                    width={350}
                    visible={visibleModal}
                    className={clsNames.modal}
                    onCancel={this.hideModal}
                    onOk={this.onOk}
                >
                    <div className={clsNames.modalContent}>{this.renderSuppliers()}</div>
                </Modal>
            </Fragment>
        );
    }
}

const wrap = `selection-pool-page-filter__supplier`;
const clsNames = {
    modal: `${wrap}__modal`,
    modalContent: `${wrap}__modal__content`,
    suppliers: `${wrap}__modal__content__suppliers`,
};
