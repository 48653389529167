import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, isArray, forEach } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface ScratchCardAddFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class scratchCardAddFormView extends PureComponent<ScratchCardAddFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;

        let fields = [
            {
                property: 'baseInfo.name',
            },
            { property: 'baseInfo.dateRange' },
            { property: 'baseInfo.subsiteIds' },
            { property: 'baseInfo.marketingSchedule' },
            { property: 'baseInfo.shareStatus' },
            { property: 'ruleInfo.memberLevel' },
            {
                property: 'ruleInfo.shareReward',
                visible: (values: any) => {
                    const check = get(values, 'baseInfo.shareStatus');
                    if (check && check.includes('true')) {
                        return true;
                    } else {
                        return false;
                    }
                },
            },
            { property: 'ruleInfo.pointExchange' },
            { property: 'ruleInfo.periodLimit' },
            { property: 'ruleInfo.remark' },
            { property: 'pictureInfo.backgroundPicture' },
            { property: 'pictureInfo.showRewardRecord' },
            { property: 'pictureInfo.sharePicture' },
            { property: 'pictureInfo.shareContent' },
            { property: 'pictureInfo.description' },
            {
                property: 'prizeInfo.assetRewards',
                className: 'prize-info-asset-rewards',
            },
            {
                property: 'prizeInfo.finishWithNoPrize',
                visible: (values: any) => {
                    const assetRewards = get(values, 'prizeInfo.assetRewards') || [];
                    const findNoneReward = assetRewards.filter(
                        (item: any) => item.type.checked === 'NONE'
                    );
                    if (findNoneReward.length > 0) {
                        return true;
                    }
                    return false;
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'ScratchCardAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'scratch-card-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            items: {},
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('qx'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                const assetRewards = get(entityFields, 'prizeInfo.assetRewards', null);
                if (!assetRewards || !isArray(assetRewards) || assetRewards.length <= 0) {
                    AntMessage.warning(services.language.getText('qtjjp'));
                    return false;
                }
                let haslotteryFailureValidate = true;
                let hasWinningLimitValidate = false;
                if (assetRewards) {
                    //验证中奖率100%
                    let conditionValue = 0;
                    forEach(assetRewards, (item: any) => {
                        if (item.status === 'ENABLED') {
                            conditionValue += parseFloat(item.conditionValue);
                            //存在未中奖配置
                            if (item.type.checked === 'NONE') {
                                haslotteryFailureValidate = false;
                            }
                            //存在中奖限制
                            if (
                                item.quantityType === 'NO' ||
                                item?.rewardPeriod?.memberRewardLimitType === 'NO' ||
                                item?.rewardPeriod?.periodQuantityType === 'NO'
                            ) {
                                hasWinningLimitValidate = true;
                            }
                        }
                    });
                    if (Number(conditionValue.toFixed(2)) !== 100) {
                        AntMessage.warning(services.language.getText('jpzjglbzq'));
                        return false;
                    }
                }

                const memberLevel = get(entityFields, 'ruleInfo.memberLevel', null);
                if (memberLevel) {
                    if (!memberLevel.unifiedLevel) {
                        //验证会员等级
                        if (!memberLevel.levelInfos || memberLevel.levelInfos.length === 0) {
                            AntMessage.warning(services.language.getText('qxzhydj'));
                            return false;
                        }
                    }
                    //验证抽奖次数规则
                    let hasValidate = true;
                    forEach(memberLevel.rewardInfos, (rewardInfo: any) => {
                        if (rewardInfo.rewardValue === null || rewardInfo.rewardValue === '') {
                            AntMessage.warning(services.language.getText('qsrcshdcjcs'));
                            hasValidate = false;
                            return false;
                        }
                        if (
                            rewardInfo.experienceValue === null ||
                            rewardInfo.experienceValue === ''
                        ) {
                            AntMessage.warning(services.language.getText('qsrmtewhdcjcs'));
                            hasValidate = false;
                            return false;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                }
                const shareStatus = get(entityFields, 'baseInfo.shareStatus', null);
                const shareStatusFlag = shareStatus.includes('true') ? true : false;
                const shareReward = get(entityFields, 'ruleInfo.shareReward', null);
                if (
                    shareStatusFlag &&
                    shareReward &&
                    shareReward.list &&
                    shareReward.list.length > 0
                ) {
                    let hasShareRewardValidate = true;
                    forEach(shareReward.list, (rewardInfo: any) => {
                        if (rewardInfo.limit === null || rewardInfo.limit === '') {
                            AntMessage.warning(services.language.getText('qsrfxcssx'));
                            hasShareRewardValidate = false;
                            return false;
                        }
                    });
                    if (!hasShareRewardValidate) {
                        return false;
                    }
                }
                const pointExchange = get(entityFields, 'ruleInfo.pointExchange', null);
                if (pointExchange && pointExchange.list && pointExchange.list.length > 0) {
                    let hasPointExchangeValidate = true;
                    forEach(pointExchange.list, (rewardInfo: any) => {
                        if (
                            !rewardInfo.point ||
                            rewardInfo.point === null ||
                            rewardInfo.point === ''
                        ) {
                            AntMessage.warning(services.language.getText('qsrdhjf'));
                            hasPointExchangeValidate = false;
                            return false;
                        }
                        if (
                            !rewardInfo.limit ||
                            rewardInfo.limit === null ||
                            rewardInfo.limit === ''
                        ) {
                            AntMessage.warning(services.language.getText('qsrdhcssx'));
                            hasPointExchangeValidate = false;
                            return false;
                        }
                    });
                    if (!hasPointExchangeValidate) {
                        return false;
                    }
                }
                const periodLimit = get(entityFields, 'ruleInfo.periodLimit', null);
                if (periodLimit && periodLimit.list && periodLimit.list.length > 0) {
                    let hasPeriodLimitValidate = true;
                    forEach(periodLimit.list, (rewardInfo: any) => {
                        if (
                            !rewardInfo.limit ||
                            rewardInfo.limit === null ||
                            rewardInfo.limit === ''
                        ) {
                            AntMessage.warning(services.language.getText('qsrzjcssx'));
                            hasPeriodLimitValidate = false;
                            return false;
                        }
                        hasWinningLimitValidate = false;
                    });
                    if (!hasPeriodLimitValidate) {
                        return false;
                    }
                    if (!hasWinningLimitValidate && haslotteryFailureValidate) {
                        AntMessage.warning(services.language.getText('dqjpjczslxz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('ScratchCardAddFormContainer'));
            },
        }
    );
}
export const ScratchCardAddFormView = connect(mapDispatchToProps)(scratchCardAddFormView);
