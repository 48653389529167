import React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';

export class ExtendAttributesMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ExtendAttributes {...controlInfo} />;
    }
}

// 目的是占位  entity.modify 提交含有非表单的数据时 会 请求终止。
function ExtendAttributes(params: any) {
    return null;
}
