import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import { cloneDeep, map } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CardNavItem } from './item';

/**
 * 卡片导航组件列表
 */
export class CardNavItems extends PureComponent<{
    maxCount?: number;
    onChange?: (value: any) => void;
    value?: Array<any>;
    data: any;
    linkTypes: Array<{ id: string; name: string }>;
    sourceRoot: {
        image?: string;
    };
    uploadAction: string;
    selector?: any;
}> {
    static propTypes = {
        maxCount: PropTypes.number,
        onChange: PropTypes.func,
        value: PropTypes.array,
        data: PropTypes.object,
    };

    state = { uploadLoading: false };

    onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const { value, onChange } = this.props;
        let newValue: any = cloneDeep(value);
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = newValue.splice(startIndex, 1);
        newValue.splice(endIndex, 0, removed);

        onChange && onChange(cloneDeep(newValue));
    };

    addItem = () => {
        let { value, onChange } = this.props;
        if (!value) {
            value = [];
        }
        onChange && onChange([...value, {}]);
    };

    renderCreateItem = () => {
        const { value, maxCount } = this.props;
        if (value && maxCount && value.length >= maxCount) {
            return undefined;
        }
        return (
            <div className="entry-item item-create">
                <p>
                    <Button type={'link'} onClick={this.addItem}>
                        <Icon type="plus" /> {'新增导航项'}
                    </Button>
                </p>
            </div>
        );
    };

    renderItem = (item: any, index: any, isDragDisabled: any) => {
        const { onChange, linkTypes, value, sourceRoot, selector, uploadAction } = this.props;

        return (
            <Draggable
                isDragDisabled={isDragDisabled}
                draggableId={'drag-' + index}
                key={index}
                index={index}
            >
                {(provided: any) => {
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <CardNavItem
                                sourceRoot={sourceRoot}
                                items={value}
                                index={index}
                                onChange={onChange}
                                item={item}
                                uploadAction={uploadAction}
                                linkTypes={linkTypes}
                                selector={selector}
                            />
                        </div>
                    );
                }}
            </Draggable>
        );
    };

    renderItems = () => {
        const { value: items } = this.props;
        const isDragDropDisabled = items && items.length === 1;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable
                    isDropDisabled={isDragDropDisabled}
                    droppableId="cm-editor-list"
                    direction="vertical"
                >
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {map(items, (item, index) =>
                                this.renderItem(item, index, isDragDropDisabled)
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    render() {
        return (
            <div className="entry-list">
                {this.renderItems()}
                {this.renderCreateItem()}
            </div>
        );
    }
}
